import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';
import {
  CheckCircle as SuccessIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import { Grid, Box } from '@material-ui/core';

const BaseBox = styled(Box)`
  padding: ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2.5)}px;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => props.theme.palette.text.primary};
  color: ${props => props.theme.palette.text.primary};
`;

// border-color: ${props => props.theme.palette.info.main};
const InfoBox = styled(BaseBox)`
  border-color: ${props => '#A0B3D9'};
  color: ${props => props.theme.palette.info.main};
`;

const ErrorBox = styled(BaseBox)`
  border-color: ${props => props.theme.palette.error.main};
  color: ${props => props.theme.palette.error.main};
`;

const WarningBox = styled(BaseBox)`
  border-color: ${props => props.theme.palette.warning.main};
  color: ${props => props.theme.palette.warning.main};
`;

const SuccessBox = styled(BaseBox)`
  border-color: ${props => props.theme.palette.success.main};
  color: ${props => props.theme.palette.success.main};
`;

function NoticeBox({
  variant = "base",
  children,
  ...props
}) {
  const theme = useTheme();
  const getBox = () => {
    switch (variant) {
      case "info":
        return InfoBox;
      case "warning":
        return WarningBox;
      case "error":
        return ErrorBox;
      case "success":
        return SuccessBox;
      default:
        return BaseBox;
    }
  }
  const getIcon = () => {
    switch (variant) {
      case "error":
        return ErrorIcon;
      case "success":
        return SuccessIcon;
      case "info":
      case "warning":
      default:
        return InfoIcon;
    }
  }

  const BoxComp = getBox();
  const Icon = getIcon();

  return (
    <BoxComp theme={theme} {...props}>
      <Grid container wrap="nowrap" spacing={1} alignItems="center">
        <Grid item xs={1}>
          <Icon
            fontSize="large"
            color="inherit"
          />
        </Grid>
        <Grid item xs={11}>
          {children}
        </Grid>
      </Grid>
    </BoxComp>
  );
}

export default NoticeBox;

import React from 'react';
import PropTypes from 'prop-types';
import { Route as BaseRoute, Redirect } from 'react-router-dom';

const Route = ({ valid, invalidRedirectTo, ...props }) => {
  if (valid) {
    return <BaseRoute {...props} />;
  }

  if (invalidRedirectTo) {
    return (
      <Redirect
        to={{ pathname: invalidRedirectTo, state: { ...props?.state } }}
      />
    );
  }

  return null;
};

Route.propTypes = {
  valid: PropTypes.bool,
  invalidRedirectTo: PropTypes.any,
};

export default Route;

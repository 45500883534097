import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash/lang';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import {
  getMinValue,
  getMaxValue,
} from 'containers/VistaIllustrationPage/helper';
import { isFieldEmpty } from 'utils/form';
import { isAlphaNumeric } from 'helpers/lang';

const validate = (values, props) => {
  const errors = {};
  const gcConfig = props.gcConfig;
  const lifeAssured = props.lifeAssured;
  const MAX_GROWTH_RATE = gcConfig?.features?.growthRate?.maxRate;
  const MIN_GROWTH_RATE = gcConfig?.features?.growthRate?.minRate;
  const MAX_TARGET_FUND = 999999999;
  const MAX_TARGE_YEAR = 2100;

  const maxStartDate = gcConfig.productCode === 'VISP2' ? 1.5 : 1; // get max start date

  if (!values.isEmpty()) {
    const offerCodeValues = values.get('offerCode');
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const termValues = values.get('term');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const targetFunctionalityValues = values.get('targetFunctionality');
    const hasToCalculate = values.get('hasToCalculate');
    const targetYearValues = values.get('targetYear');
    const targetFundValues = values.get('targetFund');
    const growthRateValues = values.get('growthRate');
    const premiumValues = values.get('premium');
    const isEducationFeeWithdrawalValues = values.get(
      'isEducationalFeeWithdrawal',
    );
    const isRegularWithdrawalValues = values.get('isRegularWithdrawal');
    const regularWithdrawalValues = values.get('regularWithdrawal');

    // const coveragesValues = values.get('coverages');
    const isSinglePremiumValues = values.get('haveAdditionalSinglePremium');
    const singlePremiumValues = values.get('singlePremium');

    if (!startDateValues || !moment(startDateValues).isValid())
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!termValues || termValues === 'Select')
      errors.term = <FormattedMessage {...globalMessages.pleaseSelectValue} />;

    if (
      (!paymentFrequencyValues && termValues != 'SinglePremium') ||
      paymentFrequencyValues === 'Select'
    )
      errors.paymentFrequency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );

    if (targetFunctionalityValues) {
      if (!targetYearValues || targetYearValues === 'Invalid date')
        errors.targetYear = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (
        targetYearValues &&
        targetYearValues < new Date().getFullYear() + 5
      )
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: new Date().getFullYear() + 5 }}
          />
        );
      else if (targetYearValues && targetYearValues > MAX_TARGE_YEAR)
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_TARGE_YEAR }}
          />
        );

      if (!targetFundValues)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (targetFundValues && targetFundValues <= 0)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.greaterThanZero} />
        );
      else if (targetFundValues > MAX_TARGET_FUND) {
        errors.targetFund = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{
              value: MAX_TARGET_FUND.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              ),
            }}
          />
        );
      }
    }

    if (hasToCalculate) {
      errors.hasToCalculate = (
        <FormattedMessage {...globalMessages.hasToCalculate} />
      );
    }

    if (!Number(premiumValues)) {
      if (!targetFunctionalityValues)
        errors.premium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
    } else {
      var minVal = 0;
      var maxVal = 0;
      if (termValues === 'SinglePremium') {
        minVal = getMinValue('Single', gcConfig.features, currencyValues);
        maxVal = getMaxValue('Single', gcConfig.features, currencyValues);
      } else {
        minVal = getMinValue(
          paymentFrequencyValues,
          gcConfig.features,
          currencyValues,
        );
        maxVal = getMaxValue(
          paymentFrequencyValues,
          gcConfig.features,
          currencyValues,
        );
      }

      if (premiumValues < minVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minVal }}
          />
        );

      if (premiumValues > maxVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: maxVal }}
          />
        );
    }

    if (isSinglePremiumValues) {
      if (!singlePremiumValues)
        errors.singlePremium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else {
        var minSinglePremium = 0;
        var maxSinglePremium = 0;
        gcConfig.features?.currencyPremiumSize.map(val => {
          if (currencyValues === val.currency) {
            minSinglePremium = val.minAdditionalSinglePremium;
            maxSinglePremium = val.maxAdditionalSinglePremium;
            return;
          }
        });
        if (singlePremiumValues < minSinglePremium)
          errors.singlePremium = (
            <FormattedMessage
              {...globalMessages.minLimit}
              values={{ value: minSinglePremium }}
            />
          );

        if (singlePremiumValues > maxSinglePremium) {
          errors.singlePremium = (
            <FormattedMessage
              {...globalMessages.maxLimit}
              values={{ value: maxSinglePremium }}
            />
          );
        }
      }
    }

    if (!growthRateValues)
      errors.growthRate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    else {
      if (growthRateValues <= MIN_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage {...globalMessages.minLimitGrowthRate} />
        );
      if (growthRateValues > MAX_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_GROWTH_RATE }}
          />
        );
    }

    // Regular withdrawal validation
    if (isRegularWithdrawalValues) {
      let errorRegularWithdrawal = {};
      if (
        !regularWithdrawalValues.get('frequency') ||
        regularWithdrawalValues.get('frequency') === 'Select'
      )
        errorRegularWithdrawal.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );

      if (
        !regularWithdrawalValues.get('startDate') ||
        !moment(regularWithdrawalValues.get('startDate')).isValid()
      )
        errorRegularWithdrawal.startDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var minStartDate = moment(startDateValues)
          .date(15)
          .add(maxStartDate, 'years')
          .toDate();

        if (moment(regularStartDateValues, 'YYYY-MM-DD').format('DD') != 15)
          errorRegularWithdrawal.startDate = (
            <FormattedMessage {...globalMessages.dateValidation2} />
          );
        else {
          if (regularStartDateValues.isBefore(minStartDate))
            errorRegularWithdrawal.startDate = (
              <FormattedMessage
                {...globalMessages.dateValidation}
                values={{ value: moment(minStartDate).format('DD/MM/YYYY') }}
              />
            );
        }
      }

      if (
        !regularWithdrawalValues.get('endDate') ||
        !moment(regularWithdrawalValues.get('endDate')).isValid()
      )
        errorRegularWithdrawal.endDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularEndDateValue = moment(
          regularWithdrawalValues.get('endDate'),
        );
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var maxEndDate = moment(regularStartDateValues)
          .add(100, 'years')
          .toDate();

        if (regularEndDateValue.isBefore(regularStartDateValues))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.minEndDateValidation} />
          );

        if (regularEndDateValue.isAfter(maxEndDate))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.maxEndDateValidation} />
          );
      }

      if (regularWithdrawalValues.get('isAmountChecked') == 1) {
        if (!regularWithdrawalValues.get('amount'))
          errorRegularWithdrawal.amount = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const withdrawalAmount = regularWithdrawalValues.get('amount');
          var minPremiumAllowed = 0;
          var maxPremiumAllowed = 0;

          gcConfig.features.withdrawalSize.map(premiumSize => {
            if (premiumSize.currency === currencyValues) {
              minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
            }
          });

          if (termValues === 'SinglePremium')
            maxPremiumAllowed = getMaxValue(
              'Single',
              gcConfig.features,
              currencyValues,
            );
          else
            maxPremiumAllowed = getMaxValue(
              paymentFrequencyValues,
              gcConfig.features,
              currencyValues,
            );

          if (withdrawalAmount < minPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPremiumAllowed }}
              />
            );

          if (withdrawalAmount > maxPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPremiumAllowed }}
              />
            );
        }
      }

      if (regularWithdrawalValues.get('isAmountChecked') == 2) {
        const minPercentage =
          gcConfig.features?.regularWithdrawal?.minPercentage;
        const maxPercentage =
          gcConfig.features?.regularWithdrawal?.maxPercentage;

        if (!regularWithdrawalValues.get('percentage'))
          errorRegularWithdrawal.percentage = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          if (regularWithdrawalValues.get('percentage') <= 0)
            errorRegularWithdrawal.percentage = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPercentage }}
              />
            );

          if (regularWithdrawalValues.get('percentage') > maxPercentage)
            errorRegularWithdrawal.percentage = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPercentage }}
              />
            );
        }
      }
      if (!isEmpty(errorRegularWithdrawal)) {
        errors.regularWithdrawal = errorRegularWithdrawal;
      }
    }

    // Education Fee withdrawal validation
    if (isEducationFeeWithdrawalValues) {
      const requiredTextFields = [
        'startDate',
        'endDate',
        'firstName',
        'lastName',
        'amount',
      ];
      const requiredSelectFields = ['frequency'];
      const errorEducationFeesWithdrawal = [];

      const educationWithdrawal = values
        .get('educationFeesWithdrawalArray')
        .toJS();

      educationWithdrawal.forEach((eduWD, index) => {
        const eduWDErrors = {};
        requiredSelectFields.forEach(field => {
          if (isFieldEmpty(eduWD[field]) || eduWD[field] === 'Select') {
            eduWDErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
            errorEducationFeesWithdrawal[index] = eduWDErrors;
          }
        });

        requiredTextFields.forEach(field => {
          if (isFieldEmpty(eduWD[field])) {
            eduWDErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
            errorEducationFeesWithdrawal[index] = eduWDErrors;
          } else {
            if (field === 'startDate') {
              const educationFeeStartDateValues = moment(eduWD.startDate);

              if (!educationFeeStartDateValues.isValid())
                eduWDErrors[field] = (
                  <FormattedMessage {...globalMessages.enterValidValue} />
                );

              const minStartDate = moment(startDateValues)
                .date(15)
                .add(maxStartDate, 'years')
                .toDate();
              if (
                moment(educationFeeStartDateValues, 'YYYY-MM-DD').format(
                  'DD',
                ) != 15
              )
                eduWDErrors[field] = (
                  <FormattedMessage {...globalMessages.dateValidation2} />
                );
              else {
                if (educationFeeStartDateValues.isBefore(minStartDate))
                  eduWDErrors[field] = (
                    <FormattedMessage
                      {...globalMessages.dateValidation}
                      values={{
                        value: moment(minStartDate).format('DD/MM/YYYY'),
                      }}
                    />
                  );
              }
            }

            if (field === 'endDate') {
              const educationFeeEndDateValue = moment(eduWD.endDate);
              const educationFeeStartDateValues = moment(eduWD.startDate);
              const maxEndDate = moment(eduWD.startDate)
                .add(100, 'years')
                .toDate();

              if (!educationFeeEndDateValue.isValid())
                eduWDErrors[field] = (
                  <FormattedMessage {...globalMessages.enterValidValue} />
                );

              if (
                educationFeeEndDateValue.isBefore(educationFeeStartDateValues)
              )
                eduWDErrors[field] = (
                  <FormattedMessage {...globalMessages.minEndDateValidation} />
                );
              if (educationFeeEndDateValue.isAfter(maxEndDate))
                eduWDErrors[field] = (
                  <FormattedMessage {...globalMessages.maxEndDateValidation} />
                );
            }

            if (field === 'firstName') {
              if (!isAlphaNumeric(eduWD.firstName)) {
                eduWDErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.onlyAlphanumericCharacters}
                  />
                );
              }
            }

            if (field === 'lastName') {
              if (!isAlphaNumeric(eduWD.lastName)) {
                eduWDErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.onlyAlphanumericCharacters}
                  />
                );
              }
            }

            if (field === 'amount') {
              const withdrawalAmount = eduWD.amount;
              let minPremiumAllowed = 0;
              let maxPremiumAllowed = 0;
              let withdrawalFrequencyValues = eduWD.frequency;
              gcConfig.features.withdrawalSize.map(premiumSize => {
                if (premiumSize.currency === currencyValues) {
                  minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
                }
              });
              if (termValues === 'SinglePremium')
                maxPremiumAllowed = getMaxValue(
                  'Single',
                  gcConfig.features,
                  currencyValues,
                );
              else
                maxPremiumAllowed = getMaxValue(
                  withdrawalFrequencyValues,
                  gcConfig.features,
                  currencyValues,
                );
              if (withdrawalAmount < minPremiumAllowed)
                eduWDErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.minWithdrawalValidation}
                    values={{ value: minPremiumAllowed }}
                  />
                );
              if (withdrawalAmount > maxPremiumAllowed)
                eduWDErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maxWithdrawalValidation}
                    values={{ value: maxPremiumAllowed }}
                  />
                );
            }
            if (!isEmpty(eduWDErrors)) {
              errorEducationFeesWithdrawal[index] = eduWDErrors;
            }
          }
        });
      });

      if (errorEducationFeesWithdrawal.length) {
        errors.educationFeesWithdrawalArray = errorEducationFeesWithdrawal;
      }
    }

    if (!isEmpty(offerCodeValues)) {
      if (!premiumValues)
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseFillInThePremium} />
        );

      if (currencyValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectCurrency} />
        );
      }
      if (termValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectPremiumTerm} />
        );
      }
    }
  }
  return errors;
};

export default validate;

/*
 * IllustrationPage Messages
 *
 * This contains all the text for the IllustrationPage container.
 */
import { defineMessages } from 'react-intl';
export const scope = 'app.containers.OpsDashboardPage';
export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Illustrations',
  },
  newIllustratoin: {
    id: `${scope}.newIllustratoin`,
    defaultMessage: 'New Illustration',
  },
  newCustomer: {
    id: `${scope}.newCustomer`,
    defaultMessage: 'New Customer',
  },
  existingCustomer: {
    id: `${scope}.existingCustomer`,
    defaultMessage: 'Existing Customer',
  },
  chooseProduct: {
    id: `${scope}.chooseProduct`,
    defaultMessage: 'Choose your product',
  },
  selectAProduct: {
    id: `${scope}.selectAProduct`,
    defaultMessage: 'Select a product',
  },
  ita: {
    id: `${scope}.ita`,
    defaultMessage: '1. ITA-International Term Assurance',
  },
  futura: {
    id: `${scope}.futura`,
    defaultMessage: '2. FUT-Futura',
  },
  processThisOne: {
    id: `${scope}.processThisOne`,
    defaultMessage: 'Process this one',
  },
  makeAnother: {
    id: `${scope}.makeAnother`,
    defaultMessage: 'Make another',
  },
  clientListIllustratoin: {
    id: `${scope}.clientListIllustratoin`,
    defaultMessage: 'Client list: Frank Skinner',
  },
  productITA: {
    id: `${scope}.productITA`,
    defaultMessage: 'International Term Assurance',
  },
  productFUTU: {
    id: `${scope}.productFUTU`,
    defaultMessage: 'Futura',
  },
  productMFS01: {
    id: `${scope}.productMFS01`,
    defaultMessage: 'Global Choice',
  },
  productDTME2: {
    id: `${scope}.productDTME2`,
    defaultMessage: 'International Decreasing Term Assurance',
  },
  productSW: {
    id: `${scope}.productSW`,
    defaultMessage: 'Simple Wealth Plan',
  },
  productRSP: {
    id: `${scope}.productRSP`,
    defaultMessage: 'Legacy',
  },
  productWAP: {
    id: `${scope}.productWAP`,
    defaultMessage: 'Wealth Accumulation Plan',
  },
  emailPDF: {
    id: `${scope}.emailPDF`,
    defaultMessage: 'Email PDF',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
  choose: {
    id: `${scope}.choose`,
    defaultMessage: 'Choose',
  },
  customers: {
    id: `${scope}.customers`,
    defaultMessage: 'Customers',
  },
  ci: {
    id: `${scope}.ci`,
    defaultMessage: 'Critical illness',
  },
  protectionBenefit: {
    id: `${scope}.protectionBenefit`,
    defaultMessage: 'Protection benefit',
  },
  lifeCover: {
    id: `${scope}.lifeCover`,
    defaultMessage: 'Life cover',
  },
  waiver: {
    id: `${scope}.waiver`,
    defaultMessage: 'Waiver',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View',
  },
  approveEmail: {
    id: `${scope}.approveEmail`,
    defaultMessage: 'Application Review Dashboard',
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name',
  },
  approve: {
    id: `${scope}.approve`,
    defaultMessage: 'Approve',
  },
  reject: {
    id: `${scope}.reject`,
    defaultMessage: 'Reject',
  },
  rejectReasonTitle: {
    id: `${scope}.rejectReasonTitle`,
    defaultMessage: 'Reason for Rejection',
  },
  rejectReason: {
    id: `${scope}.rejectReason`,
    defaultMessage: 'Please provide reason for rejection',
  },
  reasonOfRejection: {
    id: `${scope}.reasonOfRejection`,
    defaultMessage: 'Rejected for Submission to Zurich: ',
  },
  approvedMessage: {
    id: `${scope}.approvedMessage`,
    defaultMessage: 'Approved for Submission to Zurich',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },

  referenceNumber: {
    id: `${scope}.referenceNumber`,
    defaultMessage: 'Reference :',
  },
  newCustomer: {
    id: `${scope}.newCustomer`,
    defaultMessage: 'New Customer',
  },
  accept: {
    id: `${scope}.accept`,
    defaultMessage: 'Accept',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  exit: {
    id: `${scope}.exit`,
    defaultMessage: 'Exit',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: `Next`,
  },
  confirmAprrove: {
    id: `${scope}.confirmAprrove`,
    defaultMessage: `Are you sure, you want to approve?`,
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: `Yes`,
  },  
  no: {
    id: `${scope}.no`,
    defaultMessage: `No`,
  },  
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Cover details',
  },
  extReferenceNumber: {
    id: `${scope}.extReferenceNumber`,
    defaultMessage: 'Ext Reference :',
  },
  policyNumber: {
    id: `${scope}.policyNumber`,
    defaultMessage: 'Policy Number :',
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Back',
  },
});

import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoRow from 'components/InfoRow';
const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '660px',
  },
  link: {
    color: '#00C3B4',
  },
  disabled: {
    color: '#B6B8B4',
  },
}));

export default function Section(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { sectionName, title, currency, value, frequency, additionalSinglePremiumAmount, isWap, additionalSinglePremiumLabel } = props;
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" color="primary">
          {sectionName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {(isWap === true) ?
          <>
            <InfoRow>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={2}
              >
                <Grid item>
                  <CheckCircleIcon
                    fontSize="small"
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h4" color="inherit">
                    {title}
                    {/* <FormattedMessage {...messages.premiumAmount} /> */}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <div>
                    <Typography variant="h4" color="inherit">
                      {`${currency} ${value}`}
                    </Typography>
                  </div>
                </Grid>


              </Grid>

            </InfoRow>
            <InfoRow>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                justify="center"
                spacing={2}
              >
                <Grid item>
                  <CheckCircleIcon
                    fontSize="small"
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="h4" color="inherit">
                    {additionalSinglePremiumLabel}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <div>
                    <Typography variant="h4" color="inherit">
                      {`${currency} ${additionalSinglePremiumAmount} ${frequency ? ' / ' + frequency : ''}`}
                    </Typography>
                  </div>
                </Grid>


              </Grid>

            </InfoRow>
          </>
          :
          <InfoRow>
            <Grid
              container
              alignItems="center"
              alignContent="center"
              justify="center"
              spacing={2}
            >
              <Grid item>
                <CheckCircleIcon
                  fontSize="small"
                  style={{
                    color: theme.palette.grey[400],
                  }}
                />
              </Grid>
              <Grid item xs>
                <Typography variant="h4" color="inherit">
                  {title}
                  {/* <FormattedMessage {...messages.premiumAmount} /> */}
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <div>
                  <Typography variant="h4" color="inherit">
                    {`${currency} ${value} ${frequency ? '/ ' + frequency : ''}`}
                  </Typography>
                </div>
              </Grid>


            </Grid>

          </InfoRow>
        }

      </Grid>
    </>
  );
}

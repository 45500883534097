import React from 'react';
import SnackbarContent from 'components/SnackbarContent';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

const CustomSnackbarContent = styled(({ theme, ...props }) => (
  <SnackbarContent {...props} />
))`
  && {
    width: 280px;
    min-height: 80px;
    padding: 20px;
    font-weight: 600;
    position: relative;
  }
`;

export default withTheme(CustomSnackbarContent);

/**
 *
 * IdVerification
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Switch, Route } from 'react-router-dom';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import Landing from './components/Landing';
// import { makeSelectShuftiPro } from './selectors';
import { resetStateAction } from './actions';
import { Grid, Button } from '@material-ui/core';
import Header from './components/Header';
import ZLogo from 'images/logo-white.png';
import messages from 'containers/IdVerification/messages';
import { FormattedMessage } from 'react-intl';

export function IdVerification({ resetState }) {
  useInjectReducer({ key: 'idVerification', reducer });
  useInjectSaga({ key: 'idVerification', saga });

  useEffect(() => {
    return () => {
      resetState();
    }
  },[]);

  return (
    <Grid
      container direction="column"
      style={{
        height: '100%',
      }}
    >
      <Grid item>
        <Header>
          <Grid container alignItems="center">
            <Grid item xs>
              <img src={ZLogo} alt="zurich-logo" height={24} />
            </Grid>
            <Grid item>
              <Button
                color="default"
                variant="contained"
                onClick={() => {
                  window.close();
                }}
              >
                <FormattedMessage {...messages.endSession} />
              </Button>
            </Grid>
          </Grid>
        </Header>
      </Grid>
      <Grid item xs>
        <Switch>
          <Route path="/id-verification" exact component={Landing} />
        </Switch>
      </Grid>
    </Grid>
  );
}

IdVerification.propTypes = {
  resetState: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
});

function mapDispatchToProps(dispatch) {
  return {
    resetState: () => dispatch(resetStateAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IdVerification);

/**
 * Withdrawal
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import { Grid, FormControlLabel, Radio, Divider, Box } from '@material-ui/core';
import globalMessages from "containers/App/messages";
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import messages from '../messages';
import FormSelect from 'components/FormSelect';
import FormRadioGroup from 'components/FormRadioGroup';
import FormNumberField from 'components/FormNumberField';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));


function SinglePremium({ formValues = {}, frequencyList, error, minDate, maxDate, isEducationWithdrawal }) {
  const theme = useTheme();
  const intl = useIntl();


  if (isEducationWithdrawal)
    formValues = formValues.additionalSinglePremium;

  const classes = useStyles();
  return (
    <Box mb={3} ml={1}>
      <Grid container spacing={3} alignItems="center">
        <Grid item>
          <Field
            shrink
            name="amount"
            style={{ width: 172 }}
            fullWidth
            component={FormNumberField}
            label={<FormattedMessage {...messages.amount} />}
            placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
          />
        </Grid>

        <Grid item sm={5} xs={12} md={5}>
          <Field
            shrink
            name="frequency"
            fullWidth
            component={FormSelect}
            // options={frequencyList}
            style={{
              color: changePlaceHolderColor(convertIfImmutable(formValues).frequency),
              width: 260,
            }}
            options={[
              { value: 'Select', title: 'Select' },
              ...frequencyList ]}
            type="text"
            color="primary"
            variant="standard"
            label={<FormattedMessage {...messages.frequency} />}
          />
        </Grid>

        {/* <Grid item sm={5} xs={12} md={5}>
           <Field
             name="startDate"
             component={FormDatePicker}
             label={<FormattedMessage {...messages.startDate} />}
             dateInputFormat="DD/MM/YYYY"
             placeholder="dd/mm/yyyy"
             margin="normal"
             minDate={minDate}
             maxDate={maxDate}
             InputLabelProps={{
               style: {
                 fontWeight: 'normal',
                 fontSize: theme.typography.body2.fontSize,
               },
             }}
             shouldDisableDate={date => {
               if (date.date() !== 1) {
                 return true;
               }
               return false;
             }}
           />
         </Grid>
 
         <Grid item sm={5} xs={12} md={5}>
           <Field
             name="endDate"
             component={FormDatePicker}
             label={<FormattedMessage {...messages.endDate} />}
             dateInputFormat="DD/MM/YYYY"
             placeholder="dd/mm/yyyy"
             margin="normal"
             minDate={minDate}
             maxDate={maxDate}
             InputLabelProps={{
               style: {
                 fontSize: theme.typography.body2.fontSize,
                 fontWeight: 'normal',
               },
             }}
             shouldDisableDate={date => {
              if (date.date() !== 1) {
                return true;
              }
              return false;
            }}
           />
         </Grid> */}
      </Grid>
    </Box>
  );
}

SinglePremium.propTypes = {
  formValues: PropTypes.object,
  frequencyList: PropTypes.array,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  error: PropTypes.bool,
  isEducationWithdrawal: PropTypes.bool
};

export default SinglePremium;
import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  fill: currentColor;
`;

function IconSearch(props) {
  return (
    <SvgIcon viewBox="0 0 23 23" version="1.1" fontSize="inherit" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <GA transform="translate(-1.000000, -1.000000)" fillRule="nonzero">
          <path d="M23.707,20.879 L19.337,16.509 C20.386,14.928 21,13.035 21,11 C21,5.486 16.514,1 11,1 C5.486,1 1,5.486 1,11 C1,16.514 5.486,21 11,21 C13.035,21 14.928,20.386 16.509,19.337 L20.878,23.707 C21.269,24.098 21.902,24.098 22.292,23.707 L23.706,22.293 C24.097,21.902 24.098,21.269 23.707,20.879 Z M3,11 C3,6.589 6.589,3 11,3 C15.411,3 19,6.589 19,11 C19,15.411 15.411,19 11,19 C6.589,19 3,15.411 3,11 Z" id="Shape" />
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconSearch;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import messages from 'containers/ApplicationFormPage/messages';
import globalMessages from 'containers/App/messages';
import { get } from 'lodash/object';
import { Field, FieldArray, FormSection, Fields } from 'redux-form/immutable';
import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';

import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import FormNumberField from 'components/FormNumberField';
import { options } from 'numeral';
import { convertIfImmutable } from 'helpers/lang';

const useStyle = makeStyles(theme => ({
  bold: { fontWeight: 800 },
  hr: {
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(3),
    // marginLeft: '-2rem',
    // marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  container: { marginBottom: '1rem' },
}));

const RenderFundYears = props => {
  const { fields } = props;
  const intl = useIntl();
  const classes = useStyle();

  useEffect(() => {
    if (fields.length === 0) {
      fields.push({
        year: moment().format('YYYY'),
        earnedSalary: '',
        investmentIncome: '',
        otherIncome: '',
      });
      fields.push({
        year: moment()
          .subtract('1', 'year')
          .format('YYYY'),
        earnedSalary: '',
        investmentIncome: '',
        otherIncome: '',
      });
      fields.push({
        year: moment()
          .subtract('2', 'year')
          .format('YYYY'),
        earnedSalary: '',
        investmentIncome: '',
        otherIncome: '',
      });
    }
  }, [fields]);

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        {fields.map((field, index) => {
          return (
            <Grid item xs={12} key={`${field}-${index}`}>
              <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item xs={3}>
                  <Typography variant="body1">
                    {convertIfImmutable(fields.get(index)).year}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Field
                    shrink
                    name={`${field}.earnedSalary`}
                    fullWidth
                    component={FormNumberField}
                    label={<FormattedMessage {...messages.earnedSalary} />}
                    type="text"
                    color="inherit"
                    margin="normal"
                    variant="standard"
                    placeholder={intl.formatMessage(globalMessages.amount)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    shrink
                    name={`${field}.investmentIncome`}
                    fullWidth
                    component={FormNumberField}
                    label={<FormattedMessage {...messages.investmentIncome} />}
                    type="text"
                    color="inherit"
                    margin="normal"
                    variant="standard"
                    placeholder={intl.formatMessage(globalMessages.amount)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    shrink
                    name={`${field}.otherIncome`}
                    fullWidth
                    component={FormNumberField}
                    label={<FormattedMessage {...messages.otherIncome} />}
                    type="text"
                    color="inherit"
                    margin="normal"
                    variant="standard"
                    placeholder={intl.formatMessage(globalMessages.amount)}
                  />
                </Grid>
              </Grid>
              <hr className={classes.hr} style={{marginTop: 24}}/>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
  return;
};

function PayorFunds(props) {
  const classes = useStyle();

  const { formValues = {}, options } = props;

  return (
    <Grid container spacing={3} style={{paddingTop: 30, paddingLeft: 18}}>
      <Grid item xs={12} md={8}>
        <Typography variant="body1">
          <FormattedMessage {...messages.sourceOfFunds} />
        </Typography>
      </Grid>
      {/* CURRENCY */}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="body1" className={classes.bold}>
              <FormattedMessage {...messages.currency} />
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Field
              name="currency"
              component={FormSelect}
              fullWidth
              options={options.currency}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ height: '3rem' }} />

      <Grid item xs={12}>
        <hr className={classes.hr} />
      </Grid>
      <Grid item xs={12}>
        <FieldArray name="FundYears" component={RenderFundYears} />
      </Grid>
    </Grid>
  );
}

PayorFunds.propTypes = {
  options: PropTypes.object,
  formValues: PropTypes.object,
};

export default PayorFunds;

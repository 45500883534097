/**
 *
 * Snackbar
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SnackbarContent as BaseSnackbarContent } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

function getSeverityColor(severity, theme) {
  switch (severity) {
    case 'error':
      return theme.palette.error;
    case 'warning':
      return theme.palette.warning;
    case 'info':
      return theme.palette.info;
    case 'success':
      return theme.palette.success;
    default:
      return theme.palette.info;
  }
}

const StyledSnackbarContent = styled(props => {
  const { severity, theme, ...snackbarContentProps } = props;
  return <BaseSnackbarContent {...snackbarContentProps} />;
})`
  && {
    line-height: 1.5;
    flex-wrap: nowrap;
    background-color: ${props =>
      getSeverityColor(props.severity, props.theme).main};
    color: ${props =>
      getSeverityColor(props.severity, props.theme).contrastText};
  }
`;

function SnackbarContent(props) {
  const theme = useTheme();
  return <StyledSnackbarContent theme={theme} {...props} />;
}

SnackbarContent.propTypes = {
  severity: PropTypes.oneOf(['warning', 'success', 'error', 'info', 'default']),
};

export default SnackbarContent;

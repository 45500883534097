/*
 *
 * OktaAuthHandler actions
 *
 */

import { DEFAULT_ACTION, REFRESH_OKTA_TOKEN_ACTION } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function refreshOktaTokenAction(oktaAccessToken) {
  return {
    type: REFRESH_OKTA_TOKEN_ACTION,
    payload: oktaAccessToken,
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import styled from 'styled-components';
import OktaAuthHandler from 'containers/OktaAuthHandler/Loadable';
// import { Security } from '@okta/okta-react';
// import { okta } from 'environments';
// import globalMessages from 'containers/App/messages';
import { logoutAction } from './actions';
import { Grid } from '@material-ui/core';

const isIE = /*@cc_on!@*/ false || !!document.documentMode;

function AppWrapper({ children, logout, ...props }) {
  return (
    <Grid
      container
      direction="column"
      style={{ height: '100%', display: isIE ? 'block' : 'flex' }}
      wrap="nowrap"
    >
      {children}
      <OktaAuthHandler />
    </Grid>
  );
}

AppWrapper.propTypes = {
  children: PropTypes.node,
  logout: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    logout: ({ message }) => dispatch(logoutAction({ message })),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AppWrapper);

import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  // TODO: config the theme as necessary
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 920,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    common: {
      white: '#ffffff',
    },
    text: {
      primary: '#333333',
      grey: '#b8b8b8',
    },
    primary: {
      light: '#4998EB',
      main: '#4066B3',
      subMain: '#3765B9',
      dark: '#043594',
      contrastText: '#ffffff',
    },
    info: {
      main: '#4066B3',
      contrastText: '#ffffff',
    },
    success: {
      main: '#01BFB3',
      contrastText: '#ffffff',
    },
    warning: {
      main: '#F9BC3E',
      contrastText: '#ffffff',
    },
    error: {
      main: '#EA635C',
      contrastText: '#ffffff',
    },
    background: {
      default: '#ffffff',
      grey: '#F1F1F1',
      greyDarker: '#d5d5d5'
    },
  },
  typography: {
    htmlFontSize: 10,
    fontFamily: [
      // 'Frutiger',
      'Open Sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    h1: {
      fontSize: '2.2rem',
      lineHeight: '2.5rem',
    },
    h2: {
      fontSize: '2rem',
      lineHeight: '2.5rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.8rem',
      lineHeight: '2.4rem',
    },
    h4: {
      fontSize: '1.4rem',
      lineHeight: '2.1rem',
    },
    body1: {
      fontSize: '1.6rem',
      lineHeight: '2.3rem',
    },
    body2: {
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
    },
    caption: {
      fontSize: '1.2rem',
      lineHeight: '1.5rem',
    },
  },
  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: 24,
        paddingRight: 24,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
        textTransform: 'none',
        // fontSize: '1.4rem',
        // lineHeight: `1.6rem`,
        // paddingTop: 18,
        // paddingBottom: 18,
        // paddingLeft: 24,
        // paddingRight: 24,
        // minWidth: 172,
      },
      contained: {
        borderRadius: 0,
        fontSize: '1.4rem',
        lineHeight: `1.6rem`,
        fontWeight: 'bold',
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 24,
        paddingRight: 24,
        minWidth: 120,
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
      },
      outlined: {
        fontSize: '1.4rem',
        lineHeight: `1.6rem`,
        paddingTop: 18,
        paddingBottom: 18,
        paddingLeft: 24,
        paddingRight: 24,
        fontWeight: 'bold',
      },
      outlinedPrimary: {
        '&:hover': {
          // backgroundColor: '#043594 !important',
          color: '#043594',
        },
      },
      containedSecondary: {
        backgroundColor: '#01BFB3',
        color: '#ffffff',
      },
      text: {
        fontWeight: 'bold',
        fontSize: '1.4rem',
        lineHeight: `1.8rem`,
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: '#ffffff',
      },
    },
    MuiLinearProgress: {
      root: {
        height: 5,
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 2,
      },
    },
    MuiOutlinedInput: {
      root: {
        'label + &': {
          marginTop: 8,
        },
        // maxWidth: 474,
        borderRadius: 8,
        '&$disabled': {
          backgroundColor: `#F2F2F2`,
        },
      },
      input: {
        fontSize: '1.4rem',
        lineHeight: '1.8rem',
        padding: '11px 9px 11px 14px',
        minHeight: 43,

        boxSizing: 'border-box',
        animation: 'none',
      },
    },
    MuiInputLabel: {
      root: {
        color: '#333333',
        fontWeight: 'normal',
        // fontSize: '1.4rem',
      },
      formControl: {
        transform: 'none',
        position: 'static',
      },
      shrink: {
        transform: 'none',
        fontWeight: '600',
        fontSize: '1.4rem',
      },
    },
    MuiTypography: {
      paragraph: {
        marginBottom: '1.4rem',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: 0,
        marginRight: 30,
      },
    },
    MuiRadio: {
      root: {
        marginRight: 8,
        padding: 0,
      },
    },
    MuiCheckbox: {
      root: {
        marginRight: 8,
        padding: 0,
      },
    },
    MuiExpansionPanel: {
      root: {
        minHeight: 55,
        '&$expanded': {
          minHeight: 55,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        // maxHeight: 55,
        paddingLeft: 40,
        paddingRight: 40,
        minHeight: 55,
        '@media (max-width:600px)': {
          paddingLeft: 20,
          paddingRight: 20,
        },
        '&$expanded': {
          // margin: 0,
          minHeight: 55,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: `14px 40px`,
        lineHeight: `2.1rem`,
      },
    },
    MuiAutocomplete: {
      inputRoot: {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        '&[class*="MuiOutlinedInput-root"]': {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
        },
        '&&[class*="MuiOutlinedInput-root"] $input:first-child': {
          padding: '11px 9px 11px 14px',
        },
      },
      input: {
        padding: '11px 9px 11px 14px',
      },
      paper: {
        fontSize: '1.4rem',
        '@media (max-height:568px) and (max-width: 320px)': {
          fontSize: '1.2rem',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '3px solid white',
      },
    },
  },
});

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Form,
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Grid, Typography, Collapse, Box } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import NoticeBox from 'components/NoticeBox';
import { makeSelectOption } from 'containers/App/selectors';
//components

//validate
import FormCheck from 'components/FormCheck';
import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import { isEmpty, isEqual } from 'lodash/lang';
import { some, forEach, filter, includes } from 'lodash/collection';
import { keys } from 'lodash/object';
import { convertIfImmutable, partyFilter } from 'helpers/lang';
import { newParty } from 'helpers/placeholder';
// eslint-disable-next-line import/no-duplicates
import { makeSelectFinancialInstituteOptions } from 'containers/App/selectors';
import BeneficiaryDetails from './BeneficiaryDetails';
import validate from './saciValidate';
import messages from '../../../messages';

const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  containerWrapper: {
    maxWidth: '660px',
    marginTop: theme.spacing(4),
  },
  beneficiariesContainer: {
    marginTop: '2rem',
  },
  error: {
    color: red[800],
    fontWeight: 600,
  },
  whoPribeneficiearyCheckbox: {
    marginBottom: '2rem',
  },
}));
function SaciBeneficiary({
  formValues,
  handleSubmit,
  initialValues,
  change,
  onSubmit,
  formErrors,
  options,
  canSubmit,
}) {
  const classes = useStyle();
  const parsedInitialValues = convertIfImmutable(initialValues);

  useEffect(() => {
    canSubmit(false);
  }, [formValues]);

  return (
    <CardSectionForm variant="outlined">
      <Grid
        container
        direction="row"
        alignItems="center"
        justify="space-between"
      >
        <Typography variant="body1">
          <FormattedMessage {...messages.assignBeneficiary} />
        </Typography>

        <Field name="otherPrimaryBeneficiaries" component={FormSwitch} />
      </Grid>
      <Collapse in={formValues.otherPrimaryBeneficiaries}>
        <hr className={classes.hr} />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Grid item xs={12} md={8} lg={6} className={classes.containerWrapper}>
            <CardSectionForm variant="outlined">
              <Typography
                variant="body1"
                style={{ fontWeight: 'bold', marginBottom: 10 }}
              >
                <FormattedMessage {...messages.willBeYourBeneficiary} />
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 20 }}>
                <FormattedMessage {...messages.willBeYourBeneficiary2} />
              </Typography>
              <FormSection name="">
                <FieldArray
                  name="primaryBeneficiaries"
                  component={BeneficiaryDetails}
                  isShowSharePercentage={false}
                  options={options}
                  formValues={formValues.primaryBeneficiaries}
                  addLabel={
                    <FormattedMessage {...messages.addPrimaryBeneficiary} />
                  }
                  fieldName="primaryBeneficiaries"
                  lifeInsuredParties={parsedInitialValues.lifeInsuredParties}
                  // disabledPercentage={true}
                  newPartyPlaceholder={{
                    ...newParty({
                      isPrimaryBeneficiary: true,
                      isBeneficiary: true,
                      isPolicyOwner: false,
                      isLifeInsured: false,
                    }),
                    selectedAddress: 'different',
                  }}
                  change={change}
                  formErrors={formErrors}
                />
              </FormSection>
            </CardSectionForm>
          </Grid>
        </Form>
      </Collapse>
    </CardSectionForm>
  );
}

SaciBeneficiary.propTypes = {
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  change: PropTypes.func,
  onSubmit: PropTypes.func,
  canSubmit: PropTypes.func,
  formErrors: PropTypes.object,
  options: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
  financialInstituteOptions: makeSelectFinancialInstituteOptions(),
});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  form: 'saciBeneficiary',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  validate,
});

const withFormSelector = connect(state => {
  const formValues = getFormValues('saciBeneficiary')(state);
  const formErrors = getFormSyncErrors('saciBeneficiary')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

export default compose(
  withConnect,
  withFormSelector,
  withForm,
)(SaciBeneficiary);

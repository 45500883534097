import { QUOTE_TYPE } from 'containers/App/constants';
import { formatMonetaryValue } from 'helpers/math';

const availableProductIFI = [
  'FUTU5',
  'FUTU2',
  'FUTU4',
  'SCFUT',
  'FUTU3',
  'CTFUT',
  'CTFU2',
  'CTFU3',
  'FUTUR',
  'WAPL1',
  'WAPL2',
  'SIMWE',
  'SIMW2'
];

export function filterIllustrations(illustrations, quoteType, productList) {
  if (quoteType === QUOTE_TYPE.NEW_BUSINESS) {
    return illustrations.filter(item => !item?.prospect?.quotes[0]?.isInforce);
  }

  return illustrations
    .filter(item => item?.prospect?.quotes[0]?.isInforce)
    .filter(item => availableProductIFI.includes(item.productCode));
}

function getApplicationByType(quoteType, listApplication, productList) {
  if (quoteType === QUOTE_TYPE.NEW_BUSINESS) {
    return listApplication.filter(item => {
      return item.isInforce === null || item.isInforce === false;
    });
  }

  return listApplication
    .filter(item => {
      return item.isInforce;
    })
    .filter(item => availableProductIFI.includes(item.productCode));
}

export function filterHubApplication(listApplication, quoteType, productList) {
  const arrActionable = [];
  const arrPending = [];
  const arrInprogress = [];
  const arrRejected = []; 
  const filteredApplication = getApplicationByType(
    quoteType,
    listApplication,
    productList,
  );

  filteredApplication.forEach(item => {
    if (item.mappedStatus === 'actionable') {
      arrActionable.push(item);
    } else if (item.mappedStatus === 'pending') {
      arrPending.push(item);
    } else if (item.mappedStatus === 'in progress') {
      arrInprogress.push(item);
    } else if (item.mappedStatus === 'rejected') { 
      arrRejected.push(item);
    }
  });

  return {
    arrActionable,
    arrPending,
    arrInprogress,
    arrRejected 
  };
}

export function filterSearchApplication(
  listApplication,
  quoteType,
  productList,
) {
  if (quoteType === QUOTE_TYPE.NEW_BUSINESS) {
    return listApplication.filter(item => !item.isInforce);
  }

  return listApplication
    .filter(item => item.isInforce)
    .filter(item => availableProductIFI.includes(item.productCode));
}

export const formatBenefitReview = (coverage, value, currency) => {
  if (!coverage.selected) {
    return null;
  }

  if (coverage.id === 'WOP') {
    return value ? 'Yes' : 'No';
  }

  if (coverage.id === 'ESC') {
    let escValue = `${value}%`;

    if (typeof value === 'boolean') {
      escValue = value ? '5%' : '0%';
    }

    return escValue;
  }

  if (!['WOP, ESC'].includes(coverage.id)) {
    return coverage.selected
      ? `${currency} ${formatMonetaryValue(value, false)}`
      : '-';
  }

  return null;
};

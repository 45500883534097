/**
 *
 * ParentDetailsForm
 *
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Collapse, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  formValueSelector,
} from 'redux-form/immutable';
import { InjectedFormProps, WrappedFieldArrayProps, change } from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { fromJS } from 'immutable';
import { FormattedMessage, useIntl } from 'react-intl';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import OptSwitch from 'components/OptSwitch';
import FormSelect from 'components/FormSelect';
import CardSectionForm from 'components/CardSectionForm';
import DependentPersonalDetails from '../DependentPersonalDetails';
import ParentsPersonalDetails from '../ParentsPersonalDetails';
import { dimension } from 'styles/constants';
import { changePlaceHolderColor, changeToTitleCase } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import { getStartDate } from '../../helper';
import messages from '../../messages';
import validate from './validate';

const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
   && {
     display: flex;
     flex-direction: column;
     width: 100%;
   }
 `;

const renderSwitch = ({
  input: { onChange, value },
  theme,
  disabled,
  nameType,
  isPolicyOwnerIndividual,
  isInsuredOnePolicyOwner,
  isInsuredTwo,
  isInsuredTwoPolicyOwner,
}) => {
  if (
    (isPolicyOwnerIndividual &&
      isInsuredOnePolicyOwner &&
      isInsuredTwo &&
      isInsuredTwoPolicyOwner) ||
    !isPolicyOwnerIndividual
  ) {
    if (nameType === 'isPolicyOwnerIndividual' && value) {
      value = false;
    }
  }
  return (
    <OptSwitch
      checked={value}
      value={value}
      color="default"
      onChange={onChange}
      labelNegative={<FormattedMessage {...messages.no} />}
      disabled={disabled}
    />
  );
};

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const lessThan = otherField => (value, previousValue, allValues) => {
  if (!value) return '';
  return value.length <= otherField ? value : previousValue;
};

const renderIndivisual = ({
  fields,
  options,
  insuredOne,
  disabled,
  isInsuredTwoPolicyOwner,
  policyOwnerIndividual,
}) => {
  const intl = useIntl();
  return (
    <div>
      {fields.map((individual, index) => (
        <Grid container key={individual}>
          <Grid container spacing={3}>
            <Grid item>
              <Field
                name={`${individual}.firstName`}
                disabled={disabled}
                style={{ width: 260 }}
                component={FormTextField}
                label={<FormattedMessage {...globalMessages.firstName} />}
                type="text"
                margin="normal"
                normalize={lessThan(45)}
                variant="standard"
                placeholder={intl.formatMessage(
                  globalMessages.fieldPlaceholder,
                )}
                format={(value) => changeToTitleCase(value)}
              />
            </Grid>
            <Grid item>
              <Field
                name={`${individual}.lastName`}
                disabled={disabled}
                style={{ width: 260 }}
                component={FormTextField}
                label={<FormattedMessage {...globalMessages.familyName} />}
                margin="normal"
                normalize={lessThan(40)}
                variant="standard"
                placeholder={intl.formatMessage(
                  globalMessages.fieldPlaceholder,
                )}
                format={(value) => changeToTitleCase(value)}
              />
            </Grid>
            <Grid item>
              <Field
                shrink
                disabled={disabled}
                name={`${individual}.relationshipToLifeInsured`}
                style={{
                  width: 355,
                  color: changePlaceHolderColor(
                    policyOwnerIndividual[index]?.relationshipToLifeInsured,
                  ),
                }}
                component={FormSelect}
                options={[
                  { value: 'Select an option', title: 'Select an option' },
                  ...options.applicationRelationship,
                ]}
                label={
                  <FormattedMessage {...messages.relationWithLifeInsured} />
                }
                type="text"
                margin="normal"
                variant="standard"
              />
            </Grid>

            <Grid item>
              <Grid container alignItems="center" justify="center">
                {fields.length !== 2 &&
                  !insuredOne.isPolicyOwner &&
                  !isInsuredTwoPolicyOwner ? (
                  <Button
                    disabled={disabled}
                    variant="text"
                    color="primary"
                    margin="normal"
                    style={{ marginTop: dimension.spacing.m }}
                    onClick={() =>
                      fields.push(
                        fromJS({
                          prospectId: '',
                          firstName: '',
                          lastName: '',
                          relationshipToLifeInsured: 'Select an option',
                          isPolicyOwner: true,
                          isLifeInsured: false,
                          isPrimaryLifeInsured: false,
                        }),
                      )
                    }
                  >
                    <FormattedMessage {...messages.addAnotherPolicyOwner} />
                  </Button>
                ) : null}

                <Field
                  name={`${individual}.prospectId`}
                  fullWidth
                  type="hidden"
                  style={{ visibility: 'hidden' }}
                  component={FormTextField}
                  color="primary"
                // margin="normal"
                // variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

renderIndivisual.propTypes = {
  ...WrappedFieldArrayProps,
  options: PropTypes.object,
  insuredOne: PropTypes.object,
  insuredTwo: PropTypes.object,
};

function ParentDetailsForm({
  options,
  gcConfig,
  insuredOne,
  insuredTwo,
  isInsuredTwo,
  isPolicyOwnerIndividual,
  isInsuredOnePolicyOwner,
  isInsuredTwoPolicyOwner,
  handleSubmit,
  onSubmit,
  disabled,
  resetPolicyOwnerIndividual,
  policyOwnerIndividual,
  change,
  isBoldInsuredPolicyOwner,
  location,
}) {
  const theme = useTheme();
  const classes = useStyle();

  const minDob = getStartDate()
    .toDate()
    .setFullYear(
      getStartDate()
        .toDate()
        .getFullYear() - 75,
    );
  const maxDob = getStartDate()
    .toDate()
    .setFullYear(
      getStartDate()
        .toDate()
        .getFullYear() - 18,
    );

  useEffect(() => {
    if (isBoldInsuredPolicyOwner) change('isPolicyOwnerIndividual', false);
  }, [isBoldInsuredPolicyOwner]);

  useEffect(() => {
    if (policyOwnerIndividual.length === 2) {
      change('insuredTwo.isPolicyOwner', false);
    }
  }, [policyOwnerIndividual.length]);

  return (
    <>
      {location?.state?.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={() => handleSubmit(onSubmit)}>
          <Wrapper theme={theme}>
            <FormSection name="insuredOne">
              <ParentsPersonalDetails
                disabled={disabled}
                options={options}
                formValues={insuredOne}
                minDate={minDob}
                maxDate={maxDob}
                disabledPolicyOwner={
                  (isPolicyOwnerIndividual && isInsuredTwoPolicyOwner) ||
                  (isPolicyOwnerIndividual &&
                    policyOwnerIndividual.length === 2)
                }
                isNationalityShowing
              />
            </FormSection>

            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body1">
                  <FormattedMessage {...messages.isSecondInsured} />
                </Typography>

                <Field
                  name="isInsuredTwo"
                  component={renderSwitch}
                  theme={theme}
                  margin="normal"
                  disabled={disabled}
                  nameType="isInsuredTwo"
                  isPolicyOwnerIndividual={isPolicyOwnerIndividual}
                  isInsuredOnePolicyOwner={isInsuredOnePolicyOwner}
                  isInsuredTwo={isInsuredTwo}
                  isInsuredTwoPolicyOwner={isInsuredTwoPolicyOwner}
                />
              </Grid>
              <Collapse in={isInsuredTwo}>
                <hr className={classes.hr} />
                <FormSection name="insuredTwo">
                  <DependentPersonalDetails
                    disabled={disabled}
                    options={options}
                    formValues={insuredTwo}
                    minDate={minDob}
                    maxDate={maxDob}
                    disabledPolicyOwner={
                      (isPolicyOwnerIndividual && isInsuredOnePolicyOwner) ||
                      (isPolicyOwnerIndividual &&
                        policyOwnerIndividual.length === 2)
                    }
                    isNationalityShowing
                  />
                </FormSection>
              </Collapse>
            </CardSectionForm>


          </Wrapper>
        </form>

      )}
    </>
  );
}

ParentDetailsForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  insuredOne: PropTypes.object,
  insuredTwo: PropTypes.object,
  isInsuredTwo: PropTypes.bool,
  isInsuredOnePolicyOwner: PropTypes.bool,
  isInsuredTwoPolicyOwner: PropTypes.bool,
  isPolicyOwnerIndividual: PropTypes.bool,
  resetInsuredPolicyOwner: PropTypes.func,
  policyOwnerIndividual: PropTypes.array,
  resetPolicyOwnerIndividual: PropTypes.func,
};

const withForm = reduxForm({
  validate,
  form: 'ParentDetailsForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ParentDetailsForm);

const selector = formValueSelector('ParentDetailsForm');

const mapStateToProps = state => {
  let isInsuredTwo = selector(state, 'isInsuredTwo');
  let insuredOne = selector(state, 'insuredOne');
  let insuredTwo = selector(state, 'insuredTwo');
  let isInsuredOnePolicyOwner = false;
  let isInsuredTwoPolicyOwner = false;
  if (insuredOne) {
    var insuredData = insuredOne.toJS();
    isInsuredOnePolicyOwner = insuredData.isPolicyOwner;
  }
  if (insuredTwo) {
    var insuredData = insuredTwo.toJS();
    isInsuredTwoPolicyOwner = insuredData.isPolicyOwner;
  }
  if (!isInsuredTwo) isInsuredTwoPolicyOwner = false;
  let isBoldInsuredPolicyOwner =
    isInsuredOnePolicyOwner && isInsuredTwoPolicyOwner;
  let policyOwnerIndividual = selector(state, 'policyOwnerIndividual');
  let isPolicyOwnerIndividual = selector(state, 'isPolicyOwnerIndividual');
  return {
    isInsuredTwo,
    //insuredTwo: insuredTwo?.toJS() || {},
    //insuredOne: insuredOne?.toJS() || {},
    isPolicyOwnerIndividual,
    isInsuredOnePolicyOwner,
    isInsuredTwoPolicyOwner,
    policyOwnerIndividual: policyOwnerIndividual?.toJS() || [],
    isBoldInsuredPolicyOwner,
  };
};

const mapDispatchToProps = dispatch => ({
  resetInsuredPolicyOwner: () =>
    dispatch(change('ParentDetailsForm', 'insuredTwo.isPolicyOwner', false)),
  resetPolicyOwnerIndividual: () =>
    dispatch(
      change(
        'ParentDetailsForm',
        'policyOwnerIndividual',
        fromJS([
          {
            prospectId: '',
            firstName: '',
            lastName: '',
            relationshipToLifeInsured: 'Select an option',
            isPolicyOwner: true,
            isLifeInsured: false,
            isPrimaryLifeInsured: false,
          },
        ]),
      ),
    ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withConnect;

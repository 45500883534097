/**
 *
 * QuoteForm
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Collapse,
  IconButton,
  Card,
  Divider,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
} from 'redux-form/immutable';
import { InjectedFormProps, FormSection } from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import {
  AddCircle,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import { get } from 'lodash/object';
import LoadingDialog from 'components/LoadingDialog';
import Benefits from './Benefits';
import messages from '../../messages';
import validate from './validate';
import BenefitsITA from './BenefitsITA';
import PremiumAmountField from './PremiumAmountField';

// const HeaderContent = styled(({ theme, ...props }) => <div {...props} />)`
//   && {
//     padding-left: ${props => props.theme.spacing(3.25)}px;
//     padding-top: ${props => props.theme.spacing(3.25)}px;
//     padding-bottom: ${props => props.theme.spacing(2)}px;
//     background-color: ${props => props.theme.palette.primary.dark};
//     color: ${props => props.theme.palette.common.white};
//   }
// `;

const BrContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(1.25)}px;
    padding-bottom: ${props => props.theme.spacing(1)}px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.common.white};
  }
`;

const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0)',
  },
}));

function QuoteForm({
  illustrationTableDict,
  gcConfig,
  productConfig,
  handleSubmit,
  onSubmit,
  formValues,
  location,
  coverages2,
  itaConfig,
  change,
  isBundlingQuote,
  lifeAssured,
  onUpdateValues,
  gcConfig2,
  initialValues,
  biTableValues,
}) {
  const theme = useTheme();
  const classes = useStyle();
  const [open, setOpen] = useState(false);
  const [openPremiumAmount, setOpenPremiumAmount] = useState(false);
  const [editSavingPremiumAmount, setEditSavingPremiumAmount] = useState(false);
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });
  const [isBrShowing, setBrShowing] = useState(false);
  let myGrowthRateNode = `${numeral(productConfig.growthRate / 100).value()}`;
  myGrowthRateNode = numeral(myGrowthRateNode).format('0.0000');
  myGrowthRateNode = parseFloat(myGrowthRateNode.toString());

  // let isCoveragesError = false;
  // if (!isEmpty(warningCoverages)) {
  //   warningCoverages.coverages.map(warningBenefit => {
  //     if (!isEmpty(warningBenefit)) {
  //       isCoveragesError = true;
  //     }
  //   });
  // }

  let aspFrequencyLabel = '';

  switch (productConfig?.paymentFrequency) {
    case 'Monthly':
      aspFrequencyLabel = 'per month';
      break;
    case 'Quarterly':
      aspFrequencyLabel = 'per quarter';
      break;
    case 'Half-Yearly':
      aspFrequencyLabel = 'per half-year';
      break;
    case 'Yearly':
      aspFrequencyLabel = 'per year';
      break;
    default:
      aspFrequencyLabel = 'per month';
      break;
  }
  const myBiTableValues = get(
    biTableValues,
    `illustrationTableDict[${myGrowthRateNode}]`,
    get(
      initialValues.toJS(),
      `biTableValues.illustrationTableDict[${myGrowthRateNode}]`,
      {},
    ),
  );

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-20px' }}>
          <Card
            style={{
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 0,
            }}
          >
            {/* <HeaderContent theme={theme}> */}
            <Grid
              container
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: `${theme.spacing(2)}px`,
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.premiumPaid} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: `${theme.spacing(2)}px`,
                  borderRight: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.policyTerm} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  padding: `${theme.spacing(2)}px`,
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Typography
                  variant="h4"
                  margin="normal"
                  align="right"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.fundValues} />
                </Typography>
              </Grid>
            </Grid>
            {/* </HeaderContent> */}

            {!isEmpty(myBiTableValues) ? (
              <Benefits
                formValues={myBiTableValues['5.0']}
                gcConfig={gcConfig}
                years={5}
              />
            ) : null}

            {!isEmpty(myBiTableValues) ? (
              <Benefits
                formValues={myBiTableValues['10.0']}
                gcConfig={gcConfig}
                years={10}
              />
            ) : null}

            {!isEmpty(myBiTableValues) ? (
              <Benefits
                formValues={myBiTableValues['15.0']}
                gcConfig={gcConfig}
                years={15}
              />
            ) : null}

            {!isEmpty(myBiTableValues) ? (
              <Benefits
                formValues={myBiTableValues['20.0']}
                gcConfig={gcConfig}
                years={20}
              />
            ) : null}

            {!isEmpty(myBiTableValues) ? (
              <Benefits
                formValues={myBiTableValues['25.0']}
                gcConfig={gcConfig}
                years={25}
              />
            ) : null}

            {!isEmpty(myBiTableValues) ? (
              <Benefits
                formValues={myBiTableValues['30.0']}
                gcConfig={gcConfig}
                years={30}
              />
            ) : null}

            <hr className={classes.hr} />

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{ paddingRight: theme.spacing(2) }}
            >
              <Grid item>
                <Typography
                  component="span"
                  variant="h1"
                  style={{
                    color: theme.palette.common.white,
                    // fontWeight: '600',
                  }}
                >
                  {productConfig.currency}
                </Typography>
              </Grid>
              <Grid item>
                <span
                  id="primaryPremiumAmount"
                  style={{
                    color: theme.palette.common.white,
                    fontSize: '6.6rem',
                    lineHeight: 1,
                    fontWeight: 700,
                  }}
                >
                  {!isEmpty(myBiTableValues)
                    ? formatMonetaryValue(
                        Math.round(myBiTableValues['30.0']['Fund Value']),
                        false,
                      )
                    : 0}
                </span>
              </Grid>
            </Grid>

            <Divider
              style={{
                backgroundColor: theme.palette.info.main,
                margin: theme.spacing(2),
              }}
            />

            <Grid
              container
              spacing={1}
              justify="space-between"
              style={{
                paddingRight: theme.spacing(2),
                paddingBottom: theme.spacing(3),
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: '#B9BBB8',
                  marginLeft: theme.spacing(2),
                  // fontWeight: 700,
                }}
              >
                {isBundlingQuote &&
                  `${productConfig?.currency} ${formatMonetaryValue(
                    parseFloat(gcConfig2?.premium),
                  )} ${aspFrequencyLabel}`}
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: '#B9BBB8',
                  // fontWeight: 700,
                }}
              >
                <FormattedMessage {...messages.projectedFundValues} />
              </Typography>
            </Grid>

            {/* =========== BR CONTENT ============ */}
            {productConfig?.specialTermsFlag ? (
              <BrContent container theme={theme}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={1}>
                    <AddCircle style={{ color: theme.palette.common.white }} />
                  </Grid>

                  <Grid item xs={10}>
                    <Typography
                      color="inherit"
                      // variant="h3"
                      component="span"
                      style={{ fontWeight: '900' }}
                    >
                      <FormattedMessage {...messages.brDebits} />
                    </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton onClick={() => setBrShowing(!isBrShowing)}>
                      {isBrShowing ? (
                        <KeyboardArrowUp
                          style={{ color: theme.palette.primary.light }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          style={{ color: theme.palette.primary.light }}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>

                {isBrShowing ? (
                  <Divider
                    style={{
                      height: '2px',
                      color: theme.palette.primary.dark,
                    }}
                  />
                ) : null}

                <Collapse in={isBrShowing}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    style={{ padding: theme.spacing(1) }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="span"
                        style={{ fontWeight: 'bold' }}
                      >
                        {lifeAssured.insuredOne.firstName}
                      </Typography>
                    </Grid>
                    {lifeAssured.isInsuredTwo ? (
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                          component="span"
                        >
                          {lifeAssured.insuredTwo.firstName}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>

                  {coverages2.map((benefit, index) => {
                    const isShowingData =
                      benefit.brSelectedInsuredOneValue ||
                      benefit.brSelectedInsuredTwoValue ||
                      benefit.brSelectedPercentValueOne ||
                      benefit.brSelectedPercentValueTwo ||
                      benefit.brSelectedTempValueOne ||
                      benefit.brSelectedTempValueTwo;

                    return isShowingData ? (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        style={{ padding: theme.spacing(1) }}
                        key={index}
                      >
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            component="span"
                            style={{
                              fontWeight: 'bold',
                              marginTop: theme.spacing(1),
                            }}
                          >
                            {benefit.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} />

                        {(benefit.brSelectedTempValueOne ||
                          benefit.brSelectedTempValueTwo) && (
                          <>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  color: theme.palette.grey[400],
                                  lineHeight: 3,
                                }}
                              >
                                <FormattedMessage {...messages.temporary} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedTempValueOne
                                  ? `+${benefit.perTempMileOne.toFixed(
                                      2,
                                    )} per mille`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedTempValueTwo
                                    ? `+${benefit.perTempMileTwo.toFixed(
                                        2,
                                      )} per mille`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}

                        {(benefit.brSelectedTempValueOne ||
                          benefit.brSelectedTempValueTwo) && (
                          <>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                <FormattedMessage {...messages.period} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedTempValueOne
                                  ? `+${benefit.perTempMonthOne} months`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedTempValueTwo
                                    ? `+${benefit.perTempMonthTwo} months`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}

                        {(benefit.brSelectedInsuredOneValue ||
                          benefit.brSelectedInsuredTwoValue ||
                          benefit.brSelectedPercentValueOne ||
                          benefit.brSelectedPercentValueTwo) && (
                          <>
                            <Grid
                              item
                              xs={6}
                              style={{ marginTop: theme.spacing(1) }}
                            >
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  color: theme.palette.grey[400],
                                  lineHeight: 3,
                                }}
                              >
                                <FormattedMessage {...messages.permanent} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedInsuredOneValue
                                  ? `+${benefit.perMileOne.toFixed(
                                      2,
                                    )} per mille`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedInsuredTwoValue
                                    ? `+${benefit.perMileTwo.toFixed(
                                        2,
                                      )} per mille`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}

                            <Grid item xs={6} />
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedPercentValueOne
                                  ? `+${benefit.perPercentageOne}%`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedPercentValueTwo
                                    ? `+${benefit.perPercentageTwo}%`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}
                      </Grid>
                    ) : null;
                  })}
                </Collapse>
              </BrContent>
            ) : null}
          </Card>
          <Card>
            <FormSection name="productConfig">
              <PremiumAmountField
                component={PremiumAmountField}
                inactive={openPremiumAmount}
                itaConfig={itaConfig}
                currency={productConfig.currency}
                editSavingPremiumAmount={editSavingPremiumAmount}
                setEditSavingPremiumAmount={setEditSavingPremiumAmount}
                isInsuredTwo={lifeAssured.isInsuredTwo}
                calculateEditData={val => onUpdateValues(formValues, false)}
                formValues={formValues}
              />
            </FormSection>

            {coverages2.length > 0 && isBundlingQuote ? (
              <FormSection name="productConfig">
                <FieldArray
                  name="coverages2"
                  component={BenefitsITA}
                  inactive={open}
                  coverages={coverages2}
                  itaConfig={itaConfig}
                  currency={productConfig.currency}
                  change={change}
                  editData={editData}
                  isCoveragesError={false}
                  calculateEditData={val => {
                    setEditData(val);
                    return onUpdateValues(formValues, false);
                  }}
                  changeEditData={val => {
                    setEditData(val);
                  }}
                  isInsuredTwo={lifeAssured.isInsuredTwo}
                />
              </FormSection>
            ) : null}
          </Card>
        </form>
      )}
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  gcConfig: PropTypes.object,
  productConfig: PropTypes.object,
  illustrationTableDict: PropTypes.object,
  annexureDict: PropTypes.object,
  annualIrr: PropTypes.object,
  additionalLifeCover: PropTypes.number,
  coverages2: PropTypes.array,
  itaConfig: PropTypes.object,
  change: PropTypes.func,
  lifeAssured: PropTypes.object,
  onUpdateValues: PropTypes.func,
  isBundlingQuote: PropTypes.bool,
  gcConfig2: PropTypes.object,
  biTableValues: PropTypes.object,
};

const withForm = reduxForm({
  form: 'quoteForm',
  warn: validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');

const withConnect = connect(state => {
  const biTableValues = selector(state, 'biTableValues');
  const productConfig = selector(state, 'productConfig');
  const illustrationTableDict = selector(state, 'illustrationTableDict');
  const annexureDict = selector(state, 'annexureDict');
  const annualIrr = selector(state, 'annualIrr');
  const additionalLifeCover = selector(state, 'additionalLifeCover');
  const formValues = getFormValues('quoteForm')(state);
  return {
    illustrationTableDict: biTableValues?.toJS().illustrationTableDict || {},
    annexureDict: annexureDict?.toJS() || {},
    annualIrr: annualIrr?.toJS() || {},
    additionalLifeCover,
    formValues: formValues?.toJS() || {},
    coverages2: productConfig?.toJS().coverages2 || [],
  };
})(withForm);

export default withConnect;

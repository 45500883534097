import React from 'react';
import numeral from 'numeral';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { get, keys } from 'lodash/object';
import { filter, map, includes } from 'lodash/collection';
// import { startCase } from 'lodash/string';
import { startCase } from 'helpers/lang';
import { isEmpty } from 'lodash/lang';
import { isBoolean } from 'lodash/lang';

import InfoRow from 'components/InfoRow';

import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '660px',
  },
  link: {
    color: '#00C3B4',
  },
  disabled: {
    color: '#B6B8B4',
  },
  infoSmallContainer: {
    backgroundColor: '#fafafa',
    padding: '1rem',
    marginTop: '2px',
    paddingLeft: '7.5rem',
  },
  infoSmallContainerInner: {
    backgroundColor: '#fafafa',
    padding: '1rem',
    marginTop: '2px',
    paddingLeft: '7.5rem',
  },
  gray: { color: '#9B9B9B' },
  infoSmall: {
    width: '100%',
  },
  marginRight: {
    marginRight: '2.5rem',
  },
}));

export default function Section(props) {
  const classes = useStyles();
  const { sectionName, title, currency, value, frequency, coverages } = props;

  function getCoverageDisplayValue(value, currency = '') {
    // if boolean, return yes/no
    if (isBoolean(value)) {
      return value ? 'Yes' : 'No';
    }
    // if everything else that is falsy, return -
    if (!value) {
      return '-';
    }
    // return formatted number otherwise + currency

    return `${currency} ${numeral(value).format('0,0')}`;
  }

  const coverageList = filter(keys(coverages[0]), k => {
    return k !== 'isPrimary';
  });
  const coverageList2 = filter(keys(coverages[1]), k => {
    return k !== 'isPrimary';
  });

  const InnerSectionExclusion = ({ name }) => {
    return (
      <div className={classes.infoSmallContainer}>
        <div className={classes.infoSmall}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.gray}>
                {startCase(name) === 'PerPercentage'
                  ? 'Percentage'
                  : startCase(name)}
              </Typography>
            </Grid>
            {/* <Grid item xs={3} align="right">
              <div className={classes.marginRight}>
                <Typography variant="body2" color="inherit">
                  {!isEmpty(singleCoverage) ? singleCoverage[name] : '---'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3} align="right">
              <div className={classes.marginRight}>
                <Typography variant="body2" color="inherit">
                  {!isEmpty(singleCoverage2) ? singleCoverage2[name] : '---'}
                </Typography>
              </div>
            </Grid> */}
          </Grid>
        </div>
      </div>
    );
  };

  const InnerSection = ({
    name,
    // permanentList,
    singleCoverage,
    singleCoverage2,
  }) => {
    return (
      <div className={classes.infoSmallContainer}>
        <div className={classes.infoSmall}>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <Typography variant="body2" className={classes.gray}>
                {startCase(name) === 'Per Percentage'
                  ? 'Percentage'
                  : startCase(name)}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.marginRight}>
                <Typography variant="body2" color="inherit">
                  {!isEmpty(singleCoverage) ? singleCoverage[name] : '---'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.marginRight}>
                <Typography variant="body2" color="inherit">
                  {!isEmpty(singleCoverage2) ? singleCoverage2[name] : '---'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  const Exclusion = ({ exclusion, exclusion2 }) => {
    return (
      <div className={classes.infoSmallContainerInner}>
        <div className={classes.infoSmall}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" color="inherit" className={classes.gray}>
                Exclusion
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.marginRight}>
                <Typography
                  variant="h4"
                  color="inherit"
                  className={classes.gray}
                >
                  {exclusion?.length > 0 ? 'Y' : '---'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.marginRight}>
                <Typography
                  variant="h4"
                  color="inherit"
                  className={classes.gray}
                >
                  {exclusion2?.length > 0 ? 'Y' : '---'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" color="primary">
          {sectionName}
        </Typography>
      </Grid>
      {map(
        filter(coverageList, cv => {
          return coverages[0][cv].selected;
        }),
        c => {
          const singleCoverage = coverages[0] ? coverages[0][c] : {};
          const singleCoverage2 = coverages[1] ? coverages[1][c] : {};
          // const permanentList = keys(singleCoverage.permanent);
          // const permanentLis2 = keys(singleCoverage2.permanent);
          const temporaryList = keys(singleCoverage.temporary);
          const temporaryList2 = keys(singleCoverage2.temporary);

          const exclusion = singleCoverage.exclusion;
          const exclusion2 = singleCoverage2.exclusion;
          return (
            <>
              <Grid item xs={12}>
                <InfoRow>
                  <Grid container justify="center" spacing={2}>
                    <Grid item xs={1}>
                      <InfoIcon color="primary" />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="body2" color="inherit">
                        {singleCoverage.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} align="left">
                      <div>
                        <Typography variant="h4" color="inherit">
                          {getCoverageDisplayValue(
                            singleCoverage.value,
                            currency,
                          )}
                        </Typography>
                      </div>
                    </Grid>
                    {!isEmpty(singleCoverage2) ? (
                      <Grid item xs={3} align="left">
                        <div>
                          <Typography variant="h4" color="inherit">
                            {getCoverageDisplayValue(
                              singleCoverage2.value,
                              currency,
                            )}
                            {/* {`${currency} ${value} / ${frequency}`} */}
                          </Typography>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={3} />
                    )}
                  </Grid>
                </InfoRow>

                {map(
                  filter(keys(singleCoverage), singleCoverageKey => {
                    const exludedKeys = ['name', 'value', 'selected'];
                    return !includes(exludedKeys, singleCoverageKey);
                  }),

                  filteredCoverage => {
                    if (filteredCoverage === 'exclusion') {
                      map(singleCoverage['exclusion'], ex => {
                        return (
                          <InnerSectionExclusion
                            name={ex}
                            // permanentList={permanentList}
                            // singleCoverage={singleCoverage}
                            // singleCoverage2={singleCoverage2}
                          />
                        );
                      });
                    } else {
                      return (
                        <InnerSection
                          name={filteredCoverage}
                          // permanentList={permanentList}
                          singleCoverage={singleCoverage}
                          singleCoverage2={singleCoverage2}
                        />
                      );
                    }
                  },
                )}

                {exclusion?.length > 0 || exclusion2?.length > 0 ? (
                  <Exclusion exclusion={exclusion} exclusion2={exclusion2} />
                ) : (
                  ''
                )}
                {/* {singleCoverage.permanent && (
                  <InnerSection
                    name={'permanent'}
                    permanentList={permanentList}
                    singleCoverage={singleCoverage}
                    singleCoverage2={singleCoverage2}
                  />
                )}
                {singleCoverage.temporary && (
                  <InnerSection
                    name={'temporary'}
                    permanentList={temporaryList}
                    singleCoverage={singleCoverage}
                    singleCoverage2={singleCoverage2}
                  />
                )} */}
              </Grid>
            </>
          );
        },
      )}
    </>
  );
}

/**
 *
 * MiniDrawer
 *
 */

import React from 'react';
import {
  Grid,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  Collapse,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import { forEach } from 'lodash/collection';

import { allSubOrderList } from 'helpers/list';
import { useLocation, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { changeSubStepPageAction } from '../actions';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { ADJUSTED_POLICY_SUBSTEPPERS } from 'containers/ApplicationFormPage/constants';

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: row;
    width: 152px;
    height: 100%;
    background-color: ${props => props.theme.palette.primary.main};
  }
`;

const SubMenu = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;
    height: 100%;
    background-color: ${props => props.theme.palette.primary.dark};
  }
`;

const Circle = styled(({ theme, selected, completed, ...props }) => (
  <div {...props} />
))`
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background-color: ${props => {
      if (props.completed) {
        return props.theme.palette.success.main;
      } else if (props.selected) {
        return props.theme.palette.common.white;
      }
      return props.theme.palette.primary.light;
    }};
    color: ${props => {
      if (props.selected) {
        return props.theme.palette.primary.main;
      }
      return props.theme.palette.common.white;
    }};
    border-radius: 50%;
    font-size: 1rem;
    font-weight: 700;
  }
`;

const useStyles = makeStyles(theme => ({
  stepper: { backgroundColor: 'transparent' },
  // step: { color: 'white' },
  stepLabel: {
    color: 'white',
    fontSize: '1rem',
    cursor: 'pointer',
  },
  stepIcon: { color: 'white', fontSize: '1rem' },
  //connectorStyling
  lineConnector: {
    minHeight: '.5rem',
  },
  verticalConnector: {
    padding: '0',
    marginLeft: '4.5px',
  },
  subMenu: {
    paddingTop: 90,
  },
}));
function MiniDrawer({
  subPage,
  subPageList,
  steps,
  subPageStepper,
  setSubmenu,
  changeSubStepPage,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const pathParsed = location.pathname.split('form')[1];

  const stepIcon = props => {
    const { isActive } = props;
    return isActive ? (
      <FiberManualRecordIcon className={classes.stepIcon} />
    ) : (
      <RadioButtonUncheckedIcon className={classes.stepIcon} />
    );
  };

  const mapSessionId = order => {
    const sessionId = subPageList[1].subOrder.filter(item => {
      return Number(item.order) === Number(order);
    });
    return sessionId[0];
  };

  const clickStep = (order, text) => {
    if (text === 'Policy Details') {
      switch (pathParsed) {
        case '/policy-owner':
          if (order === 1) {
            setSubmenu(order);
            history.push('policy-owner');
          }
          break;
        case '/beneficiaries':
          if (order === 1) {
            history.push('policy-owner');
            setSubmenu(order);
          } else if (order === 2) {
            history.push('beneficiaries');
            setSubmenu(order);
          }
          break;
        case '/payor':
          if (order === 1) {
            history.push('policy-owner');
            setSubmenu(order);
          } else if (order === 2) {
            history.push('beneficiaries');
            setSubmenu(order);
          } else if (order === 3) {
            history.push('payor');
            setSubmenu(order);
          }
          break;
        case '/payor-salary':
          if (order === 1) {
            history.push('policy-owner');
            setSubmenu(order);
          } else if (order === 2) {
            history.push('beneficiaries');
            setSubmenu(order);
          } else if (order === 3) {
            history.push('payor');
            setSubmenu(order);
          }
          break;
        case '/investment':
          if (order === 1) {
            history.push('policy-owner');
            setSubmenu(order);
          } else if (order === 2) {
            history.push('beneficiaries');
            setSubmenu(order);
          } else if (order === 3) {
            history.push('payor');
            setSubmenu(order);
          } else if (order === 5) {
            history.push('investment');
            setSubmenu(order);
          }
          break;
      }
    } else if (text === 'Payment') {
      if (subPageStepper <= order) {
        return;
      } else {
        if (pathParsed === '/payment-preview') {
          history.push('payment-method');
        }
      }
    } else if (text === 'Underwriting') {
      if (subPageStepper <= order) {
        return;
      } else {
        if (mapSessionId(order).text === 'Submit') {
          history.push('uw-submit');
        } else {
          changeSubStepPage(mapSessionId(order).order);
          history.push({
            pathname: 'uw-section',
            state: { sessionId: mapSessionId(order).order },
          });
        }
      }
    }
  };

  return (
    <Wrapper theme={theme}>
      <SubMenu theme={theme}>
        <Grid container spacing={2} className={classes.subMenu}>
          {subPageList.map((listItem, index) => {
            const selected = listItem.order === subPage;
            const completed = listItem.order < subPage;
            var allIsHidden = true;
            forEach(listItem.subOrder, item => {
              if (item.isHidden === false) {
                allIsHidden = false;
              }
            });
            return (
              <Box px={1} mb={2} key={`${listItem.order}-${index}`}>
                {/* ########## START  :: MAIN SUB PAGE ########## */}
                <Grid container alignItems="center" spacing={1} wrap="nowrap">
                  <Grid item>
                    <Circle
                      theme={theme}
                      selected={selected}
                      completed={completed}
                    >
                      {listItem.order}
                    </Circle>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      style={{
                        color: theme.palette.common.white,
                        fontWeight: selected ? '600' : '400',
                      }}
                    >
                      {listItem.text}
                    </Typography>
                  </Grid>
                </Grid>
                {/* ########## START  :: MAIN SUB PAGE ########## */}
                {!allIsHidden && allSubOrderList(subPageList).length > 0 ? (
                  <Collapse
                    in={listItem.order === subPage}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Grid item xs={12}>
                      <Stepper
                        className={classes.stepper}
                        activeStep={0}
                        orientation="vertical"
                        connector={
                          <StepConnector
                            classes={{
                              line: classes.lineConnector,
                              vertical: classes.verticalConnector,
                            }}
                          />
                        }
                      >
                        {listItem.subOrder.map((item, index) => {
                          if (!item.isHidden) {
                            return (
                              <Step
                                key={item.text}
                                className={classes.step}
                                onClick={() =>
                                  clickStep(item.order, listItem.text)
                                }
                              >
                                <StepLabel
                                  StepIconComponent={stepIcon}
                                  StepIconProps={{
                                    isActive: item.order <= subPageStepper,
                                  }}
                                >
                                  <Typography
                                    className={classes.stepLabel}
                                    variant="body2"
                                  >
                                    {item.text}
                                  </Typography>
                                </StepLabel>
                              </Step>
                            );
                          }
                        })}
                      </Stepper>
                    </Grid>
                  </Collapse>
                ) : (
                  ''
                )}
              </Box>
            );
          })}
        </Grid>
      </SubMenu>
    </Wrapper>
  );
}

MiniDrawer.propTypes = {
  subPage: PropTypes.number,
  subPageList: PropTypes.array,
  subPageStepper: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    setSubmenu: value => dispatch(changeSubStepPageAction(value)),
    changeSubStepPage: value => dispatch(changeSubStepPageAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MiniDrawer);

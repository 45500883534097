import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import { dobFormat } from 'helpers/constants';

/** HELPER */
export function getCoverages(configs) {
  const coverages = [];
  configs.productBenefits.mandatoryList.forEach(benefit => {
    if (!benefit.isComplimentary)
      coverages.push({
        type: '1',
        selected: false,
        disabled: false,
        id: benefit.benefitCode,
        title: benefit.benefitName,
        valueInsuredOne: '',
        perMileOne: 0,
        perPercentageOne: 0,
        valueInsuredTwo: '',
        perMileTwo: 0,
        perPercentageTwo: 0,
        maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
        perTempMileOne: 0,
        perTempMileTwo: 0,
        perTempMonthTwo: 0,
        perTempMonthOne: 0,
        brSelectedTempValueOne: false,
        brSelectedTempValueTwo: false,
        brSelectedPercentValueOne: false,
        brSelectedPercentValueTwo: false,
        brSelectedInsuredTwoValue: false,
        brSelectedInsuredOneValue: false,
      });
  });

  return coverages;
}

export function calculateAgeNextBirthdate(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  let monthDayPoint = 0;
  if (policyStartDateMonth === bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}

export function getMinValue(productId, dtaConfig, currency) {
  const indexOfCurrency = dtaConfig.benefitCurrencyMap.indexOf(currency);
  let minValue = 0;

  const productBenefits = [
    ...dtaConfig.productBenefits.optionalList,
    ...dtaConfig.productBenefits.mandatoryList,
  ];

  productBenefits.map(benefit => {
    if (!benefit.isComplimentary) {
      if (benefit.benefitCode === productId)
        minValue = benefit.minBenefitAmount[indexOfCurrency];
    }
  });
  return minValue;
}

export function getMaxValue(productId, dtaConfig, currency) {
  const indexOfCurrency = dtaConfig.benefitCurrencyMap.indexOf(currency);
  let maxValue = 0;

  const productBenefits = [
    ...dtaConfig.productBenefits.optionalList,
    ...dtaConfig.productBenefits.mandatoryList,
  ];

  productBenefits.map(benefit => {
    if (!benefit.isComplimentary) {
      if (benefit.benefitCode === productId)
        maxValue = benefit.maxBenefitAmount[indexOfCurrency];
    }
  });
  return maxValue;
}

export function isValidEmail(email) {
  if (email && isEmail(email)) {
    return true;
  }
  return false;
}

export function getStartDate() {
  let month = new Date().getMonth() + 1;
  if (new Date().getDate() <= 15) month -= 1;

  return moment()
    .month(month)
    .date(1);
}

export function generateStartDateList() {
  const startDateList = [];
  startDateList.push({
    title: getStartDate()
      .subtract(2, 'months')
      .date(1)
      .format('DD/MM/YYYY'),
    value: getStartDate()
      .subtract(2, 'months')
      .date(1)
      .format('YYYY-MM-DD'),
  });
  startDateList.push({
    title: getStartDate()
      .subtract(1, 'months')
      .date(1)
      .format('DD/MM/YYYY'),
    value: getStartDate()
      .subtract(1, 'months')
      .date(1)
      .format('YYYY-MM-DD'),
  });
  startDateList.push({
    title: getStartDate().format('DD/MM/YYYY'),
    value: getStartDate().format('YYYY-MM-DD'),
  });
  startDateList.push({
    title: getStartDate()
      .add(1, 'months')
      .date(1)
      .format('DD/MM/YYYY'),
    value: getStartDate()
      .add(1, 'months')
      .date(1)
      .format('YYYY-MM-DD'),
  });
  startDateList.push({
    title: getStartDate()
      .add(2, 'months')
      .date(1)
      .format('DD/MM/YYYY'),
    value: getStartDate()
      .add(2, 'months')
      .date(1)
      .format('YYYY-MM-DD'),
  });

  return startDateList;
}

export const generateTermList = (configsDetail, startDate, lifeAssured) => {
  const policyTermList = [];
  const anbInsuredOne = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredOne.dateOfBirth,
  );
  const anbInsuredTwo = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredTwo.dateOfBirth,
  );

  let minPremiumValue = configsDetail.features?.policyTerm.minTerm;
  let maxPremiumValue = configsDetail.features?.policyTerm.maxTerm;
  const expiryAgeValue = configsDetail.features?.policyTerm.expiryAge;

  const considerAge =
    anbInsuredOne < anbInsuredTwo ? anbInsuredTwo : anbInsuredOne;
  const calculateMaxTerm = expiryAgeValue - considerAge;
  maxPremiumValue =
    calculateMaxTerm > maxPremiumValue ? maxPremiumValue : calculateMaxTerm;

  while (minPremiumValue <= maxPremiumValue) {
    const myObj = { title: `${minPremiumValue}`, value: minPremiumValue };
    policyTermList.push(myObj);
    minPremiumValue += 1;
  }

  return policyTermList;
};

import { fromJS } from 'immutable';

import {
  SET_ZIO_PIN_SUCCESS,
  SET_ZIO_PIN_FAILURE,
  SET_IS_ZIO_PIN_VERIFIED,
  SET_ZIO_PIN_VERIFY_TIMESTAMP,
} from './constants';

const defaultState = {
  zioPinData: {},
  zioPinError: {},
  isZioPinVerified: false,
  zioPinVerifyTimeStamp: null,
  zioPinData1: {
    error: {},
    data: {},
    isZioPinVerified: false,
    zioPinVerifyTimeStamp: null,
  },
};

export const initialState = fromJS({ ...defaultState });

function MFOauthPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ZIO_PIN_SUCCESS:
      return state.set('zioPinData', fromJS(action.payload));
    case SET_ZIO_PIN_FAILURE:
      return state.set('zioPinError', fromJS(action.payload));
    case SET_IS_ZIO_PIN_VERIFIED:
      return state.set('isZioPinVerified', action.payload);
    case SET_ZIO_PIN_VERIFY_TIMESTAMP:
      return state.set('zioPinVerifyTimeStamp', action.payload);
    default:
      return state;
  }
}

export default MFOauthPageReducer;

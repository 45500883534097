import {
  Box,
  Button,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import CardSectionForm from 'components/CardSectionForm';
import Dialog from 'components/Dialog';
import FormDatePicker from 'components/FormDatePicker';
import FormNumberField from 'components/FormNumberField';
import FormRadioGroup from 'components/FormRadioGroup';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
// components
import FormTextField from 'components/FormTextField';
import Helper from 'components/Helper';
import CustomRadio from 'components/CustomRadioButton';
import OptSwitch from 'components/OptSwitch';
import globalMessages from 'containers/App/messages';
// selectors
import { makeSelectOption } from 'containers/App/selectors';
import { filterCountryOption } from 'containers/ApplicationFormPage/helpers';
import messages from 'containers/ApplicationFormPage/messages';
import { convertIfImmutable, changePlaceHolderColor } from 'helpers/lang';
import { newTaxPurposes } from 'helpers/placeholder';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { Field } from 'redux-form/immutable';
import { FieldArray, FormSection } from 'redux-form/lib/immutable';
import { createStructuredSelector } from 'reselect';
import red from '@material-ui/core/colors/red';
import { isDisabledField, productCodeCheck } from 'helpers/constants';
import TaxPuposes from './TaxPuposes';
import TaxResidency from './TaxResidency';
import { isEqual } from 'lodash/lang';
import { useEffect } from 'react';

const useStyle = makeStyles(theme => ({
  gridSubContainer: {
    width: '100%',
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(6),
  },
  titleName: {
    // color: blue[800],
    color: theme.palette.primary.subMain,
  },
  radioForm: {
    display: 'flex',
    alignItems: 'center',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  containerWrapper: {
    width: '660px',
    margin: 'auto',
  },
  btnDefault: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  helperText: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: '35px',
  },
  gridSubContainerEmail: {
    paddingRight: theme.spacing(20),
  },
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 30,
    height: 30,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#137cbd',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 30,
      height: 30,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  error: {
    color: red[800],
    fontWeight: 600,
    marginBottom: 20,
  },
  hrOptional: {
    paddingTop: '26px!important',
    paddingBottom: '35px!important',
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => {
  const intl = useIntl();
  return (
    <OptSwitch
      checked={value}
      value={value}
      color="default"
      onChange={onChange}
      labelNegative={intl.formatMessage(messages.no)}
      disabled={disabled}
    />
  );
};

function Owner(props) {
  const classes = useStyle();
  const theme = useTheme();
  const intl = useIntl();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  const showPopupWarning = e => {
    if (e.target.defaultValue === 'true') {
      setOpen(true);
    }
  };
  const [differentAddress, setDifferentAddress] = useState(false);
  const [countOnDiffAddress, setCountOnDiffAddress] = useState(0);
  const {
    isUAEAgency,
    isShowTaxResidence,
    options,
    fields,
    parties,
    change,
    formValues,
    formErrors,
    isInforce,
    disabledFields,
  } = props;
  const { productCode } = formValues;
  let optionList = isInforce
    ? options?.countryCode
    : options?.addressGccCountry;
if(productCodeCheck(8, productCode)){
  optionList = [{ value: 'AE', title: 'United Arab Emirates' }]
}
useEffect(() => {
 //Changes for Defect IIU-214 Starts
 if(isInforce && parties.length > 1 && countOnDiffAddress == 0) {
  if(!isEqual(parties[0].addressLine1, parties[1].addressLine1) || 
     !isEqual(parties[0].addressLine2 ,parties[1].addressLine2) || 
     !isEqual(parties[0].addressLine3 , parties[1].addressLine3) || 
     !isEqual(parties[0].city, parties[1].city) || 
     !isEqual(parties[0].country, parties[1].country)) {
      // change(`${field}.isDifferentAddress`, true);
      setCountOnDiffAddress(1);
      setDifferentAddress(true);
  }
}
//Changes for Defect IIU-214 Ends
}, []);

  return fields.map((field, index) => {
    const parsedField = convertIfImmutable(fields.get(index));
    const {
      partyapplicationrelationships: { isLifeInsured },
    } = parsedField;

    //  if (isInforce && isDisabledField(disabledFields,'po','addressLine1')) {
    //    change(`${field}.isDifferentAddress`, true);
    //  }
    if(differentAddress) {
      change(`${field}.isDifferentAddress`, true);
    }
    
    // if taxPurposes have items in it , the toggle should be set to true
    if (
      formValues.parties[index].taxPurposes?.length > 0 &&
      formValues.parties[index]?.taxPurposes[0].countryOfTax !==
        'Select an option'
    ) {
      change(`${field}.isTaxPurposes`, true);
    }
    return (
      <Grid
        container
        spacing={3}
        key={`${field}-${index}`}
        style={index > 0 ? { marginTop: '25px' } : {}}
      >
        <Grid item xs={12}>
          <Grid container spacing={3} className={classes.gridSubContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.titleName}>
                <FormattedMessage
                  {...messages.policyOwner}
                  values={{
                    name: `${parties[index].firstName} ${parties[index].lastName}`,
                    number: index + 1,
                  }}
                />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {/* <CardWrapper variant="outlined" theme={theme}> */}
              <Grid item container>
                <Grid item xs={8}>
                  <Typography variant="body1" className={classes.helperText}>
                    <FormattedMessage {...messages.isPEP} />
                    <Helper
                      helperText={intl.formatMessage(messages.pepDescription)}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  alignItems="center"
                  justify="flex-start"
                >
                  <Field
                    name={`${field}.isPep`}
                    type="radio"
                    component={FormRadioGroup}
                    normalize={value => value === 'true'}
                    value={formValues.parties[index].isPep}
                    onBlur={e => {
                      e.preventDefault();
                    }}
                  >
                    <FormControlLabel
                      control={<CustomRadio />}
                      value
                      label="Yes"
                      labelPlacement="end"
                      disabled={isDisabledField(disabledFields, 'po', 'isPep')}
                    />
                    <FormControlLabel
                      control={<CustomRadio />}
                      value={false}
                      label="No"
                      labelPlacement="end"
                      disabled={isDisabledField(disabledFields, 'po', 'isPep')}
                    />
                  </Field>
                </Grid>
              </Grid>
              <Dialog
                open={open}
                onClose={() => setOpen(false)}
                title={<FormattedMessage {...messages.warningIsCityzen} />}
                variant="error"
              >
                <Grid
                  style={{
                    padding: '16px 16px',
                    fontWeight: '900',
                  }}
                >
                  <span>
                    One or more parties has indicated that they are a US citizen
                    and/or US tax payor. Therefore you cannot proceed and this
                    application is void.
                  </span>
                </Grid>
                <Grid
                  style={{
                    display: 'flex',
                    marginTop: 20,
                  }}
                  container
                >
                  <Grid item xs container justify="center">
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        setOpen(false);
                        history.push('/illustrations');
                      }}
                      style={{
                        margin: theme.spacing(2),
                        padding: theme.spacing(2),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...messages.returnToHub} />
                    </Button>
                  </Grid>
                </Grid>
              </Dialog>
              {productCodeCheck(3, productCode) ? (
                <div>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                    container
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        className={classes.helperText}
                      >
                        <FormattedMessage {...messages.citizen} />
                        <Helper
                          helperText={
                            <div>
                              Definition of U.S Territories includes:
                              <br />
                              <span> — Federated States of Micronesia </span>
                              <br />
                              <span>— Guam</span>
                              <br />
                              <span>— Republic of Marshall Islands</span>
                              <br />
                              <span> — Northern Mariana Islands</span>
                              <br />
                              <span>— Puerto Rico</span>
                              <br /> <span>— Republic of Palau</span>
                              <br />
                              <span>
                                — United States Minor Outlying Islands{' '}
                              </span>
                              <br />
                              <span>— United States </span>
                              <br /> <span>— Virgin Islands US</span>
                              <br />
                              <span>— Midway Islands</span>
                            </div>
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name={`${field}.isUsResident`}
                        type="radio"
                        component={FormRadioGroup}
                        normalize={value => value === 'true'}
                        value={formValues.parties[index].isUsResident}
                        onBlur={e => {
                          // eslint-disable-next-line no-console
                          e.preventDefault();
                        }}
                        onChange={e => {
                          showPopupWarning(e);
                        }}
                        disabled={isDisabledField(
                          disabledFields,
                          'po',
                          'isUsResident',
                        )}
                      >
                        <FormControlLabel
                          control={<CustomRadio />}
                          value={true}
                          label="Yes"
                          labelPlacement="end"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'isUsResident',
                          )}
                        />
                        <FormControlLabel
                          control={<CustomRadio />}
                          value={false}
                          label="No"
                          labelPlacement="end"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'isUsResident',
                          )}
                        />
                      </Field>
                    </Grid>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: 20,
                    }}
                    container
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        className={classes.helperText}
                      >
                        {<FormattedMessage {...messages.taxpayer} />}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        name={`${field}.isUsTaxPayer`}
                        type="radio"
                        component={FormRadioGroup}
                        normalize={value => value === 'true'}
                        value={formValues.parties[index].isUsTaxPayer}
                        disabled={isDisabledField(
                          disabledFields,
                          'po',
                          'isUsTaxPayer',
                        )}
                        onBlur={e => {
                          e.preventDefault();
                        }}
                        onChange={e => {
                          showPopupWarning(e);
                        }}
                      >
                        <FormControlLabel
                          control={<CustomRadio />}
                          value={true}
                          label="Yes"
                          labelPlacement="end"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'isUsTaxPayer',
                          )}
                        />
                        <FormControlLabel
                          control={<CustomRadio />}
                          value={false}
                          label="No"
                          labelPlacement="end"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'isUsTaxPayer',
                          )}
                        />
                      </Field>
                    </Grid>
                  </Grid>
                </div>
              ) : (
                ''
              )}
            </Grid>
            {!isLifeInsured && (
              <>
                <Grid item>
                  <Field
                    style={{ width: 260 }}
                    shrink
                    name={`${field}.dateOfBirth`}
                    component={FormDatePicker}
                    label={<FormattedMessage {...globalMessages.dob} />}
                    inputProps={{ inputMode: 'numeric' }}
                    dateInputFormat="DD/MM/YYYY"
                    placeholder="dd/mm/yyyy"
                    margin="normal"
                    minDate={new Date().setFullYear(
                      new Date().getFullYear() - 100,
                    )}
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'dateOfBirth',
                    )}
                    maxDate={new Date().setFullYear(new Date().getFullYear())}
                  />
                </Grid>
              </>
            )}

            {productCodeCheck(3, productCode) ||
            parties[index].partyapplicationrelationships.isLifeInsured ===
              false ? (
              <Grid item>
                <Field
                  name={`${field}.primaryNationality`}
                  style={{
                    width: 260,
                    color: changePlaceHolderColor(
                      formValues.parties[index].primaryNationality,
                    ),
                  }}
                  component={FormSelect}
                  options={[
                    { value: 'Select an option', title: 'Select an option' },
                    ...filterCountryOption(
                      options.countryCode,
                      formValues.parties[index].secondaryNationality,
                    ),
                  ]}
                  label={<FormattedMessage {...messages.primaryNationality} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  disabled={isDisabledField(
                    disabledFields,
                    'po',
                    'primaryNationality',
                  )}
                />
              </Grid>
            ) : (
              ''
            )}

            <Grid item>
              <Field
                name={`${field}.secondaryNationality`}
                style={{
                  width: 260,
                  color: changePlaceHolderColor(
                    formValues.parties[index].secondaryNationality,
                  ),
                }}
                component={FormSelect}
                options={[
                  { value: 'Select an option', title: 'Select an option' },
                  ...filterCountryOption(
                    options.countryCode,
                    formValues.parties[index].primaryNationality,
                  ),
                ]}
                label={<FormattedMessage {...messages.dualNationality} />}
                placeholder={intl.formatMessage(messages.pleaseEnter)}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                disabled={isDisabledField(
                  disabledFields,
                  'po',
                  'secondaryNationality',
                )}
              />
            </Grid>

            <Grid item>
              <Field
                name={`${field}.preferredName`}
                style={{ width: 260 }}
                component={FormTextField}
                label={<FormattedMessage {...messages.alternateName} />}
                placeholder={intl.formatMessage(messages.pleaseEnter)}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                disabled={isDisabledField(
                  disabledFields,
                  'po',
                  'preferredName',
                )}
              />
            </Grid>

            <Grid item>
              <Field
                name={`${field}.countryOfBirth`}
                style={{
                  width: 260,
                  color: changePlaceHolderColor(
                    formValues.parties[index].countryOfBirth,
                  ),
                }}
                component={FormSelect}
                options={[
                  { value: 'Select an option', title: 'Select an option' },
                  ...options.countryCode,
                ]}
                label={<FormattedMessage {...messages.countryOfBirth} />}
                placeholder={intl.formatMessage(messages.pleaseEnter)}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                disabled={isDisabledField(
                  disabledFields,
                  'po',
                  'countryOfBirth',
                )}
              />
            </Grid>
            {productCodeCheck(3, productCode) ? (
              <Grid item xs={6}>
                <Field
                  name={`${field}.placeOfBirth`}
                  style={{
                    width: 260,
                  }}
                  component={FormTextField}
                  label={<FormattedMessage {...messages.placeOfBirth} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  disabled={isDisabledField(
                    disabledFields,
                    'po',
                    'placeOfBirth',
                  )}
                />
              </Grid>
            ) : (
              ''
            )}

            {/* {isInforce && isLifeInsured ? (
              <>
                <Grid item xs={8}>
                  <Typography variant="body1" className={classes.helperText}>
                    <FormattedMessage {...messages.isSmoker} />
                    <Helper
                      helperText={globalMessages.tooltipInsured.defaultMessage}
                    />
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Field
                    name={`${field}.isSmoke`}
                    type="radio"
                    component={FormRadioGroup}
                    normalize={value => value === 'true'}
                    value={formValues.parties[index].isSmoke}
                    onBlur={e => {
                      // eslint-disable-next-line no-console
                      e.preventDefault();
                    }}
                    disabled={isDisabledField(disabledFields, 'po', 'isSmoke')}
                  >
                    <FormControlLabel
                      control={<CustomRadio />}
                      value={true}
                      label="Yes"
                      labelPlacement="end"
                      disabled={isDisabledField(
                        disabledFields,
                        'po',
                        'isSmoke',
                      )}
                    />
                    <FormControlLabel
                      control={<CustomRadio />}
                      value={false}
                      label="No"
                      labelPlacement="end"
                      disabled={isDisabledField(
                        disabledFields,
                        'po',
                        'isSmoke',
                      )}
                    />
                  </Field>
                </Grid>
              </>
            ) : (
              ''
            )} */}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            className={clsx(
              classes.gridSubContainer,
              classes.gridSubContainerEmail,
            )}
          >
            <Grid item xs={12}>
              <Field
                name={`${field}.email`}
                style={{ width: 355 }}
                component={FormTextField}
                label={<FormattedMessage {...messages.emailAddress} />}
                placeholder={intl.formatMessage(messages.pleaseEnter)}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                disabled={isDisabledField(disabledFields, 'po', 'email')}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <Field
                    name={`${field}.primaryCountryDialingCode`}
                    style={{
                      width: 130,
                      color: changePlaceHolderColor(
                        formValues.parties[index].primaryCountryDialingCode,
                      ),
                    }}
                    component={FormSelect}
                    // options={options.countryDialingCode}
                    options={[
                      { value: 'Select', title: 'Select' },
                      ...options.countryCode,
                    ]}
                    label={<FormattedMessage {...messages.countryCode} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    value=""
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'primaryCountryDialingCode',
                    )}
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={`${field}.primaryPhoneNumber`}
                    style={{ width: 260 }}
                    component={FormNumberField}
                    thousandSeparator=""
                    label={<FormattedMessage {...messages.mobileNumber} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    inputProps={{ inputMode: 'numeric' }}
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'primaryPhoneNumber',
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item>
                  <Field
                    name={`${field}.secondaryCountryDialingCode`}
                    style={{
                      width: 130,
                      color: changePlaceHolderColor(
                        formValues.parties[index].secondaryCountryDialingCode,
                      ),
                    }}
                    component={FormSelect}
                    options={[
                      { value: 'Select', title: 'Select' },
                      ...options.countryCode,
                    ]}
                    label={<FormattedMessage {...messages.countryCode} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    value=""
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'secondaryCountryDialingCode',
                    )}
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={`${field}.secondaryPhoneNumber`}
                    style={{ width: 260 }}
                    component={FormNumberField}
                    thousandSeparator=""
                    label={<FormattedMessage {...messages.telephoneNumber} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    inputProps={{ inputMode: 'numeric' }}
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'secondaryPhoneNumber',
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} className={classes.gridSubContainer}>
            {index === 0 && (
              <>
                <Grid item>
                  <Field
                    name={`${field}.addressLine1`}
                    style={{ width: 260 }}
                    component={FormTextField}
                    label={<FormattedMessage {...messages.flatNumber} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'addressLine1',
                    )}
                  />
                </Grid>
                <Grid item>
                  <Field
                    name={`${field}.addressLine2`}
                    style={{ width: 260 }}
                    component={FormTextField}
                    label={<FormattedMessage {...messages.propertyName} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'addressLine2',
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${field}.addressLine3`}
                    style={{ width: 355 }}
                    component={FormTextField}
                    label={<FormattedMessage {...messages.area} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'addressLine3',
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${field}.city`}
                    style={{ width: 355 }}
                    component={FormTextField}
                    label={<FormattedMessage {...messages.emirateCity} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled={isDisabledField(disabledFields, 'po', 'city')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name={`${field}.country`}
                    style={{
                      width: 355,
                      color: changePlaceHolderColor(
                        formValues.parties[index].country,
                      ),
                    }}
                    component={FormSelect}
                    options={[
                      { value: 'Select an option', title: 'Select an option' },
                      ...optionList,
                    ]}
                    label={<FormattedMessage {...messages.country} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    value=""
                    disabled={isDisabledField(disabledFields, 'po', 'country')}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ paddingTop: 26, paddingBottom: 26 }}
                >
                  <Divider />
                </Grid>
                <Grid item>
                  <Field
                    name={`${field}.postOfficeBoxLine`}
                    style={{ width: 260 }}
                    component={FormTextField}
                    label={<FormattedMessage {...messages.poBoxOptional} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'postOfficeBoxLine',
                    )}
                  />
                </Grid>

                <Grid item>
                  <Field
                    name={`${field}.postOfficeBoxCity`}
                    style={{ width: 260 }}
                    component={FormTextField}
                    label={
                      <FormattedMessage {...messages.emirateCityOptional} />
                    }
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'postOfficeBoxCity',
                    )}
                  />
                </Grid>
                {index === 0 && fields.length > 1 && (
                  <Grid item xs={12} className={classes.hrOptional}>
                    <Divider />
                  </Grid>
                )}
              </>
            )}

            {index === 1 && (
              <Grid item xs={12}>
                <CardSectionForm
                  variant="outlined"
                  className={classes.containerWrapper}
                >
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                  >
                    <Box style={{ width: '85%' }}>
                      <Typography variant="body1">
                        <FormattedMessage
                          {...messages.isSameAddress}
                          values={{
                            firstName: parties[0].firstName,
                            lastName: parties[0].lastName,
                          }}
                        />
                      </Typography>
                    </Box>
                    <Field
                      name={`${field}.isDifferentAddress`}
                      component={renderSwitch}
                      theme={theme}
                      margin="normal"
                      disabled={isDisabledField(
                        disabledFields,
                        'po',
                        'addressLine1',
                      )}
                      checked={differentAddress}
                      onChange={e => {
                        setDifferentAddress(e.target.checked);
                        if (e.target.checked) {
                          change(`${field}.addressLine1`, '');
                          change(`${field}.addressLine2`, '');
                          change(`${field}.addressLine3`, '');
                          change(`${field}.city`, '');
                          change(`${field}.country`, 'Select an option');
                        }
                      }}
                    />
                  </Grid>
                  <Collapse
                    in={
                      formValues.parties[index].isDifferentAddress
                    }
                    mountOnEnter
                    unmountOnExit
                  >
                    <hr className={classes.hr} />
                    <Grid container spacing={3}>
                      <Grid item>
                        <Field
                          name={`${field}.addressLine1`}
                          style={{ width: 260 }}
                          component={FormTextField}
                          label={<FormattedMessage {...messages.flatNumber} />}
                          placeholder={intl.formatMessage(messages.pleaseEnter)}
                          type="text"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'addressLine1',
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name={`${field}.addressLine2`}
                          style={{ width: 260 }}
                          component={FormTextField}
                          label={
                            <FormattedMessage {...messages.propertyName} />
                          }
                          placeholder={intl.formatMessage(messages.pleaseEnter)}
                          type="text"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'addressLine2',
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name={`${field}.addressLine3`}
                          style={{ width: 355 }}
                          component={FormTextField}
                          label={<FormattedMessage {...messages.area} />}
                          placeholder={intl.formatMessage(messages.pleaseEnter)}
                          type="text"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'addressLine3',
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name={`${field}.city`}
                          style={{ width: 355 }}
                          component={FormTextField}
                          label={<FormattedMessage {...messages.emirateCity} />}
                          placeholder={intl.formatMessage(messages.pleaseEnter)}
                          type="text"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'city',
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name={`${field}.country`}
                          style={{
                            width: 355,
                            color: changePlaceHolderColor(
                              formValues.parties[index].country,
                            ),
                          }}
                          component={FormSelect}
                          options={[
                            {
                              value: 'Select an option',
                              title: 'Select an option',
                            },
                            ...optionList,
                          ]}
                          label={<FormattedMessage {...messages.country} />}
                          placeholder={intl.formatMessage(messages.pleaseEnter)}
                          type="text"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          value=""
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'country',
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <Grid item>
                        <Field
                          name={`${field}.postOfficeBoxLine`}
                          style={{ width: 260 }}
                          component={FormTextField}
                          label={
                            <FormattedMessage {...messages.poBoxOptional} />
                          }
                          placeholder={intl.formatMessage(messages.pleaseEnter)}
                          type="text"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'postOfficeBoxLine',
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Field
                          name={`${field}.postOfficeBoxCity`}
                          style={{ width: 260 }}
                          component={FormTextField}
                          label={
                            <FormattedMessage
                              {...messages.emirateCityOptional}
                            />
                          }
                          placeholder={intl.formatMessage(messages.pleaseEnter)}
                          type="text"
                          color="primary"
                          margin="normal"
                          variant="standard"
                          disabled={isDisabledField(
                            disabledFields,
                            'po',
                            'postOfficeBoxCity',
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </CardSectionForm>
              </Grid>
            )}
          </Grid>
        </Grid>

        {productCodeCheck(3, productCode) &&
          isUAEAgency &&
          isShowTaxResidence && (
            <TaxResidency
              options={options}
              index={index}
              formValues={formValues}
              formErrors={formErrors}
              field={field}
              change={change}
            />
          )}

        {productCodeCheck(3, productCode) && (
          <>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <CardSectionForm
                variant="outlined"
                className={classes.containerWrapper}
              >
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Box style={{ width: '85%' }}>
                    <Typography variant="body1">
                      <FormattedMessage {...messages.taxPurposes} />
                    </Typography>
                  </Box>
                  <Field
                    name={`${field}.isTaxPurposes`}
                    component={FormSwitch}
                    disabled={isDisabledField(
                      disabledFields,
                      'po',
                      'taxPurposes',
                    )}
                  />
                </Grid>
                <Collapse in={formValues.parties[index].isTaxPurposes}>
                  <hr className={classes.hr} />
                  <FormSection>
                    <FieldArray
                      name={`${field}.taxPurposes`}
                      disabled={isDisabledField(
                        disabledFields,
                        'po',
                        'taxPurposes',
                      )}
                      component={TaxPuposes}
                      options={options}
                      formValues={formValues.parties[index]}
                      disabledPercentage
                      newTaxPuposesPlaceholder={{
                        ...newTaxPurposes,
                      }}
                      disabledFields={disabledFields}
                    />
                  </FormSection>
                </Collapse>
              </CardSectionForm>
              <Typography variant="body1" className={classes.error}>
                {!!formErrors.selectTaxPurpose &&
                  formErrors.selectTaxPurpose[index]}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    );
  });
}

// Owner.propTypes = {
//   options: PropTypes.array,
//   nationlityOptions: PropTypes.array,
// };

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
});

function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Owner);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { convertIfImmutable } from 'helpers/lang';
import { isEmpty } from 'lodash/lang';
import { map } from 'lodash/collection';

import { Grid, Typography, Collapse, Box } from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

//selector
import { makeSelectOption } from 'containers/App/selectors';
import validate from './validate';

import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import FormCheck from 'components/FormCheck';
import OtherParties from './components/OtherParties';
import LifeOffices from './components/LifeOffices';
import ExistingZurichPlan from './components/ExistingZurichPlan';
import BankAccount from './components/BankAccount';
import { newBankAccount } from 'helpers/placeholder';
import { makeSelectFinancialInstituteOptions } from 'containers/App/selectors';
import OtherBankAccount from './components/OtherBankAccount';
import { mapFinancialInstituteOptions } from 'containers/ApplicationFormPage/helpers';
import { productCodeCheck } from 'helpers/constants';

const useStyle = makeStyles(theme => ({
  containerWrapper: {
    width: '640px',
    marginBottom: '26px !important',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  subTitle: {
    fontSize: '1.4rem',
    transform: 'none',
    fontWeight: 'bold',
    transformOrigin: 'top left',
    marginBottom: 5,
  },
  titleAccount: {
    marginTop: 15,
    fontSize: 14,
    color: '#B6B8B4',
  },
}));

function PayorBank(props) {
  const classes = useStyle();
  const {
    change,
    formValues,
    options,
    financialInstituteOptions,
    isInforce,
  } = props;
  const { productCode } = formValues;

  return (
    <>
      {/* ==================FUTU5=================== */}
      {productCodeCheck(3, productCode) ? (
        <>
          <Grid container item xs={12} style={{ maxWidth: 430 }}>
            <FormSection
              name="bankAccountDetail"
              component={BankAccount}
              formValues={formValues}
              options={mapFinancialInstituteOptions(
                convertIfImmutable(financialInstituteOptions),
              )}
              change={change}
              isCompany
            />
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <Box mb={4.5}>
                <Field
                  name="isPaymentDetails"
                  margin="normal"
                  fullWidth
                  component={FormCheck}
                  label={
                    <Typography style={{ fontSize: '14px' }}>
                      <FormattedMessage {...messages.isPaymentDetails} />
                    </Typography>
                  }
                  type="checkbox"
                  color="primary"
                  variant="standard"
                />
              </Box>
            </Grid>
          </Grid>

          <CardSectionForm
            variant="outlined"
            className={classes.containerWrapper}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Box mr={5}>
                <Typography variant="body1">
                  <FormattedMessage {...messages.isBankAccount} />
                </Typography>
              </Box>
              <Field name="isOtherBankAccount" component={FormSwitch} />
            </Grid>
            <Collapse in={formValues.isOtherBankAccount}>
              <hr className={classes.hr} />
              <FormSection>
                <FieldArray
                  name="otherBankAccountDetails"
                  component={OtherBankAccount}
                  formValues={formValues.otherBankAccountDetails}
                  disabledPercentage
                  options={mapFinancialInstituteOptions(
                    convertIfImmutable(financialInstituteOptions),
                  )}
                  newOtherBankAccountPlaceholder={{
                    ...newBankAccount,
                  }}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          <CardSectionForm
            variant="outlined"
            className={classes.containerWrapper}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Box mr={5}>
                <Typography variant="body1">
                  <FormattedMessage {...messages.isOtherParties} />
                </Typography>
              </Box>
              <Field name="isOtherParties" component={FormSwitch} />
            </Grid>
            <Collapse in={formValues.isOtherParties}>
              <hr className={classes.hr} />
              <FormSection
                name="otherPartiesDetails"
                component={OtherParties}
                formValues={formValues}
                options={options}
                change={change}
                isCompany
              />
            </Collapse>
          </CardSectionForm>
        </>
      ) : (
        ''
      )}

      {/* ==================END-FUTU5=================== */}
      {!isInforce ? (
        <CardSectionForm
          variant="outlined"
          className={classes.containerWrapper}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Box mr={5}>
              <Typography variant="body1">
                <FormattedMessage {...messages.existingZurichPlan} />
              </Typography>
            </Box>
            <Field name="isExistingZurichPlan" component={FormSwitch} />
          </Grid>
          <Collapse in={formValues.isExistingZurichPlan}>
            <hr className={classes.hr} />
            <FormSection
              name="existingZurichPlanDetails"
              component={ExistingZurichPlan}
              formValues={formValues}
              options={options}
              change={change}
              isCompany
            />
          </Collapse>
        </CardSectionForm>
      ) : (
        ''
      )}
      {/* <CardSectionForm variant="outlined" className={classes.containerWrapper}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Box mr={5}>
            <Typography variant="body1">
              <FormattedMessage {...messages.lifeOffices} />
            </Typography>
          </Box>
          <Field name="isLifeOffices" component={FormSwitch} />
        </Grid>
        <Collapse in={formValues.isLifeOffices}>
          <hr className={classes.hr} />
          <FormSection
            name="lifeOfficesDetails"
            component={LifeOffices}
            formValues={formValues}
            options={options}
            change={change}
            isCompany
          />
        </Collapse>
      </CardSectionForm> */}
    </>
  );
}

PayorBank.propTypes = {
  options: PropTypes.object,
  financialInstituteOptions: PropTypes.object,
  ISOCode: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
  financialInstituteOptions: makeSelectFinancialInstituteOptions(),
});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  form: 'payorBank',
  onSubmit: submit,
  validate,
});

const withFormSelector = connect(state => {
  const formValues = getFormValues('payorBank')(state);
  const formErrors = getFormSyncErrors('payorBank')(state);
  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

export default compose(withConnect, withFormSelector, withForm)(PayorBank);

import { makeStyles } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

export const getPaymentFrequency = (frequency, singlePremiumTerm) => {
  switch (frequency) {
    case 'Monthly':
      return 'p/month';

    case 'Quarterly':
      return 'p/quarter';

    case 'Half-Yearly':
      return 'p/half-year';

    case 'Yearly':
      return 'p/year';

    case 'Single':
      if (singlePremiumTerm > 0){
        return 'per year';
      } else {
        return 'Single time';
      }

    default:
      return '';
  }
};

export const mapStatus = (classes, status) => {
  switch (status) {
    case 'in progress':
      return classes.inProcessText;
    case 'pending':
    case 'pending for review': // for admin module
      return classes.pendingText;
    case 'actionable':
      return classes.actionableText;
    case 'submitted':
      return classes.submittedText;
    case 'rejected': // For admin module
      return classes.rejectedText;
  }
};


export const useStyles = makeStyles(theme => ({
  inProcessText: {
    color: '#00C3B4',
    textTransform: 'capitalize',
  },
  pendingText: {
    color: '#FF8F4A',
    textTransform: 'capitalize',
  },
  actionableText: {
    color: '#E75252',
    textTransform: 'capitalize',
  },
  rejectedText: {
    color: '#E75252',
    // textTransform: 'capitalize',
  },
  redText : { // added redText and amberText 
    color: '#FF7569',
    textTransform: 'capitalize',
  },
  amberText : {
    color: '#F7AD00',
    textTransform: 'capitalize',
  },
  submittedText: {
    color: '#043594',
    textTransform: 'capitalize',
  },
  addExternalNumber: {
    color: '#717171',
  },
  addExternalNumberIcon: {
    height: '1.3rem',
    color: '#717171',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexJustifyCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  capitalizeAllCaps: {
    textTransform: 'lowercase',
    display: 'inline-block',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  // whiteButton: {},
  openButton: {
    minWidth: '172px',
    height: '53px',
    padding: '18px 23px',
    marginBottom: '0.7rem',
  },
  bold: { fontWeight: 800 },
  buttonWhite: {
    // marginTop: '1rem',
    height: '53px',
    minWidth: '150px',
    border: `2px solid #A0B3D9`,
    backgroundColor: 'white',
    color: '#4066B3',
    '&:hover': {
      backgroundColor: blue[900],
      border: `2px solid ${blue[900]}`,
      color: 'white',
    },
  },
  buttonPrimary: {
    minWidth: '150px',
  },
}));

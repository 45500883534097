/*
 *
 * WapIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/WapIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/WapIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/WapIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const SET_WAP_CONFIG_ACTION =
  'app/WapIllustrationPage/SET_WAP_CONFIG_ACTION';
export const GET_WAP_CONFIG_SUCCESS_ACTION =
  'app/WapIllustrationPage/GET_WAP_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION =
  'app/WapIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/WapIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/WapIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/WapIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/WapIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/WapIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/WapIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/WapIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/WapIllustrationPage/SET_PTV_VERSION_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/WapIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/WapIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/WapIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_ERROR_ACTION = 'app/WapIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/WapIllustrationPage/RESET_ACTION';
export const GET_FIA_ACTION = 'app/WapIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/WapIllustrationPage/SET_FIA_ACTION';
export const SET_BI_TABLE_VALUES_ACTION =
  'app/WapIllustrationPage/SET_BI_TABLE_VALUES_ACTION';
export const CLONE_QUOTE_ACTION = 'app/WapIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/WapIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/WapIllustrationPage/SET_BI_PDF_ACTION';
export const SET_EMAIL_SENT_ACTION =
  'app/WapIllustrationPage/SET_EMAIL_SENT_ACTION';
export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/WapIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';

export const SET_PRODUCT_CODE_ACTION =
  'app/WapIllustrationPage/SET_PRODUCT_CODE_ACTION';

export const SET_FORM_INPUT = 'app/WapIllustrationPage/SET_FORM_INPUT';

import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the gcIllustrationPage state domain
 */
const selectRspIllustrationPageDomain = state =>
  state.get('rspIllustrationPage', initialState);

/**
 * Other specific selectors
 */
const makeSelectLifeAssured = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('lifeAssured').toJS(),
  );

const makeSelectInitialLifeAssured = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('initialLifeAssured').toJS(),
  );

const makeSelectError = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('errorData').toJS(),
  );

const makeSelectConfig = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('gcConfig').toJS(),
  );

const makeSelectBiTableValues = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('biTableValues').toJS(),
  );

const makeSelectBiTableValues1 = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('biTableValues1').toJS(),
  );

const makeSelectFiaCharge = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('fiaData').toJS(),
  );

const makeSelectProductConfig = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('productConfig').toJS(),
  );

const makeSelectProductConfig2 = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('productConfig2').toJS(),
  );

const makeSelectQuoteId = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('quoteId'),
  );

const makeSelectApplicationId = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('applicationId'),
  );

const makeSelectBiPdf = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('benefitIllustrationPdf'),
  );
const makeSelectBiPdf1 = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('benefitIllustrationPdf1'),
  );

const makeSelectStatus = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('status'),
  );

const makeSelectReferenceNumber = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('referenceNumber'),
  );

const makeSelectCloneStatus = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('successClone'),
  );

const makeSelectemailSentStatus = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('emailSent'),
  );

const makeSelectEmailErrorMessage = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('emailErrorMessage'),
  );

const makeSelectCoverages2 = () =>
  createSelector(selectRspIllustrationPageDomain, substate =>
    substate.get('coverages2').toJS(),
  );

export {
  selectRspIllustrationPageDomain,
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectFiaCharge,
  makeSelectConfig,
  makeSelectError,
  makeSelectQuoteId,
  makeSelectApplicationId,
  makeSelectBiPdf,
  makeSelectStatus,
  makeSelectReferenceNumber,
  makeSelectInitialLifeAssured,
  makeSelectBiTableValues,
  makeSelectBiTableValues1,
  makeSelectCloneStatus,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectProductConfig2,
  makeSelectBiPdf1,
  makeSelectCoverages2,
};

/**
 *
 * Review
 *
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
  Typography,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from '@material-ui/core';
import CustomDialog from 'components/Dialog';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';
import globalMessages from 'containers/App/messages';
import {
  Cancel,
  CheckCircle,
  DescriptionOutlined,
  Info,
} from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import { isValidEmail } from '../helper';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectBiPdf,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
  makeSelectConfig,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectProductConfig2,
  makeSelectBiPdf1,
} from '../selectors';
import {
  getQuoteAction,
  updateStatusAction,
  emailPdfAction,
  setEmailSentAction,
} from '../actions';
import InfoRow from 'components/InfoRow';
import { isEmpty } from 'lodash';
import numeral from 'numeral';
import ReviewUserData from 'components/ReviewUserData';
import { find } from 'lodash/collection';
import { get } from 'lodash/object';

function Review({
  lifeAssured,
  productConfig,
  getQuote,
  gcConfig,
  benefitIllustrationPdf,
  updateStatus,
  emailPdf,
  referenceNumber,
  disableProceed,
  onClickBackToClientList,
  fiaData,
  emailSent,
  setEmailSent,
  emailErrorMessage,
  productConfig2,
  benefitIllustrationPdf1,
}) {
  const theme = useTheme();
  useEffect(() => {
    getQuote(0);
  }, []);

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [errorMail, setErrorMail] = useState(false);
  const [RSPPDF, setRSPPDF] = useState(false);
  const [ITAPDF, setITAPDF] = useState(false);
  //const biPdf = benefitIllustrationPdf + '&isPreview=true';

  useEffect(() => {
    if (emailSent) {
      setErrorMail(true);
    }
  }, [emailSent]);

  useEffect(() => {
    return () => {
      setEmailSent(false);
    };
  }, []);
  const selectedItem = productConfig.coverages.filter(item => {
    return item.selected;
  });

  const selectedItem2 = productConfig2.coverages.filter(item => {
    return item.selected;
  });

  const getBrTitle = brValue => {
    switch (brValue) {
      case '0':
        return '';

      case '1':
        return 'Per Mille';

      case '2':
        return 'Percentage';

      default:
        return '';
    }
  };

  var frequencyLabel = 'p/month';

  switch (productConfig.paymentFrequency) {
    case 'Monthly':
      frequencyLabel = 'p/month';
      break;

    case 'Quarterly':
      frequencyLabel = 'p/quarter';
      break;

    case 'Half-Yearly':
      frequencyLabel = 'p/half-year';
      break;

    case 'Yearly':
      frequencyLabel = 'p/year';
      break;

    default:
      frequencyLabel = 'p/month';
      break;
  }

  //function to check if both view PDFs are clicked

  useEffect(() => {
    if (productConfig?.isBundlingQuote) {
      if (ITAPDF && RSPPDF) {
        updateStatus(1);
      }
    } else {
      if (RSPPDF) {
        updateStatus(1);
      }
    }
  }, [ITAPDF, RSPPDF]);

  return (
    <div style={{ maxWidth: 650 }}>
      {/* ########## START  :: REFERENCE NUMBER ########## */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          {/* <Button
            display="block"
            variant="text"
            color="primary"
            onClick={() => onClickBackToClientList()}
          >
            <FormattedMessage {...messages.backToClientList} />
          </Button> */}
        </Grid>

        <Grid item>
          <Typography
            display="block"
            align="right"
            variant="body2"
            style={{ color: '#666', paddingTop: '20px' }}
          >
            <FormattedMessage {...messages.reference} />:{` ${referenceNumber}`}
          </Typography>
        </Grid>
      </Grid>

      {/* ########## END    :: REFERENCE NUMBER ########## */}
      {/* ########## START  :: POLICY OWNER ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          {<FormattedMessage {...messages.policyOwner} />}
        </Typography>
        {/* LI 1 as PO */}
        {lifeAssured.insuredOne.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredOne} />
        )}
        {/* LI 2 as PO */}
        {lifeAssured.isInsuredTwo && lifeAssured.insuredTwo.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
        {/* other POs */}
        {lifeAssured.isPolicyOwnerIndividual &&
          lifeAssured.policyOwnerIndividual.map((po, index) => {
            const key = `${po.firstName}-${index}`;
            return <ReviewUserData key={key} party={po} />;
          })}
      </Box>
      {/* ########## END    :: POLICY OWNER ########## */}
      {/* ########## START  :: LIFE INSURED ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          {<FormattedMessage {...messages.insuredParty} />}
        </Typography>

        <ReviewUserData party={lifeAssured.insuredOne} />
        {lifeAssured.isInsuredTwo && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
      </Box>
      {/* ########## END    :: LIFE INSURED ########## */}

      {/* ########## START  :: PREMIUM SUMMARY ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          {<FormattedMessage {...messages.premiumSummary} />}
        </Typography>
        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.premiumAmount} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {productConfig.currency}{' '}
                {productConfig?.isBundlingQuote
                  ? formatMonetaryValue(
                      get(
                        productConfig,
                        'pricingData[2].premium',
                        get(productConfig2, 'premium', 0),
                      ),

                      true,
                    )
                  : formatMonetaryValue(productConfig.premium, true)}
                {` ${frequencyLabel}`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.premiumPaymentTerm} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {`${productConfig.term} Years`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        {!isEmpty(fiaData) && gcConfig.features.haveFiaCharge ? (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...globalMessages.advisorFeeLabel} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {`${productConfig.fiaCharge} %`}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        ) : null}

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.assumedGrowthRate} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {`${productConfig.growthRate} %`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>
      </Box>
      {/* ########## END    :: PREMIUM SUMMARY ########## */}

      {/* ########## START  :: WITHDRAWAL ########## */}
      {productConfig.isEducationalFeeWithdrawal ||
      productConfig.isRegularWithdrawal ? (
        <Box mb={5}>
          <Typography
            variant="h3"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.subMain,
            }}
          >
            {<FormattedMessage {...messages.withdrawals} />}
          </Typography>

          {productConfig.isEducationalFeeWithdrawal ? (
            <InfoRow>
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                justify="space-between"
              >
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {productConfig.currency}{' '}
                    {formatMonetaryValue(
                      productConfig.educationFeesWithdrawal.child1.amount,
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(
                      productConfig.educationFeesWithdrawal.child1.startDate,
                    ).format('DD MMM YYYY')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(
                      productConfig.educationFeesWithdrawal.child1.endDate,
                    ).format('DD MMM YYYY')}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {productConfig.educationFeesWithdrawal.child1.frequency}
                  </Typography>
                </Grid>
              </Grid>
            </InfoRow>
          ) : null}

          {productConfig.isRegularWithdrawal ? (
            <InfoRow>
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                justify="space-between"
              >
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {numeral(
                      productConfig.regularWithdrawal.percentage,
                    ).value() != 0
                      ? `${productConfig.regularWithdrawal.percentage}%`
                      : `${productConfig.currency} ${formatMonetaryValue(
                          productConfig.regularWithdrawal.amount,
                          false,
                        )}`}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(productConfig.regularWithdrawal.startDate).format(
                      'DD MMM YYYY',
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(productConfig.regularWithdrawal.endDate).format(
                      'DD MMM YYYY',
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {productConfig.regularWithdrawal.frequency}
                  </Typography>
                </Grid>
              </Grid>
            </InfoRow>
          ) : null}
        </Box>
      ) : null}
      {/* ########## END  :: WITHDRAWAL ########## */}

      {/* ########## START  :: COVER DETAILS ########## */}
      <Box mb={5}>
        {selectedItem.length > 0 ? (
          <Typography
            variant="h3"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.subMain,
            }}
          >
            {<FormattedMessage {...messages.coverDetails} />}
          </Typography>
        ) : null}

        {productConfig.coverages.map((cvg, index) => {
          if (!cvg.selected) {
            return null;
          }
          return (
            <Grid container direction="column" key={cvg.id}>
              <InfoRow style={{ maxHeight: 64 }}>
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  justify="space-between"
                >
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        style={{
                          color: theme.palette.grey[400],
                        }}
                      >
                        {cvg.selected ? (
                          <CheckCircle color="inherit" />
                        ) : (
                          <Cancel color="inherit" />
                        )}
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2">{cvg.title}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="body2">
                      {cvg.value ? 'Yes' : 'No'}
                    </Typography>
                  </Grid>

                  {lifeAssured.isInsuredTwo ? (
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {cvg.id === 'WOP'
                          ? cvg.selected
                            ? 'No'
                            : 'Yes'
                          : cvg.value
                          ? 'Yes'
                          : 'No'}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </InfoRow>
            </Grid>
          );
        })}
      </Box>

      {/* ########## END    :: COVER DETAILS ########## */}

      {/* ########## START  :: PROTECTION COVER DETAILS ########## */}

      {productConfig.isBundlingQuote && (
        <>
          <Box mb={5}>
            {selectedItem2.length > 0 ? (
              <Typography
                variant="h3"
                style={{
                  marginBottom: theme.spacing(2),
                  color: theme.palette.primary.subMain,
                }}
              >
                {<FormattedMessage {...messages.protectionCoverDetails} />}
              </Typography>
            ) : null}

            {productConfig2.coverages.map((cvg, index) => {
              if (!cvg.selected) {
                return null;
              }

              const value =
                typeof cvg.value === 'boolean'
                  ? cvg.value
                  : typeof cvg.value === 'number'
                  ? numeral(cvg.value).value()
                  : typeof cvg.valueInsuredOne === 'boolean'
                  ? cvg.valueInsuredOne
                  : numeral(cvg.valueInsuredOne).value();

              const value2 =
                typeof cvg.value2 === 'boolean'
                  ? cvg.value2
                  : typeof cvg.value2 === 'number'
                  ? numeral(cvg.value2).value()
                  : typeof cvg.valueInsuredTwo === 'boolean'
                  ? cvg.valueInsuredTwo
                  : numeral(cvg.valueInsuredTwo).value();
              return (
                <Grid container direction="column" key={cvg.id}>
                  <InfoRow style={{ maxHeight: 64 }}>
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          >
                            {cvg.selected ? (
                              <CheckCircle color="inherit" />
                            ) : (
                              <Cancel color="inherit" />
                            )}
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2">
                              {cvg.name ? cvg.name : cvg.title}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {typeof value === 'boolean'
                            ? value
                              ? 'Yes'
                              : 'No'
                            : `${productConfig.currency} ${formatMonetaryValue(
                                value,
                              )}`}
                        </Typography>
                      </Grid>

                      {lifeAssured.isInsuredTwo ? (
                        <Grid item xs={3}>
                          <Typography variant="body2">
                            {typeof value2 === 'boolean'
                              ? value2
                                ? 'Yes'
                                : 'No'
                              : `${
                                  productConfig.currency
                                } ${formatMonetaryValue(value2)}`}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </InfoRow>
                </Grid>
              );
            })}
          </Box>
        </>
      )}

      {/* ########## END    :: PROTECTION COVER DETAILS ########## */}

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
        }}
      >
        <FormattedMessage {...messages.reviewNotice} />
      </Typography>
      <InfoRow variant="document">
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item xs={1}>
            <DescriptionOutlined color="inherit" />
          </Grid>
          <Grid item xs={7}>
            <Typography variant="body1" color="inherit">
              {gcConfig.shortName}
            </Typography>
          </Grid>

          <Grid item xs={3}>
            <Link
              href={benefitIllustrationPdf}
              target="_blank"
              align="center"
              variant="body2"
              disabled={!benefitIllustrationPdf}
              onClick={() => setRSPPDF(true)}
              replace
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.viewExportPDF} />
            </Link>
          </Grid>
          <Grid item xs={2}>
            <Link
              target="_blank"
              align="center"
              variant="body2"
              replace
              onClick={() => setOpen(true)}
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.emailPDF} />
            </Link>
          </Grid>
        </Grid>
      </InfoRow>

      {/* ITA */}
      {productConfig?.productList?.length > 0 ||
      productConfig?.productConfig2 ? (
        <InfoRow variant="document">
          <Grid container alignItems="center" wrap="nowrap" spacing={2}>
            <Grid item xs={1}>
              <DescriptionOutlined color="inherit" />
            </Grid>
            <Grid item xs xs={7}>
              <Typography variant="body1" color="inherit">
                {get(
                  productConfig,
                  'productList[1].name',
                  productConfig?.productConfig2?.name,
                )}
              </Typography>
            </Grid>

            <Grid item xs={3}>
              <Link
                href={benefitIllustrationPdf1}
                target="_blank"
                align="center"
                variant="body2"
                disabled={isEmpty(benefitIllustrationPdf1)}
                onClick={() => setITAPDF(true)}
                replace
                style={{
                  color: theme.palette.success.main,
                  fontWeight: 700,
                }}
              >
                <FormattedMessage {...messages.viewExportPDF} />
              </Link>
            </Grid>
            <Grid item xs={2}>
              {/* <Link
                target="_blank"
                align="center"
                variant="body2"
                replace
                onClick={() => setOpen(true)}
                style={{
                  color: theme.palette.success.main,
                  fontWeight: 700,
                }}
              >
                <FormattedMessage {...messages.emailPDF} />
              </Link> */}
            </Grid>
          </Grid>
        </InfoRow>
      ) : null}

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage {...messages.emailPDF} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            id="emailAddress"
            label={<FormattedMessage {...globalMessages.email} />}
            type="email"
            error={error}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isValidEmail(email)) {
                setError(false);
                emailPdf(email);
                return setOpen(false);
              } else {
                return setError(true);
              }
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.send} />
          </Button>
        </DialogActions>
      </Dialog>

      {/* EMAIL ERROR  */}
      <CustomDialog
        open={errorMail}
        onClose={() => {
          setErrorMail(false);
          setEmailSent(false);
        }}
        title={<FormattedMessage {...messages.error} />}
        variant="error"
        actions={
          <Button
            onClick={() => {
              setErrorMail(false);
              return setEmailSent(false);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.close} />
          </Button>
        }
      >
        <Typography variant="h2">
          <FormattedMessage
            {...messages.errorEmail}
            values={{ message: emailErrorMessage }}
          />
        </Typography>
      </CustomDialog>
    </div>
  );
}

Review.propTypes = {
  fiaData: PropTypes.object,
  lifeAssured: PropTypes.object,
  productConfig: PropTypes.object,
  gcConfig: PropTypes.object,
  benefitIllustrationPdf: PropTypes.string,
  benefitIllustrationPdf1: PropTypes.string,
  getQuote: PropTypes.func,
  updateStatus: PropTypes.func,
  emailPdf: PropTypes.func,
  referenceNumber: PropTypes.string,
  disableProceed: PropTypes.bool,
  onClickBackToClientList: PropTypes.func,
  emailSent: PropTypes.bool,
  setEmailSent: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  productConfig2: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  lifeAssured: makeSelectLifeAssured(),
  productConfig: makeSelectProductConfig(),
  benefitIllustrationPdf: makeSelectBiPdf(),
  benefitIllustrationPdf1: makeSelectBiPdf1(),
  fiaData: makeSelectFiaCharge(),
  gcConfig: makeSelectConfig(),
  referenceNumber: makeSelectReferenceNumber(),
  emailSent: makeSelectemailSentStatus(),
  emailErrorMessage: makeSelectEmailErrorMessage(),
  productConfig2: makeSelectProductConfig2(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: val => dispatch(updateStatusAction(val)),
    getQuote: val => dispatch(getQuoteAction(val)),
    emailPdf: value => dispatch(emailPdfAction(value)),
    setEmailSent: value => dispatch(setEmailSentAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Review);

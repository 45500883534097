import React, { useEffect } from 'react';
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatMonetaryValue } from 'helpers/math';
import messages from '../../../messages';
import {
  SINGLE_PREMIUM,
  WHOLE_OF_LIFE,
} from 'containers/InforceIllutrationPage/constants';

const AmountItem = ({
  title,
  currency,
  value,
  isBig = false,
  isLastItem = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={isBig ? 12 : 5}
      style={{
        borderBottom: isLastItem ? 'none' : '2px solid white',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      }}
      {...props}
    >
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: isBig ? '2.8rem' : '1.4rem' }}
      >
        {currency} {value}
      </Typography>
    </Grid>
  );
};

AmountItem.propTypes = {
  isBig: PropTypes.bool,
  isLastItem: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  props: PropTypes.object,
};

export default function WapSummaryAmount({
  premium,
  paidToDate,
  growthRateDetail,
  startDate,
  lastPayment,
  paymentDue,
  term,
  coverageAmount,
  currency,
  totalPartialSurrender
}) {
  const theme = useTheme();
  let coverageAmountDetails = coverageAmount?.replace(currency, '')
  return (
    <Grid
      container
      direction="column"
      style={{
        background: '#F1F1F1',
        padding: theme.spacing(2),
      }}
      wrap="nowrap"
    >
      <AmountItem
        isBig
        value={formatMonetaryValue(coverageAmountDetails, false)}
        currency={currency}
        title={'Current fund value'}
      />
      <AmountItem
        isBig
        value={formatMonetaryValue(totalPartialSurrender, false)}
        currency={currency}
        title={'Total withdrawal till date'}
      />
      <AmountItem
        isBig
        value={formatMonetaryValue(paidToDate, false)}
        currency={currency}
        title={'Premium paid to Date'}
      />
      <Grid
        container item
        style={{
          borderBottom: '2px solid white', paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(3)
        }}>
        <Grid item xs={12} style={{
          color: 'rgba(0, 0, 0, 0.54)'
        }}>
          Growth rate
        </Grid>
        <Grid item xs={12} style={{ fontSize: '28px', fontWeight: '700', paddingTop: '7px' }}>{(growthRateDetail > 0) ? growthRateDetail + "%" : '-'}</Grid>
      </Grid>
      <Grid
        item
        xs
        container
        style={{
          borderBottom: '2px solid white',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <AmountItem
          isLastItem
          value={moment(startDate).format('DD MMMM YYYY')}
          title={<FormattedMessage {...messages.startDate} />}
        />
        <Grid xs={2} item>
          <Divider
            orientation="vertical"
            variant="middle"
            style={{
              background: 'white',
            }}
          />
        </Grid>
        <AmountItem
          isLastItem
          value={
            term === 'SinglePremium'
              ? SINGLE_PREMIUM
              : term === 'WholeOfLife'
                ? WHOLE_OF_LIFE
                : `${term} years`
          }
          title={<FormattedMessage {...messages.paymentTerm} />}
        />
      </Grid>
      <Grid item xs container style={{ paddingBottom: theme.spacing(2) }}>
        <AmountItem
          isLastItem
          value={new Date(lastPayment).getFullYear() == 0 ? "-" : moment(lastPayment).format('DD MMMM YYYY')}
          title={<FormattedMessage {...messages.lastPayment} />}
        />
        <Grid xs={2} item>
          <Divider
            orientation="vertical"
            variant="middle"
            style={{
              background: 'white',
              marginTop: theme.spacing(2),
            }}
          />
        </Grid>
        <AmountItem
          isLastItem
          value={'-'}
          title={<FormattedMessage {...messages.paymentDue} />}
        />
      </Grid>
    </Grid>
  );
}

WapSummaryAmount.propTypes = {
  // TODO: defines here
};

/**
 * CoverageForm
 */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';
import {
  WrappedFieldArrayProps,
  InjectedFormProps,
  change,
  FormSection,
} from 'redux-form';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  Collapse,
  Radio,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';

import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import FormTextField from 'components/FormTextField';
import FormOptionButtons from 'components/FormOptionButtons';
import { dimension } from 'styles/constants';
import FormCheck from 'components/FormCheck';
import FormNumberField from 'components/FormNumberField';
import FormDatePicker from 'components/FormDatePicker';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import moment from 'moment';
import CustomFormCheck from 'components/CustomFormCheck';
// import FormAutocomplete from 'components/FormAutocomplete';

import EmptyIcon from 'images/checkbox-blue-empty.svg';
import CheckedIcon from 'images/checkbox-blue-tick.svg';
import CardSectionForm from 'components/CardSectionForm';
import OptSwitch from 'components/OptSwitch';
import { isEmpty } from 'lodash';
import { find } from 'lodash/collection';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import FormSwitch from 'components/FormSwitch';
import numeral from 'numeral';
import Helper from 'components/Helper';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import SinglePremium from '../SinglePremium';
import Withdrawal from '../Withdrawal';
import { getStartDate, calculateAgeNextBirthdate } from '../../helper';
import Tooltip from '../Tooltip';
import validate from './validate';
import messages from '../../messages';
import asyncValidator from './asyncValidate';
import { get } from 'lodash/object';
import globalMessages from 'containers/App/messages';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: 23,
    marginBottom: 28,
  },
  lightHr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  labelCheckbox: {
    fontWeight: 700,
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => (
  <OptSwitch
    checked={value}
    value={value}
    color="default"
    onChange={onChange}
    labelNegative={<FormattedMessage {...messages?.no} />}
    disabled={disabled}
  />
);

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const renderBenefit = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
}) => (
  <div>
    {fields?.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne.dateOfBirth,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo.dateOfBirth,
      );

      let ageValidType = 0;

      let isFormCheckDisabled = myBenefit[index].disabled;
      const isFieldsDisabled = !myBenefit[index].selected;

      let isWopOneDisabled = myBenefit[index].disabled;
      let isWopTwoDisabled = myBenefit[index].disabled;

      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA != '0') {
          if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
            isWopOneDisabled = true;
            change(`${benefit}.value`, false);
          }

          if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
            isWopTwoDisabled = true;
            change(`${benefit}.value`, false);
          }
        }
      } else if (myBenefit[index].maxEntryAgeLA != '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          isWopOneDisabled = true;
          change(`${benefit}.value`, false);
          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }
      return (
        <div key={myBenefit[index].id}>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            item
            xs={11}
          >
            <Grid item xs={12} sm={6}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.selected`}
                  fullWidth
                  component={CustomFormCheck}
                  label={myBenefit[index].title}
                  disabled={isFormCheckDisabled}
                  validType={ageValidType}
                  type="text"
                  color="inherit"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`${benefit}.value`, false);
                    }
                  }}
                />
              ) : (
                <Grid container direction="row">
                  <Typography variant="h4" style={{ fontWeight: '600' }}>
                    {myBenefit[index].title}
                  </Typography>
                  <div>
                    <Helper
                      helperText={
                        <FormattedMessage {...messages?.descriptionTwo} />
                      }
                    ></Helper>
                  </div>
                </Grid>
              )}
            </Grid>
            {/* test */}
            <Grid item xs={6} sm={3}>
              <Field
                // shrink
                name={`${benefit}.value`}
                component={FormBooleanOption}
                type="text"
                margin="normal"
                disabled={isWopOneDisabled}
                // shrink
                fullWidth
              />
            </Grid>
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  isWap,
  policyConfig,
  handleSubmit,
  lifeAssured,
  options,
  product,
  productConfig,
  change,
  startDate,
  fiaData,
  fiaCharge,
  regularWithdrawal,
  isRegularWithdrawal,
  isAdditionalSinglePremium,
  additionalSinglePremium,
  isAdditionalFeatures,
  additionalFeatures,
  currency,
  offerCode,
  premium,
  offerCodeData,
  agentDetails,
  location,
  resetOfferCode,
  validateOfferCode,
  quoteStartDay,
  policyValue,
  isoCountryCode,
  HarvestPolicyStartDate
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  let minCharge = get(
    productConfig,
    'features.establishmentCharge.minPercentage',
    0,
  );
  let maxCharge = get(
    productConfig,
    'features.establishmentCharge.maxPercentage',
    4.5,
  );

  const agencies = get(productConfig, 'features.establishmentCharge.agencies', []);
  const currencyOption = [];
  const chargeOptions = [];
  const paymentFrequencyOption = [];
  const paymentWithdraw = []
  const policyTermList = [];
  const startDateList = [];
  const agencyNumber = agentDetails?.agentNumber;
  policyConfig.agencyNumber = (agencyNumber);
  productConfig.isoCountryCode = isoCountryCode;
  const quoteStartDate = quoteStartDay;
  const [growthRateArray, setGrowthRateArray] = React.useState([]);
  let disabledPartialSurrender = false;
  useEffect(() => {
    if (!isEmpty(agencies)) {
      const matchedAgency = find(
        agencies,
        item => item.number === agencyNumber,
      );
      if (matchedAgency && matchedAgency.number === agencyNumber) {
        change('establishmentCharge', matchedAgency.defaultPercentage);
      }
    }
  }, [agencyNumber]);

  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '') {
      validateOfferCode({
        offerCode,
        productCode: productConfig?.productCode,
        productVersion: productConfig?.productVersion,
        currency,
        premium,
        premiumType: 'SinglePremium',
      });
    }

  }, []);
  useEffect(() => {
    if (!isEmpty(productConfig)) {
      let agencyArray = []
      if (productConfig?.establishmentChargeList?.agencies?.length > 0) {
        agencyArray = productConfig?.establishmentChargeList?.agencies?.filter(establishmentDetail => (establishmentDetail?.number === agencyNumber))
      }
      let maxRate = productConfig?.establishmentChargeList?.maxPercentage;
      let minRate = productConfig?.establishmentChargeList?.minPercentage ? productConfig?.establishmentChargeList?.maxPercentage : 0;
      let defaultRate = agencyArray?.length > 0 ? agencyArray[0].defaultPercentage : productConfig?.establishmentChargeList?.defaultPercentage;
      if (maxRate) {
        let array = []
        let i = minRate;
        do {
          let label = Number.isInteger(i) ? i + ".0%" : i + "%";
          let obj = { value: i, title: label }
          array.push(obj);
          i = i + 0.5;
        } while (i <= maxRate)
        setGrowthRateArray(array);
        if (productConfig?.establishmentCharge === ""){
          change('establishmentCharge', defaultRate);
        }
      }
    }
  }, [productConfig])
  if (policyConfig && productConfig) {
    let minAdhocWithdrawal = '';
    let maxPartialSurrender = productConfig?.maxPartialSurrender;
    policyConfig?.features?.withdrawalSize.map(withdrawal => {
      if (withdrawal.currency === currency) {
        minAdhocWithdrawal = withdrawal.minimumPartialWithdrawalAmount;
      }
    });
    if (maxPartialSurrender < minAdhocWithdrawal) {
      disabledPartialSurrender = true;
    } else {
      disabledPartialSurrender = false;
    }
  }

  while (minCharge <= maxCharge) {
    chargeOptions.push({
      title: `${numeral(minCharge).format('0.0')} %`,
      value: minCharge,
    });
    minCharge += 0.5;
  }

  if (startDateList.length == 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  const minStartDate = moment(startDate)
    .date(15)
    .add(1, 'months')
    .toDate();

  const maxStartDate = moment(startDate)
    .add(100, 'years')
    .toDate();

  let minPremiumPaymentTerm = 5;
  const maxPremiumPaymentTerm = 30;

  while (minPremiumPaymentTerm <= maxPremiumPaymentTerm) {
    const myObj = {
      title: `${minPremiumPaymentTerm}`,
      value: minPremiumPaymentTerm,
    };
    policyTermList.push(myObj);
    minPremiumPaymentTerm++;
  }
  policyTermList.push({ title: 'Single premium', value: 'SinglePremium' });

  productConfig?.features?.benefitCurrencyMap?.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  if (isWap === true) {

    if (isRegularWithdrawal === true) {
      change(`regularWithdrawal.selected`, true)
      productConfig.regularWithdrawal = regularWithdrawal;
    } else {
      change(`regularWithdrawal.selected`, false)
    }

    const singlePremiumFrquencyList = policyConfig?.features?.premiumPaymentFrequencyType?.filter(element => {
      if (element?.name === 'Single') {
        return element?.periods
      }
    })
    const withdrawalList = policyConfig?.features?.premiumPaymentFrequencyType?.filter(element => {
      if (element?.name === 'Regular') {
        return element?.periods
      }
    })

    if (withdrawalList?.length > 0) {
      withdrawalList[0]?.periods.map(frequency => {
        paymentFrequencyOption.push({ value: frequency, title: frequency });
      })
    }

  } else {
    productConfig?.features?.premiumPaymentFrequencyType[0]?.periods?.map(frequency => {
      paymentFrequencyOption.push({ value: frequency, title: frequency });
    });
  }

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';
  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit} style={{ marginTop: 26 }}>
          <Grid container spacing={3} style={{ maxWidth: 660, margin: 0 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              {(isWap === true) ?
                <Field
                  // shrink
                  name="HarvestPolicyStartDate"
                  style={{ width: 260 }}
                  component={FormTextField}
                  value={HarvestPolicyStartDate}
                  disabled
                  label=< FormattedMessage {...messages?.policyStartDate} />
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  placeholder={HarvestPolicyStartDate}
                />
                : <Field
                  // shrink
                  name="startDate"
                  style={{ width: 260 }}
                  component={FormSelect}
                  options={startDateList}
                  label={isWap === true ? <FormattedMessage {...messages?.policyStartDate} /> : <FormattedMessage {...messages?.assumedStartDate} />}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  placeholder={intl.formatMessage(messages?.select)}
                />}
            </Grid>
            {/* CURRRENCY */}

            {(isWap === true) ?
              <Grid item>
                <Field
                  name="quoteStartDate"
                  style={{ width: 260 }}
                  component={FormTextField}
                  value={quoteStartDate}
                  disabled
                  label=< FormattedMessage {...messages?.quoteStartDate} />
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  placeholder={quoteStartDate}
                />
              </Grid>
              :
              <Grid item>
                <Field
                  // shrink
                  style={{
                    width: 130,
                    color: changePlaceHolderColor(currency),
                  }}
                  name="currency"
                  fullWidth
                  component={FormSelect}
                  // options={currencyOption}
                  options={[
                    { value: 'Select', title: 'Select' },
                    ...currencyOption,
                  ]}
                  label={<FormattedMessage {...messages?.currency} />}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  placeholder={intl.formatMessage(messages?.select)}
                />
              </Grid>
            }


            {(isWap === true) ?
              <>
                <Grid item>
                  <Field
                    // shrink
                    name="policyValue"
                    style={{ width: 260 }}
                    component={FormTextField}
                    label={<FormattedMessage {...messages?.currentFundValue} />}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled
                    value={policyValue}
                    placeholder={intl.formatMessage(messages?.enterAmount)}
                  />
                </Grid>
                <Grid item>
                  <Field
                    // shrink
                    name="fiaCharge"
                    style={{ width: 260 }}
                    component={FormTextField}
                    label={<FormattedMessage {...messages?.fiaText} />}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled
                    placeholder={intl.formatMessage(messages?.fiaText)}
                  />
                </Grid>
              </>
              :

              <Grid item sm={12}>
                {/* PREMIUM AMOUNT */}
                <Field
                  // shrink
                  name="premium"
                  style={{ width: 172 }}
                  component={FormNumberField}
                  label={<FormattedMessage {...messages?.premiumAmount} />}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  placeholder={intl.formatMessage(messages?.enterAmount)}
                />
              </Grid>
            }
          </Grid>

          <Divider className={classes.hr} />

          <Grid container spacing={3} style={{ maxWidth: 660, margin: 0 }}>
            {/* Assumed growth rate */}
            {(isWap !== true) &&
              <Grid item xs={4} md={4}>
                <Field
                  // shrink
                  style={{ width: 172 }}
                  name="growthRate"
                  fullWidth
                  component={FormNumberField}
                  label={<FormattedMessage {...messages?.assumedGrowthRate} />}
                  type="number"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  placeholder={intl.formatMessage(messages?.growthRate)}
                />
              </Grid>
            }
            {(isWap === true) &&
              <>
                <Grid item>
                  <Field
                    // shrink
                    style={{
                      width: 260,
                      color: changePlaceHolderColor(currency),
                    }}
                    name="currency"
                    fullWidth
                    component={FormSelect}
                    // options={currencyOption}
                    options={[
                      { value: 'Select', title: 'Select' },
                      { value: currency, title: currency },
                      ...currencyOption,
                    ]}
                    value={currency}
                    label={<FormattedMessage {...messages?.currency} />}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    placeholder={intl.formatMessage(messages?.select)}
                    disabled
                  />

                </Grid>
                <Grid item>

                  <Field
                    // shrink
                    style={{ width: 260 }}
                    name="premium"
                    fullWidth
                    component={FormNumberField}
                    label={<FormattedMessage {...messages?.newSinglePremiumAmount} />}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    placeholder={intl.formatMessage(messages?.newSinglePremiumAmount)}
                    onChange={(e, value) => {

                      change('premium', Number(value))

                    }}
                  />
                </Grid>
                <Grid item>
                  <Field
                    // shrink
                    style={{
                      width: 260,
                      color: changePlaceHolderColor(currency),
                    }}
                    name="establishmentCharge"
                    fullWidth
                    component={FormSelect}
                    // options={currencyOption}
                    options={growthRateArray}

                    // options={currencyOption}
                    label={<FormattedMessage {...messages?.establishmentCharge} />}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    placeholder={intl.formatMessage(messages?.select)}
                  />
                </Grid>
                <Grid item>

                  <Field
                    // shrink
                    style={{ width: 260 }}
                    name="growthRate"
                    fullWidth
                    component={FormNumberField}
                    label={<FormattedMessage {...messages?.assumedGrowthRatePercentage} />}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    placeholder='0.0%'
                  />
                </Grid>
              </>
            }
          </Grid>

          <Grid>
            <FieldArray
              name="coverages"
              component={renderBenefit}
              rerenderOnEveryChange
              lifeAssured={lifeAssured}
              options={options}
              change={change}
              startDate={startDate}
              intl={intl}
            />
          </Grid>

          <Divider className={classes.hr} />
          <Grid
            item
            xs={6}
            spacing={3}
            style={{ maxWidth: 660, margin: '40px 0 40px 10px' }}
          >
            <Field
              name="offerCode"
              fullWidth6
              style={{
                width: 355,
              }}
              label={<FormattedMessage {...messages?.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                  offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              placeholder={intl.formatMessage(messages?.pleaseEnter)}
            />
          </Grid>

          {/* <Divider className={classes.hr} /> */}

          {/* ===== Additional Single Premium ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                {(isWap === true) ? <FormattedMessage {...messages?.additionalSinglePremiumTitle} /> : <FormattedMessage {...messages?.additionalSinglePremium} />}
              </Typography>

              <Field
                name="isAdditionalSinglePremium"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isAdditionalSinglePremium}>
              <Divider className={classes.lightHr} />
              <FormSection name="additionalSinglePremium">
                <SinglePremium
                  formValues={additionalSinglePremium}
                  isEducationWithdrawal={false}
                  frequencyList={paymentFrequencyOption}
                  isWap={isWap}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>
          { /* changes */}
          {(isWap === true) &&
            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid
                  item
                  xs={6}
                  md={8}
                >
                  <Typography variant="body1">
                    <FormattedMessage {...messages?.partialWithdrawal} />
                  </Typography></Grid>
                <Grid
                  item
                  xs={6}
                  md={4}
                >
                  <Field
                    // shrink
                    style={{ width: 172 }}
                    name="adHocwithdrawl"
                    fullWidth
                    component={FormNumberField}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    disabled={disabledPartialSurrender}
                    placeholder={disabledPartialSurrender ? intl.formatMessage(messages?.notApplicable) : intl.formatMessage(messages?.partialWithdrawal)}
                  /></Grid>

              </Grid>
            </CardSectionForm>
          }
          {/* end changes */}
          {/* ===== Regular withdrawal ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages?.regularWithdrawalTitle} />
              </Typography>

              <Field
                name="isRegularWithdrawal"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isRegularWithdrawal}>
              <Divider className={classes.lightHr} />
              <FormSection name="regularWithdrawal">
                <Withdrawal
                  formValues={regularWithdrawal}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  isEducationWithdrawal={false}
                  frequencyList={paymentFrequencyOption}
                  change={change}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          {/* ===== FIA Charges ===== */}
          {(isWap !== true) &&
            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body1">
                  <FormattedMessage {...globalMessages?.additionalFetures} />
                </Typography>

                <Field
                  name="isAdditionalFeatures"
                  component={FormSwitch}
                  disabled={false}
                />
              </Grid>

              <Collapse in={isAdditionalFeatures}>
                <Divider className={classes.lightHr} />
                <Grid item xs={4}>
                  <Field
                    name="establishmentCharge"
                    component={FormSelect}
                    options={chargeOptions}
                    type="text"
                    fullWidth
                    label={<FormattedMessage {...messages?.establishmentCharge} />}
                  />
                </Grid>
                {!isEmpty(fiaData) && productConfig.features.haveFiaCharge ? (
                  <>
                    <Divider className={classes.hr} />
                    <Typography
                      // variant="h4"
                      style={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                      }}
                    >
                      <FormattedMessage {...globalMessages?.fiaText} />
                    </Typography>

                    <Field
                      name="fiaCharge"
                      type="radio"
                      component={FormRadioGroup}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControlLabel
                            classes={{ label: classes.labelCheckbox }}
                            control={<CustomRadio checked={fiaCharge == 0} />}
                            value={fiaData?.minFIACharge}
                            label={<FormattedMessage {...globalMessages?.nil} />}
                            labelPlacement="left"
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container justify="space-between">
                            <FormControlLabel
                              classes={{ label: classes.labelCheckbox }}
                              control={<CustomRadio checked={fiaCharge != 0} />}
                              value={fiaData?.maxFIAChange}
                              label={
                                <FormattedMessage
                                  {...globalMessages?.annualPercentage}
                                />
                              }
                              labelPlacement="end"
                            />

                            <TextField
                              value={`${fiaData?.maxFIAChange} %`}
                              variant="outlined"
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Field>
                  </>
                ) : null}
              </Collapse>
            </CardSectionForm>
          }
        </form >
      )
      }
    </>
  );
}

CoverageForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  wapConfig: PropTypes.object,
  regularWithdrawal: PropTypes.object,
  additionalFeatures: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  startDate: PropTypes.string,
  fiaCharge: PropTypes.number,
  term: PropTypes.string,
  offerCode: PropTypes.string,
  coverages: PropTypes.array,
  fiaData: PropTypes.object,
  isRegularWithdrawal: PropTypes.bool,
  isAdditionalSinglePremium: PropTypes.bool,
  additionalSinglePremium: PropTypes.object,
  isAdditionalFeatures: PropTypes.bool,
  agentDetails: PropTypes.object,
};

const selector = formValueSelector('coverageForm');
const mapStateToProps = createStructuredSelector({});

const withFormSelector = connect(state => {
  const startDate = selector(state, 'startDate');
  const fiaCharge = selector(state, 'fiaCharge');
  const regularWithdrawal = selector(state, 'regularWithdrawal');
  const currency = selector(state, 'currency');
  const offerCode = selector(state, 'offerCode');
  const premium = selector(state, 'premium');
  const additionalSinglePremium = selector(state, 'additionalSinglePremium');
  const additionalFeatures = selector(state, 'additionalFeatures');
  const term = selector(state, 'term');
  const isAdditionalSinglePremium = selector(
    state,
    'isAdditionalSinglePremium',
  );
  const isAdditionalFeatures = selector(state, 'isAdditionalFeatures');
  const isRegularWithdrawal = selector(state, 'isRegularWithdrawal');

  return {
    startDate,
    fiaCharge,
    term,
    regularWithdrawal: regularWithdrawal?.toJS() || {},
    isRegularWithdrawal,
    isAdditionalSinglePremium,
    additionalSinglePremium,
    additionalFeatures,
    isAdditionalFeatures,
    currency,
    offerCode,
    premium,
  };
});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: ['offerCode', 'currency', 'premium'],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import {
  Form,
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Grid, Typography, Collapse, Box } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import NoticeBox from 'components/NoticeBox';
import { makeSelectOption } from 'containers/App/selectors';
import FormCheck from 'components/FormCheck';
import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import { isEmpty, isEqual } from 'lodash/lang';
import { convertIfImmutable, partyFilter } from 'helpers/lang';
import { newParty, newConditionalAssignee } from 'helpers/placeholder';
// eslint-disable-next-line import/no-duplicates
import { makeSelectFinancialInstituteOptions } from 'containers/App/selectors';
import { productCodeCheck } from 'helpers/constants';
import ConditionalAssignee from './ConditionalAssignee';
import BeneficiaryDetails from './BeneficiaryDetails';
import validate from './validate';
import messages from '../../../messages';

const useStyle = makeStyles(theme => ({
  titleName: {
    // color: blue[800],
    color: theme.palette.primary.subMain,
    marginBottom: theme.spacing(2),
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  containerWrapper: {
    maxWidth: '660px',
    marginTop: theme.spacing(4),
  },
  beneficiariesContainer: {
    marginTop: '2rem',
  },
  error: {
    color: red[800],
    fontWeight: 600,
  },
  whoPribeneficiearyCheckbox: {
    marginBottom: '2rem',
  },
}));
function Beneficiaries({
  isWAPAndSCBAgency,
  policyOwners,
  formValues,
  handleSubmit,
  initialValues,
  change,
  onSubmit,
  formErrors,
  options,
  financialInstituteOptions,
  canSubmit,
  applicationDetails,
}) {
  const classes = useStyle();
  const parsedInitialValues = convertIfImmutable(initialValues);
  const filteredPolicyOwner = partyFilter(
    parsedInitialValues.parties,
    {
      name: 'isPolicyOwner',
      condition: true,
    },
    {
      name: 'isLifeInsured',
      condition: true,
    },
  );

  useEffect(() => {
    if (parsedInitialValues.isShariaBeneficiary) {
      change('isShariaBeneficiary', true);
      change('isBenefit', false);
      change('otherPrimaryBeneficiaries', false);
      change('otherContingentBeneficiaries', false);
    } else if (parsedInitialValues.isBenefit) {
      change('isShariaBeneficiary', false);
      change('otherPrimaryBeneficiaries', false);
    } else if (
      parsedInitialValues.primaryBeneficiaries.length > 0 &&
      parsedInitialValues.primaryBeneficiaries[0].firstName !== ''
    ) {
      change('otherPrimaryBeneficiaries', true);
    } else if (parsedInitialValues.isBenefit) {
      change('isBenefit', true);
      change('isShariaBeneficiary', false);
      change('otherPrimaryBeneficiaries', false);
    } else {
      change('isShariaBeneficiary', false);
    }

    const timedFunc = setTimeout(() => {
      if (
        parsedInitialValues.contingentBeneficiaries.length > 0 &&
        parsedInitialValues.contingentBeneficiaries[0].firstName !== ''
      ) {
        change('otherContingentBeneficiaries', true);
      }
    }, 200);

    return () => {
      clearTimeout(timedFunc);
    };
  }, []);

  useEffect(() => {
    // disable all if shariaBeneficiary is checked
    if (!isEmpty(formValues)) {
      if (formValues.isShariaBeneficiary) {
        change('isBenefit', false);
        change('otherPrimaryBeneficiaries', false);
      } else if (!formValues.otherPrimaryBeneficiaries) {
        if (!formValues.isBenefit) {
          change('otherContingentBeneficiaries', false);
        }
      } else if (formValues.isBenefit) {
        change('otherPrimaryBeneficiaries', false);
      }
    }
  });

  const checkSubmit = () => {
    let flag = true;
    const {
      isShariaBeneficiary,
      otherPrimaryBeneficiaries,
      primaryBeneficiaries,
      otherContingentBeneficiaries,
      contingentBeneficiaries,
      appointConditionalAssignee,
      additionalDetails,
      isBenefit,
    } = formValues;
    if (isShariaBeneficiary || isBenefit) {
      flag = true;
    }
    if (isBenefit) {
      flag = true;
    }
    if (otherPrimaryBeneficiaries) {
      flag = !primaryBeneficiaries.every(item => {
        return (
          isEmpty(item['firstName']) ||
          isEmpty(item['lastName']) ||
          isEmpty(item['relationshipToLifeInsured']) ||
          isEmpty(item['dateOfBirth']) ||
          isEmpty(item['email']) ||
          isEmpty(item['sharePercentage'])
        );
      });
    }
    if (otherContingentBeneficiaries) {
      flag = !contingentBeneficiaries.every(item => {
        return (
          isEmpty(item['firstName']) ||
          isEmpty(item['lastName']) ||
          isEmpty(item['relationshipToLifeInsured']) ||
          isEmpty(item['dateOfBirth']) ||
          isEmpty(item['sharePercentage'])
        );
      });
    }
    if (appointConditionalAssignee) {
      flag = !additionalDetails.conditionalAssignee.every(item => {
        return (
          isEmpty(item['institution']) || isEmpty(item['currency'])
          // isEmpty(item['email'])
        );
      });
    }
    return flag;
  };

  useEffect(() => {
    canSubmit(!checkSubmit());
  }, [formValues]);

  useEffect(() => {
    if (isWAPAndSCBAgency) {
      change('appointConditionalAssignee', true);
      policyOwners.forEach((_, index) => {
        change(`additionalDetails.appointSCBPolicyOwner${index + 1}`, true);
      });
    }
  }, [isWAPAndSCBAgency]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {applicationDetails?.isBolton && (
        <Grid item xs={12} md={8} lg={6}>
          <NoticeBox variant="info" mb={4}>
            <Typography variant="body2" color="inherit">
              <FormattedMessage
                {...messages.InfoBolton}
                values={{
                  productName: applicationDetails.productConfigs[0].name,
                }}
              />
            </Typography>
          </NoticeBox>
        </Grid>
      )}

      <Grid item xs={12} md={8} lg={6} className={classes.containerWrapper}>
        <Typography variant="body1" className={classes.error}>
          {formErrors.selectOnePrimaryBeneficiaries}
        </Typography>
        <Typography variant="body1" style={{ marginLeft: '2rem' }}>
          <FormattedMessage {...messages.whoPrimaryBeneficieary} />
        </Typography>
        <Box mt={2} ml={2.5} className={classes.whoPribeneficiearyCheckbox}>
          <Field
            name="isShariaBeneficiary"
            fullWidth
            component={FormCheck}
            label={<FormattedMessage {...messages.asPerSharia} />}
            type="checkbox"
            color="primary"
            margin="normal"
            variant="standard"
            disabled={formValues.isBenefit}
          />
        </Box>

        {filteredPolicyOwner.length > 1 && (
          <Box mb={4} mt={2} ml={2.5}>
            <Field
              name="isBenefit"
              fullWidth
              component={FormCheck}
              label={
                <FormattedMessage
                  {...messages.nominatedEachOther}
                  values={{
                    value1: `${filteredPolicyOwner[0].firstName} ${filteredPolicyOwner[0].lastName}`,
                    value2: `${filteredPolicyOwner[1].firstName} ${filteredPolicyOwner[1].lastName}`,
                  }}
                />
              }
              type="checkbox"
              color="primary"
              margin="normal"
              variant="standard"
              disabled={formValues.isShariaBeneficiary}
            />
          </Box>
        )}

        <CardSectionForm
          variant="outlined"
          style={{
            background:
              formValues.isShariaBeneficiary ||
              formValues.isInsured ||
              formValues.isBenefit
                ? // formValues.appointConditionalAssignee
                  '#F2F2F2'
                : 'white',
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Typography variant="body1">
              <FormattedMessage {...messages.assignOtherBeneficiary} />
            </Typography>

            <Field
              name="otherPrimaryBeneficiaries"
              component={FormSwitch}
              disabled={
                formValues.isShariaBeneficiary ||
                formValues.isInsured ||
                formValues.isBenefit
              }
            />
          </Grid>
          <Collapse
            in={
              formValues.isShariaBeneficiary || formValues.isBenefit
                ? false
                : formValues.otherPrimaryBeneficiaries
            }
          >
            <hr className={classes.hr} />
            <FormSection name="">
              <FieldArray
                name="primaryBeneficiaries"
                component={BeneficiaryDetails}
                options={options}
                formValues={formValues.primaryBeneficiaries}
                addLabel={
                  <FormattedMessage {...messages.addPrimaryBeneficiary} />
                }
                fieldName="primaryBeneficiaries"
                lifeInsuredParties={parsedInitialValues.lifeInsuredParties}
                // disabledPercentage={true}
                newPartyPlaceholder={{
                  ...newParty({
                    isPrimaryBeneficiary: true,
                    isBeneficiary: true,
                    isPolicyOwner: false,
                    isLifeInsured: false,
                  }),
                  selectedAddress: 'different',
                }}
                change={change}
                formErrors={formErrors}
              />
            </FormSection>
          </Collapse>
        </CardSectionForm>

        <CardSectionForm
          variant="outlined"
          style={{
            background:
              formValues.otherPrimaryBeneficiaries ||
              formValues.isBenefit ||
              formValues.isShariaBeneficiary
                ? 'white'
                : formValues.otherContingentBeneficiaries
                ? 'white'
                : '#F2F2F2',
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Typography variant="body1">
              <FormattedMessage {...messages.assignOtherBeneficiaryContigent} />
            </Typography>

            <Field
              name="otherContingentBeneficiaries"
              component={FormSwitch}
              disabled={
                formValues.isBenefit || formValues.otherPrimaryBeneficiaries
                  ? formValues.isShariaBeneficiary
                    ? true
                    : false
                  : !formValues.otherContingentBeneficiaries
              }
            />
          </Grid>
          <Collapse
            in={
              formValues.isBenefit || formValues.otherPrimaryBeneficiaries
                ? formValues.otherContingentBeneficiaries
                : false
            }
          >
            <hr className={classes.hr} />
            <FormSection name="">
              <FieldArray
                name="contingentBeneficiaries"
                component={BeneficiaryDetails}
                options={options}
                formValues={formValues.contingentBeneficiaries}
                addLabel={
                  <FormattedMessage {...messages.addContingentBeneficiary} />
                }
                fieldName={'contingentBeneficiaries'}
                lifeInsuredParties={parsedInitialValues.lifeInsuredParties}
                change={change}
                newPartyPlaceholder={{
                  ...newParty({
                    isPrimaryBeneficiary: false,
                    isBeneficiary: true,
                    isPolicyOwner: false,
                    isLifeInsured: false,
                  }),
                  selectedAddress: 'different',
                }}
                formErrors={formErrors}
              />
            </FormSection>
          </Collapse>
        </CardSectionForm>

        {(productCodeCheck(2, applicationDetails.productCode) ||
          isWAPAndSCBAgency) && (
          <>
            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body1">
                  <FormattedMessage {...messages.assigneeAppointed} />
                </Typography>

                <Field
                  name="appointConditionalAssignee"
                  component={FormSwitch}
                  disabled={isWAPAndSCBAgency}
                />
              </Grid>
              <Collapse in={formValues.appointConditionalAssignee}>
                <hr className={classes.hr} />
                <FormSection name="additionalDetails">
                  {isWAPAndSCBAgency ? (
                    <>
                      {policyOwners.map((po, index) => (
                        <Grid item>
                          <Typography
                            variant="h2"
                            className={classes.titleName}
                          >
                            <FormattedMessage
                              {...messages.policyOwner}
                              values={{
                                name: `${po.firstName} ${po.lastName}`,
                                number: index + 1,
                              }}
                            />
                          </Typography>
                          <Field
                            name={`appointSCBPolicyOwner${index + 1}`}
                            fullWidth
                            direction="start"
                            disabled={isWAPAndSCBAgency}
                            component={FormCheck}
                            label={
                              <FormattedMessage {...messages.appointSCB} />
                            }
                            type="text"
                          />
                          {index + 1 !== policyOwners.length && (
                            <hr style={{ margin: '16px 0' }} />
                          )}
                        </Grid>
                      ))}
                    </>
                  ) : (
                    <FieldArray
                      name="conditionalAssignee"
                      component={ConditionalAssignee}
                      applicationDetails={applicationDetails}
                      options={options}
                      financialInstituteOptions={financialInstituteOptions}
                      addLabel={<FormattedMessage {...messages.addAnother} />}
                      newConditionalAssigneePlaceholder={{
                        ...newConditionalAssignee,
                      }}
                    />
                  )}
                </FormSection>
              </Collapse>
            </CardSectionForm>
            {isWAPAndSCBAgency && (
              <Typography variant="body1" className={classes.error}>
                {formErrors?.additionalDetails?.conditionalAssignee}
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Form>
  );
}

Beneficiaries.propTypes = {};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
  financialInstituteOptions: makeSelectFinancialInstituteOptions(),
});

function mapDispatchToProps() {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  form: 'beneficiaries',
  validate,
});

const withFormSelector = connect(state => {
  const formValues = getFormValues('beneficiaries')(state);
  const formErrors = getFormSyncErrors('beneficiaries')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

export default compose(withConnect, withFormSelector, withForm)(Beneficiaries);

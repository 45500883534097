/*
 *
 * LoginPage actions
 *
 */

import { LOGIN_ACTION, SET_LOGIN_STATUS_ACTION } from './constants';

export function loginAction({ username, password }) {
  return {
    type: LOGIN_ACTION,
    payload: {
      username,
      password,
    },
  };
}

export function setLoginStatusAction(payload) {
  return {
    type: SET_LOGIN_STATUS_ACTION,
    payload
  };
}

export const dobFormat = 'YYYY-MM-DD';

export const DOB_LIST_FORMAT = 'DD MMM YYYY';

export const productCodeCheck = (productCodes, productCode) => {
  // just add new switch case if there's a specific product code condition that needs to be added
  // if there's a new product and there's no similar from the arrays below please add a new one . just follow the pattern

  //SIMILAR/DUPLICATE PRODUCTS

  // ITA
  const ITA = ['ITAS6', 'ITAB2', 'ITAS5', 'ITAS3'];

  //SACI
  const SACI = ['SACI1'];

  // IDTA
  const DTME = ['DTME2', 'DTAME'];

  // WAP
  const WAP = ['WAPL1', 'WAPL2'];

  // RSP
  const RSP = ['RSP01'];

  // ESP
  const ESP = ['ESP01'];

  // FUTU
  const FUTU = [
    'FUTU5',
    'FUTU2',
    'FUTU4',
    'SCFUT',
    'FUTU3',
    'CTFUT',
    'CTFU2',
    'CTFU3',
    'FUTUR',
  ];

  // SIM
  const SIM = ['SIMW2', 'SIMWE'];

  // GC
  const GC = ['MFS01', 'SAVP3', 'VISP2', 'CTVP2'];

  // VISTA
  const VISTA = ['VISP2', 'CTVP2'];

  // CUSTOM COMBINATION PRODUCTS

  // 1
  const productCodes1 = [...ITA, ...DTME, ...SACI];

  // 2
  const productCodes2 = [...ITA, ...DTME, ...FUTU];

  // 3 // INVESTMENT LINK PRODUCTS
  const productCodes3 = [...WAP, ...FUTU, ...SIM, ...RSP, ...GC, ...ESP];

  // 4
  const productCodes4 = [...WAP, ...SIM, ...RSP, ...GC, ...ESP];

  // 5
  const productCodes5 = [...SIM, ...GC, ...RSP, ...ESP];

  // 6
  const productCodes6 = [...WAP, ...SIM];

  // 7
  const productCodes7 = [
    ...ITA,
    ...DTME,
    ...SACI,
    ...WAP,
    ...FUTU,
    ...SIM,
    ...RSP,
    ...ESP,
    ...GC,
  ];
  const productCodes8 = [
    ...ESP
  ]

  // consist of similar products
  if (typeof productCodes === 'string') {
    switch (productCodes) {
      case 'ITA':
        return ITA.includes(productCode);
      case 'DTME':
        return DTME.includes(productCode);
      case 'SACI':
        return SACI.includes(productCode);
      case 'WAP':
        return WAP.includes(productCode);
      case 'RSP':
        return RSP.includes(productCode);
      case 'ESP':
        return ESP.includes(productCode);
      case 'FUTU':
        return FUTU.includes(productCode);
      case 'SIM':
        return SIM.includes(productCode);
      case 'GC':
        return GC.includes(productCode);
      case 'VISTA':
        return VISTA.includes(productCode);
      default:
        break;
    }
  } else if (typeof productCodes === 'number') {
    // custom product code combination
    switch (productCodes) {
      case 1:
        return productCodes1.includes(productCode);
      case 2:
        return productCodes2.includes(productCode);
      case 3: // INVESTMENT LINK PRODUCTS
        return productCodes3.includes(productCode);
      case 4:
        return productCodes4.includes(productCode);
      case 5:
        return productCodes5.includes(productCode);
      case 6:
        return productCodes6.includes(productCode);
      case 7:
        return productCodes7.includes(productCode);
      case 8:
        return productCodes8.includes(productCode);
      default:
        return false;
    }
  } else {
    return false;
  }
};

// export const nonEditableFields = {
//   po: [
//     'firstName',
//     'POFirstName',
//     'LIFirstName',
//     'lastName',
//     'dateOfBirth',
//     'countryOfBirth',
//     'primaryNationality',
//     'secondaryNationality',
//     'isUsResident',
//     'isUsTaxPayer',
//     'isTaxPurposes',
//     'city',
//     'relationshipToPolicyOwner',
//   ],
//   payor: [
//     'firstName',
//     'POFirstName',
//     'LIFirstName',
//     'lastName',
//     'dateOfBirth',
//     'countryOfBirth',
//     'primaryNationality',
//     'secondaryNationality',
//     'isUsResident',
//     'isUsTaxPayer',
//     'isTaxPurposes',
//     'city',
//     'relationshipToPolicyOwner',
//   ],
// };

export const isDisabledField = (disabledFields, formName, field = '') => {
  // field = name of the field that will be disabled
  // the fields are not disabled
  if (
    disabledFields === undefined ||
    disabledFields === null ||
    disabledFields[formName]?.length === 0
  ) {
    return false;
  }

  if (formName === 'shuftipro') {
    return disabledFields[formName];
  }

  return disabledFields[formName]?.includes(field);
};

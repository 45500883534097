import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../messages';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControlLabel,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { makeSelectApplicationDetails } from './../../../../../ApplicationPage/selectors';
import { makeSelectConvertCurrency } from '../../../../selectors';
import { getConvertCurrencyAction } from '../../../../actions';
import { productCodeCheck } from 'helpers/constants';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';

import { Field } from 'redux-form/immutable';

const useStyles = makeStyles(theme => ({
  table: {
    borderCollapse: 'unset',
    borderSpacing: '0px 3px',
  },
  textNote: {
    fontSize: 18,
    color: '#B6B8B4',
    lineHeight: 2,
  },
}));

const StyledTableCell = withStyles(theme => ({
  root: {
    border: 'none',
  },
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(checked, benefitName, amount) {
  return { checked, benefitName, amount };
}

function parseName(data) {
  if (data === 'CURRENT_ACCOUNT') {
    return 'Current account/Savings account';
  }
  if (data === 'CREDIT_CARD') {
    return 'Credit card';
  }
}

function add (a,b=0) {
  let firstNum = isNaN(a) ? 0 : a;
  let secondNum = isNaN(b) ? 0 : b;
  return firstNum + secondNum
}

function PaymentDetail(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const {
    paymentDetail,
    applicationDetail,
    getConvertCurrency,
    convertCurrency,
    bankName,
    paymentType,
    isInforce,
    formValues,
    initReset,
    isExistingPaymentToggle,
  } = props;

  const application = applicationDetail.toJS();

  const isBolton = application?.isBolton;

  const productConfigs = isBolton
    ? application?.productConfigs[0]
    : application?.productConfigs;

  const getPaymentFrequency = (frequency,singlePremiumTerm) => {
    switch (frequency) {
      case 'Monthly':
        return 'p/month';

      case 'Quarterly':
        return 'p/quarter';

      case 'Half-Yearly':
        return 'p/half-year';

      case 'Yearly':
        return 'p/year';

      case 'Single':
        if (singlePremiumTerm > 0){
          return 'per year';
        } else {
          return 'Single time';
        }

      default:
        return '';
    }
  };

  const getPaymentMethodString = method => {
    switch (method) {
      case 'UAE_DIRECT_DEBIT':
        return 'Direct Debit';
      case 'CREDIT_CARD':
        return 'Credit Card';
      case 'CHEQUE':
        return 'Cheque';
      case 'BANK_TRANSFER':
        return 'Bank Instruction Letter';
      case 'STANDING_ORDER':
        return 'Standing Order';
      case 'TELEGRAPHIC_TRANSFER':
        return 'Telegraphic Transfer';
      case 'POSTDATED_CHEQUE':
        return 'Postdated Cheque';
      case 'MIDDLE_EAST_BANK':
        return 'Middle East Bank';
      case 'BANK_DRAFT':
        return 'Bank Draft';
      case 'RENEWAL_NOTICE':
        return 'Renewal Notice';
      default:
        return method;
    }
  };

  let current = productConfigs?.singlePremiumTerm >= 0 && productConfigs?.regularPremiumSliceLength === 0
                  ? productConfigs?.premium === 0
                    ? application?.premium === 0
                      ? productConfigs?.singlePremium
                      : application?.premium?.toFixed(2)
                    : productConfigs?.premium?.toFixed(2)
                  : application?.premium?.toFixed(2);
  // const current = Number(convertCurrency).toFixed(2);
  // const currentChange = Number(changeCurrency).toFixed(4);
  // const dataCurrent = `${application.productConfigs.currency} ${current} ${getPaymentFrequency(application.premiumFrequency)}`;
  let dataCurrent = (productCodeCheck('WAP', application.productCode) || productCodeCheck('IWA', application.productCode)) // for new product IWA
    ? `${productConfigs?.currency} ${current}`
    : `${productConfigs?.currency} ${current} ${getPaymentFrequency(
        application.premiumFrequency,productConfigs?.singlePremiumTerm
      )}`;
  if(isInforce && (productCodeCheck(6, application.productCode))){ //For Inforce WAP and SW
    let prem = productConfigs?.isAdditionalSinglePremium ? `${add(productConfigs.premium, productConfigs.additionalSinglePremium)}` : productConfigs?.premium;
    current = Number(prem).toFixed(2)
    dataCurrent = `${productConfigs?.currency} ${current}`
  }
  
  useEffect(() => {
    let data = {
      scrCurrency: productConfigs?.currency,
      destCurrency: 'AED',
      amount: application.premium,
    };
    if(isInforce && (productCodeCheck(6, application.productCode))){ //For Inforce WAP and SW
      data.amount = current; 
    }
    if (isInforce && paymentType === 'CREDIT_CARD') {
      setRows([
        createData(
          true,
          `${get(
            paymentDetail,
            'card_holder_name',
            get(paymentDetail, 'accountHolderName', ''),
          )}`,
          `${dataCurrent}`,
        ),
        createData(
          true,
          `Credit card`,
          `${get(
            paymentDetail,
            'card_number',
            get(paymentDetail, 'cardNumber', ''),
          )}`,
        ),
      ]);
    } else {
      setRows([
        createData(
          true,
          `${paymentDetail?.accountHolderName || ''}`,
          `${dataCurrent}`,
        ),
        createData(
          true,
          // `${bankName}`,
          `${
            !isEmpty(bankName)
              ? bankName
              : getPaymentMethodString(application.paymentMethod)
          }`,
          `${paymentDetail?.cardNumber || ''}`,
        ),
      ]);
    }
    if (!!paymentDetail.directDebitSource) {
      setRows([
        createData(
          true,
          `${paymentDetail?.accountHolderName || ''}`,
          `${dataCurrent}`,
        ),
        createData(
          true,
          `${parseName(paymentDetail?.directDebitSource || '')}`,
          `${paymentDetail?.cardNumber || ''}`,
        ),
        createData(true, `${bankName}`, `${paymentDetail?.cardNumber}`),
      ]);
    }
    getConvertCurrency(data);
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{ color: theme.palette.primary.dark }}
          >
            <FormattedMessage {...messages.paymentDetails} />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table className={classes.table} aria-label="customized table">
              <TableBody>
                {rows.map((row, index) => (
                  <StyledTableRow key={`${row.benefitName}-${row.index}`}>
                    <StyledTableCell component="th" scope="row">
                      <Typography variant="body1">
                        <InfoIcon
                          fontSize="small"
                          style={{ color: '#B6B8B4', marginRight: '1rem' }}
                        />
                        {` ${row.benefitName}`}
                      </Typography>
                    </StyledTableCell>

                    <StyledTableCell align="left" variant="body">
                      <Typography variant="body1">{row.amount}</Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {isExistingPaymentToggle} 

        {/* {isInforce && !initReset ? (
          console.log('Admin log checkReset : ', checkReset())
          <Grid item xs={12}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Typography variant="body2" style={{ marginRight: '3rem' }}>
                {<FormattedMessage {...messages.preferPaymentMethod} />}
              </Typography>

              <Field
                name={`resetDetails`}
                type="radio"
                component={FormRadioGroup}
                normalize={value => value === 'true'}
                value={
                  formValues?.resetDetails === null
                    ? false
                    : formValues?.resetDetails
                }
                onBlur={e => {
                  e.preventDefault();
                }}
              >
                <FormControlLabel
                  control={<CustomRadio />}
                  value={true}
                  label="Yes"
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={<CustomRadio />}
                  value={false}
                  label="No"
                  labelPlacement="end"
                />
              </Field>
            </div>
          </Grid>
        ) : (
          ''
        )} */}
      </Grid>
    </div>
  );
}

PaymentDetail.propTypes = {
  paymentDetail: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  applicationDetail: makeSelectApplicationDetails(),
  convertCurrency: makeSelectConvertCurrency(),
});

function mapDispatchToProps(dispatch) {
  return {
    getConvertCurrency: data => dispatch(getConvertCurrencyAction(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PaymentDetail);

/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { WrappedFieldArrayProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Cancel, CheckCircle } from '@material-ui/icons';
import FormSlider from 'components/FormSlider';
import {
  getMinValue,
  getMaxValue,
} from 'containers/ItaIllustrationPage/helper';
import FormBooleanOption from 'components/FormBooleanOption';
import { formatMonetaryValue } from 'helpers/math';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormNumberField from 'components/FormNumberField';
import NumberField from 'components/NumberField';
import messages from '../../messages';

const Benefits = ({
  coverages,
  itaConfig,
  currency,
  isInsuredTwo,
  changeEditData,
  calculateEditData,
  change,
  inactive,
  isCoveragesError,
  editSavingPremiumAmount,
  setEditSavingPremiumAmount,
  formValues,
}) => {
  const theme = useTheme();

  return (
    <Box
      bgcolor={inactive ? '#f2f2f2' : undefined}
      pl={3}
      pr={1}
      py={2}
      borderBottom={`1px solid ${theme.palette.divider}`}
    >
      <Grid container alignItems="center" spacing={1} wrap="nowrap">
        <Grid item>
          <Grid container alignItems="center" justify="center">
            <CheckCircle style={{ color: theme.palette.success.main }} />
          </Grid>
        </Grid>

        <Grid item xs>
          <Typography
            variant="body1"
            style={{
              fontWeight: 700,
            }}
          >
            <FormattedMessage {...messages.savingsPremiumAmount} />
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Grid
            container
            alignItems="center"
            justify="space-between"
            wrap="nowrap"
            style={{ marginBottom: '1rem' }}
          >
            <Grid item xs={8}>
              {editSavingPremiumAmount ? (
                <Field
                  shrink
                  name="premium"
                  fullWidth
                  component={FormNumberField}
                  //   onChange={newVal =>
                  //     change(`${benefit}.valueInsuredTwo`, newVal)
                  //   }
                  color="inherit"
                  variant="standard"
                />
              ) : (
                <Typography
                  variant="body1"
                  color="textPrimary"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {formatMonetaryValue(formValues?.productConfig?.premium)}
                </Typography>
              )}
            </Grid>
            {!editSavingPremiumAmount ? (
              <Grid item xs={4}>
                <IconButton
                  // disabled={!coverages[index].selected || isCoveragesError}
                  onClick={() => setEditSavingPremiumAmount(true)}
                >
                  <img src={IconPencilBlue} alt="" />
                </IconButton>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Collapse in={editSavingPremiumAmount}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={7}>
            {/* {editData.editInsured === 1 ? (
                coverages[index].type === '1' ? (
                  <Field
                    name={`${benefit}.valueInsuredOne`}
                    component={FormSlider}
                    id={coverages[index].id}
                    min={getMinValue(
                      coverages[index].id,
                      itaConfig.features,
                      currency,
                    )}
                    max={getMaxValue(
                      coverages[index].id,
                      itaConfig.features,
                      currency,
                    )}
                    diff={50000}
                    onChange={(e, newVal) =>
                      change(`${benefit}.valueInsuredTwo`, newVal)
                    }
                    isPrimary={false}
                    type="text"
                  />
                ) : (
                  <Field
                    name={`${benefit}.valueInsuredOne`}
                    component={FormBooleanOption}
                    type="text"
                    margin="normal"
                    //onChange={(e, newVal) => change(`${benefit}.valueInsuredTwo`, newVal)}
                    shrink
                    fullWidth
                  />
                )
              ) : coverages[index].type === '1' ? (
                <Field
                  name={`${benefit}.valueInsuredTwo`}
                  component={FormSlider}
                  id={coverages[index].id}
                  min={getMinValue(
                    coverages[index].id,
                    itaConfig.features,
                    currency,
                  )}
                  max={getMaxValue(
                    coverages[index].id,
                    itaConfig.features,
                    currency,
                  )}
                  diff={50000}
                  isPrimary={false}
                  disabled={true}
                  type="text"
                />
              ) : (
                <Field
                  name={`${benefit}.valueInsuredTwo`}
                  component={FormBooleanOption}
                  type="text"
                  margin="normal"
                  disabled={false}
                  shrink
                  fullWidth
                />
              )} */}
          </Grid>

          <Grid item xs={3}>
            <Button
              size="large"
              variant="contained"
              disabled={isCoveragesError}
              onClick={() => {
                calculateEditData();
                setEditSavingPremiumAmount(false);
              }}
              style={{ marginLeft: '.5rem' }}
            >
              <FormattedMessage {...messages.update} />
            </Button>
          </Grid>
        </Grid>
      </Collapse>
    </Box>
  );
};

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  theme: PropTypes.object,
  options: PropTypes.object,
  itaConfig: PropTypes.object,
  currency: PropTypes.string,
  coverages: PropTypes.array.isRequired,
  editData: PropTypes.object,
  changeEditData: PropTypes.func,
  calculateEditData: PropTypes.func,
  isInsuredTwo: PropTypes.bool,
  change: PropTypes.func,
  setEditSavingPremiumAmount: PropTypes.func,
  editSavingPremiumAmount: PropTypes.bool,
  formValues: PropTypes.object,
};

export default Benefits;

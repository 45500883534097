import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { map, filter } from 'lodash/collection';
import { isNumber, isEmpty, toString } from 'lodash/lang';
import { keys, get } from 'lodash/object';
import messages from '../../messages';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { formatMonetaryValue } from 'helpers/math';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
//COLOR
import { green } from '@material-ui/core/colors';
import { getPaymentFrequency } from 'containers/ApplicationPage/helper';
import { productCodeCheck } from 'helpers/constants';
const useStyles = makeStyles(theme => ({}));

const StyledTableCell = withStyles(theme => ({
  root: {},
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);
function createData(checked, benefitName, amount) {
  return { checked, benefitName, amount };
}
function PolicySummary(props) {
  const theme = useTheme();
  const classes = useStyles();
  const intl = useIntl();
  const { applicationDetails } = props;
  const isBolton = applicationDetails.isBolton;
  const isInforce = applicationDetails.isInforce;
  const productConfigs = isBolton
    ? applicationDetails.productConfigs[0]
    : applicationDetails.productConfigs;
  const policyCoverTerm = productConfigs?.policyCoverTerm;

  var aspFrequencyLabel = 'p/month';

  switch (productConfigs?.paymentFrequency) {
    case 'Monthly':
      aspFrequencyLabel = 'p/month';
      break;
    case 'Quarterly':
      aspFrequencyLabel = 'p/quarter';
      break;
    case 'Half-Yearly':
      aspFrequencyLabel = 'p/half-year';
      break;
    case 'Yearly':
      aspFrequencyLabel = 'p/year';
      break;
    default:
      if (productConfigs?.singlePremiumTerm >= 0) {
        aspFrequencyLabel = 'per year'
      } else {
        aspFrequencyLabel = 'p/month';
      }
      break;
  }

  const rows = [
    // ITA
    ((applicationDetails.premium || productConfigs?.premium) && (productCodeCheck(6, productConfigs?.productCode) && productConfigs?.isInforce === true)) ?
      createData(
        false,
        'Premium amount',
        `${productConfigs?.currency} ${formatMonetaryValue(
          applicationDetails?.productConfigs?.premium,
          true,
        )}`
      ) :
      (applicationDetails.premium || productConfigs?.premium) &&
      createData(
        false,
        'Premium amount',
        (!productCodeCheck('WAP', productConfigs?.productCode)
          && !productCodeCheck('IWA', productConfigs?.productCode)) // for new product IWA
          ? applicationDetails?.productConfigs?.singlePremiumTerm >= 0 && applicationDetails?.productConfigs?.regularPremiumSliceLength === 0
            ? `${productConfigs?.currency} ${formatMonetaryValue(
              applicationDetails?.productConfigs?.premium,
              true,
            )}
          ${getPaymentFrequency(productConfigs?.paymentFrequency, productConfigs?.singlePremiumTerm)}
          `
            : `${productConfigs?.currency} ${formatMonetaryValue(
              applicationDetails.premium,
              true,
            )}
        ${getPaymentFrequency(productConfigs?.paymentFrequency, productConfigs?.singlePremiumTerm)}
        `
          : `${productConfigs?.currency} ${formatMonetaryValue(
            applicationDetails.premium,
            true,
          )}`,
      ),
    productConfigs?.lifeBasis
      ? createData(
        false,
        intl.formatMessage(messages.policyBasis),
        productConfigs.lifeBasis === 'JLLS' ||
          productConfigs.lifeBasis === 'JLLD'
          ? 'Joint life last death'
          : productConfigs.lifeBasis === 'SL'
            ? 'Single life'
            : productConfigs.lifeBasis === 'JLFD'
              ? 'Joint life first death'
              : productConfigs.lifeBasis === 'JLBD'
                ? 'Joint life both death'
                : '',
      )
      : null,
    productConfigs?.term &&
    (productCodeCheck(3, productConfigs.productCode)
      ? null
      : createData(
        false,
        intl.formatMessage(messages.policyTermTitle),
        `${productConfigs?.term} Years`,
      )),
    isNumber(productConfigs?.interestRate) &&
    createData(
      false,
      intl.formatMessage(messages.interestRateTitle),
      `${numeral(productConfigs?.interestRate * 100).format('0')} %`,
    ),

    // FUTURA
    policyCoverTerm && productCodeCheck(3, applicationDetails.productCode)
      ? policyCoverTerm
        ? createData(
          false,
          intl.formatMessage(messages.coverageTerm),
          policyCoverTerm === 'WholeOfLife'
            ? 'Whole of life'
            : policyCoverTerm === 'CustomPremium'
              ? `${applicationDetails.pricingData.response.premium} Years`
              : productConfigs?.singlePremiumTerm >= 0 && productConfigs?.regularPremiumSliceLength === 0
                ? `${productConfigs?.policyTerm} Years`
                : `${policyCoverTerm} Years`,
        )
        : null
      : null,
    productCodeCheck(3, applicationDetails.productCode) && productConfigs?.term
      ? createData(
        false,
        intl.formatMessage(messages.premiumPaymentTerm),
        productConfigs?.term === 'SinglePremium'
          ? 'Single premium'
          : productConfigs?.term === 'WholeOfLife'
            ? 'Whole of life'
            : `${productConfigs?.term} Years`,
      )
      : null,
    productConfigs?.growthRate &&
    createData(
      false,
      intl.formatMessage(messages.assumedGrowthRate),
      `${productConfigs?.growthRate} %`,
    ),

    productCodeCheck(3, applicationDetails.productCode)
      ? productConfigs?.fiaCharge !== 0
        ? createData(
          false,
          intl.formatMessage(messages.advisorFeeLabel),
          `${productConfigs?.fiaCharge} %`,
        )
        : null
      : null,

    productConfigs?.additionalSinglePremium
      ? createData(
        false,
        intl.formatMessage(messages.additionalSinglePremium),
        // `${productConfigs?.additionalSinglePremium}`,
        `${productConfigs?.currency} ${formatMonetaryValue(
          productConfigs.additionalSinglePremium,
          true,
        )} ${applicationDetails.productConfigs.singlePremiumTerm >= 0 && applicationDetails.productConfigs.regularPremiumSliceLength === 0 ? '' : aspFrequencyLabel}
          `,
      )
      : null,
  ];
  if (rows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.premiumSummary} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {map(rows, (row, index) => {
                    if (row) {
                      return (
                        <StyledTableRow key={`${row.benefitName}-${row.index}`}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{ width: '70%' }}
                          >
                            <Typography variant="h4">
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: 'gray',
                                  marginRight: '1rem',
                                }}
                              />
                              {` ${row.benefitName}`}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '30%' }}>
                            <Typography variant="h4">
                              {productConfigs && row.amount}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    } else {
                      return '';
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  } else return '';
}
PolicySummary.propTypes = {};
export default PolicySummary;

/**
 *
 * Header
 *
 */

import React from 'react';
import LoadingDialog from 'components/LoadingDialog';

function PopupLoading() {
  return <LoadingDialog isLoading />;
}

PopupLoading.propTypes = {};

export default PopupLoading;

/*
 *
 * VistaIllustrationPage actions
 *
 */

import {
  CHANGE_LIFE_ASSURED_ACTION,
  SET_ERROR_ACTION,
  SET_PRODUCT_CONFIG_ACTION,
  ADD_POLICY_OWNER_ACTION,
  CREATE_QUOTE_ACTION,
  SET_QUOTE_ACTION,
  CALCULATE_QUOTE_ACTION,
  SET_CALCULATION_ACTION,
  SET_COVERAGES_LIST_ACTION,
  GET_QUOTE_ACTION,
  SET_QUOTE_DETAILS_ACTION,
  UPDATE_STATUS_ACTION,
  RESET_ACTION,
  EMAIL_PDF_ACTION,
  CREATE_APPLICATION_ACTION,
  SET_APPLICATION_ID_ACTION,
  GET_GC_CONFIG_ACTION,
  SET_GC_CONFIG_ACTION,
  SET_PTV_VERSION_ACTION,
  GET_FIA_ACTION,
  SET_FIA_ACTION,
  SET_BI_TABLE_VALUES_ACTION,
  CLONE_QUOTE_ACTION,
  SET_CLONE_STATUS_ACTION,
  SET_BI_PDF_ACTION,
  SET_EMAIL_SENT_ACTION,
  SET_EMAIL_ERROR_MESSAGE_ACTION,
  SET_PRODUCT_CODE_ACTION,
  RESET_VALIDATE_OFFER_CODE
} from './constants';

export function ChangeLifeAssuredAction(payload) {
  return {
    type: CHANGE_LIFE_ASSURED_ACTION,
    payload,
  };
}

export function setProductCodeAction(payload) {
  return {
    type: SET_PRODUCT_CODE_ACTION,
    payload,
  };
}

export function ChangeCoverageAction(
  data,
  recalculateQuote = false,
  isLifeAssuredEqual,
  isGetPremium,
) {
  return {
    type: SET_PRODUCT_CONFIG_ACTION,
    payload: {
      data,
      recalculateQuote,
      isLifeAssuredEqual,
      isGetPremium,
    },
  };
}

export function SetCoveragesListAction(payload) {
  return {
    type: SET_COVERAGES_LIST_ACTION,
    payload,
  };
}

export function AddPolicyOwnerAction(payload) {
  return {
    type: ADD_POLICY_OWNER_ACTION,
    payload,
  };
}

export function createQuoteAction(payload) {
  return {
    type: CREATE_QUOTE_ACTION,
    payload,
  };
}

export function calculateQuoteAction(payload) {
  return {
    type: CALCULATE_QUOTE_ACTION,
    payload,
  };
}

export function setPtvVersionAction(payload) {
  return {
    type: SET_PTV_VERSION_ACTION,
    payload,
  };
}

export function setQuoteAction(payload) {
  return {
    type: SET_QUOTE_ACTION,
    payload,
  };
}

export function setCalculateAction(payload) {
  return {
    type: SET_CALCULATION_ACTION,
    payload,
  };
}

export function setGcConfigAction(payload) {
  return {
    type: SET_GC_CONFIG_ACTION,
    payload,
  };
}

export function getQuoteAction(payload) {
  return {
    type: GET_QUOTE_ACTION,
    payload,
  };
}

export function setQuoteDetailsAction(payload) {
  return {
    type: SET_QUOTE_DETAILS_ACTION,
    payload,
  };
}

export function updateStatusAction(payload) {
  return {
    type: UPDATE_STATUS_ACTION,
    payload,
  };
}

export function emailPdfAction(payload) {
  return {
    type: EMAIL_PDF_ACTION,
    payload,
  };
}

export function createApplicationAction(payload) {
  return {
    type: CREATE_APPLICATION_ACTION,
    payload,
  };
}

export function setApplicationIdAction(payload) {
  return {
    type: SET_APPLICATION_ID_ACTION,
    payload,
  };
}

export function setErrorAction(payload) {
  return {
    type: SET_ERROR_ACTION,
    payload,
  };
}

export function resetAction(payload) {
  return {
    type: RESET_ACTION,
    payload,
  };
}

export function getFiaAction(payload) {
  return {
    type: GET_FIA_ACTION,
    payload,
  };
}

export function setFiaAction(payload) {
  return {
    type: SET_FIA_ACTION,
    payload,
  };
}

export function setBiTableValuesaAction(payload) {
  return {
    type: SET_BI_TABLE_VALUES_ACTION,
    payload,
  };
}

export function cloneQuoteAction(payload) {
  return {
    type: CLONE_QUOTE_ACTION,
    payload,
  };
}

export function setCloneStatusAction(payload) {
  return {
    type: SET_CLONE_STATUS_ACTION,
    payload,
  };
}

export function setBiPdfAction(payload) {
  return {
    type: SET_BI_PDF_ACTION,
    payload,
  };
}

export function setEmailSentAction(payload) {
  return {
    type: SET_EMAIL_SENT_ACTION,
    payload,
  };
}

export function setEmailErrorMessage(payload) {
  return {
    type: SET_EMAIL_ERROR_MESSAGE_ACTION,
    payload,
  };
}

export function resetOfferCodeAction() {
  return {
    type: RESET_VALIDATE_OFFER_CODE,
  };
}

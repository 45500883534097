/*
 *
 * VistaIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/VistaIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/VistaIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/VistaIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const GET_GC_CONFIG_ACTION =
  'app/VistaIllustrationPage/GET_GC_CONFIG_ACTION';
export const SET_GC_CONFIG_ACTION =
  'app/VistaIllustrationPage/SET_GC_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION = 'app/VistaIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/VistaIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/VistaIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/VistaIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/VistaIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/VistaIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/VistaIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/VistaIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/VistaIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/VistaIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/VistaIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/VistaIllustrationPage/SET_PTV_VERSION_ACTION';
export const SET_ERROR_ACTION = 'app/VistaIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/VistaIllustrationPage/RESET_ACTION';
export const GET_FIA_ACTION = 'app/VistaIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/VistaIllustrationPage/SET_FIA_ACTION';
export const SET_BI_TABLE_VALUES_ACTION =
  'app/VistaIllustrationPage/SET_BI_TABLE_VALUES_ACTION';
export const CLONE_QUOTE_ACTION = 'app/VistaIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/VistaIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/VistaIllustrationPage/SET_BI_PDF_ACTION';

export const SET_EMAIL_SENT_ACTION =
  'app/VistaIllustrationPage/SET_EMAIL_SENT_ACTION';
export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/VistaIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';
export const SET_PRODUCT_CODE_ACTION =
  'app/VistaIllustrationPage/SET_PRODUCT_CODE_ACTION';
export const RESET_VALIDATE_OFFER_CODE =
  'app/ApplicationPage/RESET_VALIDATE_OFFER_CODE';

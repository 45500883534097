/*
 *
 * Futura/WAP/SW IllustrationPage actions
 *
 */

import {
  CHANGE_LIFE_ASSURED_ACTION,
  SET_ERROR_ACTION,
  CHANGE_COVERAGE_ACTION,
  ADD_POLICY_OWNER_ACTION,
  CREATE_QUOTE_ACTION,
  SET_QUOTE_ACTION,
  CALCULATE_QUOTE_ACTION,
  SET_CALCULATION_ACTION,
  SET_ASP_ACTION,
  SET_COVERAGES_LIST_ACTION,
  GET_QUOTE_ACTION,
  SET_QUOTE_DETAILS_ACTION,
  UPDATE_STATUS_ACTION,
  RESET_ACTION,
  EMAIL_PDF_ACTION,
  CREATE_APPLICATION_ACTION,
  SET_APPLICATION_ID_ACTION,
  SET_FUTURA_CONFIG_ACTION,
  SET_PREMIUM_TERM_ACTION,
  SET_PTV_VERSION_ACTION,
  GET_FIA_ACTION,
  SET_FIA_ACTION,
  SET_MINPREMIUM_ACTION,
  CLONE_QUOTE_ACTION,
  SET_CLONE_STATUS_ACTION,
  SET_BI_PDF_ACTION,
  SET_EMAIL_SENT_ACTION,
  SET_COMPLETED_DIALOG,
  SET_EMAIL_ERROR_MESSAGE_ACTION,
  SET_IS_CALCULATE_QUOTE_PAGE_ACTION,
  SET_PRODUCT_CODE_ACTION,
  SET_POLICY_CONFIG_ACTION,
  SET_HARVEST_DATA_ACTION,
  RECALCULATE_SUSTAINABILITY_ACTION,
  SET_SUSTAINABILITY_YEAR_ACTION,
  DOWNLOAD_HEALTH_CHECK_PDF_ACTION,
  SET_INIT_COVERAGES_ACTION,
  SET_INIT_PREMIUM_SLICES_ACTION,
  SET_PREMIUM_SLICES_ACTION,
  SET_GROWTH_RATE_ACTION,
  SET_SHOW_WARNING_REVIEW,
  SET_POLICY_TERM,
  RESET_PRODUCT_CONFIGS_ACTION,
  SET_INIT_COVERAGES_LIST_ACTION,
  SET_MANUAL_PREMIUM,
  SET_LAST_ACTION_EDIT_QUOTE,
  SET_POLICY_TERM_HARVEST,
  SET_COUNT_GET_SUSTAIN_AGE_ACTION,
  SET_BI_TABLE_VALUES_ACTION,
  SET_LIFE_ASSURED_HARVEST
} from './constants';

export function ChangeLifeAssuredAction(payload) {
  return {
    type: CHANGE_LIFE_ASSURED_ACTION,
    payload,
  };
}
export function setLifeAssuredHarvestAction(payload) {
  return {
    type: SET_LIFE_ASSURED_HARVEST,
    payload
  }
}

export function setProductCodeAction(payload) {
  return {
    type: SET_PRODUCT_CODE_ACTION,
    payload,
  };
}
export function setBiTableValuesaAction(payload) {
  return {
    type: SET_BI_TABLE_VALUES_ACTION,
    payload,
  };
}
export function changeCoverageAction(data) {
  return {
    type: CHANGE_COVERAGE_ACTION,
    payload: {
      data,
    },
  };
}
export function ChangeCoverageAction2(
  data,
  recalculateQuote = false,
  reverseCalculate = false,
  isCalculateQuotePage = false,
  isLifeAssuredEqual = false,
  isCheckOfferCode = false,
) {
  return {
    type: CHANGE_COVERAGE_ACTION,
    payload: {
      data,
      recalculateQuote,
      reverseCalculate,
      isCalculateQuotePage,
      isLifeAssuredEqual,
      isCheckOfferCode,
    },
  };
}




export function setCoveragesListAction(payload) {
  return {
    type: SET_COVERAGES_LIST_ACTION,
    payload,
  };
}

export function SetMinPremiumAction(payload) {
  return {
    type: SET_MINPREMIUM_ACTION,
    payload,
  };
}

export function AddPolicyOwnerAction(payload) {
  return {
    type: ADD_POLICY_OWNER_ACTION,
    payload,
  };
}

export function createQuoteAction(payload) {
  return {
    type: CREATE_QUOTE_ACTION,
    payload,
  };
}

export function calculateQuoteAction(payload) {
  return {
    type: CALCULATE_QUOTE_ACTION,
    payload,
  };
}

export function setQuoteAction(payload) {
  return {
    type: SET_QUOTE_ACTION,
    payload,
  };
}

export function setCalculateAction(payload) {
  return {
    type: SET_CALCULATION_ACTION,
    payload,
  };
}

export function setASPAction(payload) {
  return {
    type: SET_ASP_ACTION,
    payload,
  };
}

export function setPremiumTermAction(payload) {
  return {
    type: SET_PREMIUM_TERM_ACTION,
    payload,
  };
}

export function setPtvVersionAction(payload) {
  return {
    type: SET_PTV_VERSION_ACTION,
    payload,
  };
}

export function setPolicyConfigAction(payload) {
  return {
    type: SET_POLICY_CONFIG_ACTION,
    payload,
  };
}

export function setBiPdfAction(payload) {
  return {
    type: SET_BI_PDF_ACTION,
    payload,
  };
}

export function getQuoteAction(payload) {
  return {
    type: GET_QUOTE_ACTION,
    payload,
  };
}

export function setQuoteDetailsAction(payload) {
  return {
    type: SET_QUOTE_DETAILS_ACTION,
    payload,
  };
}

export function updateStatusAction(payload) {
  return {
    type: UPDATE_STATUS_ACTION,
    payload,
  };
}

export function emailPdfAction(payload) {
  return {
    type: EMAIL_PDF_ACTION,
    payload,
  };
}

export function createApplicationAction(payload) {
  return {
    type: CREATE_APPLICATION_ACTION,
    payload,
  };
}

export function setApplicationIdAction(payload) {
  return {
    type: SET_APPLICATION_ID_ACTION,
    payload,
  };
}

export function setErrorAction(payload) {
  return {
    type: SET_ERROR_ACTION,
    payload,
  };
}

export function resetAction(payload) {
  return {
    type: RESET_ACTION,
    payload,
  };
}

export function getFiaAction(payload) {
  return {
    type: GET_FIA_ACTION,
    payload,
  };
}

export function setFiaAction(payload) {
  return {
    type: SET_FIA_ACTION,
    payload,
  };
}

export function cloneQuoteAction(payload) {
  return {
    type: CLONE_QUOTE_ACTION,
    payload,
  };
}

export function setCloneStatusAction(payload) {
  return {
    type: SET_CLONE_STATUS_ACTION,
    payload,
  };
}

export function setEmailSentAction(payload) {
  return {
    type: SET_EMAIL_SENT_ACTION,
    payload,
  };
}

export function setEmailErrorMessage(payload) {
  return {
    type: SET_EMAIL_ERROR_MESSAGE_ACTION,
    payload,
  };
}

export function setCompletedDialogAction(payload) {
  return {
    type: SET_COMPLETED_DIALOG,
    payload,
  };
}

export function setIsCalculateQuotePageAction(payload) {
  return {
    type: SET_IS_CALCULATE_QUOTE_PAGE_ACTION,
    payload,
  };
}

export function setHarvestDataAction(payload) {
  return {
    type: SET_HARVEST_DATA_ACTION,
    payload,
  };
}

export function recalculateSustainabilityAction(payload) {
  return {
    type: RECALCULATE_SUSTAINABILITY_ACTION,
    payload,
  };
}

export function setSustainabilityYearsAction(payload) {
  return {
    type: SET_SUSTAINABILITY_YEAR_ACTION,
    payload,
  };
}

export function downloadHealthCheckPdfAction(payload) {
  return {
    type: DOWNLOAD_HEALTH_CHECK_PDF_ACTION,
    payload,
  };
}
export function setInitCoveragesAction(payload) {
  return {
    type: SET_INIT_COVERAGES_ACTION,
    payload,
  };
}

export function setInitPremiumSlicesAction(payload) {
  return {
    type: SET_INIT_PREMIUM_SLICES_ACTION,
    payload,
  };
}

export function setPremiumSlicesAction(payload) {
  return {
    type: SET_PREMIUM_SLICES_ACTION,
    payload,
  };
}

export function setGrowthRateAction(payload) {
  return {
    type: SET_GROWTH_RATE_ACTION,
    payload,
  };
}

export function setShowWarningReviewAction(payload) {
  return {
    type: SET_SHOW_WARNING_REVIEW,
    payload,
  };
}

export function setPolicyTermAction(payload) {
  return {
    type: SET_POLICY_TERM,
    payload,
  };
}

export function resetProductConfigsAction(payload) {
  return {
    type: RESET_PRODUCT_CONFIGS_ACTION,
    payload,
  };
}

export function setInitProductConfigsCoveragesAction(payload) {
  return {
    type: SET_INIT_COVERAGES_LIST_ACTION,
    payload,
  };
}

export function setManualPremiumAction(payload) {
  return {
    type: SET_MANUAL_PREMIUM,
    payload,
  };
}

export function setLastActionEditQuote(payload) {
  return {
    type: SET_LAST_ACTION_EDIT_QUOTE,
    payload,
  };
}

export function setPolicyTermHarvestAction(payload) {
  return {
    type: SET_POLICY_TERM_HARVEST,
    payload,
  };
}

export function setCountGetSustainAgeAction(payload) {
  return {
    type: SET_COUNT_GET_SUSTAIN_AGE_ACTION,
    payload,
  };
}

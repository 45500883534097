/**
 *
 * ReviewUserData
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import numeral from 'numeral';
import moment from 'moment';
import InfoRow from 'components/InfoRow';

const ReviewUserData = ({ party, isCovered }) => {
    return <InfoRow>
        <Grid container wrap="nowrap" alignItems="center" spacing={2}>
            <Grid item xs={3}>
                <Typography
                    variant="body2"
                    style={{
                        fontWeight: '700',
                    }}
                >
                    {party?.firstName + ' ' + party?.lastName}
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant="body2">
                    {(party?.dateOfBirth !== undefined && !isEmpty(party?.dateOfBirth) && moment(party.dateOfBirth).isValid())
                        ? moment(party?.dateOfBirth).format('DD MMMM YYYY')
                        : '-'}
                </Typography>
            </Grid>

            <Grid item xs={3}>
                <Typography variant="body2">
                    {party?.gender !== undefined
                        ? numeral(party?.gender).value() === 1
                            ? 'Male'
                            : 'Female'
                        : '-'}
                </Typography>
            </Grid>
            {(party?.relationshipToLifeInsured !== "CHILD") &&
                <Grid item xs={3}>
                    <Typography variant="body2">
                        {(isCovered) ? 'Covered' : 'Not covered'}
                    </Typography>
                </Grid>
            }
            {/* <Grid item xs={3}>
          <Typography
            variant="body2"
          >
            {party.isSmoke !== undefined ? (
              party.isSmoke ? 'Smoker' : 'Not Smoker'
            ) : '-'}
          </Typography>
        </Grid> */}
        </Grid>
    </InfoRow>
}

ReviewUserData.propTypes = {
    party: PropTypes.object,
};

export default ReviewUserData;

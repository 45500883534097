/**
 *
 * FormNumberField
 *
 */

import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import NumberField2 from 'components/NumberField2';
import { FormattedMessage, useIntl } from 'react-intl';
import { fixLeadingZero } from 'helpers/math';
import TextField from 'components/TextField';
import { InputAdornment, MenuItem, Grid } from '@material-ui/core';
import { isImmutable } from 'immutable';
import messages from 'containers/ApplicationFormPage/messages';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { convertIfImmutable } from 'helpers/lang';
import Helper from 'components/Helper';
import { find, map, filter } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';

const useStyles = makeStyles(theme => ({
  root: {
    // width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  textField: {
    borderRadius: '6px',
    height: '4.3rem',
  },
}));

const FormNumberField = ({
  question,
  label,
  input: { value, onChange, onBlur, ...otherInputProps },
  meta: { invalid, error, touched, warning },
  inputFormat,
  showLabel = true,
  ...custom
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }
  const getValue = () => {
    if (isImmutable(value)) {
      return value.toJS();
    } else {
      return value;
    }
  };
  const [touchedField, setTouchedField] = useState(false);
  const fieldValue = convertIfImmutable(value);
  const availableUnits = map(question.constraints.availableUnits, unit => {
    return { value: unit.unitSymbol, title: unit.label };
  });

  const selectedUnit = filter(question.constraints.availableUnits, unit => {
    return unit.unitSymbol === fieldValue.unit;
  });

  const maxValue = !isEmpty(selectedUnit)
    ? selectedUnit[0].validationRanges[0].upper
    : 100;

  const minValue = !isEmpty(selectedUnit)
    ? selectedUnit[0].validationRanges[0].lower
    : 0;

  const checkIfError = () => {
    let value = getValue().v;

    let val = parseFloat(value.toString().replace(/,/g, ''));

    if (!touchedField || (maxValue + 1 > val && minValue - 1 < val)) {
      return false;
    } else {
      return true;
    }
  };

  if (!isEmpty(fieldValue)) {
    return (
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <NumberField2
              variant="outlined"
              fullWidth
              label={
                showLabel && (
                  <>
                    {`${label}`}
                    {/* {`${label} (${question.constraints.availableUnits[0].unitSymbol})`} */}
                    {question.helpText.length > 0 && (
                      <Helper
                        // helperText={'This is a sample text'}
                        helperText={question.helpText}
                      />
                    )}
                  </>
                )
              }
              value={getValue().v}
              onBlur={v => {
                var newVal = {
                  ...fieldValue,
                  v,
                  // unit: question.constraints.availableUnits[0].unitSymbol, //made this static because of the UI specification of zurich (they dont have multiple units)
                };
                setTouchedField(true);
                return onBlur(newVal);
              }}
              error={checkIfError()}
              // error={!!warning || (touched && invalid)}
              // error={touched && checkIfError}
              // helperText={helperMsg}
              helperText={`min value is ${minValue} and max value is ${maxValue}`}
              format={inputFormat}
              {...custom}
              {...otherInputProps}
              style={{
                borderRadius: '10px',
                marginTop: showLabel ? theme.spacing(2) : '',
                border: `1px solid ${theme.palette.divider}`,
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <div style={{ marginTop: '1rem' }}>
              <TextField
                value={fieldValue.unit}
                fullWidth
                variant="outlined"
                className={classes.textField}
                onChange={e => {
                  onBlur({ ...fieldValue, unit: e.target.value });
                }}
                label={<FormattedMessage {...messages.selectUnit} />}
                select
              >
                {map(availableUnits, option => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.title}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return '';
  }
};

FormNumberField.propTypes = {
  ...WrappedFieldProps,
};

export default FormNumberField;

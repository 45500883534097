import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import pdfLink from 'static/pdf/ZurichPro-TermsOfUse-September-2020.pdf';

function Footnote({ ...props }) {
  return (
    <Typography variant="caption" color="textSecondary" {...props}>
      <FormattedMessage
        {...messages.footnote}
        values={{
          link1: (
            <Link
              target="_blank"
              underline="always"
              href="https://www.zurichinternational.com/im/legal/privacy"
            >
              <FormattedMessage {...messages.privacyPolicy} />
            </Link>
          ),
          link2: (
            <Link
              target="_blank"
              underline="always"
              href={pdfLink}
            >
              <FormattedMessage {...messages.termsOfUse} />
            </Link>
          ),
        }}
      />
    </Typography>
  );
}

Footnote.propTypes = {};

export default Footnote;

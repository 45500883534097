import {
  takeLatest,
  call,
  put,
  all,
  select,
  takeEvery,
} from 'redux-saga/effects';
import request from 'utils/request';
import { api } from 'environments';
import { makeSelectAuth } from 'containers/App/selectors';
import {
  CALL_ZIO_PIN_VERIFICATION,
  GET_PERSISTED_ZIO_PIN_DATA,
} from './constants';

import {
  setZioPinSuccess,
  setZioPinFailure,
  setIsZioPinVerified,
  setZioPinVerifyTimestamp,
} from './actions';

export function* verifyZioPin(data) {
  const auth = yield select(makeSelectAuth());

  const endpoint = `${api.host}/api/v${api.version}/b2b/auth/ziopin/validatepin`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(data.payload),
  };
  try {
    const response = yield call(request, endpoint, requestOpt);
    // const response = {
    //   data: {
    //     errorCode: 'OK',
    //     errorDescription: null,
    //     error: false,
    //   },
    // };
    const { data } = response;
    if (data.error) {
      sessionStorage.setItem('zioPinData', null);
      yield put(setZioPinFailure(data));
      yield put(setIsZioPinVerified(false));
      yield put(setZioPinVerifyTimestamp(null));
    } else {
      const currentTimeStamp = new Date().valueOf();
      const zioPinData = {
        data,
        error: {},
        isZioPinVerified: true,
        zioPinVerifyTimeStamp: currentTimeStamp,
      };
      sessionStorage.setItem('zioPinData', JSON.stringify(zioPinData));
      yield put(setZioPinSuccess(data));
      yield put(setIsZioPinVerified(true));
      yield put(setZioPinVerifyTimestamp(currentTimeStamp));
    }
  } catch (err) {
    sessionStorage.setItem('zioPinData', null);
    yield put(
      setZioPinFailure({ error: true, message: 'Something went wrong' }),
    );
    yield put(setIsZioPinVerified(false));
    yield put(setZioPinVerifyTimestamp(null));
  }
}

export function* getPersistedZioPinInfo() {
  try {
    let persistedZioPinData = sessionStorage.getItem('zioPinData');
    if (persistedZioPinData) {
      const currentTimeStamp = Date.now();
      persistedZioPinData = JSON.parse(persistedZioPinData);
      const difference =
        currentTimeStamp - persistedZioPinData.zioPinVerifyTimeStamp;
      const differenceInHours = difference / (1000 * 60 * 60);
      if (differenceInHours < 1) {
        yield put(setZioPinSuccess(persistedZioPinData.data));
        yield put(setZioPinFailure(persistedZioPinData.error));
        yield put(setIsZioPinVerified(persistedZioPinData.isZioPinVerified));
        yield put(
          setZioPinVerifyTimestamp(persistedZioPinData.zioPinVerifyTimeStamp),
        );
      }
    }
  } catch (err) {
    // No error
  }
}

export default function* MFOauthPageSaga() {
  yield all([
    takeLatest(CALL_ZIO_PIN_VERIFICATION, verifyZioPin),
    takeLatest(GET_PERSISTED_ZIO_PIN_DATA, getPersistedZioPinInfo),
  ]);
}

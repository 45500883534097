/**
 *
 * InforceIllutrationPage
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import LoadingDialog from 'components/LoadingDialog';
import { usePrevious } from 'utils/hooks';
import moment from 'moment';
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { get } from 'lodash/object';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import globalMessages from 'containers/App/messages';
import { Grid, Typography, Button } from '@material-ui/core';
import FormHeader from 'components/FormHeader';
import CoverageForm from './components/CoverageForm';
import FormFooter from 'components/FormFooter';
import { makeSelectSubPage } from 'containers/DashboardPage/selectors';
import { makeSelectProductConfig as makeSelectProductConfig2 } from 'containers/IllustrationPage/selectors';

import {
  changeSubPageAction,
  changeSubPageListAction,
} from 'containers/DashboardPage/actions';
import Dialog from 'components/Dialog';
import { submit, change } from 'redux-form';
import {
  makeSelectOption,
  makeSelectAgentDetails,
  makeSelectOfferCodeData,
  makeSelectErrorOfferCodeData,
} from 'containers/App/selectors';
import FormMainBody from 'components/FormMainBody';
import { isEmpty } from 'lodash';
import { isEqual } from 'lodash/lang';
import { resetProductConfigAction } from 'containers/IllustrationPage/actions';
import NoticeBox from 'components/NoticeBox';
import {
  getLifeAssuredName,
  checkQuoteFormChanged,
  getInitLifeAssured,
  getCoveragesSubmitValue,
  getPremiumSlicesSubmitValue,
  getSinglePremiumSlicesSubmitValue,
  isPaidUpPolicyWithPremiumSkipInNAPRAPPeriod
} from './helper';
import LifeAssuredForm from './components/LifeAssuredForm';
import PolicyDetailsPage from './components/PolicyDetailsPage';
import Review from './components/Review';
import WapReview from './components/WapReview';
import FuturaLifeAssuredForm from './FuturaComponents/LifeAssuredForm';
import FuturaPolicyDetailsPage from './FuturaComponents/PolicyDetailsPage';
import FuturaReview from './FuturaComponents/Review';
import {
  ChangeLifeAssuredAction,
  createQuoteAction,
  calculateQuoteAction,
  setErrorAction,
  setCoveragesListAction,
  getQuoteAction,
  updateStatusAction,
  resetAction,
  createApplicationAction,
  setQuoteAction,
  setPtvVersionAction,
  setPremiumTermAction,
  cloneQuoteAction,
  setCloneStatusAction,
  setCompletedDialogAction,
  setProductCodeAction,
  resetProductConfigsAction,
  setWapConfigAction,
  setCountGetSustainAgeAction,
  ChangeCoverageAction,
  ChangeCoverageAction2
} from './actions';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectError,
  makeSelectStatus,
  makeSelectApplicationId,
  makeSelectQuoteId,
  makeSelectFiaCharge,
  makeSelectCloneStatus,
  makeSelectCompletedDialog,
  makeSelectIsCalculateQuotePage,
  makeSelectPolicyConfig,
  makeSelectHarvestData,
  makeSelectSustainabilityYears,
  makeSelectInitProductConfig,
  makeSelectPolicyTerm,
  makeSelectManualPremium,
  makeSelectCountOfCallingSustainAge,
  makeSelectPremium,
  makeSelectLastActionEditQuote,
  makeSelectConfig,
  makeSelectBiTableValues
} from './selectors';
import QuoteForm from './components/QuoteForm';
import FuturaQuoteForm from './FuturaComponents/QuoteForm';
import messages from './messages';
import saga from './saga';
import reducer from './reducer';
import { GET_QUOTE_POLICY, ACTION_EDIT_QUOTE } from './constants';
import { productCodeCheck } from 'helpers/constants';

const subPageListfutura = [
  { order: 1, text: 'Edit details', subOrder: [] },
  { order: 2, text: 'Quote', subOrder: [] },
  { order: 3, text: 'Review', subOrder: [] },
];

const subPageListWap = [
  { order: 1, text: 'Life Insured', subOrder: [] },
  { order: 2, text: 'Coverage', subOrder: [] },
  { order: 3, text: 'Quote', subOrder: [] },
  { order: 4, text: 'Review', subOrder: [] },
];
export function InforceIllutrationPage({
  quoteId,
  saveQuote,
  calculateQuote,
  subPage,
  changeSubPage,
  changeSubPageList,
  isManualPremium,
  lifeAssured,
  changeLifeAssured,
  productConfig,
  initProductConfig,
  policyConfig,
  agentDetails,
  harvestData,
  resetProductConfigs,
  submitLifeAssured,
  sustainabilityYears,
  lastActionEditQuote,
  wapConfig,
  setWapConfig,
  submitQuote,
  options,
  getQuote,
  updateStatus,
  errorData,
  applicationId,
  status,
  createApplication,
  setError,
  resetData,
  resetProductConfig,
  policyTerm,
  fiaData,
  offerCodeData,
  completedDialog,
  setCompletedDialog,
  offerCodeErrorMsg,
  changeIsGetPremium,
  changeIsCalculateSustainability,
  productConfig2,
  submitCoverage,
  changeCoverage,
  biTableValues,
  policyStartDate
}) {

  useInjectReducer({ key: 'ifiIllustrationPage', reducer });
  useInjectSaga({ key: 'ifiIllustrationPage', saga });
  const history = useHistory();
  const location = useLocation();
  const [product, setProduct] = useState({});
  const [isWapProductConfig, setWapProductConfig] = useState("");
  const [isQuoteSubmit, setQuoteSubmit] = useState(false);
  const [isWap, setIsWap] = useState(false);
  const [isLifeAssuredEqual, setLifeAssuredEqual] = useState(false);
  const [quoteStartDay, setQuoteStartDay] = useState()
  const [url, setUrl] = useState('wap')
  const getQuoteStartDate = () => {
    let date_today = new Date();
    let currentDate = new Date().getDate();
    let firstDay = currentDate < 15 ? new Date(date_today.getFullYear(), date_today.getMonth(), 1) : new Date(date_today.getFullYear(), date_today.getMonth() + 1, 1);
    console.log(firstDay.toLocaleDateString('en-GB'))
    return firstDay.toLocaleDateString('en-GB')
  }
  const [premiumSkip, setPremiumSkip] = useState(false);
  useEffect(() => {
    const firstDate = getQuoteStartDate()
    setQuoteStartDay(firstDate)
  }, [])

  if (productCodeCheck(6, productConfig?.productCode)) {
    productConfig.startDate = moment(quoteStartDay).format('YYYY-DD-MM')
  }

  if (applicationId) {
    changeSubPage(0);
    history.replace(`/applications/${applicationId}`);
    resetProductConfig();
  }

  useEffect(() => {
    // set the sub pages list for sidebar
    if (productCodeCheck('FUTU', productConfig.productCode)) { // added condition to execute this only for Futura product
      if (location.pathname !== '/ifi') {  
        changeSubPageList(subPageListfutura);
      }
    }
  }, [location.pathname]);

  const prevBiTableValues = usePrevious(biTableValues);

  useEffect(() => {
    console.log("bi values", biTableValues)
    if (
      !isEmpty(biTableValues) &&
      !isEqual(
        prevBiTableValues.illustrationTableDict,
        biTableValues.illustrationTableDict,
      )
    ) {
      return history.replace(`/ifi/${url}/quote`, location.state);
    }
    if (
      location.pathname === `/ifi/${url}/quote` &&
      isEmpty(biTableValues)
    ) {
      return history.replace(`/ifi/${url}/coverage`, location.state);
    }
  }, [biTableValues.illustrationValueDict]);


  // set the productConfig aka wapConfig
  useEffect(() => {

    //setWapConfig(location.state.wapConfig);
    //if (location.state.wapConfig.features.haveFiaCharge) callFia();
    if (location.state.quoteId) {
      setQuoteId(location.state.quoteId);
      getQuote(location.state.type);
    }
    // set the sub pages list for sidebar
    changeSubPageList(subPageListWap);
    return () => {
      changeSubPage(0);
      changeSubPageList([]);
      resetData();
    };
  }, []);

  // set the product details from agent data

  useEffect(() => {
    // set the sub pages list for sidebar
    // if (location.pathname !== '/ifi') {
    if (productConfig.productCode === 'WAPL2' || productConfig.productCode === 'WAPL1') {
      setUrl('wap')
    } else if (productConfig.productCode === 'SIMWE' || productConfig.productCode === 'SIMW2') {
      setUrl('sw')
    }
    if (productCodeCheck(6, productConfig?.productCode)) {
      setIsWap(true)
      changeSubPageList(subPageListWap)
    } else if(productCodeCheck('FUTU', productConfig.productCode)) {
      setIsWap(false)
      changeSubPageList(subPageListfutura);
    }
    //}
  }, [productConfig]);

  useEffect(() => {
    const { policyNumber, productCode, productVersion } = location.state;
    // callFia();
    if (location.state?.policyNumber) {
      getQuote({
        type: GET_QUOTE_POLICY,
        policyNumber,
        productCode,
        productVersion,
      });
    }

    return () => {
      changeSubPage(0);
      changeSubPageList([]);
      resetData();
    };
  }, []);

  useEffect(() => {
    if (status === 'AGREED') {
      createApplication();
    }

    if ((status === 'SAVED' || status === 'PREVIEW') && !isEmpty(quoteId)) {
      if (productCodeCheck(6, productConfig?.productCode)) {
        history.replace(`/ifi/${url}/review`, location.state);
      } else {
        history.replace('/ifi/review', location.state);
      }
    }
  }, [status]);

  useEffect(() => {
    if (errorData.error) {
      changeIsGetPremium(false);
      changeIsCalculateSustainability(false);
      setQuoteSubmit(false);
    }
  }, [errorData.error]);

  useEffect(() => {
    let activeSubPage = subPage;
    switch (location.pathname) {
      case '/ifi/insured':
        activeSubPage = 1;
        break;
      case '/ifi/quote':
        activeSubPage = 2;
        break;
      case '/ifi/review':
        activeSubPage = 3;
        break;
      case `/ifi/${url}/insured`:
        activeSubPage = 1;
        break;
      case `/ifi/${url}/coverage`:
        activeSubPage = 2;
        break;
      case `/ifi/${url}/quote`:
        activeSubPage = 3;
        break;
      case `/ifi/${url}/review`:
        activeSubPage = 4;
        break;
      default:
        break;
    }
    changeSubPage(activeSubPage);
  }, [location.pathname]);

  useEffect(() => {
    if (errorData.error) window.scrollTo(0, 0);
  }, [errorData]);

  const getTitle = () => {
    switch (location.pathname) {
      case '/ifi/insured':
        return globalMessages.lifeAssuredTitle;
      case '/ifi/coverage':
        return globalMessages.coverageTitle;
      case '/ifi/benefitrating':
        return globalMessages.benefitRatingTitle;
      case '/ifi/quote':
        return globalMessages.quoteTitle;
      case '/ifi/review':
        return globalMessages.reviewTitle;
      case `/ifi/${url}/insured`:
        return globalMessages.lifeAssuredTitle;
      case `/ifi/${url}/coverage`:
        return globalMessages.coverageTitle;
      case `/ifi/${url}/quote`:
        return globalMessages.quoteTitle;
      case `/ifi/${url}/review`:
        return globalMessages.reviewTitle;
      default:
        return globalMessages.lifeAssuredTitle;
    }
  };

  const getFooterLabel3 = () => {
    switch (location.pathname) {
      case '/ifi/review':
        return globalMessages.agreeAndProceed;
      case '/ifi/quote':
        return globalMessages.saveQuote;
      case `/ifi/${url}/review`:
        return globalMessages.agreeAndProceed;
      case `/ifi/${url}/quote`:
        return globalMessages.saveQuote;
      default:
        return globalMessages.next;
    }
  };

  return (
    <>
      {location.pathname !== '/ifi' ?
        (
          <FormHeader
            title={<FormattedMessage {...getTitle()} />}
            productName={getLifeAssuredName(lifeAssured)}
          />
        ) : (
          <Grid style={{ paddingTop: '60px' }} />
        )}

      {['/ifi/quote', '/ifi'].includes(location.pathname) && errorData.error ? (
        <Grid container style={{ paddingLeft: '40px' }}>
          <Grid item xs={7}>
            <NoticeBox variant="error" mb={4}>
              {errorData.message.map((error, index) => (
                <Typography
                  key={index}
                  id={error.message}
                  variant="body2"
                  color="error"
                >
                  {error.message}
                </Typography>
              ))}
            </NoticeBox>
          </Grid>
        </Grid>
      ) : null}

      <FormMainBody>
        <Grid item xs={12} md={11} lg={7} xl={6}>
          <Switch>
            <Route
              path="/ifi/insured"
              render={() => (
                <FuturaLifeAssuredForm
                  initialValues={getInitLifeAssured(lifeAssured)}
                  lifeBasisHarvest={lifeAssured.lifeBasisHarvest}
                  coverages={productConfig.coverages}
                  initProductConfig={initProductConfig}
                  location={location}
                  options={options}
                  policyConfig={policyConfig}
                  fiaData={fiaData}
                  onSubmit={immutableValues => {
                    const lifeAssuredValues = immutableValues.toJS();
                    if (!lifeAssuredValues.isInsuredTwo) {
                      lifeAssuredValues.lifeBasis = 'SL';
                    }
                    if (
                      !isEqual(
                        lifeAssuredValues,
                        getInitLifeAssured(lifeAssured),
                      )
                    ) {
                      setQuoteSubmit(false);
                    }
                    changeLifeAssured(lifeAssuredValues);
                    return history.replace('/ifi/quote', location.state);
                  }}
                />
              )}
            />
            <Route
              path={`/ifi/${url}/insured`}
              render={() => (

                <LifeAssuredForm
                  isWap={isWap}
                  initialValues={getInitLifeAssured(lifeAssured)}
                  lifeBasisHarvest={lifeAssured.lifeBasisHarvest}
                  coverages={productConfig.coverages}
                  initProductConfig={initProductConfig}
                  location={location}
                  options={options}
                  policyConfig={policyConfig}
                  fiaData={fiaData}
                  onSubmit={immutableValues => {
                    return history.replace(`/ifi/${url}/coverage`, location.state);
                  }}
                />
              )}
            />
            <Route
              path="/ifi/coverage"
              render={routeProps => (
                <CoverageForm
                  initialValues={{
                    ...productConfig,
                    shortName: productConfig2.shortName,
                    FMC: get(
                      find(productConfig2.features?.FMC, f => {
                        return f.region === agentDetails.ISOCountryCode;
                      }),
                      'FMCrate',
                      '',
                    ),
                  }}
                  location={location}
                  lifeAssured={lifeAssured}
                  options={options}
                  product={product}
                  itaConfig={itaConfig}
                  fiaData={fiaData}
                  offerCodeData={offerCodeData}
                  onSubmit={immutableValues => {
                    if (immutableValues.toJS().specialTermsFlag) {
                      changeCoverage(
                        immutableValues.toJS(),
                        false,
                        false,
                        false,
                        isLifeAssuredEqual,
                      );
                      return history.replace(
                        '/ifi/coverage',
                        location.state,
                      );
                    }
                    changeCoverage(
                      immutableValues.toJS(),
                      true,
                      false,
                      false,
                      isLifeAssuredEqual,
                    );
                  }}
                  {...routeProps}
                />
              )}
            />
            <Route
              path={`/ifi/${url}/coverage`}
              render={routeProps => (
                <CoverageForm
                  isWap={isWap}
                  quoteStartDay={quoteStartDay}
                  policyConfig={policyConfig}
                  productConfig={productConfig}
                  isoCountryCode={agentDetails.ISOCountryCode}
                  initialValues={{
                    ...productConfig,
                    shortName: productConfig2.shortName,
                    FMC: get(
                      find(productConfig2.features?.FMC, f => {
                        return f.region === agentDetails.ISOCountryCode;
                      }),
                      'FMCrate',
                      '',
                    ),
                  }}
                  location={location}
                  calculateQuote={val => calculateQuote(val)}
                  lifeAssured={lifeAssured}
                  HarvestPolicyStartDate={moment(harvestData.policyDetail?.policyStartDate).format('DD/MM/YYYY')}
                  options={options}
                  product={product}
                  fiaData={fiaData}
                  agentDetails={agentDetails}
                  offerCodeData={offerCodeData}
                  onSubmit={immutableValues => {
                    setWapProductConfig(immutableValues.toJS())
                    if (immutableValues.toJS().specialTermsFlag) {
                      changeCoverage(
                        immutableValues.toJS(),
                        false,
                        false,
                        false,
                        isLifeAssuredEqual,
                      );
                      return history.replace(
                        `/ifi/${url}/quote`,
                        location.state,
                      );
                    }
                    changeCoverage(
                      immutableValues.toJS(),
                      true,
                      false,
                      false,
                      isLifeAssuredEqual,
                    );
                    /*return history.replace(
                      '/ifi/wap/quote',
                      location.state,
                    );*/
                  }}
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/ifi/quote"
              render={() => (
                <FuturaQuoteForm
                  initialValues={productConfig}
                  // isWap={isWap}
                  initProductConfig={initProductConfig}
                  productConfig={productConfig}
                  location={location}
                  lifeAssured={lifeAssured}
                  agentDetails={agentDetails}
                  originalPolicy={harvestData.originalPolicySummary}
                  harvestPolicyDetails= {harvestData.policyDetail}
                  paymentDue={productConfig.nextDueDate}
                  sustainabilityYears={sustainabilityYears}
                  options={options}
                  product={product}
                  policyConfig={policyConfig}
                  calculateQuote={val => calculateQuote(val)}
                  setNextButtonStatus={value => {
                    setQuoteSubmit(value);
                  }}
                  setPremiumSkip={value => {
                    setPremiumSkip(value)
                  }}
                  onSubmit={immutableValues => {
                    const formValues = immutableValues.toJS();
                    const isAddedNewLife =
                      lifeAssured.lifeBasis !== 'SL' &&
                      !lifeAssured?.insuredTwo?.isExisting;

                    const {
                      regularPremiumSlices,
                      singlePremiumSlices,
                      premium,
                      paymentFrequency,
                      singlePremium,
                      isGetPremium,
                      isCalculateSustainability,
                      term,
                    } = formValues;
                    const isQuoteFormChanged = checkQuoteFormChanged(
                      { ...formValues },
                      initProductConfig,
                    );

                    const sumRegularPremiumSlices = [
                      ...regularPremiumSlices,
                    ]?.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.annualPremium,
                      0,
                    );
                    const isGetSustainAgeWithInvalidTerm =
                      sumRegularPremiumSlices !== 0 &&
                      initProductConfig.premium === 0 &&
                      Number(premium) === 0;
                    if (isQuoteFormChanged || isGetPremium || isAddedNewLife || (formValues.policyStatus == 'L' || formValues.policyStatus == 'C')) {
                      setQuoteSubmit(true);
                    }
                    //Defect fix for IIU-232
                    if(isPaidUpPolicyWithPremiumSkipInNAPRAPPeriod(formValues,premiumSkip)) {
                      setQuoteSubmit(false);
                    } 
                    //Defect fix for IIU-232
                    if (isGetPremium || isCalculateSustainability) {
                      const submitCoveragesValues = getCoveragesSubmitValue(
                        formValues,
                        isAddedNewLife,
                      );

                      let submitRPPremiumSlices = [...regularPremiumSlices];
                        if (isCalculateSustainability || (productCodeCheck('FUTU', productConfig.productCode))) {
                        submitRPPremiumSlices = getPremiumSlicesSubmitValue(
                          regularPremiumSlices,
                          paymentFrequency,
                          Number(premium),
                        );
                      }
                      const submitSPPremiumSlices = getSinglePremiumSlicesSubmitValue(
                        singlePremiumSlices,
                        singlePremium,
                      );

                      if (
                        isGetSustainAgeWithInvalidTerm &&
                        isCalculateSustainability &&
                        Number(term) === initProductConfig.term
                      ) {
                        submitRPPremiumSlices[
                          submitRPPremiumSlices.length - 1
                        ].annualPremium = 0;
                      }

                      resetProductConfigs({
                        ...formValues,
                        coverages: submitCoveragesValues,
                        regularPremiumSlices: submitRPPremiumSlices,
                        singlePremiumSlices: submitSPPremiumSlices,
                        paymentFrequency,
                        policyTerm,
                      });

                      return calculateQuote();
                    }

                    if (!isGetPremium && !isCalculateSustainability) {
                      let submitRPPremiumSlices = [...regularPremiumSlices];

                      if (
                        isManualPremium ||
                        [
                          ACTION_EDIT_QUOTE.GET_SUSTAIN_AGE,
                          ACTION_EDIT_QUOTE.SAVE_QUOTE,
                          ACTION_EDIT_QUOTE.GET_ASP_WOL,
                        ].includes(lastActionEditQuote)
                      ) {
                        submitRPPremiumSlices = getPremiumSlicesSubmitValue(
                          regularPremiumSlices,
                          productConfig.paymentFrequency,
                          premium,
                        );
                      } else {
                        submitRPPremiumSlices[
                          submitRPPremiumSlices.length - 1
                        ].annualPremium = 0;
                      }

                      if (
                        isGetSustainAgeWithInvalidTerm &&
                        Number(term) === initProductConfig.term
                      ) {
                        submitRPPremiumSlices[
                          submitRPPremiumSlices.length - 1
                        ].annualPremium = 0;
                      }

                      resetProductConfigs({
                        ...productConfig,
                        regularPremiumSlices: submitRPPremiumSlices,
                        paymentFrequency: productConfig.paymentFrequency,
                      });
                      return saveQuote({ isLifeAssuredEqual: false });
                    }
                  }}
                  offerCodeData={offerCodeData}
                />
              )}
            />
            <Route
              path={`/ifi/${url}/quote`}
              render={() => (
                <QuoteForm
                  isWap={isWap}
                  initialValues={{ ...productConfig, ...biTableValues }}
                  biTableValues={biTableValues}
                  initProductConfig={initProductConfig}
                  productConfig={productConfig}
                  location={location}
                  lifeAssured={lifeAssured}
                  originalPolicy={harvestData.originalPolicySummary}
                  paymentDue={productConfig.nextDueDate}
                  sustainabilityYears={sustainabilityYears}
                  options={options}
                  product={product}
                  policyConfig={policyConfig}
                  calculateQuote={val => calculateQuote(val)}
                  setNextButtonStatus={value => {
                    setQuoteSubmit(true);
                  }}
                  onSubmit={immutableValues => {
                    const formValues = immutableValues.toJS();
                    const isAddedNewLife =
                      lifeAssured.lifeBasis !== 'SL' &&
                      !lifeAssured?.insuredTwo?.isExisting;

                    const {
                      regularPremiumSlices,
                      singlePremiumSlices,
                      premium,
                      paymentFrequency,
                      singlePremium,
                      isGetPremium,
                      isCalculateSustainability,
                      term,
                    } = formValues;
                    const isQuoteFormChanged = checkQuoteFormChanged(
                      { ...formValues },
                      initProductConfig,
                    );
                    const sumRegularPremiumSlices = regularPremiumSlices?.length > 0 && regularPremiumSlices.reduce(
                      (accumulator, currentValue) =>
                        accumulator + currentValue.annualPremium,
                      0,
                    );
                    const isGetSustainAgeWithInvalidTerm =
                      sumRegularPremiumSlices !== 0 &&
                      initProductConfig.premium === 0 &&
                      Number(premium) === 0;

                    if (isQuoteFormChanged || isGetPremium || isAddedNewLife) {
                      setQuoteSubmit(true);
                    }

                    if (isGetPremium || isCalculateSustainability) {
                      const submitCoveragesValues = getCoveragesSubmitValue(
                        formValues,
                        isAddedNewLife,
                      );

                      let submitRPPremiumSlices = [...regularPremiumSlices];
                      if (isCalculateSustainability) {
                        submitRPPremiumSlices = getPremiumSlicesSubmitValue(
                          regularPremiumSlices,
                          paymentFrequency,
                          Number(premium),
                        );
                      }
                      const submitSPPremiumSlices = getSinglePremiumSlicesSubmitValue(
                        singlePremiumSlices,
                        singlePremium,
                      );

                      if (
                        isGetSustainAgeWithInvalidTerm &&
                        isCalculateSustainability &&
                        Number(term) === initProductConfig.term
                      ) {
                        submitRPPremiumSlices[
                          submitRPPremiumSlices.length - 1
                        ].annualPremium = 0;
                      }

                      resetProductConfigs({
                        ...formValues,
                        coverages: submitCoveragesValues,
                        regularPremiumSlices: submitRPPremiumSlices,
                        singlePremiumSlices: submitSPPremiumSlices,
                        paymentFrequency,
                        policyTerm,
                      });

                      return calculateQuote();
                    }

                    if (!isGetPremium && !isCalculateSustainability) {
                      let submitRPPremiumSlices = [...regularPremiumSlices];

                      if (
                        isManualPremium ||
                        [
                          ACTION_EDIT_QUOTE.GET_SUSTAIN_AGE,
                          ACTION_EDIT_QUOTE.SAVE_QUOTE,
                          ACTION_EDIT_QUOTE.GET_ASP_WOL,
                        ].includes(lastActionEditQuote)
                      ) {
                        submitRPPremiumSlices = getPremiumSlicesSubmitValue(
                          regularPremiumSlices,
                          productConfig.paymentFrequency,
                          premium,
                        );
                      } else {
                        submitRPPremiumSlices[
                          submitRPPremiumSlices.length - 1
                        ].annualPremium = 0;
                      }

                      if (
                        isGetSustainAgeWithInvalidTerm &&
                        Number(term) === initProductConfig.term
                      ) {
                        submitRPPremiumSlices[
                          submitRPPremiumSlices.length - 1
                        ].annualPremium = 0;
                      }

                      resetProductConfigs({
                        ...productConfig,
                        regularPremiumSlices: submitRPPremiumSlices,
                        paymentFrequency: productConfig.paymentFrequency,
                      });
                      return saveQuote({ isLifeAssuredEqual: false });
                    }
                  }}
                  offerCodeData={offerCodeData}
                />
              )}
            />
            <Route
              path="/ifi/review"
              render={() => <FuturaReview
                initProductConfig={initProductConfig}
                location={location} />
              }
            />
            <Route
              path={`/ifi/${url}/review`}
              render={() => <WapReview location={location} isWap={isWap} wapProductConfigs={isWapProductConfig} />}
            />
            
            {/* {(!isEmpty(productConfig)) && isWap !=null ?
              isWap ? ( // PolicyDetailsPage is called if the product is WAP
              <Route path="/" render={() => <PolicyDetailsPage isWap={isWap} url={url} />} />
            ) : ( // FuturaPolicyDetailsPage is called if the product is Futura
              <Route path="/" render={() => <FuturaPolicyDetailsPage />} />
            ): null} */}
            {(!isEmpty(productConfig) && isWap) &&
              <Route path="/" render={() => <PolicyDetailsPage isWap={isWap} url={url} />} />
            }
            {  
            // FuturaPolicyDetailsPage is called if the product is Futura
              (!isEmpty(productConfig) && productCodeCheck('FUTU',productConfig?.productCode)) &&<Route path="/" render={() => <FuturaPolicyDetailsPage />} />}
               {console.log("productConfig =>", productConfig)}
            </Switch>
        </Grid>
      </FormMainBody>
      {location.pathname !== '/ifi' && (
        <FormFooter
          onClickPrevious={() => {
            switch (location.pathname) {
              case '/ifi/insured':
                history.replace('/ifi', location.state);
                break;
              case '/ifi/quote':
                history.replace('/ifi/insured', location.state);
                break;
              case '/ifi/review':
                history.replace('/ifi/quote', location.state);
                break;
              case `/ifi/${url}/insured`:
                history.replace('/ifi', location.state);
                break;
              case `/ifi/${url}/coverage`:
                history.replace(`/ifi/${url}/insured`, location.state);
                break;
              case `/ifi/${url}/quote`:
                history.replace(`/ifi/${url}/coverage`, location.state);
                break;
              case `/ifi/${url}/review`:
                history.replace(`/ifi/${url}/quote`, location.state);
                break;
              default:
                break;
            }
            return changeSubPage(subPage - 1);
          }}
          onClickExit={() => {
            setCompletedDialog({
              open: true,
              message: 'EXIT',
            });
          }
          }
          onClickNext={async () => {
            switch (location.pathname) {
              case '/ifi/insured':
                submitLifeAssured();
                break;
              case `/ifi/${url}/insured`:
                submitLifeAssured();
                break;
              case '/ifi/quote': {
                await changeIsGetPremium(false);
                await changeIsCalculateSustainability(false);
                submitQuote();
                break;
              }
              case '/ifi/review':
                updateStatus(2);
                break;
              case `/ifi/${url}/quote`: {
                await changeIsGetPremium(false);
                submitQuote();
                break;
              }
              case `/ifi/${url}/review`:
                updateStatus(2);
                break;
              case `/ifi/${url}/coverage`:
                submitCoverage()
                break;
              default:
                break;
            }
          }}
          disabledPrevious={
            location.pathname === '/ifi/insured' ||
            location.pathname === '/ifi/review' ||
            location.pathname === `/ifi/${url}/insured` ||
            location.pathname === `/ifi/${url}/review`

          }
          disabledNext={
            (location.pathname === '/ifi/quote' &&
              (!isQuoteSubmit || policyTerm === null)) ||
            (location.pathname === '/ifi/review' && status !== 'PREVIEW') ||
            // (location.pathname === '/ifi/review' && productConfig?.premium == "0") ||  // disable agree and proceed if premium is 0
            (location.pathname === '/ifi/review' && (productConfig?.premium == "0" &&  productConfig?.singlePremium <= 0)) ||  // disable agree and proceed if asp is 0 and premium is 0
            (location.pathname === `/ifi/${url}/review` && status !== 'PREVIEW') ||
            (location.pathname === `/ifi/${url}/review` && !((isEmpty(productConfig?.premium) === false || productConfig?.additionalSinglePremium?.amount > 0) && productConfig?.isRegularWithdrawal === false && isEmpty(productConfig?.adHocwithdrawl) === true)) ||
            (location.pathname === '/ifi/quote' &&
              !isEmpty(offerCodeErrorMsg)) ||
            (location.pathname === '/ifi/quote' && errorData.error)
          }
          label1={<FormattedMessage {...globalMessages.previous} />}
          label2={<FormattedMessage {...globalMessages.exit} />}
          label3={<FormattedMessage {...getFooterLabel3()} />}
        />
      )}

      <Dialog
        open={completedDialog.open}
        onClose={() =>
          setCompletedDialog({
            ...completedDialog,
            open: false,
          })
        }
        onExited={() =>
          setCompletedDialog({
            ...completedDialog,
            message: null,
          })
        }
        variant={completedDialog.message === 'EXIT' ? 'error' : 'success'}
        title={
          <FormattedMessage
            {...(completedDialog.message === 'EXIT'
              ? globalMessages.warning
              : messages.added)}
          />
        }
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            {completedDialog.message === 'ADDED' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setCompletedDialog({
                      open: false,
                      message: null,
                    });
                    changeSubPage(0);
                    history.replace('/illustrations');
                  }}
                >
                  <FormattedMessage {...messages.clientList} />
                </Button>
              </Grid>
            )}
            {completedDialog.message === 'EXIT' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setCompletedDialog({
                      open: false,
                      message: null,
                    });
                    if (completedDialog.message === 'ADDED') {
                      cloneQuote(2);
                    } else if (completedDialog.message === 'EXIT') {
                      changeSubPage(0);
                      history.replace('/illustrations');
                    }
                  }}
                >
                  <FormattedMessage {...globalMessages.exit} />
                </Button>
              </Grid>
            )}
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setCompletedDialog({
                    open: false,
                    message: null,
                  });
                  if (
                    completedDialog.message === 'ADDED' &&
                    !isEmpty(quoteId)
                  ) {
                    // condition check to see whether the product is WAP or SAW
                    if (productCodeCheck(6, productConfig?.productCode)) {
                      history.replace(`/ifi/${url}/review`, location.state);
                    } else { // flow for Futura product
                      history.replace('/ifi/review', location.state);
                    }
                  }
                }}
              >
                <FormattedMessage {...messages.continue} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage
            {...(completedDialog.message === 'EXIT'
              ? messages.exitQuoteNotice
              : messages.addedInfo)}
          />
        </Typography>
      </Dialog>
    </>
  );
}

InforceIllutrationPage.propTypes = {
  lifeAssured: PropTypes.object,
  quoteId: PropTypes.string,
  applicationId: PropTypes.string,
  productConfig: PropTypes.object,
  policyConfig: PropTypes.object,
  setCoverage: PropTypes.func,
  setQuoteId: PropTypes.func,
  saveQuote: PropTypes.func,
  calculateQuote: PropTypes.func,
  subPage: PropTypes.number,
  changeSubPage: PropTypes.func,
  getQuote: PropTypes.func,
  updateStatus: PropTypes.func,
  changeSubPageList: PropTypes.func,
  changeLifeAssured: PropTypes.func,
  resetProductConfigs: PropTypes.func,
  submitLifeAssured: PropTypes.func,
  submitCoverage: PropTypes.func,
  submitQuote: PropTypes.func,
  wapConfig: PropTypes.object,
  submitBenefitRating: PropTypes.func,
  changePolicyCoverTerm: PropTypes.func,
  options: PropTypes.object,
  errorData: PropTypes.object,
  setError: PropTypes.func,
  status: PropTypes.string,
  resetData: PropTypes.func,
  createApplication: PropTypes.func,
  resetProductConfig: PropTypes.func,
  agentDetails: PropTypes.object,
  setPtvVersion: PropTypes.func,
  callFia: PropTypes.func,
  fiaData: PropTypes.object,
  cloneQuote: PropTypes.func,
  cloneStatus: PropTypes.bool,
  changeCloneStatus: PropTypes.func,
  offerCodeData: PropTypes.object,
  completedDialog: PropTypes.object,
  setCompletedDialog: PropTypes.func,
  isCalculateQuotePage: PropTypes.bool,
  offerCodeErrorMsg: PropTypes.string,
  setProductCode: PropTypes.func,
  productConfig2: PropTypes.object,
  changeCoverage: PropTypes.func,
  biTableValues: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  subPage: makeSelectSubPage(), // redux data fetching
  fiaData: makeSelectFiaCharge(),
  quoteId: makeSelectQuoteId(),
  lifeAssured: makeSelectLifeAssured(),
  productConfig: makeSelectProductConfig(),
  policyConfig: makeSelectPolicyConfig(),
  options: makeSelectOption(),
  errorData: makeSelectError(),
  status: makeSelectStatus(),
  applicationId: makeSelectApplicationId(),
  agentDetails: makeSelectAgentDetails(),
  cloneStatus: makeSelectCloneStatus(),
  offerCodeData: makeSelectOfferCodeData(),
  completedDialog: makeSelectCompletedDialog(),
  isCalculateQuotePage: makeSelectIsCalculateQuotePage(),
  offerCodeErrorMsg: makeSelectErrorOfferCodeData(),
  harvestData: makeSelectHarvestData(),
  sustainabilityYears: makeSelectSustainabilityYears(),
  initProductConfig: makeSelectInitProductConfig(),
  policyTerm: makeSelectPolicyTerm(),
  isManualPremium: makeSelectManualPremium(),
  countOfCallingSustainAge: makeSelectCountOfCallingSustainAge(),
  premiumState: makeSelectPremium(),
  productConfig2: makeSelectProductConfig2(),
  wapConfig: makeSelectConfig(),
  lastActionEditQuote: makeSelectLastActionEditQuote(),
  biTableValues: makeSelectBiTableValues(),
});

function mapDispatchToProps(dispatch) {
  return {
    saveQuote: value => dispatch(createQuoteAction(value)),
    setCoverage: value => dispatch(setCoveragesListAction(value)),
    calculateQuote: value => dispatch(calculateQuoteAction(value)),
    changeSubPage: value => dispatch(changeSubPageAction(value)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    changeLifeAssured: value => dispatch(ChangeLifeAssuredAction(value)),
    changePolicyCoverTerm: val => dispatch(setPremiumTermAction(val)),
    cloneQuote: value => dispatch(cloneQuoteAction(value)),
    changeCloneStatus: value => dispatch(setCloneStatusAction(value)),
    resetProductConfigs: (
      value,
      reacalculateQuote = false,
      reverseCalculate = false,
      isCalculateQuotePage = false,
      isLifeAssuredEqual = false,
      isCheckOfferCode = false,
    ) =>
      dispatch(
        resetProductConfigsAction(
          value,
          reacalculateQuote,
          reverseCalculate,
          isCalculateQuotePage,
          isLifeAssuredEqual,
          isCheckOfferCode,
        ),
      ),
    updateStatus: val => dispatch(updateStatusAction(val)),
    getQuote: value => dispatch(getQuoteAction(value)),
    setQuoteId: value => dispatch(setQuoteAction(value)),
    resetData: () => dispatch(resetAction()),
    setError: value => dispatch(setErrorAction(value)),
    resetProductConfig: () => dispatch(resetProductConfigAction()),
    createApplication: value => dispatch(createApplicationAction(value)),
    setPtvVersion: value => dispatch(setPtvVersionAction(value)),
    submitLifeAssured: () => dispatch(submit('lifeAssuredForm')),
    submitCoverage: () => dispatch(submit('coverageForm')),
    submitBenefitRating: () => dispatch(submit('benefitRatingForm')),
    submitQuote: () => dispatch(submit('quoteForm')),
    setCompletedDialog: value => dispatch(setCompletedDialogAction(value)),
    setProductCode: value => dispatch(setProductCodeAction(value)),
    changeIsGetPremium: value =>
      dispatch(change('quoteForm', 'isGetPremium', value)),
    changeIsCalculateSustainability: value =>
      dispatch(change('quoteForm', 'isCalculateSustainability', value)),
    setCountGetSustainAge: value =>
      dispatch(setCountGetSustainAgeAction(value)),
    changeCoverage: (
      value,
      reacalculateQuote = false,
      reverseCalculate = false,
      isLifeAssuredEqual = false,
      isGetPremium = false,
    ) =>
      dispatch(
        ChangeCoverageAction2(
          value,
          reacalculateQuote,
          reverseCalculate,
          isLifeAssuredEqual,
          isGetPremium
        ),
      ),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(InforceIllutrationPage);

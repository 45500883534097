import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  fill: currentColor;
`;

function IconClose(props) {
  return (
    <SvgIcon xviewBox="0 0 24 24" version="1.1" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <GA transform="translate(-967.000000, -22.000000)">
          <g transform="translate(967.000000, 22.000000)">
            <circle id="Oval-42" stroke="#DDDFE0" strokeWidth="1" fill="#FFFFFF" cx="12" cy="12" r="11.5" />
            <path d="M12,5.99695167 C12.4736274,5.99695167 12.8575783,6.38090257 12.8575783,6.85453001 L12.8569517,11.1419517 L17.14547,11.1424217 C17.6190974,11.1424217 18.0030483,11.5263726 18.0030483,12 C18.0030483,12.4736274 17.6190974,12.8575783 17.14547,12.8575783 L12.8569517,12.8569517 L12.8575783,17.14547 C12.8575783,17.6190974 12.4736274,18.0030483 12,18.0030483 C11.5263726,18.0030483 11.1424217,17.6190974 11.1424217,17.14547 L11.1419517,12.8569517 L6.85453001,12.8575783 C6.38090257,12.8575783 5.99695167,12.4736274 5.99695167,12 C5.99695167,11.5263726 6.38090257,11.1424217 6.85453001,11.1424217 L11.1419517,11.1419517 L11.1424217,6.85453001 C11.1424217,6.38090257 11.5263726,5.99695167 12,5.99695167 Z" id="Combined-Shape" fill="#4066B3" transform="translate(12.000000, 12.000000) rotate(-315.000000) translate(-12.000000, -12.000000) " />
          </g>
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconClose;

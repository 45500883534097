import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const styles = theme => ({
  tooltip: {
    backgroundColor: '#a3b2bf',
    color: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
});

export default withStyles(styles)(Tooltip);

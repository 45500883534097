import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash/lang';
import { convertIfImmutable, changeToTitleCase } from 'helpers/lang';
import { Field } from 'redux-form/immutable';
import globalMessages from 'containers/App/messages';
import messages from 'containers/ApplicationFormPage/messages';
import {
  // Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  getFormSyncErrors,
  InjectedFormProps,
} from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//components
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';

import { makeSelectOption } from 'containers/App/selectors';

//validate
import validate from './DoctorFormValidate';

const useStyle = makeStyles(theme => ({
  wrapper: { width: '660px' },
}));

const lessThan = otherField => (value, previousValue, allValues) => {
  if(!value) return '';
  return value.length <= otherField ? value : previousValue;
}

function DoctorForm(props) {
  const classes = useStyle();

  const {
    initialValues,
    change,
    handleSubmit,
    onSubmit,
    formValues,
    options,
  } = props;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Field
              name="firstName"
              component={FormTextField}
              label={<FormattedMessage {...globalMessages.firstName} />}
              fullWidth
              normalize={lessThan(45)}
              format = {(value) => changeToTitleCase(value)} 
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="lastName"
              component={FormTextField}
              label={<FormattedMessage {...globalMessages.lastName} />}
              fullWidth
              normalize={lessThan(40)}
              format = {(value) => changeToTitleCase(value)} 
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="facilityName"
              component={FormTextField}
              label={<FormattedMessage {...messages.medicalFacility} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="phoneNumber"
              component={FormNumberField}
              thousandSeparator={''}
              label={<FormattedMessage {...messages.phoneNumber} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="emailAddress"
              component={FormTextField}
              label={<FormattedMessage {...messages.emailAddress} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="addressLine1"
              component={FormTextField}
              label={<FormattedMessage {...messages.addressLine1} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name="addressLine2"
              component={FormTextField}
              label={<FormattedMessage {...messages.addressLine2} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <Field
              name="city"
              component={FormTextField}
              label={<FormattedMessage {...messages.city} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Field
              name="stateOrProvince"
              component={FormTextField}
              label={<FormattedMessage {...messages.stateOrProvince} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="zipCode"
              component={FormNumberField}
              thousandSeparator={''}
              label={<FormattedMessage {...messages.zipCode} />}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name="country"
              component={FormSelect}
              thousandSeparator={''}
              options={options.countryCode}
              label={<FormattedMessage {...messages.country} />}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}

DoctorForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
});

const withConnect = connect(mapStateToProps);

const withFormSelector = connect(state => {
  const formValues = getFormValues('DoctorForm')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
  };
});

export default reduxForm({
  validate,
  form: 'DoctorForm',
})(compose(withConnect, withFormSelector)(DoctorForm));
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Box } from '@material-ui/core';
import { Info, CheckCircle, Cancel } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';
import InfoRow from 'components/InfoRow';
import { useTheme } from '@material-ui/core/styles';
import globalMessages from 'containers/App/messages';
import { formatBenefitReview } from 'helpers/quote';
import { productCodeCheck } from 'helpers/constants';
const getBrTitle = brValue => {
  switch (brValue) {
    case '0':
      return '';

    case '1':
      return 'Per Mille';

    case '2':
      return 'Percentage';

    case '3':
      return 'Period';

    default:
      return '';
  }
};

const convertProductConfigs = productConfigs => {
  const coverages = [];
  if (productConfigs?.coverages) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in productConfigs.coverages[0]) {
      if (key !== 'isPrimary') {
        const coverageLife1 = productConfigs.coverages[0][key];
        const benefit = {
          id: key,
          title: (coverageLife1.name === 'Life Cover and PTD' && productCodeCheck(8, productConfigs?.productCode)) ? 'Future Premium Protection' : coverageLife1.name,
        };
        if (coverageLife1 && coverageLife1.selected) {
          benefit.valueInsuredOne = coverageLife1.value;
          benefit.selected = true;
          benefit.perMileOne = coverageLife1?.permanent?.perMille;
          benefit.perPercentageOne = coverageLife1?.permanent?.perPercentage;
          benefit.perTempMileOne = coverageLife1?.temporary?.perMille;
          benefit.perTempMonthOne = coverageLife1?.temporary?.time;

          if (coverageLife1?.permanent?.perMille && (!productCodeCheck(8, productConfigs?.productCode))) {
            benefit.brSelectedInsuredOneValue = true;
          }

          if (coverageLife1?.permanent?.perPercentage && (!productCodeCheck(8, productConfigs?.productCode))) {
            benefit.brSelectedPercentValueOne = true;
          }

          if (coverageLife1?.temporary?.perMille && (!productCodeCheck(8, productConfigs?.productCode))) {
            benefit.brSelectedTempValueOne = true;
          }
        }
        const coverageLife2 =
          productConfigs.coverages[1] && productConfigs.coverages[1][key];

        if (coverageLife2 && coverageLife2.selected) {
          benefit.selected = true;
          benefit.valueInsuredTwo = coverageLife2.value;
          benefit.perMileTwo = coverageLife2?.permanent?.perMille;
          benefit.perPercentageTwo = coverageLife2?.permanent?.perPercentage;
          benefit.perTempMileTwo = coverageLife2?.temporary?.perMille;
          benefit.perTempMonthTwo = coverageLife2?.temporary?.time;

          if (coverageLife2?.permanent?.perMille && (!productCodeCheck(8, productConfigs?.productCode))) {
            benefit.brSelectedInsuredTwoValue = true;
          }

          if (coverageLife2?.permanent?.perPercentage && (!productCodeCheck(8, productConfigs?.productCode))) {
            benefit.brSelectedPercentValueTwo = true;
          }

          if (coverageLife2?.temporary?.perMille && (!productCodeCheck(8, productConfigs?.productCode))) {
            benefit.brSelectedTempValueTwo = true;
          }
        }

        coverages.push(benefit);
      }
    }

    const specialTermsData = [];
    const standardData = [];
    coverages.forEach(cvg => {
      const isBenefitRating =
        !cvg.brSelectedInsuredOneValue &&
        !cvg.brSelectedInsuredTwoValue &&
        !cvg.brSelectedTempValueOne &&
        !cvg.brSelectedTempValueTwo &&
        !cvg.brSelectedPercentValueOne &&
        !cvg.brSelectedPercentValueTwo;

      if (isBenefitRating) {
        standardData.push(cvg);
      } else {
        specialTermsData.push(cvg);
      }
    });

    return { specialTermsData, standardData };
  }

  return {};
};

const BenefitRatingSummary = ({ productConfigs }) => {
  const theme = useTheme();
  const formatProductConfigs = convertProductConfigs(productConfigs);
  let { specialTermsData, standardData } = formatProductConfigs;
  if ((productCodeCheck(8, productConfigs?.productCode) && productConfigs?.specialTermsFlag === false)) {
    standardData = standardData?.filter(element => element?.id !== 'PTD')
  }
  const isInsuredTwo = productConfigs?.coverages?.length > 1;
  return ((formatProductConfigs && productConfigs?.specialTermsFlag) || (productCodeCheck(8, productConfigs?.productCode) && productConfigs?.specialTermsFlag === false)) ? (
    <>
      {specialTermsData?.length > 0 ? (
        <Box mb={5}>
          <Typography
            variant="h3"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.subMain,
            }}
          >
            <FormattedMessage {...globalMessages.specialTerms} />
          </Typography>

          {specialTermsData.map((cvg, index) => {
            const key = `$specTerms${index}`;
            return (
              <Grid container direction="column" key={key}>
                <InfoRow style={{ maxHeight: 64 }}>
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          style={{
                            color: theme.palette.grey[400],
                          }}
                        >
                          <Info color="primary" />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">{cvg.title}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {formatBenefitReview(
                          cvg,
                          cvg.valueInsuredOne,
                          productConfigs.currency,
                        )}
                      </Typography>
                    </Grid>

                    {isInsuredTwo ? (
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {formatBenefitReview(
                            cvg,
                            cvg.valueInsuredTwo,
                            productConfigs.currency,
                          )}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </InfoRow>

                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue ||
                  cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                    <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                      <Typography
                        variant="h4"
                        style={{
                          color: theme.palette.primary.dark,
                          paddingBottom: theme.spacing(1),
                          fontWeight: 'bold',
                        }}
                      >
                        <FormattedMessage {...globalMessages.permanent} />
                      </Typography>
                    </InfoRow>
                  )}
                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue) && (
                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('1')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          {!cvg.brSelectedInsuredOneValue ? (
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              -
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.brSelectedInsuredOneValue
                                ? `+${cvg.perMileOne.toFixed(2)}`
                                : `-`}
                            </Typography>
                          )}
                        </Grid>

                        {isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.brSelectedInsuredTwoValue
                                ? `+${cvg.perMileTwo.toFixed(2)}`
                                : `-`}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>
                  )}

                {(cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('2')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          {!cvg.brSelectedPercentValueOne ? (
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              -
                            </Typography>
                          ) : (
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.brSelectedPercentValueOne
                                ? `+${cvg.perPercentageOne}`
                                : '-'}
                            </Typography>
                          )}
                        </Grid>

                        {isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.brSelectedPercentValueTwo
                                ? `+${cvg.perPercentageTwo}`
                                : '-'}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>
                  )}

                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.dark,
                        paddingBottom: theme.spacing(1),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...globalMessages.temporary} />
                    </Typography>
                  </InfoRow>
                )}
                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <>
                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('1')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMileOne !== 0
                              ? `+${cvg.perTempMileOne.toFixed(2)}`
                              : '-'}
                          </Typography>
                        </Grid>

                        {isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMileTwo !== 0
                                ? `+${cvg.perTempMileTwo.toFixed(2)}`
                                : '-'}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>

                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('3')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMonthOne !== 0
                              ? `+${cvg.perTempMonthOne} months`
                              : '-'}
                          </Typography>
                        </Grid>

                        {isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMonthTwo !== 0
                                ? `+${cvg.perTempMonthTwo} month`
                                : '-'}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>
                  </>
                )}
              </Grid>
            );
          })}
        </Box>
      ) : null}
      {/* ########## END    :: SPECIAL TERMS DETAILS ########## */}
      {/* ########## START  :: COVER DETAILS ########## */}
      {standardData?.filter(item => item.selected).length > 0 && (
        <Box mb={5}>
          <Typography
            variant="h3"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.subMain,
            }}
          >
            <FormattedMessage {...globalMessages.coverDetails} />
          </Typography>

          {standardData.map(cvg => {
            if (!cvg.selected) {
              return null;
            }
            return (
              <Grid container direction="column" key={cvg.id}>
                <InfoRow style={{ maxHeight: 64 }}>
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          style={{
                            color: theme.palette.grey[400],
                          }}
                        >
                          {cvg.selected ? (
                            <CheckCircle color="inherit" />
                          ) : (
                            <Cancel color="inherit" />
                          )}
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">{cvg.title}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      {(!productCodeCheck(8, productConfigs?.productCode)) &&
                        < Typography variant="body2">
                          {formatBenefitReview(
                            cvg,
                            cvg.valueInsuredOne,
                            productConfigs.currency,
                          )}
                        </Typography>
                      }
                    </Grid>

                    {isInsuredTwo ? (
                      <Grid item xs={3}>
                        {(!productCodeCheck(8, productConfigs?.productCode)) &&
                          <Typography variant="body2">
                            {formatBenefitReview(
                              cvg,
                              cvg.valueInsuredTwo,
                              productConfigs.currency,
                            )}
                          </Typography>
                        }
                      </Grid>
                    ) : null}
                  </Grid>
                </InfoRow>
              </Grid>
            );
          })}
        </Box >
      )}
    </>
  ) : (
    <></>
  );
};

BenefitRatingSummary.propTypes = {
  productConfigs: PropTypes.object,
};

export default BenefitRatingSummary;

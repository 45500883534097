/*
 *
 * ApplicationPage actions
 *
 */

import {
  DEFAULT_ACTION,
  SET_UNDERWRITING_SECTIONS,
  CALL_INITIAL_SESSION,
  SET_SESSION_ID,
  SET_ETAG,
  SET_APPLCATION_SUBORDERS,
  GET_APPLICATION_LIST_ACTION,
  SET_APPLICATION_LIST_ACTION,
  GET_APPLICATION_DETAILS_ACTION,
  SET_APPLICATION_DETAILS_ACTION,
  UPDATE_APPLICATION_DETAILS_ACTION,
  UPDATE_APPLICATION_DETAILS_AND_STATUS_ACTION,
  UPDATE_APPLICATION_PARTY_ACTION,
  UPDATE_APPLICATION_PARTIES_ACTION,
  ADD_APPLICATION_PARTY_ACTION,
  ADD_APPLICATION_PARTIES_ACTION,
  DELETE_APPLICATION_PARTY_ACTION,
  DELETE_APPLICATION_PARTIES_ACTION,
  UPDATE_APPLICATION_STATUS_ACTION,
  SET_LIFE_INSURED_PARTIES_ACTION,
  CALL_FINANCIAL_INSTITUTE_OPTION,
  SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION,
  SET_LIST_APPLICATION_PARTY_ACTION,
  GET_LIST_APPLICATION_PARTY_ACTION,
  DELETE_APPLICATION_ACTION,
  SET_DELETE_APPLICATION_ACTION,
  SET_LOGOUT_WHEN_UPDATE_APP_ACTION,
  SET_ADMIN_ACTION_DETAIL_ACTION,
  GET_ADMIN_ACTION_DETAIL_ACTION
} from './constants';

//INITIAL SESSION
export function callInitialSessionAction(payload) {
  return {
    type: CALL_INITIAL_SESSION,
    payload,
  };
}

export function setSessionId(payload) {
  return {
    type: SET_SESSION_ID,
    payload,
  };
}

export function setETag(payload) {
  return {
    type: SET_ETAG,
    payload,
  };
}

export function setApplicationSubOrders(payload) {
  return {
    type: SET_APPLCATION_SUBORDERS,
    payload,
  };
}
export function setUnderwritingSections(payload) {
  return {
    type: SET_UNDERWRITING_SECTIONS,
    payload,
  };
}

export function setLIPartiesAction(payload) {
  return { type: SET_LIFE_INSURED_PARTIES_ACTION, payload };
}

//APPLICATION DETAILS
export function getApplicationDetailsAction(payload) {
  return { type: GET_APPLICATION_DETAILS_ACTION, payload };
}
export function setApplicationDetailsAction(payload) {
  return { type: SET_APPLICATION_DETAILS_ACTION, payload };
}

export function updateApplicationDetailsAction(payload, isLogout) {
  return {
    type: UPDATE_APPLICATION_DETAILS_ACTION,
    payload,
    isLogout,
  };
}

//  to solve async issue of update application and update status API call
export function updateApplicationDetailsAndStatusAction(payload, isLogout) {
  return {
    type: UPDATE_APPLICATION_DETAILS_AND_STATUS_ACTION,
    payload,
    isLogout,
  };
}

export function updateApplicationStatusAction(payload) {
  return {
    type: UPDATE_APPLICATION_STATUS_ACTION,
    statusName: payload.statusName,
    status: payload.status,
    applicationId: payload.applicationId,
    filteredFundList: payload.filteredFundList,
    nextPage: payload.nextPage,
    isDelay: payload.isDelay
  };
}

//APPLICATION PARTY

export function updateApplicationPartyAction(payload) {
  return {
    type: UPDATE_APPLICATION_PARTY_ACTION,
    party: payload.party,
    applicationId: payload.applicationId,
  };
}

export function updateApplicationPartiesAction(payload) {
  return {
    type: UPDATE_APPLICATION_PARTIES_ACTION,
    parties: payload.parties,
    applicationId: payload.applicationId,
  };
}

export function addApplicationPartyAction(payload) {
  return {
    type: ADD_APPLICATION_PARTY_ACTION,
    party: payload.party,
    applicationId: payload.applicationId,
  };
}

export function addApplicationPartiesAction(payload) {
  return {
    type: ADD_APPLICATION_PARTIES_ACTION,
    parties: payload.parties,
    applicationId: payload.applicationId,
  };
}

export function deleteApplicationPartyAction(payload) {
  return {
    type: DELETE_APPLICATION_PARTY_ACTION,
    party: payload.party,
    applicationId: payload.applicationId,
  };
}

export function deleteApplicationPartiesAction(payload) {
  return {
    type: DELETE_APPLICATION_PARTIES_ACTION,
    parties: payload.parties,
    applicationId: payload.applicationId,
  };
}

export function callFinancialInstituteOptionAction() {
  return {
    type: CALL_FINANCIAL_INSTITUTE_OPTION,
  };
}

//APPLICATION LIST
export function getApplicationListAction(payload) {
  return { type: GET_APPLICATION_LIST_ACTION, payload };
}

export function setApplicationListAction() {
  return { type: SET_APPLICATION_LIST_ACTION };
}

export function setFinancialInstituteOptionAction(payload) {
  return { type: SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION, payload };
}

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function getListApplicationsPartyAction(payload) {
  return { type: GET_LIST_APPLICATION_PARTY_ACTION, payload };
}

export function setListApplicationPartyAction(payload) {
  return {
    type: SET_LIST_APPLICATION_PARTY_ACTION,
    payload,
  };
}

export function deleteApplicationAction(payload) {
  return {
    type: DELETE_APPLICATION_ACTION,
    payload,
  };
}

export function setDeleteApplicationAction(payload) {
  return {
    type: SET_DELETE_APPLICATION_ACTION,
    payload,
  };
}

export function setLogoutWhenUpdateAppAction(payload) {
  return {
    type: SET_LOGOUT_WHEN_UPDATE_APP_ACTION,
    payload,
  };
}


export function getAdminActionDetailAction(payload) {
  return {
    type: GET_ADMIN_ACTION_DETAIL_ACTION,
    payload: payload
  };
}
export function setAdminActionDetailAction(payload) {
  return {
    type: SET_ADMIN_ACTION_DETAIL_ACTION,
    payload: payload
  };
}
/**
 *
 * FormSelect
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { WrappedFieldProps } from 'redux-form';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Helper from 'components/Helper';

import { TextField, Chip, Typography, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import TextField from 'components/TextField';
import { isEqual } from 'lodash/lang';
import { isImmutable } from 'immutable';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertIfImmutable } from 'helpers/lang';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';

const CustomAutoComplete = withStyles({
  inputRoot: {
    borderRadius: '10px',
  },
})(props => <Autocomplete {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    // width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: '10px',
  },
  title: {
    lineHeight: '24px',
    fontWeight: 800,
    color: theme.palette.primary.dark,
  },
}));

const SearchInput = ({
  searchAnswer,
  question,
  questions,
  section,
  input,
  required,
  meta: { invalid, error, touched, warning },
  helperText,
  sessionId,
  sectionName,
  ...custom
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState([]);

  useEffect(() => {
    setValue(getValue());
  }, [question]);

  let helperMsg = helperText;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  const getValue = () => {
    if (isImmutable(input.value)) {
      return input.value.toJS();
    } else if (input.value.length > 0) {
      return input.value;
    } else {
      return [];
    }
  };
  const defaultProps = {
    options: question.option ? question.option : [],
    getOptionLabel: option => option.text,
  };
  return (
    <div className={classes.root}>
      {isFirstChild(questions, question) && (
        <>
          <Typography variant="h2" className={classes.title2}>
            {question.context[0]}
          </Typography>

          <Divider style={{ margin: '1rem 0' }} />
        </>
      )}
      <Typography variant="body2" className={classes.title}>
        {question.text}
        {question.helpText.length > 0 && (
          <Helper
            // helperText={'This is a sample text'}
            helperText={question.helpText}
          />
        )}
      </Typography>
      <CustomAutoComplete
        className={classes.input}
        fullWidth
        multiple
        id="tags-outlined"
        // value={value}
        loading={loading}
        loadingText={<CircularProgress />}
        {...defaultProps}
        noOptionsText={'Search for a keyword'}
        onChange={(event, value) => {
          return input.onChange(value);
        }}
        onInputChange={(e, v, r) => {
          setLoading(true);
          if (v.length > 1) {
            searchAnswer({
              value: v,
              questionNumber: question.id,
              section,
              sessionId,
              sectionName,
            });
          }
        }}
        filterSelectedOptions
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            error={!!warning || (touched && invalid)}
            helperText={helperMsg}
          />
        )}
      />
      {helperText}
    </div>
  );
};

SearchInput.propTypes = {
  ...WrappedFieldProps,
  helperText: PropTypes.node,
};

export default SearchInput;

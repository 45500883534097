/*
 *
 * IllustrationPage constants
 *
 */

export const GET_PRODUCT_CONFIG = 'app/IllustrationPage/GET_PRODUCT_CONFIG';
export const SET_PRODUCT_CONFIG = 'app/IllustrationPage/SET_PRODUCT_CONFIG';
export const SET_PRODUCT_CONFIG2 = 'app/IllustrationPage/SET_PRODUCT_CONFIG2';
export const GET_PRODUCT_ID = 'app/IllustrationPage/GET_PRODUCT_ID';
export const SET_PRODUCT_ID = 'app/IllustrationPage/SET_PRODUCT_ID';
export const RESET_PRODUCT_CONFIG = 'app/IllustrationPage/RESET_PRODUCT_CONFIG';
export const CLONE_PRODUCT = 'app/IllustrationPage/CLONE_PRODUCT';

export const FETCH_ILLUSTRATIONS_ACTION =
  'app/IllustrationPage/FETCH_ILLUSTRATIONS_ACTION';
export const SET_ILLUSTRATIONS_ACTION =
  'app/IllustrationPage/SET_ILLUSTRATIONS_ACTION';

export const GET_CUSTOMERS_ACTION = 'app/IllustrationPage/GET_CUSTOMERS_ACTION';
export const SET_CUSTOMERS_ACTION = 'app/IllustrationPage/SET_CUSTOMERS_ACTION';

export const GET_QUOTES_ACTION = 'app/IllustrationPage/GET_QUOTES_ACTION';
export const SET_QUOTES_ACTION = 'app/IllustrationPage/SET_QUOTES_ACTION';
export const SET_ERROR_ACTION = 'app/IllustrationPage/SET_ERROR_ACTION';
export const SEND_EMAIL_ACTION = 'app/IllustrationPage/SEND_EMAIL_ACTION';

export const GET_SEARCH_ACTION = 'app/IllustrationPage/GET_SEARCH_ACTION';
export const SET_CUSTOMER_DETAIL_ACTION =
  'app/IllustrationPage/SET_CUSTOMER_DETAIL_ACTION';

export const DELETE_ILLUSTRATION_ACTION =
  'app/IllustrationPage/DELETE_ILLUSTRATION_ACTION';
export const SET_DELETE_ILLUSTRATION_ACTION =
  'app/IllustrationPage/SET_DELETE_ILLUSTRATION_ACTION';

import styled from 'styled-components';

const IFrameWrapper = styled.div`
  /* position: fixed;
  top: 50px;
  left: 0;
  height: calc(100% - 110px);
  width: 100%; */
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export default IFrameWrapper;

import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the wapIllustrationPage state domain
 */

const selectWapIllustrationPageDomain = state =>
  state.get('wapIllustrationPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by WapIllustrationPage
 */

const makeSelectWapIllustrationPage = () =>
  createSelector(selectWapIllustrationPageDomain, substate => substate.toJS());

const makeSelectLifeAssured = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('lifeAssured').toJS(),
  );

const makeSelectInitialLifeAssured = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('initialLifeAssured').toJS(),
  );

const makeSelectError = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('errorData').toJS(),
  );

const makeSelectConfig = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('wapConfig').toJS(),
  );

const makeSelectBiTableValues = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('biTableValues').toJS(),
  );

const makeSelectFiaCharge = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('fiaData').toJS(),
  );

const makeSelectProductConfig = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('productConfig').toJS(),
  );

const makeSelectQuoteId = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('quoteId'),
  );

const makeSelectApplicationId = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('applicationId'),
  );

const makeSelectBiPdf = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('benefitIllustrationPdf'),
  );

const makeSelectStatus = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('status'),
  );

const makeSelectReferenceNumber = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('referenceNumber'),
  );

const makeSelectCloneStatus = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('successClone'),
  );

const makeSelectemailSentStatus = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('emailSent'),
  );

const makeSelectEmailErrorMessage = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('emailErrorMessage'),
  );

const makeSelectFormInput = () =>
  createSelector(selectWapIllustrationPageDomain, substate =>
    substate.get('input').toJS(),
  );

export {
  selectWapIllustrationPageDomain,
  makeSelectWapIllustrationPage,
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectFiaCharge,
  makeSelectConfig,
  makeSelectError,
  makeSelectQuoteId,
  makeSelectApplicationId,
  makeSelectBiPdf,
  makeSelectStatus,
  makeSelectReferenceNumber,
  makeSelectInitialLifeAssured,
  makeSelectBiTableValues,
  makeSelectCloneStatus,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectFormInput,
};

/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import {
  SET_APP_LOADING_ACTION,
  SET_AUTH_ACTION,
  RESET_APP_DATA_ACTION,
  LOGOUT_ACTION,
  LOGOUT_SUCCESS_ACTION,
  SET_ERROR_ACTION,
  CLEAR_ERROR_ACTION,
  SET_RESOURCES_ACTION,
  CHANGE_SELECTED_AGENCY_ACTION,
  SET_AGENT_DETAILS_ACTION,
  GET_PERSISTED_AUTH_ACTION,
  GET_PERSISTED_AUTH_SUCCESS_ACTION,
  SET_SELECTED_AGENCY_ACTION,
  SET_FILE,
  SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION,
  SET_CSRF_ACTION,
  VALIDATE_OFFER_CODE_SUCCESS_ACTION,
  VALIDATE_OFFER_CODE_ACTION,
  RESET_VALIDATE_OFFER_CODE,
  SET_OFFER_CODE_ERROR_ACTION,
  SET_OFFER_CODE_VALIDATING_ACTION,
  SET_QUOTE_TYPE,
  QUOTE_TYPE,
} from './constants';

const defaultState = {
  loading: false,
  loadingMessage: {},
  error: {
    message: null,
    values: null,
  },
  selectedAgency: '0',
  agentDetails: {},
  // auth: {
  //   token: null,
  //   okta: null,
  //   loggingOut: false,
  //   agent: [],
  // },
  persistance: {
    loaded: false,
  },
  offerCodeData: {
    code: '',
    discountRate: 0,
    discountMessage: {
      english: '',
      arabic: '',
    },
    errorMessage: '',
    validating: false,
  },
  auth: {
    // token:
    //   'eyJraWQiOiJMbTlnTHA2eVZIYm0wZFliRkhkX1VjdW5HY2dOSFU0ejlwemV0VURybVBrIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULm5SNWx4cFpJMTg5ekk5WmR5R2pTSERUOXFEb1dJNGNNVFlPRUJRamgxWnciLCJpc3MiOiJodHRwczovL3p1cmljaHRlc3Qub2t0YXByZXZpZXcuY29tL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTU5Mjg4MTc1NiwiZXhwIjoxNTkyODg1MzU2LCJjaWQiOiIwb2FwcGh0eWhxbWhuMnhYSzBoNyIsInVpZCI6IjAwdXBsb3ViNmR0Y2RFUWc3MGg3Iiwic2NwIjpbIm9wZW5pZCIsImVtYWlsIiwicHJvZmlsZSJdLCJzdWIiOiJ0ZXN0MS4zNjBmQHp1cmljaC5jb20ifQ.SjMTq5Aqv5Ph4wVx9OCHUnrxkaYvO_PkP0RIr45Tr7lr8UtgqIF3SeeKPV62Ldg4bKNfVNYffM9mgO2RufEDqSyBTjvD3M4UMLoPIhWh1Ht_idJV9gBF7yPvi5ncMxSjEljhFDSAmwDH09K0syuRXNBxamSfWNmkEa8pcvePSX-6S9PA4-7m8QDRntp9UUU0CuRDwdzBo_8K0CWd0Pm4OgqQNc7McZTvjGfNybmTipvQ3IFD92ZPyLB7jW0OUbFa5raKm-n91Hw2Rw8vEhehkbYqQ_u6DHJakOogdLX0CuhzyCeZhPD7oupPMlEGNDv7CEuhFTm82S2G-oaficBLgQ',
    token: null,
    okta: null,
    loggingOut: false,
    agent: [],
    // token: 'TOKEN',
    // agent: [{
    //   "agentNumber": "8903069",
    //   "agentName": "METROPOLITAN INSURANCE BROKERS LLC",
    //   "ASCSalesChannel": "IFA",
    //   "ISOCountryCode": "AE",
    //   "region": "ME",
    //   "agentSUBChannel": "IFA",
    //   "agentStatus": "A",
    //   "products": [{
    //     "productId": "ITAS6",
    //     "PTVCode": "ITAS6",
    //     "PTVVersion": "",
    //     "effectiveFrom": "2018-10-01T00:00:00",
    //     "effectiveTo": "1899-12-30T00:00:00",
    //     "specialOffers": [{
    //       "specialOfferCode": "1FPFL",
    //       "specialOfferDesc": "1 free premium based on life cover",
    //       "offerStartDate": "2020-04-01T00:00:00",
    //       "offerEndDate": "2020-04-14T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "2FPME",
    //       "specialOfferDesc": "2 free premiums based on life cover",
    //       "offerStartDate": "2020-04-01T00:00:00",
    //       "offerEndDate": "2020-04-14T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "2FPME",
    //       "specialOfferDesc": "2 free premiums based on life cover",
    //       "offerStartDate": "2020-05-01T00:00:00",
    //       "offerEndDate": "2020-05-31T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "STF3P",
    //       "specialOfferDesc": "Distributor staff offer - 3 free premiums",
    //       "offerStartDate": "2020-05-05T00:00:00",
    //       "offerEndDate": "2020-06-15T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "1FPFL",
    //       "specialOfferDesc": "1 free premium based on life cover",
    //       "offerStartDate": "2020-06-01T00:00:00",
    //       "offerEndDate": "2020-06-30T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "2FPME",
    //       "specialOfferDesc": "2 free premiums based on life cover",
    //       "offerStartDate": "2020-06-01T00:00:00",
    //       "offerEndDate": "2020-06-30T23:59:59.997"
    //     }]
    //   }, {
    //     "productId": "FUTU5",
    //     "PTVCode": "FUTU5",
    //     "PTVVersion": "FUTU5_0001",
    //     "effectiveFrom": "2018-03-01T00:00:00",
    //     "effectiveTo": "1899-12-30T00:00:00",
    //     "specialOffers": [{
    //       "specialOfferCode": "1FPFL",
    //       "specialOfferDesc": "1 free premium based on life cover",
    //       "offerStartDate": "2020-04-01T00:00:00",
    //       "offerEndDate": "2020-04-14T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "2FPME",
    //       "specialOfferDesc": "2 free premiums based on life cover",
    //       "offerStartDate": "2020-04-01T00:00:00",
    //       "offerEndDate": "2020-04-14T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "2FPME",
    //       "specialOfferDesc": "2 free premiums based on life cover",
    //       "offerStartDate": "2020-05-01T00:00:00",
    //       "offerEndDate": "2020-05-31T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "STF3P",
    //       "specialOfferDesc": "Distributor staff offer - 3 free premiums",
    //       "offerStartDate": "2020-05-05T00:00:00",
    //       "offerEndDate": "2020-06-15T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "1FPFL",
    //       "specialOfferDesc": "1 free premium based on life cover",
    //       "offerStartDate": "2020-06-01T00:00:00",
    //       "offerEndDate": "2020-06-30T23:59:59.997"
    //     }, {
    //       "specialOfferCode": "2FPME",
    //       "specialOfferDesc": "2 free premiums based on life cover",
    //       "offerStartDate": "2020-06-01T00:00:00",
    //       "offerEndDate": "2020-06-30T23:59:59.997"
    //     }]
    //   }]
    // }],
  },
  options: {},
  file: {},
  financialInstituteOptions: [],
  csrf: null,
  quoteType: QUOTE_TYPE.NEW_BUSINESS,
};

// The initial state of the App
const initialState = fromJS({ ...defaultState });

function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_APP_LOADING_ACTION:
      return state
        .set('loading', action.payload.loading)
        .set('loadingMessage', fromJS({ ...action.payload.message }));
    case SET_AUTH_ACTION:
      return state.set('auth', fromJS(action.payload));
    case SET_RESOURCES_ACTION:
      return state.set('options', action.payload);
    case SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION:
      return state.set('financialInstituteOptions', fromJS(action.payload));
    case SET_ERROR_ACTION:
      return state.set('error', fromJS({ ...action.payload }));
    case CLEAR_ERROR_ACTION:
      return state.set('error', fromJS({ ...defaultState.error }));
    case LOGOUT_ACTION:
      return state.setIn(['auth', 'loggingOut'], true);
    case LOGOUT_SUCCESS_ACTION: {
      const { error } = action.payload;
      return state
        .set(
          'error',
          fromJS(
            error?.message
              ? {
                  ...error,
                }
              : {
                  message: null,
                  values: null,
                },
          ),
        )
        .setIn(['auth', 'token'], '')
        .setIn(['auth', 'okta'], null)
        .setIn(['auth', 'loggingOut'], false);
    }
    case RESET_APP_DATA_ACTION:
      return fromJS({ ...defaultState });
    case CHANGE_SELECTED_AGENCY_ACTION:
    case SET_SELECTED_AGENCY_ACTION:
      sessionStorage.setItem('selectedAgency', action.payload);
      return state.set('selectedAgency', action.payload);
    case SET_AGENT_DETAILS_ACTION:
      sessionStorage.setItem('agentDetails',  fromJS(action.payload).get('ISOCountryCode'));
      return state.set('agentDetails', fromJS(action.payload));
    case GET_PERSISTED_AUTH_ACTION:
      return state.setIn(['persistance', 'loaded'], false);
    case GET_PERSISTED_AUTH_SUCCESS_ACTION:
      return state.setIn(['persistance', 'loaded'], true);
    case SET_FILE:
      return state.set('file', fromJS(action.payload));
    // .setIn(['auth', 'okta', 'accessToken'], action.payload.oktaAccessToken)
    // .setIn(['selectedAgency'], action.payload.selectedAgency);
    case SET_CSRF_ACTION:
      return state.set('csrf', fromJS(action.payload));
    case VALIDATE_OFFER_CODE_SUCCESS_ACTION:
      return state.set('offerCodeData', fromJS(action.payload));
    case RESET_VALIDATE_OFFER_CODE:
      return state.set(
        'offerCodeData',
        fromJS({
          code: '',
          discountRate: 0,
          discountMessage: {
            english: '',
            arabic: '',
          },
          validating: false,
          errorMessage: '',
        }),
      );
    case VALIDATE_OFFER_CODE_ACTION:
      return state.setIn(['offerCodeData', 'validating'], true);
    case SET_OFFER_CODE_ERROR_ACTION: {
      return state.setIn(['offerCodeData', 'errorMessage'], action.payload);
    }
    case SET_OFFER_CODE_VALIDATING_ACTION: {
      return state.setIn(['offerCodeData', 'validating'], action.payload);
    }
    case SET_QUOTE_TYPE: {
      return state.set('quoteType', action.payload);
    }
    default:
      return state;
  }
}

export default appReducer;

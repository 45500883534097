import React, { Component } from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { forEach, find } from 'lodash/collection';
import { isBoolean, isEmpty } from 'lodash/lang';
import messages from '../../messages';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';

//COLOR
import { filteredParty } from 'helpers/lang';
import { findIndex } from 'lodash/array';

const StyledTableCell = withStyles(theme => ({
  root: {},
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(checked, benefitName, amount, secondAmount) {
  return { checked, benefitName, amount, secondAmount };
}

function getPremiumIndexValue(value) {
  if (isBoolean(value)) {
    return value ? 'Yes' : 'No';
  }
  if (!value) {
    return '-';
  }
  return `${value}%`;
}

function getCoverageDisplayValue(value, currency = '') {
  // if boolean, return yes/no
  if (isBoolean(value)) {
    return value ? 'Yes' : 'No';
  }
  // if everything else that is falsy, return -
  if (!value) {
    return '-';
  }
  // return formatted number otherwise + currency

  return `${currency} ${numeral(value).format('0,0')}`;
}

function PolicySummary(props) {
  const theme = useTheme();

  let { productConfigs, parties, title } = props;

  // check how many LIs are in the application to render the policy summary
  const lifeInsuredCount = filteredParty(parties, 'isLifeInsured')?.length;

  let newRows = [];

  // STEP 1: extract the coverages from primary LI data
  const primaryCoverages = find(
    productConfigs && productConfigs.coverages,
    coverages => {
      return coverages.isPrimary;
    },
  );
  // loop thru the coverages to populate the rows for initial data
  if (!isEmpty(primaryCoverages)) {
    forEach(primaryCoverages, (pc, pcKey) => {
      // skip isPrimary
      if (pcKey !== 'isPrimary' && pc.selected) {
        let value = pc.value;

        if (pcKey === 'ESC') {
          value = pc.value !== 0;
          if (
            pcKey === 'ESC' &&
            ['VISP2', 'CTVP2', 'FUTU4', 'SCFUT'].includes(
              productConfigs.productCode,
            ) // display as % instead of YES/NO for VISP2 and CTVP2
          ) {
            value = pc.value;
          }
        }
        newRows.push(createData(pc.selected, pc.name, value));
      }
    });
  }

  // STEP 2: update the row data by inserting the `secondAmount` based on second LI data
  const secondCoverages = find(
    productConfigs && productConfigs.coverages,
    coverages => {
      return !coverages.isPrimary;
    },
  );
  // loop thru the coverages for second life
  if (!isEmpty(secondCoverages)) {
    forEach(secondCoverages, (sc, scKey) => {
      let value = sc.value;

      if (scKey === 'ESC') {
        value = sc.value !== 0;
        if (
          scKey === 'ESC' &&
          ['VISP2', 'CTVP2', 'FUTU4', 'SCFUT'].includes(
            productConfigs.productCode,
          ) // display as % instead of YES/NO for VISP2 and CTVP2
        ) {
          value = sc.value;
        }
      }
      // skip isPrimary, but for second life data, isPrimary would not be there, this is just for safe measure
      if (scKey !== 'isPrimary') {
        // get the index of the array element if the benefit is already populated into the array
        const rowIndex = findIndex(newRows, rd => rd.benefitName === sc.name);
        if (rowIndex >= 0 && sc.selected) {
          // update the data in that index, by inserting the `secondAmount`
          newRows[rowIndex].secondAmount = value;
        } else if (sc.selected) {
          // otherwise, push a new array element
          newRows.push(createData(sc.selected, sc.name, 0, value));
        }
      }
    });
  }
  if (newRows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              {title}
              {/* <FormattedMessage {...messages.coverDetails} /> */}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {newRows.map(row => (
                    <StyledTableRow key={row.benefitName}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={
                          lifeInsuredCount > 1
                            ? { width: '40%' }
                            : { width: '70%' }
                        }
                      >
                        <Typography variant="h4">
                          <CheckCircleIcon
                            fontSize="small"
                            style={{
                              color: row.checked
                                ? theme.palette.success.main
                                : theme.palette.grey[400],
                              marginRight: '1rem',
                            }}
                          />
                          {` ${row.benefitName}`}
                        </Typography>
                      </StyledTableCell>

                      <StyledTableCell style={{ width: '30%' }}>
                        <Typography variant="h4">
                          {row.benefitName === 'Premium Indexation'
                            ? getPremiumIndexValue(row.amount)
                            : getCoverageDisplayValue(
                                row.amount,
                                productConfigs?.currency,
                              )}
                        </Typography>
                      </StyledTableCell>
                      {/* SECOND AMOUNT for JL if there are more than 1 LIs */}
                      {lifeInsuredCount > 1 && (
                        <StyledTableCell style={{ width: '30%' }}>
                          <Typography variant="h4">
                            {row.benefitName === 'Premium Indexation'
                              ? getPremiumIndexValue(row.secondAmount)
                              : getCoverageDisplayValue(
                                  row.secondAmount,
                                  productConfigs?.currency,
                                )}
                          </Typography>
                        </StyledTableCell>
                      )}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  } else return '';
}

PolicySummary.propTypes = {};

export default PolicySummary;

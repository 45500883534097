import { useRef, useEffect } from 'react';

const useEffectSkipFirst = (fn, arr) => {
  const isFirst = useRef(true);

  useEffect(() => {
    if (isFirst.current) {
      isFirst.current = false;
      return;
    }

    fn();
  }, arr);
};

export default useEffectSkipFirst;

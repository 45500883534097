/*
 * AgencySelection Messages
 *
 * This contains all the text for the AgencySelection container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.AgencySelection';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the AgencySelection container!',
  },
  selectPlaceholder: {
    id: `${scope}.selectPlaceholder`,
    defaultMessage: 'Select agency name',
  },
});

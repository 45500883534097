import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash/lang';
import {
  Field,
  reduxForm,
  InjectedFormProps,
  getFormValues,
} from 'redux-form/immutable';
import { reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import messages from 'containers/ApplicationFormPage/messages';
import { compose } from 'redux';
import { FormattedMessage } from 'react-intl';

import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

//components
import FormCheck from 'components/FormCheck';

//validate
import validate from './validate';

const useStyle = makeStyles(theme => ({
  container: {
    maxWidth: '660px',
  },
}));

function UWConsent(props) {
  const classes = useStyle();
  const {
    handleSubmit,
    onSubmit,
    canSubmit,
    formValues,
    canGoBack,
    parsedApplicationDetails,
  } = props;
  useEffect(() => {
    canSubmit(!formValues.uwConsent);
  }, [formValues]);

  useEffect(() => {
    canGoBack(true);
  }, [parsedApplicationDetails]);

  return (
    <div className={classes.container}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Field
          shrink="true"
          name="uwConsent"
          margin="normal"
          fullWidth
          component={FormCheck}
          label={
            <Typography
              variant="h3"
              style={{ fontSize: '16px', maxWidth: '660px' }}
            >
              I have read{' '}
              <Link
                underline="always"
                href="#"
                onClick={e => {
                  e.preventDefault();
                  window.open(
                    'https://www.zurichinternational.com/im/legal/privacy',
                    '_blank',
                  );
                }}
              >
                Zurich’s Privacy Notice
              </Link>{' '}
              which explains how personal data is collected and stored. I
              consent to my health data being used in this way.
              {/* <FormattedMessage {...messages.UWConsent} /> */}
            </Typography>
          }
          type="checkbox"
          color="inherit"
          margin="normal"
          variant="standard"
          // disabled={formValues.isBenefit}
        />
      </form>
    </div>
  );
}

UWConsent.propTypes = {
  ...InjectedFormProps,
};

const mapStateToProps = (state, props) => {
  const { section } = props;
  return createStructuredSelector({});
};

function mapDispatchToProps(dispatch) {
  return {};
}

const withFormSelector = connect(state => {
  const formValues = getFormValues('UWConsent')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  validate,
  form: 'UWConsent',
  enableReinitialize: true,
})(compose(withConnect, withFormSelector)(UWConsent));

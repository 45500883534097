import { call, put, all, takeLatest } from 'redux-saga/effects';
import { api } from 'environments';
import request from 'utils/request';
import {
  setErrorAction as setAppErrorAction,
  setAppLoadingAction,
} from 'containers/App/actions';
import globalMessages from 'containers/App/messages';
import { followUpLogin } from 'containers/App/saga';
import { REFRESH_OKTA_TOKEN_ACTION } from './constants';

export function* handleTokenChange(action) {
  yield put(setAppLoadingAction(true));
  const oktaAccessToken = action.payload;
  const metadata = JSON.stringify({
    userAgent: navigator.userAgent,
  });
  const endpoint = `${api.host}/api/v${api.version}/b2b/auth/login-sso`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${oktaAccessToken}`,
    },
    body: JSON.stringify({
      token: oktaAccessToken,
      metadata,
    }),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield call(followUpLogin, {
        agent: response.data.agent,
        okta: { accessToken: oktaAccessToken },
      });
    } else {
      yield put(
        setAppErrorAction({
          message: globalMessages.errSSOUserIsNotAuthorized,
        }),
      );
    }
  } catch (err) {
    yield put(
      setAppErrorAction({
        message: globalMessages.errSSOUserIsNotAuthorized,
      }),
    );
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

// Individual exports for testing
export default function* oktaAuthHandlerSaga() {
  // See example in containers/HomePage/saga.js
  yield all([takeLatest(REFRESH_OKTA_TOKEN_ACTION, handleTokenChange)]);
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import numeral from 'numeral';
import { getMinValue, getMaxValue, calculateAgeNextBirthdate } from '../../helper';

const validate = (values, props) => {
  const errors = {};
  const futuraConfig = props.futuraConfig;
  const lifeAssured = props.lifeAssured;

  if (!values.isEmpty()) {
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const minCalculatedPremiumValues = values.get('minCalculatedPremium');
    const premiumValues = values.get('premium');
    const termValues = values.get('term');
    const coveragesValues = values.get('coverages');
    const singlePremiumTermValues = values.get('singlePremiumTerm');
  
    var minPremium = 0;
    var maxPremium = 0;
    futuraConfig.features.currencyPremiumSize.map(val => {
      if (currencyValues === val.currency) {
        if (termValues === 'SinglePremium') {
          if(singlePremiumTermValues != undefined && singlePremiumTermValues > 1){
            minPremium = val.minPremiumSingle / singlePremiumTermValues;
            maxPremium = val.maxPremiumSingle / singlePremiumTermValues;
          } else {
            minPremium = val.minPremiumSingle;
            maxPremium = val.maxPremiumSingle;
          }
        } else {
          switch (paymentFrequencyValues) {
            case 'Monthly':
              minPremium = val.minPremiumMonthly;
              maxPremium = val.maxPremiumMonthly;
              break;

            case 'Quarterly':
              minPremium = val.minPremiumQuarterly;
              maxPremium = val.maxPremiumQuarterly;
              break;

            case 'Half-Yearly':
              minPremium = val.minPremiumHalfYearly;
              maxPremium = val.maxPremiumHalfYearly;
              break;

            case 'Yearly':
              minPremium = val.minPremiumYearly;
              maxPremium = val.maxPremiumYearly;
              break;

            case 'Single':
              minPremium = val.minPremiumSingle;
              maxPremium = val.maxPremiumSingle;
              break;

            default:
              minPremium = val.minPremiumMonthly;
              maxPremium = val.maxPremiumMonthly;
              break;
          }
        }
      }
    });

    if (minPremium < minCalculatedPremiumValues) minPremium = minCalculatedPremiumValues;

    if (!premiumValues)
      errors.premium = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
    else {
      if (premiumValues < minPremium)
        errors.premium = (<FormattedMessage {...globalMessages.minLimit} values={{ value: minPremium }} />);

      if (premiumValues > maxPremium)
        errors.premium = (<FormattedMessage {...globalMessages.maxLimit} values={{ value: maxPremium }} />);
    }

    if (coveragesValues && coveragesValues.size > 0) {
      const coveragesError = [];
      var lfcInsuredOne = 0;
      var lfcInsuredTwo = 0;
      coveragesValues.map(coverage => {
        var isInsuredOneValid = true;
        var isInsuredTwoValid = true;
        var validateFirstLife = true;
        var validateSecondLife = true;
        const ageOfLifeInsuredOne = calculateAgeNextBirthdate(startDateValues, lifeAssured.insuredOne?.dateOfBirth);
        const ageOfLifeInsuredTwo = calculateAgeNextBirthdate(startDateValues, lifeAssured.insuredTwo?.dateOfBirth);

        if (coverage.get('id') === 'LFC') {
          lfcInsuredOne = coverage.get('valueInsuredOne');
          lfcInsuredTwo = coverage.get('valueInsuredTwo');
        }

        if (lifeAssured.isInsuredTwo && lifeAssured.lifeBasis === 'JLBD') {
          if (coverage.get('maxEntryAgeLA') != '0') {
            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredOne) {
              isInsuredOneValid = false;
              validateFirstLife = false;
            }

            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredTwo) {
              validateSecondLife = false;
              isInsuredTwoValid = false;
            }
          }
        }

        if (lifeAssured.isInsuredTwo && (lifeAssured.lifeBasis === 'JLLS' || lifeAssured.lifeBasis === 'JLFD')) {
          if (coverage.get('maxEntryAgeLA') != '0') {
            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredOne)
              isInsuredOneValid = false;

            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredTwo)
              isInsuredTwoValid = false;

            if (coverage.get('id') != 'WOP') {
              validateSecondLife = false;
            }
          }
        }

        if (!lifeAssured.isInsuredTwo || lifeAssured.lifeBasis === '') {
          validateSecondLife = false;
          isInsuredTwoValid = false;
          if (coverage.get('maxEntryAgeLA') != '0') {
            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredOne)
              isInsuredOneValid = false;
          }
        }

        //Special condition for CRI && CCC
        if (coverage.get('id') === 'CRI') {
          coveragesValues.map(benefitData => {
            if (benefitData.get('id') === 'CCC' && benefitData.get('selected')) {
              if (benefitData.get('valueInsuredOne'))
                validateFirstLife = false;

              if (benefitData.get('valueInsuredTwo'))
                validateSecondLife = false;
            }
          });
        }

        if (coverage.get('id') === 'CCC') {
          coveragesValues.map(benefitData => {
            if (benefitData.get('id') === 'CRI' && benefitData.get('selected')) {
              if (benefitData.get('valueInsuredOne'))
                validateFirstLife = false;

              if (benefitData.get('valueInsuredTwo'))
                validateSecondLife = false;
            }
          });
        }

        var requiredCoverageFields = [];
        if (validateFirstLife && isInsuredOneValid) {
          requiredCoverageFields.push('valueInsuredOne');
          requiredCoverageFields.push('termOne');
        }
        if (validateSecondLife && isInsuredTwoValid) {
          requiredCoverageFields.push('valueInsuredTwo');
          requiredCoverageFields.push('termTwo');
        }

        const coverageError = {};
        requiredCoverageFields.forEach(field => {
          if (coverage.get('selected')) {
            if (coverage.get('id') === 'FMI' || coverage.get('id') === 'FTI') {
              if (field === 'termOne' || field === 'termTwo') {
                if (isFieldEmpty(coverage.get('termOne')) && isFieldEmpty(coverage.get('termTwo'))) {
                  coverageError['termOne'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                } else if (isFieldEmpty(coverage.get('termOne')) && !isFieldEmpty(coverage.get('valueInsuredOne'))) {
                  coverageError['termOne'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                } else if (isFieldEmpty(coverage.get('termTwo')) && !isFieldEmpty(coverage.get('valueInsuredTwo'))) {
                  coverageError['termTwo'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                } else {
                  if (coverage.get(field) > 40 && !isEmpty(coverage.get(field)))
                    coverageError[field] = (<FormattedMessage {...globalMessages.maxValueLimit} values={{ value: '40' }} />);

                  if (coverage.get(field) < 10 && !isEmpty(coverage.get(field)))
                    coverageError[field] = (<FormattedMessage {...globalMessages.minValueLimit} values={{ value: '10' }} />)
                }
              } else {
                if (isFieldEmpty(coverage.get('valueInsuredOne')) && isFieldEmpty(coverage.get('valueInsuredTwo'))) {
                  coverageError['valueInsuredOne'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                } else if (isFieldEmpty(coverage.get('valueInsuredOne')) && !isFieldEmpty(coverage.get('termOne'))) {
                  coverageError['valueInsuredOne'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                } else if (isFieldEmpty(coverage.get('valueInsuredTwo')) && !isFieldEmpty(coverage.get('termTwo'))) {
                  coverageError['valueInsuredTwo'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                } else {
                  const minVal = getMinValue(coverage.get('id'), futuraConfig.features, currencyValues);
                  const maxVal = getMaxValue(coverage.get('id'), futuraConfig.features, currencyValues);

                  if (minVal > coverage.get(field) && !isEmpty(coverage.get(field)))
                    coverageError[field] = (<FormattedMessage {...globalMessages.minAmountLimit} values={{ value: minVal }} />);

                  if (maxVal < coverage.get(field) && !isEmpty(coverage.get(field)))
                    coverageError[field] = (<FormattedMessage {...globalMessages.maxAmountLimit} values={{ value: maxVal }} />);

                  if (field === 'valueInsuredOne' && !isEmpty(coverage.get('valueInsuredOne'))) {
                    if (numeral(coverage.get(field)).value() > numeral(lfcInsuredOne).value())
                      coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />);
                  }

                  if (field === 'valueInsuredTwo' && !isEmpty(coverage.get('valueInsuredTwo'))) {
                    if (numeral(coverage.get(field)).value() > numeral(lfcInsuredTwo).value())
                      coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />)
                  }
                }
              }
            } else {
              if (field === 'valueInsuredOne' || field === 'valueInsuredTwo') {
                if (coverage.get('id') === 'WOP' || coverage.get('id') === 'LFC' || coverage.get('id') === 'ESC') {
                  if (isFieldEmpty(coverage.get(field))) {
                    coverageError[field] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                  } else {
                    if (coverage.get('id') !== 'WOP' && coverage.get('id') !== 'ESC') {
                      const minVal = getMinValue(coverage.get('id'), futuraConfig.features, currencyValues);
                      const maxVal = getMaxValue(coverage.get('id'), futuraConfig.features, currencyValues);

                      if (minVal > coverage.get(field))
                        coverageError[field] = (<FormattedMessage {...globalMessages.minAmountLimit} values={{ value: minVal }} />);

                      if (maxVal < coverage.get(field))
                        coverageError[field] = (<FormattedMessage {...globalMessages.maxAmountLimit} values={{ value: maxVal }} />);

                      if (field === 'valueInsuredOne') {
                        if (numeral(coverage.get(field)).value() > numeral(lfcInsuredOne).value())
                          coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />);
                      }

                      if (field === 'valueInsuredTwo') {
                        if (numeral(coverage.get(field)).value() > numeral(lfcInsuredTwo).value())
                          coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />)
                      }
                    }
                  }
                } else {
                  if (isFieldEmpty(coverage.get('valueInsuredOne')) && isFieldEmpty(coverage.get('valueInsuredTwo'))) {
                    coverageError['valueInsuredOne'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
                  } else {
                    const minVal = getMinValue(coverage.get('id'), futuraConfig.features, currencyValues);
                    const maxVal = getMaxValue(coverage.get('id'), futuraConfig.features, currencyValues);

                    if (minVal > coverage.get(field) && !isEmpty(coverage.get(field)))
                      coverageError[field] = (<FormattedMessage {...globalMessages.minAmountLimit} values={{ value: minVal }} />);

                    if (maxVal < coverage.get(field) && !isEmpty(coverage.get(field)))
                      coverageError[field] = (<FormattedMessage {...globalMessages.maxAmountLimit} values={{ value: maxVal }} />);

                    if (field === 'valueInsuredOne' && !isEmpty(coverage.get('valueInsuredOne'))) {
                      if (numeral(coverage.get(field)).value() > numeral(lfcInsuredOne).value())
                        coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />);
                    }

                    if (field === 'valueInsuredTwo' && !isEmpty(coverage.get('valueInsuredTwo'))) {
                      if (numeral(coverage.get(field)).value() > numeral(lfcInsuredTwo).value())
                        coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />)
                    }
                  }
                }
              }
            }
          }
        });
        coveragesError.push(coverageError);
      });

      if (!isEmpty(coveragesError))
        errors.coverages = coveragesError
    }
  }

  return errors;
};

export default validate;

import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the registerPage state domain
 */

const selectRegisterPageDomain = state =>
  state.get('registerPage', initialState);

/**
 * Other specific selectors
 */
const makeSelectInputData = () =>
  createSelector(selectRegisterPageDomain, substate => substate.get('inputData').toJS());
const makeSelectNotification = () =>
  createSelector(selectRegisterPageDomain, substate => substate.get('notification').toJS());

/**
 * Default selector used by RegisterPage
 */

const makeSelectRegisterPage = () =>
  createSelector(selectRegisterPageDomain, substate => substate.toJS());

const makeSelectSignUpAccess = () =>
  createSelector(selectRegisterPageDomain, substate => substate.get('isSignUpSuccess'));

export default makeSelectRegisterPage;
export { selectRegisterPageDomain, makeSelectInputData, makeSelectNotification, makeSelectSignUpAccess };

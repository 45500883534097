import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import {
  reduxForm,
  InjectedFormProps,
  FormSection,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPaymentType } from 'containers/ApplicationFormPage/selectors';
import { makeSelectFinancialInstituteOptions } from 'containers/App/selectors';
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';
import NoticeBox from 'components/NoticeBox';
import { isEmpty } from 'lodash';
import { convertIfImmutable } from 'helpers/lang';
import UaeDirectDebit from './components/UaeDirectDebit';
import CreditCard from './components/CreditCard';
import BankTransfer from './components/BankTransfer';
import Cheque from './components/Cheque';
import validate from './validate';

function PaymentMethod(props) {
  const {
    isInforce,
    applicationDetail,
    handleSubmit,
    onSubmit,
    paymentType,
    formValues,
    formErrors,
    canSubmit,
    openDialogPayment,
    setOpenDialogPayment,
    financialInstituteOptions,
    change,
  } = props;
  useEffect(() => {
    if (paymentType === 'CREDIT_CARD' && applicationDetail.toJS()?.missingKFS) {
      canSubmit(true);
    }
  }, []);

  const renderContent = () => {
    switch (paymentType) {
      case 'UAE_DIRECT_DEBIT':
        return (
          <FormSection
            name="paymentDetails"
            component={UaeDirectDebit}
            financialInstituteOptions={financialInstituteOptions}
          />
        );

      case 'CREDIT_CARD':
        return (
          <FormSection
            isInforce={isInforce}
            name="paymentDetails"
            formValues={formValues}
            formErrors={formErrors}
            component={CreditCard}
            openDialogPayment={openDialogPayment}
            setOpenDialogPayment={setOpenDialogPayment}
            canSubmit={canSubmit}
            change={change}
          />
        );
      case 'BANK_TRANSFER':
        return (
          <FormSection
            name="paymentDetails"
            component={BankTransfer}
            financialInstituteOptions={financialInstituteOptions}
          />
        );
      case 'CHEQUE':
        return (
          <FormSection
            name="paymentDetails"
            financialInstituteOptions={financialInstituteOptions}
            component={Cheque}
          />
        );
      case 'STANDING_ORDER':
        return (
          <FormSection
            name="paymentDetails"
            component={BankTransfer}
            financialInstituteOptions={financialInstituteOptions}
          />
        );
      default:
        return null;
    }
  };

  const isInvalidApply =
    paymentType === 'CREDIT_CARD' && applicationDetail.toJS()?.missingKFS;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isInvalidApply && (
        <Grid container>
          <Grid item xs={7}>
            <NoticeBox variant="error" mb={4}>
              <Typography variant="body2" color="error">
                The Quote is invalid. Please regenerate new one.
              </Typography>
            </NoticeBox>
          </Grid>
        </Grid>
      )}
      <Grid container>{renderContent()}</Grid>
    </form>
  );
}

PaymentMethod.propTypes = { ...InjectedFormProps };

const mapStateToProps = createStructuredSelector({
  paymentType: makeSelectPaymentType(),
  financialInstituteOptions: makeSelectFinancialInstituteOptions(),
  applicationDetail: makeSelectApplicationDetails(),
});

function mapDispatchToProps() {
  return {};
}

const withFormSelector = connect(state => {
  const formValues = getFormValues('PaymentMethod')(state);
  const formErrors = getFormSyncErrors('PaymentMethod')(state);
  return {
    formValues: !isEmpty(formValues.toJS().paymentDetails)
      ? formValues.toJS()
      : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  validate,
  form: 'PaymentMethod',
  enableReinitialize: true,
})(compose(withConnect, withFormSelector)(PaymentMethod));

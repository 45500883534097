import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import { isFieldEmpty } from 'utils/form';
import numeral from 'numeral';
import globalMessages from 'containers/App/messages';
import { getMinValue, getMaxValue } from '../../helper';


const validate = (values, props) => {
  const errors = {};
  const itaConfig = props.itaConfig;

  if (!values.isEmpty()) {
    const currencyValues = values.get('currency');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const premiumValues = values.get('premium');
    const coveragesValues = values.get('coverages');

    var minPremium = 0;
    var maxPremium = 0;
    itaConfig.features.currencyPremiumSize.map(val => {
      if (currencyValues === val.currency) {
        if (paymentFrequencyValues === 'Monthly') {
          minPremium = val.minPremiumMonthly;
          maxPremium = val.maxPremiumMonthly;
        } else {
          minPremium = val.minPremiumYearly;
          maxPremium = val.maxPremiumYearly;
        }
      }
    });

    if (!premiumValues)
      errors.premium = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
    else {
      if (premiumValues < minPremium)
        errors.premium = (<FormattedMessage {...globalMessages.minLimit} values={{ value: minPremium }} />);

      if (premiumValues > maxPremium)
        errors.premium = (<FormattedMessage {...globalMessages.maxLimit} values={{ value: maxPremium }} />);
    }

    if (coveragesValues && coveragesValues.size > 0) {
      const coveragesError = [];
      var lfcInsuredOne = 0;

      coveragesValues.map(coverage => {
        const requiredCoverageFields = ['valueInsuredOne'];
        const coverageError = {};

        if (coverage.get('id') === 'LFC') {
          lfcInsuredOne = coverage.get('valueInsuredOne');
        }

        requiredCoverageFields.forEach(field => {
          if (coverage.get('selected')) {
            if (isFieldEmpty(coverage.get(field))) {
              coverageError[field] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
            } else if (coverage.get('type') === "1") {
              const minVal = getMinValue(coverage.get('id'), itaConfig.features, currencyValues);
              const maxVal = getMaxValue(coverage.get('id'), itaConfig.features, currencyValues);

              if (minVal > coverage.get(field)) {
                coverageError[field] = (<FormattedMessage {...globalMessages.minAmountLimit} values={{ value: minVal }} />);
              }

              if (maxVal < coverage.get(field)) {
                coverageError[field] = (<FormattedMessage {...globalMessages.maxAmountLimit} values={{ value: maxVal }} />);
              }

              if (numeral(coverage.get('valueInsuredOne')).value() > numeral(lfcInsuredOne).value()) {
                coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />);
              }
            }
          }
        });
        coveragesError.push(coverageError);
      });
      if (!isEmpty(coveragesError))
        errors.coverages = coveragesError;
    }
  }

  return errors;
};

export default validate;
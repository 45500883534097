import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { WrappedFieldArrayProps, change } from 'redux-form';
import { Grid, Divider, Typography } from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import { dimension } from 'styles/constants';
import FormNumberField from 'components/FormNumberField';
import FormBooleanOption from 'components/FormBooleanOption';
import { calculateAgeNextBirthdate } from 'containers/FuturaIllustrationPage/helper';
import CustomFormCheck from 'components/CustomFormCheck';
import Helper from 'components/Helper';
// import Tooltip from '../Tooltip';
import messages from '../../messages';

function checkDisableESC(ageLifeOne, ageLifeTwo, lifeBasis) {
  const MAX_AGE_ESC = 50;

  if (['', 'SL'].includes(lifeBasis)) {
    return ageLifeOne >= MAX_AGE_ESC;
  }

  if (['JLFD', 'JLLS', 'JLLD'].includes(lifeBasis)) {
    return ageLifeOne >= MAX_AGE_ESC || ageLifeTwo >= MAX_AGE_ESC;
  }

  if (lifeBasis === 'JLBD') {
    return ageLifeOne >= MAX_AGE_ESC && ageLifeTwo >= MAX_AGE_ESC;
  }

  return false;
}

const Benefits = ({
  fields,
  lifeAssured,
  intl,
  inValidWop,
  change,
  coverages,
  startDate,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      let isShowingFields = true;
      let isInsuredOneEditable = true;
      let isInsuredTwoEditable = true;
      let isInsuredOneValid = true;
      let isInsuredTwoValid = true;
      let isFormCheckDisabled = myBenefit[index].disabled;
      let ageValidType = 0;

      const ageOfLifeInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
          ? lifeAssured.insuredOne.dateOfBirth
          : startDate,
      );
      const ageOfLifeInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo && lifeAssured.insuredOne.dateOfBirth
          ? lifeAssured.insuredTwo.dateOfBirth
          : startDate,
      );
      if (myBenefit[index].id !== 'ESC') {
        // If the case is JLBD
        if (lifeAssured.isInsuredTwo && lifeAssured.lifeBasis === 'JLBD') {
          // Max Entry age Validation
          if (myBenefit[index].maxEntryAgeLA != '0') {
            if (myBenefit[index].maxEntryAgeLA < ageOfLifeInsuredOne)
              isInsuredOneValid = false;

            if (myBenefit[index].maxEntryAgeLA < ageOfLifeInsuredTwo)
              isInsuredTwoValid = false;
          }

          if (!isInsuredOneValid) {
            isInsuredOneEditable = false;
            if (myBenefit[index].id === 'WOP')
              change(`${benefit}.valueInsuredOne`, false);
            else change(`${benefit}.valueInsuredOne`, '');
          }

          if (!isInsuredTwoValid) {
            isInsuredTwoEditable = false;
            if (myBenefit[index].id === 'WOP')
              change(`${benefit}.valueInsuredTwo`, false);
            else change(`${benefit}.valueInsuredTwo`, '');
          }

          if (!isInsuredOneValid && !isInsuredTwoValid) {
            isFormCheckDisabled = true;
            ageValidType = 1;
            change(`${benefit}.selected`, false);
          }
        }

        // If the case is JLLS OR JLFD
        if (
          lifeAssured.isInsuredTwo &&
          (lifeAssured.lifeBasis === 'JLLS' || lifeAssured.lifeBasis === 'JLFD')
        ) {
          // Max Entry age Validation
          if (myBenefit[index].maxEntryAgeLA != '0') {
            if (myBenefit[index].maxEntryAgeLA < ageOfLifeInsuredOne)
              isInsuredOneValid = false;

            if (myBenefit[index].maxEntryAgeLA < ageOfLifeInsuredTwo)
              isInsuredTwoValid = false;
          }

          if (!isInsuredOneValid) {
            isInsuredOneEditable = false;
            if (myBenefit[index].id === 'WOP')
              change(`${benefit}.valueInsuredOne`, false);
            else change(`${benefit}.valueInsuredOne`, '');
          }

          if (!isInsuredTwoValid) {
            if (myBenefit[index].id === 'WOP')
              change(`${benefit}.valueInsuredTwo`, false);
            else change(`${benefit}.valueInsuredTwo`, '');
          }

          if (myBenefit[index].id === 'WOP' && isInsuredTwoValid)
            isInsuredTwoEditable = true;
          else isInsuredTwoEditable = false;

          if (!isInsuredOneValid || !isInsuredTwoValid) {
            isFormCheckDisabled = true;
            ageValidType = 1;
            change(`${benefit}.selected`, false);
          }

          // Only show LFC, WOP, ESC when lifeBasis is JL Last Death
          if (lifeAssured.lifeBasis === 'JLLS') {
            if (
              myBenefit[index].id === 'LFC' ||
              myBenefit[index].id === 'WOP' ||
              myBenefit[index].id === 'ESC'
            )
              isShowingFields = true;
            else {
              change(`${benefit}.valueInsuredOne`, '');
              change(`${benefit}.valueInsuredTwo`, '');
              change(`${benefit}.selected`, false);
              isShowingFields = false;
            }
          }
        }

        // If the policy is single life.
        if (
          !lifeAssured.isInsuredTwo ||
          lifeAssured.lifeBasis === '' ||
          lifeAssured.lifeBasis === 'SL'
        ) {
          // Max Entry age Validation
          if (myBenefit[index].maxEntryAgeLA != '0') {
            if (myBenefit[index].maxEntryAgeLA < ageOfLifeInsuredOne)
              isInsuredOneValid = false;
          }

          isInsuredTwoEditable = false;
          isInsuredTwoValid = true;

          if (!isInsuredOneValid) {
            isInsuredOneEditable = false;
            isFormCheckDisabled = true;
            ageValidType = 1;
            if (myBenefit[index].id === 'WOP') {
              change(`${benefit}.valueInsuredOne`, false);
              change(`${benefit}.valueInsuredTwo`, false);
            } else {
              change(`${benefit}.valueInsuredOne`, '');
              change(`${benefit}.valueInsuredTwo`, '');
              change(`${benefit}.selected`, false);
            }
          }
        }
      } else if (
        checkDisableESC(
          ageOfLifeInsuredOne,
          ageOfLifeInsuredTwo,
          lifeAssured.lifeBasis,
        )
      ) {
        change(`${benefit}.valueInsuredOne`, false);
        change(`${benefit}.valueInsuredTwo`, false);
      }

      if (myBenefit[index].id === 'CRI') {
        coverages.map(benefitData => {
          if (benefitData.id === 'CCC' && benefitData.selected) {
            if (lifeAssured.lifeBasis === 'JLBD') {
              if (benefitData.valueInsuredOne) isInsuredOneEditable = false;

              if (benefitData.valueInsuredTwo) isInsuredTwoEditable = false;
            } else {
              isInsuredTwoEditable = false;
              isInsuredOneEditable = false;
              isFormCheckDisabled = true;
              ageValidType = 1;
            }
          }
        });

        if (!isInsuredOneEditable && !isInsuredTwoEditable) {
          isFormCheckDisabled = true;
          ageValidType = 1;
          isInsuredTwoValid = false;
          change(`${benefit}.selected`, false);
        }
      }

      if (myBenefit[index].id === 'CCC') {
        coverages.map(benefitData => {
          if (benefitData.id === 'CRI' && benefitData.selected) {
            if (lifeAssured.lifeBasis === 'JLBD') {
              if (benefitData.valueInsuredOne) isInsuredOneEditable = false;

              if (benefitData.valueInsuredTwo) isInsuredTwoEditable = false;
            } else {
              isInsuredTwoEditable = false;
              isInsuredOneEditable = false;
              isFormCheckDisabled = true;
              ageValidType = 1;
            }
          }
        });

        if (!isInsuredOneEditable && !isInsuredTwoEditable) {
          isFormCheckDisabled = true;
          ageValidType = 1;
          isInsuredTwoValid = false;
          change(`${benefit}.selected`, false);
        }
      }

      return isShowingFields ? (
        <div key={myBenefit[index].id}>
          <Grid
            container
            spacing={1}
            justify="start"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            wrap="nowrap"
          >
            {/* Title column */}
            <Grid item xs={6} md={6} sm={6}>
              {myBenefit[index].id === 'WOP' ||
              myBenefit[index].id === 'ESC' ? (
                <Grid container direction="row">
                  <Typography variant="h4" style={{ fontWeight: '600' }}>
                    {myBenefit[index].title}
                  </Typography>
                  <div>
                    <Helper
                      helperText={
                        myBenefit[index].id === 'WOP' ? (
                          <FormattedMessage {...messages.descriptionTwo} />
                        ) : (
                          <FormattedMessage {...messages.descriptionEsc} />
                        )
                      }
                    ></Helper>
                  </div>
                </Grid>
              ) : (
                <Field
                  name={`${benefit}.selected`}
                  fullWidth
                  component={CustomFormCheck}
                  label={myBenefit[index].title}
                  disabled={isFormCheckDisabled}
                  validType={ageValidType}
                  type="text"
                  color="primary"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`${benefit}.valueInsuredOne`, '');
                      change(`${benefit}.valueInsuredTwo`, '');
                      change(`${benefit}.termOne`, '');
                      change(`${benefit}.termTwo`, '');
                    }
                  }}
                />
              )}
            </Grid>
            {/* Title column - END */}

            {/* Life 1 column */}
            {myBenefit[index].id === 'FMI' ||
            myBenefit[index].id === 'FTI' ? null : (
              <Grid item xs={6} md={3} sm={3}>
                {myBenefit[index].id === 'WOP' ||
                myBenefit[index].id === 'ESC' ? (
                  <Field
                    name={`${benefit}.valueInsuredOne`}
                    component={FormBooleanOption}
                    type="text"
                    margin="normal"
                    disabled={
                      myBenefit[index].id === 'WOP'
                        ? !isInsuredOneEditable || inValidWop
                        : checkDisableESC(
                            ageOfLifeInsuredOne,
                            ageOfLifeInsuredTwo,
                            lifeAssured.lifeBasis,
                          )
                    }
                    onChange={(e, newVal) => {
                      if (coverages[index].id === 'ESC') {
                        change(`${benefit}.valueInsuredTwo`, newVal);
                      }
                    }}
                    fullWidth
                  />
                ) : (
                  <Field
                    name={`${benefit}.valueInsuredOne`}
                    fullWidth
                    component={FormNumberField}
                    decimalScale={0}
                    disabled={
                      !myBenefit[index].selected || !isInsuredOneEditable
                    }
                    type="text"
                    color="primary"
                    variant="standard"
                    onChange={(e, newVal) => {
                      isInsuredTwoEditable
                        ? null
                        : isInsuredTwoValid && lifeAssured.lifeBasis != 'JLBD'
                        ? change(`${benefit}.valueInsuredTwo`, newVal)
                        : null;
                    }}
                    placeholder={
                      ageValidType === 1
                        ? intl.formatMessage(globalMessages.naMessage)
                        : intl.formatMessage(messages.enterAmount)
                    }
                  />
                )}
              </Grid>
            )}

            {/* Life 1 column - END*/}

            {/* Life 2 column */}
            {lifeAssured.isInsuredTwo &&
              myBenefit[index].id !== 'ESC' &&
              (['FMI', 'FTI'].includes(myBenefit[index].id) ? null : (
                <Grid item xs={6} md={3} sm={3}>
                  {myBenefit[index].id === 'WOP' ? (
                    <Field
                      name={`${benefit}.valueInsuredTwo`}
                      component={FormBooleanOption}
                      type="text"
                      margin="normal"
                      disabled={
                        myBenefit[index].id === 'WOP'
                          ? !isInsuredTwoEditable || inValidWop
                          : false
                      }
                      fullWidth
                    />
                  ) : (
                    <Field
                      name={`${benefit}.valueInsuredTwo`}
                      fullWidth
                      component={FormNumberField}
                      decimalScale={0}
                      disabled={
                        !myBenefit[index].selected || !isInsuredTwoEditable
                      }
                      type="text"
                      color="primary"
                      variant="standard"
                      placeholder={
                        ageValidType === 1
                          ? intl.formatMessage(globalMessages.naMessage)
                          : intl.formatMessage(messages.enterAmount)
                      }
                    />
                  )}
                </Grid>
              ))}
          </Grid>
          {/* Life 2 column - END*/}

          {myBenefit[index].id === 'FMI' || myBenefit[index].id === 'FTI' ? (
            <div>
              <Grid
                container
                spacing={1}
                justify="start"
                alignItems="center"
                style={{
                  marginBottom: dimension.spacing.xs,
                  marginTop: dimension.spacing.xs,
                  paddingRight: '10%',
                }}
                wrap="nowrap"
              >
                <Grid item xs={6} md={6}>
                  <Typography variant="h4" style={{ fontWeight: '500' }}>
                    <FormattedMessage {...messages.amountEachYr} />
                  </Typography>
                </Grid>

                <Grid item xs={3} md={3}>
                  <Field
                    name={`${benefit}.valueInsuredOne`}
                    fullWidth
                    component={FormNumberField}
                    decimalScale={0}
                    disabled={
                      !myBenefit[index].selected || !isInsuredOneEditable
                    }
                    onChange={(e, newVal) => {
                      isInsuredTwoEditable
                        ? null
                        : isInsuredTwoValid
                        ? change(`${benefit}.valueInsuredTwo`, newVal)
                        : null;
                    }}
                    type="text"
                    color="primary"
                    variant="standard"
                    placeholder={
                      ageValidType === 1
                        ? intl.formatMessage(globalMessages.naMessage)
                        : intl.formatMessage(messages.enterAmount)
                    }
                  />
                </Grid>

                {lifeAssured.isInsuredTwo ? (
                  <Grid item xs={3} md={3}>
                    <Field
                      name={`${benefit}.valueInsuredTwo`}
                      fullWidth
                      component={FormNumberField}
                      decimalScale={0}
                      disabled={
                        !myBenefit[index].selected || !isInsuredTwoEditable
                      }
                      type="text"
                      color="primary"
                      variant="standard"
                      placeholder={
                        ageValidType === 1
                          ? intl.formatMessage(globalMessages.naMessage)
                          : intl.formatMessage(messages.enterAmount)
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid
                container
                spacing={1}
                justify="start"
                alignItems="center"
                style={{
                  marginBottom: dimension.spacing.xs,
                  marginTop: dimension.spacing.xs,
                  paddingRight: '10%',
                }}
                wrap="nowrap"
              >
                <Grid item xs={6} md={6}>
                  <Typography variant="h4" style={{ fontWeight: '500' }}>
                    {myBenefit[index].id === 'FMI' ? (
                      <FormattedMessage {...messages.coverTerm2} />
                    ) : (
                      <FormattedMessage {...messages.coverTerm} />
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3} md={3}>
                  <Field
                    name={`${benefit}.termOne`}
                    fullWidth
                    component={FormNumberField}
                    decimalScale={0}
                    disabled={
                      !myBenefit[index].selected || !isInsuredOneEditable
                    }
                    onChange={(e, newVal) => {
                      isInsuredTwoEditable
                        ? null
                        : isInsuredTwoValid
                        ? change(`${benefit}.termTwo`, newVal)
                        : null;
                    }}
                    type="text"
                    color="primary"
                    variant="standard"
                    placeholder={
                      ageValidType === 1
                        ? intl.formatMessage(globalMessages.naMessage)
                        : intl.formatMessage(messages.enterTerm)
                    }
                  />
                </Grid>

                {lifeAssured.isInsuredTwo ? (
                  <Grid item xs={3} md={3}>
                    <Field
                      name={`${benefit}.termTwo`}
                      fullWidth
                      component={FormNumberField}
                      decimalScale={0}
                      disabled={
                        !myBenefit[index].selected || !isInsuredTwoEditable
                      }
                      type="text"
                      color="primary"
                      variant="standard"
                      placeholder={
                        ageValidType === 1
                          ? intl.formatMessage(globalMessages.naMessage)
                          : intl.formatMessage(messages.enterTerm)
                      }
                    />
                  </Grid>
                ) : null}
              </Grid>
            </div>
          ) : null}

          {index + 1 < fields.length && <Divider />}
        </div>
      ) : null;
    })}
  </div>
);

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  coverages: PropTypes.array,
  inValidWop: PropTypes.bool,
  lifeAssured: PropTypes.object,
  startDate: PropTypes.string,
  options: PropTypes.object,
  change: PropTypes.func,
};

export default Benefits;

/**
 * CoverageForm
 */
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';
// import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
// import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Divider, Typography } from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import { dimension } from 'styles/constants';
import FormCheck from 'components/FormCheck';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import CustomFormCheck from 'components/CustomFormCheck';
import Helper from 'components/Helper';
import { changePlaceHolderColor } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import { getFormValues } from 'redux-form/lib/immutable';

// import asyncValidator from './asyncValidate';
import { generateStartDateList, generateTermList } from '../../helper';
import validate from './validate';
import messages from '../../messages';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

// const disableFormCheck = (coverages, benefitName) => {
//   const criCoverage = coverages.find(item => item.id === 'CRI');
//   const ccCoverage = coverages.find(item => item.id === 'CCC');
//   if (benefitName === 'CCC' && criCoverage?.selected) {
//     return true;
//   }

//   if (benefitName === 'CRI' && ccCoverage?.selected) {
//     return true;
//   }

//   return false;
// };

const renderBenefit = ({ coverages, fields, lifeAssured, intl, change }) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      return (
        <div key={myBenefit[index].id}>
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            wrap="nowrap"
          >
            <Grid item xs={6} md={6} sm={6}>
              <Grid container direction="row">
                <Grid item>
                  <Field
                    name={`${benefit}.selected`}
                    fullWidth
                    component={CustomFormCheck}
                    label={myBenefit[index].title}
                    // disabled={disableFormCheck(coverages, myBenefit[index].id)}
                    // validType={
                    //   disableFormCheck(coverages, myBenefit[index].id) ? 1 : 0
                    // }
                    type="text"
                    variant="standard"
                    onChange={(_, newVal) => {
                      const indexOfCC = myBenefit.findIndex(
                        item => item.id === 'CCC',
                      );
                      const indexOfCRI = myBenefit.findIndex(
                        item => item.id === 'CRI',
                      );
                      if (newVal) {
                        if (myBenefit[index].id === 'CRI') {
                          change(`coverages[${indexOfCC}].valueInsuredOne`, '');
                          change(`coverages[${indexOfCC}].valueInsuredTwo`, '');
                          change(`coverages[${indexOfCC}].disabled`, true);
                          change(`coverages[${indexOfCC}].selected`, false);
                          change(`coverages[${indexOfCRI}].disabled`, false);
                        } else {
                          myBenefit[indexOfCRI].selected = false;
                          change(
                            `coverages[${indexOfCRI}].valueInsuredOne`,
                            '',
                          );
                          change(
                            `coverages[${indexOfCRI}].valueInsuredTwo`,
                            '',
                          );
                          change(`coverages[${indexOfCRI}].selected`, false);
                          change(`coverages[${indexOfCRI}].disabled`, true);
                          change(`coverages[${indexOfCC}].disabled`, false);
                        }
                      } else {
                        change(`${benefit}.valueInsuredOne`, '');
                        change(`${benefit}.valueInsuredTwo`, '');
                      }
                    }}
                  />
                </Grid>

                {/* {myBenefit[index].id === 'CRI' && (
                  <Grid item>
                    <Helper
                      helperText={
                        <FormattedMessage {...messages.descriptionCri} />
                      }
                    ></Helper>
                  </Grid>
                )} */}
              </Grid>
            </Grid>
            {/* test */}
            <Grid item xs={3} md={3} sm={3}>
              {myBenefit[index].type === '1' && (
                <Field
                  name={`${benefit}.valueInsuredOne`}
                  component={FormNumberField}
                  decimalScale={0}
                  disabled={
                    myBenefit[index].disabled || !myBenefit[index].selected
                  }
                  // type={isShowingField ? 'text' : 'hidden'}
                  fullWidth
                  variant="standard"
                  onChange={(_, newVal) => {
                    change(`${benefit}.valueInsuredTwo`, newVal);
                  }}
                  placeholder={
                    myBenefit[index].disabled || !myBenefit[index].selected
                      ? intl.formatMessage(globalMessages.naMessage)
                      : intl.formatMessage(messages.enterAmount)
                  }
                />
              )}
            </Grid>
            {lifeAssured.isInsuredTwo && (
              <Grid item xs={3} md={3} sm={3}>
                {myBenefit[index].type === '1' && (
                  <Field
                    // shrink
                    name={`${benefit}.valueInsuredTwo`}
                    fullWidth
                    component={FormNumberField}
                    decimalScale={0}
                    disabled
                    // type={isShowingField ? 'text' : 'hidden'}
                    variant="standard"
                    placeholder={
                      myBenefit[index].disabled || !myBenefit[index].selected
                        ? intl.formatMessage(globalMessages.naMessage)
                        : intl.formatMessage(messages.enterAmount)
                    }
                  />
                )}
              </Grid>
            )}
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  lifeAssured: PropTypes.object,
  coverages: PropTypes.object,
  fields: PropTypes.object,
  intl: PropTypes.object,
  change: PropTypes.func,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  isUAEAgency,
  configsDetail,
  coverages,
  change,
  // offerCode,
  // offerCodeData,
  startDate,
  term,
  paymentFrequency,
  currency,
  location,
  // formValues,
  // resetOfferCode,
  // validateOfferCode,
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const currencyOption = [];
  const paymentFrequencyOption = [];
  const policyTermList = generateTermList(
    configsDetail,
    startDate,
    lifeAssured,
  );
  const startDateList = generateStartDateList();
  // Temporary comment for OfferCode feature

  // useEffect(() => {
  //   if (offerCode === '') {
  //     resetOfferCode();
  //   }

  //   if (offerCode !== '') {
  //     validateOfferCode({
  //       offerCode,
  //       productCode: configsDetail.productCode,
  //       productVersion: configsDetail.productVersion,
  //       premiumType: term,
  //       currency,
  //       premium: coverages?.[0]?.valueInsuredOne,
  //     });
  //   }
  // }, []);

  configsDetail.features.benefitCurrencyMap.forEach(cur => {
    currencyOption.push({ value: cur, title: cur });
  });

  configsDetail.features.premiumPaymentFrequencyType[0].periods.forEach(
    frequency => {
      paymentFrequencyOption.push({ value: frequency, title: frequency });
    },
  );

  // const hasErrorOfferCode = offerCodeData.errorMessage !== '';

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* CURRRENCY */}
            <Grid item>
              <Field
                // shrink
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption.filter(cur => {
                    if (!isUAEAgency) {
                      return cur.value !== 'AED';
                    }
                    return true;
                  }),
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* POLICY TERM */}
            <Grid item>
              <Field
                // shrink
                name="term"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(term),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.policyTerm} />}
                type="text"
                // options={policyTermList}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...policyTermList,
                ]}
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* PAYMENT FREQUENCY */}
            <Grid item>
              <Field
                name="paymentFrequency"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(paymentFrequency),
                }}
                component={FormSelect}
                // options={paymentFrequencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...paymentFrequencyOption,
                ]}
                label={<FormattedMessage {...messages.paymentFrequency} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
          </Grid>

          <Divider className={classes.hr} />

          <Grid
            container
            direction="row"
            spacing={1}
            justify="space-between"
            alignItems="center"
            item
            style={{ marginBottom: theme.spacing(1), paddingRight: '10%' }}
            wrap="nowrap"
          >
            <Grid item xs={6} md={6} sm={6} />
            <Grid item xs={6} md={3} sm={3}>
              <Typography
                color="primary"
                variant="h3"
                style={{ marginLeft: theme.spacing(0.5) }}
              >
                {lifeAssured.insuredOne.firstName}
              </Typography>
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredTwo.firstName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid>
            <FieldArray
              name="coverages"
              component={renderBenefit}
              rerenderOnEveryChange
              coverages={coverages}
              lifeAssured={lifeAssured}
              options={options}
              change={change}
              startDate={startDate}
              intl={intl}
            />
          </Grid>

          {/* <Divider className={classes.hr} />
          <Grid item xs={6} style={{ marginBottom: '5rem' }}>
            <Field
              // shrink
              name="offerCode"
              style={{
                width: 355,
              }}
              fullWidth
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              placeholder={intl.formatMessage(messages.pleaseEnter)}
            />
          </Grid> */}

          <Divider className={classes.hr} />

          <Grid item xs={12}>
            <Field
              // shrink
              name="specialTermsFlag"
              fullWidth
              component={FormCheck}
              label={<FormattedMessage {...globalMessages.isBenefitRating} />}
              type="text"
              margin="normal"
              variant="standard"
            />
          </Grid>
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  offerCode: PropTypes.string,
  isUAEAgency: PropTypes.bool,
  paymentFrequency: PropTypes.string,
  term: PropTypes.string,
  currency: PropTypes.string,
  options: PropTypes.object,
  configsDetail: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  offerCodeData: PropTypes.object,
  location: PropTypes.object,
  startDate: PropTypes.string,
  coverages: PropTypes.array,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
};

const selector = formValueSelector('coverageForm');
const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withFormSelector = connect(state => {
  const formValues = getFormValues('coverageForm')(state);
  const coverages = selector(state, 'coverages');
  const startDate = selector(state, 'startDate');
  const fiaCharge = selector(state, 'fiaCharge');
  const currency = selector(state, 'currency');
  const term = selector(state, 'term');
  const offerCode = selector(state, 'offerCode');
  const paymentFrequency = selector(state, 'paymentFrequency');
  return {
    startDate,
    fiaCharge,
    currency,
    term,
    paymentFrequency,
    offerCode,
    coverages: coverages?.toJS() || [],
    formValues: formValues?.toJS() || {},
  };
});

export default reduxForm({
  // Temporary comment for offer code feature

  // shouldAsyncValidate: params =>
  //   defaultShouldAsyncValidate({
  //     ...params,
  //     syncValidationPasses: true,
  //   }),
  // asyncValidate: asyncValidator,
  // asyncChangeFields: [
  //   'offerCode',
  //   'term',
  //   'coverages[].valueInsuredOne',
  //   'currency',
  // ],
  validate,
  form: 'coverageForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

export const color = {
  black: '#000000',
  white: '#ffffff',
  scarlet: '#E74C3C',
  blue: '#1942D8',
  mediumBlue: '#0076FF',
  babyBlue: '#E1F5F8',
  blue1: '#4176E5',
  blue2: '#01339C',
  blue3: '#004DFF',
  blue4: '#4066b3',
  blue5: '#009EE0',
  dijonMustard: '#DBB204',
  sunflower: '#FAAD0C',
  green: '#28B384',
  red: '#E74C3C',
  orange: '#FF6107',
  syntheticPurple: '#A03EFF',
  grey: {
    '50': '#fafafa',
    '100': '#f5f5f5',
    '200': '#f0f0f0',
    '300': '#e6e6e6',
    '400': '#cccccc',
    '500': '#b3b3b3',
    '600': '#979797',
    '700': '#646464',
    '800': '#424242',
    '900': '#212121',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161',
  },
  darkGrey: {
    '50': '#EDEDEE',
    '100': '#C9CACE',
    '200': '#A6A7AD',
    '300': '#71727D',
    '400': '#4D4F5C',
  },
  darkBlue: {
    '50': '#E6E9ED',
    '100': '#B6BDCA',
    '200': '#8691A7',
    '300': '#3E4F73',
    '400': '#0E2350',
  },
  purple: {
    '50': '#EAE5EF',
    '100': '#C1B2CF',
    '200': '#9880AF',
    '300': '#5A3480',
    '400': '#310160',
  },
  cyan: {
    '50': '#E9FAFD',
    '100': '#BFF0F8',
    '200': '#95E5F3',
    '300': '#56D6EC',
    '400': '#2CCCE7',
  },

  // New Color needs to add in appStyles
  pink: {
    '50': '#F100D2',
    '100': '#BF00A7',
    '200': '#9933CC',
  },
  brown: {
    '50': '#6F004D',
    '100': '#550041',
  },
  orange1: '#E12C2C',
  yellow: '#FAAD0C',
  lightRed: '#e74c3c',

  greyText: '#949494',
  purple2: '#5500A7',
};

export const typography = {};

export const dimension = {
  maxWidth: {
    main: 1400,
    mobileContent: 500,
  },
  maxHeight: {
    main: 1200,
  },
  borderRadius: {
    xxs: 5,
    xs: 10,
    s: 20,
    m: 30,
    l: 50,
  },
  spacing: {
    xxs: 4,
    xs: 8,
    s: 16,
    m: 24,
    l: 40,
    xl: 64,
    xxl: 80,
  },
};

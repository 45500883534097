/**
 *
 * QuoteForm
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Card, Divider, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  reduxForm,
  formValueSelector,
  getFormValues,
} from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

import { formatMonetaryValue } from 'helpers/math';
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import LoadingDialog from 'components/LoadingDialog';
import messages from '../../messages';
import Benefits from './Benefits';
import validate from './validate';
import { map, forEach, filter } from 'lodash/collection';
import moment from 'moment';
import { calculateAgeNextBirthdate } from 'containers/WapIllustrationPage/helper';

const useStyle = makeStyles(theme => ({
  wrapperTable: {
    padding: '40px 23px 0px 23px',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
  },
}));

function QuoteForm({
  illustrationValueDict,
  illustrationTableDict,
  wapConfig,
  productConfig,
  lifeAssured,
  onUpdateValues,
  handleSubmit,
  onSubmit,
  change,
  location,
  offerCodeData,
  input,
}) {
  const theme = useTheme();
  const classes = useStyle();
  let coverageTerm = 0;
  //LI1 and LI2 ages
  const lifeBasis = input['Life Basis'];

  const Life1Age = calculateAgeNextBirthdate(
    productConfig.startDate,
    lifeAssured?.insuredOne?.dateOfBirth,
  );

  const Life2Age = calculateAgeNextBirthdate(
    productConfig.startDate,
    lifeAssured?.insuredTwo?.dateOfBirth,
  );
  
  if (lifeBasis === 'SL' || isNaN(Life2Age)) {
    //get the min value between 2 ages
    coverageTerm = 100 - Life1Age;
  } else {
    coverageTerm = 100 - Math.min(Life1Age, Life2Age);
  }

  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });

  let myGrowthRateNode = `${numeral(productConfig.growthRate / 100).value()}`;
  myGrowthRateNode = numeral(myGrowthRateNode).format('0.0000');
  myGrowthRateNode = parseFloat(myGrowthRateNode.toString());

  // var myGrowthRateNode = `${productConfig.growthRate}`;
  // if ((productConfig.growthRate - Math.floor(productConfig.growthRate)) === 0)
  // myGrowthRateNode = `${numeral(productConfig.growthRate).format('0.0')}`;

  const myBiTableValues = illustrationTableDict[myGrowthRateNode];

  const renderRows = () => {
    let arrayOfNumbers = [];

    //push all keys to array
    forEach(myBiTableValues, (item, key) => {
      if (parseInt(key) <= coverageTerm) {
        arrayOfNumbers.push(parseInt(key));
      }
    });

    //sort them accordingly
    arrayOfNumbers.sort(function(a, b) {
      return a - b;
    });

    //filter the items by 5
    let filteredNum = filter(arrayOfNumbers, num => {
      if (num % 5 === 0 || arrayOfNumbers.length === num) {
        return num;
      }
    });

    //render the items
    return map(filteredNum, num2 => (
      <Benefits
        formValues={myBiTableValues[`${num2}.0`]}
        wapConfig={wapConfig}
        years={parseInt(num2)}
      />
    ));
  };

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-20px' }}>
          <Card
            style={{
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 0,
              width: 680,
            }}
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: '26px 12px',
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.premiumPaid} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: '26px 12px',
                  borderRight: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.policyTerm} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  padding: '26px 12px',
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                  textAlign: 'right',
                }}
              >
                <Typography
                  variant="h4"
                  margin="normal"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.fundValues} />
                </Typography>
              </Grid>
            </Grid>

            {coverageTerm < 30 && productConfig.productCode === 'WAPL1' ? (
              renderRows()
            ) : !isEmpty(myBiTableValues) ? (
              <>
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['5.0']}
                    wapConfig={wapConfig}
                    years={5}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['10.0']}
                    wapConfig={wapConfig}
                    years={10}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['15.0']}
                    wapConfig={wapConfig}
                    years={15}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['20.0']}
                    wapConfig={wapConfig}
                    years={20}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['25.0']}
                    wapConfig={wapConfig}
                    years={25}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['30.0']}
                    wapConfig={wapConfig}
                    years={30}
                  />
                ) : null}{' '}
              </>
            ) : null}

            <hr className={classes.hr} />

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{ paddingRight: theme.spacing(2) }}
            >
              <Grid item>
                <Typography
                  component="span"
                  variant="h1"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '400',
                    fontSize: '2.2rem',
                  }}
                >
                  {productConfig.currency}
                </Typography>
              </Grid>
              <Grid item>
                <span
                  id="primaryPremiumAmount"
                  style={{
                    color: theme.palette.common.white,
                    fontSize: '6.6rem',
                    lineHeight: 1,
                    fontWeight: 700,
                  }}
                >
                  {!isEmpty(myBiTableValues)
                    ? formatMonetaryValue(
                        myBiTableValues[
                          `${
                            coverageTerm < 30 &&
                            productConfig.productCode === 'WAPL1'
                              ? coverageTerm
                              : 30
                          }.0`
                        ]['Fund Value'],
                        false,
                      )
                    : 0}
                </span>
              </Grid>
            </Grid>

            <Divider
              style={{
                backgroundColor: theme.palette.info.main,
                margin: theme.spacing(2),
              }}
            />

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{
                paddingRight: theme.spacing(2),
                paddingBottom: theme.spacing(3),
              }}
            >
              <Typography
                variant="h4"
                style={{
                  color: '#B9BBB8',
                  // fontWeight: 700,
                  fontSize: '16px',
                }}
              >
                <FormattedMessage
                  {...messages.projectedFundValues}
                  values={{
                    value:
                      coverageTerm < 30 && productConfig.productCode === 'WAPL1'
                        ? coverageTerm
                        : 30,
                  }}
                />
              </Typography>
            </Grid>
          </Card>
          {offerCodeData.discountMessage?.english && (
            <Box my={2}>
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                <FormattedMessage
                  {...messages.offerCodeValid}
                  values={{
                    value: offerCodeData.discountMessage?.english,
                  }}
                />
              </Typography>
            </Box>
          )}
        </form>
      )}
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  wapConfig: PropTypes.object,
  productConfig: PropTypes.object,
  onUpdateValues: PropTypes.func,
  illustrationTableDict: PropTypes.object,
  illustrationValueDict: PropTypes.object,
  annexureDict: PropTypes.object,
  annualIrr: PropTypes.object,
  additionalLifeCover: PropTypes.number,
};

const withForm = reduxForm({
  form: 'quoteForm',
  warn: validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');

const withConnect = connect(state => {
  const illustrationTableDict = selector(state, 'illustrationTableDict');
  const illustrationValueDict = selector(state, 'illustrationValueDict');
  const annexureDict = selector(state, 'annexureDict');
  const annualIrr = selector(state, 'annualIrr');
  const additionalLifeCover = selector(state, 'additionalLifeCover');
  const formValues = getFormValues('quoteForm')(state);
  return {
    illustrationTableDict: illustrationTableDict?.toJS() || {},
    illustrationValueDict: illustrationValueDict?.toJS() || {},
    annexureDict: annexureDict?.toJS() || {},
    annualIrr: annualIrr?.toJS() || {},
    additionalLifeCover,
    formValues: formValues?.toJS() || {},
  };
})(withForm);

export default withConnect;

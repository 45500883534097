import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import messages from 'containers/ApplicationFormPage/messages';
import { compose } from 'redux';
import { find, map, forEach, filter, groupBy } from 'lodash/collection';
import { isEmpty, isEqual } from 'lodash/lang';
import { startCase } from 'lodash/string';
import request from 'utils/request';
import { api } from 'environments';
// MATERIAL UI
import { Grid, Typography, Link, Button, Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

import Dialog from 'components/Dialog';
import Helper from 'components/Helper';
import { createIDVSessionAction, setOtherDocumentStatusAction } from 'containers/ApplicationFormPage/actions';
import { makeSelectAuth } from 'containers/App/selectors';
import { makeSelectCountIdvSubmit } from 'containers/ApplicationFormPage/selectors';
import { getDisabledStatus } from 'containers/ApplicationFormPage/helpers';
import { isDisabledField } from 'helpers/constants';
import UploadDialog from './UploadDialog';
import ProofUploadDialog from './ProofUploadDialog';
import SendEmailDialog from './SendEmailDialog';

const useStyle = makeStyles(() => ({
  actionContainer: {
    margin: '0.7rem 0.5rem',
    position: 'relative',
  },
  actionDialogContainer: { padding: '0 5rem' },
}));

const allowedExtensions = [
  'pdf',
  'doc',
  'docx',
  'tif',
  'tiff',
  'jpeg',
  'jpg',
  'png',
];

const handleShutiProButtonColor = (
  isDisabled,
  isExceed,
  value1,
  value2,
  using3rdPartyService,
) => {
  if (process.env.SHUTIPRO_ENABLED === 'y' && using3rdPartyService) {
    return isDisabled || isExceed ? value1 : value2;
  }
  return value1;
};

const getColorStatus = (documentRequirement, theme) => {
  if (documentRequirement.isRequired && !documentRequirement.isUploaded) {
    return theme.palette.error.main;
  }

  if (documentRequirement.isUploaded) {
    return theme.palette.success.main;
  }

  return '#F2F2F2';
};

function ActionContainer(props) {
  const theme = useTheme();
  const classes = useStyle();
  const [file, setFile] = useState([]);
  const [proofFile, setProofFile] = useState([]);
  const [email, setEmail] = useState('');
  const [openUpload, setOpenUpload] = useState(false);
  const [openSend, setOpenSend] = useState(false);
  const [camera, setCamera] = useState(false);
  const [isExceedRetry, setExceedRetry] = useState(false);
  const [dialogShuftiPro, setDialogShuftiPro] = useState({
    show: false,
    message: '',
  });

  const {
    applicationId,
    documentRequirement,
    party,
    actionTitle,
    handlePhotoFile,
    handleFileUpload,
    handleFileDelete,
    createIDVSession,
    countIdvSubmit,
    proofDocsRequired,
    auth,
    disabledFields,
    parsedApplicationDetails,
    setOtherDocumentStatus
  } = props;

  const isInforce = parsedApplicationDetails?.isInforce;
  const filteredDocumentsByTag = filter(
    party.documents,
    doc => doc.tag === documentRequirement.tag,
  );
  const filterDocumentsProof = filter(
    party.documents,
    doc => doc.tag === 'POA' || doc.tag === 'POI',
  );
  useEffect(() => {
    if (party) {
      setEmail(party.email);
    }

    if (filteredDocumentsByTag) {
      setFile([...filteredDocumentsByTag]);
    }

    if (filterDocumentsProof) {
      setProofFile([...filterDocumentsProof]);
    }
  }, [party]);

  useEffect(() => {
    const POI = find(party.documentRequirements, function(o) {
      return o.tag === 'POI';
    });
    if (documentRequirement.tag === 'POI' && POI.isUploaded === false) {
      try {
        const endpoint = `${api.host}/api/v${api.version}/b2b/id-verification/get-status`;
        const requestOpt = {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': api.subKey,
            Authorization: `Bearer ${auth.okta.accessToken}`,
          },
          body: JSON.stringify({ applicationId, partyId: party.id }),
        };

        request(endpoint, requestOpt)
          .then()
          .catch(err => {
            err.response.json().then(data => {
              if (data.message === 'IDV_TOO_MANY_RETRY_FOR_THIS_PARTY') {
                setExceedRetry(true);
                setDialogShuftiPro({
                  show: true,
                  message:
                    'Document originality cannot be verified via ShuftiPro. Please proceed to "Upload" option',
                });
              }
            });
          });
      } catch (err) {}
    }
  }, [countIdvSubmit]);

  const toggleSend = bool => {
    setOpenSend(bool);
  };

  const toggleUpload = bool => {
    setOpenUpload(bool);
  };

  const handleDocumentFile = (tag, e) => {
    setDocumentFile(tag, e.target.files);
  };
  const hasPOADoc =
    proofDocsRequired &&
    proofDocsRequired.filter(item => item.tag === 'POA').length;

  const hasPOIDoc =
    proofDocsRequired &&
    proofDocsRequired.filter(item => item.tag === 'POI').length;

  const setDocumentFile = (tag, data, isCamera = false) => {
    let isLargeFile = false;
    let isValidExtension = false;

    forEach(data, d => {
      if (
        allowedExtensions.indexOf(
          d.name
            .toLowerCase()
            .substr(d.name.toLowerCase().lastIndexOf('.') + 1),
        ) === -1
      ) {
        window.alert(
          `Invalid format. Only ${allowedExtensions.join(', ')} are allowed`,
        );
        isValidExtension = false;
      } else {
        isValidExtension = true;
      }
      if (d.size > 15e6) {
        window.alert('Please upload a file smaller than 15 MB');
        isLargeFile = true;
        return false;
      }
    });

    // convert it into array
    const convertedData = map(data, d => {
      d.tag = tag;
      return d;
    });

    if (isCamera) {
      data.tag = tag;
      setProofFile([...proofFile, data]);
      setCamera(false);
    }
    if (!isLargeFile && isValidExtension) {
      if (
        documentRequirement.tag === 'POI' ||
        documentRequirement.tag === 'POA'
      ) {
        setProofFile([...proofFile, ...convertedData]);
      }
      setFile([...file, ...convertedData]);
    }
  };

  function checkEnableButton() {
    const lengthOfRequireDocUploaded = Object.keys(groupBy(proofFile, 'tag'))
      .length;
    if (
      documentRequirement.tag === 'POI' ||
      documentRequirement.tag === 'POA'
    ) {
      return lengthOfRequireDocUploaded >= proofDocsRequired.length;
    }
    return file.length > 0;
  }

  const handleSendEmail = () => {
    if (!isEmpty(party.email)) {
      createIDVSession({
        applicationId,
        partyId: party.id,
        updatedEmail: null,
        isSendMail: true,
      });
    } else if (!isEmpty(email)) {
      createIDVSession({
        applicationId,
        partyId: party.id,
        updatedEmail: email,
        isSendMail: true,
      });
    }
    toggleSend(false);
  };

  function handleDisableButton() {
    if (
      documentRequirement.isUploaded ||
      party.idvResponse?.status === 'COMPLETED'
    ) {
      return true;
    }
    return false;
  }

  function checkShutiProVerifiedStatus() {
    if (
      party.idvResponse?.status === 'PENDING' ||
      party.idvResponse?.status === 'COMPLETED'
    ) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    // show a dialog when refreshed and its not uploaded
    if (documentRequirement.tag === 'POI') {
      const POI = find(party.documentRequirements, function(o) {
        return o.tag === 'POI';
      });

      if (documentRequirement.tag === 'POI' && POI.isUploaded === false) {
        const endpoint = `${api.host}/api/v${api.version}/b2b/id-verification/get-status`;
        const requestOpt = {
          method: 'POST',
          headers: {
            'Ocp-Apim-Subscription-Key': api.subKey,
            Authorization: `Bearer ${auth.okta.accessToken}`,
          },
          body: JSON.stringify({ applicationId, partyId: party.id }),
        };

        request(endpoint, requestOpt)
          .then(() => {
            if (party.idvResponse?.errorMessage) {
              setDialogShuftiPro({
                show: true,
                message: party.idvResponse?.errorMessage,
              });
            } else if (party.idvResponse?.status === 'DECLINED') {
              setDialogShuftiPro({
                show: true,
                message: startCase(party.idvResponse?.declined_reason),
              });
            }
          })
          .catch(err => {
            err.response.json().then(data => {
              if (data.message === 'IDV_TOO_MANY_RETRY_FOR_THIS_PARTY') {
                setExceedRetry(true);
                setDialogShuftiPro({
                  show: true,
                  message:
                    'Document originality cannot be verified via ShuftiPro. Please proceed to "Upload" option',
                });
              }
            });
          });
      }
    }
  }, [party.idvResponse?.status]);

  return (
    <>
      <Grid
        container
        className={classes.actionContainer}
        alignItems="center"
        justify="center"
        wrap="nowrap"
      >
        {['AD', 'UW', 'VISA'].includes(documentRequirement.tag) ? (
          <Grid item container wrap="nowrap">
            <Grid item xs container justify="center" spacing={1}>
              <Grid item>
                <FiberManualRecordIcon
                  style={{
                    color: getColorStatus(documentRequirement, theme),
                  }}
                />
              </Grid>
              <Grid item xs style={{ paddingTop: theme.spacing(0.9) }}>
                <Typography variant="body2">
                  {`${actionTitle}`}{' '}
                  {documentRequirement.tag === 'VISA' && (
                    <Helper helperText={messages.visaTooltip.defaultMessage} />
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={7}>
              <Grid container alignItems="center" justify="flex-start">
                {map(filteredDocumentsByTag, doc => (
                  <>
                    <Grid item xs={9} style={{ marginBottom: '2rem' }}>
                      <Typography variant="body2" style={{ color: '#ccc' }}>
                        {doc.originalFileName}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginBottom: '2rem' }}
                      container
                      justify="center"
                    >
                      <Link
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          handleFileDelete(doc, party, applicationId);
                          // toggleUpload(true);
                          // window.close();
                        }}
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.primary.dark,
                        }}
                      >
                        <FormattedMessage {...messages.delete} />
                      </Link>
                    </Grid>
                  </>
                ))}

                <Grid item xs={4} />
                <Grid item xs={5} />
                <Grid item xs={3} container justify="center">
                  {filteredDocumentsByTag.length > 0 && (
                    <Box
                      height="1px"
                      left={-18}
                      right={-7}
                      position="absolute"
                      bgcolor="#DDDFE0"
                    />
                  )}
                  <Link
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      toggleUpload(true);
                    }}
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.primary.dark,
                      paddingTop:
                        filteredDocumentsByTag.length > 0
                          ? theme.spacing(2)
                          : 0,
                    }}
                  >
                    <FormattedMessage {...messages.upload} />
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item container wrap="nowrap">
              <Grid item xs container justify="center" spacing={1}>
                <Grid item>
                  <FiberManualRecordIcon
                    style={{
                      color: getColorStatus(documentRequirement, theme),
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">{`${actionTitle}`}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={
                  filterDocumentsProof.filter(
                    doc => doc.tag === documentRequirement.tag,
                  ).length > 0
                    ? 7
                    : 3
                }
              >
                {map(
                  filterDocumentsProof.filter(
                    doc => doc.tag === documentRequirement.tag,
                  ),
                  f => (
                    <>
                      <Grid item xs={9}>
                        <Typography variant="span" style={{ color: '#ccc' }}>
                          {party.idvResponse?.status === 'COMPLETED' ? (
                            <FormattedMessage {...messages.approved} />
                          ) : f.originalFileName ? (
                            f.originalFileName
                          ) : (
                            f.name
                          )}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{ marginBottom: '2rem' }}
                        container
                        justify="center"
                      >
                        <Link
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            handleFileDelete(f, party, applicationId);
                            // toggleUpload(true);
                            // window.close();
                          }}
                          style={{
                            fontWeight: 'bold',
                            color: theme.palette.primary.dark,
                          }}
                        >
                          <FormattedMessage {...messages.delete} />
                        </Link>
                      </Grid>
                    </>
                  ),
                )}
              </Grid>
              {filterDocumentsProof.filter(
                doc => doc.tag === documentRequirement.tag,
              ).length > 0 ? (
                ''
              ) : (
                <Grid item xs={4}>
                  <Grid item container>
                    {(documentRequirement.tag === 'POI' ||
                      (!hasPOIDoc && documentRequirement.tag === 'POA')) &&
                    filterDocumentsProof.filter(doc => doc.tag === 'POI')
                      .length === 0 &&
                    filterDocumentsProof.filter(doc => doc.tag === 'POA')
                      .length === 0 ? (
                      <>
                        <Grid item xs={4} container justify="flex-start">
                          <Link
                            href="#"
                            onClick={e => {
                              e.preventDefault();

                              if (isInforce) {
                                if (
                                  !isDisabledField(disabledFields, 'shuftipro')
                                ) {
                                  createIDVSession({
                                    applicationId,
                                    partyId: party.id,
                                    isSendMail: false,
                                  });
                                }
                              } else {
                                !getDisabledStatus(
                                  documentRequirement.using3rdPartyService,
                                  handleDisableButton,
                                  isExceedRetry,
                                )
                                  ? createIDVSession({
                                      applicationId,
                                      partyId: party.id,
                                      isSendMail: false,
                                    })
                                  : null;
                              }
                            }}
                            style={{
                              fontWeight: 'bold',
                              color: isInforce
                                ? isDisabledField(disabledFields, 'shuftipro')
                                  ? theme.palette.grey[400]
                                  : theme.palette.primary.dark
                                : handleShutiProButtonColor(
                                    handleDisableButton(),
                                    isExceedRetry,
                                    theme.palette.grey[400],
                                    theme.palette.primary.dark,
                                    documentRequirement.using3rdPartyService,
                                  ),
                              pointerEvents: isInforce
                                ? isDisabledField(disabledFields, 'shuftipro')
                                  ? theme.palette.grey[400]
                                  : theme.palette.primary.dark
                                : handleShutiProButtonColor(
                                    handleDisableButton(),
                                    isExceedRetry,
                                    theme.palette.grey[400],
                                    theme.palette.primary.dark,
                                    documentRequirement.using3rdPartyService,
                                  ),
                            }}
                            disabled={
                              isInforce
                                ? isDisabledField(disabledFields, 'shuftipro')
                                : getDisabledStatus(
                                    documentRequirement.using3rdPartyService,
                                    handleDisableButton,
                                    isExceedRetry,
                                  )
                            }
                          >
                            <FormattedMessage {...messages.open} />
                          </Link>
                        </Grid>
                        <Grid item xs={4} container justify="flex-start">
                          <Link
                            href="#"
                            onClick={e => {
                              e.preventDefault();

                              !getDisabledStatus(
                                documentRequirement.using3rdPartyService,
                                handleDisableButton,
                                isExceedRetry,
                              ) && !isDisabledField(disabledFields, 'shuftipro')
                                ? toggleSend(true)
                                : null;
                            }}
                            style={{
                              fontWeight: 'bold',
                              color: isInforce
                                ? isDisabledField(disabledFields, 'shuftipro')
                                  ? theme.palette.grey[400]
                                  : theme.palette.primary.dark
                                : handleShutiProButtonColor(
                                    handleDisableButton(),
                                    isExceedRetry,
                                    theme.palette.grey[400],
                                    theme.palette.primary.dark,
                                    documentRequirement.using3rdPartyService,
                                  ),
                              pointerEvents: isInforce
                                ? isDisabledField(disabledFields, 'shuftipro')
                                  ? theme.palette.grey[400]
                                  : theme.palette.primary.dark
                                : handleShutiProButtonColor(
                                    handleDisableButton(),
                                    isExceedRetry,
                                    theme.palette.grey[400],
                                    theme.palette.primary.dark,
                                    documentRequirement.using3rdPartyService,
                                  ),
                            }}
                            disabled={
                              isInforce
                                ? isDisabledField(disabledFields, 'shuftipro')
                                : getDisabledStatus(
                                    documentRequirement.using3rdPartyService,
                                    handleDisableButton,
                                    isExceedRetry,
                                  )
                            }
                          >
                            <FormattedMessage {...messages.send} />
                          </Link>
                        </Grid>
                        <Grid item xs={4} container justify="flex-start">
                          <Link
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              if (!checkShutiProVerifiedStatus()) {
                                toggleUpload(true);
                              }
                              // window.close();
                            }}
                            style={{
                              fontWeight: 'bold',
                              color:
                                documentRequirement.tag === 'POI' &&
                                checkShutiProVerifiedStatus()
                                  ? theme.palette.grey[400]
                                  : theme.palette.primary.dark,
                              pointerEvents: checkShutiProVerifiedStatus()
                                ? 'none'
                                : 'auto',
                            }}
                            disabled={
                              documentRequirement.tag === 'POI' &&
                              checkShutiProVerifiedStatus()
                            }
                          >
                            <FormattedMessage {...messages.upload} />
                          </Link>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={4}></Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {/* SEND */}
        <Dialog
          open={openSend}
          onClose={() => toggleSend(false)}
          title={<FormattedMessage {...messages.sendEmail} />}
          actions={
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              className={classes.actionDialogContainer}
            >
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  variant="outlined"
                  style={{ color: theme.palette.primary.dark }}
                  onClick={() => {
                    toggleSend(false);
                  }}
                >
                  <FormattedMessage {...messages.cancelExit} />
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={email === ''}
                  onClick={() => {
                    handleSendEmail();
                  }}
                >
                  <FormattedMessage {...messages.sendEmail} />
                </Button>
              </Grid>
            </Grid>
          }
        >
          <SendEmailDialog
            partyEmail={party.email}
            email={email}
            setEmail={setEmail}
          />
        </Dialog>
        {/* UPLOAD */}
        <Dialog
          open={openUpload}
          onClose={() => {
            toggleUpload(false);
            setCamera(false);
          }}
          title={<FormattedMessage {...messages.upload} />}
          actions={
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              spacing={3}
              className={classes.actionDialogContainer}
            >
              <Grid item xs={12} md={6} container justify="flex-end">
                <Button
                  style={{
                    color: theme.palette.primary.main,
                    minWidth: '150px',
                    border: '2px solid #A0B3D9',
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    toggleUpload(false);
                    setCamera(false);
                  }}
                >
                  <FormattedMessage {...messages.cancelExit} />
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="outlined"
                  style={{
                    minWidth: '150px',
                    color: theme.palette.common.white,
                    backgroundColor: checkEnableButton()
                      ? theme.palette.primary.main
                      : theme.palette.grey[400],
                    border: 'none',
                  }}
                  disabled={!checkEnableButton()}
                  onClick={() => {
                    handleFileUpload(
                      documentRequirement.tag === 'POI' ||
                        documentRequirement.tag === 'POA'
                        ? proofFile
                        : file,
                      party,
                      applicationId,
                      documentRequirement,
                    );
                    setOtherDocumentStatus({})
                    toggleUpload(false);
                  }}
                >
                  <FormattedMessage {...messages.attachDocuments} />
                </Button>
              </Grid>
            </Grid>
          }
        >
          {['AD', 'UW', 'VISA'].includes(documentRequirement.tag) ? (
            <UploadDialog
              handleDocumentFile={handleDocumentFile}
              handlePhotoFile={handlePhotoFile}
              file={file}
              setFile={setFile}
              actionTitle={actionTitle}
              documentRequirement={documentRequirement}
            />
          ) : (
            <ProofUploadDialog
              handleDocumentFile={handleDocumentFile}
              handlePhotoFile={handlePhotoFile}
              setDocumentFile={setDocumentFile}
              camera={camera}
              setCamera={setCamera}
              file={proofFile}
              setFile={setProofFile}
              proofDocsRequired={proofDocsRequired}
            />
          )}
        </Dialog>

        {/* ERROR */}
        <Dialog
          open={dialogShuftiPro.show}
          onClose={() => {
            setDialogShuftiPro({ show: false, message: '' });
          }}
          title="Shuftipro Error"
          variant="error"
          actions={
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              justify="center"
              spacing={2}
              className={classes.actionDialogContainer}
            >
              <Grid item xs={12} md={6}>
                <Button
                  fullWidth
                  style={{ color: theme.palette.primary.dark }}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setDialogShuftiPro({ show: false, message: '' });
                  }}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          }
        >
          <Typography variant="h1">{dialogShuftiPro.message}</Typography>
        </Dialog>
      </Grid>
      {(filterDocumentsProof.filter(doc => doc.tag === 'POA').length > 0 ||
        filterDocumentsProof.filter(doc => doc.tag === 'POI').length > 0) &&
        ((hasPOADoc && documentRequirement.tag === 'POA') ||
          (!hasPOADoc && documentRequirement.tag === 'POI')) && (
          <Grid
            container
            justify="center"
            spacing={1}
            style={{ position: 'relative' }}
          >
            <Grid item xs={3} />
            <Grid item xs={5} />
            <Grid item container xs={4} wrap="nowrap" spacing={2}>
              <Box
                height="1px"
                left={-7}
                right={-7}
                top={10}
                position="absolute"
                bgcolor="#DDDFE0"
              />
              <Grid item xs={4} style={{ padding: '30px 0 12px 0' }}>
                <Link
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (isInforce) {
                      if (!isDisabledField(disabledFields, 'shuftipro')) {
                        createIDVSession({
                          applicationId,
                          partyId: party.id,
                          isSendMail: false,
                        });
                      }
                    } else {
                      !handleDisableButton() &&
                        createIDVSession({
                          applicationId,
                          partyId: party.id,
                          isSendMail: false,
                        });
                    }
                  }}
                  style={{
                    fontWeight: 'bold',
                    color: isInforce
                      ? isDisabledField(disabledFields, 'shuftipro')
                        ? theme.palette.grey[400]
                        : theme.palette.primary.dark
                      : handleShutiProButtonColor(
                          handleDisableButton(),
                          isExceedRetry,
                          theme.palette.grey[400],
                          theme.palette.primary.dark,
                          documentRequirement.using3rdPartyService,
                        ),
                    pointerEvents: isInforce
                      ? isDisabledField(disabledFields, 'shuftipro')
                        ? theme.palette.grey[400]
                        : theme.palette.primary.dark
                      : handleShutiProButtonColor(
                          handleDisableButton(),
                          isExceedRetry,
                          theme.palette.grey[400],
                          theme.palette.primary.dark,
                          documentRequirement.using3rdPartyService,
                        ),
                  }}
                  disabled={
                    isInforce
                      ? isDisabledField(disabledFields, 'shuftipro')
                      : getDisabledStatus(
                          documentRequirement.using3rdPartyService,
                          handleDisableButton,
                          isExceedRetry,
                        )
                  }
                >
                  <FormattedMessage {...messages.open} />
                </Link>
              </Grid>
              <Grid item xs={4} style={{ padding: '30px 0 12px 0' }}>
                <Link
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    !getDisabledStatus(
                      documentRequirement.using3rdPartyService,
                      handleDisableButton,
                      isExceedRetry,
                    ) && !isDisabledField(disabledFields, 'shuftipro')
                      ? toggleSend(true)
                      : null;
                  }}
                  style={{
                    fontWeight: 'bold',
                    color: isInforce
                      ? isDisabledField(disabledFields, 'shuftipro')
                        ? theme.palette.grey[400]
                        : theme.palette.primary.dark
                      : handleShutiProButtonColor(
                          handleDisableButton(),
                          isExceedRetry,
                          theme.palette.grey[400],
                          theme.palette.primary.dark,
                          documentRequirement.using3rdPartyService,
                        ),
                    pointerEvents: isInforce
                      ? isDisabledField(disabledFields, 'shuftipro')
                        ? theme.palette.grey[400]
                        : theme.palette.primary.dark
                      : handleShutiProButtonColor(
                          handleDisableButton(),
                          isExceedRetry,
                          theme.palette.grey[400],
                          theme.palette.primary.dark,
                          documentRequirement.using3rdPartyService,
                        ),
                  }}
                  disabled={
                    isInforce
                      ? isDisabledField(disabledFields, 'shuftipro')
                      : getDisabledStatus(
                          documentRequirement.using3rdPartyService,
                          handleDisableButton,
                          isExceedRetry,
                        )
                  }
                >
                  <FormattedMessage {...messages.send} />
                </Link>
              </Grid>
              <Grid item xs={4} style={{ paddingTop: '30px' }}>
                <Link
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    if (!checkShutiProVerifiedStatus()) {
                      toggleUpload(true);
                    }
                    // window.close();
                  }}
                  style={{
                    fontWeight: 'bold',
                    color:
                      documentRequirement.tag === 'POI' &&
                      checkShutiProVerifiedStatus()
                        ? theme.palette.grey[400]
                        : theme.palette.primary.dark,
                    pointerEvents: checkShutiProVerifiedStatus()
                      ? 'none'
                      : 'auto',
                  }}
                  disabled={
                    documentRequirement.tag === 'POI' &&
                    checkShutiProVerifiedStatus()
                  }
                >
                  <FormattedMessage {...messages.upload} />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
    </>
  );
}

ActionContainer.propTypes = {};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
  countIdvSubmit: makeSelectCountIdvSubmit(),
});

function mapDispatchToProps(dispatch) {
  return {
    createIDVSession: data => dispatch(createIDVSessionAction(data)),
    setOtherDocumentStatus: data => dispatch(setOtherDocumentStatusAction(data))
    // getHealthQuestions: () => dispatch(callHealthQuestionsAction()),
  };
}

function areEqual(prevProps, nextProps) {
  return (
    isEqual(prevProps.party, nextProps.party) &&
    isEqual(prevProps.countIdvSubmit, nextProps.countIdvSubmit)
  );
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(React.memo(ActionContainer, areEqual));

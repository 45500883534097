/*
 *
 * OpsDashboardPage actions
 *
 */

import {
  GET_PRODUCT_CONFIG,
  SET_PRODUCT_CONFIG,
  RESET_PRODUCT_CONFIG,
  SET_PRODUCT_CONFIG2,
  FETCH_ILLUSTRATIONS_ACTION,
  SET_QUOTES_ACTION,
  GET_QUOTES_ACTION,
  GET_SEARCH_ACTION,
  SET_PRODUCT_ID,
  SET_ERROR_ACTION,
  CLONE_PRODUCT,
  SEND_EMAIL_ACTION,
  GET_CUSTOMERS_ACTION,
  SET_CUSTOMERS_ACTION,
  SET_CUSTOMER_DETAIL_ACTION,
  DELETE_ILLUSTRATION_ACTION,
  SET_DELETE_ILLUSTRATION_ACTION,
  GET_APPLICATION_DATA_ACTION,
  SET_APPLICATION_DATA_ACTION,
  REJECT_APPLICATION_ACTION,
  SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION,
  SET_LIST_APPLICATION_PARTY_ACTION,
  GET_LIST_APPLICATION_PARTY_ACTION,
  GET_APPLICATION_LIST_ACTION,
  SET_APPLICATION_LIST_ACTION,
  GET_ADMIN_ACTION_DETAIL_ACTION,
  SET_ADMIN_ACTION_DETAIL_ACTION,
  GET_APPLICATION_DETAILS_ACTION,
  SET_APPLICATION_DETAILS_ACTION,
  GET_SUBMIT_ADMIN_ACTION,
  SET_SUBMIT_ADMIN_ACTION,
  GET_ALL_DOCUMENTS_ACTION,
  SET_ALL_DOCUMENTS_ACTION,
  GET_SINGLE_DOCUMENTS_ACTION,
  SET_SINGLE_DOCUMENTS_ACTION,
  SEND_ADMIN_ACTION_EMAIL_ACTION,
  CALL_INITIAL_SESSION,
  UPDATE_APPLICATION_DETAILS_ACTION,
  UPDATE_APPLICATION_PARTY_ACTION,
  UPDATE_APPLICATION_PARTIES_ACTION,
  ADD_APPLICATION_PARTY_ACTION,
  ADD_APPLICATION_PARTIES_ACTION,
  DELETE_APPLICATION_PARTY_ACTION,
  DELETE_APPLICATION_PARTIES_ACTION,
  UPDATE_APPLICATION_STATUS_ACTION,
  SET_LIFE_INSURED_PARTIES_ACTION,
  CALL_FINANCIAL_INSTITUTE_OPTION,
  DELETE_APPLICATION_ACTION,
  SET_DELETE_APPLICATION_ACTION,
  SET_LOGOUT_WHEN_UPDATE_APP_ACTION,
  SET_ILLUSTRATIONS_ACTION,
} from './constants';

export function getProductConfigAction(payload) {
  return {
    type: GET_PRODUCT_CONFIG,
    payload,
  };
}

export function setProductConfigAction(payload) {
  return {
    type: SET_PRODUCT_CONFIG,
    payload,
  };
}

export function setProductConfig2Action(payload) {
  return {
    type: SET_PRODUCT_CONFIG2,
    payload,
  };
}

export function setErrorAction(payload) {
  return {
    type: SET_ERROR_ACTION,
    payload,
  };
}

export function setProductIdAction(payload) {
  return {
    type: SET_PRODUCT_ID,
    payload,
  };
}

export function resetProductConfigAction() {
  return {
    type: RESET_PRODUCT_CONFIG,
  };
}

export function cloneProductAction(payload) {
  return {
    type: CLONE_PRODUCT,
    payload,
  };
}

export function sendEmailAction(payload) {
  return {
    type: SEND_EMAIL_ACTION,
    payload,
  };
}

export function fetchIllustrationsAction(agentDetails = '') {
  return {
    type: FETCH_ILLUSTRATIONS_ACTION,
    payload: agentDetails.agentNumber,
  };
}

export function getCustomersAction(searchText = '') {
  return {
    type: GET_CUSTOMERS_ACTION,
    payload: searchText,
  };
}

export function setCustomersAction(customers = []) {
  return {
    type: SET_CUSTOMERS_ACTION,
    payload: customers,
  };
}

export function getQuotesAction(data) {
  return {
    type: GET_QUOTES_ACTION,
    payload: data,
  };
}

export function setQuotesAction(quote = {}) {
  return {
    type: SET_QUOTES_ACTION,
    payload: quote,
  };
}

export function setCustomerDetailAction(data) {
  return {
    type: SET_CUSTOMER_DETAIL_ACTION,
    payload: data,
  };
}

export function deleteIllustrationAction(payload) {
  return {
    type: DELETE_ILLUSTRATION_ACTION,
    payload: payload,
  };
}

export function setDeleteIllustrationAction(payload) {
  return {
    type: SET_DELETE_ILLUSTRATION_ACTION,
    payload: payload,
  };
}

// for nexus admin - review page
export function getApplicationDataAction(payload) {
  return {
    type: GET_APPLICATION_DATA_ACTION,
    payload: payload
  };
}

export function setApplicationDataAction(payload) {
  return {
    type: SET_APPLICATION_DATA_ACTION,
    payload: payload
  };
}

export function getAdminActionDetailAction(payload) {
  return {
    type: GET_ADMIN_ACTION_DETAIL_ACTION,
    payload: payload
  };
}
export function setAdminActionDetailAction(payload) {
  return {
    type: SET_ADMIN_ACTION_DETAIL_ACTION,
    payload: payload
  };
}

export function getSubmitAdminAction(payload) {
  return {
    type: GET_SUBMIT_ADMIN_ACTION,
    payload: payload
  };
}
export function setSubmitAdminAction(payload) {
  return {
    type: SET_SUBMIT_ADMIN_ACTION,
    payload: payload
  };
}

export function getAllDocumentsAction(payload) {
  return {
    type: GET_ALL_DOCUMENTS_ACTION,
    payload: payload
  };
}
export function setAllDocumentsAction(payload) {
  return {
    type: SET_ALL_DOCUMENTS_ACTION,
    payload: payload
  };
}

export function getSingleDocumentsAction(payload) {
  return {
    type: GET_SINGLE_DOCUMENTS_ACTION,
    payload: payload
  };
}
export function setSingleDocumentsAction(payload) {
  return {
    type: SET_SINGLE_DOCUMENTS_ACTION,
    payload: payload
  };
}

export function sendAdminActionEmailAction(payload) {
  return {
    type: SEND_ADMIN_ACTION_EMAIL_ACTION,
    payload: payload
  };
}

export function rejectApplicationAction(payload) {
  return {
    type: REJECT_APPLICATION_ACTION,
    payload: payload
  };
}

  // for nexus admin - dashboard
  export function callFinancialInstituteOptionAction() {
    return {
      type: CALL_FINANCIAL_INSTITUTE_OPTION,
    };
  }

  export function setListApplicationPartyAction(payload) {
    return {
      type: SET_LIST_APPLICATION_PARTY_ACTION,
      payload,
    };
  }

  export function getApplicationDetailsAction(payload) {
    return { 
      type: GET_APPLICATION_DETAILS_ACTION, 
      payload 
    };
  }

  export function setApplicationDetailsAction(payload) {
    return { 
      type: SET_APPLICATION_DETAILS_ACTION,
      payload 
    };
  }

  export function getSearchIllustrationsAction(data) { 
     return {
       type: GET_SEARCH_ACTION,
       payload: data,
     };
   }


  	//INITIAL SESSION
export function callInitialSessionAction(payload) {
  return {
    type: CALL_INITIAL_SESSION,
    payload,
  };
}
export function setIllustrationsAction(illustrations = []) {
  return {
    type: SET_ILLUSTRATIONS_ACTION,
    payload: illustrations,
  };
}


export function addApplicationPartyAction(payload) {
  return {
    type: ADD_APPLICATION_PARTY_ACTION,
    party: payload.party,
    applicationId: payload.applicationId,
  };
}
export function addApplicationPartiesAction(payload) {
  return {
    type: ADD_APPLICATION_PARTIES_ACTION,
    parties: payload.parties,
    applicationId: payload.applicationId,
  };
}
export function deleteApplicationPartyAction(payload) {
  return {
    type: DELETE_APPLICATION_PARTY_ACTION,
    party: payload.party,
    applicationId: payload.applicationId,
  };
}
export function deleteApplicationPartiesAction(payload) {
  return {
    type: DELETE_APPLICATION_PARTIES_ACTION,
    parties: payload.parties,
    applicationId: payload.applicationId,
  };
}

//APPLICATION LIST
export function getApplicationListAction(data) {
  return { type: GET_APPLICATION_LIST_ACTION, payload: data };
}
export function setApplicationListAction() {
  return { type: SET_APPLICATION_LIST_ACTION };
}
export function setFinancialInstituteOptionAction(payload) {
  return { type: SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION, payload };
}
export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}
export function getListApplicationsPartyAction(data) {
  return { type: GET_LIST_APPLICATION_PARTY_ACTION, payload: data };
}
export function deleteApplicationAction(payload) {
  return {
    type: DELETE_APPLICATION_ACTION,
    payload,
  };
}
export function setDeleteApplicationAction(payload) {
  return {
    type: SET_DELETE_APPLICATION_ACTION,
    payload,
  };
}
export function setLogoutWhenUpdateAppAction(payload) {
  return {
    type: SET_LOGOUT_WHEN_UPDATE_APP_ACTION,
    payload,
  };
}


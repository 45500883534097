/**
 *
 * FormTextField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { WrappedFieldProps } from 'redux-form';
import Helper from 'components/Helper';
import { blue } from '@material-ui/core/colors';
import {
  Grid,
  Radio,
  FormControl,
  FormControlLabel,
  Typography,
  MenuItem,
  Box,
  RadioGroup,
  Divider,
  // Switch,
} from '@material-ui/core';
import TextField from 'components/TextField';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

import { isFirstChild } from 'containers/ApplicationFormPage/helpers';

import OptSwitch from 'components/OptSwitch';
import { forEach, filter } from 'lodash/collection';
import { has } from 'lodash/object';
import CardSectionForm from 'components/CardSectionForm';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CustomRadio from 'components/CustomRadioButton';
const useStyle = makeStyles(theme => ({
  OptSwitch: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    borderRadius: '10px',
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  OptSwitchNoContainer: {
    display: 'flex',
    flexDirection: 'row',

    marginTop: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectBorder: {
    [`& fieldset`]: {
      borderRadius: '10px',
    },
  },
  title: {
    fontWeight: 800,
    fontSize: '14px',
    // color: theme.palette.primary.dark,
  },
  title2: {
    fontWeight: 800,
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
}));

// const CustomRadio = withStyles({
//   root: {
//     color: blue[600],
//     '&$checked': {
//       color: blue[800],
//     },
//   },
//   checked: {},
// })(props => <Radio {...props} />);

const FormTextField = ({
  question,
  input,
  showLabel = true,
  meta: { invalid, error, touched, warning },
  helperText,
  childQuestions,
  questions,
  ...custom
}) => {
  let helperMsg = helperText;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  const classes = useStyle();
  const theme = useTheme();

  function createMarkup() {
    return { __html: question.richText };
  }

  const isTrueOrFalse = () => {
    if (question.constraints.choices.length == 2) {
      var isBool = true;
      forEach(question.constraints.choices, choice => {
        if (choice.text === 'Yes' || choice.text === 'No') {
          isBool = true;
        } else {
          isBool = false;
        }
      });

      return isBool;
    }

    return false;
  };
  if (isTrueOrFalse() && typeof question.parentQuestionId === 'undefined') {
    return (
      <CardSectionForm variant="outlined">
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
          // wrap="nowrap"
        >
          <Grid item xs={10}>
            <Typography variant="body1">
              <span dangerouslySetInnerHTML={createMarkup()} />
              {/* {question.text}{' '} */}
              {question.helpText.length > 0 && (
                <Helper
                  // helperText={'This is a sample text'}
                  helperText={question.helpText}
                />
              )}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              style={{ marginLeft: theme.spacing(2) }}
              control={
                <OptSwitch
                  checked={input.value ? true : false}
                  value={input.value ? true : false}
                  color="default"
                  // labelPositive={'yes'}
                  labelNegative={'no'}
                />
              }
              {...input}
              {...custom}
            />
          </Grid>
        </Grid>
        {childQuestions}
      </CardSectionForm>
    );
  } else if (isTrueOrFalse()) {
    return (
      <div className={classes.OptSwitchNoContainer}>
        <Grid
          container
          alignItems="center"
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
        >
          {isFirstChild(questions, question) && (
            <>
              {' '}
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.title2}>
                  {question.context[0]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: '1rem 0' }} />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              component="fieldset"
              style={{ width: '100%' }}
            >
              <Grid container>
                <Grid item xs={8}>
                  {showLabel && (
                    <Typography variant="body1" className={classes.title}>
                      {question.text}
                      {question.helpText.length > 0 && (
                        <Helper
                          // helperText={'This is a sample text'}
                          helperText={question.helpText}
                        />
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <RadioGroup
                    value={input.value}
                    onChange={e => {
                      input.onChange(e.target.value);
                    }}
                    style={{ display: 'flex', flexDirection: 'row-reverse' }}
                  >
                    {question.constraints.choices.map((choice, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          value={choice.value}
                          control={<CustomRadio />}
                          label={choice.text}
                        />
                      );
                    })}
                  </RadioGroup>
                </Grid>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  } else {
    if (
      question.text === 'What is the purpose of the insurance?' &&
      question.id === 2
    ) {
      return (
        <div className={classes.OptSwitchNoContainer}>
          <Grid
            container
            alignItems="center"
            style={{
              paddingTop: theme.spacing(2),
              paddingBottom: theme.spacing(2),
            }}
          >
            <Grid item xs={12}>
              <Divider style={{ margin: '3rem 0' }} />
              <FormControl
                variant="outlined"
                component="fieldset"
                style={{ width: '100%' }}
              >
                {showLabel && (
                  <Typography variant="body1" className={classes.title}>
                    {question.text}
                    {question.helpText.length > 0 && (
                      <Helper
                        // helperText={'This is a sample text'}
                        helperText={question.helpText}
                      />
                    )}
                  </Typography>
                )}

                <br />
                <RadioGroup
                  value={input.value}
                  onChange={e => {
                    input.onChange(e.target.value);
                  }}
                >
                  {question.constraints.choices.map((choice, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        style={{
                          marginBottom: '1rem',
                        }}
                        value={choice.value}
                        control={<CustomRadio />}
                        label={
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 800 }}
                          >
                            {choice.text}
                          </Typography>
                        }
                      />
                    );
                  })}
                </RadioGroup>
                <span>{helperMsg}</span>
              </FormControl>
              <Divider style={{ margin: '3rem 0' }} />
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div className={classes.OptSwitchNoContainer}>
        <Grid
          container
          alignItems="center"
          style={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
          }}
        >
          {isFirstChild(questions, question) && (
            <>
              {' '}
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.title2}>
                  {question.context[0]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: '1rem 0' }} />
              </Grid>
            </>
          )}
          <Grid item xs={6}>
            <FormControl
              variant="outlined"
              component="fieldset"
              style={{ width: '100%' }}
            >
              {showLabel && (
                <Typography variant="body1" className={classes.title}>
                  {question.text}
                  {question.helpText.length > 0 && (
                    <Helper
                      // helperText={'This is a sample text'}
                      helperText={question.helpText}
                    />
                  )}
                </Typography>
              )}

              <br />

              <TextField
                className={classes.selectBorder}
                select
                value={input.value}
                error={!!warning || (touched && invalid)}
                helperText={helperMsg}
                onChange={e => {
                  input.onChange(e.target.value);
                }}
                IconComponent={({ className }) => {
                  return (
                    <KeyboardArrowDownIcon
                      style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                      className={className}
                    />
                  );
                }}
                variant="outlined"
              >
                {question.constraints.choices.map(choice => {
                  return (
                    <MenuItem key={choice.value} value={choice.value}>
                      {choice.text}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>
          </Grid>
          {childQuestions}
        </Grid>
      </div>
    );
  }
};

FormTextField.propTypes = {
  ...WrappedFieldProps,
  helperText: PropTypes.node,
};

export default FormTextField;

import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { map } from 'lodash/collection';
import { compose } from 'redux';
import messages from 'containers/ApplicationFormPage/messages';
import { formatBytes } from 'helpers/math';
import { isEmpty } from 'lodash/lang';
import IconFile from 'images/icon_file.svg';
import IconUpload from 'images/ic_upload.svg';

//MATERIAL UI
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { makeSelectOtherDocumentStatus } from 'containers/ApplicationFormPage/selectors';

//action

const useStyle = makeStyles(theme => ({
  uploadContainer: {
    padding: '0 1rem',
  },
  bold: {
    fontWeight: 700,
  },
  button: {
    width: '100%',
    minHeight: '95px',
    border: `1px dashed ${theme.palette.primary.light}`,
  },
  multiButtonContainer: {
    width: '100%',
    minHeight: '95px',
    border: `1px dashed ${theme.palette.primary.light}`,
  },
  uploadButtonIcon: {
    marginRight: '1rem',
  },
  multiFileContainer: {
    padding: '0.7rem',
    border: `2px solid #A0B3D9`,
    height: '60px',
    marginBottom: '1rem',
  },
  fileContainer: {
    padding: '2rem',
    border: `2px solid #A0B3D9`,
  },
  fileIcon: {
    height: '40px',
    width: '40px',
    color: theme.palette.primary.main,
  },
}));

function UploadDialog(props) {
  const theme = useTheme();
  const classes = useStyle();
  const intl = useIntl();

  const {
    party,
    handleFileUpload,
    handleDocumentFile,
    handlePhotoFile,
    file,
    setFile,
    documentRequirement,
    otherDocumentStatus,
    ...propList
  } = props;

  useEffect(() => {
    if (otherDocumentStatus.files && otherDocumentStatus.files.length > 0) {
      const filteredFiles = [];
      const filesThatHasError = otherDocumentStatus.files;
      for (const f of file) {
        if (!filesThatHasError.find(erroFile => erroFile.localName === f?.name)) {
          filteredFiles.push(f)
        }
      }
      setFile([...filteredFiles]);
    }
  }, [otherDocumentStatus])

  return (
    <div className={classes.uploadContainer}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.bold}>
            {documentRequirement.text}:
          </Typography>
        </Grid>
        <Grid item xs={12} md={12}>
          <input
            id="selectImage"
            hidden
            type="file"
            // accept="image/*"
            multiple
            onClick={e => {
              e.target.value = null;
            }}
            onChange={e => handleDocumentFile(documentRequirement.tag, e)}
          />
          <Button
            // variant="outlined"
            color="primary"
            margin="normal"
            id="plus"
            className={classes.multiButtonContainer}
            onClick={handlePhotoFile}
          >
            <img src={IconUpload} className={classes.uploadButtonIcon} alt="" />
            <FormattedMessage {...messages.uploadDocument} />
          </Button>
        </Grid>
        {!isEmpty(file) && (
          <Grid item xs={12}>
            {map(file, f => {
              return (
                <div className={classes.multiFileContainer}>
                  <Grid
                    item
                    xs
                    container
                    alignItems="center"
                    justify="center"
                    wrap="nowrap"
                    spacing={1}
                  >
                    <Grid item>
                      <img src={IconFile} className={classes.fileIcon} />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 'bold' }}
                      >
                        {f.originalFileName ? f.originalFileName : f.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} container justify="flex-end">
                      <Typography variant="body2">
                        {f.fileSize
                          ? formatBytes(parseInt(f.fileSize))
                          : formatBytes(f.size)}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </Grid>
        )}
      </Grid>
    </div>
  );
}

UploadDialog.propTypes = {};

const mapStateToProps = createStructuredSelector({
  otherDocumentStatus: makeSelectOtherDocumentStatus()
});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(UploadDialog);

import React from 'react';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';

export const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    max-width: 920px;
    height: 100%;
    padding-top: 5rem;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
  }
`;

/**
 *
 * OpsDashboardPage - index.js
 *
 */

import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { useTheme, makeStyles } from '@material-ui/core/styles';
// import { usePrevious } from 'utils/hooks';
import {
  callInitialSessionAction,
  getApplicationDetailsAction,
  callFinancialInstituteOptionAction,
  sendEmailAction,
} from './actions';
import { getSubmitAdminAction } from 'containers/OpsDashboardPage/actions';
import OpsDashboard from './components/OpsDashboard'
import reducer from './reducer';
import saga from './saga';
import {
  makeSelectCustomerDetail,
  makeSelectError,
  makeSelectProductConfig,
  makeSelectProductId,
} from './selectors';
import {
  makeSelectQuoteType,
  makeSelectAgentDetails,
} from 'containers/App/selectors';
import { makeSelectListApplicationParty } from './selectors';
import { getListApplicationsPartyAction } from 'containers/OpsDashboardPage/actions';

const useStyles = makeStyles(theme => ({}));

export function OpsDashboardPage({
  getInterviewSessionId,
    getApplicationDetails,
    getFinancialInstitute,
    agentDetails,
    listApplication,
    getApplicationListParty,
    sendEmail,
    getSubmitAdmin
}) {
  useInjectReducer({ key: 'OpsDashboardPage', reducer });
  useInjectSaga({ key: 'OpsDashboardPage', saga });

  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const [valueSearch, setValueSearch] = useState('');
  const accessToken = localStorage.getItem('okta-token-storage');
  const zioId = JSON.parse(accessToken).accessToken.claims.zio;

  useEffect(() => {
    console.log("OPSDASHBOARD INDEX USEEFFECT CALLED")
    const agencyNumber = agentDetails.agentNumber;
    getApplicationListParty({ valueSearch: '' });
    updateAdminAgentNumber()
  }, []);

// scenario for locking the application by admin
  const updateAdminAgentNumber = async() => {
    
    const agentNumberStored = sessionStorage.getItem("agentNumber");
    const alreadySubmitted = sessionStorage.getItem("alreadySubmitted");
    if (alreadySubmitted == 'false' && agentNumberStored == zioId)  {
          // const referenceNumber = sessionStorage.getItem("referenceNumber");
          const data = {
            applicationId: sessionStorage.getItem("applicationId"),
            agentNumber: "",
            // referenceNumber: referenceNumber,
          }
          console.log("updateAdminAgentNumber in index :: before getSubmitAdmin :: data", data);
          await getSubmitAdmin(data);
        }
  };

  const onSearch = () => {
    const agencyNumber = agentDetails.agentNumber;
    getApplicationListParty({ valueSearch });
  };

  return (

    <div>
        <Route
          path="/dashboard"
          exact
          render={props => (
            <OpsDashboard
              {...props}
              setValueSearch={setValueSearch}
              onSearch={onSearch}
              listApplication={listApplication}
              sendEmail={val => emailQuote(val)}
            />
          )}
        />
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  agentDetails: makeSelectAgentDetails(),
  listApplication: makeSelectListApplicationParty(),
});

function mapDispatchToProps(dispatch) {
  return {
    getInterviewSessionId: value => dispatch(callInitialSessionAction(value)),
    getApplicationDetails: value =>
      dispatch(getApplicationDetailsAction(value)),
    getFinancialInstitute: () => dispatch(callFinancialInstituteOptionAction()),
    getApplicationListParty: value =>
      dispatch(getListApplicationsPartyAction(value)),
    emailQuote: val => dispatch(sendEmailAction(val)),
    getSubmitAdmin: value => dispatch(getSubmitAdminAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OpsDashboardPage);

/**
 * PersonalDetails
 */
 import React from 'react';
 import PropTypes from 'prop-types';
 import { FormattedMessage, useIntl } from 'react-intl';
 import { useTheme } from '@material-ui/core/styles';
 import { Field } from 'redux-form/immutable';
 import { Grid, FormControlLabel, Box } from '@material-ui/core';
 import globalMessages from 'containers/App/messages';
 import FormTextField from 'components/FormTextField';
 import FormDatePicker from 'components/FormDatePicker';
 import FormRadioGroup from 'components/FormRadioGroup';
 import FormCheck from 'components/FormCheck';
 import FormSelect from 'components/FormSelect';
 import FormBooleanOption from 'components/FormBooleanOption';
 import CustomRadio from 'components/CustomRadioButton';
 import Helper from 'components/Helper';
 import { changePlaceHolderColor, changeToTitleCase } from 'helpers/lang';
 
 
 const lessThan = otherField => (value, previousValue, allValues) => {
   if (!value) return '';
   return value.length <= otherField ? value : previousValue;
 };
 
 
 function PersonalDetails({
   disabled,
   formValues = {},
   options,
   disabledPolicyOwner,
   isNationalityShowing,
   minDate,
   maxDate,
 }) {
 
   const theme = useTheme();
   const intl = useIntl();
 
   return (
     <Box mb={3} ml={1}>
       <Grid container spacing={3} alignItems="center">
         <Grid item>
           <Field
             name="firstName"
             disabled={disabled}
             style={{ width: 260 }}
             component={FormTextField}
             label={<FormattedMessage {...globalMessages.firstName} />}
             type="text"
             margin="normal"
             normalize={lessThan(45)}
             placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
             format = {(value) => changeToTitleCase(value)} 
           />
         </Grid>
         
         <Grid item>
           <Field
             name="lastName"
             disabled={disabled}
             style={{ width: 260 }}
             component={FormTextField}
             label={<FormattedMessage {...globalMessages.familyName} />}
             margin="normal"
             normalize={lessThan(40)}
             placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
             format = {(value) => changeToTitleCase(value)}  
           />
         </Grid>
         <Grid container />
         <Grid item>
           <Field
             style={{ width: 260 }}
             disabled={disabled}
             name="dateOfBirth"
             component={FormDatePicker}
             label={<FormattedMessage {...globalMessages.dob} />}
             dateInputFormat="DD/MM/YYYY"
             placeholder="dd/mm/yyyy"
             margin="normal"
             minDate={minDate}
             maxDate={maxDate}
             inputProps={{ inputMode: 'numeric' }}
             InputLabelProps={{
               style: {
                 fontSize: theme.typography.body2.fontSize,
               },
             }}
           />
         </Grid>
         <Grid item>
           <Field
             name="gender"
             type="radio"
             component={FormRadioGroup}
             disabled={disabled}
           >
             <FormControlLabel
               disabled={disabled}
               style={{ marginRight: theme.spacing(2) }}
               control={<CustomRadio checked={formValues.gender === '1'} />}
               value={1}
               label={<FormattedMessage {...globalMessages.male} />}
               labelPlacement="end"
             />
 
             <FormControlLabel
               disabled={disabled}
               control={<CustomRadio checked={formValues.gender === '0'} />}
               value={0}
               label={<FormattedMessage {...globalMessages.female} />}
               labelPlacement="end"
             />
           </Field>
         </Grid>
         <Grid item xs={12}>
           <Field
             // shrink
             name="isSmoke"
             disabled={disabled}
             component={FormBooleanOption}
             type="text"
             margin="normal"
             label={
               <>
                 <FormattedMessage {...globalMessages.isSmoker} />
                 <Helper
                   helperText={globalMessages.tooltipInsured.defaultMessage}
                 />
               </>
             }
             fullWidth
           />
         </Grid>
         {isNationalityShowing ? (
           <Grid item>
             <Field
               // shrink
               disabled={disabled}
               name="nationality"
               style={{
                 width: 355,
                 color: changePlaceHolderColor(formValues.nationality),
               }}
               component={FormSelect}
               // options={options.countryCode}
               options={[
                 { value: 'Select an option', title: 'Select an option' },
                 ...options.countryCode,
               ]}
               label={<FormattedMessage {...globalMessages.nationality} />}
               placeholder={intl.formatMessage(globalMessages.select)}
               type="text"
               margin="normal"
             />
           </Grid>
         ) : null}
         <Grid item xs={12}>
           <Field
             // shrink
             name="isPolicyOwner"
             fullWidth
             disabled={disabledPolicyOwner || disabled}
             component={FormCheck}
             label={<FormattedMessage {...globalMessages.thisIsPolicyOwner} />}
             type="text"
           />
         </Grid>
       </Grid>
       <Grid item xs={12} style={{ visibility: 'hidden' }}>
         <Field
           name="prospectId"
           component={FormTextField}
           type="hidden"
           // margin="normal"
           // variant="standard"
         />
       </Grid>
     </Box>
   );
 }
 
 PersonalDetails.propTypes = {
   options: PropTypes.object,
   formValues: PropTypes.object,
   disabledPolicyOwner: PropTypes.bool,
   isNationalityShowing: PropTypes.bool,
   minDate: PropTypes.number,
   maxDate: PropTypes.number,
   productCode: PropTypes.string,
   error: PropTypes.bool,
   disabled: PropTypes.bool,
 };
 
 export default PersonalDetails;
 
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import { isEqual } from 'lodash/lang';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { filter } from 'lodash/collection';

import messages from 'containers/ApplicationFormPage/messages';
import globalMessages from 'containers/App/messages';
import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';

import {
  Grid,
  Typography,
  Divider,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
  Collapse,
  IconButton,
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import Minus from 'icons/Minus';
import { convertIfImmutable } from 'helpers/lang';

//selectors
import { makeSelectOption } from 'containers/App/selectors';

//components

import FormNumberField from 'components/FormNumberField';

function FundList(props) {
  const theme = useTheme();
  const intl = useIntl();

  const { fields, classes, formValues, search, setDeleted, checkFund } = props;

  return fields.map((field, index) => {
    const parsedItem = convertIfImmutable(fields.get(index));
    return (
      <Grid container item xs={12} key={index}>
        <Grid item xs={10} className={classes.boxFavourites}>
          <Grid item xs={6} className={classes.titleEuro}>
            {parsedItem.autoStartegyDesc}
            {parsedItem.fundDescription}
          </Grid>
          <Grid item xs={2} align="center">
            {parsedItem.fundCcy}
          </Grid>
          <Grid item xs={3} className={classes.eur} align="center">
            {parsedItem.autoStrategyCode}
            {parsedItem.fundCode}
          </Grid>
          <Grid item xs={1} align="right">
            <IconButton
              onClick={() => {
                fields.remove(index);
                setDeleted(false);
              }}
            >
              <Minus />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={2} className={classes.inputFavourites}>
          <Field
            shrink
            name={`${field}.percent`}
            fullWidth
            component={FormNumberField}
            isAllowed={v => {
              if (v.floatValue > 100 || v.floatValue < 1) {
                return false;
              }
              return true;
            }}
            defaultValue={1}
            color="primary"
            margin="normal"
            variant="standard"
            placeholder="%"
            thousandSeparator=""
            // onBlur={() => checkFund()}
          />
        </Grid>
      </Grid>
    );
  });
}

// FundList.propTypes = {
// options: PropTypes.array,
// nationlityOptions: PropTypes.array,
// setDeleted: PropTypes.func
// };

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(FundList);

/**
 *
 * FormRadioGroup
 *
 */

import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { RadioGroup, FormControl, FormHelperText } from '@material-ui/core';

const FormRadioGroup = ({
  label,
  input,
  meta: { error, touched, warning },
  ...custom
}) => {
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }
  return (
    <FormControl component="fieldset" error={!!warning || (touched && !!error)}>
      <RadioGroup
        row
        label={label}
        defaultValue="left"
        // value={value}
        // onBlur={(e) => {
        //   onBlur(value)
        // }}
        {...input}
        {...custom}
      />
      {helperMsg && <FormHelperText error>{helperMsg}</FormHelperText>}
    </FormControl>
  );
};

FormRadioGroup.propTypes = {
  ...WrappedFieldProps,
};

export default FormRadioGroup;

import React from 'react';
import { forEach } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';

const validate = values => {
  const errors = { paymentDetails: { payerDetail: {} } };
  const parseValues = values.toJS();

  const requiredTextFields = [
    'accountHolderName',
    'cardNumber',
    'bankName',
    'iban',
    'directDebitSetter',
    'directDebitSource',
    'card_holder_name',
    'card_number',
    'expiry_date',
    'cardCurrency',
    'preferredDateOfCollection',
    'card_security_code',
  ];

  if (!isEmpty(parseValues) && !isEmpty(parseValues.paymentDetails)) {
    forEach(requiredTextFields, field => {
      if (!parseValues.paymentDetails[field]) {
        errors.paymentDetails[field] = 'Field is Required';
      } else if (
        field === 'card_holder_name' ||
        field === 'accountHolderName'
      ) {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var formatNumbers = /^[^0-9]+$/;
        if (
          format.test(parseValues.paymentDetails[field]) ||
          !formatNumbers.test(parseValues.paymentDetails[field])
        ) {
          errors.paymentDetails[field] =
            'Field cannot have numbers or special characters';
        } else if (parseValues.paymentDetails[field]?.length > 45) {
          errors.paymentDetails[field] = 'Maximum characters allowed is 45';
        }
      } else if (field === 'card_number') {
        if (
          parseValues.paymentDetails[field]?.length > 16 ||
          parseValues.paymentDetails[field]?.length < 16
        ) {
          errors.paymentDetails[field] = 'card number only contains 16 digits';
        }
      } else if (field === 'cardNumber') {
        if (
          parseValues.paymentDetails[field]?.length < 1 ||
          parseValues.paymentDetails[field]?.length > 16
        ) {
          errors.paymentDetails[field] =
            'account number only contains 16 digits';
        }
      } else if (field === 'iban') {
        var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        var formatIban = /[[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){4})(?:[ ]?[0-9]{3})]?/;
        if (parseValues.paymentDetails[field]?.length > 23) {
          errors.paymentDetails[field] = 'IBAN only contains 23 characters max';
        } else if (format.test(parseValues.paymentDetails[field])) {
          errors.paymentDetails[field] = 'Field cannot have special characters';
        } else if (!formatIban.test(parseValues.paymentDetails[field])) {
          errors.paymentDetails[field] = 'Invalid IBAN format';
        }
      } else if (field === 'expiry_date') {
        const date = moment(parseValues.paymentDetails[field]);
        const dateYesterday = moment()
          .subtract(1, 'days')
          .startOf('month')
          .format();
        if (!date.isValid()) {
          errors.paymentDetails[field] = 'Date invalid, only accepts MM/YY';
        } else if (date.startOf('month').format() < dateYesterday) {
          errors.paymentDetails[field] = "Date can't be past dates";
        }
      } else if (field === 'preferredDateOfCollection') {
        if (
          parseValues.paymentDetails[field] > 31 ||
          parseValues.paymentDetails[field] <= 0
        ) {
          errors.paymentDetails[field] = 'only accepts days 1-31';
        }
      } else if (field === 'bankName') {
        if (parseValues.paymentDetails[field] === 'Select an option') {
          errors.paymentDetails[field] = 'Field is Required';
        }
      } else if (field === 'cardCurrency') {
        if (parseValues.paymentDetails[field] === 'Select an option') {
          errors.paymentDetails[field] = 'Field is Required';
        }
      }
    });
  }

  if (isEmpty(parseValues) || isEmpty(parseValues.paymentDetails)) {
    errors.paymentDetails.accountHolderName = 'Field is Required';
    errors.paymentDetails.card_holder_name = 'Field is Required';
  }

  // Validate for payment link form

  if (!parseValues?.paymentDetails?.payerDetail?.isUseCCDirectly) {
    const payerEmail = parseValues?.paymentDetails?.payerDetail?.payerEmail;
    const cardCurrency = parseValues?.paymentDetails?.payerDetail?.cardCurrency;
    const preferredDateOfCollection =
      parseValues?.paymentDetails?.payerDetail?.preferredDateOfCollection;
    const emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!emailReg.test(payerEmail)) {
      errors.paymentDetails.payerDetail.payerEmail = 'Invalid Email Address';
    }

    if (cardCurrency === 'Select an option' || !cardCurrency) {
      errors.paymentDetails.payerDetail.cardCurrency = 'Field is Required';
    }

    if (
      !preferredDateOfCollection ||
      preferredDateOfCollection > 31 ||
      preferredDateOfCollection <= 0
    ) {
      errors.paymentDetails.payerDetail.preferredDateOfCollection =
        'only accepts days 1-31';
    }
  }

  return errors;
};

export default validate;

/*
 * FuturaIllustrationPage Messages
 *
 * This contains all the text for the FuturaIllustrationPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.FuturaIllustrationPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the FuturaIllustrationPage container!',
  },
  policyOwner: {
    id: `${scope}.policyOwner`,
    defaultMessage: 'Policy Owner',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email address (optional)',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  selectOption: {
    id: `${scope}.selectOption`,
    defaultMessage: 'Select an option',
  },
  isSecondPolicyOwner: {
    id: `${scope}.isSecondPolicyOwner`,
    defaultMessage: 'Do you want to add a second policy owner?',
  },
  whoLifeInsured: {
    id: `${scope}.whoLifeInsured`,
    defaultMessage: 'Who will be the life insured?',
  },
  insuredOne: {
    id: `${scope}.insuredOne`,
    defaultMessage: 'Insured 1',
  },
  insuredTwo: {
    id: `${scope}.insuredTwo`,
    defaultMessage: 'Insured 2',
  },
  isSecondInsured: {
    id: `${scope}.isSecondInsured`,
    defaultMessage: 'Do you want to insure a second life e.g. a spouse?',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  coverageAmount: {
    id: `${scope}.coverageAmount`,
    defaultMessage: 'Coverage Amount Required',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Policy term (in years)',
  },
  policyFrequency: {
    id: `${scope}.policyFrequency`,
    defaultMessage: 'Policy Frequency',
  },
  premiumWaiverBenefit: {
    id: `${scope}.premiumWaiverBenefit`,
    defaultMessage: 'Avail of premium waiver benefit?',
  },
  descriptionTwo: {
    id: `${scope}.descriptionTwo`,
    defaultMessage:
      'Should one or more insured persons becomes unable to work, the premium is waived. This is subject to Terms & Conditions.',
  },
  descriptionEsc: {
    id: `${scope}.descriptionEsc`,
    defaultMessage:
      'The premiums increase by 5% each year i.e. If the monthly premium is $1000 in year one, it will increase to $1050 per month in year two, and so on.',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  enterAmount: {
    id: `${scope}.enterAmount`,
    defaultMessage: 'Enter Amount',
  },
  editPremiumAmount: {
    id: `${scope}.editPremiumAmount`,
    defaultMessage: 'Edit Premium Amount*',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  quoteDescription: {
    id: `${scope}.quoteDescription`,
    defaultMessage:
      '*Editing the illustration directly impacts the sum assured and renders the extra benefits void',
  },
  insuredParty: {
    id: `${scope}.insuredParty`,
    defaultMessage: 'The Insured',
  },
  policySummary: {
    id: `${scope}.policySummary`,
    defaultMessage: 'Policy Summary',
  },
  isPolicyOwnerIndividual: {
    id: `${scope}.isPolicyOwnerIndividual`,
    defaultMessage: 'Will the policy owner(s) be other individuals?',
  },
  relationWithLifeInsured: {
    id: `${scope}.relationWithLifeInsured`,
    defaultMessage: 'Relationship to life insured',
  },
  assumedStartDate: {
    id: `${scope}.assumedStartDate`,
    defaultMessage: 'Assumed start date',
  },
  paymentFrequency: {
    id: `${scope}.paymentFrequency`,
    defaultMessage: 'Payment frequency (if applicable)',
  },
  lifeCover: {
    id: `${scope}.lifeCover`,
    defaultMessage: 'Life cover',
  },
  criticalIlleness: {
    id: `${scope}.criticalIlleness`,
    defaultMessage: 'Critical illeness',
  },
  ptd: {
    id: `${scope}.ptd`,
    defaultMessage: 'Permanent & total disability',
  },
  waiveThePremium: {
    id: `${scope}.waiveThePremium`,
    defaultMessage: 'Waive the premium?',
  },
  specialOffer: {
    id: `${scope}.specialOffer`,
    defaultMessage: 'Special offer (optional)',
  },
  addAnotherPolicyOwner: {
    id: `${scope}.addAnotherPolicyOwner`,
    defaultMessage: 'Add another policy owner',
  },
  added: {
    id: `${scope}.added`,
    defaultMessage: 'Added',
  },
  addedInfo: {
    id: `${scope}.addedInfo`,
    defaultMessage: `We've added this illustration to the client's list.`,
  },
  clientList: {
    id: `${scope}.clientList`,
    defaultMessage: 'Client List',
  },
  createAnother: {
    id: `${scope}.createAnother`,
    defaultMessage: 'Create Another',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  notePremiumCalculation: {
    id: `${scope}.notePremiumCalculation`,
    defaultMessage:
      'Note: editing the premium directly impacts the level of life cover, and resets the extra benefits.',
  },
  futuraIllustration: {
    id: `${scope}.futuraIllustration`,
    defaultMessage: 'FUTURA Illustration',
  },
  viewExportPDF: {
    id: `${scope}.viewExportPDF`,
    defaultMessage: 'View / Export PDF',
  },
  emailPDF: {
    id: `${scope}.emailPDF`,
    defaultMessage: 'Email PDF',
  },
  reference: {
    id: `${scope}.reference`,
    defaultMessage: 'Reference',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Cover details',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium summary',
  },
  pleaseEnter: {
    id: `${scope}.pleaseEnter`,
    defaultMessage: 'Please enter',
  },
  whatPolicyBasisRequired: {
    id: `${scope}.whatPolicyBasisRequired`,
    defaultMessage: 'What policy basis do you required?',
  },
  jlfd: {
    id: `${scope}.jlfd`,
    defaultMessage: 'Joint life first death',
  },
  jlld: {
    id: `${scope}.jlld`,
    defaultMessage: 'Joint life last death',
  },
  jlbd: {
    id: `${scope}.jlbd`,
    defaultMessage: 'Joint life both death',
  },
  coverageTerm: {
    id: `${scope}.coverageTerm`,
    defaultMessage: 'Coverage term',
  },
  premiumPaymentTerm: {
    id: `${scope}.premiumPaymentTerm`,
    defaultMessage: 'Premium payment term',
  },
  premiumPaymentTermTitle: {
    id: `${scope}.premiumPaymentTermTitle`,
    defaultMessage: 'Premium payment term (in years)',
  },
  singlePremiumPaymentTerm1Title: {
    id: `${scope}.singlePremiumPaymentTermTitle`,
    defaultMessage: 'One time payment',
  },
  singlePremiumPaymentTerm2Title: {
    id: `${scope}.singlePremiumPaymentTermTitle`,
    defaultMessage: 'One payment per year for 2 years',
  },
  singlePremiumPaymentTerm3Title: {
    id: `${scope}.singlePremiumPaymentTermTitle`,
    defaultMessage: 'One payment per year for 3 years',
  },
  additionalSinglePremium: {
    id: `${scope}.additionalSinglePremium`,
    defaultMessage: 'Additional single premium',
  },
  assumedGrowthRate: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'Assumed growth rate',
  },
  enterTerm: {
    id: `${scope}.enterTerm`,
    defaultMessage: 'Enter term',
  },
  amountEachYr: {
    id: `${scope}.amountEachYr`,
    defaultMessage: 'Amount each year',
  },
  coverTerm: {
    id: `${scope}.coverTerm`,
    defaultMessage: 'Cover term from date of claim',
  },
  coverTerm2: {
    id: `${scope}.coverTerm2`,
    defaultMessage: 'Cover term from date of policy issue',
  },
  vanishingPremiumTerm: {
    id: `${scope}.vanishingPremiumTerm`,
    defaultMessage: 'Vanishing premium term',
  },
  policyCoverageTerm: {
    id: `${scope}.policyCoverageTerm`,
    defaultMessage: 'Policy coverage term',
  },
  premiumTerm: {
    id: `${scope}.premiumTerm`,
    defaultMessage: 'Premium term',
  },
  premiumAmount: {
    id: `${scope}.premiumAmount`,
    defaultMessage: 'Premium amount',
  },
  reviewNotice: {
    id: `${scope}.reviewNotice`,
    defaultMessage: 'Please open and review the illustration to proceed',
  },
  offerIncluded: {
    id: `${scope}.offerIncluded`,
    defaultMessage: 'Offer Included',
  },
  brDebits: {
    id: `${scope}.brDebits`,
    defaultMessage: 'Benefits rating',
  },
  specialTerms: {
    id: `${scope}.specialTerms`,
    defaultMessage: 'Special Terms',
  },
  coverType: {
    id: `${scope}.coverType`,
    defaultMessage: 'Cover type',
  },
  coverageAmountTitle: {
    id: `${scope}.coverageAmountTitle`,
    defaultMessage: 'Coverage amount',
  },
  permanent: {
    id: `${scope}.permanent`,
    defaultMessage: 'Permanent',
  },
  temporary: {
    id: `${scope}.temporary`,
    defaultMessage: 'Temporary',
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: 'Period',
  },
  perMilleAndMonths: {
    id: `${scope}.perMilleAndMonths`,
    defaultMessage: 'Per mille and months',
  },
  growthRate: {
    id: `${scope}.growthRate`,
    defaultMessage: '0.0%',
  },
  offerCodeValid: {
    id: `${scope}.offerCodeValid`,
    defaultMessage: 'This quote benefits from special offer of {value}',
  },
  specialOfferCode: {
    id: `${scope}.specialOfferCode`,
    defaultMessage: 'Special offer',
  },
  errorEmail: {
    id: `${scope}.errorEmail`,
    defaultMessage: '{message}',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error',
  },
  illustration: {
    id: `${scope}.illustration`,
    defaultMessage: '{name} Illustration',
  },
});

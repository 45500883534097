import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import messages from '../../messages';
import { map } from 'lodash/collection';
import moment from 'moment';
import { getGender, filteredParty } from 'helpers/lang';
import { productCodeCheck } from 'helpers/constants';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(name, date, gender, isSmoker) {
  return { name, date, gender, isSmoker };
}

// const rows = [createData('Mr. Frank Smith', '02.05.1986', 'Male', 'Smoker')];

function InsuredParty(props) {
  const theme = useTheme();

  const { parties, applicationDetails, classes } = props;

  let rows = [
    ...map(
      filteredParty(parties, 'isLifeInsured').sort(function (x, y) {
        //SORT who is PO1
        return (
          y.partyapplicationrelationships?.isPrimaryPolicyOwner -
          x.partyapplicationrelationships?.isPrimaryPolicyOwner
        );
      }),
      party => {
        return createData(
          `${party.firstName} ${party.lastName}`,
          moment(party.dateOfBirth).format('DD MMM YYYY'),
          getGender(party.gender),
          // party.isSmoke,
        );
      },
    ),
  ];

  if (productCodeCheck(8, applicationDetails?.productCode)) {
    let rowList = [...rows]
    let newList = []
    rowList?.forEach(element => {
      let selectedParty = applicationDetails?.parties?.filter(party => ((party?.firstName + " " + party?.lastName) === element?.name && moment(element?.date).format('DD MMM YYYY') == moment(party?.dateOfBirth).format('DD MMM YYYY')))
      if (selectedParty?.length > 0 && selectedParty[0]?.partyapplicationrelationships?.additionalDetails?.relationshipToLifeInsured === 'CHILD') {
        newList.unshift(element);
      } else {
        newList.push(element);
      }
    })
    rows = [...newList]
  }
  if (rows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.theInsured} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {rows.map((row, index) => (
                    <StyledTableRow key={`${row.name}-${index}`}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: '30%' }}
                      >
                        <Typography variant="h4" className={classes.bold}>
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '30%' }}>
                        <Typography variant="h4">{row.date}</Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '40%' }}>
                        <Typography variant="h4">{row.gender}</Typography>
                      </StyledTableCell>
                      {/* <StyledTableCell>
                        <Typography variant="body1">{row.isSmoker}</Typography>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  } else return '';
}

InsuredParty.propTypes = {
  parties: PropTypes.array,
};

export default InsuredParty;

import React, { useCallback, useState, useRef } from 'react';
import Webcam from 'react-webcam';
import { dataURLtoFile } from 'helpers/lang';
import messages from '../../../messages';
import { Button, Grid } from '@material-ui/core';
import { Camera as CameraIcon, SwitchCamera } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const FACING_MODE_USER = 'user';
const FACING_MODE_ENVIRONMENT = 'environment';

const CapturePhoto = props => {
  const { setFile, setDocumentFile } = props;
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER);

  const onSwitch = useCallback(() => {
    setFacingMode(prevState =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  }, []);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();

    setImgSrc(imageSrc);
  }, [webcamRef, setFile]);

  const setFileImage = () => {
    setDocumentFile(dataURLtoFile(imgSrc, 'photo.jpg'));
  };

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };

  return (
    <Grid container direction="column" alignItems="center" spacing={1}>
      <Grid item xs={12}>
        {imgSrc ? (
          <img src={imgSrc} />
        ) : (
          <Webcam
            imageSmoothing
            height={400}
            width={400}
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              ...videoConstraints,
              facingMode,
            }}
            screenshotQuality={1}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {imgSrc ? (
          <div style={{ display: 'flex' }}>
            <Button
              variant="outlined"
              style={{ marginTop: '2rem' }}
              onClick={() => {
                setImgSrc(null);
              }}
            >
              <FormattedMessage {...messages.captureAgain} />
            </Button>
            <Button
              variant="outlined"
              onClick={setFileImage}
              style={{ marginTop: '2rem' }}
            >
              <FormattedMessage {...messages.setAsImage} />
            </Button>
          </div>
        ) : (
          <>
            <Button variant="outlined" onClick={capture}>
              <CameraIcon fontSize="large" />
            </Button>
            <Button variant="outlined" onClick={onSwitch}>
              <SwitchCamera fontSize="large" />
            </Button>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default CapturePhoto;

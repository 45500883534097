/**
 * CoverageForm
 */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import {
  WrappedFieldArrayProps,
  InjectedFormProps,
  FormSection,
} from 'redux-form';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  Collapse,
  Radio,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import TargetFund from 'containers/App/components/TargetFund';
import FormTextField from 'components/FormTextField';
import FormOptionButtons from 'components/FormOptionButtons';
import { dimension } from 'styles/constants';
import FormCheck from 'components/FormCheck';
import FormNumberField from 'components/FormNumberField';
import FormDatePicker from 'components/FormDatePicker';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import moment from 'moment';
import CustomFormCheck from 'components/CustomFormCheck';
// import FormAutocomplete from 'components/FormAutocomplete';

import EmptyIcon from 'images/checkbox-blue-empty.svg';
import CheckedIcon from 'images/checkbox-blue-tick.svg';
import CardSectionForm from 'components/CardSectionForm';
import OptSwitch from 'components/OptSwitch';
import { isEmpty } from 'lodash';
import { find } from 'lodash/collection';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import FormSwitch from 'components/FormSwitch';
import numeral from 'numeral';
import Helper from 'components/Helper';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import SinglePremium from '../SinglePremium';
import Withdrawal from '../Withdrawal';
import { getStartDate, calculateAgeNextBirthdate } from '../../helper';
import Tooltip from '../Tooltip';
import validate from './validate';
import messages from '../../messages';
import asyncValidator from './asyncValidate';
import { get } from 'lodash/object';
import { EXEMPTION_AGENCIES } from 'containers/WapIllustrationPage/constants';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: 23,
    marginBottom: 28,
  },
  lightHr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  labelCheckbox: {
    fontWeight: 700,
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => (
  <OptSwitch
    checked={value}
    value={value}
    color="default"
    onChange={onChange}
    labelNegative={<FormattedMessage {...messages.no} />}
    disabled={disabled}
  />
);

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const renderBenefit = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne.dateOfBirth,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo.dateOfBirth,
      );

      let ageValidType = 0;

      let isFormCheckDisabled = myBenefit[index].disabled;
      const isFieldsDisabled = !myBenefit[index].selected;

      let isWopOneDisabled = myBenefit[index].disabled;
      let isWopTwoDisabled = myBenefit[index].disabled;

      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA != '0') {
          if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
            isWopOneDisabled = true;
            change(`${benefit}.value`, false);
          }

          if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
            isWopTwoDisabled = true;
            change(`${benefit}.value`, false);
          }
        }
      } else if (myBenefit[index].maxEntryAgeLA != '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          isWopOneDisabled = true;
          change(`${benefit}.value`, false);
          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }

      return (
        <div>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            item
            xs={11}
          >
            <Grid item xs={12} sm={6}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.selected`}
                  fullWidth
                  component={CustomFormCheck}
                  label={myBenefit[index].title}
                  disabled={isFormCheckDisabled}
                  validType={ageValidType}
                  type="text"
                  color="inherit"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`${benefit}.value`, false);
                    }
                  }}
                />
              ) : (
                <Grid container direction="row">
                  <Typography variant="h4" style={{ fontWeight: '600' }}>
                    {myBenefit[index].title}
                  </Typography>
                  <div>
                    <Helper
                      helperText={
                        <FormattedMessage {...messages.descriptionTwo} />
                      }
                    ></Helper>
                  </div>
                </Grid>
              )}
            </Grid>
            {/* test */}
            <Grid item xs={6} sm={3}>
              <Field
                // shrink
                name={`${benefit}.value`}
                component={FormBooleanOption}
                type="text"
                margin="normal"
                disabled={isWopOneDisabled}
                // shrink
                fullWidth
              />
            </Grid>
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  wapConfig,
  change,
  startDate,
  fiaData,
  fiaCharge,
  regularWithdrawal,
  isRegularWithdrawal,
  isAdditionalSinglePremium,
  additionalSinglePremium,
  isAdditionalFeatures,
  currency,
  offerCode,
  premium,
  offerCodeData,
  agentDetails,
  location,
  resetOfferCode,
  validateOfferCode,
  formValues,
  formErrors,
  submitFailed,
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  let minCharge = get(
    wapConfig,
    'features.establishmentCharge.minPercentage',
    0,
  );
  let maxCharge = get(
    wapConfig,
    'features.establishmentCharge.maxPercentage',
    4.5,
  );

  const agencies = get(wapConfig, 'features.establishmentCharge.agencies', []);
  const currencyOption = [];
  const chargeOptions = [];
  const paymentFrequencyOption = [];
  const policyTermList = [];
  const startDateList = [];
  const agencyNumber = agentDetails.agentNumber;
  wapConfig.agencyNumber=(agencyNumber);

  useEffect(() => {
    if (!isEmpty(agencies)) {
      const matchedAgency = find(
        agencies,
        item => item.number === agencyNumber,
      );
      if (matchedAgency && matchedAgency.number === agencyNumber) {
        change('establishmentCharge', matchedAgency.defaultPercentage);
      }
    }
  }, [agencyNumber]);

  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '') {
      validateOfferCode({
        offerCode,
        productCode: wapConfig.productCode,
        productVersion: wapConfig.productVersion,
        currency,
        premium,
        premiumType: 'SinglePremium',
      });
    }
  }, []);

  while (minCharge <= maxCharge) {
    chargeOptions.push({
      title: `${numeral(minCharge).format('0.0')} %`,
      value: minCharge,
    });
    minCharge += 0.5;
  }

  if (startDateList.length == 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  const minStartDate = moment(startDate)
    .date(15)
    .add(1, 'months')
    .toDate();

  const minAspStartDate = moment(startDate)
    .date(1)
    .add(1, 'months')
    .toDate();

  const maxAspStartDate = moment(startDate)
    .date(1)
    .add(100, 'years')
    .toDate();

  const maxStartDate = moment(startDate)
    .add(100, 'years')
    .toDate();

  let minPremiumPaymentTerm = 5;
  const maxPremiumPaymentTerm = 30;

  while (minPremiumPaymentTerm <= maxPremiumPaymentTerm) {
    const myObj = {
      title: `${minPremiumPaymentTerm}`,
      value: minPremiumPaymentTerm,
    };
    policyTermList.push(myObj);
    minPremiumPaymentTerm++;
  }
  policyTermList.push({ title: 'Single premium', value: 'SinglePremium' });

  wapConfig.features.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  wapConfig.features.premiumPaymentFrequencyType[0].periods.map(frequency => {
    paymentFrequencyOption.push({ value: frequency, title: frequency });
  });

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';
  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit} style={{ marginTop: 26 }}>
          <Grid container spacing={3} style={{ maxWidth: 660, margin: 0 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* CURRRENCY */}
            <Grid item>
              <Field
                // shrink
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                name="currency"
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>

            {/* PREMIUM AMOUNT */}
            <Grid item sm={12}>
              <Field
                // shrink
                name="premium"
                style={{ width: 172 }}
                component={FormNumberField}
                disabled={formValues.targetFunctionality}
                label={<FormattedMessage {...messages.premiumAmount} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.enterAmount)}
              />
            </Grid>
          </Grid>
          <Divider
            className={classes.hr}
            style={{ marginBottom: theme.spacing(2) }}
          />
          <Grid container alignItems="center" item spacing={2}>
            <TargetFund
              formValues={formValues}
              formErrors={formErrors}
              submitFailed={submitFailed}
              premiumDisabled={formValues.targetFunctionality}
              change={change}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid container spacing={3} style={{ maxWidth: 660, margin: 0 }}>
            {/* Assumed growth rate */}
            <Grid item xs={4} md={4}>
              <Field
                // shrink
                style={{ width: 172 }}
                name="growthRate"
                fullWidth
                component={FormNumberField}
                label={<FormattedMessage {...messages.assumedGrowthRate} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.growthRate)}
              />
            </Grid>
          </Grid>

          <Grid>
            <FieldArray
              name="coverages"
              component={renderBenefit}
              rerenderOnEveryChange
              lifeAssured={lifeAssured}
              options={options}
              change={change}
              startDate={startDate}
              intl={intl}
            />
          </Grid>

          <Divider className={classes.hr} />
          <Grid
            item
            xs={6}
            spacing={3}
            style={{ maxWidth: 660, margin: '40px 0 40px 10px' }}
          >
            <Field
              name="offerCode"
              fullWidth
              style={{
                width: 355,
              }}
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              placeholder={intl.formatMessage(messages.pleaseEnter)}
            />
          </Grid>

          {/* <Divider className={classes.hr} /> */}

          {/* ===== Additional Single Premium ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages.additionalSinglePremium} />
              </Typography>

              <Field
                name="isAdditionalSinglePremium"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isAdditionalSinglePremium}>
              <Divider className={classes.lightHr} />
              <FormSection name="additionalSinglePremium">
                <SinglePremium
                  formValues={additionalSinglePremium}
                  isEducationWithdrawal={false}
                  frequencyList={paymentFrequencyOption}
                  minDate={minAspStartDate}
                  maxDate={maxAspStartDate}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          {/* ===== Regular withdrawal ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages.regularWithdrawalTitle} />
              </Typography>

              <Field
                name="isRegularWithdrawal"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isRegularWithdrawal}>
              <Divider className={classes.lightHr} />
              <FormSection name="regularWithdrawal">
                <Withdrawal
                  formValues={regularWithdrawal}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  isEducationWithdrawal={false}
                  frequencyList={paymentFrequencyOption}
                  change={change}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          {/* ===== FIA Charges ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...globalMessages.additionalFetures} />
              </Typography>

              <Field
                name="isAdditionalFeatures"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isAdditionalFeatures}>
              <Divider className={classes.lightHr} />
              <Grid item xs={4}>
                <Field
                  name="establishmentCharge"
                  component={FormSelect}
                  options={chargeOptions}
                  type="text"
                  fullWidth
                  label={<FormattedMessage {...messages.establishmentCharge} />}
                />
              </Grid>
              {!isEmpty(fiaData) && wapConfig.features.haveFiaCharge ? (
                <>
                  <Divider className={classes.hr} />
                  <Typography
                    // variant="h4"
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <FormattedMessage {...globalMessages.fiaText} />
                  </Typography>

                  <Field
                    name="fiaCharge"
                    type="radio"
                    component={FormRadioGroup}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          classes={{ label: classes.labelCheckbox }}
                          control={<CustomRadio checked={fiaCharge == 0} />}
                          value={fiaData?.minFIACharge}
                          label={<FormattedMessage {...globalMessages.nil} />}
                          labelPlacement="left"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justify="space-between">
                          <FormControlLabel
                            classes={{ label: classes.labelCheckbox }}
                            control={<CustomRadio checked={fiaCharge != 0} />}
                            value={fiaData?.maxFIAChange}
                            label={
                              <FormattedMessage
                                {...globalMessages.annualPercentage}
                              />
                            }
                            labelPlacement="end"
                          />

                          <TextField
                            value={`${fiaData?.maxFIAChange} %`}
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Field>
                </>
              ) : null}
            </Collapse>
          </CardSectionForm>
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  wapConfig: PropTypes.object,
  regularWithdrawal: PropTypes.object,
  additionalFeatures: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  startDate: PropTypes.string,
  fiaCharge: PropTypes.number,
  term: PropTypes.string,
  offerCode: PropTypes.string,
  coverages: PropTypes.array,
  fiaData: PropTypes.object,
  isRegularWithdrawal: PropTypes.bool,
  isAdditionalSinglePremium: PropTypes.bool,
  isAdditionalFeatures: PropTypes.bool,
  agentDetails: PropTypes.object,
};

const selector = formValueSelector('coverageForm');
const mapStateToProps = createStructuredSelector({});

const withFormSelector = connect(state => {
  const formValues = getFormValues('coverageForm')(state);
  const formErrors = getFormSyncErrors('coverageForm')(state);
  const startDate = selector(state, 'startDate');
  const fiaCharge = selector(state, 'fiaCharge');
  const regularWithdrawal = selector(state, 'regularWithdrawal');
  const currency = selector(state, 'currency');
  const offerCode = selector(state, 'offerCode');
  const premium = selector(state, 'premium');
  const additionalSinglePremium = selector(state, 'additionalSinglePremium');
  const additionalFeatures = selector(state, 'additionalFeatures');
  const term = selector(state, 'term');
  const isAdditionalSinglePremium = selector(
    state,
    'isAdditionalSinglePremium',
  );
  const isAdditionalFeatures = selector(state, 'isAdditionalFeatures');
  const isRegularWithdrawal = selector(state, 'isRegularWithdrawal');
  return {
    startDate,
    fiaCharge,
    term,
    regularWithdrawal: regularWithdrawal?.toJS() || {},
    isRegularWithdrawal,
    isAdditionalSinglePremium,
    additionalSinglePremium,
    additionalFeatures,
    isAdditionalFeatures,
    currency,
    offerCode,
    premium,
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: ['offerCode', 'currency', 'premium'],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

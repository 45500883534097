import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { newPayorProceeds, newPayorSource } from 'helpers/placeholder';
import {
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  Form,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { submit } from 'redux-form';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../../messages';
import globalMessages from 'containers/App/messages';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { convertIfImmutable } from 'helpers/lang';
import { isEmpty } from 'lodash/lang';
import { map, find } from 'lodash/collection';
import red from '@material-ui/core/colors/red';
import {
  Grid,
  Typography,
  Collapse,
  Box,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
//selector
import { makeSelectOption } from 'containers/App/selectors';

//components
import FormRadioGroup from 'components/FormRadioGroup';
import FormSelect from 'components/FormSelect';
import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import PayorProceeds from './components/PayorProceeds';
import PayorSource from './components/PayorSource';
import PayorFunds from './components/PayorFunds';
import PayorPolicy from './components/PayorPolicy';

//validate
import validate from './validate';
import FormNumberField from 'components/FormNumberField';
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';
import CustomRadio from 'components/CustomRadioButton';
import { productCodeCheck } from 'helpers/constants';
import FormTextField from 'components/FormTextField';

export function useISOcountrycode() {
  return sessionStorage.getItem('agentDetails');
}

const useStyle = makeStyles(theme => ({
  containerWrapper: {
    width: '660px',
  },
  radioForm: {
    display: 'flex',
    alignItems: 'center',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  error: {
    color: red[800],
    fontWeight: 600,
  },
}));

const RenderPayorSalary = ({ options, disabled }) => {
  const intl = useIntl();
  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Field
            name="paymentCurrency"
            component={FormSelect}
            fullWidth
            options={[
              { value: 'Select', title: 'Select' },
              ...options.cardCurrency,
            ]}
            label={<FormattedMessage {...messages.salaryCurrency} />}
            placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={3}>
          <Field
            name="paymentAmount"
            component={FormNumberField}
            fullWidth
            label={<FormattedMessage {...messages.annualIncome} />}
            placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

function PayorSalary(props) {
  const classes = useStyle();
  const theme = useTheme();
  const intl = useIntl();
  const ISOCode = useISOcountrycode();
  const {
    handleSubmit,
    formValues,
    change,
    options,
    onSubmit,
    canSubmit,
    formErrors,
    applicationDetails,
  } = props;
  const parsedApplicationDetails = convertIfImmutable(applicationDetails);
  const history = useHistory();
  const checkSubmit = () => {
    let flag = false;
    const {
      isPolicyPaidBySalary,
      payorDetails,
      isPayorProceeds,
      payorProceeds,
      isPayorSource,
    } = formValues;
    if (
      isEmpty(isPolicyPaidBySalary) &&
      isEmpty(isPayorProceeds) &&
      isEmpty(isPayorSource)
    ) {
      flag = true;
    }
    if (isPayorProceeds) {
      flag = !payorProceeds.every(item => {
        return (
          isEmpty(item['addressProperty']) ||
          isEmpty(item['saleCurrency']) ||
          isEmpty(item['sharesProperty']) ||
          isEmpty(item['sharesProperty'])
        );
      });
    }
    return flag;
  };

  useEffect(() => {
    canSubmit(!checkSubmit());

    if (productCodeCheck(3, parsedApplicationDetails.productCode)) {
      change('isPolicyPaidBySalary', false);
    }
  }, [formValues]);

  function handleChange(event) {
    if (event.target.value === 'true') {
      change('isPolicyPaidBySalary', true);
    } else {
      change('isPolicyPaidBySalary', false);
    }
  }
  function handleIsArmyServiceChange(event) {
    if (event.target.value === 'true' && ISOCode == 'AE') {
      change('isArmyService', false);
    } 
    else if( ISOCode != 'AE') {
      change('isArmyService', false);
    }
  }
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} className={classes.containerWrapper}>
        {productCodeCheck(3, parsedApplicationDetails.productCode) ? (
          ''
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="body1">
                <FormattedMessage {...messages.willPayingBySalary} />
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box mb={0} mt={0} style={{ marginBottom: '1rem' }}>
                <Field
                  name="isPolicyPaidBySalary"
                  type="radio"
                  component={FormRadioGroup}
                  onBlur={e => {
                    e.preventDefault();
                  }}
                  normalize={value => value === 'true'}
                >
                  <div className={classes.radioForm}>
                    <FormControlLabel
                      key={1}
                      control={
                        <CustomRadio
                          checked={formValues.isPolicyPaidBySalary === true}
                          onClick={handleChange}
                        />
                      }
                      value={true}
                      label="Yes"
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      key={2}
                      control={
                        <CustomRadio
                          checked={formValues.isPolicyPaidBySalary === false}
                          onClick={handleChange}
                        />
                      }
                      value={false}
                      label="No"
                      labelPlacement="end"
                    />
                  </div>
                </Field>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {' '}
              <FormSection
                name="payorDetails"
                component={RenderPayorSalary}
                options={options}
                disabled={formValues.isPolicyPaidBySalary === false}
              />
            </Grid>
            {['AE'].includes(ISOCode) &&
              <Grid item xs={8} container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography
                  variant="body1"
                  style={{
                    marginRight: '0px',
                    fontSize: '1.6rem',
                    fontWeight: 400,
                    lineheight: '2.3rem'
                  }}>
                  <FormattedMessage {...messages.armyservice} />
                </Typography>
                <Field name="isArmyService"
                  component={FormSwitch}
                  onChange={handleIsArmyServiceChange}

                />
              </Grid>
            }
              {formValues.isArmyService ? (''

              ) : (<Grid item xs={8}>
                <Field
                  name="nameOfJobTitle"
                  component={FormTextField}
                  label={<FormattedMessage {...messages.nameOfJobTitle} />}
                  shrink
                  fullWidth
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                />
              </Grid>
              )
              }
          </>
        )}
        <>
          {productCodeCheck(3, parsedApplicationDetails.productCode) ? (
            <>
              <FormSection
                name="payorFunds"
                component={PayorFunds}
                options={options}
              />
              <CardSectionForm variant="outlined" style={{ width: '100%' }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Box mr={5}>
                    <Typography variant="body1">
                      <FormattedMessage {...messages.bonusAmount} />
                    </Typography>
                  </Box>

                  <Field name="isPolicyPaidBySalary2" component={FormSwitch} />
                </Grid>
                <Collapse in={formValues.isPolicyPaidBySalary2}>
                  <hr className={classes.hr} />
                  <FormSection
                    name="policyPaidBySalary"
                    component={PayorPolicy}
                    options={options}
                    formValues={formValues}
                    // newPayorProceedsPlaceholder={{
                    //   ...newPayorProceeds,
                    // }}
                    change={change}
                  />
                </Collapse>
              </CardSectionForm>
            </>
          ) : (
            ''
          )}

          {formValues.isPolicyPaidBySalary ? (
            ''
          ) : (
            <>
              <CardSectionForm variant="outlined" style={{ width: '100%' }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Box mr={5}>
                    <Typography variant="body1">
                      <FormattedMessage {...messages.PayorProceeds} />
                    </Typography>
                  </Box>

                  <Field name="isPayorProceeds" component={FormSwitch} />
                </Grid>
                <Collapse in={formValues.isPayorProceeds}>
                  <hr className={classes.hr} />
                  <FormSection name="">
                    <FieldArray
                      name="payorProceeds"
                      component={PayorProceeds}
                      options={options}
                      formValues={formValues}
                      disabledPercentage={true}
                      newPayorProceedsPlaceholder={{
                        ...newPayorProceeds,
                      }}
                      change={change}
                    />
                  </FormSection>
                </Collapse>
              </CardSectionForm>
              <CardSectionForm variant="outlined" style={{ width: '100%' }}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Box mr={5}>
                    <Typography variant="body1">
                      {<FormattedMessage {...messages.otherWealth} />}
                    </Typography>
                  </Box>

                  <Field name="isPayorSource" component={FormSwitch} />
                </Grid>
                <Collapse in={formValues.isPayorSource}>
                  <hr className={classes.hr} />
                  <FormSection name="">
                    <FieldArray
                      name="payorSource"
                      component={PayorSource}
                      options={options}
                      formValues={formValues}
                      newPayorSourcePlaceholder={{
                        ...newPayorSource,
                      }}
                      change={change}
                    />
                  </FormSection>
                  <Typography variant="body1" className={classes.error}>
                    {formErrors.selectOriginOfWealth}
                  </Typography>
                </Collapse>
              </CardSectionForm>
              <Typography variant="body1" className={classes.error}>
                {formErrors.selectSalary}
              </Typography>
            </>
          )}
        </>
      </Grid>
    </Form>
  );
}

PayorSalary.propTypes = {
  applicationDetails: PropTypes.object,
  options: PropTypes.object,
  ISOCode: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
  applicationDetails: makeSelectApplicationDetails(),
});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  form: 'payorSalary',
  enableReinitialize: true,
  // onSubmit: submit,
  validate,
});

const withFormSelector = connect(state => {
  const formValues = getFormValues('payorSalary')(state);
  const formErrors = getFormSyncErrors('payorSalary')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

export default compose(withConnect, withFormSelector, withForm)(PayorSalary);

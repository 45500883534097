import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  fill: none;
`;

function IconAdd(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" version="1.1" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <GA transform="translate(-743.000000, -170.000000)">
          <g transform="translate(743.000000, 170.000000)">
            <circle stroke="#DDDFE0" strokeWidth="1" cx="12" cy="12" r="11.5" />
            <path d="M12.2604076,6.52081528 C12.713249,6.52081528 13.0803494,6.88791571 13.0803494,7.34075705 L13.0798153,11.4398153 L17.1800582,11.4404659 C17.6328996,11.4404659 18,11.8075663 18,12.2604076 C18,12.713249 17.6328996,13.0803494 17.1800582,13.0803494 L13.0798153,13.0798153 L13.0803494,17.1800582 C13.0803494,17.6328996 12.713249,18 12.2604076,18 C11.8075663,18 11.4404659,17.6328996 11.4404659,17.1800582 L11.4398153,13.0798153 L7.34075705,13.0803494 C6.88791571,13.0803494 6.52081528,12.713249 6.52081528,12.2604076 C6.52081528,11.8075663 6.88791571,11.4404659 7.34075705,11.4404659 L11.4398153,11.4398153 L11.4404659,7.34075705 C11.4404659,6.88791571 11.8075663,6.52081528 12.2604076,6.52081528 Z" id="Combined-Shape" fill="#4066B3" fillRule="evenodd" transform="translate(12.260408, 12.260408) rotate(-360.000000) translate(-12.260408, -12.260408) " />
          </g>
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconAdd;

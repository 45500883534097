import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import { SubmissionError } from 'redux-form/immutable'; // ES6

//validators
import isEmail from 'validator/lib/isEmail';

import { isEmpty } from 'lodash/lang';
import { forEach, map } from 'lodash/collection';
import { keys } from 'lodash/object';
import { isFieldEmpty } from 'utils/form';

const validate = (values, props) => {
  var errors = {};

  if (!values.isEmpty()) {
    const parsedValue = values.toJS();
    const requiredFields = [
      'firstName',
      // 'lastName',
      'facilityName',
      // 'phoneNumber',
      // 'emailAddress',
      // 'addressLine1',
      // 'addressLine2',
      // 'city',
      // 'stateOrProvince',
      // 'zipCode',
    ];

    const requiredSelectFields = [
      // 'country'
    ];
    const fieldArrayErrors = [];

    forEach(requiredFields, (field, index) => {
      if (isFieldEmpty(parsedValue[field])) {
        fieldArrayErrors[field] = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      }
    });

    forEach(requiredSelectFields, (field, index) => {
      if (isFieldEmpty(parsedValue[field])) {
        fieldArrayErrors[field] = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      }
    });

    if (keys(fieldArrayErrors).length > 0) {
      errors = fieldArrayErrors;
    }
  }

  return errors;
};

export default validate;

import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages';
import PropTypes from 'prop-types';
import PaymentDetail from './components/PaymentDetail';
import { filter } from 'lodash/collection';
import { isEmpty } from 'lodash';
import {
  makeSelectPaymentType,
  makeSelectPaymentDetail,
  makeSelectPayment,
  makeSelectChangeCurrency,
} from 'containers/ApplicationFormPage/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import Dialog from 'components/Dialog';
import NoticeBox from 'components/NoticeBox';
import {
  setPaymentDataAction,
  updatePaymentType,
  setConvertCurrencyAction,
  setChangeCurrencyAction,
  setPaymentDetail,
  getChangeCurrencyAction,
} from '../../../actions.js';
import { changeSubPageListAction } from 'containers/DashboardPage/actions';
import { useHistory } from 'react-router-dom';
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';
import { makeSelectFinancialInstituteOptions } from 'containers/App/selectors';
import { convertIfImmutable } from 'helpers/lang';
import { getApplicationDetailsAction } from 'containers/ApplicationPage/actions';
import { useParams } from 'react-router-dom';
import { logoutAction } from 'containers/DashboardPage/actions';
import { useOktaAuth } from '@okta/okta-react';
import {
  reduxForm,
  InjectedFormProps,
  getFormValues,
} from 'redux-form/immutable';

const useStyle = makeStyles(theme => ({
  decription: {
    fontSize: 18,
    marginTop: theme.spacing(6),
  },
  label: {
    color: '#043594',
    fontSize: 18,
  },
  textRadio: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
  textNote: {
    fontSize: 18,
    color: '#B6B8B4',
    lineHeight: 2,
    marginTop: theme.spacing(2),
  },
}));
function PaymentPreview(props) {
  const classes = useStyle();
  const history = useHistory();
  const { authService } = useOktaAuth();

  const {
    paymentType,
    paymentDetail,
    paymentStatus,
    setPaymentStatus,
    updatePaymentType,
    changeSubPageList,
    setChangeCurrent,
    setConvertCurrency,
    updatePaymentDetail,
    financialInstituteOptions,
    getChangeCurrent,
    applicationDetail,
    getApplicationDetails,
    logout,
    isInforce,
    initReset,
    formValues,
    ISOCode,
    canSubmit,
    canGoBack,
    isExistingPaymentToggle,
  } = props;
  const [dialog, setDialog] = useState({
    open: false,
    message: null,
  });
  const { applicationId } = useParams();

  // Check condition to enable or disable Next button
  const checkSubmit = isMissingKFS => {
    let flag = false;

    if (paymentType !== null) {
      flag = true;
    }

    if (isMissingKFS) {
      flag = false;
    }

    return flag;
  };

  useEffect(() => {
    const isMissingKFS = applicationDetail.toJS()?.missingKFS;
    if (isInforce || isMissingKFS) {
      canSubmit(!checkSubmit(isMissingKFS));
    }
  }, [paymentType]);

  const isBolton = applicationDetail.toJS()?.isBolton;

  const productConfigs = isBolton
    ? applicationDetail.toJS().productConfigs[0]
    : applicationDetail.toJS().productConfigs;

  useEffect(() => {
    const change = {
      scrCurrency: productConfigs?.currency,
      destCurrency: 'AED',
      amount: 1,
    };
    getChangeCurrent(change);
  }, []);

  useEffect(() => {
    if (isInforce && !initReset) {
      canGoBack(true);
    }
  }, [isInforce]);

  // Reset redux state if payment success
  const resetState = () => {
    setChangeCurrent('');
    setConvertCurrency('');
    changeSubPageList([]);
    updatePaymentType('');
    setPaymentStatus({ status: null });
    updatePaymentDetail({});
  };

  const mapBankName = () => {
    if (isInforce && !initReset) {
      return applicationDetail.toJS()?.paymentDetails?.financialInstitute
        ?.bankFullName;
    }

    return filter(
      convertIfImmutable(financialInstituteOptions),
      item =>
        `${item.bankBranchAddTNField1}${item.bankBranchAddTNField2}` ===
        paymentDetail.bankName,
    )[0]?.bankFullName;
  };

  const renderChequeDescription = () => {
    return (
      <Grid item xs={12}>
        <Typography className={classes.decription}>
          <FormattedMessage {...messages.madePayableCheque} />
        </Typography>
        <Typography className={classes.label}>
          <FormattedMessage {...messages.ZILT} />
        </Typography>
      </Grid>
    );
  };

  const renderOriginalDescription = () => {
    return (
      <Grid item xs={12}>
        <Typography className={classes.decription}>
          <FormattedMessage {...messages.originalDirectDebit} />
        </Typography>
        <Typography className={classes.label}>
          <FormattedMessage {...messages.ZILT} />
        </Typography>
        <Typography className={classes.label}>
          {ISOCode === 'BH' ? (
            <FormattedMessage {...messages.POBoxBH} />
          ) : ISOCode === 'QA' ? (
            <FormattedMessage {...messages.POBoxQA} />
          ) : (
            <FormattedMessage {...messages.POBox} />
          )}
        </Typography>
        <Typography className={classes.label}>
          {ISOCode === 'BH' ? (
            <FormattedMessage {...messages.BHAddress} />
          ) : ISOCode === 'QA' ? (
            <FormattedMessage {...messages.QAAddress} />
          ) : (
            <FormattedMessage {...messages.dubai} />
          )}
        </Typography>
        <Typography className={classes.label}>
          {ISOCode === 'BH' ? (
            <FormattedMessage {...messages.BH} />
          ) : ISOCode === 'QA' ? (
            <FormattedMessage {...messages.QA} />
          ) : (
            <FormattedMessage {...messages.UAE} />
          )}
        </Typography>
      </Grid>
    );
  };

  const renderContent = () => {
    switch (paymentType) {
      case 'UAE_DIRECT_DEBIT':
        return <>{renderOriginalDescription()}</>;
      case 'CREDIT_CARD':
        return <></>;
      case 'BANK_TRANSFER':
        return;
      case 'CHEQUE':
        return (
          <>
            {renderChequeDescription()}
            {renderOriginalDescription()}
          </>
        );
      case 'STANDING_ORDER':
        return;
      default:
        return;
    }
  };

  return (
    <Grid container>
      {applicationDetail.toJS()?.missingKFS && (
        <Grid container>
          <Grid item xs={7}>
            <NoticeBox variant="error" mb={4}>
              <Typography variant="body2" color="error">
                The Quote is invalid. Please regenerate new one.
              </Typography>
            </NoticeBox>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} md={8} className={classes.containerWrapper}>
        <PaymentDetail
          isInforce={isInforce}
          initReset={initReset}
          paymentDetail={
            isInforce && !initReset
              ? applicationDetail.toJS()?.paymentDetails
              : paymentDetail
          }
          bankName={mapBankName()}
          paymentType={paymentType}
          formValues={formValues}
          isExistingPaymentToggle={isExistingPaymentToggle}
        />
      </Grid>
      {renderContent()}
      {paymentType === 'CHEQUE' && (
        /* <Typography className={classes.textNote}>
          Please note: Cheques are only accepted in UAE Dirham (
          {applicationDetail.toJS().productConfigs.currency} 1 ={' '}
          {Number(changeCurrency).toFixed(4)})
        </Typography> */
        <Typography className={classes.textNote}>
          <FormattedMessage {...messages.chequeText} />
        </Typography>
      )}
      <Dialog  
        open={paymentStatus.status === 'SAVE_SUCCESS' || dialog.open}
        onClose={() => {
          setDialog({
            ...dialog,
            open: false,
          });
          setPaymentStatus('');
          getApplicationDetails(applicationId);
        }}
        onExited={() => {
          setDialog({
            ...dialog,
            message: null,
          });
          getApplicationDetails(applicationId);
        }}
        variant={'success'}
        title="Success"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  logout(authService);
                  //resetState();

                  // history.push('/dashboard');
                }}
              >
                <FormattedMessage {...messages.logout} />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  resetState();
                  // logout(authService);
                  history.push('/dashboard');
                }}
              >
                <FormattedMessage {...messages.goToDashboard} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage {...messages.thankYou} />
        </Typography>
      </Dialog>
      
      {/*Dialog for NEXUS and FAB agencies*/}
      <Dialog
        open={
          paymentStatus.status === 'intermediate' ||
          dialog.open
        }
        onClose={() => {
          setDialog({
            ...dialog,
            open: false,
          });
          setPaymentStatus('');
          getApplicationDetails(applicationId);
        }}
        onExited={() => {
          setDialog({
            ...dialog,
            message: null,
          });
          getApplicationDetails(applicationId);
        }}
        variant="success"
        title="Success"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={3}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                style={{ minWidth: 165 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  logout(authService);
                }}
              >
                <FormattedMessage {...messages.logout} />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ minWidth: 165 }}
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  resetState();
                  history.push('/dashboard');
                }}
              >
                <FormattedMessage {...messages.goToDashboard} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage {...messages.thankYouForAdminReview} />
        </Typography>
      </Dialog>
    </Grid>
  );
}

const mapStateToProps = createStructuredSelector({
  paymentType: makeSelectPaymentType(),
  paymentDetail: makeSelectPaymentDetail(),
  paymentStatus: makeSelectPayment(),
  financialInstituteOptions: makeSelectFinancialInstituteOptions(),
  changeCurrency: makeSelectChangeCurrency(),
  applicationDetail: makeSelectApplicationDetails(),
});

PaymentPreview.propTypes = {
  ISOCode: PropTypes.string,
};

function mapDispatchToProps(dispatch) {
  return {
    setPaymentStatus: data => dispatch(setPaymentDataAction(data)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    updatePaymentType: value => dispatch(updatePaymentType(value)),
    setConvertCurrency: data => dispatch(setConvertCurrencyAction(data)),
    setChangeCurrent: change => dispatch(setChangeCurrencyAction(change)),
    updatePaymentDetail: values => dispatch(setPaymentDetail(values)),
    getChangeCurrent: change => dispatch(getChangeCurrencyAction(change)),
    getApplicationDetails: value =>
      dispatch(getApplicationDetailsAction(value)),
    logout: value => dispatch(logoutAction(value)),
  };
}

const withFormSelector = connect(state => {
  const formValues = getFormValues('PaymentPreview')(state);
  // const formErrors = getFormSyncErrors('Payment')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    // formErrors: convertIfImmutable(formErrors),
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({ form: 'PaymentPreview' })(
  compose(withConnect, withFormSelector)(PaymentPreview),
);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { convertIfImmutable } from 'helpers/lang';
import { filter, map, forEach, some } from 'lodash/collection';
import messages from 'containers/ApplicationFormPage/messages';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import { createStructuredSelector } from 'reselect';
import {
  reduxForm,
  InjectedFormProps,
  Field,
  FieldArray,
  getFormValues,
} from 'redux-form/immutable';
import { compose } from 'redux';
import red from '@material-ui/core/colors/red';
import validate from './validate';

import {
  Grid,
  Typography,
  Modal,
  Backdrop,
  Fade,
  Button,
  Divider,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';

//components
import LifeInsured from './components/lifeInsured';
import FormCheck from 'components/FormCheck';

//actions
import { getApplicationDetailsAction } from 'containers/ApplicationPage/actions';
import { setDocumentStatusAction, setOtherDocumentStatusAction } from 'containers/ApplicationFormPage/actions';

//selectors
import { makeSelectApplicationDetailsParties } from 'containers/ApplicationPage/selectors';
import { makeSelectDocumentStatus, makeSelectOtherDocumentStatus } from 'containers/ApplicationFormPage/selectors';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '770px',
  },
  actionContainer: {
    margin: '0.1rem 0',
  },
  error: {
    color: red[600],
    fontWeight: 600,
  },
}));

function DocumentForm(props) {
  const theme = useTheme();
  const classes = useStyles();
  const {
    isWAPAndSCBAgency,
    getApplicationDetails,
    parties,
    match,
    handleSubmit,
    onSubmit,
    formValues,
    canSubmit,
    documentStatus,
    setDocumentStatus,
    otherDocumentStatus,
    setOtherDocumentStatus,
    canGoBack,
    parsedApplicationDetails,
  } = props;
  const applicationId = match.params.applicationId;
  const parsedParties = convertIfImmutable(parties);
  const [docsSubmitted, setdocsSubmitted] = useState(false);

  const checkSubmit = () => {
    let flag = false;
    if (!!formValues.trueDocumentCopies && formValues.trueDocumentCopies) {
      flag = true;
    }
    return flag;
  };

  useEffect(() => {
    getApplicationDetails(applicationId);
    canGoBack(false);
  }, []);

  useEffect(() => {
    let requiredDocs = [];
    forEach(parsedParties, p => {
      const filteredReqDocs = filter(
        p.documentRequirements,
        doc => doc.isRequired,
      );

      requiredDocs = [...requiredDocs, ...filteredReqDocs];
    });
    if (some(requiredDocs, ['isUploaded', false])) {
      setdocsSubmitted(true);
    } else {
      setdocsSubmitted(false);
    }
  }, [parties]);

  useEffect(() => {
    if (documentStatus.message === 'IDV_HAS_BEEN_COMPLETED_SUCCESSFULLY') {
      getApplicationDetails(applicationId);
    }
  }, [documentStatus]);

  useEffect(() => {
    canSubmit(!checkSubmit());
  }, [formValues]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.container}>
      <Grid container spacing={3}>
        {
          !isEmpty(otherDocumentStatus) &&
          <Grid item xs={12} style={{ marginTop: '12px' }}>
            <Typography variant="h4" className={classes.error}>
              {/* {otherDocumentStatus.type == 'AD' && 'Additional Documments'} */}
              Upload Error: {`${otherDocumentStatus.message}`}
            </Typography>
            <Typography variant="body2" className={classes.error}>Please reupload these files: {`${otherDocumentStatus.files.map(item => item.localName).join(', ')}`}</Typography>
          </Grid>
        }
        <Grid item xs={12}>
          <FieldArray
            name="parties"
            component={LifeInsured}
            applicationId={applicationId}
            formValues={formValues}
            parsedApplicationDetails={parsedApplicationDetails}
            disabledFields={parsedApplicationDetails?.disabledFields}
          />
        </Grid>
        {isWAPAndSCBAgency ? (
          <Grid item xs={12} style={{ marginTop: '12px' }}>
            <Typography variant="h4" className={classes.error}>
              <FormattedMessage {...messages.noticeOfAssignment} />
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Divider />
          </Grid>
        )}
        <Grid item xs={12}>
          <Field
            shrink="true"
            name="trueDocumentCopies"
            margin="normal"
            fullWidth
            component={FormCheck}
            label={
              <Typography variant="body2">
                <FormattedMessage {...messages.documentsisLegitCopies} />
              </Typography>
            }
            type="checkbox"
            color="inherit"
            variant="standard"
            disabled={docsSubmitted}
          />
        </Grid>
      </Grid>
      <Dialog
        open={documentStatus.message === 'IDV_TOO_MANY_RETRY_FOR_THIS_PARTY'}
        onClose={() => setDocumentStatus({})}
        variant={'error'}
        title="Error"
        actions={
          <Grid
            container
            alignItems="center"
            justify="center"
            wrap="nowrap"
            spacing={2}
            className={classes.actionDialogContainer}
          >
            <Grid item xs={6} md={6}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDocumentStatus({});
                }}
              >
                <FormattedMessage {...messages.cancelExit} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage {...messages.maximumNumberReached} />
        </Typography>
      </Dialog>
    </form>
  );
}

DocumentForm.propTypes = { ...InjectedFormProps };

const mapStateToProps = createStructuredSelector({
  parties: makeSelectApplicationDetailsParties(),
  documentStatus: makeSelectDocumentStatus(),
  otherDocumentStatus: makeSelectOtherDocumentStatus()
});

function mapDispatchToProps(dispatch) {
  return {
    getApplicationDetails: data => dispatch(getApplicationDetailsAction(data)),
    setDocumentStatus: data => dispatch(setDocumentStatusAction(data)),
    setOtherDocumentStatus: data => dispatch(setOtherDocumentStatusAction(data))
    // getHealthQuestions: () => dispatch(callHealthQuestionsAction()),
  };
}

const withFormSelector = connect(state => {
  const formValues = getFormValues('DocumentForm')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  validate,
  form: 'DocumentForm',
  enableReinitialize: true,
})(compose(withConnect, withFormSelector)(DocumentForm));

import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import request from 'utils/request';
import { api } from 'environments';
import numeral from 'numeral';
import {
  setAppLoadingAction,
  validateOfferCodeSuccessAction,
} from 'containers/App/actions';
import {
  makeSelectAuth,
  makeSelectOfferCodeData,
} from 'containers/App/selectors';
import { isEmpty } from 'lodash';
import { get } from 'lodash/object';
import { change } from 'redux-form';
import {
  setQuoteAction,
  setErrorAction,
  setQuoteDetailsAction,
  setApplicationIdAction,
  calculateQuoteAction,
  ChangeLifeAssuredAction,
  ChangeCoverageAction,
  setFiaAction,
  setBiTableValuesaAction,
  setCloneStatusAction,
  setBiPdfAction,
  setEmailSentAction,
  setEmailErrorMessage,
  setFormInput,
} from './actions';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectQuoteId,
} from './selectors';
import {
  CREATE_QUOTE_ACTION,
  CALCULATE_QUOTE_ACTION,
  GET_QUOTE_ACTION,
  UPDATE_STATUS_ACTION,
  EMAIL_PDF_ACTION,
  CREATE_APPLICATION_ACTION,
  SET_PRODUCT_CONFIG_ACTION,
  GET_FIA_ACTION,
  CLONE_QUOTE_ACTION,
} from './constants';

function calculateBody(
  lifeAssured,
  myProductConfig,
  quoteId,
  isLifeAssuredEqual,
  offerCodeData,
) {
  const partyDetails = [];

  if (isEmpty(quoteId) || quoteId == undefined) {
    delete lifeAssured.insuredOne.prospectId;
    delete lifeAssured.insuredTwo.prospectId;
  }

  if (lifeAssured.insuredOne.isPolicyOwner)
    lifeAssured.insuredOne.isPrimaryPolicyOwner = true;
  else lifeAssured.insuredOne.isPrimaryPolicyOwner = false;

  partyDetails.push(lifeAssured.insuredOne);
  if (lifeAssured.isInsuredTwo) {
    if (
      !lifeAssured.insuredOne.isPolicyOwner &&
      lifeAssured.insuredTwo.isPolicyOwner
    )
      lifeAssured.insuredTwo.isPrimaryPolicyOwner = true;
    else lifeAssured.insuredTwo.isPrimaryPolicyOwner = false;

    partyDetails.push(lifeAssured.insuredTwo);
  }

  if (
    lifeAssured.isPolicyOwnerIndividual &&
    lifeAssured.policyOwnerIndividual.length
  ) {
    lifeAssured.policyOwnerIndividual.forEach((poInd, index) => {
      if (
        index === 0 &&
        !lifeAssured.insuredOne.isPolicyOwner &&
        !lifeAssured.insuredTwo.isPolicyOwner
      )
        poInd.isPrimaryPolicyOwner = true;
      else poInd.isPrimaryPolicyOwner = false;
      partyDetails.push(poInd);
    });
  }

  myProductConfig.regularWithdrawal.amount = numeral(
    myProductConfig.regularWithdrawal.amount,
  ).value();
  myProductConfig.regularWithdrawal.percentage = numeral(
    myProductConfig.regularWithdrawal.percentage,
  ).value();

  myProductConfig.regularWithdrawal.selected =
    myProductConfig.isRegularWithdrawal;

  let productConfigs = {};
  // delete myProductConfig.regularWithdrawal.isAmountChecked;

  productConfigs = {
    name: myProductConfig.name,
    productCode: myProductConfig.productCode,
    ptvVersion: myProductConfig.ptvVersion,
    productVersion: myProductConfig.ptvVersion,
    currency: myProductConfig.currency,
    premium: numeral(myProductConfig.premium).value() || 0,
    growthRate: numeral(myProductConfig.growthRate).value(),
    startDate: myProductConfig.startDate,
    offerCodes: [{ ...offerCodeData }],
    establishmentCharge: myProductConfig.establishmentCharge,
    maxEstablishmentCharge: myProductConfig.maxEstablishmentCharge, // BE need the maxPercent to from the productConfig
    fiaCharge: myProductConfig.isAdditionalFeatures
      ? numeral(myProductConfig.fiaCharge).value()
      : 0,
    isAdditionalFeatures: myProductConfig.isAdditionalFeatures,
    regularWithdrawal: myProductConfig.regularWithdrawal,
    targetFunctionality: myProductConfig.isGetPremium,
    targetFundEnable: myProductConfig.targetFunctionality,
    targetFund: numeral(myProductConfig.targetFund).value() || 0,
    targetYear:
      numeral(myProductConfig.targetYear - new Date().getFullYear()).value() ||
      0,
    FMC:
      myProductConfig.FMC === 0
        ? myProductConfig.FMC
        : myProductConfig.FMC || '',
    shortName: myProductConfig.shortName || '',
  };

  const requestBody = {};

  if (!isEmpty(quoteId) && quoteId != undefined) {
    requestBody.id = quoteId;
    requestBody.partyDetails = partyDetails;
    requestBody.productConfigs = productConfigs;
    requestBody.noUpdatePartyDetails = isLifeAssuredEqual;
  } else {
    requestBody.partyDetails = partyDetails;
    requestBody.productConfigs = productConfigs;
    requestBody.noUpdatePartyDetails = false;
  }

  return requestBody;
}

export function* createQuote(action) {
  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote`;

  const isLifeAssuredEqual = action.payload;

  const quoteId = yield select(makeSelectQuoteId());

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const auth = yield select(makeSelectAuth());
  const offerCodeData = yield select(makeSelectOfferCodeData());

  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    quoteId,
    isLifeAssuredEqual,
    offerCodeData,
  );

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setQuoteAction(response.data));
      yield put(setAppLoadingAction(false));
      yield put(setErrorAction({ error: false, message: [] }));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* calculateQuote(action) {
  yield put(setBiTableValuesaAction({}));
  const { isLifeAssuredEqual, isGetPremium } = action.payload;

  const quoteId = yield select(makeSelectQuoteId());

  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/calculate`;

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const auth = yield select(makeSelectAuth());
  const offerCodeData = yield select(makeSelectOfferCodeData());

  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    quoteId,
    isLifeAssuredEqual,
    offerCodeData,
  );

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    yield put(change('coverageForm', 'isGetPremium', false));
    const response = yield call(request, endpoint, requestOpt);
    if (get(response, 'data.isValid', true)) {
      if (response.data && isGetPremium) {
        yield put(
          change(
            'coverageForm',
            'premium',
            response.data.response.premium.toFixed(2),
          ),
        );
        yield put(change('coverageForm', 'hasToCalculate', false));
        yield put(
          ChangeCoverageAction(
            {
              ...myProductConfig,
              isGetPremium: false,
              premium: response.data.response.premium.toFixed(2),
            },
            false,
          ),
        );
      } else {
        yield put(setBiTableValuesaAction(response.data.BITableValues));
      }
      yield put(setErrorAction({ error: false, message: [] }));
    } else {
      if (isGetPremium) yield put(change('coverageForm', 'premium', null));
      yield put(
        setErrorAction({ error: true, message: response.data.errorList }),
      );
    }
  } catch (err) {
    if (err.response) {
      const response = yield err.response.json();
      if (response.message && response.error)
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.message }],
          }),
        );
      else
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.errorCode }],
          }),
        );
    } else {
    }
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* updateQuoteStatus(action) {
  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/status`;

  const auth = yield select(makeSelectAuth());

  const quoteId = yield select(makeSelectQuoteId());
  let myBody = {};
  if (action.payload === 1) {
    myBody = {
      quoteId,
      isPreview: true,
    };
  }

  if (action.payload === 2) {
    myBody = {
      quoteId,
      isAgree: true,
    };
  }

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
      yield call(getQuote);
    } else {
      yield put(setAppLoadingAction(false));
      yield put(
        setErrorAction({
          error: true,
          message: [{ message: response.message }],
        }),
      );
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getQuote(action) {
  yield put(setAppLoadingAction(true));

  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());

  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${quoteId}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
      if (action && action.payload !== undefined) {
        const lifeInsuredData = {};
        lifeInsuredData.policyOwnerIndividual = [];
        response.data.prospects.forEach(productData => {
          const partyDetails = {
            prospectId: productData.id,
            title: productData.title,
            firstName: productData.firstName,
            lastName: productData.lastName,
            dateOfBirth: productData.dateOfBirth,
            gender: productData.gender.toString(),
            isSmoke: productData.isSmoke,
            isLifeInsured: productData.prospectquoterelationships.isLifeInsured,
            isPrimaryLifeInsured:
              productData.prospectquoterelationships.isPrimaryLifeInsured,
            isPolicyOwner: productData.prospectquoterelationships.isPolicyOwner,
            relationshipToLifeInsured: productData.relationshipToLifeInsured,
          };

          if (partyDetails.isLifeInsured && partyDetails.isPrimaryLifeInsured)
            lifeInsuredData.insuredOne = partyDetails;

          if (
            partyDetails.isLifeInsured &&
            !partyDetails.isPrimaryLifeInsured
          ) {
            lifeInsuredData.isInsuredTwo = true;
            lifeInsuredData.insuredTwo = partyDetails;
          }

          if (!partyDetails.isLifeInsured) {
            lifeInsuredData.isPolicyOwnerIndividual = true;
            lifeInsuredData.policyOwnerIndividual.push(partyDetails);
          }
        });
        yield put(ChangeLifeAssuredAction(lifeInsuredData));

        const data = {};
        if (response.data.productConfigs) {
          const offerCodes =
            response.data.productConfigs?.offerCodes &&
            response.data.productConfigs?.offerCodes[0];
          if (offerCodes) {
            yield put(
              validateOfferCodeSuccessAction({
                code: offerCodes.code ? offerCodes.code : '',
                discountRate: offerCodes.discountRate
                  ? offerCodes.discountRate
                  : 0,
                discountMessage: offerCodes.discountMessage
                  ? offerCodes.discountMessage
                  : {},
                validating: false,
              }),
            );
          }

          data.name = response.data.productConfigs.name;
          data.productCode = response.data.productConfigs.productCode;
          data.ptvVersion = response.data.productConfigs.ptvVersion;
          data.establishmentCharge =
            response.data.productConfigs.establishmentCharge;
          data.fiaCharge = response.data.productConfigs.fiaCharge;
          data.startDate = response.data.productConfigs.startDate;
          data.currency = response.data.productConfigs.currency;
          data.growthRate = response.data.productConfigs.growthRate;
          data.offerCode = offerCodes ? offerCodes.code : '';
          data.premium = response.data.productConfigs.premium;
          data.targetFunctionality =
            response.data.productConfigs.targetFundEnable;
          data.targetFund = response.data.productConfigs.targetFund;
          data.targetYear = response.data.productConfigs.targetFundEnable
            ? response.data.productConfigs.targetYear + new Date().getFullYear()
            : null;
          data.regularWithdrawal =
            response.data.productConfigs.regularWithdrawal;
          data.isRegularWithdrawal =
            response.data.productConfigs.regularWithdrawal.selected;
          if (response.data.productConfigs.isInforce === true) {
            data.additionalSinglePremium = {
              amount: response.data.productConfigs.additionalSinglePremium,
              frequency: response.data.productConfigs.paymentFrequency,
              startDate: response.data.productConfigs.additionalSinglePremiumStartDate,
              endDate: response.data.productConfigs.additionalSinglePremiumEndDate
            };
            data.isAdditionalSinglePremium =
              response.data.productConfigs.isAdditionalSinglePremium;
            data.adHocwithdrawl = response.data.productConfigs.adHocwithdrawl;
            data.isInforce = response.data.productConfigs.isInforce;
          }
          data.isAdditionalFeatures =
            response.data.productConfigs.isAdditionalFeatures;
          if (action.payload != 2) {
            const details = {
              referenceNumber: response.data.referenceNumber,
              benefitIllustrationPdf: response.data.benefitIllustrationPdf,
              status: response.data.status,
            };
            yield put(setQuoteDetailsAction(details));
            yield call(
              getPdf,
              response.data.benefitIllustrationPdf.split('=')[1],
            );
          }
        }
        yield put(ChangeCoverageAction(data));
      } else {
        const details = {
          referenceNumber: response.data.referenceNumber,
          benefitIllustrationPdf: response.data.benefitIllustrationPdf,
          status: response.data.status,
        };
        yield put(setQuoteDetailsAction(details));
        yield call(getPdf, response.data.benefitIllustrationPdf.split('=')[1]);
      }
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getPdf(fileName) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/storage/create`;

  const updatedPayload = {
    type: 'FILE',
    name: fileName,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(updatedPayload),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!isEmpty(response)) {
      yield put(setAppLoadingAction(false));
      yield put(setBiPdfAction(`${response.previewUrl}`));
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* emailQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/bi-pdf-email`;

  const myBody = {
    quoteId,
    email: action.payload,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
    yield put(setEmailSentAction(false));
  } catch (err) {
    const response = yield err.response.json();
    if (response?.code === 'TOO_MANY_REQUESTS') {
      yield put(setEmailSentAction(true));
      yield put(setEmailErrorMessage(response.message));
    }
    yield put(setAppLoadingAction(false));
  }
}

export function* getFiaCharges(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/fia-charge`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!isEmpty(response)) {
      yield put(setAppLoadingAction(false));
      if (!response.error) {
        yield put(setErrorAction({ error: false, message: [] }));
        yield put(setFiaAction(response.data));
        yield put(setAppLoadingAction(false));
      }
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* createApplication() {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application`;

  const myBody = {
    quoteId,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setApplicationIdAction(response.data.id));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* cloneQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${quoteId}/quote-reference`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setCloneStatusAction(true));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* handleUpdateCoverage(action) {
  const {
    recalculateQuote,
    reverseCalculate,
    isLifeAssuredEqual,
    isGetPremium,
  } = action.payload;
  if (recalculateQuote) {
    yield put(
      calculateQuoteAction({
        reverseCalculate,
        isLifeAssuredEqual,
        isGetPremium,
      }),
    );
  }
}

// Individual exports for testing
export default function* gcIllustrationPageSaga() {
  yield all([
    takeLatest(CREATE_QUOTE_ACTION, createQuote),
    takeLatest(CALCULATE_QUOTE_ACTION, calculateQuote),
    takeLatest(UPDATE_STATUS_ACTION, updateQuoteStatus),
    takeLatest(GET_QUOTE_ACTION, getQuote),
    takeLatest(EMAIL_PDF_ACTION, emailQuote),
    takeLatest(CREATE_APPLICATION_ACTION, createApplication),
    takeLatest(SET_PRODUCT_CONFIG_ACTION, handleUpdateCoverage),
    takeLatest(GET_FIA_ACTION, getFiaCharges),
    takeLatest(CLONE_QUOTE_ACTION, cloneQuote),
  ]);
}

/*
 *
 * ApplicationPage reducer
 *
 */

import { fromJS } from 'immutable';
import { forEach } from 'lodash/collection';
import {
  DEFAULT_ACTION,
  SET_UNDERWRITING_SECTIONS,
  SET_SESSION_ID,
  SET_ETAG,
  SET_APPLCATION_SUBORDERS,
  SET_APPLICATION_DETAILS_ACTION,
  SET_LIFE_INSURED_PARTIES_ACTION,
  SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION,
  SET_LIST_APPLICATION_PARTY_ACTION,
  SET_DELETE_APPLICATION_ACTION,
  SET_LOGOUT_WHEN_UPDATE_APP_ACTION,
  SET_ADMIN_ACTION_DETAIL_ACTION,
} from './constants';
// this is the overall structure of the Application form
const defaultState = {
  underWritingSections: [
    {
      order: 5,
      text: 'Loading',
      id: null,
      name: 'Loading',
      sessionId: '0',
      partyId: '0',
      eTag: '0',
    },
  ],
  LIParties: [],
  applicationSubOrders: [
    {
      order: 1,
      text: 'Policy Details',
      subOrder: [
        { order: 1, text: 'Policy owner(s)', isHidden: false },
        { order: 2, text: 'Beneficiaries', isHidden: false },
        { order: 3, text: 'Payor', isHidden: false },
        { order: 4, text: 'Payor Details', isHidden: true },
        { order: 5, text: 'Payor Details continued', isHidden: true },
        { order: 6, text: 'Life insured', isHidden: false },
        { order: 7, text: 'Investment', isHidden: false },
      ],
    },
    {
      order: 2,
      text: 'Underwriting',
      subOrder: [
        { order: 8, text: 'Consent', isHidden: true },
        { order: 9, text: 'Loading', isHidden: false },
      ],
    },
    {
      order: 3,
      text: 'Documents',
      subOrder: [],
    },
    {
      order: 4,
      text: 'Consent',
      subOrder: [],
    },
    {
      order: 5,
      text: 'Payment',
      subOrder: [
        { order: 10, text: 'Payment', isHidden: true },
        { order: 11, text: 'Payment method', isHidden: false },
        { order: 12, text: 'Review', isHidden: false },
      ],
    },
  ],
  applicationDetails: {},
  financialInstituteOptions: [],
  listApplication: [],
  isLogout: false,
};

export const initialState = fromJS({ ...defaultState });

function applicationPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SESSION_ID:
      return state.setIn(['sessionId'], fromJS(action.payload));
    case SET_ETAG:
      const { eTag, partyId } = action.payload;

      const { underWritingSections, LIParties } = state.toJS();

      let newUnderWritingSections = [];
      let newLIParties = [];

      forEach(underWritingSections, section => {
        //for UW Sections
        if (section.partyId === partyId) {
          newUnderWritingSections.push({ ...section, eTag });
        } else {
          newUnderWritingSections.push(section);
        }
      });

      forEach(LIParties, party => {
        if (party.partyId === partyId) {
          newLIParties.push({ ...party, eTag });
        } else {
          newLIParties.push({ ...party });
        }
      });

      return state
        .setIn(['underWritingSections'], newUnderWritingSections)
        .setIn(['LIParties'], newLIParties);
    case SET_UNDERWRITING_SECTIONS:
      return state.set('underWritingSections', fromJS(action.payload));
    case SET_APPLCATION_SUBORDERS:
      return state.set('applicationSubOrders', fromJS(action.payload));
    case SET_APPLICATION_DETAILS_ACTION:
      return state.set('applicationDetails', fromJS(action.payload));
    case SET_LIFE_INSURED_PARTIES_ACTION:
      return state.set('LIParties', fromJS(action.payload));
    case SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION:
      return state.set('financialInstituteOptions', fromJS(action.payload));
    case SET_LIST_APPLICATION_PARTY_ACTION:
      return state.set('listApplication', fromJS(action.payload));
    case SET_LOGOUT_WHEN_UPDATE_APP_ACTION:
      return state.set('isLogout', action.payload);
    case SET_DELETE_APPLICATION_ACTION:
      const { idDelete, message } = action.payload;
      const { listApplication } = state.toJS();
      let newListApplication;
      if (message === 'SUCCESS') {
        newListApplication = listApplication.filter(
          item => item.id !== idDelete,
        );
      }
      return state.set('listApplication', fromJS(newListApplication));
    case SET_ADMIN_ACTION_DETAIL_ACTION:
      {
          return state.set('adminActionDetails', fromJS(action.payload));
      }
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default applicationPageReducer;

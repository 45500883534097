/*
 *
 * HubPage constants
 *
 */

export const DEFAULT_ACTION = 'app/HubPage/DEFAULT_ACTION';

export const GET_LIST_APPLICATION_PARTY_ACTION = 'app/HubPage/GET_LIST_APPLICATION_PARTY_ACTION';
export const SET_LIST_APPLICATION_PARTY_ACTION = 'app/HubPage/SET_LIST_APPLICATION_PARTY_ACTION';

export const GET_LIST_ILLUSTRATION_ACTION = 'app/HubPage/GET_LIST_ILLUSTRATION_ACTION';
export const SET_LIST_ILLUSTRATION_ACTION = 'app/HubPage/SET_LIST_ILLUSTRATION_ACTION';

export const GET_LIST_IN_FORCE_POLICY_ACTION = 'app/HubPage/GET_LIST_IN_FORCE_POLICY_ACTION';
export const SET_LIST_IN_FORCE_POLICY_ACTION = 'app/HubPage/SET_LIST_IN_FORCE_POLICY_ACTION';

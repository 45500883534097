import React from 'react';
import PropTypes from 'prop-types';
import { WrappedFieldProps } from 'redux-form';
import moment from 'moment';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from 'components/TextField';
import { dobFormat } from 'helpers/constants';
import { InputLabel } from '@material-ui/core';

function FormDatePicker(props) {
  const {
    input: { onChange, value, onBlur, ...inputProps },
    label,
    disabled,
    meta: { invalid, error, touched, warning },
    dateInputFormat = 'DD/MM/YYYY',
    intl,
    minDate,
    maxDate,
    ...custom
  } = props;
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  return (
    <KeyboardDatePicker
      label={
        <InputLabel disableAnimation shrink>
          {label}
        </InputLabel>
      }
      value={value ? moment(new Date(`${value}`)) : null}
      error={!!warning || (touched && invalid)}
      helperText={helperMsg}
      {...inputProps}
      onChange={momentDate => {
        if (momentDate !== null) {
          return onChange(momentDate.format(dobFormat));
          // return onChange(momentDate.format('YYYY-MM-DD'));
        }
        return onChange(null);
        // return onChange(momentDate.format('YYYY-MM-DD'));
      }}
      onBlur={() => (value ? onBlur(moment(value).format(dobFormat)) : null)}
      // onBlur={() => (value ? onBlur(moment(value).format('YYYY-MM-DD')) : null)}
      disabled={disabled}
      TextFieldComponent={TextField}
      fullWidth
      {...custom}
      format={dateInputFormat}
    />
  );
}

FormDatePicker.propTypes = {
  ...WrappedFieldProps,
  dateInputFormat: PropTypes.string,
  autoOk: PropTypes.bool,
  okLabel: PropTypes.node,
  cancelLabel: PropTypes.node,
  inputVariant: PropTypes.string,
  animateYearScrolling: PropTypes.bool,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minDateMessage: PropTypes.node,
  maxDateMessage: PropTypes.node,
  invalidDateMessage: PropTypes.node,
};

FormDatePicker.defaultProps = {
  autoOk: true,
  okLabel: '',
  cancelLabel: '',
  inputVariant: 'outlined',
  animateYearScrolling: true,
  minDate: new Date().setFullYear(new Date().getFullYear() - 74),
  maxDate: new Date().setFullYear(new Date().getFullYear() - 18),
  minDateMessage: '',
  maxDateMessage: '',
  invalidDateMessage: '',
};

export default FormDatePicker;

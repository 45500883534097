import { Grid, Link, Typography, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dot from '@material-ui/icons/FiberManualRecord';
import InfoIcon from '@material-ui/icons/Info';
import { ADJUSTED_POLICY_SUBSTEPPERS } from 'containers/ApplicationFormPage/constants';
import { makeSelectQuestions } from 'containers/ApplicationFormPage/selectors';
import numeral from 'numeral';
import moment from 'moment';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';

import { productCodeCheck } from 'helpers/constants';
//SELECTOR
import {
  makeSelectUnderwritingSections,
  makeSelectLIParties,
} from 'containers/ApplicationPage/selectors';
//ACTION
import { changeSubStepPageAction } from 'containers/DashboardPage/actions';
import { convertIfImmutable } from 'helpers/lang';
import { forEach, filter, map, find } from 'lodash/collection';
import { get } from 'lodash/object';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import validate from './validate';
import Dialog from 'components/Dialog';
import { getApplicationDetailsAction } from 'containers/ApplicationPage/actions';

const useStyle = makeStyles(theme => ({
  wrapper: {
    width: '660px',
  },
  wrapperPadding: {
    padding: '3rem',
  },
  name: {
    color: blue[800],
  },
  sectionAnswerContainer: {
    border: '1px solid #ccc',
    // padding: '2rem',
  },
  sectionItemPadding: {
    padding: '2rem',
  },
  bold: {
    fontWeight: '700',
  },
  flexContainer: {
    display: 'flex',
    // alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '2rem',
  },
  Dot: {
    color: blue[800],
    marginRight: '1rem',
  },
  answer: {
    backgroundColor: '#F2F2F2',
    padding: '2rem',
  },
  marginBottom: {
    marginBottom: '.5rem',
  },
  marginLeft: {
    marginLeft: '2.5rem',
  },
  noPadding: {
    padding: 0,
  },
}));

function Submit(props) {
  const theme = useTheme();
  const classes = useStyle();
  const history = useHistory();

  const {
    UWSections,
    UWQuestions,
    changeSubStepPage,
    LIParties,
    lifeInsured,
    openDialog,
    setOpenDialog,
    submitDialog,
    canSubmit,
    getApplicationDetails,
    match,
    applicationDetails
  } = props;
  const applicationId = match.params.applicationId;
  useEffect(() => {
    getApplicationDetails(applicationId);
    canSubmit(false);
  }, []);

  const renderAnsweredQuestion = (questions, title, sectionId) => {
    let titleDetails = (productCodeCheck(8, applicationDetails.productCode)) && title?.split("-");
    var unansweredQuestion = 0;
    forEach(questions, question => {
      !question.parentQuestionId &&
        question.type === 'SINGLE_CHOICE' &&
        question.constraints?.choices[0].value === 'true' &&
        question?.answer?.value === 'true'
        ? (unansweredQuestion += 1)
        : '';
      // !question.isAnswered ? (unansweredQuestion += 1) : '';
    });

    //RENDER ANSWER BY QUESTION TYPE
    const renderAnswer = question => {
      switch (question.type) {
        case 'SINGLE_CHOICE':
          return (
            <span className={classes.marginLeft}>{question.answer.text}</span>
          );
        case 'MULTIPLE_CHOICE':
        case 'SEARCH':
          return (
            <ul>
              {question.answer.answers.map(answer => (
                <li>{answer.text}</li>
              ))}
            </ul>
          );
        case 'UNITIZED':
          return (
            <span className={classes.marginLeft}>
              {get(question, 'answer.answerParts') +
                ' ' +
                find(get(question, 'constraints.availableUnits'), function (o) {
                  return (
                    o.symbolParts[0] === get(question, 'answer.selectedUnit')
                  );
                }).label}
            </span>
          );
        case 'NUMERIC':
          return (
            <>
              <span className={classes.marginLeft}>
                {numeral(question.answer.value).format('0,0')}
              </span>
            </>
          );
        case 'BLOOD_PRESSURE':
          return (
            <>
              {' '}
              <span className={classes.marginLeft}>
                {question.answer?.readings[0].diastolic} diastolic
              </span>
              <br />
              <span className={classes.marginLeft}>
                {question.answer?.readings[0].systolic} systolic
              </span>
            </>
          );
        case 'DATE':
          if (question.answer.dateFormat === 'MONTH_YEAR') {
            return (
              <span className={classes.marginLeft}>
                {moment(question.answer.value).format('MM/YYYY')}
              </span>
            );
          } else if (question.answer.dateFormat === 'EXACT_DATE') {
            return (
              <span className={classes.marginLeft}>
                {moment(question.answer.value).format('DD/MM/YYYY')}
              </span>
            );
          } else if (question.answer.dateFormat === 'MONTHS_SINCE_OCCURRENCE') {
            return (
              <span className={classes.marginLeft}>
                {question.answer.value} months ago
              </span>
            );
          } else {
            return (
              <span className={classes.marginLeft}>
                {question.answer.value}
              </span>
            );
          }
        case 'DOCTOR':
          return (
            <span className={classes.marginLeft}>
              {question.answer.key.replace('|', ' ')}
            </span>
          );
        case 'TEXT':
          return (
            <span className={classes.marginLeft}>{question.answer.value}</span>
          );
        case 'PERCENT':
          return (
            <div>
              <ul className={classes.noPadding}>
                {map(question.answer?.answers, answer => {
                  return (
                    <ol>
                      <span>
                        {answer.amount}% - {answer.text}
                      </span>
                    </ol>
                  );
                })}
              </ul>
            </div>
          );
      }
    };

    return (
      <Grid item xs={12}>
        <div className={classes.sectionAnswerContainer}>
          <Grid container>
            {/* HEADING */}
            <Grid item xs={6}>
              <div className={classes.sectionItemPadding}>
                <Typography variant="body1" className={classes.bold}>
                  {(titleDetails?.length > 0 && productCodeCheck(8, applicationDetails.productCode)) ? titleDetails[0] : title} ({unansweredQuestion})
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.flexEnd}>
                <Link
                  href="#"
                  onClick={() => {
                    changeSubStepPage(sectionId + ADJUSTED_POLICY_SUBSTEPPERS);
                    history.push({
                      pathname: 'uw-section',
                      state: {
                        sessionId: sectionId + ADJUSTED_POLICY_SUBSTEPPERS,
                      },
                    });
                  }}
                  style={{ fontWeight: 800 }}
                >
                  Edit
                </Link>
              </div>
            </Grid>
            {/* BODY */}
            {map(
              filter(questions, (question, index) => {
                //check if the parent yes or no question is no
                if (
                  question.type === 'SINGLE_CHOICE' &&
                  question.answer.value === 'false' &&
                  typeof question.parentQuestionId === 'undefined'
                ) {
                  return false;
                }

                if (
                  question.type === 'STATEMENT' &&
                  typeof question.parentQuestionId === 'undefined'
                ) {
                  // check the question underneath it has answers or until it see another statement or it ends
                  for (var i = index; i < questions.length; i++) {
                    // if STATEMENT
                    if (
                      questions[i + 1]?.type === 'STATEMENT' &&
                      typeof questions[i + 1]?.parentQuestionId === 'undefined'
                    ) {
                      return false;
                    } else if (
                      questions[i + 1]?.type === 'SINGLE_CHOICE' &&
                      questions[i + 1]?.answer.value === 'false' &&
                      typeof questions[i + 1].parentQuestionId === 'undefined'
                    ) {
                    } else if (
                      (questions[i + 1]?.type === 'SINGLE_CHOICE' &&
                        questions[i + 1]?.answer.value === 'true' &&
                        typeof questions[i + 1]?.parentQuestionId ===
                        'undefined') ||
                      (i === questions.length &&
                        questions[i]?.type === 'SINGLE_CHOICE' &&
                        questions[i]?.answer.value === 'true' &&
                        typeof questions[i]?.parentQuestionId === 'undefined')
                    ) {
                      return true;
                    } else if (
                      i + 1 === questions.length &&
                      questions[i]?.type === 'SINGLE_CHOICE' &&
                      questions[i]?.answer.value === 'false' &&
                      typeof questions[i]?.parentQuestionId === 'undefined'
                    ) {
                      return false;
                    } else return true;
                  }
                }

                return true;
              }),
              question => {
                if (question.type === 'STATEMENT') {
                  if (question.id !== 1) {
                    return (
                      <>
                        <Grid item xs={12}>
                          <div className={classes.answer}>
                            <Grid container>
                              <Grid item xs={12}>
                                <div className={classes.flexContainer}>
                                  <Typography variant="body2">
                                    {question.text}
                                  </Typography>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.marginBottom} />
                        </Grid>
                      </>
                    );
                  }
                } else {
                  return (
                    <>
                      {isFirstChild(questions, question) && (
                        <Grid item xs={12}>
                          <div
                            className={classes.answer}
                            style={{
                              // paddingLeft: '170px',
                              fontWeight: '800',
                            }}
                          >
                            <Typography
                              variant="h4"
                              style={{ fontWeight: '800' }}
                            >
                              {question.context[0]}
                            </Typography>
                          </div>
                        </Grid>
                      )}
                      {!question.parentQuestionId && (
                        <Grid item xs={12}>
                          <div className={classes.marginBottom} />
                        </Grid>
                      )}
                      <Grid item xs={12}>
                        <div className={classes.answer}>
                          <Grid container>
                            {' '}
                            <Grid item xs={6}>
                              <div
                                className={classes.flexContainer}
                              // style={{
                              //   paddingLeft: question.parentQuestionId
                              //     ? '150px'
                              //     : '0px',
                              // }}
                              >
                                {!question.parentQuestionId &&
                                  question.type === 'SINGLE_CHOICE' &&
                                  question.constraints.choices[0].value ===
                                  'true' ? (
                                  <div>
                                    <InfoIcon className={classes.Dot} />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {/* {!question.answer && (
                                  <Dot className={classes.Dot} />
                                )} */}
                                <Typography variant="body2">
                                  {question.text}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant="body2">
                                {question.answer && renderAnswer(question)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </>
                  );
                }
              },
            )}
          </Grid>
        </div>
      </Grid>
    );
  };
  const lifeInsuredAnswers = map(
    lifeInsured.sort(function (x, y) {
      //SORT who is PO1
      return (
        y.partyapplicationrelationships?.isPrimaryLifeInsured -
        x.partyapplicationrelationships?.isPrimaryLifeInsured
      );
    }),
    party => {
      let updatedQuestions = [];
      //find the section associated to the party
      const sections = filter(convertIfImmutable(UWSections), section => {
        return section.partyId === party.id;
      });

      //map the answers together with its text name
      forEach(sections, section => {
        updatedQuestions.push({
          sectionName: section.text,
          questionList: UWQuestions[section.text],
          id: section.id,
        });
      });
      return {
        firstName: party.firstName,
        lastName: party.lastName,
        questions: updatedQuestions,
      };
    },
  );
  return (
    <Grid container spacing={3} className={classes.wrapper}>
      {map(lifeInsuredAnswers, party => {
        if (party.questions?.length > 0) {
          return (
            <>
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.name}>
                  {`${party.firstName} ${party.lastName}`}
                </Typography>
              </Grid>

              {map(party.questions, question =>
                renderAnsweredQuestion(
                  question.questionList,
                  question.sectionName,
                  question.id,
                ),
              )}
            </>
          );
        } else {
          return '';
        }
      })}
      <Dialog
        open={openDialog}
        onClose={() => {
          setOpenDialog(false);
        }}
        onExited={() => setOpenDialog(false)}
        variant={'error'}
        title="Warning"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => setOpenDialog(false)}
              >
                Edit
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setOpenDialog(false);
                  submitDialog();
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          Answer to the underwriting questions cannot be changed once they have
          been submitted
        </Typography>
      </Dialog>
    </Grid>
  );
}

Submit.propTypes = {
  ...InjectedFormProps,
};

const mapStateToProps = createStructuredSelector({
  UWSections: makeSelectUnderwritingSections(),
  UWQuestions: makeSelectQuestions(),
  LIParties: makeSelectLIParties(),
});

function mapDispatchToProps(dispatch) {
  return {
    changeSubStepPage: value => dispatch(changeSubStepPageAction(value)),
    getApplicationDetails: value =>
      dispatch(getApplicationDetailsAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  validate,
  enableReinitialize: true,
});

export default compose(withConnect, withForm)(Submit);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Box,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { map } from 'lodash/collection';
import NoticeBox from 'components/NoticeBox';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages';
import FormRadioGroup from 'components/FormRadioGroup';
import { Field } from 'redux-form/immutable';
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import globalMessages from 'containers/App/messages';
import Helper from 'components/Helper';
import FormSelect from 'components/FormSelect';
import { convertIfImmutable } from 'helpers/lang';
import { mapFinancialInstituteOptions } from 'containers/ApplicationFormPage/helpers';
import { isEmpty } from 'lodash';
import CustomRadio from 'components/CustomRadioButton';

const useStyles = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  containerWrapper: {
    width: '660px',
  },
  beneficiariesContainer: {
    marginTop: '2rem',
  },
  radioForm: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  textRadio: {
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
}));

function UaeDirectDebit(props) {
  const classes = useStyles();
  const intl = useIntl();
  const { financialInstituteOptions, formValues } = props;

  const institutionOptions = map(
    convertIfImmutable(financialInstituteOptions),
    institute => {
      return { value: institute.bankCode, title: institute.bankFullName };
    },
  );

  const dataWhoSettingArray = [
    {
      id: 'ZURICH',
      name: 'Zurich',
    },
    {
      id: 'PAYOR',
      name: 'Payor',
    },
  ];
  const dataHowSettingArray = [
    {
      id: 'CREDIT_CARD',
      name: 'Credit card',
    },
    {
      id: 'CURRENT_ACCOUNT',
      name: 'Current account/Savings account',
    },
  ];

  return (
    <div>
      <Grid item xs={12} md={8} className={classes.containerWrapper}>
        <NoticeBox variant="info" mb={4}>
          <Typography variant="body2" color="inherit">
            <FormattedMessage {...messages.subsequentPaymentsOnly} />
          </Typography>
        </NoticeBox>
        <Typography className={classes.label}>
          <FormattedMessage {...messages.whoSettingUAEDD} />
        </Typography>
        <Box mb={1} mt={2} style={{ marginBottom: '3rem' }}>
          <Field
            name="directDebitSetter"
            type="radio"
            value={
              !!formValues.paymentDetails?.directDebitSetter
                ? formValues.paymentDetails.directDebitSetter
                : ''
            }
            component={FormRadioGroup}
            onBlur={e => {
              e.preventDefault();
            }}
          >
            <div className={classes.radioForm}>
              {map(dataWhoSettingArray, (item, index) => {
                return (
                  <FormControlLabel
                    key={`${item.id}-${index}`}
                    control={<CustomRadio />}
                    value={item.id}
                    label={`${item.name}`}
                    labelPlacement="end"
                    className={classes.textRadio}
                  />
                );
              })}
            </div>
          </Field>
        </Box>
        <hr className={classes.hr} />
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.label}>
          <FormattedMessage {...messages.howSettingUAEDD} />
        </Typography>
        <Box mb={1} mt={2} style={{ marginBottom: '3rem' }}>
          <Field
            name="directDebitSource"
            type="radio"
            value={
              !!formValues.paymentDetails?.directDebitSource
                ? formValues.paymentDetails.directDebitSource
                : ''
            }
            component={FormRadioGroup}
            onBlur={e => {
              e.preventDefault();
            }}
          >
            <div className={classes.radioForm}>
              {map(dataHowSettingArray, (item, index) => {
                return (
                  <FormControlLabel
                    key={`${item.id}-${index}`}
                    control={<CustomRadio />}
                    value={item.id}
                    label={`${item.name}`}
                    labelPlacement="end"
                    className={classes.textRadio}
                  />
                );
              })}
            </div>
          </Field>
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Field
          name="accountHolderName"
          component={FormTextField}
          fullWidth
          label={<FormattedMessage {...messages.cardHolderName} />}
          placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
        />
      </Grid>
      <Grid item xs={12} />
      <Box mb={3} />
      {!isEmpty(formValues) &&
        formValues.paymentDetails.directDebitSource === 'CREDIT_CARD' && (
          <Grid item xs={5}>
            <Field
              name="cardNumber"
              component={FormNumberField}
              fullWidth
              label={<FormattedMessage {...messages.cardNumber} />}
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
              allowLeadingZero
              type="text"
              thousandSeparator=""
              // inputFormat="################"
              shrink
              margin="normal"
            />
          </Grid>
        )}
      {/* <Grid item xs={12} md={12} /> */}
      {/* <Box mb={3} /> */}
      <Grid item xs={4}>
        <Field
          shrink
          name={`bankName`}
          fullWidth
          component={FormSelect}
          options={mapFinancialInstituteOptions(
            convertIfImmutable(financialInstituteOptions),
          )}
          label={<FormattedMessage {...messages.bankName} />}
          placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
        />
      </Grid>
      <Grid item xs={12} md={12} />
      <Box mb={3} />
      {!isEmpty(formValues) &&
        formValues.paymentDetails.directDebitSource === 'CURRENT_ACCOUNT' && (
          <Grid item xs={5}>
            <Field
              name="iban"
              component={FormTextField}
              thousandSeparator=""
              fullWidth
              label={
                <>
                  <FormattedMessage {...messages.IBAN} />
                  <Helper
                    helperText={intl.formatMessage(messages.infomationIBAN)}
                  />
                </>
              }
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            />
          </Grid>
        )}
    </div>
  );
}

UaeDirectDebit.propTypes = {};

export default UaeDirectDebit;
/**
 *
 * QuoteForm
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Card,
  Divider,
  Box,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncWarnings,
} from 'redux-form/immutable';
import { InjectedFormProps, change } from 'redux-form';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import {
  CheckCircle,
  AddCircle,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons';
import FormSlider from 'components/FormSlider';
import { formatMonetaryValue } from 'helpers/math';
import { calculateAgeNextBirthdate } from 'containers/FuturaIllustrationPage/helper';
import FormSelect from 'components/FormSelect';
import { isEmpty } from 'lodash';
import FormTextField from 'components/FormTextField';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormPremium from 'components/FormPremium';
import LoadingDialog from 'components/LoadingDialog';

import Benefits from './Benefits';
import messages from '../../messages';
import validate from './validate';
import { productCodeCheck } from 'helpers/constants';

const HeaderContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(3.25)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    background-color: ${props => props.theme.palette.primary.dark};
    color: ${props => props.theme.palette.common.white};
  }
`;

const BrContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(0.5)}px;
    padding-bottom: ${props => props.theme.spacing(0.5)}px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.common.white};
  }
`;

const BottomRow = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60px;
    padding: ${props => props.theme.spacing(1)}px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
  }
`;

function QuoteForm({
  premium,
  annualizedPremium,
  minCalculatedPremium,
  options,
  futuraConfig,
  agentDetails,
  currency,
  startDate,
  policyCoverTerm,
  term,
  offerCode,
  growthRate,
  paymentFrequency,
  singlePremiumTerm,
  coverages,
  lifeAssured,
  handleSubmit,
  onSubmit,
  change,
  onUpdateValues,
  formValues,
  product,
  specialTermsFlag,
  originalPremium,
  warningCoverages,
  location,
  offerCodeData,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });
  const [editType, setEditType] = useState(0);
  const premiumPaymentTerm = [];
  const coverageTerm = [];
  let frequencyLabel = '';

  const agencyNumber = agentDetails.agentNumber;
  futuraConfig.agencyNumber = (agencyNumber)

  const MAX_GROWTH_RATE = futuraConfig?.agencyNumber==="8910138" && futuraConfig?.productCode === "SCFUT" ? 6 : futuraConfig.features?.growthRate?.maxRate;
  const MIN_GROWTH_RATE = futuraConfig?.agencyNumber==="8910138" && futuraConfig?.productCode === "SCFUT" ? 6 : futuraConfig.features?.growthRate?.minRate;
  
  let isCoveragesError = false;
  if (!isEmpty(warningCoverages)) {
    warningCoverages?.coverages.map(warningBenefit => {
      if (!isEmpty(warningBenefit)) {
        isCoveragesError = true;
      }
    });
  }


  let minPremium = 0;
  let maxPremium = 0;
  const isMLP = futuraConfig.productCode === 'SCFUT'; // My Life Protect
  const isFUTU5 = futuraConfig.productCode === 'FUTU5'; // My Life Protect
  
  const primaryPremium =  premium
  const secondryPremium =  annualizedPremium

  futuraConfig.features?.currencyPremiumSize.map(val => {
    if (currency === val.currency) {
      if (term === 'SinglePremium') {
        minPremium = val.minPremiumSingle / singlePremiumTerm;
        maxPremium = val.maxPremiumSingle / singlePremiumTerm;
      } else {
        switch (paymentFrequency) {
          case 'Monthly':
            minPremium = val.minPremiumMonthly;
            maxPremium = val.maxPremiumMonthly;
            break;

          case 'Quarterly':
            minPremium = val.minPremiumQuarterly;
            maxPremium = val.maxPremiumQuarterly;
            break;

          case 'Half-Yearly':
            minPremium = val.minPremiumHalfYearly;
            maxPremium = val.maxPremiumHalfYearly;
            break;

          case 'Yearly':
            minPremium = val.minPremiumYearly;
            maxPremium = val.maxPremiumYearly;
            break;

          case 'Single':
            minPremium = val.minPremiumSingle;
            maxPremium = val.maxPremiumSingle;
            break;

          default:
            minPremium = val.minPremiumMonthly;
            maxPremium = val.maxPremiumMonthly;
            break;
        }
      }
    }
  });

  if (minPremium < minCalculatedPremium) minPremium = minCalculatedPremium;

  const disabledUpdate = premium < minPremium;

  let minPremiumValue =
    futuraConfig.features?.policyTerm.minVanishingPremiumTerm;
  let maxPremiumValue =
    futuraConfig.features?.policyTerm.maxVanishingPremiumTerm;

  const expiryAgeValue = futuraConfig.features?.policyTerm.expiryAge;

  let maxCoverageTerm =
    expiryAgeValue -
    calculateAgeNextBirthdate(
      startDate,
      lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
        ? lifeAssured.insuredOne.dateOfBirth
        : startDate,
    );

  if (policyCoverTerm === 'WholeOfLife') {
    const ageOfLifeInsuredOne = calculateAgeNextBirthdate(
      startDate,
      lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
        ? lifeAssured.insuredOne.dateOfBirth
        : startDate,
    );
    const ageOfLifeInsuredTwo = calculateAgeNextBirthdate(
      startDate,
      lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
        ? lifeAssured.insuredOne.dateOfBirth
        : startDate,
    );
    switch (lifeAssured.lifeBasis) {
      case 'JLBD':
        const considerAgeJlbd =
          ageOfLifeInsuredOne < ageOfLifeInsuredTwo
            ? ageOfLifeInsuredOne
            : ageOfLifeInsuredTwo;
        const calculateMaxTermJlbd = expiryAgeValue - considerAgeJlbd;
        maxCoverageTerm =
          calculateMaxTermJlbd > maxPremiumValue
            ? maxPremiumValue
            : calculateMaxTermJlbd;
        break;

      case 'JLFD':
        const considerAgeJlfd =
          ageOfLifeInsuredOne < ageOfLifeInsuredTwo
            ? ageOfLifeInsuredTwo
            : ageOfLifeInsuredOne;
        const calculateMaxTermJlfd = expiryAgeValue - considerAgeJlfd;
        maxCoverageTerm =
          calculateMaxTermJlfd > maxPremiumValue
            ? maxPremiumValue
            : calculateMaxTermJlfd;
        break;

      case 'JLLS':
        const considerAgeJlls =
          ageOfLifeInsuredOne < ageOfLifeInsuredTwo
            ? ageOfLifeInsuredOne
            : ageOfLifeInsuredTwo;
        const calculateMaxTermJlls = expiryAgeValue - considerAgeJlls;
        maxCoverageTerm =
          calculateMaxTermJlls > maxPremiumValue
            ? maxPremiumValue
            : calculateMaxTermJlls;
        break;

      default:
        const calculateMaxTerm = expiryAgeValue - ageOfLifeInsuredOne;
        maxCoverageTerm =
          calculateMaxTerm > maxPremiumValue
            ? maxPremiumValue
            : calculateMaxTerm;
        break;
    }
  } else
    maxCoverageTerm =
      policyCoverTerm > maxPremiumValue ? maxPremiumValue : policyCoverTerm;

  if (coverageTerm.length === 0) {
    let minCoverageTerm = futuraConfig.features?.policyTerm.minTerm;

    while (minCoverageTerm <= maxCoverageTerm) {
      if (minCoverageTerm === maxCoverageTerm) {
        coverageTerm.push({ title: 'Whole of life', value: 'WholeOfLife' });
        break;
      } else {
        coverageTerm.push({ title: minCoverageTerm, value: minCoverageTerm });
        minCoverageTerm++;
      }
    }
  }

  if (policyCoverTerm) {
    if (policyCoverTerm === 'WholeOfLife')
      maxPremiumValue =
        maxCoverageTerm > maxPremiumValue
          ? maxPremiumValue
          : maxCoverageTerm;
    else
      maxPremiumValue =
        policyCoverTerm > maxPremiumValue ? maxPremiumValue : policyCoverTerm;

    while (minPremiumValue <= maxPremiumValue) {
      const myObj = { title: minPremiumValue, value: minPremiumValue };
      premiumPaymentTerm.push(myObj);
      minPremiumValue++;
    }
    if (!isMLP) {
      premiumPaymentTerm.push({
        title: 'Single Premium',
        value: 'SinglePremium',
      });
    }

    if (policyCoverTerm === 'WholeOfLife')
      premiumPaymentTerm.push({ title: 'Whole of life', value: 'WholeOfLife' });
  }

  switch (paymentFrequency) {
    case 'Monthly':
      frequencyLabel = 'per month';
      break;

    case 'Quarterly':
      frequencyLabel = 'per quarter';
      break;

    case 'Half-Yearly':
      frequencyLabel = 'per half-year';
      break;

    case 'Yearly':
      frequencyLabel = 'per year';
      break;

    default:
      if (isFUTU5 && term == 'SinglePremium' && singlePremiumTerm > 0 ){
        if (singlePremiumTerm === 1){
          frequencyLabel = 'per year for ' + singlePremiumTerm + ' year'
        } else {
          frequencyLabel = 'per year for ' + singlePremiumTerm + ' years'
        }
      }
      else{
        frequencyLabel = 'single time'
      }
      break;
  }

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            
            {/*= ===== Premium and annulised premium heading ====== */}
            <HeaderContent container theme={theme}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography component="span" variant="h1">
                    {currency}
                  </Typography>
                </Grid>
                <Grid item>
                  <span
                    id="primaryPremiumAmount"
                    style={{
                      color: 'inherit',
                      fontSize: '6.6rem',
                      lineHeight: 1,
                      fontWeight: 700,
                    }}
                  >
                    {formatMonetaryValue(premium, true)}
                  </span>
                  <Typography color="inherit" variant="body1" component="span">
                    {frequencyLabel}
                  </Typography>
                </Grid>
              </Grid>

              {/* ########## START  :: EDIT PREMIUM ########## */}
              <Collapse in={open}>
                <Box pt={1}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item xs>

                      <Field
                        id="premium"
                        name="premium"
                        component={FormPremium}
                        min={minPremium}
                        max={maxPremium}
                        label=""
                        diff={100}
                        isPrimary
                        type="text"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        id="updatePremium"
                        variant="contained"
                        size="large"
                        disabled={disabledUpdate}
                        onClick={() => {
                          let annualizedPremium = premium;
                          switch (paymentFrequency) {
                            case 'Monthly':
                              annualizedPremium = premium * 12;
                              break;

                            case 'Quarterly':
                              annualizedPremium = premium * 4;
                              break;

                            case 'Half-Yearly':
                              annualizedPremium = premium * 2;
                              break;

                            case 'Yearly':
                              annualizedPremium = premium;
                              break;
                          }
                          if (isFUTU5 && term == 'SinglePremium' && singlePremiumTerm > 1 ){
                            annualizedPremium = premium * singlePremiumTerm;
                          }
                          formValues.policyCoverTerm = 'CustomPremium';
                          formValues.annualizedPremium = annualizedPremium;
                          onUpdateValues(formValues, true);
                          return setOpen(false);
                        }}
                        style={{
                          backgroundColor: disabledUpdate
                            ? theme.palette.grey[400]
                            : theme.palette.common.white,
                          color: disabledUpdate
                            ? theme.palette.common.white
                            : theme.palette.primary.dark,
                          padding: theme.spacing(2),
                        }}
                      >
                        <FormattedMessage {...messages.update} />
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        color="inherit"
                        variant="text"
                        onClick={() => {
                          change('premium', originalPremium);
                          return setOpen(false);
                        }}
                      >
                        <FormattedMessage {...messages.cancel} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
              {/* ########## END    :: EDIT PREMIUM ########## */}

              <Field
                name="annualizedPremium"
                type="hidden"
                component={FormTextField}
                style={{ visibility: 'hidden' }}
              />

              <Field
                name="minCalculatedPremium"
                type="hidden"
                component={FormTextField}
                style={{ visibility: 'hidden' }}
              />

              <Divider
                style={{
                  backgroundColor: theme.palette.info.main,
                  marginTop: theme.spacing(1),
                  marginRight: theme.spacing(3),
                  marginBottom: theme.spacing(1),
                }}
              />

              <Collapse in={open}>
                <Typography
                  display="block"
                  variant="caption"
                  component="span"
                  color="inherit"
                >
                  <FormattedMessage {...messages.notePremiumCalculation} />
                </Typography>
              </Collapse>
              <Collapse in={!open}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="inherit">
                      <span id="secondaryPremiumAmount">
                        {formatMonetaryValue(annualizedPremium, true)}
                      </span>
                      {(isFUTU5 && term == 'SinglePremium' ) ? ` ${currency} Total Single premium` : ` ${currency} Annualised premium`}
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingRight: 12 }}>
                    <IconButton id="edit" onClick={() => setOpen(!open)}>
                      <img src={IconPencilBlue} alt="" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Collapse>
            </HeaderContent>

            {/*= ===== Premium Coverage Term ====== */}
            <BottomRow
              container
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle style={{ color: theme.palette.grey[300] }} />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: '900',
                      color: theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.policyCoverageTerm} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: '900',
                        color: theme.palette.common.black,
                      }}
                    >
                      {policyCoverTerm === 'WholeOfLife'
                        ? 'Whole of life'
                        : `${policyCoverTerm} Years`}
                    </Typography>
                    <IconButton disabled onClick={() => setEditType(1)}>
                      <img
                        style={{ height: '1.6rem' }}
                        src={IconPencilBlue}
                        alt=""
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={editType === 1}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={7}>
                    <Field
                      shrink
                      name="policyCoverTerm"
                      fullWidth
                      component={FormSelect}
                      type="text"
                      options={coverageTerm}
                      margin="normal"
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={2}></Grid>

                  <Grid item xs={3}>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => {
                        setEditType(0);
                        return onUpdateValues(formValues, false, false);
                      }}
                    >
                      <FormattedMessage {...messages.update} />
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </BottomRow>

            {/*= ===== Premium Payment Term ====== */}
            <BottomRow
              container
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle style={{ color: theme.palette.grey[300] }} />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: '900',
                      color: theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.premiumTerm} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: '900',
                        color: theme.palette.common.black,
                      }}
                    >
                      {term === 'WholeOfLife'
                        ? 'Whole of life'
                        : `${term} ${
                            term != 'SinglePremium' && term != 'WholeOfLife'
                              ? 'Years'
                              : ''
                          }`}
                    </Typography>
                    <IconButton onClick={() => setEditType(2)}>
                      <img
                        style={{ height: '1.6rem' }}
                        src={IconPencilBlue}
                        alt=""
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={editType === 2}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={7}>
                    <Field
                      shrink
                      name="term"
                      fullWidth
                      component={FormSelect}
                      type="text"
                      onChange={(e, newVal) => {
                        if (newVal === 'SinglePremium')
                          change('paymentFrequency', '');
                      }}
                      options={premiumPaymentTerm}
                      margin="normal"
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={2}></Grid>

                  <Grid item xs={3}>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => {
                        const isCheckOfferCode = offerCode !== '';
                        setEditType(0);
                        return onUpdateValues(
                          formValues,
                          false,
                          isCheckOfferCode,
                        );
                      }}
                    >
                      <FormattedMessage {...messages.update} />
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </BottomRow>

            {/*= ===== Assumed Growth rate ====== */}
            <BottomRow
              container
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle style={{ color: theme.palette.grey[300] }} />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: '900',
                      color: theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.assumedGrowthRate} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Typography
                      variant="h4"
                      style={{
                        fontWeight: '900',
                        color: theme.palette.common.black,
                      }}
                    >
                      {`${growthRate}%`}
                    </Typography>
                    <IconButton onClick={() => setEditType(3)}>
                      <img
                        style={{ height: '1.6rem' }}
                        src={IconPencilBlue}
                        alt=""
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={editType === 3}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={7}>
                    <Field
                      name="growthRate"
                      component={FormSlider}
                      min={MIN_GROWTH_RATE}
                      max={MAX_GROWTH_RATE}
                      diff={0.5}
                      isPrimary={false}
                      type="text"
                    />
                  </Grid>

                  <Grid item xs={2}></Grid>

                  <Grid item xs={3}>
                    <Button
                      size="large"
                      variant="contained"
                      onClick={() => {
                        setEditType(0);
                        return onUpdateValues(formValues, false);
                      }}
                    >
                      <FormattedMessage {...messages.update} />
                    </Button>
                  </Grid>
                </Grid>
              </Collapse>
            </BottomRow>

            <Grid
              container
              direction="row"
              spacing={1}
              justify="space-between"
              alignItems="center"
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              <Grid item xs />
              <Grid item xs={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredOne.firstName}
                </Typography>
              </Grid>
              {lifeAssured.isInsuredTwo ? (
                <Grid item xs={3}>
                  <Typography
                    color="primary"
                    variant="h3"
                    style={{ marginLeft: theme.spacing(0.5) }}
                  >
                    {lifeAssured.insuredTwo.firstName}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>

            {coverages.length > 0 ? (
              <FieldArray
                name="coverages"
                component={Benefits}
                theme={theme}
                isCoveragesError={isCoveragesError}
                coverages={coverages}
                inactive={open}
                futuraConfig={futuraConfig}
                currency={currency}
                options={options}
                lifeBasis={lifeAssured.lifeBasis}
                change={change}
                editData={editData}
                calculateEditData={val => {
                  const isCheckOfferCode = val.index === 0 && offerCode !== '';
                  setEditData(val);
                  return onUpdateValues(formValues, false, isCheckOfferCode);
                }}
                changeEditData={val => {
                  setEditData(val);
                }}
                isInsuredTwo={lifeAssured.isInsuredTwo}
              />
            ) : null}

            {/*= ===== Offer code ====== */}
            <BottomRow
              container
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle style={{ color: theme.palette.grey[300] }} />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: '900',
                      color: theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.specialOfferCode} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.common.black,
                        }}
                      >
                        {offerCode || '-'}
                        {offerCodeData.errorMessage && (
                          <>
                            <br />
                            <FormHelperText error>
                              {offerCodeData.errorMessage}
                            </FormHelperText>
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginRight: theme.spacing(1) }}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BottomRow>
            {/* =========== END OFFER CODE ============*/}
          </Card>
          {offerCodeData.discountMessage?.english &&
            offerCodeData.errorMessage === '' && (
              <Box my={2}>
                <Typography variant="body1" style={{ fontWeight: 700 }}>
                  <FormattedMessage
                    {...messages.offerCodeValid}
                    values={{
                      value: offerCodeData.discountMessage?.english,
                    }}
                  />
                </Typography>
              </Box>
            )}
        </form>
      )}
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  lifeAssured: PropTypes.object,
  warningCoverages: PropTypes.object,
  options: PropTypes.object,
  futuraConfig: PropTypes.object,
  onUpdateValues: PropTypes.func,
  policyCoverTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startDate: PropTypes.string,
  growthRate: PropTypes.string,
  originalPremium: PropTypes.number,
  term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offerCode: PropTypes.string,
  currency: PropTypes.string,
  paymentFrequency: PropTypes.string,
  singlePremiumTerm: PropTypes.number,
  specialTermsFlag: PropTypes.bool,
  minCalculatedPremium: PropTypes.number,
  premium: PropTypes.number,
  annualizedPremium: PropTypes.number,
  formValues: PropTypes.object,
  product: PropTypes.object,
  coverages: PropTypes.array,
  offerCodeData: PropTypes.object,
};

const withForm = reduxForm({
  form: 'quoteForm',
  warn: validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');
const syncWarningsSelector = getFormSyncWarnings('quoteForm');

const mapStateToProps = state => {
  const currency = selector(state, 'currency');
  const policyCoverTerm = selector(state, 'policyCoverTerm');
  const growthRate = selector(state, 'growthRate');
  const specialTermsFlag = selector(state, 'specialTermsFlag');
  const startDate = selector(state, 'startDate');
  const singlePremiumTerm = selector(state, 'singlePremiumTerm');
  const term = selector(state, 'term');
  const offerCode = selector(state, 'offerCode');
  const premium = selector(state, 'premium');
  const minCalculatedPremium = selector(state, 'minCalculatedPremium');
  const annualizedPremium = selector(state, 'annualizedPremium');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const coverages = selector(state, 'coverages');
  const formValues = getFormValues('quoteForm')(state);
  let warningCoverages = syncWarningsSelector(state, 'coverages');
  if (warningCoverages instanceof Map) {
    warningCoverages = warningCoverages.toJS();
  }
  return {
    premium,
    annualizedPremium,
    minCalculatedPremium,
    currency,
    policyCoverTerm,
    term,
    offerCode,
    startDate,
    growthRate,
    paymentFrequency,
    specialTermsFlag,
    singlePremiumTerm,
    coverages: coverages?.toJS() || [],
    formValues: formValues?.toJS() || {},
    warningCoverages,
  };
};

const mapDispatchToProps = dispatch => ({
  // changePolicyCoverTerm: val => dispatch(change('quoteForm', 'policyCoverTerm', val)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withConnect;

/**
 * BeneficiaryDetails
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Field, FieldArray, FormSection } from 'redux-form/immutable';
import { Box, Grid, Typography, Button, Collapse } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import CardSectionForm from 'components/CardSectionForm';
import FormTextField from 'components/FormTextField';
import messages from 'containers/ApplicationFormPage/messages';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import { newTaxPurposes } from 'helpers/placeholder';

const useStyle = makeStyles(theme => ({
  gridSubContainer: {
    width: '100%',
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(6),
  },
  hr: {
    margin: '0 0rem',
    borderTop: '1px solid #DDDFE0',
  },
  hrCollapse: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  containerWrapper: {
    width: '660px',
    margin: 'auto',
  },
  error: {
    color: red[800],
    fontWeight: 600,
    marginBottom: 20,
  },
  button: {
    fontWeight: 800,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function TaxResidency({ field, formValues, index, change }) {
  const classes = useStyle();
  return (
    <Grid item xs={12}>
      <Grid
        container
        spacing={3}
        className={clsx(
          classes.gridSubContainer,
          classes.gridSubContainerEmail,
        )}
      >
        <Grid item xs={12}>
          <CardSectionForm
            variant="outlined"
            className={classes.containerWrapper}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Box style={{ width: '85%' }}>
                <Typography variant="body1">
                  <FormattedMessage {...messages.isUAEResidence} />
                </Typography>
              </Box>
              <Field
                name={`${field}.isUAEResidenceVisa`}
                component={FormSwitch}
                value={formValues.parties[index].isUAEResidenceVisa}
                onChange={() => {
                  change(`${field}.obtainUAEResidence`, false);
                }}
              ></Field>
              <Collapse in={formValues.parties[index].isUAEResidenceVisa}>
                <hr className={classes.hrCollapse} />
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Box style={{ width: '85%' }}>
                    <Typography variant="body1">
                      <FormattedMessage {...messages.obtainUAEResidence} />
                    </Typography>
                  </Box>
                  <Field
                    name={`${field}.obtainUAEResidence`}
                    component={FormSwitch}
                    value={formValues.parties[index].obtainUAEResidence}
                  ></Field>
                </Grid>
              </Collapse>
            </Grid>
          </CardSectionForm>
        </Grid>
      </Grid>
    </Grid>
  );
}

TaxResidency.propTypes = {
  field: PropTypes.object,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  index: PropTypes.number,
  options: PropTypes.array,
  change: PropTypes.func,
};

function CountryResidence(props) {
  const intl = useIntl();
  const classes = useStyle();

  const { fields, options, disabled, formValues } = props;

  const addTaxPurposes = () => {
    if (fields.length < 3) {
      fields.push({ ...newTaxPurposes });
    }
  };

  return fields.map((field, index) => {
    const isGBCountry = formValues?.taxPurposes[index]?.countryOfTax === 'GB';
    return (
      <Grid container spacing={2} className={classes.container}>
        <Grid item>
          <Field
            name={`${field}.countryOfTax`}
            disabled={disabled}
            component={FormSelect}
            style={{
              width: 210,
            }}
            options={[
              { title: 'Select an option', value: 'Select an option' },
              ...options.countryCode,
            ]}
            label={<FormattedMessage {...messages.countryOfTax} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            margin="normal"
            variant="standard"
          />
        </Grid>
        <Grid item>
          <Field
            name={`${field}.taxReferenceNumber`}
            fullWidth
            disabled={disabled}
            component={FormTextField}
            thousandSeparator=""
            style={{ width: 210 }}
            label={
              <Box pb={0}>
                <FormattedMessage {...messages.taxReferenceNumber} />
              </Box>
            }
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            margin="normal"
            variant="standard"
          />
        </Grid>
        {isGBCountry && (
          <Grid item>
            <Field
              name={`${field}.taxInsuranceNumber`}
              fullWidth
              disabled={disabled}
              component={FormTextField}
              thousandSeparator=""
              style={{ width: 210 }}
              label={
                <Box pb={0}>
                  <FormattedMessage {...messages.taxInsuranceNumber} />
                </Box>
              }
              placeholder={intl.formatMessage(messages.pleaseEnter)}
              type="text"
              margin="normal"
              variant="standard"
            />
          </Grid>
        )}
        <Grid item>
          {!disabled && (
            <div className={classes.buttonContainer}>
              <Grid container spacing={0}>
                {fields.length === index + 1 && fields.length < 3 && (
                  <Grid item style={{ marginRight: 20 }}>
                    <Button className={classes.button} onClick={addTaxPurposes}>
                      Add
                    </Button>
                  </Grid>
                )}
                {fields.length !== 1 && (
                  <Grid item>
                    <Button
                      className={classes.button}
                      onClick={() => {
                        fields.remove(index);
                      }}
                    >
                      Delete
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    );
  });
}

CountryResidence.propTypes = {
  fields: PropTypes.object,
  formValues: PropTypes.object,
  options: PropTypes.object,
  disabled: PropTypes.bool,
};

export default TaxResidency;

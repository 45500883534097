import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash/object';
import { Field, FormSection } from 'redux-form/immutable';
import {
  Grid,
  FormControlLabel,
  Radio,
  Button,
  Divider,
  IconButton,
  Link,
} from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import PayorProceeds from './PayorProceeds';
import FormDatePicker from 'components/FormDatePicker';
import { newPayorSource } from 'helpers/placeholder';
import { isEmpty } from 'lodash';

const useStyle = makeStyles(theme => ({
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 80,
  },
  button: {
    fontWeight: 800,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hr: {
    marginTop: 30,
    marginBottom: 10,
    borderTop: '1px solid #DDDFE0',
  },
}));

function PayorSource(props) {
  const classes = useStyle();
  const intl = useIntl();

  const { fields, options, formValues = {} } = props;

  const { payorSource } = formValues;
  //   console.log(payorSource);
  //   const { originOfWealth } = payorSource;

  // const originOfWealth = get(formValues, 'payorSource.originOfWealth');
  const payorSources = !isEmpty(formValues) && get(formValues, 'payorSource');

  const addPayorSource = () => {
    if (fields.length < 3) {
      fields.push({ ...newPayorSource });
    }
  };

  const renderCompanySale = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.nameOfCompany`}
            component={FormTextField}
            label={<FormattedMessage {...messages.nameOfCompany} />}
            //   options={options.sourceOfWealth}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.natureOfCompany`}
            component={FormTextField}
            label={<FormattedMessage {...messages.natureOfCompany} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateOfSale`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateOfSale} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select an option' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.totalAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.sharePercentage`}
            thousandSeparator={''}
            component={FormNumberField}
            label={<FormattedMessage {...messages.sharePercentage} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
      </>
    );
  };
  const renderMaturing = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.descriptionOfMaturing`}
            component={FormTextField}
            label={<FormattedMessage {...messages.descriptionOfMaturing} />}
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateOfMaturity`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateOfMaturity} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select an option' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.maturingProceeds`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.maturingProceeds} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
      </>
    );
  };

  const renderInheritance = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.detailsOfInheritance`}
            component={FormTextField}
            label={<FormattedMessage {...messages.detailsOfInheritance} />}
            //   options={options.sourceOfWealth}
            MultiLine
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateRecieved`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateReceived} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select an option' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.totalAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
      </>
    );
  };

  const renderGearedLoan = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.nameOfLoanProvider`}
            component={FormTextField}
            label={<FormattedMessage {...messages.nameOfLoanProvider} />}
            //   options={options.sourceOfWealth}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.natureOfLoanProvider`}
            component={FormTextField}
            label={<FormattedMessage {...messages.natureOfLoanProvider} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateOfLoan`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateOfLoan} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select an option' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.totalAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
      </>
    );
  };

  const renderGift = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateRecieved`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateReceived} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select an option' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.totalAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.reasonForGift`}
            component={FormTextField}
            label={<FormattedMessage {...messages.reasonForGift} />}
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.relationshipToApplicant`}
            component={FormSelect}
            label={<FormattedMessage {...messages.relationshipToApplicant} />}
            options={[
              { value: 'Select', title: 'Select' },
              ...options.applicationRelationship,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.donorsOriginOfWealth`}
            component={FormTextField}
            label={<FormattedMessage {...messages.donorsOriginOfWealth} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
      </>
    );
  };

  const renderGambling = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateOfWin`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateOfWin} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={options.currency}
            options={[
              { value: 'Select', title: 'Select' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.totalAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.detailsOfWin`}
            component={FormTextField}
            label={<FormattedMessage {...messages.detailsOfWin} />}
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.detailsOfOrganisation`}
            component={FormTextField}
            label={<FormattedMessage {...messages.detailsOfOrganisation} />}
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
      </>
    );
  };

  const renderPayment = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateRecieved`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateReceived} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.totalAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.reasonForPayment`}
            component={FormTextField}
            label={<FormattedMessage {...messages.reasonForPayment} />}
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
      </>
    );
  };

  const renderCorporate = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.natureOfBusiness`}
            component={FormTextField}
            label={<FormattedMessage {...messages.natureOfBusiness} />}
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.detailsOfTurnover`}
            component={FormTextField}
            label={<FormattedMessage {...messages.detailsOfTurnover} />}
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
      </>
    );
  };

  const renderRetirement = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.retirementDate`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.retirementDate} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.detailsOfPreviousProfession`}
            component={FormTextField}
            label={
              <FormattedMessage {...messages.detailsOfPreviousProfession} />
            }
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.nameNAddressOfLastEmployer`}
            component={FormTextField}
            label={
              <FormattedMessage {...messages.nameNAddressOfLastEmployer} />
            }
            //   options={options.sourceOfWealth}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.approxSalary`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.approxSalary} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.pensionIncome`}
            // thousandSeparator={''}
            component={FormNumberField}
            label={<FormattedMessage {...messages.pensionIncome} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
      </>
    );
  };

  const renderOther = (field) => {
    return (
      <>
        <Grid item xs={8}>
          <Field
            name={`${field}.natureOfSource`}
            component={FormTextField}
            label={<FormattedMessage {...messages.natureOfSource} />}
            //   options={options.sourceOfWealth}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.dateRecieved`}
            component={FormDatePicker}
            label={<FormattedMessage {...messages.dateReceived} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
            maxDate={new Date().setFullYear(new Date().getFullYear())}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.currency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.currency} />}
            options={[
              { value: 'Select', title: 'Select' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Field
            name={`${field}.totalAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.recievedFrom`}
            component={FormTextField}
            label={<FormattedMessage {...messages.recievedFrom} />}
            //   options={options.sourceOfWealth}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
      </>
    );
  };

  const renderFields = (field, index) => {
    const originOfWealth = !!payorSources && payorSources[index].originOfWealth;
    if (originOfWealth === 'CompanySale') {
      return renderCompanySale(field);
    } else if (originOfWealth === 'MaturingInvestmentOrPolicyClaim') {
      return renderMaturing(field);
    } else if (originOfWealth === 'Inheritance') {
      return renderInheritance(field);
    } else if (originOfWealth === 'GearedLoan') {
      return renderGearedLoan(field);
    } else if (originOfWealth === 'Gift') {
      return renderGift(field);
    } else if (originOfWealth === 'LotteryBettingCasino') {
      return renderGambling(field);
    } else if (originOfWealth === 'CompensationPayment') {
      return renderPayment(field);
    } else if (originOfWealth === 'CorporateInvestment') {
      return renderCorporate(field);
    } else if (originOfWealth === 'RetirementIncome') {
      return renderRetirement(field);
    } else if (originOfWealth === 'OtherMonies') {
      return renderOther(field);
    }
  };
  return fields.map((field, index) => {
    return (
      <Grid container spacing={3} key={index}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <Field
                name={`${field}.originOfWealth`}
                component={FormSelect}
                label={<FormattedMessage {...messages.originOfWealth} />}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...options.sourceOfWealth,
                ]}
                shrink
                fullWidth
              />
            </Grid>
            {renderFields(field, index)}
            <Grid item xs={4}>
              <div className={classes.buttonContainer}>
                <Grid container spacing={0}>
                  {fields.length < 3 && fields.length === index + 1 && (
                    <Grid item xs={6}>
                      <Link className={classes.button} onClick={addPayorSource}>
                        <FormattedMessage {...messages.addAnother} />
                      </Link>
                    </Grid>
                  )}
                  {fields.length === 1 ? (
                    ''
                  ) : (
                    <Grid item xs={6}>
                      <Link
                        className={classes.button}
                        onClick={() => {
                          fields.remove(index);
                        }}
                      >
                        <FormattedMessage {...messages.delete} />
                      </Link>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
          {fields.length > 1 && (
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
          )}
        </Grid>
        {/* <Grid xs={12} /> */}
        
      </Grid>
    );
  });
}

PayorSource.propTypes = {
  options: PropTypes.object,
  formValues: PropTypes.object,
  fields: PropTypes.object,
};

export default PayorSource;

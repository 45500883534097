/**
 * UserForm
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { InjectedFormProps } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form/immutable';
import { Button, Grid, Link, Box } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import globalMessages from 'containers/App/messages';
import messages from 'containers/RegisterPage/messages';
import FormTextField from 'components/FormTextField';
import validate from './validate';

function UserForm({ invalid, handleSubmit }) {
  return (
    <form>
      <Grid container spacing={2}>
        {/* <Grid item xs={12}>
          <Field
            name="firstName"
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.firstName} />}
            type="text"
            color="inherit"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="lastName"
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.lastName} />}
            type="text"
            color="inherit"
            margin="normal"
          />
        </Grid> */}
        <Grid item xs={12}>
          <Field
            name="username"
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.username} />}
            type="text"
            color="primary"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="agentEmail"
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.email} />}
            type="email"
            color="primary"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="agencyNumber"
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.agencyNo} />}
            type="email"
            color="primary"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="mobile"
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.mobile} />}
            type="tel"
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <Box textAlign="right">
            <Link
              underline="always"
              component={RouterLink}
              to="/login"
            >
              <FormattedMessage {...messages.loginLinkLabel} />
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Box px={10} mt={5}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          margin="normal"
          disabled={invalid}
          onClick={handleSubmit}
          id="registerUserButton"
          fullWidth
        >
          {/* <FormattedMessage {...globalMessages.next} /> */}
          <FormattedMessage {...messages.register} />
        </Button>
      </Box>
    </form>
  );
}

UserForm.propTypes = {
  ...InjectedFormProps,
};

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(mapStateToProps);

export default reduxForm({
  validate,
  form: 'userRegisterForm',
  enableReinitialize: true,
})(compose(withConnect)(UserForm));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

const ChildContent = styled.span`
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: white;
  border: 1px solid #c0c0c0;
  vertical-align: top;
  margin: -2px 0px 0px 5px;
  border-radius: 50%;
  position: relative;
  text-align: center;
  font-size: 12px;
  line-height: 13px;
  font-weight: bold;
  color: #4066b3;
  z-index: 1;
  cursor: pointer;
  /* vertical-align: text-top; */
`;
const CustomToolTip = withStyles({
  tooltip: {
    color: '#fff',
    backgroundColor: '#A0B3D9',
  },
})(Tooltip);

const Helper = ({ helperText, placement }) => {
  const [hoverStatus, setHover] = useState(true);

  // eslint-disable-next-line no-underscore-dangle
  const _onTouchStart = () => {
    setHover(false);
  };
  // eslint-disable-next-line no-underscore-dangle
  const _onTouchEnd = () => {
    setHover(true);
  };
  return (
    <CustomToolTip
      disableFocusListener
      disableTouchListener={!hoverStatus}
      title={helperText}
      placement={placement}
    >
      <ChildContent onTouchStart={_onTouchStart} onTouchEnd={_onTouchEnd}>
        ?
      </ChildContent>
    </CustomToolTip>
  );
};

Helper.propTypes = {
  helperText: PropTypes.string,
  placement: PropTypes.string,
};

export default Helper;

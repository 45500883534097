import React from 'react';
import numeral from 'numeral';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { get, keys, values } from 'lodash/object';
import { filter, map, some, every } from 'lodash/collection';
import { flow } from 'lodash';
// import { startCase } from 'lodash/string';
import { startCase } from 'helpers/lang';
import { isEmpty } from 'lodash/lang';
import { compact } from 'lodash/array';
import { isBoolean } from 'lodash/lang';

import InfoRow from 'components/InfoRow';

import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '660px',
  },
  link: {
    color: '#00C3B4',
  },
  disabled: {
    color: '#B6B8B4',
  },
  infoSmallContainer: {
    backgroundColor: '#f5f5f5',
    padding: '1rem',
    marginTop: '2px',
    paddingLeft: '7.5rem',
  },
  infoSmallContainerInner: {
    backgroundColor: '#fafafa',
    padding: '1rem',
    marginTop: '2px',
    paddingLeft: '7.5rem',
  },
  infoSmall: {
    width: '100%',
  },
  marginRight: {
    marginRight: '2.5rem',
  },
  gray: {
    color: '#8d8d8d',
  },
  primary: {
    color: theme.palette.primary.dark,
  },
}));

const isEmptyObject = flow(values, compact, isEmpty);

export default function Section(props) {
  const classes = useStyles();
  const {
    sectionName,
    title,
    currency,
    value,
    frequency,
    coverages,
    sectionName2,
    isBolton = false,
  } = props;

  function getCoverageDisplayValue(value, currency = '') {
    // if boolean, return yes/no
    if (isBoolean(value)) {
      return value ? 'Yes' : 'No';
    }
    // if everything else that is falsy, return -
    if (!value) {
      return '-';
    }
    // return formatted number otherwise + currency

    return `${currency} ${numeral(value).format('0,0')}`;
  }

  const coverageList = filter(keys(coverages[0]), k => {
    if (isBolton && k === 'ESC') {
      return null;
    }
    return k !== 'isPrimary';
  });
  const coverageList2 = filter(keys(coverages[1]), k => {
    return k !== 'isPrimary';
  });

  const InnerSection = ({
    name,
    permanentList,
    singleCoverage,
    singleCoverage2,
  }) => {
    return (
      <>
        {' '}
        <div className={classes.infoSmallContainer}>
          <div className={classes.infoSmall}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.primary}>
                  {startCase(name)}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
        {map(
          filter(permanentList, per => {
            return (
              !isEmptyObject(singleCoverage) || !isEmptyObject(singleCoverage2)
            );
          }),
          p => {
            if (
              get(singleCoverage, `${name}.${p}`, 0) === 0 &&
              get(singleCoverage2, `${name}.${p}`, 0) === 0
            ) {
              return '';
            }
            return (
              <>
                <div className={classes.infoSmallContainerInner}>
                  <div className={classes.infoSmall}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Typography
                          variant="h4"
                          color="inherit"
                          className={classes.gray}
                        >
                          {startCase(p) === 'PerPercentage'
                            ? 'Percentage'
                            : startCase(p)}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <div className={classes.marginRight}>
                          <Typography
                            variant="h4"
                            color="inherit"
                            className={classes.gray}
                          >
                            {get(singleCoverage, `${name}.${p}`, 0) !== 0
                              ? singleCoverage[name][p]
                              : '---'}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className={classes.marginRight}>
                          <Typography
                            variant="h4"
                            color="inherit"
                            className={classes.gray}
                          >
                            {get(singleCoverage2, `${name}.${p}`, 0) !== 0
                              ? singleCoverage2[name][p]
                              : '---'}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </>
            );
          },
        )}
      </>
    );
  };

  const Exclusion = ({ exclusion, exclusion2 }) => {
    return (
      <div className={classes.infoSmallContainerInner}>
        <div className={classes.infoSmall}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h4" color="inherit" className={classes.gray}>
                Exclusion
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.marginRight}>
                <Typography
                  variant="h4"
                  color="inherit"
                  className={classes.gray}
                >
                  {exclusion?.length > 0 ? 'Y' : '---'}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={classes.marginRight}>
                <Typography
                  variant="h4"
                  color="inherit"
                  className={classes.gray}
                >
                  {exclusion2?.length > 0 ? 'Y' : '---'}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant="body1" color="primary">
          {sectionName} {sectionName2 && `(${sectionName2})`}
        </Typography>
      </Grid>
      {map(
        filter(coverageList, cv => {
          return coverages[0][cv].selected;
        }),
        c => {
          const singleCoverage = coverages[0] ? coverages[0][c] : {};
          const singleCoverage2 = coverages[1] ? coverages[1][c] : {};
          const isEmptyPermanent = isEmptyObject(singleCoverage.permanent);
          const isEmptyPermanent2 = isEmptyObject(singleCoverage2.permanent);
          const isEmptyTemporary = isEmptyObject(singleCoverage.temporary);
          const isEmptyTemporary2 = isEmptyObject(singleCoverage2.temporary);

          const permanentList = keys(singleCoverage.permanent);
          const permanentLis2 = keys(singleCoverage2.permanent);
          const temporaryList = keys(singleCoverage.temporary);
          const temporaryList2 = keys(singleCoverage2.temporary);

          const exclusion = singleCoverage.exclusion;
          const exclusion2 = singleCoverage2.exclusion;
          return (
            <>
              <Grid item xs={12}>
                <InfoRow>
                  <Grid container justify="center" spacing={2}>
                    <Grid item xs={1}>
                      <InfoIcon color="primary" />
                    </Grid>
                    <Grid item xs={5}>
                      <Typography variant="h4" color="inherit">
                        {singleCoverage.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <div>
                        <Typography variant="h4" color="inherit">
                          {getCoverageDisplayValue(
                            singleCoverage.value,
                            currency,
                          )}
                        </Typography>
                      </div>
                    </Grid>
                    {!isEmpty(singleCoverage2) ? (
                      <Grid item xs={3}>
                        <div>
                          <Typography variant="h4" color="inherit">
                            {getCoverageDisplayValue(
                              singleCoverage2.value,
                              currency,
                            )}
                            {/* {`${currency} ${value} / ${frequency}`} */}
                          </Typography>
                        </div>
                      </Grid>
                    ) : (
                      <Grid item xs={3} />
                    )}
                  </Grid>
                </InfoRow>
                {!isEmptyPermanent || !isEmptyPermanent2 ? (
                  <InnerSection
                    name={'permanent'}
                    permanentList={permanentList}
                    singleCoverage={singleCoverage}
                    singleCoverage2={singleCoverage2}
                  />
                ) : (
                  ''
                )}

                {!isEmptyTemporary || !isEmptyTemporary2 ? (
                  <InnerSection
                    name={'temporary'}
                    permanentList={temporaryList}
                    singleCoverage={singleCoverage}
                    singleCoverage2={singleCoverage2}
                  />
                ) : (
                  ''
                )}

                {exclusion?.length > 0 || exclusion2?.length > 0 ? (
                  <Exclusion exclusion={exclusion} exclusion2={exclusion2} />
                ) : (
                  ''
                )}
              </Grid>
            </>
          );
        },
      )}
    </>
  );
}

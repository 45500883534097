import { createSelector } from 'reselect';
import { initialState } from './reducer';



/**
 * Direct selector to the dashboardPage state domain
 */
const selectDashboardPageDomain = state =>
  state.get('dashboardPage', initialState);

/**
 * Other specific selectors
 */

const makeSelectSubPage = () =>
  createSelector(selectDashboardPageDomain, substate =>
    substate.get('subPage'),
  );
const makeSelectSubPageStepper = () =>
  createSelector(selectDashboardPageDomain, substate =>
    substate.get('subPageStepper'),
  );

const makeSelectSubPageList = () =>
  createSelector(selectDashboardPageDomain, substate =>
    substate.get('subPageList').toJS(),
  );

export {
  selectDashboardPageDomain,
  makeSelectSubPage,
  makeSelectSubPageStepper,
  makeSelectSubPageList,
};

/*
 *
 * HubPage actions
 *
 */

import {
  DEFAULT_ACTION,
  SET_LIST_APPLICATION_PARTY_ACTION,
  GET_LIST_APPLICATION_PARTY_ACTION,
  GET_LIST_ILLUSTRATION_ACTION,
  SET_LIST_ILLUSTRATION_ACTION,
  SET_LIST_IN_FORCE_POLICY_ACTION,
  GET_LIST_IN_FORCE_POLICY_ACTION,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function getListApplicationsPartyAction(payload) {
  return { type: GET_LIST_APPLICATION_PARTY_ACTION, payload };
}

export function setListApplicationPartyAction(payload) {
  return {
    type: SET_LIST_APPLICATION_PARTY_ACTION,
    payload,
  };
}

export function getListIllustrationAction(payload) {
  return { type: GET_LIST_ILLUSTRATION_ACTION, payload };
}

export function setListIllustrationAction(payload) {
  return {
    type: SET_LIST_ILLUSTRATION_ACTION,
    payload,
  };
}

export function setListPolicyAction(payload) {
  return {
    type: SET_LIST_IN_FORCE_POLICY_ACTION,
    payload,
  };
}

export function getListInForcePolicyAction(payload) {
  return {
    type: GET_LIST_IN_FORCE_POLICY_ACTION,
    payload,
  };
}

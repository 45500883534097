/*
 * Footnote Messages
 *
 * This contains all the text for the Footnote component
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.components.Footnote';

export default defineMessages({
  footnote: {
    id: `${scope}.footnote`,
    defaultMessage: `Use of this platform is subject to Zurich's {link1} and {link2}`,
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage: `Privacy Policy`,
  },
  termsOfUse: {
    id: `${scope}.termsOfUse`,
    defaultMessage: `Terms of Use`,
  },
});

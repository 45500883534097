import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import CustomDialog from 'components/Dialog';
import IconEditSrc from 'images/open-external.svg';
import IconDeleteSrc from 'images/icon-trash.svg';
import { deleteApplicationAction } from '../../actions';
import { mapStatus } from '../../helper';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#4066B3',
    fontSize: 16,
    borderBottom: '1px solid #ffffff',
  },
  body: {
    fontSize: 14,
    borderBottom: '1px solid #ffffff',
    maxWidth: 140,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  table: {
    minWidth: 700,
  },
  lifeInsured: {
    fontWeight: 'bold',
  },
  btnUpLoad: {
    color: '#4066B3',
    float: 'left',
    cursor: 'pointer',
    marginTop: '0.3rem',
    marginRight: '2rem',
  },
  btnDelete: {
    color: '#b6b8b4',
    cursor: 'pointer',
  },
  customTable: {
    overflowY: 'scroll',
  },
  inProcessText: {
    color: '#00C3B4',
    textTransform: 'capitalize',
  },
  pendingText: {
    color: '#FF8F4A',
    textTransform: 'capitalize',
  },
  actionableText: {
    color: '#E75252',
    textTransform: 'capitalize',
  },
  rejectedText: { 
    color: '#E75252',
    textTransform: 'capitalize',
  },
  submittedText: {
    color: '#043594',
    textTransform: 'capitalize',
  },
  customBox: {
    marginTop: 150,
  },
}));

export function TableApplication({
  dataApplication,
  deleteApplication,
  setApplicationDetails,
}) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [name, setName] = useState('');
  const upLoad = (id, productCode) => {
    setApplicationDetails({});
    history.push({
      pathname: `applications/${id}`,
      state: { productCode },
    });
  };

  const showModalDelete = id => {
    if (id) {
      setOpen(true);
      setIdDelete(id);
      const itemDelete = dataApplication.filter(item => item.id === id);
      const item = itemDelete[0].parties.filter(
        element =>
          Boolean(
            element.partyapplicationrelationships.isPrimaryLifeInsured,
          ) === true,
      );
      const nameDelete = `${item[0].firstName} ${item[0].lastName}`;
      setName(nameDelete);
    }
  };

  const deleteApplications = idDelete => {
    deleteApplication(idDelete);
    setOpen(false);
  };

  const sortDate = () => {};

  const formatDate = date => {
    return moment(date).format('DD MMM YYYY');
  };

  return (
    <>
      <Box mt={5} className={classes.customBox}>
        <TableContainer classes={{ root: classes.customTable }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell style={{ width: '15%' }}>Name</StyledTableCell>
                <StyledTableCell style={{ width: '20%' }}>
                  <Typography onClick={() => sortDate()}>
                    Submitted
                    <ArrowDropDownIcon />
                  </Typography>
                </StyledTableCell>
                <StyledTableCell style={{ width: '18%' }}>
                  Product
                </StyledTableCell>
                <StyledTableCell style={{ width: '15%' }}>
                  Ref no.
                </StyledTableCell>
                <StyledTableCell style={{ width: '18%' }}>
                  Status
                </StyledTableCell>
                <StyledTableCell style={{ width: '12%' }}>
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataApplication.map((item, index) => (
                <TableRow key={index} className={classes.root}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.lifeInsured}
                  >
                    {item.parties.map((element, i) => {
                      const key = `el${i}`;
                      return (
                        <div key={key}>
                          {element.partyapplicationrelationships
                            .isPrimaryLifeInsured && (
                            <div>
                              <div>{`${element.firstName} ${element.lastName}`}</div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDate(item.createdAt)}
                  </StyledTableCell>
                  <StyledTableCell>{item.productName}</StyledTableCell>
                  <StyledTableCell>{item.referenceNumber}</StyledTableCell>
                  <StyledTableCell
                    className={mapStatus(classes, item.mappedStatus)}
                  >
                    {item.mappedStatus}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <img
                      src={IconEditSrc}
                      alt=""
                      className={classes.btnUpLoad}
                      onClick={() => upLoad(item.id, item.productCode)}
                    />
                    <img
                      src={IconDeleteSrc}
                      alt=""
                      className={classes.btnDelete}
                      onClick={() => showModalDelete(item.id)}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <CustomDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Delete the application(s)?"
        actions={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              margin="normal"
              className={classes.button}
              style={{
                marginRight: 30,
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.button}
              onClick={() => {
                deleteApplications(idDelete);
              }}
            >
              Delete
            </Button>
          </div>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 800 }}>
          Are you sure you want to delete <b>{name}</b> ?You cannot undo this
          action
        </Typography>
      </CustomDialog>
    </>
  );
}

TableApplication.propTypes = {
  dataApplication: PropTypes.func,
  deleteApplication: PropTypes.func,
  setApplicationDetails: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    deleteApplication: id => dispatch(deleteApplicationAction(id)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TableApplication);

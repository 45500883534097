/**
 * CoverageForm
 */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
// import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import TargetFund from 'containers/App/components/TargetFund';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  WrappedFieldArrayProps,
  InjectedFormProps,
  FormSection,
} from 'redux-form';
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  Collapse,
  Radio,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import { dimension } from 'styles/constants';
import FormNumberField from 'components/FormNumberField';
import FormTextField from 'components/FormTextField';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import moment from 'moment';
import CustomFormCheck from 'components/CustomFormCheck';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
import CardSectionForm from 'components/CardSectionForm';
import OptSwitch from 'components/OptSwitch';
import { isEmpty } from 'lodash';
import FormRadioGroup from 'components/FormRadioGroup';
import FormSwitch from 'components/FormSwitch';
import CustomRadio from 'components/CustomRadioButton';
import numeral from 'numeral';
import Helper from 'components/Helper';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import Withdrawal from '../Withdrawal';
import { getStartDate, calculateAgeNextBirthdate } from '../../helper';
import validate from './validate';
import asyncValidator from './asyncValidate';
import messages from '../../messages';
import { get } from 'lodash/object';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  labelCheckbox: {
    fontWeight: 700,
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => (
  <OptSwitch
    checked={value}
    value={value}
    color="default"
    onChange={onChange}
    labelNegative={<FormattedMessage {...messages.no} />}
    disabled={disabled}
  />
);

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const renderBenefit = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne.dateOfBirth,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo.dateOfBirth,
      );

      let ageValidType = 0;

      let isFormCheckDisabled = myBenefit[index].disabled;
      const isFieldsDisabled = !myBenefit[index].selected;

      let isWopOneDisabled = myBenefit[index].disabled;
      let isWopTwoDisabled = myBenefit[index].disabled;

      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA != '0') {
          if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
            isWopOneDisabled = true;
            change(`${benefit}.value`, false);
          }

          if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
            isWopTwoDisabled = true;
            change(`${benefit}.value`, false);
          }
        }
      } else if (myBenefit[index].maxEntryAgeLA != '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          isWopOneDisabled = true;
          change(`${benefit}.value`, false);
          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }

      return (
        <div>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justiify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            item
            xs={11}
          >
            <Grid item xs={12} sm={6}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.selected`}
                  fullWidth
                  component={CustomFormCheck}
                  label={myBenefit[index].title}
                  disabled={isFormCheckDisabled}
                  validType={ageValidType}
                  type="text"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`${benefit}.value`, false);
                    }
                  }}
                />
              ) : (
                <Grid container direction="row">
                  <Typography variant="h4" style={{ fontWeight: '600' }}>
                    {myBenefit[index].title}
                  </Typography>
                  <div>
                    <Helper
                      helperText={
                        <FormattedMessage {...messages.descriptionTwo} />
                      }
                    ></Helper>
                  </div>
                </Grid>
              )}
            </Grid>
            {/* test */}
            <Grid item xs={6} sm={3}>
              <Field
                // shrink
                name={`${benefit}.value`}
                component={FormBooleanOption}
                type="text"
                margin="normal"
                disabled={isWopOneDisabled}
                // shrink
                fullWidth
              />
            </Grid>
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  product,
  swConfig,
  change,
  offerCodeData,
  startDate,
  fiaData,
  premium,
  fiaCharge,
  regularWithdrawal,
  isRegularWithdrawal,
  isAdditionalFeatures,
  location,
  currency,
  offerCode,
  resetOfferCode,
  validateOfferCode,
  agentDetails,
  formValues,
  formErrors,
  submitFailed,
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const offerList = [];
  const currencyOption = [];
  const chargeOptions = [];
  let minCharge = get(
    swConfig,
    'features.establishmentCharge.minPercentage',
    0,
  );
  let maxCharge = get(
    swConfig,
    'features.establishmentCharge.maxPercentage',
    4.5,
  );
  const paymentFrequencyOption = [];
  const policyTermList = [];
  const startDateList = [];

  while (minCharge <= maxCharge) {
    chargeOptions.push({
      title: `${numeral(minCharge).format('0.0')} %`,
      value: minCharge,
    });
    minCharge += 0.5;
  }

  if (startDateList.length == 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  const minStartDate = moment(startDate)
    .date(15)
    .add(1, 'months')
    .toDate();

  const maxStartDate = moment(startDate)
    .add(100, 'years')
    .toDate();

  let minPremiumPaymentTerm = 5;
  const maxPremiumPaymentTerm = 30;

  while (minPremiumPaymentTerm <= maxPremiumPaymentTerm) {
    const myObj = {
      title: `${minPremiumPaymentTerm}`,
      value: minPremiumPaymentTerm,
    };
    policyTermList.push(myObj);
    minPremiumPaymentTerm++;
  }
  policyTermList.push({ title: 'Single premium', value: 'SinglePremium' });

  offerList.push({ title: 'No Offer', value: '' });
  product?.specialOffers?.map(offer => {
    offerList.push({
      value: offer.specialOfferCode,
      title: offer.specialOfferCode,
    });
  });

  swConfig.features.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  swConfig.features.premiumPaymentFrequencyType[0].periods.map(frequency => {
    paymentFrequencyOption.push({ value: frequency, title: frequency });
  });

  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '') {
      validateOfferCode({
        offerCode,
        productCode: swConfig.productCode,
        productVersion: swConfig.productVersion,
        currency,
        premium,
        premiumType: 'SinglePremium',
      });
    }
  }, []);

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* CURRRENCY */}
            <Grid item>
              <Field
                // shrink
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            <Grid item sm={12}>
              <Field
                // shrink
                name="premium"
                style={{ width: 172 }}
                disabled={formValues.targetFunctionality}
                component={FormNumberField}
                label={<FormattedMessage {...messages.premiumAmount} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.enterAmount)}
              />
            </Grid>
          </Grid>

          <Divider
            className={classes.hr}
            style={{ marginBottom: theme.spacing(2) }}
          />
          <Grid container alignItems="center" item spacing={2}>
            <TargetFund
              formValues={formValues}
              formErrors={formErrors}
              submitFailed={submitFailed}
              premiumDisabled={formValues.targetFunctionality}
              change={change}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid>
            <Grid container spacing={3} item xs={10} md={12}>
              {/* Assumed growth rate */}
              <Grid item xs={4} md={4}>
                <Field
                  // shrink
                  name="growthRate"
                  fullWidth
                  component={FormNumberField}
                  label={<FormattedMessage {...messages.assumedGrowthRate} />}
                  type="text"
                  margin="normal"
                  variant="standard"
                  placeholder={intl.formatMessage(messages.growthRate)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <FieldArray
              name="coverages"
              component={renderBenefit}
              rerenderOnEveryChange
              lifeAssured={lifeAssured}
              options={options}
              change={change}
              startDate={startDate}
              intl={intl}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid item xs={6} style={{ marginBottom: '5rem' }}>
            <Field
              name="offerCode"
              fullWidth
              style={{
                width: 355,
              }}
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              component={FormSelect}
              options={offerList}
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              placeholder={intl.formatMessage(messages.pleaseEnter)}
            />
          </Grid>

          {/* <Divider className={classes.hr} /> */}

          {/* ===== Regular withdrawal ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages.regularWithdrawalTitle} />
              </Typography>

              <Field
                name="isRegularWithdrawal"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isRegularWithdrawal}>
              <Divider className={classes.hr} />
              <FormSection name="regularWithdrawal">
                <Withdrawal
                  formValues={regularWithdrawal}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  isEducationWithdrawal={false}
                  frequencyList={paymentFrequencyOption}
                  change={change}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          {/* ===== FIA Charges ===== */}
          {/* {!isEmpty(fiaData) && swConfig.features.haveFiaCharge ? (
           <CardSectionForm variant="outlined">
             <Grid
               container
               direction="row"
               alignItems="center"
               justify="space-between"
             >
               <Typography variant="body1">
                 {<FormattedMessage {...globalMessages.additionalFetures} />}
               </Typography>

               <Field
                 name="isAdditionalFeatures"
                 component={FormSwitch}
                 disabled={false}
               />
             </Grid>

             <Collapse in={isAdditionalFeatures}>
               <Divider className={classes.hr} />
               <FormSection name={'additionalFeatures'}>
                 <AdditionalFeatures
                   formValues={additionalFeatures}
                   chargeOptions={chargeOptions}
                   fiaCharge={fiaCharge}
                 />
               </FormSection>
             </Collapse>
           </CardSectionForm>
         ) : null} */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...globalMessages.additionalFetures} />
              </Typography>

              <Field
                name="isAdditionalFeatures"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isAdditionalFeatures}>
              <Divider className={classes.hr} />
              <Grid item xs={4}>
                <Field
                  name="establishmentCharge"
                  component={FormSelect}
                  options={chargeOptions}
                  type="text"
                  fullWidth
                  label={<FormattedMessage {...messages.establishmentCharge} />}
                />
              </Grid>
              {!isEmpty(fiaData) && swConfig.features.haveFiaCharge ? (
                <>
                  <Divider className={classes.hr} />
                  <Typography
                    variant="h4"
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                      fontSize: '1.6rem',
                    }}
                  >
                    <FormattedMessage {...globalMessages.fiaText} />
                  </Typography>

                  <Field
                    name="fiaCharge"
                    type="radio"
                    component={FormRadioGroup}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          classes={{ label: classes.labelCheckbox }}
                          control={<CustomRadio checked={fiaCharge == 0} />}
                          value={fiaData?.minFIACharge}
                          label={<FormattedMessage {...globalMessages.nil} />}
                          labelPlacement="left"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justify="space-between">
                          <FormControlLabel
                            classes={{ label: classes.labelCheckbox }}
                            control={<CustomRadio checked={fiaCharge != 0} />}
                            value={fiaData?.maxFIAChange}
                            label={
                              <FormattedMessage
                                {...globalMessages.annualPercentage}
                              />
                            }
                            labelPlacement="end"
                          />

                          <TextField
                            value={`${fiaData?.maxFIAChange} %`}
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Field>
                </>
              ) : null}
            </Collapse>
          </CardSectionForm>
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  swConfig: PropTypes.object,
  regularWithdrawal: PropTypes.object,
  additionalFeatures: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  startDate: PropTypes.string,
  fiaCharge: PropTypes.any,
  term: PropTypes.string,
  coverages: PropTypes.array,
  fiaData: PropTypes.object,
  isRegularWithdrawal: PropTypes.bool,
  isAdditionalFeatures: PropTypes.bool,
};

const selector = formValueSelector('coverageForm');
const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withFormSelector = connect(state => {
  const startDate = selector(state, 'startDate');
  const fiaCharge = selector(state, 'fiaCharge');
  const currency = selector(state, 'currency');
  const premium = selector(state, 'premium');
  const formValues = getFormValues('coverageForm')(state);
  const formErrors = getFormSyncErrors('coverageForm')(state);
  const offerCode = selector(state, 'offerCode');
  const regularWithdrawal = selector(state, 'regularWithdrawal');
  const term = selector(state, 'term');
  const isRegularWithdrawal = selector(state, 'isRegularWithdrawal');
  const isAdditionalFeatures = selector(state, 'isAdditionalFeatures');
  const additionalFeatures = selector(state, 'additionalFeatures');
  return {
    startDate,
    fiaCharge,
    term,
    regularWithdrawal: regularWithdrawal?.toJS() || {},
    isRegularWithdrawal,
    additionalFeatures,
    isAdditionalFeatures,
    currency,
    offerCode,
    premium,
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: ['offerCode', 'currency', 'premium'],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    font-size: 62.5%;
  }

  html,
  body {
    height: 100%;
    width: 100%;
  }

  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: #ffffff;
  }

  body.fontLoaded {
    /* font-family: 'Frutiger', 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  #app {
    width: 100%;
    height: 100%;
  }
`;

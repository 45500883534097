import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import { map, filter, forEach } from 'lodash/collection';
import {
  Grid,
  Typography,
  Collapse,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Helper from 'components/Helper';

import messages from 'containers/ApplicationFormPage/messages';
import globalMessages from 'containers/App/messages';

import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import FormRadioGroup from 'components/FormRadioGroup';
import FormSelect from 'components/FormSelect';
import { convertIfImmutable } from 'helpers/lang';
import { filterCountryOption } from 'containers/ApplicationFormPage/helpers';
import CustomRadio from 'components/CustomRadioButton';
import { productCodeCheck } from 'helpers/constants';

const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

const renderRelationship = ({ disabled, options }) => {
  const intl = useIntl();
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Field
          name="relationshipToLifeInsured"
          component={FormSelect}
          options={options.applicationRelationship}
          fullWidth
          label={<FormattedMessage {...messages.relationshipToLifeInsured} />}
          placeholder={intl.formatMessage(globalMessages.select)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Field
          name="reasonToPay"
          component={FormTextField}
          fullWidth
          label={<FormattedMessage {...messages.reasonWhyThirdIsPaying} />}
          placeholder={intl.formatMessage(globalMessages.select)}
        />
      </Grid>

      <Grid item xs={12} md={8}>
        <Field
          name="other"
          component={FormTextField}
          multiline
          rows={3}
          fullWidth
          label={<FormattedMessage {...messages.other} />}
          placeholder={intl.formatMessage(globalMessages.pleaseProvideDetails)}
          // disabled={disabled ? true : false}
        />
      </Grid>
    </Grid>
  );
};

function LifeInsuredPayor(props) {
  const classes = useStyle();
  const theme = useTheme();
  const intl = useIntl();
  const {
    fields,
    lifeInsuredParties,
    options,
    formValues,
    fieldName,
    change,
    applicationDetails,
    lifeInsuredIndex,
    isInforce,
  } = props;

  const { productCode } = applicationDetails;

  return fields.map((field, index) => {
    const party = convertIfImmutable(fields.get(index));
    const parties = convertIfImmutable(fields.getAll());
    const firstName = party.firstName;
    const lastName = party.lastName;

    if (!isEmpty(formValues)) {
      //ADDRESS CONDITION
      if (
        formValues[fieldName][index]?.selectedAddress !== 'different' &&
        formValues[fieldName][index]?.selectedAddress !== '' &&
        typeof formValues[fieldName][index]?.selectedAddress !== 'undefined'
      ) {
        //get the party that is selected
        const selectedParty = filter(formValues.parties, party => {
          return party.id === formValues[fieldName][index].selectedAddress;
        });
        change(
          `${fieldName}[${index}].addressLine1`,
          selectedParty[0].addressLine1,
        );
        change(
          `${fieldName}[${index}].addressLine2`,
          selectedParty[0].addressLine2,
        );
        change(
          `${fieldName}[${index}].addressLine3`,
          selectedParty[0].addressLine3,
        );
        change(
          `${fieldName}[${index}].postOfficeBoxLine`,
          selectedParty[0].postOfficeBoxLine,
        );
        change(
          `${fieldName}[${index}].postOfficeBoxCity`,
          selectedParty[0].postOfficeBoxCity,
        );
        change(`${fieldName}[${index}].city`, selectedParty[0].city);
        change(`${fieldName}[${index}].country`, selectedParty[0].country);
      }

      //UNSELECT ALL when there's no LI selected
      if (
        formValues.selectedLifeInsuredParties ||
        formValues.thirdPersonPayor
      ) {
        change(`${fieldName}[${index}].lifeInsuredPersonPayor`, false);
      }

      // UNSELECT the other LI if this LI is selected
      // if (formValues[fieldName][index].lifeInsuredPersonPayor) {
      //   forEach(formValues[fieldName], (_, index3) => {
      //     if (index3 !== index) {
      //       change(`${fieldName}[${index3}].lifeInsuredPersonPayor`, false);
      //     } else if (lifeInsuredIndex === index) {
      //       change(`${fieldName}[${index}].lifeInsuredPersonPayor`, true);
      //     }
      //   });
      // }
    }

    return (
      <CardSectionForm variant="outlined" key={index}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Typography variant="body1">
            <FormattedMessage
              {...messages.lifeInsuredPayor}
              values={{
                firstName,
                lastName,
              }}
            />
          </Typography>

          <Field
            name={`${field}.lifeInsuredPersonPayor`}
            component={FormSwitch}
            disabled={
              formValues.selectedLifeInsuredParties ||
              formValues.thirdPersonPayor ||
              formValues.isCompanyPayor
            }
            onChange={e => {
              change(
                `${fieldName}[${index}].lifeInsuredPersonPayor`,
                e.target.value,
              );
              forEach(formValues[fieldName], (_, index3) => {
                if (index3 !== index) {
                  change(
                    `${fieldName}[${index3}].lifeInsuredPersonPayor`,
                    false,
                  );
                }
              });
            }}
          />
        </Grid>
        <Collapse
          in={
            formValues.selectedLifeInsuredParties ||
            formValues.thirdPersonPayor ||
            formValues.isCompanyPayor
              ? false
              : party.lifeInsuredPersonPayor
          }
        >
          <hr className={classes.hr} />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                }}
              >
                <Typography variant="body2" style={{ fontSize: '1.45rem' }}>
                  {<FormattedMessage {...messages.isPEP} />}
                </Typography>
                <div style={{ marginRight: '1rem' }}>
                  <Helper
                    helperText={intl.formatMessage(messages.pepDescription)}
                  />
                </div>
                <Field
                  name={`${field}.isPep`}
                  type="radio"
                  component={FormRadioGroup}
                  normalize={value => value === 'true'}
                  onBlur={e => {
                    e.preventDefault();
                  }}
                >
                  <FormControlLabel
                    control={
                      <CustomRadio
                        checked={
                          !isEmpty(formValues) &&
                          formValues[fieldName][index].isPep === true
                        }
                      />
                    }
                    value={true}
                    label="Yes"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <CustomRadio
                        checked={
                          !isEmpty(formValues) &&
                          formValues[fieldName][index].isPep === false
                        }
                      />
                    }
                    value={false}
                    label="No"
                    labelPlacement="end"
                  />
                </Field>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name={`${field}.preferredName`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.alternateName} />}
                placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                name={`${field}.primaryNationality`}
                fullWidth
                component={FormSelect}
                options={
                  !isEmpty(formValues)
                    ? filterCountryOption(
                        options.countryCode,
                        formValues.lifeInsuredPayor[index].secondaryNationality,
                      )
                    : []
                }
                label={<FormattedMessage {...messages.primaryNationality} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                disabled={productCodeCheck(1, productCode)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                name={`${field}.secondaryNationality`}
                fullWidth
                component={FormSelect}
                options={
                  !isEmpty(formValues)
                    ? [
                        { value: '', title: 'Select' },
                        ...filterCountryOption(
                          options.countryCode,
                          formValues.lifeInsuredPayor[index].primaryNationality,
                        ),
                      ]
                    : []
                }
                label={<FormattedMessage {...messages.dualNationality} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Field
                name={`${field}.countryOfBirth`}
                fullWidth
                component={FormSelect}
                options={options.countryCode}
                label={<FormattedMessage {...messages.countryOfBirth} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
              />
            </Grid>

            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>

            <Grid item xs={12} md={7}>
              <Field
                name={`${field}.email`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.emailAddress} />}
                placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Field
                    name={`${field}.primaryCountryDialingCode`}
                    fullWidth
                    component={FormSelect}
                    options={options.countryCode}
                    label={<FormattedMessage {...messages.countryCode} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    value=""
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name={`${field}.primaryPhoneNumber`}
                    fullWidth
                    component={FormNumberField}
                    thousandSeparator={''}
                    label={<FormattedMessage {...messages.mobileNumber} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={3}>
                  <Field
                    name={`${field}.secondaryCountryDialingCode`}
                    fullWidth
                    component={FormSelect}
                    options={[
                      { value: '', title: 'Select' },
                      ...options.countryCode,
                    ]}
                    label={<FormattedMessage {...messages.countryCode} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                    value=""
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    name={`${field}.secondaryPhoneNumber`}
                    fullWidth
                    component={FormNumberField}
                    thousandSeparator={''}
                    label={<FormattedMessage {...messages.telephoneNumber} />}
                    placeholder={intl.formatMessage(messages.pleaseEnter)}
                    type="text"
                    color="primary"
                    margin="normal"
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>

            <Grid item xs={12}>
              <Field
                name={`${field}.selectedAddress`}
                type="radio"
                component={FormRadioGroup}
              >
                <div className={classes.radioForm}>
                  <FormControlLabel
                    control={
                      <CustomRadio
                        checked={
                          formValues[fieldName] &&
                          'different' ===
                            formValues[fieldName][index].selectedAddress
                        }
                      />
                    }
                    value={'different'}
                    label={
                      <Typography
                        variant="body1"
                        className={classes.bold}
                      >{`Different address`}</Typography>
                    }
                    labelPlacement="end"
                  />
                  {map(lifeInsuredParties, (party, index2) => {
                    return (
                      <FormControlLabel
                        key={`${party.id}-${index2}`}
                        control={
                          <CustomRadio
                            checked={
                              !isEmpty(formValues) &&
                              party.id ===
                                formValues[fieldName][index].selectedAddress
                            }
                          />
                        }
                        value={party.id}
                        label={
                          <Typography
                            variant="body1"
                            className={classes.bold}
                          >{`Same as ${party.firstName} ${party.lastName}`}</Typography>
                        }
                        labelPlacement="end"
                      />
                    );
                  })}
                </div>
              </Field>
            </Grid>

            {/* ADDRESS */}
            <Grid item xs={12} md={6}>
              <Field
                name={`${field}.addressLine1`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.flatNumber} />}
                placeholder={intl.formatMessage(globalMessages.select)}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Field
                name={`${field}.addressLine2`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.propertyName} />}
                placeholder={intl.formatMessage(globalMessages.select)}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Field
                name={`${field}.addressLine3`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.area} />}
                placeholder={intl.formatMessage(globalMessages.select)}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Field
                name={`${field}.city`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.emirateCity} />}
                placeholder={intl.formatMessage(globalMessages.select)}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
              />
            </Grid>

            {/* <Grid item xs={12} md={8}>
              <Field
                name={`${field}.postOfficeBoxLine`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.poBox} />}
                placeholder={intl.formatMessage(globalMessages.select)}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
              />
            </Grid> */}

            <Grid item xs={12} md={8}>
              <Field
                name={`${field}.country`}
                component={FormSelect}
                options={
                  isInforce ? options.countryCode : options.addressGccCountry
                }
                fullWidth
                label={<FormattedMessage {...messages.country} />}
                placeholder={intl.formatMessage(globalMessages.select)}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
              />
            </Grid>

            <>
              <Grid item xs={12}>
                <hr className={classes.hr} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name={`${field}.postOfficeBoxLine`}
                  component={FormTextField}
                  fullWidth
                  label={<FormattedMessage {...messages.poBoxOptional} />}
                  placeholder={intl.formatMessage(globalMessages.select)}
                  disabled={
                    !isEmpty(formValues) &&
                    formValues[fieldName][index].selectedAddress !== 'different'
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  name={`${field}.postOfficeBoxCity`}
                  component={FormTextField}
                  fullWidth
                  label={<FormattedMessage {...messages.emirateCityOptional} />}
                  placeholder={intl.formatMessage(globalMessages.select)}
                  disabled={
                    !isEmpty(formValues) &&
                    formValues[fieldName][index].selectedAddress !== 'different'
                  }
                />
              </Grid>
            </>

            {/* <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
            <Grid item xs={12}>
              <FormSection
                name={`${field}.relationships`}
                component={renderRelationship}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
                options={options}
              />
            </Grid> */}
          </Grid>
        </Collapse>
      </CardSectionForm>
    );
  });
}

LifeInsuredPayor.propTypes = {
  options: PropTypes.object,
  formValues: PropTypes.object,
  error: PropTypes.bool,
  fieldName: PropTypes.string,
  lifeInsuredParties: PropTypes.array,
};

export default LifeInsuredPayor;

/**
 *
 * QuoteForm
 *
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Card,
  Box,
  Divider
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import numeral from 'numeral';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { fromJS } from 'immutable';
import { InjectedFormProps } from 'redux-form';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';
import { Cancel, CheckCircle, Create } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormSlider from 'components/FormSlider';
import { formatMonetaryValue } from 'helpers/math';
import FormSelect from 'components/FormSelect';
import { isEmpty } from 'lodash';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormPremium from 'components/FormPremium';
import FormNumberField from 'components/FormNumberField';
import FormTextField from 'components/FormTextField';
import globalMessages from 'containers/App/messages';
import {
  setGrowthRateAction,
  setSustainabilityYearsAction,
  setPolicyTermAction,
  resetProductConfigsAction,
  setManualPremiumAction,
} from 'containers/InforceIllutrationPage/actions';
import Benefits from './Benefits';
import WapBenefits from './WapBenefits';
import messages from '../../messages';
import validate from './validate';
import { lifeText } from '../../constants';
import { calculateAgeNextBirthdate } from 'containers/InforceIllutrationPage/helper';
import {
  makeSelectPolicyConfig,
  makeSelectInitProductConfig,
} from '../../selectors';
import {
  checkNextMonthWithInOneMonth,
  getAnniversaryDate,
  getAgeOfLife,
  getChosenInsured,
  calculateSustainYears,
  getPremiumRange,
  getFrequencyLabel,
  checkCanEditPremium,
  renderTerm,
  checkValidTerm,
  getMinValidTerm,
  getFrequencyOptions,
  getInitPremiumByFrequency,
  basisTypeDescription
} from '../../helper';
import { productCodeCheck } from 'helpers/constants';

const HeaderContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding: 40px 0;
    background-color: ${props => props.theme.palette.primary.dark};
    color: ${props => props.theme.palette.common.white};
    position: relative;
  }
`;

const BottomRow = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60px;
    padding: ${props => props.theme.spacing(1)}px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    ${props =>
    props.isLock &&
    `
    background-color: #F2F2F2;
  `}
  }
`;

const VerticalLine = styled.hr`
  width: 1px;
  height: 100%;
  position: absolute;
  left: 50%;
  margin: 0;
  border: 0;
  top: 0;
  transform: translateX(-50%);
  background-color: ${props => props.color};
`;

function QuoteForm({
  premium,
  options,
  policyConfig,
  currency,
  startDate,
  productConfig,
  term,
  growthRate,
  paymentFrequency,
  coverages,
  singlePremium,
  lifeAssured,
  isValidTerm,
  isEditPremium,
  handleSubmit,
  onSubmit,
  change,
  agentDetails,
  maxTerm,
  minTerm,
  errorCoverages,
  initProductConfig,
  sustainabilityYears,
  resetSustainabilityYears,
  setPolicyTerm,
  resetProductConfigs,
  setNextButtonStatus,
  setManualPremium,
  fundValues,
  illustrationValueDict,
  illustrationTableDict,
  input,
  offerCodeData,
  isWap
}) {
  const theme = useTheme();
  const MAX_GROWTH_RATE = policyConfig?.features?.growthRate?.ifi?.maxRate;
  const MIN_GROWTH_RATE = policyConfig?.features?.growthRate?.ifi?.minRate;
  const [open, setOpen] = useState(false);
  const [sspTwoOpen, setSspTwoOpen] = useState(false);
  const [sspThreeOpen, setSspThreeOpen] = useState(false);
  const [sspTwoZeroOpen, setSspTwoZeroOpen] = useState(false);
  const [sspThreeZeroOpen, setSspThreeZeroOpen] = useState(false);
  const isLockQuoteForm = false;
  const [isMerged, setIsMerged] = useState(false);
  const [isASPWOL, setIsASPWOL] = useState(true);
  const [isResetForm, setResetForm] = useState(false);
  const [frequencyOptions, setFrequencyOptions] = useState([]);
  const [lastPremium, setLastPremium] = useState(premium);
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });
  const [editType, setEditType] = useState(0);
  const [initialAge] = useState(initProductConfig.singlePremiumTerm === 3 ? sustainabilityYears : null);

  const isSingePaymentFrequency = productConfig.paymentFrequency === 'Single';
  const isFutu5 = productConfig.productCode === 'FUTU5';
  const agencyNumber = agentDetails?.agentNumber;
  policyConfig.agencyNumber = (agencyNumber);

  if (productConfig.additionalSinglePremium != 0) {
    productConfig.mergeASP = productConfig.additionalSinglePremium;
    change('mergeASP', productConfig.additionalSinglePremium);
  }

  const canEditPremium =
    (productConfig.singlePremiumTerm >= 0) && isASPWOL && (productConfig.regularPremiumSliceLength === 0)
      ? true
      : isValidTerm && checkCanEditPremium(productConfig, initProductConfig)

  const canDecreasePremium =
    (productConfig.singlePremiumTerm >= 0) && (productConfig.regularPremiumSliceLength === 0)
      ? true
      : isValidTerm && checkCanEditPremium(productConfig, initProductConfig)

  const termDisplay = renderTerm(term);

  const isValidAnniversaryDate = checkNextMonthWithInOneMonth(
    getAnniversaryDate(startDate),
  );
  const chosenInsured = getChosenInsured(lifeAssured);
  const ageOfLife = getAgeOfLife(
    lifeAssured.lifeBasis,
    lifeAssured,
    chosenInsured,
    startDate,
  );

  let isCoveragesError = false;
  if (!isEmpty(errorCoverages)) {
    errorCoverages?.coverages &&
      errorCoverages?.coverages.map(warningBenefit => {
        if (!isEmpty(warningBenefit)) {
          isCoveragesError = true;
        }
      });
  }
  let coverageTerm = 0;
  const lifeBasis = lifeAssured.lifeBasis;

  const Life1Age = calculateAgeNextBirthdate(
    productConfig.startDate,
    lifeAssured?.insuredOne?.dateOfBirth,
  );

  const Life2Age = calculateAgeNextBirthdate(
    productConfig.startDate,
    lifeAssured?.insuredTwo?.dateOfBirth,
  );

  if (lifeBasis === 'SL' || isNaN(Life2Age)) {
    //get the min value between 2 ages
    coverageTerm = 100 - Life1Age;
  } else {
    coverageTerm = 100 - Math.min(Life1Age, Life2Age);
  }
  const refreshEditQuote = () => {
    setResetForm(true);
    setNextButtonStatus(false);
    resetProductConfigs(initProductConfig);
    if (initProductConfig.singlePremiumTerm === 3) {
      resetSustainabilityYears(initialAge);
    } else {
      resetSustainabilityYears(null);
    }
    setManualPremium(false);
    setIsMerged(false);
    setSspTwoOpen(false);
    setSspThreeOpen(false);
    setSspTwoZeroOpen(false);
    setSspThreeZeroOpen(false);
    setFrequencyOptions(
      getFrequencyOptions(
        true,
        initProductConfig.premium,
        policyConfig?.features?.currencyPremiumSize,
        currency,
      ),
    );
    change('isEditTerm', false);
    change('isEditPremium', false);
    change('term', initProductConfig.term);
    change('premium', initProductConfig.premium);
    change('coverages', fromJS(initProductConfig.coverages));
    change('paymentFrequency', initProductConfig.paymentFrequency);
    change('growthRate', initProductConfig.growthRate);
    change('singlePremium', initProductConfig.singlePremium);
    change('singlePremiumTerm', initProductConfig.singlePremiumTerm);
    change('policyCoverTerm', initProductConfig.policyCoverTerm);
    change('isMerged', false);
    change('mergeSustainability', false);
    change('policyWOL', initProductConfig.policyWOL);
    productConfig.isMerged = false;
    productConfig.mergeSustainability = false;
  };

  useEffect(() => {
    setPolicyTerm(sustainabilityYears);
  }, [sustainabilityYears]);

  useEffect(() => {
    if (premium !== initProductConfig.premium) {
      setFrequencyOptions(getFrequencyOptions());
    } else {
      setFrequencyOptions(
        getFrequencyOptions(
          true,
          premium,
          policyConfig?.features?.currencyPremiumSize,
          currency,
        ),
      );
    }
  }, [currency]);

  const premiumRange = getPremiumRange(
    policyConfig,
    paymentFrequency,
    currency,
  );

  function checkSinglePreimum() {
    if (productConfig.singlePremiumTerm > 0) {
      return true
    } else {
      return false
    }
  }

  function checkAge() {
    const age = calculateSustainYears(sustainabilityYears, ageOfLife, initialAge);
    if (age === 'Whole of life') {
      productConfig.policyTerm = 'WholeOfLife'
      change('policyTerm', 'WholeOfLife')
    }
    return age;
  }

  const frequencyLabel = getFrequencyLabel(paymentFrequency);


  let myGrowthRateNode = `${numeral(productConfig.growthRate / 100).value()}`;
  myGrowthRateNode = numeral(myGrowthRateNode).format('0.0000');
  myGrowthRateNode = parseFloat(myGrowthRateNode.toString());
  const myBiTableValues = illustrationTableDict[myGrowthRateNode];
  console.log(myBiTableValues)
  const renderRows = () => {
    let arrayOfNumbers = [];
    if (!isEmpty(myBiTableValues)) {
      const biTablekeys = Object.keys(myBiTableValues);
      /* keys.forEach((key, index) => {
         console.log(`${key}: ${courses[key]}`);
       });*/
      //push all keys to array
      biTablekeys?.forEach((key, index) => {
        if (parseInt(key) <= coverageTerm) {
          arrayOfNumbers.push(parseInt(key));
        }
      });

      //sort them accordingly
      arrayOfNumbers?.sort(function (a, b) {
        return a - b;
      });

      //filter the items by 5
      let filteredNum = arrayOfNumbers?.filter(num => {
        if (num % 5 === 0 || arrayOfNumbers.length === num) {
          return num;
        }
      });

      //render the items
      return filteredNum?.map(num2 => (
        <WapBenefits
          formValues={myBiTableValues[`${num2}.0`]}
          productConfig={productConfig}
          years={parseInt(num2)}
        />
      ));
    }
  };



  return (
    <>
      {isWap === false ?
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>

            <HeaderContent container theme={theme}>
              <Grid container style={{ paddingLeft: '20px' }}>
                <Grid item xs={6} spacing={1}>
                  <Grid item style={{ paddingBottom: '5px' }}>
                    <Typography component="span" variant="h3">
                      {checkSinglePreimum()
                        ? <FormattedMessage {...messages.premiumPaid} />
                        : <FormattedMessage {...messages.premium} />}
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingBottom: '10px' }}></Grid>

                  {checkSinglePreimum()
                    ? <Grid item>
                      <span
                        id="primaryPremiumAmount"
                        style={{
                          color: 'inherit',
                          fontSize: '2.6rem',
                          lineHeight: 1,
                          fontWeight: 700,
                        }}
                      >
                        {currency} {formatMonetaryValue(productConfig.sspSliceOne, true)}{' '}
                      </span>
                      <Typography
                        color="inherit"
                        variant="body1"
                        component="span"
                        style={{ color: '#9B9A9B' }}
                      >
                        1st Year
                      </Typography>
                      <Grid item style={{ paddingBottom: '10px' }}></Grid>
                    </Grid>
                    : productConfig.regularPremiumSliceLength === 0
                      ? <Grid item>
                        <span
                          id="primaryPremiumAmount"
                          style={{
                            color: 'inherit',
                            fontSize: '2.6rem',
                            lineHeight: 1,
                            fontWeight: 700,
                          }}
                        >
                          {currency} {formatMonetaryValue(premium, true)}{' '}
                        </span>
                        <Typography
                          color="inherit"
                          variant="body1"
                          component="span"
                          style={{ color: '#9B9A9B' }}
                        >
                          {frequencyLabel}
                        </Typography>
                        <Grid item style={{ paddingBottom: '15px' }}></Grid>
                        {productConfig.additionalSinglePremium != 0
                          ? <Grid>
                            <Grid item style={{ paddingBottom: '5px' }}>
                              <Typography component="span" variant="h3">
                                <FormattedMessage {...messages.ASP} />
                              </Typography>
                            </Grid>
                            <Grid item style={{ paddingBottom: '10px' }}></Grid>
                            <Grid item>
                              <span
                                id="primaryPremiumAmount"
                                style={{
                                  color: 'inherit',
                                  fontSize: '2.6rem',
                                  lineHeight: 1,
                                  fontWeight: 700,
                                }}
                              >
                                {currency} {formatMonetaryValue(productConfig.additionalSinglePremium, true)} {' '}
                              </span>
                            </Grid>
                          </Grid>
                          : productConfig.additionalSinglePremium === 'Sufficient Premium'
                            ? <Grid>
                              <Grid item style={{ paddingBottom: '5px' }}>
                                <Typography component="span" variant="h3">
                                  <FormattedMessage {...messages.ASP} />
                                </Typography>
                              </Grid>
                              <Grid item style={{ paddingBottom: '10px' }}></Grid>
                              <Grid item>
                                <span
                                  id="primaryPremiumAmount"
                                  style={{
                                    color: 'inherit',
                                    fontSize: '2.6rem',
                                    lineHeight: 1,
                                    fontWeight: 700,
                                  }}
                                >
                                  {currency} {formatMonetaryValue(0, true)} {' '}
                                </span>
                              </Grid>
                            </Grid>
                            : <Grid></Grid>
                        }
                      </Grid>
                      : <Grid item>
                        <span
                          id="primaryPremiumAmount"
                          style={{
                            color: 'inherit',
                            fontSize: '2.6rem',
                            lineHeight: 1,
                            fontWeight: 700,
                          }}
                        >
                          {currency} {formatMonetaryValue(premium, true)}{' '}
                        </span>
                        <Typography
                          color="inherit"
                          variant="body1"
                          component="span"
                          style={{ color: '#9B9A9B' }}
                        >
                          {frequencyLabel}
                        </Typography>

                        <IconButton
                          disabled={!canEditPremium}
                          id="edit"
                          onClick={() => canEditPremium && setOpen(!open)}
                        >
                          <Create
                            style={{
                              color: canEditPremium
                                ? theme.palette.success.main
                                : theme.palette.grey[400],
                            }}
                          />
                        </IconButton>
                      </Grid>
                  }

                  {productConfig.sspSliceTwo !== 0
                    ? <Grid item>
                      <span
                        id="primaryPremiumAmount"
                        style={{
                          color: 'inherit',
                          fontSize: '2.6rem',
                          lineHeight: 1,
                          fontWeight: 700,
                        }}
                      >
                        {currency} {formatMonetaryValue(productConfig.sspSliceTwo, true)}{' '}
                      </span>
                      <Typography
                        color="inherit"
                        variant="body1"
                        component="span"
                        style={{ color: '#9B9A9B' }}
                      >
                        2nd Year
                      </Typography>
                      <Grid item style={{ paddingBottom: '10px' }}></Grid>
                    </Grid>
                    : <Grid></Grid>
                  }

                  {productConfig.sspSliceThree !== 0
                    ? <Grid item>
                      <span
                        id="primaryPremiumAmount"
                        style={{
                          color: 'inherit',
                          fontSize: '2.6rem',
                          lineHeight: 1,
                          fontWeight: 700,
                        }}
                      >
                        {currency} {formatMonetaryValue(productConfig.sspSliceThree, true)}{' '}
                      </span>
                      <Typography
                        color="inherit"
                        variant="body1"
                        component="span"
                        style={{ color: '#9B9A9B' }}
                      >
                        3rd Year
                      </Typography>
                      <Grid item style={{ paddingBottom: '10px' }}></Grid>
                    </Grid>
                    : <Grid></Grid>
                  }
                </Grid>

                <VerticalLine color="#4066B" />
                <Grid item xs={6} spacing={1}>
                  <Grid item style={{ paddingBottom: '5px' }}>
                    <Typography component="span" variant="h3">
                      <FormattedMessage {...globalMessages.age} />
                    </Typography>
                  </Grid>
                  <Grid item>
                    <span
                      id="primaryPremiumAmount"
                      style={{
                        color: 'inherit',
                        fontSize: '2.6rem',
                        lineHeight: 1,
                        fontWeight: 700,
                      }}
                    >
                      {
                        `${checkAge()} `}
                    </span>
                    <Typography
                      color="inherit"
                      variant="body1"
                      component="span"
                      style={{ color: '#9B9A9B' }}
                    >
                      {lifeAssured.lifeBasis && (
                        <FormattedMessage
                          {...messages[lifeText[lifeAssured.lifeBasis]]}
                        />
                      )}
                    </Typography>
                    <RefreshIcon
                      style={{
                        color: theme.palette.success.light,
                        cursor: 'pointer',
                      }}
                      onClick={() => refreshEditQuote()}
                    ></RefreshIcon>
                  </Grid>
                </Grid>

                {checkSinglePreimum()
                  ? <Grid item xs={6} spacing={1}>
                    <Grid item style={{ paddingBottom: '15px' }}></Grid>
                    <Grid item style={{ paddingBottom: '5px' }}>
                      <Typography component="span" variant="h3" style={{ paddingRight: '50px' }}>
                        <FormattedMessage {...messages.premiumDue} />
                      </Typography>
                      {
                        initProductConfig.singlePremiumTerm === 3 && productConfig.sspSliceThree === 0 && productConfig.sspSliceTwo === 0
                          ? isMerged
                            ? <Button
                              style={{
                                backgroundColor: '#4066B3',
                                color: 'white',
                                padding: 6,
                                borderRadius: 10,
                              }}
                              variant="contained"
                              onClick={async () => {
                                if (initProductConfig.singlePremiumTerm === 3) {
                                  setNextButtonStatus(false);
                                  setIsMerged(false);
                                  setSspTwoZeroOpen(false);
                                  setSspThreeZeroOpen(false);
                                  resetSustainabilityYears(null);
                                  resetProductConfigs(initProductConfig);
                                  productConfig.isMerged = false;
                                  productConfig.singlePremiumTerm = 3;
                                  productConfig.mergeSustainability = false;
                                  await change('singlePremium', initProductConfig.singlePremium);
                                  await change('premium', initProductConfig.premium);
                                  await change('singlePremiumTerm', initProductConfig.singlePremiumTerm);
                                  await change('isGetPremium', false);
                                  await change('isCalculateSustainability', false);
                                  await change('isMerged', false);
                                  await change('policyCoverTerm', initProductConfig.policyCoverTerm);
                                  await change('mergeSustainability', false);
                                  await change('policyWOL', initProductConfig.policyWOL);
                                }
                              }}
                            >
                              <FormattedMessage {...messages.unMergeButton} />
                            </Button>
                            : <Button
                              style={{
                                backgroundColor: '#4066B3',
                                color: 'white',
                                padding: 6,
                                borderRadius: 10,
                              }}
                              variant="contained"
                              onClick={async () => {
                                if (productConfig.singlePremiumTerm === 3) {
                                  productConfig.mergeSustainability = false;
                                  setIsMerged(true);
                                  setSspTwoOpen(false);
                                  if (!isCoveragesError) {
                                    setEditData({ editIndex: 0, editInsured: 0 });
                                    setEditType(0);
                                  }
                                  await change('singlePremium', 0);
                                  await change('isCalculateSustainability', false);
                                  await change('isMerged', true);
                                  await change('isGetPremium', true);
                                  await change('singlePremiumTerm', 2);
                                  await change('mergeSustainability', false);
                                  const policyAge = checkAge()
                                  if (policyAge === 'Whole of life' && initProductConfig.singlePremiumTerm === 3) {
                                    await change('policyWOL', true);
                                  }
                                  handleSubmit();
                                }
                              }}
                            >
                              <FormattedMessage {...messages.mergeButton} />
                            </Button>
                          : <Grid></Grid>
                      }
                    </Grid>
                    {
                      isMerged
                        ? <Grid item style={{ paddingBottom: '10px' }}></Grid>
                        : <Grid item style={{ paddingBottom: '5px' }}></Grid>
                    }
                    {productConfig.sspSliceTwo === 0
                      ? isMerged
                        ? <Grid item>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          >
                            {currency} {formatMonetaryValue('0', true)}{' '}
                          </span>
                        </Grid>
                        : <Grid item>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          >
                            {currency} {formatMonetaryValue(premium > productConfig.exisitingPremium ? productConfig.exisitingPremium : premium, true)}{' '}
                          </span>
                          <Typography
                            color="inherit"
                            variant="body1"
                            component="span"
                            style={{ color: '#9B9A9B' }}
                          >
                            {'2nd Year'}
                          </Typography>

                          <IconButton
                            id="edit"
                            disabled={!canDecreasePremium}
                            onClick={() => canDecreasePremium && setSspTwoOpen(!sspTwoOpen)}
                          >
                            <Create
                              style={{
                                color: canDecreasePremium
                                  ? theme.palette.success.main
                                  : theme.palette.grey[400],
                              }}
                            />
                          </IconButton>

                          {/* ########## START  :: EDIT PREMIUM ########## */}
                          <Collapse in={sspTwoOpen}>
                            <Box pt={1}>
                              <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Field
                                    id='premium'
                                    name='premium'
                                    component={FormPremium}
                                    min={checkSinglePreimum() ? initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium) / (productConfig.singlePremiumTerm - 1) : premiumRange.min}
                                    max={checkSinglePreimum() ? productConfig.premium : premiumRange.max}
                                    label=""
                                    diff={100}
                                    isPrimary
                                    type="text"
                                    singleSplitPremium={checkSinglePreimum()}
                                    onBlur={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onButtonActionClick={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onChange={(e, newVal, previousValue) => {
                                      if (
                                        !isResetForm &&
                                        Number(newVal) !== Number(previousValue)
                                      ) {
                                        if (Number(newVal) === 0) {
                                          setSspTwoZeroOpen(true);
                                        } else {
                                          setSspTwoZeroOpen(false);
                                        }
                                        change('isEditPremium', true);
                                        setFrequencyOptions(
                                          getFrequencyOptions(
                                            true,
                                            Number(newVal),
                                            policyConfig?.features?.currencyPremiumSize,
                                            currency,
                                          ),
                                        );
                                      } else {
                                        setResetForm(false);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                          {/* ########## END    :: EDIT PREMIUM ########## */}
                          <Collapse in={sspTwoZeroOpen}>
                            <Box pt={0}>
                              <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item xs>
                                  <Typography
                                    style={{ fontSize: '1.18rem', color: theme.palette.error.main, paddingLeft: '58px' }}
                                  >
                                    <FormattedMessage
                                      {...globalMessages.minLimit}
                                      values={{ value: formatMonetaryValue(initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium) / (productConfig.singlePremiumTerm - 1), true) }
                                      }
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                        </Grid>
                      : productConfig.sspSliceThree === 0
                        ? <Grid></Grid>
                        : <Grid>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          >
                            {currency} {formatMonetaryValue('0', true)}{' '}
                          </span>
                        </Grid>}

                    <Grid item style={{ paddingBottom: '10px' }}>
                    </Grid>
                    {
                      isMerged || initProductConfig.singlePremiumTerm === 2 || productConfig.sspSliceThree != 0
                        ? <Grid item style={{ paddingBottom: '10px' }}></Grid>
                        : <Grid item>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          >
                            {currency} {formatMonetaryValue(premium > productConfig.exisitingPremium ? productConfig.exisitingPremium : premium, true)}{' '}
                          </span>
                          <Typography
                            color="inherit"
                            variant="body1"
                            component="span"
                            style={{ color: '#9B9A9B' }}
                          >
                            {'3rd Year'}
                          </Typography>
                          {
                            productConfig.sspSliceTwo === 0
                              ? <Grid></Grid>
                              : <IconButton
                                id="edit"
                                disabled={!canDecreasePremium}
                                onClick={() => canDecreasePremium && setSspThreeOpen(!sspThreeOpen)}
                              >
                                <Create
                                  style={{
                                    color: canDecreasePremium
                                      ? theme.palette.success.main
                                      : theme.palette.grey[400],
                                  }}
                                />
                              </IconButton>
                          }
                          {/* ########## START  :: EDIT PREMIUM ########## */}
                          <Collapse in={sspThreeOpen}>
                            <Box pt={1}>
                              <Grid container direction="row" spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Field
                                    id='premium'//{premium < productConfig.exisitingPremium ? "premium" : "singlePremium"}
                                    name='premium'//{premium < productConfig.exisitingPremium ? "premium" : "singlePremium"}
                                    component={FormPremium}
                                    min={checkSinglePreimum() ? initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium) / (productConfig.singlePremiumTerm - 1) : premiumRange.min}
                                    max={checkSinglePreimum() ? productConfig.premium : premiumRange.max}
                                    label=""
                                    diff={100}
                                    isPrimary
                                    type="text"
                                    singleSplitPremium={checkSinglePreimum()}
                                    onBlur={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onButtonActionClick={() => {
                                      setNextButtonStatus(false);
                                      setManualPremium(true);
                                    }}
                                    onChange={(e, newVal, previousValue) => {
                                      if (
                                        !isResetForm &&
                                        Number(newVal) !== Number(previousValue)
                                      ) {
                                        if (Number(newVal) === 0) {
                                          setSspThreeZeroOpen(true);
                                        } else {
                                          setSspThreeZeroOpen(false);
                                        }
                                        change('isEditPremium', true);
                                        setFrequencyOptions(
                                          getFrequencyOptions(
                                            true,
                                            Number(newVal),
                                            policyConfig?.features?.currencyPremiumSize,
                                            currency,
                                          ),
                                        );
                                      } else {
                                        setResetForm(false);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                          <Collapse in={sspThreeZeroOpen}>
                            <Box pt={0}>
                              <Grid container direction="row" spacing={1} alignItems="center">
                                <Grid item xs>
                                  <Typography
                                    style={{ fontSize: '1.18rem', color: theme.palette.error.main, paddingLeft: '58px' }}
                                  >
                                    <FormattedMessage
                                      {...globalMessages.minLimit}
                                      values={{ value: formatMonetaryValue(initProductConfig.premium >= premiumRange.min ? 0 : (premiumRange.min - initProductConfig.premium) / (productConfig.singlePremiumTerm - 1), true) }
                                      }
                                    />
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Box>
                          </Collapse>
                          {/* ########## END    :: EDIT PREMIUM ########## */}
                          <Grid item style={{ paddingBottom: '15px' }}></Grid>
                        </Grid>
                    }
                    {productConfig.additionalSinglePremium != 0
                      ? <Grid>
                        <Grid item style={{ paddingBottom: '5px' }}>
                          <Typography component="span" variant="h3">
                            <FormattedMessage {...messages.ASP} />
                          </Typography>
                        </Grid>
                        <Grid item style={{ paddingBottom: '10px' }}></Grid>
                        <Grid item>
                          <span
                            id="primaryPremiumAmount"
                            style={{
                              color: 'inherit',
                              fontSize: '2.6rem',
                              lineHeight: 1,
                              fontWeight: 700,
                            }}
                          >
                            {currency} {formatMonetaryValue(productConfig.additionalSinglePremium, true)} {' '}
                          </span>
                        </Grid>
                      </Grid>
                      : productConfig.additionalSinglePremium === 'Sufficient Premium'
                        ? <Grid>
                          <Grid item style={{ paddingBottom: '5px' }}>
                            <Typography component="span" variant="h3">
                              <FormattedMessage {...messages.ASP} />
                            </Typography>
                          </Grid>
                          <Grid item style={{ paddingBottom: '10px' }}></Grid>
                          <Grid item>
                            <span
                              id="primaryPremiumAmount"
                              style={{
                                color: 'inherit',
                                fontSize: '2.6rem',
                                lineHeight: 1,
                                fontWeight: 700,
                              }}
                            >
                              {currency} {formatMonetaryValue(0, true)} {' '}
                            </span>
                          </Grid>
                        </Grid>
                        : isMerged
                          ? <Grid>
                            <Grid item style={{ paddingBottom: '5px' }}>
                              <Typography component="span" variant="h3">
                                <FormattedMessage {...messages.ASP} />
                              </Typography>
                            </Grid>
                            <Grid item style={{ paddingBottom: '10px' }}></Grid>
                            <Grid item>
                              <span
                                id="primaryPremiumAmount"
                                style={{
                                  color: 'inherit',
                                  fontSize: '2.6rem',
                                  lineHeight: 1,
                                  fontWeight: 700,
                                }}
                              >
                                {currency} {formatMonetaryValue(productConfig.mergeASP, true)} {' '}
                              </span>
                            </Grid>
                          </Grid>
                          : <Grid></Grid>
                    }
                  </Grid>
                  : <Grid></Grid>}
              </Grid>
              {/* ########## START  :: EDIT PREMIUM ########## */}
              <Collapse in={open}>
                <Box pt={1}>
                  <Grid container direction="row" spacing={2} alignItems="center">
                    <Grid item xs>
                      <Field
                        id='premium'
                        name='premium'
                        component={FormPremium}
                        min={premiumRange.min}
                        max={checkSinglePreimum() ? productConfig.premium : premiumRange.max}
                        label=""
                        diff={100}
                        isPrimary
                        type="text"
                        onBlur={() => {
                          setNextButtonStatus(false);
                          setManualPremium(true);
                        }}
                        onButtonActionClick={() => {
                          setNextButtonStatus(false);
                          setManualPremium(true);
                        }}
                        onChange={(e, newVal, previousValue) => {
                          if (
                            !isResetForm &&
                            Number(newVal) !== Number(previousValue)
                          ) {
                            change('isEditPremium', true);
                            setFrequencyOptions(
                              getFrequencyOptions(
                                true,
                                Number(newVal),
                                policyConfig?.features?.currencyPremiumSize,
                                currency,
                              ),
                            );
                          } else {
                            setResetForm(false);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
              {/* ########## END    :: EDIT PREMIUM ########## */}
            </HeaderContent>
            <Grid container style={{ position: 'relative' }}>
              <Grid item xs={6}>
                <Field component="input" type="hidden" name="isGetPremium" />
                <Field
                  component="input"
                  type="hidden"
                  name="isCalculateSustainability"
                />
                <Button
                  disabled={!(canEditPremium && isASPWOL) || (premium < productConfig.exisitingPremium && premium > 0 && productConfig.isMerged === false)}
                  style={{
                    backgroundColor: (canEditPremium && isASPWOL) && !(premium < productConfig.exisitingPremium && premium > 0 && productConfig.isMerged === false) ? '#4066B3' : '#BFBBAA',
                    color: 'white',
                    width: '100%',
                    padding: '20px 0',
                  }}
                  variant="contained"
                  onClick={async () => {
                    await change('isGetPremium', true);
                    await change('isCalculateSustainability', false);
                    await change('policyCoverTerm', 'WholeOfLife');
                    await change('mergeSustainability', false);
                    productConfig.mergeSustainability = false;
                    resetSustainabilityYears('WholeOfLife');
                    setSspThreeOpen(false);
                    setSspTwoOpen(false);
                    setOpen(false);
                    setSspTwoZeroOpen(false);
                    setSspThreeZeroOpen(false);
                    handleSubmit();
                    if (canEditPremium) {
                      if (premium !== lastPremium) {
                        setOpen(false);
                        setLastPremium(premium);
                      }
                    }

                    if (!isCoveragesError) {
                      setEditData({ editIndex: 0, editInsured: 0 });
                      setEditType(0);
                    }
                  }}
                >
                  {
                    checkSinglePreimum() || (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)
                      ? <FormattedMessage {...messages.getASPBaseWOL} />
                      : <FormattedMessage {...messages.getPremiumBaseWOL} />
                  }
                </Button>
              </Grid>
              <VerticalLine color="#023694" />
              <Grid item xs={6}>
                <Button
                  style={{
                    backgroundColor: '#4066B3',
                    color: 'white',
                    width: '100%',
                    padding: '20px 0',
                  }}
                  variant="contained"
                  onClick={async e => {
                    await change('isGetPremium', false);
                    await change('isCalculateSustainability', true);
                    await change('mergeSustainability', true);
                    productConfig.mergeSustainability = true;
                    await change('policyCoverTerm', 'CustomPremium');
                    handleSubmit();
                    setSspThreeOpen(false);
                    setSspTwoOpen(false);
                    setOpen(false);
                    setSspTwoZeroOpen(false);
                    setSspThreeZeroOpen(false);
                    if (!isCoveragesError) {
                      setEditData({ editIndex: 0, editInsured: 0 });
                      setEditType(0);
                    }
                  }}
                >
                  <FormattedMessage {...messages.calculateSustain} />
                </Button>
              </Grid>
            </Grid>

            {/*= ===== Premium Payment Term ====== */}
            <BottomRow
              container
              isLock={isLockQuoteForm}
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle
                      style={
                        isLockQuoteForm
                          ? { color: theme.palette.grey[400] }
                          : { color: theme.palette.success.main }
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '900',
                      color: isLockQuoteForm
                        ? theme.palette.grey[400]
                        : theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.premiumTerm} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    spacing={1}
                    justify="space-between"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: '900',
                          color: isLockQuoteForm
                            ? theme.palette.grey[400]
                            : theme.palette.common.black,
                        }}
                      >
                        {checkSinglePreimum()
                          ? `${productConfig.singlePremiumTerm}  Years`
                          : productConfig.singlePremiumTerm === 1
                            ? `${productConfig.singlePremiumTerm}  Year`
                            : termDisplay
                        }
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {!isSingePaymentFrequency && !isFutu5 && (
                        <IconButton onClick={() => setEditType(1)}>
                          <img
                            style={{ height: '1.6rem' }}
                            src={IconPencilBlue}
                            alt=""
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={editType === 1}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={7}>
                    <Field
                      shrink
                      name="term"
                      fullWidth
                      disabled={!Number.isInteger(Number(term))}
                      component={
                        Number.isInteger(Number(term))
                          ? FormNumberField
                          : FormTextField
                      }
                      type="text"
                      onChange={(e, newVal, previousValue) => {
                        setNextButtonStatus(false);
                        if (
                          Number(newVal) >= minTerm &&
                          Number(newVal) <= maxTerm &&
                          !isEditPremium
                        ) {
                          change(
                            'premium',
                            getInitPremiumByFrequency(
                              initProductConfig.regularPremiumSlices,
                              initProductConfig.paymentFrequency,
                            ),
                          );
                        }
                        if (
                          !isResetForm &&
                          Number(newVal) != Number(previousValue)
                        ) {
                          change('isEditTerm', true);
                        } else {
                          setResetForm(false);
                        }
                      }}
                      margin="normal"
                      variant="standard"
                    />
                  </Grid>

                  <Grid item xs={2}></Grid>
                </Grid>
              </Collapse>
            </BottomRow>

            {/*= ===== Premium Payment Frequency ====== */}
            <BottomRow
              container
              isLock={isLockQuoteForm}
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle
                      style={
                        isLockQuoteForm
                          ? { color: theme.palette.grey[400] }
                          : { color: theme.palette.success.main }
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '900',
                      color: isLockQuoteForm
                        ? theme.palette.grey[400]
                        : theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.premiumPaymentFrequency} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: '900',
                          color: isLockQuoteForm
                            ? theme.palette.grey[400]
                            : theme.palette.common.black,
                        }}
                      >
                        {paymentFrequency}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      {isValidAnniversaryDate && productConfig.regularPremiumSliceLength != 0 && (
                        <IconButton onClick={() => setEditType(2)}>
                          <img
                            style={{ height: '1.6rem' }}
                            src={IconPencilBlue}
                            alt=""
                          />
                        </IconButton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={editType === 2}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={7}>
                    <Field
                      shrink
                      name="paymentFrequency"
                      fullWidth
                      component={FormSelect}
                      type="text"
                      options={frequencyOptions}
                      margin="normal"
                      variant="standard"
                      onChange={() => {
                        setNextButtonStatus(false);
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}></Grid>
                </Grid>
              </Collapse>
            </BottomRow>

            {/*= ===== Assumed Growth rate ====== */}
            <BottomRow
              isLock={isLockQuoteForm}
              container
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle
                      style={
                        isLockQuoteForm
                          ? { color: theme.palette.grey[400] }
                          : { color: theme.palette.success.main }
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '900',
                      color: isLockQuoteForm
                        ? theme.palette.grey[400]
                        : theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.assumedGrowthRate} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: '900',
                          color: isLockQuoteForm
                            ? theme.palette.grey[400]
                            : theme.palette.common.black,
                        }}
                      >
                        {`${growthRate}%`}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <IconButton onClick={() => setEditType(3)}>
                        <img
                          style={{ height: '1.6rem' }}
                          src={IconPencilBlue}
                          alt=""
                        />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={editType === 3}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Grid item xs={7}>
                    <Field
                      name="growthRate"
                      component={FormSlider}
                      min={MIN_GROWTH_RATE}
                      max={MAX_GROWTH_RATE}
                      diff={0.5}
                      isPrimary={false}
                      type="text"
                      onChange={() => {
                        setNextButtonStatus(false);
                      }}
                    />
                  </Grid>

                  <Grid item xs={2}>
                    <Field
                      name="growthRate"
                      fullWidth
                      component={FormNumberField}
                      type="text"
                      color="primary"
                      margin="normal"
                      variant="standard"
                      onChange={() => {
                        setNextButtonStatus(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </BottomRow>

            {/* ===== Additional Single Premium ===== */}

            <BottomRow
              container
              isLock={isLockQuoteForm}
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    {singlePremium > 0 || productConfig.additionalSinglePremium > 0 ? (
                      <CheckCircle
                        style={{ color: theme.palette.success.main }}
                      />
                    ) : (
                      <Cancel style={{ color: theme.palette.grey[400] }} />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: '900',
                      color: isLockQuoteForm
                        ? theme.palette.grey[400]
                        : theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.additionalSinglePremium} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="body1"
                        style={{
                          fontWeight: '900',
                          color: isLockQuoteForm
                            ? theme.palette.grey[400]
                            : theme.palette.common.black,
                        }}
                      >
                        {
                          (checkSinglePreimum() || (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0))
                            ? productConfig.singlePremium === 0 || productConfig.singlePremium === '0'
                              ? formatMonetaryValue(productConfig.additionalSinglePremium, true)
                              : formatMonetaryValue(productConfig.singlePremium, false)
                            : formatMonetaryValue(singlePremium, false)
                        }
                      </Typography>
                    </Grid>
                    {
                      (checkSinglePreimum() && productConfig.additionalSinglePremium === 0)
                        ? <Grid item xs={4}>
                          <IconButton onClick={() => setEditType(4)}>
                            <img
                              style={{ height: '1.6rem' }}
                              src={IconPencilBlue}
                              alt=""
                            />
                          </IconButton>
                        </Grid>
                        : (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0)
                          ? productConfig.additionalSinglePremium === 0
                            ? <Grid item xs={4}>
                              <IconButton onClick={() => setEditType(4)}>
                                <img
                                  style={{ height: '1.6rem' }}
                                  src={IconPencilBlue}
                                  alt=""
                                />
                              </IconButton>
                            </Grid>
                            : <Grid item xs={4}></Grid>
                          : checkSinglePreimum()
                            ? <Grid item xs={4}></Grid>
                            : <Grid item xs={4}>
                              <IconButton onClick={() => setEditType(4)}>
                                <img
                                  style={{ height: '1.6rem' }}
                                  src={IconPencilBlue}
                                  alt=""
                                />
                              </IconButton>
                            </Grid>
                    }
                  </Grid>
                </Grid>
              </Grid>

              <Collapse in={editType === 4}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                >
                  <Grid item xs={4}>
                    <Field
                      shrink
                      name="singlePremium"
                      fullWidth
                      component={FormNumberField}
                      type="text"
                      margin="normal"
                      variant="standard"
                      onChange={() => {
                        setNextButtonStatus(false);
                        if (singlePremium > 0 && (checkSinglePreimum() || (productConfig.singlePremiumTerm === 0 && productConfig.regularPremiumSliceLength === 0))) {
                          setIsASPWOL(false);
                        } else {
                          setIsASPWOL(true);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </BottomRow>
            <Grid
              container
              direction="row"
              spacing={1}
              justify="space-between"
              alignItems="center"
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              <Grid item xs />
              <Grid item xs={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredOne.firstName}
                </Typography>
              </Grid>
              {lifeAssured.isInsuredTwo ? (
                <Grid item xs={3}>
                  <Typography
                    color="primary"
                    variant="h3"
                    style={{ marginLeft: theme.spacing(0.5) }}
                  >
                    {lifeAssured.insuredTwo.firstName}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>

            {coverages.length > 0 ? (
              <FieldArray
                name="coverages"
                component={Benefits}
                theme={theme}
                coverages={coverages}
                inactive={open}
                isResetForm={isResetForm}
                isCoveragesError={isCoveragesError}
                policyConfig={policyConfig}
                currency={currency}
                options={options}
                lifeBasis={lifeAssured.lifeBasis}
                lifeAssured={lifeAssured}
                startDate={startDate}
                change={change}
                editData={editData}
                changeEditData={val => {
                  setEditData(val);
                }}
                onCoverageChange={() => {
                  setNextButtonStatus(false);
                  setResetForm(false);
                }}
                isInsuredTwo={lifeAssured.isInsuredTwo}
              />
            ) : null}
          </Card>
        </form>
        :

        < form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-20px' }}>
          <Card
            style={{
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 0,
              width: 680,
            }}
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: '26px 12px',
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.premiumPaid} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: '26px 12px',
                  borderRight: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.policyTerm} />
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  padding: '26px 12px',
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                  textAlign: 'right',
                }}
              >
                <Typography
                  variant="h4"
                  margin="normal"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  <FormattedMessage {...messages.fundValues} />
                </Typography>
              </Grid>
            </Grid>

            {coverageTerm < 30 && (productCodeCheck(6, productConfig.productCode)) ? (
              renderRows()
            ) : !isEmpty(myBiTableValues) ? (
              <>
                {
                  (!isEmpty(myBiTableValues) && myBiTableValues['5.0'] !== undefined) ? (
                    <WapBenefits
                      formValues={myBiTableValues['5.0']}
                      productConfig={productConfig}
                      years={5}
                    />
                  ) : null}
                {(!isEmpty(myBiTableValues) && myBiTableValues['10.0'] !== undefined) ? (
                  <WapBenefits
                    formValues={myBiTableValues['10.0']}
                    productConfig={productConfig}
                    years={10}
                  />
                ) : null}
                {(!isEmpty(myBiTableValues) && myBiTableValues['15.0'] !== undefined) ? (
                  <WapBenefits
                    formValues={myBiTableValues['15.0']}
                    productConfig={productConfig}
                    years={15}
                  />
                ) : null}

                {(!isEmpty(myBiTableValues) && myBiTableValues['20.0'] !== undefined) ? (
                  <WapBenefits
                    formValues={myBiTableValues['20.0']}
                    productConfig={productConfig}
                    years={20}
                  />
                ) : null}
                {(!isEmpty(myBiTableValues) && myBiTableValues['25.0'] !== undefined) ? (
                  <WapBenefits
                    formValues={myBiTableValues['25.0']}
                    productConfig={productConfig}
                    years={25}
                  />
                ) : null}
                {(!isEmpty(myBiTableValues) && myBiTableValues['30.0'] !== undefined) ? (
                  <WapBenefits
                    formValues={myBiTableValues['30.0']}
                    productConfig={productConfig}
                    years={30}
                  />
                ) : null}{' '}
              </>
            ) : null}

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{ paddingBottom: theme.spacing(3) }}>
            </Grid>

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{ paddingRight: theme.spacing(2) }}
            >
              <Grid item>
                <Typography
                  component="span"
                  variant="h1"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '400',
                    fontSize: '2.2rem',
                  }}
                >
                  {productConfig.currency}
                </Typography>
              </Grid>
              <Grid item>
                <span
                  id="primaryPremiumAmount"
                  style={{
                    color: theme.palette.common.white,
                    fontSize: '6.6rem',
                    lineHeight: 1,
                    fontWeight: 700,
                  }}
                >
                  {!isEmpty(myBiTableValues)
                    ? formatMonetaryValue(
                      myBiTableValues[
                      `${(coverageTerm < 30 &&
                        isWap === true)
                        ? coverageTerm
                        : 30
                      }.0`
                      ]['Fund Value'],
                      false,
                    )
                    : 0}
                </span>
              </Grid>
            </Grid>

            <Divider
              style={{
                backgroundColor: theme.palette.info.main,
                margin: theme.spacing(2),
              }}
            />

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{
                paddingRight: theme.spacing(2),
                paddingBottom: theme.spacing(3),
              }}
            >
              <Typography
                variant="h4"
                style={{
                  color: '#B9BBB8',
                  // fontWeight: 700,
                  fontSize: '16px',
                }}
              >
                <FormattedMessage
                  {...messages.projectedFundValues}
                  values={{
                    value:
                      coverageTerm < 30 && (productCodeCheck(6, productConfig.productCode))
                        ? coverageTerm
                        : 30,
                  }}
                />
              </Typography>
            </Grid>
          </Card>
          {offerCodeData.discountMessage?.english && (
            <Box my={2}>
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                <FormattedMessage
                  {...messages.offerCodeValid}
                  values={{
                    value: offerCodeData.discountMessage?.english,
                  }}
                />
              </Typography>
            </Box>
          )}
        </form>
      }
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  lifeAssured: PropTypes.object,
  errorCoverages: PropTypes.object,
  options: PropTypes.object,
  policyConfig: PropTypes.object,
  onUpdateValues: PropTypes.func,
  startDate: PropTypes.string,
  growthRate: PropTypes.number,
  term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offerCode: PropTypes.string,
  currency: PropTypes.string,
  paymentFrequency: PropTypes.string,
  specialTermsFlag: PropTypes.bool,
  premium: PropTypes.number,
  formValues: PropTypes.object,
  product: PropTypes.object,
  coverages: PropTypes.array,
  offerCodeData: PropTypes.object,
  resetGrowthRate: PropTypes.func,
  resetSustainabilityYears: PropTypes.func,
  illustrationTableDict: PropTypes.object,
  illustrationValueDict: PropTypes.object,
};

const withForm = reduxForm({
  form: 'quoteForm',
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  touchOnChange: true,
  touchOnBlur: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');
const syncErrorsSelector = getFormSyncErrors('quoteForm');

const mapStateToProps = state => {
  const currency = selector(state, 'currency');
  const growthRate = selector(state, 'growthRate');
  const specialTermsFlag = selector(state, 'specialTermsFlag');
  const startDate = selector(state, 'startDate');
  const term = selector(state, 'term');
  const offerCode = selector(state, 'offerCode');
  const premium = selector(state, 'premium');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const singlePremium = selector(state, 'singlePremium');
  const coverages = selector(state, 'coverages');
  const isGetPremium = selector(state, 'isGetPremium');
  const isEditPremium = selector(state, 'isEditPremium');
  const formValues = getFormValues('quoteForm')(state);
  let errorCoverages = syncErrorsSelector(state, 'coverages');
  const policyConfig = makeSelectPolicyConfig()(state);
  const initProductConfig = makeSelectInitProductConfig()(state);
  const minTerm = getMinValidTerm(initProductConfig.term, startDate);
  const maxTerm = policyConfig?.features?.policyTerm.maxVanishingPremiumTerm;
  const isValidTerm = checkValidTerm(term, maxTerm, startDate);
  const illustrationTableDict = selector(state, 'illustrationTableDict');
  const illustrationValueDict = selector(state, 'illustrationValueDict');
  if (errorCoverages instanceof Map) {
    errorCoverages = errorCoverages.toJS();
  }
  return {
    premium,
    currency,
    term,
    offerCode,
    startDate,
    growthRate,
    paymentFrequency,
    specialTermsFlag,
    singlePremium,
    coverages: coverages?.toJS() || [],
    formValues: formValues?.toJS() || {},
    errorCoverages,
    isValidTerm,
    maxTerm,
    minTerm,
    isGetPremium,
    isEditPremium,
    illustrationTableDict: illustrationTableDict?.toJS() || {},
    illustrationValueDict: illustrationValueDict?.toJS() || {},
  };
};

const mapDispatchToProps = dispatch => ({
  resetSustainabilityYears: value =>
    dispatch(setSustainabilityYearsAction(value)),
  resetGrowthRate: number => dispatch(setGrowthRateAction(number)),
  setPolicyTerm: value => dispatch(setPolicyTermAction(value)),
  resetProductConfigs: data => dispatch(resetProductConfigsAction(data)),
  setManualPremium: value => dispatch(setManualPremiumAction(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);


export default withConnect;

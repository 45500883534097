/**
 *
 * FormSelect
 *
 */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Grid, MenuItem, Typography, Divider } from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { WrappedFieldProps } from 'redux-form';
import { convertIfImmutable } from 'helpers/lang';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';

import { find, map } from 'lodash/collection';
import { fixLeadingZero } from 'helpers/math';

import TextField from 'components/TextField';
import NumberField from 'components/NumberField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import messages from 'containers/ApplicationFormPage/messages';
import { isEmpty } from 'lodash';

const MIN = 1;
const MAX = 500;

const useStyles = makeStyles(theme => ({
  // root: {
  //   // width: 500,
  //   '& > * + *': {
  //     marginTop: theme.spacing(3),
  //   },
  // },
  numberFormat: {
    lineHeight: '2rem',
    marginTop: '2rem',
  },
  input: {
    borderRadius: '10px',
    width: '335px',
  },
  datePicker: {
    marginTop: '-.5rem',
    width: '335px',
  },
  textField: {
    // borderRadius: '8px',
    height: '4.8rem',
    width: '130px',
  },
  title: {
    lineHeight: '24px',
    fontWeight: 800,
    color: theme.palette.primary.dark,
  },
  title2: {
    fontWeight: 800,
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
}));

const FormDate = ({
  question,
  questions,
  section,
  input: { onChange, value, onBlur, ...inputProps },
  required,
  meta: { invalid, error, touched, warning },
  ...custom
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [formError, setFormError] = useState(false);
  const EXACT_DATE = 'EXACT_DATE';
  const MONTH_YEAR = 'MONTH_YEAR';
  const MONTH_SINCE_OCCURANCE = 'MONTHS_SINCE_OCCURRENCE';
  const RELATIVE_AGE = 'RELATIVE_AGE';
  const fieldValue = convertIfImmutable(value);
  const availableDateFormats = map(
    question.constraints.availableDateFormats,
    format => {
      if (format === EXACT_DATE) {
        return { value: EXACT_DATE, title: 'Exact Date', format: 'DD/MM/YYYY' };
      } else if (format === MONTH_YEAR) {
        return { value: MONTH_YEAR, title: 'Month Year', format: 'MM/YYYY' };
      } else if (format === MONTH_SINCE_OCCURANCE) {
        return {
          value: MONTH_SINCE_OCCURANCE,
          title: 'Month Since Occurrence',
          format: '',
        };
      } else if (format === RELATIVE_AGE) {
        return { value: RELATIVE_AGE, title: 'Relative age', format: '' };
      }
    },
  );

  const formatString = find(availableDateFormats, format => {
    return format.value === fieldValue.format;
  });

  const checkIfError = () => {
    if (500 + 1 > fieldValue.date && 1 - 1 < fieldValue.date) {
      return false;
    } else {
      return true;
    }
  };

  if (!isEmpty(fieldValue)) {
    return (
      <div>
        <Grid container spacing={3} alignItems="center" alignContent="center">
          {isFirstChild(questions, question) && (
            <>
              <Grid item xs={12}>
                <Typography variant="h2" className={classes.title2}>
                  {question.context[0]}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ margin: '1rem 0' }} />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.title}>
              {question.text}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              value={fieldValue.format}
              fullWidth
              variant="outlined"
              className={classes.textField}
              onChange={e => {
                onChange({ ...fieldValue, format: e.target.value });
              }}
              label={
                <Typography variant="body2">
                  <FormattedMessage {...messages.selectDateFormat} />
                </Typography>
              }
              select
              size="small"
            >
              {availableDateFormats.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          {fieldValue.format === EXACT_DATE ||
          fieldValue.format === MONTH_YEAR ? (
            <Grid item xs={8}>
              <div className={classes.datePicker}>
                <KeyboardDatePicker
                  label={
                    <>
                      <Typography variant="body2">
                        <FormattedMessage {...messages.selectDate} />
                      </Typography>
                    </>
                  }
                  value={
                    fieldValue.date
                      ? moment(new Date(`${fieldValue.date}`))
                      : null
                  }
                  format={formatString && formatString.format}
                  dateInputFormat={formatString && formatString.format}
                  placeholder={formatString && formatString.format}
                  // margin="normal"
                  minDate={moment(question.constraints.minDate)}
                  maxDate={
                    (formatString &&
                      formatString.format !== MONTH_SINCE_OCCURANCE) ||
                    (formatString && formatString.format !== RELATIVE_AGE)
                      ? moment(question.constraints.maxDate)
                      : null
                  }
                  views={
                    fieldValue.format === MONTH_YEAR
                      ? ['year', 'month']
                      : ['year', 'month', 'date']
                  }
                  onChange={(momentDate, value) => {
                    if (momentDate) {
                      if (momentDate.isValid()) {
                        return onChange({
                          ...fieldValue,
                          date: momentDate.format(
                            'YYYY-MM-DD[T]HH:mm:ss.SSSZZ',
                          ),
                        });
                      }
                      return onChange({ ...fieldValue, date: value });
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      fontWeight: 'normal',
                      fontSize: theme.typography.body1.fontSize,
                    },
                  }}
                  error={error}
                  TextFieldComponent={TextField}
                  animateYearScrolling={true}
                  autoOk={true}
                  okLabel={''}
                  cancelLabel={''}
                  fullWidth
                  inputVariant={'outlined'}
                />
              </div>
            </Grid>
          ) : (
            <>
              <Grid item xs={3}>
                <NumberField
                  variant="outlined"
                  className={classes.numberFormat}
                  // label={
                  //   fieldValue.format === MONTH_SINCE_OCCURANCE ? (
                  //     <FormattedMessage {...messages.addAValueMonth} />
                  //   ) : (
                  //     <FormattedMessage {...messages.addAValueYear} />
                  //   )
                  // }
                  value={
                    fieldValue.date >= MIN || fieldValue.date < MAX
                      ? fieldValue.date
                      : null
                  }
                  onChange={newVal => {
                    return false;
                  }}
                  onBlur={e => {
                    const targetValue = fixLeadingZero(e.target.value);
                    setFormError(
                      !(500 + 1 > targetValue && 1 - 1 < targetValue),
                    );
                    if (500 + 1 > targetValue && 1 - 1 < targetValue) {
                      const fixedLeadingZero = fixLeadingZero(e.target.value);
                      return onChange({
                        ...fieldValue,
                        date: fixedLeadingZero,
                      });
                    }
                  }}
                  error={checkIfError() || formError}
                  fullWidth
                  helperText={`Min value is ${MIN} and max value is ${MAX}`}
                  // format={inputFormat}
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body1" style={{ marginTop: '1rem' }}>
                  {fieldValue.format === MONTH_SINCE_OCCURANCE && 'Months ago'}
                  {fieldValue.format === RELATIVE_AGE && 'Years old'}
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  } else {
    return '';
  }
};

FormDate.propTypes = {
  ...WrappedFieldProps,
};

export default FormDate;

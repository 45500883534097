import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import messages from 'containers/ApplicationFormPage/messages';
// import FormCheck from 'components/FormCheck';
import { getFormValues, getFormSyncErrors, Field } from 'redux-form/immutable';

import { Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';
import FormTextField from 'components/FormTextField';
import { convertIfImmutable } from 'helpers/lang';
import { productCodeCheck } from 'helpers/constants';

function PartyDetails(props) {
  const { classes, formValues, isInforce, newLifeAdded, applicationProductCode } = props;
  const [emailPO, setEmailPO] = useState('');

  const [emailPO2, setEmailPO2] = useState('');
  const [emailLI1, setEmailLI1] = useState('');
  const [emailLI2, setEmailLI2] = useState('');
  useEffect(() => {
    if (!isEmpty(formValues)) {
      setEmailPO(formValues.partyDetails.emailPO);

      setEmailPO2(formValues.partyDetails.emailPO2);
      setEmailLI1(formValues.partyDetails.emailLI1);
      setEmailLI2(formValues.partyDetails.emailLI2);
    }
  }, []);

  function showLIField(email) {
    if (!isEmpty(email)) {
      return true;
    }
    if (isInforce) {
      if (newLifeAdded) {
        // false/null not showing
        return true;
      }
      return false;
    }
    return false;
  }

  return (
    <Grid container item xs={12} alignItems="center" alignContent="center">
      {!isEmpty(emailPO) && (
        <Grid container item xs={12} alignItems="center" alignContent="center">
          <Grid item xs={3}>
            <Typography variant="h4" className={classes.textTitle}>
              <FormattedMessage {...messages.policyOwnerEmail} />
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Field
              shrink
              name="emailPO.email"
              fullWidth
              component={FormTextField}
              color="inherit"
              margin="normal"
              variant="standard"
              placeholder="Please enter"
              disabled={emailPO.email !== null}
            />
          </Grid>
        </Grid>
      )}

      {!isEmpty(emailPO2) && (
        <Grid container item xs={12} alignItems="center" alignContent="center">
          <Grid item xs={3}>
            <Typography variant="h4" className={classes.textTitle}>
              <FormattedMessage {...messages.policyOwner2} />
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Field
              shrink
              name="emailPO2.email"
              fullWidth
              component={FormTextField}
              color="inherit"
              margin="normal"
              variant="standard"
              placeholder="Please enter"
              disabled={emailPO2.email !== null}
            />
          </Grid>
        </Grid>
      )}

      {showLIField(emailLI1) && (
        <Grid container item xs={12} alignItems="center" alignContent="center">
          <Grid item xs={3}>
            <Typography variant="h4" className={classes.textTitle}>
              {
                productCodeCheck(8, applicationProductCode) ? <FormattedMessage {...messages.lifeInsuredTwo} /> : <FormattedMessage {...messages.lifeInsuredOne} />

              }
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Field
              shrink
              name="emailLI1.email"
              fullWidth
              component={FormTextField}
              color="inherit"
              margin="normal"
              variant="standard"
              placeholder="Please enter"
             // disabled={emailLI1.email !== null}
            />
          </Grid>
        </Grid>
      )}

      {showLIField(emailLI2) && (
        <Grid container item xs={12} alignItems="center" alignContent="center">
          <Grid item xs={3}>
            <Typography variant="h4" className={classes.textTitle}>
              {
                productCodeCheck(8, applicationProductCode) ? <FormattedMessage {...messages.lifeInsuredThree} /> : <FormattedMessage {...messages.lifeInsuredTwo} />

              }
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Field
              shrink
              name="emailLI2.email"
              fullWidth
              component={FormTextField}
              color="inherit"
              margin="normal"
              variant="standard"
              placeholder="Please enter"
              //disabled={emailLI2.email !== null}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

PartyDetails.propTypes = {
  classes: PropTypes.object,
  formValues: PropTypes.object,
  isInforce: PropTypes.bool,
  newLifeAdded: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {};
}

const withFormSelector = connect(state => {
  const formValues = getFormValues('Consent')(state);
  const formErrors = getFormSyncErrors('Consent')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, withFormSelector)(PartyDetails);

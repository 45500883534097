import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { createStructuredSelector } from 'reselect';
import { useParams, useHistory } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import messages from '../messages';
import { Wrapper } from 'helpers/layout';
import clsx from 'clsx';
import { Field, reduxForm } from 'redux-form/immutable';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoRounded, HighlightOff } from '@material-ui/icons';
import { Grid, Box, Typography, Button, IconButton, Link, Fab, Dialog, Divider, DialogTitle } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { getApplicationDataAction, getAdminActionDetailAction, getSubmitAdminAction, sendAdminActionEmailAction, rejectApplicationAction, getAllDocumentsAction, getSingleDocumentsAction } from 'containers/OpsDashboardPage/actions';
import { mapStatus, useStyles } from 'containers/ApplicationPage/helper';
import PropTypes from 'prop-types';
import PolicyOwnerDetails from './policyOwnerDetails';
import InsuredPartyDetails from './insuredPartyDetails';
import BeneficiariesDetails from './BeneficiariesDetails';
import PayorDetails from './PayorDetails';
import PremiumSummaryDetails from './PremiumSummaryDetails';
import CoverDetails from './CoverDetails';

import Documents from './Documents';
import FormFooter from 'components/FormFooter';
import FormFooterAR from './FormFooterAR';
import { convertIfImmutable, isNexusAgency } from 'helpers/lang';
import { makeSelectApplicationById, makeSelectAdminActionDetails, makeSelectAllDocuments, makeSelectSingleDocuments } from '../selectors';
import { getApplicationDataFunction, submitApplication, getAdminActionData } from '../saga'
import CustomDialog from 'components/Dialog';
import FormTextField from 'components/FormTextField';

import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import reducer from '../reducer';
import saga from '../saga';

const DialogWrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 540px;
    padding: ${props => props.theme.spacing(2)}px;
    border-radius: 0;
    border: 2px solid #4066b3;
  }
`;

const Wrapper1 = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    flex-direction: row;
    width: 100%;
    height: 70px;
    border-top: 1px solid ${props => props.theme.palette.divider};
    z-index: ${props => props.theme.zIndex.drawer + 1};
    background-color: ${props => props.theme.palette.common.white};
    bottom: 0;
    left: 0;
    position: fixed;
  }
`;
const OpsTesting = (props) => {
  const {
    getAppData,
    applicationById,
    getAdminActionDetail,
    adminActionDetails,
    getSubmitAdmin,
    sendAdminEmail,
    getAllDocuments,
    allDocuments,
    getSingleDocuments,
    singleDocuments,
  } = props;

  useInjectReducer({ key: 'OpsDashboardPage', reducer });
  useInjectSaga({ key: 'OpsDashboardPage', saga });

  const theme = useTheme();
  const [content, setContent] = useState([]);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  const [status, setStatus] = useState("pending");
  const [message, setMessage] = useState("");
  const [rejectReason, setRejectReason] = useState("");
  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const contentData = convertIfImmutable(applicationById);
  const adminActionDetail = convertIfImmutable(adminActionDetails);
  const allDocumentsData = convertIfImmutable(allDocuments);
  const singleDocumentsData = convertIfImmutable(singleDocuments);
  const accessToken = localStorage.getItem('okta-token-storage');
  const zioId = JSON.parse(accessToken).accessToken.claims.zio;
  const getApplicationData = () => {
    if (contentData) {
      console.log("set content called in getApplicationData : contentData.id =",contentData.id);
      console.log("set content called in getApplicationData : id =",id);
      if (contentData.id == id) {
        setContent(contentData)
        // sessionStorage.setItem("referenceNumber", contentData.referenceNumber)
      }
    }
  }

  const getAdminActionDataFunction = async () => {
    if (adminActionDetail != null && adminActionDetail?.applicationId == id ) {
      sessionStorage.setItem("agentNumber", adminActionDetail?.agentNumber);
      setStatus(adminActionDetail?.applicationStatus.toLowerCase())
        //     sessionStorage.setItem("referenceNumber", contentData.referenceNumber)

        if (adminActionDetail?.applicationStatus.toLowerCase() == "rejected") {
          setIsAlreadySubmitted(true);
          setMessage("Rejected for Submission to Zurich: " + adminActionDetail?.reason);
        } else if (adminActionDetail?.applicationStatus.toLowerCase() == "approved") {
          setIsAlreadySubmitted(true);
          setMessage("Approved for submission to Zurich");
        } else {
            if (adminActionDetail?.agentNumber == "" || adminActionDetail?.agentNumber == null) {
                const data = {
                  applicationId: id,
                  agentNumber: zioId,
                  // referenceNumber : sessionStorage.getItem("referenceNumber"),
                }
                await getSubmitAdmin(data);
                setIsAlreadySubmitted(false)
                setMessage("");
            } else if (adminActionDetail?.agentNumber != zioId) {
              setIsAlreadySubmitted(true)
              setMessage("This application is under process");
            } else {
              setIsAlreadySubmitted(false)
              setMessage("");
            }
        }
    } 
  }
  
  const updateAgentNumber = async() => {
  const agentNumberStored = sessionStorage.getItem("agentNumber");
      if (!isAlreadySubmitted && agentNumberStored == zioId) {
        // const referenceNumber = sessionStorage.getItem("referenceNumber");
        const data = {
          applicationId: id,
          agentNumber: "",
          // referenceNumber: referenceNumber,
        }
        await getSubmitAdmin(data);
        
      }
};


React.useEffect(() => {
    window.onpopstate = () =>
    {
      updateAgentNumber()
    }
})

  const getAllDocumentsZip = () => {
    getAllDocuments(id);
  }

  const getSingleDocument = (data) => {
    getSingleDocuments(data)
  }

  useEffect(() => {
      getAppData(id);
      getAdminActionDetail(id);
      console.log("sessionStorage.getItem(agentNumber) :: ", sessionStorage.getItem("agentNumber"));
      sessionStorage.setItem("applicationId",id);
      sessionStorage.setItem("alreadySubmitted", isAlreadySubmitted);
      console.log("sessionStorage.getItem(applicationId) :: ", sessionStorage.getItem("applicationId"));
  }, []);


  useEffect(() => {
    console.log("useEffect applicationById : ", applicationById);
    getApplicationData()
  }, [applicationById])

  useEffect(() => {
    console.log("useEffect adminActionDetails : ", adminActionDetails);
    getAdminActionDataFunction()
  }, [adminActionDetails])

  function getProductConfig(isBolton) {
    return isBolton ? content.productConfigs[0] : content.productConfigs;
  }

  function getProductConfig1(isBolton) {
    return isBolton ? content.productConfigs[1] : {};
  }

  async function handleSubmit(status) {
    const data = {
      applicationId: content.id,
      referenceNumber: content.referenceNumber,
      agentNumber: zioId,
      applicationStatus: status,
      reason: rejectReason,
      isInforce: content.isInforce
    }
    const emailData = {
      applicationId: content.id,
      type: status,
    }
    await getSubmitAdmin(data);
    setTimeout(async () => {
      history.push(`/dashboard`);
    }, 3000)
  }

  return (
    <Wrapper theme={theme} style={{ position: 'absolute' }}>
      <Grid container spacing={5} style={{ maxWidth: '820px' }}>
        {isAlreadySubmitted && message != "" && (
          <Grid item xs={12} md={8} style={{
            width: '100%',
            border: (status == "rejected") ? '1px solid #EA635C' : '1px solid blue',
            padding: '20px',
            marginLeft: '20px'
          }}>
            <Typography
              variant="body1"
              color={status == "rejected" ? "error" : "primary"}
            >
              {message}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} md={10} style={{ paddingBottom: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>

              <Grid
                container
                spacing={3}
                style={{ paddingBottom: theme.spacing(3) }}
              >
                <Grid item xs={12}>
                  {/* APPLICATION NUMBER */}
                  <Typography
                    variant="h1"
                    style={{
                      marginTop: theme.spacing(1),
                      fontSize: '3rem',
                    }}
                  >
                    {(content && content.policyNumber != null) ? (
                        <span> 
                        <FormattedMessage
                          {...messages.policyNumber}
                        /> #
                        {content.policyNumber}
                        </span>

                    ) : (content &&
                      isNexusAgency(content.agencyNumber) ? (
                        <span> 
                        <FormattedMessage
                          {...messages.extReferenceNumber}
                        /> #
                        {content.externalReferenceNumber}
                        </span>
                    ) : (
                        <span>
                        <FormattedMessage
                          {...messages.referenceNumber}
                        /> #
                        {content.referenceNumber}
                        </span>
                    )

                    )
                       
                    }
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={10} style={{ paddingTop: 0 }}>
          {/* POLICY OWNER */}
          <PolicyOwnerDetails parties={content.parties} classes={classes} />
        </Grid>

        {/* INSURANCE PARTY */}
        <InsuredPartyDetails parties={content.parties} classes={classes} />
        {/* BENEFICIARIES PARTY */}
        <BeneficiariesDetails parties={content.parties} classes={classes} />
        {/* DOCUMENTS */}
        <Documents parties={content.parties} classes={classes} allDocument={getAllDocumentsZip} singleDocument={getSingleDocument} allDocumentsData={allDocumentsData} singleDocumentsData={singleDocumentsData} applicationDetail={content}/>
        {/* PAYOR PARTY */}
        <PayorDetails parties={content.parties} classes={classes} />
        {/* Premium SUMMARY */}
        <PremiumSummaryDetails
          applicationDetails={content}
          productConfigs={getProductConfig(content.isBolton)}
        />
         {/* COVER DETAILS */}
        <CoverDetails
          productConfigs={getProductConfig(content.isBolton)}
          parties={content.parties}
        />
      </Grid>

    <Wrapper1 theme={theme} container alignItems="stretch" style={{position:'sticky'}}>
     <Grid item xs sx={4} >
      <Button
          variant="contained"
          color="primary"
          onClick={() => { setOpenConfirmApprove(true) }}
          margin="normal"
          disabled={isAlreadySubmitted}
          style={{
            height: '100%',
            float:'right',
            marginRight:'100px'
          }} 
        ><FormattedMessage {...messages.approve} /></Button>
      </Grid>
      <Grid item xs sx={4}>
        <Button
          variant="contained"
          color="primary"
          disabled={isAlreadySubmitted}
          onClick={() => setOpen(true)}
          style={{
            // padding: '17px 22px',
            height: '100%',
            
          }}
        ><FormattedMessage {...messages.reject} /></Button>
      </Grid>
    </Wrapper1>
      <Dialog open={open}>
        <DialogWrapper container theme={theme}>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
          >
            <Grid item xs={10}>
              <DialogTitle style={{ paddingLeft: 0 }}>
                <Typography color="primary" style={{ fontSize: 20 }}>
                  {<FormattedMessage {...messages.rejectReasonTitle} />}
                </Typography>
              </DialogTitle>
            </Grid>
          </Grid>
          <Divider style={{ marginTop: theme.spacing(2) }} />
          <Grid
            item
            style={{
              marginTop: theme.spacing(2),
            }}
          >
            <Grid item xs={12} style={{ padding: theme.spacing(2) }}>

             <textarea name="rejectReason" rows="5" cols="60" id="rejectReason" 
              placeholder="Please provide reason for rejection" 
              onChange={(e) => { 
                setRejectReason(e.target.value); 
                }} />
              <div
                style={{ marginTop: 40 }}
                className={classes.flexJustifyCenter}
              >
                <Button
                  variant="contained"
                  disabled={!rejectReason}
                  color="primary"
                  style={{ marginRight: '2.5rem' }}
                  margin="normal"
                  className={clsx(classes.button, classes.buttonPrimary)}
                  onClick={() => { handleSubmit("rejected"); setOpen(false) }}
                >
                  <FormattedMessage {...messages.submit} />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  className={clsx(classes.button, classes.buttonPrimary)}
                  onClick={() => setOpen(false)}
                >
                  <FormattedMessage {...messages.cancel} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogWrapper>
      </Dialog>
      <Dialog open={openConfirmApprove}      >
        <DialogWrapper container theme={theme}>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='space-between'
          >
            <Grid item xs={10}>
              <DialogTitle style={{ paddingLeft: 0 }}>
                <Typography color="primary" style={{ fontSize: 20 }}>
                  {<FormattedMessage {...messages.confirmAprrove} />}
                </Typography>
              </DialogTitle>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: theme.spacing(2) }} />

          <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
            <div
              style={{ marginTop: 60 }}
              className={classes.flexJustifyCenter}
            >
              <Button
                variant="contained"
                color="primary"
                style={{ marginRight: '2.5rem' }}
                margin="normal"
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={() => { handleSubmit("approved"); setOpenConfirmApprove(false) }}
              >
                <FormattedMessage {...messages.yes} />
              </Button>
              <Button
                variant="contained"
                color="primary"
                margin="normal"
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={() => setOpenConfirmApprove(false)}
              >
                <FormattedMessage {...messages.no} />
              </Button>
            </div>
          </Grid>
        </DialogWrapper>
      </Dialog>
    </Wrapper >

  )
}

OpsTesting.propTypes = {
  getAppData: PropTypes.func,
  applicationById: PropTypes.object,
  getAdminActionDetail: PropTypes.func,
  adminActionDetails: PropTypes.object,
  getSubmitAdmin: PropTypes.func,
  sendAdminEmail: PropTypes.func,
  getAllDocuments: PropTypes.func,
  allDocuments: PropTypes.object,
  getSingleDocuments: PropTypes.func,
  singleDocuments: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  applicationById: makeSelectApplicationById(),
  adminActionDetails: makeSelectAdminActionDetails(),
  allDocuments: makeSelectAllDocuments(),
  singleDocuments: makeSelectSingleDocuments(),
});

function mapDispatchToProps(dispatch) {
  return {
    getAppData: value => dispatch(getApplicationDataAction(value)),
    getAdminActionDetail: value => dispatch(getAdminActionDetailAction(value)),
    getSubmitAdmin: value => dispatch(getSubmitAdminAction(value)),
    sendAdminEmail: value => dispatch(sendAdminActionEmailAction(value)),
    getAllDocuments: value => dispatch(getAllDocumentsAction(value)),
    getSingleDocuments: value => dispatch(getSingleDocumentsAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default withConnect(OpsTesting)


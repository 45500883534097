/**
 *
 * NumberField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import TextField from 'components/TextField';

function NumberField(props) {
  const { onBlur, ...otherProps } = props;
  return (
    <NumberFormat
      thousandSeparator
      decimalSeparator="."
      decimalScale={2}
      {...otherProps}
      customInput={TextField}
      onBlur={e => {
        onBlur(e.target.value);
      }}
    />
  );
}

NumberField.propTypes = {
  onBlur: PropTypes.func,
};

export default NumberField;

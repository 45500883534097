export const newParty = status => {
  return {
    title: '',
    underwriting: '',
    isUsNumberPhone: '',
    isPep: false,
    isSmoke: false,
    firstName: '',
    lastName: '',
    gender: 0,
    preferredName: '',
    primaryNationality: 'Select an option',
    secondaryNationality: 'Select an option',
    countryOfBirth: 'Select an option',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    postOfficeBoxLine: '',
    postOfficeBoxCity: '',
    city: '',
    country: 'Select an option',
    primaryCountryDialingCode: 'Select',
    primaryPhoneNumber: '',
    secondaryCountryDialingCode: 'Select',
    secondaryPhoneNumber: '',
    additionalDetails: null,
    relationshipToLifeInsured: 'Select an option',
    partyapplicationrelationships: {
      ...status,
      // isPolicyOwner: true,
      // isPrimaryPolicyOwner: true,
      // isLifeInsured: true,
      // isPrimaryLifeInsured: true,
      // isPayor: true,
      // isBeneficiary: true,
      // isPrimaryBeneficiary: true,
      additionalDetails: {
        relationshipToPolicyOwner: 'Select an option',
        relationshipToLifeInsured: 'Select an option',
        reasonToPay: 'Select an option', 
        other: '',
      },
    },
  };
};

export const newCompanyPayor = status => {
  return {
    companyName: '',
    email: '',
    primaryCountryDialingCode: '',
    primaryPhoneNumber: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    postOfficeBoxLine: '',
    postOfficeBoxCity: '',
    country: '',
    additionalDetails: {
      // relationshipToPolicyOwner: 'child',
      relationshipToLifeInsured: 'Select an option',
      reasonToPay: 'Select an option', 
      other: '',
    },
    partyapplicationrelationships: {
      ...status,
      additionalDetails: {
        isCompanyPayor: true,
      },
    },
  };
};

export const newConditionalAssignee = () => {
  return {
    institution: 'Select an option',
    currency: 'Select an option',
    reasonForAssignment: '',
    email: '',
    amount: '',
  };
};

export const newPayorProceeds = {
  saleCurrency: 'Select',
  totalSaleAmount: '',
  addressProperty: '',
  sharesProperty: '',
};

export const newpolicyPaidBySalary = {
  employerName: '',
  employerAddress: '',
  countryDialingCode: 'Select',
  phoneNumber: '',
  annualIncome: '',
};

export const newPayorSource = {
  originOfWealth: 'Select',

  nameOfCompany: '',
  natureOfCompany: '',
  dateOfSale: '',
  currency: 'Select',
  totalAmount: '',
  sharePercentage: '',

  descriptionOfMaturing: '',
  dateOfMaturity: '',
  maturingProceeds: '',

  detailsOfInheritance: '',
  dateRecieved: '',

  nameOfLoanProvider: '',
  natureOfLoanProvider: '',
  dateOfLoan: '',

  reasonForGift: '',
  donorsOriginOfWealth: '',
  relationshipToApplicant: 'Select',

  dateOfWin: '',
  detailsOfOrganisation: '',
  detailsOfWin: '',

  reasonForPayment: '',

  detailsOfPreviousProfession: '',
  nameNAddressOfLastEmployer: '',
  approxSalary: '',
  pensionIncome: '',
  retirementDate: '',

  natureOfSource: '',
  recievedFrom: '',
  dateRecieved: '',
};

export const newTaxPurposes = {
  countryOfTax: 'Select an option',
  taxReferenceNumber: '',
};

export const newBankAccount = {
  bankName: 'Select an option',
  cardNumber: '',
  yearNumber: '',
  monthNumber: '',
};

export const newBankDetail = {
  bankName: 'Select an option',
  cardNumber: '',
};

export const newCCDetail = {
  cardCurrency: 'Select an option',
  card_number: '',
  card_holder_name: '',
  expiry_date: '',
  card_security_code: '',
  preferredDateOfCollection: '',
};

export const newOtherPartiesDetails = {
  potentialBorrowers: '',
};

export const newExistingZurichPlanDetails = {
  isPolicyNumber: false,
  existingPolicyNumber: '',
};

export const newLifeOfficesDetails = {
  nameOfCompany: '',
  policyType: '',
  amountOfCover: '',
  premiumAmount: '',
  policyTerm: '',
  policyCurrency: '',
};

export const newFund = {
  fundCode: '',
  fundDescription: '',
  fundCcy: '',
  percent: '',
};
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isString } from 'lodash/lang';
import moment from 'moment';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import {
  getStartDate,
  calculateAgeNextBirthdate,
} from 'containers/GcIllustrationPage/helper';
import { isAlphaNumeric } from 'helpers/lang';
import { dobFormat } from 'helpers/constants';
import messages from '../../messages';

const validate = (values, props) => {
  const errors = {};
  const gcConfig = props?.gcConfig;
  const productConfigSource = props?.productConfig?.source;
  const startDate = getStartDate().format(dobFormat);
  const MAX_AGE = gcConfig?.features?.ageAtEntry?.childmaxEntryAge;
  const MIN_AGE = gcConfig?.features?.ageAtEntry?.childminEntryAge;
  const MAX_TARGET_FUND = 999999999;
  if (!values.isEmpty()) {
    // INSURED ONE
    const insuredOneValues = values.get('insuredOne');
    const isInsuredTwoValues = values.get('isInsuredTwo');
    const insuredTwoValues = values.get('insuredTwo');
    const isPolicyOwnerIndividualValues = values.get('isPolicyOwnerIndividual');
    const childDetailsValues = values.get('childDetails');
    const countryOfEducationValue = values.get('countryOfEducation');
    const requiredInsuredOneFields = [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
      'isSmoke',
      'nationality',
      'countryOfResidence',
      'countryOfEducation',
      'otherUniversityCountryCode',
      "targetYear",
      "currency",
      "targetFund",
      "payoutYears"

    ];
    const childDetailsError = {};
    if (childDetailsValues && !childDetailsValues.isEmpty()) {
      requiredInsuredOneFields.forEach(field => {
        if (isFieldEmpty(childDetailsValues.get(field))) {
          if (
            field === 'gender' ||
            field === 'isSmoke' ||
            field === 'nationality' ||
            field === 'countryOfEducation' ||
            field === 'countryOfResidence' ||
            field === 'currency'
          )
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          else
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
        }
        // else {
        //   if (field === 'firstName' || field === 'lastName') {
        //     if (
        //       isString(insuredOneValues.get(field)) &&
        //       !isAlphaNumeric(insuredOneValues.get(field))
        //     ) {
        //       childDetailsError[field] = (
        //         <FormattedMessage
        //           {...globalMessages.onlyAlphanumericCharacters}
        //         />
        //       );
        //     }
        //   }
        // }
      
        if (field === 'dateOfBirth') {
          if (isFieldEmpty(childDetailsValues.get(field))) {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          } else {
            if (!moment(childDetailsValues.get(field)).isValid()) {
              childDetailsError[field] = (
                <FormattedMessage {...globalMessages.dateInvalid} />
              );
            }
            let dob = childDetailsValues.get(field)
            let currentdate = moment()
            let anb = currentdate.diff(dob, 'years', true);
            let futureDate = moment(dob).isAfter(currentdate);
            if (futureDate)
              childDetailsError[field] = (
                <FormattedMessage
                  {...messages.minAgeDateValidation}
                  values={{ value1: moment().format('MMM D, YYYY') }}
                />
              );

            let diff = moment(dob).diff(moment(), 'milliseconds');
            let durationdays = moment.duration(diff).days();
            let durationMonths = moment.duration(diff).months()
            let durationYears = moment.duration(diff).years()
            if (Math.abs(durationYears) >= MAX_AGE)

              childDetailsError[field] = (
                <FormattedMessage
                  {...globalMessages.maxAgeValidation}
                  values={{ value1: MAX_AGE - 1 }}
                />
              );

          }
        }
        if (field === 'targetYear') {
          if (isFieldEmpty(childDetailsValues.get(field))) {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          } else {
            if (!moment(childDetailsValues.get(field)).isValid()) {
              childDetailsError[field] = (
                <FormattedMessage {...globalMessages.dateInvalid} />
              );
            }

            let datevalue = moment(childDetailsValues.get('dateOfBirth'), dobFormat)
            let minDate = '';
            let maxDate = '';
            let age = moment().diff(datevalue, 'years', false);
            let dif = 16 - age;
            let minYear = moment().add(dif, 'years').calendar()
            let maxYear = moment().add(dif + 4, 'years').calendar()
            let currentYear = moment().year() + 5
            let minDateYear = moment(minYear).year()
            let maxDateYear = moment(maxYear).year()
            if (minDateYear <= currentYear && maxDateYear > (currentYear)) {
              minDate = moment().add(5, 'years').calendar()
              maxDate = maxYear
            } else if (minDateYear > currentYear && maxDateYear > currentYear) {
              minDate = minYear
              maxDate = maxYear
            } else {
              minDate = moment().add(5, 'years').calendar()
              maxDate = moment().add(5, 'Years').calendar()
            }
            let minTargetYear = new Date(minDate).getFullYear()
            let maxTargetYear = new Date(maxDate).getFullYear()

            let anb = childDetailsValues.get(field);

            if (anb < minTargetYear && (anb !== maxTargetYear && maxTargetYear === minTargetYear))
              childDetailsError[field] = (
                <FormattedMessage
                  {...messages.validTargetYear}
                  values={{ value: currentYear, value1: minTargetYear, value2: maxTargetYear }}

                />
              );
            if (anb > maxTargetYear && (anb !== maxTargetYear && maxTargetYear === minTargetYear))
              childDetailsError[field] = (
                <FormattedMessage
                  {...messages.validTargetYear}
                  values={{ value: currentYear, value1: minTargetYear, value2: maxTargetYear }}

                />
              );
            if (anb < minTargetYear)
              childDetailsError[field] = (
                <FormattedMessage
                  {...messages.validTargetYear}
                  values={{ value: currentYear, value1: minTargetYear, value2: maxTargetYear }}

                />
              );
            if (anb > maxTargetYear)
              childDetailsError[field] = (
                <FormattedMessage
                  {...messages.validTargetYear}
                  values={{ value: currentYear, value1: minTargetYear, value2: maxTargetYear }}

                />
              );
          }
        }
        if( field === 'otherUniversityCountryCode' && childDetailsValues.get('countryOfEducation') === 'OT' && productConfigSource !== 'DA' ){
          if (isFieldEmpty(childDetailsValues.get(field)) ||childDetailsValues.get(field) === 'Select an option') {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          }
        }
        if (field === 'countryOfEducation') {
          if (childDetailsValues.get(field) === 'Select an option') {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        }
        if (field === 'currency') {
          if (childDetailsValues.get(field) === 'Select') {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        }
        if (field === 'nationality') {
          if (childDetailsValues.get(field) === 'Select an option') {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        }
        if (field === 'countryOfResidence') {
          if (childDetailsValues.get(field) === 'Select an option') {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        }
        if (field === 'targetFund') {
          const targetFundValue = childDetailsValues.get(field);
          if (targetFundValue === '0' || targetFundValue <= 0) {
            childDetailsError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          } 
          if (targetFundValue > MAX_TARGET_FUND) {
            childDetailsError[field] = (
              <FormattedMessage
                {...globalMessages.maxAmountLimit}
                values={{ value: MAX_TARGET_FUND }}
              />
            );
          }
        }
        if (field === 'payoutYears') {
          const payoutYearsValue = childDetailsValues.get(field);
          if (payoutYearsValue === '0' || payoutYearsValue <= 0) {
            childDetailsError[field] = (
              <FormattedMessage {...messages.validPayoutYears} />
            );
          }
          if (payoutYearsValue > 5) {
            childDetailsError[field] = (
              <FormattedMessage {...messages.validPayoutYears} />
            );

          }
        }
      });

      if (!isPolicyOwnerIndividualValues) {
        if (isInsuredTwoValues) {
          /*if (
            !insuredOneValues.get('isPolicyOwner') &&
            !insuredTwoValues.get('isPolicyOwner')
          ) {
            childDetailsError['isPolicyOwner'] = (
              <FormattedMessage {...globalMessages.atleastOnePolicyOwner} />
            );
          }*/
        }
      }
    }
    if (!isEmpty(childDetailsError)) errors.childDetails = childDetailsError;

    if (isInsuredTwoValues) {
      // INSURED TWO
      const requiredInsuredTwoFields = [
        'firstName',
        'lastName',
        'dateOfBirth',
        'gender',
        'isSmoke',
        'nationality',
      ];
      const insuredTwoError = {};
      if (insuredTwoValues && !insuredTwoValues.isEmpty()) {
        requiredInsuredTwoFields.forEach(field => {
          if (isFieldEmpty(insuredTwoValues.get(field))) {
            if (field === 'gender' || field === 'isSmoke' || 'nationality')
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
            else
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
          }
          // else {
          //   if (field === 'firstName' || field === 'lastName') {
          //     if (
          //       isString(insuredTwoValues.get(field)) &&
          //       !isAlphaNumeric(insuredTwoValues.get(field))
          //     ) {
          //       insuredTwoError[field] = (
          //         <FormattedMessage
          //           {...globalMessages.onlyAlphanumericCharacters}
          //         />
          //       );
          //     }
          //   }
          // }

          if (field === 'dateOfBirth') {
            if (isFieldEmpty(insuredTwoValues.get(field))) {
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            } else {
              if (!moment(insuredTwoValues.get(field)).isValid()) {
                insuredTwoError[field] = (
                  <FormattedMessage {...globalMessages.dateInvalid} />
                );
              }
              let dob = childDetailsValues.get(field)
              let currentdate = moment()
              let anb = currentdate.diff(dob, 'years', true);

              if (anb < 18)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.minAgeValidation}
                    values={{ value1: '18' }}
                  />
                );

              if (anb > 75)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxAgeValidation}
                    values={{ value1: '74' }}
                  />
                );
            }
          }
          if (field === 'nationality') {
            if (insuredTwoValues.get(field) === 'Select an option') {
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            }
          }
        });
      }
      if (!isEmpty(insuredTwoError)) errors.insuredTwo = insuredTwoError;
    }

    // INDIVIDUAL POLICY OWNER
    if (isPolicyOwnerIndividualValues) {
      const policyOwnerIndividualValues = values.get('policyOwnerIndividual');
      const policyOwnerIndividualError = [];
      if (policyOwnerIndividualValues && policyOwnerIndividualValues.size > 0) {
        const requiredIndividualFields = [
          'firstName',
          'lastName',
          'relationshipToLifeInsured',
        ];
        policyOwnerIndividualValues.map(individual => {
          const individualError = {};
          requiredIndividualFields.forEach(field => {
            if (isFieldEmpty(individual.get(field))) {
              if (field === 'relationshipToLifeInsured')
                individualError[field] = (
                  <FormattedMessage {...globalMessages.pleaseSelectValue} />
                );
              else
                individualError[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
            }
            if (field === 'relationshipToLifeInsured') {
              if (individual.get(field) === 'Select an option') {
                individualError[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              }
            }
            // else {
            //   if (field === 'firstName' || field === 'lastName') {
            //     if (
            //       isString(individual.get(field)) &&
            //       !isAlphaNumeric(individual.get(field))
            //     ) {
            //       individualError[field] = (
            //         <FormattedMessage
            //           {...globalMessages.onlyAlphanumericCharacters}
            //         />
            //       );
            //     }
            //   }
            // }
          });
          policyOwnerIndividualError.push(individualError);
        });
      }
      if (!isEmpty(policyOwnerIndividualError))
        errors.policyOwnerIndividual = policyOwnerIndividualError;
    }
  }
  return errors;
};

export default validate;

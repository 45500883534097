import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import Notification from 'icons/Notification';
import Pending from 'icons/Pending';
import Progress from 'icons/Progress';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import IconEditSrc from 'images/open-external.svg';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 315,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'white',
    borderBottom: '1px solid white',
    padding: 14,
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notification: {
    backgroundColor: '#d75c58',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  pending: {
    backgroundColor: '#FF8933',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  progress: {
    backgroundColor: '#00C3B4',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  subTitle: {
    fontSize: 14,
    color: 'white',
    padding: 7,
    textAlign: 'center',
  },
  bodyText: {
    padding: 10,
    backgroundColor: '#F9F9F9',
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    minHeight: 70,
  },
  textRef: {
    textAlign: 'right',
    paddingRight: 5,
    fontSize: '1.4rem',
  },
  icon: {
    color: theme.palette.primary.dark,
    fontSize: '1.4rem',
  },
  text: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
}));

export function TableHub(props) {
  const classes = useStyles();
  const history = useHistory();
  const { listApplication } = props;

  const handleProceedApplication = (applicationId, productCode) => {
    history.push({
      pathname: `applications/${applicationId}`,
      state: { productCode: productCode },
    });
  };

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={4} xs={4}>
        <Typography
          component="div"
          className={clsx(classes.title, classes.notification)}
        >
          <Typography variant="h3">
            <Notification /> Actionable
          </Typography>
          <Typography variant="h4">
            {!!listApplication.arrActionable &&
              listApplication.arrActionable.length}
          </Typography>
        </Typography>

        <Typography component="div">
          {listApplication.arrActionable.map((item, index) => (
            <div key={index}>
              <Grid container key={index} className={classes.bodyText}>
                {item.parties.map((element, i) => {
                  return (
                    element.partyapplicationrelationships
                      .isPrimaryLifeInsured && (
                      <Grid item sm={6} lg={8} md={6} key={i}>
                        <Typography className={classes.text}>
                          {`${element.firstName} ${element.lastName}`}
                        </Typography>
                      </Grid>
                    )
                  );
                })}
                <Grid item sm={4} lg={2} md={4} className={classes.textRef}>
                  {item.productCode}
                </Grid>
                <Grid item sm={2} lg={2} md={2} align="right">
                  <img
                    src={IconEditSrc}
                    alt=""
                    className={classes.icon}
                    onClick={() =>
                      handleProceedApplication(item.id, item.productCode)
                    }
                  />
                </Grid>
              </Grid>
            </div>
          ))}
        </Typography>
      </Grid>

      <Grid item md={4} xs={4}>
        <Typography
          component="div"
          className={clsx(classes.title, classes.pending)}
        >
          <Typography variant="h3">
            <Pending /> Pending
          </Typography>
          <Typography variant="h4">
            {!!listApplication.arrPending && listApplication.arrPending.length}
          </Typography>
        </Typography>

        <Typography component="div">
          {listApplication.arrPending.map((item, index) => (
            <Grid container key={index} className={classes.bodyText}>
              {item.parties.map((element, i) => {
                return (
                  element.partyapplicationrelationships
                    .isPrimaryLifeInsured && (
                    <Grid item sm={8} lg={8} md={8} key={i}>
                      <Typography
                        className={classes.text}
                      >{`${element.firstName} ${element.lastName}`}</Typography>
                    </Grid>
                  )
                );
              })}
              <Grid item sm={4} lg={4} md={4} className={classes.textRef}>
                {item.productCode}
              </Grid>
            </Grid>
          ))}
        </Typography>
      </Grid>

      <Grid item md={4} xs={4}>
        <Typography
          component="div"
          className={clsx(classes.title, classes.progress)}
        >
          <Typography variant="h3">
            <Progress /> In progress
          </Typography>

          <Typography variant="h4">
            {!!listApplication.arrInprogress &&
              listApplication.arrInprogress.length}
          </Typography>
        </Typography>
        <Typography component="div">
          {listApplication.arrInprogress.map((item, index) => (
            <Grid container key={index} className={classes.bodyText}>
              {item.parties.map((element, i) => {
                return (
                  element.partyapplicationrelationships
                    .isPrimaryLifeInsured && (
                    <Grid item xs={7} lg={7} key={i}>
                      <Typography
                        className={classes.text}
                      >{`${element.firstName} ${element.lastName}`}</Typography>
                    </Grid>
                  )
                );
              })}
              <Grid item xs={3} lg={3} align="center">
                <Typography variant="h4">{item.productCode}</Typography>
              </Grid>
              <Grid item xs={2} align="right">
                <img
                  src={IconEditSrc}
                  alt=""
                  className={classes.icon}
                  onClick={() => {
                    handleProceedApplication(item.id, item.productCode);
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Typography>
      </Grid>
    </Grid>
  );
}

TableHub.propTypes = {
  listApplication: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TableHub);

import React from 'react';
import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import {
  SUBMIT_REGISTRATION_ACTION,
  ERROR_RESPONSE_MESSAGE,
} from './constants';
import request from 'utils/request';
import { makeSelectInputData } from './selectors';
import { api } from 'environments';
import {
  setNotificationAction,
  resetStateAction,
  setSignUpSuccessAction,
} from './actions';
import messages from './messages';
import globalMessages from 'containers/App/messages';
import { FormattedMessage } from 'react-intl';
import { setAppLoadingAction } from 'containers/App/actions';

export function* submit() {
  yield put(setAppLoadingAction(true));
  const inputData = yield select(makeSelectInputData());
  const endpoint = `${api.host}/api/v${api.version}/b2b/auth/sign-up`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...inputData,
    }),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.error) {
      const { errorSummary, errorCode, message } = response;
      yield put(
        setNotificationAction({
          open: true,
          message: `${errorCode}: ${message}, ${errorSummary}`,
          severity: 'error',
        }),
      );
    } else {
      yield put(resetStateAction());
      yield put(
        setNotificationAction({
          open: true,
          message: <FormattedMessage {...messages.registrationSuccessNotice} />,
          severity: 'success',
        }),
      );
      yield put(setSignUpSuccessAction());
    }
  } catch (err) {
    if (err.response) {
      let response = {};
      try {
        response = yield err.response.json();
      } catch (resErr) {
        //
      }
      const { message } = response;
      if (ERROR_RESPONSE_MESSAGE[message]) {
        yield put(
          setNotificationAction({
            open: true,
            message: (
              <FormattedMessage
                {...messages[ERROR_RESPONSE_MESSAGE[message]]}
              />
            ),
            severity: 'error',
          }),
        );
      } else {
        yield put(
          setNotificationAction({
            open: true,
            message: <FormattedMessage {...globalMessages.errSomethingWrong} />,
            severity: 'error',
          }),
        );
      }
    } else {
      yield put(
        setNotificationAction({
          open: true,
          message: <FormattedMessage {...globalMessages.errSomethingWrong} />,
          severity: 'error',
        }),
      );
    }
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

// Individual exports for testing
export default function* registerPageSaga() {
  // See example in containers/HomePage/saga.js
  yield all([takeLatest(SUBMIT_REGISTRATION_ACTION, submit)]);
}

import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import messages from 'containers/ApplicationPage/messages';
import { map } from 'lodash/collection';
import moment from 'moment';
import { getGender, filteredParty, partyFilter } from 'helpers/lang';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow, Link,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import FileDownload from 'js-file-download';

const useStyle = makeStyles(() => ({
  link: {
    color: '#4066B3',
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(name, date, relationshipToLifeInsured) {
  return { name, date, relationshipToLifeInsured };
}

// const rows = [createData('Mr. Frank Smith', '02.05.1986', 'Male', 'Smoker')];

function Documents(props) {
  const theme = useTheme();
  const linkClasses = useStyle();
  const rows = [];
  const { parties, classes, allDocument, singleDocument, allDocumentsData, singleDocumentsData, applicationDetail } = props;
  const [onGetAllDocsClicked,setAllDocsClicked] = useState(false);
  const [onGetSingleDocClicked,setSingleDocsClicked] = useState(false);
  const [onGetSingleDocFileName,setSingleDocFileName] = useState('');
  const referenceNumber = applicationDetail.referenceNumber;

  if(applicationDetail && applicationDetail.verifiedDocuments) {
    let dontIncludeVerifiedDocs = ['UnderwriterForm','CompletionCert'];
    for (let key in applicationDetail.verifiedDocuments) {
        for (let i in applicationDetail.verifiedDocuments[key].data){
          if(applicationDetail.verifiedDocuments[key].data[i].url && applicationDetail.verifiedDocuments[key].data[i].type && !(dontIncludeVerifiedDocs.includes(applicationDetail.verifiedDocuments[key].data[i].type)) ){
            if (applicationDetail.verifiedDocuments[key].data[i].type === 'IDENTITY'){
              applicationDetail.verifiedDocuments[key].data[i].type = applicationDetail.verifiedDocuments[key].data[i].name.substring(0,3)
            }
            rows.push(applicationDetail.verifiedDocuments[key].data[i]);
          }
        }
    }
  }

  // if (parties) { //Parties only contain POI POA UW VISA AD document related to individual parties
  //   let dontIncludePartiesDocs = ['VISA','IDENTITY','UnderwriterForm'];
  //   parties.map((party, index) => {
  //     parties[index].documents.map((document, i) => {
  //       console.log('newbuzz document : ',document)
  //       if (!(dontIncludePartiesDocs.includes(document.type))){
  //         console.log('newbuzz passed document.type : ',document.type)
  //         rows.push(document);
  //       }
  //     })
  //   })
  // }
  
  if(applicationDetail && applicationDetail.documents) {
    let includeDocs = ['PaymentForm'];
    for (let key in applicationDetail.documents) {
      if(applicationDetail.documents.hasOwnProperty(key) && (includeDocs.includes(key))){
        if(applicationDetail.documents[key].url && applicationDetail.documents[key].type) {
          rows.push(applicationDetail.documents[key]);
        }
      }
    }
  }

  const singleDocumentFunc = (name, fileName,type) => {
    const nameUrl = name.split('name=').pop();
    singleDocument(nameUrl)
    setSingleDocsClicked(true)
    let fname = fileName ? fileName : `${referenceNumber + '_' + type +'.pdf'}`
    setSingleDocFileName(fname)
  }
  useEffect(()=>{
    if (singleDocumentsData && onGetSingleDocClicked && onGetSingleDocFileName) {
      FileDownload(singleDocumentsData, onGetSingleDocFileName)
      setSingleDocsClicked(false)
      setSingleDocFileName('')
    }
  },[singleDocumentsData]);

  const allDocumentFunc = () => {
    allDocument()
    setAllDocsClicked(true);
  }
  useEffect(()=>{
    if (allDocumentsData && onGetAllDocsClicked) {
      FileDownload(allDocumentsData, `${referenceNumber}.zip`)
      setAllDocsClicked(false);
    }
  },[allDocumentsData]);

  function withPaymentLink(row) {
    let linkStatus = ['PENDING_PAYMENT','ADMIN_ACTION_PENDING']
    if(row.type === 'PaymentForm' && applicationDetail.paymentDetails['preferredCCPaymentLink'] === 'Y' && linkStatus.includes(applicationDetail.status)){
      return <Typography variant="h4">
      CC With Payment Link
      </Typography>
    } else {
      return <Link
              href="#"
              color="inherit"
              variant="body2"
              underline="always"
              className={linkClasses.link}
              onClick={(e) => { 
                e.preventDefault();
                singleDocumentFunc(row.url, row.name,row.type) 
              }}
            >
              {createFileName(row)}
            </Link>
    }
  }

  function createFileName(row) {
    let fileName = row.tag?.toLowerCase() || row.type;
    //if(row.originalFileName){
    //  fileName = fileName + '.' + row.originalFileName.split('.').pop().toLowerCase()
    if(row.name){
      fileName = fileName + '.' + row.name.split('.').pop().toLowerCase()
    } else{
      fileName = fileName + '.pdf'
    }
    return fileName;
  }
  if (rows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.documents} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {rows.map((row, index) => (
                    <StyledTableRow key={index}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: '70%' }}
                      >
                        <Typography variant="h4" className={classes.bold}>
                        {row.tag || row.type}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '30%' }}>
                      {withPaymentLink(row)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow>
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{ width: '70%' }}
                    >
                      <Typography variant="h4" className={classes.bold}>
                        Download all documents (zip format)
                      </Typography>
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '30%' }}>
                      <Link
                        href="#"
                        color="inherit"
                        variant="body2"
                        underline="always"
                        className={linkClasses.link}
                        onClick={(e) => { 
                          e.preventDefault();
                          allDocumentFunc() 
                        }}
                      >
                        {referenceNumber + '.zip'}
                      </Link>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid >
    );
  } else {
    return null;
  }
}

Documents.propTypes = {
  parties: PropTypes.array,
  allDocument: PropTypes.func,
  singleDocument: PropTypes.func,
  allDocumentsData: PropTypes.object,
  singleDocumentsData: PropTypes.object,
  applicationDetail: PropTypes.object
};

export default Documents;

import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import request from 'utils/request';
import { api } from 'environments';
import numeral from 'numeral';
import {
  setAppLoadingAction,
  validateOfferCodeSuccessAction,
  resetOfferCodeAction,
} from 'containers/App/actions';
import {
  makeSelectAuth,
  makeSelectOfferCodeData,
} from 'containers/App/selectors';
import { isEmpty } from 'lodash';
import {
  setQuoteAction,
  setCalculateAction,
  setErrorAction,
  SetCoveragesListAction,
  setQuoteDetailsAction,
  setApplicationIdAction,
  calculateQuoteAction,
  ChangeLifeAssuredAction,
  ChangeCoverageAction,
  setCloneStatusAction,
  setBiPdfAction,
  setEmailSentAction,
  setEmailErrorMessage,
  setCompletedDialogAction,
  setIsCalculateQuotePageAction,
} from './actions';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectQuoteId,
} from './selectors';
import {
  CREATE_QUOTE_ACTION,
  CALCULATE_QUOTE_ACTION,
  GET_QUOTE_ACTION,
  UPDATE_STATUS_ACTION,
  EMAIL_PDF_ACTION,
  CREATE_APPLICATION_ACTION,
  SET_PRODUCT_CONFIG_ACTION,
  CLONE_QUOTE_ACTION,
} from './constants';
import { validateOfferCode } from 'containers/App/saga';

function calculateBody(
  lifeAssured,
  myProductConfig,
  isReversePremiumCalculation,
  quoteId,
  isLifeAssuredEqual,
  offerCodeData,
) {
  const partyDetails = [];

  if (isEmpty(quoteId) || quoteId === undefined) {
    delete lifeAssured.insuredOne.prospectId;
    delete lifeAssured.insuredTwo.prospectId;
  }

  if (lifeAssured.insuredOne.isPolicyOwner)
    lifeAssured.insuredOne.isPrimaryPolicyOwner = true;
  else lifeAssured.insuredOne.isPrimaryPolicyOwner = false;

  partyDetails.push(lifeAssured.insuredOne);
  if (lifeAssured.isInsuredTwo) {
    if (
      !lifeAssured.insuredOne.isPolicyOwner &&
      lifeAssured.insuredTwo.isPolicyOwner
    )
      lifeAssured.insuredTwo.isPrimaryPolicyOwner = true;
    else lifeAssured.insuredTwo.isPrimaryPolicyOwner = false;

    partyDetails.push(lifeAssured.insuredTwo);
  }

  if (
    lifeAssured.isPolicyOwnerIndividual &&
    lifeAssured.policyOwnerIndividual.length
  ) {
    lifeAssured.policyOwnerIndividual.forEach((poInd, index) => {
      if (
        index === 0 &&
        !lifeAssured.insuredOne.isPolicyOwner &&
        !lifeAssured.insuredTwo.isPolicyOwner
      )
        poInd.isPrimaryPolicyOwner = true;
      else poInd.isPrimaryPolicyOwner = false;
      partyDetails.push(poInd);
    });
  }

  const myCoverages = [];
  const myCoverageOne = {};
  const myCoverageTwo = {};
  myCoverageOne.isPrimary = true;

  myProductConfig.coverages.map(product => {
    let isSelected = false;
    if (product.type === '2') {
      if (lifeAssured.isInsuredTwo)
        isSelected = product.valueInsuredOne || product.valueInsuredTwo;
      else isSelected = product.valueInsuredOne;
    } else isSelected = product.selected;

    myCoverageOne[product.id] = {
      selected: isSelected,
      name: product.title,
      value:
        product.type === '1'
          ? numeral(product.valueInsuredOne).value()
          : product.valueInsuredOne,
      permanent: {
        perMille:
          (product.brSelectedInsuredOneValue ||
            product.brSelectedPercentValueOne) &&
          myProductConfig.specialTermsFlag &&
          isSelected
            ? product.perMileOne
            : 0,
        perPercentage:
          (product.brSelectedInsuredOneValue ||
            product.brSelectedPercentValueOne) &&
          myProductConfig.specialTermsFlag &&
          isSelected
            ? product.perPercentageOne
            : 0,
      },
      temporary: {
        perMille:
          myProductConfig.specialTermsFlag && isSelected
            ? numeral(product.perTempMileOne).value()
            : numeral(0).value(),
        perPercentage: numeral(0).value(),
        time:
          myProductConfig.specialTermsFlag && isSelected
            ? numeral(product.perTempMonthOne).value()
            : numeral(0).value(),
      },
    };
    if (lifeAssured.isInsuredTwo) {
      myCoverageTwo[product.id] = {
        selected: isSelected,
        name: product.title,
        value:
          product.type === '1'
            ? numeral(product.valueInsuredTwo).value()
            : product.valueInsuredTwo,
        temporary: {
          perMille:
            myProductConfig.specialTermsFlag && isSelected
              ? numeral(product.perTempMileTwo).value()
              : numeral(0).value(),
          perPercentage: numeral(0).value(),
          time:
            myProductConfig.specialTermsFlag && isSelected
              ? numeral(product.perTempMonthTwo).value()
              : numeral(0).value(),
        },
        permanent: {
          perMille:
            (product.brSelectedInsuredTwoValue ||
              product.brSelectedPercentValueTwo) &&
            myProductConfig.specialTermsFlag &&
            isSelected
              ? product.perMileTwo
              : 0,
          perPercentage:
            (product.brSelectedInsuredTwoValue ||
              product.brSelectedPercentValueTwo) &&
            myProductConfig.specialTermsFlag &&
            isSelected
              ? product.perPercentageTwo
              : 0,
        },
      };
    }
  });

  myCoverages.push(myCoverageOne);

  if (lifeAssured.isInsuredTwo) myCoverages.push(myCoverageTwo);

  let productConfigs = {};

  if (isReversePremiumCalculation) {
    productConfigs = {
      name: myProductConfig.name,
      ptvVersion: myProductConfig.ptvVersion,
      productVersion: myProductConfig.ptvVersion,
      productCode: myProductConfig.productCode,
      currency: myProductConfig.currency,
      term: numeral(myProductConfig.term).value(),
      paymentFrequency: myProductConfig.paymentFrequency,
      premium: numeral(myProductConfig.premium).value(),
      startDate: myProductConfig.startDate,
      offerCodes: [{ ...offerCodeData }],
      coverages: myCoverages,
      fiaCharge: numeral(myProductConfig.fiaCharge).value(),
      specialTermsFlag: myProductConfig.specialTermsFlag,
      interestRate: numeral(myProductConfig.interestRate).value(),
      FMC:
        myProductConfig.FMC === 0
          ? myProductConfig.FMC
          : myProductConfig.FMC || '',
      shortName: myProductConfig.shortName || '',
    };
  } else {
    productConfigs = {
      name: myProductConfig.name,
      ptvVersion: myProductConfig.ptvVersion,
      productVersion: myProductConfig.ptvVersion,
      productCode: myProductConfig.productCode,
      interestRate: numeral(myProductConfig.interestRate).value(),
      currency: myProductConfig.currency,
      term: numeral(myProductConfig.term).value(),
      paymentFrequency: myProductConfig.paymentFrequency,
      startDate: myProductConfig.startDate,
      offerCodes: [{ ...offerCodeData }],
      coverages: myCoverages,
      fiaCharge: numeral(myProductConfig.fiaCharge).value(),
      specialTermsFlag: myProductConfig.specialTermsFlag,
      FMC:
        myProductConfig.FMC === 0
          ? myProductConfig.FMC
          : myProductConfig.FMC || '',
      shortName: myProductConfig.shortName || '',
    };
  }

  const requestBody = {};

  if (!isEmpty(quoteId) && quoteId !== undefined) {
    requestBody.id = quoteId;
    requestBody.partyDetails = partyDetails;
    requestBody.productConfigs = productConfigs;
    requestBody.noUpdatePartyDetails = isLifeAssuredEqual;
  } else {
    requestBody.partyDetails = partyDetails;
    requestBody.productConfigs = productConfigs;
    requestBody.noUpdatePartyDetails = false;
  }

  return requestBody;
}

export function* createQuote(action) {
  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote`;

  const isLifeAssuredEqual = action.payload;
  const quoteId = yield select(makeSelectQuoteId());

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const auth = yield select(makeSelectAuth());
  const offerCodeData = yield select(makeSelectOfferCodeData());

  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    false,
    quoteId,
    isLifeAssuredEqual,
    offerCodeData,
  );

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    if (offerCodeData?.errorMessage !== '') {
      return;
    }
    const response = yield call(request, endpoint, requestOpt);

    if (response.data && response.data?.isValid === undefined) {
      yield put(setQuoteAction(response.data));
      yield put(setAppLoadingAction(false));
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setCompletedDialogAction({ open: true, message: 'ADDED' }));
    }

    if (response.data?.isValid === false) {
      yield put(
        setErrorAction({
          error: true,
          message: response.data.validationMessage,
        }),
      );
    }
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* calculateQuote(action) {
  const isReverseCalculation = action.payload.reverseCalculate;
  const quoteId = yield select(makeSelectQuoteId());
  const {
    isLifeAssuredEqual,
    isCalculateQuotePage,
    isCheckOfferCode,
  } = action.payload;
  yield put(setIsCalculateQuotePageAction(isCalculateQuotePage));
  if (!isReverseCalculation) yield put(setCalculateAction(0));

  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/calculate`;

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const auth = yield select(makeSelectAuth());
  const offerCodeData = yield select(makeSelectOfferCodeData());

  let offerDataAfterCheck = {};
  if (isCheckOfferCode && !isReverseCalculation) {
    yield call(validateOfferCode, {
      payload: {
        ...myProductConfig,
        premium: Number(myProductConfig?.coverages[0]?.valueInsuredOne),
        productVersion: myProductConfig.ptvVersion,
        premiumType: myProductConfig.term,
      },
    });
    offerDataAfterCheck = yield select(makeSelectOfferCodeData());
  }

  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    isReverseCalculation,
    quoteId,
    isLifeAssuredEqual,
    isCheckOfferCode ? offerDataAfterCheck : offerCodeData,
  );

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      if (response.data.isValid) {
        yield put(setErrorAction({ error: false, message: [] }));
        if (isReverseCalculation) {
          const myNewCoverages = [];
          myProductConfig.coverages.map(coverage => {
            if (coverage.id === 'LFC') {
              const valueInsuredTerm = numeral(
                response.data.response.SumInsuredTerm,
              ).format('00');
              coverage.valueInsuredOne = valueInsuredTerm;
              coverage.valueInsuredTwo = valueInsuredTerm;
              myNewCoverages.push(coverage);
            } else {
              if (coverage.id === 'WOP') {
                coverage.selected = true;
              } else {
                coverage.valueInsuredOne = null;
                coverage.valueInsuredTwo = null;
                coverage.selected = false;
              }
              myNewCoverages.push(coverage);
            }
          });
          yield call(validateOfferCode, {
            payload: {
              ...myProductConfig,
              premium: response.data.response.SumInsuredTerm,
              productVersion: myProductConfig.ptvVersion,
              premiumType: myProductConfig.term,
            },
          });
          yield put(SetCoveragesListAction(myNewCoverages));
        } else {
          yield put(setCalculateAction(response.data.response));
        }
      } else {
        yield put(setCalculateAction(0));
        yield put(
          setErrorAction({ error: true, message: response.data.errorList }),
        );
      }
    }
  } catch (err) {
    if (err.response) {
      const response = yield err.response.json();
      if (response.message && response.error)
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.message }],
          }),
        );
      else
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.errorCode }],
          }),
        );
    } else {
    }
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* updateQuoteStatus(action) {
  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/status`;

  const auth = yield select(makeSelectAuth());

  const quoteId = yield select(makeSelectQuoteId());
  let myBody = {};
  if (action.payload === 1) {
    myBody = {
      quoteId,
      isPreview: true,
    };
  }

  if (action.payload === 2) {
    myBody = {
      quoteId,
      isAgree: true,
    };
  }

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (
      !response.error &&
      !response?.data?.productVersionOutOfDate &&
      !response?.data?.missingKFS
    ) {
      yield call(getQuote);
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
      let { message } = response;
      if (response?.data?.productVersionOutOfDate) {
        message = 'The Quote is outdated. Please regenerate new one.';
      }

      if (response?.data?.missingKFS) {
        message = 'The Quote is invalid. Please regenerate new one.';
      }
      yield put(
        setErrorAction({
          error: true,
          message: [{ message }],
        }),
      );
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());

  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${quoteId}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
      if (action && action.payload != undefined) {
        yield put(resetOfferCodeAction());
        const lifeInsuredData = {};
        lifeInsuredData.policyOwnerIndividual = [];
        response.data.prospects.map(productData => {
          const partyDetails = {
            prospectId: productData.id,
            title: productData.title,
            firstName: productData.firstName,
            lastName: productData.lastName,
            dateOfBirth: productData.dateOfBirth,
            gender: productData.gender.toString(),
            nationality: productData.nationality,
            isSmoke: productData.isSmoke,
            isLifeInsured: productData.prospectquoterelationships.isLifeInsured,
            isPrimaryLifeInsured:
              productData.prospectquoterelationships.isPrimaryLifeInsured,
            isPolicyOwner: productData.prospectquoterelationships.isPolicyOwner,
            relationshipToLifeInsured: productData.relationshipToLifeInsured,
          };

          if (partyDetails.isLifeInsured && partyDetails.isPrimaryLifeInsured)
            lifeInsuredData.insuredOne = partyDetails;

          if (
            partyDetails.isLifeInsured &&
            !partyDetails.isPrimaryLifeInsured
          ) {
            lifeInsuredData.isInsuredTwo = true;
            lifeInsuredData.insuredTwo = partyDetails;
          }

          if (!partyDetails.isLifeInsured) {
            lifeInsuredData.isPolicyOwnerIndividual = true;
            lifeInsuredData.policyOwnerIndividual.push(partyDetails);
          }
        });
        yield put(ChangeLifeAssuredAction(lifeInsuredData));

        const existingProductConfigData = yield select(
          makeSelectProductConfig(),
        );
        const data = {};
        if (response.data.productConfigs) {
          const offerCodes =
            response.data.productConfigs?.offerCodes &&
            response.data.productConfigs?.offerCodes[0];
          if (offerCodes) {
            yield put(
              validateOfferCodeSuccessAction({
                code: offerCodes.code ? offerCodes.code : '',
                discountRate: offerCodes.discountRate
                  ? offerCodes.discountRate
                  : 0,
                discountMessage: offerCodes.discountMessage
                  ? offerCodes.discountMessage
                  : {},
                validating: false,
              }),
            );
          }

          data.name = response.data.productConfigs.name;
          data.productCode = response.data.productConfigs.productCode;
          data.ptvVersion = response.data.productConfigs.ptvVersion;
          data.specialTermsFlag = response.data.productConfigs.specialTermsFlag;
          data.startDate = response.data.productConfigs.startDate;
          data.createdAt = response.data.createdAt;
          data.requiredKFS = response.data.productConfigs.requiredKFS;
          data.currency = response.data.productConfigs.currency;
          data.term = response.data.productConfigs.term;
          data.interestRate = response.data.productConfigs.interestRate;
          data.paymentFrequency = response.data.productConfigs.paymentFrequency;
          data.offerCode = offerCodes ? offerCodes.code : '';
          data.annualizedPremium =
            action.payload != 2
              ? response.data.pricingData.response.annualizedPremium
              : 0;
          data.premium =
            action.payload != 2
              ? response.data.pricingData.response.premium
              : 0;
          data.slicedPremium =
            response.data.pricingData.response?.slicedPremium || {};
          const myCoverages = [];
          existingProductConfigData.coverages.map(myBenefit => {
            response.data.productConfigs.coverages.map(responseBenefit => {
              const responseObject = responseBenefit[myBenefit.id];
              if (responseObject) {
                if (responseObject.selected) myBenefit.selected = true;
                if (responseBenefit.isPrimary) {
                  myBenefit.valueInsuredOne = responseObject.value;
                  myBenefit.perMileOne = responseObject.permanent.perMille;
                  myBenefit.perPercentageOne =
                    responseObject.permanent.perPercentage;
                  myBenefit.perTempMileOne = responseObject.temporary.perMille;
                  myBenefit.perTempMonthOne = responseObject.temporary.time;

                  if (responseObject.permanent.perMille != '0') {
                    myBenefit.brSelectedInsuredOneValue = true;
                  }

                  if (responseObject.permanent.perPercentage != '0') {
                    myBenefit.brSelectedPercentValueOne = true;
                  }

                  if (responseObject.temporary.perMille != '0') {
                    myBenefit.brSelectedTempValueOne = true;
                  }
                } else {
                  myBenefit.valueInsuredTwo = responseObject.value;
                  myBenefit.perMileTwo = responseObject.permanent.perMille;
                  myBenefit.perPercentageTwo =
                    responseObject.permanent.perPercentage;
                  myBenefit.perTempMileTwo = responseObject.temporary.perMille;
                  myBenefit.perTempMonthTwo = responseObject.temporary.time;

                  if (responseObject.permanent.perMille != '0') {
                    myBenefit.brSelectedInsuredTwoValue = true;
                  }

                  if (responseObject.permanent.perPercentage != '0') {
                    myBenefit.brSelectedPercentValueTwo = true;
                  }

                  if (responseObject.temporary.perMille != '0') {
                    myBenefit.brSelectedTempValueTwo = true;
                  }
                }
              }
            });
            myCoverages.push(myBenefit);
          });
          data.coverages = myCoverages;

          if (action.payload != 2) {
            const details = {
              referenceNumber: response.data.referenceNumber,
              benefitIllustrationPdf: response.data.benefitIllustrationPdf,
              status: response.data.status,
            };
            yield put(setQuoteDetailsAction(details));
            yield call(
              getPdf,
              response.data.benefitIllustrationPdf.split('=')[1],
            );
          }
        }
        yield put(ChangeCoverageAction(data));
      } else {
        const details = {
          referenceNumber: response.data.referenceNumber,
          benefitIllustrationPdf: response.data.benefitIllustrationPdf,
          status: response.data.status,
        };
        yield put(setQuoteDetailsAction(details));
        yield call(getPdf, response.data.benefitIllustrationPdf.split('=')[1]);
      }
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getPdf(fileName) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/storage/create`;

  const updatedPayload = {
    type: 'FILE',
    name: fileName,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(updatedPayload),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!isEmpty(response)) {
      yield put(setAppLoadingAction(false));
      yield put(setBiPdfAction(`${response.previewUrl}`));
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* emailQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/bi-pdf-email`;

  const myBody = {
    quoteId,
    email: action.payload,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
    yield put(setEmailSentAction(false));
  } catch (err) {
    const response = yield err.response.json();
    if (response?.code === 'TOO_MANY_REQUESTS') {
      yield put(setEmailSentAction(true));
      yield put(setEmailErrorMessage(response.message));
    }
    yield put(setAppLoadingAction(false));
  }
}

export function* createApplication() {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application`;

  const myBody = {
    quoteId,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (
      response.data &&
      !response?.data?.productVersionOutOfDate &&
      !response?.data?.missingKFS
    ) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setApplicationIdAction(response.data.id));
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
      let { message } = response;
      if (response?.data?.productVersionOutOfDate) {
        message = 'The Quote is outdated. Please regenerate new one.';
      }

      if (response?.data?.missingKFS) {
        message = 'The Quote is invalid. Please regenerate new one.';
      }
      yield put(
        setErrorAction({
          error: true,
          message: [{ message }],
        }),
      );
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* cloneQuote() {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${quoteId}/quote-reference`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setCloneStatusAction(true));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* handleUpdateCoverage(action) {
  const {
    recalculateQuote,
    reverseCalculate,
    isCalculateQuotePage,
    isLifeAssuredEqual,
    isCheckOfferCode,
  } = action.payload;
  if (recalculateQuote) {
    yield put(
      calculateQuoteAction({
        reverseCalculate,
        isLifeAssuredEqual,
        isCalculateQuotePage,
        isCheckOfferCode,
      }),
    );
  }
}

// Individual exports for testing
export default function* dtaIllustrationPageSaga() {
  yield all([
    takeLatest(CREATE_QUOTE_ACTION, createQuote),
    takeLatest(CALCULATE_QUOTE_ACTION, calculateQuote),
    takeLatest(UPDATE_STATUS_ACTION, updateQuoteStatus),
    takeLatest(GET_QUOTE_ACTION, getQuote),
    takeLatest(EMAIL_PDF_ACTION, emailQuote),
    takeLatest(CREATE_APPLICATION_ACTION, createApplication),
    takeLatest(SET_PRODUCT_CONFIG_ACTION, handleUpdateCoverage),
    takeLatest(CLONE_QUOTE_ACTION, cloneQuote),
  ]);
}

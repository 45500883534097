import {
  takeLatest,
  call,
  put,
  all,
  select,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import request from 'utils/request';
import { api } from 'environments';
import {
  CALL_INITIAL_SESSION,
  GET_APPLICATION_DETAILS_ACTION,
  UPDATE_APPLICATION_DETAILS_ACTION,
  UPDATE_APPLICATION_PARTY_ACTION,
  UPDATE_APPLICATION_PARTIES_ACTION,
  ADD_APPLICATION_PARTY_ACTION,
  ADD_APPLICATION_PARTIES_ACTION,
  UPDATE_APPLICATION_STATUS_ACTION,
  CALL_FINANCIAL_INSTITUTE_OPTION,
  GET_LIST_APPLICATION_PARTY_ACTION,
  GET_APPLICATION_DATA_ACTION,
  GET_ADMIN_ACTION_DETAIL_ACTION,
  GET_SUBMIT_ADMIN_ACTION,
  SEND_ADMIN_ACTION_EMAIL_ACTION,
  SEND_EMAIL_ACTION,
  GET_SEARCH_ACTION,
  GET_ALL_DOCUMENTS_ACTION,
  GET_SINGLE_DOCUMENTS_ACTION
} from './constants';
import {
  makeSelectApplicationSubOrder,
  makeSelectApplicationDetails,
} from './selectors';
import { getProductConfigAction } from 'containers/IllustrationPage/actions';
import { makeSelectProductConfig } from 'containers/IllustrationPage/selectors';
import { setAppLoadingAction } from 'containers/App/actions';
import {
  setUnderwritingSections,
  setApplicationSubOrders,
  setApplicationDetailsAction,
  setLIPartiesAction,
  setFinancialInstituteOptionAction,
  setListApplicationPartyAction,
  setIllustrationsAction,
  setApplicationDataAction,
  setAdminActionDetailAction,
  setSubmitAdminAction,
  sendAdminActionEmailAction,
  setAllDocumentsAction,
  setSingleDocumentsAction
} from './actions';
import { makeSelectAuth } from 'containers/App/selectors';

export function* financialInstitute() {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/financial-institutes`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    yield put(setFinancialInstituteOptionAction(response.data));
  } catch (err) {
    console.log(err);
  }
}

export function* callInitialSession(payload) {
  const { applicationId } = payload.payload;
  const subPageApplicationForm = convertIfImmutable(
    yield select(makeSelectApplicationSubOrder()),
  );
  const auth = yield select(makeSelectAuth());
  const applicationDetails = yield select(makeSelectApplicationDetails());
  const parsedApplicationDetails = convertIfImmutable(applicationDetails);
  const endpoint = `${api.host}/api/v${api.version}/b2b/uw/sections/${applicationId}`;
  // const endpoint = `${api.host}/api/v1/b2b/uw/session`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    // yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setLIPartiesAction(response.data));

    // merge the subPageApplicationForm and underwriting

    let UWSections = [];
    let newPaymentSubOrder = [];
    // this is always based on the policy details sub order section number
    let startId = subPageApplicationForm[0].subOrder.length + 1; // 1 represents the consent page for UW at the first page
    let newSubOrder = [];

    forEach(response.data, (LIParty, index1) => {
      forEach(LIParty.sections, section => {
        // if LIParty is more than 1 Party add a ${section.name} + value length to it
        if (response.data.length > 1) {
          newSubOrder.push({
            order: startId + 1,
            text: `${section.name === 'Underwriting Questions'
              ? 'Questions'
              : section.name
              } L${index1 + 1}`,
            id: section.id,
            name:
              section.name === 'Underwriting Questions'
                ? 'Questions'
                : section.name,
            sessionId: LIParty.sessionId,
            partyId: LIParty.partyId,
            eTag: LIParty.eTag,
          });
          startId += 1;
        } else {
          //normal

          newSubOrder.push({
            order: startId + 1,
            text: `${section.name === 'Underwriting Questions'
              ? 'Questions'
              : `${section.name} ${LIParty?.isPrimaryLifeInsured ? 'L1' : 'L2'
              }`
              }`,
            id: section.id,
            name:
              section.name === 'Underwriting Questions'
                ? 'Questions'
                : section.name,
            sessionId: LIParty.sessionId,
            partyId: LIParty.partyId,
            eTag: LIParty.eTag,
          });
          startId += 1;
        }
      });
    });
    UWSections = newSubOrder;
    // the last 2 sub order sections
    // newSubOrder = [...newSubOrder, { order: startId, text: 'Consent' }];
    newSubOrder = [
      ...newSubOrder,
      {
        order: startId + 1,
        text: 'Submit',
        isHidden: productCodeCheck(6, parsedApplicationDetails.productCode),
      },
    ];
    newSubOrder = [
      ...newSubOrder,
      {
        order: startId + 2,
        text: 'Decision',
        isHidden: productCodeCheck(6, parsedApplicationDetails.productCode),
      },
    ];

    // payment
    newPaymentSubOrder = [
      ...newPaymentSubOrder,
      { order: startId + 3, text: 'Payment', isHidden: true },
    ];
    newPaymentSubOrder = [
      ...newPaymentSubOrder,
      { order: startId + 4, text: 'Payment method' },
    ];
    newPaymentSubOrder = [
      ...newPaymentSubOrder,
      { order: startId + 5, text: 'Review' },
    ];

    // push it to the copied applicationsubOrder/underwriting list
    subPageApplicationForm[1].subOrder = newSubOrder;
    if (
      productCodeCheck(6, parsedApplicationDetails.productCode) &&
      !parsedApplicationDetails?.isBolton
    ) {
      subPageApplicationForm[1].text = 'Summary';
    }
    subPageApplicationForm[4].subOrder = newPaymentSubOrder;
    yield put(setUnderwritingSections(UWSections));
    yield put(changeSubPageListAction(subPageApplicationForm));
    yield put(setApplicationSubOrders(subPageApplicationForm));
    yield put(setAppLoadingAction(false));
    yield call(getApplicationDetails, { payload: applicationId });
  } catch (err) {
    console.log(err);
  }
}

export function* getApplicationDetails(payload) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${payload.payload}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    yield put(setAppLoadingAction(true));

    const response = yield call(request, endpoint, requestOpt);
    const existingProductConfigData = yield select(makeSelectProductConfig());
    const isBolton = response.data?.isBolton;

    const productConfigs = isBolton
      ? response.data.productConfigs[0]
      : response.data.productConfigs;
    const { productCode, productVersion } = productConfigs;

    if (
      productConfigs?.policyCoverTerm === 'MinimumPremium' &&
      existingProductConfigData.productVersion !== productVersion
    ) {
      // get productConfig to display the coverage term
      yield call(
        getProductConfig,
        getProductConfigAction({ productCode, productVersion }),
      );

      const productConfigData = yield select(makeSelectProductConfig());
      productConfigs.policyCoverTerm =
        productConfigData?.features?.policyTerm?.minTerm;
    }

    yield put(setApplicationDetailsAction(response.data));
    // if (response.data.productCode !== 'FUTU5') {
    //   subPageApplicationForm[0].subOrder = filter(
    //     subPageApplicationForm[0].subOrder,
    //     n => {
    //       return n.text !== 'Investment';
    //     },
    //   );
    //   yield put(setApplicationSubOrders(subPageApplicationForm));
    // }
    yield put(setAppLoadingAction(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getProductConfig(action) {
  yield put(setAppLoadingAction(true));
  const isSecondaryProduct = action.payload?.isSecondaryProduct;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/product?productCode=${action.payload.productCode
    }&productVersion=${action.payload.productVersion}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setAppLoadingAction(false));
      if (isSecondaryProduct) {
        yield put(setProductConfig2Action(response.data));
      } else {
        yield put(setProductConfigAction(response.data));
      }
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* cloneQuote(action) {
  yield put(setAppLoadingAction(true));

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${action.payload}/quote-reference`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setProductIdAction(response.data));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* updateDetails(action) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application`;
  delete action.payload.pricingData;
  delete action.payload.parties;
  const requestOpt = {
    method: 'PUT',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },

    body: JSON.stringify(action.payload),
  };

  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    // get the latest updated values
    if (response) {
      yield call(getApplicationDetails, { payload: response.data.id });
      yield put(setAppLoadingAction(false));
      if (action.isLogout) {
        yield put(setLogoutWhenUpdateAppAction(true));
      }
    }
  } catch (err) {
    console.log(err);
    yield call(handleUnsupportedCharError, err);
  }
}

export function* updateApplicationParty(payload) {
  const auth = yield select(makeSelectAuth());
  const { party, applicationId } = payload;
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/party/${party.id}`;
  const requestOpt = {
    method: 'PUT',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },

    body: JSON.stringify(party),
  };

  try {
    yield call(request, endpoint, requestOpt);
    // get the latest updated values
    // if (response) {
    //   yield call(getApplicationDetails, { payload: applicationId });
    // }
  } catch (err) {
    yield call(handleUnsupportedCharError, err);
  }
}

export function* updateApplicationParties(payload) {
  yield put(setAppLoadingAction(true));
  const { parties, applicationId } = payload;
  // const updateParties = [];

  try {
    if (parties.length) {
      //   forEach(parties, party => {
      //     /* eslint-disable redux-saga/yield-effects */
      //     updateParties.push(
      //       call(updateApplicationParty, { party, applicationId }),
      //     );
      //   });
      // }
      // yield all([...updateParties]);

      for (const party of parties) {
        yield call(updateApplicationParty, { party, applicationId });
      }
    }
    yield call(getApplicationDetails, { payload: applicationId });
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* addApplicationParty(payload) {
  yield delay(1200);
  const auth = yield select(makeSelectAuth());
  const { party, applicationId } = payload;
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/party`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },

    body: JSON.stringify(party),
  };

  try {
    yield put(setAppLoadingAction(true));
    yield call(request, endpoint, requestOpt);
    // get the latest updated values
    yield call(getApplicationDetails, { payload: applicationId });
  } catch (err) {
    console.log(err);
    yield call(handleUnsupportedCharError, err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* addApplicationParties(payload) {
  yield put(setAppLoadingAction(true));
  const { parties, applicationId } = payload;

  // const addParties = [];
  if (parties.length) {
    // forEach(parties, party => {
    //   /* eslint-disable redux-saga/yield-effects */
    //   addParties.push(call(addApplicationParty, { party, applicationId }));
    // });
    for (const party of parties) {
      yield call(addApplicationParty, { party, applicationId });
    }
  }
  // yield all([...addParties]);
  yield call(getApplicationDetails, { payload: applicationId });

  try {
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* updateApplicationStatus(payload) {
  const {
    statusName,
    status,
    applicationId,
    filteredFundList,
    nextPage,
    isDelay,
  } = payload;
  if (isDelay) yield delay(3000);
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());

  const partyStatus = { id: applicationId, [statusName]: status };
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/update-status`;
  const requestOpt = {
    method: 'PUT',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },

    body: JSON.stringify(partyStatus),
  };

  try {
    yield put(
      setErrorAction({
        message: '',
      }),
    );
    yield call(request, endpoint, requestOpt);
    if (nextPage) {
      yield put(
        push({
          pathname: `/applications/${applicationId}/form/${nextPage}`,
        }),
      );
    }
  } catch (err) {
    const response = yield err.response.json();
    if (response.message === 'POLICY_DETAILS_NOT_COMPLETED') {
      yield put(
        setErrorAction({
          message: messages.policyDetailFail,
        }),
      );
      yield put(
        push({
          pathname: `/applications/${applicationId}/form/policy-owner`,
        }),
      );
    }

    if (response.message === 'BENEFICIARY_NOT_COMPLETED') {
      yield put(
        setErrorAction({
          message: messages.beneficiaryFail,
        }),
      );
      yield put(
        push({
          pathname: `/applications/${applicationId}/form/beneficiaries`,
        }),
      );
    }
  } finally {
    if(filteredFundList?.ASPFundList?.length > 0) {
      yield call(updateApplicationFund, {
        payload: {
          // fundList: filteredFundList?.FundList ? filteredFundList?.FundList : [],
          // ASPFundList: filteredFundList?.ASPFundList ? filteredFundList?.ASPFundList : [],
          filteredFundList,
          applicationId,
        },
      });
    }
    else if (filteredFundList?.FundList?.length > 0) {
      yield call(updateApplicationFund, {
        payload: {
          fundList: filteredFundList,
          applicationId,
        },
      });

      yield call(generateApplicationPdf, {
        payload: { id: applicationId },
      });
    }
    yield call(getApplicationDetails, { payload: applicationId });
    yield put(setAppLoadingAction(false));
  }
}

export function* getCustomers(action) {
  yield put(setAppLoadingAction(true));

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/get-parties?searchText=${action.payload.searchText}&pageSize=20&pageNumber=1`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    // TODO: PASS THE CORRECT DATA FORMAT INTO REDUX STATE
    yield put(setCustomersAction(response.data));
  } catch (err) {
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* getQuotes(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v1/b2b/prospect/${action.payload.id}/with-li-quotes`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    // TODO: PASS THE CORRECT DATA FORMAT INTO REDUX STATE
    yield put(setQuotesAction(response.data));
  } catch (err) {
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* getApplicationListParty(payload) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/get-applications-for-admin?searchText=${sessionStorage.getItem('AgenciesAssociatedForAdmin')}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setListApplicationPartyAction(response));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    console.log(err);
    yield put(setListApplicationPartyAction([]));
    yield put(setAppLoadingAction(false));
  }
}

export function* getSearch(action) {
  yield put(setAppLoadingAction(true));

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v1/b2b/agency/${action.payload.agencyNumber}/illustrations?searchText=${action.payload.valueSearch}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    yield put(setIllustrationsAction(response));
  } catch (err) {
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* emailQuote(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/bi-pdf-email`;

  const myBody = {
    quoteId: action.payload.id,
    email: action.payload.email,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* deleteIllustration(action) {
  const auth = yield select(makeSelectAuth());
  const {
    payload: { id, productVersion, productCode },
  } = action;
  const endpoint = `${api.host}/api/v${api.version}/b2b/prospect/${id}/delete-li-quotes`;

  const myBody = {
    productCode,
    productVersion: productVersion || '',
  };

  const requestOpt = {
    method: 'DELETE',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    const message = response.message;
    const data = { idDelete: id, message: message };
    yield put(setDeleteIllustrationAction(data));
  } catch (err) {
    console.log(err);
  }
}

function* handleUnsupportedCharError(err) {
  const response = yield err?.response?.json();
  console.log('response', response);
  if (response?.message === 'There was Unsupported chars.') {
    let problems = '';
    if (response.problems) {
      response.problems.forEach(item => (problems += `\n${item.value}.`));
    }
    yield put(
      setErrorAction({
        error: true,
        message: messages.unsupportedChars,
        values: { value: problems },
      }),
    );
  }
}


export async function sendAdminEmail(action) {
  let auth = sessionStorage.getItem('oscarAuth');
  let oscarAuth = JSON.parse(auth);
  return new Promise((resolve, rejected) => {
    const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/send-admin-agent-email`;
    const myBody = {
      applicationId: action.applicationID,
      type: action.applicationStatus,
    }
    fetch(endpoint, {
      method: "POST",
      body: JSON.stringify(myBody),
      headers: {
        'Ocp-Apim-Subscription-Key': api.subKey,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${oscarAuth.oktaAccessToken}`,
      }
    }).then(async (res) => {
      const response = await res.json();
      resolve(response);
      return response;
    }).catch((err) => {
      console.log(err, "//err")
      rejected(err);
    })
  })
}


export function* getApplicationData(payload) {
  const auth = yield select(makeSelectAuth());
  const id = payload.payload;
  const endpoint = `${api.host}/api/v1/b2b/adminapplication/${id}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    const existingProductConfigData = yield select(makeSelectProductConfig());
    const isBolton = response.data?.isBolton;
    const productConfigs = isBolton
      ? response.data.productConfigs[0]
      : response.data.productConfigs;
    const { productCode, productVersion } = productConfigs;
    if (
      productConfigs?.policyCoverTerm === 'MinimumPremium' &&
      existingProductConfigData.productVersion !== productVersion
    ) {
      // get productConfig to display the coverage term
      yield call(
        getProductConfig,
        getProductConfigAction({ productCode, productVersion }),
      );
      const productConfigData = yield select(makeSelectProductConfig());
      productConfigs.policyCoverTerm =
        productConfigData?.features?.policyTerm?.minTerm;
    }

    yield put(setApplicationDataAction(response.data));

    yield put(setAppLoadingAction(false));
  } catch (err) {
    yield put(setApplicationDataAction([]));
    yield put(setAppLoadingAction(false));
  }
}

export function* getAdminActionDetails(payload) {
  const auth = yield select(makeSelectAuth());
  const id = payload.payload;
  const endpoint = `${api.host}/api/v1/b2b/admin-action/${id}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setAdminActionDetailAction(response.data));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    yield put(setAdminActionDetailAction([]));
    yield put(setAppLoadingAction(false));
  }
}

export function* submitAdminAction(payload) {
  const auth = yield select(makeSelectAuth());

  const endpoint = `${api.host}/api/v${api.version}/b2b/admin-action`;
  const myBody = {
    applicationId: payload.payload.applicationId,
    referenceNumber: payload.payload.referenceNumber,
    agentNumber: payload.payload.agentNumber,
    applicationStatus: payload.payload.applicationStatus,
    reason: payload.payload.reason,
    isInforce : payload.payload.isInforce
  }
  sessionStorage.setItem("agentNumber", payload.payload.agentNumber);
  const requestOpt = {
    method: 'POST',
    body: JSON.stringify(myBody),
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* sendAdminActionEmail(payload) {
  const auth = yield select(makeSelectAuth());

  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/send-admin-agent-email`;
  const myBody = {
    applicationId: payload.payload.applicationId,
    type: payload.payload.type,
  }
  const requestOpt = {
    method: 'POST',
    body: JSON.stringify(myBody),
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}



export function* getAllDocuments(payload) {
  const auth = yield select(makeSelectAuth());
  const id = payload.payload;

  const endpoint = `${api.host}/api/v${api.version}/b2b/document/application/${id}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/zip',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setAllDocumentsAction((response)));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    yield put(setAllDocumentsAction([]));
    yield put(setAppLoadingAction(false));
  }
}

export function* getSingleDocuments(payload) {
  const auth = yield select(makeSelectAuth());
  const name = payload.payload;

  const endpoint = `${api.host}/api/v${api.version}/b2b/file?name=${name}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/octet-stream',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setSingleDocumentsAction(response));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    yield put(setSingleDocumentsAction([]));
    yield put(setAppLoadingAction(false));
  }
}

// Individual exports for testing
export default function* OpsDashboardPageSaga() {

  yield all([
    takeEvery(GET_APPLICATION_DETAILS_ACTION, getApplicationDetails),
    takeLatest(GET_APPLICATION_DATA_ACTION, getApplicationData),
    takeLatest(GET_ADMIN_ACTION_DETAIL_ACTION, getAdminActionDetails),
    takeEvery(GET_SUBMIT_ADMIN_ACTION, submitAdminAction),
    takeEvery(SEND_ADMIN_ACTION_EMAIL_ACTION, sendAdminActionEmail),
    takeEvery(GET_ALL_DOCUMENTS_ACTION, getAllDocuments),
    takeEvery(GET_SINGLE_DOCUMENTS_ACTION, getSingleDocuments),
    takeLatest(CALL_FINANCIAL_INSTITUTE_OPTION, financialInstitute),
    takeEvery(UPDATE_APPLICATION_STATUS_ACTION, updateApplicationStatus),
    takeLatest(ADD_APPLICATION_PARTIES_ACTION, addApplicationParties),
    takeLatest(ADD_APPLICATION_PARTY_ACTION, addApplicationParty),
    takeLatest(UPDATE_APPLICATION_PARTIES_ACTION, updateApplicationParties),
    takeLatest(UPDATE_APPLICATION_PARTY_ACTION, updateApplicationParty),
    takeEvery(UPDATE_APPLICATION_DETAILS_ACTION, updateDetails),
    takeLatest(CALL_INITIAL_SESSION, callInitialSession),
    takeEvery(GET_LIST_APPLICATION_PARTY_ACTION, getApplicationListParty),
    takeLatest(SEND_EMAIL_ACTION, emailQuote),
    takeLatest(GET_SEARCH_ACTION, getSearch),

  ]);
}

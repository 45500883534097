import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import messages from 'containers/ApplicationFormPage/messages';
import { SubmissionError } from 'redux-form/immutable'; // ES6
import moment from 'moment';

// validators
import isEmail from 'validator/lib/isEmail';

import { isEmpty } from 'lodash/lang';
import { forEach, map } from 'lodash/collection';
import { keys } from 'lodash/object';
import { isFieldEmpty } from 'utils/form';

const validate = (values, props) => {
  let errors = {};
  const { questions } = props;
  const sectionName = props.section;
  if (!values.isEmpty()) {
    const parsedValue = values.toJS();
    const listOfKeys = keys(parsedValue);
    const fieldArrayErrors = [];

    forEach(listOfKeys, (field, index) => {
      if (isFieldEmpty(parsedValue[field])) {
        fieldArrayErrors[field] = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      }

      // PERCENT question type

      // get the id number in the field
      const id = field.split('-')[1];

      forEach(questions[sectionName], question => {
        if (question.type === 'PERCENT' && parseInt(id) === question.id) {
          let totalPercentage = 0;

          forEach(parsedValue[field], v => {
            totalPercentage += v.amount;
          });

          if (totalPercentage > 100 || totalPercentage < 100) {
            fieldArrayErrors[field] = (
              <FormattedMessage {...messages.allowedPercentage100} />
              // <FormattedMessage {...messages.maximumPercentage100Allowed} />
            );
          }
        }

        if (question.type === 'DATE' && parseInt(id) === question.id) {
          if (
            parsedValue[field].format === 'EXACT_DATE' ||
            parsedValue[field].format === 'MONTH_YEAR'
          ) {
            if (parsedValue[field].date) {
              const dateInput = moment(parsedValue[field].date);
              if (!parsedValue[field].date.includes('_')) {
                if (!dateInput.isValid()) {
                  fieldArrayErrors[field] = (
                    <FormattedMessage {...messages.isNotValidDate} />
                  );
                }
              }
            }
          }
        }
      });
    });

    if (keys(fieldArrayErrors).length > 0) {
      errors = fieldArrayErrors;
    }
  }
  return errors;
};

export default validate;

/**
 *
 * QuoteForm
 *
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Card,
  Divider,
  Box,
  FormHelperText,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Map } from 'immutable';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncWarnings,
} from 'redux-form/immutable';
import { WrappedFieldArrayProps, InjectedFormProps, submit } from 'redux-form';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import {
  AddCircle,
  KeyboardArrowUp,
  KeyboardArrowDown,
  CheckCircle,
} from '@material-ui/icons';
import FormPremium from 'components/FormPremium';
import { formatMonetaryValue } from 'helpers/math';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import { isEmpty } from 'lodash';
import LoadingDialog from 'components/LoadingDialog';
import Benefits from './Benefits';
import messages from '../../messages';
import validate from './validate';

const HeaderContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(3.25)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    padding-right: ${props => props.theme.spacing(1)}px;
    background-color: ${props => props.theme.palette.primary.dark};
    color: ${props => props.theme.palette.common.white};
  }
`;

const BrContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(0.5)}px;
    padding-bottom: ${props => props.theme.spacing(0.5)}px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.common.white};
  }
`;

const BottomRow = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 60px;
    padding: ${props => props.theme.spacing(1)}px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
  }
`;

function QuoteForm({
  premium,
  originalPremium,
  itaConfig,
  currency,
  paymentFrequency,
  specialTermsFlag,
  coverages,
  lifeAssured,
  handleSubmit,
  onSubmit,
  change,
  formValues,
  warningCoverages,
  onUpdateValues,
  location,
  offerCodeData,
  offerCode,
  annualizedPremium,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });
  const [isBrShowing, setBrShowing] = useState(false);

  let isCoveragesError = false;
  if (!isEmpty(warningCoverages)) {
    warningCoverages.coverages.map(warningBenefit => {
      if (!isEmpty(warningBenefit)) {
        isCoveragesError = true;
      }
    });
  }

  let minPremium = 0;
  let maxPremium = 0;
  itaConfig.features.currencyPremiumSize.map(val => {
    if (currency === val.currency) {
      if (paymentFrequency === 'Monthly') {
        minPremium = val.minPremiumMonthly;
        maxPremium = val.maxPremiumMonthly;
      } else {
        minPremium = val.minPremiumYearly;
        maxPremium = val.maxPremiumYearly;
      }
    }
  });

  const disabledUpdate = premium < minPremium;

  let monthlyPremium = 0;
  // let annualPremium = 0;

  if (paymentFrequency === 'Monthly') {
    monthlyPremium = formatMonetaryValue(premium, true);
    // annualPremium = formatMonetaryValue(premium * 12, true);
  } else {
    monthlyPremium = formatMonetaryValue(premium / 12, true);
    // annualPremium = formatMonetaryValue(premium, true);
  }
  let aspFrequencyLabel = '';

  switch (paymentFrequency) {
    case 'Monthly':
      aspFrequencyLabel = 'per month';
      break;
    case 'Quarterly':
      aspFrequencyLabel = 'per quarter';
      break;
    case 'Half-Yearly':
      aspFrequencyLabel = 'per half-year';
      break;
    case 'Yearly':
      aspFrequencyLabel = 'per year';
      break;
    default:
      aspFrequencyLabel = 'per month';
      break;
  }
  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-20px' }}>
          <Card>
            <HeaderContent theme={theme}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography component="span" variant="h1">
                    {currency}
                  </Typography>
                </Grid>
                <Grid item>
                  <span
                    id="primaryPremiumAmount"
                    style={{
                      color: 'inherit',
                      fontSize: '6.6rem',
                      lineHeight: 1,
                      fontWeight: 700,
                    }}
                  >
                    {formatMonetaryValue(premium, true)}
                    {/* {paymentFrequency === 'Monthly'
                      ? monthlyPremium
                      : annualPremium} */}
                  </span>
                  <Typography color="inherit" variant="body1" component="span">
                    {' '}
                    {aspFrequencyLabel}
                  </Typography>
                </Grid>
              </Grid>
              {/* ########## START  :: EDIT PREMIUM ########## */}
              <Collapse in={open}>
                <Box pt={1}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item xs>
                      {/* <Field
                      id="premium"
                      name="premium"
                      component={FormSlider}
                      min={minPremium}
                      max={maxPremium}
                      diff={50}
                      isPrimary={true}
                      type="text"
                    /> */}

                      <Field
                        id="premium"
                        name="premium"
                        component={FormPremium}
                        min={minPremium}
                        max={maxPremium}
                        label=""
                        diff={100}
                        isPrimary
                        type="text"
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        id="updatePremium"
                        variant="contained"
                        size="large"
                        disabled={disabledUpdate}
                        onClick={() => {
                          onUpdateValues(formValues, true);
                          return setOpen(false);
                        }}
                        style={{
                          backgroundColor: disabledUpdate
                            ? theme.palette.grey[400]
                            : theme.palette.common.white,
                          color: disabledUpdate
                            ? theme.palette.common.white
                            : theme.palette.primary.dark,
                          padding: theme.spacing(2),
                        }}
                      >
                        <FormattedMessage {...messages.update} />
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        color="inherit"
                        variant="text"
                        onClick={() => {
                          change('premium', originalPremium);
                          return setOpen(false);
                        }}
                      >
                        <FormattedMessage {...messages.cancel} />
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
              {/* ########## END    :: EDIT PREMIUM ########## */}
              <Divider
                style={{
                  backgroundColor: theme.palette.info.main,
                  marginTop: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  marginBottom: theme.spacing(1),
                }}
              />
              <Collapse in={open}>
                <Typography
                  display="block"
                  variant="caption"
                  component="span"
                  color="inherit"
                >
                  <FormattedMessage {...messages.notePremiumCalculation} />
                </Typography>
              </Collapse>
              <Collapse in={!open}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="inherit">
                      <span id="secondaryPremiumAmount">
                        {formatMonetaryValue(annualizedPremium, true)}
                      </span>
                      {` ${currency} Annualised premium`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton id="edit" onClick={() => setOpen(!open)}>
                      <img src={IconPencilBlue} alt="" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Collapse>
            </HeaderContent>

            {/* =========== BR CONTENT ============ */}
            {specialTermsFlag ? (
              <BrContent container theme={theme}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={1}>
                    <AddCircle style={{ color: theme.palette.common.white }} />
                  </Grid>

                  <Grid item xs={10}>
                    <Typography
                      color="inherit"
                      // variant="h3"
                      component="span"
                      style={{ fontWeight: '900' }}
                    >
                      <FormattedMessage {...messages.brDebits} />
                    </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton onClick={() => setBrShowing(!isBrShowing)}>
                      {isBrShowing ? (
                        <KeyboardArrowUp
                          style={{ color: theme.palette.primary.light }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          style={{ color: theme.palette.primary.light }}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>

                {isBrShowing ? (
                  <Divider
                    style={{
                      height: '2px',
                      color: theme.palette.primary.dark,
                    }}
                  />
                ) : null}

                <Collapse in={isBrShowing}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    style={{ padding: theme.spacing(1) }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="span"
                        style={{ fontWeight: 'bold' }}
                      >
                        {lifeAssured.insuredOne.firstName}
                      </Typography>
                    </Grid>
                    {lifeAssured.isInsuredTwo ? (
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                          component="span"
                        >
                          {lifeAssured.insuredTwo.firstName}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>

                  {coverages.map((benefit, index) => {
                    const isShowingData =
                      benefit.brSelectedInsuredOneValue ||
                      benefit.brSelectedInsuredTwoValue ||
                      benefit.brSelectedPercentValueOne ||
                      benefit.brSelectedPercentValueTwo ||
                      benefit.brSelectedTempValueOne ||
                      benefit.brSelectedTempValueTwo;

                    return isShowingData ? (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        style={{ padding: theme.spacing(1) }}
                        key={index}
                      >
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            component="span"
                            style={{
                              fontWeight: 'bold',
                              marginTop: theme.spacing(1),
                            }}
                          >
                            {benefit.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} />

                        {(benefit.brSelectedTempValueOne ||
                          benefit.brSelectedTempValueTwo) && (
                          <>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  color: theme.palette.grey[400],
                                  lineHeight: 3,
                                }}
                              >
                                <FormattedMessage {...messages.temporary} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedTempValueOne
                                  ? `+${benefit.perTempMileOne.toFixed(
                                      2,
                                    )} per mille`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedTempValueTwo
                                    ? `+${benefit.perTempMileTwo.toFixed(
                                        2,
                                      )} per mille`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}

                        {(benefit.brSelectedTempValueOne ||
                          benefit.brSelectedTempValueTwo) && (
                          <>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                <FormattedMessage {...messages.period} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedTempValueOne
                                  ? `+${benefit.perTempMonthOne} months`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedTempValueTwo
                                    ? `+${benefit.perTempMonthTwo} months`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}

                        {(benefit.brSelectedInsuredOneValue ||
                          benefit.brSelectedInsuredTwoValue ||
                          benefit.brSelectedPercentValueOne ||
                          benefit.brSelectedPercentValueTwo) && (
                          <>
                            <Grid
                              item
                              xs={6}
                              style={{ marginTop: theme.spacing(1) }}
                            >
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  color: theme.palette.grey[400],
                                  lineHeight: 3,
                                }}
                              >
                                <FormattedMessage {...messages.permanent} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedInsuredOneValue
                                  ? `+${benefit.perMileOne.toFixed(
                                      2,
                                    )} per mille`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedInsuredTwoValue
                                    ? `+${benefit.perMileTwo.toFixed(
                                        2,
                                      )} per mille`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}

                            <Grid item xs={6} />
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedPercentValueOne
                                  ? `+${benefit.perPercentageOne}%`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedPercentValueTwo
                                    ? `+${benefit.perPercentageTwo}%`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}
                      </Grid>
                    ) : null;
                  })}
                </Collapse>
              </BrContent>
            ) : null}

            <Grid
              container
              direction="row"
              spacing={1}
              justify="space-between"
              alignItems="center"
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              <Grid item xs />
              <Grid item xs={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredOne.firstName}
                </Typography>
              </Grid>
              {lifeAssured.isInsuredTwo ? (
                <Grid item xs={3}>
                  <Typography
                    color="primary"
                    variant="h3"
                    style={{ marginLeft: theme.spacing(0.5) }}
                  >
                    {lifeAssured.insuredTwo.firstName}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>

            {coverages.length > 0 ? (
              <FieldArray
                name="coverages"
                component={Benefits}
                inactive={open}
                coverages={coverages}
                itaConfig={itaConfig}
                currency={currency}
                change={change}
                editData={editData}
                isCoveragesError={isCoveragesError}
                calculateEditData={val => {
                  const isCheckOfferCode = val.index === 0 && offerCode !== '';
                  setEditData(val);
                  return onUpdateValues(formValues, false, isCheckOfferCode);
                }}
                changeEditData={val => {
                  setEditData(val);
                }}
                isInsuredTwo={lifeAssured.isInsuredTwo}
              />
            ) : null}
            {/*= ===== Offer code ====== */}
            <BottomRow
              container
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle style={{ color: theme.palette.grey[300] }} />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: '900',
                      color: theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.specialOffer} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.common.black,
                        }}
                      >
                        {offerCode || '-'}
                        {offerCodeData.errorMessage && (
                          <>
                            <br />
                            <FormHelperText error>
                              {offerCodeData.errorMessage}
                            </FormHelperText>
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginRight: theme.spacing(1) }}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BottomRow>

            {/* ===========END OFFER CODE============ */}
          </Card>
          {offerCodeData.discountMessage?.english &&
            offerCodeData.errorMessage === '' && (
              <Box my={2}>
                <Typography variant="body1" style={{ fontWeight: 700 }}>
                  <FormattedMessage
                    {...messages.offerCodeValid}
                    values={{
                      value: offerCodeData.discountMessage?.english,
                    }}
                  />
                </Typography>
              </Box>
            )}
        </form>
      )}
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  warningCoverages: PropTypes.object,
  options: PropTypes.object,
  itaConfig: PropTypes.object,
  currency: PropTypes.string,
  paymentFrequency: PropTypes.string,
  specialTermsFlag: PropTypes.bool,
  premium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  coverages: PropTypes.array.isRequired,
  originalPremium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  formValues: PropTypes.object,
  onUpdateValues: PropTypes.func,
  onSubmit: PropTypes.func,
  annualizedPremium: PropTypes.number,
};

const withForm = reduxForm({
  form: 'quoteForm',
  warn: validate,
  enableReinitialize: true,
  // keepDirtyOnReinitialize: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');
const syncWarningsSelector = getFormSyncWarnings('quoteForm');

const withConnect = connect(state => {
  const currency = selector(state, 'currency');
  const premium = selector(state, 'premium');
  const offerCode = selector(state, 'offerCode');
  const annualizedPremium = selector(state, 'annualizedPremium');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const coverages = selector(state, 'coverages');
  const specialTermsFlag = selector(state, 'specialTermsFlag');
  const formValues = getFormValues('quoteForm')(state);
  let warningCoverages = syncWarningsSelector(state, 'coverages');
  if (warningCoverages instanceof Map) {
    warningCoverages = warningCoverages.toJS();
  }
  return {
    premium,
    currency,
    paymentFrequency,
    specialTermsFlag,
    coverages: coverages?.toJS() || [],
    formValues: formValues?.toJS() || {},
    warningCoverages,
    offerCode,
    annualizedPremium,
  };
})(withForm);

export default withConnect;

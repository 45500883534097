import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { createStructuredSelector } from 'reselect';

import { compose } from 'redux';
import messages from 'containers/ApplicationFormPage/messages';
import { formatBytes } from 'helpers/math';

//MATERIAL UI
import { Grid, Typography, TextField } from '@material-ui/core';

import { makeStyles, useTheme } from '@material-ui/core/styles';

//action

const useStyle = makeStyles(theme => ({
  uploadContainer: {
    padding: '1rem 2rem',
  },
  bold: {
    fontWeight: 800,
  },
}));

function SendEmailDialog(props) {
  const theme = useTheme();
  const classes = useStyle();
  const intl = useIntl();

  const { email, setEmail, partyEmail, ...propList } = props;

  return (
    <div className={classes.uploadContainer}>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Typography variant="body2" className={classes.bold}>
            <FormattedMessage {...messages.emailAddress} />
          </Typography>
          <br />
        </Grid>
        <Grid xs={12} md={8}>
          <TextField
            fullWidth
            variant="outlined"
            value={email}
            disabled={partyEmail}
            onChange={e => {
              setEmail(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}

SendEmailDialog.propTypes = {
  email: PropTypes.string,
  setEmail: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SendEmailDialog);

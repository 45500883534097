/*
 *
 * LoginPage reducer
 *
 */

import { fromJS } from 'immutable';
import { SET_LOGIN_STATUS_ACTION } from './constants';

const defaultState = {
  loading: false,
  error: {
    success: null,
    message: null,
  }
};

export const initialState = fromJS({ ...defaultState });

function loginPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LOGIN_STATUS_ACTION:
      return state
        .set('loading', false)
        .setIn(['error', 'success'], action.payload.success)
        .setIn(['error', 'message'], action.payload.message);
    default:
      return state;
  }
}

export default loginPageReducer;

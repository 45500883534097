import React, { useEffect } from 'react';
import { Field } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import FormNumberField from 'components/FormNumberField';
import FormBooleanOption from 'components/FormBooleanOption';
import FormYearPicker from 'components/FormYearPicker';
import globalMessages from 'containers/App/messages';
import isEmpty from 'lodash/isEmpty';
import { productCodeCheck } from 'helpers/constants';

// To show only calculate error.
const checkCalculateError = formErrors => {
  if (
    Object.keys(formErrors).length === 2 &&
    Object.keys(formErrors).includes('coverages2') &&
    Object.keys(formErrors).includes('hasToCalculate')
  ) {
    return true;
  }

  return (
    Object.keys(formErrors).length === 1 &&
    Object.keys(formErrors)[0] === 'hasToCalculate'
  );
};

function TargetFund({
  formValues,
  formErrors,
  submitFailed,
  premiumDisabled,
  maxStartYearTerm,
  minStartYearTerm,
  change,
}) {
  const isShowCalculateError = checkCalculateError(formErrors);
  return (
    <>
      <Grid container item direction="row" spacing={3} alignItems="center">
        <Grid item>
          <Typography variant="h4" style={{ fontWeight: '600', width: 260 }}>
            Target Fund
          </Typography>
        </Grid>
        <Grid item>
          <Field
            // shrink
            name="targetFunctionality"
            component={FormBooleanOption}
            type="text"
            margin="normal"
            fullWidth
            onChange={val => {
              change('hasToCalculate', val);
              if (!val && !productCodeCheck(8, formValues?.productCode)) {
                change('targetFund', null);
                change('targetYear', null);
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container item direction="row" spacing={3} alignItems="flex-end">
        <Grid item>
          <Field
            // shrink
            name="targetFund"
            style={{ width: 260 }}
            component={FormNumberField}
            disabled={!premiumDisabled}
            label={<FormattedMessage {...globalMessages.targetFundValue} />}
            type="text"
            margin="normal"
            variant="standard"
            onChange={() => {
              if (formValues.targetFunctionality) {
                change('hasToCalculate', true);
              }
            }}
          />
        </Grid>
        <Grid item>
          <Field
            // shrink
            name="targetYear"
            component={FormYearPicker}
            disabled={!premiumDisabled}
            label={<FormattedMessage {...globalMessages.targetYear} />}
            type="text"
            style={{
              width: 260,
            }}
            margin="normal"
            variant="standard"
            placeholder="YYYY"
            minDate={(productCodeCheck(8, formValues?.productCode) && minStartYearTerm)? new Date().setFullYear(minStartYearTerm) :new Date().setFullYear(new Date().getFullYear() + 5)}
            maxDate={(productCodeCheck(8, formValues?.productCode) && maxStartYearTerm ) ? new Date().setFullYear(maxStartYearTerm) : new Date().setFullYear(2100)}
            onChange={(value) => {
              if (formValues.targetFunctionality) {

                change('hasToCalculate', true);
                if (productCodeCheck(8, formValues?.productCode)) {
                  let withdrawendYearDetail = Number(value) + Number(formValues?.payoutYears) -1;
                  let termDetails = Number(value) - new Date().getFullYear()
                  change(
                    `startYear`,
                    value
                  );
                  change(
                    `endYear`,
                    withdrawendYearDetail
                  );
                  change('term', termDetails)
                }
              }
            }}
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="medium"
            disabled={!formValues.targetFunctionality}
            onClick={() => {
              change('premium', 0);
              change('isGetPremium', true);
              change('hasToCalculate', false);
            }}
          >
            Calculate
          </Button>
        </Grid>
      </Grid>
      <Grid container item>
        <Field component="input" type="hidden" name="hasToCalculate" />
        <Typography variant="body2" color="error">
          {isShowCalculateError && submitFailed && formErrors.hasToCalculate}
        </Typography>
      </Grid>
    </>
  );
}

TargetFund.propTypes = {
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  premiumDisabled: PropTypes.bool,
  submitFailed: PropTypes.bool,
  change: PropTypes.func,
};

export default TargetFund;

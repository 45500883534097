import React from 'react';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { WrappedFieldArrayProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { Cancel, CheckCircle } from '@material-ui/icons';
import FormSlider from 'components/FormSlider';
import FormBooleanOption from 'components/FormBooleanOption';
import { formatMonetaryValue } from 'helpers/math';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormNumberField from 'components/FormNumberField';
import { getMinValue, getMaxValue } from '../../helper';
import messages from '../../messages';

const Benefits = ({
  fields,
  coverages,
  futuraConfig,
  currency,
  isInsuredTwo,
  editData,
  lifeBasis,
  changeEditData,
  calculateEditData,
  change,
  inactive,
  isCoveragesError,
}) => {
  const theme = useTheme();
  return (
    <Box bgcolor={inactive ? `#f2f2f2` : undefined}>
      {fields.map((benefit, index) => {
        const myBenefit = fields.getAll().toJS();

        if (!coverages[index].selected) {
          return null;
        }
        let isValidData = coverages[index].selected;
        let isShowingData = coverages[index].selected;

        let isFieldsDisabled = !myBenefit[index].selected;
        let isFieldTwoDisabled = !myBenefit[index].selected;

        if (lifeBasis === 'JLBD') isFieldTwoDisabled = false;
        else isFieldTwoDisabled = true;

        if (myBenefit[index].id === 'CRI') {
          coverages.map(benefitData => {
            if (benefitData.id === 'CCC' && benefitData.selected) {
              if (lifeBasis === 'JLBD') {
                if (benefitData.valueInsuredOne) isFieldsDisabled = true;

                if (benefitData.valueInsuredTwo) isFieldTwoDisabled = true;
              }
            }
          });
        }

        if (myBenefit[index].id === 'CCC') {
          coverages.map(benefitData => {
            if (benefitData.id === 'CRI' && benefitData.selected) {
              if (lifeBasis === 'JLBD') {
                if (benefitData.valueInsuredOne) isFieldsDisabled = true;

                if (benefitData.valueInsuredTwo) isFieldTwoDisabled = true;
              }
            }
          });
        }

        if (myBenefit[index].id === 'WOP' || myBenefit[index].id === 'ESC') {
          isShowingData = true;
          isFieldTwoDisabled = false;
          if (isInsuredTwo)
            isValidData =
              coverages[index].valueInsuredOne &&
              coverages[index].valueInsuredTwo;
          else isValidData = coverages[index].valueInsuredOne;
        }

        return isShowingData ? (
          <Box
            bgcolor={inactive ? '#f2f2f2' : undefined}
            pl={3}
            pr={1}
            py={2}
            borderBottom={`1px solid ${theme.palette.divider}`}
            key={index}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  {isValidData ? (
                    <CheckCircle
                      style={{ color: theme.palette.success.main }}
                    />
                  ) : (
                    <Cancel style={{ color: theme.palette.error.main }} />
                  )}
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {coverages[index].title}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={1}
                >
                  <Grid item xs={8}>
                    {editData.editIndex === index &&
                    editData.editInsured === 1 &&
                    coverages[index].id !== 'WOP' &&
                    coverages[index].id !== 'ESC' &&
                    coverages[index].id !== 'FMI' &&
                    coverages[index].id !== 'FTI' ? (
                      <Field
                        shrink
                        name={`${benefit}.valueInsuredOne`}
                        fullWidth
                        component={FormNumberField}
                        decimalScale={0}
                        onChange={(e, newVal) =>
                          isFieldTwoDisabled
                            ? change(`${benefit}.valueInsuredTwo`, newVal)
                            : null
                        }
                        variant="standard"
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        id={`${coverages[index].id}.insuredOne`}
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        {coverages[index].selected
                          ? coverages[index].type === '1'
                            ? formatMonetaryValue(
                                coverages[index].valueInsuredOne,
                                false,
                              )
                            : coverages[index].valueInsuredOne
                            ? 'Yes'
                            : 'No '
                          : '-'}
                      </Typography>
                    )}
                  </Grid>

                  {!inactive && (
                    <Grid item xs={4}>
                      <IconButton
                        disabled={isFieldsDisabled || isCoveragesError}
                        style={{
                          paddingRight: 12,
                        }}
                        onClick={() =>
                          changeEditData({
                            editIndex: index,
                            editInsured: 1,
                          })
                        }
                      >
                        <img
                          style={{ height: '1.6rem' }}
                          src={IconPencilBlue}
                          alt=""
                        />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {isInsuredTwo && (
                <Grid item xs={3}>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={8}>
                      {editData.editIndex === index &&
                      editData.editInsured === 2 &&
                      coverages[index].id !== 'WOP' &&
                      coverages[index].id !== 'ESC' &&
                      coverages[index].id !== 'FMI' &&
                      coverages[index].id !== 'FTI' ? (
                        <Field
                          shrink
                          name={`${benefit}.valueInsuredTwo`}
                          fullWidth
                          component={FormNumberField}
                          decimalScale={0}
                          variant="standard"
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          id={`${coverages[index].id}.insuredTwo`}
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          {coverages[index].id !== 'ESC' &&
                          coverages[index].selected
                            ? coverages[index].type === '1'
                              ? formatMonetaryValue(
                                  coverages[index].valueInsuredTwo,
                                  false,
                                )
                              : coverages[index].valueInsuredTwo
                              ? 'Yes'
                              : 'No '
                            : ''}
                        </Typography>
                      )}
                    </Grid>
                    {!inactive && coverages[index].id !== 'ESC' && (
                      <Grid item xs={3}>
                        <IconButton
                          style={{ paddingRight: 12 }}
                          disabled={
                            isFieldTwoDisabled ||
                            isCoveragesError ||
                            coverages[index].id === 'ESC'
                          }
                          onClick={() =>
                            changeEditData({
                              editIndex: index,
                              editInsured: 2,
                            })
                          }
                        >
                          <img
                            style={{ height: '1.6rem' }}
                            src={IconPencilBlue}
                            alt=""
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Collapse
              in={editData.editIndex === index && editData.editInsured != 0}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={7}>
                  {editData.editInsured === 1 ? (
                    coverages[index].type === '1' ? (
                      <Field
                        name={`${benefit}.valueInsuredOne`}
                        component={FormSlider}
                        id={coverages[index].id}
                        min={getMinValue(
                          coverages[index].id,
                          futuraConfig.features,
                          currency,
                        )}
                        max={getMaxValue(
                          coverages[index].id,
                          futuraConfig.features,
                          currency,
                        )}
                        diff={50000}
                        onChange={(e, newVal) =>
                          isFieldTwoDisabled
                            ? change(`${benefit}.valueInsuredTwo`, newVal)
                            : null
                        }
                        isPrimary={false}
                        type="text"
                      />
                    ) : (
                      <Field
                        name={`${benefit}.valueInsuredOne`}
                        component={FormBooleanOption}
                        type="text"
                        margin="normal"
                        onChange={(e, newVal) => {
                          if (coverages[index].id !== 'ESC') {
                            isFieldTwoDisabled
                              ? change(`${benefit}.valueInsuredTwo`, newVal)
                              : null;
                          } else {
                            change(`${benefit}.valueInsuredTwo`, newVal);
                          }
                        }}
                        shrink
                        fullWidth
                      />
                    )
                  ) : coverages[index].type === '1' ? (
                    <Field
                      name={`${benefit}.valueInsuredTwo`}
                      component={FormSlider}
                      id={coverages[index].id}
                      min={getMinValue(
                        coverages[index].id,
                        futuraConfig.features,
                        currency,
                      )}
                      max={getMaxValue(
                        coverages[index].id,
                        futuraConfig.features,
                        currency,
                      )}
                      diff={50000}
                      isPrimary={false}
                      type="text"
                    />
                  ) : (
                    coverages[index].id !== 'ESC' && (
                      <Field
                        name={`${benefit}.valueInsuredTwo`}
                        component={FormBooleanOption}
                        type="text"
                        margin="normal"
                        disabled={false}
                        shrink
                        fullWidth
                      />
                    )
                  )}
                </Grid>

                {coverages[index].id === 'FMI' ||
                coverages[index].id === 'FTI' ? (
                  <Grid item xs={2}>
                    {/* <Typography
                        variant="h4"
                        style={{
                          fontWeight: '900',
                          color: theme.palette.common.black,
                        }}
                      >
                        {coverages[index].selected
                          ? editData.editInsured === 1
                            ? coverages[index].valueInsuredOne
                            : coverages[index].valueInsuredTwo
                          : '-'}
                      </Typography> */}
                    {editData.editInsured === 1 ? (
                      <Field
                        shrink
                        name={`${benefit}.valueInsuredOne`}
                        fullWidth
                        component={FormNumberField}
                        decimalScale={0}
                        onChange={(e, newVal) =>
                          isFieldTwoDisabled
                            ? change(`${benefit}.valueInsuredTwo`, newVal)
                            : null
                        }
                        variant="standard"
                      />
                    ) : (
                      <Field
                        shrink
                        name={`${benefit}.valueInsuredTwo`}
                        fullWidth
                        component={FormNumberField}
                        decimalScale={0}
                        variant="standard"
                      />
                    )}
                  </Grid>
                ) : null}

                <Grid item xs={3}>
                  {coverages[index].id === 'FMI' ||
                  coverages[index].id === 'FTI' ? null : (
                    <Button
                      size="large"
                      variant="contained"
                      disabled={isCoveragesError}
                      onClick={() =>
                        calculateEditData({
                          editIndex: 0,
                          editInsured: 0,
                          index,
                        })
                      }
                    >
                      <FormattedMessage {...messages.update} />
                    </Button>
                  )}
                </Grid>

                {coverages[index].id === 'FMI' ||
                coverages[index].id === 'FTI' ? (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={7}>
                      {editData.editInsured === 1 ? (
                        <Field
                          name={`${benefit}.termOne`}
                          component={FormSlider}
                          min={0}
                          max={40}
                          diff={5}
                          onChange={(e, newVal) =>
                            isFieldTwoDisabled
                              ? change(`${benefit}.termTwo`, newVal)
                              : null
                          }
                          isPrimary={false}
                          type="text"
                        />
                      ) : (
                        <Field
                          name={`${benefit}.termTwo`}
                          component={FormSlider}
                          min={0}
                          max={40}
                          diff={5}
                          isPrimary={false}
                          disabled
                          type="text"
                        />
                      )}
                    </Grid>

                    <Grid item xs={2}>
                      {/* <Typography
                          variant="h4"
                          style={{
                            fontWeight: '900',
                            color: theme.palette.common.black,
                          }}
                        >
                          {coverages[index].selected
                            ? editData.editInsured === 1
                              ? coverages[index].termOne
                              : coverages[index].termTwo
                            : '-'}
                        </Typography> */}

                      {editData.editInsured === 1 ? (
                        <Field
                          shrink
                          name={`${benefit}.termOne`}
                          fullWidth
                          component={FormNumberField}
                          decimalScale={0}
                          onChange={(e, newVal) =>
                            isFieldTwoDisabled
                              ? change(`${benefit}.termTwo`, newVal)
                              : null
                          }
                          variant="standard"
                        />
                      ) : (
                        <Field
                          shrink
                          name={`${benefit}.termTwo`}
                          fullWidth
                          component={FormNumberField}
                          decimalScale={0}
                          variant="standard"
                        />
                      )}
                    </Grid>

                    <Grid item xs={3}>
                      <Button
                        size="large"
                        variant="contained"
                        disabled={isCoveragesError}
                        onClick={() =>
                          calculateEditData({ editIndex: 0, editInsured: 0 })
                        }
                      >
                        <FormattedMessage {...messages.update} />
                      </Button>
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Collapse>
          </Box>
        ) : null;
      })}
    </Box>
  );
};

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  theme: PropTypes.object,
  options: PropTypes.object,
  futuraConfig: PropTypes.object,
  currency: PropTypes.string,
  coverages: PropTypes.array,
  editData: PropTypes.object,
  lifeBasis: PropTypes.string,
  changeEditData: PropTypes.func,
  calculateEditData: PropTypes.func,
  isInsuredTwo: PropTypes.bool,
  change: PropTypes.func,
};

export default Benefits;

/*
 * ApplicationPage Messages
 *
 * This contains all the text for the ApplicationPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ApplicationPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Application Page',
  },
  applicationNumber: {
    id: `${scope}.applicationNumber`,
    defaultMessage: 'Application: #{value}',
  },
  addExternalNumber: {
    id: `${scope}.addExternalNumber`,
    defaultMessage: 'Add external reference number',
  },
  policyOwner: {
    id: `${scope}.policyOwner`,
    defaultMessage: 'Policy Owner',
  },
  withdrawals: { id: `${scope}.withdrawals`, defaultMessage: 'Withdrawals' },
  premiumPaymentTerm: {
    id: `${scope}.premiumPaymentTerm`,
    defaultMessage: 'Premium payment term',
  },
  assumedGrowthRate: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'Assumed growth rate',
  },
  advisorFeeLabel: {
    id: `${scope}.advisorFeeLabel`,
    defaultMessage: 'Fund investment advisor fee',
  },
  policyTermTitle: {
    id: `${scope}.policyTermTitle`,
    defaultMessage: 'Policy term',
  },
  interestRateTitle: {
    id: `${scope}.interestRateTitle`,
    defaultMessage: 'Interest rate',
  },
  coverageTerm: {
    id: `${scope}.coverageTerm`,
    defaultMessage: 'Coverage term',
  },
  insuredParty: {
    id: `${scope}.insuredParty`,
    defaultMessage: 'Insured Party',
  },
  theInsured: {
    id: `${scope}.theInsured`,
    defaultMessage: 'The insured',
  },
  policySummary: {
    id: `${scope}.policySummary`,
    defaultMessage: 'Policy Summary',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium Summary',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Cover details',
  },
  protectionBenefit: {
    id: `${scope}.protectionBenefit`,
    defaultMessage: 'Protection benefits',
  },
  payor: {
    id: `${scope}.payor`,
    defaultMessage: 'Payor',
  },
  beneficiaries: {
    id: `${scope}.beneficiaries`,
    defaultMessage: 'Beneficiaries',
  },
  paperApplication: {
    id: `${scope}.paperApplication`,
    defaultMessage: 'Paper Application',
  },
  startEApplication: {
    id: `${scope}.startEApplication`,
    defaultMessage: 'Start E-Application',
  },
  openEApplication: {
    id: `${scope}.openEApplication`,
    defaultMessage: 'Open E-Application',
  },
  openApplication: {
    id: `${scope}.openApplication`,
    defaultMessage: 'Open Application',
  },
  externalReferenceNumber: {
    id: `${scope}.externalReferenceNumber`,
    defaultMessage: 'External Reference Number',
  },
  addEditExternalNumber: {
    id: `${scope}.addEditExternalNumber`,
    defaultMessage: 'Add / edit external reference number?',
  },
  save: {
    id: `${scope}.save`,
    defaultMessage: 'Save',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  pleaseNote: {
    id: `${scope}.pleaseNote`,
    defaultMessage: 'Please note',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  policyBasis: {
    id: `${scope}.policyBasis`,
    defaultMessage: 'Policy basis',
  },
  additionalSinglePremium: {
    id: `${scope}.additionalSinglePremium`,
    defaultMessage: 'Additional Single Premium',
  },
  premiumIndexation: {
    id: `${scope}.premiumIndexation`,
    defaultMessage: `Premium Indexation`,
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  goToDashboard: {
    id: `${scope}.goToDashboard`,
    defaultMessage: 'Go to dashboard',
  },
  thankYou: {
    id: `${scope}.thankYou`,
    defaultMessage: 'Thank you, your application has been submitted to Zurich.',
  },
  thankYouForAdminReview: {
    id: `${scope}.thankYouForAdminReview`,
    defaultMessage: 'Thank you, your application has been sent for Admin Review.',
  },
  documents: {
    id: `${scope}.documents`,
    defaultMessage: 'Documents',
  },
  withdrawalPeriod: { id: `${scope}.withdrawalPeriod`, defaultMessage: 'Withdrawal period' },
  withdrawalFrequency: { id: `${scope}.withdrawalFrequency`, defaultMessage: 'Withdrawal frequency' },
  withdrawalDuration: { id: `${scope}.withdrawalDuration`, defaultMessage: 'Withdrawal duration' },
});

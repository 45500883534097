import { all, takeLatest, call, put } from 'redux-saga/effects';
import request from 'utils/request';
import { api } from 'environments';

import { setAppLoadingAction } from 'containers/App/actions';
import { START_VERIFICATION_ACTION } from './constants';
import { setShuftiProAction } from './actions';

export function* startShuftiProVerification(action) {
  yield put(setAppLoadingAction(true));

  const sessionId = action.payload;

  const endpoint = `${api.host}/api/v${api.version}/b2b/id-verification/get-session?session=${sessionId}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      // 'X-TENANT-ID': tenant['360f'].id,
      'Content-Type': 'application/json',
      // 'Content-Length':
      // 'Host': window.location.origin,
    },
  };

  try {
    yield put(
      setShuftiProAction({
        error: '',
      }),
    );
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(
        setShuftiProAction({
          verificationUrl: response.data.url,
          reference: response.data.reference,
        }),
      );
    }
  } catch (err) {
    yield put(
      setShuftiProAction({
        error:
          'We are experiencing issues with ID Verification, please try again.',
      }),
    );
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

// Individual exports for testing
export default function* idVerificationSaga() {
  // See example in containers/HomePage/saga.js
  yield all([
    takeLatest(START_VERIFICATION_ACTION, startShuftiProVerification),
  ]);
}

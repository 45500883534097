/**
 * BeneficiaryDetails
 */
 import React from 'react';
 import PropTypes from 'prop-types';
 import { FormattedMessage, useIntl } from 'react-intl';
 import { useTheme, makeStyles } from '@material-ui/core/styles';
 import { Field } from 'redux-form/immutable';
 
 import { map, filter, forEach } from 'lodash/collection';
 import { blue } from '@material-ui/core/colors';
 import {
   Grid,
   FormControlLabel,
   Radio,
   Button,
   Divider,
   IconButton,
   Typography,
 } from '@material-ui/core';
 import DeleteIcon from '@material-ui/icons/Delete';
 import messages from 'containers/ApplicationFormPage/messages';
 import globalMessages from 'containers/App/messages';
 import FormTextField from 'components/FormTextField';
 import FormDatePicker from 'components/FormDatePicker';
 import FormNumberField from 'components/FormNumberField';
 import FormRadioGroup from 'components/FormRadioGroup';
 import CustomRadio from 'components/CustomRadioButton';
 import FormSelect from 'components/FormSelect';
 import { changePlaceHolderColor, changeToTitleCase } from 'helpers/lang';
 import { isEmpty } from 'lodash';
 
 const useStyle = makeStyles(theme => ({
   hr: {
     marginTop: theme.spacing(2),
     marginBottom: theme.spacing(3),
     marginLeft: '-2rem',
     marginRight: '-2rem',
     borderTop: '1px solid rgba(0, 0, 0, 0.12)',
   },
 }));
 
 const lessThan = otherField => (value, previousValue, allValues) => {
   if (!value) return '';
   return value.length <= otherField ? value : previousValue;
 };
 
 const normalizePhone = value => {
   if (!value) {
     return value;
   }
   const onlyNums = value.replace(/[^0-9]/g, '');
   return onlyNums;
 };
 
 function BeneficiaryDetails({
   formValues = {},
   fields,
   options,
   isShowSharePercentage = true,
   error,
   newPartyPlaceholder,
   disabledPercentage,
   addLabel,
   formErrors,
   lifeInsuredParties,
   fieldName,
   change,
 }) {
   const theme = useTheme();
   const intl = useIntl();
   const classes = useStyle();
   const addAssignee = () => {
     if (fields.length < 4) {
       fields.push({ ...newPartyPlaceholder });
     }
   };
 
   return fields.map((field, index) => {
     change(`${fieldName}[${index}].order`, index);
     if (!isEmpty(formValues)) {
       //ADDRESS CONDITION
       if (
         formValues[index].selectedAddress !== 'different' &&
         formValues[index].selectedAddress !== '' &&
         typeof formValues[index].selectedAddress !== 'undefined'
       ) {
         //get the party that is selected
         const selectedParty = filter(lifeInsuredParties, party => {
           return party.id === formValues[index].selectedAddress;
         });
         change(
           `${fieldName}[${index}].addressLine1`,
           selectedParty[0].addressLine1,
         );
         change(
           `${fieldName}[${index}].addressLine2`,
           selectedParty[0].addressLine2,
         );
         change(
           `${fieldName}[${index}].addressLine3`,
           selectedParty[0].addressLine3,
         );
         change(
           `${fieldName}[${index}].postOfficeBoxLine`,
           selectedParty[0].postOfficeBoxLine,
         );
         change(
           `${fieldName}[${index}].postOfficeBoxCity`,
           selectedParty[0].postOfficeBoxCity,
         );
         change(`${fieldName}[${index}].city`, selectedParty[0].city);
         change(`${fieldName}[${index}].country`, selectedParty[0].country);
       }
 
       //UNSELECT ALL when there's no LI selected
       if (
         formValues.selectedLifeInsuredParties ||
         formValues.thirdPersonPayor
       ) {
         change(`${fieldName}[${index}].lifeInsuredPersonPayor`, false);
       }
 
       // UNSELECT the other LI if this LI is selected
       // if (formValues[fieldName][index].lifeInsuredPersonPayor) {
       //   forEach(formValues[fieldName], (_, index3) => {
       //     if (index3 !== index) {
       //       change(`${fieldName}[${index3}].lifeInsuredPersonPayor`, false);
       //     } else if (lifeInsuredIndex === index) {
       //       change(`${fieldName}[${index}].lifeInsuredPersonPayor`, true);
       //     }
       //   });
       // }
     }
     return (
       <>
         <div key={`${field}-${index}`} style={{ marginBottom: '2rem' }}>
           {index === 0 ? '' : <Divider style={{ marginBottom: 28 }} />}
           <Grid container spacing={1}>
             <Grid item xs={6}>
               <Field
                 name={`${field}.firstName`}
                 style={{ width: 260 }}
                 fullWidth
                 component={FormTextField}
                 label={<FormattedMessage {...globalMessages.firstName} />}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 normalize={lessThan(45)}
                 placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
                 format = {(value) => changeToTitleCase(value)} 
               />
             </Grid>
             <Grid item xs={6}>
               <Field
                 name={`${field}.lastName`}
                 style={{ width: 260 }}
                 fullWidth
                 component={FormTextField}
                 label={<FormattedMessage {...globalMessages.familyName} />}
                 color="primary"
                 margin="normal"
                 variant="standard"
                 normalize={lessThan(40)}
                 placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
                 format = {(value) => changeToTitleCase(value)} 
               />
             </Grid>
           </Grid>
 
           <Grid
             container
             alignItems="center"
             spacing={2}
             style={{ marginTop: 16 }}
           >
             <Grid item xs={8}>
               <Field
                 name={`${field}.relationshipToLifeInsured`}
                 style={{
                   width: 355,
                   color: !isEmpty(formValues)
                     ? changePlaceHolderColor(
                         formValues[index].relationshipToLifeInsured,
                       )
                     : '',
                 }}
                 fullWidth
                 component={FormSelect}
                 options={[
                   { value: 'Select an option', title: 'Select an option' },
                   ...options.beneficiaryRelationship,
                 ]}
                 label={
                   <FormattedMessage
                     {...globalMessages.relationWithLifeInsured}
                   />
                 }
                 color="primary"
                 margin="normal"
                 variant="standard"
                 placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
               />
             </Grid>
           </Grid>
 
           <Grid
             container
             alignItems="center"
             spacing={2}
             style={{ marginTop: 16 }}
           >
             <Grid item xs={6}>
               <Field
                 name={`${field}.dateOfBirth`}
                 style={{ width: 260 }}
                 component={FormDatePicker}
                 label={<FormattedMessage {...globalMessages.dob} />}
                 inputProps={{ inputMode: 'numeric' }}
                 dateInputFormat="DD/MM/YYYY"
                 placeholder="dd/mm/yyyy"
                 margin="normal"
                 minDate={new Date().setFullYear(new Date().getFullYear() - 100)}
                 maxDate={new Date().setFullYear(new Date().getFullYear())}
               />
             </Grid>
             <Grid item xs={8}>
               <Field
                 name={`${field}.email`}
                 style={{ width: 355 }}
                 fullWidth
                 component={FormTextField}
                 label={<FormattedMessage {...globalMessages.email} />}
                 color="primary"
                 margin="normal"
                 variant="standard"
                 placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
               />
             </Grid>
             <Grid item xs={12} style={{ paddingTop: 26, paddingBottom: 26 }}>
               <Divider />
             </Grid>
 
             <Grid item xs={12}>
               <Field
                 name={`${field}.selectedAddress`}
                 type="radio"
                 component={FormRadioGroup}
               >
                 <div className={classes.radioForm}>
                   <FormControlLabel
                     style={{ marginBottom: '6px' }}
                     control={
                       <CustomRadio
                         checked={
                           formValues &&
                           formValues[index]?.selectedAddress === 'different'
                         }
                       />
                     }
                     value="different"
                     label={
                       <Typography variant="body1" className={classes.bold}>
                         Different address
                       </Typography>
                     }
                     labelPlacement="end"
                   />
                   {map(lifeInsuredParties, (party, index2) => {
                     return (
                       <FormControlLabel
                         style={{ marginBottom: '6px' }}
                         key={`${party.id}-${index2}`}
                         control={
                           <CustomRadio
                             checked={
                               !isEmpty(formValues) &&
                               party.id === formValues[index]?.selectedAddress
                             }
                           />
                         }
                         value={party.id}
                         label={
                           <Typography
                             variant="body1"
                             className={classes.bold}
                           >{`Same as ${party.firstName} ${party.lastName}`}</Typography>
                         }
                         labelPlacement="end"
                       />
                     );
                   })}
                 </div>
               </Field>
             </Grid>
             <Grid item>
               <Field
                 name={`${field}.addressLine1`}
                 style={{ width: 260 }}
                 component={FormTextField}
                 label={<FormattedMessage {...messages.flatNumber} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={
                   !isEmpty(formValues) &&
                   formValues[index].selectedAddress !== 'different'
                 }
               />
             </Grid>
             <Grid item>
               <Field
                 name={`${field}.addressLine2`}
                 style={{ width: 260 }}
                 component={FormTextField}
                 label={<FormattedMessage {...messages.propertyName} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={
                   !isEmpty(formValues) &&
                   formValues[index].selectedAddress !== 'different'
                 }
               />
             </Grid>
             <Grid item xs={12}>
               <Field
                 name={`${field}.addressLine3`}
                 style={{ width: 355 }}
                 component={FormTextField}
                 label={<FormattedMessage {...messages.area} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={
                   !isEmpty(formValues) &&
                   formValues[index].selectedAddress !== 'different'
                 }
               />
             </Grid>
             <Grid item xs={12}>
               <Field
                 name={`${field}.city`}
                 style={{ width: 355 }}
                 component={FormTextField}
                 label={<FormattedMessage {...messages.emirateCity} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={
                   !isEmpty(formValues) &&
                   formValues[index].selectedAddress !== 'different'
                 }
               />
             </Grid>
             <Grid item xs={12}>
               <Field
                 name={`${field}.country`}
                 style={{
                   width: 355,
                   // color: changePlaceHolderColor(
                   //   formValues.parties[index].country,
                   // ),
                 }}
                 component={FormSelect}
                 options={[
                   { value: 'Select an option', title: 'Select an option' },
                   ...options.countryCode,
                 ]}
                 label={<FormattedMessage {...messages.country} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 value=""
                 disabled={
                   !isEmpty(formValues) &&
                   formValues[index].selectedAddress !== 'different'
                 }
               />
             </Grid>
             <Grid item xs={12} style={{ paddingTop: 26, paddingBottom: 26 }}>
               <Divider />
             </Grid>
             <Grid item>
               <Field
                 name={`${field}.postOfficeBoxLine`}
                 style={{ width: 260 }}
                 component={FormTextField}
                 label={<FormattedMessage {...messages.poBoxOptional} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={
                   !isEmpty(formValues) &&
                   formValues[index].selectedAddress !== 'different'
                 }
               />
             </Grid>
             <Grid item>
               <Field
                 name={`${field}.postOfficeBoxCity`}
                 style={{ width: 260 }}
                 component={FormTextField}
                 label={<FormattedMessage {...messages.emirateCityOptional} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={
                   !isEmpty(formValues) &&
                   formValues[index].selectedAddress !== 'different'
                 }
               />
             </Grid>
             <Grid item xs={12} style={{ paddingTop: 26, paddingBottom: 26 }}>
               <Divider />
             </Grid>
           </Grid>
 
           <Grid
             container
             alignItems="center"
             spacing={1}
             style={{ marginTop: 16 }}
           >
             {isShowSharePercentage && (
               <Grid item>
                 <Field
                   name={`${field}.sharePercentage`}
                   style={{ width: 150 }}
                   component={FormTextField}
                   label={
                     <FormattedMessage {...globalMessages.sharePercentage} />
                   }
                   margin="normal"
                   variant="standard"
                   placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
                   disabled={disabledPercentage}
                   normalize={normalizePhone}
                 />
               </Grid>
             )}
             {isShowSharePercentage &&
               fields.length <= 3 &&
               fields.length === index + 1 && (
                 <Grid item>
                   <Button onClick={addAssignee} style={{ color: blue[800] }}>
                     {addLabel}
                     {/* <FormattedMessage {...globalMessages.addAssignee} /> */}
                   </Button>
                 </Grid>
               )}
             {fields.length === 1 ? (
               ''
             ) : (
               <Grid item xs>
                 {/* <IconButton
                 onClick={() => {
                   fields.remove(index);
                 }}
               >
                 <DeleteIcon />
               </IconButton> */}
                 <Button
                   onClick={() => {
                     fields.remove(index);
                   }}
                   style={{ color: blue[800] }}
                 >
                   <FormattedMessage {...messages.deleteBeneficiary} />
                 </Button>
               </Grid>
             )}
           </Grid>
           {formErrors?.atleast100SharePercentage ? (
             <Typography variant="caption" color="error">
               {formErrors?.atleast100SharePercentage}
             </Typography>
           ) : (
             ''
           )}
           <div style={{ display: 'none' }}>
             <Field name={`${field}.order`} component={FormTextField} />
           </div>
         </div>
       </>
     );
   });
 }
 
 BeneficiaryDetails.propTypes = {
   options: PropTypes.object,
   formValues: PropTypes.object,
   error: PropTypes.bool,
   formErrors: PropTypes.object,
   lifeInsuredParties: PropTypes.array,
   change: PropTypes.func,
 };
 
 export default BeneficiaryDetails;
 
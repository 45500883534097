/*
 *
 * ApplicationPage constants
 *
 */

export const DEFAULT_ACTION = 'app/ApplicationPage/DEFAULT_ACTION';

//INITIAL SESSION
export const CALL_INITIAL_SESSION =
  'app/ApplicationFormPage/CALL_INITIAL_SESSION';
export const SET_LIFE_INSURED_PARTIES_ACTION =
  'app/ApplicationFormPage/SET_LIFE_INSURED_PARTIES_ACTION';
export const SET_SESSION_ID = 'app/ApplicationFormPage/SET_SESSION_ID';
export const SET_ETAG = 'app/ApplicationFormPage/SET_ETAG';
export const SET_UNDERWRITING_SECTIONS =
  'app/DashboardPage/SET_UNDERWRITING_SECTIONS';
export const SET_APPLCATION_SUBORDERS =
  'app/DashboardPage/SET_APPLCATION_SUBORDERS';

//APPLICATION DETAILS
export const GET_APPLICATION_DETAILS_ACTION =
  'app/ApplicationPage/GET_APPLICATION_DETAILS_ACTION';
export const SET_APPLICATION_DETAILS_ACTION =
  'app/ApplicationPage/SET_APPLICATION_DETAILS_ACTION';
export const UPDATE_APPLICATION_STATUS_ACTION =
  'app/ApplicationPage/UPDATE_APPLICATION_STATUS_ACTION';

//APPLICATION PARTY
export const UPDATE_APPLICATION_PARTY_ACTION =
  'app/ApplicationPage/UPDATE_APPLICATION_PARTY_ACTION';
export const UPDATE_APPLICATION_PARTIES_ACTION =
  'app/ApplicationPage/UPDATE_APPLICATION_PARTIES_ACTION';

export const ADD_APPLICATION_PARTY_ACTION =
  'app/ApplicationPage/ADD_APPLICATION_PARTY_ACTION';
export const ADD_APPLICATION_PARTIES_ACTION =
  'app/ApplicationPage/ADD_APPLICATION_PARTIES_ACTION';

export const DELETE_APPLICATION_PARTY_ACTION =
  'app/ApplicationPage/DELETE_APPLICATION_PARTY_ACTION';
export const DELETE_APPLICATION_PARTIES_ACTION =
  'app/ApplicationPage/DELETE_APPLICATION_PARTIES_ACTION';

export const GET_ADMIN_ACTION_DETAIL_ACTION = 
   'app/ApplicationPage/GET_ADMIN_ACTION_DETAIL_ACTION';

 export const SET_ADMIN_ACTION_DETAIL_ACTION =
   'app/ApplicationPage/SET_ADMIN_ACTION_DETAIL_ACTION';

//APPLICATION LIST
export const GET_APPLICATION_LIST_ACTION =
  'app/ApplicationPage/GET_APPLICATION_LIST_ACTION';
export const SET_APPLICATION_LIST_ACTION =
  'app/ApplicationPage/SET_APPLICATION_LIST_ACTION';

export const UPDATE_APPLICATION_DETAILS_ACTION =
  'app/ApplicationPage/UPDATE_APPLICATION_DETAILS_ACTION';

export const UPDATE_APPLICATION_DETAILS_AND_STATUS_ACTION =
  'app/ApplicationPage/UPDATE_APPLICATION_DETAILS_AND_STATUS_ACTION';

export const CALL_FINANCIAL_INSTITUTE_OPTION =
  'app/ApplicationPage/CALL_FINANCIAL_INSTITUTE_OPTION';

export const SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION =
  'app/ApplicationPage/SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION';

export const GET_LIST_APPLICATION_PARTY_ACTION =
  'app/ApplicationPage/GET_LIST_APPLICATION_PARTY_ACTION';
export const SET_LIST_APPLICATION_PARTY_ACTION =
  'app/ApplicationPage/SET_LIST_APPLICATION_PARTY_ACTION';

export const DELETE_APPLICATION_ACTION =
  'app/ApplicationPage/DELETE_APPLICATION_ACTION';
export const SET_DELETE_APPLICATION_ACTION =
  'app/ApplicationPage/SET_DELETE_APPLICATION_ACTION';
  export const SET_LOGOUT_WHEN_UPDATE_APP_ACTION =
  'app/ApplicationPage/SET_LOGOUT_WHEN_UPDATE_APP_ACTION';

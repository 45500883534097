import React, { useState, useEffect } from 'react';
import PropTypes, { number } from 'prop-types';
import { Grid, Container, Typography } from '@material-ui/core';
import { useOktaAuth } from '@okta/okta-react';
import Dialog from 'components/Dialog';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectAuth } from 'containers/App/selectors';
import { compose } from 'redux';
import { api } from 'environments';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import request from 'utils/request';
import { FormattedMessage } from 'react-intl';
import {
  makeSelectZioPinData,
  makeSelectZioPinError,
  makeSelectIsZioPinVerified,
  makeSelectZioPinVerifyTimeStamp,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import { callZioPinVerification, getPersistedZioPinData } from './actions';
import ZioPinForm from './components/ZioPinForm';
import messages from './messages';

export function MFOauth({
  logout,
  auth,
  verifyZioPin,
  zioPinData,
  zioPinError,
  isZioPinVerified,
  zioPinVerifyTimeStamp,
  fetchPersistedZioPinData,
}) {
  useInjectReducer({ key: 'MFOauthPage', reducer });
  useInjectSaga({ key: 'MFOauthPage', saga });
  const [zioPinLength, setZioPinLength] = useState(0);
  const { oktaAuth } = useOktaAuth();
  const handleCloseZioPin = () => {
    logout(oktaAuth);
  };

  useEffect(() => {
    if (isZioPinVerified === false) {
      fetchPersistedZioPinData();
    }
  }, []);

  useEffect(() => {
    const getZioPinLength = async () => {
      try {
        const endpoint = `${api.host}/api/v${api.version}/b2b/auth/ziopin/getpinlength`;
        // const endpoint = `https://mezproplusbackenddev.azurewebsites.net/api/v1/b2b/auth/ziopin/getpinlength`;

        const requestOpt = {
          method: 'POST',
          body: JSON.stringify({
            email: auth.emailId,
          }),
          headers: {
            'Ocp-Apim-Subscription-Key': api.subKey,
            Authorization: `Bearer ${auth.okta.accessToken}`,
          },
        };

        const response = await request(endpoint, requestOpt);
        const { data } = response;
        setZioPinLength(parseInt(data.userAuthLength));
        // setopenZipPin(true);
      } catch (err) {
        console.log('err: ', err);
      }
    };
    getZioPinLength();
  }, []);

  const onSubmit = immutableData => {
    const data = immutableData.toJS();
    let zioPin = '';
    for (let i = 0; i < zioPinLength; i++) {
      zioPin += data[`pin_${i}`] ? data[`pin_${i}`] : '*';
    }
    verifyZioPin({ pinCode: zioPin, email: auth.emailId });
  };

  return (
    <Dialog
      open={!isZioPinVerified}
      onClose={handleCloseZioPin}
      title="Verify Zio Pin"
    >
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Container maxWidth="xs">
            <ZioPinForm
              zioPinLength={zioPinLength}
              zioPinError={zioPinError}
              onSubmit={onSubmit}
            />
          </Container>
        </Grid>
        <Grid item>
          <Typography>
            <FormattedMessage {...messages.zioPinNote} />
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
}

MFOauth.propTypes = {
  logout: PropTypes.func,
  auth: PropTypes.object,
  verifyZioPin: PropTypes.func,
  zioPinData: PropTypes.object,
  zioPinError: PropTypes.object,
  isZioPinVerified: PropTypes.bool,
  zioPinVerifyTimeStamp: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
  zioPinData: makeSelectZioPinData(),
  zioPinError: makeSelectZioPinError(),
  isZioPinVerified: makeSelectIsZioPinVerified(),
  zioPinVerifyTimeStamp: makeSelectZioPinVerifyTimeStamp(),
});

function mapDispatchToProps(dispatch) {
  return {
    verifyZioPin: value => dispatch(callZioPinVerification(value)),
    fetchPersistedZioPinData: value => dispatch(getPersistedZioPinData()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(MFOauth);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import { isFieldEmpty } from 'utils/form';
import globalMessages from 'containers/App/messages';
import { getMinValue, getMaxValue } from '../../helper';

const validate = (values, props) => {
  const errors = {};
  const { configsDetail } = props;

  if (!values.isEmpty()) {
    const currencyValues = values.get('currency');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const premiumValues = values.get('premium');
    const coveragesValues = values.get('coverages');

    let minPremium = 0;
    let maxPremium = 0;
    configsDetail.features.currencyPremiumSize.forEach(val => {
      if (currencyValues === val.currency) {
        switch (paymentFrequencyValues) {
          case 'Monthly':
            minPremium = val.minPremiumMonthly;
            maxPremium = val.maxPremiumMonthly;
            break;

          case 'Quarterly':
            minPremium = val.minPremiumQuarterly;
            maxPremium = val.maxPremiumQuarterly;
            break;

          case 'Half-Yearly':
            minPremium = val.minPremiumHalfYearly;
            maxPremium = val.maxPremiumHalfYearly;
            break;

          case 'Yearly':
            minPremium = val.minPremiumYearly;
            maxPremium = val.maxPremiumYearly;
            break;

          default:
            minPremium = val.minPremiumMonthly;
            maxPremium = val.maxPremiumMonthly;
            break;
        }
      }
    });

    if (!premiumValues)
      errors.premium = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    else {
      if (premiumValues < minPremium)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minPremium }}
          />
        );

      if (premiumValues > maxPremium)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: maxPremium }}
          />
        );
    }

    if (coveragesValues && coveragesValues.size > 0) {
      const coveragesError = [];

      coveragesValues.forEach(coverage => {
        const requiredCoverageFields = ['valueInsuredOne'];
        const coverageError = {};

        requiredCoverageFields.forEach(field => {
          if (coverage.get('selected')) {
            if (isFieldEmpty(coverage.get(field))) {
              coverageError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            } else if (coverage.get('type') === '1') {
              const minVal = getMinValue(
                coverage.get('id'),
                configsDetail.features,
                currencyValues,
              );
              const maxVal = getMaxValue(
                coverage.get('id'),
                configsDetail.features,
                currencyValues,
              );

              if (minVal > coverage.get(field)) {
                coverageError[field] = (
                  <FormattedMessage
                    {...globalMessages.minAmountLimit}
                    values={{ value: minVal }}
                  />
                );
              }

              if (maxVal < coverage.get(field)) {
                coverageError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxAmountLimit}
                    values={{ value: maxVal }}
                  />
                );
              }

              // if (numeral(coverage.get('valueInsuredOne')).value() > numeral(lfcInsuredOne).value()) {
              //   coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />);
              // }
            }
          }
        });
        coveragesError.push(coverageError);
      });
      if (!isEmpty(coveragesError)) errors.coverages = coveragesError;
    }
  }
  return errors;
};

export default validate;

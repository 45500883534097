/*
 *
 * IllustrationPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SET_PRODUCT_CONFIG,
  SET_PRODUCT_CONFIG2,
  SET_PRODUCT_ID,
  RESET_PRODUCT_CONFIG,
  SET_ILLUSTRATIONS_ACTION,
  SET_QUOTES_ACTION,
  SET_ERROR_ACTION,
  SET_CUSTOMERS_ACTION,
  SET_CUSTOMER_DETAIL_ACTION,
  SET_DELETE_ILLUSTRATION_ACTION,
} from './constants';

export const initialState = fromJS({
  productConfig: {},
  productConfig2: {},
  illustrations: [],
  quotes: {},
  productId: '',
  error: { isValid: true, errorList: [] },
  customers: [],
  customerDetail: {},
});

function illustrationPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PRODUCT_CONFIG:
      return state.set('productConfig', fromJS(action.payload));
    case SET_PRODUCT_CONFIG2:
      return state.set('productConfig2', fromJS(action.payload));
    case SET_PRODUCT_ID:
      return state.set('productId', action.payload);
    case RESET_PRODUCT_CONFIG:
      return state.set('productConfig', fromJS({})).set('productId', '');
    case SET_ERROR_ACTION:
      return state.set('error', fromJS(action.payload));
    case SET_ILLUSTRATIONS_ACTION:
      return state.set('illustrations', fromJS(action.payload));
    case SET_CUSTOMERS_ACTION:
      return state.set('customers', fromJS(action.payload));
    case SET_QUOTES_ACTION:
      return state.set('quotes', fromJS(action.payload));
    case SET_CUSTOMER_DETAIL_ACTION:
      return state.set('customerDetail', fromJS(action.payload));
    case SET_DELETE_ILLUSTRATION_ACTION:
      const { idDelete, message } = action.payload;
      const { illustrations } = state.toJS();
      let newListIllustration;
      if (message === 'SUCCESS') {
        newListIllustration = illustrations.filter(
          item => item.prospect.id !== idDelete,
        );
      }
      return state.set('illustrations', fromJS(newListIllustration));
    default:
      return state;
  }
}

export default illustrationPageReducer;

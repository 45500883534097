/*
 * ApplicationFormPage Messages
 *
 * This contains all the text for the ApplicationFormPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.ApplicationFormPage';

export default defineMessages({
  pleaseEnter: {
    id: `${scope}.pleaseEnter`,
    defaultMessage: 'Please enter',
  },
  policyTermTitle: {
    id: `${scope}.policyTermTitle`,
    defaultMessage: 'Policy term',
  },
  interestRateTitle: {
    id: `${scope}.interestRateTitle`,
    defaultMessage: 'Interest rate',
  },
  Withdrawals: {
    id: `${scope}.Withdrawals`,
    defaultMessage: 'Withdrawals',
  },
  coverageTerm: {
    id: `${scope}.coverageTerm`,
    defaultMessage: 'Coverage term',
  },
  placeOfBirth: {
    id: `${scope}.placeOfBirth`,
    defaultMessage: 'Place of birth',
  },
  legalAge: {
    id: `${scope}.legalAge`,
    defaultMessage: `legal age is atleast 18`,
  },
  minAge: {
    id: `${scope}.minAge`,
    defaultMessage: `Minimum age is atleast 1`,
  },
  maxAge: {
    id: `${scope}.maxAge`,
    defaultMessage: `Maximum age is atleast 30`,
  },
  currencyOfIncome: {
    id: `${scope}.currencyOfIncome`,
    defaultMessage: 'Currency of Income',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  premiumPaymentTerm: {
    id: `${scope}.premiumPaymentTerm`,
    defaultMessage: 'Premium payment term',
  },
  negativeValuesNotAllowed: {
    id: `${scope}.negativeValuesNotAllowed`,
    defaultMessage: 'negative values are not allowed',
  },
  assumedGrowthRate: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'Assumed growth rate',
  },
  cannotExceed100Characters: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'cannot exceed character length of 100',
  },
  morethanLifeCoverages: {
    id: `${scope}.morethanLifeCoverages`,
    defaultMessage:
      'Total amount is more than the life cover of {curr} {value1}.',
  },
  exportFiles: {
    id: `${scope}.exportFiles`,
    defaultMessage: 'Export files',
  },
  advisorFeeLabel: {
    id: `${scope}.advisorFeeLabel`,
    defaultMessage: 'Fund investment advisor fee',
  },
  showingAllAvailableFunds: {
    id: `${scope}.showingAllAvailableFunds`,
    defaultMessage: 'Showing all available funds',
  },
  showingAllAvailableStrategies: {
    id: `${scope}.showingAllAvailableStrategies`,
    defaultMessage: 'Showing all available strategies',
  },
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the ApplicationFormPage container!',
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: 'Next',
  },
  exit: {
    id: `${scope}.exit`,
    defaultMessage: 'Exit',
  },
  strategies: {
    id: `${scope}.strategies`,
    defaultMessage: 'Strategies',
  },
  funds: { id: `${scope}.funds`, defaultMessage: 'Funds' },
  isNotValidDate: {
    id: `${scope}.isNotValidDate`,
    defaultMessage: 'this is not a valid date',
  },
  saveQuote: {
    id: `${scope}.saveQuote`,
    defaultMessage: 'Save This Quote',
  },
  basicInformation: {
    id: `${scope}.basicInformation`,
    defaultMessage: 'Basic Information',
  },
  flatNumber: {
    id: `${scope}.flatNumber`,
    defaultMessage: 'Flat/Villa number',
  },
  flatNumber2: {
    id: `${scope}.flatNumber`,
    defaultMessage: 'Flat/Villa number/Office number',
  },
  propertyName: {
    id: `${scope}.propertyName`,
    defaultMessage: 'Property/Building Name',
  },
  area: {
    id: `${scope}.area`,
    defaultMessage: 'Area',
  },
  poBox: {
    id: `${scope}.poBox`,
    defaultMessage: 'P/O Box',
  },
  poBoxOptional: {
    id: `${scope}.p`,
    defaultMessage: 'P/O Box (optional)',
  },
  emirateCity: {
    id: `${scope}.emirateCity`,
    defaultMessage: 'Emirate/city',
  },
  emirateCityOptional: {
    id: `${scope}.emirateCityOptional`,
    defaultMessage: 'Emirate/city (optional)',
  },
  city: {
    id: `${scope}.city`,
    defaultMessage: 'City',
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country',
  },
  countryCode: {
    id: `${scope}.countryCode`,
    defaultMessage: 'Country Code',
  },
  telephoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Telephone number (optional)',
  },
  phoneNumberOptional: {
    id: `${scope}.phoneNumberOptional`,
    defaultMessage: 'Phone number (optional)',
  },
  emailAddress: {
    id: `${scope}.emailAddress`,
    defaultMessage: 'Email Address',
  },
  isDifferentAddress: {
    id: `${scope}.isDifferentAddress`,
    defaultMessage: 'Do you have a different correspondance address ?',
  },
  isOtherNamesKnownBy: {
    id: `${scope}.isOtherNamesKnownBy`,
    defaultMessage: 'Do you have any other names you are known by ?',
  },
  // Information
  assignOtherBeneficiary: {
    id: `${scope}.assignOtherBeneficiary`,
    defaultMessage: 'Or assign other primary beneficiaries',
  },
  assignBeneficiary: {
    id: `${scope}.assignBeneficiary`,
    defaultMessage: 'Assign beneficiary',
  },
  assignOtherBeneficiaryContigent: {
    id: `${scope}.assignOtherBeneficiaryContigent`,
    defaultMessage: 'Assign other (contingent) beneficiaries?',
  },
  assigneeAppointed: {
    id: `${scope}.assigneeAppointed`,
    defaultMessage: 'Will there be a conditional assignee appointed?',
  },
  assignBenefitToAnotherPolicy: {
    id: `${scope}.assignBenefitToAnotherPolicy`,
    defaultMessage:
      'Do you wish to assign another to benefit from this policy ?',
  },
  assignAsBeneficiary: {
    id: `${scope}.assignAsBeneficiary`,
    defaultMessage: 'Do you wish to assign a bank as beneficiary ?',
  },
  thirdPersonPolicy: {
    id: `${scope}.thirdPersonPolicy`,
    defaultMessage: 'Do you wish a third person paying for your policy ?',
  },
  companyPayingForYourPolicy: {
    id: `${scope}.companyPayingForYourPolicy`,
    defaultMessage: 'Do you have a companyPayingfor your policy ?',
  },

  //Lifestyle
  weight: {
    id: `${scope}.weight`,
    defaultMessage: 'Weight (kgs)',
  },
  height: {
    id: `${scope}.height`,
    defaultMessage: 'Height (cms)',
  },

  //documents

  proofOfIdentity: {
    id: `${scope}.proofOfIdentity`,
    defaultMessage: 'Proof of Identity',
  },
  otherDocs: { id: `${scope}.otherDocs`, defaultMessage: 'Other Documents' },
  medicalDocs: {
    id: `${scope}.medicalDocs`,
    defaultMessage: 'Medical Documents',
  },
  additionalDocs: {
    id: `${scope}.additionalDocs`,
    defaultMessage: 'Additional Documents',
  },
  open: { id: `${scope}.open`, defaultMessage: 'Open' },
  send: { id: `${scope}.send`, defaultMessage: 'Send' },
  upload: { id: `${scope}.upload`, defaultMessage: 'Upload' },
  uploadDocument: {
    id: `${scope}.uploadDocument`,
    defaultMessage: 'Upload Document',
  },
  uploadDocumentAndTakePhoto: {
    id: `${scope}.uploadDocumentAndTakePhoto`,
    defaultMessage: 'Take a photo or upload a document',
  },

  //Beneficiary
  whoPrimaryBeneficieary: {
    id: `${scope}.whoPrimaryBeneficieary`,
    defaultMessage: 'Who will be the primary beneficiaries?',
  },
  asPerSharia: {
    id: `${scope}.asPerSharia`,
    defaultMessage: 'As per Sharia law',
  },
  nominatedEachOther: {
    id: `${scope}.nominatedEachOther`,
    defaultMessage: '{value1} & {value2} nominate each other',
  },
  policyOwner: {
    id: `${scope}.policyOwner`,
    defaultMessage: 'Policy Owner {number}: {name}',
  },
  alternateName: {
    id: `${scope}.alternateName`,
    defaultMessage: 'Alternate name (if applicable)',
  },

  dualNationality: {
    id: `${scope}.dualNationality`,
    defaultMessage: 'Dual nationality (if applicable)',
  },
  primaryNationality: {
    id: `${scope}.primaryNationality`,
    defaultMessage: 'Nationality',
  },
  countryOfBirth: {
    id: `${scope}.countryOfBirth`,
    defaultMessage: 'Country of birth',
  },
  mobileNumber: {
    id: `${scope}.mobileNumber`,
    defaultMessage: 'Mobile number',
  },
  emirate: {
    id: `${scope}.emirate`,
    defaultMessage: 'Emirate/city',
  },
  poBox: { id: `${scope}.poBox`, defaultMessage: 'P.O. Box' },
  no: { id: `${scope}.no`, defaultMessage: 'no' },
  isPEP: {
    id: `${scope}.isPEP`,
    defaultMessage: 'Are you a politically exposed person (PEP)?',
  },
  valueCannotBeZero: {
    id: `${scope}.valueCannotBeZero`,
    defaultMessage: 'Value cannot be 0',
  },
  citizen: {
    id: `${scope}.citizen`,
    defaultMessage: 'Are you a U.S citizen?',
  },
  tooltipCitizen: {
    id: `${scope}.tooltipCitizen`,
    defaultMessage: 'tooltipCitizen',
  },
  taxpayer: {
    id: `${scope}.taxpayer`,
    defaultMessage: 'Are you a U.S taxpayer?',
  },
  taxPurposes: {
    id: `${scope}.taxPurposes`,
    defaultMessage:
      'Are you deemed resident in another country for tax purposes?',
  },
  countryOfTax: {
    id: `${scope}.countryOfTax`,
    defaultMessage: 'Country of tax residence',
  },
  taxReferenceNumber: {
    id: `${scope}.taxReferenceNumber`,
    defaultMessage: 'Tax reference number',
  },
  taxInsuranceNumber: {
    id: `${scope}.taxInsuranceNumber`,
    defaultMessage: 'National Insurance number',
  },
  isSameAddress: {
    id: `${scope}.isSameAddress`,
    defaultMessage:
      'Do you have a different residential address from {firstName} {lastName}',
  },
  isSameEmailId: {
    id: `${scope}.isSameEmailId`,
    defaultMessage:
      'Mail ID of both policy owners cannot be the same',
  },
  whoIsPaying: {
    id: `${scope}.whoIsPaying`,
    defaultMessage: 'Who will be paying for the policy?',
  },
  thirdPersonPayor: {
    id: `${scope}.thirdPersonPayor`,
    defaultMessage: 'Or is there a third person paying for the policy?',
  },
  relationshipToLifeInsured: {
    id: `${scope}.relationshipToLifeInsured`,
    defaultMessage: 'Relationship to life insured',
  },
  relationshipToPolicyOwner2: {
    id: `${scope}.relationshipToPolicyOwner2`,
    defaultMessage: 'Relationship to policy owner',
  },
  relationshipToPolicyOwner: {
    id: `${scope}.relationshipToPolicyOwner`,
    defaultMessage: 'Reason a third party is paying',
  },
  reasonWhyThirdIsPaying: {
    id: `${scope}.reasonWhyThirdIsPaying`,
    defaultMessage: 'Reason a third party is paying',
  },
  otherReasonWhyThirdIsPaying: {
    id: `${scope}.otherReasonWhyThirdIsPaying`,
    defaultMessage: 'Other Reasons for third party paying',
  },
  reasonWhyThirdIsPayment: {
    id: `${scope}.reasonWhyThirdIsPayment`,
    defaultMessage: 'Reason for third party payment',
  },
  other: {
    id: `${scope}.other`,
    defaultMessage: 'Other',
  },
  pepDescription: {
    id: `${scope}.pepDescription`,
    defaultMessage:
      'Politically exposed persons are individuals or international entities who are, or have been, entrusted with prominent public functions and their immediate family members and close associates. A full description can be found in the ‘Customer’s guide to AML.',
  },
  selectOneBeneficiaries: {
    id: `${scope}.selectOneBeneficiaries`,
    defaultMessage:
      "Please select at least one beneficiary or select 'As per Sharia law'",
  },
  willPayingBySalary: {
    id: `${scope}.willPayingBySalary`,
    defaultMessage: 'Will the policy be paid for by salary/business income?',
  },
  savingIncomeBonus: {
    id: `${scope}.savingIncomeBonus`,
    defaultMessage: 'Savings from income/salary/company profits/bonus',
  },
  salaryCurrency: {
    id: `${scope}.salaryCurrency`,
    defaultMessage: 'Salary currency',
  },
  saleCurrency: {
    id: `${scope}.saleCurrency`,
    defaultMessage: 'Sale currency',
  },
  annualIncome: {
    id: `${scope}.annualIncome`,
    defaultMessage: 'Annual income',
  },
  chooseInstitution: {
    id: `${scope}.chooseInstitution`,
    defaultMessage: 'Choose institution',
  },
  reasonForReassignment: {
    id: `${scope}.reasonForReassignment`,
    defaultMessage: 'Reason for assignment',
  },
  takeAPhoto: {
    id: `${scope}.takeAPhoto`,
    defaultMessage: 'Take a Photo',
  },
  cancelExit: {
    id: `${scope}.cancelExit`,
    defaultMessage: 'Cancel and Exit',
  },
  attachDocuments: {
    id: `${scope}.attachDocuments`,
    defaultMessage: 'Attach Documents',
  },
  sendEmail: {
    id: `${scope}.sendEmail`,
    defaultMessage: 'Send Email',
  },
  success: {
    id: `${scope}.success`,
    defaultMessage: 'Success',
  },

  status: { id: `${scope}.status`, defaultMessage: 'Status: {value}' },
  policySummary: {
    id: `${scope}.policySummary`,
    defaultMessage: 'Policy Summary',
  },
  policySummary2: {
    id: `${scope}.policySummary`,
    defaultMessage: '{name} Policy summary',
  },
  premiumAmount: {
    id: `${scope}.premiumAmount`,
    defaultMessage: 'Premium amount',
  },
  itaPolicySummary: {
    id: `${scope}.itaPolicySummary`,
    defaultMessage: 'ITA Policy summary',
  },
  dtaPolicySummary: {
    id: `${scope}.itaPolicySummary`,
    defaultMessage: 'IDTA Policy summary',
  },
  globalChoicePolicySummary: {
    id: `${scope}.globalChoicePolicySummary`,
    defaultMessage: 'Global Choice Policy summary',
  },
  wealthAccumulationPlanSummary: {
    id: `${scope}.wealthAccumulationPlanSummary`,
    defaultMessage: 'Wealth Accumulation Plan Policy summary',
  },
  simpleWealthPlanSummary: {
    id: `${scope}.simpleWealthPlanSummary`,
    defaultMessage: 'Simple Wealth Policy summary',
  },
  regularSavingsPlan: {
    id: `${scope}.regularSavingsPlan`,
    defaultMessage: 'Regular Savings plan',
  },
  futuPolicySummary: {
    id: `${scope}.futuPolicySummary`,
    defaultMessage: 'FUTU Policy summary',
  },
  underwritingQuestions: {
    id: `${scope}.underwritingQuestions`,
    defaultMessage: 'Underwriting questions',
  },
  viewExportPDF: {
    id: `${scope}.viewExportPDF`,
    defaultMessage: 'View/Export PDF',
  },
  emailPDF: {
    id: `${scope}.viemailPDFewExportPDF`,
    defaultMessage: 'Email PDF',
  },
  systolicBloodPressure: {
    id: `${scope}.systolicBloodPressure`,
    defaultMessage: 'Systolic blood pressure',
  },
  diastolicBloodPressure: {
    id: `${scope}.diastolicBloodPressure`,
    defaultMessage: 'Diastolic blood pressure',
  },
  maximumPercentage100Allowed: {
    id: `${scope}.maximumPercentage100Allowed`,
    defaultMessage: 'Maximum Percentage is 100%',
  },
  totalPrecentMustBe100: {
    id: `${scope}.totalPrecentMustBe100`,
    defaultMessage: 'Total percent must be 100%',
  },
  sharePercentageMorethan100: {
    id: `${scope}.sharePercentageMorethan100`,
    defaultMessage: 'Share Percentage cannot exceed 100% between parties',
  },
  sharePercentageLessThan100: {
    id: `${scope}.sharePercentageLessThan100`,
    defaultMessage: 'Share Percentage cannot be less than 100% between parties',
  },
  sumOfSharePercentageShouldBe100: {
    id: `${scope}.sumOfSharePercentageShouldBe100`,
    defaultMessage: 'Sum of share percentage should be 100% between parties',
  },
  invalidNumber: {
    id: `${scope}.invalidNumber`,
    defaultMessage: 'Invalid number',
  },
  documentRequireTranslation: {
    id: `${scope}.documentRequireTranslation`,
    defaultMessage:
      'Document(s) uploaded for this application require translation',
  },
  documentsisLegitCopies: {
    id: `${scope}.documentsisLegitCopies`,
    defaultMessage:
      'The documents uploaded are true copies of the original documents, and any photographs on the original documents are a true likeness of the holder.',
  },
  UWConsent: {
    id: `${scope}.UWConsent`,
    defaultMessage:
      'I have read Zurich’s Privacy Notice which explains how personal data is collected and stored. I consent to my health data being used in this way.',
  },
  subsequentPaymentsOnly: {
    id: `${scope}.subsequentPaymentsOnly`,
    defaultMessage:
      'Direct debit will be used to collect subsequent payments only',
  },
  whoSettingUAEDD: {
    id: `${scope}.whoSettingUAEDD`,
    defaultMessage: 'Who will be setting up the UAE DD?',
  },
  howSettingUAEDD: {
    id: `${scope}.howSettingUAEDD`,
    defaultMessage: 'How would you like to set up the UAE DD?',
  },
  cardHolderName: {
    id: `${scope}.cardHolderName`,
    defaultMessage: 'Account holder/card holder name',
  },
  cardNumber: {
    id: `${scope}.cardNumber`,
    defaultMessage: 'Card number',
  },
  bankName: {
    id: `${scope}.bankName`,
    defaultMessage: 'Bank name',
  },
  IBAN: {
    id: `${scope}.IBAN`,
    defaultMessage: 'IBAN',
  },
  infomationIBAN: {
    id: `${scope}.infomationIBAN`,
    defaultMessage: 'Infomation about IBAN',
  },
  lifeInsured: {
    id: `${scope}.lifeInsured`,
    defaultMessage: 'Life Insured {number}: {name}',
  },
  lifeInsuredChild: {
    id: `${scope}.lifeInsured`,
    defaultMessage: 'Life Insured Details: {name}',
  },
  lifeInsuredPayor: {
    id: `${scope}.lifeInsuredPayor`,
    defaultMessage: 'Or is {firstName} {lastName} paying for the policy?',
  },
  atleastOnePayor: {
    id: `${scope}.lifeInsuredPayor`,
    defaultMessage: 'Please select at least one payor.',
  },
  companyPayor: {
    id: `${scope}.companyPayor`,
    defaultMessage: 'Is the payor a company?',
  },
  paymentDetails: {
    id: `${scope}.paymentDetails`,
    defaultMessage: 'Payment details',
  },
  originalDirectDebit: {
    id: `${scope}.originalDirectDebit`,
    defaultMessage: 'Please send cheque to:',
  },
  nameAsPerCard: {
    id: `${scope}.nameAsPerCard`,
    defaultMessage: 'Name as per card',
  },
  cvc: {
    id: `${scope}.cvc`,
    defaultMessage: 'CVC',
  },
  infomationCVC: {
    id: `${scope}.infomationCVC`,
    defaultMessage:
      'The Card Verification Number (CVV) is the last three digit number printed on the signature panel located on the back of your card.',
  },
  expiry: {
    id: `${scope}.expiry`,
    defaultMessage: 'Expiry',
  },
  cardCurrency: {
    id: `${scope}.cardCurrency`,
    defaultMessage: 'Card currency',
  },
  collectionDay: {
    id: `${scope}.collectionDay`,
    defaultMessage: 'Collection day',
  },
  accountHoldersName: {
    id: `${scope}.accountHoldersName`,
    defaultMessage: 'Account holder’s name',
  },
  accountNumber: {
    id: `${scope}.accountNumber`,
    defaultMessage: 'Account number',
  },
  physicalCheque: {
    id: `${scope}.physicalCheque`,
    defaultMessage:
      'Zurich will need the physical cheque in order to process the application',
  },
  paymentErrorDeclined: {
    id: `${scope}.paymentErrorDeclined`,
    defaultMessage:
      'The payment was declined. Please check for errors, or try using alternative payment details.',
  },
  companyName: {
    id: `${scope}.companyName`,
    defaultMessage: 'Company name',
  },
  PayorProceeds: {
    id: `${scope}.PayorProceeds`,
    defaultMessage: 'Proceeds from shares/investment holdings/property sale?',
  },
  addressProperty: {
    id: `${scope}.addressProperty`,
    defaultMessage: 'Details/address of property',
  },
  sharesProperty: {
    id: `${scope}.sharesProperty`,
    defaultMessage: 'Details of shares/investment holding property sale',
  },
  addAnother: {
    id: `${scope}.addAnother`,
    defaultMessage: 'Add another',
  },
  removeItem: {
    id: `${scope}.removeItem`,
    defaultMessage: 'Remove Item',
  },
  otherWealth: {
    id: `${scope}.otherWealth`,
    defaultMessage: 'Other sources of wealth?',
  },
  originOfWealth: {
    id: `${scope}.originOfWealth`,
    defaultMessage: 'Please specify the origin of wealth',
  },
  nameOfCompany: {
    id: `${scope}.nameOfCompany`,
    defaultMessage: 'Name of company',
  },
  natureOfCompany: {
    id: `${scope}.natureOfCompany`,
    defaultMessage: 'Nature of company',
  },
  dateOfSale: {
    id: `${scope}.dateOfSale`,
    defaultMessage: 'Date of Sale',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  totalAmount: {
    id: `${scope}.totalAmount`,
    defaultMessage: 'Total amount',
  },
  sharePercentage: {
    id: `${scope}.sharePercentage`,
    defaultMessage: 'Share percentage %',
  },
  descriptionOfMaturing: {
    id: `${scope}.descriptionOfMaturing`,
    defaultMessage: 'Description of maturing investments',
  },
  dateOfMaturity: {
    id: `${scope}.dateOfMaturity`,
    defaultMessage: 'Date of maturity',
  },
  maturingProceeds: {
    id: `${scope}.maturingProceeds`,
    defaultMessage: 'Maturing proceeds',
  },
  detailsOfInheritance: {
    id: `${scope}.detailsOfInheritance`,
    defaultMessage: 'Details of inheritance',
  },
  dateReceived: {
    id: `${scope}.dateReceived`,
    defaultMessage: 'Date received',
  },
  nameOfLoanProvider: {
    id: `${scope}.nameOfLoanProvider`,
    defaultMessage: 'Name of loan provider',
  },
  natureOfLoanProvider: {
    id: `${scope}.natureOfLoanProvider`,
    defaultMessage: 'Nature of loan provider',
  },
  dateOfLoan: {
    id: `${scope}.dateOfLoan`,
    defaultMessage: 'Date of loan',
  },
  reasonForGift: {
    id: `${scope}.reasonForGift`,
    defaultMessage: 'Reason for gift',
  },
  relationshipToApplicant: {
    id: `${scope}.relationshipToApplicant`,
    defaultMessage: 'Relationship to applicant',
  },
  donorsOriginOfWealth: {
    id: `${scope}.donorsOriginOfWealth`,
    defaultMessage: 'Donors origin of wealth',
  },
  dateOfWin: {
    id: `${scope}.dateOfWin`,
    defaultMessage: 'Date of win',
  },
  detailsOfWin: {
    id: `${scope}.detailsOfWin`,
    defaultMessage: 'Details of win',
  },
  detailsOfOrganisation: {
    id: `${scope}.detailsOfOrganisation`,
    defaultMessage: 'Details of organisation',
  },
  reasonForPayment: {
    id: `${scope}.reasonForPayment`,
    defaultMessage: 'Reason for payment',
  },
  natureOfBusiness: {
    id: `${scope}.natureOfBusiness`,
    defaultMessage: 'Nature of business',
  },
  detailsOfTurnover: {
    id: `${scope}.detailsOfTurnover`,
    defaultMessage: 'Details of Turnover',
  },

  retirementDate: {
    id: `${scope}.retirementDate`,
    defaultMessage: 'Retirement date',
  },
  detailsOfPreviousProfession: {
    id: `${scope}.detailsOfPreviousProfession`,
    defaultMessage: 'Details of previous profession/occupation',
  },
  nameNAddressOfLastEmployer: {
    id: `${scope}.nameNAddressOfLastEmployer`,
    defaultMessage: 'Name and address of last employer',
  },
  approxSalary: {
    id: `${scope}.approxSalary`,
    defaultMessage: 'Approx figure of salary on retirement',
  },
  pensionIncome: {
    id: `${scope}.pensionIncome`,
    defaultMessage: 'Pension income',
  },
  natureOfSource: {
    id: `${scope}.natureOfSource`,
    defaultMessage: 'Nature of Source',
  },
  recievedFrom: {
    id: `${scope}.recievedFrom`,
    defaultMessage: 'Received from',
  },
  medicalFacility: {
    id: `${scope}.medicalFacility`,
    defaultMessage: 'Medical Facility',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name',
  },
  phoneNumber: {
    id: `${scope}.phoneNumber`,
    defaultMessage: 'Phone number',
  },
  addressLine1: {
    id: `${scope}.addressLine1`,
    defaultMessage: 'Address Line 1',
  },
  addressLine2: {
    id: `${scope}.addressLine2`,
    defaultMessage: 'Address Line 2',
  },
  stateOrProvince: {
    id: `${scope}.stateOrProvince`,
    defaultMessage: 'State or Province',
  },
  zipCode: {
    id: `${scope}.zipCode`,
    defaultMessage: 'Zip Code',
  },
  selectExistingDoctor: {
    id: `${scope}.selectExistingDoctor`,
    defaultMessage: 'Select existing doctor',
  },
  selectDateFormat: {
    id: `${scope}.selectDateFormat`,
    defaultMessage: 'Select date format',
  },
  selectDate: {
    id: `${scope}.selectDate`,
    defaultMessage: 'Select date',
  },
  addAValueMonth: {
    id: `${scope}.addAValueMonth`,
    defaultMessage: 'Add a value (month)',
  },
  addAValueYear: {
    id: `${scope}.addAValueYear`,
    defaultMessage: 'Add a value (year)',
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete',
  },
  sourceOfFunds: {
    id: `${scope}.sourceOfFunds`,
    defaultMessage: 'Please provide more details on the source of your funds',
  },
  earnedSalary: {
    id: `${scope}.earnedSalary`,
    defaultMessage: 'Earned income/salary',
  },
  investmentIncome: {
    id: `${scope}.investmentIncome`,
    defaultMessage: 'Investment income',
  },
  otherIncome: {
    id: `${scope}.otherIncome`,
    defaultMessage: 'Other Income',
  },
  existingZurichPlan: {
    id: `${scope}.existingZurichPlan`,
    defaultMessage:
      'Would you like this policy to replace an existing Zurich plan?',
  },
  lifeOffices: {
    id: `${scope}.lifeOffices`,
    defaultMessage:
      'Do you have any concurrent applications to other life offices?',
  },
  isPolicyNumber: {
    id: `${scope}.isPolicyNumber`,
    defaultMessage: 'Do you have this policy number to hand?',
  },
  existingPolicyNumber: {
    id: `${scope}.existingPolicyNumber`,
    defaultMessage: 'Existing policy number',
  },
  policyType: {
    id: `${scope}.policyType`,
    defaultMessage: 'Policy type',
  },
  amountOfCover: {
    id: `${scope}.amountOfCover`,
    defaultMessage: 'Amount of cover',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Policy term',
  },
  policyCurrency: {
    id: `${scope}.policyCurrency`,
    defaultMessage: 'Policy currency',
  },
  pleaseGiveDetails: {
    id: `${scope}.pleaseGiveDetails`,
    defaultMessage: 'Please give details',
  },
  potentialBorrowers: {
    id: `${scope}.potentialBorrowers`,
    defaultMessage: 'Details of lenders/potential borrowers etc',
  },
  isOtherParties: {
    id: `${scope}.isOtherParties`,
    defaultMessage:
      'Are there any other parties directly involved with this application?',
  },
  isBankAccount: {
    id: `${scope}.isBankAccount`,
    defaultMessage: 'Do you have more than one bank account?',
  },
  bankName: {
    id: `${scope}.bankName`,
    defaultMessage: 'Bank name',
  },
  accountNumber: {
    id: `${scope}.accountNumber`,
    defaultMessage: 'Account number',
  },
  accountHeld: {
    id: `${scope}.accountHeld`,
    defaultMessage: ' How long has the account be held for?',
  },
  isPaymentDetails: {
    id: `${scope}.isPaymentDetails`,
    defaultMessage: 'Save these for my payment details',
  },
  agreeAndProceed: {
    id: `${scope}.agreeAndProceed`,
    defaultMessage: 'Agree and proceed',
  },
  totalSaleAmount: {
    id: `${scope}.totalSaleAmount`,
    defaultMessage: 'Total sale amount',
  },
  selectUnit: {
    id: `${scope}.selectUnit`,
    defaultMessage: 'Please select a unit',
  },
  USCitizencannotProceed: {
    id: `${scope}.USCitizencannotProceed`,
    defaultMessage: 'US Citizens cannot proceed',
  },
  awaitingActionText1: {
    id: `${scope}.awaitingActionText`,
    defaultMessage: `Your application will be reviewed by an expert underwriter.`,
  },
  awaitingActionText2: {
    id: `${scope}.awaitingActionText2`,
    defaultMessage: `Please complete the next steps and submit the application.`,
  },
  awaitingActionText3: {
    id: `${scope}.awaitingActionText3`,
    defaultMessage: `We'll update the progress of the application on the pipeline.`,
  },
  policyOwners: {
    id: `${scope}.policyOwners`,
    defaultMessage: 'Policy Owner',
  },
  theInsured: {
    id: `${scope}.theInsured`,
    defaultMessage: 'The insured',
  },
  payor: {
    id: `${scope}.payor`,
    defaultMessage: 'Payor',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium Summary',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Cover details',
  },
  policyDocuments: {
    id: `${scope}.policyDocuments`,
    defaultMessage: 'Policy documents',
  },
  sendForSignature: {
    id: `${scope}.sendForSignature`,
    defaultMessage: 'Send for signature',
  },
  proceedSendSignature: {
    id: `${scope}.proceedSendSignature`,
    defaultMessage:
      'To proceed, please send the policy documents for digital signature',
  },
  consentContacted: {
    id: `${scope}.consentContacted`,
    defaultMessage:
      'I/we consent to being contacted by the Company, or the Zurich group companies, by mail, email, telephone or other appropriate means about carefully selected products, services or offers that may be of interest to you. ',
  },
  addPrimaryBeneficiary: {
    id: `${scope}.addPrimaryBeneficiary`,
    defaultMessage: 'Add another primary beneficiary',
  },
  addContingentBeneficiary: {
    id: `${scope}.addContingentBeneficiary`,
    defaultMessage: 'Add another (contingent) beneficiary',
  },
  addAnother: {
    id: `${scope}.addAnother`,
    defaultMessage: 'Add another',
  },
  branchName: {
    id: `${scope}.branchName`,
    defaultMessage: 'Branch name',
  },
  confirmAndSend: {
    id: `${scope}.confirmAndSend`,
    defaultMessage: 'Confirm and send',
  },
  lifeInsuredTwo: {
    id: `${scope}.lifeInsuredTwo`,
    defaultMessage: 'Life insured 2',
  },
  lifeInsuredOne: {
    id: `${scope}.lifeInsuredOne`,
    defaultMessage: 'Life insured 1',
  },
  policyOwnerEmail: {
    id: `${scope}.policyOwnerEmail`,
    defaultMessage: 'Policy owner 1',
  },
  policyOwner2: {
    id: `${scope}.policyOwner2`,
    defaultMessage: 'Policy owner 2',
  },
  beneficiaries: {
    id: `${scope}.beneficiaries`,
    defaultMessage: 'Beneficiaries',
  },
  deleteBeneficiary: {
    id: `${scope}.deleteBeneficiary`,
    defaultMessage: 'Delete this beneficiary',
  },
  maximumNumberReached: {
    id: `${scope}.maximumNumberReached`,
    defaultMessage: 'Maximum number of retries has been reached',
  },
  refresh: {
    id: `${scope}.refresh`,
    defaultMessage: 'Refresh',
  },
  allowedPercentage100: {
    id: `${scope}.allowedPercentage100`,
    defaultMessage: 'Allowed percentage is 100%',
  },
  pleaseProvideDetails: {
    id: `${scope}.pleaseProvideDetails`,
    defaultMessage: 'Please provide details',
  },
  futureDates: {
    id: `${scope}.futureDates`,
    defaultMessage: 'Future dates cannot be entered',
  },
  submitDetails: {
    id: `${scope}.submitDetails`,
    defaultMessage: 'Submit details',
  },
  SpecialTerms: {
    id: `${scope}.SpecialTerms`,
    defaultMessage: 'Special Terms',
  },
  tryAnotherCard: {
    id: `${scope}.tryAnotherCard`,
    defaultMessage: 'Try another card',
  },
  showAll: {
    id: `${scope}.showAll`,
    defaultMessage: 'Show All',
  },
  showAllFunds: {
    id: `${scope}.showAllFunds`,
    defaultMessage: 'Show all funds',
  },
  showAllStrategies: {
    id: `${scope}.showAllStrategies`,
    defaultMessage: 'Show all strategies',
  },
  showtaxReferenceNumber: {
    id: `${scope}.showtaxReferenceNumber`,
    defaultMessage:
      'If you are currently tax resident in the United Kindgom, please provide your National Insurance Number',
  },
  warningIsCityzen: {
    id: `${scope}.warningIsCityzen`,
    defaultMessage: 'Warning',
  },
  warningContentIsCityzen: {
    id: `${scope}.warningContentIsCityzen`,
    defaultMessage:
      'One or more parties has indicated that they are a US citizen and/or US tax payor. Therefore you cannot proceed and this application is void.',
  },
  errorAmount: {
    id: `${scope}.errorAmount`,
    defaultMessage: 'Amount cannot be negative value',
  },
  employerName: {
    id: `${scope}.employerName`,
    defaultMessage: "Employer's name",
  },
  armyservice: {
    id: `${scope}.armyservice`,
    defaultMessage: "Are you employed with UAE armed services?",
  },
  nameOfJobTitle: {
    id: `${scope}.nameOfJobTitle`,
    defaultMessage: "Job Title",
  },
  employerAddress: {
    id: `${scope}.employerAddress`,
    defaultMessage: "Employer's address",
  },
  countrySide: {
    id: `${scope}.countrySide`,
    defaultMessage: 'Country side',
  },
  employerPhone: {
    id: `${scope}.employerPhone`,
    defaultMessage: 'Employer’s telephone number',
  },
  bonusAmount: {
    id: `${scope}.bonusAmount`,
    defaultMessage: 'Income per annum and/or bonus amount',
  },
  natureOfCompanyBusiness: {
    id: `${scope}.natureOfCompanyBusiness`,
    defaultMessage: 'Nature of company business',
  },
  durationOfEmployment: {
    id: `${scope}.durationOfEmployment`,
    defaultMessage: 'Number of years employed with company',
  },
  durationOfSavingsAndBonus: {
    id: `${scope}.durationOfSavingsAndBonus`,
    defaultMessage: 'Duration of savings and bonus amount',
  },
  durationOfsavingsLabel: {
    id: `${scope}.durationOfsavingsLabel`,
    defaultMessage: 'Number of years you have been saving',
  },
  madePayableCheque: {
    id: `${scope}.madePayableCheque`,
    defaultMessage: 'Please make cheques must be made payable to:',
  },
  ZILT: {
    id: `${scope}.ZILT`,
    defaultMessage: 'Zurich International Life Limited',
  },
  POBox: {
    id: `${scope}.POBox`,
    defaultMessage: 'P O Box — 50389',
  },
  POBoxBH: {
    id: `${scope}.POBoxBH`,
    defaultMessage: 'P O Box — 10032',
  },
  POBoxQA: {
    id: `${scope}.POBoxQA`,
    defaultMessage: 'P O Box — 26777, 404 Fourth Floor',
  },
  UAE: {
    id: `${scope}.UAE`,
    defaultMessage: 'United Arab Emirates',
  },
  dubai: {
    id: `${scope}.dubai`,
    defaultMessage: 'Dubai',
  },
  QAAddress: {
    id: `${scope}.QAAddress`,
    defaultMessage: 'Qatar Financial Centre Tower',
  },
  BHAddress: {
    id: `${scope}.BHAddress`,
    defaultMessage: '27th floor, Almoayyed Tower',
  },
  BH: {
    id: `${scope}.BH`,
    defaultMessage: 'Seef District, Kingdom of Bahrain',
  },
  QA: {
    id: `${scope}.QA`,
    defaultMessage: 'West Bay, Doha, Qatar',
  },
  logout: {
    id: `${scope}.logout`,
    defaultMessage: 'Logout',
  },
  goToDashboard: {
    id: `${scope}.goToDashboard`,
    defaultMessage: 'Go to dashboard',
  },
  thankYou: {
    id: `${scope}.thankYou`,
    defaultMessage: 'Thank you, your application has been submitted to Zurich.',
  },
  // For nexus FAB admin - hold application
  thankYouForAdminReview: {
    id: `${scope}.thankYouForAdminReview`,
    defaultMessage: 'Thank you, your application has been sent for Admin Review.',
  },
  submitForReview: {
    id: `${scope}.submitForReview`,
    defaultMessage: 'Submit For Approval',
  },
  sameAsPrimaryNationalityNotAllowed: {
    id: `${scope}.sameAsPrimaryNationalityNotAllowed`,
    defaultMessage: 'cannot be the same as primary nationality',
  },
  requireYes: {
    id: `${scope}.requireYes`,
    defaultMessage: 'This field is required Yes option',
  },
  returnToHub: {
    id: `${scope}.returnToHub`,
    defaultMessage: 'Return to Hub',
  },
  howWillThePolicyBePaid: {
    id: `${scope}.howWillThePolicyBePaid`,
    defaultMessage: 'How will the policy be paid?',
  },
  policyTermsAndCond: {
    id: `${scope}.policyTermsAndCond`,
    defaultMessage: 'While the Policy Terms and Conditions apply a number of acceptable premium payment methods, given your purchase of the product has been intermediated through FAB your first premium settlement method has been restricted to be completed by way of bank transfer through your FAB bank account. You maintain the right to change your subsequent premium payments after your first premium been settled, to an alternative method of payment allowed by the Policy Terms and Conditions. By accepting to proceed with this proposal you acknowledge and accept this statement.',
  },
  chequeText: {
    id: `${scope}.chequeText`,
    defaultMessage:
      'Please note: Cheques are accepted only in UAE Dirham(USD1=3.6775), Bahraini Dinar(USD1=0.3775), and Qatari Riyal(USD1=3.65)',
  },
  approved: {
    id: `${scope}.approved`,
    defaultMessage: 'Approved',
  },
  years: {
    id: `${scope}.years`,
    defaultMessage: 'Years',
  },
  months: {
    id: `${scope}.months`,
    defaultMessage: 'Months',
  },
  setAsImage: {
    id: `${scope}.setAsImage`,
    defaultMessage: 'Set as image',
  },
  captureAgain: {
    id: `${scope}.captureAgain`,
    defaultMessage: 'Capture Again',
  },
  additionalSinglePremium: {
    id: `${scope}.additionalSinglePremium`,
    defaultMessage: 'Additional Single Premium',
  },
  UWInfo: {
    id: `${scope}.UWInfo`,
    defaultMessage:
      'You cannot change the inputs for this section once you  have submitted the form',
  },
  InfoBolton: {
    id: `${scope}.InfoBolton`,
    defaultMessage:
      'Beneficiaries apply to both {productName} & the ITA protection benefit. If the customer wishes to choose separate beneficiaries for each product that can be done in ZIO or by contacting Help point once the policy is issued.',
  },
  errorEmail: {
    id: `${scope}.errorEmail`,
    defaultMessage: '{message}',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error',
  },
  protectionCoverDetails: {
    id: `${scope}.protectionCoverDetails`,
    defaultMessage: 'Protection cover details',
  },
  fundError: {
    id: `${scope}.fundError`,
    defaultMessage:
      'The 1% fee has not been included in the original illustration and it will have an impact on the premium and illustrative values',
  },
  isSmoker: {
    id: `${scope}.isSmoker`,
    defaultMessage: 'Are you a smoker (Tobacco/Nicotine user)?',
  },
  preferPaymentMethod: {
    id: `${scope}.preferPaymentMethod`,
    defaultMessage:
      'Would you prefer to continue with existing payment method?',
  },
  policyBasis: {
    id: `${scope}.policyBasis`,
    defaultMessage: 'Policy basis',
  },
  investmentCaption: {
    id: `${scope}.investmentCaption`,
    defaultMessage:
      'The selected strategies will be applicable for the whole policy',
  },
  isUAEResidence: {
    id: `${scope}.isUAEResidence`,
    defaultMessage:
      'Is your UAE residence visa issued for a period of five years or more?',
  },
  obtainUAEResidence: {
    id: `${scope}.obtainUAEResidence`,
    defaultMessage:
      'Did you obtain UAE residency under a residency by investment scheme?',
  },
  areResidenceAnotherCountry: {
    id: `${scope}.areResidenceAnotherCountry`,
    defaultMessage:
      'Are you deemed resident in another country for tax purposes?',
  },
  visaTooltip: {
    id: `${scope}.visaTooltip`,
    defaultMessage: 'copy of your valid UAE residence visa',
  },
  authorizeCC: {
    id: `${scope}.authorizeCC`,
    defaultMessage: 'Authorize Credit Card',
  },
  payorName: {
    id: `${scope}.payorName`,
    defaultMessage: 'Name',
  },
  PAYMENT_REQUEST_LINK_FAIL: {
    id: `PAYMENT_REQUEST_LINK_FAIL`,
    defaultMessage: 'PAYMENT_REQUEST_LINK_FAIL',
  },
  sharedCCDetail: {
    id: `${scope}.sharedCCDetail`,
    defaultMessage: 'Is the customer willing to share credit card details?',
  },
  paymentLink: {
    id: `${scope}.paymentLink`,
    defaultMessage: 'No, the customer prefers to pay now with',
  },
  paymentLink2: {
    id: `${scope}.paymentLink2`,
    defaultMessage: 'a secure payment link',
  },
  willBeYourBeneficiary: {
    id: `${scope}.willBeYourBeneficiary`,
    defaultMessage: 'Who will be your beneficiary?',
  },
  willBeYourBeneficiary2: {
    id: `${scope}.willBeYourBeneficiary2`,
    defaultMessage:
      'In the unfortunate event of your death, who should receive your funeral cover amount?',
  },
  appointSCB: {
    id: `${scope}.appointSCB`,
    defaultMessage:
      'I hereby appoint Standard Chartered Bank as the conditional assignee of my policy by ticking here.',
  },
  noticeOfAssignment: {
    id: `${scope}.noticeOfAssignment`,
    defaultMessage: 'Please upload the notice of assignment',
  },
  lifeInsuredThree: {
    id: `${scope}.lifeInsuredThree`,
    defaultMessage: 'Life insured 3',
  },
});
/*
 *
 * ItaIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/ItaIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/ItaIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/ItaIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const GET_ITA_CONFIG_ACTION =
  'app/ItaIllustrationPage/GET_ITA_CONFIG_ACTION';
export const SET_ITA_CONFIG_ACTION =
  'app/ItaIllustrationPage/SET_ITA_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION =
  'app/ItaIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/ItaIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/ItaIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/ItaIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/ItaIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/ItaIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/ItaIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/ItaIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/ItaIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/ItaIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/ItaIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_ERROR_ACTION = 'app/ItaIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/ItaIllustrationPage/RESET_ACTION';
export const GET_FIA_ACTION = 'app/ItaIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/ItaIllustrationPage/SET_FIA_ACTION';
export const CLONE_QUOTE_ACTION = 'app/ItaIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/ItaIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/ItaIllustrationPage/SET_BI_PDF_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/ItaIllustrationPage/SET_PTV_VERSION_ACTION';
export const SET_PRODUCT_CODE_ACTION =
  'app/ItaIllustrationPage/SET_PRODUCT_CODE_ACTION';

export const SET_EMAIL_SENT_ACTION =
  'app/ItaIllustrationPage/SET_EMAIL_SENT_ACTION';

export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/ItaIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';

export const SET_COMPLETED_DIALOG =
  'app/ItaIllustrationPage/SET_COMPLETED_DIALOG';
export const SET_IS_CALCULATE_QUOTE_PAGE_ACTION =
  'app/ItaIllustrationPage/SET_IS_CALCULATE_QUOTE_PAGE_ACTION';

export const SET_CALCULATE_ANNUAL_PREMIUM_ACTION =
  'app/ItaIllustrationPage/SET_CALCULATE_ANNUAL_PREMIUM_ACTION';

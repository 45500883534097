/**
 *
 * Header
 *
 */

import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import ZLogo from 'images/logo-white.png';
import { FormattedMessage } from 'react-intl';

const TitleWrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 6.6rem;
    justify-content: center;
    padding-left: ${props => props.theme.spacing(2.5)}px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    margin-bottom: 4.4rem;
  }
`;

function Header({ title }) {
  const theme = useTheme();
  return (
    <Grid container>
      <Grid item>
        <div
          style={{
            width: 190,
            padding: '2rem 0',
            paddingLeft: '7px',
            textAlign: 'center',
            display: 'block',
            backgroundColor: theme.palette.primary.dark,
            borderBottom: `1px solid ${theme.palette.primary.dark}`,
          }}
        >
          <Link component={RouterLink} to="/">
            <img src={ZLogo} alt="zurich-logo" height={24} />
          </Link>
        </div>
      </Grid>
      <Grid item xs>
        <TitleWrapper theme={theme}>
          <Typography
            variant="body1"
            style={{
              fontWeight: '700',
            }}
          >
            {title}
          </Typography>
        </TitleWrapper>
      </Grid>
    </Grid>
  );
}

Header.propTypes = {
  title: PropTypes.object,
};

export default Header;

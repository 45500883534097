/**
 * BeneficiaryDetails
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import { forEach, map, filter, includes } from 'lodash/collection';
import { convertIfImmutable } from 'helpers/lang';
import { blue } from '@material-ui/core/colors';
import { mapFinancialInstituteOptions } from 'containers/ApplicationFormPage/helpers';
import {
  Grid,
  FormControlLabel,
  Radio,
  Button,
  Divider,
  IconButton,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import messages from 'containers/ApplicationFormPage/messages';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';

function BeneficiaryDetails({
  // formValues = {},
  fields,
  options,
  // error,
  newPartyPlaceholder,
  addLabel,
  financialInstituteOptions,
  applicationDetails,
}) {
  // const theme = useTheme();
  const intl = useIntl();
  const policyCurrency = applicationDetails.productConfigs?.currency;

  const addAssignee = () => {
    if (fields.length < 3) {
      fields.push({ ...newPartyPlaceholder });
    }
  };

  const filteredCardCurrency = filter(options.cardCurrency, cur => {
    const defaultCurrencies = ['AED', 'BHD', 'QAR', policyCurrency];
    return includes(defaultCurrencies, cur.value);
  });

  return fields.map((field, index) => {
    return (
      <div key={`${field}-${index}`} style={{ marginBottom: '2rem' }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={8}>
            <Field
              name={`${field}.institution`}
              style={{ width: 355 }}
              fullWidth
              component={FormSelect}
              options={[
                { value: 'Select an option', title: 'Select an option' },
                ...mapFinancialInstituteOptions(
                  convertIfImmutable(financialInstituteOptions),
                ),
              ]}
              label={<FormattedMessage {...messages.chooseInstitution} />}
              color="primary"
              margin="normal"
              variant="standard"
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={`${field}.currency`}
              style={{ width: 155 }}
              fullWidth
              component={FormSelect}
              options={[
                { value: 'Select an option', title: 'Select an option' },
                ...filteredCardCurrency,
              ]}
              label={<FormattedMessage {...globalMessages.currency} />}
              color="primary"
              margin="normal"
              variant="standard"
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={`${field}.branchName`}
              style={{ width: 260 }}
              fullWidth
              component={FormTextField}
              label={<FormattedMessage {...messages.branchName} />}
              color="primary"
              margin="normal"
              variant="standard"
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              name={`${field}.postOfficeBoxLine`}
              style={{ width: 260 }}
              fullWidth
              component={FormTextField}
              label={<FormattedMessage {...messages.poBox} />}
              color="primary"
              margin="normal"
              variant="standard"
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            />
          </Grid>
          <Grid item xs={8}>
            <Field
              name={`${field}.reasonForAssignment`}
              fullWidth
              component={FormTextField}
              style={{ width: 355 }}
              // options={reasonOptions}
              multiline
              label={<FormattedMessage {...messages.reasonForReassignment} />}
              color="primary"
              margin="normal"
              variant="standard"
              placeholder={intl.formatMessage(globalMessages.pleaseEnterValue)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ marginTop: 16 }}
        >
          <Grid item xs={8}>
            <Field
              name={`${field}.email`}
              style={{ width: 355 }}
              fullWidth
              component={FormTextField}
              label={<FormattedMessage {...globalMessages.emailIfApplicable} />}
              color="primary"
              margin="normal"
              variant="standard"
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={2}
          style={{ marginTop: 16 }}
        >
          <Grid item xs={4}>
            <Field
              name={`${field}.amount`}
              style={{ width: 150 }}
              fullWidth
              component={FormNumberField}
              label={<FormattedMessage {...globalMessages.amount} />}
              color="primary"
              margin="normal"
              variant="standard"
              placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
              // disabled={true}
            />
          </Grid>
          {fields.length <= 2 && fields.length === index + 1 && (
            <Grid item xs={3}>
              <Button onClick={addAssignee} style={{ color: blue[800] }}>
                {addLabel}
                {/* <FormattedMessage {...globalMessages.addConditionalAssignee} /> */}
              </Button>
            </Grid>
          )}
          {fields.length === 1 ? (
            ''
          ) : (
            <Grid item xs={2}>
              <Button
                onClick={() => {
                  fields.remove(index);
                }}
                style={{ color: blue[800] }}
              >
                <FormattedMessage {...messages.delete} />
                {/* <FormattedMessage {...globalMessages.addConditionalAssignee} /> */}
              </Button>
              {/* <IconButton
                onClick={() => {
                  fields.remove(index);
                }}
              >
                <DeleteIcon />
              </IconButton> */}
            </Grid>
          )}
        </Grid>
        {fields.length > 1 && fields.length !== index + 1 && (
          <Divider style={{ marginTop: 28 }} />
        )}
      </div>
    );
  });
}

BeneficiaryDetails.propTypes = {
  options: PropTypes.object,
  formValues: PropTypes.object,
  error: PropTypes.bool,
};

export default BeneficiaryDetails;

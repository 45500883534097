import globalMessages from 'containers/App/messages';
import {
  getMaxValue, getMinValue
} from 'containers/ItaIllustrationPage/helper';
import { isEmpty } from 'lodash/lang';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isFieldEmpty } from 'utils/form';

const validate = (values, props) => {
  const errors = {};
  const itaConfig = props.itaConfig;
  //const lifeAssured = props.lifeAssured;

  if (!values.isEmpty()) {
    const offerCodeValues = values.get('offerCode');
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const termValues = values.get('term');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const coveragesValues = values.get('coverages');

    if (!startDateValues || !moment(startDateValues).isValid()) {
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    }

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );

    if (!termValues || termValues === 'Select')
      errors.term = <FormattedMessage {...globalMessages.pleaseEnterValue} />;
    else {
      if (itaConfig.features.policyTerm.minTerm > termValues) {
        errors.term = (
          <FormattedMessage
            {...globalMessages.minValueLimit}
            values={{ value: itaConfig.features.policyTerm.minTerm }}
          />
        );
      }

      if (itaConfig.features.policyTerm.maxTerm < termValues) {
        errors.term = (
          <FormattedMessage
            {...globalMessages.maxValueLimit}
            values={{ value: itaConfig.features.policyTerm.maxTerm }}
          />
        );
      }
    }

    if (!paymentFrequencyValues || paymentFrequencyValues === 'Select')
      errors.paymentFrequency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );

    if (coveragesValues && coveragesValues.size > 0) {
      const coveragesError = [];
      var lfcInsuredOne = 0;
      coveragesValues.map(coverage => {
        const requiredCoverageFields = ['valueInsuredOne'];
        const coverageError = {};

        if (coverage.get('id') === 'LFC') {
          lfcInsuredOne = coverage.get('valueInsuredOne');
        }

        requiredCoverageFields.forEach(field => {
          if (coverage.get('selected')) {
            if (isFieldEmpty(coverage.get(field))) {
              coverageError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            } else if (coverage.get('type') === '1') {
              const minVal = getMinValue(
                coverage.get('id'),
                itaConfig.features,
                currencyValues,
              );
              const maxVal = getMaxValue(
                coverage.get('id'),
                itaConfig.features,
                currencyValues,
              );

              if (minVal > coverage.get(field)) {
                coverageError[field] = (
                  <FormattedMessage
                    {...globalMessages.minAmountLimit}
                    values={{ value: minVal }}
                  />
                );
              }

              if (maxVal < coverage.get(field)) {
                coverageError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxAmountLimit}
                    values={{ value: maxVal }}
                  />
                );
              }

              if (
                numeral(coverage.get('valueInsuredOne')).value() >
                numeral(lfcInsuredOne).value()
              ) {
                coverageError[field] = (
                  <FormattedMessage {...globalMessages.accelerateValidation} />
                );
              }
            }
          }
        });
        coveragesError.push(coverageError);
      });

      if (!isEmpty(coveragesError)) errors.coverages = coveragesError;
    }

    if (!isEmpty(offerCodeValues)) {
      const coverages = coveragesValues.toJS();
      const lifeCover = coverages.find(item => item.id === 'LFC');
      if (lifeCover?.valueInsuredOne === '') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectLifeCover} />
        );
      }
      if (currencyValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectCurrency} />
        );
      }
      if (termValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectPolicyTerm} />
        );
      }
    }
  }
  return errors;
};

export default validate;

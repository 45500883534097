import moment from 'moment';
import { dobFormat } from 'helpers/constants';

export function calculateAgeNextBirthDate(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  var monthDayPoint = 0;
  if (policyStartDateMonth == bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}

export const MIN_DATE_QUOTE_VALID = '2021-01-03';

/*
 *
 * IdVerification actions
 *
 */

import {
  DEFAULT_ACTION,
  START_VERIFICATION_ACTION,
  SET_SHUFTI_PRO_ACTION,
  RESET_STATE_ACTION,
} from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function startVerificationAction(sessionId) {
  return {
    type: START_VERIFICATION_ACTION,
    payload: sessionId,
  };
}

export function setShuftiProAction({
  verificationUrl = null,
  reference = null,
  error = '',
}) {
  return {
    type: SET_SHUFTI_PRO_ACTION,
    payload: {
      verificationUrl,
      reference,
      error,
    },
  };
}

export function resetStateAction() {
  return {
    type: RESET_STATE_ACTION,
  };
}

/**
 * The global state selectors
 */

import { createSelector } from 'reselect';

const selectGlobal = state => state.get('global');

const selectRouter = state => state.get('router');

const makeSelectLocation = () =>
  createSelector(selectRouter, routerState =>
    routerState.get('location').toJS(),
  );

const makeSelectLoading = () =>
  createSelector(selectGlobal, globalState => globalState.get('loading'));

const makeSelectCsrf = () =>
  createSelector(selectGlobal, globalState => globalState.get('csrf'));

const makeSelectLoadingMessage = () =>
  createSelector(selectGlobal, globalState =>
    globalState.get('loadingMessage').toJS(),
  );

const makeSelectAuth = () =>
  createSelector(selectGlobal, globalState => globalState.get('auth').toJS());

const makeSelectOption = () =>
  createSelector(selectGlobal, globalState => globalState.get('options'));

const makeSelectError = () =>
  createSelector(selectGlobal, globalState => globalState.get('error').toJS());

const makeSelectSelectedAgency = () =>
  createSelector(selectGlobal, globalState =>
    globalState.get('selectedAgency'),
  );

const makeSelectAgentDetails = () =>
  createSelector(selectGlobal, globalState =>
    globalState.get('agentDetails').toJS(),
  );

const makeSelectPersistance = () =>
  createSelector(selectGlobal, globalState =>
    globalState.get('persistance').toJS(),
  );

const makeSelectFile = () =>
  createSelector(selectGlobal, globalState => globalState.get('file').toJS());

const makeSelectFinancialInstituteOptions = () =>
  createSelector(selectGlobal, globalState =>
    globalState.get('financialInstituteOptions'),
  );

const makeSelectOfferCodeData = () =>
  createSelector(selectGlobal, globalState =>
    globalState.get('offerCodeData').toJS(),
  );

const makeSelectErrorOfferCodeData = () =>
  createSelector(selectGlobal, globalState =>
    globalState.getIn(['offerCodeData', 'errorMessage']),
  );

const makeSelectQuoteType = () =>
  createSelector(selectGlobal, globalState => globalState.get('quoteType'));

export {
  selectGlobal,
  makeSelectLocation,
  makeSelectLoading,
  makeSelectLoadingMessage,
  makeSelectAuth,
  makeSelectError,
  makeSelectOption,
  makeSelectSelectedAgency,
  makeSelectAgentDetails,
  makeSelectPersistance,
  makeSelectFile,
  makeSelectFinancialInstituteOptions,
  makeSelectCsrf,
  makeSelectOfferCodeData,
  makeSelectErrorOfferCodeData,
  makeSelectQuoteType,
};

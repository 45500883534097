/*
 *
 * FuturaIllustrationPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CHANGE_LIFE_ASSURED_ACTION,
  CHANGE_COVERAGE_ACTION,
  SET_QUOTE_ACTION,
  SET_CALCULATION_ACTION,
  SET_ERROR_ACTION,
  SET_COVERAGES_LIST_ACTION,
  SET_QUOTE_DETAILS_ACTION,
  RESET_ACTION,
  SET_APPLICATION_ID_ACTION,
  SET_FUTURA_CONFIG_ACTION,
  SET_PREMIUM_TERM_ACTION,
  SET_PTV_VERSION_ACTION,
  SET_FIA_ACTION,
  SET_MINPREMIUM_ACTION,
  SET_CLONE_STATUS_ACTION,
  SET_BI_PDF_ACTION,
  SET_EMAIL_SENT_ACTION,
  SET_EMAIL_ERROR_MESSAGE_ACTION,
  SET_COMPLETED_DIALOG,
  SET_IS_CALCULATE_QUOTE_PAGE_ACTION,
  SET_PRODUCT_CODE_ACTION,
} from './constants';
import { getStartDate } from './helper';

const defaultState = {
  emailErrorMessage: '',
  emailSent: false,
  quoteId: '',
  applicationId: '',
  referenceNumber: '',
  benefitIllustrationPdf: '',
  status: '',
  errorData: {
    error: false,
    message: [],
  },
  lifeAssured: {
    insuredOne: {
      prospectId: '',
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      isSmoke: null,
      isLifeInsured: true,
      isPrimaryLifeInsured: true,
      isPolicyOwner: true,
      relationshipToLifeInsured: '',
    },
    isInsuredTwo: false,
    lifeBasis: '',
    insuredTwo: {
      prospectId: '',
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      isSmoke: null,
      isLifeInsured: true,
      isPolicyOwner: false,
      isPrimaryLifeInsured: false,
      relationshipToLifeInsured: '',
    },
    isPolicyOwnerIndividual: false,
    policyOwnerIndividual: [
      {
        prospectId: '',
        firstName: '',
        lastName: '',
        relationshipToLifeInsured: '',
        isPolicyOwner: true,
        isLifeInsured: false,
        isPrimaryLifeInsured: false,
      },
    ],
  },
  productConfig: {
    name: 'Futura',
    productCode: 'FUTU5',
    ptvVersion: '',
    startDate: getStartDate().format('YYYY-MM-DD'),
    currency: 'Select',
    term: 'Select',
    singlePremiumTerm: 0,
    fiaCharge: 0,
    specialTermsFlag: false,
    policyCoverTerm: 'Select',
    paymentFrequency: 'Select',
    haveAdditionalSinglePremium: false,
    singlePremium: '',
    growthRate: '',
    offerCode: '',
    minCalculatedPremium: 0,
    premium: 0,
    annualizedPremium: 0,
    coverages: [],
  },
  futuraConfig: {},
  fiaData: {},
  initialLifeAssured: {},
  successClone: false,
  completedDialog: {
    open: false,
    message: null,
  },
  isCalculateQuotePage: false,
};

export const initialState = fromJS({ ...defaultState });

function futuraIllustrationPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_ACTION:
      return state.set('errorData', action.payload);
    case RESET_ACTION:
      return fromJS({ ...defaultState });
    case SET_QUOTE_ACTION:
      return state.set('quoteId', action.payload);
    case SET_APPLICATION_ID_ACTION:
      return state.set('applicationId', action.payload);
    case SET_FUTURA_CONFIG_ACTION:
      return state.set('futuraConfig', fromJS(action.payload));
    case CHANGE_LIFE_ASSURED_ACTION:
      return state
        .set('lifeAssured', fromJS(action.payload))
        .set('initialLifeAssured', fromJS(action.payload));
    case CHANGE_COVERAGE_ACTION:
      return state.set('productConfig', fromJS(action.payload.data));
    case SET_QUOTE_DETAILS_ACTION:
      return (
        state
          .set('referenceNumber', action.payload.referenceNumber)
          //.set('benefitIllustrationPdf', action.payload.benefitIllustrationPdf)
          .set('status', action.payload.status)
      );
    case SET_COVERAGES_LIST_ACTION:
      return state.setIn(['productConfig', 'coverages'], action.payload);
    case SET_CALCULATION_ACTION:
      return state
        .setIn(['productConfig', 'premium'],action.payload.premium)
        .setIn(['productConfig', 'annualizedPremium'],action.payload.annualizedPremium);
    case SET_MINPREMIUM_ACTION:
      return state.setIn(
        ['productConfig', 'minCalculatedPremium'],
        Math.ceil(action.payload.response.premium),
      );
    case SET_PREMIUM_TERM_ACTION:
      return state.setIn(['productConfig', 'policyCoverTerm'], action.payload);
    case SET_PTV_VERSION_ACTION:
      return state.setIn(['productConfig', 'ptvVersion'], action.payload);
    case SET_PRODUCT_CODE_ACTION:
      return state.setIn(['productConfig', 'productCode'], action.payload);
    case SET_FIA_ACTION:
      return state.set('fiaData', fromJS(action.payload));
    case SET_CLONE_STATUS_ACTION:
      return state.set('successClone', action.payload);
    case SET_BI_PDF_ACTION:
      return state.set('benefitIllustrationPdf', action.payload);
    case SET_EMAIL_SENT_ACTION:
      return state.set('emailSent', action.payload);
    case SET_EMAIL_ERROR_MESSAGE_ACTION:
      return state.set('emailErrorMessage', action.payload);
    case SET_COMPLETED_DIALOG:
      return state.set('completedDialog', action.payload);
    case SET_IS_CALCULATE_QUOTE_PAGE_ACTION:
      return state.set('isCalculateQuotePage', action.payload);
    default:
      return state;
  }
}

export default futuraIllustrationPageReducer;

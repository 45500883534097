/**
 * CoverageForm
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import numeral from 'numeral';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import {
  WrappedFieldArrayProps,
  InjectedFormProps,
  FormSection,
} from 'redux-form';
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  Collapse,
  TextField,
  InputAdornment,
  CircularProgress,
  Button,
} from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import EducationWithdraw from 'components/EducationWithdraw';
// import FormOptionButtons from 'components/FormOptionButtons';
import { dimension } from 'styles/constants';
// import FormCheck from 'components/FormCheck';
import FormNumberField from 'components/FormNumberField';
import TargetFund from 'containers/App/components/TargetFund';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import moment from 'moment';
import CustomFormCheck from 'components/CustomFormCheck';
// import FormAutocomplete from 'components/FormAutocomplete';

import CardSectionForm from 'components/CardSectionForm';
import OptSwitch from 'components/OptSwitch';
import { isEmpty } from 'lodash';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import FormSwitch from 'components/FormSwitch';
import Helper from 'components/Helper';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import Withdrawal from '../Withdrawal';
import asyncValidator from './asyncValidate';
import {
  getStartDate,
  calculateAgeNextBirthdate,
  getTooltipsMsg,
} from '../../helper';
import validate from './validate';
import messages from '../../messages';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import {setErrorAction} from '../../actions';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  hrSlider: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    margin: `${theme.spacing(3)}px ${theme.spacing(-2)}px ${theme.spacing(
      5,
    )}px ${theme.spacing(-2)}px`,
  },
  inputWidth: {
    maxWidth: '135px',
    width: '135px',
  },
  labelRadio: {
    '&> span': {
      fontWeight: 'bold',
    },
  },
}));

const renderBenefit = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
  paymentFrequency,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne.dateOfBirth,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo.dateOfBirth,
      );

      let ageValidType = 0;
      let isDisabledESC = false;

      let isFormCheckDisabled = myBenefit[index].disabled;
      const isESC = myBenefit[index].id === 'ESC';

      const disabledWOP =
        paymentFrequency === 'Single' && myBenefit[index].id === 'WOP';
      let isWopOneDisabled = myBenefit[index].disabled || disabledWOP;
      let isWopTwoDisabled = myBenefit[index].disabled || disabledWOP;

      // mapping value to ESC dropdown
      const premiumIndexationOptions = [];
      if (
        myBenefit[index].id === 'ESC' &&
        myBenefit[index].possibleValues.length > 0
      ) {
        const items = myBenefit[index].possibleValues.length;
        for (let i = 0; i < items; i += 1) {
          const parsedNumber = numeral(
            myBenefit[index].possibleValues[i],
          ).value();
          premiumIndexationOptions.push({
            title: `${parsedNumber} %`,
            value: `${parsedNumber}`,
          });
        }
      }

      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].id === 'WOP') {
            isWopTwoDisabled = true;
            change(`${benefit}.valueInsuredTwo`, false);
        }
        if (myBenefit[index].maxEntryAgeLA !== '0') {
          if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
            isWopOneDisabled = true;
            isDisabledESC = true;
            change(`${benefit}.value`, isESC ? '0' : false);
          }

          if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
            isDisabledESC = true;
            isWopTwoDisabled = true;
            change(`${benefit}.valueInsuredTwo`, isESC ? '0' : false);
          }
        }
      } else if (myBenefit[index].maxEntryAgeLA !== '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          change(`${benefit}.value`, isESC ? '0' : false); //  always defaulted to 0% when the age is greater than 65anb...
          change(`${benefit}.valueInsuredTwo`, false);

          isDisabledESC = true;
          isWopOneDisabled = true;
          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }

      return (
        <>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            item
            xs={11}
          >
            <Grid item xs={12} sm={6}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.selected`}
                  fullWidth
                  component={CustomFormCheck}
                  label={myBenefit[index].title}
                  disabled={isFormCheckDisabled}
                  validType={ageValidType}
                  type="text"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`${benefit}.value`, false);
                    }
                  }}
                />
              ) : (
                <Grid container direction="row">
                  <Typography variant="h4" style={{ fontWeight: '600' }}>
                    {myBenefit[index].title}
                  </Typography>
                  <div>
                    <Helper
                      helperText={getTooltipsMsg(myBenefit[index].id, intl)}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            {/* BENEFITS */}
            <Grid item xs={6} sm={3}>
              {myBenefit[index].id === 'ESC' ? (
                <Field
                  name={`${benefit}.value`}
                  style={{
                    color: changePlaceHolderColor(myBenefit[index].value),
                  }}
                  fullWidth
                  component={FormSelect}
                  type="text"
                  color="primary"
                  variant="standard"
                  options={[
                    { value: 'Select', title: 'Select' },
                    ...premiumIndexationOptions,
                  ]}
                  disabled={paymentFrequency === 'Single' || isDisabledESC}
                />
              ) : (
                <Field
                  // shrink
                  name={`${benefit}.value`}
                  component={FormBooleanOption}
                  type="text"
                  margin="normal"
                  disabled={isWopOneDisabled}
                  fullWidth
                />
              )}
            </Grid>

            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                {myBenefit[index].type === '2' &&
                  myBenefit[index].id !== 'ESC' && (
                    <Field
                      name={`${benefit}.valueInsuredTwo`}
                      component={FormBooleanOption}
                      type="text"
                      margin="normal"
                      disabled={isWopTwoDisabled}
                      fullWidth
                    />
                  )}
              </Grid>
            ) : null}
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  isBHAndQatar,
  gcConfig,
  change,
  offerCodeData,
  term,
  startDate,
  fiaData,
  fiaCharge,
  regularWithdrawal,
  isEducationalFeeWithdrawal,
  isRegularWithdrawal,
  isAdditionalFeature,
  currency,
  paymentFrequency,
  location,
  haveAdditionalSinglePremium,
  offerCode,
  resetOfferCode,
  validateOfferCode,
  premium,
  formValues,
  formErrors,
  submitFailed,
  escValue,
  setError
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const currencyOption = [];
  const paymentFrequencyOption = [];
  const policyTermList = [];
  const startDateList = [];
  const [premiumDisabled, setPremiumDisabled] = useState(
    formValues.targetFunctionality,
  );

  if (startDateList.length === 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  const minStartDate = moment(startDate)
    .date(15)
    .add(5, 'years')
    .toDate();

  const maxStartDate = moment(startDate)
    .add(100, 'years')
    .toDate();

  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '') {
      validateOfferCode({
        offerCode,
        productCode: gcConfig.productCode,
        productVersion: gcConfig.productVersion,
        currency,
        premium,
        //premiumType: 'term',
        premiumType: term
      });
    }
  }, []);

  useEffect(() => {
    if (!!escValue) {
      resetOfferCode();
      change('offerCode', '');
    }
  }, [escValue]);

  const anbInsuredOne = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
      ? lifeAssured.insuredOne.dateOfBirth
      : startDate,
  );
  const anbInsuredTwo = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredTwo && lifeAssured.insuredTwo.dateOfBirth
      ? lifeAssured.insuredTwo.dateOfBirth
      : startDate,
  );

  let minPremiumValue = gcConfig.features?.policyTerm.minTerm;
  let maxPremiumValue = gcConfig.features?.policyTerm.maxTerm;
  const expiryAgeValue = gcConfig.features?.policyTerm.expiryAge;

  const considerAge =
    anbInsuredOne < anbInsuredTwo ? anbInsuredTwo : anbInsuredOne;
  const calculateMaxTerm = expiryAgeValue - considerAge;
  maxPremiumValue =
    calculateMaxTerm > maxPremiumValue ? maxPremiumValue : calculateMaxTerm;

  while (minPremiumValue <= maxPremiumValue) {
    const myObj = { title: `${minPremiumValue}`, value: minPremiumValue };
    policyTermList.push(myObj);
    minPremiumValue++;
  }
  // policyTermList.push({ title: 'Single premium', value: 'SinglePremium' });

  gcConfig.features.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  gcConfig.features.premiumPaymentFrequencyType[0].periods.map(frequency => {
    paymentFrequencyOption.push({ value: frequency, title: frequency });
  });

  const filteredPaymentFrequency = paymentFrequencyOption.filter(
    item => item.value !== 'Single', // Remove Single for EducationFee and Regular Withdrawal
  );

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';

  useEffect(() => {
    setPremiumDisabled(formValues.targetFunctionality);
  }, [formValues.targetFunctionality]);
  
  //When the special offer is changed reset the error shown on Top which is part of parent index.js
  const onOfferCodeChange = ()=>{
    setError({ error: false, message: [] });
  };
  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* CURRRENCY */}
            <Grid item>
              <Field
                // shrink
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                margin="normal"
                variant="standard"
              />
            </Grid>
            {/* POLICY TERM */}
            <Grid item>
              <Field
                // shrink
                name="term"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(term),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.policyTerm2} />}
                type="text"
                // options={policyTermList}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...policyTermList,
                ]}
                margin="normal"
                variant="standard"
              />
            </Grid>
            {/* PAYMENT FREQUENCY */}
            <Grid item>
              <Field
                // shrink
                name="paymentFrequency"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(paymentFrequency),
                }}
                component={FormSelect}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...paymentFrequencyOption,
                ]}
                onChange={(e, newVal) => {
                  if (newVal === 'SinglePremium') {
                    change('haveAdditionalSinglePremium', false);
                  }
                }}
                label={<FormattedMessage {...messages.paymentFrequency} />}
                type="text"
                disabled={term === 'SinglePremium'}
                margin="normal"
                variant="standard"
              />
            </Grid>

            {/* PREMIUM AMOUNT */}
            <Grid item sm={12}>
              <Field
                // shrink
                name="premium"
                style={{ width: 164 }}
                component={FormNumberField}
                disabled={premiumDisabled}
                label={<FormattedMessage {...messages.premiumAmount} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.enterAmount)}
              />
            </Grid>
          </Grid>

          <Divider
            className={classes.hr}
            style={{ marginBottom: theme.spacing(2) }}
          />
          <Grid container alignItems="center" item spacing={2}>
            <TargetFund
              formValues={formValues}
              formErrors={formErrors}
              submitFailed={submitFailed}
              premiumDisabled={premiumDisabled}
              change={change}
            />
          </Grid>

          <Divider className={classes.hr} />
          {gcConfig.productCode === 'VISP2' && (
            <>
              {/* ===== START ASP ===== */}
              <Grid container alignItems="center" item spacing={2}>
                {/* Additional single premium */}
                <Grid item xs={6} md={6}>
                  <Field
                    fullWidth
                    name="haveAdditionalSinglePremium"
                    component={CustomFormCheck}
                    label={
                      <FormattedMessage {...messages.additionalSinglePremium} />
                    }
                    disabled={paymentFrequency === 'Single'}
                    onChange={(e, newVal) => {
                      change('singlePremium', 0);
                    }}
                    type="text"
                    color="primary"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <Field
                    name="singlePremium"
                    fullWidth
                    component={FormNumberField}
                    disabled={!haveAdditionalSinglePremium}
                    type="text"
                    color="primary"
                    variant="standard"
                    placeholder={intl.formatMessage(messages.enterAmount)}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.hr} />
            </>
          )}
          {/* ===== END ASP ===== */}
          <Grid container spacing={3} item xs={10} md={12}>
            {/* Assumed growth rate */}
            <Grid item xs={4} md={4}>
              <Field
                // shrink
                name="growthRate"
                fullWidth
                component={FormNumberField}
                label={<FormattedMessage {...messages.assumedGrowthRate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.enterAmount)}
              />
            </Grid>
          </Grid>

          <Divider className={classes.hr} />
          {/* BENEFITS */}
          <Grid
            container
            direction="row"
            spacing={1}
            justify="space-between"
            alignItems="center"
            item
            style={{ marginBottom: theme.spacing(1), paddingRight: '10%' }}
            wrap="nowrap"
            xs={11}
          >
            <Grid item xs={12} md={6} sm={6} />
            <Grid item xs={6} md={3} sm={3}>
              <Typography
                color="primary"
                variant="h3"
                style={{ marginLeft: theme.spacing(0.5) }}
              >
                {lifeAssured.insuredOne.firstName}
              </Typography>
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredTwo.firstName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <FieldArray
            name="coverages"
            component={renderBenefit}
            rerenderOnEveryChange
            lifeAssured={lifeAssured}
            options={options}
            change={change}
            startDate={startDate}
            intl={intl}
            paymentFrequency={paymentFrequency}
          />

          <Divider className={classes.hr} />

          <Grid item xs={6} style={{ marginBottom: '5rem' }}>
            <Field
              name="offerCode"
              fullWidth
              disabled={!!escValue}
              style={{
                width: 355,
              }}
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              placeholder={intl.formatMessage(messages.pleaseEnter)}
              onChange = {onOfferCodeChange}
            />
          </Grid>

          {/* ===== Regular withdrawal ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages.regularWithdrawalTitle} />
              </Typography>

              <Field
                name="isRegularWithdrawal"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>
            <Collapse in={isRegularWithdrawal}>
              <Divider className={classes.hrSlider} />
              <FormSection name="regularWithdrawal">
                <Withdrawal
                  formValues={regularWithdrawal}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  isEducationWithdrawal={false}
                  frequencyList={filteredPaymentFrequency}
                  change={change}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          {/* ===== Education Fee withdrawal ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages.eduWithdrawalTitle} />
              </Typography>

              <Field
                name="isEducationalFeeWithdrawal"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isEducationalFeeWithdrawal}>
              <Divider className={classes.hrSlider} />
              <FieldArray
                name="educationFeesWithdrawalArray"
                component={EducationWithdraw}
                isBHAndQatar={isBHAndQatar}
                frequencyList={filteredPaymentFrequency}
                change={change}
                rerenderOnEveryChange
              />
            </Collapse>
          </CardSectionForm>

          {/* ===== FIA Charges ===== */}
          {!isEmpty(fiaData) && gcConfig.features.haveFiaCharge ? (
            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body1">
                  <FormattedMessage {...globalMessages.additionalFetures} />
                </Typography>

                <Field
                  name="isAdditionalFeature"
                  component={FormSwitch}
                  disabled={false}
                />
                {/* <OptSwitch
                  checked={isAdditionalFeature}
                  color="default"
                  onChange={() => setAdditionalFeature(!isAdditionalFeature)}
                  labelNegative={<FormattedMessage {...globalMessages.no} />}
                /> */}
              </Grid>

              <Collapse in={isAdditionalFeature}>
                <Divider className={classes.hrSlider} />
                <Typography
                  // variant="h4"
                  style={{
                    marginBottom: theme.spacing(2.5),
                  }}
                >
                  <FormattedMessage {...globalMessages.fiaText} />
                </Typography>

                <Field name="fiaCharge" type="radio" component={FormRadioGroup}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<CustomRadio checked={fiaCharge == 0} />}
                        value={fiaData?.minFIACharge}
                        label={<FormattedMessage {...globalMessages.nil} />}
                        labelPlacement="end"
                        classes={{ root: classes.labelRadio }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justify="space-between">
                        <FormControlLabel
                          control={<CustomRadio checked={fiaCharge != 0} />}
                          value={fiaData?.maxFIAChange}
                          label={
                            <FormattedMessage
                              {...globalMessages.annualPercentage}
                            />
                          }
                          labelPlacement="end"
                          classes={{ root: classes.labelRadio }}
                        />

                        <TextField
                          className={classes.inputWidth}
                          value={`${fiaData?.maxFIAChange} %`}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Field>
              </Collapse>
            </CardSectionForm>
          ) : null}
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  gcConfig: PropTypes.object,
  regularWithdrawal: PropTypes.object,
  educationFeesWithdrawal: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  offerCodeData: PropTypes.object,
  startDate: PropTypes.string,
  fiaCharge: PropTypes.string,
  term: PropTypes.number,
  coverages: PropTypes.array,
  fiaData: PropTypes.object,
  isEducationalFeeWithdrawal: PropTypes.bool,
  isRegularWithdrawal: PropTypes.bool,
};

const selector = formValueSelector('coverageForm');

const mapStateToProps = createStructuredSelector({
  offerCodeData: makeSelectOfferCodeData(),
});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
    setError: value => dispatch(setErrorAction(value))
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withFormSelector = connect(state => {
  const formValues = getFormValues('coverageForm')(state);
  const formErrors = getFormSyncErrors('coverageForm')(state);
  const startDate = selector(state, 'startDate');
  const currency = selector(state, 'currency');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const fiaCharge = selector(state, 'fiaCharge');
  const regularWithdrawal = selector(state, 'regularWithdrawal');
  const term = selector(state, 'term');
  const educationFeesWithdrawal = selector(state, 'educationFeesWithdrawal');
  const isEducationalFeeWithdrawal = selector(
    state,
    'isEducationalFeeWithdrawal',
  );
  const isAdditionalFeature = selector(state, 'isAdditionalFeature');
  const isRegularWithdrawal = selector(state, 'isRegularWithdrawal');
  const haveAdditionalSinglePremium = selector(
    state,
    'haveAdditionalSinglePremium',
  );
  const offerCode = selector(state, 'offerCode');
  const premium = selector(state, 'premium');
  const coverages = convertIfImmutable(selector(state, 'coverages'));

  return {
    startDate,
    fiaCharge,
    term,
    regularWithdrawal: regularWithdrawal?.toJS() || {},
    educationFeesWithdrawal,
    isAdditionalFeature,
    isEducationalFeeWithdrawal,
    isRegularWithdrawal,
    currency,
    paymentFrequency,
    haveAdditionalSinglePremium,
    offerCode,
    premium,
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
    escValue: Number(coverages.find(cov => cov.id === 'ESC')?.value),
  };
});

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: ['offerCode', 'currency', 'premium', 'term'],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

import { takeLatest, call, all, select } from 'redux-saga/effects';
import { api } from 'environments';
import request from 'utils/request';
import { makeSelectAuth } from 'containers/App/selectors';
import { LOGOUT_ACTION } from './constants';

// Individual exports for testing

export function* logout(action) {
  const auth = yield select(makeSelectAuth());

  const endpoint = `${api.host}/api/v1/b2b/auth/logout`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data === 'OK') {
      sessionStorage.removeItem('oscarAuth');
      sessionStorage.removeItem('zioPinData');
      action.payload.signOut({
        postLogoutRedirectUri: `${window.location.origin}/login`,
      });
    }
  } catch (err) {}
}

export default function* dashboardPageSaga() {
  // See example in containers/HomePage/saga.js
  yield all([takeLatest(LOGOUT_ACTION, logout)]);
}

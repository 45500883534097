import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { reduxForm, InjectedFormProps } from 'redux-form/immutable';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages';
import { convertIfImmutable } from 'helpers/lang';
import { formatMonetaryValue } from 'helpers/math';
import { isEmpty } from 'lodash/lang';
import { get } from 'lodash/object';
import { map, forEach } from 'lodash/collection';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Link, Button, TextField } from '@material-ui/core';

import queryString from 'query-string';
import globalMessages from 'containers/App/messages';
// components
import NoticeBox from 'components/NoticeBox';
import InfoRow from 'components/InfoRow';
import Dialog from 'components/Dialog';

// icons
import DescriptionIcon from '@material-ui/icons/Description';
import InfoIcon from '@material-ui/icons/Info';
import CustomDialog from 'components/Dialog';
// selectors
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';
import {
  makeSelectUWStatus,
  makeSelectSummaryLink,
  makeSelectUWLink,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
} from 'containers/ApplicationFormPage/selectors';
import { makeSelectFile } from 'containers/App/selectors';

// actions
import {
  sendEmailPDFAction,
  setEmailSentAction,
} from 'containers/ApplicationFormPage/actions';
import { createFileAction } from 'containers/App/actions';
import SpecialTerms2 from './SpecialTerms2';
import SpecialTerms from './SpecialTerms';
import PremiumSummary from './PremiumSummary';
import { productCodeCheck } from 'helpers/constants';

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: '660px',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  link: {
    color: '#00C3B4',
  },
  disabled: {
    color: '#B6B8B4',
  },
}));

function Decision(props) {
  const classes = useStyles();
  const intl = useIntl();
  const {
    applicationDetails,
    match,
    handleSubmit,
    onSubmit,
    sendEmailPDF,
    canGoBack,
    canSubmit,
    createFile,
    file,
    UWLink,
    lifeInsured,
    setEmailSent,
    emailSent,
    emailErrorMessage,
  } = props;
  const [dialogPS, setDialogPS] = useState(false);
  const [PSEmail, setPSEmail] = useState('');
  const [PSisEmail, setPSisEMail] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [dialogUW, setDialogUW] = useState(false);
  const [UWEmail, setUWEmail] = useState('');
  const [UWisEmail, setUWisEMail] = useState(false);
  const [auraSession, setAuraSession] = useState(false);
  const parsedApplicationDetails = convertIfImmutable(applicationDetails);
  const { applicationId } = match.params;
  const [isWap, setIsWap] = useState(false)

  useEffect(() => {
    canGoBack(true);
    canSubmit(false);
  }, []);

  useEffect(() => {
    if (emailSent) {
      setErrorMail(true);
    }
  }, [emailSent]);

  useEffect(
    () => () => {
      setEmailSent(false);
    },
    [],
  );
  useEffect(() => {
    if (parsedApplicationDetails?.productCode === 'WAPL2' || parsedApplicationDetails?.productCode === 'WAPL1' || parsedApplicationDetails?.productCode === 'SIMWE' || parsedApplicationDetails?.productCode === 'SIMW2') {
      setIsWap(true)
    }

  }, [parsedApplicationDetails])

  useEffect(() => {
    if (!isEmpty(parsedApplicationDetails)) {
      const parsedURLApplicationForm = queryString.parseUrl(
        parsedApplicationDetails?.isBolton
          ? get(
            parsedApplicationDetails,
            'productList[1].documents.ApplicationForm.url',
            '',
          )
          : get(parsedApplicationDetails, 'documents.ApplicationForm.url', ''),
      );
      const parsedURLHealthLifeStyle = queryString.parseUrl(
        parsedApplicationDetails?.isBolton
          ? get(
            parsedApplicationDetails,
            'productList[1].documents.HealthLifeStyle.url',
            '',
          )
          : get(parsedApplicationDetails, 'documents.HealthLifeStyle.url', ''),
      );
      // for POLICY SUMMARY
      createFile({
        applicationId,
        type: 'POLICY_SUMMARY',
        name: parsedURLApplicationForm.query.name,
        isDecision: false,
        isSummary: true,
      });
      // for UW
      createFile({
        applicationId,
        type: 'FILE',
        name: parsedURLHealthLifeStyle.query.name,
        isDecision: true,
      });
    }
    // check if there's any signs of aura sessions
    forEach(lifeInsured, party => {
      if (party.auraSessionId) {
        setAuraSession(true);
      }
    });
  }, [applicationDetails]);

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const getStatus = status => {
    switch (status) {
      case 'ACCEPTED':
        return { statusBox: 'success', text: 'Accepted' };
      case 'ACCEPTED_AT_NON_STANDARD':
        return { statusBox: 'warning', text: 'Accepted At Non Standard Rates' };
      case 'ACCEPTED_WITH_EXCLUSION':
        return { statusBox: 'warning', text: 'Accepted with Exclusions' };
      default:
        return { statusBox: 'error', text: 'Referral - Awaiting Action' };
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.container}>
          <Grid container spacing={0}>
            {auraSession && (
              <Grid item xs={12}>
                <NoticeBox
                  variant={
                    getStatus(parsedApplicationDetails.uwStatus).statusBox
                  }
                  mb={6}
                >
                  <Typography variant="body2" color="inherit">
                    <FormattedMessage
                      {...messages.status}
                      values={{
                        value: getStatus(parsedApplicationDetails.uwStatus)
                          .text,
                      }}
                    />
                  </Typography>
                </NoticeBox>
              </Grid>
            )}
            {parsedApplicationDetails.uwStatus === 'AWAITING_ACTION' ? (
              <Grid item xs={12}>
                <Typography variant="h3">
                  <Link style={{ fontWeight: 800 }}>
                    #{parsedApplicationDetails.referenceNumber}
                  </Link>
                </Typography><br></br>

                <Typography variant="h3" color="primary">
                  <FormattedMessage {...messages.awaitingActionText1} />
                </Typography><br></br>
                <Typography variant="h3" color="primary">
                  <FormattedMessage {...messages.awaitingActionText2} />
                </Typography><br></br>
                <Typography variant="h3" color="primary">
                  <FormattedMessage {...messages.awaitingActionText3} />
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {/* POLICY SUMMARY */}
                  <PremiumSummary
                    sectionName={
                      productCodeCheck(4, parsedApplicationDetails.productCode)
                        ? intl.formatMessage(messages.premiumSummary)
                        : intl.formatMessage(messages.policySummary)
                    }
                    title={intl.formatMessage(messages.premiumAmount)}
                    additionalSinglePremiumLabel={intl.formatMessage(messages.additionalSinglePremium)}
                    currency={get(
                      parsedApplicationDetails,
                      'productConfigs.currency',
                      get(
                        parsedApplicationDetails,
                        'productConfigs[0].currency',
                      ),
                    )}
                    value={formatMonetaryValue(
                      parsedApplicationDetails.premium,
                    )}
                    isWap={isWap}
                    additionalSinglePremiumAmount={formatMonetaryValue(parsedApplicationDetails?.productConfigs?.additionalSinglePremium)}
                    frequency={parsedApplicationDetails.premiumFrequency}
                  />
                  {/* SPECIALTERMS */}
                  {/* {!isInforce && (
                    <> */}
                  {parsedApplicationDetails?.uwStatus === 'ACCEPTED' &&
                    parsedApplicationDetails?.isBolton ? null : ( // don't show special terms for accepted bolton
                    <>
                      {get(
                        parsedApplicationDetails,
                        'productConfigs.specialTermsFlag',
                        get(
                          parsedApplicationDetails,
                          'productConfigs[1].specialTermsFlag', // ITA bolton
                          false,
                        ),
                      ) ? (
                        <>
                          {productCodeCheck(
                            'ITA',
                            parsedApplicationDetails.productCode,
                          ) ||
                            productCodeCheck(
                              'SACI',
                              parsedApplicationDetails.productCode,
                            ) ||
                            productCodeCheck(
                              3,
                              parsedApplicationDetails.productCode,
                            ) ||
                            productCodeCheck(
                              'DTME',
                              parsedApplicationDetails.productCode,
                            ) ? (
                            <>
                              <SpecialTerms
                                // NEW PROPS
                                isBolton={parsedApplicationDetails?.isBolton}
                                coverages={
                                  parsedApplicationDetails?.isBolton
                                    ? get(
                                      parsedApplicationDetails,
                                      'productConfigs[0].coverages',
                                      0,
                                    )
                                    : get(
                                      parsedApplicationDetails,
                                      'productConfigs.coverages',
                                      0,
                                    )
                                }
                                // OLD PROPS
                                sectionName={intl.formatMessage(
                                  messages.SpecialTerms,
                                )}
                                sectionName2={
                                  parsedApplicationDetails?.isBolton
                                    ? get(
                                      parsedApplicationDetails,
                                      'productConfigs[0].name',
                                    )
                                    : null
                                }
                                title={intl.formatMessage(
                                  messages.premiumAmount,
                                )}
                                currency={
                                  parsedApplicationDetails?.isBolton
                                    ? get(
                                      parsedApplicationDetails,
                                      'productConfigs[0].currency',
                                      'USD',
                                    )
                                    : get(
                                      parsedApplicationDetails,
                                      'productConfigs.currency',
                                      'USD',
                                    )
                                }
                                value={formatMonetaryValue(
                                  parsedApplicationDetails.premium,
                                )}
                                frequency={
                                  parsedApplicationDetails.premiumFrequency
                                }
                              />
                              {/* //BOLTON */}
                              {parsedApplicationDetails?.isBolton && (
                                <SpecialTerms
                                  // NEW PROPS
                                  coverages={
                                    parsedApplicationDetails?.isBolton
                                      ? get(
                                        parsedApplicationDetails,
                                        'productConfigs[1].coverages',
                                        0,
                                      )
                                      : get(
                                        parsedApplicationDetails,
                                        'productConfigs.coverages',
                                        0,
                                      )
                                  }
                                  // OLD PROPS
                                  sectionName={intl.formatMessage(
                                    messages.SpecialTerms,
                                  )}
                                  sectionName2={get(
                                    parsedApplicationDetails,
                                    'productConfigs[1].name',
                                  )}
                                  title={intl.formatMessage(
                                    messages.premiumAmount,
                                  )}
                                  currency={
                                    parsedApplicationDetails?.isBolton
                                      ? get(
                                        parsedApplicationDetails,
                                        'productConfigs[0].currency',
                                        'USD',
                                      )
                                      : get(
                                        parsedApplicationDetails,
                                        'productConfigs.currency',
                                        'USD',
                                      )
                                  }
                                  value={formatMonetaryValue(
                                    parsedApplicationDetails.premium,
                                  )}
                                  frequency={
                                    parsedApplicationDetails.premiumFrequency
                                  }
                                />
                              )}
                            </>
                          ) : (
                            <>
                              <SpecialTerms2
                                // NEW PROPS
                                coverages={
                                  parsedApplicationDetails?.isBolton
                                    ? get(
                                      parsedApplicationDetails,
                                      'productConfigs[0].coverages',
                                      0,
                                    )
                                    : get(
                                      parsedApplicationDetails,
                                      'productConfigs.coverages',
                                      0,
                                    )
                                }
                                // OLD PROPS
                                sectionName={intl.formatMessage(
                                  messages.SpecialTerms,
                                )}
                                title={intl.formatMessage(
                                  messages.premiumAmount,
                                )}
                                currency={
                                  parsedApplicationDetails?.isBolton
                                    ? get(
                                      parsedApplicationDetails,
                                      'productConfigs[0].currency',
                                      'USD',
                                    )
                                    : get(
                                      parsedApplicationDetails,
                                      'productConfigs.currency',
                                      'USD',
                                    )
                                }
                                value={formatMonetaryValue(
                                  parsedApplicationDetails.premium,
                                )}
                                frequency={
                                  parsedApplicationDetails.premiumFrequency
                                }
                              />
                              <SpecialTerms2
                                // NEW PROPS
                                coverages={
                                  parsedApplicationDetails?.isBolton
                                    ? get(
                                      parsedApplicationDetails,
                                      'productConfigs[1].coverages',
                                      0,
                                    )
                                    : get(
                                      parsedApplicationDetails,
                                      'productConfigs.coverages',
                                      0,
                                    )
                                }
                                // OLD PROPS
                                sectionName={intl.formatMessage(
                                  messages.SpecialTerms,
                                )}
                                title={intl.formatMessage(
                                  messages.premiumAmount,
                                )}
                                currency={
                                  parsedApplicationDetails?.isBolton
                                    ? get(
                                      parsedApplicationDetails,
                                      'productConfigs[0].currency',
                                      'USD',
                                    )
                                    : get(
                                      parsedApplicationDetails,
                                      'productConfigs.currency',
                                      'USD',
                                    )
                                }
                                value={formatMonetaryValue(
                                  parsedApplicationDetails.premium,
                                )}
                                frequency={
                                  parsedApplicationDetails.premiumFrequency
                                }
                              />
                            </>
                          )}
                        </>
                      ) : null}
                    </>
                  )}

                  <Grid item xs={12}>
                    <InfoRow variant="document">
                      <Grid container>
                        <Grid item xs={1}>
                          <DescriptionIcon />
                        </Grid>
                        <Grid item xs={6}>
                          <Typography variant="body1">
                            <FormattedMessage
                              {...messages.policySummary2}
                              values={{
                                name: get(
                                  parsedApplicationDetails,
                                  'productConfigs.name',
                                  get(
                                    parsedApplicationDetails,
                                    'productConfigs[0].name',
                                    'USD',
                                  ),
                                ),
                              }}
                            />
                            {/* {productCodeCheck(
                              'FUTU',
                              parsedApplicationDetails.productCode,
                            ) && (
                              <FormattedMessage
                                {...messages.futuPolicySummary}
                              />
                            )}

                            {productCodeCheck(
                              'ITA',
                              parsedApplicationDetails.productCode,
                            ) ? (
                              <FormattedMessage
                                {...messages.itaPolicySummary}
                              />
                            ) : (
                              ''
                            )}
                            {productCodeCheck(
                              'DTME',
                              parsedApplicationDetails.productCode,
                            ) ? (
                              <FormattedMessage
                                {...messages.dtaPolicySummary}
                              />
                            ) : (
                              ''
                            )}
                            {productCodeCheck(
                              'GC',
                              parsedApplicationDetails.productCode,
                            ) && (
                              <FormattedMessage
                                {...messages.globalChoicePolicySummary}
                              />
                            )}
                            {productCodeCheck(
                              'WAP',
                              parsedApplicationDetails.productCode,
                            ) && (
                              <FormattedMessage
                                {...messages.wealthAccumulationPlanSummary}
                              />
                            )}

                            {productCodeCheck(
                              'SIM',
                              parsedApplicationDetails.productCode,
                            ) && (
                              <FormattedMessage
                                {...messages.simpleWealthPlanSummary}
                              />
                            )}
                            {productCodeCheck(
                              'RSP',
                              parsedApplicationDetails.productCode,
                            ) && (
                              <FormattedMessage
                                {...messages.regularSavingsPlan}
                              />
                            )} */}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <div className={classes.flexEnd}>
                            <Link
                              href="#"
                              disabled={!file.url}
                              onClick={e => {
                                if (file.url) {
                                  window.open(file.url);
                                }
                              }}
                              // download="summary.zip"
                              color="inherit"
                              variant="body2"
                              underline="none"
                              className={classes.link}
                            >
                              <FormattedMessage {...messages.viewExportPDF} />
                            </Link>
                          </div>
                        </Grid>
                        <Grid item xs={2}>
                          <div className={classes.flexEnd}>
                            <Link
                              href="#"
                              onClick={e => {
                                e.preventDefault();
                                setDialogPS(true);
                              }}
                              color="inherit"
                              variant="body2"
                              underline="none"
                              className={classes.link}
                            >
                              <FormattedMessage {...messages.emailPDF} />
                            </Link>
                          </div>
                        </Grid>
                      </Grid>
                    </InfoRow>
                  </Grid>
                  {auraSession && (
                    <Grid item xs={12}>
                      <InfoRow variant="document">
                        <Grid container>
                          <Grid item xs={1}>
                            <DescriptionIcon />
                          </Grid>
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <FormattedMessage
                                {...messages.underwritingQuestions}
                              />
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <div className={classes.flexEnd}>
                              <Link
                                href="#"
                                color="inherit"
                                variant="body2"
                                underline="none"
                                className={classes.link}
                                onClick={e => {
                                  if (convertIfImmutable(UWLink).previewUrl) {
                                    window.open(
                                      convertIfImmutable(UWLink).previewUrl,
                                    );
                                  }
                                }}
                                disabled={
                                  !convertIfImmutable(UWLink).previewUrl
                                }
                              >
                                <FormattedMessage {...messages.viewExportPDF} />
                              </Link>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className={classes.flexEnd}>
                              <Link
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  setDialogUW(true);
                                }}
                                color="inherit"
                                variant="body2"
                                underline="none"
                                className={classes.link}
                              >
                                <FormattedMessage {...messages.emailPDF} />
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </InfoRow>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        </div>
      </form>

      {/* POLICY SUMMARY */}
      <Dialog
        open={dialogPS}
        onClose={() => {
          setDialogPS(false);
        }}
        // onExited={() =>
        //   setDialog({
        //     ...dialog,
        //     message: null,
        //   })
        // }
        variant="info"
        title="Send"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialogPS(false);
                }}
              >
                Cancel and exit
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={!PSisEmail}
                onClick={() => {
                  sendEmailPDF({
                    type: 'policy-summary',
                    data: {
                      applicationId: parsedApplicationDetails.id,
                      email: PSEmail,
                    },
                  });
                  setDialogPS(false);
                }}
              >
                Send Email
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          Email address:
        </Typography>
        <br />
        <TextField
          fullWidth
          id="outlined-basic"
          variant="outlined"
          helperText={!PSisEmail && 'Please enter a valid email'}
          onChange={e => {
            setPSEmail(e.target.value);

            if (
              re.test(String(e.target.value).toLowerCase()) &&
              !isEmpty(e.target.value)
            ) {
              setPSisEMail(true);
            } else {
              setPSisEMail(false);
            }
          }}
        />
      </Dialog>

      {/* UW PDF */}
      <Dialog
        open={dialogUW}
        onClose={() => {
          setDialogUW(false);
        }}
        // onExited={() =>
        //   setDialog({
        //     ...dialog,
        //     message: null,
        //   })
        // }
        variant="info"
        title="Send"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialogUW(false);
                }}
              >
                Cancel and exit
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={!UWisEmail}
                onClick={() => {
                  sendEmailPDF({
                    type: 'underwriting',
                    data: {
                      applicationId: parsedApplicationDetails.id,
                      email: UWEmail,
                    },
                  });
                  setDialogUW(false);
                }}
              >
                Send Email
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          Email address:
        </Typography>
        <br />
        <TextField
          fullWidth
          id="outlined-basic"
          variant="outlined"
          helperText={!PSisEmail && 'Please enter a valid email'}
          onChange={e => {
            setUWEmail(e.target.value);

            if (
              re.test(String(e.target.value).toLowerCase()) &&
              !isEmpty(e.target.value)
            ) {
              setUWisEMail(true);
            } else {
              setUWisEMail(false);
            }
          }}
        />
      </Dialog>
      <CustomDialog
        open={errorMail}
        onClose={() => {
          setErrorMail(false);
          setEmailSent(false);
        }}
        title={<FormattedMessage {...messages.error} />}
        variant="error"
        actions={
          <Button
            onClick={() => {
              setErrorMail(false);
              return setEmailSent(false);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.close} />
          </Button>
        }
      >
        <Typography variant="h2">
          <FormattedMessage
            {...messages.errorEmail}
            values={{ message: emailErrorMessage }}
          />
        </Typography>
      </CustomDialog>
    </div>
  );
}

Decision.propTypes = {
  applicationDetails: PropTypes.object,
  setEmailSent: PropTypes.func,
  emailSent: PropTypes.bool,
  emailErrorMessage: PropTypes.string,
  ...InjectedFormProps,
};

const mapStateToProps = createStructuredSelector({
  applicationDetails: makeSelectApplicationDetails(),
  UWStatus: makeSelectUWStatus(),
  summaryLink: makeSelectSummaryLink(),
  file: makeSelectFile(),
  UWLink: makeSelectUWLink(),
  emailSent: makeSelectemailSentStatus(),
  emailErrorMessage: makeSelectEmailErrorMessage(),
});

function mapDispatchToProps(dispatch) {
  return {
    sendEmailPDF: data => dispatch(sendEmailPDFAction(data)),
    createFile: data => dispatch(createFileAction(data)),
    setEmailSent: value => dispatch(setEmailSentAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  // validate, <-ignore this first for now
  form: 'Decision',
})(compose(withConnect)(Decision));

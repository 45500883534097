/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
// import '@babel/polyfill';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';

// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router/immutable';
import FontFaceObserver from 'fontfaceobserver';
import history from 'utils/history';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/moment';
import 'sanitize.css/sanitize.css';
import 'simplebar/dist/simplebar.min.css';
import TagManager from 'react-gtm-module';
import { analytics, okta } from 'environments';
//This is the comment which needs to be removed later done on 26 May 2023.
// Import root app
import App from 'containers/App';
// import global messages
import globalMessages from 'containers/App/messages';

// Import Language Provider
import LanguageProvider from 'containers/LanguageProvider';

// Load the .htaccess file
import 'file-loader?name=.htaccess!./.htaccess'; // eslint-disable-line import/extensions
// Load web.config file
import 'file-loader?name=web.config!./web.config';

import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { theme } from 'styles/theme';
import { setAppLoadingAction } from 'containers/App/actions';
import configureStore from './configureStore';

// Import i18n messages
import { translationMessages } from './i18n';

// Observe the locally served fonts
// - Frutiger
// When fonts are loaded, add a fontLoaded class to the body
// Promise.all([new FontFaceObserver('Frutiger').load()]).then(() => {
//   document.body.classList.add('fontLoaded');
// });
Promise.all([
  new FontFaceObserver('Frutiger').load(),
  new FontFaceObserver('Open Sans').load(),
]).then(() => {
  document.body.classList.add('fontLoaded');
});

// scroll polyfill
smoothscroll.polyfill();

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);
const MOUNT_NODE = document.getElementById('app');

if (analytics.gtmId) {
  const tagManagerArgs = {
    gtmId: analytics.gtmId,
  };

  TagManager.initialize(tagManagerArgs);
}

const oktaAuth = new OktaAuth(okta);

const customAuthHandler = () => {
  // Redirect to the /login page that has a CustomLoginComponent
  // This example is specific to React-Router
  history.push('/login');
};

const render = messages => {
  ReactDOM.render(
    <>
      <Provider store={store}>
        <LanguageProvider messages={messages}>
          <ConnectedRouter history={history}>
            <MuiThemeProvider theme={theme}>
              <CssBaseline />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Security
                  oktaAuth={oktaAuth}
                  onAuthRequired={customAuthHandler}
                  pkce={false}
                  // className="okta-security-wrapper"
                  tokenManager={{
                    // trigger renewal 2mins before the actual expiry as the threshold
                    // can change this to a much bigger number to debug
                    // e.g. 3550, so that it will trigger after 50 seconds, assuming the TTL of the token is 1h (3600s)
                    //
                    expireEarlySeconds: 120,
                  }}
                >
                  <App />
                </Security>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </ConnectedRouter>
        </LanguageProvider>
      </Provider>
    </>,
    MOUNT_NODE,
  );
};

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'));
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')])) // eslint-disable-line prettier/prettier
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === 'production') {
  const runtime = require('offline-plugin/runtime'); // eslint-disable-line global-require
  runtime.install({
    onUpdating: () => {
      store.dispatch(
        setAppLoadingAction(true, globalMessages.appUpdatingMessage),
      );
    },
    onUpdateReady: () => {
      // Tells to new SW to take control immediately
      runtime.applyUpdate();
    },
    onUpdated: () => {
      store.dispatch(setAppLoadingAction(true, globalMessages.appReloading));
      setTimeout(() => {
        store.dispatch(setAppLoadingAction(false));
        // Reload the webpage to load into the new version
        window.location.replace(`${window.location.pathname}`);
      }, 1000);
    },
    onUpdateFailed: () => {
      store.dispatch(setAppLoadingAction(false));
    },
  });
}

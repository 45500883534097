/*
 *
 * IdVerification constants
 *
 */

export const DEFAULT_ACTION = 'app/IdVerification/DEFAULT_ACTION';

export const START_VERIFICATION_ACTION =
  'app/IdVerification/START_VERIFICATION_ACTION';

export const SET_SHUFTI_PRO_ACTION = 'app/IdVerification/SET_SHUFTI_PRO_ACTION';

export const RESET_STATE_ACTION = 'app/IdVerification/RESET_STATE_ACTION';

import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import messages from 'containers/App/messages';
import { SubmissionError } from 'redux-form/immutable';
import moment from 'moment';
import numeral from 'numeral';

//validators
import isEmail from 'validator/lib/isEmail';

import { isEmpty } from 'lodash/lang';
import { forEach, map } from 'lodash/collection';
import { isFieldEmpty } from 'utils/form';

import { convertIfImmutable, partyFilter, isHSBCBank } from 'helpers/lang';

const validate = (values, props) => {
  const parsedValues = convertIfImmutable(values);
  var errors = {};

  if (!isEmpty(convertIfImmutable(values))) {
    const requiredFields = ['externalReferenceNumber'];

    forEach(requiredFields, field => {
      if (isFieldEmpty(parsedValues[field])) {
        errors[field] = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      } else if (
        field === 'externalReferenceNumber' &&
        !isHSBCBank(parsedValues.agencyNumber) &&
        parsedValues[field].length > 10
      ) {
        errors[field] = (
          <FormattedMessage
            {...globalMessages.maximumLength}
            values={{ value1: 10 }}
          />
        );
      } else if (
        field === 'externalReferenceNumber' &&
        isHSBCBank(parsedValues.agencyNumber) &&
        parsedValues[field].length > 15
      ) {
        errors[field] = (
          <FormattedMessage
            {...globalMessages.maximumLength}
            values={{ value1: 15 }}
          />
        );
      }
    });
  }
  return errors;
};

export default validate;

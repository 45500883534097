import React from 'react';
import PropTypes from 'prop-types';
import messages from 'containers/ApplicationFormPage/messages';
import { FormattedMessage } from 'react-intl';
import { Button, Grid, Typography } from '@material-ui/core';
import { Field } from 'redux-form/immutable';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import FormNumberField from 'components/FormNumberField';
import { useTheme } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';

export default function CreditCardMethod({
  formValues,
  formErrors,
  options,
  sendEmailAuthorizeCC,
}) {
  const theme = useTheme();
  return (
    <Grid container style={{ marginBottom: theme.spacing(3) }}>
      <Grid container direction="row" spacing={2} style={{ width: '415px' }}>
        <Grid item xs={12}>
          <Field
            name="payerName"
            fullWidth
            disabled
            component={FormTextField}
            label={<FormattedMessage {...messages.payorName} />}
            type="text"
            margin="normal"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name="payerEmail"
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.emailAddress} />}
            type="text"
            margin="normal"
          />
        </Grid>
        <Grid container spacing={3} item>
          <Grid item style={{ width: 284 }}>
            <Field
              name="cardCurrency"
              fullWidth
              component={FormSelect}
              options={[
                {
                  value: 'Select an option',
                  title: 'Select an option',
                },
                ...options.cardCurrency,
              ]}
              label={<FormattedMessage {...messages.cardCurrency} />}
              placeholder={<FormattedMessage {...messages.pleaseEnter} />}
              type="text"
              margin="normal"
            />
          </Grid>
          <Grid item style={{ width: 139 }}>
            <Field
              shrink
              name="preferredDateOfCollection"
              component={FormNumberField}
              decimalScale={0}
              label={<FormattedMessage {...messages.collectionDay} />}
              placeholder="DD"
              type="text"
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            An email will be sent to the registered email address of the payor
            to complete the payment
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={!isEmpty(formErrors?.paymentDetails?.payerDetail)}
            onClick={() => {
              sendEmailAuthorizeCC(formValues?.paymentDetails?.payerDetail);
            }}
          >
            Send Email
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

CreditCardMethod.propTypes = {
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  options: PropTypes.object,
  sendEmailAuthorizeCC: PropTypes.func,
  onUsePaymentLink: PropTypes.func,
};
import React from 'react';
import Switch from 'components/OptSwitch';

function FormSwitch({
  input: { onChange, value },
  disabled,
  ...props
}) {
  return (
    <Switch
      checked={value}
      value={value}
      color="default"
      onChange={onChange}
      labelNegative="No"
      disabled={disabled}
      {...props}
    />
  );
}

export default FormSwitch;

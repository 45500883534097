import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Button,
  Divider,
  Box,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import IconEditSrc from 'images/open-external.svg';
import TextField from 'components/TextField';
import Search from 'icons/Search';
import styled from 'styled-components';
import Dialog from 'components/Dialog';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/IllustrationPage/messages';
import {
  // makeSelectAuth,
  makeSelectAgentDetails,
} from 'containers/App/selectors';
import { resetOfferCodeAction } from 'containers/App/actions';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { makeSelectCustomers } from '../selectors';
import {
  getCustomersAction,
  setCustomerDetailAction,
  resetProductConfigAction,
} from '../actions';

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 ${props => props.theme.spacing(2)}px
      ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  }
`;
const useStyles = makeStyles(() => ({
  bodyText: {
    padding: '20px 30px',
    marginBottom: 2,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F9F9F9',
    },
  },
  textRef: {
    paddingRight: 5,
  },
  text: {
    fontWeight: 'bold',
  },
  btnUpLoad: {
    color: '#4066B3',
    cursor: 'pointer',
  },
  customerHeader: {
    height: 180,
    backgroundColor: '#ffffff',
    position: 'fixed',
    zIndex: 10,
    width: `calc(100% - 220px)`,
    top: 0,
    paddingTop: 30,
    '@media (max-width:919px)': {
      width: `calc(100% - 90px)`,
    },
  },
  titleCustomer: {
    marginTop: 8,
    marginBottom: 30,
    fontSize: 28,
  },
  tableCustomers: {
    marginTop: 180,
  },
  textResult: {
    fontSize: 14,
    color: '#B6B8B4',
    marginTop: 10,
  },
  widthSearch: {
    padding: 0,
  },
}));

export function TableCustomers({
  getProductConfig,
  agentDetails,
  resetProductConfig,
  customers,
  getCustomers,
  setCustomerDetail,
  resetOfferCode,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [valueSearch, setValueSearch] = useState('');
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [productInfo, setProductInfo] = useState({});

  const handleSearch = value => {
    setValueSearch(value);
  };

  const searchCustomers = () => {
    getCustomers({ searchText: valueSearch });
  };

  const onClick = () => {
    setValueSearch('');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      searchCustomers();
    }, 500);
    return () => clearTimeout(timer);
  }, [valueSearch]);

  useEffect(() => {
    resetProductConfig();
  }, []);

  return (
    <>
      <Wrapper theme={theme}>
        <Grid container>
          <Grid className={classes.customerHeader}>
            <Grid item xs={12}>
              <Typography variant="h1" className={classes.titleCustomer}>
                <FormattedMessage {...messages.customers} />
              </Typography>
            </Grid>
            <Typography
              style={{ display: 'flex', alignItems: 'center', width: 280 }}
            >
              <Grid item xs={10}>
                <TextField
                  className={classes.widthSearch}
                  fullWidth
                  value={valueSearch}
                  onChange={event => {
                    handleSearch(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <Search style={{ color: '#DDDFE0' }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs>
                {valueSearch !== '' && (
                  <Typography
                    onClick={onClick}
                    variant="h4"
                    style={{
                      marginLeft: 10,
                      color: '#043594',
                      fontWeight: 'bold',
                    }}
                  >
                    <FormattedMessage {...messages.clear} />
                  </Typography>
                )}
              </Grid>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.tableCustomers}>
            {valueSearch !== '' && (
              <>
                <Typography component="div">
                  <Typography className={classes.textResult}>
                    Showing results 1-{`${customers.length} `}
                    for <i>{valueSearch}</i>
                  </Typography>
                </Typography>
                <Box mt={1} mb={2}>
                  <Divider style={{ width: '100%' }} />
                </Box>
              </>
            )}
            <Typography component="div">
              {customers.map((item, index) => (
                <Grid container key={index} className={classes.bodyText}>
                  <Grid
                    item
                    xs={4}
                    className={classes.text}
                  >{`${item.firstName} ${item.lastName}`}</Grid>
                  <Grid item xs={3}>
                    {item.primaryPhoneNumber}
                  </Grid>
                  <Grid item xs={3} className={classes.textRef}>
                    {item.email}
                  </Grid>
                  <Grid item xs={2} align="right">
                    <img
                      src={IconEditSrc}
                      alt=""
                      className={classes.btnUpLoad}
                      onClick={() => {
                        setOpenDialog(true);
                        setCustomerDetail({
                          dateOfBirth: moment(item.dateOfBirth).format(
                            'YYYY-MM-DD',
                          ),
                          firstName: item.firstName,
                          gender: item.gender.toString(),
                          isPrimaryLifeInsured: true,
                          isSmoke: false,
                          lastName: item.lastName,
                          nationality: '',
                          isPolicyOwner: true,
                          isLifeInsured: true,
                          isPrimaryPolicyOwner: true,
                          relationshipToLifeInsured: '',
                          title: '',
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Typography>
          </Grid>
        </Grid>
      </Wrapper>

      <Dialog
        open={isOpenDialog}
        onClose={() => setOpenDialog(false)}
        title={<FormattedMessage {...messages.selectAProduct} />}
        variant="info"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
            style={{ padding: 25 }}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={isEmpty(productInfo)}
                onClick={() => {
                  setOpenDialog(false);
                  resetOfferCode();
                  return getProductConfig(productInfo);
                }}
              >
                <FormattedMessage {...messages.choose} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={1}>
          {agentDetails.products &&
            agentDetails.products.map(product => (
              <>
                <Grid item xs={4}>
                  <Button
                    variant="outlined"
                    fullWidth
                    onClick={() => {
                      setProductInfo(product);
                    }}
                    style={{
                      padding: theme.spacing(2),
                      borderRadius: '6px',
                      fontSize: '1.4rem',
                      fontWeight: 'normal',
                      height: 90,
                      border:
                        productInfo.productId === product.productId &&
                        productInfo.PTVVersion === product.PTVVersion
                          ? '2px solid #4066B3'
                          : '',
                    }}
                  >
                    {product?.name || ''}
                  </Button>
                </Grid>
              </>
            ))}
        </Grid>
      </Dialog>
    </>
  );
}

TableCustomers.propTypes = {
  agentDetails: PropTypes.object,
  customers: PropTypes.object,
  getProductConfig: PropTypes.func,
  resetProductConfig: PropTypes.func,
  getCustomers: PropTypes.func,
  setCustomerDetail: PropTypes.func,
  resetOfferCode: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  agentDetails: makeSelectAgentDetails(),
  customers: makeSelectCustomers(),
});

function mapDispatchToProps(dispatch) {
  return {
    // getSearchIllustrations: valueSearch =>
    //   dispatch(getSearchIllustrationsAction(valueSearch)),
    getCustomers: value => dispatch(getCustomersAction(value)),
    setCustomerDetail: value => dispatch(setCustomerDetailAction(value)),
    resetProductConfig: () => dispatch(resetProductConfigAction()),
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TableCustomers);

/**
 *
 * QuoteForm
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Card,
  Divider,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
} from 'redux-form/immutable';
import { InjectedFormProps, FormSection } from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import validate from './validate';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { formatMonetaryValue } from 'helpers/math';
import Benefits from './Benefits';
import numeral from 'numeral';
import { isEmpty } from 'lodash';
import { map, forEach, filter } from 'lodash/collection';
import { calculateAgeNextBirthdate, removeTrailingZeroes } from '../../helper';
import LoadingDialog from 'components/LoadingDialog';
import moment from 'moment';

const HeaderContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(3.25)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    background-color: ${props => props.theme.palette.primary.dark};
    color: ${props => props.theme.palette.common.white};
  }
`;

const BrContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(3.25)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.common.white};
  }
`;

const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0)',
  },
}));

function QuoteForm({
  illustrationTableDict,
  gcConfig,
  productConfig,
  handleSubmit,
  onSubmit,
  location,
  input,
  lifeAssured,
}) {
  const theme = useTheme();
  const classes = useStyle();
  let coverageTerm = 0;
  //LI1 and LI2 ages
  const lifeBasis = input['Life Basis'];

  const Life1Age = calculateAgeNextBirthdate(
    productConfig.startDate,
    lifeAssured?.insuredOne?.dateOfBirth,
  );

  const Life2Age = calculateAgeNextBirthdate(
    productConfig.startDate,
    lifeAssured?.insuredTwo?.dateOfBirth,
  );

  if (lifeBasis === 'SL' || isNaN(Life2Age)) {
    //get the min value between 2 ages
    coverageTerm = 100 - Life1Age;
  } else {
    coverageTerm = 100 - Math.min(Life1Age, Life2Age);
  }

  var myGrowthRateNode = `${numeral(productConfig.growthRate / 100).value()}`;
  myGrowthRateNode = numeral(myGrowthRateNode).format('0.0000');
  myGrowthRateNode = parseFloat(myGrowthRateNode.toString());

  const myBiTableValues = illustrationTableDict[myGrowthRateNode];

  const renderRows = () => {
    let arrayOfNumbers = [];

    //push all keys to array
    forEach(myBiTableValues, (item, key) => {
      if (parseInt(key) <= coverageTerm) {
        arrayOfNumbers.push(parseInt(key));
      }
    });

    //sort them accordingly
    arrayOfNumbers.sort(function(a, b) {
      return a - b;
    });

    //filter the items by 5
    let filteredNum = filter(arrayOfNumbers, num => {
      if (num % 5 === 0 || arrayOfNumbers.length === num) {
        return num;
      }
    });

    //render the items
    return map(filteredNum, num2 => (
      <Benefits
        formValues={myBiTableValues[`${num2}.0`]}
        gcConfig={gcConfig}
        years={parseInt(num2)}
      />
    ));
  };

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '-20px' }}>
          <Card
            style={{
              backgroundColor: theme.palette.primary.dark,
              borderRadius: 0,
            }}
          >
            <Grid
              container
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: `${theme.spacing(2)}px`,
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  {<FormattedMessage {...messages.premiumPaid} />}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  backgroundColor: theme.palette.primary.main,
                  padding: `${theme.spacing(2)}px`,
                  borderRight: `1px solid ${theme.palette.primary.main}`,
                }}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  {<FormattedMessage {...messages.policyTerm} />}
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  padding: `${theme.spacing(2)}px`,
                  borderRight: `1px solid ${theme.palette.primary.dark}`,
                }}
              >
                <Typography
                  variant="h4"
                  margin="normal"
                  align="right"
                  style={{
                    color: theme.palette.common.white,
                    fontWeight: '600',
                  }}
                >
                  {<FormattedMessage {...messages.fundValues} />}
                </Typography>
              </Grid>
            </Grid>

            {coverageTerm < 30 && productConfig.productCode === 'SAVP3' ? (
              renderRows()
            ) : !isEmpty(myBiTableValues) ? (
              <>
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['5.0']}
                    gcConfig={gcConfig}
                    years={5}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['10.0']}
                    gcConfig={gcConfig}
                    years={10}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['15.0']}
                    gcConfig={gcConfig}
                    years={15}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['20.0']}
                    gcConfig={gcConfig}
                    years={20}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['25.0']}
                    gcConfig={gcConfig}
                    years={25}
                  />
                ) : null}
                {!isEmpty(myBiTableValues) ? (
                  <Benefits
                    formValues={myBiTableValues['30.0']}
                    gcConfig={gcConfig}
                    years={30}
                  />
                ) : null}
              </>
            ) : null}

            <hr className={classes.hr} />

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{ paddingRight: theme.spacing(2) }}
            >
              <Grid item>
                <Typography
                  component="span"
                  variant="h1"
                  style={{
                    color: theme.palette.common.white,
                    // fontWeight: '600',
                  }}
                >
                  {productConfig.currency}
                </Typography>
              </Grid>
              <Grid item>
                <span
                  id="primaryPremiumAmount"
                  style={{
                    color: theme.palette.common.white,
                    fontSize: '6.6rem',
                    lineHeight: 1,
                    fontWeight: 700,
                  }}
                >
                  {!isEmpty(myBiTableValues)
                    ? formatMonetaryValue(
                        Math.round(
                          myBiTableValues[
                            `${
                              coverageTerm < 30 &&
                              productConfig.productCode === 'SAVP3'
                                ? coverageTerm
                                : 30
                            }.0`
                          ]['Fund Value'],
                        ),
                        false,
                      )
                    : 0}
                </span>
              </Grid>
            </Grid>

            <Divider
              style={{
                backgroundColor: theme.palette.info.main,
                margin: theme.spacing(2),
              }}
            />

            <Grid
              container
              spacing={1}
              justify="flex-end"
              style={{
                paddingRight: theme.spacing(2),
                paddingBottom: theme.spacing(3),
              }}
            >
              <Typography
                variant="body1"
                style={{
                  color: '#B9BBB8',
                  // fontWeight: 700,
                }}
              >
                {
                  <FormattedMessage
                    {...messages.projectedFundValues}
                    values={{
                      value:
                        coverageTerm < 30 &&
                        productConfig.productCode === 'SAVP3'
                          ? coverageTerm
                          : 30,
                    }}
                  />
                }
              </Typography>
            </Grid>
          </Card>
        </form>
      )}
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  gcConfig: PropTypes.object,
  productConfig: PropTypes.object,
  illustrationTableDict: PropTypes.object,
  annexureDict: PropTypes.object,
  annualIrr: PropTypes.object,
  additionalLifeCover: PropTypes.number,
};

const withForm = reduxForm({
  form: 'quoteForm',
  warn: validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');

const withConnect = connect(state => {
  let illustrationTableDict = selector(state, 'illustrationTableDict');
  let annexureDict = selector(state, 'annexureDict');
  let annualIrr = selector(state, 'annualIrr');
  let additionalLifeCover = selector(state, 'additionalLifeCover');
  const formValues = getFormValues('quoteForm')(state);
  return {
    illustrationTableDict: illustrationTableDict?.toJS() || {},
    annexureDict: annexureDict?.toJS() || {},
    annualIrr: annualIrr?.toJS() || {},
    additionalLifeCover,
    formValues: formValues?.toJS() || {},
  };
})(withForm);

export default withConnect;

import { put, takeLatest, all, call, select, delay } from 'redux-saga/effects';
import request from 'utils/request';
import { api } from 'environments';
import numeral from 'numeral';
import { orderBy } from 'lodash/collection';
import {
  setAppLoadingAction,
  validateOfferCodeSuccessAction,
} from 'containers/App/actions';
import {
  makeSelectAuth,
  makeSelectOfferCodeData,
} from 'containers/App/selectors';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import {
  setQuoteAction,
  setCalculateAction,
  setASPAction,
  setErrorAction,
  setQuoteDetailsAction,
  setApplicationIdAction,
  calculateQuoteAction,
  ChangeLifeAssuredAction,
  changeCoverageAction,
  setFiaAction,
  setCloneStatusAction,
  setBiPdfAction,
  setEmailSentAction,
  setEmailErrorMessage,
  setCompletedDialogAction,
  setPolicyConfigAction,
  setCoveragesListAction,
  setHarvestDataAction,
  setSustainabilityYearsAction,
  setInitCoveragesAction,
  setShowWarningReviewAction,
  setManualPremiumAction,
  setLastActionEditQuote,
  setPolicyTermHarvestAction,
  setHealthCheckPdfAction,
  setBiTableValuesaAction,
  setLifeAssuredHarvestAction,

} from './actions';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectQuoteId,
  makeSelectHarvestData,
  makeSelectLastActionEditQuote,
  makeSelectPolicyTerm,
  makeSelectInitProductConfig,
} from './selectors';
import {
  CREATE_QUOTE_ACTION,
  CALCULATE_QUOTE_ACTION,
  GET_QUOTE_ACTION,
  UPDATE_STATUS_ACTION,
  EMAIL_PDF_ACTION,
  CREATE_APPLICATION_ACTION,
  CHANGE_COVERAGE_ACTION,
  GET_FIA_ACTION,
  CLONE_QUOTE_ACTION,
  RECALCULATE_SUSTAINABILITY_ACTION,
  DOWNLOAD_HEALTH_CHECK_PDF_ACTION,
  GET_QUOTE_CREATED,
  GET_QUOTE_POLICY,
  ACTION_EDIT_QUOTE,
} from './constants';
import {
  getCoverages,
  getInitPremiumByFrequency,
  getSelectedCoverageStatus,
  getUpatedCoverageFromPricingEngine,
  isPrmIndexationApplicable
} from './helper';
import { productCodeCheck } from 'helpers/constants';
// to get the quote start date
const getQuoteStartDate = () => {
  let date_today = new Date();
  let currentDate = new Date().getDate();
  let firstDay = currentDate < 15 ? new Date(date_today.getFullYear(), date_today.getMonth(), 1) : new Date(date_today.getFullYear(), date_today.getMonth() + 1, 1);
  return firstDay;
}

function calculateBody(
  lifeAssured,
  myProductConfig,
  offerCodeData,
  policyTerm,
) {
  const partyDetails = [];
  if (lifeAssured.insuredOne.isPolicyOwner)
    lifeAssured.insuredOne.isPrimaryPolicyOwner = true;
  else lifeAssured.insuredOne.isPrimaryPolicyOwner = false;

  partyDetails.push(lifeAssured.insuredOne);

  if (lifeAssured.isInsuredTwo) {
    if (
      !lifeAssured.insuredOne.isPolicyOwner &&
      lifeAssured.insuredTwo.isPolicyOwner
    )
      lifeAssured.insuredTwo.isPrimaryPolicyOwner = true;
    else lifeAssured.insuredTwo.isPrimaryPolicyOwner = false;
    if (
      lifeAssured.insuredOne.isPolicyOwner &&
      lifeAssured.insuredTwo.isPolicyOwner
    ) {
      lifeAssured.isPolicyOwnerIndividual = false;
      lifeAssured.policyOwnerIndividual = [];
    }

    partyDetails.push(lifeAssured.insuredTwo);
  }

  if (
    lifeAssured.isPolicyOwnerIndividual &&
    lifeAssured.policyOwnerIndividual.length
  ) {
    lifeAssured.policyOwnerIndividual.forEach((poInd, index) => {
      if (
        index === 0 &&
        !lifeAssured.insuredOne.isPolicyOwner &&
        !lifeAssured?.insuredTwo?.isPolicyOwner
      )
        poInd.isPrimaryPolicyOwner = true;
      else poInd.isPrimaryPolicyOwner = false;
      partyDetails.push(poInd);
    });
  }

  const myCoverages = [];
  const myCoverageOne = {};
  const myCoverageTwo = {};
  myCoverageOne.isPrimary = true;

  myProductConfig.coverages.map(product => {
    const selectedStatus = getSelectedCoverageStatus(product);
    const benefitSlicesOne = []
    product.benefitSlicesOne.forEach(benefitSlice => {
      let updatedBenefitSlice = benefitSlice;
      updatedBenefitSlice['chargeStartDate'] = product.chargeStartDate
      updatedBenefitSlice['chargeEndDate'] = product.chargeEndDate
      benefitSlicesOne.push(updatedBenefitSlice)
    })
    myCoverageOne[product.id] = {
      selected: selectedStatus[0],
      benefitSlices: benefitSlicesOne,
      name: product.title,
      value:
        product.id === 'WOP'
          ? product.valueInsuredOne
          : numeral(product.valueInsuredOne).value(),
      term: product.termOne ? numeral(product.termOne).value() : 0,
      permanent: {
        perMille: product.perMileOne,
        perPercentage: product.perPercentageOne,
      },
      temporary: {
        perMille: numeral(product.perTempMileOne).value(),
        perPercentage: numeral(0).value(),
        time: numeral(product.perTempMonthOne).value(),
      },
    };

    if (lifeAssured.isInsuredTwo) {
      myCoverageTwo[product.id] = {
        selected: selectedStatus[1],
        benefitSlices: product.benefitSlicesTwo,
        name: product.title,
        value:
          product.id === 'WOP'
            ? product.valueInsuredTwo
            : numeral(product.valueInsuredTwo).value(),
        term: product.termTwo ? numeral(product.termTwo).value() : 0,
        permanent: {
          perMille: product.perMileTwo,
          perPercentage: product.perPercentageTwo,
        },
        temporary: {
          perMille: numeral(product.perTempMileTwo).value(),
          perPercentage: numeral(0).value(),
          time: numeral(product.perTempMonthTwo).value(),
        },
      };
    }
  });

  myCoverages.push(myCoverageOne);

  if (lifeAssured.isInsuredTwo) {
    myCoverages.push(myCoverageTwo);
  }

  if (isNumber(myProductConfig.policyTerm) === false) {
    policyTerm = myProductConfig.policyTerm;
  }

  const requestBody = {};
  requestBody.partyDetails = partyDetails;
  if (productCodeCheck(6, myProductConfig.productCode)) { // setting values below for WAP/SW in-force policy
    myProductConfig.regularWithdrawal.amount = Number(myProductConfig.regularWithdrawal.amount)
    myProductConfig.regularWithdrawal.percentage = Number(myProductConfig.regularWithdrawal.percentage)
    let fiaDetails = myProductConfig?.fiaCharge.replace('%', '')
    let policyValueDetailArray = myProductConfig.policyValue?.split(' ')
    let policyValueDetailCommaValue = policyValueDetailArray?.length > 0 ? policyValueDetailArray[1] : 0
    let policyValueDetail = policyValueDetailCommaValue?.replace(/,/g, '')
    let isoCountryCode = myProductConfig?.isoCountryCode ? myProductConfig?.isoCountryCode : 'AE'
    let fmcDetail = fmcDetails?.FMC?.filter(fmc => fmc.region === isoCountryCode)
    requestBody.productConfigs = {
      ...myProductConfig,
      name: myProductConfig?.name,
      additionalSinglePremium: (myProductConfig?.isAdditionalSinglePremium === true) ? Number(myProductConfig?.additionalSinglePremium?.amount) : 0,
      paymentFrequency: (myProductConfig?.isAdditionalSinglePremium === true) ? myProductConfig?.additionalSinglePremium?.frequency : '',
      premium: numeral(myProductConfig.premium).value(),
      singlePremium: numeral(myProductConfig.singlePremium).value(),
      isAdditionalSinglePremium: Number(myProductConfig?.additionalSinglePremium?.amount) > 0 ? true : false, // check this value
      isAdditionalFeatures: false,
      maxEstablishmentCharge: myProductConfig.establishmentCharge, // need to ask to salman
      establishmentCharge: myProductConfig.establishmentCharge,
      term: Number.isInteger(myProductConfig.term)
        ? Number(myProductConfig.term)
        : myProductConfig.term,
      growthRate: Number(myProductConfig.growthRate),
      coverages: myCoverages,
      policyTerm,
      lifeBasis: lifeAssured.lifeBasis,
      offerCodes: [{ ...offerCodeData }],
      FMC: fmcDetail?.length > 0 ? fmcDetail[0]['FMCrate'] : 1.5,
      shortName: myProductConfig?.sortName ? myProductConfig?.shortName : myProductConfig?.name,
      targetFundEnable: false,
      targetFund: 0,
      targetYear: 0,
      policyStartDate: myProductConfig.policyStartDate,
      startDate: myProductConfig.startDate,
      regularWithdrawal: myProductConfig.regularWithdrawal,
      premiumSlices: myProductConfig.premiumSlices,
      policyValue: Number(policyValueDetail),
      adHocwithdrawl: Number(myProductConfig.adHocwithdrawl) > 0 ? Number(myProductConfig.adHocwithdrawl) : 0,
      totalWithdrawls: myProductConfig.totalPartialSurrender ? Number(myProductConfig.totalPartialSurrender) : 0,
      existingPremiumFrequency: myProductConfig.premiumFrequency ? myProductConfig.premiumFrequency : "1",
      fiaCharge: Number(fiaDetails),
      isRegularWithdrawal: myProductConfig?.isRegularWithdrawal ? myProductConfig?.isRegularWithdrawal : false
    };
    requestBody.noUpdatePartyDetails = false;
    delete requestBody.productConfigs.growthRateDetail
    delete requestBody.productConfigs.establishmentChargeList;
    delete requestBody.productConfigs.regularPremiumSlices;
    delete requestBody.productConfigs.singlePremiumSlices;
    delete requestBody.productConfigs.haveAdditionalSinglePremium;
    delete requestBody.productConfigs.numberOfSlices;
    delete requestBody.productConfigs.blendFormFlag;
    delete requestBody.productConfigs.baseVanishingTerm;
    delete requestBody.productConfigs.policyWOL;
    delete requestBody.productConfigs.commissionType;
    delete requestBody.productConfigs.newSinglePremiumAmount; // this need to check
    delete requestBody.productConfigs.initPremium;
    delete requestBody.productConfigs.premiumSliceDates;
    delete requestBody.productConfigs.coverages;
    delete requestBody.productConfigs.term;
    delete requestBody.productConfigs.ltcSliceDates;
    delete requestBody.productConfigs.singlePremium;
    delete requestBody.productConfigs.nextDueDate;
    delete requestBody.productConfigs.fiaCurrency;
    delete requestBody.productConfigs.policyCoverTerm;
    delete requestBody.productConfigs.sliceStartDates;
    delete requestBody.productConfigs.fiaAmount;
    delete requestBody.productConfigs.annualPremium;
    delete requestBody.productConfigs.specialTermsFlag;
    delete requestBody.productConfigs.exisitingPremium;
    delete requestBody.productConfigs.initSinglePremium;
    delete requestBody.productConfigs.mergeASP; // from here again added for SW
    delete requestBody.productConfigs.annualizedPremium;
    delete requestBody.productConfigs.isMerged;
    delete requestBody.productConfigs.mergeSustainability;
    delete requestBody.productConfigs.regularPremiumSliceLength;
    delete requestBody.productConfigs.singlePremiumSliceLength;
    delete requestBody.productConfigs.singlePremiumTerm;
    delete requestBody.productConfigs.sspSliceOne;
    delete requestBody.productConfigs.sspSliceThree;
    delete requestBody.productConfigs.sspSliceTwo;

  } else { // setting productConfigs below for Futura in-force policy 
    requestBody.productConfigs = {
      ...myProductConfig,
      partialWithdrawal: numeral(myProductConfig.partialWithdrawal).value(),
      maxPartialWithdrawal: numeral(myProductConfig.maxPartialWithdrawal).value(),
      premium: numeral(myProductConfig.premium).value(),
      singlePremium: numeral(myProductConfig.singlePremium).value() == 0 ? myProductConfig.additionalSinglePremium : myProductConfig.singlePremium ,
      term: Number.isInteger(myProductConfig.term)
        ? Number(myProductConfig.term)
        : myProductConfig.term,
      growthRate: Number(myProductConfig.growthRate),
      coverages: myCoverages,
      policyTerm,
      lifeBasis: lifeAssured.lifeBasis,
      offerCodes: [{ ...offerCodeData }],
      // isReinstate : 
    };
  
  }
  delete requestBody.productConfigs.initCoverages;

  return requestBody;
}

export function* createQuote() {
  yield put(setAppLoadingAction(true));
  const myProductConfig = yield select(makeSelectProductConfig());
  const policyTerm = yield select(makeSelectPolicyTerm());
  const lifeAssured = yield select(makeSelectLifeAssured());
  const auth = yield select(makeSelectAuth());
  const offerCodeData = yield select(makeSelectOfferCodeData());
  const harvestData = yield select(makeSelectHarvestData());
  // below lines are added for Futura in-force policy - start
  const initProductConfig = yield select(makeSelectInitProductConfig());
  if(myProductConfig.partialWithdrawal){
    //add max partial surrender value
    myProductConfig.maxPartialWithdrawal = harvestData?.policyDetail?.maxPartialSurrender;
    //if partial withdrawal then set benefits from harvest
    //myProductConfig.coverages = initProductConfig.coverages;
  }
  // end
  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    offerCodeData,
    policyTerm,
  );

  const lastActionEditQuote = yield select(makeSelectLastActionEditQuote());
  yield put(setLastActionEditQuote(ACTION_EDIT_QUOTE.SAVE_QUOTE));
  let premiumCompareValidation = myProductConfig.premium;
  if (lastActionEditQuote === ACTION_EDIT_QUOTE.GET_SUSTAIN_AGE) {
    premiumCompareValidation = yield call(calculateCheckPremiumQuote);
  }
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify({ ...myBody, harvestData, isInforce: true }),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data && response.data?.isValid === undefined) {
      yield put(setQuoteAction(response.data));
      yield put(setAppLoadingAction(false));
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setCompletedDialogAction({ open: true, message: 'ADDED' }));
      if (Number(premiumCompareValidation) < Number(myProductConfig.premium)) {
        yield put(setShowWarningReviewAction(true));
      }
    }

    if (response.data?.isValid === false) {
      yield put(
        setErrorAction({
          error: true,
          message: response.data.validationMessage,
        }),
      );
    }
  } catch (err) {
    console.log('err', err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* updateQuoteStatus(action) {
  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/status`;

  const auth = yield select(makeSelectAuth());

  const quoteId = yield select(makeSelectQuoteId());
  let myBody = {};
  if (action.payload === 1) {
    myBody = {
      quoteId,
      isPreview: true,
    };
  }

  if (action.payload === 2) {
    myBody = {
      quoteId,
      isAgree: true,
    };
  }

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error && !response?.data?.missingKFS) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
      yield call(getQuote, { payload: { type: GET_QUOTE_CREATED } });
    } else {
      yield put(setAppLoadingAction(false));
      let { message } = response;
      if (response.data.missingKFS) {
        message = 'The Quote is invalid. Please regenerate new one.';
      }
      yield put(
        setErrorAction({
          error: true,
          message: [{ message }],
        }),
      );
    }
  } catch (err) {
    console.log('er', err);
    yield put(setAppLoadingAction(false));
  }
}

export function* getQuote(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const quoteId = yield select(makeSelectQuoteId());
  const { payload } = action;
  const endpoint =
    payload.type === GET_QUOTE_CREATED
      ? `${api.host}/api/v${api.version}/b2b/quote/${quoteId}`
      : `${api.host}/api/v${api.version}/b2b/inforce/get-policy-detail?policyNumber=${payload.policyNumber}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    if (payload.type === GET_QUOTE_POLICY) {
      yield call(getProductConfig, {
        productCode: payload.productCode,
        productVersion: payload.productVersion,
      });
    }
    const response = yield call(request, endpoint, requestOpt);
    if (response) {
      // Get benefitIllustrationPdf after save quote
      if (payload.type === GET_QUOTE_CREATED) {
        if (!isEmpty(response.data.benefitIllustrationPdf)) {
          const details = {
            referenceNumber: response.data.referenceNumber,
            benefitIllustrationPdf: response.data.benefitIllustrationPdf,
            status: response.data.status,
          };
          yield put(setQuoteDetailsAction(details));
          yield call(
            getPdf,
            response.data.benefitIllustrationPdf.split('=')[1],
          );
        } else {
          yield delay(1000);
          yield call(getQuote, {
            payload: {
              type: GET_QUOTE_CREATED,
            },
          });
        }
      }
      // END

      const responseData =
        payload.type === GET_QUOTE_POLICY ? response : response.data;
      yield put(setAppLoadingAction(false));
      yield put(setErrorAction({ error: false, message: [] }));

      if (action && payload.type !== undefined) {
        const existingProductConfigData = yield select(
          makeSelectProductConfig(),
        );
        const lifeInsuredData = {};
        lifeInsuredData.policyOwnerIndividual = [];
        lifeInsuredData.policyOwners = [];
        if (payload.type === GET_QUOTE_POLICY) {
          responseData.parties.map(productData => {
            const partyDetails = {
              prospectId: productData.id,
              title: productData.title,
              firstName: productData.firstName,
              lastName: productData.lastName,
              dateOfBirth: productData.dateOfBirth,
              gender: productData.gender.toString(),
              isSmoke: productData.isSmoke,
              country: productData.country,
              nationality: productData.primaryNationality,
              countryOfResidence: productData.countryOfResidence,
              placeOfBirth: productData.placeOfBirth,
              isExisting: true, // to check added new life only
              isLifeInsured:
                productData.partyapplicationrelationships.isLifeInsured,
              isPrimaryLifeInsured:
                productData.partyapplicationrelationships.isPrimaryLifeInsured,
              isPolicyOwner:
                productData.partyapplicationrelationships.isPolicyOwner,
              relationshipToLifeInsured: '',
            };

            lifeInsuredData.lifeBasisHarvest =
              responseData.productConfigs.lifeBasis;
            lifeInsuredData.lifeBasis = responseData.productConfigs.lifeBasis;

            if (partyDetails.isLifeInsured && partyDetails.isPrimaryLifeInsured)
              lifeInsuredData.insuredOne = partyDetails;

            if (
              partyDetails.isLifeInsured &&
              !partyDetails.isPrimaryLifeInsured
            ) {
              lifeInsuredData.isInsuredTwo = true;
              lifeInsuredData.insuredTwo = partyDetails;
            }

            if (!partyDetails.isLifeInsured && partyDetails.isPolicyOwner) {
              lifeInsuredData.isPolicyOwnerIndividual = true;
              partyDetails.relationshipToLifeInsured = 'FATHER';
              lifeInsuredData.policyOwnerIndividual.push(partyDetails);
            }

            if (partyDetails.isPolicyOwner) {
              lifeInsuredData.policyOwners.push(partyDetails);
            }
          });
        } else {
          responseData.prospects.map(productData => {
            const partyDetails = {
              prospectId: productData.id,
              title: productData.title,
              firstName: productData.firstName,
              lastName: productData.lastName,
              dateOfBirth: productData.dateOfBirth,
              gender: productData.gender.toString(),
              isSmoke: productData.isSmoke,
              isLifeInsured:
                productData.prospectquoterelationships.isLifeInsured,
              isPrimaryLifeInsured:
                productData.prospectquoterelationships.isPrimaryLifeInsured,
              isPolicyOwner:
                productData.prospectquoterelationships.isPolicyOwner,
              relationshipToLifeInsured: '',
            };

            lifeInsuredData.lifeBasis = responseData.productConfigs.lifeBasis;

            if (partyDetails.isLifeInsured && partyDetails.isPrimaryLifeInsured)
              lifeInsuredData.insuredOne = partyDetails;

            if (
              partyDetails.isLifeInsured &&
              !partyDetails.isPrimaryLifeInsured
            ) {
              lifeInsuredData.isInsuredTwo = true;
              lifeInsuredData.insuredTwo = partyDetails;
            }

            if (!partyDetails.isLifeInsured) {
              lifeInsuredData.isPolicyOwnerIndividual = true;
              lifeInsuredData.policyOwnerIndividual.push(partyDetails);
            }
          });
        }
        yield put(ChangeLifeAssuredAction(lifeInsuredData));
        //add to store put condition
        if(!(payload.type === GET_QUOTE_CREATED)){
          console.log('Life assured harvest updated');
          yield put(setLifeAssuredHarvestAction(lifeInsuredData));
        }
        yield put(setHarvestDataAction(responseData.harvestData));
        const data = {};
        if (responseData.productConfigs) {
          const offerCodes =
            responseData.productConfigs?.offerCodes &&
            responseData.productConfigs?.offerCodes[0];
          if (offerCodes) {
            yield put(
              validateOfferCodeSuccessAction({
                code: offerCodes.code ? offerCodes.code : '',
                discountRate: offerCodes.discountRate
                  ? offerCodes.discountRate
                  : 0,
                discountMessage: offerCodes.discountMessage
                  ? offerCodes.discountMessage
                  : {},
                validating: false,
              }),
            );
          }
          const selectedAgency = sessionStorage.getItem('agencySelected');
          data.name = responseData.productConfigs.name;
          data.policyNumber = responseData.policyNumber;
          data.lifeBasis = responseData.productConfigs.lifeBasis;
          data.totalPremiumPaid = responseData.productConfigs.totalPremiumPaid;
          data.startDate = responseData.productConfigs.startDate;
          data.exisitingPremium = responseData.productConfigs.exisitingPremium;
          data.singlePremiumTerm = responseData.productConfigs.singlePremiumTerm;
          data.singlePremiumSliceLength = responseData.productConfigs.singlePremiumSlices.length - 1;
          data.additionalSinglePremium = 0;
          data.annualizedPremium = 0;
          data.isMerged = false;
          data.rpAsp = 0;
          data.isRestart = true;
          data.oldPremium = responseData.premium;
          data.mergeSustainability = false;
          data.mergeASP = 0;
          if (responseData?.productCode === 'WAPL2' || responseData?.productCode === 'WAPL1' || responseData?.productCode === 'SIMWE' || responseData?.productCode === 'SIMW2') {
            data.policyStartDate = responseData.harvestData.policyDetail.policyStartDate;
            data.premiumSlices = responseData.harvestData.policyDetail.premiumSlices;
            data.policyValue = Number(responseData.harvestData.policyDetail.policyValue);
            data.basisTypeDescription = responseData.harvestData.policyDetail.basisTypeDescription;
            data.fiaCharge = Number(responseData?.productConfigs?.fiaAmount) > 0 ? 1 + "%" : 0 + "%";
            data.premium = '';
            data.growthRate = '';
            data.establishmentCharge = '';
            data.establishmentChargeList = establishmentCharge;
            data.totalPartialSurrender = responseData?.harvestData?.policyDetail?.totalPartialSurrender;
            data.maxPartialSurrender = Number(responseData?.harvestData?.policyDetail?.maxPartialSurrender);
            data.policyValue = responseData.productConfigs.currency + " " + Number(responseData.harvestData.policyDetail.policyValue).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            data.growthRateDetail = Number(responseData.harvestData.policyDetail.illustrationPercentage);
            data.isRegularWithdrawal = false;
            data.regularWithdrawal = {
              "frequency": "",
              "startDate": "",
              "endDate": "",
              "amount": 0,
              "percentage": 0,
              "isAmountChecked": 1,
              "selected": false
            }
            data.additionalSinglePremium = {
              "frequency": "",
              "amount": 0,
            }
          } else {
            data.premium = responseData.productConfigs.premium;
            data.fiaCharge = responseData.productConfigs.fiaCharge;
            data.growthRate = responseData.productConfigs.growthRate || 5;
           if(responseData?.productCode && selectedAgency && selectedAgency === '8910138') {
              switch (responseData?.productCode) {
                case 'SCFUT' :
                  data.growthRate = 6;
                  break;
                case 'SAVP3' :
                  data.growthRate = 7;
                  break;
              }
            }
          }

          data.productCode = responseData.productConfigs.productCode;
          data.ptvVersion = responseData.productConfigs.ptvVersion;
          data.currency = responseData.productConfigs.currency;
          data.term = responseData.productConfigs.term;

          data.specialTermsFlag = responseData.productConfigs.specialTermsFlag;
          data.paymentFrequency = responseData.productConfigs.paymentFrequency;
          data.haveAdditionalSinglePremium =
            responseData.productConfigs.haveAdditionalSinglePremium;
            // Premium Indexation and Premium Holiday is applicable only for Futura In-force policy
        if (productCodeCheck('FUTU', responseData.productConfigs.productCode)) { 
          //  for the below ptvs premium indexation is not applicable
          if (isPrmIndexationApplicable(data.productCode)) {
            data.premiumIndexation = responseData.productConfigs.premiumIndexation ? true : false;
          } 
          // Added below for premium holiday
            data.prmHolidayStartDate =  responseData.productConfigs.prmHolidayStartDate ? responseData.productConfigs.prmHolidayStartDate : '';
            data.prmHolidayEndDate = responseData.productConfigs.prmHolidayEndDate ? responseData.productConfigs.prmHolidayEndDate : '';
            data.prmHolidayActualEndDate =  responseData.productConfigs.prmHolidayActualEndDate ? responseData.productConfigs.prmHolidayActualEndDate : '';
            const currentDate = moment().format('YYYY-MM-DD');
            if (data.prmHolidayStartDate != '' && data.prmHolidayEndDate != '')
              data.isPremiumHoliday = (data.prmHolidayActualEndDate == '') ? (currentDate < data.prmHolidayEndDate) : (currentDate < data.prmHolidayActualEndDate);
            else 
              data.isPremiumHoliday = false;
              data.isAgeWOL = false;  
        }
          // Added for overdue Prem changes 

         if (responseData.productConfigs.premiumDueData && responseData.productConfigs.premiumDueData.length > 0) {
            
            const quoteStartDate = getQuoteStartDate();
            const firstOverDueDate = responseData.productConfigs.premiumDueData[0].passDueDate;
            const overduePremiumFlag = moment(firstOverDueDate).isBefore(moment(quoteStartDate));
            data.isOverDuePremium = overduePremiumFlag;
          }
          
          // end
          data.singlePremium =
            payload.type === GET_QUOTE_POLICY
              ? 0
              : responseData.productConfigs.singlePremium;

          data.initPremium = responseData.productConfigs.premium;
          data.initSinglePremium = responseData.productConfigs.singlePremium;
          data.sspSliceOne = data.singlePremiumSliceLength >= 1 ? responseData.productConfigs.singlePremiumSlices[1]['spInstalmentPaidAmount'] : 0;
          data.sspSliceTwo = data.singlePremiumSliceLength >= 2 ? responseData.productConfigs.singlePremiumSlices[2]['spInstalmentPaidAmount'] : 0;
          data.sspSliceThree = data.singlePremiumSliceLength >= 3 ? responseData.productConfigs.singlePremiumSlices[3]['spInstalmentPaidAmount'] : 0;

          data.validForIFQ = responseData.validForIFQ;
          data.mappedStatus = responseData.mappedStatus;
          data.policyStatus = responseData.status; // RI -> below changes are done for RI - start
          
          data.isReinstate = ((responseData.status == 'L' ||
                                  responseData.status == 'C') &&
                                          responseData.isReinstate  == true) ? true : false;
          // data.isReinstate = (responseData.status == 'L' ||
          //                          responseData.status == 'C') ? true : false;
          let sliceStartDate;
          let naprapEndDate;
          let premiumDueDate;
          let isInNAPRAPPeriod = false;
          let isNBSliceInNAPRAPPeriod = false;
          const currentQuoteDate = moment().format('YYYY-MM-DD'); // to get current date
           if (responseData.productConfigs.regularPremiumSlices && 
                    responseData.productConfigs.regularPremiumSlices.length > 0)  {
            responseData.productConfigs.regularPremiumSlices.forEach(premiumSlice => {
            // _.forEach(responseData.productConfigs.regularPremiumSlices, premiumSlice => {
              if (premiumSlice.source == "HARVEST") {
              sliceStartDate = premiumSlice.sliceStartDate;
              naprapEndDate = premiumSlice.naprapEndDate;
                  if (moment(currentQuoteDate).isBetween(sliceStartDate, naprapEndDate) == true)
                      isInNAPRAPPeriod = true;
                  if (responseData.productConfigs.premiumDueData && responseData.productConfigs.premiumDueData.length > 0) {
                    premiumDueDate = responseData.productConfigs.premiumDueData[0].passDueDate;
                    if (moment(premiumDueDate).isBetween(sliceStartDate, naprapEndDate) == true){
                      isInNAPRAPPeriod = true;
                    }
                  }
                  if(premiumSlice.premiumSliceNumber == 1 && isInNAPRAPPeriod) {
                    isNBSliceInNAPRAPPeriod = true;
                  }
              }
            });
          }  else if (responseData.productConfigs.singlePremiumSlices && 
                          responseData.productConfigs.singlePremiumSlices.length > 0)  {    
            responseData.productConfigs.singlePremiumSlices.forEach(premiumSlice => {
                if (premiumSlice.source == "HARVEST") {
                  sliceStartDate = premiumSlice.sliceStartDate;
                  naprapEndDate = premiumSlice.naprapEndDate;
                  if (moment(currentQuoteDate).isBetween(sliceStartDate, naprapEndDate) == true)
                      isInNAPRAPPeriod = true;
                  if (responseData.productConfigs.premiumDueData && responseData.productConfigs.premiumDueData.length > 0) {
                    premiumDueDate = responseData.productConfigs.premiumDueData[0].passDueDate;
                    if (moment(premiumDueDate).isBetween(sliceStartDate, naprapEndDate) == true){
                      isInNAPRAPPeriod = true;
                    }
                  }
                }
              });  
          }
          data.isInNAPRAPPeriod =  isInNAPRAPPeriod;       
          data.isNBSliceInNAPRAPPeriod = isNBSliceInNAPRAPPeriod;
          console.log(" RI data in saga :: isInNAPRAPPeriod => ", isInNAPRAPPeriod); 
          if (payload.type === GET_QUOTE_POLICY) {
            data.regularPremiumSlices =
              responseData.productConfigs.regularPremiumSlices;
            data.regularPremiumSlices.push({
              ...data.regularPremiumSlices[
              data.regularPremiumSlices.length - 1
              ],
              annualPremium: 0,
              premiumSliceNumber: data.regularPremiumSlices.length + 1,
              source: 'OSCAR_IFI',
              sliceTerm: 0,
              icpNAP: 0,
              commisionPercent: 0,
            });
            data.singlePremiumSlices =
              responseData.productConfigs.singlePremiumSlices;
            data.singlePremiumSlices.push({
              ...data.singlePremiumSlices[data.singlePremiumSlices.length - 1],
              annualPremium: 0,
              premiumSliceNumber: data.singlePremiumSlices.length + 1,
              source: 'OSCAR_IFI',
            });

            data.lastPaidDate = responseData.productConfigs.singlePremiumTerm === 0 && (responseData.productConfigs.regularPremiumSlices.length - 1) === 0 ? responseData.productConfigs.singlePremiumSlices[0]['sliceStartDate'] : responseData.productConfigs.lastPaidDate;
            data.nextDueDate = responseData.productConfigs.singlePremiumTerm === 0 && (responseData.productConfigs.regularPremiumSlices.length - 1) === 0 ? responseData.productConfigs.singlePremiumSlices[0]['sliceEndDate'] : responseData.productConfigs.nextDueDate;
            data.regularPremiumSliceLength = responseData.productConfigs.regularPremiumSlices.length - 1;
          }
          // check whether advance premium payment is done

          if (data.lastPaidDate || responseData.productConfigs.lastPaidDate) {
            const lastPaymentDate = (data.lastPaidDate) ? (data.lastPaidDate) : (responseData.productConfigs.lastPaidDate);
            const currentDate = moment().format('YYYY-MM-DD');
            const isAdvancePremiumPaidFlag = (currentDate < lastPaymentDate) ? true : false;
            data.isAdvancePremiumPaid = isAdvancePremiumPaidFlag;
          }

          if(responseData.productConfigs?.prmHolidayStartDate){
            const currentDate = moment().format('YYYY-MM-DD');
            const prmEdnDate = (responseData.productConfigs.prmHolidayActualEndDate != "") ? responseData.productConfigs.prmHolidayActualEndDate : responseData.productConfigs.prmHolidayEndDate 
            data.nextDueDate = (currentDate < prmEdnDate) ? prmEdnDate : data.nextDueDate;             
          }
          
          const myCoverages = [];
          existingProductConfigData.coverages.map(myBenefit => {
            responseData.productConfigs.coverages.map(responseBenefit => {
              const responseObject = responseBenefit[myBenefit.id];
              if (responseObject) {
                if (responseObject.selected) myBenefit.selected = true;
                if (responseBenefit.isPrimary) {
                  const valueInsuredOne =
                    myBenefit.id !== 'WOP'
                      ? responseObject.value !== null
                        ? `${responseObject.value}`
                        : '0'
                      : responseObject.value;
                  if (payload.type === GET_QUOTE_POLICY) {
                    myBenefit.benefitSlicesOne = responseObject.benefitSlices; 
                    myBenefit.benefitSlicesOne.push({
                      benefitAmount: 0,
                      benefitSlice: responseObject.benefitSlices.length + 1,
                      benefitTerm: 0,
                      source: 'OSCAR_IFI',
                    });
                  }

                  myBenefit.valueInsuredOne = valueInsuredOne;
                  myBenefit.initValueInsuredOne = valueInsuredOne;
                  myBenefit.termOne = responseObject.term;
                  myBenefit.initTermOne = responseObject.term;
                  myBenefit.perMileOne = responseObject.permanent.perMille;
                  myBenefit.perPercentageOne =
                    responseObject.permanent.perPercentage;
                  myBenefit.perTempMileOne = responseObject.temporary.perMille;
                  myBenefit.perTempMonthOne = responseObject.temporary.time;
                  if (responseObject.permanent.perMille != '0') {
                    myBenefit.brSelectedInsuredOneValue = true;
                  }

                  if (responseObject.permanent.perPercentage != '0') {
                    myBenefit.brSelectedPercentValueOne = true;
                  }

                  if (responseObject.temporary.perMille != '0') {
                    myBenefit.brSelectedTempValueOne = true;
                  }
                } else {
                  const valueInsuredTwo =
                    myBenefit.id !== 'WOP'
                      ? responseObject.value !== null
                        ? `${responseObject.value}`
                        : '0'
                      : responseObject.value;
                  if (payload.type === GET_QUOTE_POLICY) {
                    myBenefit.benefitSlicesTwo = responseObject.benefitSlices;
                    myBenefit.benefitSlicesTwo.push({
                      benefitAmount: 0,
                      benefitSlice: responseObject.benefitSlices.length + 1,
                      source: 'OSCAR_IFI',
                    });
                  }

                  myBenefit.valueInsuredTwo = valueInsuredTwo;
                  myBenefit.initValueInsuredTwo = valueInsuredTwo;

                  myBenefit.termTwo = responseObject.term;
                  myBenefit.initTermTwo = responseObject.term;
                  myBenefit.perMileTwo = responseObject.permanent.perMille;
                  myBenefit.perPercentageTwo =
                    responseObject.permanent.perPercentage;
                  myBenefit.perTempMileTwo = responseObject.temporary.perMille;
                  myBenefit.perTempMonthTwo = responseObject.temporary.time;
                  if (responseObject.permanent.perMille != '0') {
                    myBenefit.brSelectedInsuredTwoValue = true;
                  }

                  if (responseObject.permanent.perPercentage != '0') {
                    myBenefit.brSelectedPercentValueTwo = true;
                  }

                  if (responseObject.temporary.perMille != '0') {
                    myBenefit.brSelectedTempValueTwo = true;
                  }
                }
              }

              // Added below for rated policies

              if (responseObject && responseObject.riskStartDate) {
                myBenefit.riskStartDate = responseObject.riskStartDate;
              }
              if (responseObject && responseObject.riskEndDate){
                myBenefit.riskEndDate = responseObject.riskEndDate;
              } 
              if (responseObject && responseObject.chargeStartDate){
                myBenefit.chargeStartDate = responseObject.chargeStartDate;
              } 
              if (responseObject && responseObject.chargeEndDate){
                myBenefit.chargeEndDate = responseObject.chargeEndDate;
              } 
             
            });
            myCoverages.push(myBenefit);
          });

          data.coverages = orderBy(myCoverages, ['selected'], ['desc']).filter(
            item => item.id !== 'ESC',
          );
        }
        yield put(
          changeCoverageAction({
            ...responseData.productConfigs,
            ...data,
          }),
        );
        yield put(
          setInitCoveragesAction(responseData.productConfigs.coverages),
        );
      }
    }
  } catch (err) {
    console.log('err', err);
    yield put(setAppLoadingAction(false));
  }
}

export function* getPdf(fileName) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/storage/create`;

  const updatedPayload = {
    type: 'FILE',
    name: fileName,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(updatedPayload),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!isEmpty(response)) {
      yield put(setAppLoadingAction(false));
      yield put(setBiPdfAction(`${response.previewUrl}`));
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    console.log('err', err);
    yield put(setAppLoadingAction(false));
  }
}

export function* emailQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/bi-pdf-email`;

  const myBody = {
    quoteId,
    email: action.payload,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
    yield put(setEmailSentAction(false));
  } catch (err) {
    const response = yield err.response.json();
    if (response?.code === 'TOO_MANY_REQUESTS') {
      yield put(setEmailSentAction(true));
      yield put(setEmailErrorMessage(response.message));
    }
    yield put(setAppLoadingAction(false));
  }
}

export function* getFiaCharges(action) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/fia-charge`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!isEmpty(response)) {
      if (!response.error) {
        yield put(setErrorAction({ error: false, message: [] }));
        yield put(setFiaAction(response.data));
      }
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* createApplication() {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application`;

  const myBody = {
    quoteId,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setApplicationIdAction(response.data.id));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* cloneQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${quoteId}/quote-reference`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setCloneStatusAction(true));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* handleUpdateCoverage(action) {
  const {
    recalculateQuote,
    reverseCalculate,
    isLifeAssuredEqual,
    isCalculateQuotePage,
    isCheckOfferCode,
  } = action.payload;
  if (recalculateQuote) {
    yield put(
      calculateQuoteAction({
        reverseCalculate,
        isLifeAssuredEqual,
        isCalculateQuotePage,
        isCheckOfferCode,
      }),
    );
  }
}
let fmcDetails = {}
let establishmentCharge = []
export function* getProductConfig(payload) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/product?productCode=${payload.productCode}&productVersion=${payload.productVersion}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      const {
        minEntryAgeLA,
        maxEntryAgeLA,
      } = response.data.features.ageAtEntry;
      const newMandatoryList = response.data.features.productBenefits.mandatoryList.map(
        item => ({ ...item, minEntryAgeLA, maxEntryAgeLA }),
      );
      response.data.features.productBenefits.mandatoryList = newMandatoryList;
      fmcDetails = response.data.features;
      establishmentCharge = response.data?.features?.establishmentCharge;
      yield put(setPolicyConfigAction(response.data));
      yield put(setCoveragesListAction(getCoverages(response.data.features)));
    }
  } catch (err) {
    console.log('err getProductConfig', err);
  }
}

export function* recalculateSustainability({ payload }) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const offerCodeData = yield select(makeSelectOfferCodeData());
  const myBody = calculateBody(lifeAssured, myProductConfig, offerCodeData);
  myBody.productConfigs.growthRate = payload.growthRate;
  myBody.productConfigs.policyCoverTerm = 'CustomPremium';
  const harvestData = yield select(makeSelectHarvestData());
  const initProductConfig = yield select(makeSelectInitProductConfig());
  // added below for Futura in-force journey
  if(myProductConfig.partialWithdrawal){
    //add max partial surrender value
    myProductConfig.maxPartialWithdrawal = harvestData?.policyDetail?.maxPartialSurrender;
    //if partial withdrawal then set benefits from harvest
    myProductConfig.coverages = initProductConfig.coverages;
  }
  // for Paid up policies changes where by default future premium will be 0
  if(initProductConfig.policyStatus == 'C'){
    let sumPremium =
    myProductConfig.regularPremiumSlices.reduce(
      (accumulator, currentValue) => accumulator + currentValue.annualPremium,
      0,
    );
    myProductConfig.regularPremiumSlices[
      myProductConfig.regularPremiumSlices.length - 1
    ].annualPremium = -sumPremium;
  } 
  const endpoint = `${api.host}/api/v${api.version}/b2b/inforce/recalculate-sustainability`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.sustainabilityYears) {
      yield put(setSustainabilityYearsAction(response.sustainabilityYears));
      yield put(setPolicyTermHarvestAction(response.sustainabilityYears));
    } else {
      yield put(setErrorAction({ error: true, message: response.errorList }));
    }
  } catch (err) {
    console.log('err recalculateSustainability', err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* downloadHealthCheckPdfAction({ payload }) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());

  //  for Paid up policies changes where by default future premium will be 0
  if(myProductConfig.policyStatus == 'C'){
    let sumPremium =
    myProductConfig.regularPremiumSlices.reduce(
      (accumulator, currentValue) => accumulator + currentValue.annualPremium,
      0,
    );
    myProductConfig.regularPremiumSlices[
      myProductConfig.regularPremiumSlices.length - 1
    ].annualPremium = -sumPremium;
  } 
  // End Paid up policy changes
  const offerCodeData = yield select(makeSelectOfferCodeData());
  const myBody = calculateBody(lifeAssured, myProductConfig, offerCodeData);
  const endpoint = `${api.host}/api/v${api.version}/b2b/inforce/generate-bi-pdf`;
  myBody.productConfigs.growthRate = payload.growthRate;
  myBody.productConfigs.policyCoverTerm = 'CustomPremium';
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response) {
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Illustration.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  } catch (err) {
    console.log('err downloadHealthCheckPdfAction', err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* calculateQuote() {
  yield put(setAppLoadingAction(true));
  yield put(setBiTableValuesaAction({}));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/calculate`;
  const policyTerm = yield select(makeSelectPolicyTerm());
  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const offerCodeData = yield select(makeSelectOfferCodeData());
  const harvestData = yield select(makeSelectHarvestData());
  // added for partial withdrawal in Futura in-force journey
  const initProductConfig = yield select(makeSelectInitProductConfig());
  console.log('myProductConfig before calculate quote',myProductConfig)
  console.log('initProductConfig before calculate quote',initProductConfig)
  if(myProductConfig.partialWithdrawal){
    //add max partial surrender value
    myProductConfig.maxPartialWithdrawal = harvestData?.policyDetail?.maxPartialSurrender;
    //if partial withdrawal then set benefits from harvest
    myProductConfig.coverages = initProductConfig.coverages;
  }
  console.log('myProductConfig before calculate quote',1)
  
  if (myProductConfig.isGetPremium) {
    
    if (myProductConfig.premium < myProductConfig.exisitingPremium) {
      myProductConfig.singlePremiumSlices[myProductConfig.singlePremiumSlices.length - 1].annualPremium -= myProductConfig.exisitingPremium - myProductConfig.premium
    }
    
    if(myProductConfig.rpAsp == 1){
      yield put(setLastActionEditQuote(ACTION_EDIT_QUOTE.GET_ASP_WOL));
    } else {
      yield put(setLastActionEditQuote(ACTION_EDIT_QUOTE.GET_PREMIUM_WOL));
    }

    if (myProductConfig.rpAsp != 1) {
      
      myProductConfig.regularPremiumSlices[
        myProductConfig.regularPremiumSlices.length - 1
      ].annualPremium = 0;
    }
  }

  if (myProductConfig.isCalculateSustainability) {

    if (myProductConfig.premium < myProductConfig.exisitingPremium) {
      myProductConfig.singlePremiumSlices[myProductConfig.singlePremiumSlices.length - 1].annualPremium -= myProductConfig.exisitingPremium - myProductConfig.premium
    }

    if (myProductConfig.isMerged === true) {
      yield put(
        setASPAction(myProductConfig.mergeASP)
      );
    }

    yield put(setLastActionEditQuote(ACTION_EDIT_QUOTE.GET_SUSTAIN_AGE));
  }

  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    offerCodeData,
    policyTerm,
  );
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      if (productCodeCheck(6, myProductConfig.productCode)) {
        yield put(setBiTableValuesaAction(response.data.BITableValues));
      }
      yield put(setAppLoadingAction(false));
      if (response.data.isValid) {
        if (myProductConfig.isGetPremium) {
          const initPremium = getInitPremiumByFrequency(
            myProductConfig.singlePremiumTerm > 0 ? myProductConfig.singlePremiumSlices : myProductConfig.regularPremiumSlices,
            myProductConfig.paymentFrequency,
          );
          yield put(setManualPremiumAction(false));
          if (myProductConfig.singlePremiumTerm >= 0 && myProductConfig.regularPremiumSliceLength === 0) {
            myProductConfig.additionalSinglePremium = response.data.response.additionalSinglePremium;

            if (myProductConfig.isMerged === true) {
              yield put(
                setASPAction(response.data.response.annualizedPremium + initPremium)
              );
            } else {
              if (response.data.response.premium < myProductConfig.exisitingPremium) {
                yield put(
                  setASPAction('Sufficient Premium'),
                );
              } else {
                yield put(
                  setASPAction(response.data.response.additionalSinglePremium + initPremium),
                );
              }
            }
          } else {
            if(myProductConfig.rpAsp == 1) {
              myProductConfig.additionalSinglePremium = response.data.response.asp;
              yield put(
                setASPAction(response.data.response.asp),
              );
              yield put(
                setSustainabilityYearsAction("WholeOfLife"),
              );
              // yield put(
              //   setCalculateAction(initPremium),
              // );
            } else {
              yield put(
                setCalculateAction(response.data.response.premium + parseFloat(initPremium.toFixed(2))),
              );
            }
          }
        } else {
          yield put(
            setSustainabilityYearsAction(response.data.response.premium),
          );

        }
        // Below functionality is added for Futura in-force policies
        if(myProductConfig.partialWithdrawal) {
          //update benefits
          const updatedCoverages = getUpatedCoverageFromPricingEngine(response.data.inputChanged['Insured Details']);
          let newCoverages = myProductConfig.coverages;
          newCoverages.forEach(coverage => {
            if(coverage.id === 'LFC'){
              coverage.valueInsuredOne =  updatedCoverages.life1.LFC;
              coverage.valueInsuredTwo =  updatedCoverages.life2.LFC;
            }
            if(coverage.id === 'CRI') {
              coverage.valueInsuredOne =  updatedCoverages.life1.CRI;
              coverage.valueInsuredTwo =  updatedCoverages.life2.CRI;
            }

            if(coverage.id === 'PTD') {
              coverage.valueInsuredOne =  updatedCoverages.life1.PTD;
              coverage.valueInsuredTwo =  updatedCoverages.life2.PTD;
            }

          });

          //newCoverages[0].LFC = 449000;
         // myProductConfig.coverages[0].LFC = 449000;
          yield put(setCoveragesListAction(newCoverages));
          
          console.log(newCoverages);
        }

        yield put(setErrorAction({ error: false, message: [] }));
      } else {
        yield put(
          setErrorAction({ error: true, message: response.data.errorList }),
        );
      }
    }
  } catch (err) {
    console.log('err', err);
    yield put(setAppLoadingAction(false));
    if (err.response) {
      const response = yield err.response.json();
      if (response.message && response.error)
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.message }],
          }),
        );
      else
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.errorCode }],
          }),
        );
    } else {
    }
  }
}

export function* calculateCheckPremiumQuote() {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/calculate`;
  const policyTerm = yield select(makeSelectPolicyTerm());
  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const offerCodeData = yield select(makeSelectOfferCodeData());
  const harvestData = yield select(makeSelectHarvestData());
  const initProductConfig = yield select(makeSelectInitProductConfig());
  if(myProductConfig.partialWithdrawal){ // added for Future in-force policy
    //add max partial surrender value
    myProductConfig.maxPartialWithdrawal = harvestData?.policyDetail?.maxPartialSurrender;
    //if partial withdrawal then set benefits from harvest
    //myProductConfig.coverages = initProductConfig.coverages;  //commented this to avoid quote creation time issue with respect to review screen
  }
  
  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    offerCodeData,
    policyTerm,
  );
  myBody.productConfigs.policyCoverTerm = 'WholeOfLife';

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      if (response.data.isValid) {
        const initPremium = getInitPremiumByFrequency(
          myProductConfig.regularPremiumSlices,
          myProductConfig.paymentFrequency,
        );

        return response.data.response.premium + initPremium;
      }
    }
  } catch (err) {
    console.log('err', err);
    if (err.response) {
      const response = yield err.response.json();
      if (response.message && response.error)
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.message }],
          }),
        );
      else
        yield put(
          setErrorAction({
            error: true,
            message: [{ message: response.errorCode }],
          }),
        );
    }
  }
}

// Individual exports for testing
export default function* ifiIllustrationPageSaga() {
  yield all([
    takeLatest(CREATE_QUOTE_ACTION, createQuote),
    takeLatest(CALCULATE_QUOTE_ACTION, calculateQuote),
    takeLatest(UPDATE_STATUS_ACTION, updateQuoteStatus),
    takeLatest(GET_QUOTE_ACTION, getQuote),
    takeLatest(EMAIL_PDF_ACTION, emailQuote),
    takeLatest(CREATE_APPLICATION_ACTION, createApplication),
    takeLatest(CHANGE_COVERAGE_ACTION, handleUpdateCoverage),
    takeLatest(GET_FIA_ACTION, getFiaCharges),
    takeLatest(CLONE_QUOTE_ACTION, cloneQuote),
    takeLatest(RECALCULATE_SUSTAINABILITY_ACTION, recalculateSustainability),
    takeLatest(DOWNLOAD_HEALTH_CHECK_PDF_ACTION, downloadHealthCheckPdfAction),
  ]);
}

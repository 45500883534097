import { defineMessages } from 'react-intl';

export const scope = 'app.containers.App';

export default defineMessages({
  appUpdatingMessage: {
    id: `${scope}.appUpdatingMessage`,
    defaultMessage: `Please wait. We are applying new updates...`,
  },
  legalAge: {
    id: `${scope}.legalAge`,
    defaultMessage: `legal age is atleast 18`,
  },
  appReloading: {
    id: `${scope}.appReloading`,
    defaultMessage: `Reloading app...`,
  },
  male: {
    id: `${scope}.male`,
    defaultMessage: `Male`,
  },
  female: {
    id: `${scope}.female`,
    defaultMessage: `Female`,
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: `Yes`,
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: `No`,
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: `Confirm`,
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: `Continue`,
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: `Select`,
  },
  previous: {
    id: `${scope}.previous`,
    defaultMessage: 'Previous',
  },
  exit: {
    id: `${scope}.exit`,
    defaultMessage: 'Exit',
  },
  next: {
    id: `${scope}.next`,
    defaultMessage: `Next`,
  },
  agreeAndProceed: {
    id: `${scope}.agreeAndProceed`,
    defaultMessage: 'Agree & proceed',
  },
  saveQuote: {
    id: `${scope}.saveQuote`,
    defaultMessage: 'Save this quote',
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: `Send`,
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Title',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: 'First name',
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: 'Last name',
  },
  familyName: {
    id: `${scope}.familyName`,
    defaultMessage: 'Family name',
  },
  dob: {
    id: `${scope}.dob`,
    defaultMessage: 'Date of birth',
  },
  dateOfIncorporation: {
    id: `${scope}.dateOfIncorporation`,
    defaultMessage: 'Date of incorporation',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: `Email address`,
  },
  emailIfApplicable: {
    id: `${scope}.emailIfApplicable`,
    defaultMessage: `Email address (if applicable)`,
  },
  isSmoker: {
    id: `${scope}.isSmoker`,
    defaultMessage: 'Are you a smoker (Tobacco/Nicotine user)?',
  },
  nationality: {
    id: `${scope}.nationality`,
    defaultMessage: 'Nationality',
  },
  thisIsPolicyOwner: {
    id: `${scope}.thisIsPolicyOwner`,
    defaultMessage: 'This person is also the policy owner',
  },
  productYourLife: {
    id: `${scope}.productYourLife`,
    defaultMessage: `YourLife`,
  },
  fieldPlaceholder: {
    id: `${scope}.fieldPlaceholder`,
    defaultMessage: 'Please enter',
  },
  pleaseEnterValue: {
    id: `${scope}.pleaseEnterValue`,
    defaultMessage: `Please enter value`,
  },
  pleaseProvideDetails: {
    id: `${scope}.pleaseProvideDetails`,
    defaultMessage: `Please provide Details`,
  },
  emailInvalid: {
    id: `${scope}.emailInvalid`,
    defaultMessage: `Invalid email address`,
  },
  dateInvalid: {
    id: `${scope}.dateInvalid`,
    defaultMessage: `Invalid date`,
  },
  saveForLater: {
    id: `${scope}.saveForLater`,
    defaultMessage: 'Save for later',
  },
  pleaseSelectValue: {
    id: `${scope}.pleaseSelectValue`,
    defaultMessage: `Please select a value`,
  },
  minValueLimit: {
    id: `${scope}.minValueLimit`,
    defaultMessage: `Minimum is {value} years`,
  },
  maxValueLimit: {
    id: `${scope}.maxValueLimit`,
    defaultMessage: `Maximum is {value} years`,
  },
  maxValueMonthLimit: {
    id: `${scope}.maxValueMonthLimit`,
    defaultMessage: `Maximum is {value} months`,
  },
  maxTermLimit: {
    id: `${scope}.maxTermLimit`,
    defaultMessage: `Terms can not be more than Premium payment term.`,
  },
  minAmountLimit: {
    id: `${scope}.minAmountLimit`,
    defaultMessage: `Minimum coverage is {value}`,
  },
  maxAmountLimit: {
    id: `${scope}.maxAmountLimit`,
    defaultMessage: `Maximum coverage is {value}`,
  },
  minLimit: {
    id: `${scope}.minLimit`,
    defaultMessage: `Minimum is {value}`,
  },
  maxLimit: {
    id: `${scope}.maxLimit`,
    defaultMessage: `Maximum is {value}`,
  },
  dateValidate: {
    id: `${scope}.dateValidate`,
    defaultMessage: `Policy Start Date can only be back-dated or future-dated up to 2 months.`,
  },
  atleastOnePolicyOwner: {
    id: `${scope}.atleastOnePolicyOwner`,
    defaultMessage: `There must be at least one policy owner.`,
  },
  VPR0081: {
    id: `${scope}.VPR0081`,
    defaultMessage:
      'The provided entry age for the life assured does not meet the minimum entry age.',
  },
  VPR0082: {
    id: `${scope}.VPR0082`,
    defaultMessage:
      'The provided entry age for the life assured does not meet the maximum entry age.',
  },
  tryAgain: {
    id: `${scope}.tryAgain`,
    defaultMessage: 'Try again',
  },
  callZurichContactNo: {
    id: `${scope}.callZurichContactNo`,
    defaultMessage: `Call us on 800 987 424`,
  },
  learnMore: {
    id: `${scope}.learnMore`,
    defaultMessage: `Learn more`,
  },
  invalidDate: {
    id: `${scope}.invalidDate`,
    defaultMessage: `Invalid date format`,
  },
  invalidName: {
    id: `${scope}.invalidName`,
    defaultMessage: 'Name cannot contain numeric characters',
  },
  invalidCreditCard: {
    id: `${scope}.invalidCreditCard`,
    defaultMessage: 'Please enter a valid card number',
  },
  invalidPhoneNumber: {
    id: `${scope}.invalidPhoneNumber`,
    defaultMessage: 'Please enter a valid phone number',
  },
  numbersAreNotAllowed: {
    id: `${scope}.numbersAreNotAllowed`,
    defaultMessage: 'Numbers are not allowed',
  },
  username: {
    id: `${scope}.username`,
    defaultMessage: 'Username',
  },
  pwdLabel: {
    id: `${scope}.pwdLabel`,
    defaultMessage: 'Password',
  },
  errSessionExpired: {
    id: `${scope}.errSessionExpired`,
    defaultMessage: `Your session has expired. Please login again.`,
  },
  errSSOUserIsNotAuthorized: {
    id: `${scope}.errSSOUserIsNotAuthorized`,
    defaultMessage: `Your session is currently expired. Please open a new browser tab to continue`,
  },
  errSomethingWrong: {
    id: `${scope}.errSomethingWrong`,
    defaultMessage: `Error: Something went wrong. Please try again.`,
  },
  pleaseEnter: {
    id: `${scope}.pleaseEnter`,
    defaultMessage: 'Please enter',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
  relationWithLifeInsured: {
    id: `${scope}.relationWithLifeInsured`,
    defaultMessage: 'Relationship to life insured',
  },
  sharePercentage: {
    id: `${scope}.sharePercentage`,
    defaultMessage: 'Share percentage %',
  },
  addAssignee: {
    id: `${scope}.addAssignee`,
    defaultMessage: 'Add another assignee',
  },
  addConditionalAssignee: {
    id: `${scope}.addConditionalAssignee`,
    defaultMessage: 'Add another conditional assignee',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  reasonForReassignment: {
    id: `${scope}.reasonForReassignment`,
    defaultMessage: 'Reason for assignment',
  },
  warning: {
    id: `${scope}.warning`,
    defaultMessage: 'Warning',
  },
  lifeAssuredTitle: {
    id: `${scope}.lifeAssuredTitle`,
    defaultMessage: 'Edit details',
  },
  coverageTitle: {
    id: `${scope}.coverageTitle`,
    defaultMessage: 'Coverage',
  },
  benefitRatingTitle: {
    id: `${scope}.benefitRatingTitle`,
    defaultMessage: 'Benefit rating',
  },
  quoteTitle: {
    id: `${scope}.quoteTitle`,
    defaultMessage: 'Quote',
  },
  reviewTitle: {
    id: `${scope}.reviewTitle`,
    defaultMessage: 'Review',
  },
  perMile: {
    id: `${scope}.perMile`,
    defaultMessage: 'Per mille',
  },
  perPercentage: {
    id: `${scope}.perPercentage`,
    defaultMessage: 'Percentage',
  },
  isBenefitRating: {
    id: `${scope}.isBenefitRating`,
    defaultMessage: 'Add benefit rating to this quote?',
  },
  maximumLength: {
    id: `${scope}.maximumLength`,
    defaultMessage: 'Maximum characters of {value1} exceeded',
  },
  maximumLengthDigits: {
    id: `${scope}.maximumLengthDigits`,
    defaultMessage: 'Maximum digits of {value1} exceeded',
  },
  tooltipInsured: {
    id: `${scope}.tooltipInsured`,
    defaultMessage:
      'Use of tobacco or nicotine includes the following: cigarettes including hand-rolled unfiltered cigarette- variants, cigars, pipes, dokha (midwakh), smokeless (chewing or snuff), shisha and tobacco-free nicotine delivery (nicotine gums, e-cigarettes) among others in the last 12 months.',
  },
  minAgeValidation: {
    id: `${scope}.minAgeValidation`,
    defaultMessage: 'Minimum age is {value1}.',
  },
  maxAgeValidation: {
    id: `${scope}.maxAgeValidation`,
    defaultMessage: 'Maximum Age is {value1}.',
  },
  minAgePoValidation: {
    id: `${scope}.minAgePoValidation`,
    defaultMessage: 'Minimum age for policy owner is {value1}.',
  },
  accelerateValidation: {
    id: `${scope}.accelerateValidation`,
    defaultMessage: 'Coverage amount cannot exceed Life Cover.',
  },
  naMessage: {
    id: `${scope}.naMessage`,
    defaultMessage: 'N/A',
  },
  futureDates: {
    id: `${scope}.futureDates`,
    defaultMessage: 'Future dates cannot be entered',
  },
  additionalFetures: {
    id: `${scope}.additionalFetures`,
    defaultMessage: 'Additional features?',
  },
  nil: {
    id: `${scope}.nil`,
    defaultMessage: 'Nil',
  },
  annualPercentage: {
    id: `${scope}.annualPercentage`,
    defaultMessage: 'Annual percentage',
  },
  fiaText: {
    id: `${scope}.fiaText`,
    defaultMessage: 'Investment advisor fee?',
  },
  advisorFeeLabel: {
    id: `${scope}.advisorFeeLabel`,
    defaultMessage: 'Fund investment advisor fee',
  },
  FUTU5: {
    id: `${scope}.FUTU5`,
    defaultMessage: 'Futura',
  },
  ITAS6: {
    id: `${scope}.ITAS6`,
    defaultMessage: 'ITA',
  },
  DTME2: {
    id: `${scope}.DTME2`,
    defaultMessage: 'IDTA',
  },
  MFS01: {
    id: `${scope}.MFS01`,
    defaultMessage: 'Global Choice',
  },
  RSP01: {
    id: `${scope}.RSP01`,
    defaultMessage: 'Legacy',
  },
  WAPL2: {
    id: `${scope}.WAPL2`,
    defaultMessage: 'WAP',
  },
  wealthAccumulationPlan: {
    id: `${scope}.wealthAccumulationPlan`,
    defaultMessage: 'Wealth Accumulation Plan',
  },
  SIMW2: {
    id: `${scope}.SIMW2`,
    defaultMessage: 'SW',
  },
  simpleWealth: {
    id: `${scope}.simpleWealth`,
    defaultMessage: 'Simple Wealth',
  },
  onlyAlphanumericCharacters: {
    id: `${scope}.onlyAlphanumericCharacters`,
    defaultMessage: 'Only alphanumeric characters',
  },
  descriptionInterestRate: {
    id: `${scope}.descriptionInterestRate`,
    defaultMessage:
      'Interest rate to calculate reduction in life cover amount. Percentage up to one decimal place.',
  },
  dateValidation: {
    id: `${scope}.dateValidation`,
    defaultMessage: `Only available after {value}.`,
  },
  dateValidation2: {
    id: `${scope}.dateValidation2`,
    defaultMessage: `Start date can only be 15th day of month.`,
  },
  dateValidation3: {
    id: `${scope}.dateValidation3`,
    defaultMessage: `Start date can only be 1st day of month.`,
  },
  minEndDateValidation: {
    id: `${scope}.minEndDateValidation`,
    defaultMessage: `Withdrawal End date cannot be lower than withdrawal start date.`,
  },
  maxEndDateValidation: {
    id: `${scope}.maxEndDateValidation`,
    defaultMessage: `Withdrawal End date cannot be greater than 100 years.`,
  },
  minWithdrawalValidation: {
    id: `${scope}.minWithdrawalValidation`,
    defaultMessage: `Minimum is {value}.`,
  },
  maxWithdrawalValidation: {
    id: `${scope}.maxWithdrawalValidation`,
    defaultMessage: `Maximum is {value}.`,
  },

  numberWithdrawalValidation: {
    id: `${scope}.numberWithdrawalValidation`,
    defaultMessage: `Please provide withdrawal amount`,
  },

  cannotBeLessThanZero: {
    id: `${scope}.cannotBeLessThanZero`,
    defaultMessage: `cannot be less than zero`,
  },
  minLimitGrowthRate: {
    id: `${scope}.minLimitGrowthRate`,
    defaultMessage: `Please provide positive growth rate.`,
  },
  specialCharactersNotAllowed: {
    id: `${scope}.specialCharactersNotAllowed`,
    defaultMessage: `Special characters are not allowed`,
  },
  specialCharactersAndNumbersNotAllowed: {
    id: `${scope}.specialCharactersNotAllowed`,
    defaultMessage: `Special characters and numbers are not allowed`,
  },
  negativeValuesNotAllowed: {
    id: `${scope}.negativeValuesNotAllowed`,
    defaultMessage: 'Negative values are not allowed',
  },
  decimalValuesNotAllowed: {
    id: `${scope}.decimalValuesNotAllowed`,
    defaultMessage: 'Decimal values are not allowed',
  },
  releaseVersion: {
    id: `${scope}.releaseVersion`,
    defaultMessage: 'Release {version}',
  },
  pleaseFillInThePremium: {
    id: `${scope}.pleaseFillInThePremium`,
    defaultMessage: 'Please fill in the premium',
  },
  pleaseSelectCurrency: {
    id: `${scope}.pleaseSelectCurrency`,
    defaultMessage: 'Please select the currency',
  },
  pleaseSelectPremiumTerm: {
    id: `${scope}.pleaseSelectTerm`,
    defaultMessage: 'Premium payment term cannot be blank',
  },
  pleaseSelectLifeCover: {
    id: `${scope}.pleaseSelectLifeCover`,
    defaultMessage: 'Life cover value cannot be blank',
  },
  pleaseSelectPolicyTerm: {
    id: `${scope}.pleaseSelectPolicyTerm`,
    defaultMessage: 'Policy term value cannot be blank',
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
  age: {
    id: `${scope}.age`,
    defaultMessage: 'Age',
  },
  countryOfResidence: {
    id: `${scope}.countryOfResidence`,
    defaultMessage: 'Country of residence',
  },
  specialOfferExpired: {
    id: `${scope}.specialOfferExpired`,
    defaultMessage: 'Special Offer Expired. Please generate the quote again',
  },
  badRequest: {
    id: `${scope}.badRequest`,
    defaultMessage: 'Something wrong!',
  },
  policyDetailFail: {
    id: `${scope}.policyDetailFail`,
    defaultMessage: 'POLICY DETAILS IS NOT COMPLETED, PLEASE CHECK AGAIN',
  },
  beneficiaryFail: {
    id: `${scope}.beneficiaryFail`,
    defaultMessage: 'BENEFICIARY IS NOT COMPLETED, PLEASE CHECK AGAIN',
  },
  quoteInvalid: {
    id: `${scope}.quoteInvalid`,
    defaultMessage: 'The quote is not valid please regenerate to be displayed',
  },
  targetFund: {
    id: `${scope}.targetFund`,
    defaultMessage: 'Target fund',
  },
  targetFundValue: {
    id: `${scope}.targetFundValue`,
    defaultMessage: 'Target fund value',
  },
  targetYear: {
    id: `${scope}.targetYear`,
    defaultMessage: 'Target year',
  },
  greaterThanZero: {
    id: `${scope}.greaterThanZero`,
    defaultMessage: 'Value should be greater than 0',
  },
  hasToCalculate: {
    id: `${scope}.hasToCalculate`,
    defaultMessage: 'Please select "Calculate" before proceeding',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Standard cover details',
  },
  specialTerms: {
    id: `${scope}.specialTerms`,
    defaultMessage: 'Special terms',
  },
  permanent: {
    id: `${scope}.permanent`,
    defaultMessage: 'Permanent',
  },
  temporary: {
    id: `${scope}.temporary`,
    defaultMessage: 'Temporary',
  },
  enterValidValue: {
    id: `${scope}.enterValidValue`,
    defaultMessage: 'Please enter a valid date',
  },
  unsupportedChars: {
    id: `${scope}.unsupportedChars`,
    defaultMessage: 'There was Unsupported chars.',
  },
  minMaxSame: {
    id: `${scope}.minMaxSame`,
    defaultMessage: `Allowed value is {value}`,
  }
});

/**
 *
 * FormSelect
 *
 */

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  TextField,
  Divider,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Helper from 'components/Helper';
import { isImmutable } from 'immutable';
import { uniqBy } from 'lodash/array';
import { filter, map } from 'lodash/collection';
import { has } from 'lodash/object';
import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EmptyIcon from 'images/checkbox-blue-empty.svg';
import CheckedIcon from 'images/checkbox-blue-tick.svg';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';
// import TextField from 'components/TextField';

const CustomCheckbox = withStyles({
  root: {
    color: blue[600],
    '&$checked': {
      color: blue[800],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const CustomAutoComplete = withStyles({
  inputRoot: {
    borderRadius: '10px',
  },
})(props => <Autocomplete {...props} />);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  root2: {
    // width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  title: {
    // width: 500,
    fontSize: '14px',
    fontWeight: 800,
  },
  formGroup: { width: '100%' },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    borderRadius: '10px',
    marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  bold: {
    fontWeight: 800,
  },
  title2: {
    fontWeight: 800,
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
}));

const MultipleChoice = ({
  question,
  questions,
  input,
  required,
  meta: { invalid, error, touched, warning },
  helperText,
  children,
  ...custom
}) => {
  const classes = useStyles();
  const [value, setValue] = useState([]);
  const headers = uniqBy(children, 'props.children.props.question.text');
  const noneOfTheAbove = 'NONE_OF_ABOVE_CHOICE_CODE';

  useEffect(() => {
    setValue(getValue2());
  }, [question]);

  let helperMsg = helperText;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  const getValue = () => {
    if (isImmutable(input.value)) {
      return input.value.toJS();
    } else {
      return input.value;
    }
  };

  const getValue2 = () => {
    if (isImmutable(input.value)) {
      return input.value.toJS();
    } else if (input.value.length > 0) {
      return input.value;
    } else {
      return [];
    }
  };

  if (question.constraints?.componentType === 'ListBoxResp') {
    //SEARCH
    return (
      <div className={classes.root2}>
        {isFirstChild(questions, question) && (
          <>
            <Typography variant="h2" className={classes.title2}>
              {question.context[0]}
            </Typography>

            <Divider style={{ margin: '1rem 0' }} />
          </>
        )}
        <Typography className={classes.title} variant="body1">
          {question.text}
        </Typography>
        <CustomAutoComplete
          className={classes.input}
          fullWidth
          multiple
          id="tags-outlined"
          value={value}
          options={question.constraints ? question.constraints?.choices : []}
          getOptionLabel={option => option.text}
          noOptionsText={'Search for a keyword'}
          onChange={(event, value) => {
            return input.onChange(value);
          }}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              error={!!warning || (touched && invalid)}
              helperText={helperMsg}
            />
          )}
        />
        {helperText}
      </div>
    );
  } else {
    return (
      <>
        {isFirstChild(questions, question) && (
          <>
            <Typography variant="h2" className={classes.title2}>
              {question.context[0]}
            </Typography>

            <Divider style={{ margin: '1rem 0' }} />
          </>
        )}
        <FormControl
          error={error}
          component="fieldset"
          className={classes.formControl}
        >
          <br />
          <FormGroup className={classes.formGroup}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography className={classes.title} variant="body1">
                  {question.text}
                  {question.helpText.length > 0 && (
                    <Helper
                      // helperText={'This is a sample text'}
                      helperText={question.helpText}
                    />
                  )}
                </Typography>
              </Grid>
              {/* LABEL AREA OF THE CHILD ELEMENTS */}
              {question.constraints.componentType === 'TableResp' &&
                children.length > 0 && (
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={4} />
                      {map(headers, child => {
                        return (
                          <Grid item xs>
                            <Typography variant="span" className={classes.bold}>
                              {child.props.children.props.question.text}
                            </Typography>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}

              {question.constraints.choices.map((choice, index) => {
                return (
                  <Grid item xs={12} key={index}>
                    <Grid container>
                      <Grid
                        item
                        xs={
                          question.constraints.componentType === 'TableResp'
                            ? 4
                            : 12
                        }
                        wrap="nowrap"
                      >
                        <FormControlLabel
                          key={`checkbox-${index}`}
                          control={
                            <CustomCheckbox
                              name={`${choice.value}`}
                              value={getValue()}
                              checked={has(getValue(), choice.value)}
                              // checked={input.value.indexOf(choice.value) !== -1}
                              icon={<img src={EmptyIcon} alt="people" />}
                              checkedIcon={
                                <img src={CheckedIcon} alt="people" />
                              }
                              onChange={event => {
                                let newValue = { ...getValue() };
                                if (event.target.checked) {
                                  //if none of the above remove the checked checkboxes
                                  if (
                                    choice.value === noneOfTheAbove ||
                                    has(newValue, noneOfTheAbove)
                                  ) {
                                    newValue = {
                                      [`${choice.value}`]: choice,
                                    };
                                  } else {
                                    newValue = {
                                      ...newValue,
                                      [`${choice.value}`]: choice,
                                    };
                                  }
                                } else {
                                  delete newValue[choice.value];
                                  // newValue.splice(newValue.indexOf(choice.value), 1);
                                }

                                return input.onChange(newValue);
                              }}
                            />
                          }
                          label={
                            <Typography variant="body2">
                              {choice.text}
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs>
                        {question.constraints.componentType === 'TableResp' && (
                          <Grid container spacing={1}>
                            {map(
                              filter(children, child => {
                                if (child.props.children.props.question) {
                                  //ADD IF IT DOESNT RETURN ON SPECIFIC QUESTIONT TYPE
                                  if (
                                    child.props.children.props.question.type ===
                                    'NUMERIC'
                                  ) {
                                    return (
                                      child.props.children.props.question
                                        .context[0] === choice.text
                                    );
                                  } else {
                                    //DEFAULT
                                    return (
                                      child.props.children.props.question
                                        .context[1] === choice.text
                                    );
                                  }
                                }
                              }),
                              childElement => {
                                return (
                                  <Grid item xs>
                                    {childElement}
                                  </Grid>
                                );
                              },
                            )}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </FormGroup>

          {/* <FormHelperText>You can display an error</FormHelperText> */}
        </FormControl>
      </>
    );
  }
};

MultipleChoice.propTypes = {};

export default MultipleChoice;

import { setAppLoadingAction } from 'containers/App/actions';
import {
  makeSelectAuth,
  makeSelectOfferCodeData,
} from 'containers/App/selectors';
import { api } from 'environments';
import { isEmpty } from 'lodash';
import { getCoverages2 } from 'containers/RPSavingPage/helper';
import { findIndex } from 'lodash/array';
import numeral from 'numeral';
import { all, call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { change } from 'redux-form';
import request from 'utils/request';
import {
  calculateQuoteAction,
  ChangeCoverageAction,
  ChangeLifeAssuredAction,
  setApplicationIdAction,
  setBiPdfAction,
  setBiPdfAction1,
  setBiTableValuesaAction,
  setBiTableValuesaAction1,
  setCloneStatusAction,
  setEmailErrorMessage,
  setEmailSentAction,
  setErrorAction,
  setFiaAction,
  setQuoteAction,
  setQuoteDetailsAction,
  setCalculateAction,
  SetCoveragesList2Action,
  SetCoverages2Action,
} from './actions';
import {
  CALCULATE_QUOTE_ACTION,
  CLONE_QUOTE_ACTION,
  CREATE_APPLICATION_ACTION,
  CREATE_QUOTE_ACTION,
  EMAIL_PDF_ACTION,
  GET_FIA_ACTION,
  GET_QUOTE_ACTION,
  SET_PRODUCT_CONFIG_ACTION,
  UPDATE_STATUS_ACTION,
} from './constants';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectQuoteId,
} from './selectors';
import { forEach } from 'lodash/collection';
import { makeSelectProductConfig2 } from 'containers/IllustrationPage/selectors';

function calculateBody(
  lifeAssured,
  myProductConfig,
  quoteId,
  isLifeAssuredEqual,
  offerCodeData,
  isGetPremium,
) {
  const partyDetails = [];

  if (isEmpty(quoteId) || quoteId == undefined) {
    delete lifeAssured.insuredOne.prospectId;
    delete lifeAssured.insuredTwo.prospectId;
  }

  if (lifeAssured.insuredOne.isPolicyOwner)
    lifeAssured.insuredOne.isPrimaryPolicyOwner = true;
  else lifeAssured.insuredOne.isPrimaryPolicyOwner = false;

  partyDetails.push(lifeAssured.insuredOne);
  if (lifeAssured.isInsuredTwo) {
    if (
      !lifeAssured.insuredOne.isPolicyOwner &&
      lifeAssured.insuredTwo.isPolicyOwner
    )
      lifeAssured.insuredTwo.isPrimaryPolicyOwner = true;
    else lifeAssured.insuredTwo.isPrimaryPolicyOwner = false;

    partyDetails.push(lifeAssured.insuredTwo);
  }

  if (
    lifeAssured.isPolicyOwnerIndividual &&
    lifeAssured.policyOwnerIndividual.length
  ) {
    lifeAssured.policyOwnerIndividual.forEach((poInd, index) => {
      if (
        index === 0 &&
        !lifeAssured.insuredOne.isPolicyOwner &&
        !lifeAssured.insuredTwo.isPolicyOwner
      )
        poInd.isPrimaryPolicyOwner = true;
      else poInd.isPrimaryPolicyOwner = false;
      partyDetails.push(poInd);
    });
  }

  const myCoverages = [];
  const myCoverageOne = {};
  const myCoverageTwo = {};

  myCoverageOne.isPrimary = true;
  myCoverageTwo.isPrimary = false;

  myProductConfig.coverages.map(product => {
    myCoverageOne[product.id] = {
      selected: product.value,
      name: product.title,
      value:
        product.id !== 'ESC'
          ? product.value
          : product.value
          ? product.possibleValues[1]
          : product.possibleValues[0],
      term: product.term,
    };

    if (lifeAssured.isInsuredTwo) {
      myCoverageTwo[product.id] = {
        selected: product.id === 'WOP' ? false : product.value,
        name: product.title,
        value:
          product.id !== 'ESC'
            ? product.id === 'WOP' //WOP
              ? false
              : product.value
            : product.value //ESC
            ? product.possibleValues[1]
            : product.possibleValues[0],
        term: product.term,
      };
    }
  });
  myCoverages.push(myCoverageOne);

  if (lifeAssured.isInsuredTwo) myCoverages.push(myCoverageTwo);

  myProductConfig.educationFeesWithdrawal.child1.amount = numeral(
    myProductConfig.educationFeesWithdrawal.child1.amount,
  ).value();
  myProductConfig.regularWithdrawal.amount = numeral(
    myProductConfig.regularWithdrawal.amount,
  ).value();
  myProductConfig.regularWithdrawal.percentage = numeral(
    myProductConfig.regularWithdrawal.percentage,
  ).value();

  myProductConfig.educationFeesWithdrawal.selected =
    myProductConfig.isEducationalFeeWithdrawal;
  myProductConfig.regularWithdrawal.selected =
    myProductConfig.isRegularWithdrawal;

  let productConfigs = {};
  delete myProductConfig.educationFeesWithdrawal.child1.isAmountChecked;
  delete myProductConfig.regularWithdrawal.isAmountChecked;

  const productList = [];

  productList.push({
    name: myProductConfig.name,
    productCode: myProductConfig.productCode,
    ptvVersion: myProductConfig.ptvVersion,
    productVersion: myProductConfig.ptvVersion,
    isProtectionProduct: false,
    isInvestmentLinkedProduct: true,
  });

  if (
    myProductConfig.isBundlingQuote &&
    !myProductConfig.getVal &&
    !isGetPremium
  ) {
    const myCoveragesITA = [];
    const myCoverageOneITA = {};
    const myCoverageTwoITA = {};
    myCoverageOneITA.isPrimary = true;
    myCoverageTwoITA.isPrimary = false;
    myProductConfig.coverages2.map(product => {
      let isSelected = false;
      if (product.type === '2') {
        if (lifeAssured.isInsuredTwo)
          isSelected = product.valueInsuredOne || product.valueInsuredTwo;
        else isSelected = product.valueInsuredOne;
      } else isSelected = product.selected;

      myCoverageOneITA[product.id] = {
        selected: isSelected,
        name: product.title,
        value:
          product.type === '1'
            ? numeral(product.valueInsuredOne).value()
            : product.valueInsuredOne,
        permanent: {
          perMille:
            (product.brSelectedInsuredOneValue ||
              product.brSelectedPercentValueOne) &&
            myProductConfig.specialTermsFlag &&
            isSelected
              ? product.perMileOne
              : 0,
          perPercentage:
            (product.brSelectedInsuredOneValue ||
              product.brSelectedPercentValueOne) &&
            myProductConfig.specialTermsFlag &&
            isSelected
              ? product.perPercentageOne
              : 0,
        },
        temporary: {
          perMille:
            myProductConfig.specialTermsFlag && isSelected
              ? numeral(product.perTempMileOne).value()
              : numeral(0).value(),
          perPercentage: numeral(0).value(),
          time:
            myProductConfig.specialTermsFlag && isSelected
              ? numeral(product.perTempMonthOne).value()
              : numeral(0).value(),
        },
      };
      if (lifeAssured.isInsuredTwo) {
        myCoverageTwoITA[product.id] = {
          selected: isSelected,
          name: product.title,
          value:
            product.type === '1'
              ? numeral(product.valueInsuredTwo).value()
              : product.valueInsuredTwo,
          temporary: {
            perMille:
              myProductConfig.specialTermsFlag && isSelected
                ? numeral(product.perTempMileTwo).value()
                : numeral(0).value(),
            perPercentage: numeral(0).value(),
            time:
              myProductConfig.specialTermsFlag && isSelected
                ? numeral(product.perTempMonthTwo).value()
                : numeral(0).value(),
          },
          permanent: {
            perMille:
              (product.brSelectedInsuredTwoValue ||
                product.brSelectedPercentValueTwo) &&
              myProductConfig.specialTermsFlag &&
              isSelected
                ? product.perMileTwo
                : 0,
            perPercentage:
              (product.brSelectedInsuredTwoValue ||
                product.brSelectedPercentValueTwo) &&
              myProductConfig.specialTermsFlag &&
              isSelected
                ? product.perPercentageTwo
                : 0,
          },
        };
      }
    });

    myCoveragesITA.push(myCoverageOneITA);

    if (lifeAssured.isInsuredTwo) myCoveragesITA.push(myCoverageTwoITA);
    productConfigs = [
      {
        name: myProductConfig.name,
        productCode: myProductConfig.productCode,
        ptvVersion: myProductConfig.ptvVersion,
        productVersion: myProductConfig.ptvVersion,
        currency: myProductConfig.currency,
        term: numeral(myProductConfig.term).value(),
        paymentFrequency: myProductConfig.paymentFrequency,
        premium: numeral(myProductConfig.premium).value(),
        growthRate: numeral(myProductConfig.growthRate).value(),
        startDate: myProductConfig.startDate,
        // offerCodes: [{ ...offerCodeData }],
        offerCode: myProductConfig.offerCode,
        coverages: myCoverages,
        fiaCharge: myProductConfig.isAdditionalFeature
          ? numeral(myProductConfig.fiaCharge).value()
          : 0,
        isAdditionalFeature: myProductConfig.isAdditionalFeature,
        specialTermsFlag: false,
        educationFeesWithdrawal: myProductConfig.educationFeesWithdrawal,
        regularWithdrawal: myProductConfig.regularWithdrawal,
        targetFunctionality: myProductConfig.isGetPremium,
        targetFundEnable: myProductConfig.targetFunctionality,
        targetFund: numeral(myProductConfig.targetFund).value() || 0,
        targetYear:
          numeral(
            myProductConfig.targetYear - new Date().getFullYear(),
          ).value() || 0,
        FMC:
          myProductConfig.FMC === 0
            ? myProductConfig.FMC
            : myProductConfig.FMC || '',
        shortName: myProductConfig.shortName || '',
      },
    ];

    productConfigs.push({
      name: myProductConfig.productConfig2.name,
      productCode: myProductConfig.productConfig2.productCode,
      ptvVersion: myProductConfig.productConfig2.productVersion,
      productVersion: myProductConfig.productConfig2.productVersion,
      currency: myProductConfig.currency,
      term: numeral(myProductConfig.term).value(),
      paymentFrequency: myProductConfig.paymentFrequency,
      // premium: numeral(myProductConfig.premium).value(),
      // growthRate: numeral(myProductConfig.growthRate).value(),
      startDate: myProductConfig.startDate,
      // offerCodes: [{ ...offerCodeData }],
      // offerCode: myProductConfig.offerCode,
      coverages: myCoveragesITA,
      fiaCharge: myProductConfig.isAdditionalFeature
        ? numeral(myProductConfig.fiaCharge).value()
        : 0,
      isAdditionalFeature: myProductConfig.isAdditionalFeature,
      specialTermsFlag: myProductConfig.specialTermsFlag,
      educationFeesWithdrawal: myProductConfig.educationFeesWithdrawal,
      // regularWithdrawal: myProductConfig.regularWithdrawal,
    });

    productList.push({
      name: myProductConfig.productConfig2.name,
      productCode: myProductConfig.productConfig2.productCode,
      ptvVersion: myProductConfig.productConfig2.productVersion,
      productVersion: myProductConfig.productConfig2.productVersion,
      isProtectionProduct: true,
      isInvestmentLinkedProduct: false,
    });
  } else {
    productConfigs = {
      name: myProductConfig.name,
      productCode: myProductConfig.productCode,
      ptvVersion: myProductConfig.ptvVersion,
      productVersion: myProductConfig.ptvVersion,
      currency: myProductConfig.currency,
      term: numeral(myProductConfig.term).value(),
      paymentFrequency: myProductConfig.paymentFrequency,
      premium: numeral(myProductConfig.premium).value() || 0,
      growthRate: numeral(myProductConfig.growthRate).value(),
      startDate: myProductConfig.startDate,
      // offerCodes: [{ ...offerCodeData }],
      offerCode: myProductConfig.offerCode,
      coverages: myCoverages,
      fiaCharge: myProductConfig.isAdditionalFeature
        ? numeral(myProductConfig.fiaCharge).value()
        : 0,
      isAdditionalFeature: myProductConfig.isAdditionalFeature,
      specialTermsFlag: myProductConfig.specialTermsFlag,
      educationFeesWithdrawal: myProductConfig.educationFeesWithdrawal,
      regularWithdrawal: myProductConfig.regularWithdrawal,
      targetFunctionality: myProductConfig.isGetPremium,
      targetFundEnable: myProductConfig.targetFunctionality,
      targetFund: numeral(myProductConfig.targetFund).value() || 0,
      targetYear:
        numeral(
          myProductConfig.targetYear - new Date().getFullYear(),
        ).value() || 0,
      FMC:
        myProductConfig.FMC === 0
          ? myProductConfig.FMC
          : myProductConfig.FMC || '',
      shortName: myProductConfig.shortName || '',
    };
  }

  const requestBody = {};

  if (!isEmpty(quoteId) && quoteId !== undefined) {
    requestBody.id = quoteId;
    requestBody.partyDetails = partyDetails;
    requestBody.productConfigs = productConfigs;
    requestBody.noUpdatePartyDetails = isLifeAssuredEqual;
    requestBody.productList = productList;
  } else {
    requestBody.partyDetails = partyDetails;
    requestBody.productConfigs = productConfigs;
    requestBody.noUpdatePartyDetails = false;
    requestBody.productList = productList;
  }
  return requestBody;
}

export function* createQuote(action) {
  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote`;

  const isLifeAssuredEqual = action.payload;

  const quoteId = yield select(makeSelectQuoteId());

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const auth = yield select(makeSelectAuth());
  const offerCodeData = yield select(makeSelectOfferCodeData());

  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    quoteId,
    isLifeAssuredEqual,
    offerCodeData,
  );

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify({
      isBundlingQuote: myProductConfig?.isBundlingQuote || false,
      ...myBody,
    }),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setQuoteAction(response.data));
      yield put(setAppLoadingAction(false));
      yield put(setErrorAction({ error: false, message: [] }));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* calculateQuote(action) {
  yield put(setBiTableValuesaAction({}));
  const { isLifeAssuredEqual, isGetPremium } = action.payload;

  const quoteId = yield select(makeSelectQuoteId());

  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/calculate`;

  const lifeAssured = yield select(makeSelectLifeAssured());
  const myProductConfig = yield select(makeSelectProductConfig());
  const auth = yield select(makeSelectAuth());
  const offerCodeData = yield select(makeSelectOfferCodeData());

  const getVal = myProductConfig?.getVal;

  const myBody = calculateBody(
    lifeAssured,
    myProductConfig,
    quoteId,
    isLifeAssuredEqual,
    offerCodeData,
    isGetPremium,
  );
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },

    body: JSON.stringify({
      isBundlingQuote:
        myProductConfig?.getVal || isGetPremium
          ? false
          : myProductConfig?.isBundlingQuote || false,
      ...myBody,
    }),
  };

  try {
    yield put(change('coverageForm', 'isGetPremium', false));
    const response = yield call(request, endpoint, requestOpt);
    // check if bundled product
    if (response.data.length > 0) {
      for (const i in response.data) {
        if (response.data[i].isValid === false) {
          yield put(
            setErrorAction({
              error: true,
              message: response.data[i].errorList.map(item => ({
                message: item.message,
              })),
            }),
          );

          return false;
        }
        // 0 == primary
        // 1 == secondary
        // 2 == total of primary and secondary
        if (i == 1) {
          // ITA
          yield put(setCalculateAction(response.data[i].response.premium));
        } else if (getVal) {
          // this is used for getting the values only and pre-populate the coverage ITA fields
          yield put(setBiTableValuesaAction1(response.data[i].BITableValues));
        } else if (i == 2) {
          // combination of primary and total object
          yield put(
            setBiTableValuesaAction({
              ...response.data[0].BITableValues,
              ...response.data[i].BITableValues,
            }),
          );
          yield put(setCalculateAction(response.data[i].premium));
        }

        yield put(setErrorAction({ error: false, message: [] }));
      }
    } else if (response.data.isValid === false) {
      yield put(
        setErrorAction({
          error: true,
          message: response.data.errorList.map(item => ({
            message: item.message,
          })),
        }),
      );
    } else if (getVal) {
      // this is used for getting the values only and pre-populate the coverage ITA fields
      yield put(setBiTableValuesaAction1(response.data.BITableValues));
    } else {
      if (response.data && isGetPremium) {
        yield put(
          change(
            'coverageForm',
            'premium',
            response.data.response.premium.toFixed(2),
          ),
        );
        yield put(change('coverageForm', 'hasToCalculate', false));
        yield put(
          ChangeCoverageAction(
            {
              ...myProductConfig,
              isGetPremium: false,
              premium: response.data.response.premium.toFixed(2),
              getVal: true,
            },
            true,
            false,
            isLifeAssuredEqual,
          ),
        );
      } else {
        yield put(setBiTableValuesaAction(response.data.BITableValues));
        yield put(setCalculateAction(0));
      }
    }
  } catch (err) {
    console.log('err', err);
    const response = yield err.response.json();
    if (response) {
      if (response.error) {
        if (response?.data) {
          yield put(
            setErrorAction({
              error: true,
              message: response.data.response.errorList.map(item => ({
                message: item.message,
              })),
            }),
          );
        }
      }
    }
    // if (err.response) {
    //   const response = yield err.response.json();
    //   if (response.message && response.error)
    //     yield put(
    //       setErrorAction({
    //         error: true,
    //         message: [{ message: response.message }],
    //       }),
    //     );
    //   else
    //     yield put(
    //       setErrorAction({
    //         error: true,
    //         message: [{ message: response.errorCode }],
    //       }),
    //     );
    // } else {
    // }
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* updateQuoteStatus(action) {
  yield put(setAppLoadingAction(true));
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/status`;

  const auth = yield select(makeSelectAuth());

  const quoteId = yield select(makeSelectQuoteId());
  let myBody = {};
  if (action.payload === 1) {
    myBody = {
      quoteId,
      isPreview: true,
    };
  }

  if (action.payload === 2) {
    myBody = {
      quoteId,
      isAgree: true,
    };
  }

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (
      !response.error &&
      !response?.data?.missingKFS &&
      !response?.data?.productVersionOutOfDate
    ) {
      yield call(getQuote);
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
      let { message } = response;
      if (response.data.missingKFS) {
        message = 'The Quote is invalid. Please regenerate new one.';
      }

      if (response?.data?.productVersionOutOfDate) {
        message = 'The Quote is outdated. Please regenerate new one.';
      }

      yield put(
        setErrorAction({
          error: true,
          message: [{ message }],
        }),
      );
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getQuote(action) {
  yield put(setAppLoadingAction(true));

  const id = action?.quoteId;
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const productConfig2 = yield select(makeSelectProductConfig2());
  yield delay(1000);
  let endpoint = ``;
  if (!isEmpty(id)) {
    endpoint = `${api.host}/api/v${api.version}/b2b/quote/${id}`;
  } else {
    endpoint = `${api.host}/api/v${api.version}/b2b/quote/${quoteId}`;
  }

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    if (response.data) {
      if (response.data?.isBundlingQuote) {
        if (isEmpty(id)) {
          // BUNDLED QUOTE
          yield put(setErrorAction({ error: false, message: [] }));
          yield put(setAppLoadingAction(false));
          if (
            (action && action.payload !== undefined) ||
            response.data.status === 'PREVIEW' ||
            response.data.status === 'AGREED'
          ) {
            const lifeInsuredData = {};
            lifeInsuredData.policyOwnerIndividual = [];
            response.data.prospects.forEach(productData => {
              const partyDetails = {
                prospectId: productData.id,
                title: productData.title,
                firstName: productData.firstName,
                lastName: productData.lastName,
                dateOfBirth: productData.dateOfBirth,
                nationality: productData.nationality,
                gender: productData.gender.toString(),
                isSmoke: productData.isSmoke,
                isLifeInsured:
                  productData.prospectquoterelationships.isLifeInsured,
                isPrimaryLifeInsured:
                  productData.prospectquoterelationships.isPrimaryLifeInsured,
                isPolicyOwner:
                  productData.prospectquoterelationships.isPolicyOwner,
                relationshipToLifeInsured:
                  productData.relationshipToLifeInsured,
              };
              if (
                partyDetails.isLifeInsured &&
                partyDetails.isPrimaryLifeInsured
              ) {
                lifeInsuredData.insuredOne = partyDetails;
              }

              if (
                partyDetails.isLifeInsured &&
                partyDetails.isPrimaryLifeInsured === false
              ) {
                lifeInsuredData.isInsuredTwo = true;
                lifeInsuredData.insuredTwo = { ...partyDetails };
              }

              if (!partyDetails.isLifeInsured) {
                lifeInsuredData.isPolicyOwnerIndividual = true;
                lifeInsuredData.policyOwnerIndividual.push(partyDetails);
              }
            });
            yield put(ChangeLifeAssuredAction(lifeInsuredData));
            const existingProductConfigData = yield select(
              makeSelectProductConfig(),
            );

            const data = {};

            if (response.data.productConfigs.length > 0) {
              data.productList = response.data.productList;
              data.isBundlingQuote = response.data.isBundlingQuote;
              data.pricingData = response.data.pricingData;
              data.name = response.data.productConfigs[0].name;
              data.productCode = response.data.productConfigs[0].productCode;
              data.ptvVersion = response.data.productConfigs[0].ptvVersion;
              data.specialTermsFlag =
                response.data?.productConfigs[1]?.specialTermsFlag;
              data.fiaCharge = response.data.productConfigs[0].fiaCharge;
              data.isAdditionalFeature =
                response.data.productConfigs[0].isAdditionalFeature;
              data.startDate = response.data.productConfigs[0].startDate;
              data.currency = response.data.productConfigs[0].currency;
              data.term = response.data.productConfigs[0].term;
              data.growthRate = response.data.productConfigs[0].growthRate;
              data.paymentFrequency =
                response.data.productConfigs[0].paymentFrequency;
              // data.offerCode = offerCodes ? offerCodes.code : '';
              data.offerCode = response.data.productConfigs[0].offerCode;
              data.premium = response.data.productConfigs[0].premium;
              data.educationFeesWithdrawal =
                response.data.productConfigs[0].educationFeesWithdrawal;
              data.regularWithdrawal =
                response.data.productConfigs[0].regularWithdrawal;
              data.isEducationalFeeWithdrawal =
                response.data.productConfigs[0].educationFeesWithdrawal.selected;
              data.isRegularWithdrawal =
                response.data.productConfigs[0].regularWithdrawal.selected;
              data.targetFunctionality =
                response.data.productConfigs[0].targetFundEnable;
              data.targetFund = response.data.productConfigs[0].targetFund;
              data.targetYear = response.data.productConfigs[0].targetFundEnable
                ? response.data.productConfigs[0].targetYear +
                  new Date().getFullYear()
                : null;
              const myCoverages = [];
              var myCoverages2 = [];
              existingProductConfigData.coverages.map(myBenefit => {
                response.data.productConfigs[0].coverages.map(
                  responseBenefit => {
                    const responseObject = responseBenefit[myBenefit.id];
                    if (responseObject && responseBenefit.isPrimary) {
                      myBenefit.selected = responseObject.selected;
                      myBenefit.title = responseObject.name;
                      myBenefit.value = responseObject.value;
                      myBenefit.term = responseObject.term;
                    }
                  },
                );
                myCoverages.push(myBenefit);
              });

              // BUNDLED PRODUCT
              forEach(
                response.data.productConfigs[1].coverages,
                (coverage, i) => {
                  for (const key in coverage) {
                    const responseObject = coverage[key];
                    if (typeof responseObject === 'object') {
                      if (i == 1) {
                        var index = findIndex(myCoverages2, { code: key });

                        myCoverages2.splice(index, 1, {
                          ...myCoverages2[index],
                          value2: responseObject.value,
                        });
                      } else {
                        myCoverages2.push({ ...responseObject, code: key });
                      }
                    }
                  }
                },
              );
              data.coverages = myCoverages;

              if (
                action?.payload !== 2 ||
                response.data.status === 'PREVIEW' ||
                response.data.status === 'AGREED'
              ) {
                const details = {
                  referenceNumber: response.data.referenceNumber,
                  benefitIllustrationPdf: response.data.benefitIllustrationPdf,
                  status: response.data.status,
                };
                yield put(setQuoteDetailsAction(details));
                yield call(getPdf, {
                  name: response.data.benefitIllustrationPdf.split('=')[1],
                  benefit2: false,
                });
                //2nd benefit
                if (
                  !isEmpty(response.data.productList[1].benefitIllustrationPdf)
                ) {
                  yield call(getPdf, {
                    name: response.data.productList[1].benefitIllustrationPdf.split(
                      '=',
                    )[1],
                    benefit2: true,
                  });
                } else {
                  //if the benefitIllustrationPdf is not existing
                  yield call(getQuote, {
                    quoteId: response.data.productList[1].quoteId,
                  });
                }
              }
            }

            yield put(ChangeCoverageAction(data));

            if (!isEmpty(productConfig2)) {
              yield put(
                SetCoveragesList2Action(
                  getCoverages2(productConfig2.features, myCoverages2),
                ),
              );
            } else {
              yield put(SetCoveragesList2Action(myCoverages2));
            }

            //coverages use only for checking if it comes from the API
            yield put(SetCoverages2Action(myCoverages2));
            // yield put(ChangeCoverageAction2(data));
          } else {
            yield call(getPdf, {
              name: response.data.benefitIllustrationPdf.split('=')[1],
              benefit2: true,
            });
          }
        } else {
          //recalling get quote if benefitIllustrationPdf is empty
          if (!isEmpty(response.data.benefitIllustrationPdf)) {
            yield call(getPdf, {
              name: response.data.benefitIllustrationPdf.split('=')[1],
              benefit2: true,
            });
          } else {
            yield call(getQuote, {
              quoteId: response.data.id,
            });
          }
        }
      } else {
        yield put(setErrorAction({ error: false, message: [] }));
        yield put(setAppLoadingAction(false));
        if (action && action.payload !== undefined) {
          const lifeInsuredData = {};
          lifeInsuredData.policyOwnerIndividual = [];
          response.data.prospects.map(productData => {
            const partyDetails = {
              prospectId: productData.id,
              title: productData.title,
              firstName: productData.firstName,
              lastName: productData.lastName,
              dateOfBirth: productData.dateOfBirth,
              gender: productData.gender.toString(),
              isSmoke: productData.isSmoke,
              nationality: productData.nationality,
              isLifeInsured:
                productData.prospectquoterelationships.isLifeInsured,
              isPrimaryLifeInsured:
                productData.prospectquoterelationships.isPrimaryLifeInsured,
              isPolicyOwner:
                productData.prospectquoterelationships.isPolicyOwner,
              relationshipToLifeInsured: productData.relationshipToLifeInsured,
            };
            if (
              partyDetails.isLifeInsured &&
              partyDetails.isPrimaryLifeInsured
            ) {
              lifeInsuredData.insuredOne = partyDetails;
            }

            if (
              partyDetails.isLifeInsured &&
              partyDetails.isPrimaryLifeInsured === false
            ) {
              lifeInsuredData.isInsuredTwo = true;
              lifeInsuredData.insuredTwo = { ...partyDetails };
            }

            if (!partyDetails.isLifeInsured) {
              lifeInsuredData.isPolicyOwnerIndividual = true;
              lifeInsuredData.policyOwnerIndividual.push(partyDetails);
            }
          });
          yield put(ChangeLifeAssuredAction(lifeInsuredData));

          const existingProductConfigData = yield select(
            makeSelectProductConfig(),
          );
          const data = {};
          if (response.data.productConfigs) {
            // const offerCodes =
            //   response.data.productConfigs?.offerCodes &&
            //   response.data.productConfigs?.offerCodes[0];
            // if (offerCodes) {
            //   yield put(
            //     validateOfferCodeSuccessAction({
            //       offerCode: offerCodes.code ? offerCodes.code : '',
            //       discountRate: offerCodes.discountRate
            //         ? offerCodes.discountRate
            //         : 0,
            //       discountMessage: offerCodes.discountMessage
            //         ? offerCodes.discountMessage
            //         : '',
            //       validating: false,
            //     }),
            //   );
            // }
            data.isBundlingQuote = response.data.isBundlingQuote;
            data.name = response.data.productConfigs.name;
            data.productCode = response.data.productConfigs.productCode;
            data.ptvVersion = response.data.productConfigs.ptvVersion;
            data.specialTermsFlag =
              response.data.productConfigs.specialTermsFlag;
            data.fiaCharge = response.data.productConfigs.fiaCharge;
            data.isAdditionalFeature =
              response.data.productConfigs.isAdditionalFeature;
            data.startDate = response.data.productConfigs.startDate;
            data.currency = response.data.productConfigs.currency;
            data.term = response.data.productConfigs.term;
            data.growthRate = response.data.productConfigs.growthRate;
            data.paymentFrequency =
              response.data.productConfigs.paymentFrequency;
            // data.offerCode = offerCodes ? offerCodes.code : '';
            data.offerCode = response.data.productConfigs.offerCode;
            data.premium = response.data.productConfigs.premium;
            data.educationFeesWithdrawal =
              response.data.productConfigs.educationFeesWithdrawal;
            data.regularWithdrawal =
              response.data.productConfigs.regularWithdrawal;
            data.isEducationalFeeWithdrawal =
              response.data.productConfigs.educationFeesWithdrawal.selected;
            data.isRegularWithdrawal =
              response.data.productConfigs.regularWithdrawal.selected;
            data.targetFunctionality =
              response.data.productConfigs.targetFundEnable;
            data.targetFund = response.data.productConfigs.targetFund;
            data.targetYear = response.data.productConfigs.targetFundEnable
              ? response.data.productConfigs.targetYear +
                new Date().getFullYear()
              : null;
            const myCoverages = [];
            existingProductConfigData.coverages.map(myBenefit => {
              response.data.productConfigs.coverages.map(responseBenefit => {
                const responseObject = responseBenefit[myBenefit.id];
                if (responseObject && responseBenefit.isPrimary) {
                  myBenefit.selected = responseObject.selected;
                  myBenefit.title = responseObject.name;
                  myBenefit.value = responseObject.value;
                  myBenefit.term = responseObject.term;
                }
              });
              myCoverages.push(myBenefit);
            });
            data.coverages = myCoverages;

            if (action.payload !== 2) {
              const details = {
                referenceNumber: response.data.referenceNumber,
                benefitIllustrationPdf: response.data.benefitIllustrationPdf,
                status: response.data.status,
              };
              yield put(setQuoteDetailsAction(details));
              yield call(getPdf, {
                name: response.data.benefitIllustrationPdf.split('=')[1],
                benefit2: false,
              });
            }
          }
          yield put(ChangeCoverageAction(data));
        } else {
          const details = {
            referenceNumber: response.data.referenceNumber,
            benefitIllustrationPdf: response.data.benefitIllustrationPdf,
            status: response.data.status,
          };
          yield put(setQuoteDetailsAction(details));
          yield call(getPdf, {
            name: response.data.benefitIllustrationPdf.split('=')[1],
            benefit2: false,
          });
        }
      }
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getPdf(payload) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/storage/create`;

  const updatedPayload = {
    type: 'FILE',
    name: payload.name,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(updatedPayload),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!isEmpty(response)) {
      yield put(setAppLoadingAction(false));
      if (payload.benefit2) {
        yield put(setBiPdfAction1(`${response.previewUrl}`));
      } else {
        yield put(setBiPdfAction(`${response.previewUrl}`));
      }
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* emailQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/bi-pdf-email`;

  const myBody = {
    quoteId,
    email: action.payload,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
    yield put(setEmailSentAction(false));
  } catch (err) {
    const response = yield err.response.json();
    if (response?.code === 'TOO_MANY_REQUESTS') {
      yield put(setEmailSentAction(true));
      yield put(setEmailErrorMessage(response.message));
    }
    yield put(setAppLoadingAction(false));
  }
}

export function* getFiaCharges(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/fia-charge`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!isEmpty(response)) {
      yield put(setAppLoadingAction(false));
      if (!response.error) {
        yield put(setErrorAction({ error: false, message: [] }));
        yield put(setFiaAction(response.data));
        yield put(setAppLoadingAction(false));
      }
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* createApplication() {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application`;

  const myBody = {
    quoteId,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (
      response.data &&
      !response?.data?.missingKFS &&
      !response?.data?.productVersionOutOfDate
    ) {
      yield put(setErrorAction({ error: false, message: [] }));
      yield put(setApplicationIdAction(response.data.id));
      yield put(setAppLoadingAction(false));
    } else {
      let { message } = response;
      if (response?.data?.missingKFS) {
        message = 'The Quote is invalid. Please regenerate new one.';
      }

      if (response?.data?.productVersionOutOfDate) {
        message = 'The Quote is outdated. Please regenerate new one.';
      }

      yield put(
        setErrorAction({
          error: true,
          message: [{ message }],
        }),
      );
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* cloneQuote(action) {
  yield put(setAppLoadingAction(true));
  const quoteId = yield select(makeSelectQuoteId());

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${quoteId}/quote-reference`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setCloneStatusAction(true));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* handleUpdateCoverage(action) {
  const { recalculateQuote, isLifeAssuredEqual, isGetPremium } = action.payload;
  if (recalculateQuote) {
    yield put(calculateQuoteAction({ isLifeAssuredEqual, isGetPremium }));
  }
}

// Individual exports for testing
export default function* rspIllustrationPageSaga() {
  yield all([
    takeLatest(CREATE_QUOTE_ACTION, createQuote),
    takeLatest(CALCULATE_QUOTE_ACTION, calculateQuote),
    takeLatest(UPDATE_STATUS_ACTION, updateQuoteStatus),
    takeLatest(GET_QUOTE_ACTION, getQuote),
    takeLatest(EMAIL_PDF_ACTION, emailQuote),
    takeLatest(CREATE_APPLICATION_ACTION, createApplication),
    takeLatest(SET_PRODUCT_CONFIG_ACTION, handleUpdateCoverage),
    takeLatest(GET_FIA_ACTION, getFiaCharges),
    takeLatest(CLONE_QUOTE_ACTION, cloneQuote),
  ]);
}

import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import { dobFormat } from 'helpers/constants';

/** HELPER */
export function getCoverages(dtaConfig) {
  var coverages = [];
  dtaConfig.productBenefits.mandatoryList.map(benefit => {
    if (!benefit.isComplimentary)
      coverages.push({
        type: benefit.benefitCode === 'WOP' ? '2' : '1',
        selected: benefit.isRequired ? true : false,
        disabled: benefit.isRequired,
        id: benefit.benefitCode,
        title: benefit.benefitName,
        valueInsuredOne: benefit.benefitCode === 'WOP' ? false : '',
        perMileOne: 0,
        perPercentageOne: 0,
        valueInsuredTwo: benefit.benefitCode === 'WOP' ? false : '',
        perMileTwo: 0,
        perPercentageTwo: 0,
        maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
        perTempMileOne: 0,
        perTempMileTwo: 0,
        perTempMonthTwo: 0,
        perTempMonthOne: 0,
        brSelectedTempValueOne: false,
        brSelectedTempValueTwo: false,
        brSelectedPercentValueOne: false,
        brSelectedPercentValueTwo: false,
        brSelectedInsuredTwoValue: false,
        brSelectedInsuredOneValue: false,
      });
  });

  dtaConfig.productBenefits.optionalList.map(benefit => {
    coverages.push({
      type: benefit.benefitCode === 'WOP' ? '2' : '1',
      selected:
        benefit.isRequired || benefit.benefitCode === 'WOP' ? true : false,
      disabled: benefit.isRequired,
      id: benefit.benefitCode,
      title: benefit.benefitName,
      valueInsuredOne: benefit.benefitCode === 'WOP' ? false : '',
      perMileOne: 0,
      perPercentageOne: 0,
      valueInsuredTwo: benefit.benefitCode === 'WOP' ? false : '',
      perMileTwo: 0,
      perPercentageTwo: 0,
      maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
      perTempMileOne: 0,
      perTempMileTwo: 0,
      perTempMonthTwo: 0,
      perTempMonthOne: 0,
      brSelectedTempValueOne: false,
      brSelectedTempValueTwo: false,
      brSelectedPercentValueOne: false,
      brSelectedPercentValueTwo: false,
      brSelectedInsuredTwoValue: false,
      brSelectedInsuredOneValue: false,
    });
  });
  return coverages;
}

export function calculateAgeNextBirthdate(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  var monthDayPoint = 0;
  if (policyStartDateMonth == bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}

export function getMinValue(productId, dtaConfig, currency) {
  const indexOfCurrency = dtaConfig.benefitCurrencyMap.indexOf(currency);
  var minValue = 0;

  const productBenefits = [
    ...dtaConfig.productBenefits.optionalList,
    ...dtaConfig.productBenefits.mandatoryList,
  ];

  productBenefits.map(benefit => {
    if (!benefit.isComplimentary) {
      if (benefit.benefitCode === productId)
        minValue = benefit.minBenefitAmount[indexOfCurrency];
    }
  });
  return minValue;
}

export function getMaxValue(productId, dtaConfig, currency) {
  const indexOfCurrency = dtaConfig.benefitCurrencyMap.indexOf(currency);
  var maxValue = 0;

  const productBenefits = [
    ...dtaConfig.productBenefits.optionalList,
    ...dtaConfig.productBenefits.mandatoryList,
  ];

  productBenefits.map(benefit => {
    if (!benefit.isComplimentary) {
      if (benefit.benefitCode === productId)
        maxValue = benefit.maxBenefitAmount[indexOfCurrency];
    }
  });
  return maxValue;
}

export function isValidEmail(email) {
  if (email && isEmail(email)) {
    return true;
  }
  return false;
}

export function getStartDate() {
  var month = new Date().getMonth() + 1;
  if (new Date().getDate() <= 15) month = month - 1;

  return moment()
    .month(month)
    .date(1);
}

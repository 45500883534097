import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import messages from 'containers/ApplicationPage/messages';
import { map } from 'lodash/collection';
import moment from 'moment';
import { isDate } from 'lodash/lang';
import { getGender, filteredParty } from 'helpers/lang';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useStyles } from 'containers/ApplicationPage/helper';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(name, date, gender, isSmoker) {
  return { name, date, gender, isSmoker };
}

// const rows = [createData('Mr. John Smith', '02.05.1986', 'Male', 'Smoker')];

function PolicyOwnerDetails(props) {
  const theme = useTheme();
  const { parties, classes } = props;
  const rows = [
    ...map(
      filteredParty(parties, 'isPolicyOwner').sort(function (x, y) {
        //SORT who is PO1
        return (
          y.partyapplicationrelationships?.isPrimaryPolicyOwner -
          x.partyapplicationrelationships?.isPrimaryPolicyOwner
        );
      }),
      party => {
        const dateOfBirth = moment(party.dateOfBirth);
        return createData(
          `${party.firstName} ${party.lastName}`,
          dateOfBirth.isValid() ? dateOfBirth.format('DD MMMM YYYY') : '-',
          getGender(party.gender),
          // party.isSmoke,
        );
      },
    ),
  ];
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} style={{ paddingTop: 0 }}>
        <Typography
          variant="h3"
          style={{ color: theme.palette.primary.subMain }}
        >
          <FormattedMessage {...messages.policyOwner} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={`${row.name}-${row.index}`}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{ width: '30%' }}
                  >
                    <Typography variant="h4" className={classes.bold}>
                      {row.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '30%' }}>
                    <Typography variant="h4">{row.date}</Typography>
                  </StyledTableCell>
                  <StyledTableCell style={{ width: '40%' }}>
                    <Typography variant="h4">{row.gender}</Typography>
                  </StyledTableCell>
                  {/* <StyledTableCell>
                      <Typography variant="body1">{row.isSmoker}</Typography>
                    </StyledTableCell> */}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
}

PolicyOwnerDetails.propTypes = {};

export default PolicyOwnerDetails;

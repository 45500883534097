import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import numeral from 'numeral';

import {
  getMinValue,
  getMaxValue,
  calculateAgeNextBirthdate,
} from '../../helper';

const validate = (values, props) => {
  const errors = {};
  const futuraConfig = props.futuraConfig;
  const lifeAssured = props.lifeAssured;
  const MAX_GROWTH_RATE = futuraConfig?.agencyNumber==="8910138" && futuraConfig?.productCode === "SCFUT" ? 6 : futuraConfig.features?.growthRate?.maxRate;
  const MIN_GROWTH_RATE = futuraConfig?.agencyNumber==="8910138" && futuraConfig?.productCode === "SCFUT" ? 6 : futuraConfig.features?.growthRate?.minRate;
  const minTermValue = futuraConfig.features?.policyTerm.minTerm;

  if (!values.isEmpty()) {
    const offerCodeValues = values.get('offerCode');
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const policyCoverTermValues = values.get('policyCoverTerm');
    const termValues = values.get('term');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const singlePremiumValues = values.get('singlePremium');
    const isSinglePremiumValues = values.get('haveAdditionalSinglePremium');
    const growthRateValues = values.get('growthRate');
    const coveragesValues = values.get('coverages');

    if (!startDateValues || !moment(startDateValues).isValid()) {
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    }

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (
      !policyCoverTermValues ||
      (policyCoverTermValues !== minTermValue &&
        policyCoverTermValues !== 'WholeOfLife') ||
      policyCoverTermValues === 'Select'
    )
      errors.policyCoverTerm = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!termValues || termValues === 'Select')
      errors.term = <FormattedMessage {...globalMessages.pleaseSelectValue} />;

    if (termValues !== 'SinglePremium') {
      if (!paymentFrequencyValues || paymentFrequencyValues === 'Select')
        errors.paymentFrequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
    } else {
      if (!(termValues === 'SinglePremium') && !paymentFrequencyValues)
        errors.paymentFrequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
    }
    if (isSinglePremiumValues) {
      if (!singlePremiumValues)
        errors.singlePremium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else {
        var minSinglePremium = 0;
        var maxSinglePremium = 0;
        futuraConfig.features?.currencyPremiumSize.map(val => {
          if (currencyValues === val.currency) {
            minSinglePremium = val.minAdditionalSinglePremium;
            maxSinglePremium = val.maxAdditionalSinglePremium;
            return;
          }
        });
        if (singlePremiumValues < minSinglePremium)
          errors.singlePremium = (
            <FormattedMessage
              {...globalMessages.minLimit}
              values={{ value: minSinglePremium }}
            />
          );

        if (singlePremiumValues > maxSinglePremium) {
          errors.singlePremium = (
            <FormattedMessage
              {...globalMessages.maxLimit}
              values={{ value: maxSinglePremium }}
            />
          );
        }
      }
    }

    if (!growthRateValues) {
      errors.growthRate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    } else {
      //For SCB Bahrain case Min and Max growth rate is same.
      if(MIN_GROWTH_RATE == MAX_GROWTH_RATE) {
        if(growthRateValues != MAX_GROWTH_RATE) {
          errors.growthRate = (
            <FormattedMessage {...globalMessages.minMaxSame} 
            values={{ value: MAX_GROWTH_RATE }}/>
          );
        }
      } else {
        if (growthRateValues <= MIN_GROWTH_RATE)
          errors.growthRate = (
            <FormattedMessage {...globalMessages.minLimitGrowthRate} />
          );
        if (growthRateValues > MAX_GROWTH_RATE)
          errors.growthRate = (
            <FormattedMessage
              {...globalMessages.maxLimit}
              values={{ value: MAX_GROWTH_RATE }}
            />
          );
      }
      
    }

    if (coveragesValues && coveragesValues.size > 0) {
      const coveragesError = [];
      var lfcInsuredOne = 0;
      var lfcInsuredTwo = 0;
      coveragesValues.map(coverage => {
        var isInsuredOneValid = true;
        var isInsuredTwoValid = true;
        var validateFirstLife = true;
        var validateSecondLife = true;
        const ageOfLifeInsuredOne = calculateAgeNextBirthdate(
          startDateValues,
          lifeAssured.insuredOne.dateOfBirth,
        );
        const ageOfLifeInsuredTwo = calculateAgeNextBirthdate(
          startDateValues,
          lifeAssured.insuredTwo.dateOfBirth,
        );

        if (coverage.get('id') === 'LFC') {
          lfcInsuredOne = coverage.get('valueInsuredOne');
          lfcInsuredTwo = coverage.get('valueInsuredTwo');
        }

        if (lifeAssured.isInsuredTwo && lifeAssured.lifeBasis === 'JLBD') {
          if (coverage.get('maxEntryAgeLA') != '0') {
            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredOne) {
              isInsuredOneValid = false;
              validateFirstLife = false;
            }

            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredTwo) {
              validateSecondLife = false;
              isInsuredTwoValid = false;
            }
          }
        }

        if (
          lifeAssured.isInsuredTwo &&
          (lifeAssured.lifeBasis === 'JLLS' || lifeAssured.lifeBasis === 'JLFD')
        ) {
          if (coverage.get('maxEntryAgeLA') != '0') {
            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredOne)
              isInsuredOneValid = false;

            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredTwo)
              isInsuredTwoValid = false;

            if (coverage.get('id') != 'WOP' && coverage.get('id') != 'ESC') {
              validateSecondLife = false;
            }
          }
        }

        if (!lifeAssured.isInsuredTwo || lifeAssured.lifeBasis === '') {
          validateSecondLife = false;
          isInsuredTwoValid = false;
          if (coverage.get('maxEntryAgeLA') != '0') {
            if (coverage.get('maxEntryAgeLA') < ageOfLifeInsuredOne)
              isInsuredOneValid = false;
          }
        }

        //Special condition for CRI && CCC
        if (coverage.get('id') === 'CRI') {
          coveragesValues.map(benefitData => {
            if (
              benefitData.get('id') === 'CCC' &&
              benefitData.get('selected')
            ) {
              if (benefitData.get('valueInsuredOne')) validateFirstLife = false;

              if (benefitData.get('valueInsuredTwo'))
                validateSecondLife = false;
            }
          });
        }

        if (coverage.get('id') === 'CCC') {
          coveragesValues.map(benefitData => {
            if (
              benefitData.get('id') === 'CRI' &&
              benefitData.get('selected')
            ) {
              if (benefitData.get('valueInsuredOne')) validateFirstLife = false;

              if (benefitData.get('valueInsuredTwo'))
                validateSecondLife = false;
            }
          });
        }

        var requiredCoverageFields = [];
        if (validateFirstLife && isInsuredOneValid) {
          requiredCoverageFields.push('valueInsuredOne');
          requiredCoverageFields.push('termOne');
        }
        if (validateSecondLife && isInsuredTwoValid) {
          requiredCoverageFields.push('valueInsuredTwo');
          requiredCoverageFields.push('termTwo');
        }

        const coverageError = {};
        requiredCoverageFields.forEach(field => {
          if (coverage.get('selected')) {
            if (coverage.get('id') === 'FMI' || coverage.get('id') === 'FTI') {
              if (field === 'termOne' || field === 'termTwo') {
                if (
                  isFieldEmpty(coverage.get('termOne')) &&
                  isFieldEmpty(coverage.get('termTwo'))
                ) {
                  coverageError['termOne'] = (
                    <FormattedMessage {...globalMessages.pleaseEnterValue} />
                  );
                } else if (
                  isFieldEmpty(coverage.get('termOne')) &&
                  !isFieldEmpty(coverage.get('valueInsuredOne'))
                ) {
                  coverageError['termOne'] = (
                    <FormattedMessage {...globalMessages.pleaseEnterValue} />
                  );
                } else if (
                  isFieldEmpty(coverage.get('termTwo')) &&
                  !isFieldEmpty(coverage.get('valueInsuredTwo'))
                ) {
                  coverageError['termTwo'] = (
                    <FormattedMessage {...globalMessages.pleaseEnterValue} />
                  );
                } else {
                  const maxCoverTermValue =
                    futuraConfig.features?.policyTerm
                      ?.maxCoverTermFromIssueDate;

                  const minClaimDateValue =
                    futuraConfig.features?.policyTerm
                      ?.maxCoverTermFromClaimDate;

                  if (
                    coverage.get(field) > maxCoverTermValue &&
                    !isEmpty(coverage.get(field))
                  )
                    coverageError[field] = (
                      <FormattedMessage
                        {...globalMessages.maxValueLimit}
                        values={{ value: maxCoverTermValue }}
                      />
                    );

                  if (
                    coverage.get(field) < minClaimDateValue &&
                    !isEmpty(coverage.get(field))
                  )
                    coverageError[field] = (
                      <FormattedMessage
                        {...globalMessages.minValueLimit}
                        values={{ value: minClaimDateValue }}
                      />
                    );
                }
              } else {
                if (
                  isFieldEmpty(coverage.get('valueInsuredOne')) &&
                  isFieldEmpty(coverage.get('valueInsuredTwo'))
                ) {
                  coverageError['valueInsuredOne'] = (
                    <FormattedMessage {...globalMessages.pleaseEnterValue} />
                  );
                } else if (
                  isFieldEmpty(coverage.get('valueInsuredOne')) &&
                  !isFieldEmpty(coverage.get('termOne'))
                ) {
                  coverageError['valueInsuredOne'] = (
                    <FormattedMessage {...globalMessages.pleaseEnterValue} />
                  );
                } else if (
                  isFieldEmpty(coverage.get('valueInsuredTwo')) &&
                  !isFieldEmpty(coverage.get('termTwo'))
                ) {
                  coverageError['valueInsuredTwo'] = (
                    <FormattedMessage {...globalMessages.pleaseEnterValue} />
                  );
                } else {
                  const minVal = getMinValue(
                    coverage.get('id'),
                    futuraConfig.features,
                    currencyValues,
                  );
                  const maxVal = getMaxValue(
                    coverage.get('id'),
                    futuraConfig.features,
                    currencyValues,
                  );

                  if (
                    minVal > coverage.get(field) &&
                    !isEmpty(coverage.get(field))
                  )
                    coverageError[field] = (
                      <FormattedMessage
                        {...globalMessages.minAmountLimit}
                        values={{ value: minVal }}
                      />
                    );

                  if (
                    maxVal < coverage.get(field) &&
                    !isEmpty(coverage.get(field))
                  )
                    coverageError[field] = (
                      <FormattedMessage
                        {...globalMessages.maxAmountLimit}
                        values={{ value: maxVal }}
                      />
                    );

                  // if (field === 'valueInsuredOne' && !isEmpty(coverage.get('valueInsuredOne'))) {
                  //   if (numeral(coverage.get(field)).value() > numeral(lfcInsuredOne).value())
                  //     coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />);
                  // }

                  // if (field === 'valueInsuredTwo' && !isEmpty(coverage.get('valueInsuredTwo'))) {
                  //   if (numeral(coverage.get(field)).value() > numeral(lfcInsuredTwo).value())
                  //     coverageError[field] = (<FormattedMessage {...globalMessages.accelerateValidation} />)
                  // }
                }
              }
            } else {
              if (field === 'valueInsuredOne' || field === 'valueInsuredTwo') {
                if (['WOP', 'LFC', 'ESC'].includes(coverage.get('id'))) {
                  if (isFieldEmpty(coverage.get(field))) {
                    coverageError[field] = (
                      <FormattedMessage {...globalMessages.pleaseEnterValue} />
                    );
                  } else {
                    if (
                      coverage.get('id') != 'WOP' &&
                      coverage.get('id') != 'ESC'
                    ) {
                      const minVal = getMinValue(
                        coverage.get('id'),
                        futuraConfig.features,
                        currencyValues,
                      );
                      const maxVal = getMaxValue(
                        coverage.get('id'),
                        futuraConfig.features,
                        currencyValues,
                      );

                      if (minVal > coverage.get(field))
                        coverageError[field] = (
                          <FormattedMessage
                            {...globalMessages.minAmountLimit}
                            values={{ value: minVal }}
                          />
                        );

                      if (maxVal < coverage.get(field))
                        coverageError[field] = (
                          <FormattedMessage
                            {...globalMessages.maxAmountLimit}
                            values={{ value: maxVal }}
                          />
                        );

                      if (field === 'valueInsuredOne') {
                        if (
                          numeral(coverage.get(field)).value() >
                          numeral(lfcInsuredOne).value()
                        )
                          coverageError[field] = (
                            <FormattedMessage
                              {...globalMessages.accelerateValidation}
                            />
                          );
                      }

                      if (field === 'valueInsuredTwo') {
                        if (
                          numeral(coverage.get(field)).value() >
                          numeral(lfcInsuredTwo).value()
                        )
                          coverageError[field] = (
                            <FormattedMessage
                              {...globalMessages.accelerateValidation}
                            />
                          );
                      }
                    }
                  }
                } else {
                  if (
                    isFieldEmpty(coverage.get('valueInsuredOne')) &&
                    isFieldEmpty(coverage.get('valueInsuredTwo'))
                  ) {
                    coverageError['valueInsuredOne'] = (
                      <FormattedMessage {...globalMessages.pleaseEnterValue} />
                    );
                  } else {
                    const minVal = getMinValue(
                      coverage.get('id'),
                      futuraConfig.features,
                      currencyValues,
                    );
                    const maxVal = getMaxValue(
                      coverage.get('id'),
                      futuraConfig.features,
                      currencyValues,
                    );

                    if (
                      minVal > coverage.get(field) &&
                      !isEmpty(coverage.get(field))
                    )
                      coverageError[field] = (
                        <FormattedMessage
                          {...globalMessages.minAmountLimit}
                          values={{ value: minVal }}
                        />
                      );

                    if (
                      maxVal < coverage.get(field) &&
                      !isEmpty(coverage.get(field))
                    )
                      coverageError[field] = (
                        <FormattedMessage
                          {...globalMessages.maxAmountLimit}
                          values={{ value: maxVal }}
                        />
                      );

                    if (
                      field === 'valueInsuredOne' &&
                      !isEmpty(coverage.get('valueInsuredOne'))
                    ) {
                      if (
                        numeral(coverage.get(field)).value() >
                        numeral(lfcInsuredOne).value()
                      )
                        coverageError[field] = (
                          <FormattedMessage
                            {...globalMessages.accelerateValidation}
                          />
                        );
                    }

                    if (
                      field === 'valueInsuredTwo' &&
                      !isEmpty(coverage.get('valueInsuredTwo'))
                    ) {
                      if (
                        numeral(coverage.get(field)).value() >
                        numeral(lfcInsuredTwo).value()
                      )
                        coverageError[field] = (
                          <FormattedMessage
                            {...globalMessages.accelerateValidation}
                          />
                        );
                    }
                  }
                }
              }
            }
          }
        });
        coveragesError.push(coverageError);
      });

      if (!isEmpty(coveragesError)) errors.coverages = coveragesError;
    }

    if (!isEmpty(offerCodeValues)) {
      const coverages = coveragesValues.toJS();
      const lifeCover = coverages.find(item => item.id === 'LFC');
      if (lifeCover?.valueInsuredOne === '') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectLifeCover} />
        );
      }
      if (currencyValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectCurrency} />
        );
      }
      if (termValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectPremiumTerm} />
        );
      }
    }
  }
  return errors;
};

export default validate;

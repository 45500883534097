import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isString } from 'lodash/lang';
import moment from 'moment';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import {
  getStartDate,
  calculateAgeNextBirthdate,
} from 'containers/GcIllustrationPage/helper';
import { isAlphaNumeric } from 'helpers/lang';
import { dobFormat } from 'helpers/constants';

const validate = values => {
  const errors = {};
  const startDate = getStartDate().format(dobFormat);
  if (!values.isEmpty()) {
    // INSURED ONE
    const insuredOneValues = values.get('insuredOne');
    const isInsuredTwoValues = values.get('isInsuredTwo');
    const insuredTwoValues = values.get('insuredTwo');
    const isPolicyOwnerIndividualValues = values.get('isPolicyOwnerIndividual');

    const requiredInsuredOneFields = [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
      'isSmoke',
    ];
    const insuredOneError = {};
    if (insuredOneValues && !insuredOneValues.isEmpty()) {
      requiredInsuredOneFields.forEach(field => {
        if (isFieldEmpty(insuredOneValues.get(field))) {
          if (field === 'gender' || field === 'isSmoke')
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          else
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
        } 
        // else {
        //   if (field === 'firstName' || field === 'lastName') {
        //     if (
        //       isString(insuredOneValues.get(field)) &&
        //       !isAlphaNumeric(insuredOneValues.get(field))
        //     ) {
        //       insuredOneError[field] = (
        //         <FormattedMessage
        //           {...globalMessages.onlyAlphanumericCharacters}
        //         />
        //       );
        //     }
        //   }
        // }

        if (field === 'dateOfBirth') {
          if (!moment(insuredOneValues.get(field)).isValid()) {
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.dateInvalid} />
            );
          } else {
            const anb = calculateAgeNextBirthdate(
              moment(),
              insuredOneValues.get(field),
            );
            if (anb < 18)
              insuredOneError[field] = (
                <FormattedMessage
                  {...globalMessages.minAgeValidation}
                  values={{ value1: '18' }}
                />
              );

            if (anb > 75)
              insuredOneError[field] = (
                <FormattedMessage
                  {...globalMessages.maxAgeValidation}
                  values={{ value1: '74' }}
                />
              );
          }
        }
      });

      if (!isPolicyOwnerIndividualValues) {
        if (isInsuredTwoValues) {
          if (
            !insuredOneValues.get('isPolicyOwner') &&
            !insuredTwoValues.get('isPolicyOwner')
          ) {
            insuredOneError['isPolicyOwner'] = (
              <FormattedMessage {...globalMessages.atleastOnePolicyOwner} />
            );
          }
        } else {
          if (!insuredOneValues.get('isPolicyOwner')) {
            insuredOneError['isPolicyOwner'] = (
              <FormattedMessage {...globalMessages.atleastOnePolicyOwner} />
            );
          }
        }
      }
    }
    if (!isEmpty(insuredOneError)) errors.insuredOne = insuredOneError;

    if (isInsuredTwoValues) {
      // INSURED TWO
      const requiredInsuredTwoFields = [
        'firstName',
        'lastName',
        'dateOfBirth',
        'gender',
        'isSmoke',
      ];
      const insuredTwoError = {};
      if (insuredTwoValues && !insuredTwoValues.isEmpty()) {
        requiredInsuredTwoFields.forEach(field => {
          if (isFieldEmpty(insuredTwoValues.get(field))) {
            if (field === 'gender' || field === 'isSmoke')
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
            else
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
          } 
          // else {
          //   if (field === 'firstName' || field === 'lastName') {
          //     if (
          //       isString(insuredTwoValues.get(field)) &&
          //       !isAlphaNumeric(insuredTwoValues.get(field))
          //     ) {
          //       insuredTwoError[field] = (
          //         <FormattedMessage
          //           {...globalMessages.onlyAlphanumericCharacters}
          //         />
          //       );
          //     }
          //   }
          // }

          if (field === 'dateOfBirth') {
            if (!moment(insuredTwoValues.get(field)).isValid()) {
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.dateInvalid} />
              );
            } else {
              const anb = calculateAgeNextBirthdate(
                moment(),
                insuredTwoValues.get(field),
              );
              if (anb < 18)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.minAgeValidation}
                    values={{ value1: '18' }}
                  />
                );

              if (anb > 75)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxAgeValidation}
                    values={{ value1: '74' }}
                  />
                );
            }
          }
        });
      }
      if (!isEmpty(insuredTwoError)) errors.insuredTwo = insuredTwoError;
    }

    // INDIVIDUAL POLICY OWNER
    if (isPolicyOwnerIndividualValues) {
      const policyOwnerIndividualValues = values.get('policyOwnerIndividual');
      const policyOwnerIndividualError = [];
      if (policyOwnerIndividualValues && policyOwnerIndividualValues.size > 0) {
        const requiredIndividualFields = [
          'firstName',
          'lastName',
          'relationshipToLifeInsured',
        ];
        policyOwnerIndividualValues.map(individual => {
          const individualError = {};
          requiredIndividualFields.forEach(field => {
            if (isFieldEmpty(individual.get(field))) {
              if (field === 'relationshipToLifeInsured')
                individualError[field] = (
                  <FormattedMessage {...globalMessages.pleaseSelectValue} />
                );
              else
                individualError[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
            } 
            if (field === 'relationshipToLifeInsured') {
              if (individual.get(field) === 'Select an option') {
                individualError[field] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
              } 
            }
            // else {
            //   if (field === 'firstName' || field === 'lastName') {
            //     if (
            //       isString(individual.get(field)) &&
            //       !isAlphaNumeric(individual.get(field))
            //     ) {
            //       individualError[field] = (
            //         <FormattedMessage
            //           {...globalMessages.onlyAlphanumericCharacters}
            //         />
            //       );
            //     }
            //   }
            // }
          });
          policyOwnerIndividualError.push(individualError);
        });
      }
      if (!isEmpty(policyOwnerIndividualError))
        errors.policyOwnerIndividual = policyOwnerIndividualError;
    }
  }
  return errors;
};

export default validate;

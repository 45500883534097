
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import { Grid, Box } from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import FormTextField from 'components/FormTextField';

function OtherParties() {
  const intl = useIntl();
  return (
    <Box mt={3}>
      <Grid item xs={8}>
        <Field
          name={`potentialBorrowers`}
          fullWidth
          component={FormTextField}
          label={<FormattedMessage {...messages.potentialBorrowers} />}
          placeholder={intl.formatMessage(messages.pleaseGiveDetails)}
          multiline
          rows={5}
          shrink
          fullWidth
        />
      </Grid>
    </Box>
  )
}

OtherParties.propTypes = {
};

export default OtherParties;

/**
 * Withdrawal
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import {
  Grid,
  FormControlLabel,
  Radio,
  Divider,
  Box,
  Typography,
} from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import messages from './../messages';
import FormSelect from 'components/FormSelect';
import FormRadioGroup from 'components/FormRadioGroup';
import FormNumberField from 'components/FormNumberField';
import CustomRadio from 'components/CustomRadioButton';
import numeral from 'numeral';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  labelCheckbox: {
    fontWeight: 'normal',
  },
}));

const lessThan = otherField => (value, previousValue, allValues) => {
  if (!value) return '';
  return value.length <= otherField ? value : previousValue;
};

function Withdrawal({
  formValues = {},
  frequencyList,
  error,
  minDate,
  maxDate,
  isEducationWithdrawal,
  change,
}) {
  const theme = useTheme();
  const intl = useIntl();

  if (isEducationWithdrawal) formValues = formValues.child1;

  const classes = useStyles();
  return (
    <Box mb={3} ml={1}>
      <Grid container spacing={3} alignItems="center">
        <Grid item sm={5} xs={12} md={5}>
          <Field
            shrink
            name="frequency"
            fullWidth
            component={FormSelect}
            style={{
              color: changePlaceHolderColor(
                convertIfImmutable(formValues).frequency,
              ),
            }}
            // options={frequencyList}
            options={[{ value: 'Select', title: 'Select' }, ...frequencyList]}
            type="text"
            color="primary"
            variant="standard"
            label={<FormattedMessage {...messages.frequency} />}
          />
        </Grid>

        <Grid item sm={5} xs={12} md={5}></Grid>
        <Grid item sm={5} xs={12} md={5}>
          <Field
            name="startDate"
            component={FormDatePicker}
            label={<FormattedMessage {...messages.startDate} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            margin="normal"
            minDate={minDate}
            maxDate={maxDate}
            InputLabelProps={{
              style: {
                fontWeight: 'normal',
                fontSize: theme.typography.body2.fontSize,
              },
            }}
            shouldDisableDate={date => {
              if (date.date() !== 15) {
                return true;
              }
              return false;
            }}
          />
        </Grid>

        <Grid item sm={5} xs={12} md={5}>
          <Field
            name="endDate"
            component={FormDatePicker}
            label={<FormattedMessage {...messages.endDate} />}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            margin="normal"
            minDate={minDate}
            maxDate={maxDate}
            InputLabelProps={{
              style: {
                fontSize: theme.typography.body2.fontSize,
                fontWeight: 'normal',
              },
            }}
          />
        </Grid>

        {isEducationWithdrawal ? (
          <Grid item sm={5} xs={12} md={5}>
            <Field
              name="firstName"
              fullWidth
              component={FormTextField}
              label={<FormattedMessage {...messages.firstName} />}
              placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
              margin="normal"
              normalize={lessThan(45)}
            />
          </Grid>
        ) : null}

        {isEducationWithdrawal ? (
          <Grid item sm={5} xs={12} md={5}>
            <Field
              name="lastName"
              fullWidth
              component={FormTextField}
              label={<FormattedMessage {...messages.lastName} />}
              placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
              margin="normal"
              normalize={lessThan(40)}
            />
          </Grid>
        ) : null}

        <Grid
          className={classes.hr}
          style={{ width: '95%', marginLeft: 12 }}
        ></Grid>

        <Grid container spacing={2} wrap="nowrap" style={{ height: '100px' }}>
          <Grid item xs={6}>
            <Field
              name="isAmountChecked"
              type="radio"
              component={FormRadioGroup}
              onChange={(e, newVal) => {
                if (newVal === '2') {
                  //console.log('regularWithdrawal 2 : ',productConfig.regularWithdrawal);
                  return change(`regularWithdrawal.amount`, '0');
                }
                if (newVal === '1') {
                  //console.log('regularWithdrawal 1 : ',productConfig.regularWithdrawal);
                  return change(`regularWithdrawal.percentage`, 0)
                };
              }}
              style={{ height: '85px' }}
            >
              <Grid
                container
                spacing={1}
                justify="space-between"
                style={{ height: '100%', marginLeft: 8 }}
              >
                <Grid item xs={12}>
                  <FormControlLabel
                    classes={{ label: classes.labelCheckbox }}
                    control={
                      <CustomRadio
                        checked={
                          formValues === undefined
                            ? false
                            : numeral(formValues.isAmountChecked).value() === 1
                        }
                      />
                    }
                    value={1}
                    label={<FormattedMessage {...messages.amount} />}
                    labelPlacement="left"
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    classes={{ label: classes.labelCheckbox }}
                    control={
                      <CustomRadio
                        checked={
                          formValues == undefined
                            ? false
                            : numeral(formValues.isAmountChecked).value() == 2
                        }
                      />
                    }
                    value={2}
                    label={
                      <FormattedMessage {...messages.percentageFundValue} />
                    }
                    labelPlacement="left"
                  />
                </Grid>
              </Grid>
            </Field>
          </Grid>

          <Grid item xs={5}>
            <Grid
              container
              direction="column"
              wrap="nowrap"
              spacing={1}
              justify="center"
              style={{ height: '100%' }}
            >
              <Grid item>
                <Field
                  name="amount"
                  component={FormNumberField}
                  type="text"
                  color="primary"
                  variant="standard"
                  style={{ width: 134 }}
                  disabled={
                    formValues === undefined
                      ? true
                      : numeral(formValues.isAmountChecked).value() !== 1
                  }
                  onChange={(e, newVal) => {
                    return change(`regularWithdrawal.amount`, Number(newVal))
                  }}
                />
              </Grid>

              <Grid item>
                <Field
                  name="percentage"
                  component={FormTextField}
                  type="text"
                  color="primary"
                  variant="standard"
                  style={{ width: 134 }}
                  disabled={
                    formValues === undefined
                      ? true
                      : numeral(formValues.isAmountChecked).value() !== 2
                  }
                  onChange={(e, newVal) => {
                    return change(`regularWithdrawal.percentage`, Number(newVal))
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

Withdrawal.propTypes = {
  formValues: PropTypes.object,
  frequencyList: PropTypes.array,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.bool,
  isEducationWithdrawal: PropTypes.bool,
};

export default Withdrawal;

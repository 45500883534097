import { partyFilter, filteredParty } from 'helpers/lang';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { filter, forEach, find } from 'lodash/collection';
import { keys, get } from 'lodash/object';
import { productCodeCheck } from 'helpers/constants';

export const lastSubStepper = (subPageList, subPage) => {
  if (!isEmpty(subPageList)) {
    const order =
      subPageList[subPage - 1].subOrder[
      subPageList[subPage - 1].subOrder.length - 1
      ];

    if (!isEmpty(order)) {
      return order.order;
    }
    return 0;
  }
};

export function mapFinancialInstituteOptions(financialInstitutes) {
  return financialInstitutes.map(fi => ({
    title: fi.bankFullName,
    value: `${fi.bankBranchAddTNField1}${fi.bankBranchAddTNField2}`,
  }));
}

export function getStartDate() {
  let month = new Date().getMonth() + 1;
  if (new Date().getDate() <= 15) month -= 1;

  return moment()
    .month(month)
    .date(1);
}

export const isFirstChild = (questions, currentQuestion) => {
  if (currentQuestion.parentQuestionId && questions?.length > 0) {
    const parentQuestion = filter(questions, q => {
      return q.id === currentQuestion.parentQuestionId;
    });

    // check if parent question is multi choice question
    if (
      parentQuestion[0].type === 'MULTIPLE_CHOICE' ||
      parentQuestion[0].type === 'SEARCH'
    ) {
      // get all the child questions of the parent question
      const childQuestions = filter(questions, q => {
        return q.parentQuestionId === parentQuestion[0].id;
      });

      // get the same child context
      const childContext = filter(childQuestions, cContext => {
        return cContext.context[0] === currentQuestion.context[0];
      });

      if (childContext[0].id === currentQuestion.id) {
        return true;
      }
    }
  }

  return false;
};

// checks if coverages is not available and if all benefit is in selected:false
export const isNotCoverages = productConfig => {
  let isNotCoverage = true;
  if (productConfig?.coverages) {
    const coveragesList = keys(productConfig.coverages[0]);
    forEach(coveragesList, cover => {
      if (cover !== 'isPrimary') {
        if (productConfig?.coverages[0][cover].selected) {
          isNotCoverage = false;
        }
        if (productCodeCheck('ESP', productConfig.productCode)) {
          let prodarray = productConfig?.coverages?.filter(element => element?.LFCPTD?.selected || element?.LFC?.selected)
          isNotCoverage = (prodarray?.length <= 0)
        }
        if (productCodeCheck('RSP', productConfig.productCode)) {
          isNotCoverage = !productConfig?.coverages[0]['WOP'].selected;
        }
      }
    });
  }

  return isNotCoverage;
};

// FILTERED PARTIES

export const filteredApplicationPolicyOwner = applicationDetails => {
  let updatedPO = {};
  const filteredPO = filteredParty(applicationDetails, 'isPolicyOwner', true);

  const sortedPO = filteredPO.parties.sort((a, b) => {
    return (
      a.partyapplicationrelationships.isLifeInsured -
      b.partyapplicationrelationships.isPrimaryLifeInsured
    );
  });

  updatedPO = { ...filteredPO, parties: [...sortedPO] };
  return updatedPO;
};

export const filteredPrimaryBeneficiary = applicationDetails =>
  partyFilter(
    applicationDetails.parties,
    { name: 'isBeneficiary', condition: true },
    { name: 'isPrimaryBeneficiary', condition: true },
    { name: 'isPolicyOwner', condition: false },
    { name: 'isLifeInsured', condition: false },
  );

export const filteredContingentBeneficiaries = applicationDetails =>
  partyFilter(
    applicationDetails.parties,
    { name: 'isBeneficiary', condition: true },
    { name: 'isPrimaryBeneficiary', condition: false },
    { name: 'isPolicyOwner', condition: false },
    { name: 'isLifeInsured', condition: false },
  );

export const filteredLifeInsured = applicationDetails =>
  partyFilter(applicationDetails.parties, {
    name: 'isLifeInsured',
    condition: true,
  });

export const filteredLifeInsuredOnly = applicationDetails =>
  partyFilter(
    applicationDetails.parties,
    {
      name: 'isLifeInsured',
      condition: true,
    },
    { name: 'isPolicyOwner', condition: false },
  );

export const filteredPOLI = applicationDetails =>
  partyFilter(
    applicationDetails.parties,
    { name: 'isPolicyOwner', condition: true },
    { name: 'isLifeInsured', condition: true },
  );

export const filteredPO = applicationDetails =>
  partyFilter(
    applicationDetails.parties,
    { name: 'isPolicyOwner', condition: true },
    // { name: 'isLifeInsured', condition: true },
  );

// Remove option in nationality or dualNationality if others have exist this option
export const filterCountryOption = (countryCode, formValues) => {
  if (!isEmpty(formValues)) {
    return filter(countryCode, item => item.value !== formValues);
  }
  if (isEmpty(formValues)) {
    return countryCode;
  }
};

export const filteredisPayor = applicationDetails => {
  const partyPayor = partyFilter(
    applicationDetails.parties,
    { name: 'isPayor', condition: true },
    { name: 'isPrimaryBeneficiary', condition: false },
    { name: 'isPolicyOwner', condition: false },
    { name: 'isLifeInsured', condition: false },
  );
  if (
    !partyPayor[0]?.partyapplicationrelationships?.additionalDetails
      ?.isCompanyPayor
  ) {
    return partyPayor;
  }
  return [];
};

export const filteredPayor = applicationDetails =>
  partyFilter(applicationDetails.parties, { name: 'isPayor', condition: true });

export const filteredLifeInsuredPayor = applicationDetails =>
  partyFilter(
    applicationDetails.parties,
    { name: 'isPolicyOwner', condition: false },
    { name: 'isLifeInsured', condition: true },
  );

export const filteredCompanyPayor = applicationDetails => {
  const partyPayor = partyFilter(
    applicationDetails.parties,
    { name: 'isPayor', condition: true },
    { name: 'isPrimaryBeneficiary', condition: false },
    { name: 'isPolicyOwner', condition: false },
    { name: 'isLifeInsured', condition: false },
  );

  if (
    partyPayor[0]?.partyapplicationrelationships?.additionalDetails
      ?.isCompanyPayor
  ) {
    return partyPayor[0];
  }
  return {};
};

// DOCUMENT PAGE
export const getDisabledStatus = (
  using3rdPartyService,
  handleDisableButton,
  isExceedRetry,
) => {
  return process.env.SHUTIPRO_ENABLED === 'y' && using3rdPartyService
    ? handleDisableButton() || isExceedRetry
    : true;
};

export const statusPath = (
  status,
  applicationId,
  formPath,
  history,
  uwStatus,
  uwConsent,
  lifeInsuredOnly,
  isInforce,
  availableStatuses,
) => {
  // BENEFICIARY
  if (status === 'POLICY_OWNER_COMPLETED') {
    if (policyDetailsPaths.includes(formPath)) {
      return;
    }
    history.push(`/applications/${applicationId}/form/beneficiaries`);
    return;
  }
  // PAYOR
  if (status === 'BENEFICIARY_COMPLETED') {
    if (policyDetailsPaths.includes(formPath)) {
      return;
    }
    history.push(`/applications/${applicationId}/form/payor`);
    return;
  }

  // PAYOR-SALARY
  if (status === 'PAYOR_COMPLETED') {
    history.push(`/applications/${applicationId}/form/payor-salary`);
    return;
  }

  // PAYOR BANK
  if (status === 'OOW_COMPLETED') {
    history.push(`/applications/${applicationId}/form/payor-bank`);
    return;
  }

  // LIFE INSURED  && INVESTMENT
  if (status === 'PAYOR_BANK_COMPLETED') {
    if (lifeInsuredOnly.length > 0) {
      history.push(`/applications/${applicationId}/form/lifeinsured`);
    } else {
      history.push(`/applications/${applicationId}/form/investment`);
    }
  }

  if (status === 'LIFE_INSURED_COMPLETED') {
    if (policyDetailsPaths.includes(formPath)) {
      return;
    }
    history.push(`/applications/${applicationId}/form/investment`);
    return;
  }

  if (status === 'POLICY_DETAIL_COMPLETED') {
    history.push(`/applications/${applicationId}/form/investment`);
    return;
  }

  // UNDERWRITING
  if (status === 'INVESTMENT_COMPLETED') {
    // check if UW already have a status.
    if (uwStatus) {
      history.push(`/applications/${applicationId}/form/uw-decision`);
      return;
    }
    if (formPath === '/uw-submit') {
      // check if UWConsent is true
      return;
    }

    if (uwConsent) {
      history.push(`/applications/${applicationId}/form/uw-section`);
      return;
    }
    // redirects to consent checkbox form
    history.push(`/applications/${applicationId}/form/uw-consent`);
    return;
  }

  if (
    status === 'UNDERWRITING_COMPLETED' &&
    availableStatuses.includes('UNDERWRITING_COMPLETED')
  ) {
    if (
      formPath === '/documents' ||
      formPath === '/consent' ||
      formPath === '/payment' ||
      formPath === '/payment-method' ||
      formPath === '/payment-preview'
    ) {
      return;
    }
    history.push(`/applications/${applicationId}/form/uw-decision`);
    return;
  }
  // DOCUMENTS
  if (
    status === 'IDENTIFICATION_VERIFIED' ||
    status === 'PENDING_IDENTIFICATION' ||
    status === 'UW_DECISION_COMPLETED'
  ) {
    history.push(`/applications/${applicationId}/form/documents`);
    return;
  }

  if (
    status === 'UNDERWRITING_COMPLETED' &&
    !availableStatuses.includes('UNDERWRITING_COMPLETED')
  ) {
    history.push(`/applications/${applicationId}/form/documents`);
  }

  // CONSENT
  if (status === 'VERIFY_DOC_COMPLETED' || status === 'PENDING_CONSENT') {
    if (
      formPath === '/payment' ||
      formPath === '/payment-method' ||
      formPath === '/payment-preview' ||
      formPath === '/payment-callback'
    ) {
      return;
    }

    history.push(`/applications/${applicationId}/form/consent`);
    return;
  }

  // PAYMENT
  if (status === 'CONSENT_COMPLETED') {
    if (
      formPath === '/payment' ||
      formPath === '/payment-method' ||
      formPath === '/payment-preview'
    ) {
      return;
    }

    if (isInforce) {
      history.push(`/applications/${applicationId}/form/payment-preview`);
      return;
    }
    history.push(`/applications/${applicationId}/form/payment`);
    return;
  }

  // COMPLETED / DONE
  if (
    status === 'PAYMENT_RECEIVED' ||
    status === 'COMPLETED' ||
    status === 'PENDING_PAYMENT'
  ) {
    history.push(`/applications/${applicationId}`);
  }
};

export const previousHandler = (
  path,
  stepHandler,
  statuses,
  productCode,
  applicationId,
  history,
  setReset,
) => {
  if (policyDetailsPaths.includes(path)) {
    const currentPath = find(allStatuses, stat => {
      return stat.pathURL === path;
    });

    const prevPath = find(allStatuses, stat => {
      return stat.pathURL === currentPath.prevURL;
    });

    // checks if the status of the previous path is included from BE's availableStatuses
    if (statuses.includes(prevPath.status)) {
      stepHandler(prevPath.path);
      return;
    }
    // if not run the same function but use previous path
    // this will keep going back up to policy owner page
    previousHandler(prevPath.pathURL, stepHandler, statuses, productCode);
    return;
  }

  if (path === '/policy-owner') {
    history.push(`/applications/${applicationId}`);
  }
  // UW to PAYMENT - this is the same as the previous codes before
  if (path === '/uw-consent') {
    stepHandler('investment');
  } else if (path === '/uw-submit') {
    stepHandler('uw-section');
  } else if (path === '/uw-decision') {
    stepHandler('uw-submit');
  } else if (path === '/documents') {
    stepHandler('uw-decision');
  } else if (path === '/consent') {
    stepHandler('documents');
  } else if (path === '/payment') {
    stepHandler('payment-preview');
    setReset(false);
    // setStatus(false);
  } else if (path === '/payment-method') {
    stepHandler('payment');
  } else if (path === '/payment-preview') {
    stepHandler('payment-method');
  } else if (path === '/policy-owner') {
    stepHandler(`/applications/${applicationId}`);
  }
};

// currently only for policy details section
// but can be applied up to payment page. but for now I just simplified the flow
export const allStatuses = [
  {
    status: 'POLICY_OWNER_COMPLETED',
    path: 'policy-owner',
    pathURL: '/policy-owner',
    prevURL: '/',
  },
  {
    status: 'BENEFICIARY_COMPLETED',
    path: 'beneficiary',
    pathURL: '/beneficiaries',
    prevURL: '/policy-owner',
  },
  {
    status: 'PAYOR_COMPLETED',
    path: 'payor',
    pathURL: '/payor',
    prevURL: '/beneficiaries',
  },
  {
    status: 'OOW_COMPLETED',
    path: 'payor-salary',
    pathURL: '/payor-salary',
    prevURL: '/payor',
  },
  {
    status: 'PAYOR_BANK_COMPLETED',
    path: 'payor-bank',
    pathURL: '/payor-bank',
    prevURL: '/payor-salary',
  },
  {
    status: 'LIFESINSURED_COMPLETED',
    path: 'lifeinsured',
    pathURL: '/lifeinsured',
    prevURL: '/payor-bank',
  },
  {
    status: 'INVESTMENT_COMPLETED',
    path: 'investment',
    pathURL: '/investment',
    prevURL: '/lifeinsured',
  },
];

export const policyDetailsPaths = [
  // '/policy-owner',
  '/beneficiaries',
  '/payor',
  '/payor-salary',
  '/payor-bank',
  '/investment',
  '/lifeinsured',
];

export const checkTaxResidenceShow = appDetails => {
  const isInforce = get(appDetails, 'isInforce', false);
  if (!isInforce) {
    return true;
  }

  const { isAdditionalSinglePremiumAdded, isPremiumIncreased } = get(
    appDetails,
    'inforceComparerChecks',
    {},
  );
  const policyStartDate = get(
    appDetails,
    'harvestData.policyDetail.policyStartDate',
    null,
  );
  const reportingBody = get(
    appDetails,
    'harvestData.policyDetail.reportingBody',
    null,
  );

  const isStartDateGreaterThanTargetDate =
    new Date(policyStartDate).getTime() > new Date('2017-01-01').getTime();

  return (
    reportingBody === 'UAE_RB' &&
    isStartDateGreaterThanTargetDate &&
    (isAdditionalSinglePremiumAdded || isPremiumIncreased)
  );
};

/**
 *
 * FormTextField
 *
 */

import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import styled from 'styled-components';

import {
  FormControlLabel as BaseFormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import EmptyIcon from 'images/checkbox-blue-empty.svg';
import EmptyDisabledIcon from 'images/checkbox-grey-empty.svg';
import CheckedIcon from 'images/checkbox-blue-tick.svg';
import CheckedDisabledIcon from 'images/checkbox-grey-tick.svg';
import IndeterminateCheckBoxOutlined from 'images/checkbox-blue-line.svg';
import CloseOutlined from 'images/checkbox-grey-cross.svg';

const FormControlLabel = styled(props => {
  const { fullWidth, ...baseProps } = props;
  return (
    <BaseFormControlLabel
      {...baseProps}
      classes={{
        root: 'formControlLabel-root',
        label: 'formControlLabel-label',
      }}
    />
  );
})`
  &.formControlLabel-root {
    ${props => (props.fullWidth ? 'width: 100%;' : null)}
    align-items: flex-start;

    .formControlLabel-label {
      font-size: 1.6rem;
    }
  }
`;

function getUncheckedIcon(validType, disabled) {
  switch (validType) {
    case 1:
      return CloseOutlined;
    case 2:
      return IndeterminateCheckBoxOutlined;
    default:
      if (disabled) return EmptyDisabledIcon;
      return EmptyIcon;
  }
}

function getCheckedIcon(disabled) {
  if (disabled) {
    return CheckedDisabledIcon;
  }
  return CheckedIcon;
}

const CustomFormCheck = ({
  label,
  validType,
  input,
  meta: { error, touched, warning, invalid },
  disabled,
  ...custom
}) => {
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  return (
    <FormControl component="fieldset" error={!!warning || (touched && !!error)}>
      <FormControlLabel
        control={
          <Checkbox
            checked={input.value}
            color="primary"
            disabled={disabled}
            icon={
              <img src={getUncheckedIcon(validType, disabled)} alt="people" />
            }
            checkedIcon={<img src={getCheckedIcon(disabled)} alt="people" />}
          />
        }
        label={label}
        {...input}
        {...custom}
      />
      {helperMsg && <FormHelperText error>{helperMsg}</FormHelperText>}
    </FormControl>
  );
};

CustomFormCheck.propTypes = {
  ...WrappedFieldProps,
};

export default CustomFormCheck;

/* eslint-disable no-underscore-dangle */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import messages from 'containers/ApplicationFormPage/messages';
import {
  regexSpecialCharacters,
  regexLongDashCharacters,
  regexPhoneNumber,
  regexNumbers,
  isAlphaNumeric,
} from 'helpers/lang';
import moment from 'moment';
import { isDisabledField } from 'helpers/constants';

//validators
import isEmail from 'validator/lib/isEmail';
import { isUS } from 'helpers/lang';
import { isEmpty, isString } from 'lodash/lang';
import { forEach, map, find } from 'lodash/collection';
import { isFieldEmpty } from 'utils/form';
import { getStartDate } from 'containers/ApplicationFormPage/helpers';
import { productCodeCheck } from 'helpers/constants';

const legalAge = getStartDate()
  .toDate()
  .setFullYear(
    getStartDate()
      .toDate()
      .getFullYear() - 18,
  );

const validate = (values, props) => {
  const { isUAEAgency, disabledFields } = props;
  const errors = {};
  const optionList = props?.isInforce
    ? props?.options?.countryCode
    : props?.options?.addressGccCountry;

  if (!values.isEmpty()) {
    const parties = values.get('parties').toJS();
    const productCode = values.get('productCode');
    const partiesArrayErrors = [];
    const taxPurposeErrors = [];
    const taxPurposeResidenceErrors = [];
    const parsedValues = values.toJS();

    const additionalInforceFields =
      props?.isInforce &&
      parties[0]?.partyapplicationrelationships?.isLifeInsured
        ? ['isSmoke']
        : [];
    // TEXT FIELDS
    const requiredTextFields = [
      // 'preferredName',
      'email',
      'primaryPhoneNumber',
      // 'secondaryPhoneNumber',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'city',
      'isUsResident',
      'isUsTaxPayer',
      'isPep',
      'country',
      'primaryNationality',
      'dateOfBirth',
      'placeOfBirth',
      ...additionalInforceFields,
    ];

    const nonRequiredTextFields = [
      'postOfficeBoxLine',
      'postOfficeBoxCity',
      'countryOfBirth',
      'secondaryNationality',
      'preferredName',
      // 'primaryPhoneNumber',
      'secondaryCountryDialingCode',
      'secondaryPhoneNumber',
      'primaryCountryDialingCode',
      // 'dateOfBirth',
    ];
    // SELECT FIELDS
    const requiredSelectFields = [
      'countryOfBirth',
      'primaryCountryDialingCode',
    ];

    forEach(parties, (party, index) => {
      const partyErrors = {};
      forEach([...requiredTextFields, ...nonRequiredTextFields], field => {
        if (!isDisabledField(disabledFields, 'po', field)) {
          if (isFieldEmpty(party[field])) {
            if (field === 'placeOfBirth') {
              if (!nonRequiredTextFields.includes(field)) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            } else if (field === 'dateOfBirth') {
              if (!nonRequiredTextFields.includes(field)) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            } else {
              if (requiredTextFields.includes(field)) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            }
          } else if ('country' === field) {
            if (
              party[field] === 'Select an option' ||
              isEmpty(
                find(optionList, o => {
                  return party[field] === o.value;
                }),
              )
            ) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          } else if ('email' === field) {
            if (!isEmail(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.emailInvalid} />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (party[field]?.length > 70) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 70 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          } else if (
            'addressLine1' === field ||
            'addressLine2' === field ||
            'addressLine3' === field ||
            'city' === field ||
            'postOfficeBoxCity' === field
          ) {
            if (party[field].length > 35) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }

            if (regexLongDashCharacters.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.unsupportedChars} />
              );
              partiesArrayErrors[index] = partyErrors;
            }

            if (field === 'city') {
              if (!regexNumbers.test(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (regexSpecialCharacters.test(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.specialCharactersNotAllowed}
                  />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            }
          } else if ('dateOfBirth' === field) {
            if (!party.partyapplicationrelationships?.isLifeInsured) {
              if (isEmpty(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              } else if (!moment(party[field]).isValid()) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.invalidDate} />
                );
              }
              const today = new Date().setFullYear(new Date().getFullYear());
              const date = Date.parse(party[field]);
              if (date > today) {
                partyErrors[field] = (
                  <FormattedMessage {...messages.futureDates} />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (legalAge < date) {
                partyErrors[field] = (
                  <FormattedMessage {...messages.legalAge} />
                );
              }
            }
          } else if ('postOfficeBoxLine' === field) {
            if (party[field].length > 15) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 15 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          } else if ('preferredName' === field) {
            if (!regexNumbers.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (regexSpecialCharacters.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.specialCharactersNotAllowed}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          } else if ('placeOfBirth' === field) {
            if (productCodeCheck(3, productCode)) {
              if (isEmpty(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (party[field]?.length > 35) {
                partyErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 35 }}
                  />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (!regexNumbers.test(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (regexSpecialCharacters.test(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.specialCharactersNotAllowed}
                  />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            }
          } else if ('primaryNationality' === field) {
            if (party[field] === 'Select an option') {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }

          if (productCodeCheck(3, productCode)) {
            if (
              (field === 'primaryCountryDialingCode' && isUS(party[field])) ||
              (field === 'secondaryCountryDialingCode' && isUS(party[field])) ||
              (field === 'secondaryNationality' && isUS(party[field])) ||
              // (field === 'countryOfBirth' && isUS(party[field])) ||
              (field === 'country' && isUS(party[field])) ||
              (field === 'primaryNationality' && isUS(party[field]))
            ) {
              partyErrors[field] = (
                <FormattedMessage {...messages.USCitizencannotProceed} />
              );
              partiesArrayErrors[index] = partyErrors;
            }

            if (
              (field === 'isUsResident' && party[field]) ||
              (field === 'isUsTaxPayer' && party[field])
            ) {
              partyErrors[field] = (
                <FormattedMessage {...messages.USCitizencannotProceed} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }

          if (field === 'secondaryNationality') {
            if (party[field] === party['primaryNationality']) {
              partyErrors[field] = (
                <FormattedMessage
                  {...messages.sameAsPrimaryNationalityNotAllowed}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }

          // PRIMARY NUMBER
          if (
            !isDisabledField(disabledFields, 'po', 'primaryCountryDialingCode')
          ) {
            if (
              field === 'primaryCountryDialingCode' &&
              isEmpty(party['primaryPhoneNumber']) &&
              !isEmpty(party[field])
            ) {
              if (index !== 1) {
                partyErrors['primaryPhoneNumber'] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              }
              partiesArrayErrors[index] = partyErrors;
            }

            if (
              field === 'primaryPhoneNumber' &&
              !isEmpty(party['primaryPhoneNumber'])
            ) {
              if (party[field].length > 25) {
                partyErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 25 }}
                  />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (!regexPhoneNumber.test(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.invalidPhoneNumber} />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            }
          }

          // SECONDARY NUMBER

          if (
            !isDisabledField(
              disabledFields,
              'po',
              'secondaryCountryDialingCode',
            )
          ) {
            if (
              field === 'secondaryCountryDialingCode' &&
              party['secondaryCountryDialingCode'] !== 'Select' &&
              isEmpty(party['secondaryPhoneNumber']) &&
              !isEmpty(party[field])
            ) {
              partyErrors['secondaryPhoneNumber'] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (!isEmpty(party['secondaryPhoneNumber'])) {
              if (party['secondaryPhoneNumber'].length > 25) {
                partyErrors['secondaryPhoneNumber'] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 25 }}
                  />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (
                !regexPhoneNumber.test(party['secondaryPhoneNumber'])
              ) {
                partyErrors['secondaryPhoneNumber'] = (
                  <FormattedMessage {...globalMessages.invalidPhoneNumber} />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            }
          }

          if (
            field === 'secondaryPhoneNumber' &&
            isEmpty(party['secondaryCountryDialingCode']) &&
            !isEmpty(party[field])
          ) {
            partyErrors['secondaryCountryDialingCode'] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
            partiesArrayErrors[index] = partyErrors;
          }
        } else if (
          !isDisabledField(disabledFields, 'po', field) &&
          index === 1 &&
          props?.isInforce
        ) {
          if (
            'addressLine1' === field ||
            'addressLine2' === field ||
            'addressLine3' === field ||
            'city' === field ||
            'country' === field
          ) {
            if (
              isFieldEmpty(party[field]) ||
              party[field] === 'Select an option'
            ) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (party[field].length > 35) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
              partiesArrayErrors[index] = partyErrors;
            } else if (field === 'city') {
              if (!regexNumbers.test(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
                );
                partiesArrayErrors[index] = partyErrors;
              } else if (regexSpecialCharacters.test(party[field])) {
                partyErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.specialCharactersNotAllowed}
                  />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            }

            // else if (regexSpecialCharacters.test(party[field])) {
            //   partyErrors[field] = (
            //     <FormattedMessage
            //       {...globalMessages.specialCharactersNotAllowed}
            //     />
            //   );
            //   partiesArrayErrors[index] = partyErrors;
            // }
          }
        }
      });

      forEach(requiredSelectFields, field => {
        if (!isDisabledField(disabledFields, 'po', field)) {
          if (isFieldEmpty(party[field])) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
            partiesArrayErrors[index] = partyErrors;
          } else if ('countryOfBirth' === field) {
            if (!isDisabledField(disabledFields, 'po', 'countryOfBirth')) {
              if (party[field] === 'Select an option') {
                partyErrors[field] = (
                  <FormattedMessage {...globalMessages.pleaseSelectValue} />
                );
                partiesArrayErrors[index] = partyErrors;
              }
            }
          } else if ('primaryCountryDialingCode' === field) {
            if (party[field] === 'Select') {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
              partiesArrayErrors[index] = partyErrors;
            }
          }
        }
      });

      if (
        !isEmpty(party['postOfficeBoxCity']) &&
        isEmpty(party['postOfficeBoxLine'])
      ) {
        partyErrors['postOfficeBoxLine'] = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
        partiesArrayErrors[index] = partyErrors;
      }
      if (
        isEmpty(party['postOfficeBoxCity']) &&
        !isEmpty(party['postOfficeBoxLine'])
      ) {
        partyErrors['postOfficeBoxCity'] = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
        partiesArrayErrors[index] = partyErrors;
      } else if (!regexNumbers.test(party['postOfficeBoxCity'])) {
        partyErrors['postOfficeBoxCity'] = (
          <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
        );
        partiesArrayErrors[index] = partyErrors;
      } else if (regexSpecialCharacters.test(party['postOfficeBoxCity'])) {
        partyErrors['postOfficeBoxCity'] = (
          <FormattedMessage {...globalMessages.specialCharactersNotAllowed} />
        );
        partiesArrayErrors[index] = partyErrors;
      }

      if (
        parsedValues.parties[index].isTaxPurposes &&
        productCodeCheck(3, productCode)
      ) {
        taxPurposeErrors[index] = '';
        const taxPurposes = parsedValues.parties[index].taxPurposes;
        const purposesErrorArr = [];
        map(taxPurposes, (purposes, i) => {
          const purposesError = {};
          const requiredTextFields = ['taxReferenceNumber'];
          const requiredSelectFields = ['countryOfTax'];

          forEach(requiredTextFields, field => {
            if (!isDisabledField(disabledFields, 'po', field)) {
              if (isFieldEmpty(purposes[field])) {
                purposesError[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              } else if (field === 'taxReferenceNumber') {
                if (purposes[field].length > 30) {
                  purposesError[field] = (
                    <FormattedMessage
                      {...globalMessages.maximumLength}
                      values={{ value1: 30 }}
                    />
                  );
                }
              }
            }
          });

          forEach(requiredSelectFields, field => {
            if (!isDisabledField(disabledFields, 'po', field)) {
              if (isFieldEmpty(purposes[field])) {
                purposesError[field] = (
                  <FormattedMessage {...globalMessages.pleaseSelectValue} />
                );
              } else if (field === 'countryOfTax') {
                if (purposes[field] === 'Select an option') {
                  purposesError[field] = (
                    <FormattedMessage {...globalMessages.pleaseSelectValue} />
                  );
                } else if (isUS(purposes[field])) {
                  purposesError[field] = (
                    <FormattedMessage {...messages.USCitizencannotProceed} />
                  );
                }
              }
            }
          });

          if (!isEmpty(purposesError)) {
            purposesErrorArr[i] = { ...purposesErrorArr[i], ...purposesError };
            const taxPurposesArr = { taxPurposes: purposesErrorArr };
            partiesArrayErrors[index] = {
              ...partiesArrayErrors[index],
              ...taxPurposesArr,
            };
          }
        });
      }

      if (
        !parsedValues.parties[index].isTaxPurposes &&
        productCodeCheck(3, productCode)
      ) {
        if (!isDisabledField(disabledFields, 'po', 'taxPurposes')) {
          taxPurposeErrors.push(
            'At least 1 country of tax residence is required for each policy owner',
          );
          errors.selectTaxPurpose = taxPurposeErrors;
          errors._error = 'submission failed';
        }
      }

      if (index == 1 && (parsedValues.parties[0].email == parsedValues.parties[1].email)) {
        partyErrors['email'] = (
          <FormattedMessage {...messages.isSameEmailId} />
        );
        partiesArrayErrors[1] = partyErrors;
      }

    });
  

    if (partiesArrayErrors.length) {
      errors.parties = partiesArrayErrors;
    }
  }
  return errors;
};

export default validate;
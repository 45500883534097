/**
 *
 * RegisterPage
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import {
  makeSelectInputData,
  makeSelectNotification,
  makeSelectSignUpAccess,
} from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Header from 'components/Header';
import UserForm from './components/UserForm';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import {
  changeInputDataAction,
  submitRegistrationAction,
  setNotificationAction,
  resetStateAction,
} from './actions';
import Footnote from 'components/Footnote';
import Notification from 'components/Notification';
// import SecurityForm from './components/SecurityForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  bodyRegister: {
    maxWidth: 660,
    margin: 'auto',
  },
  titleCheck: {
    fontSize: 26,
    lineHeight: 3,
  },
});

export function RegisterPage({
  inputData,
  changeInputData,
  submitRegistration,
  closeNotification,
  notification,
  resetState,
  isSignUpAccess,
}) {
  const [agentEmail, setAgentEmail] = useState('')

  useInjectReducer({ key: 'registerPage', reducer });
  useInjectSaga({ key: 'registerPage', saga });

  useEffect(() => {
    return () => {
      resetState();
    };
  }, []);

  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();

  const INTERNAL_DOMAINS="uk.zurich.com|zurich.com|zurich.ch";
  const regexForEmail = new RegExp(`^[A-Za-z0-9._%+-]+@(${INTERNAL_DOMAINS})$`);
 const isInternal = regexForEmail.test(agentEmail.trim())

  return (
    <React.Fragment>
      <Header title={<FormattedMessage {...messages.header} />} />
      {!isSignUpAccess ? (
        <React.Fragment>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Container maxWidth="xs">
                <Switch>
                  <Route
                    path="/register/user"
                    render={() => (
                      <UserForm
                        initialValues={{
                          ...inputData,
                        }}
                        onSubmit={immValues => {
                          const values = immValues.toJS();
                          setAgentEmail(values.agentEmail)
                          // changeInputData(values);
                          return submitRegistration(values);
                          // return history.push("/register/security");
                        }}
                      />
                    )}
                  />
                  {/* <Route
                path="/register/security"
                render={() => (
                  <SecurityForm
                    initialValues={{
                      password: '',
                      confirmPassword: '',
                    }}
                    onSubmit={(immValues) => {
                      const values = immValues.toJS();
                      const { password } = values;
                      return submitRegistration({
                        password,
                      });
                    }}
                  />
                )}
              /> */}
                  <Route
                    path="/register"
                    render={() => <Redirect to="/register/user" />}
                  />
                </Switch>
              </Container>
            </Grid>
            <Grid item>
              <Footnote
                display="block"
                style={{
                  marginTop: theme.spacing(3.5),
                }}
              />
            </Grid>
          </Grid>
          <Notification {...notification} onClose={closeNotification} />
        </React.Fragment>
      ) : ( isInternal ? (
          <Typography component="div" className={classes.bodyRegister}>
            <Typography className={classes.titleCheck}>
              <FormattedMessage {...messages.thankYouForReg} />
            </Typography>
            <Typography>
              <FormattedMessage {...messages.youCanUseAccess} />
            </Typography>
          </Typography>
      ) : (
          <Typography component="div" className={classes.bodyRegister}>
          <Typography className={classes.titleCheck}>
            <FormattedMessage {...messages.checkYourEmail} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages.weHaveSent} />
          </Typography>
          <br></br>
          <Typography>
            <FormattedMessage {...messages.ifHaveTrouble} />
          </Typography>
        </Typography>
        )
      )}
    </React.Fragment>
  );
}

RegisterPage.propTypes = {
  changeInputData: PropTypes.func.isRequired,
  submitRegistration: PropTypes.func.isRequired,
  closeNotification: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  inputData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  inputData: makeSelectInputData(),
  notification: makeSelectNotification(),
  isSignUpAccess: makeSelectSignUpAccess(),
});

function mapDispatchToProps(dispatch) {
  return {
    changeInputData: inputData => dispatch(changeInputDataAction(inputData)),
    submitRegistration: inputData =>
      dispatch(submitRegistrationAction(inputData)),
    closeNotification: () => dispatch(setNotificationAction()),
    resetState: () => dispatch(resetStateAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RegisterPage);

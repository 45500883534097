import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the futuraIllustrationPage state domain
 */
const selectIfiIllustrationPageDomain = state =>
  state.get('ifiIllustrationPage', initialState);

/**
 * Other specific selectors
 */
const makeSelectLifeAssured = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('lifeAssured').toJS(),
  );

const makeSelectInitialLifeAssured = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('initialLifeAssured').toJS(),
  );
const makeSelectHarvestLifeAssured = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('lifeAssuredHarvest').toJS(),
  );

const makeSelectError = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('errorData'),
  );

const makeSelectPolicyConfig = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('policyConfig').toJS(),
  );

const makeSelectProductConfig = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('productConfig').toJS(),
  );

const makeSelectFiaCharge = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('fiaData').toJS(),
  );

const makeSelectQuoteId = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('quoteId'),
  );

const makeSelectApplicationId = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('applicationId'),
  );

const makeSelectBiPdf = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('benefitIllustrationPdf'),
  );

const makeSelectStatus = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('status'),
  );

const makeSelectReferenceNumber = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('referenceNumber'),
  );

const makeSelectCloneStatus = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('successClone'),
  );

const makeSelectemailSentStatus = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('emailSent'),
  );

const makeSelectEmailErrorMessage = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('emailErrorMessage'),
  );

const makeSelectCompletedDialog = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('completedDialog'),
  );

const makeSelectIsCalculateQuotePage = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('isCalculateQuotePage'),
  );

const makeSelectValidForIFQ = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('makeSelectValidForIFQ'),
  );

const makeSelectHarvestData = () =>
  createSelector(
    selectIfiIllustrationPageDomain,
    substate => substate.get('harvestData').toJS() || {},
  );

const makeSelectSustainabilityYears = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('sustainabilityYears'),
  );

const makeSelectInitCoverages = () =>
  createSelector(
    selectIfiIllustrationPageDomain,
    substate => substate.get('initCoverages').toJS() || {},
  );

const makeSelectInitPremium = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('initPremium'),
  );

const makeSelectInitProductConfig = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('initProductConfig').toJS(),
  );

const makeSelectGrowthRate = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('growthRate'),
  );
const makeSelectConfig = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('productConfig').toJS(),
  );


const makeShowWarningReview = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('isShowWarningReview'),
  );

const makeSelectPolicyTerm = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('policyTerm'),
  );

const makeSelectManualPremium = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('isManualPremium'),
  );

const makeSelectLastActionEditQuote = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('lastAction'),
  );

const makeSelectPremium = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.getIn(['productConfig', 'premium']),
  );

const makeSelectCountOfCallingSustainAge = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('countOfCallingSustainAge'),
  );
const makeSelectBiTableValues = () =>
  createSelector(selectIfiIllustrationPageDomain, substate =>
    substate.get('biTableValues').toJS(),
  );
  
export {
  selectIfiIllustrationPageDomain,
  makeSelectLifeAssured,
  makeSelectInitialLifeAssured,
  makeSelectProductConfig,
  makeSelectError,
  makeSelectQuoteId,
  makeSelectApplicationId,
  makeSelectBiPdf,
  makeSelectStatus,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
  makeSelectCloneStatus,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectCompletedDialog,
  makeSelectIsCalculateQuotePage,
  makeSelectValidForIFQ,
  makeSelectPolicyConfig,
  makeSelectHarvestData,
  makeSelectSustainabilityYears,
  makeSelectInitCoverages,
  makeSelectInitPremium,
  makeSelectInitProductConfig,
  makeSelectGrowthRate,
  makeShowWarningReview,
  makeSelectPolicyTerm,
  makeSelectManualPremium,
  makeSelectLastActionEditQuote,
  makeSelectPremium,
  makeSelectCountOfCallingSustainAge,
  makeSelectConfig,
  makeSelectBiTableValues,
  makeSelectHarvestLifeAssured
};

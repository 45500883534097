import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import messages from '../../messages.js';
import { map } from 'lodash/collection';
import moment from 'moment';
import { isDate } from 'lodash/lang';
import { getGender, filteredParty } from 'helpers/lang';

import { productCodeCheck } from 'helpers/constants';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(name, date, gender, isSmoker, covered) {
  return { name, date, gender, isSmoker, covered };
}

// const rows = [createData('Mr. John Smith', '02.05.1986', 'Male', 'Smoker')];

function PolicyOwner(props) {
  const theme = useTheme();
  const { parties, classes, applicationDetails } = props;
  const rows = [
    ...map(
      filteredParty(parties, 'isPolicyOwner').sort(function (x, y) {
        //SORT who is PO1
        return (
          y.partyapplicationrelationships?.isPrimaryPolicyOwner -
          x.partyapplicationrelationships?.isPrimaryPolicyOwner
        );
      }),
      party => {
        const dateOfBirth = moment(party.dateOfBirth);
        const isCovered = applicationDetails?.productConfigs.coverages?.filter(element => element?.isPrimary === party?.partyapplicationrelationships?.isPrimaryPolicyOwner)
        let coveredDetails = false;
        if (isCovered?.length > 0 && isCovered[0]?.LFCPTD) {
          coveredDetails = isCovered[0]?.LFCPTD?.selected
        } else if (isCovered?.length > 0 && isCovered[0]?.LFC) {
          coveredDetails = isCovered[0]?.LFC?.selected
        } else if (isCovered?.length > 0 && isCovered[0]?.PTD) {
          coveredDetails = isCovered[0]?.PTD?.selected
        }
        return createData(
          `${party.firstName} ${party.lastName}`,
          dateOfBirth.isValid() ? dateOfBirth.format('DD MMM YYYY') : '-',
          getGender(party.gender),
          party.isSmoke,
          coveredDetails ? 'Covered' : 'Not Covered'
        );
      },
    ),
  ];
  if (rows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.policyOwners} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {rows.map((row, index) => (
                    <StyledTableRow key={`${row.name}-${row.index}`}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: '30%' }}
                      >
                        <Typography variant="h4" className={classes.bold}>
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '30%' }}>
                        <Typography variant="h4">{row.date}</Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: productCodeCheck(8, applicationDetails?.productCode) ? '20%' : '40%' }}>
                        <Typography variant="h4">{row.gender}</Typography>
                      </StyledTableCell>
                      {productCodeCheck(8, applicationDetails?.productCode) &&
                        <StyledTableCell style={{ width: '20%' }}>
                          <Typography variant="h4">{row?.covered}</Typography>
                        </StyledTableCell>
                      }
                      {/* <StyledTableCell>
                        <Typography variant="body1">{row.isSmoker}</Typography>
                      </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  } else return '';
}

PolicyOwner.propTypes = {};

export default PolicyOwner;

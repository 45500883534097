import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Button,
  Box,
  Collapse,
  FormControlLabel,
} from '@material-ui/core';
import NoticeBox from 'components/NoticeBox';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSection, Field } from 'redux-form/immutable';
import messages from 'containers/ApplicationFormPage/messages';
import FormDatePicker from 'components/FormDatePicker';
import FormSelect from 'components/FormSelect';
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import globalMessages from 'containers/App/messages';
import Helper from 'components/Helper';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectPayment } from 'containers/ApplicationFormPage/selectors';
import Dialog from 'components/Dialog';
import FormSwitch from 'components/FormSwitch';
import CardSectionForm from 'components/CardSectionForm';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import {
  changeSubPageListAction,
  logoutAction,
} from 'containers/DashboardPage/actions';
import { convertIfImmutable } from 'helpers/lang';
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';
import { makeSelectOption } from 'containers/App/selectors';
import { useParams, useHistory } from 'react-router-dom';
import { getApplicationDetailsAction } from 'containers/ApplicationPage/actions';
import { useOktaAuth } from '@okta/okta-react';
import CreditCardMethod from './CreditCardMethod';
import {
  setPaymentDataAction,
  updatePaymentType,
  setConvertCurrencyAction,
  setChangeCurrencyAction,
  setPaymentDetail,
  generateCCEmailAction,
} from '../../../../actions';

const lessThan = otherField => (value, previousValue) => {
  if (!value) return '';
  return value <= otherField ? value : previousValue;
};
function CreditCard(props) {
  const {
    isInforce,
    formValues,
    formErrors,
    change,
    paymentStatus,
    setPaymentStatus,
    changeSubPageList,
    setChangeCurrent,
    setConvertCurrency,
    updatePaymentDetail,
    applicationDetails,
    openDialogPayment,
    setOpenDialogPayment,
    options,
    getApplicationDetails,
    logout,
    updatePayment,
    generateCCEmail,
    canSubmit,
  } = props;
  const history = useHistory();
  const { authService } = useOktaAuth();
  const { applicationId } = useParams();
  const [dialog, setDialog] = useState({
    open: false,
    message: null,
  });
  const theme = useTheme();
  const intl = useIntl();

  const resetState = () => {
    setChangeCurrent('');
    setConvertCurrency('');
    changeSubPageList([]);
    updatePayment('');
    setPaymentStatus({ status: null });
    updatePaymentDetail({});
  };

  const onSendEmailAuthorizeCC = ({
    payerEmail,
    preferredDateOfCollection,
    cardCurrency,
  }) => {
    generateCCEmail({
      applicationId,
      email: payerEmail,
      preferredDateOfCollection,
      cardCurrency,
    });
  };

  const handleUsePaymentLink = value => {
    change('paymentDetails.isUseCCDirectly', !value);
  };

  useEffect(() => {
    canSubmit(!formValues?.paymentDetails?.isUseCCDirectly);
  }, [formValues]);

  return (
    <>
      {paymentStatus.status === 'declined' && (
        <NoticeBox style={{ width: 640, marginTop: 20 }} variant="error" mb={4}>
          <Typography variant="body2" color="inherit">
            <FormattedMessage {...messages.paymentErrorDeclined} />
          </Typography>
        </NoticeBox>
      )}
      {!isInforce && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" color="inherit">
              <FormattedMessage {...messages.sharedCCDetail} />
            </Typography>
          </Grid>
          <Grid item>
            <Field
              name="gender"
              type="radio"
              component={FormRadioGroup}
              onChange={(_, val) => {
                change('paymentDetails.isUseCCDirectly', val === 'true');
              }}
            >
              <FormControlLabel
                control={
                  <CustomRadio
                    checked={formValues?.paymentDetails?.isUseCCDirectly}
                  />
                }
                value
                label={<FormattedMessage {...globalMessages.yes} />}
                labelPlacement="end"
              />

              <FormControlLabel
                control={
                  <CustomRadio
                    checked={!formValues?.paymentDetails?.isUseCCDirectly}
                  />
                }
                value={false}
                label={
                  <p
                    style={{
                      margin: 0,
                      lineHeight: 1.2,
                      position: 'relative',
                      top: '6px',
                    }}
                  >
                    <FormattedMessage {...messages.paymentLink} />
                    <br />
                    <FormattedMessage {...messages.paymentLink2} />
                  </p>
                }
                labelPlacement="end"
              />
            </Field>
          </Grid>
        </Grid>
      )}
      {formValues?.paymentDetails?.isUseCCDirectly && (
        <Box mt={isInforce ? 0 : 3} width="100%">
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            <Grid
              container
              style={{
                marginBottom: theme.spacing(3),
                marginTop: theme.spacing(2),
              }}
            >
              <Grid item style={{ width: 247 }}>
                <Field
                  name="card_holder_name"
                  component={FormTextField}
                  fullWidth
                  label={<FormattedMessage {...messages.nameAsPerCard} />}
                  placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
                  shrink
                />
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: theme.spacing(3) }}>
              <Grid item style={{ width: 355 }}>
                <Field
                  name="card_number"
                  component={FormTextField}
                  fullWidth
                  label={<FormattedMessage {...messages.cardNumber} />}
                  placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
                  allowLeadingZero
                  type="text"
                  thousandSeparator=""
                  // inputFormat="################"
                  shrink
                  normalize={lessThan(10000000000000000)}
                  margin="normal"
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ marginBottom: theme.spacing(1) }}
            >
              <Grid item style={{ width: 196 }}>
                <Field
                  name="expiry_date"
                  views={['year', 'month']}
                  component={FormDatePicker}
                  type="text"
                  label={<FormattedMessage {...messages.expiry} />}
                  margin="normal"
                  fullWidth
                  shrink
                  placeholder="MMYY"
                  dateInputFormat="MM/YY"
                  minDate={new Date().setFullYear(new Date().getFullYear())}
                  maxDate={new Date().setFullYear(
                    new Date().getFullYear() + 10,
                  )}
                />
              </Grid>
              <Grid item style={{ width: 136 }}>
                <Field
                  name="card_security_code"
                  style={{ marginTop: '.7rem' }}
                  component={FormNumberField}
                  allowLeadingZero
                  type="text"
                  fullWidth
                  placeholder="***"
                  shrink
                  inputFormat="###"
                  label={
                    <>
                      <FormattedMessage {...messages.cvc} />
                      <Helper
                        helperText={intl.formatMessage(messages.infomationCVC)}
                        placement="right"
                      />
                    </>
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ marginBottom: theme.spacing(3) }}
            >
              <Grid item style={{ width: 284 }}>
                <Field
                  name="cardCurrency"
                  fullWidth
                  component={FormSelect}
                  options={[
                    {
                      value: 'Select an option',
                      title: 'Select an option',
                    },
                    ...options.cardCurrency,
                  ]}
                  label={<FormattedMessage {...messages.cardCurrency} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  margin="normal"
                />
              </Grid>
              <Grid item style={{ width: 136 }}>
                <Field
                  shrink
                  name="preferredDateOfCollection"
                  component={FormNumberField}
                  decimalScale={0}
                  label={<FormattedMessage {...messages.collectionDay} />}
                  // dateInputFormat="DD"
                  placeholder="DD"
                  type="text"
                  margin="normal"
                  // minDate={''}
                  // maxDate={''}
                />
              </Grid>
              <Field component="input" type="hidden" name="service_command" />
              <Field component="input" type="hidden" name="access_code" />
              <Field
                component="input"
                type="hidden"
                name="merchant_identifier"
              />
              <Field
                component="input"
                type="hidden"
                name="merchant_reference"
              />
              <Field component="input" type="hidden" name="language" />
              <Field component="input" type="hidden" name="return_url" />
              <Field component="input" type="hidden" name="creditCardMethod" />
            </Grid>
          </Grid>
        </Box>
      )}
      {!formValues?.paymentDetails?.isUseCCDirectly && (
        <Box mt={3}>
          <FormSection
            name="payerDetail"
            component={CreditCardMethod}
            sendEmailAuthorizeCC={onSendEmailAuthorizeCC}
            formValues={formValues}
            formErrors={formErrors}
            options={options}
            onUsePaymentLink={handleUsePaymentLink}
          />
        </Box>
      )}
      {/* Success Dialog */}
      <Dialog
        open={
          paymentStatus.status === 'completed' ||
          convertIfImmutable(applicationDetails).paymentStatus ===
            'PENDING_PAYMENT' ||
          dialog.open
        }
        onClose={() => {
          setDialog({
            ...dialog,
            open: false,
          });
          setPaymentStatus('');
          getApplicationDetails(applicationId);
        }}
        onExited={() => {
          setDialog({
            ...dialog,
            message: null,
          });
          getApplicationDetails(applicationId);
        }}
        variant="success"
        title="Success"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={3}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                style={{ minWidth: 165 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  logout(authService);
                }}
              >
                <FormattedMessage {...messages.logout} />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ minWidth: 165 }}
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  resetState();
                  history.push('/dashboard');
                }}
              >
                <FormattedMessage {...messages.goToDashboard} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage {...messages.thankYou} />
        </Typography>
      </Dialog>

      {/*Dialog for NEXUS and FAB agencies*/}
      <Dialog
        open={
          paymentStatus.status === 'intermediate' ||
          dialog.open
        }
        onClose={() => {
          setDialog({
            ...dialog,
            open: false,
          });
          setPaymentStatus('');
          getApplicationDetails(applicationId);
        }}
        onExited={() => {
          setDialog({
            ...dialog,
            message: null,
          });
          getApplicationDetails(applicationId);
        }}
        variant="success"
        title="Success"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={3}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                style={{ minWidth: 165 }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  logout(authService);
                }}
              >
                <FormattedMessage {...messages.logout} />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                style={{ minWidth: 165 }}
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  resetState();
                  history.push('/dashboard');
                }}
              >
                <FormattedMessage {...messages.goToDashboard} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage {...messages.thankYouForAdminReview} />
        </Typography>
      </Dialog>

      {/* Warning Dialog */}
      <Dialog
        open={
          convertIfImmutable(applicationDetails).paymentStatus ===
            'COMPLETED' && openDialogPayment
        }
        onClose={() => {
          setOpenDialogPayment(false);
        }}
        onExited={() => {
          setOpenDialogPayment(false);
        }}
        variant="warning"
        title="Warning"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => setOpenDialogPayment(false)}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          Sorry, Your application has been payed.
        </Typography>
      </Dialog>
    </>
  );
}

CreditCard.propTypes = {
  paymentStatus: PropTypes.string,
  isInforce: PropTypes.bool,
  formValues: PropTypes.object,
  formErrors: PropTypes.object,
  setPaymentStatus: PropTypes.func,
  options: PropTypes.array,
  setChangeCurrent: PropTypes.func,
  setConvertCurrency: PropTypes.func,
  updatePaymentDetail: PropTypes.func,
  applicationDetails: PropTypes.func,
  openDialogPayment: PropTypes.func,
  setOpenDialogPayment: PropTypes.func,
  getApplicationDetails: PropTypes.func,
  logout: PropTypes.func,
  changeSubPageList: PropTypes.func,
  change: PropTypes.func,
  updatePayment: PropTypes.func,
  generateCCEmail: PropTypes.func,
  canSubmit: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  paymentStatus: makeSelectPayment(),
  applicationDetails: makeSelectApplicationDetails(),
  options: makeSelectOption(),
});

function mapDispatchToProps(dispatch) {
  return {
    setPaymentStatus: data => dispatch(setPaymentDataAction(data)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    updatePayment: value => dispatch(updatePaymentType(value)),
    setConvertCurrency: data => dispatch(setConvertCurrencyAction(data)),
    setChangeCurrent: change => dispatch(setChangeCurrencyAction(change)),
    updatePaymentDetail: values => dispatch(setPaymentDetail(values)),
    getApplicationDetails: value =>
      dispatch(getApplicationDetailsAction(value)),
    logout: value => dispatch(logoutAction(value)),
    generateCCEmail: data => dispatch(generateCCEmailAction(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CreditCard);

import React, { useState, useEffect } from 'react';
import { api, payfort } from 'environments';
import {
  Grid,
  Box,
  Typography,
  Radio,
  Button,
  Divider,
  Link,
} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { map } from 'lodash/collection';
import { get } from 'lodash/object';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';
import InfoRow from 'components/InfoRow';
import Dialog from 'components/Dialog';
import FormCheck from 'components/FormCheck';

import { convertIfImmutable } from 'helpers/lang';
import blue from '@material-ui/core/colors/blue';
import DescriptionIcon from '@material-ui/icons/Description';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages';
import {
  reduxForm,
  Form,
  FormSection,
  InjectedFormProps,
  getFormValues,
  getFormSyncErrors,
  Field,
} from 'redux-form/immutable';
import { isEmpty } from 'lodash';
import {
  requestSignsAction,
  getApplicationEnvelopAction,
  refreshApplicationEnvelopAction,
  updateApplicationFundsAction2,
} from 'containers/ApplicationFormPage/actions';
import {
  makeSelectConsentStatus,
  makeSelectConsentLink,
  makeSelectFundsBelongToNexusOrDever,
} from 'containers/ApplicationFormPage/selectors';
import { createFileAction } from 'containers/App/actions';
import { makeSelectFile, makeSelectOption } from 'containers/App/selectors';
import { productCodeCheck } from 'helpers/constants';
import red from '@material-ui/core/colors/red';
import BenefitRatingSummary from 'components/BenefitRatingSummary';
import PartyDetails from './PartyDetails';
import PolicySummary from './PolicySummary';
import Payor from './Payor';
import Withdrawals from './Withdrawals';
import PremiumSummary from './PremiumSummary';
import Beneficiaries from './Beneficiaries';
import InsuredParty from './insuredParty';
import PolicyOwner from './PolicyOwner';

const useStyle = makeStyles(() => ({
  container: {
    maxWidth: '820px',
  },
  addExternalNumber: {
    color: '#717171',
  },
  addExternalNumberIcon: {
    height: '1.3rem',
    color: '#717171',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexJustifyCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  capitalizeAllCaps: {
    textTransform: 'lowercase',
    display: 'inline-block',
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  // whiteButton: {},
  button: {
    minWidth: '200px',
  },
  bold: { fontWeight: 800 },
  buttonWhite: {
    // marginTop: '1rem',
    minWidth: '200px',
    border: `2px solid #4066B3`,
    backgroundColor: 'white',
    color: '#4066B3',
    '&:hover': {
      backgroundColor: blue[900],
      border: `2px solid ${blue[900]}`,
      color: 'white',
    },
  },
  link: {
    color: '#00C3B4',
  },
  textTitle: {
    color: '#043594',
    fontWeight: 'bold',
    marginTop: -20,
  },
  confirmAndSendBtn: {
    padding: '18px 15px',
    backgroundColor: '#043594',
  },
  error: {
    color: red[800],
    fontWeight: 600,
  },
}));
function Consent(props) {
  const classes = useStyle();
  // const history = useHistory();
  // const intl = useIntl();
  const theme = useTheme();
  // const [consentLink, setConsentLink] = useState('');
  const {
    options,
    handleSubmit,
    onSubmit,
    applicationDetails,
    requestSigns,
    consentStatus,
    getApplicationEnvelop,
    refreshApplicationEnvelop,
    formValues,
    canGoBack,
    match,
    canSubmit,
    createFile,
    file,
    updateApplicationDetails,
  } = props;
  const [fundsBelongToNexusOrDever, setFundsBelongToNexusOrDever] = useState(
    false,
  );
  const parsedApplicationDetails = convertIfImmutable(applicationDetails);
  const { applicationId } = match.params;
  const isBolton = convertIfImmutable(applicationDetails).isBolton;
  const productConfigs = isBolton
    ? convertIfImmutable(applicationDetails).productConfigs[0]
    : convertIfImmutable(applicationDetails).productConfigs;

  useEffect(() => {
    canGoBack(!(applicationDetails.status === 'VERIFY_DOC_COMPLETED'));
    getApplicationEnvelop({
      applicationId,
    });
    createFile({ applicationId, isSummary: false, type: 'POLICY_SUMMARY' });
  }, []);

  // const checkSubmit = () => {
  //   let flag = false;
  //   if (paymentType !== null) {
  //     flag = true;
  //   }
  //   return flag;
  // };

  useEffect(() => {
    if (consentStatus.data?.envelopeId) {
      refreshApplicationEnvelop({
        applicationId,
      });
    }
    // consentStatus.toJS().data?.status !== 'CONSENT_COMPLETED'
    canSubmit(applicationDetails.status !== 'CONSENT_COMPLETED');
  }, [consentStatus]);

  useEffect(() => {
    if (!isEmpty(parsedApplicationDetails)) {
      setFundsBelongToNexusOrDever(
        parsedApplicationDetails?.productConfigs?.fundsBelongToNexusOrDevere,
      );
    }
  }, [applicationDetails]);

  const [dialog, setDialog] = useState({
    open: false,
    message: null,
  });

  const [status, setStatus] = useState(formValues.isAccept);
  const [haveFiaCharged, setHaveFiaCharged] = useState(
    formValues.haveFiaCharged,
  );

  const convertPartyId = () => {
    const emailArr = { ...formValues };
    for (const item in emailArr.partyDetails) {
      if (isEmpty(emailArr.partyDetails[item])) {
        delete emailArr.partyDetails[item];
      }
    }
    return emailArr;
  };

  // Check condition for party email if all party email are  all fill or not
  const validatePartyEmail = () => {
    const arrayEmail = [];
    Object.keys(convertPartyId().partyDetails).map((key, index) => {
      arrayEmail.push(convertPartyId().partyDetails[key]);
    });
    //return !arrayEmail.every(item => item.email !== null);
    return checkDuplicatesOrEmpty(arrayEmail)
  };

  function checkDuplicatesOrEmpty(arr) {
    const n = arr.length;
    const st = new Set();
    for (let i = 0; i < n; i++) {
        if(!arr[i].email) return true
        if (st.has(arr[i].email.trim())) return true;
        else st.add(arr[i].email.trim());
    }
    // If no duplicates are found, return false
    return false;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4} className={classes.container}>
        {/* POLICY OWNER */}
        <PolicyOwner
          parties={convertIfImmutable(applicationDetails).parties}
          classes={classes}
          applicationDetails={convertIfImmutable(applicationDetails)}
        />
        {/* INSURANCE PARTY */}
        <InsuredParty
          parties={convertIfImmutable(applicationDetails).parties}
          applicationDetails={convertIfImmutable(applicationDetails)}
          classes={classes}
        />
        {/* BENEFICIARIES PARTY */}
        <Beneficiaries
          options={options}
          parties={convertIfImmutable(applicationDetails).parties}
          classes={classes}
        />
        {/* PAYOR PARTY */}
        <Payor
          parties={convertIfImmutable(applicationDetails).parties}
          classes={classes}
        />
        {/* Premium SUMMARY */}
        <PremiumSummary
          applicationDetails={convertIfImmutable(applicationDetails)}
        />

        <Withdrawals
          applicationDetails={convertIfImmutable(applicationDetails)}
        />
        {/* POLICY SUMMARY */}
        {(!convertIfImmutable(applicationDetails).specialTermsFlag &&
          !productCodeCheck(8, convertIfImmutable(applicationDetails).productCode)) && (
            <PolicySummary
              productConfigs={productConfigs}
              parties={convertIfImmutable(applicationDetails).parties}
              title={<FormattedMessage {...messages.coverDetails} />}
            />
          )}

        {/* POLICY SUMMARY */}
        {isBolton &&
          !convertIfImmutable(applicationDetails).specialTermsFlag && (
            <PolicySummary
              productConfigs={
                convertIfImmutable(applicationDetails).productConfigs[1]
              }
              parties={convertIfImmutable(applicationDetails).parties}
              title={<FormattedMessage {...messages.protectionCoverDetails} />}
            />
          )}
        {!productCodeCheck(8, convertIfImmutable(applicationDetails).productCode) &&
          <Grid item xs={12} md={10} style={{ paddingTop: 0 }}>
            <BenefitRatingSummary
              isBolton={convertIfImmutable(applicationDetails).isBolton}
              productConfigs={
                convertIfImmutable(applicationDetails).isBolton
                  ? convertIfImmutable(applicationDetails).productConfigs[1]
                  : convertIfImmutable(applicationDetails).productConfigs
              }
            />
          </Grid>
        }

        <Grid item xs={12} md={10}>
          <Box mt={2} mb={1}>
            <Divider />
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
          <Box mb={2}>
            <Typography>
              <FormattedMessage {...messages.proceedSendSignature} />
            </Typography>
          </Box>
          <InfoRow variant="document">
            <Grid container spacing={2}>
              <Grid item>
                <DescriptionIcon />
              </Grid>
              <Grid item xs>
                <Typography variant="body1">
                  <FormattedMessage {...messages.policyDocuments} />
                </Typography>
              </Grid>
              {/* //CONSENTLINK */}
              <Grid item xs={3}>
                <div className={classes.flexEnd}>
                  <Link
                    disabled={!file.url}
                    color="inherit"
                    variant="body2"
                    underline="none"
                    className={classes.link}
                    onClick={e => {
                      if (file.url) {
                        window.open(file.url);
                      }
                    }}
                  >
                    <FormattedMessage {...messages.viewExportPDF} />
                  </Link>
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={classes.flexEnd}>
                  {(get(
                    parsedApplicationDetails,
                    'docusignEnvelop.error',
                    false,
                  ) ||
                    parsedApplicationDetails.docusignEnvelop === null) &&
                    !parsedApplicationDetails.missingKFS ? (
                    <Link
                      href="#"
                      onClick={e => {
                        e.preventDefault();

                        setDialog({
                          ...dialog,
                          open: true,
                        });
                      }}
                      color="inherit"
                      variant="body2"
                      underline="none"
                      className={classes.link}
                    >
                      <FormattedMessage {...messages.sendForSignature} />
                    </Link>
                  ) : (
                    <Tooltip
                      title={
                        parsedApplicationDetails.missingKFS
                          ? 'The Quote is invalid. Please regenerate new one.'
                          : ''
                      }
                      placement="top"
                    >
                      <Link
                        disabled
                        color="inherit"
                        variant="body2"
                        underline="none"
                        style={{ color: '#a1a6a8' }}
                      >
                        <FormattedMessage {...messages.sendForSignature} />
                      </Link>
                    </Tooltip>
                  )}
                </div>
              </Grid>
            </Grid>
          </InfoRow>
          {parsedApplicationDetails.docusignEnvelop?.error && (
            <Typography variant="body1" className={classes.error}>
              {parsedApplicationDetails.docusignEnvelop?.message}
            </Typography>
          )}
        </Grid>
        <Dialog
          open={dialog.open}
          onClose={() => {
            setDialog({
              ...dialog,
              open: false,
            });
          }}
          onExited={() =>
            setDialog({
              ...dialog,
              message: null,
            })
          }
          variant="info"
          title="Please confirm parties"
          actions={
            <Grid
              container
              alignItems="center"
              wrap="nowrap"
              justify="center"
              spacing={2}
            >
              <Grid item xs={4}>
                <Button
                  classes={{ root: classes.confirmAndSendBtn }}
                  fullWidth
                  disabled={
                    validatePartyEmail() ||
                    (fundsBelongToNexusOrDever && !haveFiaCharged)
                  }
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setDialog({
                      open: false,
                      message: null,
                    });
                    requestSigns({
                      applicationId,
                      parties: convertPartyId(),
                      status,
                    });

                    if (haveFiaCharged) {
                      if (isBolton) {
                        updateApplicationDetails({
                          ...parsedApplicationDetails,
                          productConfigs: map(
                            parsedApplicationDetails.productConfigs,
                            config => {
                              return { ...config, haveFiaCharged };
                            },
                          ),
                        });
                      } else {
                        updateApplicationDetails({
                          ...parsedApplicationDetails,
                          productConfigs: {
                            ...parsedApplicationDetails?.productConfigs,
                            haveFiaCharged,
                          },
                        });
                      }
                    }
                  }}
                >
                  <FormattedMessage {...messages.confirmAndSend} />
                </Button>
              </Grid>
            </Grid>
          }
        >
          <FormSection
            name="partyDetails"
            component={PartyDetails}
            classes={classes}
            applicationProductCode={parsedApplicationDetails?.productCode}
            isInforce={parsedApplicationDetails?.isInforce}
            newLifeAdded={
              parsedApplicationDetails?.inforceComparerChecks?.isNewLifeAdded
            }
          />
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            alignContent="center"
          >
            <Divider style={{ width: '100%', marginTop: theme.spacing(1) }} />
            <Box mb={1} mt={2}>
              <Field
                shrink="true"
                name="isAccept"
                margin="normal"
                fullWidth
                component={FormCheck}
                label={
                  <Typography style={{ fontSize: '16px', maxWidth: '435px' }}>
                    <FormattedMessage {...messages.consentContacted} />
                  </Typography>
                }
                type="checkbox"
                color="inherit"
                margin="normal"
                variant="standard"
                onChange={() => {
                  // validateBtn();
                  setStatus(!status);
                }}
              />
            </Box>
            {fundsBelongToNexusOrDever && (
              <Box mb={1} mt={2}>
                <Field
                  shrink="true"
                  name="haveFiaCharged"
                  margin="normal"
                  fullWidth
                  component={FormCheck}
                  label={
                    <Typography style={{ fontSize: '16px', maxWidth: '435px' }}>
                      <FormattedMessage {...messages.fundError} />
                    </Typography>
                  }
                  type="checkbox"
                  color="inherit"
                  margin="normal"
                  variant="standard"
                  onChange={() => {
                    // validateBtn();
                    setHaveFiaCharged(!haveFiaCharged);
                  }}
                />
              </Box>
            )}
          </Grid>
        </Dialog>
      </Grid>
    </form>
  );
}

Consent.propTypes = { ...InjectedFormProps };

const mapStateToProps = createStructuredSelector({
  applicationDetails: makeSelectApplicationDetails(),
  consentStatus: makeSelectConsentStatus(),
  consentLink: makeSelectConsentLink(),
  file: makeSelectFile(),
  options: makeSelectOption(),
}); // not really required but mostly we will use this in the future

function mapDispatchToProps(dispatch) {
  return {
    requestSigns: value => dispatch(requestSignsAction(value)),
    getApplicationEnvelop: value =>
      dispatch(getApplicationEnvelopAction(value)),
    refreshApplicationEnvelop: value =>
      dispatch(refreshApplicationEnvelopAction(value)),
    createFile: data => dispatch(createFileAction(data)),
  };
}

const withFormSelector = connect(state => {
  const formValues = getFormValues('Consent')(state);
  const formErrors = getFormSyncErrors('Consent')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  // validate, <-ignore this first for now
  form: 'Consent',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(Consent));

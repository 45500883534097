import { withStyles } from '@material-ui/core/styles';
import { Radio } from '@material-ui/core';

const styles = () => ({
  root: {
    display: 'none',
  },
});

export default withStyles(styles)(Radio);

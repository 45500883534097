/*
 *
 * RegisterPage constants
 *
 */

export const DEFAULT_ACTION = 'app/RegisterPage/DEFAULT_ACTION';
export const CHANGE_INPUT_DATA_ACTION = 'app/RegisterPage/CHANGE_INPUT_DATA_ACTION';
export const SUBMIT_REGISTRATION_ACTION = 'app/RegisterPage/SUBMIT_REGISTRATION_ACTION';
export const SET_NOTIFICATION_ACTION = 'app/RegisterPage/SET_NOTIFICATION_ACTION';
export const RESET_STATE_ACTION = 'app/RegisterPage/RESET_STATE_ACTION';

export const RESPONSE_USER_EXISTED = 'USER_EXISTED';
export const RESPONSE_AGENT_NOT_FOUND = 'AGENT_NOT_FOUND';
export const RESPONSE_NOT_ENOUGH_INFORMATION_FROM_ZIO_TO_SIGN_UP  = 'NOT_ENOUGH_INFORMATION_FROM_ZIO_TO_SIGN_UP';

export const ERROR_RESPONSE_MESSAGE = {
  [RESPONSE_USER_EXISTED]: 'errorUserExisted',
  [RESPONSE_AGENT_NOT_FOUND]: 'errorAgentNotFound',
  [RESPONSE_NOT_ENOUGH_INFORMATION_FROM_ZIO_TO_SIGN_UP]: 'errorNotEnoughInformationFromZio',
};

export const SET_SIGN_UP_SUCCESS_ACTION = 'app/RegisterPage/SET_SIGN_UP_SUCCESS_ACTION';

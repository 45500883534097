import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import { newBankAccount } from 'helpers/placeholder';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import {
  changePlaceHolderColor,
} from 'helpers/lang';

const useStyle = makeStyles(() => ({
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  subTitle: {
    fontSize: '1.4rem',
    transform: 'none',
    fontWeight: 'bold',
    transformOrigin: 'top left',
    marginBottom: 5,
  },
  titleAccount: {
    marginTop: 15,
    fontSize: 14,
    color: '#B6B8B4',
  },
}));

function BankAccount(props) {
  const intl = useIntl();
  const classes = useStyle();

  const { formValues, options } = props;

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Field
          name={`bankName`}
          component={FormSelect}
          style={{
            width: 247,
            color: changePlaceHolderColor(
              formValues?.bankAccountDetail?.bankName,
            ),
          }}
          options={[
            { value: 'Select an option', title: 'Select an option' },
            ...options
          ]}
          label={<FormattedMessage {...messages.bankName} />}
          placeholder={intl.formatMessage(messages.pleaseEnter)}
          type="text"
          color="primary"
          margin="normal"
          variant="standard"
        />
      </Grid>
      <Grid item xs={12} style={{marginTop: 24}}>
        <Field
          name={`cardNumber`}
          component={FormNumberField}
          thousandSeparator={''}
          allowLeadingZero={true}
          label={<FormattedMessage {...messages.accountNumber} />}
          placeholder={intl.formatMessage(messages.pleaseEnter)}
          type="text"
          color="primary"
          margin="normal"
          variant="standard"
          style={{ width: 355 }}
        />
      </Grid>
      <Grid item xs={12} style={{marginTop: 24}}>
        <Typography className={classes.subTitle}>
          <FormattedMessage {...messages.accountHeld} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Field
              style={{ width: 134 }}
              name={`yearNumber`}
              component={FormNumberField}
              thousandSeparator={''}
              placeholder={intl.formatMessage(messages.pleaseEnter)}
              type="text"
              color="primary"
              margin="normal"
              variant="standard"
            />
          </Grid>
          <Grid item xs={2} className={classes.titleAccount}>
            <FormattedMessage {...messages.years} />
          </Grid>
          <Grid item xs={4}>
            <Field
              name={`monthNumber`}
              style={{ width: 134 }}
              component={FormNumberField}
              thousandSeparator={''}
              placeholder={intl.formatMessage(messages.pleaseEnter)}
              type="text"
              color="primary"
              margin="normal"
              variant="standard"
            />
          </Grid>
          <Grid item xs={2} className={classes.titleAccount}>
            <FormattedMessage {...messages.months} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
BankAccount.propTypes = {
  fields: PropTypes.object,
  formValues: PropTypes.object,
  isBankAccount: PropTypes.bool,
  options: PropTypes.object,
};

export default BankAccount;

import React from 'react';
import LoadingDialog from 'components/LoadingDialog';

function PayfortCallback() {
  return <LoadingDialog isLoading />;
}

PayfortCallback.propTypes = {};

export default PayfortCallback;

import React, { useEffect } from 'react';
import { Grid, Box, FormControlLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { map } from 'lodash/collection';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  reduxForm,
  InjectedFormProps,
  Field,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import {
  updatePaymentType,
  setPaymentDataAction,
} from 'containers/ApplicationFormPage/actions';
import { makeSelectPaymentType } from 'containers/ApplicationFormPage/selectors';
import { isEmpty } from 'lodash';
import { convertIfImmutable, isFABAgency, isADCBAgency } from 'helpers/lang';
import { FormattedMessage } from 'react-intl';
import CustomRadio from 'components/CustomRadioButton';
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';

import { productCodeCheck } from 'helpers/constants';
import messages from '../../messages';
import FormCheck from 'components/FormCheck';


const useStyle = makeStyles(() => ({
  containerWrapper: {
    width: '660px',
  },
  radioButton: {
    display: 'block',
    marginBottom: 14,
    '& .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    },
  },
}));

var chkFABAgency = false;

const makePaymentMethodByPremiumFrequency = (frequency, applicationDetail) => {
  chkFABAgency = false;
  // if (applicationDetail.agencyNumber != null && 
  //   (applicationDetail.agencyNumber === "8904162" ||  
  //   applicationDetail.agencyNumber === "8904791" ||
  //   applicationDetail.agencyNumber === "8905878")) {
    if (applicationDetail.agencyNumber != null && 
      isFABAgency(applicationDetail.agencyNumber)) { 
        chkFABAgency = true;
    return [
      {
        id: 'STANDING_ORDER',
        name: 'Standing order',
      },
    ];
  }
  
  if (applicationDetail.agencyNumber != null && 
    isADCBAgency(applicationDetail.agencyNumber)) {
      if (frequency?.toUpperCase() === 'MONTHLY') {
        return [
          {
            id: 'BANK_TRANSFER',
            name: 'Bank transfer',
          },
          {
            id: 'STANDING_ORDER',
            name: 'Standing order',
          },
        ];
      } else {
        return [
          {
            id: 'CHEQUE',
            name: 'Cheque',
          },
          {
            id: 'BANK_TRANSFER',
            name: 'Bank transfer',
          },
          {
            id: 'STANDING_ORDER',
            name: 'Standing order',
          },
        ];
      }
    }

  if (
    frequency === 'SinglePremium' ||
    productCodeCheck(6, applicationDetail.productCode)
  ) {
    return [
      {
        id: 'CHEQUE',
        name: 'Cheque',
      },
      {
        id: 'BANK_TRANSFER',
        name: 'Bank transfer',
      },
      {
        id: 'STANDING_ORDER',
        name: 'Standing order',
      },
    ];
  }

  if (frequency?.toUpperCase() === 'MONTHLY') {
    return [
      {
        id: 'CREDIT_CARD',
        name: 'Credit card',
      },
      {
        id: 'BANK_TRANSFER',
        name: 'Bank transfer',
      },
      {
        id: 'STANDING_ORDER',
        name: 'Standing order',
      },
    ];
  }

  return [
    {
      id: 'CREDIT_CARD',
      name: 'Credit card',
    },
    {
      id: 'CHEQUE',
      name: 'Cheque',
    },
    {
      id: 'BANK_TRANSFER',
      name: 'Bank transfer',
    },
    {
      id: 'STANDING_ORDER',
      name: 'Standing order',
    },
  ];
};


function Payment(props) {
  const classes = useStyle();
  const {
    handleSubmit,
    onSubmit,
    paymentType,
    applicationDetail,
    initialValues,
    canSubmit,
    canGoBack,
    setPaymentStatus,
    updatePayment,
  } = props;

  const [checked,setChecked] = React.useState(false);  

  // Check condition to enable or disable Next button
  const checkSubmit = () => { 
    let flag = false;
    if (!chkFABAgency && paymentType !== null) {
      flag = true;
    } else if (chkFABAgency && checked && paymentType !== null) {
      flag = true;
    }
    return flag;
  };

 
  const application = applicationDetail.toJS();
  useEffect(() => {
    canSubmit(!checkSubmit());
  }, [paymentType,checked]);

  useEffect(() => {
    setPaymentStatus({ status: null });
    setChecked(false);
  }, []);

  useEffect(() => {
    canGoBack(false);
    if (!isEmpty(convertIfImmutable(initialValues))) {
      updatePayment(convertIfImmutable(initialValues).paymentMethod);
    }
  }, []);

  const paymentMethodArray = makePaymentMethodByPremiumFrequency(
    application.premiumFrequency === ''
      ? application.term
      : application.premiumFrequency,
    application,
  );

  const handleChange = paymentID => {
    updatePayment(paymentID);
  };

  const handlechangeCheckBox = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked && paymentType != null) {
      console.log ("INFO : Method of Payment is Chosen by FAB agent and the disclaimer is also agreed");
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={0} className={classes.containerWrapper}>
        <Grid item xs={12}>
          <Box mb={2}>
            <Typography variant="body2" style={{ fontWeight: ' bold' }}>
              <FormattedMessage {...messages.howWillThePolicyBePaid} />
            </Typography>
          </Box>
          {map(paymentMethodArray, (payment, index) => (
            <FormControlLabel
              key={`${payment.id}-${index}`}
              control={
                <CustomRadio
                  checked={payment.id === paymentType}
                  onClick={() => handleChange(payment.id)}
                />
              }
              value={payment.id}
              label={`${payment.name}`}
              labelPlacement="end"
              className={classes.radioButton}
            />
          ))}
        </Grid>
      </Grid>
      <Box mb={23}></Box>
      {chkFABAgency ?
      (<Grid container item justify="center" alignItems="center" style={{width: '900px'}}> 
        <Field
            shrink="true"
            name="termsAndConditions"
            margin="normal"
            fullWidth
            component={FormCheck}
            label={
              <Typography variant="body2">
                <FormattedMessage {...messages.policyTermsAndCond} />
              </Typography>
            }
            type="checkbox"
            color="inherit"
            variant="standard"
            checked = {checked}
            onChange = {handlechangeCheckBox}
        />
        </Grid>) : (
        <Grid item xs={12}>
        </Grid>
        )}
    </form>
  );
}

Payment.propTypes = { ...InjectedFormProps };

const mapStateToProps = createStructuredSelector({
  paymentType: makeSelectPaymentType(),
  applicationDetail: makeSelectApplicationDetails(),
}); // not really required but mostly we will use this in the future

function mapDispatchToProps(dispatch) {
  return {
    updatePayment: value => dispatch(updatePaymentType(value)),
    setPaymentStatus: data => dispatch(setPaymentDataAction(data)),
  };
}

const withFormSelector = connect(state => {
  const formValues = getFormValues('Payment')(state);
  const formErrors = getFormSyncErrors('Payment')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default reduxForm({
  // validate, <-ignore this first for now
  form: 'Payment',
})(compose(withConnect, withFormSelector)(Payment));
// import { fromJS } from 'immutable';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
// import numeral from 'numeral';
import { dobFormat } from 'helpers/constants';
import messages from './messages';

const benefitCodesType2 = ['WOP', 'ALC', 'ACD'];

/** HELPER */
export function getCoverages(gcConfig) {
  var coverages = [];
  gcConfig.productBenefits.mandatoryList.map(benefit => {
    if (!benefit.isComplimentary)
      coverages.push({
        type: '2',
        selected: benefit.isRequired ? true : false,
        disabled: benefit.isRequired,
        id: benefit.benefitCode,
        title: benefit.benefitName,
        value: false,
        term: 0,
        maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
        possibleValues: benefit.possibleValues,
      });
  });

  gcConfig.productBenefits.optionalList.map(benefit => {
    coverages.push({
      type: '2',
      selected: true,
      disabled: benefit.isRequired,
      id: benefit.benefitCode,
      title: benefit.benefitName,
      value: benefit.benefitCode !== 'ESC' ? false : '0',
      valueInsuredTwo: benefitCodesType2.includes(benefit.benefitCode)
        ? false
        : '',
      term: 0,
      maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
      possibleValues: benefit.possibleValues,
    });
  });
  return coverages;
}

export function calculateAgeNextBirthdate(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  var monthDayPoint = 0;
  if (policyStartDateMonth == bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}

export function getMinValue(paymentFrequency, gcConfig, currency) {
  var minValue = 0;
  gcConfig.currencyPremiumSize.map(gcConfingPremium => {
    if (currency === gcConfingPremium.currency) {
      switch (paymentFrequency) {
        case 'Single':
          minValue = gcConfingPremium.minPremiumSingle;
          break;

        case 'Monthly':
          minValue = gcConfingPremium.minPremiumMonthly;
          break;

        case 'Quarterly':
          minValue = gcConfingPremium.minPremiumQuarterly;
          break;

        case 'Half-Yearly':
          minValue = gcConfingPremium.minPremiumHalfYearly;
          break;

        case 'Yearly':
          minValue = gcConfingPremium.minPremiumYearly;
          break;

        default:
          minValue = gcConfingPremium.minPremiumMonthly;
          break;
      }
    }
  });
  return minValue;
}

export function getMaxValue(paymentFrequency, gcConfig, currency) {
  var maxValue = 0;
  gcConfig.currencyPremiumSize.map(gcConfingPremium => {
    if (currency === gcConfingPremium.currency) {
      switch (paymentFrequency) {
        case 'Single':
          maxValue = gcConfingPremium.maxPremiumSingle;
          break;

        case 'Monthly':
          maxValue = gcConfingPremium.maxPremiumMonthly;
          break;

        case 'Quarterly':
          maxValue = gcConfingPremium.maxPremiumQuarterly;
          break;

        case 'Half-Yearly':
          maxValue = gcConfingPremium.maxPremiumHalfYearly;
          break;

        case 'Yearly':
          maxValue = gcConfingPremium.maxPremiumYearly;
          break;

        default:
          maxValue = gcConfingPremium.maxPremiumMonthly;
          break;
      }
    }
  });
  return maxValue;
}

export function isValidEmail(email) {
  if (email && isEmail(email)) {
    return true;
  }
  return false;
}

export function getStartDate() {
  var month = new Date().getMonth() + 1;
  if (new Date().getDate() <= 15) month = month - 1;

  return moment()
    .month(month)
    .date(1);
}

export function getTooltipsMsg(id, intl) {
  switch (id) {
    case 'ALC':
      return intl.formatMessage(messages.descriptionAlc);

    case 'ACD':
      return intl.formatMessage(messages.descriptionAD);

    case 'ESC':
      return intl.formatMessage(messages.descriptionESC);

    default:
      return intl.formatMessage(messages.descriptionTwo);
  }
}

/**
 *
 * Policy Summary
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Grid, Typography, Box } from '@material-ui/core';
import CustomDialog from 'components/Dialog';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import ReviewUserData from 'components/ReviewUserData';
import { Cancel, CheckCircle, Info } from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import InfoRow from 'components/InfoRow';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
} from '../../../selectors';
import { getBrTitle } from '../../../helper';
import messages from '../../../messages';

function Review({ lifeAssured, productConfig, chosenInsured }) {
  const theme = useTheme();

  const [specialTermsData, setSpecialTermsData] = useState([]);

  useEffect(() => {
    const specialTermsDataArray = [];
    productConfig.coverages?.map(cvg => {
      const hasBenefitRating =
        cvg.brSelectedInsuredOneValue ||
        cvg.brSelectedInsuredTwoValue ||
        cvg.brSelectedPercentValueOne ||
        cvg.brSelectedPercentValueTwo ||
        cvg.brSelectedTempValueTwo ||
        cvg.brSelectedTempValueOne;

      if (hasBenefitRating) {
        specialTermsDataArray.push(cvg);
        setSpecialTermsData(specialTermsDataArray);
      }
    });
  }, [productConfig]);

  const getPaymentFrequency = (frequency,singlePremiumTerm) => {
    switch (frequency) {
      case 'Monthly':
        return 'p/month';

      case 'Quarterly':
        return 'p/quarter';

      case 'Half-Yearly':
        return 'p/half-year';

      case 'Yearly':
        return 'p/year';

      case 'Single':
        if (singlePremiumTerm > 0){
          return 'per year';
        } else {
          return 'Single time';
        }
        
      default:
        return '';
    }
  };

  return (
    <Box pb={3}>
      {/* ########## START  :: POLICY OWNER ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.policyOwner} />
        </Typography>
        {/* LI 1 as PO */}
        {lifeAssured.insuredOne.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredOne} />
        )}
        {/* LI 2 as PO */}
        {lifeAssured.isInsuredTwo && lifeAssured.insuredTwo.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
        {/* other POs */}
        {lifeAssured.isPolicyOwnerIndividual &&
          lifeAssured.policyOwnerIndividual.map((po, index) => {
            const key = `${po.firstName}-${index}`;
            return <ReviewUserData key={key} party={po} />;
          })}
      </Box>
      {/* ########## END    :: POLICY OWNER ########## */}
      {/* ########## START  :: LIFE INSURED ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.insuredParty} />
        </Typography>

        <ReviewUserData party={lifeAssured.insuredOne} />
        {lifeAssured.isInsuredTwo && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
      </Box>
      {/* ########## END    :: LIFE INSURED ########## */}

      {/* ########## START  :: PREMIUM SUMMARY ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.premiumSummary} />
        </Typography>
        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.premiumAmount} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {productConfig.currency}{' '}
                {formatMonetaryValue(productConfig.premium, true)}{' '}
                {getPaymentFrequency(productConfig.paymentFrequency,productConfig.singlePremiumTerm)}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.premiumPaymentTerm} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {productConfig.term === 'SinglePremium'
                  ? 'Single premium'
                  : productConfig.term === 'WholeOfLife'
                  ? 'Whole of life'
                  : productConfig.singlePremiumTerm > 0
                  ? `${productConfig.singlePremiumTerm} Years`
                  : `${productConfig.term} Years`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>
        {productConfig.fiaCharge !== '' && productConfig.fiaCharge !== 0 && (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.fiaText} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {`${productConfig.fiaCharge} %`}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        )}
      </Box>
      {/* ########## END    :: PREMIUM SUMMARY ########## */}

      {/* ########## START  :: SPECIAL TERMS DETAILS ########## */}
      {productConfig.specialTermsFlag &&
      !productConfig.isInforce &&
      specialTermsData.length > 0 ? (
        <Box mb={5}>
          <Typography
            variant="h3"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.subMain,
            }}
          >
            <FormattedMessage {...messages.specialTerms} />
          </Typography>

          {specialTermsData.map((cvg, index) => {
            const key = `$specTerms${index}`;
            return (
              <Grid container direction="column" key={key}>
                <InfoRow style={{ maxHeight: 64 }}>
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          style={{
                            color: theme.palette.grey[400],
                          }}
                        >
                          <Info color="primary" />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">{cvg.title}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {cvg.id === 'WOP' || cvg.id === 'ESC'
                          ? cvg.valueInsuredOne
                            ? 'Yes'
                            : 'No'
                          : cvg.selected
                          ? `${productConfig.currency} ${formatMonetaryValue(
                              cvg.valueInsuredOne,
                              false,
                            )}`
                          : '-'}
                      </Typography>
                    </Grid>

                    {lifeAssured.isInsuredTwo ? (
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {cvg.id === 'WOP' || cvg.id === 'ESC'
                            ? cvg.valueInsuredTwo
                              ? 'Yes'
                              : 'No'
                            : cvg.selected
                            ? `${productConfig.currency} ${formatMonetaryValue(
                                cvg.valueInsuredTwo,
                                false,
                              )}`
                            : '-'}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </InfoRow>

                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue ||
                  cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                  <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.dark,
                        paddingBottom: theme.spacing(1),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...messages.permanent} />
                    </Typography>
                  </InfoRow>
                )}
                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue) && (
                  <InfoRow variant="br">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          ></Grid>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {getBrTitle('1')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        {!cvg.brSelectedInsuredOneValue ? (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            -
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedInsuredOneValue
                              ? `+${cvg.perMileOne.toFixed(2)}`
                              : `-`}
                          </Typography>
                        )}
                      </Grid>

                      {lifeAssured.isInsuredTwo ? (
                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedInsuredTwoValue
                              ? `+${cvg.perMileTwo.toFixed(2)}`
                              : `-`}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </InfoRow>
                )}

                {(cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                  <InfoRow variant="br">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          ></Grid>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {getBrTitle('2')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        {!cvg.brSelectedPercentValueOne ? (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            -
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedPercentValueOne
                              ? `+${cvg.perPercentageOne}`
                              : '-'}
                          </Typography>
                        )}
                      </Grid>

                      {lifeAssured.isInsuredTwo ? (
                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedPercentValueTwo
                              ? `+${cvg.perPercentageTwo}`
                              : '-'}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </InfoRow>
                )}

                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.dark,
                        paddingBottom: theme.spacing(1),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...messages.temporary} />
                    </Typography>
                  </InfoRow>
                )}
                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <>
                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('1')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMileOne !== 0
                              ? `+${cvg.perTempMileOne.toFixed(2)}`
                              : '-'}
                          </Typography>
                        </Grid>

                        {lifeAssured.isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMileTwo !== 0
                                ? `+${cvg.perTempMileTwo.toFixed(2)}`
                                : '-'}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>

                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('3')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMonthOne !== 0
                              ? `+${cvg.perTempMonthOne} months`
                              : '-'}
                          </Typography>
                        </Grid>

                        {lifeAssured.isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMonthTwo !== 0
                                ? `+${cvg.perTempMonthTwo} month`
                                : '-'}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>
                  </>
                )}
              </Grid>
            );
          })}
        </Box>
      ) : null}
      {/* ########## END    :: SPECIAL TERMS DETAILS ########## */}

      {/* ########## START  :: COVER DETAILS ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.coverDetails} />
        </Typography>

        {productConfig.coverages &&
          productConfig.coverages.map((cvg, index) => {
            if (!cvg.selected) {
              return null;
            }
            const coverageAmount =
              productConfig.coverages &&
              productConfig.coverages.find(item => item.id === cvg.id)[
                chosenInsured
              ];

            if (Number(coverageAmount) === 0 || coverageAmount === false) {
              return null;
            }

            return (
              <Grid container direction="column" key={cvg.id}>
                <InfoRow style={{ maxHeight: 64 }}>
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          style={{
                            color: theme.palette.grey[400],
                          }}
                        >
                          {cvg.selected ? (
                            <CheckCircle color="inherit" />
                          ) : (
                            <Cancel color="inherit" />
                          )}
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">{cvg.title}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {cvg.id === 'WOP'
                          ? coverageAmount
                            ? 'Yes'
                            : 'No'
                          : cvg.selected
                          ? `${productConfig.currency} ${formatMonetaryValue(
                              coverageAmount,
                              false,
                            )}`
                          : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </InfoRow>
              </Grid>
            );
          })}
      </Box>
      {/* ########## END    :: COVER DETAILS ########## */}
    </Box>
  );
}

Review.propTypes = {
  lifeAssured: PropTypes.object,
  productConfig: PropTypes.object,
  referenceNumber: PropTypes.string,
  fiaData: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  lifeAssured: makeSelectLifeAssured(),
  productConfig: makeSelectProductConfig(),
  referenceNumber: makeSelectReferenceNumber(),
  fiaData: makeSelectFiaCharge(),
});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Review);

import numeral from 'numeral';

/**
 * Remove leading zeros from string of number
 *
 * @param {string | number} number the number or string of number
 *
 * @return {string} the string of number without leading zeros
 */
export function fixLeadingZero(number) {
  if (!number || !`${number}`) return number;
  let numStr = `${number}`;
  const isNegative = numStr[0] === '-';
  if (isNegative) numStr = numStr.substring(1, numStr.length);
  const parts = numStr.split('.');
  const beforeDecimal = parts[0].replace(/^0+/, '') || '0';
  const afterDecimal = parts[1] || '';
  return ''
    .concat(isNegative ? '-' : '')
    .concat(beforeDecimal)
    .concat(afterDecimal ? '.'.concat(afterDecimal) : '');
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Format a monetary string/number for display
 *
 * @param {string | number} number the number or string of number
 * @param {Boolean} decimal the number or string of number
 *
 * @return {string} the formatted number for display
 */
export function formatMonetaryValue(number, decimal = true) {
  return numeral(number).format(decimal ? '0,0.00' : '0,0');
}

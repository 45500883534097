/**
 * CoverageForm
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  Collapse,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import { dimension } from 'styles/constants';
import FormCheck from 'components/FormCheck';
import FormNumberField from 'components/FormNumberField';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import CustomFormCheck from 'components/CustomFormCheck';
import CustomRadio from 'components/CustomRadioButton';
import FormTextField from 'components/FormTextField';
import { createStructuredSelector } from 'reselect';
import { WrappedFieldArrayProps, InjectedFormProps } from 'redux-form';
// import FormAutocomplete from 'components/FormAutocomplete';

import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import { isEmpty } from 'lodash';
import FormRadioGroup from 'components/FormRadioGroup';
import Helper from 'components/Helper';
import { changePlaceHolderColor } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import { compose } from 'redux';
import asyncValidator from './asyncValidate';
import { getStartDate, calculateAgeNextBirthdate } from '../../helper';
import validate from './validate';
import messages from '../../messages';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => (
  <OptSwitch
    checked={value}
    value={value}
    color="default"
    onChange={onChange}
    labelNegative={<FormattedMessage {...messages.no} />}
    disabled={disabled}
  />
);

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const renderBenefit = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
          ? lifeAssured.insuredOne.dateOfBirth
          : startDate,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo && lifeAssured.insuredTwo.dateOfBirth
          ? lifeAssured.insuredTwo.dateOfBirth
          : startDate,
      );

      let ageValidType = 0;

      const isShowingField = true;
      let isFormCheckDisabled = myBenefit[index].disabled;
      const isFieldsDisabled = !myBenefit[index].selected;

      let isWopOneDisabled = myBenefit[index].disabled;
      let isWopTwoDisabled = myBenefit[index].disabled;

      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA != '0') {
          if (myBenefit[index].id === 'WOP') {
            if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
              isWopOneDisabled = true;
              change(`${benefit}.valueInsuredOne`, false);
            }

            if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
              isWopTwoDisabled = true;
              change(`${benefit}.valueInsuredTwo`, false);
            }
          } else if (
            myBenefit[index].maxEntryAgeLA < anbInsuredOne ||
            myBenefit[index].maxEntryAgeLA < anbInsuredTwo
          ) {
            ageValidType = 1;
            isFormCheckDisabled = true;
            change(`${benefit}.valueInsuredOne`, '');
            change(`${benefit}.valueInsuredTwo`, '');
            change(`${benefit}.selected`, false);
          }
        }
        // mapping data for Li2
        if (
          myBenefit[index].valueInsuredOne !== '' &&
          myBenefit[index].id !== 'WOP'
        ) {
          change(
            `${benefit}.valueInsuredTwo`,
            myBenefit[index].valueInsuredOne,
          );
        }
      } else if (myBenefit[index].maxEntryAgeLA != '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          if (myBenefit[index].id === 'WOP') {
            isWopOneDisabled = true;
            change(`${benefit}.valueInsuredOne`, false);
          } else {
            change(`${benefit}.valueInsuredOne`, '');
            change(`${benefit}.selected`, false);
          }

          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }

      return (
        <div key={index}>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            wrap="nowrap"
          >
            <Grid item xs={6} md={6} sm={6}>
              <Grid container direction="row">
                <Grid item>
                  {myBenefit[index].type === '1' ? (
                    <Field
                      // shrink
                      name={`${benefit}.selected`}
                      fullWidth
                      component={CustomFormCheck}
                      label={myBenefit[index].title}
                      disabled={isFormCheckDisabled}
                      validType={ageValidType}
                      type="text"
                      color="primary"
                      variant="standard"
                      onChange={(e, newVal) => {
                        if (!newVal) {
                          change(`${benefit}.valueInsuredOne`, '');
                          change(`${benefit}.valueInsuredTwo`, '');
                        }
                      }}
                    />
                  ) : (
                    <Grid container direction="row">
                      <Typography variant="h4" style={{ fontWeight: '600' }}>
                        {myBenefit[index].title}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {myBenefit[index].id === 'WOP' ||
                (myBenefit[index].id === 'CRI' && isFormCheckDisabled) ? (
                  <Grid item>
                    <Helper
                      helperText={
                        myBenefit[index].id === 'WOP' ? (
                          <FormattedMessage {...messages.descriptionTwo} />
                        ) : (
                          <FormattedMessage {...messages.descriptionCri} />
                        )
                      }
                    ></Helper>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {/* test */}
            <Grid item xs={6} md={3} sm={3}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.valueInsuredOne`}
                  fullWidth
                  component={FormNumberField}
                  decimalScale={0}
                  disabled={isFieldsDisabled}
                  type={isShowingField ? 'text' : 'hidden'}
                  color="primary"
                  variant="standard"
                  onChange={(e, newVal) =>
                    change(`${benefit}.valueInsuredTwo`, newVal)
                  }
                  placeholder={
                    ageValidType === 1
                      ? intl.formatMessage(globalMessages.naMessage)
                      : intl.formatMessage(messages.enterAmount)
                  }
                />
              ) : (
                <Field
                  // shrink
                  name={`${benefit}.valueInsuredOne`}
                  component={FormBooleanOption}
                  type="text"
                  margin="normal"
                  disabled={isWopOneDisabled}
                  fullWidth
                />
              )}
            </Grid>

            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                {myBenefit[index].type === '1' ? (
                  <Field
                    // shrink
                    name={`${benefit}.valueInsuredTwo`}
                    fullWidth
                    component={FormNumberField}
                    decimalScale={0}
                    disabled
                    type={isShowingField ? 'text' : 'hidden'}
                    color="primary"
                    variant="standard"
                    placeholder={
                      ageValidType === 1
                        ? intl.formatMessage(globalMessages.naMessage)
                        : intl.formatMessage(messages.enterAmount)
                    }
                  />
                ) : (
                  <Field
                    // shrink
                    name={`${benefit}.valueInsuredTwo`}
                    component={FormBooleanOption}
                    type="text"
                    margin="normal"
                    disabled={isWopTwoDisabled}
                    fullWidth
                  />
                )}
              </Grid>
            ) : null}
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  product,
  offerCodeData,
  itaConfig,
  offerCode,
  change,
  coverages,
  startDate,
  fiaData,
  fiaCharge,
  isAdditionalFeature,
  term,
  paymentFrequency,
  currency,
  location,
  resetOfferCode,
  validateOfferCode,
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const offerList = [];
  const currencyOption = [];
  const paymentFrequencyOption = [];
  const policyTermList = [];
  const startDateList = [];

  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '' && offerCode !== undefined) {
      validateOfferCode({
        offerCode,
        productCode: itaConfig.productCode,
        productVersion: itaConfig.productVersion,
        premiumType: term,
        currency,
        premium: coverages?.[0]?.valueInsuredOne,
      });
    }
  }, []);

  if (startDateList.length === 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  const minStartDate = getStartDate()
    .subtract(2, 'months')
    .toDate();
  const maxStartDate = getStartDate()
    .add(2, 'months')
    .toDate();

  const anbInsuredOne = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
      ? lifeAssured.insuredOne.dateOfBirth
      : startDate,
  );
  const anbInsuredTwo = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredTwo && lifeAssured.insuredTwo.dateOfBirth
      ? lifeAssured.insuredTwo.dateOfBirth
      : startDate,
  );

  let minPremiumValue = itaConfig.features?.policyTerm.minTerm;
  let maxPremiumValue = itaConfig.features?.policyTerm.maxTerm;
  let expiryAgeValue = itaConfig.features?.policyTerm.expiryAge;

  const considerAge =
    anbInsuredOne < anbInsuredTwo ? anbInsuredTwo : anbInsuredOne;
  const calculateMaxTerm = expiryAgeValue - considerAge;
  maxPremiumValue =
    calculateMaxTerm > maxPremiumValue ? maxPremiumValue : calculateMaxTerm;

  while (minPremiumValue <= maxPremiumValue) {
    const myObj = { title: `${minPremiumValue}`, value: minPremiumValue };
    policyTermList.push(myObj);
    minPremiumValue++;
  }

  offerList.push({ title: 'No Offer', value: '' });

  product?.specialOffers?.map(offer => {
    offerList.push({
      value: offer.specialOfferCode,
      title: offer.specialOfferCode,
    });
  });

  itaConfig.features.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  itaConfig.features.premiumPaymentFrequencyType[0].periods.map(frequency => {
    paymentFrequencyOption.push({ value: frequency, title: frequency });
  });

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            {/* <Grid item sm={6}>
            <Field
              shrink
              name="startDate"
              component={FormDatePicker}
              label={<FormattedMessage {...messages.assumedStartDate} />}
              dateInputFormat="DD/MM/YYYY"
              placeholder="dd/mm/yyyy"
              margin="normal"
              minDate={minStartDate}
              maxDate={maxStartDate}
              InputLabelProps={{
                style: {
                  fontWeight: 'normal',
                  fontSize: theme.typography.body1.fontSize,
                },
              }}
              shouldDisableDate={date => {
                if (date.date() > 1) {
                  return true;
                }
                return false;
              }}
              onBlur={(event, newValue) => {
                const mDate = moment(newValue);
                if (mDate.isValid() && mDate.date() > 1) {
                  event.preventDefault();
                  blur('startDate', mDate.date(1).format("YYYY-MM-DD"));
                }
              }}
            />
          </Grid> */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* CURRRENCY */}
            <Grid item>
              <Field
                // shrink
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* POLICY TERM */}
            <Grid item>
              <Field
                // shrink
                name="term"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(term),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.policyTerm} />}
                type="text"
                // options={policyTermList}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...policyTermList,
                ]}
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* PAYMENT FREQUENCY */}
            <Grid item>
              <Field
                // shrink
                name="paymentFrequency"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(paymentFrequency),
                }}
                component={FormSelect}
                options={paymentFrequencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...paymentFrequencyOption,
                ]}
                label={<FormattedMessage {...messages.paymentFrequency} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
          </Grid>

          <Divider className={classes.hr} />

          <Grid
            container
            direction="row"
            spacing={1}
            justify="space-between"
            alignItems="center"
            item
            style={{ marginBottom: theme.spacing(1), paddingRight: '10%' }}
            wrap="nowrap"
          >
            <Grid item xs={12} md={6} sm={6} />
            <Grid item xs={6} md={3} sm={3}>
              <Typography
                color="primary"
                variant="h3"
                style={{ marginLeft: theme.spacing(0.5) }}
              >
                {lifeAssured.insuredOne.firstName}
              </Typography>
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredTwo.firstName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid>
            <FieldArray
              name="coverages"
              component={renderBenefit}
              rerenderOnEveryChange
              lifeAssured={lifeAssured}
              options={options}
              change={change}
              startDate={startDate}
              intl={intl}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid item xs={6} style={{ marginBottom: '5rem' }}>
            <Field
              // shrink
              name="offerCode"
              style={{
                width: 355,
              }}
              fullWidth
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              placeholder={intl.formatMessage(messages.pleaseEnter)}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid item xs={12}>
            <Field
              // shrink
              name="specialTermsFlag"
              fullWidth
              component={FormCheck}
              label={<FormattedMessage {...globalMessages.isBenefitRating} />}
              type="text"
              color="primary"
              margin="normal"
              variant="standard"
            />
          </Grid>

          {!isEmpty(fiaData) && itaConfig.features.haveFiaCharge ? (
            <>
              <Divider className={classes.hr} />
              <CardSectionForm variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Typography variant="body1">
                    <FormattedMessage {...globalMessages.additionalFetures} />
                  </Typography>
                  <Field
                    name="isAdditionalFeature"
                    component={FormSwitch}
                    disabled={false}
                  />
                  {/* <OptSwitch
                    checked={isAdditionalFeature}
                    color="default"
                    onChange={() => setAdditionalFeature(!isAdditionalFeature)}
                    labelNegative={<FormattedMessage {...globalMessages.no} />}
                  /> */}
                </Grid>

                <Collapse in={isAdditionalFeature}>
                  <Typography
                    variant="h4"
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <FormattedMessage {...globalMessages.fiaText} />
                  </Typography>

                  <Field
                    name="fiaCharge"
                    type="radio"
                    component={FormRadioGroup}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<CustomRadio checked={fiaCharge == 0} />}
                          value={0}
                          label={<FormattedMessage {...globalMessages.nil} />}
                          labelPlacement="left"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justify="space-between">
                          <FormControlLabel
                            control={<CustomRadio checked={fiaCharge != 0} />}
                            value={1}
                            label={
                              <FormattedMessage
                                {...globalMessages.annualPercentage}
                              />
                            }
                            labelPlacement="left"
                          />

                          <TextField value="1%" variant="outlined" disabled />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Field>
                </Collapse>
              </CardSectionForm>
            </>
          ) : null}
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  itaConfig: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  startDate: PropTypes.string,
  fiaCharge: PropTypes.number,
  coverages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fiaData: PropTypes.object,
};

const selector = formValueSelector('coverageForm');
const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withFormSelector = connect(state => {
  const coverages = selector(state, 'coverages');
  const startDate = selector(state, 'startDate');
  const fiaCharge = selector(state, 'fiaCharge');
  const isAdditionalFeature = selector(state, 'isAdditionalFeature');
  const currency = selector(state, 'currency');
  const term = selector(state, 'term');
  const offerCode = selector(state, 'offerCode');
  const paymentFrequency = selector(state, 'paymentFrequency');
  return {
    startDate,
    fiaCharge,
    isAdditionalFeature,
    currency,
    term,
    paymentFrequency,
    offerCode,
    coverages: coverages?.toJS() || [],
  };
});

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: [
    'offerCode',
    'term',
    'coverages[].valueInsuredOne',
    'currency',
  ],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

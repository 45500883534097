/*
 *
 * OktaAuthCallback actions
 *
 */

import {
  SET_OKTA_TOKEN_ACTION,
  RESET_TO_DEFAULT_ACTION,
  SET_ERROR_ACTION,
} from './constants';

export function setOktaTokenAction(payload) {
  return {
    type: SET_OKTA_TOKEN_ACTION,
    payload,
  };
}

export function setErrorAction({ message = null }) {
  return {
    type: SET_ERROR_ACTION,
    payload: {
      message,
    },
  };
}

export function resetToDefaultAction() {
  return {
    type: RESET_TO_DEFAULT_ACTION,
  };
}

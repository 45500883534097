/*
 *
 * LanguageProvider constants
 *
 */

export const CHANGE_LOCALE = 'app/LanguageToggle/CHANGE_LOCALE';
export const SET_LOCALE = 'app/LanguageToggle/SET_LOCALE';

export const LOCALE_OPTIONS = [
  {
    value: 'en',
    title: 'English',
  },
  // TODO: enable language selections back when it's ready to test again. Disabled for UAT deployment.
  // {
  //   value: 'ko',
  //   title: '한국어',
  // },
  // {
  //   value: 'de',
  //   title: 'Deutsch',
  // },
  // {
  //   value: 'id',
  //   title: 'Bahasa Indonesia',
  // },
];

/*
 * IdVerification Messages
 *
 * This contains all the text for the IdVerification container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.IdVerification';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Proof of identity',
  },
  landingHeading: {
    id: `${scope}.landingHeading`,
    defaultMessage: 'Proof of identity',
  },
  landingDesc: {
    id: `${scope}.landingDesc`,
    defaultMessage:
      `As part of your application, we need to verify your ID.
      {br}
      Please have your Passport or UAE ID card ready.`,
  },
  acceptPrivacyPolicy: {
    id: `${scope}.acceptPrivacyPolicy`,
    defaultMessage: 'I accept the ID Verification {link}',
  },
  idVerificationNotice: {
    id: `${scope}.idVerificationNotice`,
    defaultMessage: 'The name on your application must match that on your ID',
  },
  endSession: {
    id: `${scope}.endSession`,
    defaultMessage:
      'End Session',
  },
  privacyPolicy: {
    id: `${scope}.privacyPolicy`,
    defaultMessage:
      'Privacy Policy',
  },
});

import React from 'react';
import { Card } from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import styled from 'styled-components';

const CardSectionForm = styled(({ theme, ...props }) => <Card {...props} />)`
  && {
    padding: ${props => props.theme.spacing(2)}px;
    overflow-x: hidden;
    margin-bottom: 10px;
    max-width: 640px;
  }
`;

export default withTheme(CardSectionForm);

import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isNumber } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import numeral from 'numeral';
import {
  getMinValue,
  getMaxValue,
  calculateAgeNextBirthdate,
  getMinCurrencyValue,
  getMaxCurrencyValue,
  getPremiumRange,
} from 'containers/InforceIllutrationPage/helper';
import { productCodeCheck } from 'helpers/constants';

const validate = (values, props) => {
  const errors = {};
  const { policyConfig } = props;
  const { lifeAssured } = props;
  const { productConfig } = props
  const agencyNumber = policyConfig?.agencyNumber;
  const MAX_ADHOC_WITHDRAWAL = productConfig?.maxPartialSurrender;
  const MAX_GROWTH_RATE = (agencyNumber === '8910138' && productConfig?.productCode === 'WAPL1') ? 7 : policyConfig?.features?.growthRate?.ifiMaxRate;
  let MIN_GROWTH_RATE = policyConfig?.features?.growthRate?.ifiMinRate ? policyConfig?.features?.growthRate?.ifiMinRate : 0;
  if(agencyNumber === '8910138' && productConfig?.productCode === 'WAPL1'){
    MIN_GROWTH_RATE = 7
  }
  const getFormatedValue = (value) => {
    let numberValue = Number(value)
    return numberValue?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  if (!values.isEmpty()) {
    const offerCodeValues = values.get('offerCode');
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const termValues = values.get('term');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const growthRateValues = values.get('growthRate');
    const isAdditionalSinglePremium = values.get('isAdditionalSinglePremium');
    const newSinglePremium = values.get('premium');
    const adHocwithdrawl = values.get('adHocwithdrawl');
    const isRegularWithdrawalValues = values.get('isRegularWithdrawal');
    const regularWithdrawalValues = values.get('regularWithdrawal');
    const additionalSinglePremiumValues = values.get('additionalSinglePremium');


    if (!startDateValues || !moment(startDateValues).isValid())
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!termValues)
      errors.term = <FormattedMessage {...globalMessages.pleaseSelectValue} />;

    if (!paymentFrequencyValues && termValues != 'SinglePremium')
      errors.paymentFrequency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );


    /* adhoc withdrawal validation */
    if (adHocwithdrawl !== undefined && adHocwithdrawl < 0) {
      errors.adHocwithdrawl = (
        <FormattedMessage {...globalMessages.cannotBeLessThanZero} />
      );
    } else {
      let minAdhocWithdrawal = 0;
      policyConfig?.features?.withdrawalSize.map(withdrawal => {
        if (withdrawal.currency === currencyValues) {
          minAdhocWithdrawal = withdrawal.minimumPartialWithdrawalAmount;
        }
      });

      if (adHocwithdrawl < Number(minAdhocWithdrawal))
        errors.adHocwithdrawl = (
          <FormattedMessage
            {...globalMessages.minWithdrawalValidation}
            values={{ value: getFormatedValue(minAdhocWithdrawal) }}
          />
        );

      if (adHocwithdrawl > Number(MAX_ADHOC_WITHDRAWAL))
        errors.adHocwithdrawl = (
          <FormattedMessage
            {...globalMessages.maxWithdrawalValidation}
            values={{ value: getFormatedValue(MAX_ADHOC_WITHDRAWAL) }}
          />
        );
    }
    /* new single premium amount validation */
    if (newSinglePremium !== undefined && newSinglePremium !== '') {
      const withdrawalAmount = Number(newSinglePremium);
      var minnewSinglePremiumAllowed = 0;
      var maxnewSinglePremiumAllowed = 0;
      policyConfig?.features?.currencyPremiumSize.map(premiumSize => {
        if (premiumSize.currency === currencyValues) {
          minnewSinglePremiumAllowed = premiumSize.minNewSinglePremium;
          maxnewSinglePremiumAllowed = premiumSize.maxPremiumSingle;
        }
      });

      if (withdrawalAmount < minnewSinglePremiumAllowed)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.minWithdrawalValidation}
            values={{ value: getFormatedValue(minnewSinglePremiumAllowed) }}
          />
        );

      if (withdrawalAmount > maxnewSinglePremiumAllowed)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.maxWithdrawalValidation}
            values={{ value: getFormatedValue(maxnewSinglePremiumAllowed) }}
          />
        );
    }
    /* end code new single premium amount */


    if (!growthRateValues)
      errors.growthRate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    else {
      if (growthRateValues < 0) {
        errors.growthRate = (
          <FormattedMessage {...globalMessages.minLimitGrowthRate} />
        );
      } else if(MIN_GROWTH_RATE == MAX_GROWTH_RATE) { //For SCB Bahrain case Min and Max growth rate is same.
        if (growthRateValues != MAX_GROWTH_RATE) {
          errors.growthRate = (
            <FormattedMessage {...globalMessages.minMaxSame} 
            values={{ value: MAX_GROWTH_RATE }}/>
          );
        }
      } else if (growthRateValues < MIN_GROWTH_RATE) {
        errors.growthRate = (
          <FormattedMessage {...globalMessages.minLimit} values={{ value: MIN_GROWTH_RATE }} />
        );
      }

      if (growthRateValues > MAX_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_GROWTH_RATE }}
          />
        );
    }

    // Regular withdrawal validation
    if (isRegularWithdrawalValues) {
      const errorRegularWithdrawal = {};
      if (
        !regularWithdrawalValues?.get('frequency') ||
        regularWithdrawalValues?.get('frequency') === 'Select'
      )
        errorRegularWithdrawal.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );

      if (
        !regularWithdrawalValues?.get('startDate') ||
        !moment(regularWithdrawalValues?.get('startDate')).isValid()
      )
        errorRegularWithdrawal.startDate = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      else {
        var regularStartDateValues = moment(
          regularWithdrawalValues?.get('startDate'),
        );
        const minStartDate = moment(startDateValues)
          .date(15)
          .add(1, 'months')
          .toDate();

        if (moment(regularStartDateValues, 'YYYY-MM-DD').format('DD') != 15)
          errorRegularWithdrawal.startDate = (
            <FormattedMessage {...globalMessages.dateValidation2} />
          );
        else if (regularStartDateValues.isBefore(minStartDate))
          errorRegularWithdrawal.startDate = (
            <FormattedMessage
              {...globalMessages.dateValidation}
              values={{ value: moment(minStartDate).format('DD/MM/YYYY') }}
            />
          );
      }

      if (
        !regularWithdrawalValues?.get('endDate') ||
        !moment(regularWithdrawalValues?.get('endDate')).isValid()
      )
        errorRegularWithdrawal.endDate = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      else {
        const regularEndDateValue = moment(
          regularWithdrawalValues?.get('endDate'),
        );
        var regularStartDateValues = moment(
          regularWithdrawalValues?.get('startDate'),
        );
        const maxEndDate = moment(regularStartDateValues)
          .add(100, 'years')
          .toDate();

        if (regularEndDateValue.isBefore(regularStartDateValues))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.minEndDateValidation} />
          );

        if (regularEndDateValue.isAfter(maxEndDate))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.maxEndDateValidation} />
          );
      }

      if (regularWithdrawalValues?.get('isAmountChecked') == 1) {
        if (!regularWithdrawalValues?.get('amount'))
          errorRegularWithdrawal.amount = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const withdrawalAmount = Number(
            regularWithdrawalValues?.get('amount'),
          );
          var minPremiumAllowed = 0;
          var maxPremiumAllowed = 0;

          policyConfig.features.withdrawalSize.map(premiumSize => {
            if (premiumSize.currency === currencyValues) {
              minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
              // maxPremiumAllowed = premiumSize.maxPremiumSingle;
            }
          });

          const allowedPremiumRange = getPremiumRange(
            policyConfig,
            paymentFrequencyValues,
            currencyValues,
          );

          maxPremiumAllowed = allowedPremiumRange.max;

          console.log('withdrawalAmount : ', withdrawalAmount)

          if (String(withdrawalAmount) === 'NaN') {
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.numberWithdrawalValidation}
              />
            );
          }

          if (withdrawalAmount < minPremiumAllowed) {
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: getFormatedValue(minPremiumAllowed) }}
              />
            );
          }

          if (withdrawalAmount > maxPremiumAllowed) {
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: getFormatedValue(maxPremiumAllowed) }}
              />
            );
          }

        }
      }

      if (regularWithdrawalValues?.get('isAmountChecked') == 2) {
        const minPercentage =
          policyConfig.features?.regularWithdrawal?.minPercentage;
        const maxPercentage =
          policyConfig.features?.regularWithdrawal?.maxPercentage;

        if (!regularWithdrawalValues?.get('percentage') || String(Number(regularWithdrawalValues?.get('percentage'))) === 'NaN')
          errorRegularWithdrawal.percentage = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          if (Number(regularWithdrawalValues?.get('percentage')) < minPercentage)
            errorRegularWithdrawal.percentage = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPercentage }}
              />
            );

          if (Number(regularWithdrawalValues?.get('percentage')) > Number(maxPercentage))
            errorRegularWithdrawal.percentage = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPercentage }}
              />
            );
        }
      }

      errors.regularWithdrawal = errorRegularWithdrawal;
    }
    /*Education Fee withdrawal validation */
    if (isAdditionalSinglePremium && additionalSinglePremiumValues !== 0) {
      const errorAdditionalSinglePremium = {};
      if (
        additionalSinglePremiumValues.get('frequency') === 'Select' || !additionalSinglePremiumValues.get('frequency')
      )
        errorAdditionalSinglePremium.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      if (!additionalSinglePremiumValues.get('amount'))
        errorAdditionalSinglePremium.amount = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else {
        const withdrawalAmount = Number(
          additionalSinglePremiumValues.get('amount'),
        );
        var minPremiumAllowed = 0;
        var maxPremiumAllowed = 0;

        policyConfig?.features?.currencyPremiumSize?.map(premiumSize => {
          if (premiumSize.currency === currencyValues) {
            minPremiumAllowed = premiumSize.minAdditionalSinglePremium;
            maxPremiumAllowed = premiumSize.maxAdditionalSinglePremium;
          }
        });

        // maxPremiumAllowed = getMaxValue(paymentFrequencyValues, policyConfig.features, currencyValues);

        if (withdrawalAmount < minPremiumAllowed)
          errorAdditionalSinglePremium.amount = (
            <FormattedMessage
              {...globalMessages.minWithdrawalValidation}
              values={{ value: getFormatedValue(minPremiumAllowed) }}
            />
          );

        if (withdrawalAmount > maxPremiumAllowed)
          errorAdditionalSinglePremium.amount = (
            <FormattedMessage
              {...globalMessages.maxWithdrawalValidation}
              values={{ value: getFormatedValue(maxPremiumAllowed) }}
            />
          );
      }
      errors.additionalSinglePremium = errorAdditionalSinglePremium;
    }

    if (!isEmpty(offerCodeValues)) {
      if (newSinglePremium === '') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseFillInThePremium} />
        );
      }
      if (currencyValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectCurrency} />
        );
      }
    }
  }
  return errors;
};

export default validate;

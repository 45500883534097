/**
 *
 * BenefitRatingForm
 *
 */

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm, formValueSelector, getFormValues, } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';
import { useTheme } from '@material-ui/core/styles';
import validate from './validate';
import Benefits from './Benefits';

function BenefitRatingForm({
  lifeAssured,
  productConfig,
  coverages2,
  handleSubmit,
  onSubmit,
  change,
  initialValues,
  isLifeAssuredEqual,
  onUpdateValues
}) {
  const theme = useTheme();
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });
  const recalculate = () => {
    onUpdateValues(coverages2)
  }


  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row">
          {productConfig?.isTargetFundProtectionLifeOne &&
            <Grid item xs={12}>
              <Typography variant="h3" color="primary">
                {`${lifeAssured.insuredOne.firstName} ${lifeAssured.insuredOne.lastName}`}
              </Typography>

              {coverages2.length > 0 ? (
                <FieldArray
                  name="coverages2"
                  component={Benefits}
                  recalculate={recalculate}
                  onUpdateValues={onUpdateValues}
                  coverages={coverages2}
                  editData={editData}
                  productConfig={productConfig}
                  insured={1}
                  change={change}
                  initialValues={initialValues}
                  changeEditData={val =>
                    setEditData({ editIndex: val, editInsured: 1 })
                  }
                />
              ) : null}
            </Grid>
          }
          {(lifeAssured.isInsuredTwo && productConfig?.isTargetFundProtectionLifeTwo) ? (
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: theme.spacing(4) }}
              >
                {`${lifeAssured.insuredTwo.firstName} ${lifeAssured.insuredTwo.lastName}`}
              </Typography>

              {coverages2.length > 0 ? (
                <FieldArray
                  name="coverages2"
                  component={Benefits}
                  recalculate={recalculate}
                  onUpdateValues={onUpdateValues}
                  insured={2}
                  productConfig={productConfig}
                  coverages={coverages2}
                  editData={editData}
                  change={change}
                  initialValues={initialValues}
                  changeEditData={val =>
                    setEditData({ editIndex: val, editInsured: 2 })
                  }
                />
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </form>
    </>
  );
}

BenefitRatingForm.propTypes = {
  ...InjectedFormProps,
  lifeAssured: PropTypes.object,
  coverages: PropTypes.array,
  change: PropTypes.func,
  onUpdateValues: PropTypes.func,
};

const withForm = reduxForm({
  form: 'benefitRatingForm',
  warn: validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(BenefitRatingForm);

const selector = formValueSelector('benefitRatingForm');

const withConnect = connect(state => {
  let coverages = selector(state, 'coverages2');
  return {
    coverages2: coverages?.toJS() || [],
  };
})(withForm);

export default withConnect;

import { validateOfferCodeAction } from 'containers/App/actions';

function asyncValidate(values, dispatch, props, fieldName) {
  const lengthOfCoverage = values.get('coverages').toJS().length;
  const coverageFieldsInvalid = [];
  for (let i = 0; i < lengthOfCoverage; i++) {
    coverageFieldsInvalid.push(`coverages[${i + 1}].valueInsuredOne`);
  }
  if (fieldName !== undefined && !coverageFieldsInvalid.includes(fieldName)) {
    if (
      values.get('offerCode') !== '' &&
      values.get('term') !== 'Select' &&
      values.get('currency') !== 'Select' &&
      values.get('coverages').toJS()?.[0]?.valueInsuredOne !== '' &&
      (fieldName === 'coverages[0].valueInsuredOne' ||
        fieldName === 'offerCode' ||
        fieldName === 'currency' ||
        fieldName === 'term')
    ) {
      return new Promise((resolve, reject) => {
        dispatch(
          validateOfferCodeAction({
            offerCode: values.get('offerCode'),
            productCode: props.product.PTVCode,
            productVersion: props.product.PTVVersion,
            currency: values.get('currency'),
            premium: values.get('coverages').toJS()?.[0]?.valueInsuredOne,
            premiumType: values.get('term'),
            resolve,
            reject,
          }),
        );
      });
    }

    return new Promise(resolve => {
      dispatch(
        validateOfferCodeAction({
          offerCode: '',
          resolve,
        }),
      );
    });
  }

  if (props?.offerCodeData?.errorMessage) {
    return new Promise((resolve, reject) => {
      reject({
        offerCode: props?.offerCodeData?.errorMessage,
      });
    });
  } else {
    return new Promise(resolve => {
      resolve();
    });
  }
}

export default asyncValidate;

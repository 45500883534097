/**
 *
 * PolicyDetailsPage
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Button, Fade } from '@material-ui/core';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useTheme } from '@material-ui/core/styles';
import { useHistory, useLocation } from 'react-router-dom';
import { usePrevious } from 'utils/hooks';
import Dot from '@material-ui/icons/FiberManualRecord';
import moment from 'moment';
import NoticeBox from 'components/NoticeBox';
import messages from '../../messages';
import { useStyles } from 'containers/ApplicationPage/helper';
import SummaryAmount from './components/SummaryAmount';
import CalculatePanel from './components/CalculatePanel';
import { Wrapper } from 'helpers/layout';
import PolicySummary from './components/PolicySummary';
import {
  getChosenInsured,
  getAgeOfLife,
  getPolicyOwnerName,
} from '../../helper';
import {
  makeSelectProductConfig,
  makeSelectValidForIFQ,
  makeSelectLifeAssured,
  makeSelectSustainabilityYears,
  makeSelectPolicyConfig,
} from '../../selectors';
import {
  recalculateSustainabilityAction,
  downloadHealthCheckPdfAction,
  setGrowthRateAction,
} from '../../actions';

export function PolicyDetailsPage({
  productConfig: {
    policyNumber,
    currency,
    premium,
    name,
    totalPremiumPaid,
    startDate,
    lastPaidDate,
    nextDueDate,
    term,
    coverages,
    productCode,
    validForIFQ,
    mappedStatus,
    policyStatus,
    growthRate,
    singlePremiumTerm,
    isPremiumHoliday, 
    premiumDueData, // added for over due premium - premiumdue data list
    isOverDuePremium,
    isReinstate, // flag added to check whether the policy is eligible for Reinstatement
  },
  lifeAssured,
  recalculateSustainability,
  sustainabilityYears,
  downloadHealthCheckPdf,
  setGrowthRateAct,
  policyConfig,
}) {
  let MIN_GROWTH_RATE = policyConfig.features?.growthRate?.ifi?.minRate;
  let MAX_GROWTH_RATE = policyConfig.features?.growthRate?.ifi?.maxRate;
  const selectedAgency = sessionStorage.getItem('agencySelected');
  if(productCode && selectedAgency && selectedAgency === '8910138') {
    switch (productCode) {
      case 'SCFUT' :
        MIN_GROWTH_RATE = 6;
        MAX_GROWTH_RATE = 6;
        break;
      case 'SAVP3' :
        MIN_GROWTH_RATE = 7;
        MAX_GROWTH_RATE = 7;
        break;
      case 'WAPL1' :
        MIN_GROWTH_RATE = 7;
        MAX_GROWTH_RATE = 7;
        break;
    }
  }

  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [growthRateState, setGrowthRate] = useState(MIN_GROWTH_RATE==MAX_GROWTH_RATE ? MAX_GROWTH_RATE : 5);
  const [isEditPolicy, setEditPolicy] = useState(false);
  const defaultGrowthRate = usePrevious(MIN_GROWTH_RATE);
  const chosenInsured = getChosenInsured(lifeAssured);
  const [notice, setNotice] = useState(false);
  const handleIncreasedClicked = () => {
    if (growthRateState >= MAX_GROWTH_RATE) {
      return;
    }
    setGrowthRate(growthRateState + 0.5);
  };
  const handleDecreasedClicked = () => {
    if (growthRateState <= defaultGrowthRate) {
      return;
    }
    setGrowthRate(growthRateState - 0.5);
  };
  const isAdvancedPremiumSettled = () => {
    const _lastPaidDate = moment(lastPaidDate).toDate();
    const startOfCurrentMonth = moment().startOf('month');
    const endOfNextMonth = moment().add(1,'month').endOf('month');

    if(_lastPaidDate > startOfCurrentMonth && _lastPaidDate > endOfNextMonth) {
      return true;
    } 
    return false;

  }
  const handleEditPolicyClicked = () => {
    if (validForIFQ === 'N') {
      setNotice(true);
    } else if (sustainabilityYears === '--') {
      setEditPolicy(true);
      recalculateSustainability({ growthRate });
    } else {
      history.replace('/ifi/insured', location.state);
    }
  };

  useEffect(() => {
    if (isEditPolicy && sustainabilityYears !== '--') {
      history.replace('/ifi/insured', location.state);
    }
  }, [sustainabilityYears]);

  const ageOfLife = getAgeOfLife(
    lifeAssured.lifeBasisHarvest,
    lifeAssured,
    chosenInsured,
    startDate,
  );

  const policyOwnerName =
    lifeAssured.policyOwners && getPolicyOwnerName(lifeAssured.policyOwners);

  
 
 
  return (
    <Wrapper theme={theme} style={{ padding: 0 }}>
      {notice && (
        <Fade in={notice}>
          <NoticeBox variant="info" mb={4}>
            <Typography variant="body2" color="primary">
              <FormattedMessage
                {...messages.onlineNotes}
                values={{
                  link: (
                    <a
                      href="https://online.zurichinternationalsolutions.com/login.aspx?ReturnUrl=%2f"
                      target="_blank"
                    >
                      <FormattedMessage {...messages.onlineForm} />
                    </a>
                  ),
                }}
              />
            </Typography>
          </NoticeBox>
        </Fade>
      )}
      <Box bgcolor="white">
        <Grid direction="column" container spacing={5} alignItems="flex-start">
          <Grid item xs container justify="space-between" spacing={2}>
            <Grid item xs={6}>
              <Typography
                variant="h1"
                gutterBottom
                style={{ fontWeight: 'normal', fontSize: '2.8rem' }}
              >
                {name} : {policyNumber}
              </Typography>
              <Typography
                gutterBottom
                style={{
                  color: theme.palette.primary.dark,
                  fontWeight: 'bold',
                  fontSize: '1.8rem',
                }}
              >
                {policyOwnerName}
            </Typography>
            {((!isReinstate) && (policyStatus == 'L' || policyStatus == 'C')) && // L - lapsed, C - paid up
              <FormattedMessage {...messages.RINotAllowed} /> }
            </Grid>
            <Grid
              item
              xs={6}
              container
              direction="column"
              justify="flex-end"
              alignItems="flex-end"
            >
              <Grid
                container
                item
                xs
                justify="flex-end"
                style={{ paddingBottom: theme.spacing(2) }}
              >
                <Grid item>
                  <Typography
                    variant="body1"
                    // className={
                    //   validForIFQ === 'Y' ?  classes.redText : classes.dot
                    // }
                    className =  { // RI changes
                      (validForIFQ == 'Y' && policyStatus == 'L') ?  classes.redText : 
                      (validForIFQ === 'Y' && policyStatus === 'C') ? classes.amberText : 
                      (validForIFQ === 'Y' && policyStatus == 'A') ? classes.inProcessText : classes.dot
                    }
                  >
                    Status: {((policyStatus == 'L' || policyStatus == 'A') ? mappedStatus : 'Paid Up')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Dot
                    className={ // RI changes 
                      (validForIFQ == 'Y' && policyStatus == 'L') ?  classes.redText : 
                      (validForIFQ === 'Y' && policyStatus === 'C') ? classes.amberText : 
                      (validForIFQ === 'Y' && policyStatus == 'A') ? classes.inProcessText : classes.dot
                    }
                    fontSize="small"
                    style={{ marginLeft: theme.spacing(1) }}
                  />
                </Grid>
              </Grid>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="primary"
                  margin="normal"
                  // disabled={validForIFQ === undefined || isAdvancedPremiumSettled() || (!isReinstate && (policyStatus == 'L' || policyStatus == 'C'))} // RI changes
                  className={classes.openButton}
                  onClick={handleEditPolicyClicked}
                >
                  <FormattedMessage {...messages.editPolicy} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs
            container
            alignItems="flex-start"
            justify="flex-start"
            spacing={2}
          >
            <Grid item xs>
              <SummaryAmount
                paidToDate={totalPremiumPaid}
                startDate={startDate}
                lastPayment={lastPaidDate}
                paymentDue={nextDueDate}
                isPremiumHoliday={isPremiumHoliday}
                isOverDuePremium={isOverDuePremium}
                premiumDueData={premiumDueData}
                policyStatus={policyStatus} 
                term={singlePremiumTerm > 0 ? singlePremiumTerm : term}
                coverageAmount={
                  coverages &&
                  coverages.find(item => item.id === 'LFC')[chosenInsured]
                }
                currency={currency}
              />
            </Grid>
            <Grid item xs={5}>
              <CalculatePanel
                isAvailable={validForIFQ === 'Y' && (policyStatus == 'A' || policyStatus == 'C')} // RI - Recalculate Sustainability and View/Export pdf should be disabled only for Lapsed policies
                currency={currency}
                premium={premium}
                singlePremiumTerm={singlePremiumTerm}
                lifeBasis={lifeAssured.lifeBasis}
                growthRate={growthRateState}
                defaultGrowthRate={defaultGrowthRate}
                sustainabilityYears={sustainabilityYears}
                onDecreasedClicked={handleDecreasedClicked}
                onIncreasedClicked={handleIncreasedClicked}
                recalculateSustainability={data => {
                  setGrowthRateAct(growthRateState);
                  return recalculateSustainability(data);
                }}
                downloadHealthCheckPdf={downloadHealthCheckPdf}
                ageOfLife={ageOfLife}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor="#F9F9F9" borderTop="1px solid #d3d3d3" p={3} mt={10}>
        <Typography
          variant="h1"
          color="textPrimary"
          style={{
            fontWeight: 'normal',
            fontSize: '2.8rem',
            marginBottom: theme.spacing(4),
          }}
        >
          <FormattedMessage {...messages.policySummary} />
        </Typography>
        <PolicySummary chosenInsured={chosenInsured} coverages={coverages} />
      </Box>
    </Wrapper>
  );
}

PolicyDetailsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  downloadHealthCheckPdf: PropTypes.func,
  recalculateSustainability: PropTypes.func,
  setGrowthRateAct: PropTypes.func,
  growthRate: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  productConfig: makeSelectProductConfig(),
  validForIFQ: makeSelectValidForIFQ(),
  lifeAssured: makeSelectLifeAssured(),
  sustainabilityYears: makeSelectSustainabilityYears(),
  policyConfig: makeSelectPolicyConfig(),
});

function mapDispatchToProps(dispatch) {
  return {
    recalculateSustainability: payload =>
      dispatch(recalculateSustainabilityAction(payload)),
    downloadHealthCheckPdf: payload =>
      dispatch(downloadHealthCheckPdfAction(payload)),
    setGrowthRateAct: rate => dispatch(setGrowthRateAction(rate)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PolicyDetailsPage);

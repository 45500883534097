import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import messages from '../../messages';
import { map } from 'lodash/collection';
import moment from 'moment';
import { getGender, filteredParty, partyFilter } from 'helpers/lang';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import _ from 'lodash';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(name, date, relationshipToLifeInsured) {
  return { name, date, relationshipToLifeInsured };
}

// const rows = [createData('Mr. Frank Smith', '02.05.1986', 'Male', 'Smoker')];

function Beneficiaries(props) {
  const theme = useTheme();
  const { parties, classes, options } = props;

  const parseRelationship = data => {
    const relationShipValue = options.beneficiaryRelationship.find(
      opt => opt.value === data,
    )?.title;
    if (relationShipValue) {
      return relationShipValue;
    }

    return _.startCase(_.toLower(data));
  };

  const rows = [
    ...map(
      partyFilter(parties, {
        name: 'isBeneficiary',
        condition: true,
      }),
      party => {
        return createData(
          `${party.firstName} ${party.lastName}`,
          moment(party.dateOfBirth).format('DD MMMM YYYY'),
          parseRelationship(
            party.partyapplicationrelationships.additionalDetails
              .relationshipToLifeInsured,
          ) !== ''
            ? parseRelationship(
                party.partyapplicationrelationships.additionalDetails
                  .relationshipToLifeInsured,
              )
            : ' ',
          //   getGender(party.gender),
          // party.isSmoke,
        );
      },
    ),
  ];
  if (rows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.beneficiaries} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {rows.map((row, index) => (
                    <StyledTableRow key={`${row.name}-${index}`}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: '30%' }}
                      >
                        <Typography variant="h4" className={classes.bold}>
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '30%' }}>
                        <Typography variant="h4">{row.date}</Typography>
                      </StyledTableCell>
                      {!!row.relationshipToLifeInsured ? (
                        <StyledTableCell style={{ width: '40%' }}>
                          <Typography variant="h4">
                            {row.relationshipToLifeInsured}
                          </Typography>
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          style={{ width: '40%' }}
                        ></StyledTableCell>
                      )}
                      {/* <StyledTableCell>
                              <Typography variant="body1">{row.isSmoker}</Typography>
                            </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

Beneficiaries.propTypes = {
  parties: PropTypes.array,
};

export default Beneficiaries;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';

import { forEach, map, includes } from 'lodash/collection';

import { isFieldEmpty } from 'utils/form';
import messages from 'containers/App/messages';

const validate = (values, props) => {
  const errors = {};
  const ISOCode = props.ISOCode;

  if (
    !values.isEmpty() &&
    ISOCode !== 'QA' &&
    ISOCode !== 'BH' // skipped the validation for B&Q products
  ) {
    const parsedValues = values.toJS();

    const bankAccount = parsedValues.bankAccountDetail;
    const bankAccountError = {};
    const requiredTextFields = [
      'cardNumber',
      'bankName',
      'monthNumber',
      'yearNumber',
    ];

    // if (
    //   !parsedValues.isOtherBankAccount &&
    //   !parsedValues.isOtherParties &&
    //   !parsedValues.isExistingZurichPlan
    // ) {
    //   errors.totalPercentage = (
    //     <FormattedMessage {...messages.selectAtleastOne} />
    //   );
    //   errors._error = 'submission failed';
    // }

    let bankAccountDetailsErrors;
    forEach(requiredTextFields, field => {
      if (isFieldEmpty(bankAccount[field])) {
        bankAccountError[field] = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
        bankAccountDetailsErrors = bankAccountError;
      } else if (field === 'cardNumber') {
        if (bankAccount[field]?.length > 16) {
          bankAccountError[field] = (
            <FormattedMessage
              {...globalMessages.maximumLengthDigits}
              values={{ value1: 16 }}
            />
          );
          bankAccountDetailsErrors = bankAccountError;
        }
      } else if (field === 'monthNumber') {
        if (Number(bankAccount[field]) > 11) {
          bankAccountError[field] = (
            <FormattedMessage
              {...globalMessages.maxValueMonthLimit}
              values={{ value: 11 }}
            />
          );
          bankAccountDetailsErrors = bankAccountError;
        }
        if (Number(bankAccount[field]) < 0) {
          bankAccountError[field] = (
            <FormattedMessage {...messages.negativeValuesNotAllowed} />
          );
          bankAccountDetailsErrors = bankAccountError;
        }
        if (!Number.isInteger(Number(bankAccount[field]))) {
          bankAccountError[field] = (
            <FormattedMessage {...messages.decimalValuesNotAllowed} />
          );
          bankAccountDetailsErrors = bankAccountError;
        }
      } else if (field === 'yearNumber') {
        if (Number(bankAccount[field]) > 99) {
          bankAccountError[field] = (
            <FormattedMessage
              {...globalMessages.maxValueLimit}
              values={{ value: 99 }}
            />
          );
          bankAccountDetailsErrors = bankAccountError;
        }
        if (Number(bankAccount[field]) < 0) {
          bankAccountError[field] = (
            <FormattedMessage {...messages.negativeValuesNotAllowed} />
          );
          bankAccountDetailsErrors = bankAccountError;
        }
        if (!Number.isInteger(Number(bankAccount[field]))) {
          bankAccountError[field] = (
            <FormattedMessage {...messages.decimalValuesNotAllowed} />
          );
          bankAccountDetailsErrors = bankAccountError;
        }
      }
    });
    if (!!bankAccountDetailsErrors) {
      errors.bankAccountDetail = bankAccountDetailsErrors;
    }

    if (parsedValues.isOtherBankAccount) {
      const otherBankAccountErrors = [];
      const otherBankAccountDetails = parsedValues.otherBankAccountDetails;
      forEach(otherBankAccountDetails, (otherBank, index) => {
        const otherBankError = {};
        const requiredTextFields = [
          'cardNumber',
          'bankName',
          'monthNumber',
          'yearNumber',
        ];
        forEach(requiredTextFields, field => {
          if (isFieldEmpty(otherBank[field])) {
            otherBankError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
            otherBankAccountErrors[index] = otherBankError;
          } else if (field === 'cardNumber') {
            if (otherBank[field]?.length > 16) {
              otherBankError[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLengthDigits}
                  values={{ value1: 16 }}
                />
              );
              otherBankAccountErrors[index] = otherBankError;
            }
          } else if (field === 'monthNumber') {
            if (Number(otherBank[field]) > 11) {
              otherBankError[field] = (
                <FormattedMessage
                  {...globalMessages.maxValueMonthLimit}
                  values={{ value: 11 }}
                />
              );
              otherBankAccountErrors[index] = otherBankError;
            }
            if (Number(otherBank[field]) < 0) {
              otherBankError[field] = (
                <FormattedMessage {...messages.negativeValuesNotAllowed} />
              );
              otherBankAccountErrors[index] = otherBankError;
            }
            if (!Number.isInteger(Number(otherBank[field]))) {
              otherBankError[field] = (
                <FormattedMessage {...messages.decimalValuesNotAllowed} />
              );
              otherBankAccountErrors[index] = otherBankError;
            }
          } else if (field === 'yearNumber') {
            if (Number(otherBank[field]) > 99) {
              otherBankError[field] = (
                <FormattedMessage
                  {...globalMessages.maxValueLimit}
                  values={{ value: 99 }}
                />
              );
              otherBankAccountErrors[index] = otherBankError;
            }
            if (Number(otherBank[field]) < 0) {
              otherBankError[field] = (
                <FormattedMessage {...messages.negativeValuesNotAllowed} />
              );
              otherBankAccountErrors[index] = otherBankError;
            }
            if (!Number.isInteger(Number(otherBank[field]))) {
              otherBankError[field] = (
                <FormattedMessage {...messages.decimalValuesNotAllowed} />
              );
              otherBankAccountErrors[index] = otherBankError;
            }
          }
        });
      });
      if (otherBankAccountErrors.length > 0) {
        errors.otherBankAccountDetails = otherBankAccountErrors;
      }
    }

    if (parsedValues.isOtherParties) {
      const otherParties = parsedValues.otherPartiesDetails;
      const otherError = {};
      const requiredTextFields = ['potentialBorrowers'];
      let otherPartiesDetailsErrors;
      forEach(requiredTextFields, field => {
        if (isFieldEmpty(otherParties[field])) {
          otherError[field] = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
          otherPartiesDetailsErrors = otherError;
        }
      });
      if (!!otherPartiesDetailsErrors) {
        errors.otherPartiesDetails = otherPartiesDetailsErrors;
      }
    }
    if (
      parsedValues.isExistingZurichPlan &&
      !!parsedValues.existingZurichPlanDetails.isPolicyNumber &&
      parsedValues.existingZurichPlanDetails.isPolicyNumber === true
    ) {
      const existingZurich = parsedValues.existingZurichPlanDetails;
      const existingZurichError = {};
      const requiredTextFields = ['existingPolicyNumber'];
      let existingZurichPlanDetails;
      forEach(requiredTextFields, field => {
        if (isFieldEmpty(existingZurich[field])) {
          existingZurichError[field] = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
          existingZurichPlanDetails = existingZurichError;
        } else if (field === 'existingPolicyNumber') {
          var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
          var formatNumbers = /^[^0-9]+$/;
          if (existingZurich[field]?.length > 50) {
            existingZurichError[field] = (
              <FormattedMessage
                {...globalMessages.maximumLength}
                values={{ value1: 50 }}
              />
            );
            existingZurichPlanDetails = existingZurichError;
          } else if (format.test(existingZurich[field])) {
            existingZurichError[field] = 'Field cannot have special characters';
            existingZurichPlanDetails = existingZurichError;
          }
        }
      });
      if (!!existingZurichPlanDetails) {
        errors.existingZurichPlanDetails = existingZurichPlanDetails;
      }
    }

    if (parsedValues.isLifeOffices) {
      const lifeOffices = parsedValues.lifeOfficesDetails;
      const lifeOfficesError = {};
      const requiredTextFields = [
        'nameOfCompany',
        'policyType',
        'amountOfCover',
        'premiumAmount',
        'policyTerm',
      ];
      const requiredSelectFields = ['policyCurrency'];
      let lifeOfficesDetailsErrors;
      forEach(requiredTextFields, field => {
        if (isFieldEmpty(lifeOffices[field])) {
          lifeOfficesError[field] = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
          lifeOfficesDetailsErrors = lifeOfficesError;
        }
      });
      forEach(requiredSelectFields, field => {
        if (isFieldEmpty(lifeOffices[field])) {
          lifeOfficesError[field] = (
            <FormattedMessage {...globalMessages.pleaseSelectValue} />
          );
          lifeOfficesDetailsErrors = lifeOfficesError;
        }
      });
      if (!!lifeOfficesDetailsErrors) {
        errors.lifeOfficesDetails = lifeOfficesDetailsErrors;
      }
    }
  }
  return errors;
};

export default validate;

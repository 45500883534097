/**
 * PayorDetails
 */
 import React, { useEffect, useState } from 'react';
 import PropTypes from 'prop-types';
 import { FormattedMessage, useIntl } from 'react-intl';
 import { useTheme, makeStyles } from '@material-ui/core/styles';
 import { Field, FormSection } from 'redux-form/immutable';
 import { map, filter, forEach } from 'lodash/collection';
 import { get } from 'lodash/object';
 import { blue } from '@material-ui/core/colors';
 import { Grid, FormControlLabel, Radio, Typography } from '@material-ui/core';
 import DeleteIcon from '@material-ui/icons/Delete';
 import messages from 'containers/ApplicationFormPage/messages';
 
 import globalMessages from 'containers/App/messages';
 import FormTextField from 'components/FormTextField';
 import FormDatePicker from 'components/FormDatePicker';
 import FormNumberField from 'components/FormNumberField';
 import FormRadioGroup from 'components/FormRadioGroup';
 import FormSelect from 'components/FormSelect';
 import Helper from 'components/Helper';
 import {
   getStartDate,
   filterCountryOption,
 } from 'containers/ApplicationFormPage/helpers';
 
 import { isEmpty } from 'lodash';
 import CustomRadio from 'components/CustomRadioButton';
 import { changePlaceHolderColor, changeToTitleCase } from 'helpers/lang';
 import { isDisabledField } from 'helpers/constants';
 
 const useStyle = makeStyles(theme => ({
   hr: {
     margin: '0 -3rem',
     marginTop: theme.spacing(2),
     marginBottom: theme.spacing(3),
     // marginLeft: '-2rem',
     // marginRight: '-2rem',
     borderTop: '1px solid rgba(0, 0, 0, 0.12)',
   },
   bold: {
     fontWeight: 800,
   },
   AlignItem: {
     textAlign: 'center',
   },
 }));
 
 const renderRelationship = ({
   disabled,
   options,
   isCompany,
   gender,
   fieldRelationship,
   fieldReason,
   fieldOther,
   disabledFields,
   formValues,
   change
 }) => {

   var [relations, setrelations] = useState([
     { relation: "Husband", reasons: ["Unemployed", "No bank account / Credit Card", "Relocated to different country", "Gift","Others"] },
     { relation: "Wife", reasons: ["Unemployed", "No bank account / Credit Card", "Relocated to different country", "Gift","Others"]  },
     { relation: "Son", reasons: ["No bank account", "Gift", "Others"] },
     { relation: "Daughter", reasons: ["No bank account", "Gift", "Others"] },
     { relation: "Mother", reasons: ["No bank account", "Gift", "Student", "Others"] },
     { relation: "Father", reasons: ["No bank account", "Gift", "Student", "Others"] },
     { relation: "Sister", reasons: ["No bank account", "Gift", "Others"] },
     { relation: "Brother", reasons: ["No bank account", "Gift", "Others"] },
     
     { relation: "Employee", reasons: ["Benefit as part of company policy", "Others"] },
     { relation: "Business_Owner/Partner", reasons: ["Benefit as part of company policy", "Others"] },
     { relation: "Shareholder", reasons: ["Benefit as part of company policy", "Others"] }, 
   ]);
 
   var [selectedRelation, setSelRelation] = useState("");
   var [selectedReason, setSelReason] = useState("");
 
   var [relChanged, setRelChanged] = useState(false);
   var [reasonChanged, setreasonChanged] = useState(false);
 
 
   var reasonDefault = "";
   var reasonOtherDefault = "";
 
   const [allowedreasons, setAllowedReasons] = useState([{ value: 'Select an option', 
                                                           title: 'Select an option' 
                                                         }]);
  
 useEffect(()=>{
     change(`partyapplicationrelationships.additionalDetails.reasonToPay`, '');
     setAllowedReasons(renderReasons(fieldRelationship));
 }, [fieldRelationship]);
 
 useEffect(()=>{
     change(`partyapplicationrelationships.additionalDetails.other`, '');
 }, [fieldReason]);
 
   function handleRelationChange(e) {
     setSelRelation(e.target.value);
     setSelReason("");
     if (selectedRelation != e.target.value) {
       setRelChanged(true);
     }
   } 
 
   function handleReasonChange(e) {
     let selectedReasonTemp ="";
     selectedReasonTemp = e.target.value;
     setSelReason(selectedReasonTemp);
     if (selectedReason != selectedReasonTemp) {
       setreasonChanged(true);
     }
   }
 
   function handleOnFocus(e) {
    setreasonChanged(true);
   }
  
   const genderRelationship = (isCompany) => {
    
     if (isCompany) {
       return options.companyPayorRelationship;
     }
     const male = ['Father', 'Son', 'Brother', 'Husband'];
     const female = ['Mother', 'Daughter', 'Sister', 'Wife'];
     const ArrGender = [];
     if (gender === '0') {
       forEach(options.payorRelationship, item => {
         forEach(female, data => {
           if (item.title === data) {
             ArrGender.push(item);
           }
         });
       });
     } else {
       forEach(options.payorRelationship, item => {
         forEach(male, data => {
           if (item.title === data) {
             ArrGender.push(item);
           }
         });
       });
     }
     return ArrGender;
   };
 

   const renderReasons = selectedRelation => {
     const ArrReasons = [];
     if (selectedRelation) {
     relations.forEach((relation, index) => {
     if (((relation.relation).toUpperCase()).includes(selectedRelation.toUpperCase())) {
     forEach(options.payorRelationshipReason, item => {
       if (relation.reasons.includes(item.title)) {
       ArrReasons.push(item);
       return ArrReasons;  
       }
      });
     }

     });

     }
     return ArrReasons;
   };
 

   const intl = useIntl();
   return (
     <Grid container spacing={3}>
       {/* {!isCompany && ( */}
 
       <Grid item xs={12} xs={8}>
         <Field 
           name="relationshipToPolicyOwner"
           component={FormSelect}
           style={{
             color: changePlaceHolderColor(fieldRelationship),
           }}
           options={[
             { value: 'Select an option', 
               title: 'Select an option' 
             }
             ,
             ...genderRelationship(isCompany),
           ]}
           onChange={handleRelationChange}
           fullWidth
           label={<FormattedMessage {...messages.relationshipToPolicyOwner2} />}
           placeholder={intl.formatMessage(globalMessages.select)}
           disabled={isDisabledField(
             disabledFields,
             'payor',
             'relationshipToPolicyOwner',
           )}
         />
      </Grid>
 
       <Grid item xs={12} xs={8}>
       <Field
           name="reasonToPay"
           component={FormSelect}
           style={{
             color: changePlaceHolderColor(fieldReason),
           }}
           options={
           (relChanged || reasonChanged) ? ([{ value: 'Select an option',title: 'Select an option'}, ...allowedreasons])
           : ([{value:fieldReason  ,title: changeToTitleCase(fieldReason)}]
          )
         }
           onChange={handleReasonChange}
           onFocus={handleOnFocus}
           fullWidth
           label={<FormattedMessage {...messages.reasonWhyThirdIsPaying}/>}
           placeholder={intl.formatMessage(globalMessages.select)}
           disabled={isDisabledField(disabledFields, 'payor', 'reasonToPay')}
       />
       </Grid>
       {fieldReason == 'OTHERS' ?
       (<Grid item xs={12} xs={8}>
         <Field
           name="other"
           component={FormTextField}
           fullWidth
           label={<FormattedMessage {...messages.otherReasonWhyThirdIsPaying} />}
           placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
           disabled={isDisabledField(disabledFields, 'payor', 'other')}
           format = {relChanged ? (value) => null : (value) => (value)}
         />
       </Grid>
       ) : (
        null
       )}
       
 
       {/* {!isCompany && ( */}
       {/* <Grid item xs={12} xs={8}>
          <Field
            name="other"
            component={FormTextField}
            multiline
            rows={3}
            fullWidth
            label={<FormattedMessage {...messages.other} />}
            placeholder={intl.formatMessage(globalMessages.pleaseProvideDetails)}
            // disabled={disabled ? true : false}
          />
        </Grid> */}
       {/* )} */}
     </Grid>
   );
 };
 
 const lessThan = otherField => (value, previousValue, allValues) => {
   if (!value) return '';
   return value.length <= otherField ? value : previousValue;
 };
 function PayorDetails({
   formValues = {},
   fields,
   options,
   error,
   lifeInsuredParties,
   change,
   fieldName,
   isInforce,
   isCompany = false,
   disabledFields,
 }) {
   const theme = useTheme();
   const intl = useIntl();
   const classes = useStyle();
   const minDate = getStartDate()
     .toDate()
     .setFullYear(
       getStartDate()
         .toDate()
         .getFullYear() - 75,
     );
   const maxDate = getStartDate()
     .toDate()
     .setFullYear(
       getStartDate()
         .toDate()
         .getFullYear() - 18,
     );
   useEffect(() => {
     change(
       `${fieldName}.selectedAddress`,
       get(
         formValues,
         `${fieldName}.additionalDetails.selectedAddress`,
         'different',
       ),
     );
   }, []);
 
  
 
   useEffect(() => {
     if (formValues[fieldName]?.additionalDetails?.selectedAddress) {
       change(
         `${fieldName}.selectedAddress`,
         formValues[fieldName]?.additionalDetails?.selectedAddress,
       );
     } else {
       change(`${fieldName}.selectedAddress`, 'different');
     }
   }, []);
   useEffect(() => {
     if (isCompany) {
       change(`${fieldName}.selectedAddress`, 'different');
     } else if (formValues[fieldName]?.additionalDetails?.selectedAddress) {
       change(
         `${fieldName}.selectedAddress`,
         formValues[fieldName]?.additionalDetails?.selectedAddress,
       );
     }
 
     if (formValues[fieldName]) {
       if (
         formValues[fieldName].selectedAddress !== 'different' &&
         formValues[fieldName].selectedAddress !== '' &&
         typeof formValues[fieldName].selectedAddress !== 'undefined'
       ) {
         // get the party that is selected
         const selectedParty = filter(
           formValues.parties,
           party => party.id === formValues[fieldName].selectedAddress,
         );
         if (selectedParty[0]?.addressLine1) {
           change(`${fieldName}.addressLine1`, selectedParty[0].addressLine1);
         }
         if (selectedParty[0]?.addressLine2) {
           change(`${fieldName}.addressLine2`, selectedParty[0].addressLine2);
         }
         if (selectedParty[0]?.addressLine3) {
           change(`${fieldName}.addressLine3`, selectedParty[0].addressLine3);
         }
         change(
           `${fieldName}.postOfficeBoxLine`,
           selectedParty[0].postOfficeBoxLine,
         );
         change(
           `${fieldName}.postOfficeBoxCity`,
           selectedParty[0].postOfficeBoxCity,
         );
         change(`${fieldName}.city`, selectedParty[0].city);
         change(`${fieldName}.country`, selectedParty[0].country);
         
              }
         if (formValues[fieldName]?.relationships?.reasonToPay != 'OTHERS') {
           change(`${fieldName}.relationships.other`, '');
         }
     }
     
   }, [formValues[fieldName]]);
 
   const optionList = isInforce
     ? options.countryCode
     : options.addressGccCountry;
 
   return (
     <Grid container spacing={3} alignItems="center">
       {!isCompany && (
         <>
           {isInforce ? null : (
             <Grid item xs={12}>
               {/* <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                }}
              > */}
               <Grid container>
                 <Grid
                   item
                   xs={7}
                   style={{
                     display: 'flex',
                     flexDirection: 'row',
                     alignContent: 'center',
                   }}
                 >
                   <Typography variant="body2" style={{ fontSize: '1.45rem' }}>
                     <FormattedMessage {...messages.isPEP} />
                   </Typography>
                   <div style={{ marginRight: '1rem' }}>
                     <Helper
                       helperText={intl.formatMessage(messages.pepDescription)}
                     />
                   </div>
                 </Grid>
                 <Grid item xs={5} className={classes.AlignItem}>
                   <Field
                     name="isPep"
                     type="radio"
                     component={FormRadioGroup}
                     normalize={value => value === 'true'}
                     onBlur={e => {
                       e.preventDefault();
                     }}
                     disabled={isDisabledField(disabledFields, 'payor', 'isPep')}
                   >
                     <FormControlLabel
                       control={
                         <CustomRadio
                           checked={
                             formValues[fieldName] &&
                             formValues[fieldName].isPep === true
                           }
                         />
                       }
                       value
                       label="Yes"
                       labelPlacement="end"
                     />
                     <FormControlLabel
                       control={
                         <CustomRadio
                           checked={
                             formValues[fieldName] &&
                             formValues[fieldName].isPep === false
                           }
                         />
                       }
                       value={false}
                       label="No"
                       labelPlacement="end"
                     />
                   </Field>
                 </Grid>
               </Grid>
               {/* </div> */}
             </Grid>
           )}
           <Grid item xs={6}>
             <Field
               name="firstName"
               component={FormTextField}
               fullWidth
               label={<FormattedMessage {...globalMessages.firstName} />}
               placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
               normalize={lessThan(45)}
               disabled={isDisabledField(disabledFields, 'payor', 'firstName')}
               format = {(value) => changeToTitleCase(value)} 
             />
           </Grid>
           <Grid item xs={6}>
             <Field
               name="lastName"
               component={FormTextField}
               fullWidth
               label={<FormattedMessage {...globalMessages.familyName} />}
               placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
               normalize={lessThan(40)}
               disabled={isDisabledField(disabledFields, 'payor', 'lastName')}
               format = {(value) => changeToTitleCase(value)} 
            />
           </Grid>
           <Grid item xs={6}>
             <Field
               name="preferredName"
               component={FormTextField}
               fullWidth
               label={<FormattedMessage {...messages.alternateName} />}
               placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
               disabled={isDisabledField(
                 disabledFields,
                 'payor',
                 'preferredName',
               )}
             />
           </Grid>
           <Grid item xs={6}>
             <Field
               shrink
               name="dateOfBirth"
               component={FormDatePicker}
               label={<FormattedMessage {...globalMessages.dob} />}
               inputProps={{ inputMode: 'numeric' }}
               dateInputFormat="DD/MM/YYYY"
               placeholder="dd/mm/yyyy"
               margin="normal"
               minDate={minDate}
               maxDate={maxDate}
               InputLabelProps={{
                 style: {
                   fontWeight: 'normal',
                   fontSize: theme.typography.body1.fontSize,
                 },
               }}
               disabled={isDisabledField(disabledFields, 'payor', 'dateOfBirth')}
             />
           </Grid>
 
           <Grid item xs={6}>
             <Field
               name="primaryNationality"
               fullWidth
               component={FormSelect}
               style={{
                 color: changePlaceHolderColor(
                   formValues[fieldName]?.primaryNationality,
                 ),
               }}
               options={
                 !isEmpty(formValues)
                   ? [
                       { value: 'Select an option', title: 'Select an option' },
                       ...filterCountryOption(
                         options.countryCode,
                         formValues[fieldName].secondaryNationality,
                       ),
                     ]
                   : []
               }
               label={<FormattedMessage {...globalMessages.nationality} />}
               type="text"
               color="primary"
               margin="normal"
               variant="standard"
               disabled={isDisabledField(
                 disabledFields,
                 'payor',
                 'primaryNationality',
               )}
             />
           </Grid>
           <Grid item xs={6}>
             <Field
               name="secondaryNationality"
               fullWidth
               component={FormSelect}
               style={{
                 color: changePlaceHolderColor(
                   formValues[fieldName]?.secondaryNationality,
                 ),
               }}
               options={
                 !isEmpty(formValues)
                   ? [
                       { value: 'Select an option', title: 'Select an option' },
                       ...filterCountryOption(
                         options.countryCode,
                         formValues[fieldName].primaryNationality,
                       ),
                     ]
                   : []
               }
               label={<FormattedMessage {...messages.dualNationality} />}
               type="text"
               color="primary"
               margin="normal"
               variant="standard"
               disabled={isDisabledField(
                 disabledFields,
                 'payor',
                 'secondaryNationality',
               )}
             />
           </Grid>
           <Grid item xs={6}>
             <Field
               name="countryOfBirth"
               fullWidth
               component={FormSelect}
               style={{
                 color: changePlaceHolderColor(
                   formValues[fieldName]?.countryOfBirth,
                 ),
               }}
               options={[
                 { value: 'Select an option', title: 'Select an option' },
                 ...options.countryCode,
               ]}
               label={<FormattedMessage {...messages.countryOfBirth} />}
               type="text"
               color="primary"
               margin="normal"
               variant="standard"
               disabled={isDisabledField(
                 disabledFields,
                 'payor',
                 'countryOfBirth',
               )}
             />
           </Grid>
           <Grid item xs={6}>
             <Field name="gender" type="radio" component={FormRadioGroup}>
               <FormControlLabel
                 control={
                   <CustomRadio
                     disabled={isDisabledField(
                       disabledFields,
                       'payor',
                       'gender',
                     )}
                     checked={
                       formValues[fieldName] &&
                       formValues[fieldName].gender == '1'
                     }
                   />
                 }
                 value={1}
                 label={<FormattedMessage {...globalMessages.male} />}
                 labelPlacement="end"
                 disabled={isDisabledField(
                   disabledFields,
                   'payor',
                   'preferredName',
                 )}
               />
 
               <FormControlLabel
                 control={
                   <CustomRadio
                     disabled={isDisabledField(
                       disabledFields,
                       'payor',
                       'gender',
                     )}
                     checked={
                       formValues[fieldName] &&
                       formValues[fieldName].gender == '0'
                     }
                   />
                 }
                 value={0}
                 label={<FormattedMessage {...globalMessages.female} />}
                 labelPlacement="end"
                 disabled={isDisabledField(
                   disabledFields,
                   'payor',
                   'preferredName',
                 )}
               />
             </Field>
           </Grid>
           <Grid item xs={12}>
             <hr className={classes.hr} />
           </Grid>
         </>
       )}
       {isCompany && (
         <>
           <Grid item xs={6}>
             <Field
               name="lastName"
               component={FormTextField}
               fullWidth
               label={<FormattedMessage {...messages.companyName} />}
               placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
             />
           </Grid>
           <Grid item xs={6}>
             <Field
               shrink
               name="dateOfBirth"
               component={FormDatePicker}
               label={
                 <FormattedMessage {...globalMessages.dateOfIncorporation} />
               }
               inputProps={{ inputMode: 'numeric' }}
               dateInputFormat="DD/MM/YYYY"
               placeholder="dd/mm/yyyy"
               margin="normal"
               minDate={minDate}
               maxDate={maxDate}
               InputLabelProps={{
                 style: {
                   fontWeight: 'normal',
                   fontSize: theme.typography.body1.fontSize,
                 },
               }}
             />
           </Grid>
         </>
       )}
       {!isCompany && (
         <Grid item xs={7}>
           <Field
             name="email"
             component={FormTextField}
             fullWidth
             label={<FormattedMessage {...messages.emailAddress} />}
             placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
             disabled={isDisabledField(disabledFields, 'payor', 'email')}
           />
         </Grid>
       )}
       {!isCompany && (
         <Grid item xs={12}>
           <Grid container spacing={3}>
             <Grid item xs={3}>
               <Field
                 name="primaryCountryDialingCode"
                 fullWidth
                 component={FormSelect}
                 style={{
                   color: changePlaceHolderColor(
                     formValues[fieldName]?.primaryCountryDialingCode,
                   ),
                 }}
                 options={[
                   { value: 'Select', title: 'Select' },
                   ...options.countryCode,
                 ]}
                 label={<FormattedMessage {...messages.countryCode} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 value=""
                 disabled={isDisabledField(
                   disabledFields,
                   'payor',
                   'primaryCountryDialingCode',
                 )}
               />
             </Grid>
             <Grid item xs={6}>
               <Field
                 name="primaryPhoneNumber"
                 fullWidth
                 component={FormNumberField}
                 thousandSeparator=""
                 label={<FormattedMessage {...messages.mobileNumber} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={isDisabledField(
                   disabledFields,
                   'payor',
                   'primaryPhoneNumber',
                 )}
               />
             </Grid>
           </Grid>
         </Grid>
       )}
       {!isCompany && (
         <Grid item xs={12}>
           <Grid container spacing={3}>
             <Grid item xs={3}>
               <Field
                 name="secondaryCountryDialingCode"
                 fullWidth
                 component={FormSelect}
                 style={{
                   color: changePlaceHolderColor(
                     formValues[fieldName]?.secondaryCountryDialingCode,
                   ),
                 }}
                 options={[
                   { value: 'Select', title: 'Select' },
                   ...options.countryCode,
                 ]}
                 label={<FormattedMessage {...messages.countryCode} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 value=""
                 disabled={isDisabledField(
                   disabledFields,
                   'payor',
                   'secondaryCountryDialingCode',
                 )}
               />
             </Grid>
             <Grid item xs={6}>
               <Field
                 name="secondaryPhoneNumber"
                 fullWidth
                 component={FormNumberField}
                 thousandSeparator=""
                 label={<FormattedMessage {...messages.telephoneNumber} />}
                 placeholder={intl.formatMessage(messages.pleaseEnter)}
                 type="text"
                 color="primary"
                 margin="normal"
                 variant="standard"
                 disabled={isDisabledField(
                   disabledFields,
                   'payor',
                   'secondaryPhoneNumber',
                 )}
               />
             </Grid>
           </Grid>
         </Grid>
       )}
 
       {!isCompany && (
         <Grid item xs={12}>
           <hr className={classes.hr} />
         </Grid>
       )}
       {!isCompany && (
         <>
           {isInforce ? (
             ''
           ) : (
             <Grid item xs={12}>
               <Field
                 name="selectedAddress"
                 type="radio"
                 component={FormRadioGroup}
               >
                 <div className={classes.radioForm}>
                   <FormControlLabel
                     style={{ marginBottom: '6px' }}
                     control={
                       <CustomRadio
                         checked={
                           formValues[fieldName] &&
                           formValues[fieldName].selectedAddress === 'different'
                         }
                       />
                     }
                     value="different"
                     label={
                       <Typography variant="body1" className={classes.bold}>
                         Different address
                       </Typography>
                     }
                     labelPlacement="end"
                   />
                   {map(lifeInsuredParties, (party, index) => (
                     <FormControlLabel
                       style={{ marginBottom: '6px' }}
                       key={`${party.id}-${index}`}
                       control={
                         <CustomRadio
                           disabled={isDisabledField(
                             disabledFields,
                             'payor',
                             'selectedAddress',
                           )}
                           checked={
                             formValues[fieldName] &&
                             party.id === formValues[fieldName].selectedAddress
                           }
                         />
                       }
                       value={party.id}
                       label={
                         <Typography
                           variant="body1"
                           className={classes.bold}
                         >{`Same as ${party.firstName} ${party.lastName}`}</Typography>
                       }
                       labelPlacement="end"
                     />
                   ))}
                 </div>
               </Field>
             </Grid>
           )}
         </>
       )}
 
       {/* ADDRESS */}
       <Grid item xs={6}>
         <Field
           name="addressLine1"
           component={FormTextField}
           fullWidth
           label={
             isCompany ? (
               <FormattedMessage {...messages.flatNumber2} />
             ) : (
               <FormattedMessage {...messages.flatNumber} />
             )
           }
           placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
           disabled={
             (formValues[fieldName] &&
               formValues[fieldName].selectedAddress !== 'different') ||
             isDisabledField(disabledFields, 'payor', 'addressLine3')
           }
         />
       </Grid>
       <Grid item xs={6}>
         <Field
           name="addressLine2"
           component={FormTextField}
           fullWidth
           label={<FormattedMessage {...messages.propertyName} />}
           placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
           disabled={
             (formValues[fieldName] &&
               formValues[fieldName].selectedAddress !== 'different') ||
             isDisabledField(disabledFields, 'payor', 'addressLine3')
           }
         />
       </Grid>
       <Grid item xs={8}>
         <Field
           name="addressLine3"
           component={FormTextField}
           fullWidth
           label={<FormattedMessage {...messages.area} />}
           placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
           disabled={
             (formValues[fieldName] &&
               formValues[fieldName].selectedAddress !== 'different') ||
             isDisabledField(disabledFields, 'payor', 'addressLine3')
           }
         />
       </Grid>
       <Grid item xs={8}>
         <Field
           name="city"
           component={FormTextField}
           fullWidth
           label={<FormattedMessage {...messages.emirateCity} />}
           placeholder={intl.formatMessage(globalMessages.select)}
           disabled={
             (formValues[fieldName] &&
               formValues[fieldName].selectedAddress !== 'different') ||
             isDisabledField(disabledFields, 'payor', 'city')
           }
         />
       </Grid>
       {!isCompany && (
         <Grid item xs={8}>
           <Field
             name="postOfficeBoxLine"
             component={FormTextField}
             fullWidth
             label={<FormattedMessage {...messages.poBox} />}
             placeholder={intl.formatMessage(globalMessages.select)}
             disabled={
               (formValues[fieldName] &&
                 formValues[fieldName].selectedAddress !== 'different') ||
               isDisabledField(disabledFields, 'payor', 'postOfficeBoxLine')
             }
           />
         </Grid>
       )}
       <Grid item xs={8}>
         <Field
           name="country"
           component={FormSelect}
           style={{
             color: changePlaceHolderColor(formValues[fieldName]?.country),
           }}
           options={[
             { value: 'Select an option', title: 'Select an option' },
             ...optionList,
           ]}
           fullWidth
           label={<FormattedMessage {...messages.country} />}
           placeholder={intl.formatMessage(globalMessages.select)}
           disabled={
             (formValues[fieldName] &&
               formValues[fieldName].selectedAddress !== 'different') ||
             isDisabledField(disabledFields, 'payor', 'country')
           }
         />
       </Grid>
 
       {!isCompany && (
         <>
           <Grid item xs={12}>
             <hr className={classes.hr} />
           </Grid>
           <Grid item xs={6}>
             <Field
               name="postOfficeBoxLine"
               component={FormTextField}
               fullWidth
               label={<FormattedMessage {...messages.poBoxOptional} />}
               placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
               disabled={
                 (formValues[fieldName] &&
                   formValues[fieldName].selectedAddress !== 'different') ||
                 isDisabledField(disabledFields, 'payor', 'postOfficeBoxLine')
               }
             />
           </Grid>
           <Grid item xs={6}>
             <Field
               name="postOfficeBoxCity"
               component={FormTextField}
               fullWidth
               label={<FormattedMessage {...messages.emirateCityOptional} />}
               placeholder={intl.formatMessage(globalMessages.select)}
               disabled={
                 (formValues[fieldName] &&
                   formValues[fieldName].selectedAddress !== 'different') ||
                 isDisabledField(disabledFields, 'payor', 'postOfficeBoxCity')
               }
             />
           </Grid>
         </>
       )}
       <Grid item xs={12}>
         <hr className={classes.hr} />
       </Grid>
       {/* {!isInforce && ( */}
       <Grid item xs={12}>
         <FormSection
           name="relationships"
           component={renderRelationship}
           disabled={
             formValues[fieldName] &&
             formValues[fieldName].selectedAddress !== 'different'
           }
           fieldRelationship={
             formValues[fieldName]?.relationships?.relationshipToPolicyOwner
           }
           fieldReason={
             (formValues[fieldName]?.relationships?.reasonToPay 
               === formValues[fieldName]?.relationships?.other) ? 
               "Others" : formValues[fieldName]?.relationships?.reasonToPay
           }
           fieldOther={
             formValues[fieldName]?.relationships?.other 
            
           }
           options={options}
           gender={
             formValues[fieldName] && formValues[fieldName].gender
               ? formValues[fieldName].gender
               : '0'
           }
           isCompany={isCompany}
           isInforce={isInforce}
           disabledFields={disabledFields}
           formValues={formValues}
           change={change}
         />
       </Grid>
     </Grid>
   );
 }
 
 PayorDetails.propTypes = {
   fields: PropTypes.object,
   lifeInsuredParties: PropTypes.array,
   change: PropTypes.func,
   fieldName: PropTypes.string,
   isCompany: PropTypes.bool,
   options: PropTypes.object,
   formValues: PropTypes.object,
   error: PropTypes.bool,
   disabledFields: PropTypes.object,
 };
 
 export default PayorDetails;
 
/**
 * ParentsPersonalDetails
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import { Grid, FormControlLabel, Box } from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import FormRadioGroup from 'components/FormRadioGroup';
import FormCheck from 'components/FormCheck';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import CustomRadio from 'components/CustomRadioButton';
import Helper from 'components/Helper';
import { changePlaceHolderColor, changeToTitleCase } from 'helpers/lang';
import messages from '../messages';


const lessThan = otherField => (value, previousValue, allValues) => {
    if (!value) return '';
    return value.length <= otherField ? value : previousValue;
};


function ParentsPersonalDetails({
    disabled,
    formValues = {},
    options,
    disabledPolicyOwner,
    isNationalityShowing,
    minDate,
    maxDate,
}) {

    const theme = useTheme();
    const intl = useIntl();
    const [gender, setGender] = useState(formValues?.gender ? formValues?.gender : '')
    return (
        <Box mb={3} ml={1}>
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <Field
                        name="firstName"
                        disabled={disabled}
                        style={{ width: 260 }}
                        component={FormTextField}
                        label={<FormattedMessage {...messages.policyOwner1FirstName} />}
                        type="text"
                        margin="normal"
                        normalize={lessThan(45)}
                        placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                        format={(value) => changeToTitleCase(value)}
                    />
                </Grid>

                <Grid item>
                    <Field
                        name="lastName"
                        disabled={disabled}
                        style={{ width: 260 }}
                        component={FormTextField}
                        label={<FormattedMessage {...messages.policyOwner1FamilyName} />}
                        margin="normal"
                        normalize={lessThan(40)}
                        placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                        format={(value) => changeToTitleCase(value)}
                    />
                </Grid>
                <Grid container />
                <Grid item>
                    <Field
                        style={{ width: 260 }}
                        disabled={disabled}
                        name="dateOfBirth"
                        component={FormDatePicker}
                        label={<FormattedMessage {...globalMessages.dob} />}
                        dateInputFormat="DD/MM/YYYY"
                        placeholder="dd/mm/yyyy"
                        margin="normal"
                        minDate={minDate}
                        maxDate={maxDate}
                        inputProps={{ inputMode: 'numeric' }}
                        InputLabelProps={{
                            style: {
                                fontSize: theme.typography.body2.fontSize,
                            },
                        }}
                    />
                </Grid>
                <Grid item>
                    <Field
                        name="gender"
                        type="radio"
                        component={FormRadioGroup}
                        disabled={disabled}
                        onChange={(e, newVal) => {
                            if (newVal) {
                                setGender(newVal)
                            }
                        }
                        }
                    >
                        <FormControlLabel
                            disabled={disabled}
                            style={{ marginRight: theme.spacing(2) }}
                            control={<CustomRadio checked={gender == '1'} />}
                            value={'1'}
                            label={<FormattedMessage {...globalMessages.male} />}
                            labelPlacement="end"
                        />

                        <FormControlLabel
                            disabled={disabled}
                            control={<CustomRadio checked={gender == '0'} />}
                            value={'0'}
                            label={<FormattedMessage {...globalMessages.female} />}
                            labelPlacement="end"
                        />
                    </Field>
                </Grid>
                <Grid item xs={12}>
                    <Field
                        // shrink
                        name="isSmoke"
                        disabled={disabled}
                        component={FormBooleanOption}
                        type="text"
                        margin="normal"
                        label={
                            <>
                                <FormattedMessage {...globalMessages.isSmoker} />
                                <Helper
                                    helperText={globalMessages.tooltipInsured.defaultMessage}
                                />
                            </>
                        }
                        fullWidth
                    />
                </Grid>
                <Grid item>
                    <Field
                        // shrink
                        disabled={disabled}
                        name="countryOfResidence"
                        style={{
                            width: 260,
                            color: changePlaceHolderColor(formValues.countryOfResidence),
                        }}
                        component={FormSelect}
                        // options={options.countryCode}
                        options={[
                            { value: 'Select an option', title: 'Select an option' },
                            { value: 'AE', title: 'United Arab Emirates' },
                        ]}
                        label={<FormattedMessage {...messages.countryOfResidence} />}
                        placeholder={intl.formatMessage(globalMessages.select)}
                        type="text"
                        margin="normal"
                    />
                </Grid>
                {isNationalityShowing ? (
                    <Grid item >
                        <Field
                            // shrink
                            disabled={disabled}
                            name="nationality"
                            style={{
                                width: 260,

                            }}
                            component={FormSelect}
                            // options={options.countryCode}
                            options={[
                                { value: 'Select an option', title: 'Select an option' },
                                ...options.countryCode,
                            ]}
                            label={<FormattedMessage {...globalMessages.nationality} />}
                            placeholder={intl.formatMessage(globalMessages.select)}
                            type="text"
                            margin="normal"
                        />
                    </Grid>
                ) : null}
                <Grid item>
                    <Grid item xs={12}>
                        <Field
                            // shrink
                            disabled={disabled}
                            name="relationshipToLifeInsured"
                            style={{
                                width: 260,

                            }}
                            component={FormSelect}
                            // options={options.countryCode}
                            options={[
                                { value: 'Select an option', title: 'Select an option' },
                                { value: 'MOTHER', title: 'Mother' },
                                { value: 'FATHER', title: 'Father' },

                            ]}
                            label={<FormattedMessage {...messages.relationshipToChild} />}
                            placeholder={intl.formatMessage(globalMessages.select)}
                            type="text"
                            margin="normal"
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ visibility: 'hidden' }}>
                <Field
                    name="prospectId"
                    component={FormTextField}
                    type="hidden"
                // margin="normal"
                // variant="standard"
                />
            </Grid>
        </Box >
    );
}

ParentsPersonalDetails.propTypes = {
    options: PropTypes.object,
    formValues: PropTypes.object,
    disabledPolicyOwner: PropTypes.bool,
    isNationalityShowing: PropTypes.bool,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    productCode: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default ParentsPersonalDetails;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import {
  Grid,
  Typography,
  Box,
  Button,
  Link,
  Container,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Dialog from 'components/Dialog';
import {
  CheckBoxOutlined as CheckedIcon,
  CheckBoxOutlineBlankOutlined as EmptyIcon,
} from '@material-ui/icons';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';
import messages from 'containers/IdVerification/messages';
import globalMessages from 'containers/App/messages';
import { FormattedMessage } from 'react-intl';
import ImgSrc1 from 'images/license.png';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { makeSelectShuftiPro } from 'containers/IdVerification/selectors';
import { startVerificationAction } from 'containers/IdVerification/actions';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import NoticeBox from 'components/NoticeBox';
import IFrame from './IFrame';
import IFrameWrapper from './IFrameWrapper';

const useStyle = makeStyles(() => ({
  actionContainer: {
    margin: '0.7rem 0.5rem',
    position: 'relative',
  },
  actionDialogContainer: { padding: '0 5rem' },
}));

function Landing({ triggerVerification, shuftiPro }) {
  const theme = useTheme();
  const classes = useStyle();
  const location = useLocation();
  const [sessionId, setSessionId] = useState('');
  const [accepted, setAccepted] = useState(false);
  const [errorPopup, setErrorPopup] = useState({ isOpen: false, message: '' });
  useEffect(() => {
    const parsedQ = queryString.parse(location.search);
    if (!parsedQ.id) {
      window.close();
    } else {
      setSessionId(parsedQ.id);
    }
  }, []);

  useEffect(() => {
    if (shuftiPro.error !== '') {
      setErrorPopup({ isOpen: true, message: shuftiPro.error });
    }
  }, [shuftiPro.error]);
  if (shuftiPro.verificationUrl && shuftiPro.reference) {
    return (
      <IFrameWrapper>
        <IFrame
          title="ID Verification"
          src={shuftiPro.verificationUrl}
          id="shuftipro-iframe"
          name="shuftipro-iframe"
          allow="camera"
        />
      </IFrameWrapper>
    );
  }

  return (
    <Box mt={4}>
      <Container maxWidth="sm">
        <Box mb={3}>
          <Typography
            variant="h1"
            style={{
              fontSize: '2.8rem',
              fontWeight: 300,
            }}
          >
            <FormattedMessage {...messages.landingHeading} />
          </Typography>
        </Box>
        <Box mb={4}>
          <Grid container spacing={3} wrap="nowrap">
            <Grid item xs>
              <Box mb={2}>
                <Typography variant="body1">
                  <FormattedMessage
                    {...messages.landingDesc}
                    values={{
                      br: <br />,
                    }}
                  />
                </Typography>
              </Box>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={accepted}
                      color="primary"
                      icon={<EmptyIcon />}
                      checkedIcon={<CheckedIcon />}
                      onChange={e => {
                        setAccepted(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <FormattedMessage
                      {...messages.acceptPrivacyPolicy}
                      values={{
                        link: (
                          <Link
                            underline="always"
                            component={RouterLink}
                            to="/legal-privacy"
                          >
                            <FormattedMessage {...messages.privacyPolicy} />
                          </Link>
                        ),
                      }}
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item>
              <img height={96} src={ImgSrc1} alt="ID card" />
            </Grid>
          </Grid>
        </Box>
        <NoticeBox variant="info" mb={6}>
          <Typography variant="body2" color="inherit">
            <FormattedMessage {...messages.idVerificationNotice} />
          </Typography>
        </NoticeBox>
        <Box textAlign="center">
          <Button
            color="primary"
            variant="contained"
            disabled={!accepted}
            onClick={() => triggerVerification(sessionId)}
          >
            <FormattedMessage {...globalMessages.next} />
          </Button>
        </Box>
      </Container>
      <Dialog
        open={errorPopup.isOpen}
        onClose={() => {
          setErrorPopup({ isOpen: false, message: '' });
        }}
        title="ID Verification Error"
        variant="error"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
            className={classes.actionDialogContainer}
          >
            <Grid item xs={12} md={6}>
              <Button
                fullWidth
                style={{ color: theme.palette.primary.dark }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setErrorPopup({ isOpen: false, message: '' });
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="h1">{errorPopup.message}</Typography>
      </Dialog>
    </Box>
  );
}

Landing.propTypes = {
  triggerVerification: PropTypes.func,
  shuftiPro: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  shuftiPro: makeSelectShuftiPro(),
});

function mapDispatchToProps(dispatch) {
  return {
    triggerVerification: sessionId =>
      dispatch(startVerificationAction(sessionId)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Landing);

import {
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import messages from '../../../messages';
import { FormattedMessage } from 'react-intl';
import { useTheme } from '@material-ui/core/styles';
import { RemoveCircleOutline, AddCircleOutline } from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import Helper from 'components/Helper';
import { lifeText, WHOLE_OF_LIFE } from '../../../constants';

import { calculateSustainYears } from 'containers/InforceIllutrationPage/helper';

const AdjustButton = ({ theme, onDecreasedClicked, onIncreasedClicked }) => {
  return (
    <Grid item xs container alignItems="center" justify="flex-end">
      <Grid item>
        <IconButton onClick={onDecreasedClicked} size="medium">
          <RemoveCircleOutline style={{ color: theme.palette.success.main }} />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={onIncreasedClicked} size="medium">
          <AddCircleOutline style={{ color: theme.palette.success.main }} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default function CalculatePanel({
  onRecalculateClicked,
  onIncreasedClicked,
  onDecreasedClicked,
  currency,
  premium,
  lifeBasis,
  growthRate,
  recalculateSustainability,
  isAvailable,
  sustainabilityYears,
  downloadHealthCheckPdf,
  ageOfLife,
  singlePremiumTerm,
}) {
  const theme = useTheme();
  const [fontSize, setFontSize] = useState('6.6rem');

  useEffect(() => {
    if (typeof sustainabilityYears === 'number') {
      if (
        calculateSustainYears(sustainabilityYears, ageOfLife) === WHOLE_OF_LIFE
      ) {
        return setFontSize('2.4rem');
      }
      return setFontSize('6.6rem');
    }
  }, [sustainabilityYears]);

  function checkSinglePreimum(singlePremium){
    if (singlePremiumTerm > 0){
      return <Typography
      variant="h4"
      gutterBottom
      style={{
        fontSize: '1.8rem',
        fontWeight: 'bold',
        color: isAvailable
          ? theme.palette.common.white
          : theme.palette.common.black,
      }}
    >
      per year
    </Typography>
    }
  }
  
  return (
    <Box
      bgcolor={
        isAvailable ? theme.palette.primary.dark : theme.palette.background.grey
      }
    >
      {/* ======= FIRST PART =========== */}
      <Box p={3}>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                color: isAvailable
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              }}
            >
              <FormattedMessage {...messages.premium} />
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography
              variant="h4"
              gutterBottom
              style={{
                fontSize: '2.8rem',
                fontWeight: 'bold',
                color: isAvailable
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              }}
            >
              {currency} {formatMonetaryValue(premium, false)}
            </Typography>
            {checkSinglePreimum(singlePremiumTerm)}
          </Grid>
          <Grid item xs>
            <Divider
              style={{
                background: isAvailable
                  ? theme.palette.primary.main
                  : theme.palette.common.white,
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {/* ======= SECOND PART =========== */}
      <Box px={3} pb={3}>
        <Grid container direction="column">
          <Grid item xs>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                color: isAvailable
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              }}
            >
              <FormattedMessage {...messages.growthRate} />
            </Typography>
          </Grid>
          <Grid item xs container justify="space-between" alignItems="center">
            <Grid item>
              <Typography
                variant="h4"
                paragraph
                style={{
                  fontSize: '2.8rem',
                  fontWeight: 'bold',
                  color: isAvailable
                    ? theme.palette.success.main
                    : theme.palette.common.black,
                }}
              >
                {growthRate}
              </Typography>
            </Grid>
            {isAvailable && (
              <AdjustButton
                theme={theme}
                onIncreasedClicked={onIncreasedClicked}
                onDecreasedClicked={onDecreasedClicked}
              />
            )}
          </Grid>
          <Grid item xs>
            <Button
              onClick={onRecalculateClicked}
              fullWidth
              disabled={!isAvailable}
              variant="text"
              style={{
                padding: theme.spacing(2),
                background: isAvailable
                  ? theme.palette.primary.main
                  : theme.palette.background.greyDarker,
                color: isAvailable
                  ? theme.palette.common.white
                  : theme.palette.common.black,
                fontWeight: 'bold',
              }}
              onClick={() => recalculateSustainability({ growthRate })}
            >
              <FormattedMessage {...messages.recalculate} />
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Divider
        style={{
          background: isAvailable
            ? theme.palette.primary.main
            : theme.palette.common.white,
        }}
      />
      {/* ======= LAST PART =========== */}
      <Box p={3}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <Typography
              variant="body2"
              gutterBottom
              style={{
                color: isAvailable
                  ? theme.palette.common.white
                  : theme.palette.common.black,
              }}
            >
              <FormattedMessage {...messages.sustainableUntilAge} />
              <Helper
                helperText={messages.sustainableUntilAgeTooltip.defaultMessage}
              />
            </Typography>
          </Grid>
          <Grid item xs container>
            <Grid item>
              <Typography
                variant="h1"
                align="left"
                style={{
                  fontSize: fontSize,
                  fontWeight: sustainabilityYears === '--' ? 'light' : 'bold',
                  color: isAvailable
                    ? theme.palette.common.white
                    : theme.palette.common.black,
                }}
              >
                {`${calculateSustainYears(sustainabilityYears, ageOfLife)} `}
                <Typography
                  component="span"
                  variant="body2"
                  style={{
                    color: '#9B9A9B',
                  }}
                >
                  {lifeBasis && (
                    <FormattedMessage {...messages[lifeText[lifeBasis]]} />
                  )}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Divider
        style={{
          background: isAvailable
            ? theme.palette.primary.main
            : theme.palette.common.white,
        }}
      />
      <Button
        onClick={() => downloadHealthCheckPdf({ growthRate })}
        fullWidth
        disabled={!isAvailable}
        variant="text"
        style={{
          padding: theme.spacing(3),
          color: isAvailable
            ? theme.palette.success.main
            : theme.palette.common.black,
          fontWeight: 'bold',
        }}
      >
        <FormattedMessage {...messages.viewExportPDF} />
      </Button>
    </Box>
  );
}
CalculatePanel.propTypes = {
  // TODO: propTypes here
};

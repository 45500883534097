import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import request from 'utils/request';
import { api } from 'environments';
import {
  GET_PRODUCT_CONFIG,
  FETCH_ILLUSTRATIONS_ACTION,
  GET_QUOTES_ACTION,
  GET_SEARCH_ACTION, 
  CLONE_PRODUCT,
  SEND_EMAIL_ACTION,
  GET_CUSTOMERS_ACTION,
  DELETE_ILLUSTRATION_ACTION,
} from './constants';
import { setAppLoadingAction } from 'containers/App/actions';
import {
  setProductConfigAction,
  setProductConfig2Action,
  setIllustrationsAction,
  setQuotesAction,
  setProductIdAction,
  setErrorAction,
  setCustomersAction,
  setDeleteIllustrationAction,
} from './actions';
import { makeSelectAuth } from 'containers/App/selectors';

export function* getProductConfig(action) {
  yield put(setAppLoadingAction(true));
  const isSecondaryProduct = action.payload?.isSecondaryProduct;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/product?productCode=${
    action.payload.productCode
  }&productVersion=${action.payload.productVersion}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setAppLoadingAction(false));
      if (isSecondaryProduct) {
        yield put(setProductConfig2Action(response.data));
      } else {
        yield put(setProductConfigAction(response.data));
      }
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* cloneQuote(action) {
  yield put(setAppLoadingAction(true));

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/quote/${action.payload}/quote-reference`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setProductIdAction(response.data));
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* fetchIllustrations(action) {
  yield put(setAppLoadingAction(true));

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/agency/${action.payload}/illustrations`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    // TODO: PASS THE CORRECT DATA FORMAT INTO REDUX STATE
    yield put(setIllustrationsAction(response.data));
  } catch (err) {
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* getCustomers(action) {
  yield put(setAppLoadingAction(true));

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/get-parties?searchText=${action.payload.searchText}&pageSize=20&pageNumber=1`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    // TODO: PASS THE CORRECT DATA FORMAT INTO REDUX STATE
    yield put(setCustomersAction(response.data));
  } catch (err) {
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* getQuotes(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v1/b2b/prospect/${action.payload.id}/with-li-quotes`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    // TODO: PASS THE CORRECT DATA FORMAT INTO REDUX STATE
    yield put(setQuotesAction(response.data));
  } catch (err) {
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* getSearch(action) { // LAVANYA
  yield put(setAppLoadingAction(true));

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v1/b2b/agency/${action.payload.agencyNumber}/illustrations?searchText=${action.payload.valueSearch}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    yield put(setIllustrationsAction(response.data));
  } catch (err) {
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* emailQuote(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/bi-pdf-email`;

  const myBody = {
    quoteId: action.payload.id,
    email: action.payload.email,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (!response.error) {
      yield put(setAppLoadingAction(false));
    } else {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* deleteIllustration(action) {
  const auth = yield select(makeSelectAuth());
  const {
    payload: { id, productVersion, productCode },
  } = action;
  const endpoint = `${api.host}/api/v${api.version}/b2b/prospect/${id}/delete-li-quotes`;

  const myBody = {
    productCode,
    productVersion: productVersion || '',
  };

  const requestOpt = {
    method: 'DELETE',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    const message = response.message;
    const data = { idDelete: id, message: message };
    yield put(setDeleteIllustrationAction(data));
  } catch (err) {
    console.log(err);
  }
}

// Individual exports for testing
export default function* illustrationPageSaga() {
  yield all([
    takeLatest(GET_PRODUCT_CONFIG, getProductConfig),
    takeLatest(FETCH_ILLUSTRATIONS_ACTION, fetchIllustrations),
    takeLatest(GET_CUSTOMERS_ACTION, getCustomers),
    takeLatest(CLONE_PRODUCT, cloneQuote),
    takeLatest(GET_QUOTES_ACTION, getQuotes),
    takeLatest(GET_SEARCH_ACTION, getSearch),
    takeLatest(SEND_EMAIL_ACTION, emailQuote),
    takeLatest(DELETE_ILLUSTRATION_ACTION, deleteIllustration),
  ]);
}

/**
 *
 * FormFooter
 *
 */

import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    flex-direction: row;
    width: 100%;
    height: 70px;
    border-top: 1px solid ${props => props.theme.palette.divider};
    z-index: ${props => props.theme.zIndex.drawer + 1};
    background-color: ${props => props.theme.palette.common.white};
    bottom: 0;
    left: 0;
    position: fixed;
  }
`;

function FormFooterAR({
  onClickPrevious,
  onClickExit,
  onClickNext,
  disabledPrevious,
  disabledNext,
  label1,
  label2,
  label3,
}) {
  const theme = useTheme();
  return (
    <Wrapper theme={theme} container alignItems="stretch" style={{position:'sticky'}}>
     
 <Grid item xs sx={4} >
        <Button
          variant="contained"
          color="primary"
          disabled={disabledPrevious}
          onClick={onClickPrevious}
          margin="normal"
          style={{
          //  padding: '18px 22px',
            height: '100%',
            float:'right',
            marginRight:'100px'
          }}
          
        >
          {label1}
        </Button>
      </Grid>
      
      <Grid item xs sx={4}>
        <Button
          variant="contained"
          color="primary"
          disabled={disabledNext}
          onClick={onClickNext}
          style={{
           // padding: '17px 22px',
            height: '100%',
            
          }}
        >
          {label3}
        </Button>
      </Grid>
    
    </Wrapper>
  );
}

FormFooterAR.propTypes = {
  onClickPrevious: PropTypes.func,
  onClickExit: PropTypes.func,
  onClickNext: PropTypes.func,
  disabledPrevious: PropTypes.bool,
  disabledNext: PropTypes.bool,
  label1: PropTypes.node,
  label2: PropTypes.node,
  label3: PropTypes.node,
};

export default FormFooterAR;

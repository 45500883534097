import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the futuraIllustrationPage state domain
 */
const selectFuturaIllustrationPageDomain = state =>
  state.get('futuraIllustrationPage', initialState);

/**
 * Other specific selectors
 */
const makeSelectLifeAssured = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('lifeAssured').toJS(),
  );

const makeSelectInitialLifeAssured = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('initialLifeAssured').toJS(),
  );

const makeSelectError = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('errorData'),
  );

const makeSelectConfig = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('futuraConfig').toJS(),
  );

const makeSelectProductConfig = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('productConfig').toJS(),
  );

const makeSelectFiaCharge = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('fiaData').toJS(),
  );

const makeSelectQuoteId = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('quoteId'),
  );

const makeSelectApplicationId = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('applicationId'),
  );

const makeSelectBiPdf = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('benefitIllustrationPdf'),
  );

const makeSelectStatus = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('status'),
  );

const makeSelectReferenceNumber = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('referenceNumber'),
  );

const makeSelectCloneStatus = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('successClone'),
  );

const makeSelectemailSentStatus = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('emailSent'),
  );

const makeSelectEmailErrorMessage = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('emailErrorMessage'),
  );

const makeSelectCompletedDialog = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('completedDialog'),
  );

const makeSelectIsCalculateQuotePage = () =>
  createSelector(selectFuturaIllustrationPageDomain, substate =>
    substate.get('isCalculateQuotePage'),
  );

export {
  selectFuturaIllustrationPageDomain,
  makeSelectLifeAssured,
  makeSelectInitialLifeAssured,
  makeSelectProductConfig,
  makeSelectConfig,
  makeSelectError,
  makeSelectQuoteId,
  makeSelectApplicationId,
  makeSelectBiPdf,
  makeSelectStatus,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
  makeSelectCloneStatus,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectCompletedDialog,
  makeSelectIsCalculateQuotePage,
};

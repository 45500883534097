/*
 *
 * OktaAuthHandler constants
 *
 */

export const DEFAULT_ACTION = 'app/OktaAuthHandler/DEFAULT_ACTION';

export const REFRESH_OKTA_TOKEN_ACTION =
  'app/OktaAuthHandler/REFRESH_OKTA_TOKEN_ACTION';

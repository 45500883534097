/*
 *
 * GcIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/GcIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/GcIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/GcIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const GET_GC_CONFIG_ACTION =
  'app/GcIllustrationPage/GET_GC_CONFIG_ACTION';
export const SET_GC_CONFIG_ACTION =
  'app/GcIllustrationPage/SET_GC_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION = 'app/GcIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/GcIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/GcIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/GcIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/GcIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/GcIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/GcIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/GcIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/GcIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/GcIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/GcIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/GcIllustrationPage/SET_PTV_VERSION_ACTION';
export const SET_ERROR_ACTION = 'app/GcIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/GcIllustrationPage/RESET_ACTION';
export const GET_FIA_ACTION = 'app/GcIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/GcIllustrationPage/SET_FIA_ACTION';
export const SET_BI_TABLE_VALUES_ACTION =
  'app/GcIllustrationPage/SET_BI_TABLE_VALUES_ACTION';
export const CLONE_QUOTE_ACTION = 'app/GcIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/GcIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/GcIllustrationPage/SET_BI_PDF_ACTION';

export const SET_EMAIL_SENT_ACTION =
  'app/GcIllustrationPage/SET_EMAIL_SENT_ACTION';
export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/GcIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';
export const SET_PRODUCT_CODE_ACTION =
  'app/GcIllustrationPage/SET_PRODUCT_CODE_ACTION';

export const SET_FORM_INPUT = 'app/GcIllustrationPage/SET_FORM_INPUT';

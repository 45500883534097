/*
 * OktaAuthCallback Messages
 *
 * This contains all the text for the OktaAuthCallback container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.MFOauth';

export default defineMessages({
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Confirm',
  },
  heading: {
    id: `${scope}.heading`,
    defaultMessage: 'Please Enter Zurich Online (ZIO) Pin',
  },
  pinFormate: {
    id: `${scope}.pinFormate`,
    defaultMessage:
      'Enter the 1st, 2nd and 4th digits of your Memorable number (PIN)',
  },
  zioPin: {
    id: `${scope}.zioPin`,
    defaultMessage: 'zioPin',
  },
  zioPinNote: {
    id: `${scope}.zioPinNote`,
    defaultMessage: 'Use the PIN you created for your Zurich online profile.',
  },
});

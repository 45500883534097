import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { findKey } from 'lodash/object';
import { WrappedFieldProps } from 'redux-form';
import { Grid, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';
import NumberField from 'components/NumberField';
import { fixLeadingZero } from 'helpers/math';
import { isEmpty,isNumber } from 'lodash';

const FormPremium = ({
  input: { onChange, value, onBlur, ...otherInputProps },
  meta: { invalid, error, touched, warning },
  id,
  min,
  max,
  diff,
  label,
  isPrimary,
  helperText,
  inputFormat,
  allowLeadingZero,
  onButtonActionClick,
  singleSplitPremium,
  ...custom
}) => {
  const theme = useTheme();

  if (value === ''){
    value = 0
  }

  let helperMsg = helperText;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  return (
    <Grid container direction="row" wrap="nowrap" alignItems="center">
      <IconButton
        id={`${id}.decreaseAmount`}
        onClick={() => {
          const actualVal = numeral(value).value();
          const mainDiff = numeral(diff).value();
          const changeVal = actualVal - mainDiff;
          if (changeVal > min) {
            onChange(changeVal);
            onButtonActionClick(changeVal);
          } else {
            onChange(min);
            onButtonActionClick(min);
          }
        }}
      >
        <RemoveCircleOutline
          fontSize="large"
          style={{
            color: isPrimary
              ? theme.palette.common.white
              : theme.palette.primary.dark,
          }}
        />
      </IconButton>

      <NumberField
        variant="outlined"
        label={label}
        value={value}
        onChange={newVal => onChange(newVal)}
        onBlur={() => {
          const fixedLeadingZero = fixLeadingZero(value);
          if (allowLeadingZero) {
            return onBlur(value);
          }
          return onBlur(fixedLeadingZero);
        }}
        error={!!warning || (touched && invalid)}
        helperText={helperMsg}
        format={inputFormat}
        style={{
          color: `${theme.palette.common.white}`,
          border: `1px solid ${theme.palette.common.white}`,
          backgroundColor: `${theme.palette.primary.main}`,
        }}
        {...custom}
        {...otherInputProps}
      />

      {/* <Slider
        min={min}
        max={max}
        value={value}
        onChange={(e, newVal) => onChange(newVal)}
        defaultValue={2}
        {...custom}
      /> */}

      <IconButton
        id={`${id}.increaseAmount`}
        onClick={() => {
          const actualVal = numeral(value).value();
          const mainDiff = numeral(diff).value();
          const changeVal = actualVal + mainDiff;
          if (changeVal < max) {
            onChange(changeVal);
            onButtonActionClick(changeVal);
          } else {
            onChange(max);
            onButtonActionClick(max);
          }
        }}
      >
        <AddCircleOutline
          fontSize="large"
          style={{
            color: singleSplitPremium
            ? value < max
                ? theme.palette.common.white
                : theme.palette.primary.dark
              : isPrimary
                ? theme.palette.common.white
                : theme.palette.primary.dark,
          }}
        />
      </IconButton>
    </Grid>
  );
};

FormPremium.propTypes = {
  ...WrappedFieldProps,
  id: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  diff: PropTypes.number,
  isPrimary: PropTypes.bool,
  helperText: PropTypes.node,
  onButtonActionClick: PropTypes.func,
};

FormPremium.defaultProps = {
  onButtonActionClick: () => {},
};

export default FormPremium;

/**
 *
 * ApplicationFormPage
 *
 */

import React from 'react';
import styled from 'styled-components';
import { withTheme } from '@material-ui/core/styles';

const MainBody = styled(({ theme, ...props }) => <div {...props} />)`
  width: 100%;
  padding: 0 3rem 170px !important;
`;

export default withTheme(MainBody);

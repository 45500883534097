import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import {
  Grid,
  Box,
  Radio,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import FormTextField from 'components/FormTextField';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';

function ExistingZurichPlan(props) {
  const intl = useIntl();

  const { fields, formValues, options } = props;
  const [policyNumber, setPolicyNumber] = useState(false);

  function handleChange(event) {
    const data = event.target.value;
    setPolicyNumber(data === 'true' ? true : false);
  }

  return (
    <React.Fragment>
      <Grid style={{ display: 'flex', flexDirection: 'row' }} container>
        <Grid item xs={8}>
          <Typography 
            variant="body1"
            style={{
              fontSize:'14px',
              fontWeight:'bold'
            }}
          >
            <FormattedMessage {...messages.isPolicyNumber} />
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Field
            name={`isPolicyNumber`}
            type="radio"
            component={FormRadioGroup}
            normalize={value => value === 'true'}
            onBlur={e => {
              e.preventDefault();
            }}
          >
            <FormControlLabel
              key={1}
              control={
                <CustomRadio
                  checked={policyNumber === true}
                  onClick={handleChange}
                />
              }
              value={true}
              label="Yes"
              labelPlacement="end"
            />
            <FormControlLabel
              key={2}
              control={
                <CustomRadio
                  checked={policyNumber === false}
                  onClick={handleChange}
                />
              }
              value={false}
              label="No"
              labelPlacement="end"
            />
          </Field>
        </Grid>
      </Grid>
      <Box mt={5}>
        <Grid item xs={8}>
          <Field
            name={`existingPolicyNumber`}
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.existingPolicyNumber} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
            disabled={!policyNumber}
          />
        </Grid>
      </Box>
    </React.Fragment>
  );
}

ExistingZurichPlan.propTypes = {
  fields: PropTypes.object,
  formValues: PropTypes.object,
  options: PropTypes.object,
};

export default ExistingZurichPlan;

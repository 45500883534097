import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Switch, Typography, Grid, Fade } from '@material-ui/core';
import { dimension } from 'styles/constants';
import styled from 'styled-components';
import CheckIcon from '@material-ui/icons/Check';

const Wrapper = styled.div`
  && {
    display: inline-flex;
    position: relative;
  }
`;

const TextOptionWrapper = styled(Grid)`
  && {
    position: absolute;
    top: -2px;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

const TypographyOption = styled(Typography)`
  && {
    text-transform: uppercase;
    top: 3px;
  }
`;

const styles = theme => ({
  root: {
    width: '73px',
    height: '40px',
    padding: '0px',
  },
  switchBase: {
    width: 'auto',
    transition: '.5s',
    transform: 'translateX(2px)',
    padding: '0',

    '&.Mui-disabled': {
      // transform: 'translateX(24px)',
      // color: theme.palette.common.white,
      '& + $track': {
        opacity: 0.12,
      },
    },

    '&$checked': {
      left: '14px',
      // transform: 'translateX(24px)',
      // color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: theme.palette.primary.dark,
        opacity: 1,
        border: '3px solid #bfbbaa',
      },
    },
  },
  thumb: {
    width: '39px',
    height: '38px',
    borderRadius: `${dimension.borderRadius.m}px`,
    color: '#ffffff',
    border: '1px solid #bfbbaa',
  },
  track: {
    width: '160px',
    height: '38px',
    left: '0',
    top: '24%',
    marginLeft: '0',
    borderRadius: `${dimension.borderRadius.m}px`,
    backgroundColor: '#414141',
    border: '1px solid #bfbbaa',
  },
  checked: {},
});

function OptSwitch(props) {
  const { labelPositive, labelNegative, ...switchProps } = props;
  if (switchProps.checked === '') {
    switchProps.checked = false;
  }
  return (
    <Wrapper>
      <Switch {...switchProps} />
      <TextOptionWrapper container wrap="nowrap" alignItems="center">
        <Grid item xs>
          <Fade in={switchProps.checked}>
            <TypographyOption
              display="block"
              variant="h4"
              component="span"
              align="center"
              color={!switchProps.checked ? 'primary' : 'textSecondary'}
            >
              <CheckIcon style={{ color: 'white' }} />
            </TypographyOption>
          </Fade>
        </Grid>
        <Grid item xs>
          <Fade in={!switchProps.checked}>
            <TypographyOption
              display="block"
              variant="body2"
              component="span"
              align="center"
              style={{ color: 'white', fontSize: '1.4rem', fontWeight: 700 }}
            >
              {labelNegative}
            </TypographyOption>
          </Fade>
        </Grid>
      </TextOptionWrapper>
    </Wrapper>
  );
}

OptSwitch.propTypes = {
  labelPositive: PropTypes.string,
  labelNegative: PropTypes.string,
  appStyles: PropTypes.object,
};

export default withStyles(styles)(OptSwitch);

/*
 *
 * FuturaIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/FuturaIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const CHANGE_COVERAGE_ACTION =
  'app/FuturaIllustrationPage/CHANGE_COVERAGE_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/FuturaIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const GET_FUTURA_CONFIG_ACTION =
  'app/FuturaIllustrationPage/GET_FUTURA_CONFIG_ACTION';
export const SET_FUTURA_CONFIG_ACTION =
  'app/FuturaIllustrationPage/SET_FUTURA_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION =
  'app/FuturaIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/FuturaIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/FuturaIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/FuturaIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/FuturaIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/FuturaIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/FuturaIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/FuturaIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/FuturaIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/FuturaIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/FuturaIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_PREMIUM_TERM_ACTION =
  'app/FuturaIllustrationPage/SET_PREMIUM_TERM_ACTION';
export const SET_ERROR_ACTION = 'app/FuturaIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/FuturaIllustrationPage/RESET_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/FuturaIllustrationPage/SET_PTV_VERSION_ACTION';
export const GET_FIA_ACTION = 'app/FuturaIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/FuturaIllustrationPage/SET_FIA_ACTION';
export const SET_MINPREMIUM_ACTION =
  'app/FuturaIllustrationPage/SET_MINPREMIUM_ACTION';
export const CLONE_QUOTE_ACTION =
  'app/FuturaIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/FuturaIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/FuturaIllustrationPage/SET_BI_PDF_ACTION';
export const SET_EMAIL_SENT_ACTION =
  'app/FuturaIllustrationPage/SET_EMAIL_SENT_ACTION';
export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/FuturaIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';
export const SET_COMPLETED_DIALOG =
  'app/FuturaIllustrationPage/SET_COMPLETED_DIALOG';
export const SET_IS_CALCULATE_QUOTE_PAGE_ACTION =
  'app/FuturaIllustrationPage/SET_IS_CALCULATE_QUOTE_PAGE_ACTION';
  export const SET_PRODUCT_CODE_ACTION =
  'app/FuturaIllustrationPage/SET_PRODUCT_CODE_ACTION';

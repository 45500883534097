import { validateOfferCodeAction } from 'containers/App/actions';

function asyncValidate(values, dispatch, props, fieldName) {
  if (fieldName !== undefined) {
    if (values.get('premium') !== '' && values.get('currency') !== 'Select') {
      if (values.get('offerCode') !== '' && values.get('offerCode') !== undefined) {
        return new Promise((resolve, reject) => {
          dispatch(
            validateOfferCodeAction({
              offerCode: values.get('offerCode'),
              productCode: props.product.PTVCode ? props.product.PTVCode : props?.productConfig?.productCode,
              productVersion: props.product.PTVVersion ? props.product.PTVCode : props?.productConfig?.productVersion,
              currency: values.get('currency'),
              premium: values.get('premium'),
              premiumType: 'SinglePremium',
              resolve,
              reject,
            }),
          );
        });
      }
    }

    return new Promise(resolve => {
      dispatch(
        validateOfferCodeAction({
          offerCode: '',
          resolve,
        }),
      );
    });
  }

  if (props?.offerCodeData?.errorMessage) {
    return new Promise((resolve, reject) => {
      reject({
        offerCode: props?.offerCodeData?.errorMessage,
      });
    });
  } else {
    return new Promise(resolve => {
      resolve();
    });
  }
}

export default asyncValidate;

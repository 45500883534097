import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  Select,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const ValidationOutlinedInput = styled(
  /* eslint-disable react/prop-types */
  React.forwardRef(({ theme, success, ...props }, ref) => (
    <OutlinedInput ref={ref} {...props} />
  )),
)`
  && {
    fieldset {
      ${props =>
        props.success
          ? `
        border-color: ${props.theme.palette.success.main};
      `
          : null}
    }
  }
`;

const TextField = React.forwardRef(
  (
    {
      label,
      labelPlaceholder,
      helperText,
      fullWidth,
      margin,
      error,
      success,
      InputProps,
      InputLabelProps,
      SelectProps,
      select,
      shrink,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    return (
      <FormControl fullWidth={fullWidth} margin={margin}>
        {(!!label || labelPlaceholder) && (
          <InputLabel
            disableAnimation
            shrink={shrink || true}
            {...InputLabelProps}
          >
            {label}
          </InputLabel>
        )}
        {select ? (
          <Select
            ref={ref}
            IconComponent={({ className }) => {
              return (
                <KeyboardArrowDownIcon
                  style={{ color: 'rgba(0, 0, 0, 0.26)' }}
                  className={className}
                />
              );
            }}
            fullWidth={fullWidth}
            margin={margin === 'normal' ? 'none' : margin}
            error={error}
            {...SelectProps}
            {...props}
          />
        ) : (
          <ValidationOutlinedInput
            ref={ref}
            theme={theme}
            fullWidth={fullWidth}
            success={success}
            margin={margin === 'normal' ? 'none' : margin}
            error={error}
            placeholder={placeholder}
            {...InputProps}
            {...props}
          />
        )}
        {error && <FormHelperText error>{helperText}</FormHelperText>}
        {success && helperText && (
          <FormHelperText style={{ color: theme.palette.success.main }}>
            {helperText}
          </FormHelperText>
        )}
      </FormControl>
    );
  },
);

TextField.propTypes = {
  label: PropTypes.node,
  labelPlaceholder: PropTypes.bool,
  helperText: PropTypes.node,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  error: PropTypes.bool,
  InputProps: PropTypes.object,
  InputLabelProps: PropTypes.object,
  SelectProps: PropTypes.object,
  select: PropTypes.bool,
  success: PropTypes.bool,
  shrink: PropTypes.bool,
  placeholder: PropTypes.any,
};

export default TextField;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form/immutable';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import messages from '../../messages';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Grid,
  Typography,
  Button,
  Dialog,
  Divider,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import { InfoRounded, HighlightOff } from '@material-ui/icons';

import FormTextField from 'components/FormTextField';

import validate from './validateExternal.js';

const DialogWrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 540px;
    padding: ${props => props.theme.spacing(2)}px;
    border-radius: 0;
    border: 2px solid #4066b3;
  }
`;

const ReferenceNumberDialog = props => {
  const theme = useTheme();
  const history = useHistory();
  const intl = useIntl();

  const { open, setOpen, handleSubmit, classes } = props;

  useEffect(() => {
    // return () => {
    //   handleSubmit();
    // };
  }, []);
  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogWrapper container theme={theme}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item xs={1}>
            <InfoRounded
              fontSize="large"
              style={{ color: theme.palette.primary.main }}
            />
          </Grid>
          <Grid item xs={10}>
            <DialogTitle style={{ paddingLeft: 0 }}>
              <Typography color="primary" style={{ fontSize: 28 }}>
                {<FormattedMessage {...messages.externalReferenceNumber} />}
              </Typography>
            </DialogTitle>
          </Grid>

          <Grid item xs={1}>
            <IconButton onClick={() => setOpen(false)}>
              <HighlightOff
                fontSize="large"
                style={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: theme.spacing(2) }} />

        <Grid
          item
          style={{
            marginTop: theme.spacing(2),
          }}
        >
          <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
            <Field
              shrink
              name="externalReferenceNumber"
              fullWidth
              component={FormTextField}
              label={intl.formatMessage(messages.addEditExternalNumber)}
              type="text"
              color="inherit"
              margin="normal"
              variant="standard"
            />
            <div
              style={{ marginTop: 100 }}
              className={classes.flexJustifyCenter}
            >
              <Button
                variant="outlined"
                margin="normal"
                className={clsx(classes.button, classes.buttonWhite)}
                style={{ marginRight: '2.5rem' }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <FormattedMessage {...messages.cancel} />
              </Button>

              <Button
                variant="contained"
                color="primary"
                margin="normal"
                className={clsx(classes.button, classes.buttonPrimary)}
                onClick={handleSubmit}
              >
                <FormattedMessage {...messages.save} />
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogWrapper>
    </Dialog>
  );
};

ReferenceNumberDialog.propTypes = {};

const mapStateToProps = (state, props) => {
  return createStructuredSelector({});
};

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  form: 'externalNumberForm',
  validate,
  onSubmit: submit,
  enableReinitialize: true,
});

export default compose(withConnect, withForm)(ReferenceNumberDialog);

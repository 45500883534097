import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form/immutable';
import { reset } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormattedMessage } from 'react-intl';

//components
import renderType from '../QuestionTypes/RenderQuestions';

//actions
import {
  callSectionQuestionsAction,
  answerQuestionAction,
  searchAnswerAction,
  setSectionAction,
} from 'containers/ApplicationFormPage/actions';

//selectors
import {
  makeSelectSectionAnswers,
  makeSelectSectionQuestions,
  makeSelectQuestions,
  makeSelectAnswers,
} from 'containers/ApplicationFormPage/selectors';

//validate
import validate from './validate';
import {
  makeSelectLIParties,
  makeSelectUnderwritingSections,
} from 'containers/ApplicationPage/selectors';
import { convertIfImmutable } from 'helpers/lang';
import { find } from 'lodash/collection';
import { findIndex } from 'lodash/array';
import { SlowMotionVideo } from '@material-ui/icons';
import NoticeBox from 'components/NoticeBox';
import messages from 'containers/ApplicationFormPage/messages';

const useStyle = makeStyles(theme => ({
  grid: { position: 'relative' },
  sectionBody: {
    width: '660px',
    padding: `0 ${theme.spacing(2)}`,
  },
  circular: {
    height: '8rem !important',
    width: '8rem !important',
    position: `absolute`,
    left: '0',
    right: '0',
    top: '40rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  loaderBody: {
    width: '100%',
    height: '100%',
  },
}));

function SectionForm(props) {
  const classes = useStyle();

  const {
    handleSubmit,
    getSectionQuestion,
    answerQuestion,
    searchAnswer,
    // sectionQuestions,
    sectionNumber,
    section,
    onSubmit,
    setSectionAnswer,
    sessionId,
    eTag,
    partyId,
    order,
    LIParties,
    getInterviewSessionId,
    match,
    questions,
    UWSections,
    canSubmit,
    canGoBack,
    subPageStepper,
  } = props;

  const applicationId = match.params.applicationId;

  //load UW
  useEffect(() => {
    if (convertIfImmutable(UWSections)[0].text === 'Loading') {
      getInterviewSessionId({ applicationId });
    }
  }, []);

  useEffect(() => {
    if (sectionNumber !== null) {
      getSectionQuestion({ section: sectionNumber, sessionId, order });
    }
  }, [eTag, sectionNumber, section]); // if anything goes wrong. bring back eTag inside the brackets
  // eTag will reload the form and call the getSectionQuestion again but will not make the form slow.
  useEffect(
    () => () => {
      canSubmit(false);
    },
    [],
  );

  const checkGoback = () => {
    if (
      convertIfImmutable(UWSections)[0].text === 'Loading' ||
      subPageStepper === 9
    ) {
      return true;
    }
    return false;
  };

  const checkSubmit = () => {
    let flag = false;
    if (!!questions[section]) {
      if (convertIfImmutable(LIParties).length === 0) {
        flag = true;
      }
      if (
        questions[section].length > 0 &&
        convertIfImmutable(LIParties).length > 0
      ) {
        return !questions[section].every(item => 'answer' in item);
      }
    }
    return flag;
  };

  useEffect(() => {
    canSubmit(checkSubmit());
    canGoBack(checkGoback());
  }, [questions[section]]);

  const findParentQuestion = (flatQs, parentId) => {
    const directParent = find(flatQs, q => q.id === parentId);
    if (directParent) {
      return {
        parentId: directParent.parentQuestionId || directParent.id,
        root: !directParent.parentQuestionId,
      };
    }
    return null;
  };

  if (questions[section] && questions[section].length) {
    // track the IDs of child questions to determine if it would be skipped or not in the main loop
    const skippedChildQs = [];
    // loop thru Qs, if there's a parent ID, push it as the childQs of the root parent
    let renderedQuestions = [...questions[section]];
    questions[section].forEach(q => {
      if (q.parentQuestionId) {
        let rootParentId = null;
        let rootFound = false;
        let currentParentId = q.parentQuestionId;
        while (!rootFound) {
          const checkQResult = findParentQuestion(
            questions[section],
            currentParentId,
          );
          // rootParentId = findParentQuestion(
          //   questions[section],
          //   currentParentId,
          // );
          if (checkQResult?.root) {
            rootFound = true;
            rootParentId = checkQResult.parentId;
          } else if (checkQResult) {
            rootFound = false;
            currentParentId = checkQResult.parentId;
          } else {
            // break the loop if no parent Q found
            rootFound = true;
          }
        }

        // if root parent ID is found, update the Q so that it has childQs property
        if (rootParentId) {
          // check if the root parent is SINGLE_CHOICE first
          const rootParentQ = find(
            questions[section],
            q => q.id === rootParentId,
          );
          if (
            rootParentQ.type === 'SINGLE_CHOICE' &&
            rootParentQ.constraints?.choices.length === 2
          ) {
            // then fill in the childQs property
            const rootParentQIndex = findIndex(
              questions[section],
              q => q.id === rootParentId,
            );
            // if there's still no childQs property, create it as an array
            if (!renderedQuestions[rootParentQIndex].childQs) {
              renderedQuestions[rootParentQIndex].childQs = [];
            }
            // check if there's any existing child Q with same ID
            // if not, then push it in
            if (renderedQuestions[rootParentQIndex].childQs.indexOf(q.id) < 0) {
              renderedQuestions[rootParentQIndex].childQs.push(q.id);
            }
            // push the child Q id to blacklist it in main rendering loop
            if (skippedChildQs.indexOf(q.id) < 0) {
              skippedChildQs.push(q.id);
            }
          }
        }
      }
    });

    return questions[section].length > 0 &&
      convertIfImmutable(LIParties).length > 0 ? (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.sectionBody}>
          <Grid container spacing={3}>
            {sectionNumber === 0 && (
              <Grid item xs={12}>
                <NoticeBox style={{ marginTop: 20 }} variant="info">
                  <Typography variant="body2" color="inherit">
                    <FormattedMessage {...messages.UWInfo} />
                  </Typography>
                </NoticeBox>
              </Grid>
            )}
            {renderedQuestions.map(question => {
              if (skippedChildQs.indexOf(question.id) >= 0) {
                return null;
              }
              return renderType(
                renderedQuestions,
                question,
                answerQuestion,
                sectionNumber,
                searchAnswer,
                section,
                setSectionAnswer,
                sessionId,
                eTag,
                partyId,
                question?.childQs,
                order,
              );
            })}
          </Grid>
        </div>
      </form>
    ) : (
      // </form>
      <div className={classes.loaderBody}>
        <CircularProgress className={classes.circular} />
      </div>
    );
  } else {
    return (
      <div className={classes.loaderBody}>
        <CircularProgress className={classes.circular} />
      </div>
    );
  }
}

SectionForm.propTypes = {
  ...InjectedFormProps,
  // sectionQuestions: PropTypes.array,
  setSectionAnswer: PropTypes.func,
  getSectionQuestion: PropTypes.func,
  searchAnswer: PropTypes.func,
  answerQuestion: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  const { section } = props;
  return createStructuredSelector({
    questions: makeSelectQuestions(),
    // initialValues: makeSelectSectionAnswers(section),
    // sectionQuestions: makeSelectSectionQuestions(section),
    LIParties: makeSelectLIParties(),
    UWSections: makeSelectUnderwritingSections(),
  });
};

function mapDispatchToProps(dispatch) {
  return {
    setSectionAnswer: data => dispatch(setSectionAction(data)),
    getSectionQuestion: data => dispatch(callSectionQuestionsAction(data)),
    searchAnswer: data => dispatch(searchAnswerAction(data)),
    answerQuestion: data => dispatch(answerQuestionAction(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  validate,
  // onSubmit: validate,
  enableReinitialize: true,
});

export default compose(withConnect, withForm)(SectionForm);

import React from 'react';
import { Grid, Typography, Collapse, Button, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { WrappedFieldArrayProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import FormSelect from 'components/FormSelect';
import numeral from 'numeral';
import messages from '../../messages';
import FormCheck from 'components/FormCheck';
import { isEmpty } from 'lodash';

const Benefits = ({
  fields,
  insured,
  coverages,
  editData,
  change,
  changeEditData,
}) => {
  const theme = useTheme();
  var mileOptions = [];
  var percentageOptions = [];
  var minMile = 0;
  var minPercentage = 0;
  var monthOptions = [];
  var minMonth = 0;

  while (minMile <= 20) {
    mileOptions.push({
      title: numeral(minMile).format('0.00'),
      value: minMile,
    });
    minMile = minMile + 0.25;
  }
  while (minMonth <= 120) {
    monthOptions.push({ title: minMonth, value: minMonth });
    minMonth = minMonth + 6;
  }
  while (minPercentage <= 400) {
    percentageOptions.push({ title: minPercentage, value: minPercentage });
    minPercentage = minPercentage + 25;
  }

  return (
    <Box>
      {fields.map((benefit, index) => {
        const isOpen =
          editData.editIndex === index && editData.editInsured === insured;

        let isShowing =
          insured === 1
            ? coverages[index].selected &&
              !isEmpty(coverages[index].valueInsuredOne)
            : coverages[index].selected &&
              !isEmpty(coverages[index].valueInsuredTwo);

        if (coverages[index].id === 'WOP') {
          if (insured === 1) isShowing = coverages[index].valueInsuredOne;

          if (insured === 2) isShowing = coverages[index].valueInsuredTwo;
        }

        if (coverages[index].id === 'ESC') {
          isShowing = false;
        }

        return isShowing ? (
          <Box
            // pr={1}
            key={index}
            py={2}
            id={benefit.id}
            borderBottom={`1px solid ${theme.palette.divider}`}
          >
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography variant="h3" style={{ fontWeight: '900' }}>
                {coverages[index].title}
              </Typography>

              {isOpen ? null : (
                <Typography
                  // variant="text"
                  color="primary"
                  id={`${benefit.id}.add`}
                  onClick={() => changeEditData(index)}
                  style={{ fontWeight: '900', cursor: 'pointer' }}
                >
                  {<FormattedMessage {...globalMessages.add} />}
                </Typography>
              )}
            </Grid>

            <Collapse in={isOpen} style={{ paddingRight: 8 }}>
              <Grid
                container
                direction="row"
                justify="space-between"
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={2} style={{ fontWeight: 'bold', fontSize: 14 }}>
                  <FormattedMessage {...messages.temporary} />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    id={`${benefit.id}.select`}
                    name={
                      insured === 1
                        ? `${benefit}.brSelectedTempValueOne`
                        : `${benefit}.brSelectedTempValueTwo`
                    }
                    fullWidth
                    component={FormCheck}
                    label={<FormattedMessage {...messages.perMilleAndMonths} />}
                    type="text"
                    color="inherit"
                    margin="normal"
                    variant="standard"
                    onChange={(e, newVal) => {
                      if (insured === 1) {
                        if (!newVal) {
                          change(`${benefit}.perTempMileOne`, 0);
                          change(`${benefit}.perTempMonthOne`, 0);
                        }
                      } else {
                        if (!newVal) {
                          change(`${benefit}.perTempMileTwo`, 0);
                          change(`${benefit}.perTempMonthTwo`, 0);
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    name={
                      insured === 1
                        ? `${benefit}.perTempMileOne`
                        : `${benefit}.perTempMileTwo`
                    }
                    component={FormSelect}
                    options={mileOptions}
                    disabled={
                      insured === 1
                        ? coverages[index].brSelectedTempValueOne != 1
                        : coverages[index].brSelectedTempValueTwo != 1
                    }
                    id={`${benefit.id}.perMille`}
                    type="text"
                    fullWidth
                    style={{ margin: theme.spacing(1) }}
                  />
                </Grid>

                <Grid item xs={2}>
                  <Field
                    name={
                      insured === 1
                        ? `${benefit}.perTempMonthOne`
                        : `${benefit}.perTempMonthTwo`
                    }
                    component={FormSelect}
                    options={monthOptions}
                    disabled={
                      insured === 1
                        ? coverages[index].brSelectedTempValueOne != 1
                        : coverages[index].brSelectedTempValueTwo != 1
                    }
                    id={`${benefit.id}.perMille`}
                    type="text"
                    fullWidth
                    style={{ margin: theme.spacing(1) }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justify="space-between"
                spacing={1}
              >
                <Grid
                  style={{
                    paddingTop: theme.spacing(2),
                    fontWeight: 'bold',
                    fontSize: 14,
                  }}
                  item
                  xs={2}
                >
                  <FormattedMessage {...messages.permanent} />
                </Grid>
                <Grid item xs>
                  <Grid container direction="column">
                    <Field
                      style={{ padding: '12px 0px' }}
                      id={`${benefit.id}.select`}
                      name={
                        insured === 1
                          ? `${benefit}.brSelectedInsuredOneValue`
                          : `${benefit}.brSelectedInsuredTwoValue`
                      }
                      fullWidth
                      component={FormCheck}
                      label={<FormattedMessage {...globalMessages.perMile} />}
                      type="text"
                      color="inherit"
                      margin="normal"
                      variant="standard"
                      onChange={(e, newVal) => {
                        if (insured === 1) {
                          if (!newVal)
                            return change(`${benefit}.perMileOne`, 0);
                        } else {
                          if (!newVal)
                            return change(`${benefit}.perMileTwo`, 0);
                        }
                      }}
                    />
                    <Field
                      // id={`${benefit.id}.select`}
                      // shrink
                      style={{ padding: '16px 0px' }}
                      name={
                        insured === 1
                          ? `${benefit}.brSelectedPercentValueOne`
                          : `${benefit}.brSelectedPercentValueTwo`
                      }
                      fullWidth
                      component={FormCheck}
                      label={
                        <FormattedMessage {...globalMessages.perPercentage} />
                      }
                      type="text"
                      color="inherit"
                      margin="normal"
                      variant="standard"
                      onChange={(e, newVal) => {
                        if (insured === 1) {
                          if (!newVal)
                            return change(`${benefit}.perPercentageOne`, 0);
                        } else {
                          if (newVal)
                            return change(`${benefit}.perPercentageTwo`, 0);
                        }
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={2}>
                  <Field
                    name={
                      insured === 1
                        ? `${benefit}.perMileOne`
                        : `${benefit}.perMileTwo`
                    }
                    component={FormSelect}
                    options={mileOptions}
                    disabled={
                      insured === 1
                        ? coverages[index].brSelectedInsuredOneValue != 1
                        : coverages[index].brSelectedInsuredTwoValue != 1
                    }
                    id={`${benefit.id}.perMille`}
                    type="text"
                    fullWidth
                    style={{ margin: theme.spacing(1) }}
                  />

                  <Field
                    name={
                      insured === 1
                        ? `${benefit}.perPercentageOne`
                        : `${benefit}.perPercentageTwo`
                    }
                    component={FormSelect}
                    options={percentageOptions}
                    disabled={
                      insured === 1
                        ? !coverages[index].brSelectedPercentValueOne
                        : !coverages[index].brSelectedPercentValueTwo
                    }
                    id={`${benefit.id}.perPercentage`}
                    type="text"
                    fullWidth
                    style={{ margin: theme.spacing(1) }}
                  />
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        ) : null;
      })}
    </Box>
  );
};

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  insured: PropTypes.number,
  coverages: PropTypes.array,
  editData: PropTypes.object,
  changeEditData: PropTypes.func,
  change: PropTypes.func,
};

export default Benefits;

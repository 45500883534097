import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Dialog as BaseDialog,
} from '@material-ui/core';
import React, { useEffect, useState } from "react";
import props from 'prop-types';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { compose } from 'redux';
import { Field , reduxForm } from 'redux-form/immutable';
import FormTextField from 'components/FormTextField';
import globalMessages from 'containers/App/messages';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import { map } from 'lodash/collection';
import { mapFinancialInstituteOptions } from 'containers/ApplicationFormPage/helpers';
import { InjectedFormProps, change } from 'redux-form';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import IconEditSrc from 'images/open-external.svg';
import OpsTesting from './OpsTesting';
import Search from 'icons/Search';
import styled from 'styled-components';
import messages from 'containers/OpsDashboardPage/messages';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckedIcon from 'images/checkbox-blue-tick.svg';
import EmptyIcon from 'images/checkbox-blue-empty.svg';
import { makeSelectApplicationDetails } from 'containers/ApplicationPage/selectors';
import TextField from 'components/TextField';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import { filterSearchApplication } from 'helpers/quote';
import { convertIfImmutable } from 'helpers/lang';
import {
  getApplicationListAction,
  setApplicationDetailsAction,
} from 'containers/OpsDashboardPage/actions';
import { TableApplication } from './TableApplication';
import { createIDVSessionAction } from 'containers/ApplicationFormPage/actions';
import { makeSelectFile } from 'containers/App/selectors';
import {
  makeSelectUWStatus,
  makeSelectSummaryLink,
  makeSelectUWLink,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
} from 'containers/ApplicationFormPage/selectors';

import {
  makeSelectQuoteType,
  makeSelectAgentDetails,
} from 'containers/App/selectors';
import {
  FormControlLabel as BaseFormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { Switch, Route, Routes, BrowserRouter, useHistory } from 'react-router-dom';
import IllustrationPage from 'containers/IllustrationPage';
const useStyles = makeStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  table: {
    minWidth: 700,
  },
  widthSearch: {
    padding: 0,
  },
  lifeInsured: {
    fontWeight: 'bold',
  },
  titleHeader: {
    marginTop: 8,
    fontWeight: 300,
    fontSize: 28,
  },
  btnUpLoad: {
    color: '#4066B3',
    float: 'left',
    cursor: 'pointer',
    marginTop: '0.3rem',
    marginRight: '2rem',
  },
  btnDelete: {
    color: '#b6b8b4',
    cursor: 'pointer',
  },
  customTable: {
    overflowY: 'scroll',
  },
  inProcessText: {
    color: '#00C3B4',
    textTransform: 'capitalize',
  },
  pendingText: {
    color: '#FF8F4A',
    textTransform: 'capitalize',
  },
  statusListText: {
    color: '#2167AE',
    textTransform: 'capitalize',
  },
  actionableText: {
    color: '#E75252',
    textTransform: 'capitalize',
  },
  submittedText: {
    color: '#043594',
    textTransform: 'capitalize',
  },
  customBox: {
    marginTop: 15,
  },
  btnSearch: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));
const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#4066B3',
    fontSize: 16,
    borderBottom: '1px solid #ffffff',
  },
  body: {
    fontSize: 14,
    borderBottom: '1px solid #ffffff',
    maxWidth: 140,
  },
}))(TableCell);

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 ${props => props.theme.spacing(2)}px
      ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  }
`;

export function OpsDashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [addOpsDetails, setAddOpsDetails] = React.useState([])
  const [search,setSearch] = useState('')
  const [pending,setPending] = useState(true)
  const [approved,setApproved] = useState(false)
  const [rejected,setRejected] = useState(false)
  const [all,setAll] = useState(false)
  const {
    onSearch,
    listApplication,
    quoteType,
    agentDetails,
    setApplicationDetails,
    updateApplicationDetails,
    getSearchIllustrations,
    setValueSearch,
    sendEmail

  } = props;  

  const searchedApplication = filterSearchApplication(
    convertIfImmutable(listApplication),
    quoteType,
    agentDetails.products,
  );

  function showOpsSummaryDetails() {
    <Route exact
    path="/illustrations"
    render={() => <OpsTesting />}
    />}

  const [error, setError] = useState('');

  const handleSearch = value => {
    setSearch(value);
  };

  function checkStatus(all,pending,approved,rejceted){
    if (pending){
      return 'Pending'
    } else if (approved){
      return 'Approved'
    } else if (rejected){
      return 'Rejected'
    } else if (all){
      return 'All'
    }
  }

  
  return (
      <form >
        <Wrapper theme={theme}>
        <Grid className={classes.applicationHeader}>
            <Box mt={12} />
            <Grid container>
              <Grid item xs={8}>
                <Typography variant="h1" className={classes.titleHeader}>
                  <FormattedMessage {...messages.approveEmail} />
                </Typography>
              </Grid>
              <Grid item xs={4} md={3} lg={4} className={classes.btnSearch} >
                <TextField
                  className={classes.widthSearch}
                  // justify='flex-end'
                  //value={valueSearch}
                  onChange={event => {
                    handleSearch(event.target.value);
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" >
                        <IconButton>
                          <Search className={classes.icon} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid className={classes.applicationHeader}>
            <Grid container >
              <Box m={1} pr={1}>
                <Typography variant="h2" style={{ color: '#2167AE'}} >
                  Show Status
                </Typography>
                </Box >
                <Grid item style={{ width: 160}}>
                <Field
            shrink
            name={`statusList`}
            fullWidth
            component={FormSelect}
            options={[
              { value: 'All', title: 'All'},
              { value: 'Pending', title: 'Pending'},
              { value: 'Approved', title: 'Approved' },
              { value: 'Rejected', title: 'Rejected' },
            ]}
            onChange={(e) => { 
              if (e.target.value === "All") {
                setAll(true)
                setPending(false)
                setApproved(false)
                setRejected(false)
              } else if (e.target.value === "Pending") {
                setPending(true)
                setApproved(false)
                setRejected(false)
                setAll(false)
              } else if (e.target.value === "Approved") {
                setPending(false)
                setApproved(true)
                setRejected(false)
                setAll(false)
              } else if (e.target.value === "Rejected") {
                setPending(false)
                setApproved(false)
                setRejected(true)
                setAll(false)
              }
            }}
            />
            </Grid>
            </Grid>
            </Grid>
            <TableApplication
            dataApplication={searchedApplication}
            setApplicationDetails={setApplicationDetails}
            status = {checkStatus(all,pending,approved,rejected)}
            search = {search}
            />     
        </Wrapper>
      </form>
  );
} 


OpsDashboard.propTypes = {
  onSearch: PropTypes.func,
  listApplication: PropTypes.object,
  quoteType: PropTypes.string,
  agentDetails: PropTypes.object,
  setValueSearch: PropTypes.func,
  setApplicationDetails: PropTypes.func,
  updateApplicationDetails: PropTypes.func,
  getSearchIllustrations: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  quoteType: makeSelectQuoteType(),
  agentDetails: makeSelectAgentDetails(),
  emailSent: makeSelectemailSentStatus(),
  emailErrorMessage: makeSelectEmailErrorMessage()
});

function mapDispatchToProps(dispatch) {
  return {
    getApplicationList: () => dispatch(getApplicationListAction()),
    setApplicationDetails: value =>
      dispatch(setApplicationDetailsAction(value)),
      
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)(OpsDashboard);

const composing = compose(withConnect)

const redux = reduxForm({
  form: 'selectingStatus'  ,
})(withConnect);

export default compose(redux)

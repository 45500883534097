/*
 *
 * ApplicationFormPage constants
 *
 */

export const DEFAULT_ACTION = 'app/ApplicationFormPage/DEFAULT_ACTION';

// SECTION

export const SET_SECTION_NUMBER = 'app/ApplicationFormPage/SET_SECTION_NUMBER';
export const CALL_SECTION_QUESTIONS_ACTION =
  'app/ApplicationFormPage/CALL_SECTION_QUESTIONS_ACTION';
export const SET_SECTION_QUESTIONS_ACTION =
  'app/ApplicationFormPage/SET_SECTION_QUESTIONS_ACTION';
export const SET_SECTION_ACTION = 'app/ApplicationFormPage/SET_SECTION_ACTION';
export const SET_SECTION_QUESTIONS_PROPERTY =
  'app/ApplicationFormPage/SET_SECTION_QUESTIONS_PROPERTY';

// ANSWER QUESTION
export const ANSWER_QUESTION_ACTION =
  'app/ApplicationFormPage/ANSWER_QUESTION_ACTION';

// SEARCH ANSWER
export const SEARCH_ANSWER_ACTION =
  'app/ApplicationFormPage/SEARCH_ANSWER_ACTION';

export const SUBMIT_UNDERWRITING_ACTION =
  'app/ApplicationFormPage/SUBMIT_UNDERWRITING_ACTION';

export const UPLOAD_FILE_DOCUMENTS_ACTION =
  'app/ApplicationFormPage/UPLOAD_FILE_DOCUMENTS_ACTION';
export const ADD_DOCUMENT_ACTION =
  'app/ApplicationFormPage/ADD_DOCUMENT_ACTION';
export const DELETE_FILE_DOCUMENTS_ACTION =
  'app/ApplicationFormPage/DELETE_FILE_DOCUMENTS_ACTION';

export const CREATE_IDV_SESSION = 'app/ApplicationFormPage/CREATE_IDV_SESSION';
export const SET_PAYMENT_TYPE = 'app/ApplicationFormPage/SET_PAYMENT_TYPE';
export const SET_PAYMENT_DETAIL = 'app/ApplicationFormPage/SET_PAYMENT_DETAIL';
export const SUBMIT_CREDIT_CARD_ACTION =
  'app/ApplicationFormPage/SUBMIT_CREDIT_CARD_ACTION';
export const SUBMIT_FOR_ADMIN_REVIEW_ACTION =
  'app/ApplicationFormPage/SUBMIT_FOR_ADMIN_REVIEW_ACTION'; // for holding the application for review
  SUBMIT_FOR_ADMIN_REVIEW_ACTION
export const CONTINUE_PAYMENT_CALLBACK_ACTION =
  'app/ApplicationFormPage/CONTINUE_PAYMENT_CALLBACK_ACTION';
export const SET_PAYMENT_DATA_ACTION =
  'app/ApplicationFormPage/SET_PAYMENT_DATA_ACTION';
export const SUBMIT_PAYMENT_ACTION =
  'app/ApplicationFormPage/SUBMIT_PAYMENT_ACTION';
export const ADD_DOCTOR_ACTION = 'app/ApplicationFormPage/ADD_DOCTOR_ACTION';
export const GET_DOCTORS_ACTION = 'app/ApplicationFormPage/GET_DOCTORS_ACTION';
export const SET_DOCTOR_LIST_ACTION =
  'app/ApplicationFormPage/SET_DOCTOR_LIST_ACTION';

export const CURRENT_POLICY_SUBSTEPPERS = 7;
export const ADJUSTED_POLICY_SUBSTEPPERS = CURRENT_POLICY_SUBSTEPPERS + 2;

export const GET_CONVERT_CURRENCY_ACTION =
  'app/ApplicationFormPage/GET_CONVERT_CURRENCY_ACTION';
export const SET_CONVERT_CURRENCY_ACTION =
  'app/ApplicationFormPage/SET_CONVERT_CURRENCY_ACTION';

export const GET_CHANGE_CURRENCY_ACTION =
  'app/ApplicationFormPage/GET_CHANGE_CURRENCY_ACTION';
export const SET_CHANGE_CURRENCY_ACTION =
  'app/ApplicationFormPage/SET_CHANGE_CURRENCY_ACTION';

export const GET_VALID_FUNDS_ACTION =
  'app/ApplicationFormPage/GET_VALID_FUNDS_ACTION';
export const SET_VALID_FUNDS_ACTION =
  'app/ApplicationFormPage/SET_VALID_FUNDS_ACTION';

export const SET_UW_STATUS = 'app/ApplicationFormPage/SET_UW_STATUS';
export const REQUEST_SIGNS_ACTION =
  'app/ApplicationFormPage/REQUEST_SIGNS_ACTION';
export const SET_REQUEST_SIGNS_ACTION =
  'app/ApplicationFormPage/SET_REQUEST_SIGNS_ACTION';
export const GET_APPLICATION_ENVELOP_ACTION =
  'app/ApplicationFormPage/GET_APPLICATION_ENVELOP_ACTION';
export const REFRESH_APPLICATION_ENVELOP_ACTION =
  'app/ApplicationFormPage/REFRESH_APPLICATION_ENVELOP_ACTION';
export const SET_DOCUMENT_STATUS_ACTION =
  'app/ApplicationFormPage/SET_DOCUMENT_STATUS_ACTION';

export const SET_QUESTIONS_EMPTY =
  'app/ApplicationFormPage/SET_QUESTIONS_EMPTY';

export const SEND_PDF_ACTION = 'app/ApplicationFormPage/SEND_PDF_ACTION';

export const SET_ANSWERS_EMPTY = 'app/ApplicationFormPage/SET_ANSWERS_EMPTY';

export const SET_CONSENT_LINK_ACTION =
  'app/ApplicationFormPage/SET_CONSENT_LINK_ACTION';

export const SET_FILE_UW_ACTION = 'app/ApplicationFormPage/SET_FILE_UW_ACTION';
export const SET_COUNT_IDV_SUBMIT =
  'app/ApplicationFormPage/SET_COUNT_IDV_SUBMIT';

export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/ApplicationFormPage/SET_EMAIL_ERROR_MESSAGE_ACTION';

export const SET_EMAIL_SENT_ACTION =
  'app/ApplicationFormPage/SET_EMAIL_SENT_ACTION';

export const UPDATE_APPLICATION_FUND =
  'app/ApplicationFormPage/UPDATE_APPLICATION_FUND';

export const CHECK_NEXUS_DEVERE_FUNDS =
  'app/ApplicationFormPage/CHECK_NEXUS_DEVERE_FUNDS';

export const SET_FUNDS_BELONG_TO_NEXUS_OR_DEVER =
  'app/ApplicationFormPage/SET_FUNDS_BELONG_TO_NEXUS_OR_DEVER';
export const GENERATE_APPLICATION_PDF =
  'app/ApplicationFormPage/GENERATE_APPLICATION_PDF';

export const GENERATE_CREDIT_CARD_EMAIL =
  'app/ApplicationFormPage/GENERATE_CREDIT_CARD_EMAIL';

export const SET_OTHER_DOCUMENT_STATUS_ACTION = 
  'app/ApplicationFormPage/SET_OTHER_DOCUMENT_STATUS_ACTION';

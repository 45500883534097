/**
 * CoverageForm
 */
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  TextField,
  Collapse,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import FormTextField from 'components/FormTextField';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import globalMessages from 'containers/App/messages';
import FormNumberField from 'components/FormNumberField';
// import FormDatePicker from 'components/FormDatePicker';
import FormSelect from 'components/FormSelect';
import CustomFormCheck from 'components/CustomFormCheck';
import FormCheck from 'components/FormCheck';
import { isEmpty } from 'lodash';
import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import { changePlaceHolderColor } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import Benefits from './Benefits';
import { calculateAgeNextBirthdate, getStartDate } from '../../helper';
import validate from './validate';
import asyncValidator from './asyncValidate';
import messages from '../../messages';
import { formValues } from 'redux-form';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  hrAdditional: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
  },
}));

function CoverageForm({
  handleSubmit,
  onSubmit,
  lifeAssured,
  options,
  product,
  futuraConfig,
  change,
  agentDetails,
  coverages,
  policyCoverTerm,
  term,
  singlePremiumTerm,
  offerCode,
  fiaData,
  isAdditionalFeature,
  haveAdditionalSinglePremium,
  startDate,
  fiaCharge,
  paymentFrequency,
  currency,
  location,
  offerCodeData,
  resetOfferCode,
  validateOfferCode,
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const startDateList = [];

  const currencyOption = [];
  const paymentFrequencyOption = [];
  const premiumPaymentTerm = [];
  const coverageTerm = [];
  const isMLP = futuraConfig.productCode === 'SCFUT'; // My Life Protect
  const isFUTU5 = futuraConfig.productCode === 'FUTU5'; // Single Premium split
  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '') {
      validateOfferCode({
        offerCode,
        productCode: futuraConfig.productCode,
        productVersion: futuraConfig.productVersion,
        currency,
        premium: coverages?.[0]?.valueInsuredOne,
        premiumType: term,
      });
    }
  }, []);

  if (startDateList.length == 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  if (coverageTerm.length === 0) {
    const { minTerm } = futuraConfig.features?.policyTerm || 0;

    coverageTerm.push({ title: `${minTerm}`, value: minTerm });
    coverageTerm.push({ title: 'Whole of life', value: 'WholeOfLife' });
  }

  if (policyCoverTerm) {
    let minPremiumValue =
      futuraConfig.features?.policyTerm.minVanishingPremiumTerm;
    let maxPremiumValue =
      futuraConfig.features?.policyTerm.maxVanishingPremiumTerm;

    const expiryAgeValue = futuraConfig.features?.policyTerm.expiryAge;

    if (policyCoverTerm === 'WholeOfLife') {
      const ageOfLifeInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne.dateOfBirth,
      );
      const ageOfLifeInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo.dateOfBirth,
      );

      switch (lifeAssured.lifeBasis) {
        case 'JLBD':
          const considerAgeJlbd =
            ageOfLifeInsuredOne < ageOfLifeInsuredTwo
              ? ageOfLifeInsuredOne
              : ageOfLifeInsuredTwo;
          const calculateMaxTermJlbd = expiryAgeValue - considerAgeJlbd;
          maxPremiumValue =
            calculateMaxTermJlbd > maxPremiumValue
              ? maxPremiumValue
              : calculateMaxTermJlbd - 1;
          break;

        case 'JLFD':
          const considerAgeJlfd =
            ageOfLifeInsuredOne < ageOfLifeInsuredTwo
              ? ageOfLifeInsuredTwo
              : ageOfLifeInsuredOne;
          const calculateMaxTermJlfd = expiryAgeValue - considerAgeJlfd;
          maxPremiumValue =
            calculateMaxTermJlfd > maxPremiumValue
              ? maxPremiumValue
              : calculateMaxTermJlfd - 1;
          break;

        case 'JLLS':
          const considerAgeJlls =
            ageOfLifeInsuredOne < ageOfLifeInsuredTwo
              ? ageOfLifeInsuredOne
              : ageOfLifeInsuredTwo;
          const calculateMaxTermJlls = expiryAgeValue - considerAgeJlls;
          maxPremiumValue =
            calculateMaxTermJlls > maxPremiumValue
              ? maxPremiumValue
              : calculateMaxTermJlls - 1;
          break;

        default:
          const calculateMaxTerm = expiryAgeValue - ageOfLifeInsuredOne;
          maxPremiumValue =
            calculateMaxTerm > maxPremiumValue
              ? maxPremiumValue
              : calculateMaxTerm - 1;
          break;
      }
    } else
      maxPremiumValue =
        policyCoverTerm > maxPremiumValue ? maxPremiumValue : policyCoverTerm;

    while (minPremiumValue <= maxPremiumValue) {
      const myObj = { title: minPremiumValue, value: minPremiumValue };
      premiumPaymentTerm.push(myObj);
      minPremiumValue++;
    }
    if (!isMLP) {
      premiumPaymentTerm.push({
        title: 'Single Premium',
        value: 'SinglePremium',
      });
    }
    if (policyCoverTerm === 'WholeOfLife')
      premiumPaymentTerm.push({ title: 'Whole of life', value: 'WholeOfLife' });
  }

  futuraConfig.features?.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  futuraConfig.features?.premiumPaymentFrequencyType[0].periods.map(
    frequency => {
      paymentFrequencyOption.push({ value: frequency, title: frequency });
    },
  );

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';

  const agencyNumber = agentDetails.agentNumber;
  futuraConfig.agencyNumber = (agencyNumber)

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: 660 }}>
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            {/* <Grid item xs={6} md={6}>
            <Field
              shrink
              name="startDate"
              component={FormDatePicker}
              label={<FormattedMessage {...messages.assumedStartDate} />}
              dateInputFormat="DD/MM/YYYY"
              placeholder="dd/mm/yyyy"
              margin="normal"
              minDate={moment().subtract(2, 'months').date(1).toDate()}
              maxDate={moment().add(2, 'months').date(1).toDate()}
              InputLabelProps={{
                style: {
                  fontWeight: 'normal',
                  fontSize: theme.typography.body1.fontSize,
                },
              }}
              shouldDisableDate={date => {
                if (date.date() > 1) {
                  return true;
                }
                return false;
              }}
              onBlur={(event, newValue) => {
                const mDate = moment(newValue);
                if (mDate.isValid() && mDate.date() > 1) {
                  event.preventDefault();
                  blur('startDate', mDate.date(1).format("YYYY-MM-DD"));
                }
              }}
            />
          </Grid> */}

            <Grid item>
              <Field
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>

            {/* CURRRENCY */}
            <Grid item>
              <Field
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* Coverage TERM */}
            <Grid item xs={12}>
              <Field
                name="policyCoverTerm"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(policyCoverTerm),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.coverageTerm} />}
                type="text"
                // options={coverageTerm}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...coverageTerm,
                ]}
                color="primary"
                margin="normal"
                variant="standard"
              />
            </Grid>
            <Grid item>
              <Field
                name="term"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(term),
                }}
                component={FormSelect}
                label={
                  <FormattedMessage {...messages.premiumPaymentTermTitle} />
                }
                type="text"
                onChange={(e, newVal) => {
                  if (newVal === 'SinglePremium') {
                    change('paymentFrequency', '');
                    change('haveAdditionalSinglePremium', false);
                    change('singlePremium', 0);
                  }
                }}
                // options={premiumPaymentTerm}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...premiumPaymentTerm,
                ]}
                color="primary"
                margin="normal"
                variant="standard"
              />
            </Grid>

            <Grid item>
              <Field
                name="paymentFrequency"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(paymentFrequency),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.paymentFrequency} />}
                type="text"
                disabled={term === 'SinglePremium'}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...paymentFrequencyOption,
                ]}
                color="primary"
                margin="normal"
                variant="standard"
              />
            </Grid>
          </Grid>
        {/* ########## START  :: Futura SP fleixbility ########## */}
        <Grid container alignItems="center" style={{ 
          marginTop: '2rem',
          display: (term === 'SinglePremium' && isFUTU5) ? 'block' : 'none' }} >
          <Field
            name="singlePremiumTerm"
            type="radio"
            component={FormRadioGroup}              
          >
            
          <Grid item sm={12}>
            <FormControlLabel
              control={<CustomRadio checked={singlePremiumTerm === '1'} />}
              value={1}
              label={<FormattedMessage {...messages.singlePremiumPaymentTerm1Title} />}
              labelPlacement="end"
            />
          </Grid>
          <Grid item sm={12} style={{ marginTop: '1rem' }}>
            <FormControlLabel
              control={<CustomRadio checked={singlePremiumTerm === '2'} />}
              value={2}
              label={<FormattedMessage {...messages.singlePremiumPaymentTerm2Title} />}
              labelPlacement="end"
            />
          </Grid>
            <Grid item sm={12} style={{ marginTop: '1rem' }}>
              <FormControlLabel
                control={<CustomRadio checked={singlePremiumTerm === '3'} />}
                value={3}
                label={
                  <FormattedMessage
                    {...messages.singlePremiumPaymentTerm3Title}
                  />
                }
                labelPlacement="end"
              />
            </Grid>
          </Field>
        </Grid>

        {/* ########## END  :: Futura SP fleixbility ########## */}
          <Divider className={classes.hrAdditional} />
          {!isMLP && (
            <>
              <Grid container alignItems="center" item spacing={2}>
                {/* Additional single premium */}
                <Grid item xs={6} md={6}>
                  <Field
                    fullWidth
                    name="haveAdditionalSinglePremium"
                    component={CustomFormCheck}
                    label={
                      <FormattedMessage {...messages.additionalSinglePremium} />
                    }
                    disabled={term === 'SinglePremium'}
                    onChange={(e, newVal) => {
                      change('singlePremium', 0);
                    }}
                    type="text"
                    color="primary"
                    variant="standard"
                  />
                </Grid>
                <Grid item xs={3} md={3}>
                  <Field
                    name="singlePremium"
                    fullWidth
                    component={FormNumberField}
                    disabled={!haveAdditionalSinglePremium}
                    type="text"
                    color="primary"
                    variant="standard"
                    placeholder={intl.formatMessage(messages.enterAmount)}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.hr} />
            </>
          )}

          <Grid container spacing={1}>
            {/* Assumed growth rate */}
            <Grid item xs={4} md={4}>
              <Field
                name="growthRate"
                fullWidth
                component={FormNumberField}
                label={<FormattedMessage {...messages.assumedGrowthRate} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.growthRate)}
              />
            </Grid>
          </Grid>

          <Divider
            className={classes.hr}
            style={{ marginLeft: '-2rem', marginRight: '-2rem' }}
          />

          <Grid
            container
            direction="row"
            spacing={1}
            justify="start"
            alignItems="center"
            item
            style={{ marginBottom: theme.spacing(1), paddingRight: '10%' }}
            wrap="nowrap"
          >
            <Grid item xs={12} md={6} sm={6} />
            <Grid item xs={6} md={3} sm={3}>
              <Typography
                color="primary"
                variant="h3"
                style={{ marginLeft: theme.spacing(0.5) }}
              >
                {lifeAssured.insuredOne.firstName}
              </Typography>
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredTwo.firstName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid>
            <FieldArray
              name="coverages"
              component={Benefits}
              rerenderOnEveryChange
              lifeAssured={lifeAssured}
              options={options}
              inValidWop={term === 'SinglePremium'}
              change={change}
              startDate={startDate}
              coverages={coverages}
              intl={intl}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid item xs={4} style={{ marginBottom: '5rem' }}>
            <Field
              name="offerCode"
              fullWidth
              style={{
                width: 355,
              }}
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              placeholder={intl.formatMessage(messages.pleaseEnter)}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid item xs={12}>
            <Field
              name="specialTermsFlag"
              fullWidth
              component={FormCheck}
              label={<FormattedMessage {...globalMessages.isBenefitRating} />}
              type="text"
              color="primary"
              margin="normal"
              variant="standard"
            />
          </Grid>

          {!isEmpty(fiaData) && futuraConfig.features.haveFiaCharge ? (
            <>
              <Divider className={classes.hr} />
              <CardSectionForm variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Typography variant="body1">
                    <FormattedMessage {...globalMessages.additionalFetures} />
                  </Typography>
                  <Field
                    name="isAdditionalFeature"
                    component={FormSwitch}
                    disabled={false}
                  />
                  {/* <OptSwitch
                    checked={isAdditionalFeature}
                    color="default"
                    onChange={() => setAdditionalFeature(!isAdditionalFeature)}
                    labelNegative={<FormattedMessage {...globalMessages.no} />}
                  /> */}
                </Grid>

                <Collapse in={isAdditionalFeature}>
                  <Divider
                    style={{
                      borderTop: '1px solid rgba(0,0,0,0.12)',
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  />
                  <Typography
                    // variant="h4"
                    style={{
                      marginTop: theme.spacing(2),
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <FormattedMessage {...globalMessages.fiaText} />
                  </Typography>

                  <Field
                    name="fiaCharge"
                    type="radio"
                    component={FormRadioGroup}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={<CustomRadio checked={fiaCharge == 0} />}
                          value={fiaData?.minFIACharge}
                          label={<FormattedMessage {...globalMessages.nil} />}
                          labelPlacement="end"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container justify="space-between">
                          <FormControlLabel
                            control={<CustomRadio checked={fiaCharge != 0} />}
                            value={fiaData?.maxFIAChange}
                            label={
                              <FormattedMessage
                                {...globalMessages.annualPercentage}
                              />
                            }
                            labelPlacement="end"
                          />

                          <TextField
                            value={`${fiaData?.maxFIAChange} %`}
                            variant="outlined"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Field>
                </Collapse>
              </CardSectionForm>
            </>
          ) : null}
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  options: PropTypes.object,
  futuraConfig: PropTypes.object,
  startDate: PropTypes.string,
  product: PropTypes.object,
  offerCode: PropTypes.string,
  offerCodeData: PropTypes.object,
  lifeAssured: PropTypes.object,
  policyCoverTerm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  haveAdditionalSinglePremium: PropTypes.bool,
  term: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setBenefitRating: PropTypes.func,
  coverages: PropTypes.array,
  fiaData: PropTypes.object,
  fiaCharge: PropTypes.number,
  singlePremiumTerm: PropTypes.number,
};

const selector = formValueSelector('coverageForm');
const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withFormSelector = connect(state => {
  const coverages = selector(state, 'coverages');
  const policyCoverTerm = selector(state, 'policyCoverTerm');
  const startDate = selector(state, 'startDate');
  const fiaCharge = selector(state, 'fiaCharge');
  const term = selector(state, 'term');
  const currency = selector(state, 'currency');
  const offerCode = selector(state, 'offerCode');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const haveAdditionalSinglePremium = selector(
    state,
    'haveAdditionalSinglePremium',
  );
  const isAdditionalFeature = selector(state, 'isAdditionalFeature');
  const singlePremiumTerm = selector(state, 'singlePremiumTerm');

  return {
    coverages: coverages?.toJS() || [],
    policyCoverTerm,
    term,
    haveAdditionalSinglePremium,
    startDate,
    fiaCharge,
    currency,
    paymentFrequency,
    offerCode,
    isAdditionalFeature,
    singlePremiumTerm
  };
});

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: [
    'offerCode',
    'term',
    'coverages[].valueInsuredOne',
    'currency',
  ],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

import React from 'react';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Box,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { WrappedFieldArrayProps } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { Cancel, CheckCircle, Edit } from '@material-ui/icons';
import FormSlider from 'components/FormSlider';
import { getMinValue, getMaxValue } from '../../helper';
import FormBooleanOption from 'components/FormBooleanOption';
import { formatMonetaryValue } from 'helpers/math';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormNumberField from 'components/FormNumberField';

const Benefits = ({
  fields,
  coverages,
  configsDetail,
  currency,
  isInsuredTwo,
  editData,
  changeEditData,
  calculateEditData,
  change,
  inactive,
  isCoveragesError,
}) => {
  const theme = useTheme();

  return (
    <Box bgcolor={inactive ? `#f2f2f2` : undefined}>
      {fields.map((benefit, index) => {
        if (!coverages[index].selected) {
          return null;
        }

        // const myBenefit = fields.getAll().toJS();
        let isValidData = coverages[index].selected;

        if (coverages[index].id === 'WOP') {
          if (isInsuredTwo)
            isValidData =
              coverages[index].valueInsuredOne &&
              coverages[index].valueInsuredTwo;
          else isValidData = coverages[index].valueInsuredOne;
        }

        return (
          <Box
            bgcolor={inactive ? '#f2f2f2' : undefined}
            pl={3}
            pr={1}
            py={2}
            borderBottom={`1px solid ${theme.palette.divider}`}
            key={benefit}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  {isValidData ? (
                    <CheckCircle
                      style={{ color: theme.palette.success.main }}
                    />
                  ) : (
                    <Cancel style={{ color: theme.palette.error.main }} />
                  )}
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 700,
                  }}
                >
                  {coverages[index].title}
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={1}
                >
                  <Grid item xs={8}>
                    {editData.editIndex === index &&
                    editData.editInsured !== 0 ? (
                      <Field
                        shrink
                        name={`${benefit}.valueInsuredOne`}
                        onChange={(e, newVal) =>
                          change(`${benefit}.valueInsuredTwo`, newVal)
                        }
                        fullWidth
                        component={FormNumberField}
                        decimalScale={0}
                        variant="standard"
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        id={`${coverages[index].id}.insuredOne`}
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        {coverages[index].selected
                          ? formatMonetaryValue(
                              coverages[index].valueInsuredOne,
                              false,
                            )
                          : '-'}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    {!inactive ? (
                      <IconButton
                        disabled={
                          !coverages[index].selected || isCoveragesError
                        }
                        onClick={() => {
                          return changeEditData({
                            editIndex: index,
                            editInsured: 1,
                          });
                        }}
                      >
                        <img src={IconPencilBlue} alt="" />
                      </IconButton>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              {isInsuredTwo && (
                <Grid item xs={3}>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        variant="body1"
                        color={
                          coverages[index].id === 'WOP'
                            ? 'textPrimary'
                            : 'textSecondary'
                        }
                        id={`${coverages[index].id}.insuredTwo`}
                        style={{
                          fontWeight: 700,
                        }}
                      >
                        {coverages[index].selected
                          ? formatMonetaryValue(
                              coverages[index].valueInsuredTwo,
                              false,
                            )
                          : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Collapse
              in={editData.editIndex === index && editData.editInsured !== 0}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={7}>
                  {editData.editInsured === 1 ? (
                    <Field
                      name={`${benefit}.valueInsuredOne`}
                      component={FormSlider}
                      onChange={(e, newVal) =>
                        change(`${benefit}.valueInsuredTwo`, newVal)
                      }
                      id={coverages[index].id}
                      min={getMinValue(
                        coverages[index].id,
                        configsDetail.features,
                        currency,
                      )}
                      max={getMaxValue(
                        coverages[index].id,
                        configsDetail.features,
                        currency,
                      )}
                      diff={50000}
                      isPrimary={false}
                      type="text"
                    />
                  ) : (
                    <Field
                      name={`${benefit}.valueInsuredTwo`}
                      component={FormSlider}
                      id={coverages[index].id}
                      min={getMinValue(
                        coverages[index].id,
                        configsDetail.features,
                        currency,
                      )}
                      max={getMaxValue(
                        coverages[index].id,
                        configsDetail.features,
                        currency,
                      )}
                      diff={50000}
                      isPrimary={false}
                      disabled
                      type="text"
                    />
                  )}
                </Grid>

                <Grid item xs={3}>
                  <Button
                    size="large"
                    variant="contained"
                    disabled={isCoveragesError}
                    onClick={() =>
                      calculateEditData({ editIndex: 0, editInsured: 0, index })
                    }
                  >
                    <FormattedMessage {...messages.update} />
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
          </Box>
        );
      })}
    </Box>
  );
};

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  theme: PropTypes.object,
  options: PropTypes.object,
  configsDetail: PropTypes.object,
  currency: PropTypes.string,
  coverages: PropTypes.array,
  editData: PropTypes.object,
  changeEditData: PropTypes.func,
  calculateEditData: PropTypes.func,
  isInsuredTwo: PropTypes.bool,
  change: PropTypes.func,
};

export default Benefits;

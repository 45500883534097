/*
 *
 * Futura/WAP/SW ifiIllustrationPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CHANGE_LIFE_ASSURED_ACTION,
  CHANGE_COVERAGE_ACTION,
  SET_QUOTE_ACTION,
  SET_CALCULATION_ACTION,
  SET_ASP_ACTION,
  SET_ERROR_ACTION,
  SET_COVERAGES_LIST_ACTION,
  SET_QUOTE_DETAILS_ACTION,
  RESET_ACTION,
  SET_APPLICATION_ID_ACTION,
  SET_POLICY_CONFIG_ACTION,
  SET_PREMIUM_TERM_ACTION,
  SET_PTV_VERSION_ACTION,
  SET_FIA_ACTION,
  SET_MINPREMIUM_ACTION,
  SET_CLONE_STATUS_ACTION,
  SET_BI_PDF_ACTION,
  SET_EMAIL_SENT_ACTION,
  SET_EMAIL_ERROR_MESSAGE_ACTION,
  SET_COMPLETED_DIALOG,
  SET_IS_CALCULATE_QUOTE_PAGE_ACTION,
  SET_PRODUCT_CODE_ACTION,
  SET_HARVEST_DATA_ACTION,
  SET_SUSTAINABILITY_YEAR_ACTION,
  SET_INIT_COVERAGES_ACTION,
  SET_INIT_PREMIUM_SLICES_ACTION,
  SET_PREMIUM_SLICES_ACTION,
  SET_GROWTH_RATE_ACTION,
  SET_SHOW_WARNING_REVIEW,
  SET_POLICY_TERM,
  RESET_PRODUCT_CONFIGS_ACTION,
  SET_INIT_COVERAGES_LIST_ACTION,
  SET_MANUAL_PREMIUM,
  ACTION_EDIT_QUOTE,
  SET_LAST_ACTION_EDIT_QUOTE,
  SET_POLICY_TERM_HARVEST,
  SET_COUNT_GET_SUSTAIN_AGE_ACTION,
  SET_BI_TABLE_VALUES_ACTION,
  SET_LIFE_ASSURED_HARVEST
} from './constants';

const defaultState = {
  lastAction: ACTION_EDIT_QUOTE.GET_SUSTAIN_AGE,
  countOfCallingSustainAge: 0,
  isManualPremium: false,
  emailErrorMessage: '',
  emailSent: false,
  quoteId: '',
  sustainabilityYears: '--',
  applicationId: '',
  referenceNumber: '',
  benefitIllustrationPdf: '',
  status: '',
  harvestData: {},
  restartFrom : [{ title: 'January ' + '2022',
  value: 'January ' + '2022',
  disabled: false,}
  ],
  errorData: {
    error: false,
    message: [],
  },
  lifeAssured: {
    insuredOne: {
      prospectId: '',
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      isSmoke: null,
      isLifeInsured: true,
      isPrimaryLifeInsured: true,
      isPolicyOwner: true,
      relationshipToLifeInsured: '',
    },
    isInsuredTwo: false,
    lifeBasis: '',
    insuredTwo: {
      prospectId: '',
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      isSmoke: null,
      isExisting: true, // to check if added new life
      isLifeInsured: true,
      isPolicyOwner: false,
      isPrimaryLifeInsured: false,
      relationshipToLifeInsured: '',
    },
    isPolicyOwnerIndividual: false,
    policyOwnerIndividual: [
      {
        prospectId: '',
        firstName: '',
        lastName: '',
        relationshipToLifeInsured: '',
        isPolicyOwner: true,
        isLifeInsured: false,
        isPrimaryLifeInsured: false,
      },
    ],
  },
  productConfig: {},
  initProductConfig: {},
  initCoverages: [],
  initPremium: 0,
  policyConfig: {},
  fiaData: {},
  initialLifeAssured: {},
  lifeAssuredHarvest: {},
  successClone: false,
  completedDialog: {
    open: false,
    message: null,
  },
  biTableValues: {},
  isCalculateQuotePage: false,
  isShowWarningReview: false,
};

export const initialState = fromJS({ ...defaultState });

function ifiIllustrationPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_ACTION:
      return state.set('errorData', action.payload);
    case RESET_ACTION:
      return fromJS({ ...defaultState });
    case SET_QUOTE_ACTION:
      return state.set('quoteId', action.payload);
    case SET_APPLICATION_ID_ACTION:
      return state.set('applicationId', action.payload);
    case SET_POLICY_CONFIG_ACTION:
      return state.set('policyConfig', fromJS(action.payload));
    case RESET_PRODUCT_CONFIGS_ACTION:
      return state.set('productConfig', fromJS(action.payload));
    case CHANGE_LIFE_ASSURED_ACTION:
      return state
        .set('lifeAssured', fromJS(action.payload))
        .set('initialLifeAssured', fromJS(action.payload));
    case SET_LIFE_ASSURED_HARVEST:
      return state.set('lifeAssuredHarvest',fromJS(action.payload));
    case CHANGE_COVERAGE_ACTION:
      return state
        .set('productConfig', fromJS(action.payload.data))
        .set('initProductConfig', fromJS(action.payload.data));

    case SET_INIT_COVERAGES_ACTION:
      return state.setIn(
        ['productConfig', 'initCoverages'],
        fromJS(action.payload),
      );
    case SET_INIT_PREMIUM_SLICES_ACTION:
      return state.set('initPremium', fromJS(action.payload));
    case SET_QUOTE_DETAILS_ACTION:
      return state
        .set('referenceNumber', action.payload.referenceNumber)
        .set('benefitIllustrationPdf', action.payload.benefitIllustrationPdf)
        .set('status', action.payload.status);
    case SET_COVERAGES_LIST_ACTION:
      return state.setIn(['productConfig', 'coverages'], action.payload);
    case SET_INIT_COVERAGES_LIST_ACTION:
      return state.setIn(['initProductConfig', 'coverages'], action.payload);
    case SET_CALCULATION_ACTION:
      return state.setIn(['productConfig', 'premium'], action.payload);
    case SET_ASP_ACTION:
        return state.setIn(['productConfig', 'additionalSinglePremium'], action.payload);
    case SET_MINPREMIUM_ACTION:
      return state.setIn(
        ['productConfig', 'minCalculatedPremium'],
        Math.ceil(action.payload.response.premium),
      );
    case SET_PREMIUM_TERM_ACTION:
      return state.set(['productConfig', 'policyCoverTerm'], action.payload);
    case SET_PTV_VERSION_ACTION:
      return state.setIn(['productConfig', 'ptvVersion'], action.payload);
    case SET_PRODUCT_CODE_ACTION:
      return state.setIn(['productConfig', 'productCode'], action.payload);
    case SET_FIA_ACTION:
      return state.set('fiaData', fromJS(action.payload));
    case SET_CLONE_STATUS_ACTION:
      return state.set('successClone', action.payload);
    case SET_BI_PDF_ACTION:
      return state.set('benefitIllustrationPdf', action.payload);
    case SET_EMAIL_SENT_ACTION:
      return state.set('emailSent', action.payload);
    case SET_EMAIL_ERROR_MESSAGE_ACTION:
      return state.set('emailErrorMessage', action.payload);
    case SET_COMPLETED_DIALOG:
      return state.set('completedDialog', action.payload);
    case SET_IS_CALCULATE_QUOTE_PAGE_ACTION:
      return state.set('isCalculateQuotePage', action.payload);
    case SET_HARVEST_DATA_ACTION:
      return state.set('harvestData', fromJS(action.payload));
    case SET_SUSTAINABILITY_YEAR_ACTION:
      return state.set('sustainabilityYears', action.payload);
    case SET_POLICY_TERM_HARVEST:
      return state.setIn(
        [
          'harvestData',
          'originalPolicySummary',
          'productConfigs',
          'policyTerm',
        ],
        fromJS(action.payload),
      );
    case SET_PREMIUM_SLICES_ACTION:
      return state.setIn(
        ['productConfig', 'regularPremiumSlices'],
        fromJS(action.payload),
      );
    case SET_POLICY_TERM:
      return state.set('policyTerm', action.payload).setIn(['productConfig','policyTerm'], action.payload);
    case SET_GROWTH_RATE_ACTION:
      return state.setIn(['productConfig', 'growthRate'], action.payload);
    case SET_SHOW_WARNING_REVIEW:
      return state.set('isShowWarningReview', action.payload);
    case SET_MANUAL_PREMIUM:
      return state.set('isManualPremium', action.payload);
    case SET_LAST_ACTION_EDIT_QUOTE:
      return state.set('lastAction', action.payload);
    case SET_COUNT_GET_SUSTAIN_AGE_ACTION:
      return state.set('countOfCallingSustainAge', action.payload);
    case SET_BI_TABLE_VALUES_ACTION:
      return state.set('biTableValues', fromJS(action.payload));
    default:
      return state;
  }
}

export default ifiIllustrationPageReducer;

/**
 * Withdrawal
 */
 import React from 'react';
 import PropTypes from 'prop-types';
 import { FormattedMessage, useIntl } from 'react-intl';
 import { useTheme, makeStyles } from '@material-ui/core/styles';
 import { Field } from 'redux-form/immutable';
 import { Grid, FormControlLabel, Radio, Divider, Box } from '@material-ui/core';
 import globalMessages from 'containers/App/messages';
 import FormTextField from 'components/FormTextField';
 import FormDatePicker from 'components/FormDatePicker';
 import messages from './../messages';
 import FormSelect from 'components/FormSelect';
 import FormRadioGroup from 'components/FormRadioGroup';
 import FormNumberField from 'components/FormNumberField';
 import CustomRadio from 'components/CustomRadioButton';
 import numeral from 'numeral';
 import { changePlaceHolderColor, convertIfImmutable, changeToTitleCase } from 'helpers/lang';
 
 const useStyles = makeStyles(theme => ({
   hr: {
     borderTop: '1px solid rgba(0,0,0,0.12)',
     marginTop: theme.spacing(4),
     marginBottom: theme.spacing(5),
     width: '100%',
   },
 }));
 
 const lessThan = otherField => (value, previousValue, allValues) => {
   if (!value) return '';
   return value.length <= otherField ? value : previousValue;
 };
 function Withdrawal({
   formValues = {},
   frequencyList,
   error,
   minDate,
   maxDate,
   isEducationWithdrawal,
 }) {
   const theme = useTheme();
   const intl = useIntl();
 
   if (isEducationWithdrawal) formValues = formValues.child1;
 
   const classes = useStyles();
   return (
     <Box mb={3} ml={1}>
       <Grid container spacing={3} alignItems="center">
         <Grid item>
           <Field
             shrink
             name="frequency"
             component={FormSelect}
             style={{
               color: changePlaceHolderColor(
                 convertIfImmutable(formValues)?.frequency,
               ),
               width: 260,
             }}
             // options={frequencyList}
             options={[{ value: 'Select', title: 'Select' }, ...frequencyList]}
             type="text"
             variant="standard"
             label={<FormattedMessage {...messages.frequency} />}
           />
         </Grid>
 
         <Grid item sm={5} xs={12} md={5}></Grid>
 
         <Grid item>
           <Field
             shrink
             style={{
               width: 260,
             }}
             name="startDate"
             component={FormDatePicker}
             label={<FormattedMessage {...messages.startDate} />}
             dateInputFormat="DD/MM/YYYY"
             placeholder="dd/mm/yyyy"
             margin="normal"
             minDate={minDate}
             maxDate={maxDate}
             InputLabelProps={{
               style: {
                 fontWeight: 'normal',
                 fontSize: theme.typography.body1.fontSize,
               },
             }}
             shouldDisableDate={date => {
               if (date.date() != 15) {
                 return true;
               }
               return false;
             }}
           />
         </Grid>
 
         <Grid item>
           <Field
             shrink
             style={{
               width: 260,
             }}
             name="endDate"
             component={FormDatePicker}
             label={<FormattedMessage {...messages.endDate} />}
             dateInputFormat="DD/MM/YYYY"
             placeholder="dd/mm/yyyy"
             margin="normal"
             minDate={minDate}
             maxDate={maxDate}
             InputLabelProps={{
               style: {
                 fontWeight: 'normal',
                 fontSize: theme.typography.body1.fontSize,
               },
             }}
           />
         </Grid>
 
         {isEducationWithdrawal ? (
           <Grid item>
             <Field
               shrink
               name="firstName"
               style={{
                 width: 260,
               }}
               component={FormTextField}
               label={<FormattedMessage {...messages.firstName} />}
               placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
               margin="normal"
               normalize={lessThan(45)}
               format = {(value) => changeToTitleCase(value)}
             />
           </Grid>
         ) : null}
 
         {isEducationWithdrawal ? (
           <Grid item>
             <Field
               shrink
               name="lastName"
               style={{
                 width: 260,
               }}
               component={FormTextField}
               label={<FormattedMessage {...messages.lastName} />}
               placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
               margin="normal"
               normalize={lessThan(40)}
               format = {(value) => changeToTitleCase(value)}
             />
           </Grid>
         ) : null}
 
         <Divider className={classes.hr} />
 
         {!isEducationWithdrawal ? (
           <Grid container spacing={2} wrap="nowrap" style={{ height: '100px' }}>
             <Grid item>
               <Field
                 name="isAmountChecked"
                 type="radio"
                 component={FormRadioGroup}
                 style={{ height: '85px' }}
               >
                 <Grid
                   container
                   spacing={1}
                   justify="center"
                   style={{
                     height: '100%',
                     marginLeft: 8,
                     width: 305,
                     marginRight: 20,
                   }}
                 >
                   <Grid item xs={12}>
                     <FormControlLabel
                       control={
                         <CustomRadio
                           checked={
                             formValues == undefined
                               ? false
                               : numeral(formValues.isAmountChecked).value() == 1
                           }
                         />
                       }
                       value={1}
                       style={{ margin: `${theme.spacing(0.5)}px` }}
                       label={<FormattedMessage {...messages.amount} />}
                       labelPlacement="end"
                     />
                   </Grid>
 
                   <Grid item xs={12}>
                     <FormControlLabel
                       control={
                         <CustomRadio
                           disabled={true}
                           checked={
                             formValues == undefined
                               ? false
                               : numeral(formValues.isAmountChecked).value() == 2
                           }
                         />
                       }
                       value={2}
                       disabled={true}
                       style={{ margin: `${theme.spacing(0.5)}px` }}
                       label={
                         <FormattedMessage {...messages.percentageFundValue} />
                       }
                       labelPlacement="end"
                     />
                   </Grid>
                 </Grid>
               </Field>
             </Grid>
 
             <Grid item>
               <Grid
                 container
                 direction="column"
                 wrap="nowrap"
                 spacing={1}
                 justify="center"
                 style={{ height: '100%' }}
               >
                 <Grid item>
                   <Field
                     shrink
                     name="amount"
                     component={FormNumberField}
                     style={{
                       width: 134,
                     }}
                     type="text"
                     variant="standard"
                     disabled={
                       formValues == undefined
                         ? true
                         : numeral(formValues.isAmountChecked).value() != 1
                     }
                   />
                 </Grid>
 
                 <Grid item>
                   <Field
                     shrink
                     name="percentage"
                     style={{
                       width: 134,
                     }}
                     component={FormNumberField}
                     type="text"
                     variant="standard"
                     disabled={
                       formValues == undefined
                         ? true
                         : numeral(formValues.isAmountChecked).value() != 2
                     }
                   />
                 </Grid>
               </Grid>
             </Grid>
           </Grid>
         ) : null}
 
         {isEducationWithdrawal ? (
           <Grid item>
             <Field
               shrink
               name="amount"
               component={FormNumberField}
               style={{
                 width: 134,
               }}
               type="text"
               variant="standard"
               label="Amount"
             />
           </Grid>
         ) : null}
       </Grid>
     </Box>
   );
 }
 
 Withdrawal.propTypes = {
   formValues: PropTypes.object,
   frequencyList: PropTypes.array,
   minDate: PropTypes.any,
   maxDate: PropTypes.any,
   error: PropTypes.bool,
   isEducationWithdrawal: PropTypes.bool,
 };
 
 export default Withdrawal;
 
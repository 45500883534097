import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useTheme, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const RadioButton = withStyles({
  root: {
    display: 'none',
  },
})(Radio);

export default function CustomFilter({
  value,
  isAvailableNewBusiness,
  isAvailableExistingBusiness,
  onChange,
}) {
  const theme = useTheme();
  return (
    <FormControl>
      <RadioGroup
        onChange={e => {
          if (!isAvailableNewBusiness && !isAvailableExistingBusiness) {
            return null;
          }

          if (isAvailableNewBusiness && isAvailableExistingBusiness) {
            return onChange(e.target.value === 'true');
          }

          if (isAvailableNewBusiness) {
            return onChange(true);
          }

          if (isAvailableExistingBusiness) {
            return onChange(false);
          }
          if (e.target.value === 'false') {
            return onChange(false);
          }
        }}
        style={{
          marginTop: theme.spacing(2),
        }}
      >
        <Grid container>
          <Grid item>
            <FormControlLabel
              value
              control={<RadioButton color="primary" />}
              label={
                <Typography variant="body2">
                  <FormattedMessage {...messages.newBusiness} />
                </Typography>
              }
              style={{
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(1.25),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor: value === true ? '#4066B3' : '#CFD0D1',
                color: isAvailableNewBusiness
                  ? value === true
                    ? '#FFFFFF'
                    : theme.palette.text.primary
                  : '#A6A6A6',
                margin: 0,
                border: '1px solid #DDDFE0',
              }}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              value={false}
              control={<RadioButton color="primary" />}
              label={
                <Typography variant="body2">
                  <FormattedMessage {...messages.existingBusiness} />
                </Typography>
              }
              style={{
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(1.25),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                backgroundColor: value === false ? '#4066B3' : '#CFD0D1',
                color: isAvailableExistingBusiness
                  ? value === false
                    ? '#FFFFFF'
                    : theme.palette.text.primary
                  : '#A6A6A6',
                margin: 0,
                border: '1px solid #DDDFE0',
              }}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}

CustomFilter.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  isAvailableExistingBusiness: PropTypes.object,
  isAvailableNewBusiness: PropTypes.object,
};

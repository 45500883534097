import {
  takeLatest,
  takeEvery,
  call,
  put,
  all,
  select,
  delay,
} from 'redux-saga/effects';
import { findIndex } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { sortBy, map, forEach, find } from 'lodash/collection';
import voucherCodeGenerator from 'voucher-code-generator';

import sha256 from 'js-sha256';
import moment from 'moment';
import {
  makeSelectUnderwritingSections,
  makeSelectLIParties,
  makeSelectApplicationDetails,
} from 'containers/ApplicationPage/selectors';
import {
  makeSelectSubPageStepper,
  makeSelectSubPage,
  makeSelectSubPageList,
} from 'containers/DashboardPage/selectors';

import { makeSelectAuth } from 'containers/App/selectors';

import { api, payfort } from 'environments';
import request from 'utils/request';

import { setAppLoadingAction, setErrorAction } from 'containers/App/actions';

import {
  changeSubPageAction,
  changeSubStepPageAction,
} from 'containers/DashboardPage/actions';

import { setETag } from 'containers/ApplicationPage/actions';

// helpers
import { convertIfImmutable, isUS, isNexusAgency } from 'helpers/lang';

import {
  getApplicationDetails,
  updateApplicationParty,
} from 'containers/ApplicationPage/saga';
import { lastSubStepper } from 'containers/ApplicationFormPage/helpers';
import {
  setSectionQuestionsAction,
  setSectionQuestionsProperty,
  setPaymentDataAction,
  setConvertCurrencyAction,
  setChangeCurrencyAction,
  setDoctorListAction,
  updatePaymentType,
  setValidFundsAction,
  setUWStatusAction,
  setRequestSignsAction,
  setDocumentStatusAction,
  setConsentLinkAction,
  setCountIdvSubmit,
  setEmailErrorMessage,
  setEmailSentAction,
  setFundsBelongToNexusOrDever,
  setOtherDocumentStatusAction
} from './actions';
import {
  ANSWER_QUESTION_ACTION,
  SEARCH_ANSWER_ACTION,
  CALL_SECTION_QUESTIONS_ACTION,
  SUBMIT_UNDERWRITING_ACTION,
  UPLOAD_FILE_DOCUMENTS_ACTION,
  ADD_DOCUMENT_ACTION,
  CREATE_IDV_SESSION,
  DELETE_FILE_DOCUMENTS_ACTION,
  SUBMIT_PAYMENT_ACTION,
  SUBMIT_CREDIT_CARD_ACTION,
  CONTINUE_PAYMENT_CALLBACK_ACTION,
  GET_CONVERT_CURRENCY_ACTION,
  GET_CHANGE_CURRENCY_ACTION,
  ADD_DOCTOR_ACTION,
  GET_DOCTORS_ACTION,
  GET_VALID_FUNDS_ACTION,
  REQUEST_SIGNS_ACTION,
  GET_APPLICATION_ENVELOP_ACTION,
  REFRESH_APPLICATION_ENVELOP_ACTION,
  SEND_PDF_ACTION,
  UPDATE_APPLICATION_FUND,
  CHECK_NEXUS_DEVERE_FUNDS,
  GENERATE_APPLICATION_PDF,
  GENERATE_CREDIT_CARD_EMAIL,
} from './constants';
import {
  makeSelectSectionQuestions,
  makeSelectPaymentType,
  makeSelectCountIdvSubmit,
} from './selectors';

export function* callSectionQuestion(payload) {
  const auth = yield select(makeSelectAuth());
  const { section, sessionId, order } = payload.payload;
  // const sessionId = yield select(makeSelectSessionId());
  const UWSections = convertIfImmutable(
    yield select(makeSelectUnderwritingSections()),
  );
  const endpoint = `${api.host}/api/v${api.version}/b2b/uw/questions/${sessionId}?sectionId=${section}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    const response = yield call(request, endpoint, requestOpt);

    const foundSection = find(UWSections, s => s.order === order);
    foundSection && typeof foundSection !== 'undefined'
      ? yield put(
          setSectionQuestionsAction({
            questions: response.data.body,
            sectionName: foundSection.text,
          }),
        )
      : null;

    // set an empty property field for all questions and put it on answer
    // for validation purposes
    const answerProperties = {};

    forEach(response.data.body, question => {
      if (question.type !== 'STATEMENT') {
        if (
          question.type === 'SINGLE_CHOICE' &&
          !question.parentQuestionId &&
          question.constraints.choices
        ) {
          if (question.constraints.choices.length === 2) {
            answerProperties[`question-${question.id}`] = false;
          } else {
            answerProperties[`question-${question.id}`] = '';
            // question.constraints.choices[0].value;
          }
        } else if (question.type === 'BLOOD_PRESSURE') {
          // answerProperties[`question-${question.id}`] = {
          //   systolic: question.constraints.minValSystolic,
          //   diastolic: question.constraints.minValDiastolic,
          // };

          // use the code below if the BLOOD_PRESSURE is a field array type question

          answerProperties[`question-${question.id}`] = [];
          for (let i = 0; i < question.constraints.maxNumberOfReadings; i++) {
            answerProperties[`question-${question.id}`].push({
              systolic: question.constraints.minValSystolic,
              diastolic: question.constraints.minValDiastolic,
            });
          }
        } else if (question.type === 'DATE') {
          answerProperties[`question-${question.id}`] = {
            format: question.constraints?.availableDateFormats[0],
            date: '',
          };
        } else if (question.type === 'UNITIZED') {
          answerProperties[`question-${question.id}`] = {
            v: '',
            unit: question.constraints?.availableUnits[0].unitSymbol,
          };
        } else {
          answerProperties[`question-${question.id}`] = '';
        }
      }
    });
    foundSection && typeof foundSection !== 'undefined'
      ? yield put(
          setSectionQuestionsProperty({
            answers: answerProperties,
            sectionName: foundSection.text,
          }),
        )
      : null;
  } catch (err) {
    console.log(err);
  }
}

// ANSWER QUESTION

export function* answerQuestion({ payload }) {
  yield put(setAppLoadingAction(true));
  yield delay(300);

  const auth = yield select(makeSelectAuth());
  // const sessionId = yield select(makeSelectSessionId());
  // const eTag = yield select(makeSelectEtag());

  const endpoint = `${api.host}/api/v1/b2b/uw/questions`;
  const bodyPayload = () => {
    if (
      payload.questionType === 'SINGLE_CHOICE' ||
      payload.questionType === 'NUMERIC' ||
      payload.questionType === 'TEXT'
    ) {
      return {
        answer: {
          questionType: payload.questionType,
          value: payload.value,
          unknownAnswer: payload.value === 'ANSWER_IS_UNKNOWN_CODE',
        },
        eTag: payload.eTag,
        sessionId: payload.sessionId,
        questionId: payload.questionId,
      };
    }
    if (
      payload.questionType === 'MULTIPLE_CHOICE' ||
      payload.questionType === 'SEARCH'
    ) {
      return {
        answer: {
          questionType: payload.questionType,
          answers: payload.answers,
          unknownAnswer: false,
        },
        eTag: payload.eTag,
        sessionId: payload.sessionId,
        questionId: payload.questionId,
      };
    }
    if (payload.questionType === 'UNITIZED') {
      if (payload.value.v && payload.value.unit) {
        let value = payload.value.v;
        value = parseFloat(value.toString().replace(/,/g, ''));
        return {
          answer: {
            questionType: payload.questionType,
            selectedUnit: payload.value.unit,
            answerParts:
              payload.value.unit === 'ft_in'
                ? payload.value.v.split('.')
                : [`${value}`],
            unknownAnswer: false,
          },
          eTag: payload.eTag,
          sessionId: payload.sessionId,
          questionId: payload.questionId,
        };
      }
      if (payload.questionType === 'DATE') {
        return {
          answer: {
            questionType: payload.questionType,
            value: payload.value,
            unknownAnswer: false,
          },
          eTag: payload.eTag,
          sessionId: payload.sessionId,
          questionId: payload.questionId,
        };
      }
    } else if (payload.questionType === 'DATE') {
      return {
        answer: {
          questionType: payload.questionType,
          value: payload.value.date,
          dateFormat: payload.value.format,
          unknownAnswer: false,
        },
        eTag: payload.eTag,
        sessionId: payload.sessionId,
        questionId: payload.questionId,
      };
    } else if (payload.questionType === 'BLOOD_PRESSURE') {
      return {
        answer: {
          questionType: payload.questionType,
          readings: payload.value,
          unknownAnswer: false,
        },
        eTag: payload.eTag,
        sessionId: payload.sessionId,
        questionId: payload.questionId,
      };
    } else if (payload.questionType === 'DOCTOR') {
      return {
        answer: {
          questionType: payload.questionType,
          key: payload.value,
          unknownAnswer: false,
        },
        eTag: payload.eTag,
        sessionId: payload.sessionId,
        questionId: payload.questionId,
      };
    } else if (payload.questionType === 'PERCENT') {
      return {
        answer: {
          questionType: payload.questionType,
          answers: payload.value,
          unknownAnswer: false,
        },
        eTag: payload.eTag,
        sessionId: payload.sessionId,
        questionId: payload.questionId,
      };
    }

    return {};
  };
  // check if incomplete payload
  if (!bodyPayload()) {
    yield put(setAppLoadingAction(false));
    return;
  }

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },

    body: JSON.stringify(bodyPayload()),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    yield call(callSectionQuestion, {
      payload: {
        section: payload.section,
        sessionId: payload.sessionId,
        order: payload.order,
      },
    });
    // update Page
    yield put(
      setETag({
        eTag: response.data.eTag,
        partyId: payload.partyId,
      }),
    );
    if (response.data) {
      yield put(setAppLoadingAction(false));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
    console.log(err);
  }
}

export function* searchAnswer({ payload }) {
  yield delay(800);
  const auth = yield select(makeSelectAuth());
  const UWSections = convertIfImmutable(
    yield select(makeSelectUnderwritingSections()),
  );
  // const questions = yield select(
  //   makeSelectSectionQuestions(UWSections[payload.section].text),
  // );

  const questions = yield select(
    makeSelectSectionQuestions(payload.sectionName),
  );
  const endpoint = `${api.host}/api/v1/b2b/uw/search/${payload.questionNumber}/session/${payload.sessionId}?search=${payload.value}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    // get the list question by section id and push the option inside the question
    // by question ID
    const option = response.data;

    const questionIndex = findIndex(
      questions,
      question => question.id === payload.questionNumber,
    );

    questions[questionIndex].option = option;

    yield put(
      setSectionQuestionsAction({
        questions,
        sectionName: payload.sectionName,
      }),
    );
  } catch (err) {
    console.log(err);
  }
}

export function* submitUW({ payload }) {

  yield put(setAppLoadingAction(true));
  const { applicationId, history } = payload;
  const auth = yield select(makeSelectAuth());
  const LIParty = yield select(makeSelectLIParties());
  const subPageList = yield select(makeSelectSubPageList());
  const subPage = yield select(makeSelectSubPage());
  const subPageStepper = yield select(makeSelectSubPageStepper());
  const endpoint = `${api.host}/api/v1/b2b/uw/sessions/${applicationId}/submit`;

  const body = {
    data: map(convertIfImmutable(LIParty), party => ({
      partyId: party.partyId,
      sessionId: party.sessionId,
      eTag: party.eTag,
    })),
  };
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(body),
  };
  try {
    const response = yield call(request, endpoint, requestOpt);

    //removed as per Hau's advise
    // yield call(updateApplicationStatus, {
    //   statusName: 'isUnderwritingCompleted',
    //   status: true,
    //   applicationId,
    // });
    if (lastSubStepper(subPageList, subPage) > subPageStepper) {
      yield put(changeSubStepPageAction(subPageStepper + 1));
    } else if (subPageList[subPage].subOrder.length === 0) {
      yield put(changeSubPageAction(subPage + 1));
    } else if (lastSubStepper(subPageList, subPage) === subPageStepper) {
      yield put(changeSubPageAction(subPage + 1));
      yield put(changeSubStepPageAction(subPageStepper + 1));
    } else {
      yield put(changeSubPageAction(subPage + 1));
    }

    history.push('uw-decision');

    yield put(setUWStatusAction(response.data));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    console.log(err);
    yield put(setAppLoadingAction(false));
  } finally {
    yield call(getApplicationDetails, { payload: applicationId });
  }
}

// DOCUMENTS
export function* batchUploadDocument(payload) {
  const { files, party, applicationId, documentRequirement } = payload.payload;
  const batches = [];
  let currentBatch = [];
  let currentBatchSize = 0;
  for(const file of files) {
    if(currentBatchSize + file.size > 23e6) {  // 23 MB and 2 MB (for meta data)
      batches.push(currentBatch);
      currentBatch = [];
      currentBatchSize = 0;
    }
    currentBatch.push(file);
    currentBatchSize += file.size;
  }
  if(currentBatch.length > 0) {
    batches.push(currentBatch);
  }

  for (const batch of batches) {
    try {
      yield call(uploadFileDocuments,{
        payload: {
          files: batch,
          party,
          applicationId,
          documentRequirement
        }
      })
    } catch (error) {
      console.log('Batch upload', error)
    }
  }
}

export function* uploadFileDocuments(payload) {
  const { files, party, applicationId, documentRequirement } = payload.payload;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v1/b2b/file`;
  // formData logic
  const formData = new FormData();
  const fileData = [];

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    // get Size, Tag for File
    fileData.push({ size: file.size, tag: file.tag, localName: file.name });
    formData.append('file', file, file.name);
  }

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: formData,
  };

  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);

    yield call(addDocument, {
      payload: {
        files: response.data,
        applicationId,
        party,
        fileData,
        documentRequirement,
      },
    });
  } catch (err) {
    console.log(err);
    const errorMessage = yield call([err.response, 'text']);
    yield put(setOtherDocumentStatusAction({
      type: fileData.length > 0 ? fileData[0].tag :'GEN',
      message: errorMessage,
      files: fileData
    }))
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* addDocument(payload) {
  const auth = yield select(makeSelectAuth());
  const {
    files,
    party,
    applicationId,
    fileData,
    documentRequirement,
  } = payload.payload;
  const mappedFiles = map(files, (f, index) => ({
    originalFileName: f.original,
    fileName: f.fd,
    fileSize: fileData[index].size,
    type: documentRequirement.type,
    tag: fileData[index].tag,
  }));
  const bodyPayload = {
    applicationId,
    partyId: party.id,
    files: mappedFiles,
  };

  const endpoint = `${api.host}/api/v${api.version}/b2b/document`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(bodyPayload),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    yield call(getApplicationDetails, { payload: applicationId });
  } catch (err) {
    console.log(err);
  }
}

export function* requestSigns(payload) {
  const auth = yield select(makeSelectAuth());
  const { applicationId, parties, status } = payload.payload;
  const arrayEmail = [];
  for (const item in parties.partyDetails) {
    arrayEmail.push(parties.partyDetails[item]);
  }
  let data = {};

  const partyDetail = yield all(
    arrayEmail.map(item => {
      if (status) {
        data = {
          party: {
            id: item.id,
            email: item.email,
            additionalDetails: {
              mailshot: true,
            },
          },
          applicationId,
        };
      } else {
        data = {
          party: {
            id: item.id,
            email: item.email,
            additionalDetails: {
              mailshot: false,
            },
          },
          applicationId,
        };
      }
      return call(updateApplicationParty, data);
    }),
  );

  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/request-signs`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield call(getApplicationEnvelop, { payload: { applicationId } });
      yield call(getApplicationDetails, { payload: applicationId });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* refreshApplicationEnvelop(payload) {
  const auth = yield select(makeSelectAuth());
  const { applicationId } = payload.payload;

  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/envelop/refresh`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield call(getApplicationDetails, { payload: applicationId });
      // yield put(setRequestSignsAction(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getApplicationEnvelop(payload) {
  const auth = yield select(makeSelectAuth());
  const { applicationId } = payload.payload;

  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/envelop`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setRequestSignsAction(response));
    }
  } catch (err) {
    console.log(err);
  }
}
export function* generateCCEmailSaga(action) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const {
    applicationId,
    email,
    preferredDateOfCollection,
    cardCurrency,
  } = action.payload;
  const selectedAgency = sessionStorage.getItem('agencySelected');
  const preferredCCPaymentLink = "Y";
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/payment/link`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify({
      email,
    }),
  };

  try {
    // flow for nexus and FAB agencies
     // if (isNexusAgency(selectedAgency)
      // || isFABAgency(selectedAgency)) { // for nexus and FAB
      if (isNexusAgency(selectedAgency)) { // for nexus
            yield call(submitPaymentAction, {
              payload: {
                applicationId,
                paymentDetail: { preferredDateOfCollection, cardCurrency, email, preferredCCPaymentLink},
              },
            });
        } else {
            yield call(submitPaymentAction, {
              payload: {
                applicationId,
                paymentDetail: { preferredDateOfCollection, cardCurrency, preferredCCPaymentLink },
              },
            });
            yield call(request, endpoint, requestOpt);
    }
    yield call(getApplicationDetails, { payload: applicationId });
  } catch (err) {
    console.log('err', err.response);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* deleteDocument(payload) {
  const auth = yield select(makeSelectAuth());
  const { document, party, applicationId } = payload.payload;
  const ids = [];
  if (document.length) {
    document.map(doc => ids.push(doc.id));
  } else {
    ids.push(document.id);
  }

  const endpoint = `${api.host}/api/v${api.version}/b2b/document/delete-documents`;
  const requestOpt = {
    method: 'DELETE',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify({
      applicationId,
      partyId: party.id,
      ids,
    }),
  };
  try {
    yield put(setAppLoadingAction(true));
    const response = yield call(request, endpoint, requestOpt);
    yield call(getApplicationDetails, { payload: applicationId });
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* getIDVStatus() {
  try {
    const endpoint = `${api.host}/api/v${api.version}/b2b/id-verification/get-status`;
    const requestOpt = {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': api.subKey,
        Authorization: `Bearer ${auth.okta.accessToken}`,
      },
      body: JSON.stringify({ applicationId, partyId: party.id }),
    };

    request(endpoint, requestOpt)
      .then()
      .catch(err => {
        err.response.json().then(data => {
          if (data.message === 'IDV_TOO_MANY_RETRY_FOR_THIS_PARTY') {
            setExceedRetry(true);
            setDialogShuftiPro({
              show: true,
              message:
                'Document originality cannot be verified via ShuftiPro. Please proceed to "Upload" option',
            });
          }
        });
      });
  } catch (err) {}
}

export function* createIDVSession(payload) {
  const { applicationId, partyId, isSendMail, updatedEmail } = payload.payload;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/id-verification/create-idv-session`;
  const countIdvSubmit = yield select(makeSelectCountIdvSubmit());
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify({ applicationId, partyId, isSendMail }),
  };

  if (updatedEmail) {
    yield call(updateApplicationParty, {
      party: { id: partyId, email: updatedEmail },
      applicationId,
    });
  }

  try {
    const response = yield call(request, endpoint, requestOpt);

    if (response.data) {
      // opens a window to shuftipro
      let shuftiWindow = null;
      if (!isSendMail) {
        shuftiWindow = window.open(
          `/id-verification?id=${response.data.session}`,
          'shuftipro',
          'height=490,width=648',
        );

        while (!shuftiWindow.closed) {
          yield delay(1000);
        }
      }
      yield put(setCountIdvSubmit(countIdvSubmit + 1));
      yield call(getApplicationDetails, {
        payload: applicationId,
      });
    }
  } catch (err) {
    console.log(err);
    const errorMessage = yield err.response.json();
    yield put(setDocumentStatusAction(errorMessage));
  }
}

export function* submitPaymentAction(action) {
  const { applicationId, paymentDetail, isInforce } = action.payload;
  const paymentType = yield select(makeSelectPaymentType());
  const auth = yield select(makeSelectAuth());
  const selectedAgency = sessionStorage.getItem('agencySelected');

  try {
     // if (isNexusAgency(selectedAgency)
      // || isFABAgency(selectedAgency)) { // for nexus and FAB
      if (isNexusAgency(selectedAgency)) { // for nexus
          const endpoint1 = `${api.host}/api/v${api.version}/b2b/application`;
          const requestOpt1 = {
            method: 'PUT',
            headers: {
              'Ocp-Apim-Subscription-Key': api.subKey,
              Authorization: `Bearer ${auth.okta.accessToken}`,
            },
            body: JSON.stringify({
              id : applicationId,
              nexusOrFabApplication : true,
              paymentDetails: paymentDetail,
              paymentMethod: !isEmpty(paymentType)
                ? paymentType
                : 'INFORCE_EXISTING_PAYMENT',
            }),
          };
          const response = yield call(request, endpoint1, requestOpt1);
          console.log("submitPaymentAction :: isInforce :: ", isInforce);
          console.log("submitPaymentAction :: paymentDetail.isIFINewPayment : ",paymentDetail.isIFINewPayment);
         let executeHoldAppSubmission = false;
         //For WAP and SW Inforce
         if(isEmpty(paymentType) && isInforce == true) {
          executeHoldAppSubmission = true;
         }
          if ((!isEmpty(paymentType) && paymentType != 'CREDIT_CARD') || ((!isEmpty(paymentType) && paymentType == 'CREDIT_CARD') && (paymentDetail && paymentDetail.preferredCCPaymentLink == "Y" || isInforce == true ))) {
            //Update status in applications table as ADMIN_ACTION_PENDING if MOP is other than credit card OR if MOP is credit card and payment requested through direct link
            executeHoldAppSubmission = true;
          }
          if(executeHoldAppSubmission) {
            const holdAppEndpoint = `${api.host}/api/v${api.version}/b2b/application/hold-application-submission`;
              const holdAppRequestOpt = {
                method: 'POST',
                headers: {
                  'Ocp-Apim-Subscription-Key': api.subKey,
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${auth.okta.accessToken}`,
                },
                body: JSON.stringify({
                  applicationId: applicationId
                }),
              };
              const holdAppResponse = yield call(request, holdAppEndpoint, holdAppRequestOpt);
              if (response && holdAppResponse) {
                yield put(
                  setPaymentDataAction({
                    status: 'intermediate',
                  }),
                );
              }
          }
        } else {
          const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/payment`;
          const requestOpt = {
            method: 'POST',
            headers: {
              'Ocp-Apim-Subscription-Key': api.subKey,
              Authorization: `Bearer ${auth.okta.accessToken}`,
            },
            body: JSON.stringify({
              paymentDetails: paymentDetail,
              paymentMethod: !isEmpty(paymentType)
                ? paymentType
                : 'INFORCE_EXISTING_PAYMENT',
            }),
          };
          const response = yield call(request, endpoint, requestOpt);
          yield put(
            setPaymentDataAction({
              status: response.message,
            }),
          );

          if (response.data) {
            // opens a window to shuftipro
          }
        }
  } catch (err) {
    console.log(err);
  }
}

export function* getDoctors({ sessionId, partyId }) {
  const auth = yield select(makeSelectAuth());

  const endpoint = `${api.host}/api/v${api.version}/b2b/uw/sessions/${sessionId}/doctors`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };
  try {
    const response = yield call(request, endpoint, requestOpt);
    yield put(setDoctorListAction(response.data.doctors));

    yield put(
      setETag({
        eTag: response.data.eTag,
        partyId,
      }),
    );
  } catch (err) {
    console.log(err);
  }
}

export function* addDoctor(payload) {
  const { partyId, sessionId, data } = payload;

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/uw/sessions/${sessionId}/doctors`;

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify({ ...data }),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    if (response.data) {
      // get the updated list of doctors by sessionId

      yield put(
        setETag({
          eTag: response.data.eTag,
          partyId,
        }),
      );

      yield call(getDoctors, { sessionId, partyId });
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getConvertCurrency(payload) {
  const { scrCurrency, destCurrency, amount } = payload.payload;

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v1/b2b/application/convert-currency?srcCurrency=${scrCurrency}&destCurrency=${destCurrency}&amount=${amount}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setConvertCurrencyAction(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getCurrency(payload) {
  const { scrCurrency, destCurrency, amount } = payload.payload;

  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v1/b2b/application/convert-currency?srcCurrency=${scrCurrency}&destCurrency=${destCurrency}&amount=${amount}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setChangeCurrencyAction(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* getValidFunds(payload) {
  yield put(setAppLoadingAction(true));
  const { id } = payload.payload;
  const { searchText } = payload.payload;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${id}/valid-funds?searchText=${searchText}`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    yield put(setAppLoadingAction(false));
    if (response.data) {
      yield put(setValidFundsAction(response.data));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* updateApplicationFund(payload) {
  yield put(setAppLoadingAction(true));
  const { applicationId, filteredFundList, fundList } = payload.payload;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/${applicationId}/funds`;
  let data = {} 
  if(filteredFundList){
    data['funds'] = filteredFundList;
  } else {
    data['funds'] = {FundList: [...fundList] };
  }
  const requestOpt = {
    method: 'PUT',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(data),
  };

  try {
    yield call(request, endpoint, requestOpt);
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* checkNexusDevereFunds(payload) {
  yield put(setAppLoadingAction(true));
  const { filteredFundList, applicationId } = payload.payload;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/check-nexus-devere-funds`;
  const data = {
    applicationId,
    funds: filteredFundList,
  };
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(data),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    yield put(
      setFundsBelongToNexusOrDever(response?.data?.fundsBelongToNexusOrDevere),
    );
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* generateApplicationPdf(payload) {
  yield put(setAppLoadingAction(true));
  const { id } = payload.payload;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/generate-application-form-pdf`;
  const data = { id };
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(data),
  };

  try {
    yield call(request, endpoint, requestOpt);
  } catch (err) {
    console.log(err);
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* submitCreditCardAction(action) {
  console.log("submitCreditCardAction ::  action ",action);
  const {
    cardCurrency,
    card_number,
    card_holder_name,
    card_security_code,
    expiry_date,
    preferredDateOfCollection,
  } = action.payload.paymentDetails;
  const { applicationId } = action.payload;
  const payload = {
    payload: {
      applicationId,
      paymentDetail: {
        // card_number,
        cardCurrency,
        // card_holder_name,
        // card_security_code,
        // expiry_date,
        preferredDateOfCollection,
      },
    },
  };
  yield call(submitPaymentAction, payload);
  yield put(setAppLoadingAction(true));
  try {
    const cardNumber = card_number;
    const tokenArr = [
      cardNumber.slice(0, 6),
      cardNumber.slice(6, cardNumber.length - 4),
      cardNumber.slice(cardNumber.length - 4, cardNumber.length),
    ];
    // generate random numbers for each of the 2nd array char
    const randomizer = tokenArr[1].split('');
    let newRandom = '';
    randomizer.forEach(() => {
      newRandom = newRandom.concat(`${Math.floor(Math.random() * 10)}`);
    });
    tokenArr[1] = newRandom;
    const generatedTokenName = tokenArr.join('');
    // get signature
    /* eslint-disable camelcase */
    const nonceReference = voucherCodeGenerator.generate({
      length: 4,
      count: 1,
    })[0];

    const payfortSignatureVals = {
      service_command: 'TOKENIZATION',
      access_code: payfort.access_code,
      merchant_identifier: payfort.merchant_identifier,
      merchant_reference: `${applicationId}${nonceReference}`,
      language: 'en',
      // GET
      // return_url: `${window.location.origin}/applications/${applicationId}/form/payment-callback`,
      // POST
      return_url: `${payfort.return_url}?redirect_url=${window.location.origin}/applications/${applicationId}/form/payment-callback`,
      token_name: generatedTokenName,
    };

    const sortedReqBodyKeys = sortBy(Object.keys(payfortSignatureVals), [
      val => val,
    ]);
    let shaString = '';
    sortedReqBodyKeys.forEach(k => {
      shaString = shaString.concat(`${k}=${payfortSignatureVals[k]}`);
    });
    shaString = `${payfort.sha_req_passphrase}${shaString}${payfort.sha_req_passphrase}`;

    const generatedSignature = sha256(shaString);

    const payfortTempForm = document.createElement('form');
    payfortTempForm.method = 'POST';
    payfortTempForm.action = `${payfort.host}/FortAPI/paymentPage`;
    payfortTempForm.id = 'simulatorForm';

    const payfortFormFields = [
      'card_holder_name',
      'card_number',
      'expiry_date',
      'card_security_code',
      'service_command',
      'access_code',
      'merchant_identifier',
      'merchant_reference',
      'language',
      'return_url',
      'signature',
      'token_name',
    ];

    payfortFormFields.forEach(fkey => {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = fkey;
      if (fkey === 'expiry_date') {
        hiddenField.value = moment(action.payload.paymentDetails[fkey]).format(
          'YYMM',
        );
      } else if (fkey === 'token_name') {
        hiddenField.value = generatedTokenName;
      } else if (fkey === 'signature') {
        hiddenField.value = generatedSignature;
      } else if (fkey === 'merchant_reference') {
        hiddenField.value = payfortSignatureVals[fkey];
      } else if (fkey === 'merchant_identifier') {
        hiddenField.value = payfortSignatureVals[fkey];
      } else if (fkey === 'access_code') {
        hiddenField.value = payfortSignatureVals[fkey];
      } else if (fkey === 'service_command') {
        hiddenField.value = payfortSignatureVals[fkey];
      } else if (fkey === 'language') {
        hiddenField.value = payfortSignatureVals[fkey];
      } else if (fkey === 'return_url') {
        hiddenField.value = payfortSignatureVals[fkey];
      } else {
        hiddenField.value = action.payload.paymentDetails[fkey];
      }
      payfortTempForm.appendChild(hiddenField);
    });
    document.body.appendChild(payfortTempForm);
    payfortTempForm.submit();
  } catch (err) {
    // TODO: handle errors
    console.log(err);
    yield put(setAppLoadingAction(false));
  }
}

export function* authorizePayment(payfortResponse, history) {
  yield put(setAppLoadingAction(true));
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/authorisePayment`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
      'x-forwarded-for': '118.189.22.41',
      // 'Content-Length':
      // 'Host': window.location.origin,
    },
    body: JSON.stringify({
      ...payfortResponse.params,
    }),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    if (response.data) {
      const regex3ds = new RegExp(/.{2}(064)$/, 'gi');
      /* eslint-disable camelcase */
      if (
        regex3ds.test(response.data?.response_code) &&
        !!response.data['3ds_url']
      ) {
        // redirect for 3ds
        window.location.replace(response.data['3ds_url']);
      } else if (response.data?.status === '14') {
        // otherwise, if status is payment success (status 14, complete the flow)
        yield put(
          setPaymentDataAction({
            status: 'completed',
          }),
        );
        history.push(`payment-method`);
      } else {
        yield put(
          setPaymentDataAction({
            status: 'declined',
          }),
        );
        yield put(setAppLoadingAction(false));
        history.push(`payment-method`);
        throw new Error('Payment Unsuccessful');
      }
    }
  } catch (err) {
    console.log('err', err);
    yield put(setAppLoadingAction(false));
    // TODO: handle error based on the API errors
    yield put(
      setPaymentDataAction({
        status: 'declined',
      }),
    );
    history.push(`payment-method`);
  }
}

export function* completePayment(payfortResponse, history) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/completePayment`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify({
      ...payfortResponse.params,
    }),
  };
  yield put(updatePaymentType('CREDIT_CARD'));

  try {
    yield call(request, endpoint, requestOpt);
    yield put(
      setPaymentDataAction({
        status: 'completed',
      }),
    );
    history.push(`payment-method`);
  } catch (err) {
    // TODO: handle error based on the API errors
    console.log(err);
    // throw err;
    yield put(
      setPaymentDataAction({
        status: 'declined',
      }),
    );
    history.push(`payment-method`);
  }
}

export function* continuePaymentCallback(action) {
  const { history } = action.payload;
  const selectedAgency = sessionStorage.getItem('agencySelected');
  let applicationId = action.payload.params.merchant_reference;
  applicationId = applicationId.slice(0, -4);
  const inputData = {
    applicationId : applicationId,
  }
  yield put(updatePaymentType('CREDIT_CARD'));
  yield put(setAppLoadingAction(true));
  yield delay(1500);
  try {
    if (action.payload.params.status === '18') {
      history.push(`payment-method`);

      yield call(authorizePayment, action.payload, history);
    } else if (
      action.payload.params.status === '14' ||
      action.payload.params.status === '02'
    ) {
      history.push(`payment-method`);
      //  Hold the final flow for Nexus and FAB agencies
       // if (isNexusAgency(selectedAgency)
      // || isFABAgency(selectedAgency)) { // for nexus and FAB
      if (isNexusAgency(selectedAgency)) { // for nexus
              const auth = yield select(makeSelectAuth());
              const endpoint = `${api.host}/api/v${api.version}/b2b/application/hold-application-submission`;
              const requestOpt = {
                method: 'POST',
                headers: {
                  'Ocp-Apim-Subscription-Key': api.subKey,
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${auth.okta.accessToken}`,
                },
                body: JSON.stringify(inputData),
              };
              try {
                const response = yield call(request, endpoint, requestOpt);
                if (response) {
                    yield put(
                      setPaymentDataAction({
                        status: 'intermediate',
                      }),
                    );
              }
              } catch (err) {
                console.log(err);
              }

      } else {
            return yield call(completePayment, action.payload, history);
      }
    } else {
      yield put(
        setPaymentDataAction({
          status: 'declined',
        }),
      );
      yield put(setAppLoadingAction(false));
      history.push(`payment-method`);
      // return yield call(completePayment, action.payload, history);
    }
  } catch (err) {
    console.log(err);
  }
}

export function* sendPdf(data) {
  const { payload } = data;
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/messaging/pdf-email`;
  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(payload),
  };
  try {
    const response = yield call(request, endpoint, requestOpt);
    yield put(setEmailSentAction(false));
  } catch (err) {
    const response = yield err.response.json();
    if (response?.code === 'TOO_MANY_REQUESTS') {
      yield put(setEmailSentAction(true));
      yield put(setEmailErrorMessage(response.message));
    }
    yield put(setAppLoadingAction(false));
  }
}

export function* getSummaryLink(payload) {
  const auth = yield select(makeSelectAuth());
  const { applicationId, isSummary, accessCode } = payload?.payload;
  const endpoint = `${api.host}/api/v${
    api.version
  }/b2b/application/${applicationId}/policy-documents?accessCode=${accessCode}${
    isSummary ? `&isSummary=true` : ''
  }`;

  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/zip',
      'Content-Disposition': "inline; filename='myfile.zip'",
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response) {
      if (isSummary) {
        yield put(setSummaryLinkAction(URL.createObjectURL(response)));
      } else {
        yield put(setConsentLinkAction(URL.createObjectURL(response)));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

// Individual exports for testing
export default function* applicationFormPageSaga() {
  yield all([
    takeLatest(DELETE_FILE_DOCUMENTS_ACTION, deleteDocument),
    takeLatest(CREATE_IDV_SESSION, createIDVSession),
    takeLatest(ADD_DOCUMENT_ACTION, addDocument),
    takeLatest(CALL_SECTION_QUESTIONS_ACTION, callSectionQuestion),
    takeEvery(ANSWER_QUESTION_ACTION, answerQuestion),
    takeLatest(SEARCH_ANSWER_ACTION, searchAnswer),
    takeLatest(SUBMIT_UNDERWRITING_ACTION, submitUW),
    takeLatest(UPLOAD_FILE_DOCUMENTS_ACTION, batchUploadDocument),
    takeLatest(SUBMIT_PAYMENT_ACTION, submitPaymentAction),
    takeLatest(SUBMIT_CREDIT_CARD_ACTION, submitCreditCardAction),
    takeEvery(CONTINUE_PAYMENT_CALLBACK_ACTION, continuePaymentCallback),
    takeLatest(GET_CONVERT_CURRENCY_ACTION, getConvertCurrency),
    takeLatest(GET_CHANGE_CURRENCY_ACTION, getCurrency),
    takeLatest(ADD_DOCTOR_ACTION, addDoctor),
    takeLatest(GET_DOCTORS_ACTION, getDoctors),
    takeLatest(GET_VALID_FUNDS_ACTION, getValidFunds),
    takeLatest(REQUEST_SIGNS_ACTION, requestSigns),
    takeLatest(GET_APPLICATION_ENVELOP_ACTION, getApplicationEnvelop),
    takeLatest(REFRESH_APPLICATION_ENVELOP_ACTION, refreshApplicationEnvelop),
    takeLatest(SEND_PDF_ACTION, sendPdf),
    takeLatest(UPDATE_APPLICATION_FUND, updateApplicationFund),
    takeLatest(CHECK_NEXUS_DEVERE_FUNDS, checkNexusDevereFunds),
    takeLatest(GENERATE_APPLICATION_PDF, generateApplicationPdf),
    takeLatest(GENERATE_CREDIT_CARD_EMAIL, generateCCEmailSaga),
  ]);
  // See example in containers/HomePage/saga.js
}

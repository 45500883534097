/*
 *
 * DashboardPage constants
 *
 */

export const SUB_PAGE_CHANGE_ACTION =
  'app/DashboardPage/SUB_PAGE_CHANGE_ACTION';
export const SUB_PAGE_STEPPER_CHANGE_ACTION =
  'app/DashboardPage/SUB_STEP_PAGE_CHANGE_ACTION';
export const SUB_PAGE_LIST_CHANGE_ACTION =
  'app/DashboardPage/SUB_PAGE_LIST_CHANGE_ACTION';
export const EXIT_ACTION = 'app/DashboardPage/EXIT_ACTION';

export const LOGOUT_ACTION = 'app/DashboardPage/LOGOUT_ACTION';


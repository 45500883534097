/**
 *
 * GcIllustrationPage
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash/object';
import { FormattedMessage } from 'react-intl';
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import globalMessages from 'containers/App/messages';
import { Grid, Typography, Button } from '@material-ui/core';
import FormHeader from 'components/FormHeader';
import FormFooter from 'components/FormFooter';
import {
  makeSelectSubPage,
  makeSelectSubPageList,
} from 'containers/DashboardPage/selectors';
import {
  changeSubPageAction,
  changeSubPageListAction,
} from 'containers/DashboardPage/actions';
import CoverageForm from './components/CoverageForm';
import QuoteForm from './components/QuoteForm';
import Review from './components/Review';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectError,
  makeSelectConfig,
  makeSelectStatus,
  makeSelectApplicationId,
  makeSelectQuoteId,
  makeSelectFiaCharge,
  makeSelectInitialLifeAssured,
  makeSelectBiTableValues,
  makeSelectCloneStatus,
  makeSelectFormInput,
} from './selectors';
import {
  ChangeLifeAssuredAction,
  ChangeCoverageAction,
  createQuoteAction,
  calculateQuoteAction,
  setErrorAction,
  SetCoveragesListAction,
  updateStatusAction,
  resetAction,
  createApplicationAction,
  setGcConfigAction,
  getQuoteAction,
  setQuoteAction,
  getFiaAction,
  setPtvVersionAction,
  cloneQuoteAction,
  setCloneStatusAction,
  setProductCodeAction,
} from './actions';
import LifeAssuredForm from './components/LifeAssuredForm';
import { submit } from 'redux-form';
import {
  makeSelectOption,
  makeSelectAgentDetails,
} from 'containers/App/selectors';
import { getCoverages } from './helper';
import FormMainBody from 'components/FormMainBody';
import { isEmpty, find, isEqual } from 'lodash';
import { usePrevious } from 'utils/hooks';
import LoadingDialog from 'components/LoadingDialog';
import Dialog from 'components/Dialog';
import NoticeBox from 'components/NoticeBox';
import { makeSelectProductConfig as makeSelectProductConfig2 } from 'containers/IllustrationPage/selectors';

const subPageListGC = [
  { order: 1, text: 'Life Insured', subOrder: [] },
  { order: 2, text: 'Coverage', subOrder: [] },
  { order: 3, text: 'Quote', subOrder: [] },
  { order: 4, text: 'Review', subOrder: [] },
];

export function GcIllustrationPage({
  quoteId,
  saveQuote,
  calculateQuote,
  setCoverage,
  gcConfig,
  setGcConfig,
  subPage,
  subPageList,
  changeSubPage,
  changeSubPageList,
  lifeAssured,
  initialLifeAssured,
  changeLifeAssured,
  productConfig,
  biTableValues,
  changeCoverage,
  submitLifeAssured,
  submitCoverage,
  submitQuote,
  options,
  updateStatus,
  errorData,
  applicationId,
  status,
  createApplication,
  setPtvVersion,
  setError,
  resetData,
  agentDetails,
  getQuote,
  setQuoteId,
  fiaData,
  callFia,
  cloneQuote,
  cloneStatus,
  changeCloneStatus,
  setProductCode,
  productConfig2,
  input,
}) {
  useInjectReducer({ key: 'gcIllustrationPage', reducer });
  useInjectSaga({ key: 'gcIllustrationPage', saga });

  const history = useHistory();
  const location = useLocation();

  const [dialog, setDialog] = useState({
    open: false,
    message: null,
  });

  const [product, setProduct] = useState({});
  const [isLifeAssuredEqual, setLifeAssuredEqual] = useState(false);

  useEffect(() => {
    if (applicationId) {
      history.replace(`/applications/${applicationId}`);
    }
  }, [applicationId]);

  useEffect(() => {
    if (cloneStatus) {
      history.replace({
        pathname: '/illustrations/gc',
        state: { gcConfig, quoteId, type: 2 },
      });
      getQuote(2);
      changeCloneStatus(false);
    }
  }, [cloneStatus]);

  useEffect(() => {
    if (status === 'AGREED') {
      createApplication();
    }

    if ((status === 'SAVED' || status === 'PREVIEW') && !isEmpty(quoteId)) {
      history.replace('/illustrations/gc/review', location.state);
    }
  }, [status]);

  const prevBiTableValues = usePrevious(biTableValues);

  useEffect(() => {
    if (
      !isEmpty(biTableValues) &&
      !isEqual(
        prevBiTableValues.illustrationTableDict,
        biTableValues.illustrationTableDict,
      )
    ) {
      return history.replace('/illustrations/gc/quote', location.state);
    }
  }, [biTableValues.illustrationValueDict]);

  const prevGcConfig = usePrevious(gcConfig);

  useEffect(() => {
    if (isEmpty(prevGcConfig) && !isEmpty(gcConfig))
      setCoverage(getCoverages(gcConfig.features));
  }, [gcConfig]);

  // set the productConfig aka gcConfig
  useEffect(() => {
    setGcConfig(location.state.gcConfig);
    if (location.state.gcConfig.features.haveFiaCharge) callFia();
    if (location.state.quoteId) {
      setQuoteId(location.state.quoteId);
      getQuote(location.state.type);
    }
    // set the sub pages list for sidebar
    changeSubPageList(subPageListGC);
    return () => {
      changeSubPage(0);
      changeSubPageList([]);
      resetData();
    };
  }, []);

  // set the product details from agent data
  useEffect(() => {
    if (gcConfig.productCode) {
      const productDetails = find(
        agentDetails.products,
        p => p.productId === gcConfig.productCode,
      );
      setPtvVersion(productDetails?.PTVVersion);
      setProductCode(productDetails?.PTVCode);
      setProduct(productDetails);
    }
  }, [gcConfig.productCode]);

  useEffect(() => {
    // set active subPage
    let activeSubPage = subPage;
    switch (location.pathname) {
      case '/illustrations/gc/insured':
        activeSubPage = 1;
        break;
      case '/illustrations/gc/coverage':
        activeSubPage = 2;
        break;
      case '/illustrations/gc/quote':
        activeSubPage = 3;
        break;
      case '/illustrations/gc/review':
        activeSubPage = 4;
      default:
        break;
    }
    changeSubPage(activeSubPage);
  }, [location.pathname]);

  useEffect(() => {
    if (errorData.error) window.scrollTo(0, 0);
  }, [errorData]);

  useEffect(() => {
    if (!isEmpty(location.state.customerDetail)) {
      changeLifeAssured({
        ...lifeAssured,
        insuredOne: location.state.customerDetail,
      });
    }
  }, []);

  const getTitle = () => {
    switch (location.pathname) {
      case '/illustrations/gc/insured':
        return globalMessages.lifeAssuredTitle;
      case '/illustrations/gc/coverage':
        return globalMessages.coverageTitle;
      case '/illustrations/gc/quote':
        return globalMessages.quoteTitle;
      case '/illustrations/gc/review':
        return globalMessages.reviewTitle;
      default:
        return globalMessages.lifeAssuredTitle;
    }
  };

  const getFooterLabel3 = () => {
    switch (location.pathname) {
      case '/illustrations/gc/quote':
        return globalMessages.saveQuote;
      case '/illustrations/gc/review':
        return globalMessages.agreeAndProceed;
      default:
        return globalMessages.next;
    }
  };

  if (isEmpty(product) || isEmpty(gcConfig)) {
    return <LoadingDialog isLoading />;
  }

  return (
    <>
      <FormHeader
        title={<FormattedMessage {...getTitle()} />}
        productName={gcConfig.shortName}
      />

      {errorData.error ? (
        <Grid container style={{ paddingLeft: '40px' }}>
          <Grid item xs={7}>
            <NoticeBox variant="error" mb={4}>
              {errorData.message.map(error => {
                return (
                  <Typography id={error.message} variant="body2" color="error">
                    {error.message}
                  </Typography>
                );
              })}
            </NoticeBox>
          </Grid>
        </Grid>
      ) : null}

      <FormMainBody>
        <Grid item xs={12} md={10} lg={7} xl={6}>
          <Switch>
            <Route
              path="/illustrations/gc/insured"
              render={() => (
                <LifeAssuredForm
                  initialValues={lifeAssured}
                  disabled={!!quoteId}
                  location={location}
                  options={options}
                  onSubmit={immutableValues => {
                    const currentLifeAssured = immutableValues.toJS();
                    let isLaOneEqual = false;
                    let isLaTwoEqual = false;
                    let isInsuredTwoEqual = false;
                    let isPoEqual = false;
                    let isPoValueEqual = false;

                    if (
                      isEqual(
                        initialLifeAssured.insuredOne,
                        currentLifeAssured.insuredOne,
                      )
                    )
                      isLaOneEqual = true;

                    if (
                      isEqual(
                        initialLifeAssured.insuredTwo,
                        currentLifeAssured.insuredTwo,
                      )
                    )
                      isLaTwoEqual = true;

                    if (
                      initialLifeAssured.isInsuredTwo ===
                      currentLifeAssured.isInsuredTwo
                    )
                      isInsuredTwoEqual = true;

                    if (
                      initialLifeAssured.isPolicyOwnerIndividual ===
                      currentLifeAssured.isPolicyOwnerIndividual
                    )
                      isPoEqual = true;

                    if (
                      isEqual(
                        initialLifeAssured.policyOwnerIndividual,
                        currentLifeAssured.policyOwnerIndividual,
                      )
                    )
                      isPoValueEqual = true;

                    if (
                      !initialLifeAssured.isInsuredTwo &&
                      !currentLifeAssured.isInsuredTwo
                    )
                      isLaTwoEqual = true;

                    if (
                      !initialLifeAssured.isPolicyOwnerIndividual &&
                      !currentLifeAssured.isPolicyOwnerIndividual
                    )
                      isPoValueEqual = true;

                    if (
                      isLaOneEqual &&
                      isLaTwoEqual &&
                      isInsuredTwoEqual &&
                      isPoEqual &&
                      isPoValueEqual
                    ) {
                      setLifeAssuredEqual(true);
                    }

                    changeLifeAssured(immutableValues.toJS());
                    return history.replace(
                      '/illustrations/gc/coverage',
                      location.state,
                    );
                  }}
                />
              )}
            />

            <Route
              path="/illustrations/gc/coverage"
              render={routeProps => (
                <CoverageForm
                  initialValues={{
                    ...productConfig,
                    shortName: productConfig2.shortName,
                    FMC: get(
                      find(productConfig2.features?.FMC, f => {
                        return f.region === agentDetails.ISOCountryCode;
                      }),
                      'FMCrate',
                      '',
                    ),
                  }}
                  location={location}
                  agentDetails={agentDetails}
                  lifeAssured={lifeAssured}
                  options={options}
                  product={product}
                  gcConfig={gcConfig}
                  fiaData={fiaData}
                  onSubmit={immutableValues => {
                    const data = immutableValues.toJS();
                    changeCoverage(
                      data,
                      true,
                      isLifeAssuredEqual,
                      data.isGetPremium,
                    );
                  }}
                  {...routeProps}
                />
              )}
            />

            <Route
              path="/illustrations/gc/quote"
              render={() => (
                <QuoteForm
                  input={input}
                  initialValues={biTableValues}
                  location={location}
                  lifeAssured={lifeAssured}
                  options={options}
                  productConfig={productConfig}
                  gcConfig={gcConfig}
                  onUpdateValues={newValues => {
                    changeCoverage(newValues, true, isLifeAssuredEqual);
                  }}
                  onSubmit={() => {
                    saveQuote(isLifeAssuredEqual);
                    return setDialog({
                      open: true,
                      message: 'ADDED',
                    });
                  }}
                />
              )}
            />

            <Route
              path="/illustrations/gc/review"
              render={() => (
                <Review
                  disableProceed={
                    location.pathname === '/illustrations/gc/review' &&
                    status != 'PREVIEW'
                  }
                  location={location}
                  onClickBackToClientList={() =>
                    setDialog({ open: true, message: 'EXIT' })
                  }
                />
              )}
            />
            <Route
              path="/"
              render={() => (
                <Redirect
                  to={{
                    pathname:
                      isEmpty(quoteId) || location.state.type === 2
                        ? '/illustrations/gc/insured'
                        : '/illustrations/gc/review',
                    state: location.state,
                  }}
                />
              )}
            />
          </Switch>
        </Grid>
      </FormMainBody>
      <FormFooter
        onClickPrevious={() => {
          setError({ error: false, message: [] });
          switch (location.pathname) {
            case '/illustrations/gc/insured':
              history.replace('/illustrations');
              break;
            case '/illustrations/gc/coverage':
              history.replace('/illustrations/gc/insured', location.state);
              break;
            case '/illustrations/gc/quote':
              history.replace('/illustrations/gc/coverage', location.state);
              break;
            default:
              break;
          }
          return changeSubPage(subPage - 1);
        }}
        onClickExit={() => {
          return setDialog({
            open: true,
            message: 'EXIT',
          });
        }}
        onClickNext={() => {
          setError({ error: false, message: [] });
          switch (location.pathname) {
            case '/illustrations/gc/insured':
              submitLifeAssured();
              break;
            case '/illustrations/gc/coverage':
              submitCoverage();
              break;
            case '/illustrations/gc/quote':
              submitQuote();
              break;
            case '/illustrations/gc/review':
              return updateStatus(2);
            default:
              break;
          }
        }}
        disabledPrevious={
          location.pathname === '/illustrations/gc/insured' ||
          location.pathname === '/illustrations/gc/review'
        }
        disabledNext={
          location.pathname === '/illustrations/gc/review' &&
          status != 'PREVIEW'
          //location.pathname === '/illustrations/gc/review'
        }
        label1={<FormattedMessage {...globalMessages.previous} />}
        label2={<FormattedMessage {...globalMessages.exit} />}
        label3={<FormattedMessage {...getFooterLabel3()} />}
      />

      <Dialog
        open={dialog.open}
        onClose={() =>
          setDialog({
            ...dialog,
            open: false,
          })
        }
        onExited={() =>
          setDialog({
            ...dialog,
            message: null,
          })
        }
        variant={dialog.message === 'EXIT' ? 'error' : 'success'}
        title={
          <FormattedMessage
            {...(dialog.message === 'EXIT'
              ? globalMessages.warning
              : messages.added)}
          />
        }
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            {dialog.message === 'ADDED' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setDialog({
                      open: false,
                      message: null,
                    });
                    changeSubPage(0);
                    history.replace('/illustrations');
                  }}
                >
                  {<FormattedMessage {...messages.clientList} />}
                </Button>
              </Grid>
            )}

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  if (dialog.message === 'ADDED') {
                    cloneQuote(2);
                  } else if (dialog.message === 'EXIT') {
                    changeSubPage(0);
                    history.replace('/illustrations');
                  }
                }}
              >
                <FormattedMessage
                  {...(dialog.message === 'ADDED'
                    ? messages.createAnother
                    : globalMessages.exit)}
                />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  if (dialog.message === 'ADDED' && !isEmpty(quoteId)) {
                    history.replace('/illustrations/gc/review', location.state);
                    // history.replace('/illustrations/gc/review');
                  }
                }}
              >
                {<FormattedMessage {...messages.continue} />}
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage
            {...(dialog.message === 'EXIT'
              ? messages.exitQuoteNotice
              : messages.addedInfo)}
          />
        </Typography>
      </Dialog>
    </>
  );
}

GcIllustrationPage.propTypes = {
  quoteId: PropTypes.string,
  lifeAssured: PropTypes.object,
  initialLifeAssured: PropTypes.object,
  applicationId: PropTypes.string,
  productConfig: PropTypes.object,
  biTableValues: PropTypes.object,
  setCoverage: PropTypes.func,
  gcConfig: PropTypes.object,
  getQuote: PropTypes.func,
  setQuoteId: PropTypes.func,
  saveQuote: PropTypes.func,
  calculateQuote: PropTypes.func,
  subPage: PropTypes.number,
  changeSubPage: PropTypes.func,
  updateStatus: PropTypes.func,
  subPageList: PropTypes.array,
  changeSubPageList: PropTypes.func,
  changeLifeAssured: PropTypes.func,
  changeCoverage: PropTypes.func,
  submitLifeAssured: PropTypes.func,
  submitCoverage: PropTypes.func,
  submitQuote: PropTypes.func,
  options: PropTypes.object,
  errorData: PropTypes.object,
  setError: PropTypes.func,
  status: PropTypes.string,
  resetData: PropTypes.func,
  createApplication: PropTypes.func,
  agentDetails: PropTypes.object,
  callFia: PropTypes.func,
  setPtvVersion: PropTypes.func,
  fiaData: PropTypes.object,
  cloneQuote: PropTypes.func,
  cloneStatus: PropTypes.bool,
  changeCloneStatus: PropTypes.func,
  setProductCode: PropTypes.func,
  productConfig2: PropTypes.object,
  input: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  subPage: makeSelectSubPage(),
  fiaData: makeSelectFiaCharge(),
  gcConfig: makeSelectConfig(),
  subPageList: makeSelectSubPageList(),
  lifeAssured: makeSelectLifeAssured(),
  initialLifeAssured: makeSelectInitialLifeAssured(),
  productConfig: makeSelectProductConfig(),
  biTableValues: makeSelectBiTableValues(),
  options: makeSelectOption(),
  errorData: makeSelectError(),
  status: makeSelectStatus(),
  applicationId: makeSelectApplicationId(),
  agentDetails: makeSelectAgentDetails(),
  quoteId: makeSelectQuoteId(),
  cloneStatus: makeSelectCloneStatus(),
  productConfig2: makeSelectProductConfig2(),
  input: makeSelectFormInput(),
});

function mapDispatchToProps(dispatch) {
  return {
    saveQuote: value => dispatch(createQuoteAction(value)),
    setGcConfig: value => dispatch(setGcConfigAction(value)),
    setCoverage: value => dispatch(SetCoveragesListAction(value)),
    calculateQuote: value => dispatch(calculateQuoteAction(value)),
    changeSubPage: value => dispatch(changeSubPageAction(value)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    changeLifeAssured: value => dispatch(ChangeLifeAssuredAction(value)),
    getQuote: value => dispatch(getQuoteAction(value)),
    setQuoteId: value => dispatch(setQuoteAction(value)),
    setPtvVersion: value => dispatch(setPtvVersionAction(value)),
    cloneQuote: value => dispatch(cloneQuoteAction(value)),
    changeCloneStatus: value => dispatch(setCloneStatusAction(value)),
    changeCoverage: (
      value,
      reacalculateQuote = false,
      isLifeAssuredEqual,
      isGetPremium = false,
    ) =>
      dispatch(
        ChangeCoverageAction(
          value,
          reacalculateQuote,
          isLifeAssuredEqual,
          isGetPremium,
        ),
      ),
    updateStatus: val => dispatch(updateStatusAction(val)),
    resetData: () => dispatch(resetAction()),
    callFia: () => dispatch(getFiaAction()),
    setError: value => dispatch(setErrorAction(value)),
    createApplication: value => dispatch(createApplicationAction(value)),
    setProductCode: value => dispatch(setProductCodeAction(value)),
    submitLifeAssured: () => dispatch(submit('lifeAssuredForm')),
    submitCoverage: () => dispatch(submit('coverageForm')),
    submitQuote: () => dispatch(submit('quoteForm')),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(GcIllustrationPage);

/**
 *
 * GcIllustrationPage
 *
 */

import { Button, Grid, Typography } from '@material-ui/core';
import Dialog from 'components/Dialog';
import FormFooter from 'components/FormFooter';
import FormHeader from 'components/FormHeader';
import FormMainBody from 'components/FormMainBody';
import LoadingDialog from 'components/LoadingDialog';
import NoticeBox from 'components/NoticeBox';
import { get } from 'lodash/object';
import globalMessages from 'containers/App/messages';
import { setAppLoadingAction } from 'containers/App/actions';
import {
  makeSelectAgentDetails,
  makeSelectOption,
} from 'containers/App/selectors';
import {
  changeSubPageAction,
  changeSubPageListAction,
} from 'containers/DashboardPage/actions';
import {
  makeSelectSubPage,
  makeSelectSubPageList,
} from 'containers/DashboardPage/selectors';
import { getProductConfigAction } from 'containers/IllustrationPage/actions';
import {
  makeSelectProductConfig2,
  makeSelectProductConfig as makeSelectProductConfigESP,
} from 'containers/IllustrationPage/selectors';
import BenefitRatingForm from './components/BenefitRatingForm';
import { productCodeCheck } from 'helpers/constants';
import { find, isEmpty, isEqual } from 'lodash';
import { map } from 'lodash/collection';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { compose } from 'redux';
import { submit } from 'redux-form';
import { createStructuredSelector } from 'reselect';
import { usePrevious } from 'utils/hooks';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import {
  calculateQuoteAction,
  ChangeCoverageAction,
  ChangeLifeAssuredAction,
  ChangeChildLifeAssuredAction,
  changeProductConfigAction,
  cloneQuoteAction,
  createApplicationAction,
  createQuoteAction,
  getFiaAction,
  getQuoteAction,
  resetAction,
  setCloneStatusAction,
  SetCoveragesList2Action,
  SetCoveragesListAction,
  setErrorAction,
  setGcConfigAction,
  setProductCodeAction,
  setProductConfigNameAction,
  setPtvVersionAction,
  setQuoteAction,
  updateStatusAction,
} from './actions';
import CoverageForm from './components/CoverageForm';
import LifeAssuredForm from './components/LifeAssuredForm';
import ParentDetailsForm from './components/ParentDetailsForm';
import QuoteForm from './components/QuoteForm';
import Review from './components/Review';
import { getCoverages, getCoverages2 } from './helper';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import {
  makeSelectApplicationId,
  makeSelectBiTableValues,
  makeSelectBiTableValues1,
  makeSelectCloneStatus,
  makeSelectConfig,
  makeSelectCoverages2,
  makeSelectError,
  makeSelectFiaCharge,
  makeSelectInitialLifeAssured,
  makeSelectLifeAssured,
  makeSelectChildLifeAssured,
  makeSelectProductConfig,
  makeSelectProductConfig2 as makeSelectProductGCConfig2,
  makeSelectQuoteId,
  makeSelectStatus,
} from './selectors';

const subPageListGC = [
  { order: 1, text: 'Child Details', subOrder: [] },
  { order: 2, text: 'Personal Details', subOrder: [] },
  { order: 3, text: 'Coverage', subOrder: [] },
  { order: 4, text: 'Quote', subOrder: [] },
  { order: 5, text: 'Review', subOrder: [] },
];

export function EspIllustrationPage({
  quoteId,
  saveQuote,
  calculateQuote,
  setCoverage,
  gcConfig,
  setGcConfig,
  subPage,
  subPageList,
  changeSubPage,
  showAppLoadingAction,
  changeSubPageList,
  lifeAssured,
  childDetails,
  initialLifeAssured,
  changeLifeAssured,
  changeChildLifeAssured,
  productConfig,
  biTableValues,
  changeCoverage,
  changeProductConfig,
  submitLifeAssured,
  submitParentDetailsPage,
  submitCoverage,
  submitQuote,
  options,
  updateStatus,
  errorData,
  applicationId,
  status,
  createApplication,
  setPtvVersion,
  setError,
  resetData,
  agentDetails,
  getQuote,
  setQuoteId,
  fiaData,
  callFia,
  cloneQuote,
  cloneStatus,
  changeCloneStatus,
  setProductConfigName,
  getProductConfig,
  productConfig2,
  setCoverage2,
  gcConfig2,
  submitBenefitRating,
  biTableValues1,
  coverages2,
  setProductCode,
  productConfigESP,
}) {
  useInjectReducer({ key: 'espIllustrationPage', reducer });
  useInjectSaga({ key: 'espIllustrationPage', saga });
  const history = useHistory();
  const location = useLocation();

  const [dialog, setDialog] = useState({
    open: false,
    message: null,
  });
  const [product, setProduct] = useState({});
  const [isLifeAssuredEqual, setLifeAssuredEqual] = useState(false);
  // set the productConfig aka gcConfig
  useEffect(() => {
    if (location?.state?.gcConfig) {
      setGcConfig(location.state.gcConfig);
    }
    if (location?.state?.gcConfig?.features?.haveFiaCharge) callFia();
    if (location?.state?.quoteId) {
      setQuoteId(location.state.quoteId);
      getQuote(location.state.type);
    }
  }, [location.state]);

  useEffect(() => {
    // set the sub pages list for sidebar
    changeSubPageList(subPageListGC);
    return () => {
      changeSubPage(0);
      changeSubPageList([]);
      resetData();
    };
  }, []);


  const handleGetProductConfig2 = () => {
    // Fixed for ESP, can be reconstructed for other products in the future

    const products = agentDetails?.products;

    // find ESP
    const ESPProduct = find(products, productItem =>
      productCodeCheck('ESP', productItem.productId),
    );

    if (!isEmpty(ESPProduct)) {
      getProductConfig({
        productVersion: ESPProduct?.PTVVersion,
        productCode: ESPProduct?.PTVCode,
        isSecondaryProduct: true,
      });
    }
  };

  useEffect(() => {
    if (applicationId) {
      history.replace(`/applications/${applicationId}`);
    }
  }, [applicationId]);

  useEffect(() => {
    if (cloneStatus) {
      history.replace({
        pathname: '/illustrations/esp',
        state: { gcConfig, quoteId, type: 2 },
      });
      getQuote(2);
      changeCloneStatus(false);
    }
  }, [cloneStatus]);

  useEffect(() => {
    if (
      !errorData.error &&
      gcConfig2.premium !== '0' &&
      productConfig.specialTermsFlag
    ) {
      return history.replace('/illustrations/esp/quote', location.state);
    }
  }, [gcConfig2.premium]);

  useEffect(() => {
    if (status === 'AGREED') {
      createApplication();
    }

    if ((status === 'SAVED' || status === 'PREVIEW') && !isEmpty(quoteId)) {
      history.replace('/illustrations/esp/review', location.state);
    }
  }, [status]);

  const prevBiTableValues = usePrevious(biTableValues);

  /*useEffect(() => {
    if (
      !isEmpty(biTableValues) &&
      !isEqual(
        prevBiTableValues.illustrationTableDict,
        biTableValues.illustrationTableDict,
      )
    ) {
      return history.replace('/illustrations/esp/quote', location.state);
    }
  }, [biTableValues.illustrationValueDict]);*/
  const prevGcConfig = usePrevious(gcConfig);
  const prevProductConfig2 = usePrevious(productConfig2);

  useEffect(() => {
    if (isEmpty(prevGcConfig) && !isEmpty(gcConfig)) {

      setCoverage(getCoverages(gcConfig.features));
      setCoverage2(getCoverages(gcConfig.features));
    }

  }, [gcConfig]);

  useEffect(() => {
    /*if (
      (isEmpty(prevProductConfig2) && !isEmpty(productConfig2)) ||
      (coverages2?.length > 0 && !isEmpty(productConfig2))
    ) {
      setCoverage2(getCoverages2(productConfig2.features, coverages2));
    }*/
  }, [productConfig2, coverages2?.length]);



  // set the product details from agent data
  useEffect(() => {
    if (gcConfig.productCode) {
      let productDetails = find(
        agentDetails.products,
        p =>
          p.productId === gcConfig.productCode &&
          p.PTVVersion === gcConfig.productVersion,
      );

      if (!productDetails) {
        productDetails = find(
          agentDetails.products,
          p =>
            p.productId === gcConfig.productCode
        );
      }

      setProductConfigName(productDetails?.name);
      setPtvVersion(productDetails?.PTVVersion);
      setProductCode(productDetails?.PTVCode);
      setProduct(productDetails);
    }
  }, [gcConfig.productCode]);

  useEffect(() => {
    // set active subPage
    let activeSubPage = subPage;
    switch (location.pathname) {
      case '/illustrations/esp/child':
        activeSubPage = 1;
        break;
      case '/illustrations/esp/parent':
        activeSubPage = 2;
        break;
      case '/illustrations/esp/coverage':
        activeSubPage = 3;
        break;
      case '/illustrations/esp/benefitrating':
        activeSubPage = 3;
        break;
      case '/illustrations/esp/quote':
        activeSubPage = 4;
        break;
      case '/illustrations/esp/review':
        activeSubPage = 5;
      default:
        break;
    }
    changeSubPage(activeSubPage);
  }, [location.pathname]);

  useEffect(() => {
    if (errorData.error) window.scrollTo(0, 0);
  }, [errorData]);

  useEffect(() => {
    if (!isEmpty(location?.state?.customerDetail)) {
      changeLifeAssured({
        ...lifeAssured,
        insuredOne: location?.state?.customerDetail,
      });
      changeChildLifeAssured({
        ...childDetails,
        childDetails: location?.state?.customerDetail,
      });
    }
  }, []);

  const getTitle = () => {
    switch (location.pathname) {
      case '/illustrations/esp/child':
        return globalMessages.lifeAssuredTitle;
      case '/illustrations/esp/parent':
        return globalMessages.lifeAssuredTitle;
      case '/illustrations/esp/coverage':
        return globalMessages.coverageTitle;
      case '/illustrations/esp/benefitrating':
        return globalMessages.benefitRatingTitle;
      case '/illustrations/esp/quote':
        return globalMessages.quoteTitle;
      case '/illustrations/esp/review':
        return globalMessages.reviewTitle;
      default:
        return globalMessages.lifeAssuredTitle;
    }
  };

  const getFooterLabel3 = () => {
    switch (location.pathname) {
      case '/illustrations/esp/quote':
        return globalMessages.saveQuote;
      case '/illustrations/esp/review':
        return globalMessages.agreeAndProceed;
      default:
        return globalMessages.next;
    }
  };

  if (isEmpty(product) || isEmpty(gcConfig)) {
    return <LoadingDialog isLoading />;
  }
  const _getCoveragesDetails = () => {
    if (productConfig?.coverages?.length > 0) {
      let coveragesArray = []
      let isFlagArray = productConfig?.coverages?.filter(element => element?.isPrimary === true)

      if (isFlagArray?.length <= 0) {
        productConfig?.coverages?.map(element => {
          if (lifeAssured.insuredOne.isPolicyOwner) {
            let obj = { ...element, isPrimary: true }
            coveragesArray.push(obj)
          }
          if (lifeAssured.isInsuredTwo) {
            let obj = { ...element, isPrimary: false }
            coveragesArray.push(obj)
          }
        })
        return coveragesArray
      } else {
        return productConfig?.coverages;
      }

    }
  }
  return (
    <>
      <FormHeader
        title={<FormattedMessage {...getTitle()} />}
        productName={gcConfig.shortName}
      />

      {errorData.error ? (
        <Grid container style={{ paddingLeft: '40px' }}>
          <Grid item xs={7}>
            <NoticeBox variant="error" mb={4}>
              {errorData.message.map(error => (
                <Typography id={error.message} variant="body2" color="error">
                  {error.message}
                </Typography>
              ))}
            </NoticeBox>
          </Grid>
        </Grid>
      ) : null}

      <FormMainBody>
        <Grid item xs={12} md={10} lg={8} xl={6}>
          <Switch>
            <Route
              path="/illustrations/esp/child"
              render={() => (
                <LifeAssuredForm
                  initialValues={lifeAssured}
                  disabled={!!quoteId}
                  location={location}
                  gcConfig={gcConfig}
                  productConfig={productConfig}
                  childDetails={childDetails}
                  options={options}
                  onSubmit={immutableValues => {
                    const currentLifeAssured = immutableValues.toJS();
                    changeChildLifeAssured(currentLifeAssured?.childDetails);
                    changeProductConfig(
                      { ...productConfig, 
                        currency: currentLifeAssured?.childDetails?.currency, 
                        term: (Number(currentLifeAssured?.childDetails?.targetYear) - new Date().getFullYear()),
                        payoutYears: currentLifeAssured?.childDetails?.payoutYears,
                        startYear: currentLifeAssured?.childDetails?.targetYear, 
                        targetYear: currentLifeAssured?.childDetails?.targetYear,
                        endYear: Number(currentLifeAssured?.childDetails?.targetYear) + Number(currentLifeAssured?.childDetails?.payoutYears) -1, 
                        targetFund: Number(currentLifeAssured?.childDetails?.targetFund),
                        countryOfEducationOthers:currentLifeAssured?.childDetails?.countryOfEducationOthers,
                        universityFeeUpdated:currentLifeAssured?.childDetails?.universityFeeUpdated,
                        otherUniversityCountryName:currentLifeAssured?.childDetails?.otherUniversityCountryName,
                        otherUniversityCountryCode:currentLifeAssured?.childDetails?.otherUniversityCountryCode}
                    );
                    return history.replace(
                      '/illustrations/esp/parent',
                      location.state,
                    );
                  }}
                />
              )}
            />
            <Route
              path="/illustrations/esp/parent"
              render={() => (
                <ParentDetailsForm
                  initialValues={lifeAssured}
                  disabled={!!quoteId}
                  gcConfig={gcConfig}
                  location={location}
                  options={options}
                  insuredOne={lifeAssured?.insuredOne}
                  insuredTwo={lifeAssured?.insuredTwo}
                  onSubmit={immutableValues => {
                    const currentLifeAssured = immutableValues.toJS();
                    let isLaOneEqual = false;
                    let isLaTwoEqual = false;
                    let isInsuredTwoEqual = false;
                    let isPoEqual = false;
                    let isPoValueEqual = false;

                    if (
                      isEqual(
                        initialLifeAssured.insuredOne,
                        currentLifeAssured.insuredOne,
                      )
                    )
                      isLaOneEqual = true;

                    if (
                      isEqual(
                        initialLifeAssured.insuredTwo,
                        currentLifeAssured.insuredTwo,
                      )
                    )
                      isLaTwoEqual = true;

                    if (
                      initialLifeAssured.isInsuredTwo ===
                      currentLifeAssured.isInsuredTwo
                    )
                      isInsuredTwoEqual = true;

                    if (
                      initialLifeAssured.isPolicyOwnerIndividual ===
                      currentLifeAssured.isPolicyOwnerIndividual
                    )
                      isPoEqual = true;

                    if (
                      isEqual(
                        initialLifeAssured.policyOwnerIndividual,
                        currentLifeAssured.policyOwnerIndividual,
                      )
                    )
                      isPoValueEqual = true;

                    if (
                      !initialLifeAssured.isInsuredTwo &&
                      !currentLifeAssured.isInsuredTwo
                    )
                      isLaTwoEqual = true;

                    if (
                      !initialLifeAssured.isPolicyOwnerIndividual &&
                      !currentLifeAssured.isPolicyOwnerIndividual
                    )
                      isPoValueEqual = true;

                    if (
                      isLaOneEqual &&
                      isLaTwoEqual &&
                      isInsuredTwoEqual &&
                      isPoEqual &&
                      isPoValueEqual
                    ) {
                      setLifeAssuredEqual(true);
                    }

                    changeLifeAssured(immutableValues.toJS());
                    return history.replace(
                      '/illustrations/esp/coverage',
                      location.state,
                    );
                  }}
                />
              )}
            />
            <Route
              path="/illustrations/esp/coverage"
              render={routeProps => (
                <CoverageForm
                  biTableValues1={biTableValues1}
                  currency={productConfig?.currency}
                  childDetails={childDetails}
                  initialValues={{
                    ...productConfig,
                    ...gcConfig,
                    shortName: productConfig?.shortName,
                    FMC: productConfig?.FMC ? productConfig?.FMC : get(
                      find(productConfigESP.features?.FMC, f => {
                        return f.region === agentDetails.ISOCountryCode;
                      }),
                      'FMCrate',
                      '',
                    ),
                    coverages: _getCoveragesDetails(),
                    coverages2: map(gcConfig2?.coverages, benefit => ({
                      ...benefit,
                      selected: true,
                    })),
                  }}
                  agentDetails={agentDetails}
                  location={location}
                  isLifeAssuredEqual={isLifeAssuredEqual}
                  lifeAssured={lifeAssured}
                  options={options}
                  product={product}
                  gcConfig={gcConfig}
                  fiaData={fiaData}
                  gcConfig2={gcConfig2}
                  productConfig2={productConfig2}
                  coverages2={productConfig.coverages2}
                  handleGetProductConfig2={handleGetProductConfig2}
                  onSubmit={immutableValues => {
                    const data = immutableValues.toJS();
                    let specialTerm = data.specialTermsFlag;
                    if( data?.targetFunctionality && data?.isGetPremium){
                    changeCoverage(
                      { ...data, productConfig2, coverages2: getCoverages(gcConfig.features), specialTermsFlag: specialTerm, getVal: false, isTargetFundProtectionLifeOne: data?.isTargetFundProtectionLifeOne, isTargetFundProtectionLifeTwo: data?.isTargetFundProtectionLifeTwo },
                      true,
                      false,
                      isLifeAssuredEqual,
                      data.isGetPremium
                    )}
                    else{
                    changeCoverage(
                      {
                        ...data, 
                        productConfig2, 
                        coverages2: getCoverages(gcConfig.features), 
                        specialTermsFlag: specialTerm, 
                        getVal: false, 
                        isTargetFundProtectionLifeOne: data?.isTargetFundProtectionLifeOne, 
                        isTargetFundProtectionLifeTwo: data?.isTargetFundProtectionLifeTwo,
                        isGetPremium: false,
                        hasToCalculate: false
                      },
                      true,
                      false,
                      isLifeAssuredEqual,
                    );
                  }
                    if (!data.isGetPremium && data.premium>0 ) {
                      setError({ error: false, message: [] });
                      if (specialTerm) {
                        return history.replace(
                          '/illustrations/esp/benefitrating',
                          location.state,
                        );
                      } else {
                        return history.replace(
                          '/illustrations/esp/quote',
                          location.state,
                        );
                      }
                    }
                  }
                  }
                  {...routeProps}
                />
              )}
            />
            <Route
              path="/illustrations/esp/benefitrating"
              render={() => (
                <BenefitRatingForm
                  initialValues={{ ...productConfig, coverages2: productConfig?.coverages2 ? productConfig?.coverages2 : getCoverages(gcConfig.features) }}
                  productConfig={productConfig}
                  isLifeAssuredEqual={isLifeAssuredEqual}
                  lifeAssured={lifeAssured}
                  onUpdateValues={(newValues) => {
                    let data = {
                      ...productConfig, coverages2: newValues
                    };
                    let getPremiumFlag = productConfig?.targetFunctionality && productConfig?.targetFund
                    if (getPremiumFlag) {
                      changeCoverage(
                        {
                          ...data,
                          getVal: false,
                          isGetPremium: true,
                          hasToCalculate: true,
                          premium: 0
                        },
                        true,
                        false,
                        isLifeAssuredEqual,
                        true
                      );

                    }
                  }}
                  onSubmit={immutableValues => {
                    showAppLoadingAction(true)
                    setTimeout(() => {
                      let data = immutableValues.toJS()
                      changeCoverage(
                        {
                          ...data,
                          isGetPremium: false,
                          hasToCalculate: false, premium: productConfig?.premium
                        },
                        true,
                        false,
                        isLifeAssuredEqual,
                      );
                      //setAppLoadingAction(false)
                      return history.replace(
                        '/illustrations/esp/quote',
                        location.state,
                      );

                    }, 3000)
                  }}
                />
              )}
            />

            <Route
              path="/illustrations/esp/quote"
              render={() => (
                <QuoteForm
                  initialValues={{ biTableValues, productConfig }}
                  coverages={productConfig.coverages}
                  biTableValues={biTableValues}
                  location={location}
                  lifeAssured={lifeAssured}
                  options={options}
                  productConfig={productConfig}
                  gcConfig={gcConfig}
                  gcConfig2={gcConfig2}
                  onUpdateValues={(newValues, reverseCalculate) => {
                    setCoverage2(newValues?.productConfig?.coverages2);
                    changeCoverage(
                      newValues?.productConfig,
                      true,
                      reverseCalculate,
                      isLifeAssuredEqual,
                    );
                  }}
                  onSubmit={() => {
                    saveQuote(isLifeAssuredEqual);
                    return setDialog({
                      open: true,
                      message: 'ADDED',
                    });
                  }}
                  originalPremium={productConfig.premium}
                  itaConfig={productConfig}
                  isBundlingQuote={productConfig.isBundlingQuote}
                />
              )}
            />

            <Route
              path="/illustrations/esp/review"
              render={() => (
                <Review
                  disableProceed={
                    location.pathname === '/illustrations/esp/review' &&
                    status !== 'PREVIEW'
                  }
                  lifeAssured={lifeAssured}
                  location={location}
                  onClickBackToClientList={() =>
                    setDialog({ open: true, message: 'EXIT' })
                  }
                />
              )}
            />
            <Route
              path="/"
              render={() => (
                <Redirect
                  to={{
                    pathname:
                      isEmpty(quoteId) || location.state.type === 2
                        ? '/illustrations/esp/child'
                        : '/illustrations/esp/review',
                    state: location.state,
                  }}
                />
              )}
            />
          </Switch>
        </Grid>
      </FormMainBody>
      <FormFooter
        onClickPrevious={() => {
          setError({ error: false, message: [] });
          switch (location.pathname) {
            case '/illustrations/esp/child':
              history.replace('/illustrations');
              break;
            case '/illustrations/esp/parent':
              history.replace('/illustrations/esp/child');
              break;
            case '/illustrations/esp/coverage':
              history.replace('/illustrations/esp/parent', location.state);
              break;
            case '/illustrations/esp/benefitrating':
              history.replace('/illustrations/esp/coverage', location.state);
              break;
            case '/illustrations/esp/quote':
              productConfig.specialTermsFlag
                ? history.replace(
                  '/illustrations/esp/benefitrating',
                  location.state,
                )
                :
                history.replace(
                  '/illustrations/esp/coverage',
                  location.state,
                )
              break;
            default:
              break;
          }
          return changeSubPage(subPage - 1);
        }}
        onClickExit={() =>
          setDialog({
            open: true,
            message: 'EXIT',
          })
        }
        onClickNext={() => {
          //setError({ error: false, message: [] });
          switch (location.pathname) {
            case '/illustrations/esp/child':
              submitLifeAssured();
              break;
            case '/illustrations/esp/parent':
              submitParentDetailsPage();
              break;
            case '/illustrations/esp/coverage':
              submitCoverage();
              break;
            case '/illustrations/esp/benefitrating':
              submitBenefitRating();
              break;
            case '/illustrations/esp/quote':
              submitQuote();
              break;
            case '/illustrations/esp/review':
              return updateStatus(2);
            default:
              break;
          }
        }}
        disabledPrevious={
          location.pathname === '/illustrations/esp/child' ||
          location.pathname === '/illustrations/esp/review'
        }
        disabledNext={
          location.pathname === '/illustrations/esp/review' &&
          status !== 'PREVIEW'
          // location.pathname === '/illustrations/esp/review'
        }
        label1={<FormattedMessage {...globalMessages.previous} />}
        label2={<FormattedMessage {...globalMessages.exit} />}
        label3={<FormattedMessage {...getFooterLabel3()} />}
      />

      <Dialog
        open={dialog.open}
        onClose={() =>
          setDialog({
            ...dialog,
            open: false,
          })
        }
        onExited={() =>
          setDialog({
            ...dialog,
            message: null,
          })
        }
        variant={dialog.message === 'EXIT' ? 'error' : 'success'}
        title={
          <FormattedMessage
            {...(dialog.message === 'EXIT'
              ? globalMessages.warning
              : messages.added)}
          />
        }
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            {dialog.message === 'ADDED' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setDialog({
                      open: false,
                      message: null,
                    });
                    changeSubPage(0);
                    history.replace('/illustrations');
                  }}
                >
                  <FormattedMessage {...messages.clientList} />
                </Button>
              </Grid>
            )}

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  if (dialog.message === 'ADDED') {
                    cloneQuote(2);
                  } else if (dialog.message === 'EXIT') {
                    changeSubPage(0);
                    history.replace('/illustrations');
                  }
                }}
              >
                <FormattedMessage
                  {...(dialog.message === 'ADDED'
                    ? messages.createAnother
                    : globalMessages.exit)}
                />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setDialog({
                    open: false,
                    message: null,
                  });
                  if (dialog.message === 'ADDED' && !isEmpty(quoteId)) {
                    history.replace(
                      '/illustrations/esp/review',
                      location.state,
                    );
                    // history.replace('/illustrations/esp/review');
                  }
                }}
              >
                <FormattedMessage {...messages.continue} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage
            {...(dialog.message === 'EXIT'
              ? messages.exitQuoteNotice
              : messages.addedInfo)}
          />
        </Typography>
      </Dialog>
    </>
  );
}

EspIllustrationPage.propTypes = {
  quoteId: PropTypes.string,
  lifeAssured: PropTypes.object,
  initialLifeAssured: PropTypes.object,
  applicationId: PropTypes.string,
  productConfig: PropTypes.object,
  biTableValues: PropTypes.object,
  biTableValues1: PropTypes.object,
  setGcConfig: PropTypes.object,
  setCoverage: PropTypes.func,
  gcConfig: PropTypes.object,
  getQuote: PropTypes.func,
  setQuoteId: PropTypes.func,
  saveQuote: PropTypes.func,
  calculateQuote: PropTypes.func,
  subPage: PropTypes.number,
  changeSubPage: PropTypes.func,
  showAppLoadingAction: PropTypes.func,
  updateStatus: PropTypes.func,
  subPageList: PropTypes.array,
  changeSubPageList: PropTypes.func,
  changeLifeAssured: PropTypes.func,
  changeChildLifeAssured: PropTypes.func,
  changeCoverage: PropTypes.func,
  changeProductConfig: PropTypes.func,
  submitLifeAssured: PropTypes.func,
  submitParentDetailsPage: PropTypes.func,
  submitCoverage: PropTypes.func,
  submitQuote: PropTypes.func,
  options: PropTypes.object,
  errorData: PropTypes.object,
  setError: PropTypes.func,
  status: PropTypes.string,
  resetData: PropTypes.func,
  createApplication: PropTypes.func,
  agentDetails: PropTypes.object,
  callFia: PropTypes.func,
  setPtvVersion: PropTypes.func,
  fiaData: PropTypes.object,
  cloneQuote: PropTypes.func,
  cloneStatus: PropTypes.bool,
  changeCloneStatus: PropTypes.func,
  getProductConfig: PropTypes.func,
  productConfig2: PropTypes.object,
  setCoverage2: PropTypes.func,
  gcConfig2: PropTypes.object,
  submitBenefitRating: PropTypes.func,
  setProductCode: PropTypes.func,
  productConfigESP: PropTypes.object,
  childDetails: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  subPage: makeSelectSubPage(),
  fiaData: makeSelectFiaCharge(),
  gcConfig: makeSelectConfig(),
  subPageList: makeSelectSubPageList(),
  lifeAssured: makeSelectLifeAssured(),
  childDetails: makeSelectChildLifeAssured(),
  initialLifeAssured: makeSelectInitialLifeAssured(),
  productConfig: makeSelectProductConfig(),
  biTableValues: makeSelectBiTableValues(),
  biTableValues1: makeSelectBiTableValues1(),
  options: makeSelectOption(),
  errorData: makeSelectError(),
  status: makeSelectStatus(),
  applicationId: makeSelectApplicationId(),
  agentDetails: makeSelectAgentDetails(),
  quoteId: makeSelectQuoteId(),
  cloneStatus: makeSelectCloneStatus(),
  productConfig2: makeSelectProductConfig2(),
  productConfigESP: makeSelectProductConfigESP(),
  gcConfig2: makeSelectProductGCConfig2(),
  coverages2: makeSelectCoverages2(),
});

function mapDispatchToProps(dispatch) {
  return {
    saveQuote: value => dispatch(createQuoteAction(value)),
    setGcConfig: value => dispatch(setGcConfigAction(value)),
    setCoverage: value => dispatch(SetCoveragesListAction(value)),
    setCoverage2: value => dispatch(SetCoveragesList2Action(value)),
    calculateQuote: value => dispatch(calculateQuoteAction(value)),
    showAppLoadingAction: value => dispatch(setAppLoadingAction(value)),
    changeSubPage: value => dispatch(changeSubPageAction(value)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    changeLifeAssured: value => dispatch(ChangeLifeAssuredAction(value)),
    changeChildLifeAssured: value => dispatch(ChangeChildLifeAssuredAction(value)),
    getQuote: value => dispatch(getQuoteAction(value)),
    setQuoteId: value => dispatch(setQuoteAction(value)),
    setPtvVersion: value => dispatch(setPtvVersionAction(value)),
    setProductConfigName: value => dispatch(setProductConfigNameAction(value)),
    cloneQuote: value => dispatch(cloneQuoteAction(value)),
    changeCloneStatus: value => dispatch(setCloneStatusAction(value)),
    changeProductConfig: value => dispatch(changeProductConfigAction(value)),
    changeCoverage: (
      value,
      reacalculateQuote = false,
      reverseCalculate = false,
      isLifeAssuredEqual,
      isGetPremium = false,
    ) =>
      dispatch(
        ChangeCoverageAction(
          value,
          reacalculateQuote,
          reverseCalculate,
          isLifeAssuredEqual,
          isGetPremium,
        ),
      ),
    updateStatus: val => dispatch(updateStatusAction(val)),
    resetData: () => dispatch(resetAction()),
    callFia: () => dispatch(getFiaAction()),
    setError: value => dispatch(setErrorAction(value)),
    createApplication: value => dispatch(createApplicationAction(value)),
    submitLifeAssured: () => dispatch(submit('lifeAssuredForm')),
    submitParentDetailsPage: () => dispatch(submit('ParentDetailsForm')),
    submitCoverage: () => dispatch(submit('coverageForm')),
    submitQuote: () => dispatch(submit('quoteForm')),
    submitBenefitRating: () => dispatch(submit('benefitRatingForm')),
    getProductConfig: value => dispatch(getProductConfigAction(value)),
    setProductCode: value => dispatch(setProductCodeAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EspIllustrationPage);

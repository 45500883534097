import { createSelector } from 'reselect';
import { initialState } from './reducer';

import { getImmutableState } from 'helpers/lang';

/**
 * Direct selector to the applicationPage state domain
 */

const selectApplicationPageDomain = state =>
  state.get('applicationPage', initialState);

/**
 * Other specific selectors
 */

//SESSION
const makeSelectSessionId = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('sessionId'),
  );

//ETAG
const makeSelectEtag = () =>
  createSelector(selectApplicationPageDomain, substate => substate.get('eTag'));

const makeSelectLIParties = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('LIParties'),
  );

//UW SECTIONS
const makeSelectUnderwritingSections = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('underWritingSections'),
  );

const makeSelectApplicationSubOrder = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('applicationSubOrders'),
  );

const makeSelectListApplicationParty = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('listApplication'),
  );

const makeSelectApplicationDetails = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('applicationDetails'),
  );

const makeSelectApplicationDetailsParties = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.getIn(['applicationDetails', 'parties']),
  );

const makeSelectFinancialInstituteOptions = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('financialInstituteOptions'),
  );

const makeSelectLogout = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('isLogout'),
  );
/**
 * Default selector used by ApplicationPage
 */

const makeSelectApplicationPage = () =>
  createSelector(selectApplicationPageDomain, substate => substate.toJS());

const makeSelectAdminActionDetails = () =>
  createSelector(selectApplicationPageDomain, substate =>
    substate.get('adminActionDetails'),
  );


export {
  makeSelectFinancialInstituteOptions,
  makeSelectLIParties,
  makeSelectApplicationPage,
  selectApplicationPageDomain,
  makeSelectSessionId,
  makeSelectEtag,
  makeSelectUnderwritingSections,
  makeSelectApplicationSubOrder,
  makeSelectApplicationDetails,
  makeSelectApplicationDetailsParties,
  makeSelectListApplicationParty,
  makeSelectLogout,
  makeSelectAdminActionDetails
};

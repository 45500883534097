import { fromJS } from 'immutable';
import isEmail from 'validator/lib/isEmail';
import { isEqual } from 'lodash/lang';
import moment from 'moment';
import numeral from 'numeral';
import { dobFormat } from 'helpers/constants';
import { WHOLE_OF_LIFE } from './constants';
import { has } from 'lodash';

/** HELPER */
export function getCoverages(productConfig) {
  var coverages = [];
  productConfig.productBenefits.mandatoryList.map(benefit => {
    if (!benefit.isComplimentary)
      coverages.push({
        type:
          benefit.benefitCode === 'WOP' || benefit.benefitCode === 'ESC'
            ? '2'
            : '1',
        selected: true,
        disabled: benefit.isRequired,
        id: benefit.benefitCode,
        title: benefit.benefitName,
        order: getBenefitOrder(benefit.benefitCode),
        valueInsuredOne: benefit.benefitCode === 'WOP' ? false : '',
        termOne: '',
        valueInsuredTwo: benefit.benefitCode === 'WOP' ? false : '',
        maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
        perPercentageOne: 0,
        perMileOne: 0,
        perMileTwo: 0,
        perPercentageTwo: 0,
        perTempMileOne: 0,
        perTempMileTwo: 0,
        perTempMonthTwo: 0,
        perTempMonthOne: 0,
        brSelectedTempValueOne: false,
        brSelectedTempValueTwo: false,
        brSelectedPercentValueOne: false,
        brSelectedPercentValueTwo: false,
        brSelectedInsuredTwoValue: false,
        brSelectedInsuredOneValue: false,
      });
  });

  productConfig.productBenefits.optionalList.map(benefit => {
    coverages.push({
      type:
        benefit.benefitCode === 'WOP' || benefit.benefitCode === 'ESC'
          ? '2'
          : '1',
      selected: benefit.benefitCode === 'WOP' ? true : false,
      disabled: benefit.isRequired,
      id: benefit.benefitCode,
      title: benefit.benefitName,
      order: getBenefitOrder(benefit.benefitCode),
      valueInsuredOne: benefit.benefitCode === 'WOP' ? false : '',
      termOne: '',
      valueInsuredTwo: benefit.benefitCode === 'WOP' ? false : '',
      maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
      perPercentageOne: 0,
      perMileOne: 0,
      perMileTwo: 0,
      perPercentageTwo: 0,
      perTempMileOne: 0,
      perTempMileTwo: 0,
      perTempMonthTwo: 0,
      perTempMonthOne: 0,
      brSelectedTempValueOne: false,
      brSelectedTempValueTwo: false,
      brSelectedPercentValueOne: false,
      brSelectedPercentValueTwo: false,
      brSelectedInsuredTwoValue: false,
      brSelectedInsuredOneValue: false,
    });
  });
  return fromJS(
    coverages.sort(function (a, b) {
      return a.order - b.order;
    }),
  );
}

export function getMinValue(productId, productConfig, currency) {
  const indexOfCurrency = productConfig.benefitCurrencyMap.indexOf(currency);
  var minValue = 0;

  const productBenefits = [
    ...productConfig.productBenefits.optionalList,
    ...productConfig.productBenefits.mandatoryList,
  ];

  productBenefits.map(benefit => {
    if (!benefit.isComplimentary) {
      if (benefit.benefitCode === productId)
        minValue = benefit.minBenefitAmount[indexOfCurrency];
    }
  });
  return minValue;
}

export function getMaxValue(productId, productConfig, currency) {
  const indexOfCurrency = productConfig.benefitCurrencyMap.indexOf(currency);
  var maxValue = 0;

  const productBenefits = [
    ...productConfig.productBenefits.optionalList,
    ...productConfig.productBenefits.mandatoryList,
  ];

  productBenefits.map(benefit => {
    if (!benefit.isComplimentary) {
      if (benefit.benefitCode === productId)
        maxValue = benefit.maxBenefitAmount[indexOfCurrency];
    }
  });
  return maxValue;
}

export function isValidEmail(email) {
  if (email && isEmail(email)) {
    return true;
  }
  return false;
}

export function getStartDate() {
  var month = new Date().getMonth() + 1;
  if (new Date().getDate() <= 15) month = month - 1;

  return moment()
    .month(month)
    .date(1);
}

export function getBenefitOrder(benefitId) {
  switch (benefitId) {
    case 'LFC':
      return 1;

    case 'CRI':
      return 2;

    case 'CCC':
      return 3;

    case 'ACD':
      return 4;

    case 'DMB':
      return 5;

    case 'FMI':
      return 6;

    case 'FTI':
      return 7;

    case 'HSP':
      return 8;

    case 'PTD':
      return 9;

    case 'WOP':
      return 10;

    default:
      return 11;
  }
}

export function getChosenInsured(lifeAssured) {
  const orderInsuredByAge = getOrderInsuredByAge(lifeAssured);
  if (lifeAssured.lifeBasis === 'JLFD') {
    return orderInsuredByAge.olderLife;
  }

  if (['JLLS', 'JLBD'].includes(lifeAssured.lifeBasis)) {
    return orderInsuredByAge.youngerLife;
  }

  return 'valueInsuredOne';
}

function getOrderInsuredByAge(lifeAssured) {
  const { insuredOne, insuredTwo } = lifeAssured;
  if (insuredOne && insuredTwo) {
    const dobTimeInsuredOne = new Date(insuredOne.dateOfBirth).getTime();
    const dobTimeInsuredTwo = new Date(insuredTwo.dateOfBirth).getTime();
    if (dobTimeInsuredOne < dobTimeInsuredTwo) {
      return {
        olderLife: 'valueInsuredOne',
        youngerLife: 'valueInsuredTwo',
      };
    } else {
      return {
        olderLife: 'valueInsuredTwo',
        youngerLife: 'valueInsuredOne',
      };
    }
  }

  return false;
}

export function getLifeAssuredName(lifeAssured) {
  const life1Name = `${lifeAssured.insuredOne.firstName} ${lifeAssured.insuredOne.lastName}`;

  if (lifeAssured.insuredTwo && lifeAssured.lifeBasisHarvest !== 'SL') {
    return (
      life1Name +
      ` and ${lifeAssured.insuredTwo.firstName} ${lifeAssured.insuredTwo.lastName}`
    );
  }

  return life1Name;
}

export function checkNextMonthWithInOneMonth(targetDate = new Date()) {
  const today = new Date();
  let nextMonth;
  let nextTwoMonth;

  if (today.getMonth() == 11) {
    nextMonth = new Date(today.getFullYear() + 1, 0, 1);
  } else {
    nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
  }

  if (nextMonth.getMonth() == 11) {
    nextTwoMonth = new Date(nextMonth.getFullYear() + 1, 0, 1);
  } else {
    nextTwoMonth = new Date(
      nextMonth.getFullYear(),
      nextMonth.getMonth() + 1,
      1,
    );
  }

  if (
    targetDate.getTime() >= nextMonth.getTime() &&
    targetDate.getTime() <= nextTwoMonth.getTime()
  ) {
    return true;
  }

  return false;
}

export function getAnniversaryDate(startDate) {
  const firstAnniversaryDate = moment(startDate).toDate();
  const today = new Date();
  const yearOfToday = today.getFullYear();

  const dayOfBirthday = firstAnniversaryDate.getDate();
  const monthOfBirthday = firstAnniversaryDate.getMonth();
  if (
    today.getTime() <
    new Date(yearOfToday, monthOfBirthday, dayOfBirthday).getTime()
  ) {
    return new Date(yearOfToday, monthOfBirthday, dayOfBirthday);
  }

  return new Date(yearOfToday + 1, monthOfBirthday, dayOfBirthday);
}

export function checkQuoteFormChanged(currentForm, initialForm) {
  return (
    !isEqual(currentForm.coverages, initialForm.coverages) ||
    !isEqual(currentForm.paymentFrequency, initialForm.paymentFrequency) ||
    !isEqual(Number(currentForm.premium), Number(initialForm.premium)) ||
    !isEqual(currentForm.partialWithdrawal, initialForm.partialWithdrawal) ||
    !isEqual(
      Number(currentForm.singlePremium),
      Number(initialForm.singlePremium),
    ) ||
    !isEqual(currentForm.term, initialForm.term)
  );
}

export function getChangedFieldsNameInQuoteForm(currentForm, harvestPolicyDetails){
  const changedFieldNames = [];
  if(checkCoverageDifference(currentForm.coverages,harvestPolicyDetails.coverages)) changedFieldNames.push('COVERAGE');
  if(!isEqual(currentForm.paymentFrequency, harvestPolicyDetails.paymentFrequency)) changedFieldNames.push('PAYMENT_FREQUENCY');
  const currentPremium = currentForm.premium;
  const newPremiumAccordingToFreqChange = getPremiumOfNewFrequency(harvestPolicyDetails.premium,harvestPolicyDetails.paymentFrequency,currentForm.paymentFrequency);
  if(!isEqual(Number(currentForm.premium), Number(harvestPolicyDetails.premium)) && (currentPremium != newPremiumAccordingToFreqChange)) changedFieldNames.push('PREMIUM');
  if(!isEqual(currentForm.partialWithdrawal, harvestPolicyDetails.partialWithdrawal) && currentForm.partialWithdrawal) changedFieldNames.push('PARTIAL_WITHDRAWAL');
  if(!isEqual(
    Number(currentForm.singlePremium),
    Number(harvestPolicyDetails.singlePremium),
  )) {
    changedFieldNames.push('SINGLE_PREMIUM')
  }

  if(!isEqual(currentForm.term, harvestPolicyDetails.term)) changedFieldNames.push('TERM');
  return changedFieldNames;
}

export function checkCoverageDifference(currentCoverages,initCoverages){
    let isJL = initCoverages.length >1;
    for(const coverage of currentCoverages){
        if(Number(coverage.valueInsuredOne) != Number(initCoverages[0][coverage.id].value)){
          return true;
        }
        if(isJL){
          if(Number(coverage.valueInsuredTwo) != Number(initCoverages[1][coverage.id].value)){
            return true;
          }
        }
    }
    return false;
}


export function getInitLifeAssured(lifeAssured) {
  if (!lifeAssured.insuredTwo) {
    return {
      ...lifeAssured,
      insuredTwo: {
        dateOfBirth: '',
        firstName: '',
        gender: '',
        isLifeInsured: true,
        isPolicyOwner: false,
        isPrimaryLifeInsured: false,
        isSmoke: null,
        lastName: '',
        prospectId: '',
        relationshipToLifeInsured: '',
        title: '',
      },
    };
  }

  return lifeAssured;
}

export function getCoveragesSubmitValue({ coverages }, isAddedNewLife) {
  return coverages.map(item => {
    if (['WOP', 'ESC'].includes(item.id)) {
      return item;
    }
    let benefitSlicesOne = item.benefitSlicesOne;
    let benefitSlicesTwo = item.benefitSlicesTwo;
    if (benefitSlicesOne.length) {
      benefitSlicesOne[benefitSlicesOne.length - 1].benefitAmount =
        Number(item.valueInsuredOne) - Number(item.initValueInsuredOne);

      benefitSlicesOne[benefitSlicesOne.length - 1].benefitTerm =
        Number(item.termOne) - Number(item.initTermOne);
    }

    if (benefitSlicesTwo && benefitSlicesTwo.length) {
      const initValue = isAddedNewLife ? 0 : Number(item.initValueInsuredTwo);
      const initTerm = isAddedNewLife ? 0 : Number(item.initTermTwo);

      benefitSlicesTwo[benefitSlicesTwo.length - 1].benefitAmount =
        Number(item.valueInsuredTwo) - initValue;
      benefitSlicesTwo[benefitSlicesTwo.length - 1].benefitTerm =
        Number(item.termTwo) - initTerm;
    }

    const newCoverages = {
      ...item,
      benefitSlicesOne,
      benefitSlicesTwo,
    };

    Object.keys(newCoverages).forEach(key => {
      if (newCoverages[key] === undefined) {
        delete newCoverages[key];
      }
    });

    return newCoverages;
  });
}

export function getSinglePremiumSlicesSubmitValue(
  singlePremiumSlices,
  singlePremium,
) {
  singlePremiumSlices[singlePremiumSlices.length - 1].annualPremium = Number(
    singlePremium,
  );

  return singlePremiumSlices;
}
export function calculateAgeNextBirthdate(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  var monthDayPoint = 0;
  if (policyStartDateMonth == bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}
export function getPremiumSlicesSubmitValue(
  premiumSlices,
  paymentFrequency,
  premium,
) {
  const totalPremiumSlices = Number(
    premiumSlices.reduce(
      (accumulator, currentValue) => accumulator + currentValue.annualPremium,
      0,
    ) - premiumSlices[premiumSlices.length - 1].annualPremium,
  );

  let annualPremium = 0;
  switch (paymentFrequency) {
    case 'Monthly':
      annualPremium = premium * 12;
      break;
    case 'Quarterly':
      annualPremium = premium * 4;
      break;
    case 'Half-Yearly':
      annualPremium = premium * 2;
      break;
    case 'Yearly':
      annualPremium = premium;
      break;
    default:
      annualPremium = premium * 12;
      break;
  }
  premiumSlices[premiumSlices.length - 1].annualPremium =
    Number(annualPremium) - totalPremiumSlices;

  return premiumSlices;
}

export function getAgeOfLife(lifeBasis, lifeAssured, chosenInsured, startDate) {
  if (lifeBasis === 'SL') {
    return calculateANB(startDate, lifeAssured.insuredOne.dateOfBirth);
  } else {
    if (chosenInsured === 'valueInsuredTwo') {
      return calculateANB(startDate, lifeAssured.insuredTwo.dateOfBirth);
    }
    return calculateANB(startDate, lifeAssured.insuredOne.dateOfBirth);
  }
}

export function calculateSustainYears(sustainabilityYears, ageOfLife,initialAge=null) {
  if (sustainabilityYears === 'WholeOfLife') {
    return 'Whole Of Life';
  }  
  console.log('check sustainabilityYears : ',sustainabilityYears)

  if (sustainabilityYears === null && initialAge != null){
    sustainabilityYears = initialAge;
  }

  if (sustainabilityYears && typeof sustainabilityYears === 'number') {
    const age = numeral(sustainabilityYears)
      .add(ageOfLife)
      .value();

    if (age >= 96) {
      return WHOLE_OF_LIFE;
    }
    return age;
  } else {
    return '--';
  }
}

export function calculateANB(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  let monthDayPoint = 0;
  if (policyStartDateMonth === bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}

export function getInitPremiumByFrequency(premiumSlices, paymentFrequency) {
  const sumPremium =
    premiumSlices.reduce(
      (accumulator, currentValue) => accumulator + currentValue.annualPremium,
      0,
    ) - premiumSlices[premiumSlices.length - 1].annualPremium;

  switch (paymentFrequency) {
    case 'Monthly':
      return sumPremium / 12;
    case 'Quarterly':
      return sumPremium / 4;
    case 'Half-Yearly':
      return sumPremium / 2;
    case 'Yearly':
      return sumPremium;
    default:
      return 0;
  }
}

export function getPremiumSliceByFrequency(premium, paymentFrequency) {
  switch (paymentFrequency) {
    case 'Monthly':
      return premium * 12;
    case 'Quarterly':
      return premium * 4;
    case 'Half-Yearly':
      return premium * 2;
    case 'Yearly':
      return premium;
    default:
      return 0;
  }
}

export function getSelectedCoverageStatus(product) {
  if (product.id === 'WOP') {
    return [product.valueInsuredOne, product.valueInsuredTwo];
  }

  return [
    Number(product.valueInsuredOne) > 0,
    Number(product.valueInsuredTwo) > 0,
  ];
}

export function getPremiumRange(configs, paymentFrequency, currency) {
  let minPremium = 0;
  let maxPremium = 0;

  configs.features &&
    configs.features.currencyPremiumSize.map(val => {
      if (currency === val.currency) {
        switch (paymentFrequency) {
          case 'Monthly':
            minPremium = val.minPremiumMonthly;
            maxPremium = val.maxPremiumMonthly;
            break;

          case 'Quarterly':
            minPremium = val.minPremiumQuarterly;
            maxPremium = val.maxPremiumQuarterly;
            break;

          case 'Half-Yearly':
            minPremium = val.minPremiumHalfYearly;
            maxPremium = val.maxPremiumHalfYearly;
            break;

          case 'Yearly':
            minPremium = val.minPremiumYearly;
            maxPremium = val.maxPremiumYearly;
            break;

          case 'Single':
            minPremium = val.minPremiumSingle;
            maxPremium = val.maxPremiumSingle;
            break;

          default:
            minPremium = val.minPremiumMonthly;
            maxPremium = val.maxPremiumMonthly;
            break;
        }
      }
    });

  return { min: minPremium, max: maxPremium };
}

export function getPaymentTermOptions(originalPolicy, policyConfig, term) {
  const premiumPaymentTerm = [];
  let minTermValue = originalPolicy?.productConfigs.term;
  const maxTermValue =
    policyConfig?.features?.policyTerm.maxVanishingPremiumTerm;
  if (term === 'WholeOfLife') {
    premiumPaymentTerm.push({ title: 'Whole of life', value: 'WholeOfLife' });
  } else {
    while (minTermValue <= maxTermValue) {
      const myObj = { title: minTermValue, value: minTermValue };
      premiumPaymentTerm.push(myObj);
      minTermValue++;
    }
  }

  return premiumPaymentTerm;
}

export function getFrequencyLabel(paymentFrequency) {
  switch (paymentFrequency) {
    case 'Monthly':
      return 'per month';
    case 'Quarterly':
      return 'per quarter';
    case 'Half-Yearly':
      return 'per half-year';
    case 'Yearly':
      return 'per year';
    default:
      return 'single time';
  }
}

export function getPolicyOwnerName(policyOwners) {
  const policyOwnerName = policyOwners.map(
    item => `${item.firstName} ${item.lastName}`,
  );

  if (policyOwnerName.length === 1) {
    return policyOwnerName[0];
  }

  return `${policyOwnerName[0]} & ${policyOwnerName[1]}`;
}

function getNumberOfPaymentsPerYear(paymentFrequency) {
  switch (paymentFrequency) {
    case 'Monthly':
      return 12;
    case 'Quarterly':
      return 4;
    case 'Half-Yearly':
      return 2;
    case 'Yearly':
      return 1;
    default:
      return 0;
  }
}

function monthDiff(d1, d2) {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export function getQuoteStartDate() {
  const nextMonth =
    new Date().getMonth() === 11 ? 0 : new Date().getMonth() + 1;
  const nextYear =
    nextMonth === 0 ? new Date().getFullYear() + 1 : new Date().getFullYear();

  /**
   * Fixed issues was invalid format e.g: 2021-8-01
   */
  const originMonth = nextMonth + 1;
  const formattedNextMonth = originMonth < 10 ? `0${originMonth}` : originMonth;

  return `${nextYear}-${formattedNextMonth}-01`;
}

export function checkCanEditPremium(productConfig, inputProductConfig, paymentFrequency) {
  const isMonthlyFrequency = paymentFrequency === 'Monthly';
  const quoteStartDate = getQuoteStartDate();

  const policyMonth =
    monthDiff(new Date(productConfig.startDate), new Date(quoteStartDate)) + 1;
  const numberOfPaymentsPerYear = getNumberOfPaymentsPerYear(
    //inputProductConfig.paymentFrequency,
    paymentFrequency,
  );
  
  const isModPolicyMonth = policyMonth % (12 / numberOfPaymentsPerYear) === 1;

  const isQuoteDateGreaterThanPaidDate =
    new Date(quoteStartDate).getTime() >=
    new Date(productConfig.lastPaidDate).getTime();

  

  if (productConfig.policyStatus == 'A' && productConfig.isOverDuePremium)  {  // For inforce over due premium policies, condition "(isMonthlyFrequency || isModPolicyMonth)" is not required as per JIRA ticket 269 => https://mezujira.atlassian.net/browse/IIU-269
    return isQuoteDateGreaterThanPaidDate;
  } else {
      return ((isMonthlyFrequency || isModPolicyMonth) && isQuoteDateGreaterThanPaidDate);
  }
  // return true
}

export function renderTerm(term) {
  return term === 'WholeOfLife'
    ? 'Whole of life'
    : `${term} ${term !== 'SinglePremium' && term !== 'WholeOfLife' ? 'Years' : ''
    }`;
}

export function getMinValidTerm(term, policyStartDate) {
  const quoteStartDate = getQuoteStartDate();
  const completedMonths = monthDiff(
    new Date(policyStartDate),
    new Date(quoteStartDate),
  );

  return Math.max(term, Math.round((completedMonths + 12) / 12));
}

export function checkValidTerm(term, maxTerm, policyStartDate) {
  if (term === 'WholeOfLife') {
    return true;
  }

  const minTerm = getMinValidTerm(term, policyStartDate);
  return term >= minTerm || term > maxTerm;
}

export const getBrTitle = brValue => {
  switch (brValue) {
    case '0':
      return '';

    case '1':
      return 'Per Mille';

    case '2':
      return 'Percentage';

    case '3':
      return 'Period';

    default:
      return '';
  }
};

export const getFrequencyOptions = (
  isInitial = false,
  premium,
  currencyPremiumSize = [],
  currency,
) => {
  const premiumValidations =
    currencyPremiumSize.length && currency
      ? currencyPremiumSize.find(item => item.currency === currency)
      : {};

  const options = [
    {
      title: 'Monthly',
      value: 'Monthly',
      validate: premiumValidations['minPremiumMonthly'],
      disabled: false,
    },
    {
      title: 'Quarterly',
      value: 'Quarterly',
      validate: premiumValidations['minPremiumQuarterly'],
      disabled: false,
    },
    {
      title: 'Half-Yearly',
      value: 'Half-Yearly',
      validate: premiumValidations['minPremiumHalfYearly'],
      disabled: false,
    },
    {
      title: 'Yearly',
      value: 'Yearly',
      validate: premiumValidations['minPremiumYearly'],
      disabled: false,
    },
  ];
  if (isInitial) {
    return options.map(item => ({
      ...item,
      disabled: premium < item.validate,
    }));
  }

  return options;
};

export const getUpatedCoverageFromPricingEngine = (insuredDetails) => {
  const _sum = (total, num) => total + num;
  let life1 = {'LFC': insuredDetails['Life1']['SumInsuredTerm'].reduce(_sum), 'CRI': insuredDetails['Life1']['SumInsuredCI'].reduce(_sum), 'PTD': insuredDetails['Life1']['SumInsuredPTD'].reduce(_sum)};
  let life2 = {'LFC': insuredDetails['Life2']['SumInsuredTerm'].reduce(_sum), 'CRI': insuredDetails['Life2']['SumInsuredCI'].reduce(_sum), 'PTD': insuredDetails['Life1']['SumInsuredPTD'].reduce(_sum)};
  return {life1,life2};
}

export const getFrequencyOptionsForFuturaInforce = (
  isInitial = false,
  premium,
  currencyPremiumSize = [],
  currency,
  policyInceptionDate,
  currentFreq,
  initialFreq
) => {
  const premiumValidations =
    currencyPremiumSize.length && currency
      ? currencyPremiumSize.find(item => item.currency === currency)
      : {};
  // Calculate title with effective month
  console.log('Currency',currency)
  
  const today = new Date();
  const policyInceptionMonth = policyInceptionDate.getMonth();
  const currentMonth = today.getMonth();
  const _monthlyFreq = startMonthOfMonthlyFrequency(policyInceptionMonth,currentMonth,initialFreq);
  const _quarterFreq = startMonthOfNextQuarter(policyInceptionMonth,currentMonth);
  const _halfYearlyFreq = startMonthOfNextHalf(policyInceptionMonth,currentMonth);
  const _yearlyFreq = startMonthOfNextYear(policyInceptionMonth,currentMonth);

  const options = [
    {
      title: isEqual('Monthly',initialFreq) ? 'Monthly - Current' : `Monthly - effective ${_monthlyFreq.month}`,
      value: 'Monthly',
      isValidTimeToEnable: _monthlyFreq.isValidTimeToEnable && !isEqual('Monthly',initialFreq),
      validate: premiumValidations['minPremiumMonthly'],
      disabled: false,
    },
    {
      title: isEqual('Quarterly', initialFreq) ? 'Quarterly - Current ' :`Quarterly - effective ${_quarterFreq.month}`,
      value: 'Quarterly',
      isValidTimeToEnable: _quarterFreq.isValidTimeToEnable && !isEqual('Quarterly',initialFreq),
      validate: premiumValidations['minPremiumQuarterly'],
      disabled: false,
    },
    {
      title: isEqual('Half-Yearly', initialFreq) ? 'Half-Yearly - Current' : `Half-Yearly - effective ${_halfYearlyFreq.month}`,
      value: 'Half-Yearly',
      isValidTimeToEnable: _halfYearlyFreq.isValidTimeToEnable && !isEqual('Half-Yearly', initialFreq),
      validate: premiumValidations['minPremiumHalfYearly'],
      disabled: false,
    },
    {
      title:  isEqual('Yearly', initialFreq) ? 'Yearly - Current' : `Yearly - effective ${_yearlyFreq.month}`,
      value: 'Yearly',
      isValidTimeToEnable: _yearlyFreq.isValidTimeToEnable && !isEqual('Yearly', initialFreq),
      validate: premiumValidations['minPremiumYearly'],
      disabled: false,
    },
  ];
  if (isInitial) {
    return options.map(item => ({
      ...item,
      disabled: validateFrequencyWithMinMaxRule(premium,currentFreq,item.value,item.validate,item.isValidTimeToEnable)  //premium < item.validate,
    }));
  }

  return options;
}

function validateFrequencyWithMinMaxRule(premium,currentFreq, newFreq, newFreqMinPremium,isValidTimeToEnable) {
    let newPremium = getPremiumOfNewFrequency(premium,currentFreq,newFreq)
    let nPre = newPremium < newFreqMinPremium;
    if(!isValidTimeToEnable){
      nPre = true
    }
    return nPre;
}

export const getPremiumOfNewFrequency = (premium,currentFreq, newFreq) => {
  console.log(premium,currentFreq,newFreq)
  const freqMap = {
    'Monthly': 1,
    'Quarterly': 3,
    'Half-Yearly': 6,
    'Yearly': 12
  }
  const currentFreqFactor = freqMap[currentFreq];
    const newFreqFactor = freqMap[newFreq];
    let newPremium;
    if(currentFreqFactor <= newFreqFactor) {
      newPremium = premium * (newFreqFactor/currentFreqFactor);
    } else {
      newPremium = premium/ (currentFreqFactor/newFreqFactor);
    }
  console.log(newPremium);
  return newPremium;
}

function startMonthOfNextQuarter(startMonth, currentMonth) {
  
  let quaterRange = [startMonth%12,(startMonth+3)%12,(startMonth+6)%12,(startMonth+9)%12]
  quaterRange.sort(function(a, b){return a-b})
  let _startMonthOfNextQuarter = quaterRange[0];
  for(let i =0; i< quaterRange.length; i++){
       if(currentMonth == quaterRange[i]){
           //_startMonthOfNextQuarter = i === quaterRange.length-1? quaterRange[0]: quaterRange[i+1]
           _startMonthOfNextQuarter = quaterRange[i];
           break;
       }
       if(currentMonth<quaterRange[i]){
           _startMonthOfNextQuarter =  quaterRange[i];
           break;
       }
  }
  const currentDate = new Date();
  let year = 0;
   if(currentMonth < startMonth){
       year = currentDate.getFullYear();
       if(startMonth <= _startMonthOfNextQuarter){
           year = currentDate.getFullYear();
       }
   } else {
       year = currentMonth <= _startMonthOfNextQuarter? currentDate.getFullYear() : currentDate.getFullYear()+1
   }
   const monthDiff = moment([year, _startMonthOfNextQuarter, 1]).diff(moment([currentDate.getFullYear(), currentMonth, 1]), 'months', true);
   console.log(monthDiff);
  return { month: `${moment().month(_startMonthOfNextQuarter).format('MMMM')}`, isValidTimeToEnable:( monthDiff === 0 || monthDiff === 1) ? true:false}
}

function startMonthOfNextHalf(startMonth,currentMonth){
  let halfYearlyRange = [startMonth%12,(startMonth+6)%12]
  halfYearlyRange.sort(function(a, b){return a-b})
  let _startMonthOfNextHalf = halfYearlyRange[0];
  for(let i =0; i< halfYearlyRange.length; i++){
      if(currentMonth == halfYearlyRange[i]){
          //_startMonthOfNextHalf = i === halfYearlyRange.length-1? halfYearlyRange[0]: halfYearlyRange[i+1]
          _startMonthOfNextHalf = halfYearlyRange[i]
          break;
      }
      if(currentMonth<halfYearlyRange[i]){
          _startMonthOfNextHalf =  halfYearlyRange[i];
          break;
      }
 }
 const currentDate = new Date();
 let year = 0;
 if(currentMonth < startMonth) {
      year = currentDate.getFullYear() ;
 } else {
      year = currentMonth <= _startMonthOfNextHalf? currentDate.getFullYear() : currentDate.getFullYear()+1
 }
 const monthDiff = moment([year, _startMonthOfNextHalf, 1]).diff(moment([currentDate.getFullYear(), currentMonth, 1]), 'months', true);
 return {month:`${moment().month(_startMonthOfNextHalf).format('MMMM')}`,isValidTimeToEnable: ( monthDiff === 0 || monthDiff === 1)  }
}

function startMonthOfNextYear(startMonth,currentMonth) {
  const currentDate = new Date();
  let year = 0;
  if(currentMonth <= startMonth) {
    year = currentDate.getFullYear();
  } else {
    year = currentDate.getFullYear() +1;
  }
  const monthDiff = moment([year, startMonth, 1]).diff(moment([currentDate.getFullYear(), currentMonth, 1]), 'months', true);
  return {month:`${moment().month(startMonth).format('MMMM')}`, isValidTimeToEnable:( monthDiff === 0 || monthDiff === 1)}
}

function startMonthOfMonthlyFrequency(startMonth,currentMonth, currentFreq) {
    if(currentFreq === 'Monthly') return {month:'',isValidTimeToEnable:false};
    if(currentFreq === 'Quarterly') return startMonthOfNextQuarter(startMonth,currentMonth);
    if(currentFreq === 'Half-Yearly') return startMonthOfNextHalf(startMonth,currentMonth);
    return startMonthOfNextYear(startMonth,currentMonth);
}

// to check whether premium indexation is applicable for the PTVs
export function isPrmIndexationApplicable (productCode) {
 if (['FUTUR','FUTU2','FUTU3','FUTU4'].includes(productCode))
  {
    return true;
  } else {
    return false;
  }
};

export function checkFuturaFrequencyChangeApplyJourneyAllowed(changedFields, currentValue, harvestValue) {
  if(changedFields.includes('PAYMENT_FREQUENCY')){
      if(changedFields.length === 1){
        return true;
      }
      const newPremiumAccordingToFreqChange = getPremiumOfNewFrequency(harvestValue.premium,harvestValue.paymentFrequency,currentValue.paymentFrequency);
      const diff = currentValue.premium - newPremiumAccordingToFreqChange;
      if(changedFields.includes('PREMIUM') && changedFields.length ===2 && !(diff > 30 || diff < 1)){
        return true;
      }
      return false;
    
  } else {
    return false;
  }

}

export function getChangedFieldsNameInLifeAssured(currentValue, harvestValue) {
  const fields = [];
  if(isEqual(currentValue, harvestValue)){
    return fields;
  } else {
    if(!isEqual(harvestValue.lifeBasis , currentValue.lifeBasis)){
      fields.push('LIFE_BASIS');
    }
    //check life 1
    if(!isEqual(currentValue?.insuredOne, harvestValue?.insuredOne)){
      if(!isEqual(currentValue?.insuredOne.isSmoke,harvestValue?.insuredOne.isSmoke)){
        fields.push('LIFE1_SMOKER_STATUS');
      }
    }
    if(has(currentValue,'insuredTwo') && has(harvestValue,'insuredTwo')){
      if(!isEqual(currentValue?.insuredTwo, harvestValue?.insuredTwo)){
        if(!isEqual(currentValue?.insuredTwo.isSmoke, harvestValue?.insuredTwo.isSmoke)){
          fields.push('LIFE2_SMOKER_STATUS');
        }
      }
    } else if(has(currentValue,'insuredTwo') && !has(harvestValue,'insuredTwo')) {
      fields.push('LIFE2_ADDED')
    }
    return fields;

  }
}

// returns premium due dates for Futura lapsed or paid up policies

export const getRestartFromOptions = (premiumDueData, isNBSliceInNAPRAPPeriod, isInNAPRAPPeriod, reinstatementCount ) => {
  //console.log('arguments inside getRestartFromOption ->',premiumDueData, isNBSliceInNAPRAPPeriod, isInNAPRAPPeriod, reinstatementCount)
  let restartFromOptions = [];
  let premiumDueDateEach;
  let premiumDueMonth;
  let premiumDueYear;
  let premiumDueDateFirst;
  if(premiumDueData && premiumDueData.length > 0) {
    premiumDueDateFirst = moment(premiumDueData[0].passDueDate).toDate();
  }
  // console.log('inside getRestartFromOption -> premiumDueDateFirst',premiumDueDateFirst)
  //let currentdate = moment();

  premiumDueData.map(item => {
      premiumDueDateEach = moment(item.passDueDate).toDate();
      let pushRestartOption = true;
      let monthDiff = moment(premiumDueDateEach).diff(premiumDueDateFirst, 'months', true);
      // console.log('inside getRestartFromOption -> monthDiff',monthDiff )
      //Only past six months skip in premium is allowed.
      if(isNBSliceInNAPRAPPeriod || (isNBSliceInNAPRAPPeriod && isInNAPRAPPeriod)) {
        if(monthDiff > 6) {
          pushRestartOption = false;
        }
      }
      //No Skip in Premium allowed. set only first premium due in options
      if(isNBSliceInNAPRAPPeriod && Number(reinstatementCount) > 1) {
        if(moment(premiumDueDateFirst).isSame(premiumDueDateEach, 'month')) {
          pushRestartOption = true;
        } else {
          pushRestartOption = false;
        }
      }
      if(pushRestartOption) {
        premiumDueMonth = moment(premiumDueDateEach).format('MMMM');
        premiumDueYear = moment(premiumDueDateEach).year();

        restartFromOptions.push({
          title: premiumDueMonth + ' ' + premiumDueYear,
          value: premiumDueMonth + ' ' + premiumDueYear,
          disabled: false,
        });
      }
  })

  return restartFromOptions;
};

//if the policy is paid up and user is not skippig any premium and there is increase in premium then enable Save Quote.
//if the policy is paid up and user is skippig any premium and there is increase in premium then disable Save Quote.
export function isPaidUpPolicyWithPremiumSkipInNAPRAPPeriod(formValues, isPremiumSkipped) {
  if(formValues && formValues.isNBSliceInNAPRAPPeriod && formValues.isInNAPRAPPeriod && formValues.policyStatus == 'C' && 
    formValues.isRestart && (Number(formValues.premium) > Number(formValues.initPremium)) && isPremiumSkipped) {
      return true;
    }
}

export function isPaidUpPolicyWithPremiumIncreaseAfterWOLCalc(productConfig, isChooseToPayAllDues) {
  if(productConfig && productConfig.policyStatus == 'C' && productConfig.isGetPremium && 
    (Number(productConfig.premium)  > Number(productConfig.exisitingPremium)) && 
    productConfig.isNBSliceInNAPRAPPeriod && productConfig.isInNAPRAPPeriod && !isChooseToPayAllDues) {
      return true;
    }
}
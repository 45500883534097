import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, isBoolean } from 'lodash/lang';
import { convertIfImmutable } from 'helpers/lang';
import { map, filter } from 'lodash/collection';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages';
import globalMessages from 'containers/App/messages';
import Helper from 'components/Helper';
import clsx from 'clsx';
import { productCodeCheck, isDisabledField } from 'helpers/constants';
//selector
import { makeSelectOption } from 'containers/App/selectors';

import {
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  InjectedFormProps,
} from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  Grid,
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  Box,
  Collapse,
  Button,
} from '@material-ui/core';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';

//validate
import validate from './validate';

//component
import FormDatePicker from 'components/FormDatePicker';
import FormRadioGroup from 'components/FormRadioGroup';
import FormSelect from 'components/FormSelect';
import FormNumberField from 'components/FormNumberField';
import FormTextField from 'components/FormTextField';
import Dialog from 'components/Dialog';
import CardSectionForm from 'components/CardSectionForm';
// import FormSwitch from 'components/FormSwitch';
import OptSwitch from 'components/OptSwitch';
import { filterCountryOption } from 'containers/ApplicationFormPage/helpers';
import CustomRadio from 'components/CustomRadioButton';

const useStyle = makeStyles(theme => ({
  wrapper: { width: '660px' },
  gridSubContainer: {
    width: '100%',
    paddingLeft: theme.spacing(1.25),
    paddingRight: theme.spacing(6),
  },
  titleName: {
    // color: blue[800],
    color: theme.palette.primary.subMain,
  },
  radioForm: {
    display: 'flex',
    alignItems: 'center',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  containerWrapper: {
    width: '660px',
    margin: 'auto',
  },
  btnDefault: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
  },
  helperText: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: '35px',
  },
  gridSubContainerEmail: {
    paddingRight: theme.spacing(20),
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => {
  const intl = useIntl();
  return (
    <OptSwitch
      checked={value}
      value={value}
      color="default"
      onChange={onChange}
      labelNegative={intl.formatMessage(messages.no)}
      disabled={disabled}
    />
  );
};

function LifeInsured(props) {
  const classes = useStyle();

  const {
    match,
    handleSubmit,
    onSubmit,
    formValues,
    initialValues,
    change,
    options,
    lifeInsuredOnly,
    policyOwners,
    applicationDetails,
    isInforce,
    disabledFields,
  } = props;
  useEffect(() => {
    // canSubmit(checkSubmit());
  }, [formValues]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1} className={classes.wrapper}>
        <FieldArray
          name="lifeInsured"
          component={RenderLifeInsured}
          parties={convertIfImmutable(initialValues)}
          change={change}
          options={options}
          formValues={formValues}
          lifeInsuredOnly={lifeInsuredOnly}
          policyOwners={policyOwners}
          applicationDetails={applicationDetails}
          isInforce={isInforce}
          disabledFields={disabledFields}
        />
      </Grid>
    </form>
  );
}

const RenderLifeInsured = props => {
  const classes = useStyle();
  const intl = useIntl();
  const theme = useTheme();
  const [differentAddress, setDifferentAddress] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    options,
    fields,
    parties,
    change,
    formValues,
    lifeInsuredOnly,
    policyOwners,
    applicationDetails,
    isInforce,
    disabledFields,
  } = props;
  return fields.map((field, index) => {
    let parsedField = convertIfImmutable(fields.get(index));
    const { partyapplicationrelationships } = parsedField;
    const isPrimaryLifeInsured = partyapplicationrelationships.isLifeInsured;
    const isLifeInsured = partyapplicationrelationships.isPrimaryLifeInsured;
    const isPO = partyapplicationrelationships.isPolicyOwner;
    const isPPO = partyapplicationrelationships.isPrimaryPolicyOwner;
    const isChild = partyapplicationrelationships?.additionalDetails?.relationshipToLifeInsured;
    const is3rdPO = !isLifeInsured && isPO && !isPPO;
    if ((parsedField.selectedAddress === 'different' ||
      parsedField.selectedAddress === '' ||
      typeof parsedField.selectedAddress === 'undefined') &&
      (isChild === 'CHILD' && productCodeCheck(8, applicationDetails.productCode))
    ) {
      let selectedParty = policyOwners?.filter(party => party?.partyapplicationrelationships?.isPrimaryPolicyOwner === true)
      change(
        `selectedAddress`,
        selectedParty[0]?.id,
      );
      parsedField.selectedAddress = selectedParty[0]?.id
    }
    if (!isEmpty(formValues)) {
      //ADDRESS CONDITION
      if (
        parsedField.selectedAddress !== 'different' &&
        parsedField.selectedAddress !== '' &&
        typeof parsedField.selectedAddress !== 'undefined'
      ) {
        //get the party that is selected
        const selectedParty = filter(policyOwners, party => {
          return party.id === parsedField.selectedAddress;
        });
        change(
          `${'lifeInsured'}[${index}].addressLine1`,
          selectedParty[0].addressLine1,
        );
        change(
          `${'lifeInsured'}[${index}].addressLine2`,
          selectedParty[0].addressLine2,
        );
        change(
          `${'lifeInsured'}[${index}].addressLine3`,
          selectedParty[0].addressLine3,
        );
        change(
          `${'lifeInsured'}[${index}].postOfficeBoxLine`,
          selectedParty[0].postOfficeBoxLine,
        );
        change(
          `${'lifeInsured'}[${index}].postOfficeBoxCity`,
          selectedParty[0].postOfficeBoxCity,
        );
        change(`${'lifeInsured'}[${index}].city`, selectedParty[0].city);
        change(`${'lifeInsured'}[${index}].country`, selectedParty[0].country);
      }

      //IF NOT SELECTED
      if (
        parsedField.selectedLifeInsuredParties ||
        parsedField.thirdPersonPayor ||
        parsedField.isCompanyPayor
      ) {
        change(`${fieldName}[${index}].lifeInsuredPersonPayor`, false);
      }
    }

    return (
      <Grid container spacing={3} key={`${field}-${index}`}>
        <Grid item xs={12}>
          <Grid container spacing={3} className={classes.gridSubContainer}>
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.titleName}>
                {(isChild === 'CHILD' && productCodeCheck(8, applicationDetails.productCode)) ?
                  <FormattedMessage
                    {...messages.lifeInsuredChild}
                    values={{
                      name: `${parsedField.firstName} ${parsedField.lastName}`
                    }}
                  />
                  :
                  <FormattedMessage
                    {...messages.lifeInsured}
                    values={{
                      name: `${parsedField.firstName} ${parsedField.lastName}`,
                      number: isLifeInsured ? 10 : 20,
                    }}
                  />}
              </Typography>
            </Grid>

            {/* {!fields.get(index).toJS().partyapplicationrelationships
              .isLifeInsured && (
              <> */}
            {(!isLifeInsured) && (
              <React.Fragment>
                <Grid item md={6} xs={12}>
                  <Field
                    shrink
                    name={`${field}.dateOfBirth`}
                    component={FormDatePicker}
                    label={<FormattedMessage {...globalMessages.dob} />}
                    inputProps={{ inputMode: 'numeric' }}
                    maxDate={new Date()}
                    dateInputFormat="DD/MM/YYYY"
                    placeholder="dd/mm/yyyy"
                    margin="normal"
                    minDate={new Date().setFullYear(
                      new Date().getFullYear() - 100,
                    )}
                    maxDate={new Date().setFullYear(new Date().getFullYear())}
                    disabled={isDisabledField(
                      disabledFields,
                      `li${isLifeInsured ? 1 : 2}`,
                      'dateOfBirth',
                    ) || (isChild === 'CHILD' && productCodeCheck(8, applicationDetails.productCode))}
                  />
                </Grid>
              </React.Fragment>
            )}

            <Grid item xs={6}>
              <Field
                name={`${field}.primaryNationality`}
                fullWidth
                component={FormSelect}
                options={filterCountryOption(
                  options.countryCode,
                  formValues.lifeInsured[index].secondaryNationality,
                )}
                label={<FormattedMessage {...messages.primaryNationality} />}
                placeholder={intl.formatMessage(messages.pleaseEnter)}
                disabled={productCodeCheck(1, applicationDetails.productCode)}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                disabled={isDisabledField(
                  disabledFields,
                  `li${isLifeInsured ? 1 : 2}`,
                  'primaryNationality',
                ) || (isChild === 'CHILD' && productCodeCheck(8, applicationDetails.productCode))}
              />
            </Grid>
            {(isChild !== 'CHILD' && productCodeCheck(8, applicationDetails.productCode)) &&
              <Grid item xs={6}>
                <Field
                  name={`${field}.secondaryNationality`}
                  fullWidth
                  component={FormSelect}
                  options={[
                    { value: '', title: 'Select' },
                    ...filterCountryOption(
                      options.countryCode,
                      formValues.lifeInsured[index].primaryNationality,
                    ),
                  ]}
                  label={<FormattedMessage {...messages.dualNationality} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  disabled={isDisabledField(
                    disabledFields,
                    `li${isLifeInsured ? 1 : 2}`,
                    'secondaryNationality',
                  )}
                />
              </Grid>
            }
            {(isChild !== 'CHILD' && productCodeCheck(8, applicationDetails.productCode)) &&

              <Grid item xs={6}>
                <Field
                  name={`${field}.preferredName`}
                  fullWidth
                  component={FormTextField}
                  label={<FormattedMessage {...messages.alternateName} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  disabled={isDisabledField(
                    disabledFields,
                    `li${isLifeInsured ? 1 : 2}`,
                    'preferredName',
                  )}
                />
              </Grid>
            }
            <Grid item xs={6}>
              <Field
                name={`${field}.countryOfBirth`}
                fullWidth
                component={FormSelect}
                options={options.countryCode}
                label={<FormattedMessage {...messages.countryOfBirth} />}
                placeholder={intl.formatMessage(messages.pleaseEnter)}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
                disabled={isDisabledField(
                  disabledFields,
                  `li${isLifeInsured ? 1 : 2}`,
                  'countryOfBirth',
                )}
              />
            </Grid>
            {isInforce && (
              <Grid item xs={6}>
                <Field
                  name={`${field}.relationshipToPolicyOwner`}
                  fullWidth
                  component={FormSelect}
                  options={options.payorRelationship}
                  label={
                    <FormattedMessage
                      {...messages.relationshipToPolicyOwner2}
                    />
                  }
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  // disabled
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  disabled={isDisabledField(
                    disabledFields,
                    `li${isLifeInsured ? 1 : 2}`,
                    'relationshipToPolicyOwner',
                  )}
                />
              </Grid>
            )}
            <Grid item xs={6} />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        {(productCodeCheck(8, applicationDetails.productCode) && parsedField?.partyapplicationrelationships?.additionalDetails?.relationshipToLifeInsured !== 'CHILD') &&
          <Grid item xs={12}>
            <Grid
              container
              spacing={3}
              className={clsx(
                classes.gridSubContainer,
                classes.gridSubContainerEmail,
              )}
            >
              <Grid item xs={10}>
                <Field
                  name={`${field}.email`}
                  fullWidth
                  component={FormTextField}
                  label={<FormattedMessage {...messages.emailAddress} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                  disabled={isDisabledField(
                    disabledFields,
                    `li${isLifeInsured ? 1 : 2}`,
                    'email',
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Field
                      name={`${field}.primaryCountryDialingCode`}
                      fullWidth
                      component={FormSelect}
                      // options={options.countryDialingCode}
                      options={options.countryCode}
                      label={<FormattedMessage {...messages.countryCode} />}
                      placeholder={intl.formatMessage(messages.pleaseEnter)}
                      type="text"
                      color="primary"
                      margin="normal"
                      variant="standard"
                      value=""
                      disabled={isDisabledField(
                        disabledFields,
                        `li${isLifeInsured ? 1 : 2}`,
                        'primaryCountryDialingCode',
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      name={`${field}.primaryPhoneNumber`}
                      fullWidth
                      component={FormNumberField}
                      thousandSeparator={''}
                      label={<FormattedMessage {...messages.mobileNumber} />}
                      placeholder={intl.formatMessage(messages.pleaseEnter)}
                      type="text"
                      color="primary"
                      margin="normal"
                      variant="standard"
                      disabled={isDisabledField(
                        disabledFields,
                        `li${isLifeInsured ? 1 : 2}`,
                        'primaryPhoneNumber',
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <Field
                      name={`${field}.secondaryCountryDialingCode`}
                      fullWidth
                      component={FormSelect}
                      options={[
                        { value: '', title: 'Select' },
                        ...options.countryCode,
                      ]}
                      label={<FormattedMessage {...messages.countryCode} />}
                      placeholder={intl.formatMessage(messages.pleaseEnter)}
                      type="text"
                      color="primary"
                      margin="normal"
                      variant="standard"
                      value=""
                      disabled={isDisabledField(
                        disabledFields,
                        `li${isLifeInsured ? 1 : 2}`,
                        'secondaryCountryDialingCode',
                      )}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <Field
                      name={`${field}.secondaryPhoneNumber`}
                      fullWidth
                      component={FormNumberField}
                      thousandSeparator={''}
                      label={<FormattedMessage {...messages.phoneNumber} />}
                      placeholder={intl.formatMessage(messages.pleaseEnter)}
                      type="text"
                      color="primary"
                      margin="normal"
                      variant="standard"
                      disabled={isDisabledField(
                        disabledFields,
                        `li${isLifeInsured ? 1 : 2}`,
                        'secondaryPhoneNumber',
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }

        <Grid item xs={12}>
          <Field
            name={`${field}.selectedAddress`}
            type="radio"
            component={FormRadioGroup}
            disabled={isDisabledField(
              disabledFields,
              `li${isLifeInsured ? 1 : 2}`,
              'selectedAddress',
            )}
          >
            <div className={classes.radioForm}>
              {(!productCodeCheck(8, applicationDetails.productCode) && parsedField?.partyapplicationrelationships?.additionalDetails?.relationshipToLifeInsured !== 'CHILD') &&

                <FormControlLabel
                  control={
                    <CustomRadio
                      checked={
                        parsedField && 'different' === parsedField.selectedAddress
                      }
                      disabled={isDisabledField(
                        disabledFields,
                        `li${isLifeInsured ? 1 : 2}`,
                        'selectedAddress',
                      )}
                    />
                  }
                  value={'different'}
                  label={
                    <Typography
                      variant="body1"
                      className={classes.bold}
                    >{`Different address`}</Typography>
                  }
                  labelPlacement="end"
                />
              }
              {map(policyOwners, (party, index2) => {
                return (
                  <FormControlLabel
                    key={`${party.id}-${index2}`}
                    control={
                      <CustomRadio
                        checked={
                          !isEmpty(formValues) &&
                          party.id === parsedField.selectedAddress
                        }
                        disabled={isDisabledField(
                          disabledFields,
                          `li${isLifeInsured ? 1 : 2}`,
                          'selectedAddress',
                        )}
                      />
                    }
                    value={party.id}
                    label={
                      <Typography
                        variant="body1"
                        className={classes.bold}
                      >{`Same as ${party.firstName} ${party.lastName}`}</Typography>
                    }
                    labelPlacement="end"
                  />
                );
              })}
            </div>
          </Field>
        </Grid>

        {/* ADDRESS */}
        <Grid item xs={12} md={6}>
          <Field
            name={`${field}.addressLine1`}
            component={FormTextField}
            fullWidth
            label={<FormattedMessage {...messages.flatNumber} />}
            placeholder={intl.formatMessage(globalMessages.select)}
            disabled={
              (!isEmpty(formValues) &&
                parsedField.selectedAddress !== 'different') ||
              isDisabledField(disabledFields, `li${isLifeInsured ? 1 : 2}`, 'addressLine1')
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Field
            name={`${field}.addressLine2`}
            component={FormTextField}
            fullWidth
            label={<FormattedMessage {...messages.propertyName} />}
            placeholder={intl.formatMessage(globalMessages.select)}
            disabled={
              (!isEmpty(formValues) &&
                parsedField.selectedAddress !== 'different') ||
              isDisabledField(disabledFields, `li${isLifeInsured ? 1 : 2}`, 'addressLine2')
            }
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Field
            name={`${field}.addressLine3`}
            component={FormTextField}
            fullWidth
            label={<FormattedMessage {...messages.area} />}
            placeholder={intl.formatMessage(globalMessages.select)}
            disabled={
              (!isEmpty(formValues) &&
                parsedField.selectedAddress !== 'different') ||
              isDisabledField(disabledFields, `li${isLifeInsured ? 1 : 2}`, 'addressLine3')
            }
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Field
            name={`${field}.city`}
            component={FormTextField}
            fullWidth
            label={<FormattedMessage {...messages.emirateCity} />}
            placeholder={intl.formatMessage(globalMessages.select)}
            disabled={
              (!isEmpty(formValues) &&
                parsedField.selectedAddress !== 'different') ||
              isDisabledField(disabledFields, `li${isLifeInsured ? 1 : 2}`, 'city')
            }
          />
        </Grid>

        {/* <Grid item xs={12} md={8}>
              <Field
                name={`${field}.postOfficeBoxLine`}
                component={FormTextField}
                fullWidth
                label={<FormattedMessage {...messages.poBox} />}
                placeholder={intl.formatMessage(globalMessages.select)}
                disabled={
                  !isEmpty(formValues) &&
                  formValues[fieldName][index].selectedAddress !== 'different'
                }
              />
            </Grid> */}

        <Grid item xs={12} md={8}>
          <Field
            name={`${field}.country`}
            component={FormSelect}
            options={
              isInforce ? options.countryCode : options.addressGccCountry
            }
            fullWidth
            label={<FormattedMessage {...messages.country} />}
            placeholder={intl.formatMessage(globalMessages.select)}
            disabled={
              (!isEmpty(formValues) &&
                parsedField.selectedAddress !== 'different') ||
              isDisabledField(disabledFields, `li${isLifeInsured ? 1 : 2}`, 'country')
            }
          />
        </Grid>

        <>
          <Grid item xs={12}>
            <hr className={classes.hr} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name={`${field}.postOfficeBoxLine`}
              component={FormTextField}
              fullWidth
              label={<FormattedMessage {...messages.poBoxOptional} />}
              placeholder={intl.formatMessage(globalMessages.select)}
              disabled={
                (!isEmpty(formValues) &&
                  parsedField.selectedAddress !== 'different') ||
                isDisabledField(
                  disabledFields,
                  `li${isLifeInsured ? 1 : 2}`,
                  'postOfficeBoxLine',
                )
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name={`${field}.postOfficeBoxCity`}
              component={FormTextField}
              fullWidth
              label={<FormattedMessage {...messages.emirateCityOptional} />}
              placeholder={intl.formatMessage(globalMessages.select)}
              disabled={
                (!isEmpty(formValues) &&
                  parsedField.selectedAddress !== 'different') ||
                isDisabledField(
                  disabledFields,
                  `li${isLifeInsured ? 1 : 2}`,
                  'postOfficeBoxCity',
                )
              }
            />
          </Grid>

          {/* {isInforce && (
            <>
              <Grid item xs={8}>
                <Typography variant="body1" className={classes.helperText}>
                  {<FormattedMessage {...messages.isSmoker} />}
                  <Helper
                    helperText={globalMessages.tooltipInsured.defaultMessage}
                  />
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`${field}.isSmoke`}
                  type="radio"
                  component={FormRadioGroup}
                  normalize={value => value === 'true'}
                  value={formValues.lifeInsured[index].isSmoke}
                  onBlur={e => {
                    // eslint-disable-next-line no-console
                    e.preventDefault();
                  }}
                >
                  <FormControlLabel
                    control={<CustomRadio />}
                    value={true}
                    label="Yes"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={<CustomRadio />}
                    value={false}
                    label="No"
                    labelPlacement="end"
                  />
                </Field>
              </Grid>
            </>
          )} */}
        </>

        <Grid item xs={12}>
          <br />
          <Divider />
          <br />
        </Grid>
      </Grid>
    );
  });
};

LifeInsured.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  isInforce: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
});

const withConnect = connect(mapStateToProps);

const withFormSelector = connect(state => {
  const formValues = getFormValues('lifeInsured')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
  };
});

export default reduxForm({
  enableReinitialize: true,
  validate,
  form: 'lifeInsured',
})(compose(withConnect, withFormSelector)(LifeInsured));

/*
 * Futura/WAP/SW IllustrationPage Messages
 *
 * This contains all the text for the FuturaIllustrationPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.FuturaIllustrationPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the FuturaIllustrationPage container!',
  },
  policyOwner: {
    id: `${scope}.policyOwner`,
    defaultMessage: 'Policy Owner',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email address (optional)',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  selectOption: {
    id: `${scope}.selectOption`,
    defaultMessage: 'Select an option',
  },
  isSecondPolicyOwner: {
    id: `${scope}.isSecondPolicyOwner`,
    defaultMessage: 'Do you want to add a second policy owner?',
  },
  whoLifeInsured: {
    id: `${scope}.whoLifeInsured`,
    defaultMessage: 'Who will be the life insured?',
  },
  insuredOne: {
    id: `${scope}.insuredOne`,
    defaultMessage: 'Insured 1',
  },
  insuredTwo: {
    id: `${scope}.insuredTwo`,
    defaultMessage: 'Insured 2',
  },
  isSecondInsured: {
    id: `${scope}.isSecondInsured`,
    defaultMessage: 'Do you want to insure a second life e.g. a spouse?',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  coverageAmount: {
    id: `${scope}.coverageAmount`,
    defaultMessage: 'Coverage Amount Required',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Policy term (in years)',
  },
  policyFrequency: {
    id: `${scope}.policyFrequency`,
    defaultMessage: 'Policy Frequency',
  },
  premiumWaiverBenefit: {
    id: `${scope}.premiumWaiverBenefit`,
    defaultMessage: 'Avail of premium waiver benefit?',
  },
  descriptionTwo: {
    id: `${scope}.descriptionTwo`,
    defaultMessage:
      'Should one or more insured persons becomes unable to work, the premium is waived. This is subject to Terms & Conditions.',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  enterAmount: {
    id: `${scope}.enterAmount`,
    defaultMessage: 'Enter Amount',
  },
  editPremiumAmount: {
    id: `${scope}.editPremiumAmount`,
    defaultMessage: 'Edit Premium Amount*',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  quoteDescription: {
    id: `${scope}.quoteDescription`,
    defaultMessage:
      '*Editing the illustration directly impacts the sum assured and renders the extra benefits void',
  },
  insuredParty: {
    id: `${scope}.insuredParty`,
    defaultMessage: 'The Insured',
  },
  policySummary: {
    id: `${scope}.policySummary`,
    defaultMessage: 'Policy Summary',
  },
  isPolicyOwnerIndividual: {
    id: `${scope}.isPolicyOwnerIndividual`,
    defaultMessage: 'Do you wish to add an Investment Advisor Fee?',
  },
  relationWithLifeInsured: {
    id: `${scope}.relationWithLifeInsured`,
    defaultMessage: 'Relationship to life insured',
  },
  assumedStartDate: {
    id: `${scope}.assumedStartDate`,
    defaultMessage: 'Assumed start date',
  },
  paymentFrequency: {
    id: `${scope}.paymentFrequency`,
    defaultMessage: 'Payment frequency (if applicable)',
  },
  lifeCover: {
    id: `${scope}.lifeCover`,
    defaultMessage: 'Life cover',
  },
  criticalIlleness: {
    id: `${scope}.criticalIlleness`,
    defaultMessage: 'Critical illeness',
  },
  ptd: {
    id: `${scope}.ptd`,
    defaultMessage: 'Permanent & total disability',
  },
  waiveThePremium: {
    id: `${scope}.waiveThePremium`,
    defaultMessage: 'Waive the premium?',
  },
  specialOffer: {
    id: `${scope}.specialOffer`,
    defaultMessage: 'Special offer (optional)',
  },
  addAnotherPolicyOwner: {
    id: `${scope}.addAnotherPolicyOwner`,
    defaultMessage: 'Add another policy owner',
  },
  added: {
    id: `${scope}.added`,
    defaultMessage: 'Added',
  },
  addedInfo: {
    id: `${scope}.addedInfo`,
    defaultMessage: `We've added this illustration to the client's list.`,
  },
  clientList: {
    id: `${scope}.clientList`,
    defaultMessage: 'Client List',
  },
  createAnother: {
    id: `${scope}.createAnother`,
    defaultMessage: 'Create Another',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  notePremiumCalculation: {
    id: `${scope}.notePremiumCalculation`,
    defaultMessage:
      'Note: editing the premium directly impacts the level of life cover, and resets the extra benefits.',
  },
  futuraIllustration: {
    id: `${scope}.futuraIllustration`,
    defaultMessage: 'FUTURA Illustration',
  },
  viewExportPDF: {
    id: `${scope}.viewExportPDF`,
    defaultMessage: 'View / Export PDF',
  },
  emailPDF: {
    id: `${scope}.emailPDF`,
    defaultMessage: 'Email PDF',
  },
  reference: {
    id: `${scope}.reference`,
    defaultMessage: 'Reference',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Cover details',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium summary',
  },
  pleaseEnter: {
    id: `${scope}.pleaseEnter`,
    defaultMessage: 'Please enter',
  },
  whatPolicyBasisRequired: {
    id: `${scope}.whatPolicyBasisRequired`,
    defaultMessage: 'What policy basis do you required?',
  },
  jlfd: {
    id: `${scope}.jlfd`,
    defaultMessage: 'Joint life first death',
  },
  jlld: {
    id: `${scope}.jlld`,
    defaultMessage: 'Joint life last death',
  },
  jlbd: {
    id: `${scope}.jlbd`,
    defaultMessage: 'Joint life both death',
  },
  coverageTerm: {
    id: `${scope}.coverageTerm`,
    defaultMessage: 'Coverage term',
  },
  premiumPaymentTerm: {
    id: `${scope}.premiumPaymentTerm`,
    defaultMessage: 'Premium payment term',
  },
  premiumPaymentFrequency: {
    id: `${scope}.premiumPaymentFrequency`,
    defaultMessage: 'Premium payment frequency',
  },
  premiumPaymentTermTitle: {
    id: `${scope}.premiumPaymentTermTitle`,
    defaultMessage: 'Premium payment term (in years)',
  },
  additionalSinglePremium: {
    id: `${scope}.additionalSinglePremium`,
    defaultMessage: 'Additional single premium',
  },
  partialWithdrawal: {
    id: `${scope}.partialWithdrawal`,
    defaultMessage: 'Partial Withdrawal',
  },
  singlePremium: {
    id: `${scope}.singlePremium`,
    defaultMessage: 'Single premium',
  },
  assumedGrowthRate: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'Assumed growth rate',
  },
  assumedGrowthRatePercentage: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'Assumed growth rate %',
  },
  enterTerm: {
    id: `${scope}.enterTerm`,
    defaultMessage: 'Enter term',
  },
  amountEachYr: {
    id: `${scope}.amountEachYr`,
    defaultMessage: 'Amount each year',
  },
  coverTerm: {
    id: `${scope}.coverTerm`,
    defaultMessage: 'Cover term from date of claim',
  },
  coverTerm2: {
    id: `${scope}.coverTerm2`,
    defaultMessage: 'Cover term from date of policy issue',
  },
  vanishingPremiumTerm: {
    id: `${scope}.vanishingPremiumTerm`,
    defaultMessage: 'Vanishing premium term',
  },
  policyCoverageTerm: {
    id: `${scope}.policyCoverageTerm`,
    defaultMessage: 'Policy coverage term',
  },
  premiumTerm: {
    id: `${scope}.premiumTerm`,
    defaultMessage: 'Premium term',
  },
  premiumAmount: {
    id: `${scope}.premiumAmount`,
    defaultMessage: 'Premium amount',
  },
  reviewNotice: {
    id: `${scope}.reviewNotice`,
    defaultMessage: 'Please open and review the illustration to proceed',
  },
  offerIncluded: {
    id: `${scope}.offerIncluded`,
    defaultMessage: 'Offer Included',
  },
  brDebits: {
    id: `${scope}.brDebits`,
    defaultMessage: 'Benefits rating',
  },
  specialTerms: {
    id: `${scope}.specialTerms`,
    defaultMessage: 'Special Terms',
  },
  coverType: {
    id: `${scope}.coverType`,
    defaultMessage: 'Cover type',
  },
  coverageAmountTitle: {
    id: `${scope}.coverageAmountTitle`,
    defaultMessage: 'Coverage amount',
  },
  permanent: {
    id: `${scope}.permanent`,
    defaultMessage: 'Permanent',
  },
  temporary: {
    id: `${scope}.temporary`,
    defaultMessage: 'Temporary',
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: 'Period',
  },
  perMilleAndMonths: {
    id: `${scope}.perMilleAndMonths`,
    defaultMessage: 'Per mille and months',
  },
  growthRate: {
    id: `${scope}.growthRate`,
    defaultMessage: '0.0%',
  },
  notApplicable: {
    id: `${scope}.notApplicable`,
    defaultMessage: 'Not applicable',
  },
  offerCodeValid: {
    id: `${scope}.offerCodeValid`,
    defaultMessage: 'This quote benefits from special offer of {value}',
  },
  specialOfferCode: {
    id: `${scope}.specialOfferCode`,
    defaultMessage: 'Special offer',
  },
  errorEmail: {
    id: `${scope}.errorEmail`,
    defaultMessage: '{message}',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error',
  },
  ofLifeInsured: {
    id: `${scope}.ofLifeInsured`,
    defaultMessage: 'of life insured',
  },
  ofOlderLife: {
    id: `${scope}.ofOlderLife`,
    defaultMessage: 'of older life',
  },
  ofYoungerLife: {
    id: `${scope}.ofYoungerLife`,
    defaultMessage: 'of younger life',
  },
  editPolicy: {
    id: `${scope}.editPolicy`,
    defaultMessage: 'Edit Policy',
  },
  coverageAmount: {
    id: `${scope}.coverageAmount`,
    defaultMessage: 'Coverage amount',
  },
  paidToDate: {
    id: `${scope}.paidToDate`,
    defaultMessage: 'Paid to date',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Start date',
  },
  paymentTerm: {
    id: `${scope}.paymentTerm`,
    defaultMessage: 'Payment term',
  },
  paymentDue: {
    id: `${scope}.paymentDue`,
    defaultMessage: 'Payment due',
  },
  lastPayment: {
    id: `${scope}.lastPayment`,
    defaultMessage: 'Last payment',
  },
  growthRate: {
    id: `${scope}.growthRate`,
    defaultMessage: 'Growth rate %',
  },
  premium: {
    id: `${scope}.premium`,
    defaultMessage: 'Premium',
  },
  premiumDue: {
    id: `${scope}.premiumDue`,
    defaultMessage: 'Premium Due',
  },
  premiumPaid: {
    id: `${scope}.premiumPaid`,
    defaultMessage: 'Premium Paid',
  },
  mergeButton: {
    id: `${scope}.mergeButton`,
    defaultMessage: 'Merge',
  },
  unMergeButton: {
    id: `${scope}.unMergeButton`,
    defaultMessage: 'Unmerge',
  },
  ASP: {
    id: `${scope}.ASP`,
    defaultMessage: 'Additional Single Premium',
  },
  sustainableUntilAge: {
    id: `${scope}.sustainableUntilAge`,
    defaultMessage: 'Sustainable until age',
  },
  sustainableUntilAgeTooltip: {
    id: `${scope}.sustainableUntilAgeTooltip`,
    defaultMessage:
      'This takes into account all existing policy benefits, as well as life cover',
  },
  viewExportPDF: {
    id: `${scope}.viewExportPDF`,
    defaultMessage: 'View/Export PDF',
  },
  recalculate: {
    id: `${scope}.recalculate`,
    defaultMessage: 'Recalculate sustainability',
  },
  protectionBenefit: {
    id: `${scope}.protectionBenefit`,
    defaultMessage: 'Protection benefits',
  },
  policySummary: {
    id: `${scope}.policySummary`,
    defaultMessage: 'Policy Summary',
  },
  default: {
    id: `${scope}.default`,
    defaultMessage: 'Default %',
  },
  custom: {
    id: `${scope}.custom`,
    defaultMessage: 'Custom %',
  },
  fiaText: {
    id: `${scope}.fiaText`,
    defaultMessage: 'Investment advisor fee',
  },
  onlineForm: {
    id: `${scope}.onlineForm`,
    defaultMessage: 'online form',
  },
  onlineNotes: {
    id: `${scope}.onlineNotes`,
    defaultMessage: `Unfortunately we cannot complete your request here. To help us
    support your request please submit an {link}`,
  },
  lifeInsuredTwo: {
    id: `${scope}.lifeInsuredTwo`,
    defaultMessage: 'Life insured 2: ',
  },
  lifeInsuredOne: {
    id: `${scope}.lifeInsuredOne`,
    defaultMessage: 'Life insured 1: ',
  },
  tooltipJLFD: {
    id: `${scope}.tooltipJLFD`,
    defaultMessage: `The policy ends on the first death.`,
  },
  tooltipJLBD: {
    id: `${scope}.tooltipJLBD`,
    defaultMessage: `The policy pays out on the first insured event and continues for the survivor.`,
  },
  getPremiumBaseWOL: {
    id: `${scope}.getPremiumBaseWOL`,
    defaultMessage: `Get premium based on Whole of life`,
  },
  getASPBaseWOL: {
    id: `${scope}.getASPBaseWOL`,
    defaultMessage: `Get ASP based on Whole of life`,
  },
  calculateSustain: {
    id: `${scope}.calculateSustain`,
    defaultMessage: `Calculate sustainability based on inputs`,
  },
  exitQuoteNotice: {
    id: `${scope}.exitQuoteNotice`,
    defaultMessage: `You have not completed the quote. Are you sure you want to exit now?`,
  },
  premiumIndexation: {
    id: `${scope}.premiumIndexation`,
    defaultMessage: `Premium Indexation`,
  },
   descriptionEsc: {
    id: `${scope}.descriptionEsc`,
    defaultMessage:
      'The premiums increase by 5% each year i.e. If the monthly premium is $1000 in year one, it will increase to $1050 per month in year two, and so on.',
  },
  establishmentCharge: {
    id: `${scope}.establishmentCharge`,
    defaultMessage: 'Establishment charge',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  regularWithdrawalTitle: {
    id: `${scope}.regularWithdrawalTitle`,
    defaultMessage: 'Would you like to take regular withdrawals?',
  },
  frequency: {
    id: `${scope}.frequency`,
    defaultMessage: 'Frequency',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'End date',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: `Child's first name`,
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: `Child's last name`,
  },
  percentage: {
    id: `${scope}.percentage`,
    defaultMessage: 'Percentage',
  },
  percentageFundValue: {
    id: `${scope}.percentageFundValue`,
    defaultMessage: 'Percentage of fund value',
  },
  policyStartDate: {
    id: `${scope}.policyStartDate`,
    defaultMessage: 'Policy start date',
  },
  quoteStartDate: {
    id: `${scope}.quoteStartDate`,
    defaultMessage: 'Quote start date',
  },
  currentFundValue: {
    id: `${scope}.currentFundValue`,
    defaultMessage: 'Current fund value',
  },
  totalWithdrawalTillDate: {
    id: `${scope}.totalWithdrawalTillDate`,
    defaultMessage: 'Total withdrawal till date',
  },
  wapIllustration: {
    id: `${scope}.wapIllustration`,
    defaultMessage: 'WAP Illustration',
  },
  partialWithdrawal: {
    id: `${scope}.partialWithdrawal`,
    defaultMessage: 'Partial withdrawal',
  },
  swIllustration: {
    id: `${scope}.swIllustration`,
    defaultMessage: 'Simple Wealth Illustration',
  },
  advisorFeeLabel: {
    id: `${scope}.advisorFeeLabel`,
    defaultMessage: 'Fund investment advisor fee',
  },
  withdrawal: {
    id: `${scope}.withdrawal`,
    defaultMessage: 'Withdrawal',
  },
  newSinglePremiumAmount: {
    id: `${scope}.newSinglePremiumAmount`,
    defaultMessage: 'New single premium amount',
  },
  additionalSinglePremiumTitle: {
    id: `${scope}.additionalSinglePremiumTitle`,
    defaultMessage: 'Would you like to add an additional single premium (ASP)',
  },
  ASP: {
    id: `${scope}.ASP`,
    defaultMessage: 'Additional Single Premium',
  },
  ASPShort: {
    id: `${scope}.ASPShort`,
    defaultMessage: 'ASP',
  },
  adHocwithdrawl: {
    id: `${scope}.adHocwithdrawl`,
    defaultMessage: 'AdHoc withdrawl',
  },
  projectedFundValues: {
    id: `${scope}.projectedFundValues`,
    defaultMessage: '{value} years projected fund value',
  },
  fundValues: {
    id: `${scope}.fundValues`,
    defaultMessage: 'Fund values',
  },
  premiumPaid: {
    id: `${scope}.premiumPaid`,
    defaultMessage: 'Premiums paid',
  },
  RINotAllowed: {
    id: `${scope}.RINotAllowed`,
    defaultMessage: 'Reinstatement not allowed for this policy',
  },
  premiumsUnpaidCount: {
    id: `${scope}.premiumsUnpaidCount`,
    defaultMessage: 'No. of premium unpaid',
  },
  restartFrom: {
    id: `${scope}.restartFrom`,
    defaultMessage: 'Restart From',
  },
  isRestart: {
    id: `${scope}.isRestart`,
    defaultMessage: 'I want to restart my regular premiums',
  },
});

/**
 *
 * DtaIllustrationPage
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash/object';
import { FormattedMessage } from 'react-intl';
import { MIN_DATE_QUOTE_VALID } from 'helpers/date';
import {
  useHistory,
  useLocation,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import globalMessages from 'containers/App/messages';
import { Grid, Typography, Button } from '@material-ui/core';
import FormHeader from 'components/FormHeader';
import FormFooter from 'components/FormFooter';
import { makeSelectSubPage } from 'containers/DashboardPage/selectors';
import {
  changeSubPageAction,
  changeSubPageListAction,
} from 'containers/DashboardPage/actions';
import { submit } from 'redux-form';
import {
  makeSelectOption,
  makeSelectAgentDetails,
  makeSelectOfferCodeData,
  makeSelectErrorOfferCodeData,
} from 'containers/App/selectors';
import FormMainBody from 'components/FormMainBody';
//import Notification from 'components/Notification';
import { isEmpty, find, isEqual } from 'lodash';
import { usePrevious } from 'utils/hooks';
import LoadingDialog from 'components/LoadingDialog';
import Dialog from 'components/Dialog';
import NoticeBox from 'components/NoticeBox';
import { getCoverages } from './helper';
import LifeAssuredForm from './components/LifeAssuredForm';
import {
  ChangeLifeAssuredAction,
  ChangeCoverageAction,
  createQuoteAction,
  setErrorAction,
  SetCoveragesListAction,
  updateStatusAction,
  resetAction,
  createApplicationAction,
  setDtaConfigAction,
  getQuoteAction,
  setQuoteAction,
  setPtvVersionAction,
  cloneQuoteAction,
  setCloneStatusAction,
  setCompletedDialogAction,
  setProductCodeAction,
} from './actions';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectError,
  makeSelectConfig,
  makeSelectStatus,
  makeSelectApplicationId,
  makeSelectQuoteId,
  makeSelectInitialLifeAssured,
  makeSelectCloneStatus,
  makeSelectCompletedDialog,
  makeSelectIsCalculateQuotePage,
} from './selectors';
import Review from './components/Review';
import QuoteForm from './components/QuoteForm';
import BenefitRatingForm from './components/BenefitRatingForm';
import CoverageForm from './components/CoverageForm';
import messages from './messages';
import saga from './saga';
import reducer from './reducer';
import { makeSelectProductConfig as makeSelectProductConfig2 } from 'containers/IllustrationPage/selectors';

const subPageListDTA = [
  { order: 1, text: 'Life Insured', subOrder: [] },
  { order: 2, text: 'Coverage', subOrder: [] },
  { order: 3, text: 'Quote', subOrder: [] },
  { order: 4, text: 'Review', subOrder: [] },
];

export function DtaIllustrationPage({
  quoteId,
  saveQuote,
  setCoverage,
  dtaConfig,
  setDtaConfig,
  subPage,
  changeSubPage,
  changeSubPageList,
  lifeAssured,
  initialLifeAssured,
  changeLifeAssured,
  productConfig,
  changeCoverage,
  submitLifeAssured,
  submitCoverage,
  submitBenefitRating,
  submitQuote,
  options,
  updateStatus,
  errorData,
  applicationId,
  status,
  createApplication,
  setError,
  resetData,
  agentDetails,
  getQuote,
  setQuoteId,
  setPtvVersion,
  cloneQuote,
  cloneStatus,
  changeCloneStatus,
  offerCodeData,
  completedDialog,
  setCompletedDialog,
  isCalculateQuotePage,
  offerCodeErrorMsg,
  setProductCode,
  productConfig2,
}) {
  useInjectReducer({ key: 'dtaIllustrationPage', reducer });
  useInjectSaga({ key: 'dtaIllustrationPage', saga });

  const history = useHistory();
  const location = useLocation();
  const [dialog, setDialog] = useState({
    open: false,
    message: null,
  });

  const [product, setProduct] = useState({});
  const [isLifeAssuredEqual, setLifeAssuredEqual] = useState(false);
  const minValidDate = new Date(MIN_DATE_QUOTE_VALID);
  const createDate = new Date(productConfig.createdAt);
  const isValidQuoteByDate =
    productConfig.requiredKFS && createDate.getTime() > minValidDate.getTime();

  useEffect(() => {
    if (applicationId) {
      history.replace(`/applications/${applicationId}`);
    }
  }, [applicationId]);

  useEffect(() => {
    if (cloneStatus) {
      history.replace({
        pathname: '/illustrations/dta',
        state: { dtaConfig, quoteId, type: 2 },
      });
      getQuote(2);
      changeCloneStatus(false);
    }
  }, [cloneStatus]);

  useEffect(() => {
    if (status === 'AGREED') {
      createApplication();
    }

    if ((status === 'SAVED' || status === 'PREVIEW') && !isEmpty(quoteId)) {
      history.replace('/illustrations/dta/review', location.state);
    }
  }, [status]);

  const prevPremium = usePrevious(productConfig.premium);

  useEffect(() => {
    if (
      productConfig.premium != undefined &&
      productConfig.premium != 0 &&
      productConfig.premium != prevPremium &&
      status != 'SAVED' &&
      status != 'PREVIEW' &&
      status != 'AGREED'
    ) {
      return history.replace('/illustrations/dta/quote', location.state);
    }
    if (
      productConfig.premium === undefined &&
      !isCalculateQuotePage &&
      location.pathname === '/illustrations/dta/quote'
    ) {
      return history.replace('/illustrations/dta/coverage', location.state);
    }
  }, [productConfig.premium]);

  const prevDtaConfig = usePrevious(dtaConfig);

  useEffect(() => {
    if (isEmpty(prevDtaConfig) && !isEmpty(dtaConfig))
      setCoverage(getCoverages(dtaConfig.features));
  }, [dtaConfig]);

  // set the productConfig aka dtaConfig
  useEffect(() => {
    setDtaConfig(location.state.dtaConfig);
    if (location.state.quoteId) {
      setQuoteId(location.state.quoteId);
      getQuote(location.state.type);
    }
    // set the sub pages list for sidebar
    changeSubPageList(subPageListDTA);
    return () => {
      changeSubPage(0);
      changeSubPageList([]);
      resetData();
    };
  }, []);

  // set the product details from agent data
  useEffect(() => {
    if (dtaConfig.productCode) {
      const productDetails = find(
        agentDetails.products,
        p => p.productId === dtaConfig.productCode,
      );

      setPtvVersion(productDetails.PTVVersion);
      setProductCode(productDetails.PTVCode);
      setProduct(productDetails);
    }
  }, [dtaConfig.productCode]);

  useEffect(() => {
    // set active subPage
    let activeSubPage = subPage;
    switch (location.pathname) {
      case '/illustrations/dta/insured':
        activeSubPage = 1;
        break;
      case '/illustrations/dta/coverage':
        activeSubPage = 2;
        break;
      case '/illustrations/dta/benefitrating':
        activeSubPage = 2;
        break;
      case '/illustrations/dta/quote':
        activeSubPage = 3;
        break;
      case '/illustrations/dta/review':
        activeSubPage = 4;
      default:
        break;
    }
    changeSubPage(activeSubPage);
  }, [location.pathname]);

  useEffect(() => {
    if (errorData.error) window.scrollTo(0, 0);
  }, [errorData]);

  useEffect(() => {
    if (!isEmpty(location.state.customerDetail)) {
      changeLifeAssured({
        ...lifeAssured,
        insuredOne: location.state.customerDetail,
      });
    }
  }, []);

  const getTitle = () => {
    switch (location.pathname) {
      case '/illustrations/dta/insured':
        return globalMessages.lifeAssuredTitle;
      case '/illustrations/dta/coverage':
        return globalMessages.coverageTitle;
      case '/illustrations/dta/benefitrating':
        return globalMessages.benefitRatingTitle;
      case '/illustrations/dta/quote':
        return globalMessages.quoteTitle;
      case '/illustrations/dta/review':
        return globalMessages.reviewTitle;
      default:
        return globalMessages.lifeAssuredTitle;
    }
  };

  const getFooterLabel3 = () => {
    switch (location.pathname) {
      case '/illustrations/dta/quote':
        return globalMessages.saveQuote;
      case '/illustrations/dta/review':
        return globalMessages.agreeAndProceed;
      default:
        return globalMessages.next;
    }
  };

  if (isEmpty(product) || isEmpty(dtaConfig)) {
    return <LoadingDialog isLoading />;
  }

  return (
    <>
      <FormHeader
        title={<FormattedMessage {...getTitle()} />}
        productName={dtaConfig.shortName}
      />

      {errorData.error ? (
        <Grid container style={{ paddingLeft: '40px' }}>
          <Grid item xs={7}>
            <NoticeBox variant="error" mb={4}>
              {errorData.message.map((error, index) => (
                <Typography
                  key={index}
                  id={error.message}
                  variant="body2"
                  color="error"
                >
                  {error.message}
                </Typography>
              ))}
            </NoticeBox>
          </Grid>
        </Grid>
      ) : null}

      <FormMainBody>
        <Grid item xs={12} md={10} lg={7} xl={6}>
          <Switch>
            <Route
              path="/illustrations/dta/insured"
              render={() => (
                <LifeAssuredForm
                  initialValues={lifeAssured}
                  disabled={!!quoteId}
                  location={location}
                  options={options}
                  onSubmit={immutableValues => {
                    const currentLifeAssured = immutableValues.toJS();
                    let isLaOneEqual = false;
                    let isLaTwoEqual = false;
                    let isInsuredTwoEqual = false;
                    let isPoEqual = false;
                    let isPoValueEqual = false;

                    if (
                      isEqual(
                        initialLifeAssured.insuredOne,
                        currentLifeAssured.insuredOne,
                      )
                    )
                      isLaOneEqual = true;

                    if (
                      isEqual(
                        initialLifeAssured.insuredTwo,
                        currentLifeAssured.insuredTwo,
                      )
                    )
                      isLaTwoEqual = true;

                    if (
                      initialLifeAssured.isInsuredTwo ===
                      currentLifeAssured.isInsuredTwo
                    )
                      isInsuredTwoEqual = true;

                    if (
                      initialLifeAssured.isPolicyOwnerIndividual ===
                      currentLifeAssured.isPolicyOwnerIndividual
                    )
                      isPoEqual = true;

                    if (
                      isEqual(
                        initialLifeAssured.policyOwnerIndividual,
                        currentLifeAssured.policyOwnerIndividual,
                      )
                    )
                      isPoValueEqual = true;

                    if (
                      !initialLifeAssured.isInsuredTwo &&
                      !currentLifeAssured.isInsuredTwo
                    )
                      isLaTwoEqual = true;

                    if (
                      !initialLifeAssured.isPolicyOwnerIndividual &&
                      !currentLifeAssured.isPolicyOwnerIndividual
                    )
                      isPoValueEqual = true;

                    if (
                      isLaOneEqual &&
                      isLaTwoEqual &&
                      isInsuredTwoEqual &&
                      isPoEqual &&
                      isPoValueEqual
                    ) {
                      setLifeAssuredEqual(true);
                    }

                    changeLifeAssured(immutableValues.toJS());
                    return history.replace(
                      '/illustrations/dta/coverage',
                      location.state,
                    );
                  }}
                />
              )}
            />

            <Route
              path="/illustrations/dta/coverage"
              render={routeProps => (
                <CoverageForm
                  initialValues={{
                    ...productConfig,
                    shortName: productConfig2.shortName,
                    FMC: get(
                      find(productConfig2.features?.FMC, f => {
                        return f.region === agentDetails.ISOCountryCode;
                      }),
                      'FMCrate',
                      '',
                    ),
                  }}
                  location={location}
                  lifeAssured={lifeAssured}
                  options={options}
                  product={product}
                  dtaConfig={dtaConfig}
                  offerCodeData={offerCodeData}
                  onSubmit={immutableValues => {
                    if (immutableValues.toJS().specialTermsFlag) {
                      changeCoverage(
                        immutableValues.toJS(),
                        false,
                        false,
                        false,
                        isLifeAssuredEqual,
                      );
                      return history.replace(
                        '/illustrations/dta/benefitrating',
                        location.state,
                      );
                    }
                    changeCoverage(
                      immutableValues.toJS(),
                      true,
                      false,
                      false,
                      isLifeAssuredEqual,
                    );
                  }}
                  {...routeProps}
                />
              )}
            />

            <Route
              path="/illustrations/dta/benefitrating"
              render={() => (
                <BenefitRatingForm
                  initialValues={productConfig}
                  lifeAssured={lifeAssured}
                  onSubmit={immutableValues => {
                    changeCoverage(
                      immutableValues.toJS(),
                      true,
                      false,
                      false,
                      isLifeAssuredEqual,
                    );
                  }}
                />
              )}
            />

            <Route
              path="/illustrations/dta/quote"
              render={() => (
                <QuoteForm
                  initialValues={productConfig}
                  location={location}
                  originalPremium={productConfig.premium}
                  lifeAssured={lifeAssured}
                  options={options}
                  dtaConfig={dtaConfig}
                  onUpdateValues={(
                    newValues,
                    reverseCalculate,
                    isCheckOfferCode = false,
                  ) => {
                    changeCoverage(
                      newValues,
                      true,
                      reverseCalculate,
                      true,
                      isLifeAssuredEqual,
                      isCheckOfferCode,
                    );
                  }}
                  onSubmit={() => {
                    return saveQuote(isLifeAssuredEqual);
                  }}
                  offerCodeData={offerCodeData}
                />
              )}
            />

            <Route
              path="/illustrations/dta/review"
              render={() => (
                <Review
                  location={location}
                  // disableProceed={
                  //   location.pathname === '/illustrations/dta/review' &&
                  //   status != 'PREVIEW'
                  // }
                  isValidQuoteByDate={isValidQuoteByDate}
                  onClickBackToClientList={() =>
                    setCompletedDialog({ open: true, message: 'EXIT' })
                  }
                />
              )}
            />
            <Route
              path="/"
              render={() => (
                <Redirect
                  to={{
                    pathname:
                      isEmpty(quoteId) || location.state.type === 2
                        ? '/illustrations/dta/insured'
                        : '/illustrations/dta/review',
                    state: location.state,
                  }}
                />
              )}
            />
          </Switch>
        </Grid>
      </FormMainBody>
      <FormFooter
        onClickPrevious={() => {
          setError({ error: false, message: [] });
          switch (location.pathname) {
            case '/illustrations/dta/insured':
              history.replace('/illustrations');
              break;
            case '/illustrations/dta/coverage':
              history.replace('/illustrations/dta/insured', location.state);
              break;
            case '/illustrations/dta/benefitrating':
              history.replace('/illustrations/dta/coverage', location.state);
              break;
            case '/illustrations/dta/quote':
              productConfig.specialTermsFlag
                ? history.replace(
                    '/illustrations/dta/benefitrating',
                    location.state,
                  )
                : history.replace(
                    '/illustrations/dta/coverage',
                    location.state,
                  );
              break;
            default:
              break;
          }
          return changeSubPage(subPage - 1);
        }}
        onClickExit={() =>
          setCompletedDialog({
            open: true,
            message: 'EXIT',
          })
        }
        onClickNext={() => {
          setError({ error: false, message: [] });
          switch (location.pathname) {
            case '/illustrations/dta/insured':
              submitLifeAssured();
              break;
            case '/illustrations/dta/coverage':
              submitCoverage();
              break;
            case '/illustrations/dta/benefitrating':
              submitBenefitRating();
              break;
            case '/illustrations/dta/quote':
              submitQuote();
              break;
            case '/illustrations/dta/review':
              return updateStatus(2);
            default:
              break;
          }
        }}
        disabledPrevious={
          location.pathname === '/illustrations/dta/insured' ||
          location.pathname === '/illustrations/dta/review'
        }
        disabledNext={
          (location.pathname === '/illustrations/dta/review' &&
            status != 'PREVIEW') ||
          (location.pathname === '/illustrations/dta/review' &&
            offerCodeData.errorMessage) ||
          offerCodeData.validating ||
          (location.pathname === '/illustrations/dta/quote' &&
            !isEmpty(offerCodeErrorMsg)) ||
          (location.pathname === '/illustrations/dta/quote' && errorData.error)
        }
        label1={<FormattedMessage {...globalMessages.previous} />}
        label2={<FormattedMessage {...globalMessages.exit} />}
        label3={<FormattedMessage {...getFooterLabel3()} />}
      />

      <Dialog
        open={completedDialog.open}
        onClose={() =>
          setCompletedDialog({
            ...completedDialog,
            open: false,
          })
        }
        onExited={() =>
          setCompletedDialog({
            ...completedDialog,
            message: null,
          })
        }
        variant={completedDialog.message === 'EXIT' ? 'error' : 'success'}
        title={
          <FormattedMessage
            {...(completedDialog.message === 'EXIT'
              ? globalMessages.warning
              : messages.added)}
          />
        }
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            {completedDialog.message === 'ADDED' && (
              <Grid item xs={4}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setCompletedDialog({
                      open: false,
                      message: null,
                    });
                    changeSubPage(0);
                    history.replace('/illustrations');
                  }}
                >
                  <FormattedMessage {...messages.clientList} />
                </Button>
              </Grid>
            )}

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setCompletedDialog({
                    open: false,
                    message: null,
                  });
                  if (completedDialog.message === 'ADDED') {
                    cloneQuote(2);
                  } else if (completedDialog.message === 'EXIT') {
                    changeSubPage(0);
                    history.replace('/illustrations');
                  }
                }}
              >
                <FormattedMessage
                  {...(completedDialog.message === 'ADDED'
                    ? messages.createAnother
                    : globalMessages.exit)}
                />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setCompletedDialog({
                    open: false,
                    message: null,
                  });
                  if (
                    completedDialog.message === 'ADDED' &&
                    !isEmpty(quoteId)
                  ) {
                    history.replace(
                      '/illustrations/dta/review',
                      location.state,
                    );
                  }
                }}
              >
                <FormattedMessage {...messages.continue} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage
            {...(completedDialog.message === 'EXIT'
              ? messages.exitQuoteNotice
              : messages.addedInfo)}
          />
        </Typography>
      </Dialog>
    </>
  );
}

DtaIllustrationPage.propTypes = {
  quoteId: PropTypes.string,
  lifeAssured: PropTypes.object,
  initialLifeAssured: PropTypes.object,
  applicationId: PropTypes.string,
  productConfig: PropTypes.object,
  setCoverage: PropTypes.func,
  dtaConfig: PropTypes.object,
  getQuote: PropTypes.func,
  setQuoteId: PropTypes.func,
  saveQuote: PropTypes.func,
  subPage: PropTypes.number,
  changeSubPage: PropTypes.func,
  updateStatus: PropTypes.func,
  changeSubPageList: PropTypes.func,
  changeLifeAssured: PropTypes.func,
  changeCoverage: PropTypes.func,
  submitLifeAssured: PropTypes.func,
  submitCoverage: PropTypes.func,
  submitBenefitRating: PropTypes.func,
  submitQuote: PropTypes.func,
  options: PropTypes.object,
  errorData: PropTypes.object,
  setError: PropTypes.func,
  status: PropTypes.string,
  resetData: PropTypes.func,
  createApplication: PropTypes.func,
  setPtvVersion: PropTypes.func,
  cloneQuote: PropTypes.func,
  cloneStatus: PropTypes.bool,
  changeCloneStatus: PropTypes.func,
  agentDetails: PropTypes.object,
  offerCodeData: PropTypes.object,
  completedDialog: PropTypes.object,
  setCompletedDialog: PropTypes.func,
  isCalculateQuotePage: PropTypes.bool,
  offerCodeErrorMsg: PropTypes.string,
  setProductCode: PropTypes.func,
  productConfig2: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  subPage: makeSelectSubPage(),
  dtaConfig: makeSelectConfig(),
  lifeAssured: makeSelectLifeAssured(),
  initialLifeAssured: makeSelectInitialLifeAssured(),
  productConfig: makeSelectProductConfig(),
  options: makeSelectOption(),
  errorData: makeSelectError(),
  status: makeSelectStatus(),
  applicationId: makeSelectApplicationId(),
  agentDetails: makeSelectAgentDetails(),
  quoteId: makeSelectQuoteId(),
  cloneStatus: makeSelectCloneStatus(),
  offerCodeData: makeSelectOfferCodeData(),
  completedDialog: makeSelectCompletedDialog(),
  isCalculateQuotePage: makeSelectIsCalculateQuotePage(),
  offerCodeErrorMsg: makeSelectErrorOfferCodeData(),
  productConfig2: makeSelectProductConfig2(),
});

function mapDispatchToProps(dispatch) {
  return {
    saveQuote: value => dispatch(createQuoteAction(value)),
    setDtaConfig: value => dispatch(setDtaConfigAction(value)),
    setCoverage: value => dispatch(SetCoveragesListAction(value)),
    changeSubPage: value => dispatch(changeSubPageAction(value)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    changeLifeAssured: value => dispatch(ChangeLifeAssuredAction(value)),
    getQuote: value => dispatch(getQuoteAction(value)),
    setQuoteId: value => dispatch(setQuoteAction(value)),
    setPtvVersion: value => dispatch(setPtvVersionAction(value)),
    cloneQuote: value => dispatch(cloneQuoteAction(value)),
    changeCloneStatus: value => dispatch(setCloneStatusAction(value)),
    changeCoverage: (
      value,
      reacalculateQuote = false,
      reverseCalculate = false,
      isCalculateQuotePage = false,
      isLifeAssuredEqual,
      isCheckOfferCode = false,
    ) =>
      dispatch(
        ChangeCoverageAction(
          value,
          reacalculateQuote,
          reverseCalculate,
          isCalculateQuotePage,
          isLifeAssuredEqual,
          isCheckOfferCode,
        ),
      ),
    updateStatus: val => dispatch(updateStatusAction(val)),
    resetData: () => dispatch(resetAction()),
    setError: value => dispatch(setErrorAction(value)),
    createApplication: value => dispatch(createApplicationAction(value)),
    submitLifeAssured: () => dispatch(submit('lifeAssuredForm')),
    submitCoverage: () => dispatch(submit('coverageForm')),
    submitBenefitRating: () => dispatch(submit('benefitRatingForm')),
    submitQuote: () => dispatch(submit('quoteForm')),
    setCompletedDialog: value => dispatch(setCompletedDialogAction(value)),
    setProductCode: value => dispatch(setProductCodeAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(DtaIllustrationPage);

import styled from 'styled-components';

const IFrame = styled.iframe`
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  /* overflow-y: scroll;
  -webkit-overflow-scrolling: touch; */
  border: none;
`;

export default IFrame;

/**
 *
 * FormSelect
 *
 */

import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { MenuItem } from '@material-ui/core';
import TextField from 'components/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
  textField: {
    borderRadius: '8px',
    height: '4.6rem',
  },
}));

const FormSelect = ({
  label,
  input,
  meta: { invalid, error, touched, warning },
  options,
  variant = 'outlined',
  ...custom
}) => {
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  const classes = useStyle();
  return (
    <TextField
      variant="outlined"
      className={classes.textField}
      error={!!warning || (touched && invalid)}
      helperText={helperMsg}
      label={label}
      select
      {...input}
      {...custom}
    >
      {options &&
        options.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.title}
          </MenuItem>
        ))}
    </TextField>
  );
};

FormSelect.propTypes = {
  ...WrappedFieldProps,
};

export default FormSelect;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, isBoolean } from 'lodash/lang';
import { convertIfImmutable } from 'helpers/lang';

import {
  // Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  getFormSyncErrors,
  InjectedFormProps,
} from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { makeSelectAgentDetails } from 'containers/App/selectors';

//components

import Owner from './components/Owner';

//validate
import validate from './validate';

const useStyle = makeStyles(theme => ({
  wrapper: { width: '660px' },
}));

function PolicyOwner(props) {
  const classes = useStyle();

  const {
    isUAEAgency,
    isShowTaxResidence,
    initialValues,
    change,
    handleSubmit,
    onSubmit,
    formValues,
    formErrors,
    isInforce,
    disabledFields,
  } = props;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1} className={classes.wrapper}>
          <FieldArray
            name="parties"
            component={Owner}
            parties={convertIfImmutable(initialValues).parties}
            isUAEAgency={isUAEAgency}
            isShowTaxResidence={isShowTaxResidence}
            change={change}
            formValues={formValues}
            formErrors={formErrors}
            isInforce={isInforce}
            disabledFields={disabledFields}
          />
        </Grid>
      </form>
    </>
  );
}

PolicyOwner.propTypes = {
  ...InjectedFormProps,
  disabledFields: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({});

const withConnect = connect(mapStateToProps);

const withFormSelector = connect(state => {
  const formValues = getFormValues('policyOwner')(state);
  const formErrors = getFormSyncErrors('policyOwner')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

export default reduxForm({
  enableReinitialize: true,
  validate,
  form: 'policyOwner',
})(compose(withConnect, withFormSelector)(PolicyOwner));

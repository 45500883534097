import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import messages from '../../messages';
import { isFieldEmpty } from 'utils/form';
import numeral from 'numeral';
import {
  getMinValue,
  getMaxValue,
  calculateAgeNextBirthdate,
} from 'containers/GcIllustrationPage/helper';
import { isAlphaNumeric } from 'helpers/lang';
import { getMinValueITA, getMaxValueITA } from '../../helper';
import { find } from 'lodash/collection';

const validate = (values, props) => {
  const errors = {};
  const gcConfig = props.gcConfig;
  const lifeAssured = props.lifeAssured;
  const itaConfig = props.productConfig2;
  const MAX_GROWTH_RATE = gcConfig.features?.growthRate?.maxRate;
  const MIN_GROWTH_RATE = gcConfig.features?.growthRate?.minRate;
  const MAX_TARGET_FUND = 999999999;
  const MAX_TARGE_YEAR = 2100;

  if (!values.isEmpty()) {

    const currencyValues = values.get('currency');
    const premiumMinArray = gcConfig.features?.currencyPremiumSize?.filter(element => element?.currency === currencyValues);
    const MIN_PREMIUM_SINGLE = premiumMinArray?.length > 0 ? premiumMinArray[0]?.minPremiumSingle : 0;
    const coverages2 = values.get('coverages2');
    const isBundlingQuote = values.get('isBundlingQuote');
    const startDateValues = values.get('startDate');
    const termValues = values.get('term');
    let paymentFrequencyValues = values.get('paymentFrequency');
    const growthRateValues = values.get('growthRate');
    const premiumValues = values.get('premium');
    const isEducationFeeWithdrawalValues = values.get(
      'isEducationalFeeWithdrawal',
    );
    const isRegularWithdrawalValues = values.get('isRegularWithdrawal');
    const regularWithdrawalValues = values.get('regularWithdrawal');
    /*const educationFeeWithdrawalValues = values
      .get('educationFeesWithdrawal')
      .get('child1');*/
    const targetFunctionalityValues = values.get('targetFunctionality');
    const hasToCalculate = values.get('hasToCalculate');
    const targetYearValues = values.get('targetYear');
    const targetFundValues = values.get('targetFund');
    const haveAdditionalSinglePremiumValues = values.get('haveAdditionalSinglePremium')
    const singlePremiumValues = values.get('singlePremium')
    if (haveAdditionalSinglePremiumValues) {
      if (!singlePremiumValues) {
        errors.singlePremium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      }
      else {
        if (singlePremiumValues < MIN_PREMIUM_SINGLE)
          errors.singlePremium = (
            <FormattedMessage  {...globalMessages.minLimit}
              values={{ value: MIN_PREMIUM_SINGLE }} />
          );
        if (singlePremiumValues > MAX_TARGET_FUND)
          errors.singlePremium = (
            <FormattedMessage
              {...globalMessages.maxLimit}
              values={{ value: MAX_TARGET_FUND }}
            />
          );
      }
    }
    if (!startDateValues || !moment(startDateValues).isValid())
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!termValues || termValues === 'Select')
      errors.term = <FormattedMessage {...globalMessages.pleaseSelectValue} />;

    if (
      (!paymentFrequencyValues && termValues != 'SinglePremium') ||
      paymentFrequencyValues === 'Select'
    )
      errors.paymentFrequency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );

    if (targetFunctionalityValues) {
      if (!targetYearValues || targetYearValues === 'Invalid date')
        errors.targetYear = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (
        targetYearValues &&
        targetYearValues < new Date().getFullYear() + 5
      )
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: new Date().getFullYear() + 5 }}
          />
        );
      else if (targetYearValues && targetYearValues > MAX_TARGE_YEAR)
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_TARGE_YEAR }}
          />
        );

      if (!targetFundValues)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (targetFundValues && targetFundValues <= 0)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.greaterThanZero} />
        );
      else if (targetFundValues > MAX_TARGET_FUND) {
        errors.targetFund = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{
              value: MAX_TARGET_FUND.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              ),
            }}
          />
        );
      }
    }

    if (hasToCalculate) {
      errors.hasToCalculate = (
        <FormattedMessage {...globalMessages.hasToCalculate} />
      );
    }

    if (isBundlingQuote) {
      if (coverages2 && coverages2.size > 0) {
        const coveragesError = [];
        var lfcInsuredOne = 0;
        coverages2.map(coverage => {
          const requiredCoverageFields = ['valueInsuredOne'];
          const coverageError = {};

          if (coverage.get('id') === 'LFC') {
            lfcInsuredOne = coverage.get('valueInsuredOne');
          }

          requiredCoverageFields.forEach(field => {
            if (coverage.get('selected')) {
              if (isFieldEmpty(coverage.get(field))) {
                coverageError[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
              } else if (coverage.get('type') === '1') {
                const minVal = getMinValueITA(
                  coverage.get('id'),
                  itaConfig.features,
                  currencyValues,
                );
                const maxVal = getMaxValueITA(
                  coverage.get('id'),
                  itaConfig.features,
                  currencyValues,
                );

                if (minVal > coverage.get(field)) {
                  coverageError[field] = (
                    <FormattedMessage
                      {...globalMessages.minAmountLimit}
                      values={{ value: minVal }}
                    />
                  );
                }

                if (maxVal < coverage.get(field)) {
                  coverageError[field] = (
                    <FormattedMessage
                      {...globalMessages.maxAmountLimit}
                      values={{ value: maxVal }}
                    />
                  );
                }

                if (
                  numeral(coverage.get('valueInsuredOne')).value() >
                  numeral(lfcInsuredOne).value()
                ) {
                  coverageError[field] = (
                    <FormattedMessage
                      {...globalMessages.accelerateValidation}
                    />
                  );
                }
              }
            }
          });
          coveragesError.push(coverageError);
        });

        if (!isEmpty(coveragesError)) errors.coverages2 = coveragesError;
      }
    }

    if (
      (paymentFrequencyValues === 'Half-Yearly' ||
        paymentFrequencyValues === 'Quarterly') &&
      isBundlingQuote
    ) {
      const foundSelectedBenefit = find(coverages2.toJS(), item => {
        return item?.selected;
      });
      if (foundSelectedBenefit) {
        errors.paymentFrequency = (
          <FormattedMessage {...messages.notAvailableWithITA} />
        );
      }
    }

    if (!Number(premiumValues)) {
      if (!targetFunctionalityValues)
        errors.premium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
    } else {
      var minVal = 0;
      var maxVal = 0;
      if (termValues === 'SinglePremium') {
        minVal = getMinValue('Single', gcConfig.features, currencyValues);
        maxVal = getMaxValue('Single', gcConfig.features, currencyValues);
      } else {
        minVal = getMinValue(
          paymentFrequencyValues,
          gcConfig.features,
          currencyValues,
        );
        maxVal = 999999999
        /*
         getMaxValue(
           paymentFrequencyValues,
           gcConfig.features,
           currencyValues,
         );*/
      }

      if (premiumValues < minVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minVal }}
          />
        );
      if (premiumValues > maxVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: maxVal }}
          />
        );
    }

    if (!growthRateValues)
      errors.growthRate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    else {
      if (growthRateValues <= MIN_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage {...globalMessages.minLimitGrowthRate} />
        );
      if (growthRateValues > MAX_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_GROWTH_RATE }}
          />
        );
    }

    //Regular withdrawal validation
    if (isRegularWithdrawalValues) {
      var errorRegularWithdrawal = {};
      if (
        !regularWithdrawalValues.get('frequency') ||
        regularWithdrawalValues.get('frequency') === 'Select'
      )
        errorRegularWithdrawal.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );

      if (
        !regularWithdrawalValues.get('startDate') ||
        !moment(regularWithdrawalValues.get('startDate')).isValid()
      )
        errorRegularWithdrawal.startDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var minStartDate = moment(startDateValues)
          .date(15)
          .add(1, 'month')
          .toDate();

        if (moment(regularStartDateValues, 'YYYY-MM-DD').format('DD') != 15)
          errorRegularWithdrawal.startDate = (
            <FormattedMessage {...globalMessages.dateValidation2} />
          );
        else {
          if (regularStartDateValues.isBefore(minStartDate))
            errorRegularWithdrawal.startDate = (
              <FormattedMessage
                {...globalMessages.dateValidation}
                values={{ value: moment(minStartDate).format('DD/MM/YYYY') }}
              />
            );
        }
      }

      if (
        !regularWithdrawalValues.get('endDate') ||
        !moment(regularWithdrawalValues.get('endDate')).isValid()
      )
        errorRegularWithdrawal.endDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularEndDateValue = moment(
          regularWithdrawalValues.get('endDate'),
        );
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var maxEndDate = moment(regularStartDateValues)
          .add(100, 'years')
          .toDate();

        if (regularEndDateValue.isBefore(regularStartDateValues))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.minEndDateValidation} />
          );

        if (regularEndDateValue.isAfter(maxEndDate))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.maxEndDateValidation} />
          );
      }

      if (regularWithdrawalValues.get('isAmountChecked') == 1) {
        if (!regularWithdrawalValues.get('amount'))
          errorRegularWithdrawal.amount = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const withdrawalAmount = regularWithdrawalValues.get('amount');
          var minPremiumAllowed = 0;
          var maxPremiumAllowed = 0;

          gcConfig?.features?.withdrawalSize?.map(premiumSize => {
            if (premiumSize.currency === currencyValues) {
              minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
            }
          });

          if (termValues === 'SinglePremium')
            maxPremiumAllowed = getMaxValue(
              'Single',
              gcConfig.features,
              currencyValues,
            );
          else
            maxPremiumAllowed = getMaxValue(
              paymentFrequencyValues,
              gcConfig.features,
              currencyValues,
            );

          if (withdrawalAmount < minPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPremiumAllowed }}
              />
            );

          if (withdrawalAmount > maxPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPremiumAllowed }}
              />
            );
        }
      }
      if (!isEmpty(errorRegularWithdrawal)) {
        errors.regularWithdrawal = errorRegularWithdrawal;
      }
    }

    //Education Fee withdrawal validation
    if (isEducationFeeWithdrawalValues) {
      var errorEducationFeesWithdrawal = {};
      if (
        !educationFeeWithdrawalValues.get('frequency') ||
        educationFeeWithdrawalValues.get('frequency') === 'Select'
      )
        errorEducationFeesWithdrawal.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );

      if (!educationFeeWithdrawalValues.get('startDate'))
        errorEducationFeesWithdrawal.startDate = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      else {
        var educationFeeStartDateValues = moment(
          educationFeeWithdrawalValues.get('startDate'),
        );
        var minStartDate = moment(startDateValues)
          .date(15)
          .add(1, 'month')
          .toDate();

        if (
          moment(educationFeeStartDateValues, 'YYYY-MM-DD').format('DD') != 15
        )
          errorEducationFeesWithdrawal.startDate = (
            <FormattedMessage {...globalMessages.dateValidation2} />
          );
        else {
          if (educationFeeStartDateValues.isBefore(minStartDate))
            errorEducationFeesWithdrawal.startDate = (
              <FormattedMessage
                {...globalMessages.dateValidation}
                values={{ value: moment(minStartDate).format('DD/MM/YYYY') }}
              />
            );
        }
      }

      if (!educationFeeWithdrawalValues.get('endDate'))
        errorEducationFeesWithdrawal.endDate = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      else {
        var educationFeeEndDateValue = moment(
          educationFeeWithdrawalValues.get('endDate'),
        );
        var educationFeeStartDateValues = moment(
          educationFeeWithdrawalValues.get('startDate'),
        );
        var maxEndDate = moment(regularStartDateValues)
          .add(100, 'years')
          .toDate();

        if (educationFeeEndDateValue.isBefore(educationFeeStartDateValues))
          errorEducationFeesWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.minEndDateValidation} />
          );

        if (educationFeeEndDateValue.isAfter(maxEndDate))
          errorEducationFeesWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.maxEndDateValidation} />
          );
      }

      if (!educationFeeWithdrawalValues.get('firstName')) {
        errorEducationFeesWithdrawal.firstName = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      } else {
        if (!isAlphaNumeric(educationFeeWithdrawalValues.get('firstName'))) {
          errorEducationFeesWithdrawal.firstName = (
            <FormattedMessage {...globalMessages.onlyAlphanumericCharacters} />
          );
        }
      }

      if (!educationFeeWithdrawalValues.get('lastName')) {
        errorEducationFeesWithdrawal.lastName = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      } else {
        if (!isAlphaNumeric(educationFeeWithdrawalValues.get('lastName'))) {
          errorEducationFeesWithdrawal.lastName = (
            <FormattedMessage {...globalMessages.onlyAlphanumericCharacters} />
          );
        }
      }

      if (educationFeeWithdrawalValues.get('isAmountChecked') == 1) {
        if (!educationFeeWithdrawalValues.get('amount'))
          errorEducationFeesWithdrawal.amount = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const withdrawalAmount = educationFeeWithdrawalValues.get('amount');
          var minPremiumAllowed = 0;
          var maxPremiumAllowed = 0;
          let withdrawalFrequencyValues = educationFeeWithdrawalValues.get(
            'frequency',
          );
          /*gcConfig.features.withdrawalSize.map(premiumSize => {
            if (premiumSize.currency === currencyValues) {
              minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
            }
          });*/

          if (termValues === 'SinglePremium')
            maxPremiumAllowed = getMaxValue(
              'Single',
              gcConfig.features,
              currencyValues,
            );
          else
            maxPremiumAllowed = getMaxValue(
              withdrawalFrequencyValues,
              gcConfig.features,
              currencyValues,
            );

          if (withdrawalAmount < minPremiumAllowed)
            errorEducationFeesWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPremiumAllowed }}
              />
            );

          /* if (withdrawalAmount > maxPremiumAllowed)
             errorEducationFeesWithdrawal.amount = (
               <FormattedMessage
                 {...globalMessages.maxWithdrawalValidation}
                 values={{ value: maxPremiumAllowed }}
               />
             );*/
        }
      }

      if (!isEmpty(errorEducationFeesWithdrawal)) {
        errors.educationFeesWithdrawal = {
          child1: errorEducationFeesWithdrawal,
        };
      }
    }
  }

  return errors;
};

export default validate;

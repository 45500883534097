import { validateOfferCodeAction } from 'containers/App/actions';

function asyncValidate(values, dispatch, props) {
  if (values.get('premium') !== '' && values.get('currency') !== 'Select') {
    if (values.get('offerCode') !== '') {
      return new Promise((resolve, reject) => {
        dispatch(
          validateOfferCodeAction({
            offerCode: values.get('offerCode'),
            productCode: props.product.PTVCode,
            productVersion: props.product.PTVVersion,
            currency: values.get('currency'),
            premium: values.get('premium'),
            premiumType: values.get('term'),
            resolve,
            reject,
          }),
        );
      });
    }
  }
  return new Promise(resolve => {
    dispatch(
      validateOfferCodeAction({
        offerCode: '',
        resolve,
      }),
    );
  });
}

export default asyncValidate;

import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  stroke: currentColor;
`;

function IconHome(props) {
  return (
    <SvgIcon viewBox="0 0 23 22" version="1.1" fontSize="inherit" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <GA transform="translate(-12.000000, -101.000000)">
          <g transform="translate(13.000000, 102.500000)">
            <polyline points="3 7 3 19 8.625 19 8.625 14.2941176 12.375 14.2941176 12.375 19 18 19 18 7" />
            <polyline points="3 7 3 1 6 1 6 4.55" />
            <rect x="8" y="7" width="4" height="4" />
            <polyline points="0 9 10.5 0 21 9" />
          </g>
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconHome;

/**
 *
 * FormSelect
 *
 */

import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import {
  RadioGroup,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  FormControlLabel,
} from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import OptRadio from './OptRadio';

const useStyle = makeStyles(() => ({
  radioButton: {
    '& .MuiFormControlLabel-label.Mui-disabled': {
      color: '#ffffff',
    },
    '&.MuiFormControlLabel-root.Mui-disabled': {
      backgroundColor: 'rgb(64 102 179 / 50%) !important',
    },
  },
}));

const FormBooleanOption = ({
  label,
  input,
  meta: { error, touched, warning },
  fullWidth,
  disabled,
  margin,
  shrink,
  ...custom
}) => {
  const theme = useTheme();
  const classes = useStyle();
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }
  return (
    <FormControl fullWidth={fullWidth} margin={margin}>
      <InputLabel disableAnimation shrink={shrink || false}>
        {label}
      </InputLabel>
      <RadioGroup
        {...input}
        onChange={e => {
          if (!disabled) {
            if (e.target.value === 'false') {
              return input.onChange(false);
            }
            return input.onChange(true);
          }
        }}
        {...custom}
        {...disabled}
        style={{
          marginTop: theme.spacing(2),
        }}
      >
        <Grid container>
          <Grid item style={{ marginRight: '1px' }}>
            <FormControlLabel
              value={true}
              disabled={disabled}
              control={<OptRadio color="primary" />}
              label={'Yes'}
              style={{
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(1.25),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                backgroundColor:
                  input.value === true
                    ? theme.palette.primary.main
                    : theme.palette.grey[200],
                color:
                  input.value === true
                    ? theme.palette.success.contrastText
                    : theme.palette.common.black,
                margin: 0,
              }}
            />
          </Grid>

          <Grid item style={{ marginLeft: '1px' }}>
            <FormControlLabel
              disabled={disabled}
              value={false}
              control={<OptRadio color="primary" />}
              label={'No'}
              style={{
                paddingTop: theme.spacing(1.25),
                paddingBottom: theme.spacing(1.25),
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
                backgroundColor:
                  input.value === false
                    ? theme.palette.primary.main
                    : theme.palette.grey[200],
                color:
                  input.value === false
                    ? theme.palette.success.contrastText
                    : theme.palette.common.black,
                margin: 0,
              }}
              className={classes.radioButton}
            />
          </Grid>
        </Grid>
      </RadioGroup>
      {helperMsg && <FormHelperText error>{helperMsg}</FormHelperText>}
    </FormControl>
  );
};

FormBooleanOption.propTypes = {
  ...WrappedFieldProps,
};

export default FormBooleanOption;

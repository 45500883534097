/*
 *
 * Futura/WAP/SW InForceIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/InForceIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const CHANGE_COVERAGE_ACTION =
  'app/InForceIllustrationPage/CHANGE_COVERAGE_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/InForceIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const GET_FUTURA_CONFIG_ACTION =
  'app/InForceIllustrationPage/GET_FUTURA_CONFIG_ACTION';
export const SET_FUTURA_CONFIG_ACTION =
  'app/InForceIllustrationPage/SET_FUTURA_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION =
  'app/InForceIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/InForceIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/InForceIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/InForceIllustrationPage/SET_CALCULATION_ACTION';
export const SET_ASP_ACTION =
  'app/InForceIllustrationPage/SET_ASP_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/InForceIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/InForceIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/InForceIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/InForceIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/InForceIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/InForceIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/InForceIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_PREMIUM_TERM_ACTION =
  'app/InForceIllustrationPage/SET_PREMIUM_TERM_ACTION';
export const SET_ERROR_ACTION = 'app/InForceIllustrationPage/SET_ERROR_ACTION';
export const SET_BI_TABLE_VALUES_ACTION =
  'app/InForceIllustrationPage/SET_BI_TABLE_VALUES_ACTION';
export const RESET_ACTION = 'app/InForceIllustrationPage/RESET_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/InForceIllustrationPage/SET_PTV_VERSION_ACTION';
export const GET_FIA_ACTION = 'app/InForceIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/InForceIllustrationPage/SET_FIA_ACTION';
export const SET_MINPREMIUM_ACTION =
  'app/InForceIllustrationPage/SET_MINPREMIUM_ACTION';
export const CLONE_QUOTE_ACTION =
  'app/InForceIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/InForceIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION =
  'app/InForceIllustrationPage/SET_BI_PDF_ACTION';
export const SET_EMAIL_SENT_ACTION =
  'app/InForceIllustrationPage/SET_EMAIL_SENT_ACTION';
export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/InForceIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';
export const SET_COMPLETED_DIALOG =
  'app/InForceIllustrationPage/SET_COMPLETED_DIALOG';
export const SET_IS_CALCULATE_QUOTE_PAGE_ACTION =
  'app/InForceIllustrationPage/SET_IS_CALCULATE_QUOTE_PAGE_ACTION';
export const SET_PRODUCT_CODE_ACTION =
  'app/InForceIllustrationPage/SET_PRODUCT_CODE_ACTION';
export const SET_POLICY_CONFIG_ACTION =
  'app/InForceIllustrationPage/SET_POLICY_CONFIG_ACTION';
export const SET_HARVEST_DATA_ACTION =
  'app/InForceIllustrationPage/SET_HARVEST_DATA_ACTION';
export const RECALCULATE_SUSTAINABILITY_ACTION =
  'app/InForceIllustrationPage/RECALCULATE_SUSTAINABILITY';
export const SET_SUSTAINABILITY_YEAR_ACTION =
  'app/InForceIllustrationPage/SET_SUSTAINABILITY_YEAR_ACTION';
export const DOWNLOAD_HEALTH_CHECK_PDF_ACTION =
  'app/InForceIllustrationPage/DOWNLOAD_HEALTH_CHECK_PDF_ACTION';
export const SET_INIT_COVERAGES_ACTION =
  'app/InForceIllustrationPage/SET_INIT_COVERAGES_ACTION';
export const SET_INIT_PREMIUM_SLICES_ACTION =
  'app/InForceIllustrationPage/SET_INIT_PREMIUM_SLICES_ACTION';
export const SET_PREMIUM_SLICES_ACTION =
  'app/InForceIllustrationPage/SET_PREMIUM_SLICES_ACTION';
export const SET_GROWTH_RATE_ACTION =
  'app/InForceIllustrationPage/SET_GROWTH_RATE_ACTION';
export const SET_SHOW_WARNING_REVIEW =
  'app/InForceIllustrationPage/SET_SHOW_WARNING_REVIEW';
export const SET_POLICY_TERM = 'app/InForceIllustrationPage/SET_POLICY_TERM';
export const SET_MANUAL_PREMIUM =
  'app/InForceIllustrationPage/SET_MANUAL_PREMIUM';
export const RESET_PRODUCT_CONFIGS_ACTION =
  'app/InForceIllustrationPage/RESET_PRODUCT_CONFIGS_ACTION';
export const SET_INIT_COVERAGES_LIST_ACTION =
  'app/InForceIllustrationPage/SET_INIT_COVERAGES_LIST_ACTION';
export const SET_LAST_ACTION_EDIT_QUOTE =
  'app/InForceIllustrationPage/SET_LAST_ACTION_EDIT_QUOTE';
export const SET_POLICY_TERM_HARVEST =
  'app/InForceIllustrationPage/SET_POLICY_TERM_HARVEST';
  export const SET_COUNT_GET_SUSTAIN_AGE_ACTION = 'app/InForceIllustrationPage/SET_COUNT_GET_SUSTAIN_AGE_ACTION';
export const lifeText = {
  SL: 'ofLifeInsured',
  JLFD: 'ofOlderLife',
  JLBD: 'ofYoungerLife',
  JLLS: 'ofYoungerLife',
  JLLD: 'ofYoungerLife',
};

export const GET_QUOTE_CREATED = 1;
export const GET_QUOTE_POLICY = 2;
export const WHOLE_OF_LIFE = 'Whole of life';
export const SINGLE_PREMIUM = 'Single premium';
export const WHOLE_OF_LIFE_VALUE = 96;
export const JLFD = 'JLFD';
export const JLLD = 'JLLD';
export const JLBD = 'JLBD';
export const OLDER_FUTURA = [
  'FUTUR',
  'FUTU2',
  'FUTU3',
  'CTFUT',
  'CTFU2',
  'CTFU3',
  'SCFUT',
];
export const statusInForce = 'In Force';
export const statusLapsed = 'Lapsed';
export const statusPaidUp = 'Paid Up';
export const ACTION_EDIT_QUOTE = {
  GET_PREMIUM_WOL: 1,
  GET_SUSTAIN_AGE: 2,
  SAVE_QUOTE: 3,
  GET_ASP_WOL: 4,
};
export const SET_LIFE_ASSURED_HARVEST =
  'app/InForceIllustrationPage/SET_LIFE_ASSURED_HARVEST';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isString } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import moment from 'moment';
import { dobFormat } from 'helpers/constants';
import { calculateAgeNextBirthdate } from '../../helper';

const validate = (values, props) => {
  const errors = {};
  const { configsDetail } = props;

  const minEntryAge = configsDetail?.features?.ageAtEntry?.minEntryAgeLA;
  const maxEntryAge = configsDetail?.features?.ageAtEntry?.maxEntryAgeLA;

  if (!values.isEmpty()) {
    // INSURED ONE
    const insuredOneValues = values.get('insuredOne');
    const isInsuredTwoValues = values.get('isInsuredTwo');
    const insuredTwoValues = values.get('insuredTwo');
    const isPolicyOwnerIndividualValues = values.get('isPolicyOwnerIndividual');

    const requiredInsuredOneFields = [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
      'isSmoke',
      'nationality',
    ];
    const insuredOneError = {};
    if (insuredOneValues && !insuredOneValues.isEmpty()) {
      requiredInsuredOneFields.forEach(field => {
        if (isFieldEmpty(insuredOneValues.get(field))) {
          if (
            field === 'gender' ||
            field === 'isSmoke' ||
            field === 'nationality'
          )
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          else
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
        }
        if (field === 'nationality') {
          if (insuredOneValues.get(field) === 'Select an option') {
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        }

        if (field === 'dateOfBirth') {
          if (isFieldEmpty(insuredOneValues.get(field))) {
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          } else {
            if (!moment(insuredOneValues.get(field)).isValid()) {
              insuredOneError[field] = (
                <FormattedMessage {...globalMessages.invalidDate} />
              );
            }

            const anb = calculateAgeNextBirthdate(
              moment(),
              insuredOneValues.get(field),
            );

            if (anb < minEntryAge)
              insuredOneError[field] = (
                <FormattedMessage
                  {...globalMessages.minAgeValidation}
                  values={{ value1: minEntryAge - 1 }}
                />
              );

            if (anb > maxEntryAge)
              insuredOneError[field] = (
                <FormattedMessage
                  {...globalMessages.maxAgeValidation}
                  values={{ value1: maxEntryAge - 1 }}
                />
              );
          }
        }
      });

      if (!isPolicyOwnerIndividualValues) {
        if (isInsuredTwoValues) {
          if (
            !insuredOneValues.get('isPolicyOwner') &&
            !insuredTwoValues.get('isPolicyOwner')
          ) {
            insuredOneError.isPolicyOwner = (
              <FormattedMessage {...globalMessages.atleastOnePolicyOwner} />
            );
          }
        } else if (!insuredOneValues.get('isPolicyOwner')) {
          insuredOneError.isPolicyOwner = (
            <FormattedMessage {...globalMessages.atleastOnePolicyOwner} />
          );
        }
      }
    }
    if (!isEmpty(insuredOneError)) errors.insuredOne = insuredOneError;

    if (isInsuredTwoValues) {
      // INSURED TWO
      const requiredInsuredTwoFields = [
        'firstName',
        'lastName',
        'dateOfBirth',
        'gender',
        'isSmoke',
        'nationality',
      ];
      const insuredTwoError = {};
      if (insuredTwoValues && !insuredTwoValues.isEmpty()) {
        requiredInsuredTwoFields.forEach(field => {
          if (isFieldEmpty(insuredTwoValues.get(field))) {
            if (
              field === 'gender' ||
              field === 'isSmoke' ||
              field === 'nationality'
            )
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
            else
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
          }
          if (field === 'nationality') {
            if (insuredTwoValues.get(field) === 'Select an option') {
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            }
          }

          if (field === 'dateOfBirth') {
            if (isFieldEmpty(insuredTwoValues.get(field))) {
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            } else {
              if (!moment(insuredTwoValues.get(field)).isValid()) {
                insuredTwoError[field] = (
                  <FormattedMessage {...globalMessages.invalidDate} />
                );
              }

              const anb = calculateAgeNextBirthdate(
                moment(),
                insuredTwoValues.get(field),
              );

              if (anb < minEntryAge)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.minAgeValidation}
                    values={{ value1: minEntryAge - 1 }}
                  />
                );

              if (anb > maxEntryAge)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxAgeValidation}
                    values={{ value1: maxEntryAge - 1 }}
                  />
                );
            }
          }
        });
      }
      if (!isEmpty(insuredTwoError)) errors.insuredTwo = insuredTwoError;
    }
  }
  return errors;
};

export default validate;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import { Grid, Box, Typography, Link } from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import { newBankAccount } from 'helpers/placeholder';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import { changePlaceHolderColor } from 'helpers/lang';

const useStyle = makeStyles(() => ({
  button: {
    fontWeight: 800,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '1.4rem',
    transform: 'none',
    fontWeight: 'bold',
    transformOrigin: 'top left',
    marginBottom: 5,
  },
  titleAccount: {
    marginTop: 15,
    fontSize: 14,
    color: '#B6B8B4',
  },
  hr: {
    marginBottom: 20,
    marginTop: 24,
    borderTop: '1px solid #DDDFE0',
  },
}));

function OtherBankAccount(props) {
  const intl = useIntl();
  const classes = useStyle();

  const { fields, formValues, options } = props;

  const addBankAccount = () => {
    fields.push({ ...newBankAccount });
  };

  return fields.map((field, index) => {
    return (
      <Grid container>
        <React.Fragment>
          {index > 0 && (
            <Grid item xs={12}>
              <hr className={classes.hr} />
            </Grid>
          )}
          <React.Fragment>
            <Grid item xs={8} container spacing={3}>
              <Grid item xs={8}>
                <Field
                  name={`${field}.bankName`}
                  style={{
                    width: 247,
                    color: changePlaceHolderColor(
                      formValues ? formValues[index]?.bankName : '',
                    ),
                  }}
                  options={[
                    { value: 'Select an option', title: 'Select an option' },
                    ...options,
                  ]}
                  component={FormSelect}
                  label={<FormattedMessage {...messages.bankName} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  name={`${field}.cardNumber`}
                  style={{ width: 355 }}
                  component={FormNumberField}
                  thousandSeparator={''}
                  label={<FormattedMessage {...messages.accountNumber} />}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                />
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 24 }}>
              <Typography className={classes.subTitle}>
                <FormattedMessage {...messages.accountHeld} />
              </Typography>
            </Grid>
            <Grid item xs={9} spacing={2} container>
              <Grid item xs={4}>
                <Field
                  name={`${field}.yearNumber`}
                  style={{ width: 134 }}
                  component={FormNumberField}
                  thousandSeparator={''}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={2} className={classes.titleAccount}>
                Years
              </Grid>
              <Grid item xs={4}>
                <Field
                  name={`${field}.monthNumber`}
                  style={{ width: 134 }}
                  component={FormNumberField}
                  thousandSeparator={''}
                  placeholder={intl.formatMessage(messages.pleaseEnter)}
                  type="text"
                  color="primary"
                  margin="normal"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={2} className={classes.titleAccount}>
                Months
              </Grid>
            </Grid>
            <Grid item xs={3} spacing={2}>
              <div className={classes.buttonContainer}>
                <Grid container spacing={0}>
                  {fields.length === index + 1 && fields.length < 2 && (
                    <Grid item xs={12}>
                      <Box mt={2}>
                        <Link
                          className={classes.button}
                          onClick={addBankAccount}
                        >
                          Add another
                        </Link>
                      </Box>
                    </Grid>
                  )}
                  {fields.length === 1 ? (
                    ''
                  ) : (
                    <Grid item xs={6}>
                      <Box mt={2}>
                        <Link
                          className={classes.button}
                          onClick={() => {
                            fields.remove(index);
                          }}
                        >
                          Delete
                        </Link>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </React.Fragment>
        </React.Fragment>
      </Grid>
    );
  });
}

OtherBankAccount.propTypes = {
  fields: PropTypes.object,
  formValues: PropTypes.object,
  isBankAccount: PropTypes.bool,
  options: PropTypes.object,
};

export default OtherBankAccount;

import {
  call,
  select,
  all,
  takeLatest,
  put,
  takeEvery,
  delay,
} from 'redux-saga/effects';
import { forEach, groupBy } from 'lodash/collection';
import { api } from 'environments';
import { fromJS } from 'immutable';
import request from 'utils/request';
import { setFileUWAction } from 'containers/ApplicationFormPage/actions';
import { getAppToken } from 'containers/OktaAuthCallback/saga';
import { setOktaTokenAction } from 'containers/OktaAuthCallback/actions';
import { getProductConfig } from 'containers/IllustrationPage/saga';
import { getProductConfigAction } from 'containers/IllustrationPage/actions';
import {
  OPTIONS_API_KEYS,
  GET_RESOURCES_ACTION,
  mapOptionsResponseToDisplay,
  getStateOptionKey,
  FETCH_AGENT_DETAILS_ACTION,
  CHANGE_SELECTED_AGENCY_ACTION,
  GET_PERSISTED_AUTH_ACTION,
  CREATE_FILE,
  VALIDATE_OFFER_CODE_ACTION,
  QUOTE_TYPE,
} from './constants';
import {
  setResourceOptionsAction,
  setAppLoadingAction,
  setAgentDetailsAction,
  setAuthAction,
  getPersistedAuthSuccessAction,
  changeSelectedAgencyAction,
  setSelectedAgencyAction,
  setFileAction,
  setFinancialInstituteOptionAction,
  validateOfferCodeSuccessAction,
  setOfferCodeErrorAction,
  resetOfferCodeAction,
  setOfferCodeValidatingAction,
  setQuoteTypeAction,
} from './actions';
import { makeSelectAuth, makeSelectSelectedAgency } from './selectors';
import { productCodeCheck } from 'helpers/constants';

export function* setAuthPersistance() {
  try {
    const { okta } = yield select(makeSelectAuth());
    let selectedAgency = sessionStorage.getItem('selectedAgency');
    const userType = sessionStorage.getItem('userType');
    const emailId = sessionStorage.getItem('emailId');
    if (selectedAgency === null || selectedAgency === 'null') {
      selectedAgency = 0;
    }
    // const selectedAgency = yield select(makeSelectSelectedAgency());
    // only save the token and tenantId
    const stringifiedAuth = JSON.stringify({
      oktaAccessToken: okta.accessToken,
      selectedAgency,
      userType,
      emailId,
    });

    sessionStorage.setItem('oscarAuth', stringifiedAuth);
  } catch (err) {
    // TODO: handle failed sessionStorage setting
  }
}

export function* getPersistedAuth() {
  try {
    let persistedAuth = sessionStorage.getItem('oscarAuth');
    if (persistedAuth) {
      persistedAuth = JSON.parse(persistedAuth);
      yield call(
        getAppToken,
        setOktaTokenAction({ oktaAccessToken: persistedAuth.oktaAccessToken }),
      );
      yield put(setSelectedAgencyAction(persistedAuth.selectedAgency));
    }
  } catch (err) {
    // yield put(getPersistedAuthSuccessAction(currentAuth));
  } finally {
    yield put(getPersistedAuthSuccessAction());
  }
}

export function* getResources() {
  try {
    // get dropdown options
    const optionsReqs = [];
    /* eslint-disable redux-saga/yield-effects */
    optionsReqs.push(call(getAllResourceOptions));
    yield all([...optionsReqs]);
  } catch (err) {
    console.log(err);
  }
}

export function* getAllResourceOptions() {
  try {
    let index = 0;
    yield put(setAppLoadingAction(true));
    // const { agent, okta } = yield select(makeSelectAuth());
    let endpoint = `${api.host}/api/v1/b2b/optional-resources/by-group?groups=`;

    forEach(OPTIONS_API_KEYS, (optKey, index) => {
      let optionApiKey = optKey;
      if (index > 0) {
        optionApiKey = `,${optionApiKey}`;
      }
      endpoint = `${endpoint}${optionApiKey}`;
    });

    const requestOpt = {
      method: 'GET',
      headers: {
        'Ocp-Apim-Subscription-Key': api.subKey,
        // Authorization: `Bearer ${okta.accessToken}`,
      },
    };

    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      yield put(setAppLoadingAction(false));
      const grouped = groupBy(response.data, optRes => optRes.group);
      const final = {};
      final['payorRelationshipReason'] = [
        { value: 'UNEMPLOYED', title: 'Unemployed' },
        {
          value: 'NO BANK ACCOUNT / CREDIT CARD',
          title: 'No bank account / Credit Card',
        },
        {
          value: 'RELOCATED TO DIFFERENT COUNTRY',
          title: 'Relocated to different country',
        },
        { value: 'GIFT', title: 'Gift' },
        { value: 'STUDENT', title: 'Student' },
        { value: 'NO BANK ACCOUNT', title: 'No bank account' },
        {
          value: 'BENEFIT AS PART OF COMPANY POLICY',
          title: 'Benefit as part of company policy',
        },
        { value: 'OTHERS', title: 'Others' },
      ];
      // end
      forEach(grouped, (optArr, group) => {
        const stateKey = getStateOptionKey(group);
        const options = mapOptionsResponseToDisplay(stateKey, optArr);
        final[stateKey] = options;
      });

      // final['wop'] = [{ value: 'Yes', title: 'Yes' }, { value: 'No', title: 'No' }];
      yield put(setResourceOptionsAction(final));
    }
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getAgencyDetails(action) {
  try {
    yield put(setAppLoadingAction(true));
    yield call(setAuthPersistance);
    const { agent, okta } = yield select(makeSelectAuth());
    let selectedAgency = yield select(makeSelectSelectedAgency());
    if (action?.type === CHANGE_SELECTED_AGENCY_ACTION) {
      selectedAgency = action.payload;
    }
    const endpoint = `${api.host}/api/v${api.version}/b2b/auth/select-agent`;
    const requestOpt = {
      method: 'POST',
      headers: {
        'Ocp-Apim-Subscription-Key': api.subKey,
        'Content-Type': 'application/json',
        Authorization: `Bearer ${okta.accessToken}`,
      },
      body: JSON.stringify({
        agentNumber: agent[selectedAgency].agentNumber,
      }),
    };
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      sessionStorage.setItem('agencySelected', response.data.agentNumber);
      sessionStorage.removeItem('financial-institute');
      yield put(setAgentDetailsAction(response.data));
      const isAvailableNB = response.data.agentStatus !== 'B';
      yield put(
        setQuoteTypeAction(
          isAvailableNB
            ? QUOTE_TYPE.NEW_BUSINESS
            : QUOTE_TYPE.EXISTING_BUSINESS,
        ),
      );
      yield call(financialInstitute);
    }
  } catch (err) {
    // TODO: handle error response
    // {
    //   "error": true,
    //   "message": "USER_NOT_ASSIGN_TO_THIS_AGENT"
    // }
  } finally {
    yield put(setAppLoadingAction(false));
  }
}

export function* followUpLogin({
  okta,
  agent,
  emailId,
  productCode,
  productVersion,
  agencyId,
  isAdmin,
  agenciesAssociated,
  region,
  userType,
}) {
  try {
    let sessionSelectedAgency = sessionStorage.getItem('selectedAgency');
    let reduxSelectedAgency = yield select(makeSelectSelectedAgency());
    let selectedAgency =
      sessionSelectedAgency > reduxSelectedAgency
        ? sessionSelectedAgency
        : reduxSelectedAgency;
    sessionStorage.setItem('userType', userType);
    sessionStorage.setItem('emailId', emailId);
    let persistedAuth = sessionStorage.getItem('oscarAuth');
    if (persistedAuth) {
      persistedAuth = JSON.parse(persistedAuth);
      selectedAgency = sessionSelectedAgency
        ? sessionSelectedAgency
        : persistedAuth.selectedAgency;
    }
    if (agencyId) {
      selectedAgency = agent.findIndex(element => {
        return element.agentNumber === agencyId;
      });
      yield put(changeSelectedAgencyAction(selectedAgency));
    }
    if (isAdmin === true) {
      sessionStorage.setItem('isOps', true);
      sessionStorage.setItem('AgenciesAssociatedForAdmin', agenciesAssociated);
    } else {
      sessionStorage.setItem('isOps', '');
      sessionStorage.setItem('AgenciesAssociatedForAdmin', '');
    }

    yield put(
      setAuthAction({
        agent,
        okta,
        userType,
        emailId,
      }),
    );
    yield call(setAuthPersistance);
    yield call(getAgencyDetails, changeSelectedAgencyAction(selectedAgency));
    yield call(financialInstitute);

    // Integrate from DA
    if (productCode && productVersion) {
      yield call(
        getProductConfig,
        getProductConfigAction({ productCode, productVersion }),
      );
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(getPersistedAuthSuccessAction());
  }
}

export function* financialInstitute() {
  try {
    const financialInstituteOptions = sessionStorage.getItem(
      'financial-institute',
    );

    if (financialInstituteOptions === null) {
      const auth = yield select(makeSelectAuth());
      const endpoint = `${api.host}/api/v${api.version}/b2b/application/financial-institutes`;
      const requestOpt = {
        method: 'GET',
        headers: {
          'Ocp-Apim-Subscription-Key': api.subKey,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.okta.accessToken}`,
        },
      };

      const response = yield call(request, endpoint, requestOpt);
      sessionStorage.setItem(
        'financial-institute',
        JSON.stringify(response.data),
      );
      yield put(setFinancialInstituteOptionAction(response.data));
    } else {
      yield put(
        setFinancialInstituteOptionAction(
          JSON.parse(financialInstituteOptions),
        ),
      );
    }
  } catch (err) {
    console.log(err);
  }
}

export function* createFile(payload) {
  const auth = yield select(makeSelectAuth());
  const {
    type,
    name,
    applicationId,
    isSummary = false,
    isDecision = false,
  } = payload.payload;
  const endpoint = `${api.host}/api/v${api.version}/b2b/storage/create`;

  const updatedPayload = {
    type,
    applicationId: applicationId || null,
    isSummary,
    name: name || null,
  };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
    body: JSON.stringify(updatedPayload),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);

    if (response) {
      if (isDecision) {
        yield put(setFileUWAction(fromJS(response)));
      } else {
        yield put(setFileAction(fromJS(response)));
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export function* validateOfferCode(action) {
  yield delay(500);
  const {
    offerCode,
    productCode,
    productVersion,
    premium = 0,
    currency = '',
    premiumType = '',
    resolve,
    reject,
  } = action.payload;

  try {
    if (offerCode !== '' && offerCode !== undefined) {
      const auth = yield select(makeSelectAuth());
      yield put(setOfferCodeValidatingAction(false));
      const endpoint = `${api.host}/api/v${api.version}/b2b/product/get-offer-code?offerCode=${offerCode}&productCode=${productCode}&productVersion=${productVersion}&currency=${currency}&premium=${premium}&premiumType=${premiumType}`;
      const requestOpt = {
        method: 'GET',
        headers: {
          'Ocp-Apim-Subscription-Key': api.subKey,
          'Content-Type': 'application/json',
          Authorization: `Bearer ${auth.okta.accessToken}`,
        },
      };

      const response = yield call(request, endpoint, requestOpt);
      yield put(
        validateOfferCodeSuccessAction({
          ...response.data,
          validating: false,
          errorMessage: '',
        }),
      );
      resolve && resolve();
    } else {
      yield put(resetOfferCodeAction());
      resolve && resolve();
    }
  } catch (err) {
    console.log('err', err);
    const response = yield err.response.json();
    // if (response.message) {
    //   yield put(resetOfferCodeAction());
    // }
    let error = '';
    switch (response.message) {
      case 'OFFER_CODE_NOT_FOUND': {
        error = 'Invalid offer code - no discount applied';
        yield put(setOfferCodeErrorAction(error));
        reject &&
          reject({
            offerCode: error,
          });
        break;
      }
      case 'PREMIUM_OUT_OF_OFFER_RANGE': {
        error = `${
          productCodeCheck(2, productCode) ? 'Life cover' : 'Premium'
        } out of this offer range`;
        yield put(setOfferCodeErrorAction(error));
        reject &&
          reject({
            offerCode: error,
          });
        break;
      }
      case 'OFFER_CODE_NOT_APPLY_FOR_THIS_CURRENCY': {
        error = `Offer code not apply for ${currency} currency`;
        yield put(setOfferCodeErrorAction(error));
        reject &&
          reject({
            offerCode: error,
          });
        break;
      }
      case 'OFFER_CODE_OUT_OF_DATE': {
        error = `Offer code out of date`;
        yield put(setOfferCodeErrorAction(error));
        reject &&
          reject({
            offerCode: error,
          });
        break;
      }
      case 'OFFFER_CODE_INVALID_TERM': {
        error = `Policy term selected is invalid`;
        yield put(setOfferCodeErrorAction(error));
        reject &&
          reject({
            offerCode: error,
          });
        break;
      }
      default:
        break;
    }
  } finally {
    yield put(setOfferCodeValidatingAction(false));
  }
}

// Individual exports for testing
export default function* appSaga() {
  yield all([
    takeLatest(GET_RESOURCES_ACTION, getResources),
    takeLatest(
      [FETCH_AGENT_DETAILS_ACTION, CHANGE_SELECTED_AGENCY_ACTION],
      getAgencyDetails,
    ),
    takeLatest(GET_PERSISTED_AUTH_ACTION, getPersistedAuth),
    takeEvery(CREATE_FILE, createFile),
    takeLatest(VALIDATE_OFFER_CODE_ACTION, validateOfferCode),
  ]);
}
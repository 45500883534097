import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { WrappedFieldArrayProps } from 'redux-form';
import { formatMonetaryValue } from 'helpers/math';

const Benefits = ({ formValues = {}, years, gcConfig, change }) => {
  const theme = useTheme();

  return (
    <Box bgcolor={theme.palette.primary.dark}>
      <Grid container justify="space-between" alignItems="center" wrap="nowrap">
        <Grid
          item
          xs={4}
          style={{
            padding: `${theme.spacing(2)}px`,
            borderRight: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="body1"
            style={{ color: theme.palette.common.white }}
          >
            {formatMonetaryValue(formValues['Premiums paid'], false)}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            padding: `${theme.spacing(2)}px`,
            borderRight: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="body1"
            style={{ color: theme.palette.common.white }}
          >
            {years}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            padding: `${theme.spacing(2)}px`,
            borderRight: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="body1"
            align="right"
            style={{ color: theme.palette.common.white, fontWeight: 'bold' }}
          >
            {formatMonetaryValue(Math.round(formValues['Fund Value']), false)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  formValues: PropTypes.object,
  theme: PropTypes.object,
  gcConfig: PropTypes.object,
  years: PropTypes.number,
  change: PropTypes.func,
};

export default Benefits;

import { withStyles } from '@material-ui/core/styles';
import { WrappedFieldProps } from 'redux-form';
import PropTypes from 'prop-types';
import { FormControlLabel as BaseControlLabel } from '@material-ui/core';

const FormControlLabel = withStyles((theme, isFirst) => ({
  root: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: '#f2f2f2',
    borderTopLeftRadius: isFirst ? 6 : 0,
    borderBottomLeftRadius: isFirst ? 6 : 0,
    width: '100%',
    height: '100%',
    margin: 0,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.success.contrastText,
    },
  },
}))(BaseControlLabel);

FormControlLabel.propTypes = {
  ...WrappedFieldProps,
  isFirst: PropTypes.bool,
};

export default FormControlLabel;

/**
 *
 * Home
 *
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose } from 'redux';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import messages from 'containers/IllustrationPage/messages';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { dimension } from 'styles/constants';
import Dialog from 'components/Dialog';
import {
  // makeSelectAuth,
  makeSelectAgentDetails,
  makeSelectSelectedAgency,
} from 'containers/App/selectors';
import { filterIllustrations } from 'helpers/quote';
import { QUOTE_TYPE } from 'containers/App/constants';
import { resetOfferCodeAction } from 'containers/App/actions';
import {
  getCustomersAction,
  resetProductConfigAction,
  fetchIllustrationsAction,
  getSearchIllustrationsAction,
  deleteIllustrationAction,
} from 'containers/IllustrationPage/actions';
import TextField from 'components/TextField';

import Search from 'icons/Search';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { makeSelectCustomers, makeSelectIllustrations } from '../selectors';
import { TableIllustration } from './TableIllustration';

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 ${props => props.theme.spacing(2)}px
      ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  }
`;
const useStyles = makeStyles({
  illustrationHeader: {
    position: 'fixed',
    // height: 'fit-content', // Safari bugs
    backgroundColor: '#ffffff',
    paddingBottom: 52,
    width: `calc(100% - 222px)`,
    top: 0,
    paddingTop: 30,
    '@media (max-width:919px)': {
      width: `calc(100% - 68px)`,
    },
  },
  btnSearch: {
    marginTop: 32,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleIllustrations: {
    fontWeight: 300,
    fontSize: 28,
    color: '#333',
  },
  icon: {
    color: '#dddfe0',
  },
  widthSearch: {
    padding: 0,
  },
});

export function Home({
  // auth,
  getProductConfig,
  resetProductConfig,
  illustrations,
  agentDetails,
  parentMounted,
  quoteType,
  getSearchIllustrations,
  deleteIllustration,
  resetOfferCode,
}) {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [valueSearch, setValueSearch] = useState('');
  const [productInfo, setProductInfo] = useState({});
  const history = useHistory();
  const filteringIllustrations = filterIllustrations(
    illustrations,
    quoteType,
    agentDetails.products,
  );

  useEffect(() => {
    if (parentMounted) {
      resetProductConfig();
    }
  }, [parentMounted]);

  const handleSearch = value => {
    setValueSearch(value);
  };

  const redirectToCustomers = () => {
    history.push('/illustrations/customer');
  };

  const searchIllustrations = () => {
    const agencyNumber = agentDetails.agentNumber;
    const data = { agencyNumber, valueSearch };
    getSearchIllustrations(data);
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      searchIllustrations();
    }, 500);
    return () => clearTimeout(timer);
  }, [valueSearch]);

  return (
    <>
      <Wrapper theme={theme}>
        <Grid className={classes.illustrationHeader}>
          <Typography variant="h1" className={classes.titleIllustrations}>
            <FormattedMessage {...messages.header} />
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                margin="normal"
                disabled={quoteType === QUOTE_TYPE.EXISTING_BUSINESS}
                style={{
                  marginTop: dimension.spacing.m,
                  padding: '18px 30px',
                }}
                onClick={() => setOpen(true)}
              >
                <FormattedMessage {...messages.newCustomer} />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                variant="outlined"
                color="primary"
                margin="normal"
                style={{
                  marginTop: dimension.spacing.m,
                  padding: '17px 22px',
                }}
                onClick={redirectToCustomers}
              >
                <FormattedMessage {...messages.existingCustomer} />
              </Button>
            </Grid>
            <Grid item xs className={classes.btnSearch}>
              <TextField
                className={classes.widthSearch}
                value={valueSearch}
                onChange={event => {
                  handleSearch(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={searchIllustrations}
                    >
                      <IconButton>
                        <Search className={classes.icon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <TableIllustration
          dataIllustrations={filteringIllustrations}
          deleteIllustration={deleteIllustration}
        />
      </Wrapper>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          resetOfferCode();
          return setProductInfo({});
        }}
        title={<FormattedMessage {...messages.selectAProduct} />}
        variant="info"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
            style={{ padding: 25 }}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                disabled={isEmpty(productInfo)}
                onClick={() => {
                  setOpen(false);
                  resetOfferCode();
                  return getProductConfig(productInfo);
                }}
              >
                <FormattedMessage {...messages.choose} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Grid container spacing={1}>
          {agentDetails.products &&
            agentDetails.products.map((product, index) => {
              const key = `prod${index}`;
              return (
                <Grid item xs={4} key={key}>
                  <Button
                    variant="outlined"
                    color="default"
                    size="large"
                    fullWidth
                    onClick={() => {
                      setProductInfo(product);
                      // setOpen(false);
                      // return getProductConfig(product);
                    }}
                    style={{
                      padding: theme.spacing(2),
                      borderRadius: '6px',
                      fontWeight: 'normal',
                      height: 90,
                      border:
                        productInfo.productId === product.productId &&
                        productInfo.PTVVersion === product.PTVVersion
                          ? '2px solid #4066b3'
                          : '',
                    }}
                  >
                    {product?.name || ''}
                  </Button>
                </Grid>
              );
            })}
        </Grid>
      </Dialog>
    </>
  );
}
Home.propTypes = {
  getProductConfig: PropTypes.func,
  // auth: PropTypes.object,
  selectedAgency: PropTypes.string,
  resetProductConfig: PropTypes.func,
  illustrations: PropTypes.array,

  agentDetails: PropTypes.object,
  parentMounted: PropTypes.object,
  getSearchIllustrations: PropTypes.func,
  deleteIllustration: PropTypes.func,
  resetOfferCode: PropTypes.func,
};
const mapStateToProps = createStructuredSelector({
  // auth: makeSelectAuth(),
  selectedAgency: makeSelectSelectedAgency(),
  agentDetails: makeSelectAgentDetails(),
  illustrations: makeSelectIllustrations(),
  customers: makeSelectCustomers(),
});
function mapDispatchToProps(dispatch) {
  return {
    resetProductConfig: () => dispatch(resetProductConfigAction()),
    fetchIllustrations: value => dispatch(fetchIllustrationsAction(value)),
    getCustomers: value => dispatch(getCustomersAction(value)),
    getSearchIllustrations: valueSearch =>
      dispatch(getSearchIllustrationsAction(valueSearch)),
    deleteIllustration: data => dispatch(deleteIllustrationAction(data)),
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(Home);

/*
 *
 * DtaIllustrationPage constants
 *
 */
export const CHANGE_LIFE_ASSURED_ACTION =
  'app/DtaIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/DtaIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/DtaIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const GET_DTA_CONFIG_ACTION =
  'app/DtaIllustrationPage/GET_DTA_CONFIG_ACTION';
export const SET_DTA_CONFIG_ACTION =
  'app/DtaIllustrationPage/SET_DTA_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION =
  'app/DtaIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/DtaIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/DtaIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/DtaIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/DtaIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/DtaIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/DtaIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/DtaIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/DtaIllustrationPage/SET_APPLICATION_ID_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/DtaIllustrationPage/SET_PTV_VERSION_ACTION';
export const EMAIL_PDF_ACTION = 'app/DtaIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/DtaIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_ERROR_ACTION = 'app/DtaIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/DtaIllustrationPage/RESET_ACTION';
export const CLONE_QUOTE_ACTION = 'app/DtaIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/DtaIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/DtaIllustrationPage/SET_BI_PDF_ACTION';
export const SET_EMAIL_SENT_ACTION =
  'app/DtaIllustrationPage/SET_EMAIL_SENT_ACTION';

export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/DtaIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';
export const SET_COMPLETED_DIALOG =
  'app/DtaIllustrationPage/SET_COMPLETED_DIALOG';
export const SET_IS_CALCULATE_QUOTE_PAGE_ACTION =
  'app/DtaIllustrationPage/SET_IS_CALCULATE_QUOTE_PAGE_ACTION';
  export const SET_PRODUCT_CODE_ACTION =
  'app/DtaIllustrationPage/SET_PRODUCT_CODE_ACTION';

import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  stroke: currentColor;
`;

function IconApplications(props) {
  return (
    <SvgIcon viewBox="0 0 18 21" version="1.1" fontSize="inherit" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <GA transform="translate(-14.000000, -371.000000)">
          <g transform="translate(15.000000, 372.500000)">
            <polyline id="Path" points="11 0 11 5 16 5" />
            <polygon id="Path" points="11.0769231 0 0 0 0 18 16 18 16 4.8" />
            <line x1="3" y1="12.5" x2="12" y2="12.5" id="Path" />
            <line x1="3" y1="9.5" x2="12" y2="9.5" id="Path" />
            <line x1="3" y1="4.5" x2="7" y2="4.5" id="Path" />
          </g>
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconApplications;

import { fromJS } from 'immutable';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import { dobFormat } from 'helpers/constants';

/** HELPER */
export function getCoverages(productConfig) {
  var coverages = [];
  productConfig.productBenefits.mandatoryList.map(benefit => {
    if (!benefit.isComplimentary)
      coverages.push({
        type: ['WOP', 'ESC'].includes(benefit.benefitCode) ? '2' : '1',
        selected: true,
        disabled: benefit.isRequired,
        id: benefit.benefitCode,
        title: benefit.benefitName,
        order: getBenefitOrder(benefit.benefitCode),
        valueInsuredOne: ['WOP', 'ESC'].includes(benefit.benefitCode)
          ? false
          : '',
        termOne: '',
        valueInsuredTwo: ['WOP', 'ESC'].includes(benefit.benefitCode)
          ? false
          : '',
        maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
        perPercentageOne: 0,
        perMileOne: 0,
        perMileTwo: 0,
        perPercentageTwo: 0,
        perTempMileOne: 0,
        perTempMileTwo: 0,
        perTempMonthTwo: 0,
        perTempMonthOne: 0,
        brSelectedTempValueOne: false,
        brSelectedTempValueTwo: false,
        brSelectedPercentValueOne: false,
        brSelectedPercentValueTwo: false,
        brSelectedInsuredTwoValue: false,
        brSelectedInsuredOneValue: false,
        possibleValues: benefit.possibleValues,
      });
  });

  productConfig.productBenefits.optionalList.map(benefit => {
    coverages.push({
      type: ['WOP', 'ESC'].includes(benefit.benefitCode) ? '2' : '1',
      selected: ['WOP', 'ESC'].includes(benefit.benefitCode) ? true : false,
      disabled: benefit.isRequired,
      id: benefit.benefitCode,
      title: benefit.benefitName,
      order: getBenefitOrder(benefit.benefitCode),
      valueInsuredOne: ['WOP', 'ESC'].includes(benefit.benefitCode)
        ? false
        : '',
      termOne: '',
      valueInsuredTwo: ['WOP', 'ESC'].includes(benefit.benefitCode)
        ? false
        : '',
      maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
      perPercentageOne: 0,
      perMileOne: 0,
      perMileTwo: 0,
      perPercentageTwo: 0,
      perTempMileOne: 0,
      perTempMileTwo: 0,
      perTempMonthTwo: 0,
      perTempMonthOne: 0,
      brSelectedTempValueOne: false,
      brSelectedTempValueTwo: false,
      brSelectedPercentValueOne: false,
      brSelectedPercentValueTwo: false,
      brSelectedInsuredTwoValue: false,
      brSelectedInsuredOneValue: false,
      possibleValues: benefit.possibleValues,
    });
  });
  return fromJS(
    coverages.sort(function(a, b) {
      return a.order - b.order;
    }),
  );
}

export function calculateAgeNextBirthdate(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  var monthDayPoint = 0;
  if (policyStartDateMonth == bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}

export function getMinValue(productId, productConfig, currency) {
  if (productId !== 'ESC') {
    const indexOfCurrency = productConfig?.benefitCurrencyMap.indexOf(currency);
    var minValue = 0;

    const productBenefits = [
      ...productConfig?.productBenefits.optionalList,
      ...productConfig?.productBenefits.mandatoryList,
    ];

    productBenefits.map(benefit => {
      if (!benefit.isComplimentary) {
        if (benefit.benefitCode === productId)
          minValue = benefit.minBenefitAmount[indexOfCurrency];
      }
    });
    return minValue;
  }

  return 0;
}

export function getMaxValue(productId, productConfig, currency) {
  const indexOfCurrency = productConfig?.benefitCurrencyMap.indexOf(currency);
  var maxValue = 0;

  const productBenefits = [
    ...productConfig.productBenefits.optionalList,
    ...productConfig.productBenefits.mandatoryList,
  ];

  productBenefits.map(benefit => {
    if (!benefit.isComplimentary) {
      if (benefit.benefitCode === productId)
        maxValue = benefit.maxBenefitAmount[indexOfCurrency];
    }
  });
  return maxValue;
}

export function isValidEmail(email) {
  if (email && isEmail(email)) {
    return true;
  }
  return false;
}

export function getStartDate() {
  var month = new Date().getMonth() + 1;
  if (new Date().getDate() <= 15) month = month - 1;

  return moment()
    .month(month)
    .date(1);
}

export function getBenefitOrder(benefitId) {
  switch (benefitId) {
    case 'LFC':
      return 1;

    case 'CRI':
      return 2;

    case 'CCC':
      return 3;

    case 'ACD':
      return 4;

    case 'DMB':
      return 5;

    case 'FMI':
      return 6;

    case 'FTI':
      return 7;

    case 'HSP':
      return 8;

    case 'PTD':
      return 9;

    case 'WOP':
      return 10;

    default:
      return 11;
  }
}

import { func } from 'prop-types';
import {
  CALL_ZIO_PIN_VERIFICATION,
  SET_ZIO_PIN_SUCCESS,
  SET_ZIO_PIN_FAILURE,
  SET_IS_ZIO_PIN_VERIFIED,
  SET_ZIO_PIN_VERIFY_TIMESTAMP,
  GET_PERSISTED_ZIO_PIN_DATA,
} from './constants';

export function callZioPinVerification(payload) {
  return {
    type: CALL_ZIO_PIN_VERIFICATION,
    payload,
  };
}

export function setZioPinSuccess(payload) {
  return {
    type: SET_ZIO_PIN_SUCCESS,
    payload,
  };
}

export function setZioPinFailure(payload) {
  return {
    type: SET_ZIO_PIN_FAILURE,
    payload,
  };
}

export function setIsZioPinVerified(payload) {
  return {
    type: SET_IS_ZIO_PIN_VERIFIED,
    payload,
  };
}

export function setZioPinVerifyTimestamp(payload) {
  return {
    type: SET_ZIO_PIN_VERIFY_TIMESTAMP,
    payload,
  };
}

export function getPersistedZioPinData() {
  return {
    type: GET_PERSISTED_ZIO_PIN_DATA,
  };
}

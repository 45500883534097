import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import { isEmpty } from 'lodash/lang';
import { forEach } from 'lodash/collection';
import {
  regexLongDashCharacters,
  regexSpecialCharactersAndNumbers,
  convertIfImmutable,
} from 'helpers/lang';
import { isFieldEmpty } from 'utils/form';
import messages from '../../../messages';

const validate = values => {
  const errors = {};
  const isBeneficiary = values.get('otherPrimaryBeneficiaries');
  if (!isEmpty(convertIfImmutable(values)) && isBeneficiary) {
    const primaryBeneficiariesParties = convertIfImmutable(
      values.get('primaryBeneficiaries'),
    );

    const primaryBeneficiaryErrors = [];
    const requiredTextFields = [
      'firstName',
      'lastName',
      'relationshipToLifeInsured',
      'dateOfBirth',
      'email',
      'addressLine1',
      'addressLine2',
      'addressLine3',
      'city',
    ];

    const requiredSelectFields = ['country'];

    forEach(primaryBeneficiariesParties, (party, index) => {
      const partyErrors = {};

      forEach(requiredSelectFields, field => {
        if (isFieldEmpty(party[field]) || party[field] === 'Select an option') {
          partyErrors[field] = (
            <FormattedMessage {...globalMessages.pleaseSelectValue} />
          );
          primaryBeneficiaryErrors[index] = partyErrors;
        }
      });

      forEach(requiredTextFields, field => {
        if (isFieldEmpty(party[field])) {
          partyErrors[field] = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
          primaryBeneficiaryErrors[index] = partyErrors;
        } else if (field === 'email') {
          if (!isEmail(party[field])) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.emailInvalid} />
            );
            primaryBeneficiaryErrors[index] = partyErrors;
          }
        } else if (field === 'dateOfBirth') {
          const today = new Date().setFullYear(new Date().getFullYear());

          const capturedDate = moment(party[field]);
          const date = Date.parse(party[field]);
          if (date > today) {
            partyErrors[field] = <FormattedMessage {...messages.futureDates} />;
            primaryBeneficiaryErrors[index] = partyErrors;
          }

          if (!capturedDate.isValid()) {
            partyErrors[field] = (
              <FormattedMessage {...messages.isNotValidDate} />
            );
            primaryBeneficiaryErrors[index] = partyErrors;
          }
        } else if (field === 'firstName' || field === 'lastName') {
          if (regexSpecialCharactersAndNumbers.test(party[field])) {
            partyErrors[field] = (
              <FormattedMessage
                {...globalMessages.specialCharactersAndNumbersNotAllowed}
              />
            );
            primaryBeneficiaryErrors[index] = partyErrors;
          }
        } else if (field === 'relationshipToLifeInsured') {
          if (party[field] === 'Select an option') {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        } else if (
          field === 'addressLine1' ||
          field === 'addressLine2' ||
          field === 'addressLine3' ||
          field === 'city' ||
          field === 'postOfficeBoxCity'
        ) {
          if (party[field].length > 35) {
            partyErrors[field] = (
              <FormattedMessage
                {...globalMessages.maximumLength}
                values={{ value1: 35 }}
              />
            );
            primaryBeneficiaryErrors[index] = partyErrors;
          }

          if (regexLongDashCharacters.test(party[field])) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.unsupportedChars} />
            );
            primaryBeneficiaryErrors[index] = partyErrors;
          }
        }
      });
    });

    if (primaryBeneficiaryErrors.length) {
      errors.primaryBeneficiaries = primaryBeneficiaryErrors;
    }
  }

  return errors;
};

export default validate;

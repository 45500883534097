/*
 *
 * HubPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  DEFAULT_ACTION,
  SET_LIST_APPLICATION_PARTY_ACTION,
  SET_LIST_ILLUSTRATION_ACTION,
  SET_LIST_IN_FORCE_POLICY_ACTION,
} from './constants';

const defaultState = {
  listApplication: [],
  listIllustration: [],
  listPolicy: [],
};

export const initialState = fromJS({ ...defaultState });

function hubPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_LIST_APPLICATION_PARTY_ACTION:
      return state.set('listApplication', fromJS(action.payload));
    case SET_LIST_ILLUSTRATION_ACTION:
      return state.set('listIllustration', fromJS(action.payload));
    case SET_LIST_IN_FORCE_POLICY_ACTION:
      return state.set('listPolicy', fromJS(action.payload));
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default hubPageReducer;

/**
 *
 * Review
 *
 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
  Typography,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from '@material-ui/core';
import CustomDialog from 'components/Dialog';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import numeral from 'numeral';
import moment from 'moment';
import {
  Cancel,
  CheckCircle,
  DescriptionOutlined,
  Info,
} from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import InfoRow from 'components/InfoRow';
import ReviewUserData from 'components/ReviewUserData';
import NoticeBox from 'components/NoticeBox';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  getBrTitle,
  getAgeOfLife,
  getChosenInsured,
  isValidEmail,
} from '../helper';
import { GET_QUOTE_CREATED } from '../constants';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectBiPdf,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
  makeSelectPolicyConfig,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectSustainabilityYears,
  makeShowWarningReview,
} from '../selectors';
import {
  getQuoteAction,
  updateStatusAction,
  emailPdfAction,
  setEmailSentAction,
} from '../actions';
import messages from '../messages';
import { validateOfferCodeAction } from 'containers/App/actions';
function WapReview({
  lifeAssured,
  productConfig,
  wapProductConfigs,
  getQuote,
  benefitIllustrationPdf,
  updateStatus,
  emailPdf,
  referenceNumber,
  emailSent,
  setEmailSent,
  emailErrorMessage,
  isShowWarningReview,
  offerCodeData,
  validateOfferCode
}) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [errorMail, setErrorMail] = useState(false);
  const [standardData, setStandardData] = useState([]);
  const [specialTermsData, setSpecialTermsData] = useState([]);
  const chosenInsured = getChosenInsured(lifeAssured);

  useEffect(() => {
    getQuote({
      type: GET_QUOTE_CREATED,
    });
  }, []);

  useEffect(() => {
    if (emailSent) {
      setErrorMail(true);
    }
  }, [emailSent]);

  useEffect(() => {
    if (offerCodeData.code) {
      validateOfferCode({
        offerCode: offerCodeData.code,
        productCode: productConfig.productCode,
        productVersion: productConfig.productVersion,
        premiumType: 'SinglePremium',
        currency: productConfig.currency,
        premium: Number(productConfig.premium),
      });
    }
    return () => {
      setEmailSent(false);
    };
  }, []);

  useEffect(() => {
    const standardDataArray = [];
    const specialTermsDataArray = [];
    productConfig.coverages?.map(cvg => {
      const hasBenefitRating =
        cvg.brSelectedInsuredOneValue ||
        cvg.brSelectedInsuredTwoValue ||
        cvg.brSelectedPercentValueOne ||
        cvg.brSelectedPercentValueTwo ||
        cvg.brSelectedTempValueTwo ||
        cvg.brSelectedTempValueOne;
      standardDataArray.push(cvg);
      setStandardData(standardDataArray);

      if (hasBenefitRating) {
        specialTermsDataArray.push(cvg);
        setSpecialTermsData(specialTermsDataArray);
      }
    });
  }, [productConfig]);


  let aspFrequencyLabel = 'p/month';

  switch (productConfig?.additionalSinglePremium?.frequency) {
    case 'Monthly':
      aspFrequencyLabel = 'p/month';
      break;

    case 'Quarterly':
      aspFrequencyLabel = 'p/quarter';
      break;

    case 'Half-Yearly':
      aspFrequencyLabel = 'p/half-year';
      break;

    case 'Yearly':
      aspFrequencyLabel = 'p/year';
      break;

    default:
      aspFrequencyLabel = 'p/month';
      break;
  }

  return (
    <div style={{ marginTop: theme.spacing(5), maxWidth: 650 }}>
      {/* ########## START  :: REFERENCE NUMBER ########## */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          {/* <Button
            display="block"
            variant="text"
            color='primary'
            onClick={() => onClickBackToClientList()}>
            <FormattedMessage {...messages.backToClientList} />
          </Button> */}
        </Grid>

        <Grid item>
          <Typography
            display="block"
            align="right"
            variant="body2"
            style={{ color: '#666666' }}
          >
            <FormattedMessage {...messages.reference} />:{` ${referenceNumber}`}
          </Typography>
        </Grid>
        {/* {offerCodeData.errorMessage && (
          <Grid item xs={12}>
            <Typography
              display="block"
              align="right"
              variant="body2"
              style={{ color: 'red' }}
            >
              <FormattedMessage {...globalMessages.specialOfferExpired} />
            </Typography>
          </Grid>
        )} */}
      </Grid>

      {/* ########## END    :: REFERENCE NUMBER ########## */}
      {/* ########## START  :: POLICY OWNER ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.policyOwner} />
        </Typography>
        {/* LI 1 as PO */}
        {lifeAssured.insuredOne.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredOne} />
        )}
        {/* LI 2 as PO */}
        {lifeAssured.isInsuredTwo && lifeAssured.insuredTwo.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
        {/* other POs */}
        {lifeAssured.isPolicyOwnerIndividual &&
          lifeAssured.policyOwnerIndividual.map((po, index) => {
            const key = `${po.firstName}-${index}`;
            return <ReviewUserData key={key} party={po} />;
          })}
      </Box>
      {/* ########## END    :: POLICY OWNER ########## */}
      {/* ########## START  :: LIFE INSURED ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.insuredParty} />
        </Typography>

        <ReviewUserData party={lifeAssured.insuredOne} />
        {lifeAssured.isInsuredTwo && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
      </Box>
      {/* ########## END    :: LIFE INSURED ########## */}

      {/* ########## START  :: PREMIUM SUMMARY ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.premiumSummary} />
        </Typography>
        {(productConfig?.premium && productConfig?.premium > 0) &&
          (<InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.newSinglePremiumAmount} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {productConfig.currency}{' '}
                  {formatMonetaryValue(productConfig.premium, true)}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>)}

        {productConfig.isAdditionalSinglePremium && (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.ASP} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {productConfig.currency}{' '}
                  {formatMonetaryValue(
                    productConfig.additionalSinglePremium?.amount,
                    true,
                  )}{' '}
                  {aspFrequencyLabel}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        )}

        {productConfig.fiaCharge && (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.advisorFeeLabel} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {productConfig?.fiaCharge}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        )}

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.assumedGrowthRate} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {`${productConfig.growthRate} %`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>
        {offerCodeData?.code ? (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.specialOfferCode} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" align="left">
                  {offerCodeData?.discountMessage?.english}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        ) : null}
      </Box>
      {/* ########## END    :: PREMIUM SUMMARY ########## */}

      {/* ########## START  :: WITHDRAWAL ########## */}
      <Box mb={5}>
        {wapProductConfigs.isRegularWithdrawal ? (
          <>
            <Typography
              variant="h3"
              style={{
                marginBottom: theme.spacing(2),
                color: theme.palette.primary.subMain,
              }}
            >
              <FormattedMessage {...messages.withdrawal} />
            </Typography>
            <InfoRow>
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                justify="space-between"
              >
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {wapProductConfigs.regularWithdrawal.percentage != 0
                      ? `${wapProductConfigs.regularWithdrawal.percentage}%`
                      : `${wapProductConfigs.currency} ${formatMonetaryValue(
                        wapProductConfigs.regularWithdrawal.amount,
                        false,
                      )}`}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(wapProductConfigs.regularWithdrawal.startDate).format(
                      'DD MMM YYYY',
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(wapProductConfigs.regularWithdrawal.endDate).format(
                      'DD MMM YYYY',
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {wapProductConfigs.regularWithdrawal.frequency}
                  </Typography>
                </Grid>
              </Grid>
            </InfoRow>
          </>
        ) : null}
      </Box>
      {/* ########## END  :: WITHDRAWAL ########## */}

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
        }}
      >
        <FormattedMessage {...messages.reviewNotice} />
      </Typography>
      <InfoRow variant="document">
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <DescriptionOutlined color="inherit" />
          </Grid>
          <Grid item xs>
            <Typography variant="body1" color="inherit">
              {(productConfig.productCode === 'WAPL2' || productConfig.productCode === 'WAPL1') ?
                <FormattedMessage
                  {...messages.wapIllustration}
                  values={{
                    name: productConfig.name,
                  }}
                /> :
                <FormattedMessage
                  {...messages.swIllustration}
                  values={{
                    name: productConfig.name,
                  }}
                />
              }
            </Typography>
          </Grid>

          <Grid item>
            <Link
              href={benefitIllustrationPdf}
              target="_blank"
              align="center"
              variant="body2"
              disabled={!benefitIllustrationPdf}
              onClick={() => {
                updateStatus(1);
              }}
              // replace
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.viewExportPDF} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              target="_blank"
              align="center"
              variant="body2"
              // replace
              onClick={() => {
                if (!emailSent) {
                  setOpen(true);
                } else {
                  setErrorMail(true);
                }
              }}
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.emailPDF} />
            </Link>
          </Grid>
        </Grid>
      </InfoRow>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage {...messages.emailPDF} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            id="emailAddress"
            label={<FormattedMessage {...globalMessages.email} />}
            type="email"
            error={error}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isValidEmail(email)) {
                setError(false);
                emailPdf(email);
                return setOpen(false);
              }
              return setError(true);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.send} />
          </Button>
        </DialogActions>
      </Dialog>
      {/* EMAIL ERROR  */}
      <CustomDialog
        open={errorMail}
        onClose={() => {
          setErrorMail(false);
          setEmailSent(false);
        }}
        title={<FormattedMessage {...messages.error} />}
        variant="error"
        actions={
          <Button
            onClick={() => {
              setErrorMail(false);
              return setEmailSent(false);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.close} />
          </Button>
        }
      >
        <Typography variant="h2">
          <FormattedMessage
            {...messages.errorEmail}
            values={{ message: emailErrorMessage }}
          />
        </Typography>
      </CustomDialog>
    </div>
  );
}

WapReview.propTypes = {
  lifeAssured: PropTypes.object,
  productConfig: PropTypes.object,
  policyConfig: PropTypes.object,
  benefitIllustrationPdf: PropTypes.string,
  getQuote: PropTypes.func,
  updateStatus: PropTypes.func,
  emailPdf: PropTypes.func,
  referenceNumber: PropTypes.string,
  fiaData: PropTypes.object,
  offerCodeData: PropTypes.object,
  emailSent: PropTypes.bool,
  setEmailSent: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  sustainabilityYears: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  lifeAssured: makeSelectLifeAssured(),
  productConfig: makeSelectProductConfig(),
  policyConfig: makeSelectPolicyConfig(),
  benefitIllustrationPdf: makeSelectBiPdf(),
  referenceNumber: makeSelectReferenceNumber(),
  fiaData: makeSelectFiaCharge(),
  offerCodeData: makeSelectOfferCodeData(),
  emailSent: makeSelectemailSentStatus(),
  emailErrorMessage: makeSelectEmailErrorMessage(),
  sustainabilityYears: makeSelectSustainabilityYears(),
  isShowWarningReview: makeShowWarningReview(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: val => dispatch(updateStatusAction(val)),
    getQuote: value => dispatch(getQuoteAction(value)),
    emailPdf: value => dispatch(emailPdfAction(value)),
    setEmailSent: value => dispatch(setEmailSentAction(value)),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(WapReview);

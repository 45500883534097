import numeral from 'numeral';
/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */
export const SET_APP_LOADING_ACTION = 'app/App/SET_APP_LOADING_ACTION';
export const SET_AUTH_ACTION = 'app/App/SET_AUTH_ACTION';
export const RESET_APP_DATA_ACTION = 'app/App/RESET_APP_DATA_ACTION';
export const LOGOUT_ACTION = 'app/App/LOGOUT_ACTION';
export const LOGOUT_SUCCESS_ACTION = 'app/App/LOGOUT_SUCCESS_ACTION';
export const SET_ERROR_ACTION = 'app/App/SET_ERROR_ACTION';
export const CLEAR_ERROR_ACTION = 'app/App/CLEAR_ERROR_ACTION';
export const GET_RESOURCES_ACTION = 'app/App/GET_RESOURCES_ACTION';
export const SET_RESOURCES_ACTION = 'app/App/SET_RESOURCES_ACTION';
export const SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION =
  'app/App/SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION ';

export const CHANGE_SELECTED_AGENCY_ACTION =
  'app/AgencySelection/CHANGE_SELECTED_AGENCY_ACTION';
export const SET_SELECTED_AGENCY_ACTION =
  'app/AgencySelection/SET_SELECTED_AGENCY_ACTION';
export const FETCH_AGENT_DETAILS_ACTION =
  'app/AgencySelection/FETCH_AGENT_DETAILS_ACTION';
export const SET_AGENT_DETAILS_ACTION =
  'app/AgencySelection/SET_AGENT_DETAILS_ACTION';

export const GET_PERSISTED_AUTH_ACTION = 'app/App/GET_PERSISTED_AUTH_ACTION';
export const GET_PERSISTED_AUTH_SUCCESS_ACTION =
  'app/App/GET_PERSISTED_AUTH_SUCCESS_ACTION';

export const CREATE_FILE = 'app/ApplicationPage/CREATE_FILE';
export const SET_FILE = 'app/ApplicationPage/SET_FILE';

export const GET_CSRF_ACTION = 'app/ApplicationPage/GET_CSRF_ACTION';
export const SET_CSRF_ACTION = 'app/ApplicationPage/SET_CSRF_ACTION';
export const VALIDATE_OFFER_CODE_ACTION =
  'app/ApplicationPage/VALIDATE_OFFER_CODE_ACTION';
export const RESET_VALIDATE_OFFER_CODE =
  'app/ApplicationPage/RESET_VALIDATE_OFFER_CODE';
export const VALIDATE_OFFER_CODE_SUCCESS_ACTION =
  'app/ApplicationPage/VALIDATE_OFFER_CODE_SUCCESS_ACTION';
export const SET_OFFER_CODE_ERROR_ACTION =
  'app/ApplicationPage/SET_OFFER_CODE_ERROR_ACTION';
export const SET_OFFER_CODE_VALIDATING_ACTION =
  'app/ApplicationPage/SET_OFFER_CODE_VALIDATING_ACTION';
export const SET_QUOTE_TYPE = 'app/ApplicationPage/SET_QUOTE_TYPE';

export const QUOTE_TYPE = {
  NEW_BUSINESS: 1,
  EXISTING_BUSINESS: 2,
};

export const OPTIONS_API_KEYS = [
  'country_code',
  'payment_frequency',
  'currency',
  'application_relationship',
  'coverage_term_basis',
  'country_dialing_code',
  'card_currency',
  'source_of_wealth',
  'address_gcc_country',
  'payor_relationship',
  'beneficiary_relationship',
  'company_payor_relationship',
];

export function mapOptionsResponseToDisplay(optionKey, response) {
  switch (optionKey) {
    case 'countryCode':
      return response.map(opt => ({
        value: opt.isoCode,
        title: opt.text,
      }));
    case 'addressGccCountry':
      return response.map(opt => ({
        value: opt.isoCode,
        title: opt.text,
      }));
    // case 'currency':
    //   return response.map(opt => ({
    //     value: opt.code,
    //     title: opt.text,
    //   }));
    // case 'paymentFrequency':
    //   return response.map(opt => ({
    //     value: opt.code,
    //     title: opt.text,
    //   }));
    // case 'applicationRelationship':
    //   return response.map(opt => ({
    //     value: opt.code,
    //     title: opt.text,
    //   }));
    // case 'coverageTermBasis':
    //   return response.map(opt => ({
    //     value: opt.code,
    //     title: opt.text,
    //   }));
    default:
      return response.map(opt => ({
        value: opt.code,
        title: opt.text,
      }));
  }
}

/*
  state key of the given option API key
 */
function replaceSnakeWithCamelCase(string) {
  return string.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''),
  );
}

export function getStateOptionKey(apiOptionKey) {
  switch (apiOptionKey) {
    // case 'country_code':
    //   return 'countryCode';
    // case 'currency':
    //   return 'currency';
    // case 'payment_frequency':
    //   return 'paymentFrequency';
    // case 'application_relationship':
    //   return 'applicationRelationship';
    // case 'coverage_term_basis':
    //   return 'coverageTermBasis';
    default:
      return replaceSnakeWithCamelCase(apiOptionKey);
  }
}

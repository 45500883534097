import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import { getMinValue, getMaxValue } from '../../helper';

const validate = (values, props) => {
  const errors = {};
  const { configsDetail, lifeAssured } = props;

  if (!values.isEmpty()) {
    // const offerCodeValues = values.get('offerCode');
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const termValues = values.get('term');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const coveragesValues = values.get('coverages');

    if (!startDateValues || !moment(startDateValues).isValid()) {
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    }

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );

    if (!termValues || termValues === 'Select')
      errors.term = <FormattedMessage {...globalMessages.pleaseEnterValue} />;
    else {
      if (configsDetail.features.policyTerm.minTerm > termValues) {
        errors.term = (
          <FormattedMessage
            {...globalMessages.minValueLimit}
            values={{ value: configsDetail.features.policyTerm.minTerm }}
          />
        );
      }

      if (configsDetail.features.policyTerm.maxTerm < termValues) {
        errors.term = (
          <FormattedMessage
            {...globalMessages.maxValueLimit}
            values={{ value: configsDetail.features.policyTerm.maxTerm }}
          />
        );
      }
    }

    if (!paymentFrequencyValues || paymentFrequencyValues === 'Select')
      errors.paymentFrequency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );

    if (coveragesValues && coveragesValues.size > 0) {
      const coveragesError = [];
      coveragesValues.forEach(coverage => {
        const requiredCoverageFields = lifeAssured.isInsuredTwo
          ? ['valueInsuredOne', 'valueInsuredTwo']
          : ['valueInsuredOne'];
        const coverageError = {};

        requiredCoverageFields.forEach(field => {
          if (coverage.get('selected')) {
            if (isFieldEmpty(coverage.get(field))) {
              coverageError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            } else if (coverage.get('type') === '1') {
              const minVal = getMinValue(
                coverage.get('id'),
                configsDetail.features,
                currencyValues,
              );
              const maxVal = getMaxValue(
                coverage.get('id'),
                configsDetail.features,
                currencyValues,
              );

              if (minVal > coverage.get(field))
                coverageError[field] = (
                  <FormattedMessage
                    {...globalMessages.minAmountLimit}
                    values={{ value: minVal }}
                  />
                );

              if (maxVal < coverage.get(field))
                coverageError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxAmountLimit}
                    values={{ value: maxVal }}
                  />
                );
            }
          }
        });
        coveragesError.push(coverageError);
      });

      if (!isEmpty(coveragesError)) errors.coverages = coveragesError;
    }

    // if (!isEmpty(offerCodeValues)) {
    //   const coverages = coveragesValues.toJS();
    //   const lifeCover = coverages.find(item => item.id === 'LFC');
    //   if (lifeCover?.valueInsuredOne === '') {
    //     errors.offerCode = (
    //       <FormattedMessage {...globalMessages.pleaseSelectLifeCover} />
    //     );
    //   }
    //   if (currencyValues === 'Select') {
    //     errors.offerCode = (
    //       <FormattedMessage {...globalMessages.pleaseSelectCurrency} />
    //     );
    //   }
    //   if (termValues === 'Select') {
    //     errors.offerCode = (
    //       <FormattedMessage {...globalMessages.pleaseSelectPolicyTerm} />
    //     );
    //   }
    // }
  }
  return errors;
};

export default validate;

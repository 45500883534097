import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Field, FieldArray, submit, reset } from 'redux-form/immutable';
import { WrappedFieldProps } from 'redux-form';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { has } from 'lodash/object';
import { filter, forEach, map } from 'lodash/collection';
import globalMessages from 'containers/App/messages';
import messages from 'containers/ApplicationFormPage/messages';
import { FormattedMessage } from 'react-intl';
import { convertIfImmutable } from 'helpers/lang';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';
import {
  Grid,
  MenuItem,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  Typography,
  Button,
  Collapse,
  Divider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import FormSelect from 'components/FormSelect';
import DoctorForm from './DoctorForm';
import { makeSelectOption } from 'containers/App/selectors';
import { makeSelectDoctorsList } from 'containers/ApplicationFormPage/selectors';
import {
  addDoctorAction,
  getDoctorsAction,
} from 'containers/ApplicationFormPage/actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: theme.spacing(2),
    borderRadius: '10px',
    // marginTop: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
  },
  bold: {
    fontWeight: 800,
  },
  title2: {
    fontWeight: 800,
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
}));

const Doctor = ({
  answerQuestion,
  question,
  sessionId,
  partyId,
  section,
  eTag,
  required,
  children,
  options,
  submitForm,
  resetForm,
  addDoctor,
  getDoctors,
  doctorsList,
  setSectionAnswer,
  sectionName,
  questions,
  order,
  ...custom
}) => {
  const classes = useStyles();
  const [addForm, setAddForm] = useState(false);

  useEffect(() => {
    getDoctors({ sessionId });
  }, []);

  const parsedDoctorsList = map(doctorsList, doctor => {
    return {
      value: doctor.key,
      title: `${doctor.firstName} ${doctor.lastName}`,
    };
  });

  return (
    <div className={classes.container}>
      <Grid
        container
        spacing={3}
        justify="center"
        alignItems="center"
        alignContent="center"
        style={{ marginTop: '1rem' }}
      >
        {isFirstChild(questions, question) && (
          <>
            {' '}
            <Grid item xs={12}>
              <Typography variant="h2" className={classes.title2}>
                {question.context[0]}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ margin: '1rem 0' }} />
            </Grid>
          </>
        )}
        <Grid item xs={12} md={9}>
          <Field
            name={`question-${question.id}`}
            component={FormSelect}
            options={parsedDoctorsList}
            label={<FormattedMessage {...messages.selectExistingDoctor} />}
            onChange={e => {
              setSectionAnswer({
                data: { [`question-${question.id}`]: e.target.value },
                sectionName: sectionName,
              });

              const payload = {
                section,
                questionId: question.id,
                value: e.target.value,
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
                order,
              };

              answerQuestion(payload);
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setAddForm(false);
              if (addForm) {
                submitForm();
              } else {
                setAddForm(true);
              }
            }}
          >
            {/* <AddIcon />  */}
            Add Doctor
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={addForm}>
            <DoctorForm
              initialValues={{
                firstName: '',
                lastName: '',
                facilityName: '',
                phoneNumber: '',
                emailAddress: '',
                addressLine1: '',
                addressLine2: '',
                city: '',
                stateOrProvince: '',
                zipCode: '',
                country: '',
              }}
              options={options}
              onSubmit={immutable => {
                const data = immutable.toJS();

                const payload = {
                  data,
                  sessionId,
                  partyId,
                };
                
                setAddForm(false);
                resetForm();
                addDoctor(payload);
              }}
            />
          </Collapse>
        </Grid>
      </Grid>
    </div>
  );
};

Doctor.propTypes = {
  ...WrappedFieldProps,
};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
  doctorsList: makeSelectDoctorsList(),
});

function mapDispatchToProps(dispatch) {
  return {
    submitForm: () => dispatch(submit('DoctorForm')),
    addDoctor: data => dispatch(addDoctorAction(data)),
    getDoctors: data => dispatch(getDoctorsAction(data)),
    resetForm: () => dispatch(reset('DoctorForm')),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Doctor);

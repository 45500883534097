const validate = (values, props) => {
  const errors = {};

  const parsedValues = values.toJS();
  const { zioPinLength } = props;
  for (let i = 0; i < zioPinLength; i++) {
    if (!parsedValues[`pin_${i}`]) {
      errors[`pin_${i}`] = 'Required';
    } else if (parsedValues[`pin_${i}`].length !== 1) {
      errors[`pin_${i}`] = 'Must be 1 character';
    } else if (isNaN(Number(parsedValues[`pin_${i}`]))) {
      errors[`pin_${i}`] = 'Must be a number';
    } else {
      errors[`pin_${i}`] = '';
    }
  }

  return errors;
};

export default validate;

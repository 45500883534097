import React from 'react';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const BaseRow = styled.div`
  padding: ${props => props.theme.spacing(2.5)}px ${props => props.theme.spacing(3)}px;
  background-color: ${props => props.theme.palette.grey[100]};
  margin-top: ${props => props.theme.spacing(0.5)}px;
`;

const BrRow = styled.div`
  padding-left: ${props => props.theme.spacing(3)}px;
  padding-right: ${props => props.theme.spacing(3)}px;
  padding-top: ${props => props.theme.spacing(1)}px;
  background-color: ${props => props.theme.palette.grey[50]};
  margin-top: ${props => props.theme.spacing(0.5)}px;
  max-height: 40px;
`;

const Secondary = styled(BaseRow)`
  padding-top: ${props => props.theme.spacing(0.5)}px;
  padding-bottom: ${props => props.theme.spacing(0.5)}px;
`;

const Document = styled(BaseRow)`
  background-color: ${props => props.theme.palette.primary.dark};
  color: ${props => props.theme.palette.common.white};
  border-radius: ${props => props.theme.spacing(1)}px;
`

function InfoRow({
  variant = "base",
  ...props
}) {
  const theme = useTheme();
  if (variant === "document") {
    return <Document theme={theme} {...props} />;
  }
  if (variant === "secondary") {
    return <Secondary theme={theme} {...props} />;
  }
  if (variant === "br") {
    return <BrRow theme={theme} {...props} />;
  }
  return <BaseRow theme={theme} {...props} />;
}

export default InfoRow;

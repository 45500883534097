
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';
import { Grid, Box } from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import FormTextField from 'components/FormTextField';
import FormSelect from 'components/FormSelect';
import FormNumberField from 'components/FormNumberField';

const useStyle = makeStyles(() => ({
}));

function LifeOffices(props) {
  const intl = useIntl();
  const classes = useStyle();

  const { fields, formValues, options } = props;

  return (
    <Grid container >
      <Grid item xs={12} md={8} container spacing={2}>
        <Grid item xs={12}>
          <Field
            name={`nameOfCompany`}
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.nameOfCompany} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            name={`policyType`}
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.policyType} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`amountOfCover`}
            fullWidth
            component={FormNumberField}
            label={<FormattedMessage {...messages.amountOfCover} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`premiumAmount`}
            fullWidth
            component={FormNumberField}
            label={<FormattedMessage {...messages.premiumAmount} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`policyTerm`}
            fullWidth
            component={FormTextField}
            label={<FormattedMessage {...messages.policyTerm} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            name={`policyCurrency`}
            fullWidth
            component={FormSelect}
            options={options.currency}
            label={<FormattedMessage {...messages.policyCurrency} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            color="primary"
            margin="normal"
            variant="standard"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

LifeOffices.propTypes = {
  fields: PropTypes.object,
  formValues: PropTypes.object,
  options: PropTypes.object,
};

export default LifeOffices;

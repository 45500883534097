import React from 'react';
import { Field, FieldArray } from 'redux-form/immutable';
import { keys, get } from 'lodash/object';
import { forEach } from 'lodash/collection';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';

import { Typography, Grid } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { useTheme } from '@material-ui/core/styles';
import globalMessages from 'containers/App/messages';

//components
import SingleChoice from './SingleChoice';
import MultipleChoice from './MultipleChoice';
import SearchInput from './SearchInput';
import Unitized from './Unitized';
import BloodPressure from './BloodPressure';
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import Numeric from './Numeric';

const renderType = (
  question,
  answerQuestion,
  section,
  searchAnswer,
  sectionName,
  setSectionAnswer,
  sessionId,
  eTag,
  partyId,
  showLabel = true,
) => {
  const theme = useTheme();
  switch (question.type) {
    case 'SINGLE_CHOICE':
      return (
        <Grid item xs={12}>
          <Field
            shrink
            name={`question-${question.id}`}
            component={SingleChoice}
            question={question}
            showLabel={showLabel}
            onChange={(event, newValue, previousValue, name) => {
              const payload = {
                section,
                questionId: question.id,
                value: newValue,
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
              };

              setSectionAnswer({
                data: { [`question-${question.id}`]: newValue },
                sectionName: sectionName,
              });
              answerQuestion(payload);
            }}
          />
        </Grid>
      );
    case 'MULTIPLE_CHOICE':
      return (
        <Grid item xs={12}>
          <Field
            name={`question-${question.id}`}
            component={MultipleChoice}
            question={question}
            onChange={(event, newValue, previousValue, name) => {
              let convertedValue = [];
              //convert it into array type
              if (Object.keys(newValue).length > 0) {
                const keyArr = keys(newValue);

                forEach(keyArr, v => {
                  convertedValue = [...convertedValue, get(newValue, v)];
                });
              }
              const payload = {
                section,
                questionId: question.id,
                answers: convertedValue,
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
              };

              setSectionAnswer({
                data: { [`question-${question.id}`]: newValue },
                sectionName: sectionName,
              });

              answerQuestion(payload);
            }}
          />
        </Grid>
      );
    case 'SEARCH':
      return (
        <Grid item xs={12}>
          <Field
            name={`question-${question.id}`}
            component={SearchInput}
            question={question}
            section={section}
            sessionId={sessionId}
            searchAnswer={searchAnswer}
            onChange={(event, newValue, previousValue, name) => {
              const payload = {
                section,
                questionId: question.id,
                answers: newValue,
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
              };

              setSectionAnswer({
                data: { [`question-${question.id}`]: newValue },
                sectionName: sectionName,
              });

              answerQuestion(payload);
            }}
          />
        </Grid>
      );
    case 'TEXT':
      return (
        <Grid item xs={6}>
          <Field
            shrink
            name={`question-${question.id}`}
            fullWidth
            component={FormTextField}
            label={question.text}
            type="text"
            color="inherit"
            margin="normal"
            variant="standard"
            onBlur={(event, newValue, previousValue, name) => {
              const payload = {
                section,
                questionId: question.id,
                value: newValue,
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
              };

              setSectionAnswer({
                data: { [`question-${question.id}`]: newValue },
                sectionName: sectionName,
              });

              answerQuestion(payload);
            }}
          />
        </Grid>
      );
    case 'STATEMENT':
      return (
        <Grid item xs={12}>
          <Typography
            variant="h3"
            style={{ fontWeight: 800, color: blue[900] }}
          >
            {question.text}
          </Typography>
        </Grid>
      );
    case 'NUMERIC':
      return (
        <Grid item xs={question.helpText ? 12 : 6}>
          <Field
            shrink
            name={`question-${question.id}`}
            fullWidth
            component={Numeric}
            label={showLabel && question.text}
            type="text"
            color="inherit"
            margin="normal"
            showLabel={showLabel}
            variant="standard"
            question={question}
            onBlur={(event, newValue, previousValue, name) => {
              const payload = {
                section,
                questionId: question.id,
                value: newValue,
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
              };

              setSectionAnswer({
                data: { [`question-${question.id}`]: newValue },
                sectionName: sectionName,
              });

              answerQuestion(payload);
            }}
          />
        </Grid>
      );
    case 'UNITIZED':
      return (
        <Grid item xs={12}>
          <Field
            shrink
            name={`question-${question.id}`}
            component={Unitized}
            label={showLabel && question.text}
            question={question}
            type="text"
            color="inherit"
            margin="normal"
            variant="standard"
            onBlur={(event, newValue, previousValue, name) => {
              const payload = {
                section,
                questionId: question.id,
                value: newValue,
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
              };

              setSectionAnswer({
                data: { [`question-${question.id}`]: newValue },
                sectionName: sectionName,
              });

              answerQuestion(payload);
            }}
            showLabel={showLabel}
          />
        </Grid>
      );
    case 'DATE':
      return (
        <Grid item xs={12}>
          <Field
            shrink
            name={`question-${question.id}`}
            component={FormDatePicker}
            label={showLabel && question.text}
            dateInputFormat="DD/MM/YYYY"
            placeholder="dd/mm/yyyy"
            margin="normal"
            minDate={moment()
              .subtract(300, 'years')
              .date(1)
              .toDate()}
            maxDate={moment().toDate()}
            InputLabelProps={{
              style: {
                fontWeight: 'normal',
                fontSize: theme.typography.body1.fontSize,
              },
            }}
            onChange={(event, newValue, previousValue, name) => {
              const payload = {
                section,
                questionId: question.id,
                value: moment(newValue).toISOString(),
                questionType: question.type,
                sessionId,
                eTag,
                partyId,
              };
              setSectionAnswer({
                data: { [`question-${question.id}`]: newValue },
                sectionName: sectionName,
              });
              answerQuestion(payload);
            }}
            question={question}
            showLabel={showLabel}
          />
        </Grid>
      );
    case 'BLOOD_PRESSURE':
      return (
        <Grid item xs={12}>
          <FieldArray
            shrink
            name={`question-${question.id}`}
            question={question}
            component={BloodPressure}
            sectionName={sectionName}
            answerQuestion
            setSectionAnswer={setSectionAnswer}
          />
        </Grid>
      );
    default:
      return question.type;
  }
};

export default renderType;

import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  fill: currentColor;
`;

function IconMinus(props) {
  return (
    <SvgIcon viewBox="0 0 26 26" version="1.1" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <GA transform="translate(-626.000000, -167.000000)">
            <g transform="translate(627.000000, 168.000000)">
                <circle stroke="#CCCCCC" fill="#FFFFFF" cx="12" cy="12" r="12"/>
                <polygon fill="#4066B3" points="6 11 19 11 19 13 6 13"/>
            </g>
        </GA>
    </g>
</SvgIcon>
  );
}
export default IconMinus;

/*
 *
 * RegisterPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  DEFAULT_ACTION,
  CHANGE_INPUT_DATA_ACTION,
  SUBMIT_REGISTRATION_ACTION,
  SET_NOTIFICATION_ACTION,
  RESET_STATE_ACTION,
  SET_SIGN_UP_SUCCESS_ACTION,
} from './constants';

const defaultState = {
  inputData: {
    // firstName: '',
    // lastName: '',
    username: '',
    agentEmail: '',
    agencyNumber: '',
    mobile: '',
    // password: '',
  },
  notification: {
    open: false,
    message: null,
    severity: null,
  },
  isSignUpSuccess: false,
};

export const initialState = fromJS(defaultState);

function registerPageReducer(state = initialState, action) {
  switch (action.type) {
    case DEFAULT_ACTION:
      return state;
    case CHANGE_INPUT_DATA_ACTION:
    case SUBMIT_REGISTRATION_ACTION:
      return state.mergeIn(['inputData'], fromJS(action.payload));
    case SET_NOTIFICATION_ACTION:
      return state.setIn(['notification'], fromJS(action.payload));
    case RESET_STATE_ACTION:
      return fromJS(defaultState);
    case SET_SIGN_UP_SUCCESS_ACTION: {
      return state.set('isSignUpSuccess', true)
    }
    default:
      return state;
  }
}

export default registerPageReducer;

/**
 *
 * LifeInsuredForm
 *
 */

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography, Collapse, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  formValueSelector,
  getFormValues
} from 'redux-form/immutable';
import { isEmpty } from 'lodash';
import { InjectedFormProps, WrappedFieldArrayProps, change } from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { fromJS } from 'immutable';
import { FormattedMessage, useIntl } from 'react-intl';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import OptSwitch from 'components/OptSwitch';
import FormSelect from 'components/FormSelect';
import CardSectionForm from 'components/CardSectionForm';
import PersonalDetails from 'components/PersonalDetails';
import EspPersonalDetails from '../EspPersonalDetails';
import { dimension } from 'styles/constants';
import { changePlaceHolderColor, changeToTitleCase } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import { getStartDate } from '../../helper';
import messages from '../../messages';
import validate from './validate';
import {
  calculateUniversityExpenseAction,
} from '../../actions';
const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
   && {
     display: flex;
     flex-direction: column;
     width: 100%;
   }
 `;

const renderSwitch = ({
  input: { onChange, value },
  theme,
  disabled,
  nameType,
  isPolicyOwnerIndividual,
  isInsuredOnePolicyOwner,
  isInsuredTwo,
  isInsuredTwoPolicyOwner,
}) => {
  if (
    (isPolicyOwnerIndividual &&
      isInsuredOnePolicyOwner &&
      isInsuredTwo &&
      isInsuredTwoPolicyOwner) ||
    !isPolicyOwnerIndividual
  ) {
    if (nameType === 'isPolicyOwnerIndividual' && value) {
      value = false;
    }
  }
  return (
    <OptSwitch
      checked={value}
      value={value}
      color="default"
      onChange={onChange}
      labelNegative={<FormattedMessage {...messages.no} />}
      disabled={disabled}
    />
  );
};

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const lessThan = otherField => (value, previousValue, allValues) => {
  if (!value) return '';
  return value.length <= otherField ? value : previousValue;
};

const renderIndivisual = ({
  fields,
  options,
  insuredOne,
  childDetails,
  disabled,
  isInsuredTwoPolicyOwner,
  policyOwnerIndividual,
}) => {
  const intl = useIntl();
  return (
    <div>
      {fields.map((individual, index) => (
        <Grid container key={individual}>
          <Grid container spacing={3}>
            <Grid item>
              <Field
                name={`${individual}.firstName`}
                disabled={disabled}
                style={{ width: 260 }}
                component={FormTextField}
                label={<FormattedMessage {...globalMessages.firstName} />}
                type="text"
                margin="normal"
                normalize={lessThan(45)}
                variant="standard"
                placeholder={intl.formatMessage(
                  globalMessages.fieldPlaceholder,
                )}
                format={(value) => changeToTitleCase(value)}
              />
            </Grid>
            <Grid item>
              <Field
                name={`${individual}.lastName`}
                disabled={disabled}
                style={{ width: 260 }}
                component={FormTextField}
                label={<FormattedMessage {...globalMessages.familyName} />}
                margin="normal"
                normalize={lessThan(40)}
                variant="standard"
                placeholder={intl.formatMessage(
                  globalMessages.fieldPlaceholder,
                )}
                format={(value) => changeToTitleCase(value)}
              />
            </Grid>
            <Grid item>
              <Field
                shrink
                disabled={disabled}
                name={`${individual}.relationshipToLifeInsured`}
                style={{
                  width: 355,
                  color: changePlaceHolderColor(
                    policyOwnerIndividual[index]?.relationshipToLifeInsured,
                  ),
                }}
                component={FormSelect}
                options={[
                  { value: 'Select an option', title: 'Select an option' },
                  ...options.applicationRelationship,
                ]}
                label={
                  <FormattedMessage {...messages.relationWithLifeInsured} />
                }
                type="text"
                margin="normal"
                variant="standard"
              />
            </Grid>

            <Grid item>
              <Grid container alignItems="center" justify="center">
                {fields.length !== 2 &&
                  !insuredOne.isPolicyOwner &&
                  !isInsuredTwoPolicyOwner ? (
                  <Button
                    disabled={disabled}
                    variant="text"
                    color="primary"
                    margin="normal"
                    style={{ marginTop: dimension.spacing.m }}
                    onClick={() =>
                      fields.push(
                        fromJS({
                          prospectId: '',
                          firstName: '',
                          lastName: '',
                          relationshipToLifeInsured: 'Select an option',
                          isPolicyOwner: true,
                          isLifeInsured: false,
                          isPrimaryLifeInsured: false,
                        }),
                      )
                    }
                  >
                    <FormattedMessage {...messages.addAnotherPolicyOwner} />
                  </Button>
                ) : null}

                <Field
                  name={`${individual}.prospectId`}
                  fullWidth
                  type="hidden"
                  style={{ visibility: 'hidden' }}
                  component={FormTextField}
                  color="primary"
                // margin="normal"
                // variant="standard"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

renderIndivisual.propTypes = {
  ...WrappedFieldArrayProps,
  options: PropTypes.object,
  childDetails: PropTypes.object
};

function LifeInsuredForm({
  options,
  childDetails,
  handleSubmit,
  formValues,
  calculateUniversityExpense,
  onSubmit,
  currency,
  disabled,
  productConfig,
  resetPolicyOwnerIndividual,
  policyOwnerIndividual,
  change,
  isBoldInsuredPolicyOwner,
  location,
  gcConfig
}) {
  const theme = useTheme();
  const classes = useStyle();

  const minDob = getStartDate()
    .toDate()
    .setFullYear(
      getStartDate()
        .toDate()
        .getFullYear() - 75,
    );
  const maxDob = getStartDate()
    .toDate()
    .setFullYear(
      getStartDate()
        .toDate()
        .getFullYear() - 18,
    );
    useEffect(()=>{
      if(productConfig?.otherUniversityCountryCode){
        change('childDetails.otherUniversityCountryCode',productConfig?.otherUniversityCountryCode)
      }
    },[productConfig])
  return (
    <>
      {location?.state?.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (

        <form onSubmit={() => handleSubmit(onSubmit)}>
          <Wrapper theme={theme}>
            <FormSection name="childDetails">
              <EspPersonalDetails
              calculateUniversityExpense={calculateUniversityExpense}
                disabled={disabled}
                options={options}
                currency={currency}
                gcConfig={gcConfig}
                formValues={formValues?.childDetails}
                minDate={minDob}
                maxDate={maxDob}
                updatedValues={formValues}
                disabledPolicyOwner={true}
                isNationalityShowing
                change={change}
                productConfig={productConfig}
              />
            </FormSection>
          </Wrapper>
        </form>
      )
      }
    </>
  );
}

LifeInsuredForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  childDetails: PropTypes.object,
  resetInsuredPolicyOwner: PropTypes.func,
  policyOwnerIndividual: PropTypes.array,
  resetPolicyOwnerIndividual: PropTypes.func,
  calculateUniversityExpense:PropTypes.func,
};

const withForm = reduxForm({
  validate,
  form: 'lifeAssuredForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LifeInsuredForm);

const selector = formValueSelector('lifeAssuredForm');

const mapStateToProps = state => {
  const formValues = getFormValues('lifeAssuredForm')(state);
  const currency = selector(state, 'currency');
  //let childDetails = selector(state, 'childDetails');
  let policyOwnerIndividual = selector(state, 'policyOwnerIndividual');
  let isPolicyOwnerIndividual = selector(state, 'isPolicyOwnerIndividual');
  return {
    policyOwnerIndividual: policyOwnerIndividual?.toJS() || [],
    isBoldInsuredPolicyOwner: true,
    currency,
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
  };
};

const mapDispatchToProps = dispatch => ({

  calculateUniversityExpense: value => dispatch(calculateUniversityExpenseAction(value)),
  resetPolicyOwnerIndividual: () =>
    dispatch(
      change(
        'childDetails',
        fromJS([
          {
            prospectId: '',
            firstName: '',
            lastName: '',
            relationshipToLifeInsured: 'Select an option',
            isPolicyOwner: true,
            isLifeInsured: false,
            isPrimaryLifeInsured: false,
          },
        ]),
      ),
    ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withConnect;

import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  fill: currentColor;
`;

function IconPendding(props) {
  return (
    <SvgIcon viewBox="0 0 17 20" version="1.1" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <GA transform="translate(-559.000000, -208.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g transform="translate(559.500000, 198.096309)">
            <path d="M14.8477083,27.6787734 L14.0622167,27.6787734 C14.0622167,23.0424568 9.57916667,22.3335984 8.19976667,19.8621734 C9.57916667,17.3907484 14.0622167,16.6627318 14.0622167,12.0455734 L14.0622167,12.0455734 L14.8477083,12.0455734 C15.28835,12.0455734 15.6332,11.7007234 15.6332,11.2600818 C15.6332,10.8194401 15.28835,10.4745901 14.8477083,10.4745901 L0.785491667,10.4745901 C0.34485,10.4745901 -2.5845992e-13,10.8194401 -2.5845992e-13,11.2600818 C-2.5845992e-13,11.7007234 0.34485,12.0455734 0.785491667,12.0455734 L1.57098333,12.0455734 L1.57098333,12.0455734 C1.57098333,16.6818901 6.05403333,17.3907484 7.43343333,19.8621734 C6.05403333,22.3335984 1.57098333,23.0616151 1.57098333,27.6787734 L0.785491667,27.6787734 C0.34485,27.6787734 -2.5845992e-13,28.0236234 -2.5845992e-13,28.4642651 C-2.5845992e-13,28.9049068 0.34485,29.2497568 0.785491667,29.2497568 L14.8668667,29.2497568 C15.3075083,29.2497568 15.6523583,28.9049068 15.6523583,28.4642651 C15.6332,28.0427818 15.28835,27.6787734 14.8477083,27.6787734 Z M3.12280833,12.0455734 L3.12280833,12.0455734 L12.5103917,12.0455734 L12.5103917,12.0455734 C12.5103917,15.9538734 7.8166,16.7202068 7.8166,18.2911901 C7.8166,16.7202068 3.12280833,15.9538734 3.12280833,12.0455734 Z M3.12280833,27.6787734 C3.12280833,23.7704734 7.8166,22.9849818 7.8166,21.4331568 C7.8166,22.9849818 12.5103917,23.7896318 12.5103917,27.6979318 L3.12280833,27.6787734 L3.12280833,27.6787734 Z" id="Shape" />
          </g>
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconPendding;

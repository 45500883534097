/**
 *
 * BenefitRatingForm
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { FieldArray, reduxForm, formValueSelector } from 'redux-form/immutable';
import { InjectedFormProps } from 'redux-form';
import { useTheme } from '@material-ui/core/styles';
import validate from './validate';
import Benefits from './Benefits';

function BenefitRatingForm({
  lifeAssured,
  coverages2,
  handleSubmit,
  onSubmit,
  change,
  initialValues,
}) {
  const theme = useTheme();
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Typography variant="h3" color="primary">
              {`${lifeAssured.insuredOne.firstName} ${lifeAssured.insuredOne.lastName}`}
            </Typography>

            {coverages2.length > 0 ? (
              <FieldArray
                name="coverages2"
                component={Benefits}
                coverages={coverages2}
                editData={editData}
                insured={1}
                change={change}
                initialValues={initialValues}
                changeEditData={val =>
                  setEditData({ editIndex: val, editInsured: 1 })
                }
              />
            ) : null}
          </Grid>

          {lifeAssured.isInsuredTwo ? (
            <Grid item xs={12}>
              <Typography
                variant="h3"
                color="primary"
                style={{ marginTop: theme.spacing(4) }}
              >
                {`${lifeAssured.insuredTwo.firstName} ${lifeAssured.insuredTwo.lastName}`}
              </Typography>

              {coverages2.length > 0 ? (
                <FieldArray
                  name="coverages2"
                  component={Benefits}
                  insured={2}
                  coverages={coverages2}
                  editData={editData}
                  change={change}
                  initialValues={initialValues}
                  changeEditData={val =>
                    setEditData({ editIndex: val, editInsured: 2 })
                  }
                />
              ) : null}
            </Grid>
          ) : null}
        </Grid>
      </form>
    </>
  );
}

BenefitRatingForm.propTypes = {
  ...InjectedFormProps,
  lifeAssured: PropTypes.object,
  coverages: PropTypes.array,
  change: PropTypes.func,
};

const withForm = reduxForm({
  form: 'benefitRatingForm',
  warn: validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(BenefitRatingForm);

const selector = formValueSelector('benefitRatingForm');

const withConnect = connect(state => {
  let coverages2 = selector(state, 'coverages2');
  return {
    coverages2: coverages2?.toJS() || [],
  };
})(withForm);

export default withConnect;

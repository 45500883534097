import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Grid,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import CustomDialog from 'components/Dialog';
import IconEditSrc from 'images/open-external.svg';
import IconDeleteSrc from 'images/icon-trash.svg';
import { mapStatus } from '../helper';
import { convertIfImmutable } from 'helpers/lang';


const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#4066B3',
    fontSize: 16,
    borderBottom: '1px solid #ffffff',
  },
  body: {
    fontSize: 14,
    borderBottom: '1px solid #ffffff',
    maxWidth: 140,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  table: {
    minWidth: 700,
  },
  lifeInsured: {
    fontWeight: 'bold',
  },
  btnUpLoad: {
    color: '#4066B3',
    float: 'left',
    cursor: 'pointer',
    marginTop: '0.3rem',
    marginRight: '2rem',
  },
  btnDelete: {
    color: '#b6b8b4',
    cursor: 'pointer',
  },
  customTable: {
    overflowY: 'scroll',
  },
  inProcessText: {
    color: '#00C3B4',
    textTransform: 'capitalize',
  },
  pendingText: {
    color: '#FF8F4A',
    textTransform: 'capitalize',
  },
  statusListText: {
    color: '#2167AE',
    textTransform: 'capitalize',
  },
  actionableText: {
    color: '#E75252',
    textTransform: 'capitalize',
  },
  submittedText: {
    color: '#043594',
    textTransform: 'capitalize',
  },
  customBox: {
    marginTop: 15,
  },
  btnSearch: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
}));

export function TableApplication({
  dataApplication,
  setApplicationDetails,
  status='Pending',
  search=''
}) {
  const classes = useStyles();
  const history = useHistory();
  const [addOpsDetails, setAddOpsDetails] = React.useState([])
  const [open,setOpen] = useState(false)
  const [idDelete, setIdDelete] = useState('');
  const [name, setName] = useState('');
  const upLoad = (id, AdminActionStatus) => {
    console.log("Icon Clicked Application id :: ", id);
    setApplicationDetails({});
    sessionStorage.removeItem("agentNumber");
    sessionStorage.removeItem("alreadySubmitted");
    sessionStorage.removeItem("applicationId");
    sessionStorage.removeItem("referenceNumber");
    history.push({
      pathname: `adminapplications/${id}`,
      state: { AdminActionStatus},
      applicationId: id
    });
  };
  const sortDate = () => {};

  const formatDate = date => {
    return moment(date).format('DD MMM YYYY');
  };

  function displayApplications(item,index) {
    return <TableRow key={index} className={classes.root}>
      <StyledTableCell
        component="th"
        scope="row"
        className={classes.lifeInsured}
      >
        {`${item.parties.firstName} ${item.parties.lastName}`}
      </StyledTableCell>
      <StyledTableCell>
        {formatDate(item.parties.dateOfBirth)}
      </StyledTableCell>
      <StyledTableCell>
        {formatDate(item.AdminApplicationCreatedAt)}
      </StyledTableCell>
      <StyledTableCell>{item.productName}</StyledTableCell>
      <StyledTableCell>{item.policyNumber}</StyledTableCell>
      <StyledTableCell>{item.externalReferenceNumber}</StyledTableCell>
      <StyledTableCell
        className={mapStatus(classes, item.AdminActionStatus                      )}
      >
        {item.AdminActionStatus.toUpperCase()}
      </StyledTableCell>
      <StyledTableCell align="right">
        <img
          src={IconEditSrc}
          alt=""
          className={classes.btnUpLoad}
          onClick={() => upLoad(item.id, item.AdminActionStatus)}
          //onClick={() => {
          //  history.push(`/OpsTesting`);
          //}}
        />
      </StyledTableCell>
    </TableRow>
  }
  function filterList(item,index,status){
    if (search === '' ){
      if (status.toUpperCase() === 'ALL'){
        return displayApplications(item,index)
      } else if (status.toUpperCase() === item.AdminActionStatus.toUpperCase() ){
        return displayApplications(item,index)
      }
    } else if (search.toUpperCase() === item.parties.firstName.slice(0,search.length).toUpperCase()){
      if (status.toUpperCase() === 'ALL'){
        return displayApplications(item,index)
      } else if (status.toUpperCase() === item.AdminActionStatus.toUpperCase() ){
        return displayApplications(item,index)
      }
    } else if (search.toUpperCase() === item.parties.lastName.slice(0,search.length).toUpperCase()){ 
      if (status.toUpperCase() === 'ALL'){
        return displayApplications(item,index)
      } else if (status.toUpperCase() === item.AdminActionStatus.toUpperCase() ){
        return displayApplications(item,index)
      }
    } else if (search.toUpperCase() === item.productName.slice(0,search.length).toUpperCase()){ 
      if (status.toUpperCase() === 'ALL'){
        return displayApplications(item,index)
      } else if (status.toUpperCase() === item.AdminActionStatus.toUpperCase() ){
        return displayApplications(item,index)
      }
    }else if (item.policyNumber != null){ 
      if (search === item.policyNumber.slice(0,search.length)){
        if (status.toUpperCase() === 'ALL'){
          return displayApplications(item,index)
        } else if (status.toUpperCase() === item.AdminActionStatus.toUpperCase() ){
          return displayApplications(item,index)
        }
      }
    }else if (item.externalReferenceNumber != null){ 
      if (search.toUpperCase() === item.externalReferenceNumber.slice(0,search.length).toUpperCase()){
        if (status.toUpperCase() === 'ALL'){
          return displayApplications(item,index)
        } else if (status.toUpperCase() === item.AdminActionStatus.toUpperCase() ){
          return displayApplications(item,index)
        }
      }
    } else {}
  }
  return (
    <>
      <Box mt={5} className={classes.customBox}>
            <TableContainer classes={{ root: classes.customTable }}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ width: '15%' }}>Name</StyledTableCell>
                    <StyledTableCell style={{ width: '12%' }}>
                      DOB
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '12%' }}>
                        Submitted
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '18%' }}>
                      Product
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '12%' }}>
                      Policy no.
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '12%' }}>
                      External Ref no.
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '10%' }}>
                      Status 
                    </StyledTableCell>
                    <StyledTableCell style={{ width: '12%' }}>
                      Action
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataApplication.map((item, index) => ( filterList(item,index,status)))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
      
    </>
  );
}
TableApplication.propTypes = {
  dataApplication: PropTypes.func,
  setApplicationDetails: PropTypes.func,
};
const mapStateToProps = createStructuredSelector({});
function mapDispatchToProps(dispatch) {
  return {
  };
}
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withConnect)(TableApplication);
/*
 *
 * EPSavingIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/EPSavingIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const CHANGE_CHILD_LIFE_ASSURED_ACTION =
  'app/EPSavingIllustrationPage/CHANGE_CHILD_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/EPSavingIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const CHANGE_PRODUCT_CONFIG_ACTION =
  'app/EPSavingIllustrationPage/CHANGE_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/EPSavingIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const SET_COVERAGES_LIST2_ACTION =
  'app/EPSavingIllustrationPage/SET_COVERAGES_LIST2_ACTION';
export const GET_GC_CONFIG_ACTION =
  'app/EPSavingIllustrationPage/GET_GC_CONFIG_ACTION';
export const SET_GC_CONFIG_ACTION =
  'app/EPSavingIllustrationPage/SET_GC_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION =
  'app/EPSavingIllustrationPage/CREATE_QUOTE_ACTION';
  export const CALCULATE_UNIVERSITY_EXPENSE_ACTION = 
  'app/EPSavingIllustrationPage/CALCULATE_UNIVERSITY_EXPENSE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/EPSavingIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/EPSavingIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/EPSavingIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/EPSavingIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/EPSavingIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/EPSavingIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/EPSavingIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/EPSavingIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/EPSavingIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/EPSavingIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/EPSavingIllustrationPage/SET_PTV_VERSION_ACTION';
export const SET_ERROR_ACTION = 'app/EPSavingIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/EPSavingIllustrationPage/RESET_ACTION';
export const GET_FIA_ACTION = 'app/EPSavingIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/EPSavingIllustrationPage/SET_FIA_ACTION';
export const SET_BI_TABLE_VALUES_ACTION =
  'app/EPSavingIllustrationPage/SET_BI_TABLE_VALUES_ACTION';
export const SET_BI_TABLE_VALUES_ACTION1 =
  'app/EPSavingIllustrationPage/SET_BI_TABLE_VALUES_ACTION1';
export const CLONE_QUOTE_ACTION =
  'app/EPSavingIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/EPSavingIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION =
  'app/EPSavingIllustrationPage/SET_BI_PDF_ACTION';

export const SET_BI_PDF_ACTION1 =
  'app/EPSavingIllustrationPage/SET_BI_PDF_ACTION1';
export const SET_CONFIG_NAME_ACTION =
  'app/EPSavingIllustrationPage/SET_CONFIG_NAME_ACTION';
export const SET_EMAIL_SENT_ACTION =
  'app/EPSavingIllustrationPage/SET_EMAIL_SENT_ACTION';

export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/EPSavingIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';

export const SET_COVERAGES2_ACTION =
  'app/EPSavingIllustrationPage/SET_COVERAGES2_ACTION';
export const SET_PRODUCT_CODE_ACTION =
  'app/EPSavingIllustrationPage/SET_PRODUCT_CODE_ACTION';

import _ from 'lodash';

export const allSubOrderList = subPageList => {
  const list = [];

  _.forEach(subPageList, menu => {
    _.forEach(menu.subOrder, subOrder => {
      list.push(subOrder);
    });
  });

  return list;
};

import { withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';

const styles = theme => ({
  root: {
    height: 8,
    padding: 0,
    borderRadius: 8 / 2,
    backgroundColor: theme.palette.grey[400],
  },
  thumb: {
    width: 23,
    height: 23,
    border: `2px solid ${theme.palette.primary.dark}`,
    backgroundColor: theme.palette.common.white,
  },
  track: {
    height: 8,
    borderRadius: 8 / 2,
    border: `2px solid ${theme.palette.common.white}`,
    backgroundColor: theme.palette.primary.main,
  },
  rail: {
    height: 8,
    backgroundColor: theme.palette.common.white,
  },
});

export default withStyles(styles)(Slider);
/*
 * GcIllustrationPage Messages
 *
 * This contains all the text for the GcIllustrationPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.GcIllustrationPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the GcIllustrationPage container!',
  },
  policyOwner: {
    id: `${scope}.policyOwner`,
    defaultMessage: 'Policy Owner',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email address (optional)',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  selectOption: {
    id: `${scope}.selectOption`,
    defaultMessage: 'Select an option',
  },
  isSecondPolicyOwner: {
    id: `${scope}.isSecondPolicyOwner`,
    defaultMessage: 'Do you want to add a second policy owner?',
  },
  whoLifeInsured: {
    id: `${scope}.whoLifeInsured`,
    defaultMessage: 'Who will be the life insured?',
  },
  insuredOne: {
    id: `${scope}.insuredOne`,
    defaultMessage: 'Insured 1',
  },
  insuredTwo: {
    id: `${scope}.insuredTwo`,
    defaultMessage: 'Insured 2',
  },
  isSecondInsured: {
    id: `${scope}.isSecondInsured`,
    defaultMessage: 'Do you want to insure a second life e.g. a spouse?',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  coverageAmount: {
    id: `${scope}.coverageAmount`,
    defaultMessage: 'Coverage Amount Required',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Policy term (in years)',
  },
  policyFrequency: {
    id: `${scope}.policyFrequency`,
    defaultMessage: 'Policy Frequency',
  },
  premiumWaiverBenefit: {
    id: `${scope}.premiumWaiverBenefit`,
    defaultMessage: 'Avail of premium waiver benefit?',
  },
  descriptionTwo: {
    id: `${scope}.descriptionTwo`,
    defaultMessage:
      'Waiver of Premium (Savings plan) – This benefit will pay your regular savings premiums in the event that you are permanently and totally disabled during the term of your plan. Payments will continue for the remainder of your premium payment term, continuing to buy units in your chosen funds and therefore saving towards your goal- to be reviewed in line with relevant Terms & Conditions.',
  },
  descriptionWOP: {
    id: `${scope}.descriptionWOP`,
    defaultMessage:
      'Waiver of Premium (Protection plan) – This benefit will pay your protection premiums in the event that you are permanently and totally disabled during the term of your plan. Payments will continue for the remainder of your premium payment term and will ensure that any of your chosen protection benefits (such as Life Cover and Critical Illness) will continue to be offered- to be reviewed in line with relevant Terms & Conditions.',
  },
  descriptionEsc: {
    id: `${scope}.descriptionEsc`,
    defaultMessage:
      'The premiums increase by 5% each year i.e. If the monthly premium is $1000 in year one, it will increase to $1050 per month in year two, and so on.',
  },
  descriptionAlc: {
    id: `${scope}.descriptionAlc`,
    defaultMessage:
      'An optional extra life cover benefit that pays up to a lower of 5 times the yearly premium equivalent or USD250,000 (or currency equivalent) in the event of death of the life insured.',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  enterAmount: {
    id: `${scope}.enterAmount`,
    defaultMessage: 'Enter Amount',
  },
  editPremiumAmount: {
    id: `${scope}.editPremiumAmount`,
    defaultMessage: 'Edit Premium Amount*',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  quoteDescription: {
    id: `${scope}.quoteDescription`,
    defaultMessage:
      '*Editing the illustration directly impacts the sum assured and renders the extra benefits void',
  },
  insuredParty: {
    id: `${scope}.insuredParty`,
    defaultMessage: 'The Insured',
  },
  policySummary: {
    id: `${scope}.policySummary`,
    defaultMessage: 'Policy Summary',
  },
  isPolicyOwnerIndividual: {
    id: `${scope}.isPolicyOwnerIndividual`,
    defaultMessage: 'Will the policy owner(s) be other individuals?',
  },
  relationWithLifeInsured: {
    id: `${scope}.relationWithLifeInsured`,
    defaultMessage: 'Relationship to life insured',
  },
  assumedStartDate: {
    id: `${scope}.assumedStartDate`,
    defaultMessage: 'Assumed start date',
  },
  paymentFrequency: {
    id: `${scope}.paymentFrequency`,
    defaultMessage: 'Payment frequency (if applicable)',
  },
  specialOffer: {
    id: `${scope}.specialOffer`,
    defaultMessage: 'Special offer',
  },
  addAnotherPolicyOwner: {
    id: `${scope}.addAnotherPolicyOwner`,
    defaultMessage: 'Add another policy owner',
  },
  added: {
    id: `${scope}.added`,
    defaultMessage: 'Added',
  },
  addedInfo: {
    id: `${scope}.addedInfo`,
    defaultMessage: `We have added this illustration to the client's list`,
  },
  exitQuoteNotice: {
    id: `${scope}.exitQuoteNotice`,
    defaultMessage: `You have not completed the quote. Are you sure you want to exit now?`,
  },
  clientList: {
    id: `${scope}.clientList`,
    defaultMessage: 'Client List',
  },
  createAnother: {
    id: `${scope}.createAnother`,
    defaultMessage: 'Create Another',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  notePremiumCalculation: {
    id: `${scope}.notePremiumCalculation`,
    defaultMessage:
      'Note: editing the premium directly impacts the level of life cover, and resets the extra benefits.',
  },
  rspIllustration: {
    id: `${scope}.rspIllustration`,
    defaultMessage: 'Legacy',
  },
  viewExportPDF: {
    id: `${scope}.viewExportPDF`,
    defaultMessage: 'View / Export PDF',
  },
  emailPDF: {
    id: `${scope}.emailPDF`,
    defaultMessage: 'Email PDF',
  },
  pleaseEnter: {
    id: `${scope}.pleaseEnter`,
    defaultMessage: 'Please enter',
  },
  reference: {
    id: `${scope}.reference`,
    defaultMessage: 'Reference',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Cover details',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium summary',
  },
  premiumAmount: {
    id: `${scope}.premiumAmount`,
    defaultMessage: 'Premium amount',
  },
  reviewNotice: {
    id: `${scope}.reviewNotice`,
    defaultMessage: 'Please open and review the illustration to proceed',
  },
  brDebits: {
    id: `${scope}.brDebits`,
    defaultMessage: 'Benefits rating details',
  },
  backToClientList: {
    id: `${scope}.backToClientList`,
    defaultMessage: 'Back to client list',
  },
  specialTerms: {
    id: `${scope}.specialTerms`,
    defaultMessage: 'Special terms',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Premium payment term',
  },
  assumedGrowthRate: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'Assumed growth rate',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Start date',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'End date',
  },
  frequency: {
    id: `${scope}.frequency`,
    defaultMessage: 'Frequency',
  },
  regularWithdrawalTitle: {
    id: `${scope}.regularWithdrawalTitle`,
    defaultMessage: 'Would you like to take regular withdrawals?',
  },
  eduWithdrawalTitle: {
    id: `${scope}.eduWithdrawalTitle`,
    defaultMessage: 'Would you like to take withdrawals for education fees?',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  percentage: {
    id: `${scope}.percentage`,
    defaultMessage: 'Percentage',
  },

  premiumPaid: {
    id: `${scope}.premiumPaid`,
    defaultMessage: 'Premiums paid',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Policy term',
  },
  fundValues: {
    id: `${scope}.fundValues`,
    defaultMessage: 'Fund values',
  },
  projectedFundValues: {
    id: `${scope}.projectedFundValues`,
    defaultMessage: '30 years projected fund value',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: `First name`,
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: `Last name`,
  },
  childFirstName: {
    id: `${scope}.childFirstName`,
    defaultMessage: `Child's first name`,
  },
  childFamilyName: {
    id: `${scope}.childFamilyName`,
    defaultMessage: `Child's family name`,
  },
  childLastName: {
    id: `${scope}.childLastName`,
    defaultMessage: `Child's last name`,
  },
  premiumPaymentTerm: {
    id: `${scope}.premiumPaymentTerm`,
    defaultMessage: 'Premium Payment term',
  },
  withdrawals: {
    id: `${scope}.withdrawals`,
    defaultMessage: 'Withdrawals',
  },
  percentageFundValue: {
    id: `${scope}.percentageFundValue`,
    defaultMessage: 'Percentage of fund value',
  },
  growthRate: {
    id: `${scope}.growthRate`,
    defaultMessage: '0.0%',
  },
  errorEmail: {
    id: `${scope}.errorEmail`,
    defaultMessage: '{message}',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error',
  },
  itaBenefitTitle: {
    id: `${scope}.itaBenefitTitle`,
    defaultMessage: 'Would you like add protection benefits to your plan?',
  },
  notAvailableWithITA: {
    id: `${scope}.notAvailableWithITA`,
    defaultMessage:
      'Half-Yearly/Quarterly not available with ITA protection benefits',
  },
  permanent: {
    id: `${scope}.permanent`,
    defaultMessage: 'Permanent',
  },
  temporary: {
    id: `${scope}.temporary`,
    defaultMessage: 'Temporary',
  },
  perMilleAndMonths: {
    id: `${scope}.perMilleAndMonths`,
    defaultMessage: 'Per mille and months',
  },
  protectionCoverDetails: {
    id: `${scope}.protectionCoverDetails`,
    defaultMessage: 'Protection benefits',
  },
  protectionBenefit: {
    id: `${scope}.protectionBenefit`,
    defaultMessage: 'Protection benefit',
  },
  savingsPremiumAmount: {
    id: `${scope}.savingsPremiumAmount`,
    defaultMessage: 'Savings premium amount',
  },
  period: {
    id: `${scope}.period`,
    defaultMessage: 'Period',
  },
  countryOfEducation: {
    id: `${scope}.countryOfEducation`,
    defaultMessage: 'Country of education',
  },
  countryOfResidence: {
    id: `${scope}.countryOfResidence`,
    defaultMessage: 'Country of residence',
  },
  universityStartDate: {
    id: `${scope}.universityStartDate`,
    defaultMessage: 'University start year',
  },
  noOfYearOfStudy: {
    id: `${scope}.noOfYearOfStudy`,
    defaultMessage: 'No of year of study',
  },
  projectedExpenseFee: {
    id: `${scope}.projectedExpenseFee`,
    defaultMessage: 'Projected University Fee',
  },
  policyOwner1FirstName: {
    id: `${scope}.policyOwner1FirstName`,
    defaultMessage: 'Policy owner 1 First name',
  },
  policyOwner1FamilyName: {
    id: `${scope}.policyOwner1FamilyName`,
    defaultMessage: 'Policy owner 1 Family name',
  },
  policyOwner2FirstName: {
    id: `${scope}.policyOwner2FirstName`,
    defaultMessage: 'Policy owner 2 First name',
  },
  policyOwner2FamilyName: {
    id: `${scope}.policyOwner2FamilyName`,
    defaultMessage: 'Policy owner 2 Family name',
  },
  relationshipToChild: {
    id: `${scope}.relationshipToChild`,
    defaultMessage: 'Relationship to child',
  },
  additionalSinglePremiumTitle: {
    id: `${scope}.additionalSinglePremiumTitle`,
    defaultMessage: 'Would you like to add an additional single premium (ASP)',
  },
  additionalSinglePremium: {
    id: `${scope}.additionalSinglePremium`,
    defaultMessage: 'Additional single premium',
  },
  targetFundProtection: {
    id: `${scope}.targetFundProtection`,
    defaultMessage: 'Target fund protection',
  },
  withdrawalPayOutPreioud: {
    id: `${scope}.withdrawalPayOutPreioud`,
    defaultMessage: 'Withdrawal payout period',
  },
  withdrawalFrequency: {
    id: `${scope}.withdrawalFrequency`,
    defaultMessage: 'Withdrawal frequency',
  },
  startYear: {
    id: `${scope}.startYear`,
    defaultMessage: 'Start year',
  },
  endYear: {
    id: `${scope}.endYear`,
    defaultMessage: 'End year',
  },
  theChild: {
    id: `${scope}.theChild`,
    defaultMessage: 'Your Child',
  },
  withdrawalPeriod: {
    id: `${scope}.withdrawalPeriod`,
    defaultMessage: 'Withdrawal period',
  },
  withdrawalDuration: {
    id: `${scope}.withdrawalDuration`,
    defaultMessage: 'Withdrawal duration',
  },
  validTargetYear: {
    id: `${scope}.validTargetYear`,
    defaultMessage: 'Please select Year between {value1} to {value2}',
  },
  validPayoutYears: {
    id: `${scope}.validPayoutYears`,
    defaultMessage: 'Please enter value between 1 to 5',
  },
  futurePremiumProtection: {
    id: `${scope}.validPayoutYears`,
    defaultMessage: 'Future Premium Protection',
  },
  childDetailsWarningMsg1: {
    id: `${scope}.childDetailsWarningMsg1`,
    defaultMessage: 'Please note that the ‘ Country of education cannot be edited. University details can be edited in the coverage screen.’',
  },
  minAgeDateValidation: {
    id: `${scope}.minAgeDateValidation`,
    defaultMessage: 'Please select date till {value1}',
  },
  fppHelperText: {
    id: `${scope}.fppHelperText`,
    defaultMessage: 'Remaining future premiums are paid if a covered parent dies or is disabled during payment term',
  },
  countryOfResident: {
    id: `${scope}.countryOfResident`,
    defaultMessage: 'This product is design for UAE residents only',
  },
  countryOfEducationOthers:{
    id: `${scope}.countryOfEducationOthers`,
    defaultMessage: '  ',
  }

});

import React from 'react';
import PropTypes from 'prop-types';
import numeral from 'numeral';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { map } from 'lodash/collection';
import { isNumber, isEmpty, toString } from 'lodash/lang';
import { get } from 'lodash/object';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { formatMonetaryValue } from 'helpers/math';
import InfoIcon from '@material-ui/icons/Info';
import messages from 'containers/ApplicationPage/messages';
import { getPaymentFrequency } from 'containers/ApplicationPage/helper'
import { productCodeCheck } from 'helpers/constants';

const StyledTableCell = withStyles(theme => ({
  root: {},
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);
function createData(checked, benefitName, amount) {
  return { checked, benefitName, amount };
}
const elementsList = [
  {
    premiumAmount: 'Premium Amount',
    amount: 'USD 500',
  },
]

const elementsList1 = [
  {
    policyTerm: 'Policy Term',
    duration: '15 years',
  },
]

const elementsList2 = [
  {
    benefitName: 'Ishita Vagh',
    amount: '23 Dec 2022',
  },
]

function PremiumSummaryDetails(props) {
  const theme = useTheme();
  const intl = useIntl();
  const { applicationDetails, productConfigs } = props;
  const policyCoverTerm = productConfigs?.policyCoverTerm;

  let aspFrequencyLabel = 'p/month';
  switch (productConfigs?.paymentFrequency) {
    case 'Monthly':
      aspFrequencyLabel = 'p/month';
      break;
    case 'Quarterly':
      aspFrequencyLabel = 'p/quarter';
      break;
    case 'Half-Yearly':
      aspFrequencyLabel = 'p/half-year';
      break;
    case 'Yearly':
      aspFrequencyLabel = 'p/year';
      break;
    default:
        if (productConfigs?.singlePremiumTerm >= 0 && productConfigs?.regularPremiumSliceLength === 0){
          aspFrequencyLabel = 'per year'
        } else {
          aspFrequencyLabel = 'p/month';
        }
        break;
  }
  const rows = [
    // ITA
    (applicationDetails.premium || productConfigs?.premium) &&
    createData(
      false,
      'Premium amount',
      (!productCodeCheck('WAP', productConfigs?.productCode)
        && !productCodeCheck('IWA', productConfigs?.productCode)) // for new product IWA
        ? productConfigs?.singlePremiumTerm >= 0 && productConfigs?.regularPremiumSliceLength === 0
          ? `${productConfigs?.currency} ${formatMonetaryValue(
            applicationDetails.productConfigs.premium,
            true,
          )}
          ${getPaymentFrequency(productConfigs?.paymentFrequency,productConfigs?.singlePremiumTerm)}
          `
          : `${productConfigs?.currency} ${formatMonetaryValue(
              applicationDetails.premium,
              true,
            )}
        ${getPaymentFrequency(productConfigs?.paymentFrequency,productConfigs?.singlePremiumTerm)}
        `
          : `${productConfigs?.currency} ${formatMonetaryValue(
              applicationDetails.premium,
              true,
            )}`,
    ),
    productConfigs?.lifeBasis
      ? createData(
        false,
        intl.formatMessage(messages.policyBasis),
        productConfigs.lifeBasis === 'JLLS' ||
          productConfigs.lifeBasis === 'JLLD'
          ? 'Joint life last death'
          : productConfigs.lifeBasis === 'SL'
            ? 'Single life'
            : productConfigs.lifeBasis === 'JLFD'
              ? 'Joint life first death'
              : productConfigs.lifeBasis === 'JLBD'
                ? 'Joint life both death'
                : '',
      )
      : null,
    productConfigs?.term &&
    (productCodeCheck(3, productConfigs.productCode)
      ? null
      : createData(
        false,
        intl.formatMessage(messages.policyTermTitle),
        `${productConfigs?.term} Years`,
      )),
    isNumber(productConfigs?.interestRate) &&
    createData(
      false,
      intl.formatMessage(messages.interestRateTitle),
      `${numeral(productConfigs?.interestRate * 100).format('0')} %`,
    ),
    // FUTURA
    policyCoverTerm && productCodeCheck(3, applicationDetails.productCode)
      ? policyCoverTerm
        ? createData(
          false,
          intl.formatMessage(messages.coverageTerm),
          policyCoverTerm === 'WholeOfLife'
              ? 'Whole of life'
              : policyCoverTerm === 'CustomPremium'
              ? `${applicationDetails.pricingData.response.premium} Years`
              : productConfigs?.singlePremiumTerm >= 0 && productConfigs?.regularPremiumSliceLength === 0
              ? `${productConfigs?.policyTerm} Years`
              : `${policyCoverTerm} Years`,
        )
        : null
      : null,
    productCodeCheck(3, applicationDetails.productCode) && productConfigs?.term
      ? createData(
        false,
        intl.formatMessage(messages.premiumPaymentTerm),
        productConfigs?.term === 'SinglePremium'
            ? 'Single premium'
            : productConfigs?.term === 'WholeOfLife'
            ? 'Whole of life'
            : `${productConfigs?.term} Years`,
        )
      : null,
    productConfigs?.growthRate &&
    createData(
      false,
      intl.formatMessage(messages.assumedGrowthRate),
      `${productConfigs?.growthRate} %`,
    ),
    productCodeCheck(3, applicationDetails.productCode)
      ? productConfigs?.fiaCharge !== 0
        ? createData(
          false,
          intl.formatMessage(messages.advisorFeeLabel),
          `${productConfigs?.fiaCharge} %`,
        )
        : null
      : null,
    productConfigs?.additionalSinglePremium
      ? createData(
        false,
        intl.formatMessage(messages.additionalSinglePremium),
          // `${productConfigs?.additionalSinglePremium}`,
          `${productConfigs?.currency} ${formatMonetaryValue(
            productConfigs.additionalSinglePremium,
            true,
          )} ${productConfigs?.singlePremiumTerm >= 0 && productConfigs?.regularPremiumSliceLength === 0 ? '' : aspFrequencyLabel}
          `,
        )
      : null,
  ];
  if (rows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.premiumSummary} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {map(rows, (row, index) => {
                    if (row) {
                      return (
                        <StyledTableRow key={`${row.benefitName}-${row.index}`}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{ width: '70%' }}
                          >
                            <Typography variant="h4">
                              <InfoIcon
                                fontSize="small"
                                style={{
                                  color: 'gray',
                                  marginRight: '1rem',
                                }}
                              />
                              {`${row.benefitName}`}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '30%' }}>
                            <Typography variant="h4">
                              {productConfigs && row.amount}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
};
PremiumSummaryDetails.propTypes = {
  productConfigs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  applicationDetails: PropTypes.object,
};
export default PremiumSummaryDetails;

/**
 *
 * ApplicationPage
 *
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { Grid, Typography, Button, IconButton, Link } from '@material-ui/core';

import Dot from '@material-ui/icons/FiberManualRecord';
import { convertIfImmutable } from 'helpers/lang';
// import { replace } from 'lodash/string';

import { useTheme } from '@material-ui/core/styles';
import { makeSelectSubPageStepper } from 'containers/DashboardPage/selectors';
import iconPencil from 'images/icon-pencil.svg';
import CustomDialog from 'components/Dialog';
import BenefitRatingSummary from 'components/BenefitRatingSummary';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import {
  getApplicationDetailsAction,
  updateApplicationDetailsAction,
  updateApplicationStatusAction,
  setLogoutWhenUpdateAppAction,
  getAdminActionDetailAction
} from 'containers/ApplicationPage/actions';
import {
  changeSubPageAction,
  changeSubStepPageAction,
  changeSubPageListAction,
  logoutAction,
} from 'containers/DashboardPage/actions';
import { useParams, useHistory } from 'react-router-dom';
// import { getAdminActionDetailAction} from 'containers/OpsDashboardPage/actions';
// import { makeSelectAdminActionDetails } from 'containers/OpsDashboardPage/selectors';
import globalMessages from 'containers/App/messages';
import {
  makeSelectOfferCodeData,
  makeSelectOption,
} from 'containers/App/selectors';
import { isEmpty } from 'lodash';
import { productCodeCheck } from 'helpers/constants';
import { useOktaAuth } from '@okta/okta-react';
import { Wrapper } from 'helpers/layout';
import {
  makeSelectApplicationSubOrder,
  makeSelectApplicationPage,
  makeSelectApplicationDetails,
  makeSelectLogout,
  makeSelectAdminActionDetails
} from '../../selectors';
import messages from '../../messages';
// components
import PolicyOwner from './PolicyOwner';
import InsuredParty from './insuredParty';
import PolicySummary from './PolicySummary';
import Beneficiaries from './Beneficiaries';
import PremiumSummary from './PremiumSummary';
import Withdrawals from './Withdrawals';
import Payor from './Payor';
import Dialog from './Dialog';
import { isHSBCBank, isNexusAgency } from 'helpers/lang';
// actions

import { mapStatus, useStyles } from '../../helper';

export function Application(props) {
  const {
    options,
    isLogout,
    changeSubPage,
    changeSubStepPage,
    getApplicationDetails,
    applicationDetails,
    updateApplicationDetails,
    match,
    updateApplicationStatus,
    offerCodeData,
    resetOfferCode,
    validateOfferCode,
    logout,
    setLogout,
    getAdminActionDetail,
    adminActionDetails,
  } = props;

  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  // const { id } = useParams();
  const { oktaAuth } = useOktaAuth();
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState(false);
  const [isOpenPWC, setOpenPWC] = useState(false);
  const [isOpenSuccess, setSuccessPopup] = useState(false);

  const appDetails = convertIfImmutable(applicationDetails);
  const productConfig = appDetails?.productConfig;
  const adminActionDetail = convertIfImmutable(adminActionDetails);

  useEffect(() => {
    getApplication();
    // updateAppDetail(false);

  }, []);

  useEffect(() => {
    if (isLogout) {
      setLogout(false);
      logout(oktaAuth);
    }
  }, [isLogout]);

  useEffect(() => {
    if (
      appDetails.status === 'COMPLETED' &&
      appDetails.paymentInvoiceNumber &&
      !appDetails?.additionalDetails?.isViewSuccessPopup
    ) {
      setSuccessPopup(true);
    } else {
      setSuccessPopup(false);
    }
  }, [appDetails]);

  useEffect(() => {
    if (appDetails.isInvalidPWCform) {
      setOpenPWC(true);
    }
  }, [appDetails.isInvalidPWCform]);

  useEffectSkipFirst(() => {
    const { isBolton, productConfigs, isInforce } = appDetails;
    const {
      offerCodes,
      productCode,
      productVersion,
      term,
      currency,
      coverages,
      premium,
    } = productConfigs;

    resetOfferCode();
    if (
      !isBolton &&
      productConfigs?.offerCodes &&
      productConfigs?.offerCodes[0]?.code &&
      !isInforce
    ) {
      validateOfferCode({
        offerCode: offerCodes[0]?.code,
        productCode,
        productVersion,
        premiumType: term || 'SinglePremium',
        currency,
        premium: term ? coverages?.[0]?.['LFC']?.value : premium,
      });
    }
    getAdminActionById(); // to get admin module related data 

  }, [applicationDetails]);

  const getAdminActionById = async () => {

    const { id } = appDetails;
    await getAdminActionDetail(id);
  };

  function getProductConfig(isBolton) {
    return isBolton ? appDetails.productConfigs[0] : appDetails.productConfigs;
  }

  function getProductConfig1(isBolton) {
    return isBolton ? appDetails.productConfigs[1] : {};
  }

  const getApplication = () => {
    setTimeout(() => {
      getApplicationDetails(match.params.applicationId);
    }, 300);
  };

  const updateAppDetail = bLogout => {
    updateApplicationDetails(
      {
        id: appDetails.id,
        additionalDetails: {
          ...appDetails.additionalDetails,
          isViewSuccessPopup: true,
        },
      },
      bLogout,
    );
  };

  function handleContinueClicked() {
    changeSubPage(1);
    changeSubStepPage(1);
    history.push(`/applications/${appDetails.id}/form/policy-owner`);
    if (appDetails.status === 'INITIALIZED') {
      updateApplicationStatus({
        statusName: 'isOpen',
        status: true,
        applicationId: appDetails.id,
      });
    }
  }

  return (
    <Wrapper theme={theme}>
      <Grid container spacing={5} style={{ maxWidth: '820px' }}>
        <Grid item xs={12} md={10} style={{ paddingBottom: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Grid
                container
                spacing={3}
                style={{ paddingBottom: theme.spacing(3) }}
              >
                <Grid item xs={12}>
                  {/* APPLICATION NUMBER */}
                  <Typography
                    variant="h1"
                    style={{
                      marginTop: theme.spacing(1),
                      fontSize: '3rem',
                    }}
                  >
                    <FormattedMessage
                      {...messages.applicationNumber}
                      values={{
                        value: appDetails.referenceNumber,
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.flexCenter}>
                    <Typography
                      variant="body2"
                      className={classes.addExternalNumber}
                    >
                      {appDetails.externalReferenceNumber ? (
                        appDetails.externalReferenceNumber
                      ) : (
                        <FormattedMessage {...messages.addExternalNumber} />
                      )}
                    </Typography>
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <img
                        src={iconPencil}
                        alt={iconPencil}
                        className={classes.addExternalNumberIcon}
                      />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {appDetails.status === 'PAYMENT_RECEIVED' ||
              appDetails.status === 'PENDING_PAYMENT' ||
              appDetails.status === 'COMPLETED' ? (
              ''
            ) : (
              <Grid item xs container>
                <Grid
                  item
                  xs={12}
                  container
                  justify="flex-end"
                // alignItems="flex-end"
                >
                  {(appDetails?.isInforce === true) ?
                    <Button
                      variant="contained"
                      color="primary"
                      margin="normal"
                      className={classes.openButton}
                      disabled={
                        offerCodeData.errorMessage ||
                        offerCodeData.validating ||
                        (isEmpty(appDetails) &&
                          getProductConfig(appDetails.isBolton) === undefined) ||
                        appDetails.isInvalidPWCform
                        || (!isEmpty(appDetails) &&
                          (appDetails.mappedStatus == 'rejected' || appDetails.status == 'ADMIN_ACTION_PENDING'))
                      }
                      onClick={() => {
                        if (appDetails.isBolton) {
                          return handleContinueClicked();
                        }

                        return setNote(true);
                      }}
                    >
                      {appDetails.status !== 'INITIALIZED' ? (
                        <FormattedMessage {...messages.openEApplication} />
                      ) : (
                        <FormattedMessage {...messages.startEApplication} />
                      )}
                    </Button>
                    :
                    <Button
                      variant="contained"
                      color="primary"
                      margin="normal"
                      className={classes.openButton}
                      disabled={
                        offerCodeData.errorMessage ||
                        offerCodeData.validating ||
                        (isEmpty(appDetails) &&
                          getProductConfig(appDetails.isBolton) === undefined) ||
                        appDetails.isInvalidPWCform
                        || (!isEmpty(appDetails) &&
                          !appDetails.externalReferenceNumber &&
                          isHSBCBank(appDetails.agencyNumber)
                          && !appDetails.isInforce)
                        || (!isEmpty(appDetails) &&
                          !appDetails.externalReferenceNumber &&
                          isNexusAgency(appDetails.agencyNumber)
                          || (!isEmpty(appDetails) &&
                            (appDetails.mappedStatus == 'rejected' || appDetails.status == 'ADMIN_ACTION_PENDING')))
                      }
                      onClick={() => {
                        if (appDetails.isBolton) {
                          return handleContinueClicked();
                        }

                        return setNote(true);
                      }}
                    >
                      {appDetails.status !== 'INITIALIZED' ? (
                        <FormattedMessage {...messages.openEApplication} />
                      ) : (
                        <FormattedMessage {...messages.startEApplication} />
                      )}
                    </Button>
                  }
                </Grid>
                {offerCodeData.errorMessage && (
                  <Grid item xs={12}>
                    <Typography
                      display="block"
                      align="right"
                      variant="body2"
                      style={{ color: 'red', marginBottom: '4.4rem' }}
                    >
                      <FormattedMessage
                        {...globalMessages.specialOfferExpired}
                      />
                    </Typography>
                  </Grid>
                )}
                {!offerCodeData.errorMessage &&
                  (appDetails.status === 'INITIALIZED' &&
                    !appDetails.isBolton ? (
                    <Grid item xs={12} container justify="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        margin="normal"
                        className={classes.buttonWhite}
                      >
                        <Link
                          target="_blank"
                          href="https://advisersuite.zurich.com/en/home/product-literature"
                          underline="always"
                          color="inherit"
                          style={{
                            textDecoration: 'none',
                          }}
                        >
                          <FormattedMessage {...messages.paperApplication} />
                        </Link>
                      </Button>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      container
                      justify="flex-end"
                      style={{ height: '100%', width: '100%' }}
                    >

                      <Typography
                        variant="body1"
                        className={mapStatus(classes, appDetails.mappedStatus)}
                      >
                        {(appDetails.status === 'ADMIN_ACTION_PENDING')
                          ? ('Pending for Admin Approval')
                          : (
                            (appDetails.status === 'REJECTED_BY_ADMIN' && adminActionDetail)
                              ?
                              (appDetails.status) + " - " + (adminActionDetail.reason)
                              :
                              (appDetails.mappedStatus)
                          )}

                        <Dot
                          className={mapStatus(classes, appDetails.mappedStatus)}
                          fontSize="small"
                          style={{ marginLeft: theme.spacing(1) }}
                        />
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            )}
          </Grid>
          {!offerCodeData.errorMessage &&
            appDetails.status === 'INITIALIZED' &&
            !appDetails.isBolton && (
              <div className={classes.flexEnd}>
                <Typography
                  variant="body1"
                  className={mapStatus(classes, appDetails.mappedStatus)}
                >
                  {appDetails.mappedStatus}
                </Typography>
                <Dot
                  className={mapStatus(classes, appDetails.mappedStatus)}
                  fontSize="small"
                  style={{ marginLeft: theme.spacing(1) }}
                />
              </div>
            )}
        </Grid>
        <Grid item xs={12} md={10} style={{ paddingTop: 0 }}>
          {/* POLICY OWNER */}
          <PolicyOwner parties={appDetails.parties} classes={classes}
            appDetails={appDetails}
            productConfigs={getProductConfig(appDetails.isBolton)} />
        </Grid>

        {/* INSURANCE PARTY */}
        <InsuredParty parties={appDetails.parties} appDetails={appDetails} classes={classes} />

        {/* BENEFICIARIES PARTY */}
        <Beneficiaries
          parties={appDetails.parties}
          classes={classes}
          options={options}
        />

        {/* PAYOR PARTY */}
        <Payor parties={appDetails.parties} classes={classes} />

        {/* Premium SUMMARY */}
        <PremiumSummary
          applicationDetails={appDetails}
          productConfigs={getProductConfig(appDetails.isBolton)}
        />
        <Withdrawals productConfigs={getProductConfig(appDetails.isBolton)} />

        {/* POLICY SUMMARY */}
        {getProductConfig(appDetails.isBolton) &&
          !getProductConfig(appDetails.isBolton).specialTermsFlag &&
          getProductConfig(appDetails.isBolton).coverages &&
          !productCodeCheck(8, appDetails.productCode) ? (
          <PolicySummary
            productConfigs={getProductConfig(appDetails.isBolton)}
            parties={appDetails.parties}
          />
        ) : (
          ''
        )}

        {/* POLICY SUMMARY */}
        {getProductConfig1(appDetails.isBolton) &&
          !getProductConfig1(appDetails.isBolton).specialTermsFlag &&
          getProductConfig1(appDetails.isBolton).coverages &&
          !productCodeCheck(8, appDetails.productCode) ? (
          <PolicySummary
            productConfigs={getProductConfig1(appDetails.isBolton)}
            parties={appDetails.parties}
            isProtectionBenefits
          />
        ) : (
          ''
        )}
        {!productCodeCheck(8, appDetails.productCode) &&
          <Grid item xs={12} md={10} style={{ paddingTop: 0 }}>
            <BenefitRatingSummary
              isBolton={appDetails.isBolton}
              productConfigs={
                appDetails.isBolton
                  ? getProductConfig1(appDetails.isBolton)
                  : getProductConfig(appDetails.isBolton)
              }
            />
          </Grid>
        }
      </Grid>
      <Dialog
        classes={classes}
        open={open}
        setOpen={setOpen}
        initialValues={appDetails}
        onSubmit={immutableData => {
          const data = immutableData.toJS();

          updateApplicationDetails(data);
          setOpen(false);
        }}
      />

      <CustomDialog
        open={note}
        onClose={() => setNote(false)}
        title={<FormattedMessage {...messages.pleaseNote} />}
        actions={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.button}
              onClick={() => handleContinueClicked()}
            >
              <FormattedMessage {...messages.continue} />
            </Button>
          </div>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 800 }}>
          This application is in English by default. If you wish to process the
          application in Arabic, please complete a{' '}
          <Link
            target="_blank"
            href="https://advisersuite.zurich.com/en/home/product-literature"
            underline="always"
            style={{ color: '#4066B3', textDecorationStyle: 'dotted' }}
          >
            paper application process
          </Link>
        </Typography>
      </CustomDialog>

      <CustomDialog
        open={isOpenSuccess}
        onClose={() => {
          updateAppDetail(false);
        }}
        variant="success"
        title="Success"
        actions={
          <Grid
            container
            alignItems="center"
            wrap="nowrap"
            justify="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSuccessPopup(false);
                  updateAppDetail(true);
                }}
              >
                <FormattedMessage {...messages.logout} />
              </Button>
            </Grid>

            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => {
                  setSuccessPopup(false);
                  updateAppDetail(false);
                  history.push('/dashboard');
                }}
              >
                <FormattedMessage {...messages.goToDashboard} />
              </Button>
            </Grid>
          </Grid>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          <FormattedMessage {...messages.thankYou} />
        </Typography>
      </CustomDialog>

      <CustomDialog
        open={isOpenPWC}
        onClose={() => setOpenPWC(false)}
        title={<FormattedMessage {...messages.pleaseNote} />}
        actions={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              margin="normal"
              className={classes.button}
              onClick={() => {
                setOpenPWC(false);
              }}
            >
              <FormattedMessage {...messages.continue} />
            </Button>
          </div>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 800 }}>
          This application is outdated. Please create a new application.
        </Typography>
      </CustomDialog>
    </Wrapper>
  );
}

Application.propTypes = {
  subPageStepper: PropTypes.number,
  isLogout: PropTypes.bool,
  changeSubPage: PropTypes.func,
  changeSubPageList: PropTypes.func,
  steps: PropTypes.object,
  match: PropTypes.object,
  offerCodeData: PropTypes.object,
  applicationDetails: PropTypes.object,
  updateApplicationDetails: PropTypes.func,
  updateApplicationStatus: PropTypes.func,
  changeSubStepPage: PropTypes.func,
  getApplicationDetails: PropTypes.func,
  resetOfferCode: PropTypes.func,
  validateOfferCode: PropTypes.func,
  logout: PropTypes.func,
  setLogout: PropTypes.func,
  getAdminActionDetail: PropTypes.func,
  adminActionDetails: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  steps: makeSelectApplicationSubOrder(),
  ApplicationPage: makeSelectApplicationPage(),
  subPageStepper: makeSelectSubPageStepper(),
  applicationDetails: makeSelectApplicationDetails(),
  offerCodeData: makeSelectOfferCodeData(),
  isLogout: makeSelectLogout(),
  options: makeSelectOption(),
  adminActionDetails: makeSelectAdminActionDetails(),
});

function mapDispatchToProps(dispatch) {
  return {
    getApplicationDetails: value =>
      dispatch(getApplicationDetailsAction(value)),
    changeSubPage: value => dispatch(changeSubPageAction(value)),
    changeSubStepPage: value => dispatch(changeSubStepPageAction(value)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    updateApplicationDetails: (value, isLogout) =>
      dispatch(updateApplicationDetailsAction(value, isLogout)),
    updateApplicationStatus: data =>
      dispatch(updateApplicationStatusAction(data)),
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
    logout: value => dispatch(logoutAction(value)),
    setLogout: value => dispatch(setLogoutWhenUpdateAppAction(value)),
    getAdminActionDetail: value => dispatch(getAdminActionDetailAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Application);
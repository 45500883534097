/**
 *
 * HubPage
 *
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import {
  Grid,
  Typography,
  Box,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import makeSelectHubPage from './selectors';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import AgencySelection from 'containers/AgencySelection';
import { setQuoteTypeAction } from 'containers/App/actions';
import { QUOTE_TYPE } from 'containers/App/constants';
import { TableHub } from './components/TableHub';
import { TableSearch } from './components/TableSearch';
import {
  getListApplicationsPartyAction,
  getListIllustrationAction,
  getListInForcePolicyAction,
} from 'containers/HubPage/actions';
import {
  makeSelectAgentDetails,
  makeSelectQuoteType,
} from 'containers/App/selectors';
import {
  filterIllustrations,
  filterHubApplication,
  filterSearchApplication,
} from 'helpers/quote';
import {
  makeSelectListApplicationParty,
  makeSelectListIllustration,
  makeSelectListPolicy,
} from './selectors';
import { convertIfImmutable } from 'helpers/lang';
import TextField from 'components/TextField';
import Search from 'icons/Search';
import CustomFilter from './components/CustomFilter';

const useStyles = makeStyles({
  icon: {
    color: '#dddfe0',
  },
  textSearch: {
    fontSize: 18,
    marginBottom: 17,
  },
  backgroundSearch: {
    backgroundColor: '#F9F9F9',
    padding: '30px 0 0 0',
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 20,
  },
  widthSearch: {
    width: 400,
    backgroundColor: '#ffffff',
    padding: 0,
  },
  hubPageHeader: {
    backgroundColor: '#ffffff',
    position: 'fixed',
    zIndex: 10,
    width: `calc(100% - 238px)`,
    top: 0,
    paddingTop: 30,
    '@media (max-width: 919px)': {
      width: `calc(100% - 88px)`,
    },
  },
  formDesign: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export function HubPage(props) {
  useInjectReducer({ key: 'hubPage', reducer });
  useInjectSaga({ key: 'hubPage', saga });
  const theme = useTheme();
  const intl = useIntl();

  const {
    getApplicationListParty,
    agentDetails,
    listApplication,
    getIllustrations,
    listIllustration,
    listPolicy,
    quoteType,
    getListInForcePolicy,
    setQuoteType,
  } = props;
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [searchResult, setSearchResult] = useState('');
  const [isSubmit, setSubmit] = useState(false);
  const isNewBusiness = quoteType === QUOTE_TYPE.NEW_BUSINESS;
  const [isAvailableNewBusiness, setAvailableNewBusiness] = useState(
    isNewBusiness,
  );
  const [isAvailableExistingBusiness, setAvailableExistingBusiness] = useState(
    agentDetails.ISOCountryCode === 'AE' || agentDetails.ISOCountryCode === 'BH' || agentDetails.ISOCountryCode === 'QA',
  );

  const filteredIllustrations = filterIllustrations(
    convertIfImmutable(listIllustration),
    quoteType,
    agentDetails.products,
  );

  const hubApplication = filterHubApplication(
    convertIfImmutable(listApplication),
    quoteType,
    agentDetails.products,
  );

  const searchedApplication = filterSearchApplication(
    convertIfImmutable(listApplication),
    quoteType,
    agentDetails.products,
  );

  useEffect(() => { }, []);

  // call the process when agentNumber is changed
  useEffect(() => {
    const isAvailableNewBusiness = agentDetails.agentStatus !== 'B';
    setAvailableNewBusiness(isAvailableNewBusiness);
    getApplicationListParty({ valueSearch: search });
    setAvailableExistingBusiness(agentDetails.ISOCountryCode === 'AE' || agentDetails.ISOCountryCode === 'BH' || agentDetails.ISOCountryCode === 'QA');
  }, [agentDetails.agentNumber]);

  const handleSearch = value => {
    setSearch(value);
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!isNewBusiness && isAvailableExistingBusiness) {
      setSubmit(true);
      setSearchResult(search);
      getListInForcePolicy({ valueSearch: search });
      getApplicationListParty({ valueSearch: search });
    }
  };

  const handleClearSearch = () => {
    setSearch('');
    setSubmit(false);
    getApplicationListParty({ valueSearch: '' });
  };

  useEffect(() => {
    if (isNewBusiness && isAvailableNewBusiness) {
      const agencyNumber = agentDetails.agentNumber;
      const timer = setTimeout(() => {
        setSubmit(!!search);
        getApplicationListParty({ valueSearch: search });
        getIllustrations({ agencyNumber, valueSearch: search });
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [search]);

  return (
    <div
      style={{
        padding: theme.spacing(3),
      }}
    >
      <div className={classes.hubPageHeader}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={5} md={6} lg={8}>
            {/* <Typography variant="h1">
              {<FormattedMessage {...messages.hubPage} />}
            </Typography> */}
          </Grid>
          <Grid item xs={7} md={6} lg={4}>
            <AgencySelection fullWidth />
          </Grid>
        </Grid>
        <Typography component="div" className={classes.backgroundSearch}>
          <Typography className={classes.textSearch}>
            {isNewBusiness ? (
              <FormattedMessage {...messages.somethingSpecific} />
            ) : (
              <FormattedMessage {...messages.searchPolicy} />
            )}
          </Typography>
          <form className={classes.formDesign} onSubmit={onSubmit}>
            <TextField
              className={classes.widthSearch}
              value={search}
              inputProps={{
                placeholder: isNewBusiness
                  ? intl.formatMessage(messages.searchDatabase)
                  : intl.formatMessage(messages.enterPolicyNumber),
              }}
              onChange={event => {
                handleSearch(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" onClick={onSubmit}>
                    <IconButton>
                      <Search className={classes.icon} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {search !== '' && (
              <Typography
                onClick={handleClearSearch}
                variant="h4"
                style={{ marginLeft: 10, color: '#043594', fontWeight: 'bold' }}
              >
                {<FormattedMessage {...messages.clear} />}
              </Typography>
            )}
          </form>
          <Box bgcolor="#F9F9F9" minHeight={80}>
            {!isSubmit && (
              <Box
                minHeight={80}
                width="100%"
                bgcolor="#f2f2f2"
                my={4}
                style={{
                  borderTop: '1px solid #DDDFE0',
                  borderBottom: '1px solid #DDDFE0',
                }}
              >
                <CustomFilter
                  value={isNewBusiness}
                  isAvailableNewBusiness={isAvailableNewBusiness}
                  isAvailableExistingBusiness={isAvailableExistingBusiness}
                  onChange={e => {
                    setQuoteType(
                      e
                        ? QUOTE_TYPE.NEW_BUSINESS
                        : QUOTE_TYPE.EXISTING_BUSINESS,
                    );
                  }}
                />
              </Box>
            )}
          </Box>
        </Typography>
      </div>

      {isSubmit ? (
        <TableSearch
          listApplication={searchedApplication}
          listIllustration={filteredIllustrations}
          listPolicy={convertIfImmutable(listPolicy)}
          textSearch={searchResult}
          isSearchNewBusiness={isNewBusiness}
        />
      ) : (
        <TableHub listApplication={hubApplication} />
      )}
    </div>
  );
}

HubPage.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  hubPage: makeSelectHubPage(),
  agentDetails: makeSelectAgentDetails(),
  listApplication: makeSelectListApplicationParty(),
  listIllustration: makeSelectListIllustration(),
  listPolicy: makeSelectListPolicy(),
  quoteType: makeSelectQuoteType(),
});

function mapDispatchToProps(dispatch) {
  return {
    getApplicationListParty: value =>
      dispatch(getListApplicationsPartyAction(value)),
    getIllustrations: value => dispatch(getListIllustrationAction(value)),
    getListInForcePolicy: payload =>
      dispatch(getListInForcePolicyAction(payload)),
    setQuoteType: payload => dispatch(setQuoteTypeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HubPage);

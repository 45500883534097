import { isEmpty, isString } from 'lodash/lang';
import { has } from 'lodash/object';
import { map, filter, includes } from 'lodash/collection';
import { isImmutable } from 'immutable';
import { isBoolean } from 'lodash';

export function isEmptyString(value) {
  if (isEmpty(value) && isString(value)) {
    return true;
  }
  return false;
}

export function isAlphaNumeric(ch) {
  return ch.match(/^[a-z0-9 ]+$/i) !== null;
}

export function parentQuestions(questions) {
  return filter(questions, question => {
    return !has(question, 'parentQuestionId');
  });
}

export function getImmutableState(state, path, expectArray = false) {
  const stateImm = state.getIn(path);
  if (stateImm) {
    return stateImm.toJS();
  }
  if (expectArray) {
    return [];
  }
  return {};
}

export const getGender = bool => {
  if (bool === 1) {
    return 'Male';
  } else if (bool === 0) {
    return 'Female';
  } else {
    return '-';
  }
};

export function convertIfImmutable(val) {
  if (isImmutable(val)) {
    return val.toJS();
  } else {
    return val;
  }
}

export function partyFilter(party, filterBy1, filterBy2, filterBy3, filterBy4) {
  if (filterBy4) {
    return filter(party, item => {
      return (
        item.partyapplicationrelationships[filterBy1.name] ==
          filterBy1.condition &&
        item.partyapplicationrelationships[filterBy2.name] ==
          filterBy2.condition &&
        item.partyapplicationrelationships[filterBy3.name] ==
          filterBy3.condition &&
        item.partyapplicationrelationships[filterBy4.name] ==
          filterBy4.condition
      );
    });
  } else if (filterBy3) {
    return filter(party, item => {
      return (
        item.partyapplicationrelationships[filterBy1.name] ==
          filterBy1.condition &&
        item.partyapplicationrelationships[filterBy2.name] ==
          filterBy2.condition &&
        item.partyapplicationrelationships[filterBy3.name] ==
          filterBy3.condition
      );
    });
  } else if (filterBy2) {
    return filter(party, item => {
      return (
        item.partyapplicationrelationships[filterBy1.name] ==
          filterBy1.condition &&
        item.partyapplicationrelationships[filterBy2.name] ==
          filterBy2.condition
      );
    });
  } else {
    return filter(party, item => {
      return (
        item.partyapplicationrelationships[filterBy1.name] ==
        filterBy1.condition
      );
    });
  }
}

export function filteredParty(
  parties,
  filterBy,
  isApplicationDetails,
  filterBy2,
  filterBy2Condition,
) {
  if (isApplicationDetails) {
    if (filterBy2) {
      var updatedApplicationDetails = parties;

      updatedApplicationDetails.parties = filter(
        updatedApplicationDetails.parties,
        item => {
          return (
            item.partyapplicationrelationships[filterBy] &&
            item.partyapplicationrelationships[filterBy2] == filterBy2Condition
          );
        },
      );
      return updatedApplicationDetails;
    } else {
      var updatedApplicationDetails = parties;

      updatedApplicationDetails.parties = filter(
        updatedApplicationDetails.parties,
        item => {
          return item.partyapplicationrelationships[filterBy];
        },
      );
      return updatedApplicationDetails;
    }
  } else {
    return filter(parties, item => {
      return item.partyapplicationrelationships[filterBy];
    });
  }
}

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const isUS = country => {
  const USCountries = [
    'AS',
    'US',
    'UM',
    'GU',
    'PR',
    'MP',
    'FM',
    'MH',
    'PW',
    'VI',
  ];
  return includes(USCountries, country);
};

export const regexSpecialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const regexLongDashCharacters = /[`–]/;
export const regexSpecialCharactersAndNumbers = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]/;
export const regexNumbers = /^[^0-9]+$/;
export const regexPhoneNumber = /^[0-9]{1,25}$/;

export const startCase = text => {
  return text.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
};

// export const isInvestmentLinkProducts = productCode => {
//   const investmentLinkProducts = ['FUTU5', 'MFS01', 'WAPL2', 'SIMW2', 'RSP01'];

//   return includes(investmentLinkProducts, productCode);
// };

// Use to check agencyNumber are in HSBC Bank or not
export const isHSBCBank = id => {
  const hsbcBankList = [
    '401200',
    '8902801',
    '8904111',
    '8904197',
    '8904251',
    '8904421',
    '8904464',
    '8904511',
    '8905410',
    '8905843',
	  '8905444', 
	  '8980063',
    '8980039',
    '8904430',
    '8905258'
  ];

  return includes(hsbcBankList, id);
};

// Added for Nexus admin

export const isNexusAgency = id => {
  const nexusAgencyList = [
          "8250006",
          "8980012",
          "6210015",
          "8210012",
          "6200028",
          "8200025",
          "6210007",
          "8210004",
          "6200001",
          "8200009",
          "6220002",
          "8220000",
          "6200010",
          "8200017",
          "8240001",
          "6200036",
          "8230005",
          "6230008",
          "6200052"
      ];

      return includes(nexusAgencyList, id);
    };

  export const isFABAgency = id => {
    const fabAgencyList = [
              "8904162",
              "8904791", 
              "8905878"
          ];
          return includes(fabAgencyList, id);
    };

  export const isADCBAgency = id => {
      const ADCBAgencyList = [
                "8920001",
                "8903166" 
            ];
            return includes(ADCBAgencyList, id);
      };

// Use to check value of select field if it equal to placeholder value then we change color for this option
export const changePlaceHolderColor = option => {
  if (option === 'Select an option' || option === 'Select') {
    return '#B6B8B4';
  }

  return '';
};

// For changing to Titlecase 
export const changeToTitleCase = (strValue) => {
  if(strValue != null) {
    return strValue.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  } else 
     return '';
};

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/frutiger/FrutigerLTStd-Roman.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/frutiger/FrutigerLTStd-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./fonts/frutiger/FrutigerLTStd-Light.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./fonts/frutiger/FrutigerLTStd-BoldItalic.otf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./fonts/frutiger/FrutigerLTStd-LightItalic.otf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, "@font-face {\r\n  font-family: 'Frutiger';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\r\n  font-weight: 400;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Frutiger';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\r\n  font-weight: 700;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Frutiger';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('opentype');\r\n  font-weight: 300;\r\n  font-style: normal;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Frutiger';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('opentype');\r\n  font-weight: 700;\r\n  font-style: italic;\r\n}\r\n\r\n@font-face {\r\n  font-family: 'Frutiger';\r\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format('opentype');\r\n  font-weight: 300;\r\n  font-style: italic;\r\n}\r\n", ""]);
// Exports
module.exports = exports;

/**
 *
 * FormNumberField
 *
 */
import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import NumberField from 'components/NumberField';
import { fixLeadingZero } from 'helpers/math';
import { Grid, Typography, Divider } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';
import blue from '@material-ui/core/colors/blue';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
const useStyle = makeStyles(theme => ({
  title: {
    fontWeight: 800,
    // color: theme.palette.primary.dark,
  },
  title2: {
    fontWeight: 800,
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
}));

const FormNumberField = ({
  question,
  label,
  showLabel,
  input: { value, onChange, onBlur, ...otherInputProps },
  meta: { invalid, error, touched, warning },
  questions,
  inputFormat,
  ...custom
}) => {
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }
  const [touchedField, setTouchedField] = useState(false);
  const classes = useStyle();
  const theme = useTheme();
  const maxValue = question.constraints.maxVal;

  const minValue = question.constraints.minVal;
  const checkIfError = () => {
    if (!touchedField || (maxValue + 1 > value && minValue - 1 < value)) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Grid container spacing={3} alignItems="center">
      {isFirstChild(questions, question) && (
        <>
          {' '}
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title2}>
              {question.context[0]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ margin: '1rem 0' }} />
          </Grid>
        </>
      )}
      <Grid item xs={7} md={7}>
        <NumberField
          variant="outlined"
          label={
            <Typography variant="body2" className={classes.title}>
              {label}
            </Typography>
          }
          value={value}
          onChange={newVal => onChange(newVal)}
          onBlur={() => {
            const fixedLeadingZero = fixLeadingZero(value);
            setTouchedField(true);
            return onBlur(fixedLeadingZero);
          }}
          helperText={
            checkIfError()
              ? `min value is ${minValue} and max value is ${maxValue}`
              : helperMsg
          }
          error={checkIfError() || helperMsg}
          format={inputFormat}
          {...custom}
          {...otherInputProps}
        />
      </Grid>
      {question.helpText && !question.parentQuestionId ? (
        <Grid item xs={5} md={5}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InfoIcon style={{ marginRight: '1rem', color: blue[900] }} />
            <Typography variant="caption">{question.helpText}</Typography>
          </div>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

FormNumberField.propTypes = {
  ...WrappedFieldProps,
};

export default FormNumberField;

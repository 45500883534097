/*
 *
 * IllustrationPage actions
 *
 */

import {
  GET_PRODUCT_CONFIG,
  SET_PRODUCT_CONFIG,
  RESET_PRODUCT_CONFIG,
  SET_PRODUCT_CONFIG2,
  FETCH_ILLUSTRATIONS_ACTION,
  SET_ILLUSTRATIONS_ACTION,
  SET_QUOTES_ACTION,
  GET_QUOTES_ACTION,
  GET_SEARCH_ACTION,
  SET_PRODUCT_ID,
  SET_ERROR_ACTION,
  CLONE_PRODUCT,
  SEND_EMAIL_ACTION,
  GET_CUSTOMERS_ACTION,
  SET_CUSTOMERS_ACTION,
  SET_CUSTOMER_DETAIL_ACTION,
  DELETE_ILLUSTRATION_ACTION,
  SET_DELETE_ILLUSTRATION_ACTION,
} from './constants';

export function getProductConfigAction(payload) {
  return {
    type: GET_PRODUCT_CONFIG,
    payload,
  };
}

export function setProductConfigAction(payload) {
  return {
    type: SET_PRODUCT_CONFIG,
    payload,
  };
}

export function setProductConfig2Action(payload) {
  return {
    type: SET_PRODUCT_CONFIG2,
    payload,
  };
}

export function setErrorAction(payload) {
  return {
    type: SET_ERROR_ACTION,
    payload,
  };
}

export function setProductIdAction(payload) {
  return {
    type: SET_PRODUCT_ID,
    payload,
  };
}

export function resetProductConfigAction() {
  return {
    type: RESET_PRODUCT_CONFIG,
  };
}

export function cloneProductAction(payload) {
  return {
    type: CLONE_PRODUCT,
    payload,
  };
}

export function sendEmailAction(payload) {
  return {
    type: SEND_EMAIL_ACTION,
    payload,
  };
}

export function fetchIllustrationsAction(agentDetails = '') {
  return {
    type: FETCH_ILLUSTRATIONS_ACTION,
    payload: agentDetails.agentNumber,
  };
}

export function setIllustrationsAction(illustrations = []) {
  return {
    type: SET_ILLUSTRATIONS_ACTION,
    payload: illustrations,
  };
}

export function getCustomersAction(searchText = '') {
  return {
    type: GET_CUSTOMERS_ACTION,
    payload: searchText,
  };
}

export function setCustomersAction(customers = []) {
  return {
    type: SET_CUSTOMERS_ACTION,
    payload: customers,
  };
}

export function getQuotesAction(data) {
  return {
    type: GET_QUOTES_ACTION,
    payload: data,
  };
}

export function setQuotesAction(quote = {}) {
  return {
    type: SET_QUOTES_ACTION,
    payload: quote,
  };
}

export function getSearchIllustrationsAction(data) {
  return {
    type: GET_SEARCH_ACTION,
    payload: data,
  };
}

export function setCustomerDetailAction(data) {
  return {
    type: SET_CUSTOMER_DETAIL_ACTION,
    payload: data,
  };
}

export function deleteIllustrationAction(payload) {
  return {
    type: DELETE_ILLUSTRATION_ACTION,
    payload: payload,
  };
}

export function setDeleteIllustrationAction(payload) {
  return {
    type: SET_DELETE_ILLUSTRATION_ACTION,
    payload: payload,
  };
}

/*
 * DashboardPage Messages
 *
 * This contains all the text for the DashboardPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DashboardPage';

export default defineMessages({
  hub: {
    id: `${scope}.hub`,
    defaultMessage: 'Hub',
  },
  customers: {
    id: `${scope}.customers`,
    defaultMessage: 'Customers',
  },
  illustrations: {
    id: `${scope}.illustrations`,
    defaultMessage: 'Illustrations',
  },
  applications: {
    id: `${scope}.applications`,
    defaultMessage: 'Applications',
  },
  recentIllustration: {
    id: `${scope}.recentIllustration`,
    defaultMessage: 'Recent Illustrations',
  },
  lifeInsured: {
    id: `${scope}.lifeInsured`,
    defaultMessage: 'Life insured',
  },
  lastSaved: {
    id: `${scope}.lastSaved`,
    defaultMessage: 'Last saved',
  },
  syncStatus: {
    id: `${scope}.syncStatus`,
    defaultMessage: 'Sync status',
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions',
  },
  viewAll: {
    id: `${scope}.viewAll`,
    defaultMessage: 'View all illustrations',
  },
  canCel: {
    id: `${scope}.canCel`,
    defaultMessage: 'Cancel',
  },
  termsOfUse: {
    id: `${scope}.termsOfUse`,
    defaultMessage: 'Terms of use',
  },
  logOut: {
    id: `${scope}.logOut`,
    defaultMessage: 'Logout',
  },
  wantToLogOut: {
    id: `${scope}.wantToLogOut`,
    defaultMessage: 'Are you sure you want to logout?',
  },
});

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import messages from 'containers/ApplicationFormPage/messages';
import { map, forEach, find } from 'lodash/collection';
import { get } from 'lodash/object';
import { uniqBy } from 'lodash/array';
import { isEmpty } from 'lodash/lang';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';
//helpers
import { convertIfImmutable } from 'helpers/lang';
import { fixLeadingZero } from 'helpers/math';

//components
import NumberField from 'components/NumberField';

//MATERIAL UI
import { Grid, Typography, TextField, Divider } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
  title2: {
    fontWeight: 800,
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
}));

function Percent({
  question,
  questions,
  section,
  input: { onChange, value, onBlur, ...inputProps },
  required,
  meta: { invalid, error, touched, warning },
  ...custom
}) {
  const classes = useStyle();
  const theme = useTheme();
  const fieldValue = convertIfImmutable(value);
  const choices = get(question, 'constraints.choices', []);
  let totalPercentage = 0;

  forEach(fieldValue, value => {
    totalPercentage += value.amount ? value.amount : 0;
  });

  return (
    <Grid container spacing={3} justify="center" alignItems="center">
      {isFirstChild(questions, question) && (
        <>
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title2}>
              {question.context[0]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ margin: '1rem 0' }} />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontWeight: 800 }}>
          {question.text}
        </Typography>
      </Grid>
      {map(choices, (choice, index) => {
        const defaultValue = find(fieldValue, function(o) {
          return o.value === choice.value;
        });
        return (
          <>
            <Grid item xs={4}>
              <NumberField
                value={!isEmpty(defaultValue) && defaultValue.amount}
                variant="outlined"
                isAllowed={v => {
                  if (v.floatValue > 100 || 0 > v.floatValue) {
                    return false;
                  } else return true;
                }}
                // value={fieldValue.date}
                onChange={newVal => {
                  return false;
                }}
                onBlur={e => {
                  const fixedLeadingZero = fixLeadingZero(e.target.value);

                  //remove the duplicated key
                  const updatedValue = uniqBy(
                    [
                      {
                        value: choice.value,
                        amount: parseInt(fixedLeadingZero),
                      },
                      ...fieldValue,
                    ],
                    'value',
                  );

                  return onChange(updatedValue);
                }}
                min={1}
                max={100}
                // error={checkIfError()}
                fullWidth
                helperText={`min value is ${0} and max value is ${100}`}
                placeholder="% enter amount"
              />
            </Grid>
            <Grid item xs={8} justify="center">
              <Typography variant="body1" style={{ fontWeight: 800 }}>
                {choice.text}
              </Typography>
            </Grid>
          </>
        );
      })}
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{
            fontWeight: 800,
            color: 'black',
          }}
        >
          {totalPercentage} %
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          style={{
            fontWeight: 800,
            color: 'red',
          }}
        >
          {error && error}
        </Typography>
      </Grid>
    </Grid>
  );
}

Percent.propTypes = {
  question: PropTypes.object,
};

export default Percent;

import { put, takeLatest, all, call } from 'redux-saga/effects';
import { LOGIN_ACTION } from './constants';
import request from 'utils/request';
import { setLoginStatusAction } from './actions';
import { api } from 'environments';
import { setAuthAction } from 'containers/App/actions';

export function* login(action) {
  const token =
    'eyJraWQiOiJMbTlnTHA2eVZIYm0wZFliRkhkX1VjdW5HY2dOSFU0ejlwemV0VURybVBrIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULm5SNWx4cFpJMTg5ekk5WmR5R2pTSERUOXFEb1dJNGNNVFlPRUJRamgxWnciLCJpc3MiOiJodHRwczovL3p1cmljaHRlc3Qub2t0YXByZXZpZXcuY29tL29hdXRoMi9kZWZhdWx0IiwiYXVkIjoiYXBpOi8vZGVmYXVsdCIsImlhdCI6MTU5Mjg4MTc1NiwiZXhwIjoxNTkyODg1MzU2LCJjaWQiOiIwb2FwcGh0eWhxbWhuMnhYSzBoNyIsInVpZCI6IjAwdXBsb3ViNmR0Y2RFUWc3MGg3Iiwic2NwIjpbIm9wZW5pZCIsImVtYWlsIiwicHJvZmlsZSJdLCJzdWIiOiJ0ZXN0MS4zNjBmQHp1cmljaC5jb20ifQ.SjMTq5Aqv5Ph4wVx9OCHUnrxkaYvO_PkP0RIr45Tr7lr8UtgqIF3SeeKPV62Ldg4bKNfVNYffM9mgO2RufEDqSyBTjvD3M4UMLoPIhWh1Ht_idJV9gBF7yPvi5ncMxSjEljhFDSAmwDH09K0syuRXNBxamSfWNmkEa8pcvePSX-6S9PA4-7m8QDRntp9UUU0CuRDwdzBo_8K0CWd0Pm4OgqQNc7McZTvjGfNybmTipvQ3IFD92ZPyLB7jW0OUbFa5raKm-n91Hw2Rw8vEhehkbYqQ_u6DHJakOogdLX0CuhzyCeZhPD7oupPMlEGNDv7CEuhFTm82S2G-oaficBLgQ';
  const metadata = { device: navigator.userAgent };
  const endpoint = `${api.host}/auth/login-sso`;
  const myBody = { token, metadata };

  const requestOpt = {
    method: 'POST',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(myBody),
  };

  try {
    const response = yield call(request, endpoint, requestOpt);
    if (response.data) {
      const status = {
        success: true,
        message: 'Login Success',
      };
      //yield put(setAuthAction(response.data));
      //yield put(setLoginStatusAction(status));
    }
  } catch (err) {
    const status = {
      success: false,
      message: 'Login Failed',
    };
    yield put(setLoginStatusAction(status));
  }
}

// Individual exports for testing
export default function* loginPageSaga() {
  yield all([
    takeLatest(LOGIN_ACTION, login)
  ]);
}

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isEmpty, isString } from 'lodash/lang';
import globalMessages from 'containers/App/messages';
import { isFieldEmpty } from 'utils/form';
import {
  calculateAgeNextBirthdate,
  getStartDate,
} from 'containers/FuturaIllustrationPage/helper';
import { isAlphaNumeric } from 'helpers/lang';
import moment from 'moment';
import { dobFormat } from 'helpers/constants';

const validate = values => {
  const errors = {};
  const startDate = getStartDate().format(dobFormat);

  if (!values.isEmpty()) {
    // INSURED ONE
    const insuredOneValues = values.get('insuredOne');
    const isInsuredTwoValues = values.get('isInsuredTwo');
    const insuredTwoValues = values.get('insuredTwo');
    const isExistingInsuredOne = insuredOneValues.get('isExisting');
    const isExistingInsuredTwo = insuredTwoValues.get('isExisting');

    const requiredInsuredOneFields = [
      'firstName',
      'lastName',
      'dateOfBirth',
      'gender',
    ];
    const insuredOneError = {};
    if (
      insuredOneValues &&
      !insuredOneValues.isEmpty() &&
      !isExistingInsuredOne
    ) {
      requiredInsuredOneFields.forEach(field => {
        if (isFieldEmpty(insuredOneValues.get(field))) {
          if (field === 'gender' || field === 'isSmoke')
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          else
            insuredOneError[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
        }
      });
    }
    if (!isEmpty(insuredOneError)) errors.insuredOne = insuredOneError;

    if (isInsuredTwoValues) {
      // INSURED TWO
      const requiredInsuredTwoFields = [
        'firstName',
        'lastName',
        'dateOfBirth',
        'gender',
        'countryOfResidence',
        'isSmoke',
      ];
      const insuredTwoError = {};
      if (
        insuredTwoValues &&
        !insuredTwoValues.isEmpty() &&
        !isExistingInsuredTwo
      ) {
        requiredInsuredTwoFields.forEach(field => {
          if (isFieldEmpty(insuredTwoValues.get(field))) {
            if (field === 'gender')
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
            else
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
          }

          if (field === 'dateOfBirth') {
            if (isFieldEmpty(insuredTwoValues.get(field))) {
              insuredTwoError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            } else {
              if (!moment(insuredTwoValues.get(field)).isValid()) {
                insuredTwoError[field] = (
                  <FormattedMessage {...globalMessages.invalidDate} />
                );
              }

              const anb = calculateAgeNextBirthdate(
                moment(),
                insuredTwoValues.get(field),
              );

              if (anb < 18)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.minAgeValidation}
                    values={{ value1: '18' }}
                  />
                );

              if (anb > 75)
                insuredTwoError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxAgeValidation}
                    values={{ value1: '74' }}
                  />
                );
            }
          }
        });
      }
      if (!isEmpty(insuredTwoError)) errors.insuredTwo = insuredTwoError;

      const lifeBasisValues = values.get('lifeBasis');
      if (isFieldEmpty(lifeBasisValues) || lifeBasisValues === 'SL')
        errors.lifeBasis = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
    }
  }

  return errors;
};

export default validate;

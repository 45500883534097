/**
 * CoverageForm
 */
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import styled from 'styled-components';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { createStructuredSelector } from 'reselect';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import {
  WrappedFieldArrayProps,
  InjectedFormProps,
  FormSection,
} from 'redux-form';
import {
  Grid,
  Divider,
  Typography,
  FormControlLabel,
  Collapse,
  TextField,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import FormOptionButtons from 'components/FormOptionButtons';
import { dimension } from 'styles/constants';
import FormCheck from 'components/FormCheck';
import FormNumberField from 'components/FormNumberField';
import FormDatePicker from 'components/FormDatePicker';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import TargetFund from 'containers/App/components/TargetFund';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import moment from 'moment';
import CustomFormCheck from 'components/CustomFormCheck';
// import FormAutocomplete from 'components/FormAutocomplete';

import CardSectionForm from 'components/CardSectionForm';
import OptSwitch from 'components/OptSwitch';
import { isEmpty } from 'lodash';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import FormSwitch from 'components/FormSwitch';
import Helper from 'components/Helper';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import Withdrawal from '../Withdrawal';
import { getStartDate, calculateAgeNextBirthdate } from '../../helper';
import validate from './validate';
import messages from '../../messages';
import asyncValidator from './asyncValidate';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  hrSlider: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    margin: `${theme.spacing(3)}px ${theme.spacing(-2)}px ${theme.spacing(
      5,
    )}px ${theme.spacing(-2)}px`,
  },
  inputWidth: {
    maxWidth: '135px',
    width: '135px',
  },
  labelRadio: {
    '&> span': {
      fontWeight: 'bold',
    },
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => (
  <OptSwitch
    checked={value}
    value={value}
    color="default"
    onChange={onChange}
    labelNegative={<FormattedMessage {...messages.no} />}
    disabled={disabled}
  />
);

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const renderBenefit = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const possibleValues = myBenefit[index]?.possibleValues;
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne.dateOfBirth,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo.dateOfBirth,
      );

      let ageValidType = 0;
      let isFormCheckDisabled = myBenefit[index].disabled;

      let isWopOneDisabled = myBenefit[index].disabled;

      // default behaviour
      const disableSameWOP = () => {
        // LIFE 1
        if (
          anbInsuredOne <= myBenefit[index].maxEntryAgeLA &&
          !myBenefit[index]?.valueInsuredTwo
        ) {
          isWopOneDisabled = false;
        } else {
          isWopOneDisabled = true;
          change(`${benefit}.value`, false);
        }

        // LIFE 2
        if (
          anbInsuredTwo <= myBenefit[index].maxEntryAgeLA &&
          !myBenefit[index]?.value
        ) {
          isWopTwoDisabled = false;
        } else {
          isWopTwoDisabled = true;
          change(`${benefit}.valueInsuredTwo`, false);
        }
      };

      const enableSameWOP = () => {
        // LIFE 1
        if (anbInsuredOne < myBenefit[index].maxEntryAgeLA) {
          isWopOneDisabled = false;
        } else {
          isWopOneDisabled = true;
          change(`${benefit}.value`, false);
        }

        // LIFE 2
        if (anbInsuredTwo < myBenefit[index].maxEntryAgeLA) {
          isWopTwoDisabled = false;
        } else {
          isWopTwoDisabled = true;
          change(`${benefit}.valueInsuredTwo`, false);
        }
      };

      let isWopTwoDisabled = myBenefit[index].disabled;
      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA !== '0') {
          if (myBenefit[index].id === 'WOP') {
            // WOP
            //  const possibleValues1 = ['First Life', 'Second Life', 'Both Lives'];
            // const possibleValues2 = ['First Life'];

            // check posssibleValue if all contains the following values

            if (possibleValues.includes('Both Lives')) {
              enableSameWOP();
            } else {
              disableSameWOP();
            }

            // if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
            //   isWopOneDisabled = true;
            //   change(`${benefit}.value`, false);
            // }

            // if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
            //   isWopTwoDisabled = true;
            //   change(`${benefit}.value`, false);
            //   change(`${benefit}.valueInsuredTwo`, false);
            // }

            // disable either life if either life is set to yes
            // if (myBenefit[index].value) {
            //   isWopTwoDisabled = true;
            //   change(`${benefit}.valueInsuredTwo`, false);
            // } else if (myBenefit[index].valueInsuredTwo) {
            //   isWopOneDisabled = true;
            //   change(`${benefit}.value`, false);
            // }
          } else if (myBenefit[index].id === 'ALC') {
            if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
              isWopOneDisabled = true;
              change(`${benefit}.value`, false);
            }

            if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
              isWopTwoDisabled = true;
              change(`${benefit}.valueInsuredTwo`, false);
            }
          } else {
            if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
              isWopOneDisabled = true;
              change(`${benefit}.value`, false);
            }

            if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
              isWopTwoDisabled = true;
              change(`${benefit}.value`, false);
              change(`${benefit}.valueInsuredTwo`, false);
            }
          }
        }
      } else if (myBenefit[index].maxEntryAgeLA !== '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          isWopOneDisabled = true;
          change(`${benefit}.value`, false);
          change(`${benefit}.valueInsuredTwo`, false);
          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }
      return (
        <>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            item
            xs={11}
          >
            <Grid item xs={12} sm={6}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.selected`}
                  fullWidth
                  component={CustomFormCheck}
                  label={myBenefit[index].title}
                  disabled={isFormCheckDisabled}
                  validType={ageValidType}
                  type="text"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`${benefit}.value`, false);
                    }
                  }}
                />
              ) : (
                <Grid container direction="row">
                  <Typography variant="h4" style={{ fontWeight: '600' }}>
                    {myBenefit[index].title}
                  </Typography>
                  <div>
                    <Helper
                      helperText={
                        myBenefit[index].id === 'ALC' ? (
                          <FormattedMessage {...messages.descriptionAlc} />
                        ) : (
                          <FormattedMessage {...messages.descriptionTwo} />
                        )
                      }
                    ></Helper>
                  </div>
                </Grid>
              )}
            </Grid>
            {/* BENEFITS */}
            <Grid item xs={6} sm={3}>
              <Field
                // shrink
                name={`${benefit}.value`}
                component={FormBooleanOption}
                type="text"
                margin="normal"
                disabled={isWopOneDisabled}
                fullWidth
              />
            </Grid>

            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                {myBenefit[index].type === '2' && (
                  <Field
                    name={`${benefit}.valueInsuredTwo`}
                    component={FormBooleanOption}
                    type="text"
                    margin="normal"
                    disabled={isWopTwoDisabled}
                    fullWidth
                  />
                )}
              </Grid>
            ) : null}
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  agentDetails,
  gcConfig,
  change,
  offerCodeData,
  term,
  startDate,
  fiaData,
  fiaCharge,
  isAdditionalFeature,
  regularWithdrawal,
  educationFeesWithdrawal,
  isEducationalFeeWithdrawal,
  isRegularWithdrawal,
  currency,
  paymentFrequency,
  location,
  offerCode,
  resetOfferCode,
  validateOfferCode,
  premium,
  formValues,
  formErrors,
  submitFailed,
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();

  const currencyOption = [];
  const paymentFrequencyOption = [];
  const policyTermList = [];
  const startDateList = [];

  if (startDateList.length == 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  const minStartDate = moment(startDate)
    .date(15)
    .add(5, 'years')
    .toDate();

  const maxStartDate = moment(startDate)
    .add(100, 'years')
    .toDate();

  let minPremiumPaymentTerm = 5;
  const maxPremiumPaymentTerm = 30;

  while (minPremiumPaymentTerm <= maxPremiumPaymentTerm) {
    const myObj = {
      title: `${minPremiumPaymentTerm}`,
      value: minPremiumPaymentTerm,
    };
    policyTermList.push(myObj);
    minPremiumPaymentTerm++;
  }
  // policyTermList.push({ title: 'Single premium', value: 'SinglePremium' });

  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '') {
      validateOfferCode({
        offerCode,
        productCode: gcConfig.productCode,
        productVersion: gcConfig.productVersion,
        currency,
        premium,
        premiumType: 'term',
      });
    }
  }, []);

  gcConfig.features.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  gcConfig.features.premiumPaymentFrequencyType[0].periods.map(frequency => {
    paymentFrequencyOption.push({ value: frequency, title: frequency });
  });

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';
  const agencyNumber = agentDetails.agentNumber;
  gcConfig.agencyNumber = (agencyNumber);

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* CURRRENCY */}
            <Grid item>
              <Field
                // shrink
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* POLICY TERM */}
            <Grid item>
              <Field
                // shrink
                name="term"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(term),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.premiumPaymentTerm} />}
                type="text"
                // options={policyTermList}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...policyTermList,
                ]}
                onChange={(e, newVal) => {
                  if (newVal === 'SinglePremium')
                    change('paymentFrequency', '');
                }}
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* PAYMENT FREQUENCY */}
            <Grid item>
              <Field
                // shrink
                name="paymentFrequency"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(paymentFrequency),
                }}
                component={FormSelect}
                // options={paymentFrequencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...paymentFrequencyOption,
                ]}
                label={<FormattedMessage {...messages.paymentFrequency} />}
                type="text"
                disabled={term === 'SinglePremium'}
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>

            {/* PREMIUM AMOUNT */}
            <Grid item sm={12}>
              <Field
                // shrink
                name="premium"
                disabled={formValues.targetFunctionality}
                style={{ width: 164 }}
                component={FormNumberField}
                label={<FormattedMessage {...messages.premiumAmount} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.enterAmount)}
              />
            </Grid>
          </Grid>

          {agentDetails.ISOCountryCode === 'AE' && (
            <>
              <Divider
                className={classes.hr}
                style={{ marginBottom: theme.spacing(2) }}
              />
              <Grid container alignItems="center" item spacing={2}>
                <TargetFund
                  formValues={formValues}
                  formErrors={formErrors}
                  submitFailed={submitFailed}
                  premiumDisabled={formValues.targetFunctionality}
                  change={change}
                />
              </Grid>
            </>
          )}

          <Divider className={classes.hr} />

          <Grid container spacing={3} item xs={10} md={12}>
            {/* Assumed growth rate */}
            <Grid item xs={4} md={4}>
              <Field
                // shrink
                name="growthRate"
                fullWidth
                component={FormNumberField}
                label={<FormattedMessage {...messages.assumedGrowthRate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.enterAmount)}
              />
            </Grid>
          </Grid>

          <Divider className={classes.hr} />
          {/* BENEFITS */}
          <Grid
            container
            direction="row"
            spacing={1}
            justify="space-between"
            alignItems="center"
            item
            style={{ marginBottom: theme.spacing(1), paddingRight: '10%' }}
            wrap="nowrap"
            xs={11}
          >
            <Grid item xs={12} md={6} sm={6} />
            <Grid item xs={6} md={3} sm={3}>
              <Typography
                color="primary"
                variant="h3"
                style={{ marginLeft: theme.spacing(0.5) }}
              >
                {lifeAssured.insuredOne.firstName}
              </Typography>
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredTwo.firstName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
          <FieldArray
            name="coverages"
            component={renderBenefit}
            rerenderOnEveryChange
            lifeAssured={lifeAssured}
            options={options}
            change={change}
            startDate={startDate}
            intl={intl}
          />

          <Divider className={classes.hr} />

          <Grid item xs={6} style={{ marginBottom: '5rem' }}>
            <Field
              name="offerCode"
              fullWidth
              style={{
                width: 355,
              }}
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              placeholder={intl.formatMessage(messages.pleaseEnter)}
            />
          </Grid>

          {/* <Divider className={classes.hr} /> */}

          {/* ===== Regular withdrawal ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages.regularWithdrawalTitle} />
              </Typography>

              <Field
                name="isRegularWithdrawal"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isRegularWithdrawal}>
              <Divider className={classes.hrSlider} />
              <FormSection name="regularWithdrawal">
                <Withdrawal
                  formValues={regularWithdrawal}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  isEducationWithdrawal={false}
                  frequencyList={paymentFrequencyOption}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          {/* ===== Education Fee withdrawal ===== */}
          <CardSectionForm variant="outlined">
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="space-between"
            >
              <Typography variant="body1">
                <FormattedMessage {...messages.eduWithdrawalTitle} />
              </Typography>

              <Field
                name="isEducationalFeeWithdrawal"
                component={FormSwitch}
                disabled={false}
              />
            </Grid>

            <Collapse in={isEducationalFeeWithdrawal}>
              <Divider className={classes.hrSlider} />
              <FormSection name="educationFeesWithdrawal.child1">
                <Withdrawal
                  formValues={educationFeesWithdrawal}
                  minDate={minStartDate}
                  maxDate={maxStartDate}
                  isEducationWithdrawal
                  frequencyList={paymentFrequencyOption}
                />
              </FormSection>
            </Collapse>
          </CardSectionForm>

          {/* ===== FIA Charges ===== */}
          {!isEmpty(fiaData) && gcConfig.features.haveFiaCharge ? (
            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body1">
                  <FormattedMessage {...globalMessages.additionalFetures} />
                </Typography>
                <Field
                  name="isAdditionalFeature"
                  component={FormSwitch}
                  disabled={false}
                />
              </Grid>

              <Collapse in={isAdditionalFeature}>
                <Divider className={classes.hrSlider} />
                <Typography
                  // variant="h4"
                  style={{
                    marginBottom: theme.spacing(2.5),
                  }}
                >
                  <FormattedMessage {...globalMessages.fiaText} />
                </Typography>

                <Field name="fiaCharge" type="radio" component={FormRadioGroup}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<CustomRadio checked={fiaCharge == 0} />}
                        value={fiaData?.minFIACharge}
                        label={<FormattedMessage {...globalMessages.nil} />}
                        labelPlacement="end"
                        classes={{ root: classes.labelRadio }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container justify="space-between">
                        <FormControlLabel
                          control={<CustomRadio checked={fiaCharge != 0} />}
                          value={fiaData?.maxFIAChange}
                          label={
                            <FormattedMessage
                              {...globalMessages.annualPercentage}
                            />
                          }
                          labelPlacement="end"
                          classes={{ root: classes.labelRadio }}
                        />

                        <TextField
                          className={classes.inputWidth}
                          value={`${fiaData?.maxFIAChange} %`}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Field>
              </Collapse>
            </CardSectionForm>
          ) : null}
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  gcConfig: PropTypes.object,
  regularWithdrawal: PropTypes.object,
  educationFeesWithdrawal: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  offerCodeData: PropTypes.object,
  startDate: PropTypes.string,
  fiaCharge: PropTypes.string,
  term: PropTypes.number,
  coverages: PropTypes.array,
  fiaData: PropTypes.object,
  isEducationalFeeWithdrawal: PropTypes.bool,
  isRegularWithdrawal: PropTypes.bool,
};

const selector = formValueSelector('coverageForm');

const mapStateToProps = createStructuredSelector({
  offerCodeData: makeSelectOfferCodeData(),
});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withFormSelector = connect(state => {
  const startDate = selector(state, 'startDate');
  const currency = selector(state, 'currency');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const fiaCharge = selector(state, 'fiaCharge');
  const regularWithdrawal = selector(state, 'regularWithdrawal');
  const term = selector(state, 'term');
  const educationFeesWithdrawal = selector(state, 'educationFeesWithdrawal');
  const isEducationalFeeWithdrawal = selector(
    state,
    'isEducationalFeeWithdrawal',
  );
  const isRegularWithdrawal = selector(state, 'isRegularWithdrawal');
  const offerCode = selector(state, 'offerCode');
  const premium = selector(state, 'premium');
  const formValues = getFormValues('coverageForm')(state);
  const formErrors = getFormSyncErrors('coverageForm')(state);
  const isAdditionalFeature = selector(state, 'isAdditionalFeature');
  return {
    startDate,
    fiaCharge,
    isAdditionalFeature,
    term,
    regularWithdrawal: regularWithdrawal?.toJS() || {},
    educationFeesWithdrawal: educationFeesWithdrawal?.toJS() || {},
    isEducationalFeeWithdrawal,
    isRegularWithdrawal,
    currency,
    paymentFrequency,
    offerCode,
    premium,
    formValues: convertIfImmutable(formValues),
    formErrors: convertIfImmutable(formErrors),
  };
});

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: ['offerCode', 'currency', 'premium'],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

/**
 * PersonalDetails
 */
 import React from 'react';
 import PropTypes from 'prop-types';
 import { FormattedMessage, useIntl } from 'react-intl';
 import { useTheme } from '@material-ui/core/styles';
 import { Field } from 'redux-form/immutable';
 import { Grid, FormControlLabel, Box } from '@material-ui/core';
 import globalMessages from 'containers/App/messages';
 import FormTextField from 'components/FormTextField';
 import FormDatePicker from 'components/FormDatePicker';
 import FormRadioGroup from 'components/FormRadioGroup';
 import CustomRadio from 'components/CustomRadioButton';
 import FormBooleanOption from 'components/FormBooleanOption';
 import FormSelect from 'components/FormSelect';
 import Helper from 'components/Helper';
 import { changeToTitleCase } from 'helpers/lang';
 
 const lessThan = otherField => (value, previousValue, allValues) => {
   if (!value) return '';
   return value.length <= otherField ? value : previousValue;
 };
 
 function PersonalDetails({
   options,
   formValues = {},
   minDate,
   maxDate,
   isDisableForm,
 }) {
   const theme = useTheme();
   const intl = useIntl();
 
   return (
     <Box mb={3} ml={1}>
       <Grid container spacing={3} alignItems="center">
         <Grid item>
           <Field component="input" name="isExisting" type="hidden" />
           <Field
             name="firstName"
             style={{ width: 260 }}
             component={FormTextField}
             label={<FormattedMessage {...globalMessages.firstName} />}
             type="text"
             disabled={isDisableForm}
             margin="normal"
             normalize={lessThan(45)}
             placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
             format = {(value) => changeToTitleCase(value)} 
          />
         </Grid>
         <Grid item>
           <Field
             name="lastName"
             style={{ width: 260 }}
             component={FormTextField}
             label={<FormattedMessage {...globalMessages.familyName} />}
             margin="normal"
             disabled={isDisableForm}
             normalize={lessThan(40)}
             placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
             format = {(value) => changeToTitleCase(value)} 
           />
         </Grid>
         <Grid container />
         <Grid item>
           <Field
             style={{ width: 260 }}
             name="dateOfBirth"
             component={FormDatePicker}
             label={<FormattedMessage {...globalMessages.dob} />}
             dateInputFormat="DD/MM/YYYY"
             placeholder="dd/mm/yyyy"
             margin="normal"
             disabled={isDisableForm}
             minDate={minDate}
             maxDate={maxDate}
             inputProps={{ inputMode: 'numeric' }}
             InputLabelProps={{
               style: {
                 fontSize: theme.typography.body2.fontSize,
               },
             }}
           />
         </Grid>
         <Grid item>
           <Field name="gender" type="radio" component={FormRadioGroup}>
             <FormControlLabel
               style={{ marginRight: theme.spacing(2) }}
               control={<CustomRadio checked={formValues.gender === '1'} />}
               value={1}
               disabled={isDisableForm}
               label={<FormattedMessage {...globalMessages.male} />}
               labelPlacement="end"
             />
 
             <FormControlLabel
               control={<CustomRadio checked={formValues.gender === '0'} />}
               value={0}
               disabled={isDisableForm}
               label={<FormattedMessage {...globalMessages.female} />}
               labelPlacement="end"
             />
           </Field>
         </Grid>
         {!isDisableForm && (
           <>
             <Grid item>
               <Field
                 name="countryOfResidence"
                 component={FormSelect}
                 style={{ width: 260 }}
                 options={[
                   { value: 'Select an option', title: 'Select an option' },
                   ...options.countryCode,
                 ]}
                 label={
                   <FormattedMessage {...globalMessages.countryOfResidence} />
                 }
                 placeholder={intl.formatMessage(globalMessages.select)}
                 type="text"
                 margin="normal"
               />
             </Grid>
             <Grid item>
               <Field
                 name="nationality"
                 component={FormSelect}
                 style={{ width: 260 }}
                 options={[
                   { value: 'Select an option', title: 'Select an option' },
                   ...options.countryCode,
                 ]}
                 label={<FormattedMessage {...globalMessages.nationality} />}
                 placeholder={intl.formatMessage(globalMessages.select)}
                 type="text"
                 margin="normal"
               />
             </Grid>
             <Grid item xs={12}>
               <Field
                 name="isSmoke"
                 component={FormBooleanOption}
                 type="text"
                 margin="normal"
                 label={
                   <>
                     <FormattedMessage {...globalMessages.isSmoker} />
                     <Helper
                       helperText={globalMessages.tooltipInsured.defaultMessage}
                     />
                   </>
                 }
                 fullWidth
               />
             </Grid>
           </>
         )}
       </Grid>
     </Box>
   );
 }
 
 PersonalDetails.propTypes = {
   options: PropTypes.object,
   formValues: PropTypes.object,
   disabledPolicyOwner: PropTypes.bool,
   isNationalityShowing: PropTypes.bool,
   minDate: PropTypes.number,
   maxDate: PropTypes.number,
   productCode: PropTypes.string,
   error: PropTypes.bool,
 };
 
 export default PersonalDetails;
 
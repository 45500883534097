/*
 * LoginPage Messages
 *
 * This contains all the text for the LoginPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.LoginPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the LoginPage container!',
  },
  login: {
    id: `${scope}.login`,
    defaultMessage: 'Log in',
  },
  registeredQ: {
    id: `${scope}.registeredQ`,
    defaultMessage: 'Not registered yet? ',
  },
  signUp: {
    id: `${scope}.signUp`,
    defaultMessage: 'Sign up',
  },
  troubleAccessing: {
    id: `${scope}.troubleAccessing`,
    defaultMessage: 'Trouble logging in? ',
  },
  oldLogin: {
    id: `${scope}.oldLogin`,
    defaultMessage: 'Try an alternative login method',
  },
});

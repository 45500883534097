import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import messages from '../../../messages';
import { SubmissionError } from 'redux-form/immutable';
import moment from 'moment';
import numeral from 'numeral';
import { get } from 'lodash/object';

//validators
import isEmail from 'validator/lib/isEmail';

import { isEmpty, isNumber } from 'lodash/lang';
import { forEach, map } from 'lodash/collection';
import {
  regexNumbers,
  regexSpecialCharactersAndNumbers,
  regexLongDashCharacters,
} from 'helpers/lang';
import { isFieldEmpty } from 'utils/form';

import { convertIfImmutable, partyFilter } from 'helpers/lang';

const validate = (values, props) => {
  let errors = {};

  if (!isEmpty(convertIfImmutable(values))) {
    const primaryBeneficiaries = values.get('primaryBeneficiaries');
    const additionalDetails = values.get('additionalDetails');
    const appointConditionalAssignee = values.get('appointConditionalAssignee');
    const otherPrimaryBeneficiaries = values.get('otherPrimaryBeneficiaries');
    const { isBolton } = props.applicationDetails;
    const productConfigs = isBolton
      ? props.applicationDetails.productConfigs[0]
      : props.applicationDetails.productConfigs;
    const productConfigCurrency = productConfigs?.currency;
    const productConfigLFCValue = isBolton
      ? get(
          props.applicationDetails,
          'productConfigs[1].coverages[0].LFC.value',
          0,
        )
      : get(productConfigs, 'coverages[0].LFC.value', 0);
    // props.applicationDetails?.productConfigs?.coverages[0]?.LFC.value;
    const toUSD = (value, curr) => {
      const parsedValue = parseFloat(value);

      if (curr === 'AED') {
        return parsedValue / 3.665;
      }
      if (curr === 'BHD') {
        return parsedValue / 0.3765;
      }
      if (curr === 'QAR') {
        return parsedValue / 3.64;
      }

      return parsedValue;
    };

    const otherPrimaryBeneficiariesParties = convertIfImmutable(
      values.get('primaryBeneficiaries'),
    );

    const otherContingentBeneficiaries = values.get(
      'otherContingentBeneficiaries',
    );
    const otherContingentBeneficiariesParties = convertIfImmutable(
      values.get('contingentBeneficiaries'),
    );

    // check if there's a selected primary beneficiary
    // if (filteredPolicyOwner.length > 1) {
    // if (
    //   !isShariaBeneficiary &&
    //   !isBenefit &&
    //   !otherPrimaryBeneficiaries &&
    //   !otherContingentBeneficiaries &&
    //   !appointConditionalAssignee
    // ) {
    //   errors.selectOnePrimaryBeneficiaries = (
    //     <FormattedMessage {...messages.selectOneBeneficiaries} />
    //   );
    //   errors._error = 'submission failed';
    // }
    // }
    // var totalPercentage;
    if (otherPrimaryBeneficiaries) {
      const primaryBeneficiaryErrors = [];
      const requiredTextFields = [
        'firstName',
        'lastName',
        'relationshipToLifeInsured',
        'dateOfBirth',
        'email',
        'sharePercentage',
        'addressLine1',
        'addressLine2',
        'addressLine3',
        'city',
      ];

      const requiredSelectFields = ['country'];

      forEach(otherPrimaryBeneficiariesParties, (party, index) => {
        const partyErrors = {};

        forEach(requiredSelectFields, field => {
          if (
            isFieldEmpty(party[field]) ||
            party[field] === 'Select an option'
          ) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
            primaryBeneficiaryErrors[index] = partyErrors;
          }
        });

        forEach(requiredTextFields, field => {
          if (isFieldEmpty(party[field])) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
            primaryBeneficiaryErrors[index] = partyErrors;
          } else if (field === 'email') {
            if (!isEmail(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.emailInvalid} />
              );
              primaryBeneficiaryErrors[index] = partyErrors;
            }
          } else if (field === 'sharePercentage') {
            let totalPercentage = 0;
            forEach(convertIfImmutable(primaryBeneficiaries), partyB => {
              if (isNumber(totalPercentage)) {
                totalPercentage += parseInt(partyB.sharePercentage);
              } else {
                totalPercentage = parseInt(partyB.sharePercentage);
              }
            });
            if (totalPercentage !== 100) {
              partyErrors[field] = (
                <FormattedMessage
                  {...messages.sumOfSharePercentageShouldBe100}
                />
              );
              primaryBeneficiaryErrors[index] = partyErrors;
            }

            // else if (totalPercentage < 100) {
            //   partyErrors[field] = (
            //     <FormattedMessage {...messages.sharePercentageLessThan100} />
            //   );
            // }

            // if (isNumber(totalPercentage)) {
            //   totalPercentage += parseInt(party[field]);
            // } else {
            //   totalPercentage = parseInt(party[field]);
            // }

            // if (Number(party[field]) > 100) {
            //   partyErrors[field] = (
            //     <FormattedMessage {...messages.maximumPercentage100Allowed} />
            //   );
            //   primaryBeneficiaryErrors[index] = partyErrors;
            // }
          } else if (field === 'dateOfBirth') {
            const today = new Date().setFullYear(new Date().getFullYear());

            const capturedDate = moment(party[field]);
            const date = Date.parse(party[field]);
            if (date > today) {
              partyErrors[field] = (
                <FormattedMessage {...messages.futureDates} />
              );
              primaryBeneficiaryErrors[index] = partyErrors;
            }
            // else if (legalAge < date) {
            //   partyErrors[field] = <FormattedMessage {...messages.legalAge} />;
            //   primaryBeneficiaryErrors[index] = partyErrors;
            // }

            if (!capturedDate.isValid()) {
              partyErrors[field] = (
                <FormattedMessage {...messages.isNotValidDate} />
              );
              primaryBeneficiaryErrors[index] = partyErrors;
            }
          } else if (field === 'firstName' || field === 'lastName') {
            if (regexSpecialCharactersAndNumbers.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.specialCharactersAndNumbersNotAllowed}
                />
              );
              primaryBeneficiaryErrors[index] = partyErrors;
            }
          } else if (field === 'relationshipToLifeInsured') {
            if (party[field] === 'Select an option') {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            }
          } else if (
            field === 'addressLine1' ||
            field === 'addressLine2' ||
            field === 'addressLine3' ||
            field === 'city' ||
            field === 'postOfficeBoxCity'
          ) {
            if (party[field].length > 35) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
              primaryBeneficiaryErrors[index] = partyErrors;
            }

            if (regexLongDashCharacters.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.unsupportedChars} />
              );
              primaryBeneficiaryErrors[index] = partyErrors;
            }
          }
        });
      });

      if (primaryBeneficiaryErrors.length) {
        errors.primaryBeneficiaries = primaryBeneficiaryErrors;
      }
    }

    if (otherContingentBeneficiaries) {
      const contingentBeneficiaryErrors = [];
      const requiredTextFields = [
        'firstName',
        'lastName',
        'relationshipToLifeInsured',
        'dateOfBirth',
        'sharePercentage',
        'email',
        'addressLine1',
        'addressLine2',
        'addressLine3',
        'city',
      ];

      const requiredSelectFields = ['country'];

      forEach(otherContingentBeneficiariesParties, (party, index) => {
        const partyErrors = {};

        forEach(requiredSelectFields, field => {
          if (
            isFieldEmpty(party[field]) ||
            party[field] === 'Select an option'
          ) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
            contingentBeneficiaryErrors[index] = partyErrors;
          }
        });

        forEach(requiredTextFields, field => {
          if (isFieldEmpty(party[field])) {
            partyErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
            contingentBeneficiaryErrors[index] = partyErrors;
          } else if (field === 'email') {
            if (!isEmail(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.emailInvalid} />
              );
              contingentBeneficiaryErrors[index] = partyErrors;
            }
          } else if (field === 'sharePercentage') {
            var totalPercentage = 0;
            forEach(
              convertIfImmutable(otherContingentBeneficiariesParties),
              partyB => {
                if (isNumber(totalPercentage)) {
                  totalPercentage += Number(partyB.sharePercentage);
                } else {
                  totalPercentage = Number(partyB.sharePercentage);
                }

                if (!Number.isInteger(Number(partyB.sharePercentage))) {
                  partyErrors[field] = (
                    <FormattedMessage {...messages.invalidNumber} />
                  );
                  contingentBeneficiaryErrors[index] = partyErrors;
                }
                if (Number(partyB.sharePercentage) < 0) {
                  partyErrors[field] = (
                    <FormattedMessage {...messages.negativeValuesNotAllowed} />
                  );
                  contingentBeneficiaryErrors[index] = partyErrors;
                }
              },
            );

            if (totalPercentage !== 100) {
              partyErrors[field] = (
                <FormattedMessage
                  {...messages.sumOfSharePercentageShouldBe100}
                />
              );
              contingentBeneficiaryErrors[index] = partyErrors;
            }

            // if (isNumber(totalPercentage)) {
            //   totalPercentage += parseInt(party[field]);
            // } else {
            //   totalPercentage = parseInt(party[field]);
            // }

            // if (Number(party[field]) > 100) {
            //   partyErrors[field] = (
            //     <FormattedMessage {...messages.maximumPercentage100Allowed} />
            //   );
            //   contingentBeneficiaryErrors[index] = partyErrors;
            // }
          } else if (field === 'dateOfBirth') {
            const today = new Date().setFullYear(new Date().getFullYear());
            const date = Date.parse(party[field]);
            const capturedDate = moment(party[field]);
            if (date > today) {
              partyErrors[field] = (
                <FormattedMessage {...messages.futureDates} />
              );
              contingentBeneficiaryErrors[index] = partyErrors;
            }
            // else if (legalAge < date) {
            //   partyErrors[field] = <FormattedMessage {...messages.legalAge} />;
            //   contingentBeneficiaryErrors[index] = partyErrors;
            // }

            if (!capturedDate.isValid()) {
              partyErrors[field] = (
                <FormattedMessage {...messages.isNotValidDate} />
              );
              contingentBeneficiaryErrors[index] = partyErrors;
            }
          } else if (field === 'firstName' || field === 'lastName') {
            if (regexSpecialCharactersAndNumbers.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.specialCharactersAndNumbersNotAllowed}
                />
              );
              contingentBeneficiaryErrors[index] = partyErrors;
            }
          } else if (field === 'relationshipToLifeInsured') {
            if (party[field] === 'Select an option') {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            }
          } else if (
            field === 'addressLine1' ||
            field === 'addressLine2' ||
            field === 'addressLine3' ||
            field === 'city' ||
            field === 'postOfficeBoxCity'
          ) {
            if (party[field].length > 35) {
              partyErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
              contingentBeneficiaryErrors[index] = partyErrors;
            }

            if (regexLongDashCharacters.test(party[field])) {
              partyErrors[field] = (
                <FormattedMessage {...globalMessages.unsupportedChars} />
              );
              contingentBeneficiaryErrors[index] = partyErrors;
            }
          }
        });
      });

      if (contingentBeneficiaryErrors.length) {
        errors.contingentBeneficiaries = contingentBeneficiaryErrors;
      }
    }

    if (props.isWAPAndSCBAgency && convertIfImmutable(additionalDetails)) {
      const parsedAdditionalDetails = convertIfImmutable(additionalDetails);
      let conditionalAssigneeErrors = '';
      const conditionPO =
        props.policyOwners.length === 1
          ? ['appointSCBPolicyOwner1']
          : ['appointSCBPolicyOwner1', 'appointSCBPolicyOwner2'];
      if (
        !conditionPO.every(item => parsedAdditionalDetails[item]) ||
        !appointConditionalAssignee
      ) {
        conditionalAssigneeErrors =
          'Please provide your input for conditional assignee';

        errors = {
          ...errors,
          additionalDetails: {
            conditionalAssignee: conditionalAssigneeErrors,
          },
        };

        errors._error = 'submission failed';
      }
    }

    if (appointConditionalAssignee && !props.isWAPAndSCBAgency) {
      const parsedAdditionalDetails = convertIfImmutable(additionalDetails);

      const conditionalAssigneeErrors = [];

      const requiredFields = [
        'amount',
        'email',
        'postOfficeBoxLine',
        'reasonForAssignment',
        'branchName',
      ];
      const requiredSelectFields = [
        'institution',
        'currency',
        'reasonForassignment',
      ];
      // conditional Asignee totalValue

      let totalConditionalAssigneeValue = 0;
      forEach(parsedAdditionalDetails.conditionalAssignee, assignee => {
        const convertedValue = toUSD(
          parseFloat(assignee['amount']),
          assignee['currency'],
        );

        totalConditionalAssigneeValue += convertedValue;
      });
      forEach(
        parsedAdditionalDetails.conditionalAssignee,
        (assignee, index) => {
          const assigneeError = {};

          forEach(requiredFields, field => {
            if (isFieldEmpty(assignee[field]) && field !== 'email') {
              assigneeError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
              conditionalAssigneeErrors[index] = assigneeError;
            } else if (field === 'email') {
              if (!isEmpty(assignee[field])) {
                if (!isEmail(assignee[field])) {
                  assigneeError[field] = (
                    <FormattedMessage {...globalMessages.emailInvalid} />
                  );
                  conditionalAssigneeErrors[index] = assigneeError;
                }
              }
            } else if (field === 'amount') {
              if (
                toUSD(assignee[field], assignee['currency']) >
                toUSD(productConfigLFCValue, productConfigCurrency)
              ) {
                assigneeError[field] = (
                  <FormattedMessage
                    {...messages.morethanLifeCoverages}
                    values={{
                      value1: numeral(
                        productConfigLFCValue,
                        // get(
                        //   props,
                        //   'applicationDetails.productConfigs.coverages.coverages[0].LFC.value',
                        //   0,
                        // ),
                        // props.applicationDetails?.productConfigs?.coverages?.coverages[0]
                      ).format('0,0'),
                      curr: productConfigs?.currency,
                    }}
                  />
                );
                conditionalAssigneeErrors[index] = assigneeError;
              } else if (
                totalConditionalAssigneeValue >
                toUSD(productConfigLFCValue, productConfigCurrency)
              ) {
                assigneeError[field] = (
                  <FormattedMessage
                    {...messages.morethanLifeCoverages}
                    values={{
                      value1: numeral(
                        productConfigLFCValue,
                        // props.applicationDetails?.productConfigs?.coverages
                        //   ?.coverages[0]?.LFC.value,
                      ).format('0,0'),
                      curr: productConfigs?.currency,
                    }}
                  />
                );
                conditionalAssigneeErrors[index] = assigneeError;
              } else if (parseFloat(assignee[field]) < 0) {
                assigneeError[field] = (
                  <FormattedMessage {...messages.errorAmount} />
                );
              }
            } else if (field === 'reasonForAssignment') {
              if (assignee[field].length > 100) {
                assigneeError[field] = (
                  <FormattedMessage {...messages.cannotExceed100Characters} />
                );
                conditionalAssigneeErrors[index] = assigneeError;
              } else if (!regexNumbers.test(assignee[field])) {
                assigneeError[field] = (
                  <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
                );
                conditionalAssigneeErrors[index] = assigneeError;
              }
            } else if (field === 'postOfficeBoxLine') {
              if (assignee[field].length > 15) {
                assigneeError[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 15 }}
                  />
                );
                conditionalAssigneeErrors[index] = assigneeError;
              }
            }
          });

          forEach(requiredSelectFields, field => {
            if (field === 'institution') {
              if (assignee[field] === 'Select an option') {
                assigneeError[field] = (
                  <FormattedMessage {...globalMessages.pleaseSelectValue} />
                );
              }
            }
            if (field === 'currency') {
              if (assignee[field] === 'Select an option') {
                assigneeError[field] = (
                  <FormattedMessage {...globalMessages.pleaseSelectValue} />
                );
              }
            }
            if (isFieldEmpty(assignee[field])) {
              assigneeError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
              conditionalAssigneeErrors[index] = assigneeError;
            }
          });
        },
      );

      if (conditionalAssigneeErrors.length > 0) {
        errors = {
          ...errors,
          additionalDetails: {
            conditionalAssignee: conditionalAssigneeErrors,
          },
        };
      }
    }
    // if (otherPrimaryBeneficiaries || otherContingentBeneficiaries) {
    //   if (isNumber(totalPercentage)) {
    //     if (totalPercentage !== 100) {
    //       errors.atleast100SharePercentage = (
    //         <FormattedMessage {...messages.sumOfSharePercentageShouldBe100} />
    //       );
    //       errors._error = 'submission failed';
    //     }
    //   }
    // }
  }

  return errors;
};

export default validate;

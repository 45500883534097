import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import messages from '../../messages';
import { map } from 'lodash/collection';
import moment from 'moment';
import { getGender, filteredParty, partyFilter } from 'helpers/lang';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(name, date, relationshipToLifeInsured) {
  return { name, date, relationshipToLifeInsured };
}

// const rows = [createData('Mr. Frank Smith', '02.05.1986', 'Male', 'Smoker')];

function Payor(props) {
  const theme = useTheme();

  const { parties, classes } = props;
  const payor = partyFilter(parties, {
    name: 'isPayor',
    condition: true,
  });
  const isCompanyPayor =
    payor[0]?.partyapplicationrelationships?.additionalDetails?.isCompanyPayor;

  const rows = [
    ...map(payor, party => {
      return createData(
        `${!isCompanyPayor ? party.firstName : ''} ${party.lastName}`,
        party.dateOfBirth !== null
          ? moment(party.dateOfBirth).format('DD MMMM YYYY')
          : 0,
      );
    }),
  ];

  if (rows.length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.payor} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {rows.map((row, index) => (
                    <StyledTableRow key={`${row.name}-${index}`}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{ width: '70%' }}
                      >
                        <Typography variant="h4" className={classes.bold}>
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '30%' }}>
                        <Typography variant="h4">{row.date}</Typography>
                      </StyledTableCell>
                      {/* {row.relationshipToLifeInsured && (
                        <StyledTableCell>
                          <Typography
                            variant="body1"
                            style={{
                              textTransform: 'lowercase',
                            }}
                          >
                            {row.relationshipToLifeInsured}
                          </Typography>
                        </StyledTableCell>
                      )} */}
                      {/* <StyledTableCell>
                          <Typography variant="body1">{row.isSmoker}</Typography>
                        </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
}

Payor.propTypes = {
  parties: PropTypes.array,
};

export default Payor;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import IconEditSrc from 'images/open-external.svg';
import IconDeleteSrc from 'images/icon-trash.svg';
import CustomDialog from 'components/Dialog';
import { deleteIllustrationAction } from '../actions';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: '#4066B3',
    fontSize: 16,
    borderBottom: '1px solid #ffffff',
    maxWidth: 250,
  },
  body: {
    fontSize: 14,
    borderBottom: '1px solid #ffffff',
    maxWidth: 140,
  },
}))(TableCell);

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 5,
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  table: {
    minWidth: 700,
  },
  lifeInsured: {
    fontWeight: 'bold',
  },
  btnUpLoad: {
    color: '#4066B3',
    float: 'left',
    cursor: 'pointer',
    marginTop: '0.3rem',
    marginRight: '2rem',
  },
  btnDelete: {
    color: '#b6b8b4',
    cursor: 'pointer',
  },
  customTable: {
    overflowY: 'scroll',
  },
  customBox: {
    marginTop: 175,
  },
}));

export function TableIllustration({ dataIllustrations, deleteIllustration }) {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [idDelete, setIdDelete] = useState('');
  const [name, setName] = useState('');

  const upLoad = (id, productCode, productVersion) => {
    history.push({
      pathname: `/illustrations/${id}`,
      state: { productCode, productVersion },
    });
  };

  const showModalDelete = id => {
    if (!!id) {
      setOpen(true);
      setIdDelete(id);
      const itemDelete = dataIllustrations.filter(
        item => item.prospect.id === id,
      );
      const nameDelete = `${itemDelete[0].prospect.firstName} ${itemDelete[0].prospect.lastName}`;
      setName(nameDelete);
    }
  };

  const deleteIllustrations = idDelete => {
    const itemDelete = dataIllustrations.filter(
      item => item.prospect.id === idDelete,
    );
    const data = {
      id: idDelete,
      productCode: itemDelete[0].productCode,
      productVersion: itemDelete[0]?.productVersion,
    };
    deleteIllustration(data);
    setOpen(false);
  };

  const sortDate = () => {};

  const formatDate = date => {
    return moment(date).format('DD MMM YYYY');
  };

  return (
    <>
      <Box mt={5} className={classes.customBox}>
        <TableContainer classes={{ root: classes.customTable }}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Life insured 1</StyledTableCell>
                <StyledTableCell>
                  <Typography onClick={() => sortDate()}>
                    Created
                    <ArrowDropDownIcon />
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>Product</StyledTableCell>
                <StyledTableCell>Ill. no.</StyledTableCell>
                <StyledTableCell>&nbsp;</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataIllustrations.map((item, index) => (
                <TableRow key={index} className={classes.root}>
                  <StyledTableCell
                    component="th"
                    scope="row"
                    className={classes.lifeInsured}
                  >
                    {`${item.prospect.firstName} ${item.prospect.lastName}`}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatDate(item.prospect.createdAt)}
                  </StyledTableCell>
                  <StyledTableCell>{item.productName}</StyledTableCell>
                  <StyledTableCell>
                    {item.prospect.quotes.length}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <img
                      src={IconEditSrc}
                      alt=""
                      className={classes.btnUpLoad}
                      onClick={() =>
                        upLoad(
                          item.prospect.id,
                          item.productCode,
                          item.productVersion,
                        )
                      }
                    />
                    <img
                      src={IconDeleteSrc}
                      alt=""
                      className={classes.btnDelete}
                      onClick={() => showModalDelete(item.prospect.id)}
                    />
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <CustomDialog
        open={open}
        onClose={() => setOpen(false)}
        title="Delete the illustration(s)?"
        actions={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              margin="normal"
              style={{
                marginRight: 30,
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              margin="normal"
              onClick={() => {
                deleteIllustrations(idDelete);
              }}
            >
              Delete
            </Button>
          </div>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 800 }}>
          Are you sure you want to delete <b>{name}</b>? You cannot undo this
          action
        </Typography>
      </CustomDialog>
    </>
  );
}

TableIllustration.propTypes = {
  data: PropTypes.object,
  deleteIllustration: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TableIllustration);

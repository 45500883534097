/**
 *
 * OktaAuthHandler
 *
 */

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useOktaAuth } from '@okta/okta-react';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { makeSelectAuth, makeSelectError } from 'containers/App/selectors';
// import globalMessages from 'containers/App/messages';
import reducer from './reducer';
import saga from './saga';
import { refreshOktaTokenAction } from './actions';
import { logoutAction } from '../DashboardPage/actions';

export function OktaAuthHandler({ auth, refreshOktaToken, error, logout }) {
  useInjectReducer({ key: 'oktaAuthHandler', reducer });
  useInjectSaga({ key: 'oktaAuthHandler', saga });
  const { oktaAuth } = useOktaAuth();
  useEffect(() => {
    const { tokenManager } = oktaAuth;
    tokenManager.on('renewed', (key, newToken, oldToken) => {
      if (key === 'accessToken') {
        refreshOktaToken(newToken.value);
      }
    });

    return () => {
      tokenManager.off('renewed');
    };
  }, []);

  useEffect(() => {
    const handleLogout = async () => {
      // if (oktaAuth) {
      await logout(oktaAuth);
      // }
    };
    if (error.message && error.isLogout) {
      setTimeout(() => handleLogout(), 2000);
    }
    // return () => {
    //   if (error.message) {
    //     handleLogout();
    //   }
    // };
  }, [error.message]);

  return null;
}

OktaAuthHandler.propTypes = {
  auth: PropTypes.object,
  refreshOktaToken: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuth(),
  error: makeSelectError(),
});

function mapDispatchToProps(dispatch) {
  return {
    refreshOktaToken: oktaAccessToken =>
      dispatch(refreshOktaTokenAction(oktaAccessToken)),
    logout: value => dispatch(logoutAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OktaAuthHandler);

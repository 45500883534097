/**
 *
 * ApplicationFormPage
 *
 */
import { useTheme } from '@material-ui/core/styles';
import FormFooter from 'components/FormFooter';
import Header from 'components/FormHeader';
import FormMainBody from 'components/FormMainBody';
import {
  filteredApplicationPolicyOwner,
  filteredCompanyPayor,
  filteredContingentBeneficiaries,
  filteredisPayor,
  filteredLifeInsuredPayor,
  filteredPOLI,
  filteredPO,
  filteredPrimaryBeneficiary,
  filteredLifeInsured,
  filteredLifeInsuredOnly,
  isNotCoverages,
  filteredPayor,
  statusPath,
  previousHandler,
  checkTaxResidenceShow,
} from 'containers/ApplicationFormPage/helpers';
import {
  makeSelectAgentDetails,
  makeSelectFinancialInstituteOptions,
  makeSelectOption,
  makeSelectError,
} from 'containers/App/selectors';
import {
  addApplicationPartiesAction,
  addApplicationPartyAction,
  deleteApplicationPartiesAction,
  deleteApplicationPartyAction,
  updateApplicationDetailsAction,
  updateApplicationDetailsAndStatusAction,
  updateApplicationPartiesAction,
  updateApplicationStatusAction,
  getApplicationDetailsAction,
  setUnderwritingSections,
  setApplicationSubOrders,
  setLIPartiesAction,
} from 'containers/ApplicationPage/actions';
import {
  makeSelectApplicationDetails,
  makeSelectApplicationSubOrder,
  makeSelectUnderwritingSections,
} from 'containers/ApplicationPage/selectors';
// actions
import {
  changeSubPageAction,
  changeSubPageListAction,
  changeSubStepPageAction,
} from 'containers/DashboardPage/actions';
import {
  makeSelectSubPage,
  makeSelectSubPageList,
  makeSelectSubPageStepper,
} from 'containers/DashboardPage/selectors';
import { convertIfImmutable, isUS, isNexusAgency } from 'helpers/lang';
import {
  newCompanyPayor,
  newConditionalAssignee,
  newParty,
  newPayorProceeds,
  newPayorSource,
  newTaxPurposes,
  newBankAccount,
  newBankDetail,
  newCCDetail,
  newOtherPartiesDetails,
  newExistingZurichPlanDetails,
  newLifeOfficesDetails,
  newpolicyPaidBySalary,
} from 'helpers/placeholder';
import { differenceWith } from 'lodash/array';
import { filter, forEach, map, find, sortBy, orderBy } from 'lodash/collection';
import { isEqual } from 'lodash/lang';
import { get } from 'lodash/object';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { compose } from 'redux';
import { submit } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import queryString from 'query-string';
import { allSubOrderList } from 'helpers/list';
import { isEmpty } from 'lodash';
import { setFileAction } from 'containers/App/actions';
import { productCodeCheck } from 'helpers/constants';
import {
  setPaymentDetail,
  setSectionAction,
  submitPaymentAction,
  submitCreditCardAction,
  continuePaymentCallbackAction,
  submitUWAction,
  setQuestionsEmptyAction,
  setAnswersEmptyAction,
  setPaymentDataAction,
  updatePaymentType,
  updateApplicationFundsAction,
  updateApplicationFundsAction2,
} from './actions';
import Consent from './components/Consent';
import DocumentForm from './components/Document';
import Payment from './components/Payment';
import PaymentMethod from './components/Payment/PaymentMethod';
import PaymentPreview from './components/Payment/PaymentPreview';
import Beneficiaries from './components/PolicyDetails/Beneficiaries';
import SaciBeneficiary from './components/PolicyDetails/Beneficiaries/SaciBeneficiary';
import Investment from './components/PolicyDetails/Investment';
import PayfortCallback from './components/Payment/PayfortCallback';

// validation

// components
import PolicyOwner from './components/PolicyDetails/PolicyOwner';
import Decision from './components/Underwriting/Decision';
import SectionForm from './components/UnderWriting/SectionForm';
import UWConsent from './components/UnderWriting/UWConsent';
import Submit from './components/UnderWriting/Submit';
import Payor from './components/PolicyDetails/Payor';
import PayorSalary from './components/PolicyDetails/PayorSalary';
import PayorBank from './components/PolicyDetails/PayorBank';
import LifeInsured from './components/PolicyDetails/LifeInsured';
// constants
import { ADJUSTED_POLICY_SUBSTEPPERS } from './constants';
import messages from './messages';
import reducer from './reducer';
import saga from './saga';
import makeSelectApplicationFormPage, {
  makeSelectAnswers,
  makeSelectPaymentDetail,
  makeSelectPaymentType,
  makeSelectPayment,
  makeSelectFundsBelongToNexusOrDever,
} from './selectors';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  FormControlLabel,
} from '@material-ui/core';
import { Field } from 'redux-form/immutable';

export function ApplicationFormPage(props) {
  useInjectReducer({ key: 'applicationFormPage', reducer });
  useInjectSaga({ key: 'applicationFormPage', saga });
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  // get the path after "form"
  const pathParsed = location.pathname.split('form')[1];

  const {
    subPage,
    subPageStepper,
    subPageList,
    changeSubPage,
    changeSubStepPage,
    changeSubPageList,
    underwritingSections,
    steps,
    applicationDetails,
    updateApplicationDetails,
    updateApplicationDetailsAndStatus,
    addApplicationParties,
    addApplicationParty,
    updateApplicationParties,
    error,
    deleteApplicationParties,
    deleteApplicationParty,
    match,
    submitPolicyOwner,
    submitBeneficiaries,
    submitPayor,
    submitPayorBank,
    submitInvestment,
    submitSection,
    submitDecision,
    submitDocument,
    submitConsent,
    submitPayment,
    submitPaymentMethod,
    submitUWConsent,
    updateApplicationStatus,
    getInterviewSessionId,
    submitUW,
    submitUWForm,
    submitPayorSalary,
    answers,
    paymentType,
    paymentDetail,
    financialInstituteOptions,
    updatePaymentDetail,
    submitPaymentDetail,
    submitCreditCard,
    continuePayment,
    getApplicationDetails,
    setUnderwriting,
    setApplicationStruct,
    setAnswersEmpty,
    setQuestionsEmpty,
    setLIParties,
    paymentStatus,
    setPaymentStatus,
    submitLifeInsured,
    setFile,
    updatePaymentMethod,
    updateApplicationFunds2,
    fundsBelongToNexusOrDever,
    agentDetails,
    submitPaymentPreview,
    options,
  } = props;
  const { applicationId } = match.params;
  const [status, setStatus] = useState(false);
  const [goBack, setGoBack] = useState(false);
  const [page, setPage] = useState([]);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [continuing, setContinuing] = useState(false);
  const [counting, setCounting] = useState(false);
  const [isShowTaxResidence, setShowTaxResidence] = useState(false);
  const [openDialogPayment, setOpenDialogPayment] = useState(false);

  const parsedApplicationDetails = convertIfImmutable(applicationDetails);
  const isInforce = get(parsedApplicationDetails, 'isInforce', false);
  const availableStatuses = get(
    parsedApplicationDetails,
    'availableStatuses',
    [],
  );
  const disabledFields = get(parsedApplicationDetails, 'disabledFields', {});
  const payor = parsedApplicationDetails?.parties?.find(
    item => item.partyapplicationrelationships.isPayor,
  );
  const isCompanyPayor =
    payor?.partyapplicationrelationships?.additionalDetails?.isCompanyPayor;
  // Check condition to enable Next button or not
  const canSubmit = data => {
    setStatus(data);
  };

  const canGoBack = data => {
    setGoBack(data);
  };

  const submitDialog = () => {
    submitUWForm();
  };
  // only called when the page first loaded and compare if its the same application
  // useEffect(() => {}, []);

  useEffect(() => {
    // Check for case to retain pop up success not disappear quickly
    if (subPageList.length === 0) {
      if (location.pathname.indexOf('/payment-method') < 0) {
        getApplicationDetails(applicationId);
      }
      changeSubPageList(convertIfImmutable(steps));
      setPage(subPageList);
    }
  }, [subPageList]);

  // HANDLE the sub paging by checking the location
  useEffect(() => {
    const formPath = location.pathname.split('form')[1];
    // set active subPage
    let activeSubPage = subPage;
    switch (formPath) {
      case '/policy-owner':
      case '/beneficiaries':
      case '/payor':
      case '/payor-salary':
      case '/payor-bank':
      case '/lifeinsured':
      case '/investment':
        activeSubPage = 1;
        break;
      case '/uw-consent':
      case '/uw-section':
      case '/uw-submit':
      case '/uw-decision':
        activeSubPage = 2;
        break;
      case '/documents':
        activeSubPage = 3;
        break;
      case '/consent':
        activeSubPage = 4;
        break;
      case '/payment':
      case '/payment-method':
      case '/payment-preview':
      //submitPaymentPreview();
      // submitPaymentDetail({ paymentDetail, applicationId });
      case '/payment-callback':
        activeSubPage = 5;
      default:
        break;
    }
    changeSubPage(activeSubPage);
  }, [location.pathname.split('form')[1]]);

  // HANDLE sub page stepper by checking location (except for UW)
  useEffect(() => {
    const formPath = location.pathname.split('form')[1];
    const uwInterviewSectionCount = convertIfImmutable(underwritingSections)
      .length;
    // set active subPage
    let activeSubPageStep = subPage;
    switch (formPath) {
      case '/policy-owner':
        activeSubPageStep = 1;
        break;
      case '/beneficiaries':
        activeSubPageStep = 2;
        break;
      case '/payor':
        activeSubPageStep = 3;
        break;
      case '/payor-salary':
        activeSubPageStep = 4;
        break;
      case '/payor-bank':
        activeSubPageStep = 5;
        break;
      case '/lifeinsured':
        activeSubPageStep = 6;
        break;
      case '/investment':
        activeSubPageStep = 7;
        break;
      // from UW onwards, it depends on the UW section count
      case '/uw-consent':
        activeSubPageStep = 8;
      case '/uw-section':
        if (location.state?.sessionId) {
          activeSubPageStep = Number(location.state.sessionId);
        } else {
          activeSubPageStep = 9;
        }
        break;
      case '/uw-submit':
        activeSubPageStep = 8 + uwInterviewSectionCount + 1;
        break;
      case '/uw-decision':
        activeSubPageStep = 8 + uwInterviewSectionCount + 2;
        break;
      // case '/documents':
      //   activeSubPageStep = 6 + uwInterviewSectionCount + 1;
      //   break;
      // case '/consent':
      //   activeSubPageStep = 6 + uwInterviewSectionCount + 2;
      //   break;
      case '/payment':
        activeSubPageStep = 8 + uwInterviewSectionCount + 3;
        break;
      case '/payment-method':
        activeSubPageStep = 8 + uwInterviewSectionCount + 3;
        break;
      case '/payment-preview':
        activeSubPageStep = 8 + uwInterviewSectionCount + 5;
        break;
      // make the payment-callback the same as payment-method for now
      case '/payment-callback':
        activeSubPageStep = 8 + uwInterviewSectionCount + 4;
        break;
      default:
        break;
    }
    changeSubStepPage(activeSubPageStep);
  }, [location.pathname.split('form')[1]]);

  // Change content of some special text Next button in some page
  const updateTextNextButton = () => {

    const { agencyNumber } = parsedApplicationDetails;
    if (pathParsed === '/investment') {
      return <FormattedMessage {...messages.next} />;
      // return <FormattedMessage {...messages.agreeAndProceed} />;
    }
    if (pathParsed === '/payment-preview') {
      // if (agencyNumber != "" && (isNexusAgency(agencyNumber)
      // || isFABAgency(agencyNumber))){     // for nexus and FAB admin module
      if (agencyNumber != "" && isNexusAgency(agencyNumber)) {     // for nexus admin module
        if (continuing) {
          return <FormattedMessage {...messages.submitForReview} />;
        } else if (counting) {
          return <FormattedMessage {...messages.submitForReview} />;
        }
        else {
          if (isInforce) {
            return <FormattedMessage {...messages.next} />;
          } else {
            return <FormattedMessage {...messages.submitForReview} />;
          }
        }
      } else {
        return <FormattedMessage {...messages.submitDetails} />;
      }
    }
    if (
      paymentStatus.status === 'declined' &&
      pathParsed === '/payment-method'
    ) {
      return <FormattedMessage {...messages.tryAnotherCard} />;
    }
    if (pathParsed === '/payment-method') {
      // if (agencyNumber != "" && (isNexusAgency(agencyNumber)
      // || isFABAgency(agencyNumber))){     // for nexus and FAB admin module
      if (agencyNumber != "" && isNexusAgency(agencyNumber)) {     // for nexus admin module
        if (paymentType === 'CREDIT_CARD') {
          if (!isInforce) {
            return <FormattedMessage {...messages.submitForReview} />;
          }
        }
      }
    }
    return <FormattedMessage {...messages.next} />;
  };

  useEffect(() => {
    setShowTaxResidence(checkTaxResidenceShow(parsedApplicationDetails));

    if (
      isEmpty(paymentType) &&
      !isEmpty(parsedApplicationDetails?.paymentMethod)
    ) {
      updatePaymentMethod(parsedApplicationDetails?.paymentMethod);
    }
  }, [applicationDetails]);

  // APPLICATION DETAILS
  useEffect(() => {
    // works both with GET and POST methods of payfort, so no need for environment variable
    if (location.pathname.indexOf('/payment-callback') >= 0) {
      updatePaymentMethod('CREDIT_CARD');
      continuePayment({
        params: queryString.parse(location.search),
        history,
      });
    }
  }, []);

  useEffect(() => {
    // manipulate the mini drawer here
    if (subPageList.length > 0) {
      const newSubPageList = [...subPageList];
      if (productCodeCheck(3, parsedApplicationDetails.productCode)) {
        if (lifeInsuredOnly.length === 0) {
          const filter = map(newSubPageList[0].subOrder, item => {
            if (item.text === 'Life insured') {
              return { ...item, isHidden: true };
            }
            return item;
          });
          // const filter = newSubPageList[0].subOrder.filter(
          //   item => item.text !== 'Life insured',
          // );
          newSubPageList[0].subOrder = filter;
        }
      }
      else {
        const filter = map(newSubPageList[0].subOrder, item => {
          if (lifeInsuredOnly.length > 0) {
            if (item.text === 'Investment') {
              return { ...item, isHidden: true };
            }
          } else if (lifeInsuredOnly.length === 0) {
            if (item.text === 'Investment' || item.text === 'Life insured') {
              return { ...item, isHidden: true };
            }
            // return item.text !== 'Investment' && item.text !== 'Life insured';
          }

          return { ...item };
        });
        // const filter = newSubPageList[0].subOrder.filter(item => {
        //   if (lifeInsuredOnly.length > 0) {
        //     return item.text !== 'Investment';
        //   } else {
        //     return item.text !== 'Investment' && item.text !== 'Life insured';
        //   }
        // });
        newSubPageList[0].subOrder = filter;
      }
      if (productCodeCheck('WAP', parsedApplicationDetails.productCode)){
        newSubPageList[1].text = 'Underwriting';
        newSubPageList[1].subOrder = [];
      }

      if (
        productCodeCheck(5, parsedApplicationDetails.productCode) &&
        isNotCoverages(parsedApplicationDetails.productConfigs) &&
        !parsedApplicationDetails.isBolton
      ) {
        newSubPageList[1].text = 'Summary';
        newSubPageList[1].subOrder = [
          { order: 9, text: 'Loading', isHidden: true },
        ];
      }

      if (isInforce) {
        newSubPageList[0].subOrder[1].isHidden = true;
      }
      changeSubPageList(newSubPageList);
    }
  }, [page, applicationDetails]);

  // remove status when not in application forms
  useEffect(
    () => () => {
      setFile({});
      changeSubPageList([]);
      setUnderwriting([
        {
          order: 5,
          text: 'Loading',
          id: null,
          name: 'Loading',
          sessionId: '0',
          partyId: '0',
          eTag: '0',
        },
      ]);
      setApplicationStruct([
        {
          order: 1,
          text: 'Policy Details',
          subOrder: [
            { order: 1, text: 'Policy owner(s)', isHidden: false },
            { order: 2, text: 'Beneficiaries', isHidden: (productCodeCheck(8, parsedApplicationDetails.productCode)) },
            { order: 3, text: 'Payor', isHidden: false },
            { order: 4, text: 'Payor Details', isHidden: true },
            { order: 5, text: 'Payor Details continued', isHidden: true },
            { order: 6, text: 'Life insured', isHidden: false },
            { order: 7, text: 'Investment', isHidden: false }, 
          ],
        },
        {
          order: 2,
          text: 'Underwriting',
          subOrder: [
            { order: 8, text: 'Consent', isHidden: true },
            { order: 9, text: 'Loading', isHidden: false },
          ],
        },
        {
          order: 3,
          text: 'Documents',
          subOrder: [],
        },
        {
          order: 4,
          text: 'Consent',
          subOrder: [],
        },
        {
          order: 5,
          text: 'Payment',
          subOrder: [
            { order: 10, text: 'Payment', isHidden: true },
            { order: 11, text: 'Payment method', isHidden: false },
            { order: 12, text: 'Review', isHidden: false },
          ],
        },
      ]);
      setQuestionsEmpty();
      setAnswersEmpty();
      setLIParties([]);
      setReset(true);
      setPaymentStatus({ status: null });
      updatePaymentDetail({});
      updatePaymentMethod('');
    },
    [isInforce],
  );

  const lifeInsuredOnly = filteredLifeInsuredOnly(parsedApplicationDetails);
  useEffect(() => {
    const { status, uwStatus, uwConsent } = parsedApplicationDetails;
    const formPath = location.pathname.split('form')[1];

    // STATUS PATHS

    // POLICY DETAILS sub STATUS
    if (!isEmpty(status) && !error.message) {
      statusPath(
        status,
        applicationId,
        formPath,
        history,
        uwStatus,
        uwConsent,
        lifeInsuredOnly,
        isInforce,
        availableStatuses,
        isCompanyPayor,
        productCodeCheck(3, parsedApplicationDetails.productCode),
      );
    }
  }, [applicationDetails]);


  // STEP HANDLERS
  const currentUWSection = convertIfImmutable(underwritingSections)[
    subPageStepper - ADJUSTED_POLICY_SUBSTEPPERS
  ];

  const previousStepHandler = path => {
    if (path) {
      history.push(path);
    }
  };

  const onClickPreviousHandler = () => {
    const payor = parsedApplicationDetails?.parties?.find(
      item => item.partyapplicationrelationships.isPayor,
    );
    const isCompanyPayor =
      payor?.partyapplicationrelationships?.additionalDetails?.isCompanyPayor;
    // FYI if BE applied the update from inforce to other agencies. can use this code block and remove 'isInforce'
    // to handle the previous pages dynamically
    if (isInforce) {
      previousHandler(
        pathParsed,
        previousStepHandler,
        availableStatuses,
        parsedApplicationDetails?.productCode,
        applicationId,
        history,
        setReset,
      );
    } else {
      // NON INFORCE
      if (pathParsed === '/beneficiaries') {
        previousStepHandler('policy-owner');
      }
      if (pathParsed === '/payor' && !(productCodeCheck(8, parsedApplicationDetails.productCode))) {
        previousStepHandler('beneficiaries');
      }
      if (pathParsed === '/payor' && (productCodeCheck(8, parsedApplicationDetails.productCode))) {
        previousStepHandler('policy-owner');
      }
      if (pathParsed === '/payor-salary') {
        previousStepHandler('payor');
      }
      if (pathParsed === '/payor-bank') {
        previousStepHandler('payor-salary');
      }
      if (pathParsed === '/lifeinsured') {
        previousStepHandler(isCompanyPayor ? 'payor' : 'payor-bank');
      }
      if (pathParsed === '/investment') {
        if (lifeInsuredOnly.length > 0) {
          previousStepHandler('lifeinsured');
        } else {
          previousStepHandler(isCompanyPayor ? 'payor' : 'payor-bank');
        }
      }
      if (pathParsed === '/uw-consent') {
        if (!productCodeCheck(3, parsedApplicationDetails.productCode)) {
          if (!productCodeCheck(3, parsedApplicationDetails.productCode)) {
            const newSubPageList = [...subPageList];
            const filter = newSubPageList[0].subOrder.filter(
              item => item.text !== 'Investment',
            );
            newSubPageList[0].subOrder = filter;
            changeSubPageList(newSubPageList);
          }
          if (lifeInsuredOnly.length > 0) {
            previousStepHandler('lifeinsured');
          } else {
            previousStepHandler(isCompanyPayor ? 'payor' : 'payor-bank');
          }
        } else {
          previousStepHandler('investment');
        }
      }
      if (pathParsed === '/uw-section') {
        if (
          !convertIfImmutable(underwritingSections)[
          subPageStepper - 1 - ADJUSTED_POLICY_SUBSTEPPERS
          ]
        ) {
          previousStepHandler('uw-consent');
        } else {
          // if it's still in UW interview sections, manually update the subPageStepper
          changeSubStepPage(subPageStepper - 1);
        }
      } else if (pathParsed === '/uw-submit') {
        previousStepHandler('uw-section');
      } else if (pathParsed === '/uw-decision') {
        previousStepHandler('uw-submit');
      } else if (pathParsed === '/documents') {
        previousStepHandler('uw-decision');
      } else if (pathParsed === '/consent') {
        previousStepHandler('documents');
      } else if (pathParsed === '/payment') {
        previousStepHandler('consent');
        setStatus(false);
      } else if (pathParsed === '/payment-method') {
        previousStepHandler('payment');
      } else if (pathParsed === '/payment-preview') {
        previousStepHandler('payment-method');
      } else if (pathParsed === '/policy-owner') {
        previousStepHandler(`/applications/${applicationId}`);
      }
    }
  };
  const onClickExistHandler = () => {
    history.push('/application');
    changeSubPageList([]);
  };
  const nextStepHandler = path => {
    if (pathParsed === '/uw-section') {
      // update subPageStepper manually if there's still UW interview section remaining (excl. submit and decision, thus + 3)
      if (
        convertIfImmutable(underwritingSections)[
        subPageStepper - ADJUSTED_POLICY_SUBSTEPPERS + 1
        ]
      ) {
        changeSubStepPage(subPageStepper + 1);
      }
    }

    if (path) {
      history.push(path);
    }
  };

  const onClickNextHandler = () => {
    switch (pathParsed) {
      case '/policy-owner':
        submitPolicyOwner();
        break;
      case '/beneficiaries':
        submitBeneficiaries(
          parsedApplicationDetails.productCode === 'SACI1'
            ? 'saciBeneficiary'
            : 'beneficiaries',
        );
        break;
      case '/payor':
        submitPayor();
        break;
      case '/payor-salary':
        submitPayorSalary();
        break;
      case '/payor-bank':
        submitPayorBank();
        break;
      case '/lifeinsured':
        submitLifeInsured();
        break;
      case '/investment':
        submitInvestment();
        break;
      case '/uw-consent':
        submitUWConsent();
        break;
      case '/uw-section':
        submitSection(currentUWSection.text);
        break;
      case '/uw-submit':
        setOpen(true);
        break;
      case '/uw-decision':
        submitDecision();
        break;
      case '/documents':
        submitDocument();
        break;
      case '/consent':
        submitConsent();
        break;
      case '/payment':
        submitPayment();
        break;
      case '/payment-method':
        if (paymentStatus.status === 'declined') {
          setReset(true);
          setPaymentStatus({ status: null });
        } else {
          if (paymentType === 'CREDIT_CARD') {
            setOpenDialogPayment(true);
          }
          submitPaymentMethod();
        }
        break;
      case '/payment-preview':
        setCounting(true);
        submitPaymentPreview();
        // submitPaymentDetail({ paymentDetail, applicationId });
        break;
      default:
        break;
    }
  };

  const disabledNext = () => status;
  const disabledPrevious = () => goBack;

  const initialValuesBeneficiaries = {
    ...parsedApplicationDetails,
    otherPrimaryBeneficiaries:
      parsedApplicationDetails.productCode === 'SACI1'
        ? filteredPrimaryBeneficiary(parsedApplicationDetails).length > 0
        : parsedApplicationDetails.otherPrimaryBeneficiaries,
    lifeInsuredParties: filteredPO(parsedApplicationDetails),
    appointConditionalAssignee:
      parsedApplicationDetails.additionalDetails?.conditionalAssignee?.length >
      0,
    isBenefit: get(
      parsedApplicationDetails,
      'additionalDetails.isBenefit',
      false,
    ),
    conditionalAssignee:
      parsedApplicationDetails.additionalDetails?.conditionalAssignee,
    additionalDetails:
      parsedApplicationDetails.additionalDetails &&
        parsedApplicationDetails.additionalDetails.conditionalAssignee?.length > 0
        ? parsedApplicationDetails.additionalDetails
        : {
          ...parsedApplicationDetails.additionalDetails,
          conditionalAssignee: [{ ...newConditionalAssignee() }],
        },

    primaryBeneficiaries:
      filteredPrimaryBeneficiary(parsedApplicationDetails).length > 0
        ? sortBy(
          map(
            filteredPrimaryBeneficiary(parsedApplicationDetails),
            party => ({
              ...party,
              // map relationshipToLifeInsured
              selectedAddress: party.additionalDetails?.selectedAddress
                ? party.additionalDetails?.selectedAddress
                : 'different',
              relationshipToLifeInsured:
                party.partyapplicationrelationships.additionalDetails
                  .relationshipToLifeInsured,
              sharePercentage:
                party.partyapplicationrelationships.additionalDetails
                  .sharePercentage,
              order: party.applicationDetails?.order,
            }),
          ),
          [
            function (o) {
              return o.additionalDetails?.order;
            },
          ],
        )
        : [
          {
            ...newParty({
              isPrimaryBeneficiary: true,
              isBeneficiary: true,
              isPolicyOwner: false,
              isLifeInsured: false,
            }),
            selectedAddress: 'different',
          },
        ],
    contingentBeneficiaries:
      filteredContingentBeneficiaries(parsedApplicationDetails).length > 0
        ? sortBy(
          map(
            filteredContingentBeneficiaries(parsedApplicationDetails),
            party => ({
              ...party,
              // map relationshipToLifeInsured
              selectedAddress: party.additionalDetails?.selectedAddress
                ? party.additionalDetails?.selectedAddress
                : 'different',
              relationshipToLifeInsured:
                party.partyapplicationrelationships.additionalDetails
                  .relationshipToLifeInsured,
              sharePercentage:
                party.partyapplicationrelationships.additionalDetails
                  .sharePercentage,
            }),
          ),
          [
            function (o) {
              return o.additionalDetails?.order;
            },
          ],
        )
        : [
          {
            ...newParty({
              isPrimaryBeneficiary: false,
              isBeneficiary: true,
              isPolicyOwner: false,
              isLifeInsured: false,
            }),
            selectedAddress: 'different',
          },
        ],
  };

  const BeneficiaryContainer =
    parsedApplicationDetails.productCode === 'SACI1'
      ? SaciBeneficiary
      : Beneficiaries;

  // checks if isInforce === true and PO is payor
  const initialValuesPayor = {
    ...parsedApplicationDetails,
    lifeInsuredParties: filteredPO(parsedApplicationDetails),
    inforcePayor:
      filteredPayor(parsedApplicationDetails).length > 0
        ? {
          ...filteredPayor(parsedApplicationDetails)[0],
          relationships: filteredPayor(parsedApplicationDetails)[0]
            .partyapplicationrelationships.additionalDetails,
          selectedAddress: filteredPayor(parsedApplicationDetails)[0]
            .additionalDetails?.selectedAddress,
        }
        : {
          ...newParty({
            isPrimaryBeneficiary: false,
            isBeneficiary: false,
            isPolicyOwner: false,
            isLifeInsured: false,
            isPayor: true,
          }),
          relationships: newParty().partyapplicationrelationships
            .additionalDetails,
          selectedAddress: 'different',
        },
    thirdPayor:
      filteredisPayor(parsedApplicationDetails).length > 0
        ? {
          ...filteredisPayor(parsedApplicationDetails)[0],
          relationships: filteredisPayor(parsedApplicationDetails)[0]
            .partyapplicationrelationships.additionalDetails,
          selectedAddress: filteredisPayor(parsedApplicationDetails)[0]
            .additionalDetails?.selectedAddress,
        }
        : {
          ...newParty({
            isPrimaryBeneficiary: false,
            isBeneficiary: false,
            isPolicyOwner: false,
            isLifeInsured: false,
            isPayor: true,
          }),
          relationships: newParty().partyapplicationrelationships
            .additionalDetails,
          selectedAddress: 'different',
        },
    lifeInsuredPayor: map(
      filteredLifeInsuredPayor(parsedApplicationDetails),
      party => ({
        ...party,
        relationships: party.partyapplicationrelationships.additionalDetails,
        lifeInsuredPersonPayor: get(
          party,
          'partyapplicationrelationships.isPayor',
        ),
        selectedAddress: get(
          party,
          'additionalDetails.selectedAddress',
          'different',
        ),
        // party.partyapplicationrelationships.additionalDetails
        //   .lifeInsuredPersonPayor,
      }),
    ),
    companyPayor: !isEmpty(filteredCompanyPayor(parsedApplicationDetails))
      ? {
        ...filteredCompanyPayor(parsedApplicationDetails),
        relationships: filteredCompanyPayor(parsedApplicationDetails)
          .partyapplicationrelationships.additionalDetails,
      }
      : {
        ...newCompanyPayor({
          isPrimaryBeneficiary: false,
          isBeneficiary: false,
          isPolicyOwner: false,
          isLifeInsured: false,
          isPayor: true,
        }),
      },
  };

  // Added for Investment strategies page
  const isLapsePolicy = parsedApplicationDetails.productConfigs?.policyStatus == 'L' ;
  const isFutura = parsedApplicationDetails.productName == 'Futura' || parsedApplicationDetails.productName == 'My Life Protect';
  
  let investmentText = (!isInforce || !isFutura)  ? 'Investment' : (isLapsePolicy ? "Investment Strategy for Regular Premium" : "Investment Strategy for Additional Single Premium")

  const initialValuesPayorSalary = {
    productCode: parsedApplicationDetails.productCode,
    payorDetails:
      !isEmpty(parsedApplicationDetails.payorDetails) &&
        !isEmpty(parsedApplicationDetails.payorDetails.paymentCurrency)
        ? parsedApplicationDetails.payorDetails
        : {
          ...parsedApplicationDetails.payorDetails,
          paymentCurrency: 'Select',
        },
    isPolicyPaidBySalary: get(
      parsedApplicationDetails,
      'payorDetails.isPolicyPaidBySalary',
      true,
    ),
    isArmyService: get(
      parsedApplicationDetails,
      'payorDetails.isArmyService',
      false,
    ),
    nameOfJobTitle: get(
      parsedApplicationDetails,
      'payorDetails.nameOfJobTitle',
    ),
    isPolicyPaidBySalary2: get(
      parsedApplicationDetails,
      'payorDetails.isPolicyPaidBySalary2',
      false,
    ),
    policyPaidBySalary: get(
      parsedApplicationDetails,
      'payorDetails',
      newpolicyPaidBySalary,
    ),
    isPayorProceeds: get(
      parsedApplicationDetails,
      'additionalDetails.isPayorProceeds',
      false,
    ),
    payorProceeds:
      get(parsedApplicationDetails, 'additionalDetails.payorProceeds', [
        { ...newPayorProceeds },
      ]).length > 0
        ? get(parsedApplicationDetails, 'additionalDetails.payorProceeds', [
          { ...newPayorProceeds },
        ])
        : [{ ...newPayorProceeds }],
    isPayorSource: get(
      parsedApplicationDetails,
      'additionalDetails.isPayorSource',
      false,
    ),
    payorSource:
      // !isEmpty(
      //   get(
      //     parsedApplicationDetails,
      //     'additionalDetails.payorSource',
      //     newPayorSource,
      //   ),
      // ) &&
      // !isEmpty(
      //   get(
      //     parsedApplicationDetails,
      //     'additionalDetails.payorSource.originOfWealth',
      //     newPayorSource,
      //   ),
      // )
      //   ? get(
      //       parsedApplicationDetails,
      //       'additionalDetails.payorSource',
      //       newPayorSource,
      //     )
      //   : newPayorSource,
      get(parsedApplicationDetails, 'additionalDetails.payorSource', [
        { ...newPayorSource },
      ]).length > 0
        ? get(parsedApplicationDetails, 'additionalDetails.payorSource', [
          { ...newPayorSource },
        ])
        : [{ ...newPayorSource }],

    payorFunds: get(
      parsedApplicationDetails,
      'additionalDetails.payorFunds',
      {},
    ),
  };

  const initialValuesPayorBankAccount = {
    productCode: parsedApplicationDetails.productCode,

    bankAccountDetail: get(
      parsedApplicationDetails,
      'payorDetails.bankAccountDetail',
      newBankAccount,
    ),

    isOtherBankAccount: get(
      parsedApplicationDetails,
      'payorDetails.isOtherBankAccount',
    ),

    otherBankAccountDetails:
      get(parsedApplicationDetails, 'payorDetails.otherBankAccountDetails', [
        { ...newBankAccount },
      ]).length > 0
        ? get(
          parsedApplicationDetails,
          'payorDetails.otherBankAccountDetails',
          [{ ...newBankAccount }],
        )
        : [{ ...newBankAccount }],

    isExistingZurichPlan: get(
      parsedApplicationDetails,
      'payorDetails.isExistingZurichPlan',
    ),

    isOtherParties: get(
      parsedApplicationDetails,
      'payorDetails.isOtherParties',
    ),

    isPaymentDetails: get(
      parsedApplicationDetails,
      'payorDetails.isPaymentDetails',
    ),

    isLifeOffices: get(parsedApplicationDetails, 'payorDetails.isLifeOffices'),

    otherPartiesDetails: get(
      parsedApplicationDetails,
      'payorDetails.otherPartiesDetails',
      newOtherPartiesDetails,
    ),

    existingZurichPlanDetails: get(
      parsedApplicationDetails,
      'payorDetails.existingZurichPlanDetails',
      newExistingZurichPlanDetails,
    ),

    lifeOfficesDetails: get(
      parsedApplicationDetails,
      'payorDetails.lifeOfficesDetails',
      newLifeOfficesDetails,
    ),
  };

  const initialValuesInvestment = {
    FundList: map(get(parsedApplicationDetails, 'funds.FundList', []), fund => {
      // removed decimal points
      return {
        ...fund,
        percent: fund.percent?.split('.')[0],
      };
    }),
    ASPFundList: map(get(parsedApplicationDetails, 'funds.ASPFundList', []), fund => {
      // removed decimal points
      return {
        ...fund,
        percent: fund.percent?.split('.')[0],
      };
    }),
    isAsp: parsedApplicationDetails.productConfigs?.singlePremium > 0,
    isInforce: isInforce
  };

  // map and return all email of party
  const consentParties = () => {
    const appDetails = parsedApplicationDetails;
    let emailPO = {};
    let emailPO2 = {};
    let emailLI1 = {};
    let emailLI2 = {};
    if (isInforce) {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(appDetails.parties) &&
        appDetails.parties.forEach(item => {
          // PO = LI
          // if PO
          if (
            item.partyapplicationrelationships.isPolicyOwner &&
            item.partyapplicationrelationships.isLifeInsured
          ) {
            // check if PO1
            if (item.partyapplicationrelationships.isPrimaryPolicyOwner) {
              emailPO = {
                email: item.email,
                id: item.id,
              };
            } else {
              emailPO2 = {
                email: item.email,
                id: item.id,
              };
            }
          }
          // PO != LI AND UW = true
          if (
            item.partyapplicationrelationships.isPolicyOwner &&
            !item.partyapplicationrelationships.isLifeInsured &&
            availableStatuses.includes('UNDERWRITING_COMPLETED')
          ) {
            if (item.partyapplicationrelationships.isPrimaryPolicyOwner) {
              emailPO = {
                email: item.email,
                id: item.id,
              };
            } else {
              emailPO2 = {
                email: item.email,
                id: item.id,
              };
            }

            // PO != LI AND UW = false AND is LI
          } else if (
            !item.partyapplicationrelationships.isPolicyOwner &&
            item.partyapplicationrelationships.isLifeInsured &&
            availableStatuses.includes('UNDERWRITING_COMPLETED')
          ) {
            if (
              parsedApplicationDetails?.inforceComparerChecks?.isNewLifeAdded
            ) {
              emailLI1 = {
                email: item?.email,
                id: item?.id,
              };
              emailLI2 = {
                email: item?.email,
                id: item?.id,
              };
            } else if (
              (parsedApplicationDetails?.inforceComparerChecks
                ?.isPrimaryCoverageChanged ||
                parsedApplicationDetails?.inforceComparerChecks
                  ?.isPrimaryLifeSmokerChanged) &&
              emailPO?.id !== item.id &&
              item.partyapplicationrelationships.isPrimaryLifeInsured
            ) {
              emailLI1 = {
                email: item?.email,
                id: item?.id,
              };
            } else if (
              (parsedApplicationDetails?.inforceComparerChecks
                ?.isSecondaryLifeSmokerChanged ||
                parsedApplicationDetails?.inforceComparerChecks
                  ?.isSecondaryCoverageChanged) &&
              emailPO2.id !== item.id
            ) {
              emailLI2 = {
                email: item?.email,
                id: item?.id,
              };
            }

            // PO != LI AND UW = false
          } else if (
            item.partyapplicationrelationships.isPolicyOwner &&
            !item.partyapplicationrelationships.isLifeInsured &&
            !availableStatuses.includes('UNDERWRITING_COMPLETED')
          ) {
            if (item.partyapplicationrelationships.isPrimaryPolicyOwner) {
              emailPO = {
                email: item.email,
                id: item.id,
              };
            } else if (item.partyapplicationrelationships.isPolicyOwner) {
              emailPO2 = {
                email: item.email,
                id: item.id,
              };
            }
          }
        });
    } else {
      // eslint-disable-next-line no-unused-expressions
      !isEmpty(appDetails.parties) &&
        appDetails.parties.map(item => {
          if (item.partyapplicationrelationships.isPrimaryLifeInsured) {
            if (item?.partyapplicationrelationships?.additionalDetails?.relationshipToLifeInsured !== 'CHILD')
              emailLI1 = {
                email: item.email,
                id: item.id,
              };
          } else if (item.partyapplicationrelationships.isLifeInsured) {
            if (item?.partyapplicationrelationships?.additionalDetails?.relationshipToLifeInsured !== 'CHILD')
              emailLI2 = {
                email: item.email,
                id: item.id,
              };
          } else if (item.partyapplicationrelationships.isPrimaryPolicyOwner) {
            emailPO = {
              email: item.email,
              id: item.id,
            };
          } else if (item.partyapplicationrelationships.isPolicyOwner) {
            emailPO2 = {
              email: item.email,
              id: item.id,
            };
          }
        });
    }
    return {
      emailPO,
      emailPO2,
      emailLI1,
      emailLI2,
    };
  };

  const initialValuesConsent = {
    partyDetails: {
      ...consentParties(),
      isAccept: false,
      haveFiaCharged: false,
    },
  };

  // get the parties that has document requirements
  const applicationDetailsWithRequiredDocumentsParties = () => {
    const appDetails = parsedApplicationDetails;
    parsedApplicationDetails.parties = filter(
      parsedApplicationDetails.parties,
      party =>
        party?.documentRequirements && party?.documentRequirements.length > 0,
    ).sort(function (x, y) {
      // SORT who is PO1
      return (
        y.partyapplicationrelationships?.isPrimaryPolicyOwner -
        x.partyapplicationrelationships?.isPrimaryPolicyOwner
      );
    });

    return appDetails;
  };

  const initialValuesPaymentMethod = {
    paymentMethod: get(parsedApplicationDetails, 'paymentMethod', ''),
  };

  const payer =
    parsedApplicationDetails.parties &&
    parsedApplicationDetails.parties.find(
      item => item.partyapplicationrelationships.isPayor,
    );

  const isExistingPaymentToggle =
    isInforce && !counting
      ? <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography variant="body2" style={{ marginRight: '3rem' }}>
            {<FormattedMessage {...messages.preferPaymentMethod} />}
          </Typography>

          <Field
            name={`resetDetails`}
            type="radio"
            component={FormRadioGroup}
            normalize={value => value === 'true'}
            value={continuing}
            onBlur={e => {
              e.preventDefault();
            }}
          >
            <FormControlLabel
              control={
                <CustomRadio
                  onClick={() => { setContinuing(true) }}
                />
              }
              value={true}
              label="Yes"
              labelPlacement="end"
            />
            <FormControlLabel
              control={
                <CustomRadio
                  onClick={() => { setContinuing(false); setCounting(false) }}
                />
              }
              value={false}
              label="No"
              labelPlacement="end"
            />
          </Field>
        </div>
      </Grid>
      : <Grid></Grid>;

  const paymentDetails =
    isInforce && !reset
      ? get(parsedApplicationDetails, 'payorDetails.bankAccountDetail', {})
      : reset
        ? parsedApplicationDetails.payorDetails?.isPaymentDetails
          ? get(parsedApplicationDetails, 'payorDetails.bankAccountDetail', {})
          : {
            payerDetail: {
              payerEmail: payer?.email,
              payerName: `${payer?.firstName} ${payer?.lastName}`,
              cardCurrency: get(
                parsedApplicationDetails,
                'paymentDetails.cardCurrency',
                '',
              ),
              preferredDateOfCollection: get(
                parsedApplicationDetails,
                'paymentDetails.preferredDateOfCollection',
                '',
              ),
            },
          }
        : parsedApplicationDetails.payorDetails?.isPaymentDetails &&
          paymentType !== 'CREDIT_CARD'
          ? get(parsedApplicationDetails, 'payorDetails.bankAccountDetail', {})
          : paymentType === 'CREDIT_CARD'
            ? {
              ...newCCDetail,
              cardCurrency: get(
                parsedApplicationDetails,
                'paymentDetails.cardCurrency',
                '',
              ),
              preferredDateOfCollection: get(
                parsedApplicationDetails,
                'paymentDetails.preferredDateOfCollection',
                '',
              ),
              payerDetail: {
                payerEmail: payer?.email,
                payerName: `${payer?.firstName} ${payer?.lastName}`,
                cardCurrency: get(
                  parsedApplicationDetails,
                  'paymentDetails.cardCurrency',
                  '',
                ),
                preferredDateOfCollection: get(
                  parsedApplicationDetails,
                  'paymentDetails.preferredDateOfCollection',
                  '',
                ),
              },
            }
            : newBankDetail;

  const initialValuesPaymentDetail = {
    paymentDetails: { ...paymentDetails, isUseCCDirectly: true },
  };

  const getHeaderTitle = () => {
    const currentSubPage = subPageList[subPage - 1];
    if (
      subPage === 2 &&
      productCodeCheck('WAP', parsedApplicationDetails.productCode)
       && // Changes for new product
      !parsedApplicationDetails?.isBolton
    ) {
      return 'Summary';
    }
    if (currentSubPage?.subOrder?.length) {
      const currentSubPageStep = find(
        currentSubPage.subOrder,
        so => so.order === subPageStepper,
      );
      if (subPage === 2) {
        return subPageList[subPage - 1]?.text;
      }
      if (subPage === 5) {
        return `${subPageList[subPage - 1]?.text} method`;
      }
      if(currentSubPageStep.text == "Investment") {
        return investmentText
      }
      if (currentSubPageStep) {
        return currentSubPageStep.text;
      }
    }
    // return currentSubPage?.text;
    return subPageList[subPage - 1]?.text;
  };

  const getSubHeaderTitle = () => {
    if (subPage === 2) {
      if (
        allSubOrderList(subPageList)[subPageStepper - 2]?.text === 'Loading' &&
        !parsedApplicationDetails.uwConsent
      ) {
        return 'Consent';
      }
      if (
        allSubOrderList(subPageList)[subPageStepper - 2]?.text === 'Consent' &&
        parsedApplicationDetails.uwConsent
      ) {
        return 'Loading';
      }
      if (
        allSubOrderList(subPageList)[subPageStepper - 2]?.text === 'Payment'
      ) {
        if (productCodeCheck(6, parsedApplicationDetails.productCode)) {
          return '';
        }
      } else if (
        allSubOrderList(subPageList)[subPageStepper - 2]?.text === 'Loading'
      ) {
        return 'Loading';
      } else if (location.pathname.indexOf('/uw-decision') >= 0) {
        if (
          productCodeCheck(4, parsedApplicationDetails.productCode) &&
          isNotCoverages(parsedApplicationDetails.productConfigs)
        ) {
          return '';
        }
        return 'Decision';
      } else {
        return allSubOrderList(subPageList)[subPageStepper - 2]?.text;
      }
    } else if (location.pathname.indexOf('/payment-method') >= 0) {
      if (paymentType === 'CREDIT_CARD') {
        return 'Credit Card';
      }
      if (paymentType === 'UAE_DIRECT_DEBIT') {
        return 'UAE direct debit';
      }
      if (paymentType === 'BANK_TRANSFER') {
        return 'Bank Transfer';
      }
      if (paymentType === 'CHEQUE') {
        return 'Cheque';
      }
      if (paymentType === 'STANDING_ORDER') {
        return 'Standing Order';
      }
    } else if (location.pathname.indexOf('/payment-preview') >= 0) {
      return 'Review';
    } else {
      return '';
    }
  };

  const mapProductCodeName = (name, ptvVersion) => {
    switch (name) {
      case 'FUTU5':
      case 'FUTU4':
      case 'FUTU2':
      case 'FUTU3':
      case 'CTFUT':
      case 'CTFU2':
      case 'CTFU3':
      case 'SCFUT':
      case 'FUTUR':
        return 'Futura';
      case 'SCFUT':
        return 'My Life Protect';
      case 'ITAS6':
      case 'ITAB2':
      case 'ITAS5':
      case 'ITAS3':
        return 'ITA';
      case 'DTME2':
      case 'DTAME':
        return 'IDTA';
      case 'MFS01':
        return 'Global Choice';
      case 'SAVP3':
        return 'My Future Saver';
      case 'VISP2':
        return 'Vista';
      case 'CTVP2':
        return 'InvestPlus';

      case 'RSP01': {
        if (['RSP01_0001', 'RSP01_0003'].includes(ptvVersion)) {
          return 'Regular Savings Plan';
        }
        return 'Legacy';
      }
      case 'ESP01': {
        if (['ESP01_0001'].includes(ptvVersion)) {
          return 'Education Savings Plan';
        }
        return 'Legacy';
      }
      case 'WAPL2':
      case 'WAPL1':
        return 'Wealth Accumulation Plan';
      case 'SIMW2':
      case 'SIMWE':
        return 'Simple Wealth';
      default:
        break;
    }
  };

  useEffect(() => {
    if (parsedApplicationDetails.isInvalidPWCform) {
      history.push(`/applications/${applicationId}`);
    }
  }, [parsedApplicationDetails.isInvalidPWCform]);

  const { productConfigs, isBolton, productCode } = parsedApplicationDetails;
  const prodConfig = isBolton ? productConfigs[0] : productConfigs;
  return (
    <>
      <Header
        title={getHeaderTitle()}
        subTitle={getSubHeaderTitle()}
        productName={
          prodConfig?.shortName
            ? prodConfig?.shortName
            : mapProductCodeName(
              parsedApplicationDetails.productCode,
              prodConfig?.ptvVersion,
            )
        }
      />
      <FormMainBody theme={theme}>
        <Switch>
          <Route
            path="/applications/:applicationId/form/policy-owner"
            render={routeProps => (
              <PolicyOwner
                isUAEAgency={agentDetails.ISOCountryCode === 'AE'}
                isShowTaxResidence={isShowTaxResidence}
                options={options}
                disabledFields={disabledFields}
                isInforce={isInforce}
                productCode={productCode}
                canSubmit={canSubmit}
                initialValues={{
                  ...filteredApplicationPolicyOwner(parsedApplicationDetails),
                  parties: orderBy(
                    map(
                      filteredApplicationPolicyOwner(parsedApplicationDetails)
                        .parties,
                      party => ({
                        ...party,
                        isDifferentAddress:
                          party.additionalDetails?.isDifferentAddress,
                        isTaxPurposes: party.additionalDetails?.isTaxPurposes,
                        taxPurposes: party.additionalDetails?.taxPurposes
                          ? party.additionalDetails.taxPurposes?.length > 0
                            ? party.additionalDetails?.taxPurposes
                            : [newTaxPurposes]
                          : [newTaxPurposes],
                        secondaryNationality:
                          party.secondaryNationality === null ||
                            party.secondaryNationality === ''
                            ? 'Select an option'
                            : party.secondaryNationality,
                        primaryNationality:
                          party.primaryNationality === null ||
                            party.primaryNationality === ''
                            ? 'Select an option'
                            : party.primaryNationality,
                        countryOfBirth:
                          party.countryOfBirth === null
                            ? 'Select an option'
                            : party.countryOfBirth,
                        primaryCountryDialingCode:
                          party.primaryCountryDialingCode === null
                            ? 'Select'
                            : party.primaryCountryDialingCode,
                        secondaryCountryDialingCode:
                          party.secondaryCountryDialingCode === null ||
                            party.secondaryCountryDialingCode === ''
                            ? 'Select'
                            : party.secondaryCountryDialingCode,
                        country:
                          party.country === null || party.country === ''
                            ? 'Select an option'
                            : party.country,
                        isUAEResidenceVisa:
                          party.additionalDetails?.isUAEResidenceVisa || false,
                        obtainUAEResidence:
                          party.additionalDetails?.obtainUAEResidence || false,
                      }),
                    ),
                    function (pt) {
                      return pt.partyapplicationrelationships
                        .isPrimaryPolicyOwner;
                    },
                    ['desc'], // sorting of policy owner base on isPrimaryPolicyOwner
                  ),
                }}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  // tax purpose
                  const updatedParties = map(data.parties, (party, index) => {
                    if (
                      party.taxPurposes[0]?.countryOfTax ===
                      'Select an option' &&
                      productCodeCheck(1, parsedApplicationDetails.productCode)
                    ) {
                      party.taxPurposes[0].countryOfTax = '';
                    }
                    if (!party.isDifferentAddress && index === 1) {
                      return {
                        ...party,
                        isUsPhoneNumber:
                          isUS(party.primaryCountryDialingCode) ||
                          isUS(party.secondaryCountryDialingCode),
                        addressLine1: data.parties[0].addressLine1,
                        addressLine2: data.parties[0].addressLine2,
                        addressLine3: data.parties[0].addressLine3,
                        city: data.parties[0].city,
                        country: data.parties[0].country,
                        postOfficeBoxCity: data.parties[0].postOfficeBoxCity,
                        postOfficeBoxLine: data.parties[0].postOfficeBoxLine,
                        additionalDetails: {
                          ...party.additionalDetails,
                          isDifferentAddress: party.isDifferentAddress,
                          isTaxPurposes: party.isTaxPurposes,
                          taxPurposes: party.taxPurposes
                            ? party.taxPurposes.length > 0 &&
                              party.taxPurposes[0].taxReferenceNumber
                              ? party.taxPurposes
                              : []
                            : [],
                          isUAEResidenceVisa: party.isUAEResidenceVisa,
                          obtainUAEResidence: party.obtainUAEResidence,
                          areResidenceAnotherCountry: party.isTaxPurposes,
                        },
                        secondaryNationality:
                          party.secondaryNationality === 'Select an option'
                            ? ''
                            : party.secondaryNationality,
                        primaryNationality:
                          party.primaryNationality === 'Select an option'
                            ? ''
                            : party.primaryNationality,
                        secondaryCountryDialingCode:
                          party.secondaryCountryDialingCode === 'Select'
                            ? ''
                            : party.secondaryCountryDialingCode,
                      };
                    }
                    return {
                      ...party,
                      isUsPhoneNumber:
                        isUS(party.primaryCountryDialingCode) ||
                        isUS(party.secondaryCountryDialingCode),
                      additionalDetails: {
                        ...party.additionalDetails,
                        isDifferentAddress: party.isDifferentAddress,
                        isTaxPurposes: party.isTaxPurposes,
                        taxPurposes: party.taxPurposes
                          ? party.taxPurposes.length > 0
                            ? party.taxPurposes
                            : []
                          : [],
                        isUAEResidenceVisa: party.isUAEResidenceVisa,
                        obtainUAEResidence: party.obtainUAEResidence,
                        areResidenceAnotherCountry: party.isTaxPurposes,
                      },
                      secondaryNationality:
                        party.secondaryNationality === 'Select an option'
                          ? ''
                          : party.secondaryNationality,
                      primaryNationality:
                        party.primaryNationality === 'Select an option'
                          ? ''
                          : party.primaryNationality,
                      secondaryCountryDialingCode:
                        party.secondaryCountryDialingCode === 'Select'
                          ? ''
                          : party.secondaryCountryDialingCode,
                    };
                  });

                  updateApplicationParties({
                    parties: updatedParties,
                    applicationId,
                  });

                  if (parsedApplicationDetails.status === 'OPEN') {
                    setTimeout(() => {
                      updateApplicationStatus({
                        statusName: 'isPolicyOwnerCompleted',
                        status: true,
                        applicationId,
                        nextPage: (isInforce || productCodeCheck(8, parsedApplicationDetails.productCode)) ? 'payor' : 'beneficiaries',
                      });
                    }, 1500);
                  }
                }}
                {...routeProps}
              />
            )}
          />

          <Route
            path="/applications/:applicationId/form/beneficiaries"
            render={props => (
              <BeneficiaryContainer
                isInforce={isInforce}
                isWAPAndSCBAgency={ //  IWA new product changes
                  agentDetails.agentNumber === '8910162' &&
                  ((parsedApplicationDetails?.productConfigs?.productCode ===
                    'WAPL2'))
                }
                policyOwners={parsedApplicationDetails?.parties?.filter(
                  party => party.partyapplicationrelationships.isPolicyOwner,
                )}
                canSubmit={canSubmit}
                applicationDetails={parsedApplicationDetails}
                initialValues={initialValuesBeneficiaries}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  const arrSelectOption = [
                    'country',
                    'countryOfBirth',
                    'primaryCountryDialingCode',
                    'primaryNationality',
                    'relationshipToLifeInsured',
                    'secondaryCountryDialingCode',
                    'secondaryNationality',
                  ];
                  data.contingentBeneficiaries.map(item => {
                    arrSelectOption.forEach(arr => {
                      if (
                        item[arr] === 'Select an option' ||
                        item[arr] === 'Select'
                      ) {
                        item[arr] = '';
                      }
                    });
                    if (
                      item.partyapplicationrelationships.additionalDetails
                        .relationshipToLifeInsured === 'Select an option'
                    ) {
                      item.partyapplicationrelationships.additionalDetails.relationshipToLifeInsured =
                        '';
                    }
                    if (
                      item.partyapplicationrelationships.additionalDetails
                        .relationshipToPolicyOwner === 'Select an option'
                    ) {
                      item.partyapplicationrelationships.additionalDetails.relationshipToPolicyOwner =
                        '';
                    }
                    if (
                      item.partyapplicationrelationships.additionalDetails
                        .reasonToPay === 'Select an option'
                    ) {
                      item.partyapplicationrelationships.additionalDetails.reasonToPay =
                        '';
                    }
                    return item;
                  });
                  data.primaryBeneficiaries.map(item => {
                    arrSelectOption.forEach(arr => {
                      if (
                        item[arr] === 'Select an option' ||
                        item[arr] === 'Select'
                      ) {
                        item[arr] = '';
                      }
                    });
                    if (
                      item.partyapplicationrelationships.additionalDetails
                        .relationshipToLifeInsured === 'Select an option'
                    ) {
                      item.partyapplicationrelationships.additionalDetails.relationshipToLifeInsured =
                        '';
                    }
                    if (
                      item.partyapplicationrelationships.additionalDetails
                        .relationshipToPolicyOwner === 'Select an option'
                    ) {
                      item.partyapplicationrelationships.additionalDetails.relationshipToPolicyOwner =
                        '';
                    }
                    if (
                      item.partyapplicationrelationships.additionalDetails
                        .reasonToPay === 'Select an option'
                    ) {
                      item.partyapplicationrelationships.additionalDetails.reasonToPay =
                        '';
                    }
                    return item;
                  });
                  const filteredPolicyOwnersW = filteredPOLI(
                    parsedApplicationDetails,
                  );
                  // if (data.contingentBeneficiaries[0].relationshipToLifeInsured === "Select an option") {
                  //   data.contingentBeneficiaries[0].relationshipToLifeInsured = ''
                  // }
                  let updatedData = data;
                  let updatedPO = [];
                  // CONDITIONAL ASSIGNEE
                  if (!data.appointConditionalAssignee) {
                    data.additionalDetails.conditionalAssignee = [];
                  }

                  // combine all parties
                  let allParties = [];
                  if (data.otherPrimaryBeneficiaries) {
                    allParties = [...allParties, ...data.primaryBeneficiaries];
                    //console.log(allParties, "]]]]]]]]]]]]")
                  }

                  if (data.otherContingentBeneficiaries) {
                    allParties = [
                      ...allParties,
                      ...data.contingentBeneficiaries,
                    ];
                  }

                  // map relationshipToLifeInsured to the additionalDetails object

                  allParties = map(allParties, party => {
                    if (party.relationshipToLifeInsured) {
                      return {
                        ...party,
                        additionalDetails: {
                          selectedAddress: party?.selectedAddress,
                          order: party?.order,
                        },
                        partyapplicationrelationships: {
                          ...party.partyapplicationrelationships,
                          additionalDetails: {
                            ...party.partyapplicationrelationships
                              .additionalDetails,
                            relationshipToLifeInsured:
                              party.relationshipToLifeInsured,
                            sharePercentage: party.sharePercentage,
                          },
                        },
                      };
                    }

                    return party;
                  });

                  // filter out the existing one
                  const existingParties = filter(allParties, party => party.id);
                  // filter out the new one
                  const newParties = filter(
                    allParties,
                    party =>
                      !party.id &&
                      party.firstName.length > 0 &&
                      party.lastName.length > 0,
                  );

                  let deletedParties = map(
                    filter(
                      differenceWith(
                        parsedApplicationDetails.parties,
                        allParties,
                        isEqual,
                      ),
                      party =>
                        !party.id &&
                        party.firstName.length > 0 &&
                        party.lastName.length > 0,
                    ),
                    party => ({ id: party.id }),
                  );

                  // deleted primary beneficiaries

                  forEach(
                    initialValuesBeneficiaries.primaryBeneficiaries,
                    party => {
                      // return if party.id is not found in the list

                      const foundParty = find(
                        data.primaryBeneficiaries,
                        party2 => party.id === party2.id,
                      );

                      if (!foundParty) {
                        deletedParties.push(party);
                      }
                    },
                  );
                  // delete contingent beneficiaries

                  forEach(
                    initialValuesBeneficiaries.contingentBeneficiaries,
                    party => {
                      // return if party.id is not found in the list

                      const foundParty = find(
                        data.contingentBeneficiaries,
                        party2 => party.id === party2.id,
                      );

                      if (!foundParty) {
                        deletedParties.push(party);
                      }
                    },
                  );

                  // remove all parties
                  if (data.isShariaBeneficiary) {
                    deletedParties = [
                      ...deletedParties,
                      ...map(
                        filter(data.contingentBeneficiaries, party => party.id),
                        party => ({ id: party.id }),
                      ),
                      ...map(
                        filter(data.primaryBeneficiaries, party => party.id),
                        party => ({ id: party.id }),
                      ),
                    ];
                  }

                  // remove contingent parties
                  if (!data.otherContingentBeneficiaries) {
                    deletedParties = [
                      ...deletedParties,
                      ...data.contingentBeneficiaries,
                    ];
                  }

                  // remove primary parties
                  if (!data.otherPrimaryBeneficiaries || data.isBenefit) {
                    deletedParties = [
                      ...deletedParties,
                      ...map(
                        filter(data.primaryBeneficiaries, party => party.id),
                        party => ({ id: party.id }),
                      ),
                    ];
                  }

                  // change the isPolicyOwner users into isBeneficiary = true
                  if (data.isBenefit) {
                    updatedPO = map(filteredPolicyOwnersW, party => ({
                      // ...party,
                      id: party.id,
                      partyapplicationrelationships: {
                        ...party.partyapplicationrelationships,
                        isBeneficiary: true,
                        isPrimaryBeneficiary: true, // added this for nominate together
                      },
                    }));
                  } else {
                    updatedPO = map(filteredPolicyOwnersW, party => ({
                      // ...party,
                      id: party.id,
                      partyapplicationrelationships: {
                        ...party.partyapplicationrelationships,
                        isBeneficiary: false,
                        isPrimaryBeneficiary: false,
                      },
                    }));
                  }

                  // call the api
                  // if isBenefit
                  updatedData = {
                    ...updatedData,
                    additionalDetails: {
                      ...updatedData.additionalDetails,
                      isBenefit: data.isBenefit,
                    },
                  };

                  if (data.appointConditionalAssignee) {
                    const updatedConditionalAssignee = map(
                      get(data, 'additionalDetails.conditionalAssignee', []),
                      cA => ({
                        ...cA,
                        financialInstitution: find(
                          convertIfImmutable(financialInstituteOptions),
                          fi =>
                            `${fi.bankBranchAddTNField1}${fi.bankBranchAddTNField2}` ===
                            cA.institution,
                        ),
                      }),
                    );

                    updatedData = {
                      ...updatedData,
                      additionalDetails: {
                        ...updatedData.additionalDetails,
                        appointConditionalAssignee:
                          data.appointConditionalAssignee,
                        conditionalAssignee: updatedConditionalAssignee,
                      },
                    };
                  } else {
                    updatedData = {
                      ...updatedData,
                      additionalDetails: {
                        ...updatedData.additionalDetails,
                        appointConditionalAssignee: false,
                        conditionalAssignee: [],
                      },
                    };
                  }

                  updateApplicationDetails(updatedData);

                  if (existingParties.length > 0 || updatedPO.length > 0) {
                    updateApplicationParties({
                      parties: [...existingParties, ...updatedPO],
                      applicationId,
                    });
                  }
                  if (newParties.length > 0) {
                    addApplicationParties({
                      parties: newParties,
                      applicationId,
                    });
                  }
                  if (deletedParties.length > 0) {
                    deleteApplicationParties({
                      parties: deletedParties,
                      applicationId,
                    });
                  }

                  if (
                    parsedApplicationDetails.status === 'POLICY_OWNER_COMPLETED'
                  ) {
                    setTimeout(() => {
                      updateApplicationStatus({
                        statusName: 'isBeneficiaryCompleted',
                        status: true,
                        applicationId,
                      });
                    }, 1500);
                  }

                  nextStepHandler('payor');
                }}
                {...props}
              />
            )}
          />

          <Route
            path="/applications/:applicationId/form/payor"
            render={() => (
              <Payor
                disabledFields={disabledFields}
                canSubmit={canSubmit}
                isInforce={isInforce}
                initialValues={initialValuesPayor}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  let inforcePayor = data?.inforcePayor;
                  const arrSelectOption = [
                    'country',
                    'countryOfBirth',
                    'primaryCountryDialingCode',
                    'primaryNationality',
                    'relationshipToLifeInsured',
                    'secondaryCountryDialingCode',
                    'secondaryNationality',
                  ];
                  arrSelectOption.forEach(item => {
                    if (
                      data.thirdPayor[item] === 'Select an option' ||
                      data.thirdPayor[item] === 'Select'
                    ) {
                      data.thirdPayor[item] = '';
                    }
                  });
                  if (
                    data.thirdPayor.relationships.relationshipToPolicyOwner ===
                    'Select an option'
                  ) {
                    data.thirdPayor.relationships.relationshipToPolicyOwner =
                      '';
                  }
                  if (
                    data.thirdPayor.relationships.reasonToPay ===
                    'Select an option'
                  ) {
                    data.thirdPayor.relationships.reasonToPay =
                      '';
                  }
                  if (
                    data.thirdPayor.relationships.relationshipToLifeInsured ===
                    'Select an option'
                  ) {
                    data.thirdPayor.relationships.relationshipToLifeInsured =
                      '';
                  }

                  let anyLifeInsuredSelected = false;
                  let selectedLifeInsuredId = '';
                  // check if any of the lifeInsuredPayors are selected
                  forEach(convertIfImmutable(data.lifeInsuredPayor), party => {
                    if (party.lifeInsuredPersonPayor) {
                      anyLifeInsuredSelected = true;
                      selectedLifeInsuredId = party.id;
                      return false;
                    }
                  });

                  // get the selected life insured payor
                  const lifeInsuredPayor = filter(
                    data.lifeInsuredPayor,
                    party => selectedLifeInsuredId === party.id,
                  )[0];

                  // SELECTED LIFE INSURED PARTY
                  if (data.selectedLifeInsuredParties) {
                    const selectedParty = filter(
                      parsedApplicationDetails.parties,
                      party => party.id === data.selectedLifeInsuredParties,
                    );
                    // changed this to parsedApplicationDetails
                    let unselectedParties = filter(
                      parsedApplicationDetails.parties,
                      party => party.id !== data.selectedLifeInsuredParties,
                    );
                    // change the selectedParty = true
                    selectedParty[0].partyapplicationrelationships.isPayor = true;
                    // change the other parties = false
                    unselectedParties = map(unselectedParties, party => ({
                      ...party,
                      partyapplicationrelationships: {
                        ...party.partyapplicationrelationships,
                        isPayor: false,
                      },
                    }));

                    // delete thirdPersonPayor if it exist
                    if (data.thirdPayor) {
                      if (data.thirdPayor.id) {
                        deleteApplicationParty({
                          party: data.thirdPayor,
                          applicationId,
                        });
                      }
                    }

                    // delete thirdPersonPayor if it exist
                    if (data.companyPayor) {
                      if (data.companyPayor.id) {
                        deleteApplicationParty({
                          party: data.companyPayor,
                          applicationId,
                        });
                      }
                    }

                    // delete lifeInsuredPayor if it exist
                    if (data.lifeInsuredPayor) {
                      if (data.lifeInsuredPayor.id) {
                        deleteApplicationParty({
                          party: data.lifeInsuredPayor,
                          applicationId,
                        });
                      }
                    }
                    // delete lifeInsuredPayor if it exist
                    if (data.lifeInsuredPayor) {
                      if (data.lifeInsuredPayor.id) {
                        deleteApplicationParty({
                          party: data.lifeInsuredPayor.id,
                          applicationId,
                        });
                      }
                    }

                    // combine all parties
                    const allParties = isInforce
                      ? [
                        ...unselectedParties,
                        inforcePayor.id && { ...inforcePayor },
                      ] //if payor is existing
                      : [...unselectedParties, ...selectedParty];

                    //if empty just add it
                    if (isEmpty(inforcePayor?.id)) {
                      addApplicationParty({
                        party: inforcePayor,
                        applicationId,
                      });
                    }

                    // call the api
                    // make sure update party after delete third party
                    setTimeout(() => {
                      updateApplicationParties({
                        parties: filter(
                          allParties,
                          p => p.id !== data.thirdPayor.id, // don't update non existent third party
                        ),
                        applicationId,
                      });
                    }, 1000);

                    // delete companyPayor if it exist
                    if (!isEmpty(data.companyPayor)) {
                      var updatedApplicationDetails = parsedApplicationDetails;

                      updatedApplicationDetails.payorDetails = {
                        ...updatedApplicationDetails.payorDetails,
                        companyDetails: {},
                      };

                      updateApplicationDetails(updatedApplicationDetails);
                    }
                    // if thirdPersonPayor is selected
                  } else if (data.thirdPersonPayor || data.isCompanyPayor) {
                    const thirdPartyPayor = isInforce
                      ? inforcePayor
                      : data.thirdPersonPayor
                        ? data.thirdPayor
                        : data.companyPayor;
                    // move relationships to partyapplicationrelationships.additionalDetails
                    thirdPartyPayor.partyapplicationrelationships.additionalDetails = {
                      ...thirdPartyPayor?.partyapplicationrelationships
                        ?.additionalDetails,
                      ...thirdPartyPayor?.relationships,
                      isCompanyPayor: data.isCompanyPayor,
                    };
                    if (thirdPartyPayor.partyapplicationrelationships.additionalDetails.reasonToPay != "OTHERS") {
                      thirdPartyPayor.partyapplicationrelationships.additionalDetails.other = "";
                    }
                    // add selectedAddress to addtionalDetails
                    thirdPartyPayor.additionalDetails = {
                      ...data.thirdPayor.additionalDetails,
                      selectedAddress: data.thirdPayor.selectedAddress,
                    };

                    // delete thirdPayorPerson

                    if (data.isCompanyPayor) {
                      if (data.thirdPayor.id) {
                        deleteApplicationParty({
                          party: data.thirdPayor,
                          applicationId,
                        });
                      }
                    }

                    if (data.thirdPersonPayor) {
                      if (data.companyPayor.id) {
                        deleteApplicationParty({
                          party: data.companyPayor,
                          applicationId,
                        });
                      }
                    }

                    if (thirdPartyPayor.id) {
                      // update all parties except the selected payor isPayor = false
                      let unselectedParties = filter(
                        data.parties,
                        party => party.id !== thirdPartyPayor.id,
                      );
                      // change the other parties = false
                      unselectedParties = map(unselectedParties, party => ({
                        ...party,
                        partyapplicationrelationships: {
                          ...party.partyapplicationrelationships,
                          isPayor: false,
                        },
                      }));

                      // delete lifeInsuredPayor if it exist
                      if (data.lifeInsuredPayor) {
                        if (data.lifeInsuredPayor.id) {
                          deleteApplicationParty({
                            party: data.lifeInsuredPayor,
                            applicationId,
                          });
                        }
                      }

                      // call the api

                      updateApplicationParties({
                        parties: [...unselectedParties, thirdPartyPayor],
                        applicationId,
                      });

                      // delete companyPayor if it exist
                      // if (!isEmpty(data.companyPayor) && !data.isCompanyPayor) {
                      //   const updatedApplicationDetails = parsedApplicationDetails;

                      //   updatedApplicationDetails.payorDetails = {
                      //     ...updatedApplicationDetails.payorDetails,
                      //     companyDetails: {},
                      //   };

                      //   updateApplicationDetails(updatedApplicationDetails);
                      // }

                      // update PayorDetails object
                      const updatedApplicationDetails = parsedApplicationDetails;

                      updatedApplicationDetails.payorDetails = {
                        ...updatedApplicationDetails.payorDetails,
                        partyId: thirdPartyPayor.id,
                      };

                      updateApplicationDetails(updatedApplicationDetails);
                    } else {
                      const nonPayor = map(data.parties, party => ({
                        ...party,
                        partyapplicationrelationships: {
                          ...party.partyapplicationrelationships,
                          isPayor: false,
                        },
                      }));
                      // call the api
                      updateApplicationParties({
                        parties: nonPayor,
                        applicationId,
                      });
                      addApplicationParty({
                        party: thirdPartyPayor,
                        applicationId,
                      });
                    }
                  } else if (anyLifeInsuredSelected) {
                    // move relationships to partyapplicationrelationships.additionalDetails
                    lifeInsuredPayor.partyapplicationrelationships = {
                      ...lifeInsuredPayor.partyapplicationrelationships,
                      // payor flag
                      isPayor: true,
                    };
                    lifeInsuredPayor.partyapplicationrelationships.additionalDetails = {
                      ...lifeInsuredPayor.partyapplicationrelationships
                        .additionalDetails,
                      ...lifeInsuredPayor.relationships,
                      // flag of selected
                      lifeInsuredPersonPayor:
                        lifeInsuredPayor.lifeInsuredPersonPayor,
                    };

                    lifeInsuredPayor.additionalDetails = {
                      ...lifeInsuredPayor.additionalDetails,
                      selectedAddress: lifeInsuredPayor.selectedAddress,
                    };

                    if (lifeInsuredPayor.id) {
                      // update all parties except the thirdPayor isPayor = false
                      var unselectedParties = filter(
                        data.parties,
                        party => party.id !== lifeInsuredPayor.id,
                      );

                      // change the other parties = false
                      unselectedParties = map(unselectedParties, party => ({
                        ...party,
                        partyapplicationrelationships: {
                          ...party.partyapplicationrelationships,
                          isPayor: false,
                        },
                      }));
                      // call the api
                      updateApplicationParties({
                        parties: [...unselectedParties, lifeInsuredPayor],
                        applicationId,
                      });
                    } else {
                      var nonPayor = map(data.parties, party => ({
                        ...party,
                        partyapplicationrelationships: {
                          ...party.partyapplicationrelationships,
                          isPayor: false,
                        },
                      }));

                      // delete thirdPersonPayor if it exist
                      if (data.thirdPayor) {
                        if (data.thirdPayor.id) {
                          deleteApplicationParty({
                            party: data.thirdPayor,
                            applicationId,
                          });
                        }
                      }

                      // delete thirdPersonPayor if it exist
                      if (data.companyPayor) {
                        if (data.companyPayor.id) {
                          deleteApplicationParty({
                            party: data.companyPayor,
                            applicationId,
                          });
                        }
                      }

                      // call the api
                      updateApplicationParties({
                        parties: nonPayor,
                        applicationId,
                      });
                      addApplicationParty({
                        party: lifeInsuredPayor,
                        applicationId,
                      });

                      // delete companyPayor if it exist
                      if (!isEmpty(data.companyPayor)) {
                        var updatedApplicationDetails = parsedApplicationDetails;

                        updatedApplicationDetails.payorDetails = {
                          ...updatedApplicationDetails.payorDetails,
                          companyDetails: {},
                        };

                        updateApplicationDetails(updatedApplicationDetails);
                      }
                    }
                  }

                  const statusCompanyPayerFlow =
                    lifeInsuredOnly.length ||
                      productCodeCheck(3, parsedApplicationDetails.productCode) >
                      0
                      ? 'isPayorBankCompleted'
                      : 'isPolicyDetailsCompleted';
                  updateApplicationStatus({
                    // statusName: data.isCompanyPayor
                    //   ? statusCompanyPayerFlow
                    //   : 'isPayorCompleted',
                    statusName: data.isCompanyPayor || data.thirdPayor ? 'isPayorCompleted' : statusCompanyPayerFlow,
                    status: true,
                    applicationId,
                    nextPage: 'payor-salary',
                    isDelay: data.isCompanyPayor,
                  });

                  // nextStepHandler('payor-salary');
                }}
                applicationDetails={applicationDetails}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/payor-salary"
            render={() => (
              <PayorSalary
                canSubmit={canSubmit}
                initialValues={initialValuesPayorSalary}
                isCompanyPayor={isCompanyPayor}
                lifeInsuredOnly={lifeInsuredOnly}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  const arrSelectOption = [
                    'currency',
                    'relationshipToApplicant',
                    'saleCurrency',
                    'originOfWealth',
                  ];
                  data.payorProceeds.map(item => {
                    arrSelectOption.forEach(arr => {
                      if (
                        item[arr] === 'Select an option' ||
                        item[arr] === 'Select'
                      ) {
                        item[arr] = '';
                      }
                    });
                    return item;
                  });
                  data.payorSource.map(item => {
                    arrSelectOption.forEach(arr => {
                      if (
                        item[arr] === 'Select an option' ||
                        item[arr] === 'Select'
                      ) {
                        item[arr] = '';
                      }
                    });
                    return item;
                  });

                  arrSelectOption.forEach(item => {
                    if (
                      data.payorSource[item] === 'Select an option' ||
                      data.payorSource[item] === 'Select'
                    ) {
                      data.payorSource[item] = '';
                    }
                  });
                  if (data.payorDetails.paymentCurrency === 'Select') {
                    data.payorDetails.paymentCurrency = '';
                  }
                  if (data.payorSource.originOfWealth === 'Select') {
                    data.payorSource.originOfWealth = '';
                  }

                  const updatedApplicationDetails = {
                    ...parsedApplicationDetails,
                    payorDetails: {

                      ...parsedApplicationDetails.payorDetails,
                      ...data.payorDetails,
                      isPolicyPaidBySalary: data.isPolicyPaidBySalary,
                      isPolicyPaidBySalary2: data.isPolicyPaidBySalary2,
                      ...data.policyPaidBySalary,
                      isArmyService: data.isArmyService,
                      nameOfJobTitle: data.nameOfJobTitle,
                      // isPolicyPaidBySalary: data.isPolicyPaidBySalary,

                    },
                    additionalDetails: {
                      ...parsedApplicationDetails.additionalDetails,
                      // PAYOR PROCEEDS
                      isPayorProceeds: data.isPayorProceeds,
                      payorProceeds: data.isPayorProceeds
                        ? data.payorProceeds
                        : [],
                      // PAYOR SOURCE
                      isPayorSource: data.isPayorSource,
                      payorSource: data.isPayorSource ? data.payorSource : {},
                      // PAYOR FUNDS
                      payorFunds: data.payorFunds ? data.payorFunds : {},
                      // POLICYPAIDBYSALARY

                      // policyPaidBySalary: data.policyPaidBySalary
                      //   ? data.policyPaidBySalary
                      //   : {},
                    },
                    id: applicationId
                  };

                  //  to solve async issue of update application and update status API call
                  const updateStatusDetails = {
                    statusName: 'isOowCompleted',
                    status: true,
                    applicationId,
                    nextPage: 'payor-bank',
                  }

                  updateApplicationDetailsAndStatus({
                    updatedApplicationDetails: updatedApplicationDetails,
                    updateStatusDetails: updateStatusDetails
                  });
                }}
                // End of fix async issue of update application and update status API call

                // updateApplicationDetails(updatedApplicationDetails);

                // setTimeout(() => {
                //   updateApplicationStatus({
                //     statusName: 'isOowCompleted',
                //     status: true,
                //     applicationId,
                //     nextPage: 'payor-bank',
                //   });
                // }, 1500);

                // nextStepHandler('payor-bank');
                //}}
                ISOCode={agentDetails.ISOCountryCode}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/payor-bank"
            render={() => (
              <PayorBank
                canSubmit={canSubmit}
                isInforce={isInforce}
                applicationDetails={parsedApplicationDetails}
                initialValues={initialValuesPayorBankAccount}
                isCompanyPayor={isCompanyPayor}
                lifeInsuredOnly={lifeInsuredOnly}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  data.otherBankAccountDetails.map(item => {
                    if (item.bankName === 'Select an option') {
                      item.bankName = '';
                    }
                    return item;
                  });
                  if (data.bankAccountDetail.bankName === 'Select an option') {
                    data.bankAccountDetail.bankName = '';
                  }
                  const updatedApplicationDetails = {
                    ...parsedApplicationDetails,
                    payorDetails: {
                      ...parsedApplicationDetails.payorDetails,
                      bankAccountDetail: {
                        ...data.bankAccountDetail,
                        financialInstitute: find(
                          convertIfImmutable(financialInstituteOptions),
                          fi =>
                            `${fi.bankBranchAddTNField1}${fi.bankBranchAddTNField2}` ===
                            get(data, 'bankAccountDetail.bankName'),
                        ),
                      },
                      isOtherBankAccount: data.isOtherBankAccount,
                      isPaymentDetails: data.isPaymentDetails,
                      otherBankAccountDetails: data.isOtherBankAccount
                        ? data.otherBankAccountDetails.map(item => ({
                          ...item,
                          financialInstitute: find(
                            convertIfImmutable(financialInstituteOptions),
                            fi =>
                              `${fi.bankBranchAddTNField1}${fi.bankBranchAddTNField2}` ===
                              item.bankName,
                          ),
                        }))
                        : [],
                      isOtherParties: data.isOtherParties,
                      otherPartiesDetails: data.isOtherParties
                        ? data.otherPartiesDetails
                        : {},
                      isExistingZurichPlan: data.isExistingZurichPlan,
                      existingZurichPlanDetails: data.isExistingZurichPlan
                        ? data.existingZurichPlanDetails
                        : {},
                      lifeOfficesDetails: data.isLifeOffices
                        ? data.lifeOfficesDetails
                        : {},
                      isLifeOffices: data.isLifeOffices,
                    },
                  };
                  updateApplicationDetails(updatedApplicationDetails);

                  if (lifeInsuredOnly.length > 0) {
                    setTimeout(() => {
                      updateApplicationStatus({
                        statusName: 'isPayorBankCompleted',
                        status: true,
                        applicationId,
                      });
                    }, 2000);
                  } else if (
                    productCodeCheck(3, parsedApplicationDetails.productCode)
                  ) {
                    if (parsedApplicationDetails.status === 'OOW_COMPLETED') {
                      setTimeout(() => {
                        updateApplicationStatus({
                          statusName: 'isPayorBankCompleted',
                          status: true,
                          applicationId,
                          nextPage: 'investment',
                        });
                      }, 1500);
                    }

                    // nextStepHandler('investment');
                  } else {
                    setTimeout(() => {
                      updateApplicationStatus({
                        statusName: 'isPolicyDetailsCompleted',
                        status: true,
                        applicationId,
                      });
                    }, 1500);
                  }
                }}
                ISOCode={agentDetails.ISOCountryCode}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/lifeinsured"
            render={routeProps => (
              <LifeInsured
                disabledFields={disabledFields}
                isInforce={isInforce}
                applicationDetails={parsedApplicationDetails}
                initialValues={{
                  lifeInsured: map(lifeInsuredOnly, party => ({
                    ...party,
                    selectedAddress: get(
                      party,
                      'additionalDetails.selectedAddress',
                      'different',
                    ),
                    relationshipToPolicyOwner:
                      party.partyapplicationrelationships?.additionalDetails
                        ?.relationshipToPolicyOwner,
                    reasonToPay:
                      party.partyapplicationrelationships?.additionalDetails
                        ?.reasonToPay,
                  })),
                  productCode: applicationDetails.toJS().productCode,
                }}
                lifeInsuredOnly={lifeInsuredOnly}
                policyOwners={filteredPO(parsedApplicationDetails)}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  const lifeInsuredData = data.lifeInsured;

                  const updatedPartyDetails = map(lifeInsuredData, party => ({
                    ...party,
                    additionalDetails: {
                      ...party.additionalDetails,
                      selectedAddress: party?.selectedAddress,
                    },
                    partyapplicationrelationships: {
                      ...party?.partyapplicationrelationships,
                      additionalDetails: {
                        ...party.partyapplicationrelationships
                          ?.additionalDetails,
                        relationshipToPolicyOwner:
                          party?.relationshipToPolicyOwner,
                        reasonToPay:
                          party?.reasonToPay,
                      },
                    },
                  }));

                  updateApplicationParties({
                    parties: updatedPartyDetails,
                    applicationId,
                  });

                  setTimeout(() => {
                    // update applicationStatus

                    updateApplicationStatus({
                      statusName: 'isLifeInsuredCompleted',
                      status: true,
                      applicationId,
                    });
                  }, 1500);
                }}
                {...routeProps}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/investment"
            render={() => (
              <Investment
                isInforce={isInforce}
                applicationDetails={applicationDetails}
                initialValues={initialValuesInvestment}
                applicationId={applicationId}
                updateApplicationFunds2={updateApplicationFunds2}
                fundsBelongToNexusOrDever={fundsBelongToNexusOrDever}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  if (data.isAsp && isInforce) {
                    updateApplicationStatus({
                    statusName: 'isInvestmentCompleted',
                    status: true,
                    applicationId,
                    filteredFundList: data
                  });
                } else {                  
                  updateApplicationStatus({
                    statusName: 'isInvestmentCompleted',
                    status: true,
                    applicationId,
                    filteredFundList: data?.FundList,
                  });
                } 
                }}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/uw-consent"
            render={routeProps => (
              <UWConsent
                initialValues={{
                  uwConsent: parsedApplicationDetails.uwConsent,
                }}
                parsedApplicationDetails={parsedApplicationDetails}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();

                  const updatedApplicationDetails = {
                    ...parsedApplicationDetails,
                    uwConsent: data.uwConsent,
                  };
                  updateApplicationDetails(updatedApplicationDetails);

                  // nextStepHandler('uw-section');
                }}
                {...routeProps}
                canSubmit={canSubmit}
                canGoBack={canGoBack}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/uw-section"
            render={routeProps =>
              currentUWSection?.text && ( // this checking is required to wait for the subPage setting to complete
                <SectionForm
                  initialValues={answers[currentUWSection.text]}
                  key={currentUWSection.order}
                  form={currentUWSection.text}
                  onSubmit={() => {
                    if (
                      !convertIfImmutable(underwritingSections)[
                      subPageStepper + 1 - ADJUSTED_POLICY_SUBSTEPPERS
                      ]
                    ) {
                      nextStepHandler('uw-submit');
                    } else {
                      nextStepHandler();
                    }
                  }}
                  applicationDetails={parsedApplicationDetails}
                  getInterviewSessionId={getInterviewSessionId}
                  section={currentUWSection.text}
                  sectionNumber={currentUWSection.id}
                  sessionId={currentUWSection.sessionId}
                  eTag={currentUWSection.eTag}
                  partyId={currentUWSection.partyId}
                  order={currentUWSection.order}
                  {...routeProps}
                  canSubmit={canSubmit}
                  canGoBack={canGoBack}
                  subPageStepper={subPageStepper}
                />
              )
            }
          />
          <Route
            path="/applications/:applicationId/form/uw-submit"
            render={routeProps => (
              <Submit
                form="SubmitForm"
                openDialog={open}
                setOpenDialog={setOpen}
                applicationDetails={parsedApplicationDetails}
                submitDialog={submitDialog}
                lifeInsured={filteredLifeInsured(parsedApplicationDetails)}
                onSubmit={() => {
                  submitUW({ applicationId, history });
                  // nextStepHandler('uw-decision');
                }}
                canSubmit={canSubmit}
                {...routeProps}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/uw-decision"
            render={routeProps => (
              <Decision
                canSubmit={canSubmit}
                lifeInsured={filteredLifeInsured(parsedApplicationDetails)}
                canGoBack={canGoBack}
                onSubmit={() => {
                  setTimeout(() => {
                    // update applicationStatus
                    updateApplicationStatus({
                      statusName: 'isUwDecisionCompleted',
                      status: true,
                      applicationId,
                    });
                  }, 1500);
                  // nextStepHandler('documents'); // put the next url path
                }}
                {...routeProps}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/documents"
            render={routeProps => (
              <DocumentForm
                initialValues={applicationDetailsWithRequiredDocumentsParties()}
                isWAPAndSCBAgency={ //  IWA new product changes
                  agentDetails.agentNumber === '8910162' &&
                  ((parsedApplicationDetails?.productConfigs?.productCode ===
                    'WAPL2'))
                }

                getApplicationDetails={getApplicationDetails}
                canSubmit={canSubmit}
                canGoBack={canGoBack}
                form="DocumentForm"
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  updateApplicationParties({
                    parties: data.parties,
                    applicationId,
                  });
                  updateApplicationDetails(data);

                  setTimeout(() => {
                    // update applicationStatus
                    updateApplicationStatus({
                      statusName: 'isVerifiedDocCompleted',
                      status: true,
                      applicationId,
                    });
                  }, 1500);
                  // nextStepHandler('consent');
                }}
                parsedApplicationDetails={parsedApplicationDetails}
                {...routeProps}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/consent"
            render={routeProps => (
              <Consent
                canGoBack={canGoBack}
                canSubmit={canSubmit}
                updateApplicationDetails={updateApplicationDetails}
                initialValues={initialValuesConsent}
                onSubmit={() => {
                  if (isInforce) {
                    nextStepHandler('payment');
                  } else {
                    nextStepHandler('payment-preview');
                  }
                }}
                {...routeProps}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/payment"
            render={() => (
              <Payment
                canGoBack={canGoBack}
                initialValues={initialValuesPaymentMethod}
                canSubmit={canSubmit}
                onSubmit={() => {
                  nextStepHandler('payment-method'); // put the next url path name
                }}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/payment-method"
            render={routeProps => (
              <PaymentMethod
                setOpenDialogPayment={setOpenDialogPayment}
                isInforce={isInforce}
                openDialogPayment={openDialogPayment}
                initialValues={initialValuesPaymentDetail}
                canSubmit={canSubmit}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  const { paymentDetails } = data;
                  let paymentData = {};
                  if (paymentType === 'CREDIT_CARD') {
                    if (isInforce) {
                      paymentData = {
                        card_holder_name: data.paymentDetails.card_holder_name,
                        cardCurrency: data.paymentDetails.cardCurrency,
                        preferredDateOfCollection:
                          data.paymentDetails.preferredDateOfCollection,
                        card_number: data.paymentDetails.card_number,
                        card_security_code:
                          data.paymentDetails.card_security_code,
                        expiry_date: data.paymentDetails.expiry_date,
                      };
                    } else {
                      paymentData = {
                        // card_holder_name: data.paymentDetails.card_holder_name,
                        cardCurrency: data.paymentDetails.cardCurrency,
                        preferredDateOfCollection:
                          data.paymentDetails.preferredDateOfCollection,
                        // card_number: data.paymentDetails.card_number,
                        // card_security_code:
                        //   data.paymentDetails.card_security_code,
                        // expiry_date: data.paymentDetails.expiry_date,
                      };
                    }
                  }
                  if (paymentType === 'UAE_DIRECT_DEBIT') {
                    paymentData = {
                      accountHolderName: paymentDetails.accountHolderName,
                      cardNumber: paymentDetails.cardNumber,
                      bankName:
                        paymentDetails.directDebitSource === 'CREDIT_CARD'
                          ? paymentDetails.bankName
                          : '',
                      financialInstitute: find(
                        convertIfImmutable(financialInstituteOptions),
                        fi =>
                          `${fi.bankBranchAddTNField1}${fi.bankBranchAddTNField2}` ===
                          paymentDetails.bankName,
                      ),
                      directDebitSetter: paymentDetails.directDebitSetter,
                      directDebitSource: paymentDetails.directDebitSource,
                      expiry_date: paymentDetails.expiry_date,
                      iban:
                        paymentDetails.directDebitSource === 'CURRENT_ACCOUNT'
                          ? paymentDetails.iban
                          : '',
                    };
                  }
                  if (
                    paymentType === 'CHEQUE' ||
                    paymentType === 'BANK_TRANSFER' ||
                    paymentType === 'STANDING_ORDER'
                  ) {
                    paymentData = {
                      accountHolderName: paymentDetails.accountHolderName,
                      cardNumber: paymentDetails.cardNumber,
                      bankName: paymentDetails.bankName,
                      financialInstitute: find(
                        convertIfImmutable(financialInstituteOptions),
                        fi =>
                          `${fi.bankBranchAddTNField1}${fi.bankBranchAddTNField2}` ===
                          paymentDetails.bankName,
                      ),
                    };
                  }

                  const updatedApplicationDetails = {
                    ...parsedApplicationDetails,
                    paymentDetails: paymentData,
                    paymentMethod: paymentType,
                  };
                  if (
                    (paymentType !== 'CREDIT_CARD' &&
                      parsedApplicationDetails.paymentStatus !== 'COMPLETED') ||
                    paymentType !== 'CREDIT_CARD'
                  ) {
                    updateApplicationDetails(updatedApplicationDetails);
                  }
                  if (paymentType !== 'CREDIT_CARD') {
                    updatePaymentDetail(paymentData);
                    nextStepHandler('payment-preview'); // put the next url path name
                  } else if (
                    !isEmpty(data) &&
                    parsedApplicationDetails.paymentStatus !== 'COMPLETED'
                  ) {
                    if (isInforce) {
                      // submit credit card details to BE
                      updateApplicationDetails(updatedApplicationDetails);
                      updatePaymentDetail(paymentData);
                      nextStepHandler('payment-preview'); // put the next url path name
                    } else {
                      submitCreditCard({
                        paymentDetails: data.paymentDetails,
                        applicationId,
                      });
                    }
                  }
                }}
                {...routeProps}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/payment-preview"
            render={() => (
              <PaymentPreview
                initialValues={{ resetDetails: reset }}
                isInforce={isInforce}
                initReset={reset}
                canGoBack={canGoBack}
                canSubmit={canSubmit}
                onSubmit={immutableData => {
                  const data = immutableData.toJS();
                  if (isInforce) {
                    if (!data.resetDetails) {
                      setReset(true);
                      nextStepHandler('payment');
                    } else {
                      submitPaymentDetail({
                        paymentDetail: {
                          ...paymentDetail,
                          isIFINewPayment: reset,
                        },
                        applicationId,
                        isInforce,
                      });
                    }
                  } else {
                    submitPaymentDetail({ paymentDetail, applicationId, isInforce });
                  }
                }}
                ISOCode={agentDetails.ISOCountryCode}
                isExistingPaymentToggle={isExistingPaymentToggle}
              />
            )}
          />
          <Route
            path="/applications/:applicationId/form/payment-callback"
            render={() => <PayfortCallback />}
          />
          <Route path="/" render={() => <Redirect to="/applications" />} />
        </Switch>
      </FormMainBody>

      <FormFooter
        onClickPrevious={onClickPreviousHandler}
        onClickExit={onClickExistHandler}
        onClickNext={onClickNextHandler}
        // disabledNext={
        //   subPage === subPageList.length &&
        //   lastSubStepper(subPageList, subPage) === subPageStepper
        // }
        disabledPrevious={disabledPrevious()}
        disabledNext={disabledNext()}
        label1={<FormattedMessage {...messages.previous} />}
        label2={<FormattedMessage {...messages.exit} />}
        label3={updateTextNextButton()}
      />
    </>
  );
}

ApplicationFormPage.propTypes = {
  steps: PropTypes.object,
  appStyles: PropTypes.object,
  subPage: PropTypes.number,
  subPageStepper: PropTypes.number,
  changeSubPageList: PropTypes.func,
  changeHealth: PropTypes.func,
  otherHealth: PropTypes.func,
  updateApplicationDetails: PropTypes.func,
  updateApplicationDetailsAndStatus: PropTypes.func,
  updateApplicationParties: PropTypes.func,
  submitPolicyOwner: PropTypes.func,
  updateApplicationStatus: PropTypes.func,
  submitSection: PropTypes.func,
  updateApplicationFunds: PropTypes.func,
  updateApplicationFunds2: PropTypes.func,
  fundsBelongToNexusOrDever: PropTypes.bool,
  agentDetails: PropTypes.object,
  submitPaymentPreview: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      applicationId: PropTypes.string,
    }),
  }),
  error: PropTypes.object,
  underwritingSections: PropTypes.object,
  financialInstituteOptions: PropTypes.object,
  answers: PropTypes.array,
  paymentDetail: PropTypes.object,
  applicationDetails: PropTypes.object,
  paymentType: PropTypes.string,
  options: PropTypes.object,
  subPageList: PropTypes.object,
  changeSubPage: PropTypes.func,
  changeSubStepPage: PropTypes.func,
  addApplicationParties: PropTypes.func,
  addApplicationParty: PropTypes.func,
  deleteApplicationParties: PropTypes.func,
  deleteApplicationParty: PropTypes.func,
  submitBeneficiaries: PropTypes.func,
  submitPayor: PropTypes.func,
  submitPayorBank: PropTypes.func,
  submitInvestment: PropTypes.func,
  submitDecision: PropTypes.func,
  submitDocument: PropTypes.func,
  submitConsent: PropTypes.func,
  submitPayment: PropTypes.func,
  submitPaymentMethod: PropTypes.func,
  submitUWConsent: PropTypes.func,
  getInterviewSessionId: PropTypes.func,
  submitUW: PropTypes.func,
  submitUWForm: PropTypes.func,
  submitPayorSalary: PropTypes.func,
  updatePaymentDetail: PropTypes.func,
  submitPaymentDetail: PropTypes.func,
  submitCreditCard: PropTypes.func,
  continuePayment: PropTypes.func,
  getApplicationDetails: PropTypes.func,
  setUnderwriting: PropTypes.func,
  setApplicationStruct: PropTypes.func,
  setAnswersEmpty: PropTypes.func,
  setQuestionsEmpty: PropTypes.func,
  setLIParties: PropTypes.func,
  paymentStatus: PropTypes.func,
  setPaymentStatus: PropTypes.func,
  submitLifeInsured: PropTypes.func,
  setFile: PropTypes.func,
  updatePaymentMethod: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  steps: makeSelectApplicationSubOrder(),
  applicationFormPage: makeSelectApplicationFormPage(),
  subPage: makeSelectSubPage(),
  subPageStepper: makeSelectSubPageStepper(),
  subPageList: makeSelectSubPageList(),
  underwritingSections: makeSelectUnderwritingSections(),
  applicationDetails: makeSelectApplicationDetails(),
  paymentType: makeSelectPaymentType(),
  paymentDetail: makeSelectPaymentDetail(),
  financialInstituteOptions: makeSelectFinancialInstituteOptions(),
  paymentStatus: makeSelectPayment(),
  fundsBelongToNexusOrDever: makeSelectFundsBelongToNexusOrDever(),
  // UW
  answers: makeSelectAnswers(),
  agentDetails: makeSelectAgentDetails(),
  options: makeSelectOption(),
  error: makeSelectError(),
});

function mapDispatchToProps(dispatch) {
  return {
    getApplicationDetails: value =>
      dispatch(getApplicationDetailsAction(value)),
    changeSubPage: value => dispatch(changeSubPageAction(value)),
    changeSubStepPage: value => dispatch(changeSubStepPageAction(value)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    setSectionAnswer: data => dispatch(setSectionAction(data)),
    updateApplicationDetails: value =>
      dispatch(updateApplicationDetailsAction(value)),
    updateApplicationDetailsAndStatus: value =>
      dispatch(updateApplicationDetailsAndStatusAction(value)),
    updateApplicationStatus: value =>
      dispatch(updateApplicationStatusAction(value)),
    updateApplicationParties: values =>
      dispatch(updateApplicationPartiesAction(values)),
    updatePaymentDetail: values => dispatch(setPaymentDetail(values)),
    addApplicationParties: values => {
      dispatch(addApplicationPartiesAction(values));
    },
    addApplicationParty: values => {
      dispatch(addApplicationPartyAction(values));
    },
    deleteApplicationParties: values => {
      dispatch(deleteApplicationPartiesAction(values));
    },
    deleteApplicationParty: values => {
      dispatch(deleteApplicationPartyAction(values));
    },
    submitUW: data => {
      dispatch(submitUWAction(data));
    },
    submitPaymentDetail: data => {
      dispatch(submitPaymentAction(data));
    },
    submitCreditCard: data => {
      dispatch(submitCreditCardAction(data));
    },
    setUnderwriting: data => {
      dispatch(setUnderwritingSections(data));
    },
    setApplicationStruct: data => {
      dispatch(setApplicationSubOrders(data));
    },
    setQuestionsEmpty: () => {
      dispatch(setQuestionsEmptyAction());
    },
    setAnswersEmpty: () => {
      dispatch(setAnswersEmptyAction());
    },
    setLIParties: data => {
      dispatch(setLIPartiesAction(data));
    },
    setPaymentStatus: data => dispatch(setPaymentDataAction(data)),
    // SUBMIT FORMS
    submitPolicyOwner: () => dispatch(submit('policyOwner')),
    submitBeneficiaries: formName => dispatch(submit(formName)),
    submitPayor: () => dispatch(submit('payor')),
    submitUWForm: () => dispatch(submit('SubmitForm')),
    submitPayorSalary: () => dispatch(submit('payorSalary')),
    submitPayorBank: () => dispatch(submit('payorBank')),
    submitSection: sectionName => dispatch(submit(sectionName)),
    submitDecision: () => dispatch(submit('Decision')),
    submitDocument: () => dispatch(submit('DocumentForm')),
    submitConsent: () => dispatch(submit('Consent')),
    submitInvestment: () => dispatch(submit('Investment')),
    submitPaymentPreview: () => {
      dispatch(submit('PaymentPreview'));
    },
    submitPayment: () => {
      dispatch(submit('Payment'));
    },
    submitUWConsent: () => {
      dispatch(submit('UWConsent'));
    },
    submitPaymentMethod: () => {
      dispatch(submit('PaymentMethod'));
    },
    submitLifeInsured: () => {
      dispatch(submit('lifeInsured'));
    },
    continuePayment: payfortResponse =>
      dispatch(continuePaymentCallbackAction(payfortResponse)),
    setFile: data => dispatch(setFileAction(data)),
    updatePaymentMethod: data => dispatch(updatePaymentType(data)),
    updateApplicationFunds: data =>
      dispatch(updateApplicationFundsAction(data)),
    updateApplicationFunds2: data =>
      dispatch(updateApplicationFundsAction2(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ApplicationFormPage);

/**
 *
 * FormTextField
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { WrappedFieldProps } from 'redux-form';
// import { TextField as BaseTextField } from '@material-ui/core';
import TextField from 'components/TextField';

const FormTextField = ({
  label,
  input,
  meta: { invalid, error, touched, warning },
  helperText,
  placeholder,
  ...custom
}) => {
  let helperMsg = helperText;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  return (
    <TextField
      placeholder={placeholder}
      variant="outlined"
      label={label}
      error={!!warning || (touched && invalid)}
      helperText={helperMsg}
      // classes
      {...input}
      {...custom}
    />
  );
};

FormTextField.propTypes = {
  ...WrappedFieldProps,
  helperText: PropTypes.node,
  placeholder: PropTypes.string,
};

export default FormTextField;

import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMFOauthPage = state => state.get('MFOauthPage', initialState);

const makeSelectZioPinData = () =>
  createSelector(selectMFOauthPage, substate =>
    substate.get('zioPinData').toJS(),
  );

const makeSelectZioPinError = () =>
  createSelector(selectMFOauthPage, substate =>
    substate.get('zioPinError').toJS(),
  );

const makeSelectIsZioPinVerified = () =>
  createSelector(selectMFOauthPage, substate =>
    substate.get('isZioPinVerified'),
  );

const makeSelectZioPinVerifyTimeStamp = () =>
  createSelector(selectMFOauthPage, substate =>
    substate.get('zioPinVerifyTimeStamp'),
  );

export {
  makeSelectZioPinData,
  makeSelectZioPinError,
  makeSelectIsZioPinVerified,
  makeSelectZioPinVerifyTimeStamp,
};

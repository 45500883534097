import React, { useEffect } from 'react';
import { Grid, Box, Typography, Divider } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import { formatMonetaryValue } from 'helpers/math';
import messages from '../../../messages';
import {
  SINGLE_PREMIUM,
  WHOLE_OF_LIFE,
} from 'containers/InforceIllutrationPage/constants';

const AmountItem = ({
  title,
  currency,
  value,
  isBig = false,
  isLastItem = false,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={isBig ? 12 : 5}
      style={{
        borderBottom: isLastItem ? 'none' : '2px solid white',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      }}
      {...props}
    >
      <Typography variant="body2" color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography
        color="textPrimary"
        style={{ fontWeight: 'bold', fontSize: isBig ? '2.8rem' : '1.4rem' }}
      >
        {currency} {value}
      </Typography>
    </Grid>
  );
};

AmountItem.propTypes = {
  isBig: PropTypes.bool,
  isLastItem: PropTypes.bool,
  title: PropTypes.string,
  value: PropTypes.string,
  props: PropTypes.object,
};

export default function SummaryAmount({
  paidToDate,
  startDate,
  lastPayment,
  paymentDue,
  term,
  coverageAmount,
  currency,
}) {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="column"
      style={{
        background: '#F1F1F1',
        padding: theme.spacing(2),
      }}
      wrap="nowrap"
    >
      <AmountItem
        isBig
        value={formatMonetaryValue(coverageAmount, false)}
        currency={currency}
        title={<FormattedMessage {...messages.coverageAmount} />}
      />
      <AmountItem
        isBig
        value={formatMonetaryValue(paidToDate, false)}
        currency={currency}
        title={<FormattedMessage {...messages.paidToDate} />}
      />

      <Grid
        item
        xs
        container
        style={{
          borderBottom: '2px solid white',
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2),
        }}
      >
        <AmountItem
          isLastItem
          value={moment(startDate).format('DD MMMM YYYY')}
          title={<FormattedMessage {...messages.startDate} />}
        />
        <Grid xs={2} item>
          <Divider
            orientation="vertical"
            variant="middle"
            style={{
              background: 'white',
            }}
          />
        </Grid>
        <AmountItem
          isLastItem
          value={
            term === 'SinglePremium'
              ? SINGLE_PREMIUM
              : term === 'WholeOfLife'
              ? WHOLE_OF_LIFE
              : `${term} years`
          }
          title={<FormattedMessage {...messages.paymentTerm} />}
        />
      </Grid>
      <Grid item xs container style={{ paddingBottom: theme.spacing(2) }}>
        <AmountItem
          isLastItem
          value={moment(lastPayment).format('DD MMMM YYYY')}
          title={<FormattedMessage {...messages.lastPayment} />}
        />
        <Grid xs={2} item>
          <Divider
            orientation="vertical"
            variant="middle"
            style={{
              background: 'white',
              marginTop: theme.spacing(2),
            }}
          />
        </Grid>
        <AmountItem
          isLastItem
          value={paymentDue === '-' ? paymentDue : moment(paymentDue).format('DD MMMM YYYY')}
          title={<FormattedMessage {...messages.paymentDue} />}
        />
      </Grid>
    </Grid>
  );
}

SummaryAmount.propTypes = {
  // TODO: defines here
};

import React from 'react';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { findKey } from 'lodash/object';
import Slider from './Slider';
import { WrappedFieldProps } from 'redux-form';
import { Grid, IconButton } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';

const FormSlider = ({
  input: { onChange, value, onBlur },
  meta,
  id,
  min,
  max,
  diff,
  isPrimary,
  ...custom
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      wrap="nowrap"
      alignItems="center"
    >
      <IconButton
        id={`${id}.decreaseAmount`}
        onClick={() => {
          const actualVal = numeral(value).value();
          const mainDiff = numeral(diff).value();
          const changeVal = actualVal - mainDiff;
          if (changeVal > min) return onChange(changeVal);
          else return onChange(min);
        }}
      >
        <RemoveCircleOutline
          fontSize="large"
          style={{
            color: isPrimary
              ? theme.palette.common.white
              : theme.palette.primary.dark,
          }}
        />
      </IconButton>

      <Slider
        min={min}
        max={max}
        value={numeral(value).value()}
        onChange={(_e, newVal) => onChange(newVal)}
        defaultValue={2}
        {...custom}
      />

      <IconButton
        id={`${id}.increaseAmount`}
        onClick={() => {
          let actualVal = numeral(value).value();
          let mainDiff = numeral(diff).value();
          let changeVal = actualVal + mainDiff;
          if (changeVal < max) return onChange(changeVal);
          else return onChange(max);
        }}
      >
        <AddCircleOutline
          fontSize="large"
          style={{
            color: isPrimary
              ? theme.palette.common.white
              : theme.palette.primary.dark,
          }}
        />
      </IconButton>
    </Grid>
  );
};

FormSlider.propTypes = {
  ...WrappedFieldProps,
  id: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  diff: PropTypes.number,
  isPrimary: PropTypes.bool,
};

export default FormSlider;

/*
 *
 * RegisterPage actions
 *
 */

import { DEFAULT_ACTION, CHANGE_INPUT_DATA_ACTION, SUBMIT_REGISTRATION_ACTION, SET_NOTIFICATION_ACTION, RESET_STATE_ACTION, SET_SIGN_UP_SUCCESS_ACTION } from './constants';

export function defaultAction() {
  return {
    type: DEFAULT_ACTION,
  };
}

export function changeInputDataAction(inputData = {}) {
  return {
    type: CHANGE_INPUT_DATA_ACTION,
    payload: inputData,
  };
}

export function submitRegistrationAction(inputData = {}) {
  return {
    type: SUBMIT_REGISTRATION_ACTION,
    payload: inputData,
  };
}

export function setNotificationAction(notification = {
  open: false,
  message: null,
  severity: null,
}) {
  return {
    type: SET_NOTIFICATION_ACTION,
    payload: notification,
  };
}

export function resetStateAction() {
  return {
    type: RESET_STATE_ACTION,
  };
}

export function setSignUpSuccessAction(){
  return{
    type: SET_SIGN_UP_SUCCESS_ACTION
  }
}

/**
 * CoverageForm
 */
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
} from 'redux-form/immutable';
import defaultShouldAsyncValidate from 'redux-form/es/defaultShouldAsyncValidate';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  resetOfferCodeAction,
  validateOfferCodeAction,
} from 'containers/App/actions';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
  Divider,
  Typography,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import { dimension } from 'styles/constants';
import FormCheck from 'components/FormCheck';
import FormNumberField from 'components/FormNumberField';
import FormTextField from 'components/FormTextField';
import { CheckCircle as CheckIcon } from '@material-ui/icons';
import FormSelect from 'components/FormSelect';
import FormBooleanOption from 'components/FormBooleanOption';
import moment from 'moment';
import CustomFormCheck from 'components/CustomFormCheck';
import Helper from 'components/Helper';
import { changePlaceHolderColor } from 'helpers/lang';
import LoadingDialog from 'components/LoadingDialog';
import asyncValidator from './asyncValidate';
import { calculateAgeNextBirthdate } from '../../helper';
import validate from './validate';
import messages from '../../messages';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
}));

const renderBenefit = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne.dateOfBirth,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo.dateOfBirth,
      );

      let ageValidType = 0;

      const isShowingField = true;
      let isFormCheckDisabled = myBenefit[index].disabled;
      let isFieldDisable = !myBenefit[index].selected;

      let isWopOneDisabled = myBenefit[index].disabled;
      let isWopTwoDisabled = myBenefit[index].disabled;

      if (myBenefit[index].id === 'LFC') isFieldDisable = false;
      else isFieldDisable = true;
      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA != '0') {
          if (myBenefit[index].id === 'WOP') {
            if (myBenefit[index].maxEntryAgeLA < anbInsuredOne)
              isWopOneDisabled = true;

            if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo)
              isWopTwoDisabled = true;
          } else if (
            myBenefit[index].maxEntryAgeLA < anbInsuredOne ||
            myBenefit[index].maxEntryAgeLA < anbInsuredTwo
          ) {
            ageValidType = 1;
            isFormCheckDisabled = true;
            if (myBenefit[index].id === 'WOP') {
              isWopOneDisabled = true;
              change(`${benefit}.valueInsuredOne`, false);
              change(`${benefit}.valueInsuredTwo`, false);
              change(`${benefit}.selected`, false);
            } else {
              change(`${benefit}.valueInsuredOne`, '');
              change(`${benefit}.valueInsuredTwo`, '');
              change(`${benefit}.selected`, false);
            }
          }
        }
        // mapping data for Li2
        if (
          myBenefit[index].valueInsuredOne !== '' &&
          myBenefit[index].id !== 'WOP'
        ) {
          change(
            `${benefit}.valueInsuredTwo`,
            myBenefit[index].valueInsuredOne,
          );
        }
      } else if (myBenefit[index].maxEntryAgeLA != '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          ageValidType = 1;
          isFormCheckDisabled = true;
          if (myBenefit[index].id === 'WOP') {
            isWopOneDisabled = true;
            change(`${benefit}.valueInsuredOne`, false);
            change(`${benefit}.valueInsuredTwo`, false);
            change(`${benefit}.selected`, false);
          } else {
            change(`${benefit}.valueInsuredOne`, '');
            change(`${benefit}.valueInsuredTwo`, '');
            change(`${benefit}.selected`, false);
          }
        }
      }

      return (
        <div key={index}>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            wrap="nowrap"
          >
            <Grid item xs={6} md={6} sm={6}>
              <Grid container direction="row">
                <Grid item>
                  {myBenefit[index].type === '1' ? (
                    <Field
                      // shrink
                      name={`${benefit}.selected`}
                      fullWidth
                      component={CustomFormCheck}
                      label={myBenefit[index].title}
                      disabled={isFormCheckDisabled}
                      validType={ageValidType}
                      type="text"
                      variant="standard"
                      onChange={(e, newVal) => {
                        if (newVal) {
                          change(
                            `${benefit}.valueInsuredOne`,
                            myBenefit[0].valueInsuredOne,
                          );
                          change(
                            `${benefit}.valueInsuredTwo`,
                            myBenefit[0].valueInsuredOne,
                          );
                        } else {
                          change(`${benefit}.valueInsuredOne`, '');
                          change(`${benefit}.valueInsuredTwo`, '');
                        }
                      }}
                    />
                  ) : (
                    <Typography variant="h4" style={{ fontWeight: '600' }}>
                      {myBenefit[index].title}
                    </Typography>
                  )}
                </Grid>

                {myBenefit[index].id === 'WOP' ||
                (myBenefit[index].id === 'CRI' && isFormCheckDisabled) ? (
                  <Grid item>
                    <Helper
                      helperText={
                        myBenefit[index].id === 'WOP' ? (
                          <FormattedMessage {...messages.descriptionTwo} />
                        ) : (
                          <FormattedMessage {...messages.descriptionCri} />
                        )
                      }
                    ></Helper>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {/* test */}
            <Grid item xs={3} md={3} sm={3}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.valueInsuredOne`}
                  component={FormNumberField}
                  decimalScale={0}
                  disabled={isFieldDisable}
                  type={isShowingField ? 'text' : 'hidden'}
                  fullWidth
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (myBenefit[index].id === 'LFC') {
                      fields.map((benefitData, indexData) => {
                        if (
                          myBenefit[indexData].selected &&
                          myBenefit[indexData].id != 'WOP'
                        ) {
                          change(`${benefitData}.valueInsuredOne`, newVal);
                          change(`${benefitData}.valueInsuredTwo`, newVal);
                        }
                      });
                    }
                  }}
                  placeholder={
                    ageValidType === 1
                      ? intl.formatMessage(globalMessages.naMessage)
                      : intl.formatMessage(messages.enterAmount)
                  }
                />
              ) : (
                <Field
                  // shrink
                  name={`${benefit}.valueInsuredOne`}
                  component={FormBooleanOption}
                  type="text"
                  margin="normal"
                  disabled={isWopOneDisabled}
                  fullWidth
                />
              )}
            </Grid>

            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={3} md={3} sm={3}>
                {myBenefit[index].type === '1' ? (
                  <Field
                    // shrink
                    name={`${benefit}.valueInsuredTwo`}
                    fullWidth
                    component={FormNumberField}
                    decimalScale={0}
                    disabled
                    type={isShowingField ? 'text' : 'hidden'}
                    variant="standard"
                    placeholder={
                      ageValidType === 1
                        ? intl.formatMessage(globalMessages.naMessage)
                        : intl.formatMessage(messages.enterAmount)
                    }
                  />
                ) : (
                  <Field
                    // shrink
                    name={`${benefit}.valueInsuredTwo`}
                    component={FormBooleanOption}
                    type="text"
                    margin="normal"
                    disabled={isWopTwoDisabled}
                    fullWidth
                  />
                )}
              </Grid>
            ) : null}
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  product,
  dtaConfig,
  coverages,
  change,
  offerCode,
  offerCodeData,
  startDate,
  term,
  paymentFrequency,
  currency,
  location,
  resetOfferCode,
  validateOfferCode,
}) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const offerList = [];
  const currencyOption = [];
  const paymentFrequencyOption = [];
  const policyTermList = [];
  const interestRateList = [];
  const startDateList = [];

  useEffect(() => {
    if (offerCode === '') {
      resetOfferCode();
    }

    if (offerCode !== '') {
      validateOfferCode({
        offerCode,
        productCode: dtaConfig.productCode,
        productVersion: dtaConfig.productVersion,
        premiumType: term,
        currency,
        premium: coverages?.[0]?.valueInsuredOne,
      });
    }
  }, []);

  if (startDateList.length == 0) {
    startDateList.push({
      title: moment(new Date()).format('DD/MM/YYYY'),
      value: moment(new Date()).format('YYYY-MM-DD'),
    });
  }

  const anbInsuredOne = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredOne.dateOfBirth,
  );
  const anbInsuredTwo = calculateAgeNextBirthdate(
    startDate,
    lifeAssured.insuredTwo.dateOfBirth,
  );

  let minPremiumValue = dtaConfig.features?.policyTerm.minTerm;
  let maxPremiumValue = dtaConfig.features?.policyTerm.maxTerm;
  let expiryAgeValue = dtaConfig.features?.policyTerm.expiryAge;

  const considerAge =
    anbInsuredOne < anbInsuredTwo ? anbInsuredTwo : anbInsuredOne;
  const calculateMaxTerm = expiryAgeValue - considerAge;
  maxPremiumValue =
    calculateMaxTerm > maxPremiumValue ? maxPremiumValue : calculateMaxTerm;

  while (minPremiumValue <= maxPremiumValue) {
    const myObj = { title: `${minPremiumValue}`, value: minPremiumValue };
    policyTermList.push(myObj);
    minPremiumValue++;
  }

  interestRateList.push({ title: '0', value: '0.0' });
  interestRateList.push({ title: '7', value: '0.07' });
  interestRateList.push({ title: '9', value: '0.09' });
  interestRateList.push({ title: '11', value: '0.11' });

  offerList.push({ title: 'No Offer', value: '' });

  product?.specialOffers?.map(offer => {
    offerList.push({
      value: offer.specialOfferCode,
      title: offer.specialOfferCode,
    });
  });

  dtaConfig.features.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  dtaConfig.features.premiumPaymentFrequencyType[0].periods.map(frequency => {
    paymentFrequencyOption.push({ value: frequency, title: frequency });
  });

  const hasErrorOfferCode = offerCodeData.errorMessage !== '';

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* CURRRENCY */}
            <Grid item>
              <Field
                // shrink
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* POLICY TERM */}
            <Grid item>
              <Field
                // shrink
                name="term"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(term),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.policyTerm} />}
                type="text"
                // options={policyTermList}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...policyTermList,
                ]}
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>
            {/* PAYMENT FREQUENCY */}
            <Grid item>
              <Field
                // shrink
                name="paymentFrequency"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(paymentFrequency),
                }}
                component={FormSelect}
                // options={paymentFrequencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...paymentFrequencyOption,
                ]}
                label={<FormattedMessage {...messages.paymentFrequency} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
              />
            </Grid>

            {/* Interest rate */}
            <Grid item xs={12}>
              <Grid container direction="row" wrap="nowrap" alignItems="center">
                <Field
                  // shrink
                  name="interestRate"
                  style={{ width: 164 }}
                  component={FormSelect}
                  options={interestRateList}
                  label={
                    <>
                      <FormattedMessage {...messages.interestRate} />
                      <Helper
                        placement="right-start"
                        helperText={
                          globalMessages.descriptionInterestRate.defaultMessage
                        }
                      />
                    </>
                  }
                  type="text"
                  margin="normal"
                  variant="standard"
                  placeholder={intl.formatMessage(messages.enterRate)}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.hr} />

          <Grid
            container
            direction="row"
            spacing={1}
            justify="space-between"
            alignItems="center"
            item
            style={{ marginBottom: theme.spacing(1), paddingRight: '10%' }}
            wrap="nowrap"
          >
            <Grid item xs={6} md={6} sm={6} />
            <Grid item xs={6} md={3} sm={3}>
              <Typography
                color="primary"
                variant="h3"
                style={{ marginLeft: theme.spacing(0.5) }}
              >
                {lifeAssured.insuredOne.firstName}
              </Typography>
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredTwo.firstName}
                </Typography>
              </Grid>
            ) : null}
          </Grid>

          <Grid>
            <FieldArray
              name="coverages"
              component={renderBenefit}
              rerenderOnEveryChange
              lifeAssured={lifeAssured}
              options={options}
              change={change}
              startDate={startDate}
              intl={intl}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid item xs={6} style={{ marginBottom: '5rem' }}>
            <Field
              // shrink
              name="offerCode"
              style={{
                width: 355,
              }}
              fullWidth
              component={FormTextField}
              success={!hasErrorOfferCode && !!offerCodeData.discountRate}
              endAdornment={
                (!!offerCodeData.discountRate && !hasErrorOfferCode) ||
                offerCodeData.validating ? (
                  <InputAdornment
                    style={{
                      color: offerCodeData.validating
                        ? undefined
                        : theme.palette.success.main,
                    }}
                  >
                    {offerCodeData.validating ? (
                      <CircularProgress size="2rem" />
                    ) : (
                      <CheckIcon />
                    )}
                  </InputAdornment>
                ) : (
                  undefined
                )
              }
              helperText={
                !offerCodeData.validating && !hasErrorOfferCode
                  ? offerCodeData.discountMessage?.english
                  : undefined
              }
              label={<FormattedMessage {...messages.specialOffer} />}
              type="text"
              color="primary"
              variant="standard"
              placeholder={intl.formatMessage(messages.pleaseEnter)}
            />
          </Grid>

          <Divider className={classes.hr} />

          <Grid item xs={12}>
            <Field
              // shrink
              name="specialTermsFlag"
              fullWidth
              component={FormCheck}
              label={<FormattedMessage {...globalMessages.isBenefitRating} />}
              type="text"
              margin="normal"
              variant="standard"
            />
          </Grid>
        </form>
      )}
    </>
  );
}

CoverageForm.propTypes = {
  offerCode: PropTypes.string,
  options: PropTypes.object,
  dtaConfig: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  offerCodeData: PropTypes.object,
  startDate: PropTypes.string,
  coverages: PropTypes.array,
};

const selector = formValueSelector('coverageForm');
const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {
    resetOfferCode: () => dispatch(resetOfferCodeAction()),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withFormSelector = connect(state => {
  const coverages = selector(state, 'coverages');
  const startDate = selector(state, 'startDate');
  const fiaCharge = selector(state, 'fiaCharge');
  const currency = selector(state, 'currency');
  const term = selector(state, 'term');
  const offerCode = selector(state, 'offerCode');
  const paymentFrequency = selector(state, 'paymentFrequency');
  return {
    startDate,
    fiaCharge,
    currency,
    term,
    paymentFrequency,
    offerCode,
    coverages: coverages?.toJS() || [],
  };
});

export default reduxForm({
  shouldAsyncValidate: params => {
    return defaultShouldAsyncValidate({
      ...params,
      syncValidationPasses: true,
    });
  },
  validate,
  form: 'coverageForm',
  asyncValidate: asyncValidator,
  asyncChangeFields: [
    'offerCode',
    'term',
    'coverages[].valueInsuredOne',
    'currency',
  ],
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(compose(withConnect, withFormSelector)(CoverageForm));

/**
 *
 * IllustrationPage
 *
 */
import DtaIllustrationPage from 'containers/DtaIllustrationPage';
import FuturaIllustrationPage from 'containers/FuturaIllustrationPage';
import GcIllustrationPage from 'containers/GcIllustrationPage';
import ItaIllustrationPage from 'containers/ItaIllustrationPage';
import StaciIllustrationPage from 'containers/StaciIllustrationPage';
import RspIllustrationPage from 'containers/RPSavingPage';
import SwIllustrationPage from 'containers/SwIllustrationPage';
import VistaIllustrationPage from 'containers/VistaIllustrationPage';
import WapIllustrationPage from 'containers/WapIllustrationPage';
import EspIllustrationPage from 'containers/EspIllustrationPage';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { useInjectReducer } from 'utils/injectReducer';
import { useInjectSaga } from 'utils/injectSaga';
import { makeSelectQuoteType } from 'containers/App/selectors';
// import { usePrevious } from 'utils/hooks';
import {
  cloneProductAction,
  getProductConfigAction,
  resetProductConfigAction,
  sendEmailAction,
  setProductIdAction,
} from './actions';
import ClientListIllustrations from './components/ClientList';
import HomeIllustrations from './components/Home';
import TableCustomers from './components/TableCustomers';
import reducer from './reducer';
import saga from './saga';
import {
  makeSelectCustomerDetail,
  makeSelectError,
  makeSelectProductConfig,
  makeSelectProductId,
} from './selectors';

export function IllustrationPage({
  productId,
  setProductId,
  getProductConfig,
  productConfig,
  resetProductConfig,
  cloneProduct,
  emailQuote,
  customerDetail,
  quoteType,
}) {
  useInjectReducer({ key: 'illustrationPage', reducer });
  useInjectSaga({ key: 'illustrationPage', saga });

  const [productCode, setProductCode] = useState('');
  const [productVersion, setProductVersion] = useState('');
  const [type, setType] = useState(0);
  const [mounted, setMounted] = useState(false);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    setMounted(true);
    return () => {
      resetProductConfig();
      setProductId('');
    };
  }, []);

  useEffect(() => {
    if (!isEmpty(productId)) getProductConfig({ productCode, productVersion });
  }, [productId]);

  useEffect(() => {
    if (!location.state?.isNavigateFromDAQuote) {
      switch (productConfig.productCode) {
        case 'ITAS6':
        case 'ITAB2':
        case 'ITAS5':
        case 'ITAS3':
          return history.push({
            pathname: '/illustrations/ita',
            state: {
              itaConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'DTME2':
        case 'DTAME':
          return history.push({
            pathname: '/illustrations/dta',
            state: {
              dtaConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'MFS01':
        case 'SAVP3':
          return history.push({
            pathname: '/illustrations/gc',
            state: {
              gcConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'VISP2':
        case 'CTVP2':
          return history.push({
            pathname: '/illustrations/vista',
            state: {
              gcConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'FUTUR':
        case 'FUTU2':
        case 'FUTU3':
        case 'FUTU4':
        case 'FUTU5':
        case 'CTFUT':
        case 'CTFU2':
        case 'CTFU3':
        case 'SCFUT':
          return history.push({
            pathname: '/illustrations/futura',
            state: {
              futuraConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'WAPL1':
        case 'WAPL2':
          return history.push({
            pathname: '/illustrations/wap',
            state: {
              wapConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'SIMWE':
        case 'SIMW2':
          return history.push({
            pathname: '/illustrations/sw',
            state: {
              swConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'ESP01':
          return history.push({
            pathname: '/illustrations/esp',
            state: {
              gcConfig: productConfig,
              quoteId: productId,
              productConfig: productConfig,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'RSP01':
          return history.push({
            pathname: '/illustrations/rps',
            state: {
              gcConfig: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        case 'STACI':
        case 'SACI1':
          return history.push({
            pathname: '/illustrations/staci',
            state: {
              configsDetail: productConfig,
              quoteId: productId,
              type,
              customerDetail:
                location.pathname.indexOf('/customer') < 0
                  ? {}
                  : customerDetail,
            },
          });
        default:
          break;
      }
    }
  }, [productConfig?.productCode]);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Switch>
        <Route
          path="/illustrations"
          exact
          render={() => (
            <HomeIllustrations
              parentMounted={mounted}
              quoteType={quoteType}
              getProductConfig={prod => {
                setType(0);
                getProductConfig({
                  productCode: prod.productId,
                  productVersion: prod.PTVVersion,
                });
              }}
            />
          )}
        />
        <Route
          path="/illustrations/ita"
          render={() => <ItaIllustrationPage />}
        />
        <Route
          path="/illustrations/staci"
          render={() => <StaciIllustrationPage />}
        />
        <Route
          path="/illustrations/customer"
          render={() => (
            <TableCustomers
              parentMounted={mounted}
              getProductConfig={prod => {
                setType(0);
                getProductConfig({
                  productCode: prod.productId,
                  productVersion: prod.PTVVersion,
                });
              }}
            />
          )}
        />
        <Route
          path="/illustrations/dta"
          render={() => <DtaIllustrationPage />}
        />
        <Route path="/illustrations/gc" render={() => <GcIllustrationPage />} />
        <Route
          path="/illustrations/vista"
          render={() => <VistaIllustrationPage />}
        />
        <Route
          path="/illustrations/wap"
          render={() => <WapIllustrationPage />}
        />
        <Route
          path="/illustrations/esp"
          render={() => <EspIllustrationPage />}
        />
        <Route path="/illustrations/sw" render={() => <SwIllustrationPage />} />
        <Route
          path="/illustrations/rps"
          render={() => <RspIllustrationPage />}
        />
        <Route
          path="/illustrations/futura"
          render={() => <FuturaIllustrationPage />}
        />
        <Route
          path="/illustrations/:id"
          render={() => (
            <ClientListIllustrations
              sendEmail={val => emailQuote(val)}
              resetProductId={() => setProductId('')}
              getProductConfig={prod => {
                if (prod.type === 2) cloneProduct(prod.productId);

                if (prod.type !== 2) setProductId(prod.productId);

                setType(prod.type);
                setProductCode(prod.productCode);
                setProductVersion(prod.productVersion);
              }}
            />
          )}
        />
      </Switch>
    </div>
  );
}

IllustrationPage.propTypes = {
  productConfig: PropTypes.object,
  customerDetail: PropTypes.object,
  productId: PropTypes.string,
  quoteType: PropTypes.string,
  setProductId: PropTypes.func,
  cloneProduct: PropTypes.func,
  getProductConfig: PropTypes.func,
  resetProductConfig: PropTypes.func,
  emailQuote: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  productConfig: makeSelectProductConfig(),
  productId: makeSelectProductId(),
  error: makeSelectError(),
  customerDetail: makeSelectCustomerDetail(),
  quoteType: makeSelectQuoteType(),
});

function mapDispatchToProps(dispatch) {
  return {
    getProductConfig: value => dispatch(getProductConfigAction(value)),
    resetProductConfig: () => dispatch(resetProductConfigAction()),
    setProductId: val => dispatch(setProductIdAction(val)),
    cloneProduct: val => dispatch(cloneProductAction(val)),
    emailQuote: val => dispatch(sendEmailAction(val)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(IllustrationPage);

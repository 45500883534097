/**
 *
 * Review
 *
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
  Typography,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from '@material-ui/core';
import CustomDialog from 'components/Dialog';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import { DescriptionOutlined, Info } from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import InfoRow from 'components/InfoRow';
import { isEmpty } from 'lodash';
import numeral from 'numeral';
import ReviewUserData from 'components/ReviewUserData';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import { validateOfferCodeAction } from 'containers/App/actions';
import {
  getQuoteAction,
  updateStatusAction,
  emailPdfAction,
  setEmailSentAction,
} from '../actions';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectBiPdf,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
  makeSelectConfig,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
} from '../selectors';
import { isValidEmail } from '../helper';
import messages from '../messages';

function Review({
  lifeAssured,
  productConfig,
  getQuote,
  wapConfig,
  benefitIllustrationPdf,
  updateStatus,
  emailPdf,
  referenceNumber,
  fiaData,
  offerCodeData,
  emailSent,
  setEmailSent,
  emailErrorMessage,
  validateOfferCode,
  isWap
}) {
  const theme = useTheme();
  useEffect(() => {
    getQuote(0);
  }, []);

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [errorMail, setErrorMail] = useState(false);
  // const biPdf = benefitIllustrationPdf + '&isPreview=true';

  useEffect(() => {
    if (emailSent) {
      setErrorMail(true);
    }
  }, [emailSent]);

  useEffect(() => {
    return () => {
      setEmailSent(false);
    };
  }, []);

  //To check Offer code error. For WAP Inforce and NB previously it was in the above useEffect
  useEffect(() => {
    if (offerCodeData.code) {
      validateOfferCode({
        offerCode: offerCodeData.code,
        productCode: wapConfig.productCode,
        productVersion: wapConfig.productVersion,
        premiumType: 'SinglePremium',
        currency: productConfig.currency,
        premium: Number(productConfig.premium),
      });
    }
  }, [productConfig]);

  const getBrTitle = brValue => {
    switch (brValue) {
      case '0':
        return '';

      case '1':
        return 'Per Mille';

      case '2':
        return 'Percentage';

      default:
        return '';
    }
  };

  let aspFrequencyLabel = 'p/month';

  switch (productConfig.additionalSinglePremium.frequency) {
    case 'Monthly':
      aspFrequencyLabel = 'p/month';
      break;

    case 'Quarterly':
      aspFrequencyLabel = 'p/quarter';
      break;

    case 'Half-Yearly':
      aspFrequencyLabel = 'p/half-year';
      break;

    case 'Yearly':
      aspFrequencyLabel = 'p/year';
      break;

    default:
      aspFrequencyLabel = 'p/month';
      break;
  }

  return (
    <div style={{ marginTop: theme.spacing(5), maxWidth: 650 }}>
      {/* ########## START  :: REFERENCE NUMBER ########## */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          {/* <Button
            display="block"
            variant="text"
            color='primary'
            onClick={() => onClickBackToClientList()}>
            <FormattedMessage {...messages.backToClientList} />
          </Button> */}
        </Grid>

        <Grid item>
          <Typography
            display="block"
            align="right"
            variant="body2"
            style={{ color: '#666666' }}
          >
            <FormattedMessage {...messages.reference} />:{` ${referenceNumber}`}
          </Typography>
        </Grid>
        {offerCodeData.errorMessage && (
          <Grid item xs={12}>
            <Typography
              display="block"
              align="right"
              variant="body2"
              style={{ color: 'red' }}
            >
              <FormattedMessage {...globalMessages.specialOfferExpired} />
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* ########## END    :: REFERENCE NUMBER ########## */}
      {/* ########## START  :: POLICY OWNER ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.policyOwner} />
        </Typography>
        {/* LI 1 as PO */}
        {lifeAssured.insuredOne.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredOne} />
        )}
        {/* LI 2 as PO */}
        {lifeAssured.isInsuredTwo && lifeAssured.insuredTwo.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
        {/* other POs */}
        {lifeAssured.isPolicyOwnerIndividual &&
          lifeAssured.policyOwnerIndividual.map((po, index) => {
            const key = `${po.firstName}-${index}`;
            return <ReviewUserData key={key} party={po} />;
          })}
      </Box>
      {/* ########## END    :: POLICY OWNER ########## */}
      {/* ########## START  :: LIFE INSURED ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.insuredParty} />
        </Typography>

        <ReviewUserData party={lifeAssured.insuredOne} />
        {lifeAssured.isInsuredTwo && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
      </Box>
      {/* ########## END    :: LIFE INSURED ########## */}

      {/* ########## START  :: PREMIUM SUMMARY ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.premiumSummary} />
        </Typography>
        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    {(isWap === true) ? <FormattedMessage {...messages.newSinglePremiumAmount} /> : <FormattedMessage {...messages.premiumAmount} />}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {productConfig.currency}{' '}
                {formatMonetaryValue(productConfig.premium, true)}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        {productConfig.isAdditionalSinglePremium && (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.ASP} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {productConfig.currency}{' '}
                  {formatMonetaryValue(
                    productConfig.additionalSinglePremium.amount,
                    true,
                  )}{' '}
                  {aspFrequencyLabel}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        )}

        {!isEmpty(fiaData) && wapConfig.features.haveFiaCharge ? (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs={9}>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...globalMessages.advisorFeeLabel} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {`${productConfig.fiaCharge} %`}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        ) : null}

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs={9}>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.assumedGrowthRate} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {`${productConfig.growthRate} %`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>
        {offerCodeData.code ? (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.specialOfferCode} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" align="left">
                  {offerCodeData.discountMessage?.english}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        ) : null}
      </Box>
      {/* ########## END    :: PREMIUM SUMMARY ########## */}

      {/* ########## START  :: WITHDRAWAL ########## */}
      <Box mb={5}>
        {productConfig.isRegularWithdrawal ? (
          <>
            <Typography
              variant="h3"
              style={{
                marginBottom: theme.spacing(2),
                color: theme.palette.primary.subMain,
              }}
            >
              <FormattedMessage {...messages.withdrawal} />
            </Typography>
            <InfoRow>
              <Grid
                container
                alignItems="center"
                wrap="nowrap"
                justify="space-between"
              >
                <Grid item xs={3}>
                  <Typography variant="body2">
                    {numeral(
                      productConfig.regularWithdrawal.percentage,
                    ).value() != 0
                      ? `${productConfig.regularWithdrawal.percentage}%`
                      : `${productConfig.currency} ${formatMonetaryValue(
                        productConfig.regularWithdrawal.amount,
                        false,
                      )}`}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(productConfig.regularWithdrawal.startDate).format(
                      'DD MMM YYYY',
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {moment(productConfig.regularWithdrawal.endDate).format(
                      'DD MMM YYYY',
                    )}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  <Typography variant="body2">
                    {productConfig.regularWithdrawal.frequency}
                  </Typography>
                </Grid>
              </Grid>
            </InfoRow>
          </>
        ) : null}
      </Box>
      {/* ########## END  :: WITHDRAWAL ########## */}

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
        }}
      >
        <FormattedMessage {...messages.reviewNotice} />
      </Typography>
      <InfoRow variant="document">
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <DescriptionOutlined color="inherit" />
          </Grid>
          <Grid item xs>
            <Typography variant="body1" color="inherit">
              <FormattedMessage
                {...messages.illustration}
                values={{
                  name: wapConfig.shortName,
                }}
              />
            </Typography>
          </Grid>

          <Grid item>
            <Link
              href={benefitIllustrationPdf}
              target="_blank"
              align="center"
              variant="body2"
              disabled={!benefitIllustrationPdf}
              onClick={() => updateStatus(1)}
              // replace
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.viewExportPDF} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              target="_blank"
              align="center"
              variant="body2"
              // replace
              onClick={() => {
                if (!emailSent) {
                  setOpen(true);
                } else {
                  setErrorMail(true);
                }
              }}
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.emailPDF} />
            </Link>
          </Grid>
        </Grid>
      </InfoRow>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage {...messages.emailPDF} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            id="emailAddress"
            label={<FormattedMessage {...globalMessages.email} />}
            type="email"
            error={error}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isValidEmail(email)) {
                setError(false);
                emailPdf(email);
                return setOpen(false);
              }
              return setError(true);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.send} />
          </Button>
        </DialogActions>
      </Dialog>
      {/* EMAIL ERROR  */}
      <CustomDialog
        open={errorMail}
        onClose={() => {
          setErrorMail(false);
          setEmailSent(false);
        }}
        title={<FormattedMessage {...messages.error} />}
        variant="error"
        actions={
          <Button
            onClick={() => {
              setErrorMail(false);
              return setEmailSent(false);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.close} />
          </Button>
        }
      >
        <Typography variant="h2">
          <FormattedMessage
            {...messages.errorEmail}
            values={{ message: emailErrorMessage }}
          />
        </Typography>
      </CustomDialog>
    </div>
  );
}

Review.propTypes = {
  fiaData: PropTypes.object,
  lifeAssured: PropTypes.object,
  productConfig: PropTypes.object,
  wapConfig: PropTypes.object,
  benefitIllustrationPdf: PropTypes.string,
  getQuote: PropTypes.func,
  updateStatus: PropTypes.func,
  emailPdf: PropTypes.func,
  referenceNumber: PropTypes.string,
  disableProceed: PropTypes.bool,
  onClickBackToClientList: PropTypes.func,
  offerCodeData: PropTypes.object,
  emailSent: PropTypes.bool,
  setEmailSent: PropTypes.func,
  emailErrorMessage: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({
  lifeAssured: makeSelectLifeAssured(),
  productConfig: makeSelectProductConfig(),
  benefitIllustrationPdf: makeSelectBiPdf(),
  fiaData: makeSelectFiaCharge(),
  wapConfig: makeSelectConfig(),
  referenceNumber: makeSelectReferenceNumber(),
  offerCodeData: makeSelectOfferCodeData(),
  emailSent: makeSelectemailSentStatus(),
  emailErrorMessage: makeSelectEmailErrorMessage(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: val => dispatch(updateStatusAction(val)),
    getQuote: val => dispatch(getQuoteAction(val)),
    emailPdf: value => dispatch(emailPdfAction(value)),
    setEmailSent: value => dispatch(setEmailSentAction(value)),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Review);

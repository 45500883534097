import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages';
import globalMessages from 'containers/App/messages';
import { convertIfImmutable } from 'helpers/lang';
import { isEmpty } from 'lodash/lang';

const validate = values => {
  const parsedValues = convertIfImmutable(values);
  const parties = parsedValues.parties;
  const errors = {};
  const documnetArrayError = [];

  if (!isEmpty(parsedValues)) {
    // parties.map(item => {
    //   const documentError = {};
    //   if (item['documentNeedTranslation'] === null)
    //     documentError['documentNeedTranslation'] = (
    //       <FormattedMessage {...globalMessages.pleaseSelectValue} />
    //     );
    //   documnetArrayError.push(documentError);
    // });
  }
  if (!isEmpty(documnetArrayError)) errors.parties = documnetArrayError;

  return errors;
};

export default validate;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'redux-form/immutable';
import { forEach } from 'lodash/collection';
import messages from 'containers/ApplicationFormPage/messages';
import { isFirstChild } from 'containers/ApplicationFormPage/helpers';

import { Grid, Typography, TextField, Divider } from '@material-ui/core';
import { useTheme, makeStyles } from '@material-ui/core/styles';

//components
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import { convertIfImmutable } from 'helpers/lang';

const useStyle = makeStyles(theme => ({
  title2: {
    fontWeight: 800,
    fontSize: '16px',
    color: theme.palette.primary.dark,
  },
}));

function BloodPressure(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyle();
  const {
    fields,
    question,
    questions,
    section,
    answerQuestion,
    setSectionAnswer,
    sectionName,
    eTag,
    partyId,
    sessionId,
    order,
  } = props;

  const maxValueSystolic = question.constraints.maxValSystolic;

  const minValueSystolic = question.constraints.minValSystolic;
  const checkIfErrorSystolic = index => {
    if (
      maxValueSystolic + 1 > convertIfImmutable(fields.get(index)).systolic &&
      minValueSystolic - 1 < convertIfImmutable(fields.get(index)).systolic
    ) {
      return false;
    } else {
      return true;
    }
  };

  const maxValueDiastolic = question.constraints.maxValDiastolic;

  const minValueDiastolic = question.constraints.minValDiastolic;
  const checkIfErrorDiastolic = index => {
    if (
      maxValueDiastolic + 1 > convertIfImmutable(fields.get(index)).diastolic &&
      minValueDiastolic - 1 < convertIfImmutable(fields.get(index)).diastolic
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <Grid container spacing={3}>
      {isFirstChild(questions, question) && (
        <>
          {' '}
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.title2}>
              {question.context[0]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ margin: '1rem 0' }} />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {question.text}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {fields.map((field, index) => (
          <div key={`${field}-${index}`}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Field
                  name={`${field}.systolic`}
                  component={FormNumberField}
                  fullWidth
                  label={
                    <FormattedMessage {...messages.systolicBloodPressure} />
                  }
                  helperText={`min value is ${minValueSystolic} and max value is ${maxValueSystolic}`}
                  error={checkIfErrorSystolic(index)}
                  onBlur={(event, newValue, previousValue, name) => {
                    var parsedGetAll = convertIfImmutable(fields.getAll());

                    //update the current data
                    parsedGetAll[index].systolic = newValue;

                    const payload = {
                      section,
                      questionId: question.id,
                      value: parsedGetAll,
                      questionType: question.type,
                      sessionId,
                      eTag,
                      partyId,
                      order,
                    };

                    setSectionAnswer({
                      data: { [`question-${question.id}`]: parsedGetAll },
                      sectionName: sectionName,
                    });

                    answerQuestion(payload);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  shrink
                  name={`${field}.diastolic`}
                  component={FormNumberField}
                  fullWidth
                  label={
                    <FormattedMessage {...messages.diastolicBloodPressure} />
                  }
                  helperText={`min value is ${minValueDiastolic} and max value is ${maxValueDiastolic}`}
                  error={checkIfErrorDiastolic(index)}
                  onBlur={(event, newValue, previousValue, name) => {
                    var parsedGetAll = convertIfImmutable(fields.getAll());

                    //update the current data
                    parsedGetAll[index].diastolic = newValue;

                    const payload = {
                      section,
                      questionId: question.id,
                      value: parsedGetAll,
                      questionType: question.type,
                      sessionId,
                      eTag,
                      partyId,
                    };

                    setSectionAnswer({
                      data: { [`question-${question.id}`]: parsedGetAll },
                      sectionName: sectionName,
                    });

                    answerQuestion(payload);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        ))}
      </Grid>
    </Grid>
  );
}

BloodPressure.propTypes = {
  question: PropTypes.object,
};

export default BloodPressure;

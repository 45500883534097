/**
 *
 * Review
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
  Typography,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from '@material-ui/core';
import CustomDialog from 'components/Dialog';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import {
  Cancel,
  CheckCircle,
  DescriptionOutlined,
  Info,
} from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import InfoRow from 'components/InfoRow';
import ReviewUserData from 'components/ReviewUserData';
import NoticeBox from 'components/NoticeBox';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import {
  getBrTitle,
  getAgeOfLife,
  getChosenInsured,
  isValidEmail,
} from '../helper';
import { GET_QUOTE_CREATED } from '../constants';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectBiPdf,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
  makeSelectPolicyConfig,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectSustainabilityYears,
  makeShowWarningReview,
} from '../selectors';
import {
  getQuoteAction,
  updateStatusAction,
  emailPdfAction,
  setEmailSentAction,
} from '../actions';
import messages from '../messages';

function Review({
  lifeAssured,
  productConfig,
  getQuote,
  benefitIllustrationPdf,
  updateStatus,
  emailPdf,
  referenceNumber,
  emailSent,
  setEmailSent,
  emailErrorMessage,
  isShowWarningReview,
  initProductConfig,
}) {
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [errorMail, setErrorMail] = useState(false);
  const [standardData, setStandardData] = useState([]);
  const [specialTermsData, setSpecialTermsData] = useState([]);
  const [initSinglePremiumTerm] = useState(initProductConfig.singlePremiumTerm);
  const chosenInsured = getChosenInsured(lifeAssured);

  useEffect(() => {
    getQuote({
      type: GET_QUOTE_CREATED,
    });
  }, []);

  useEffect(() => {
    if (emailSent) {
      setErrorMail(true);
    }
  }, [emailSent]);

  useEffect(() => {
    return () => {
      setEmailSent(false);
    };
  }, []);

  useEffect(() => {
    const standardDataArray = [];
    const specialTermsDataArray = [];
    productConfig.coverages?.map(cvg => {
      const hasBenefitRating =
        cvg.brSelectedInsuredOneValue ||
        cvg.brSelectedInsuredTwoValue ||
        cvg.brSelectedPercentValueOne ||
        cvg.brSelectedPercentValueTwo ||
        cvg.brSelectedTempValueTwo ||
        cvg.brSelectedTempValueOne;
      standardDataArray.push(cvg);
      setStandardData(standardDataArray);

      if (hasBenefitRating) {
        specialTermsDataArray.push(cvg);
        setSpecialTermsData(specialTermsDataArray);
      }
    });
  }, [productConfig]);

  const getPaymentFrequency = (frequency,singlePremiumTerm) => {
    switch (frequency) {
      case 'Monthly':
        return 'p/month';

      case 'Quarterly':
        return 'p/quarter';

      case 'Half-Yearly':
        return 'p/half-year';

      case 'Yearly':
        return 'p/year';

      case 'Single':
        if (singlePremiumTerm > 0){
          return 'per year';
        } else {
          return 'Single time';
        }
        
      default:
        return '';
    }
  };

  const ageOfLife = getAgeOfLife(
    lifeAssured.lifeBasisHarvest,
    lifeAssured,
    chosenInsured,
    productConfig.startDate,
  );

  return (
    <Box pb={3}>
      {isShowWarningReview && (
        <Grid container>
          <Grid item xs={12}>
            <NoticeBox variant="info" mb={4}>
              <Typography variant="body2" color="primary">
                Please note: the premium you have chosen is higher than the
                minium amount required to sustain this policy for Whole Of Life
              </Typography>
            </NoticeBox>
          </Grid>
        </Grid>
      )}

      {/* ########## START  :: REFERENCE NUMBER ########## */}
      <Typography
        display="block"
        align="right"
        variant="body2"
        style={{ marginBottom: '4.4rem' }}
      >
        <FormattedMessage {...messages.reference} />:{` ${referenceNumber}`}
      </Typography>
      {/* ########## END    :: REFERENCE NUMBER ########## */}
      {/* ########## START  :: POLICY OWNER ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.policyOwner} />
        </Typography>
        {/* LI 1 as PO */}
        {lifeAssured.insuredOne.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredOne} />
        )}
        {/* LI 2 as PO */}
        {lifeAssured.isInsuredTwo && lifeAssured.insuredTwo.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
        {/* other POs */}
        {lifeAssured.isPolicyOwnerIndividual &&
          lifeAssured.policyOwnerIndividual.map((po, index) => {
            const key = `${po.firstName}-${index}`;
            return <ReviewUserData key={key} party={po} />;
          })}
      </Box>
      {/* ########## END    :: POLICY OWNER ########## */}
      {/* ########## START  :: LIFE INSURED ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.insuredParty} />
        </Typography>

        <ReviewUserData party={lifeAssured.insuredOne} />
        {lifeAssured.isInsuredTwo && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
      </Box>
      {/* ########## END    :: LIFE INSURED ########## */}

      {/* ########## START  :: PREMIUM SUMMARY ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.premiumSummary} />
        </Typography>
        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.premiumAmount} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {productConfig.currency}{' '}
                {formatMonetaryValue(productConfig.premium, true)}{' '}
                {getPaymentFrequency(productConfig.paymentFrequency,productConfig.singlePremiumTerm)}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        {/* ======== SINGLE PREMIUM ====== */}
        {/* {productConfig.singlePremium > 0 && (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.singlePremium} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {productConfig.currency}{' '}
                  {formatMonetaryValue(productConfig.singlePremium, false)}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        )} */}
        {/* ======== SINGLE PREMIUM END ====== */}

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.coverageTerm} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {productConfig.policyTerm !== 'WholeOfLife'
                  ? `${productConfig.policyTerm} years`
                  : 'Whole Of Life'}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.premiumPaymentTerm} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
              {productConfig.term === 'SinglePremium'
                  ? 'Single premium'
                  : productConfig.term === 'WholeOfLife'
                  ? 'Whole of life'
                  : productConfig.singlePremiumTerm > 0
                  ? initSinglePremiumTerm === 3 && productConfig.singlePremiumTerm === 2
                    ? '1 Year'
                    : productConfig.singlePremiumTerm === 1
                      ? '1 Year'
                      : `${productConfig.singlePremiumTerm} Years`
                  : `${productConfig.term} Years`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.assumedGrowthRate} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2">
                {`${productConfig.growthRate} %`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        {productConfig.fiaCharge !== '' && productConfig.fiaCharge !== 0 && (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.fiaText} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2">
                  {`${productConfig.fiaCharge} %`}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        )}
      </Box>
      {/* ########## END    :: PREMIUM SUMMARY ########## */}

      {/* ########## START  :: SPECIAL TERMS DETAILS ########## */}
      {productConfig.specialTermsFlag &&
      !productConfig.isInforce &&
      specialTermsData.length > 0 ? (
        <Box mb={5}>
          <Typography
            variant="h3"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.subMain,
            }}
          >
            <FormattedMessage {...messages.specialTerms} />
          </Typography>

          {specialTermsData.map((cvg, index) => {
            const key = `$specTerms${index}`;
            return (
              <Grid container direction="column" key={key}>
                <InfoRow style={{ maxHeight: 64 }}>
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          style={{
                            color: theme.palette.grey[400],
                          }}
                        >
                          <Info color="primary" />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">{cvg.title}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {cvg.id === 'WOP' || cvg.id === 'ESC'
                          ? cvg.valueInsuredOne
                            ? 'Yes'
                            : 'No'
                          : cvg.selected
                          ? `${productConfig.currency} ${formatMonetaryValue(
                              cvg.valueInsuredOne,
                              false,
                            )}`
                          : '-'}
                      </Typography>
                    </Grid>

                    {lifeAssured.isInsuredTwo ? (
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {cvg.id === 'WOP' || cvg.id === 'ESC'
                            ? cvg.valueInsuredTwo
                              ? 'Yes'
                              : 'No'
                            : cvg.selected
                            ? `${productConfig.currency} ${formatMonetaryValue(
                                cvg.valueInsuredTwo,
                                false,
                              )}`
                            : '-'}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </InfoRow>

                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue ||
                  cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                  <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.dark,
                        paddingBottom: theme.spacing(1),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...messages.permanent} />
                    </Typography>
                  </InfoRow>
                )}
                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue) && (
                  <InfoRow variant="br">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          ></Grid>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {getBrTitle('1')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        {!cvg.brSelectedInsuredOneValue ? (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            -
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedInsuredOneValue
                              ? `+${cvg.perMileOne.toFixed(2)}`
                              : `-`}
                          </Typography>
                        )}
                      </Grid>

                      {lifeAssured.isInsuredTwo ? (
                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedInsuredTwoValue
                              ? `+${cvg.perMileTwo.toFixed(2)}`
                              : `-`}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </InfoRow>
                )}

                {(cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                  <InfoRow variant="br">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          ></Grid>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {getBrTitle('2')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        {!cvg.brSelectedPercentValueOne ? (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            -
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedPercentValueOne
                              ? `+${cvg.perPercentageOne}`
                              : '-'}
                          </Typography>
                        )}
                      </Grid>

                      {lifeAssured.isInsuredTwo ? (
                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedPercentValueTwo
                              ? `+${cvg.perPercentageTwo}`
                              : '-'}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </InfoRow>
                )}

                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.dark,
                        paddingBottom: theme.spacing(1),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...messages.temporary} />
                    </Typography>
                  </InfoRow>
                )}
                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <>
                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('1')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMileOne !== 0
                              ? `+${cvg.perTempMileOne.toFixed(2)}`
                              : '-'}
                          </Typography>
                        </Grid>

                        {lifeAssured.isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMileTwo !== 0
                                ? `+${cvg.perTempMileTwo.toFixed(2)}`
                                : '-'}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>

                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('3')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMonthOne !== 0
                              ? `+${cvg.perTempMonthOne} months`
                              : '-'}
                          </Typography>
                        </Grid>

                        {lifeAssured.isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMonthTwo !== 0
                                ? `+${cvg.perTempMonthTwo} month`
                                : '-'}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>
                  </>
                )}
              </Grid>
            );
          })}
        </Box>
      ) : null}
      {/* ########## END    :: SPECIAL TERMS DETAILS ########## */}

      {/* ########## START  :: COVER DETAILS ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.coverDetails} />
        </Typography>

        {standardData.map((cvg, index) => {
          if (!cvg.selected) {
            return null;
          }
          return (
            <Grid container direction="column" key={cvg.id}>
              <InfoRow style={{ maxHeight: 64 }}>
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  justify="space-between"
                >
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        style={{
                          color: theme.palette.grey[400],
                        }}
                      >
                        {cvg.selected ? (
                          <CheckCircle color="inherit" />
                        ) : (
                          <Cancel color="inherit" />
                        )}
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2">{cvg.title}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="body2">
                      {cvg.id === 'WOP'
                        ? cvg.valueInsuredOne
                          ? 'Yes'
                          : 'No'
                        : cvg.selected
                        ? `${productConfig.currency} ${formatMonetaryValue(
                            cvg.valueInsuredOne,
                            false,
                          )}`
                        : '-'}
                    </Typography>
                  </Grid>

                  {lifeAssured.isInsuredTwo ? (
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {cvg.id === 'WOP'
                          ? cvg.valueInsuredTwo
                            ? 'Yes'
                            : 'No'
                          : cvg.selected
                          ? `${productConfig.currency} ${formatMonetaryValue(
                              cvg.valueInsuredTwo,
                              false,
                            )}`
                          : '-'}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </InfoRow>
            </Grid>
          );
        })}
      </Box>
      {/* ########## END    :: COVER DETAILS ########## */}

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
        }}
      >
        <FormattedMessage {...messages.reviewNotice} />
      </Typography>
      <InfoRow variant="document">
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <DescriptionOutlined color="inherit" />
          </Grid>
          <Grid item xs>
            <Typography variant="body1" color="inherit">
              <FormattedMessage {...messages.futuraIllustration} />
            </Typography>
          </Grid>

          <Grid item>
            <Link
              href={benefitIllustrationPdf}
              target="_blank"
              align="center"
              variant="body2"
              disabled={!benefitIllustrationPdf}
              onClick={() => {
                if(productConfig.singlePremiumTerm >= 0 && productConfig.regularPremiumSliceLength === 0 && productConfig.premium === 0 && productConfig.isMerged === false && productConfig.singlePremium === 0 && productConfig.isCalculateSustainability === true){
                  updateStatus(0);
                } else {
                  updateStatus(1);
                }
              }}
              replace
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.viewExportPDF} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              target="_blank"
              align="center"
              variant="body2"
              replace
              onClick={() => {
                if (!emailSent) {
                  setOpen(true);
                } else {
                  setErrorMail(true);
                }
              }}
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.emailPDF} />
            </Link>
          </Grid>
        </Grid>
      </InfoRow>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage {...messages.emailPDF} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            id="emailAddress"
            label={<FormattedMessage {...globalMessages.email} />}
            type="email"
            error={error}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isValidEmail(email)) {
                setError(false);
                emailPdf(email);
                return setOpen(false);
              }
              return setError(true);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.send} />
          </Button>
        </DialogActions>
      </Dialog>
      {/* EMAIL ERROR  */}
      <CustomDialog
        open={errorMail}
        onClose={() => {
          setErrorMail(false);
          setEmailSent(false);
        }}
        title={<FormattedMessage {...messages.error} />}
        variant="error"
        actions={
          <Button
            onClick={() => {
              setErrorMail(false);
              return setEmailSent(false);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.close} />
          </Button>
        }
      >
        <Typography variant="h2">
          <FormattedMessage
            {...messages.errorEmail}
            values={{ message: emailErrorMessage }}
          />
        </Typography>
      </CustomDialog>
    </Box>
  );
}

Review.propTypes = {
  lifeAssured: PropTypes.object,
  productConfig: PropTypes.object,
  policyConfig: PropTypes.object,
  benefitIllustrationPdf: PropTypes.string,
  getQuote: PropTypes.func,
  updateStatus: PropTypes.func,
  emailPdf: PropTypes.func,
  referenceNumber: PropTypes.string,
  fiaData: PropTypes.object,
  offerCodeData: PropTypes.object,
  emailSent: PropTypes.bool,
  setEmailSent: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  sustainabilityYears: PropTypes.number,
};

const mapStateToProps = createStructuredSelector({
  lifeAssured: makeSelectLifeAssured(),
  productConfig: makeSelectProductConfig(),
  policyConfig: makeSelectPolicyConfig(),
  benefitIllustrationPdf: makeSelectBiPdf(),
  referenceNumber: makeSelectReferenceNumber(),
  fiaData: makeSelectFiaCharge(),
  offerCodeData: makeSelectOfferCodeData(),
  emailSent: makeSelectemailSentStatus(),
  emailErrorMessage: makeSelectEmailErrorMessage(),
  sustainabilityYears: makeSelectSustainabilityYears(),
  isShowWarningReview: makeShowWarningReview(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: val => dispatch(updateStatusAction(val)),
    getQuote: value => dispatch(getQuoteAction(value)),
    emailPdf: value => dispatch(emailPdfAction(value)),
    setEmailSent: value => dispatch(setEmailSentAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Review);

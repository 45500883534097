/**
 *
 * ApplicationPage
 *
 */

import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory, useLocation, Switch, Route } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import reducer from './reducer';
import saga from './saga';

import {
  callInitialSessionAction,
  getApplicationDetailsAction,
  callFinancialInstituteOptionAction,
} from './actions';

// components
import Application from './components/Application';
import ApplicationList from './components/ApplicationList';
import ApplicationFormPage from 'containers/ApplicationFormPage';
import { makeSelectListApplicationParty } from './selectors';
import { makeSelectAgentDetails } from 'containers/App/selectors';
import { getListApplicationsPartyAction } from 'containers/ApplicationPage/actions';

const useStyles = makeStyles(theme => ({}));

export function ApplicationPage(props) {
  useInjectReducer({ key: 'applicationPage', reducer });
  useInjectSaga({ key: 'applicationPage', saga });
  const history = useHistory();
  const theme = useTheme();
  const classes = useStyles();
  const location = useLocation();
  const [valueSearch, setValueSearch] = useState('');

  useEffect(() => {
    const agencyNumber = agentDetails.agentNumber;
    getApplicationListParty({ valueSearch: '' });
  }, []);

  const onSearch = () => {
    const agencyNumber = agentDetails.agentNumber;
    getApplicationListParty({ valueSearch });
  };

  const {
    getInterviewSessionId,
    getApplicationDetails,
    getFinancialInstitute,
    agentDetails,
    listApplication,
    getApplicationListParty,
  } = props;

  return (
    <div>
      <Switch>
        <Route
          path="/applications"
          exact
          render={props => (
            <ApplicationList
              {...props}
              setValueSearch={setValueSearch}
              onSearch={onSearch}
              listApplication={listApplication}
            />
          )}
        />
        <Route
          path="/applications/:applicationId"
          exact
          render={props => (
            <Application
              {...props}
              getApplicationDetails={getApplicationDetails}
              getFinancialInstitute={getFinancialInstitute}
            />
          )}
        />
        <Route
          path="/applications/:applicationId/form"
          render={props => (
            <ApplicationFormPage
              {...props}
              getInterviewSessionId={getInterviewSessionId}
            />
          )}
        />
      </Switch>
    </div>
  );
}

// ApplicationPage.propTypes = {};

const mapStateToProps = createStructuredSelector({
  agentDetails: makeSelectAgentDetails(),
  listApplication: makeSelectListApplicationParty(),
});

function mapDispatchToProps(dispatch) {
  return {
    getInterviewSessionId: value => dispatch(callInitialSessionAction(value)),
    getApplicationDetails: value =>
      dispatch(getApplicationDetailsAction(value)),
    getFinancialInstitute: () => dispatch(callFinancialInstituteOptionAction()),
    getApplicationListParty: value =>
      dispatch(getListApplicationsPartyAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ApplicationPage);

/*
 *
 * OktaAuthCallback reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SET_OKTA_TOKEN_ACTION,
  SET_ERROR_ACTION,
  RESET_TO_DEFAULT_ACTION,
} from './constants';

export const defaultState = {
  okta: {
    accessToken: null,
  },
  error: {
    message: null,
  },
};

export const initialState = fromJS(defaultState);

function oktaAuthCallbackReducer(state = initialState, action) {
  switch (action.type) {
    case SET_OKTA_TOKEN_ACTION:
      return state.setIn(['okta', 'accessToken'], action.payload);
    case SET_ERROR_ACTION:
      return state.setIn(['error', 'message'], action.payload.message);
    case RESET_TO_DEFAULT_ACTION:
      return fromJS(defaultState);
    default:
      return state;
  }
}

export default oktaAuthCallbackReducer;

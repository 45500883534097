/**
 * BeneficiaryDetails
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Field, FormSection } from 'redux-form/immutable';

import { newPayorProceeds } from 'helpers/placeholder';
import {
  Grid,
  FormControlLabel,
  Radio,
  Button,
  Divider,
  IconButton,
  Link,
} from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';

const useStyle = makeStyles(theme => ({
  container: {
    marginTop: '1rem',
  },
  button: {
    fontWeight: 800,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  hr: {
    margin: '0 0rem',
    borderTop: '1px solid #DDDFE0',
  },
}));

function PayorProceeds(props) {
  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyle();

  const { fields, formValues, options } = props;

  const addProceeds = () => {
    if (fields.length < 2) {
      fields.push({ ...newPayorProceeds });
    }
  };

  return fields.map((field, index) => {
    return (
      <Grid container spacing={3} className={classes.container} key={index}>
        {index === 0 ? '' : 
          <Grid item xs={12}>
            <hr className={classes.hr} />
          </Grid>}
        <Grid item xs={4}>
          <Field
            name={`${field}.saleCurrency`}
            component={FormSelect}
            label={<FormattedMessage {...messages.saleCurrency} />}
            options={[
              { value: 'Select', title: 'Select an option' },
              ...options.currency,
            ]}
            shrink
            fullWidth
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            name={`${field}.totalSaleAmount`}
            component={FormNumberField}
            label={<FormattedMessage {...messages.totalSaleAmount} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.addressProperty`}
            component={FormTextField}
            label={<FormattedMessage {...messages.addressProperty} />}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseEnter)}
          />
        </Grid>
        <Grid item xs={8}>
          <Field
            name={`${field}.sharesProperty`}
            component={FormTextField}
            label={<FormattedMessage {...messages.sharesProperty} />}
            multiline
            rows={3}
            shrink
            fullWidth
            placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          />
        </Grid>

        <Grid item xs={4}>
          <div className={classes.buttonContainer}>
            <Grid container spacing={0}>
              {fields.length <= 1 && fields.length === index + 1 && (
                <Grid item xs={6}>
                  <Link className={classes.button} onClick={addProceeds}>
                    <FormattedMessage {...messages.addAnother} />
                  </Link>
                </Grid>
              )}
              {fields.length === 1 ? (
                ''
              ) : (
                <Grid item xs={6}>
                  <Link
                    className={classes.button}
                    onClick={() => {
                      fields.remove(index);
                    }}
                  >
                    <FormattedMessage {...messages.removeItem} />
                  </Link>
                </Grid>
              )}
            </Grid>
          </div>
        </Grid>

        {/* <Grid item xs={12}>
          <hr className={classes.hr} />
        </Grid> */}
      </Grid>
    );
  });
}

PayorProceeds.propTypes = {
  fields: PropTypes.object,
  formValues: PropTypes.object,
  options: PropTypes.object,
};

export default PayorProceeds;

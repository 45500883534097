/*
 *
 * ApplicationFormPage reducer
 *
 */

import { fromJS, List } from 'immutable';

import { keys } from 'lodash/object';
import { forEach } from 'lodash/collection';
import { SET_FILE } from 'containers/App/constants';
import {
  DEFAULT_ACTION,
  SET_SECTION_QUESTIONS_ACTION,
  SET_SECTION_ACTION,
  SET_SECTION_QUESTIONS_PROPERTY,
  SET_PAYMENT_TYPE,
  SET_PAYMENT_DETAIL,
  SET_PAYMENT_DATA_ACTION,
  SET_CONVERT_CURRENCY_ACTION,
  SET_CHANGE_CURRENCY_ACTION,
  SET_DOCTOR_LIST_ACTION,
  SET_VALID_FUNDS_ACTION,
  SET_SELECTED_FUNDS_ACTION,
  SET_UW_STATUS,
  SET_REQUEST_SIGNS_ACTION,
  SET_DOCUMENT_STATUS_ACTION,
  SET_ANSWERS_EMPTY,
  SET_QUESTIONS_EMPTY,
  SET_SUMMARY_LINK_ACTION,
  SET_CONSENT_LINK_ACTION,
  SET_FILE_UW_ACTION,
  SET_COUNT_IDV_SUBMIT,
  SET_EMAIL_ERROR_MESSAGE_ACTION,
  SET_EMAIL_SENT_ACTION,
  SET_FUNDS_BELONG_TO_NEXUS_OR_DEVER,
  SET_OTHER_DOCUMENT_STATUS_ACTION
} from './constants';

const defaultState = {
  emailErrorMessage: '',
  emailSent: false,
  questions: { Loading: {} },
  answers: { Loading: {} },
  doctorsList: [],
  paymentType: '',
  paymentDetail: {},
  payment: {
    status: null,
  },
  convertCurrency: '',
  changeCurrency: '',
  validFunds: {},
  UWStatus: {},
  consentStatus: {},
  documentStatus: {},
  otherDocumentStatus: {},
  summaryLink: '',
  consentLink: '',
  uwLink: '',
  countIdvSubmit: 0,
  fundsBelongToNexusOrDever: false,
};

export const initialState = fromJS({ ...defaultState });

function applicationFormPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SECTION_QUESTIONS_ACTION:
      return state.setIn(
        ['questions', action.payload.sectionName],
        fromJS(new List(action.payload.questions)),
      );
    case SET_SECTION_ACTION:
      return state.setIn(
        ['answers', action.payload.sectionName],
        fromJS({
          ...state.toJS().answers[action.payload.sectionName],
          ...action.payload.data,
        }),
      );
    case SET_SECTION_QUESTIONS_PROPERTY:
      const combinedAnswers = {
        ...action.payload.answers,
        ...state.toJS().answers[action.payload.sectionName],
      };

      let parsedAnswers = {};

      // dynamic adjustment of answer properties

      forEach(keys(combinedAnswers), key => {
        forEach(keys(action.payload.answers), key2 => {
          if (key === key2) {
            parsedAnswers = {
              ...parsedAnswers,
              [key2]: combinedAnswers[key2],
            };
          }
        });
      });

      return state.setIn(
        ['answers', action.payload.sectionName],
        fromJS(parsedAnswers),
      );
    case SET_PAYMENT_TYPE:
      return state.set('paymentType', fromJS(action.payload));
    case SET_PAYMENT_DETAIL:
      return state.set('paymentDetail', fromJS(action.payload));
    case SET_DOCTOR_LIST_ACTION:
      return state.set('doctorsList', fromJS(action.payload));
    case SET_PAYMENT_DATA_ACTION:
      return state.set('payment', fromJS(action.payload));
    case SET_CONVERT_CURRENCY_ACTION:
      return state.set('convertCurrency', fromJS(action.payload));
    case SET_CHANGE_CURRENCY_ACTION:
      return state.set('changeCurrency', fromJS(action.payload));
    case SET_VALID_FUNDS_ACTION:
      return state.set('validFunds', fromJS(action.payload));
    case SET_UW_STATUS:
      return state.set('UWStatus', fromJS(action.payload));
    case SET_REQUEST_SIGNS_ACTION:
      return state.set('consentStatus', fromJS(action.payload));
    case SET_ANSWERS_EMPTY:
      return state.set('answers', fromJS({ Loading: {} }));
    case SET_QUESTIONS_EMPTY:
      return state.set('questions', fromJS({ Loading: {} }));
    case SET_DOCUMENT_STATUS_ACTION:
      return state.set('documentStatus', fromJS(action.payload));
    case SET_CONSENT_LINK_ACTION:
      return state.set('consentLink', fromJS(action.payload));
    case SET_FILE_UW_ACTION:
      return state.set('uwLink', fromJS(action.payload));
    case SET_COUNT_IDV_SUBMIT:
      return state.set('countIdvSubmit', fromJS(action.payload));
    case SET_EMAIL_SENT_ACTION:
      return state.set('emailSent', action.payload);
    case SET_EMAIL_ERROR_MESSAGE_ACTION:
      return state.set('emailErrorMessage', action.payload);
    case SET_FUNDS_BELONG_TO_NEXUS_OR_DEVER:
      return state.set('fundsBelongToNexusOrDever', action.payload);
    case SET_OTHER_DOCUMENT_STATUS_ACTION:
      return state.set('otherDocumentStatus', fromJS(action.payload))
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default applicationFormPageReducer;

import React from 'react';
import { Grid, Typography, Collapse, IconButton, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Field } from 'redux-form/immutable';
import { WrappedFieldArrayProps } from 'redux-form';
import { Cancel, CheckCircle } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';

import FormSlider from 'components/FormSlider';
import FormBooleanOption from 'components/FormBooleanOption';
import { formatMonetaryValue } from 'helpers/math';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormNumberField from 'components/FormNumberField';
import { calculateAgeNextBirthDate } from 'helpers/date';
import { getMinValue, getMaxValue, getQuoteStartDate } from '../../helper';
import {
  JLBD,
  OLDER_FUTURA,
} from 'containers/InforceIllutrationPage/constants';

const IconTitle = ({ isCoverageSelected, isEdited, theme }) => {
  if (isEdited && isCoverageSelected) {
    return <InfoIcon style={{ color: theme.palette.primary.main }} />;
  }

  if (isCoverageSelected && !isEdited) {
    return <CheckCircle style={{ color: theme.palette.success.main }} />;
  }

  return <Cancel style={{ color: theme.palette.grey[400] }} />;
};

const Benefits = ({
  fields,
  coverages,
  policyConfig,
  currency,
  isInsuredTwo,
  isResetForm,
  productConfig,
  isCoveragesError,
  editData,
  lifeBasis,
  lifeAssured,
  changeEditData,
  onCoverageChange,
  isLock,
  change,
  isPartialWithdrawal
}) => {
  const theme = useTheme();
  const quoteStartDate = getQuoteStartDate();
  const currentDate = moment().format('YYYY-MM-DD');
  return (
    <Box>
      {fields.map((benefit, index) => {
        const myBenefit = fields.getAll().toJS();
        let isCoverageSelected =
          (coverages[index].selected &&
            coverages[index].valueInsuredOne &&
            coverages[index].valueInsuredOne !== '0') ||
          (coverages[index].selected &&
            coverages[index].valueInsuredTwo &&
            coverages[index].valueInsuredTwo !== '0');

        // check temporary and permanent loadings of life one

        let ratingActiveLifeOne = false;
        let ratingActiveLifeTwo = false;
        let startDate;
        let endDate;

        let isLifeOnePermLoadingsAvailable = (coverages[index].brSelectedInsuredOneValue == true 
                                  || coverages[index].brSelectedPercentValueOne == true); 

        let isLifeOneTempLoadingsAvailable = (coverages[index].brSelectedTempValueOne == true);

        if (isLifeOnePermLoadingsAvailable || isLifeOneTempLoadingsAvailable) {
          if (isLifeOnePermLoadingsAvailable) {
            startDate = moment(coverages[index].riskStartDate).format('YYYY-MM-DD');
            endDate = moment(coverages[index].riskEndDate).format('YYYY-MM-DD');
          }

          if (isLifeOneTempLoadingsAvailable) {
            startDate = moment(coverages[index].chargeStartDate).format('YYYY-MM-DD');
            endDate = moment(coverages[index].chargeEndDate).format('YYYY-MM-DD');
          }

          ratingActiveLifeOne = moment(currentDate).isBetween(startDate, endDate);
      }
      coverages[index].isLifeOneRatingActive = ratingActiveLifeOne;

      
        // to check whether benefits of life 2 are rated
        let isLifeTwoPermLoadingsAvailable = (coverages[index].brSelectedInsuredTwoValue == true 
          || coverages[index].brSelectedPercentValueTwo == true); 

        let isLifeTwoTempLoadingsAvailable = (coverages[index].brSelectedTempValueTwo == true);

        if (isLifeTwoPermLoadingsAvailable || isLifeTwoTempLoadingsAvailable) {
        if (isLifeTwoPermLoadingsAvailable) {
        startDate = moment(coverages[index].riskStartDate).format('YYYY-MM-DD');
        endDate = moment(coverages[index].riskEndDate).format('YYYY-MM-DD');
        }

        if (isLifeTwoTempLoadingsAvailable) {
        startDate = moment(coverages[index].chargeStartDate).format('YYYY-MM-DD');
        endDate = moment(coverages[index].chargeEndDate).format('YYYY-MM-DD');
        }

        ratingActiveLifeTwo = moment(currentDate).isBetween(startDate, endDate);
        }
        coverages[index].isLifeTwoRatingActive = ratingActiveLifeTwo;

        let canIncreaseBenefitOne = true;
        let canIncreaseBenefitTwo = true;

        let isLifetwoWOPDisabled = false;

        const ageOfLifeInsuredOne = calculateAgeNextBirthDate(
          quoteStartDate,
          lifeAssured.insuredOne.dateOfBirth,
        );

        const ageOfLifeInsuredTwo = calculateAgeNextBirthDate(
          quoteStartDate,
          lifeAssured.isInsuredTwo && lifeAssured.insuredTwo.dateOfBirth,
        );

        if (myBenefit[index].maxEntryAgeLA < ageOfLifeInsuredOne) {
          canIncreaseBenefitOne = false;
        }
// life 2 WOP disabled for OLDER FUTURA variations till FUTU3
        isLifetwoWOPDisabled = OLDER_FUTURA.includes(policyConfig.productCode);

        if (myBenefit[index].maxEntryAgeLA < ageOfLifeInsuredTwo) {
          canIncreaseBenefitTwo = false;
        }

        let isDisabledLifeOne = false;
        let isDisabledLifeTwo = false;
        if (myBenefit[index].id === 'CRI') {
          coverages.map(benefitData => {
            if (benefitData.id === 'CCC' && benefitData.selected) {
              const isBenefitOneSelected =
                Number(benefitData.valueInsuredOne) > 0;
              const isBenefitTwoSelected =
                Number(benefitData.valueInsuredTwo) > 0;

              if (isBenefitOneSelected) {
                isDisabledLifeOne = true;
              }

              if (isBenefitTwoSelected) {
                isDisabledLifeTwo = true;
              }
            }
          });
        }

        if (myBenefit[index].id === 'CCC') {
          coverages.map(benefitData => {
            if (benefitData.id === 'CRI' && benefitData.selected) {
              const isBenefitOneSelected =
                Number(benefitData.valueInsuredOne) > 0;
              const isBenefitTwoSelected =
                Number(benefitData.valueInsuredTwo) > 0;

              if (isBenefitOneSelected) {
                isDisabledLifeOne = true;
              }

              if (isBenefitTwoSelected) {
                isDisabledLifeTwo = true;
              }
            }
          });
        }

        //  for JLFD option in earlier versions of Futura, the two lives can have different benefits.
        const isSyncBenefits =
          lifeBasis !== JLBD &&
          !OLDER_FUTURA.includes(policyConfig.productCode);

        if (isSyncBenefits) {
          isDisabledLifeTwo = true;
        }

        isDisabledLifeOne =
          isDisabledLifeOne ||
          (!canIncreaseBenefitOne &&
            Number(coverages[index].initValueInsuredOne) === 0);

        isDisabledLifeTwo =
          isDisabledLifeTwo ||
          (!canIncreaseBenefitTwo &&
            Number(coverages[index].initValueInsuredTwo) === 0);

        if (myBenefit[index].id === 'WOP') {
          isDisabledLifeTwo = false;
          if (!canIncreaseBenefitOne) {
            isDisabledLifeOne = true;
          }
          if (!canIncreaseBenefitTwo) {
            isDisabledLifeTwo = true;
          }
          if (!canIncreaseBenefitOne && coverages[index].initValueInsuredOne) {
            isDisabledLifeOne = false;
          }

          if (!canIncreaseBenefitTwo && coverages[index].initValueInsuredTwo) {
            isDisabledLifeTwo = false;
          }
        }

        if (!canIncreaseBenefitTwo && !lifeAssured?.insuredTwo?.isExisting) {
          change(`${benefit}.valueInsuredTwo`, 0);
          change(`${benefit}.termTwo`, 0);
        }

        if (['JLLS', 'JLLD'].includes(lifeBasis)) {
          if (!['LFC', 'WOP', 'ESC'].includes(myBenefit[index].id)) {
            isDisabledLifeTwo = true;
            isDisabledLifeOne = true;
          }
        }
       
        return (
          <Box
            pl={3}
            pr={1}
            py={2}
            borderBottom={`1px solid ${theme.palette.divider}`}
            key={index}
          >
            <Grid container alignItems="center" spacing={1} wrap="nowrap">
              <Grid item>
                <Grid container alignItems="center" justify="center">
                  <IconTitle
                    isCoverageSelected={isCoverageSelected}
                    isEdited={
                      myBenefit[index].isLifeOneEdit ||
                      myBenefit[index].isLifeTwoEdit
                    }
                    theme={theme}
                  />
                </Grid>
              </Grid>

              <Grid item xs>
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 700,
                    color: isLock
                      ? theme.palette.grey[400]
                      : theme.palette.common.black,
                  }}
                >
                  {coverages[index].title} 
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Grid
                  container
                  alignItems="center"
                  justify="space-between"
                  spacing={1}
                >
                  <Grid item xs={8}>
                    {editData.editIndex === index &&
                    editData.editInsured === 1 &&
                    coverages[index].id !== 'WOP' &&
                    coverages[index].id !== 'ESC' &&
                    coverages[index].id !== 'FMI' &&
                    coverages[index].id !== 'FTI' ? (
                      <Field
                        shrink
                        name={`${benefit}.valueInsuredOne`}
                        fullWidth
                        component={FormNumberField}
                        decimalScale={0}
                        onChange={(e, newVal) => {
                          onCoverageChange();
                          if (!isResetForm) {
                            change(`${benefit}.isLifeOneEdit`, true);
                          }
                          isSyncBenefits &&
                            canIncreaseBenefitTwo &&
                            change(`${benefit}.valueInsuredTwo`, newVal);
                        }}
                        variant="standard"
                      />
                    ) : (
                      <Typography
                        variant="body1"
                        id={`${coverages[index].id}.insuredOne`}
                        style={{
                          fontWeight: 700,
                          color: isDisabledLifeOne
                            ? theme.palette.grey[400]
                            : theme.palette.common.black,
                        }}
                      >
                        {coverages[index].type === '1'
                          ? formatMonetaryValue(
                              coverages[index].valueInsuredOne,
                              false,
                            )
                          : coverages[index].valueInsuredOne
                          ? 'Yes'
                          : 'No '}
                      </Typography>
                    
                    )}
                    {(coverages[index] && coverages[index].isLifeOneRatingActive &&
                    <div style={{ color: theme.palette.primary.dark, fontWeight: 'bold'}}>
                        [Rated]
                    </div>
                    )}
                </Grid>
                  {/* below is the display to edit the benefit values -- pencil icon */}
                  {!isDisabledLifeOne && !isCoveragesError && !isPartialWithdrawal && (
                    <Grid item xs={4}>
                      <IconButton
                        // disabled={isDisabledLifeOne || isCoveragesError}
                        style={{
                          paddingRight: 12,
                        }}
                        onClick={() => {
                          change(`${benefit}.selected`, true);
                          changeEditData({
                            editIndex: index,
                            editInsured: 1,
                          });
                        }}
                      >
                        <img
                          style={{ height: '1.6rem' }}
                          src={IconPencilBlue}
                          alt=""
                        />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {isInsuredTwo && (
                <Grid item xs={3}>
                  <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={8}>
                      {editData.editIndex === index &&
                      editData.editInsured === 2 &&
                      coverages[index].id !== 'WOP' &&
                      coverages[index].id !== 'ESC' &&
                      coverages[index].id !== 'FMI' &&
                      coverages[index].id !== 'FTI' ? (
                        <Field
                          shrink
                          name={`${benefit}.valueInsuredTwo`}
                          fullWidth
                          component={FormNumberField}
                          decimalScale={0}
                          variant="standard"
                          onChange={(e, newVal) => {
                            onCoverageChange();
                            if (!isResetForm) {
                              change(`${benefit}.isLifeTwoEdit`, true);
                            }
                          }}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          id={`${coverages[index].id}.insuredTwo`}
                          style={{
                            fontWeight: 700,
                            color: isDisabledLifeTwo
                              ? theme.palette.grey[400]
                              : theme.palette.common.black,
                          }}
                        >
                          {coverages[index].type === '1'
                            ? formatMonetaryValue(
                                coverages[index].valueInsuredTwo,
                                false,
                              )
                            : coverages[index].valueInsuredTwo
                            ? 'Yes'
                            : 'No '}
                        </Typography>
                      )}
                      {(coverages[index] && coverages[index].isLifeTwoRatingActive &&
                        <div style={{ color: theme.palette.primary.dark, fontWeight: 'bold'}}>
                            [Rated]
                        </div>
                       )}
                    </Grid>
                    {!isDisabledLifeTwo && !isCoveragesError && !isPartialWithdrawal && (
                      <Grid item xs={3}>
                        <IconButton
                          style={{ paddingRight: 12 }}
                          // disabled={isDisabledLifeTwo || isCoveragesError}
                          onClick={() => {
                            change(`${benefit}.selected`, true);
                            changeEditData({
                              editIndex: index,
                              editInsured: 2,
                            });
                          }}
                        >
                          <img
                            style={{ height: '1.6rem' }}
                            src={IconPencilBlue}
                            alt=""
                          />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Collapse
              in={editData.editIndex === index && editData.editInsured !== 0}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Grid item xs={7}>
                  {editData.editInsured === 1 ? (
                    coverages[index].type === '1' ? (
                      <Field
                        name={`${benefit}.valueInsuredOne`}
                        component={FormSlider}
                        id={coverages[index].id}
                        min={
                          policyConfig.features &&
                          getMinValue(
                            coverages[index].id,
                            policyConfig.features,
                            currency,
                          )
                        }
                        max={
                          policyConfig.features &&
                          getMaxValue(
                            coverages[index].id,
                            policyConfig.features,
                            currency,
                          )
                        }
                        diff={50000}
                        onChange={(e, newVal) => {
                          onCoverageChange();
                          if (!isResetForm) {
                            change(`${benefit}.isLifeOneEdit`, true);
                          }
                          isSyncBenefits &&
                            canIncreaseBenefitTwo &&
                            change(`${benefit}.valueInsuredTwo`, newVal);
                        }}
                        isPrimary={false}
                        type="text"
                      />
                    ) : (
                      <Field
                        name={`${benefit}.valueInsuredOne`}
                        component={FormBooleanOption}
                        type="text"
                        margin="normal"
                        onChange={(e, newVal) => {
                          onCoverageChange();
                          if (!isResetForm) {
                            change(`${benefit}.isLifeOneEdit`, true);
                          }
                          isSyncBenefits &&
                            canIncreaseBenefitTwo &&
                            change(`${benefit}.valueInsuredTwo`, newVal);
                        }}
                        shrink
                        fullWidth
                      />
                    )
                  ) : coverages[index].type === '1' ? (
                    <Field
                      name={`${benefit}.valueInsuredTwo`}
                      component={FormSlider}
                      id={coverages[index].id}
                      min={
                        policyConfig.features &&
                        getMinValue(
                          coverages[index].id,
                          policyConfig.features,
                          currency,
                        )
                      }
                      max={
                        policyConfig.features &&
                        getMaxValue(
                          coverages[index].id,
                          policyConfig.features,
                          currency,
                        )
                      }
                      onChange={(e, newVal) => {
                        onCoverageChange();
                        if (!isResetForm) {
                          change(`${benefit}.isLifeTwoEdit`, true);
                        }
                      }}
                      diff={50000}
                      isPrimary={false}
                      type="text"
                    />
                  ) : (
                    <Field
                      name={`${benefit}.valueInsuredTwo`} 
                      component={FormBooleanOption}
                      type="text"
                      margin="normal"
                      disabled={isLifetwoWOPDisabled}
                      shrink
                      fullWidth
                      onChange={(e, newVal) => {
                        onCoverageChange();
                        if (!isResetForm) {
                          change(`${benefit}.isLifeTwoEdit`, true);
                        }
                      }}
                    />
                  )}
                </Grid>

                {coverages[index].id === 'FMI' ||
                coverages[index].id === 'FTI' ? (
                  <Grid item xs={2}>
                    {editData.editInsured === 1 && (
                      <Field
                        shrink
                        name={`${benefit}.valueInsuredOne`}
                        fullWidth
                        component={FormNumberField}
                        decimalScale={0}
                        onChange={(e, newVal, previousValue) => {
                          onCoverageChange();
                          if (!isResetForm && newVal != previousValue) {
                            change(`${benefit}.isLifeOneEdit`, true);
                          }
                          isSyncBenefits &&
                            canIncreaseBenefitTwo &&
                            change(`${benefit}.valueInsuredTwo`, newVal);
                        }}
                        variant="standard"
                      />
                    )}
                    {editData.editInsured === 2 && isInsuredTwo && (
                      <Field
                        shrink
                        name={`${benefit}.valueInsuredTwo`}
                        fullWidth
                        component={FormNumberField}
                        decimalScale={0}
                        variant="standard"
                        onChange={() => {
                          onCoverageChange();
                          if (!isResetForm) {
                            change(`${benefit}.isLifeTwoEdit`, true);
                          }
                        }}
                      />
                    )}
                  </Grid>
                ) : null}

                {coverages[index].id === 'FMI' ||
                coverages[index].id === 'FTI' ? (
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    spacing={1}
                  >
                    <Grid item xs={7}>
                      {editData.editInsured === 1 && (
                        <Field
                          name={`${benefit}.termOne`}
                          component={FormSlider}
                          min={0}
                          max={40}
                          diff={5}
                          onChange={(e, newVal) => {
                            onCoverageChange();
                            if (!isResetForm) {
                              change(`${benefit}.isLifeOneEdit`, true);
                            }
                            isSyncBenefits &&
                              canIncreaseBenefitTwo &&
                              change(`${benefit}.termTwo`, newVal);
                          }}
                          isPrimary={false}
                          type="text"
                        />
                      )}

                      {editData.editInsured === 2 && isInsuredTwo && (
                        <Field
                          name={`${benefit}.termTwo`}
                          component={FormSlider}
                          min={0}
                          max={40}
                          diff={5}
                          isPrimary={false}
                          type="text"
                          onChange={(e, newVal) => {
                            onCoverageChange();
                            if (!isResetForm) {
                              change(`${benefit}.isLifeTwoEdit`, true);
                            }
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      {editData.editInsured === 1 && (
                        <Field
                          shrink
                          name={`${benefit}.termOne`}
                          fullWidth
                          component={FormNumberField}
                          decimalScale={0}
                          onChange={(e, newVal, previousValue) => {
                            onCoverageChange();
                            if (!isResetForm && newVal != previousValue) {
                              change(`${benefit}.isLifeOneEdit`, true);
                            }
                            isSyncBenefits &&
                              canIncreaseBenefitTwo &&
                              change(`${benefit}.termTwo`, newVal);
                          }}
                          variant="standard"
                        />
                      )}
                      {editData.editInsured === 2 && isInsuredTwo && (
                        <Field
                          shrink
                          name={`${benefit}.termTwo`}
                          fullWidth
                          component={FormNumberField}
                          decimalScale={0}
                          variant="standard"
                          onChange={(e, newVal) => {
                            onCoverageChange();
                            if (!isResetForm) {
                              change(`${benefit}.isLifeTwoEdit`, true);
                            }
                          }}
                        />
                      )}
                    </Grid>
                  </Grid>
                ) : null}
              </Grid>
            </Collapse>
            {(coverages[index] && coverages[index].id == 'WOP' && (productConfig.policyStatus == 'C' || productConfig.policyStatus == 'L') &&
                      <div style={{ color: theme.palette.primary.dark, fontWeight: 'bold'}}>
                          Select "Yes" if you would like to reinstate/ add the Waiver of premium benefit
                      </div>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  theme: PropTypes.object,
  options: PropTypes.object,
  policyConfig: PropTypes.object,
  currency: PropTypes.string,
  coverages: PropTypes.array,
  editData: PropTypes.object,
  lifeBasis: PropTypes.string,
  lifeAssured: PropTypes.object,
  changeEditData: PropTypes.func,
  isInsuredTwo: PropTypes.bool,
  isPartialWithdrawal: PropTypes.bool,
  change: PropTypes.func,
};

export default Benefits;
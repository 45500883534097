import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CustomSnackbarContent from './CustomSnackbarContent';

function Notification({
  open,
  severity,
  message,
  ...props
}) {

  const theme = useTheme();

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={3000}
      {...props}
      style={{
        bottom: 90,
        right: 20,
        ...props.style,
      }}
    >
      <CustomSnackbarContent
        severity={severity}
        message={message}
      />
    </Snackbar>
  );
}

Notification.propTypes = {
  open: PropTypes.bool,
  severity: PropTypes.oneOf(['warning', 'success', 'error', 'info', 'default']),
  message: PropTypes.node,
};

export default Notification;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import messages from 'containers/ApplicationFormPage/messages';
import { SubmissionError } from 'redux-form/immutable'; // ES6

//validators
import isEmail from 'validator/lib/isEmail';

import { isEmpty } from 'lodash/lang';
import { forEach, map } from 'lodash/collection';
import { keys } from 'lodash/object';
import { isFieldEmpty } from 'utils/form';

const validate = (values, props) => {
  var errors = {};

  return errors;
};

export default validate;

import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  fill: currentColor;
`;

function IconProgress(props) {
  return (
    <SvgIcon viewBox="0 0 26 26" version="1.1" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-802.000000, -205.000000)">
            <g transform="translate(802.875000, 203.800000)">
                <g transform="translate(2.090000, 4.046169)">
                    <GA transform="translate(1.214574, 1.214574)">
                        <g strokeWidth="1" fill="#01BFB3">
                            <circle cx="8.71292591" cy="8.71292591" r="8.71292591"/>
                        </g>
                        <path d="M12.1629326,4.61272548 L13.672974,6.1227669 L7.21177478,12.5839661 L3.58767537,8.95986671 L5.09771679,7.44982529 L7.21167537,9.56372548 L12.1629326,4.61272548 Z" id="tick" fill="#FFFFFF"/>
                    </GA>
                    <path d="M9.9275,19.855 C15.4103069,19.855 19.855,15.4103069 19.855,9.9275 C19.855,4.44469315 15.4103069,-2.18491891e-13 9.9275,-2.18491891e-13 C4.44469315,-2.18491891e-13 -2.18491891e-13,4.44469315 -2.18491891e-13,9.9275 C-2.18491891e-13,15.4103069 4.44469315,19.855 9.9275,19.855 Z" id="Oval-Copy-2" stroke="#FFFFFF" strokeWidth="2.6125" strokeDasharray="2.09000004529953"/>
                </g>
            </g>
        </g>
    </g>
</SvgIcon>
  );
}
export default IconProgress;

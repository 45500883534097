import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the idVerification state domain
 */

const selectIdVerificationDomain = state =>
  state.get('idVerification', initialState);

/**
 * Other specific selectors
 */

const makeSelectShuftiPro = () =>
  createSelector(selectIdVerificationDomain, substate =>
    substate.get('shuftiPro').toJS(),
  );

/**
 * Default selector used by IdVerification
 */

const makeSelectIdVerification = () =>
  createSelector(selectIdVerificationDomain, substate => substate.toJS());

export default makeSelectIdVerification;
export { selectIdVerificationDomain, makeSelectShuftiPro };

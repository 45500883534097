import React from 'react';
import {
  Grid,
  Dialog as BaseDialog,
  IconButton,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {
  CheckCircle,
  HighlightOff,
  Info,
  Error as ErrorIcon,
} from '@material-ui/icons';
import { useTheme, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => {
  return {
    dialogCard: {
      borderWidth: 2,
      borderStyle: 'solid',
      boxShadow: 'none',
    },
    dialogCardSuccess: {
      borderColor: theme.palette.success.main,
    },
    dialogCardWarning: {
      borderColor: theme.palette.warning.main,
    },
    dialogCardError: {
      borderColor: theme.palette.error.main,
    },
    dialogCardInfo: {
      borderColor: theme.palette.info.main,
    },
    dialogContent: {
      marginTop: theme.spacing(5),
      padding: theme.spacing(2.5),
      minHeight: 160,
      paddingTop: 0,
    },
    dialogActions: {
      padding: theme.spacing(2.5),
      paddingTop: 0,
    },
    titleWrapper: {
      padding: theme.spacing(2.5),
      borderBottom: `1px solid ${theme.palette.grey[400]}`,
    },
    closeIcon: {
      padding: 0,
    },
    paperWidthCustom: {
      maxWidth: 568,
    },
    root: {
      backgroundColor: 'rgba(102, 102,102, 0.9)',
    },
  };
});

function Dialog(props) {
  const { children, title, actions, variant, open, onClose } = props;
  const theme = useTheme();
  const classes = useStyles();
  const getDialogColor = () => {
    switch (variant) {
      case 'success':
        return theme.palette.success.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'error':
        return theme.palette.error.main;
      case 'info':
      default:
        return theme.palette.info.main;
    }
  };

  const getDialogPaperClass = () => {
    switch (variant) {
      case 'success':
        return classes.dialogCardSuccess;
      case 'warning':
        return classes.dialogCardWarning;
      case 'error':
        return classes.dialogCardError;
      case 'info':
      default:
        return classes.dialogCardInfo;
    }
  };

  const getTitleIcon = () => {
    switch (variant) {
      case 'success':
        return CheckCircle;
      case 'warning':
        return Info;
      case 'error':
        return ErrorIcon;
      case 'info':
      default:
        return Info;
    }
  };

  const TitleIcon = getTitleIcon();

  return (
    <BaseDialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      classes={{
        paper: `${classes.dialogCard} ${getDialogPaperClass()}`,
        paperWidthSm: classes.paperWidthCustom,
      }}
      BackdropProps={{
        classes: {
          root: classes.root,
        },
      }}
    >
      <DialogTitle className={classes.titleWrapper}>
        <Grid
          container
          alignItems="center"
          style={{
            color: getDialogColor(),
          }}
          spacing={2}
        >
          <Grid item>
            <TitleIcon fontSize="large" color="inherit" />
          </Grid>
          <Grid item xs>
            <Typography
              color="inherit"
              variant="h3"
              style={{
                fontSize: '2.8rem',
              }}
            >
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton className={classes.closeIcon} onClick={onClose}>
              <HighlightOff />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>{actions}</DialogActions>
    </BaseDialog>
  );
}

export default Dialog;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import messages from 'containers/RegisterPage/messages';
import { isFieldEmpty } from 'utils/form';
import isEmail from 'validator/lib/isEmail';

const validate = values => {
  const errors = {};
  const requiredFields = ['username', 'agentEmail', 'agencyNumber'];
  if (!values.isEmpty()) {
    requiredFields.forEach(field => {
      const fieldValue = values.get(field);
      if (!field === 'agentEmail' && !isFieldEmpty(fieldValue) && !isEmail(fieldValue)) {
        errors[field] = (
          <FormattedMessage {...globalMessages.emailInvalid} />
        );
      }
      
      if (isFieldEmpty(fieldValue)) {
        errors[field] = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      }

    });
    // check if mobile is correct format
    const mobile = values.get('mobile');
    if (!isFieldEmpty(mobile)) {
      const phoneRegex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
      if (!phoneRegex.test(mobile)){
        errors.mobile = (
          <FormattedMessage {...messages.phoneFormatError} />
        );
      }
    }
  }
  return errors;
};

export default validate;

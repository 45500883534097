import { fromJS } from 'immutable';
import isEmail from 'validator/lib/isEmail';
import moment from 'moment';
import numeral from 'numeral';
import { dobFormat } from 'helpers/constants';
import { forEach } from 'lodash/collection';

/** HELPER */
export function getCoverages(gcConfig) {
  const coverages = [];
  gcConfig.productBenefits.mandatoryList.map(benefit => {
    if (benefit.isComplimentary)
      coverages.push({
        type: '2',
        selected: !!benefit.isRequired,
        disabled: benefit.isRequired,
        id: benefit.benefitCode,
        title: benefit.benefitName,
        value: false,
        term: 0,
        maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
        possibleValues: benefit.possibleValues,
      });
  });

  gcConfig?.productBenefits?.optionalList?.map(benefit => {
    coverages.push({
      type: '2',
      selected: true,
      disabled: benefit.isRequired,
      id: benefit.benefitCode,
      title: benefit.benefitName,
      value: false,
      term: 0,
      maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
      possibleValues: benefit.possibleValues,
    });
  });
  return coverages;
}

export function getCoverages2(itaConfig, coverages2 = []) {
  const coverages = [];

  if (coverages2?.length > 0) {
    itaConfig.productBenefits.mandatoryList.map(benefit => {
      if (benefit.isComplimentary)
        forEach(coverages2, coverage => {
          if (benefit.benefitCode === coverage.code) {
            coverages.push({
              type:
                benefit.benefitCode === 'WOP' || benefit.benefitCode === 'ESC'
                  ? '2'
                  : '1',
              selected: !!benefit.isRequired,
              disabled: benefit.isRequired,
              id: benefit.benefitCode,
              title: benefit.benefitName,
              valueInsuredOne: coverage.value,
              valueInsuredTwo: coverage.value2,
              maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
              perPercentageOne: 0,
              perMileOne: 0,
              perMileTwo: 0,
              perPercentageTwo: 0,
              perTempMileOne: 0,
              perTempMileTwo: 0,
              perTempMonthTwo: 0,
              perTempMonthOne: 0,
              brSelectedTempValueOne: false,
              brSelectedTempValueTwo: false,
              brSelectedPercentValueOne: false,
              brSelectedPercentValueTwo: false,
              brSelectedInsuredTwoValue: false,
              brSelectedInsuredOneValue: false,
            });
          }
        });
    });

    itaConfig?.productBenefits?.optionalList?.map(benefit => {
      if (benefit.isComplimentary)
        forEach(coverages2, coverage => {
          if (benefit.benefitCode === coverage.code) {
            coverages.push({
              type:
                benefit.benefitCode === 'WOP' || benefit.benefitCode === 'ESC'
                  ? '2'
                  : '1',
              selected: coverage.selected,
              disabled: benefit.isRequired,
              id: benefit.benefitCode,
              title: benefit.benefitName,
              valueInsuredOne: coverage.value,
              perMileOne: 0,
              perPercentageOne: 0,
              brSelectedInsuredOneValue: false,
              valueInsuredTwo: coverage.value2,
              perMileTwo: 0,
              perPercentageTwo: 0,
              brSelectedInsuredTwoValue: false,
              maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
              perTempMileOne: 0,
              perTempMileTwo: 0,
              perTempMonthTwo: 0,
              perTempMonthOne: 0,
              brSelectedTempValueOne: false,
              brSelectedTempValueTwo: false,
              brSelectedPercentValueOne: false,
              brSelectedPercentValueTwo: false,
            });
          }
        });
    });
  } else {
    itaConfig.productBenefits.mandatoryList.map(benefit => {
      if (benefit.isComplimentary)
        coverages.push({
          type:
            benefit.benefitCode === 'WOP' || benefit.benefitCode === 'ESC'
              ? '2'
              : '1',
          selected: !!benefit.isRequired,
          disabled: benefit.isRequired,
          id: benefit.benefitCode,
          title: benefit.benefitName,
          valueInsuredOne: benefit.benefitCode === 'WOP' ? false : '',
          valueInsuredTwo: benefit.benefitCode === 'WOP' ? false : '',
          maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
          perPercentageOne: 0,
          perMileOne: 0,
          perMileTwo: 0,
          perPercentageTwo: 0,
          perTempMileOne: 0,
          perTempMileTwo: 0,
          perTempMonthTwo: 0,
          perTempMonthOne: 0,
          brSelectedTempValueOne: false,
          brSelectedTempValueTwo: false,
          brSelectedPercentValueOne: false,
          brSelectedPercentValueTwo: false,
          brSelectedInsuredTwoValue: false,
          brSelectedInsuredOneValue: false,
        });
    });
    itaConfig?.productBenefits?.optionalList?.map(benefit => {
      coverages.push({
        type:
          benefit.benefitCode === 'WOP' || benefit.benefitCode === 'ESC'
            ? '2'
            : '1',
        selected:
          benefit.benefitCode === 'WOP' || benefit.benefitCode === 'ESC'
            ? false
            : true, //!!(benefit.isRequired || benefit.benefitCode === 'WOP'),
        disabled: benefit.isRequired,
        id: benefit.benefitCode,
        title: benefit.benefitName,
        valueInsuredOne: benefit.benefitCode === 'WOP' ? false : '',
        perMileOne: 0,
        perPercentageOne: 0,
        brSelectedInsuredOneValue: false,
        valueInsuredTwo: benefit.benefitCode === 'WOP' ? false : '',
        perMileTwo: 0,
        perPercentageTwo: 0,
        brSelectedInsuredTwoValue: false,
        maxEntryAgeLA: benefit.maxEntryAgeLA ? benefit.maxEntryAgeLA : 0,
        perTempMileOne: 0,
        perTempMileTwo: 0,
        perTempMonthTwo: 0,
        perTempMonthOne: 0,
        brSelectedTempValueOne: false,
        brSelectedTempValueTwo: false,
        brSelectedPercentValueOne: false,
        brSelectedPercentValueTwo: false,
      });
    });
  }
  return coverages;
}

export function calculateAgeNextBirthdate(startDate, dateOfBirth) {
  const policyStartDateYear = moment(startDate, dobFormat).format('YYYY');
  const bornYear = moment(dateOfBirth, dobFormat).format('YYYY');
  const policyStartDateMonth = moment(startDate, dobFormat).format('MM');
  const bornMonth = moment(dateOfBirth, dobFormat).format('MM');
  const policyStartDateDay = moment(startDate, dobFormat).format('DD');
  const bornDay = moment(dateOfBirth, dobFormat).format('DD');

  let monthDayPoint = 0;
  if (policyStartDateMonth == bornMonth) {
    if (policyStartDateDay >= bornDay) monthDayPoint = 0;
    else monthDayPoint = 1;
  } else if (policyStartDateMonth < bornMonth) monthDayPoint = 1;

  const anb = policyStartDateYear - bornYear - monthDayPoint + 1;
  return anb;
}

export function getMinValue(paymentFrequency, gcConfig, currency) {
  let minValue = 0;
  gcConfig.currencyPremiumSize.map(gcConfingPremium => {
    if (currency === gcConfingPremium.currency) {
      switch (paymentFrequency) {
        case 'Single':
          minValue = gcConfingPremium.minPremiumSingle;
          break;

        case 'Monthly':
          minValue = gcConfingPremium.minPremiumMonthly;
          break;

        case 'Quarterly':
          minValue = gcConfingPremium.minPremiumQuarterly;
          break;

        case 'Half-Yearly':
          minValue = gcConfingPremium.minPremiumHalfYearly;
          break;

        case 'Yearly':
          minValue = gcConfingPremium.minPremiumYearly;
          break;

        default:
          minValue = gcConfingPremium.minPremiumMonthly;
          break;
      }
    }
  });
  return minValue;
}

export function getMaxValue(paymentFrequency, gcConfig, currency) {
  let maxValue = 0;
  gcConfig.currencyPremiumSize.map(gcConfingPremium => {
    if (currency === gcConfingPremium.currency) {
      switch (paymentFrequency) {
        case 'Single':
          maxValue = gcConfingPremium.maxPremiumSingle;
          break;

        case 'Monthly':
          maxValue = gcConfingPremium.maxPremiumMonthly;
          break;

        case 'Quarterly':
          maxValue = gcConfingPremium.maxPremiumQuarterly;
          break;

        case 'Half-Yearly':
          maxValue = gcConfingPremium.maxPremiumHalfYearly;
          break;

        case 'Yearly':
          maxValue = gcConfingPremium.maxPremiumYearly;
          break;

        default:
          maxValue = gcConfingPremium.maxPremiumMonthly;
          break;
      }
    }
  });
  return maxValue;
}

export function getMinValueITA(productId, itaConfig, currency) {
  const indexOfCurrency = itaConfig?.benefitCurrencyMap?.indexOf(currency);
  let minValue = 0;
  let productBenefits;
  if (itaConfig?.productBenefits?.optionalList) {
    productBenefits = [
      ...itaConfig?.productBenefits?.optionalList,
      ...itaConfig.productBenefits?.mandatoryList,
    ];
  } else {
    productBenefits = [
      ...itaConfig.productBenefits?.mandatoryList
    ];
  }

  productBenefits?.map(benefit => {
    if (benefit?.isComplimentary) {
      if (benefit?.benefitCode === productId)
        minValue = benefit?.minBenefitAmount?.length > 0 ? benefit?.minBenefitAmount[indexOfCurrency] : 0;
    }
  });
  return minValue;
}

export function getMaxValueITA(productId, itaConfig, currency) {
  const indexOfCurrency = itaConfig.benefitCurrencyMap.indexOf(currency);
  let maxValue = 0;
  let productBenefits;
  if (itaConfig?.productBenefits?.optionalList) {
    productBenefits = [
      ...itaConfig?.productBenefits?.optionalList,
      ...itaConfig.productBenefits.mandatoryList,
    ];
  } else {
    productBenefits = [
      ...itaConfig.productBenefits.mandatoryList,
    ];
  }

  productBenefits?.map(benefit => {
    if (benefit?.isComplimentary) {
      if (benefit?.benefitCode === productId)
        maxValue = benefit?.maxBenefitAmount?.length > 0 ? benefit.maxBenefitAmount[indexOfCurrency] : 999999999;
    }
  });
  return maxValue;
}

export function isValidEmail(email) {
  if (email && isEmail(email)) {
    return true;
  }
  return false;
}

export function getStartDate() {
  let month = new Date().getMonth() + 1;
  if (new Date().getDate() <= 15) month -= 1;

  return moment()
    .month(month)
    .date(1);
}

/**
 * CoverageForm
 */
import {
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  CheckBox
} from '@material-ui/core';
import { usePrevious } from 'utils/hooks';
import { dobFormat } from 'helpers/constants';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TargetFund from 'containers/App/components/TargetFund';
import CardSectionForm from 'components/CardSectionForm';
import CustomFormCheck from 'components/CustomFormCheck';
import CustomRadio from 'components/CustomRadioButton';
import FormBooleanOption from 'components/FormBooleanOption';
import FormNumberField from 'components/FormNumberField';
import FormRadioGroup from 'components/FormRadioGroup';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch';
import Helper from 'components/Helper';
import LoadingDialog from 'components/LoadingDialog';
import OptSwitch from 'components/OptSwitch';
import globalMessages from 'containers/App/messages';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import { changePlaceHolderColor, convertIfImmutable } from 'helpers/lang';
import { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { formatMonetaryValue } from 'helpers/math';
import FormYearPicker from 'components/FormYearPicker';
import {
  FormSection,
  WrappedFieldArrayProps,
  InjectedFormProps,
} from 'redux-form';
import {
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import FormTextField from 'components/FormTextField';
import { dimension } from 'styles/constants';
import numeral from 'numeral';
import isEqual from 'lodash/isEqual';
import { forEach } from 'lodash/collection';
import { get } from 'lodash/object';
import {
  calculateAgeNextBirthdate,
  getMaxValueITA,
  getMinValueITA,
  getStartDate,
} from '../../helper';
import messages from '../../messages';
import Withdrawal from '../Withdrawal';
import validate from './validate';
import { ChangeCoverageAction } from '../../actions';
import FormDatePicker from 'components/FormDatePicker';

const useStyles = makeStyles(theme => ({
  hr: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  hrSlider: {
    borderTop: '1px solid rgba(0,0,0,0.12)',
    margin: `${theme.spacing(3)}px ${theme.spacing(-2)}px ${theme.spacing(
      5,
    )}px ${theme.spacing(-2)}px`,
  },
  inputWidth: {
    maxWidth: '135px',
    width: '135px',
  },
  labelRadio: {
    '&> span': {
      fontWeight: 'bold',
    },
  },
}));

const renderSwitch = ({ input: { onChange, value }, theme, disabled }) => (
  <OptSwitch
    checked={value}
    value={value}
    color="default"
    onChange={onChange}
    labelNegative={<FormattedMessage {...messages.no} />}
    disabled={disabled}
  />
);

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const renderBenefit = ({
  fields,
  lifeAssured,
  startDate,
  change,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      if (Number.isInteger(myBenefit[index].value)) {
        change(`${benefit}.value`, myBenefit[index].value > 0);
      }
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured?.insuredOne.dateOfBirth,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured?.insuredTwo.dateOfBirth,
      );

      let ageValidType = 0;

      let isFormCheckDisabled = myBenefit[index].disabled;

      let isWopOneDisabled = myBenefit[index].disabled;

      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA != '0') {
          if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
            isWopOneDisabled = true;
            change(`${benefit}.value`, false);
          }
          if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
            change(`${benefit}.value`, false);
          }
        }
      } else if (myBenefit[index].maxEntryAgeLA != '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          isWopOneDisabled = true;
          change(`${benefit}.value`, false);
          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }
      return (
        <div key={myBenefit[index].id}>
          <Grid
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            item
            xs={11}
          >
            <Grid item xs={12} sm={6}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.selected`}
                  fullWidth
                  component={CustomFormCheck}
                  label={myBenefit[index].title}
                  disabled={isFormCheckDisabled}
                  validType={ageValidType}
                  type="text"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`${benefit}.value`, false);
                    }
                  }}
                />
              ) : (
                <Grid container direction="row">
                  <Typography variant="h4" style={{ fontWeight: '600' }}>
                    {myBenefit[index].title}
                  </Typography>
                  <div>
                    <Helper
                      helperText={
                        myBenefit[index].id === 'ESC' ? (
                          <FormattedMessage {...messages.descriptionEsc} />
                        ) : (
                          <FormattedMessage {...messages.descriptionTwo} />
                        )
                      }
                    ></Helper>
                  </div>
                </Grid>
              )}
            </Grid>
            {/* test */}
            <Grid item xs={6} sm={3}>
              <Field
                // shrink
                name={`${benefit}.value`}
                component={FormBooleanOption}
                type="text"
                margin="normal"
                disabled={isWopOneDisabled}
                fullWidth
              />
            </Grid>
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
  getbiTableValues1: PropTypes.func,
};

const renderBenefit2 = ({
  fields,
  lifeAssured,
  classes,
  intl,
  options,
  startDate,
  change,
}) => (
  <div>
    {fields.map((benefit, index) => {
      const myBenefit = fields.getAll().toJS();
      const anbInsuredOne = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredOne && lifeAssured.insuredOne.dateOfBirth
          ? lifeAssured.insuredOne.dateOfBirth
          : startDate,
      );
      const anbInsuredTwo = calculateAgeNextBirthdate(
        startDate,
        lifeAssured.insuredTwo && lifeAssured.insuredTwo.dateOfBirth
          ? lifeAssured.insuredTwo.dateOfBirth
          : startDate,
      );

      let ageValidType = 0;

      const isShowingField = true;
      let isFormCheckDisabled = myBenefit[index].disabled;
      const isFieldsDisabled = !myBenefit[index].selected;

      let isWopOneDisabled = myBenefit[index].disabled;
      let isWopTwoDisabled = myBenefit[index].disabled;

      if (lifeAssured.isInsuredTwo) {
        if (myBenefit[index].maxEntryAgeLA != '0') {
          if (myBenefit[index].id === 'WOP') {
            if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
              isWopOneDisabled = true;
              change(`${benefit}.valueInsuredOne`, false);
            }

            if (myBenefit[index].maxEntryAgeLA < anbInsuredTwo) {
              isWopTwoDisabled = true;
              change(`${benefit}.valueInsuredTwo`, false);
            }
          } else if (
            myBenefit[index].maxEntryAgeLA < anbInsuredOne ||
            myBenefit[index].maxEntryAgeLA < anbInsuredTwo
          ) {
            ageValidType = 1;
            isFormCheckDisabled = true;
            change(`${benefit}.valueInsuredOne`, '');
            change(`${benefit}.valueInsuredTwo`, '');
            change(`${benefit}.selected`, false);
          }
        }
        // mapping data for Li2
        if (
          myBenefit[index].valueInsuredOne !== '' &&
          myBenefit[index].id !== 'WOP'
        ) {
          change(
            `${benefit}.valueInsuredTwo`,
            myBenefit[index].valueInsuredOne,
          );
        }
      } else if (myBenefit[index].maxEntryAgeLA != '0') {
        if (myBenefit[index].maxEntryAgeLA < anbInsuredOne) {
          if (myBenefit[index].id === 'WOP') {
            isWopOneDisabled = true;
            change(`${benefit}.valueInsuredOne`, false);
          } else {
            change(`${benefit}.valueInsuredOne`, '');
            change(`${benefit}.selected`, false);
          }

          ageValidType = 1;
          isFormCheckDisabled = true;
        }
      }

      return (
        <div key={index}>
          <Grid
            key={myBenefit[index].id}
            container
            spacing={1}
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '10%',
            }}
            wrap="nowrap"
          >
            <Grid item xs={6} md={6} sm={6}>
              <Grid container direction="row">
                <Grid item>
                  {myBenefit[index].type === '1' ? (
                    <Field
                      // shrink
                      name={`${benefit}.selected`}
                      fullWidth
                      component={CustomFormCheck}
                      label={myBenefit[index].title}
                      disabled={isFormCheckDisabled}
                      validType={ageValidType}
                      type="text"
                      color="primary"
                      variant="standard"
                      onChange={(e, newVal) => {
                        if (!newVal) {
                          change(`${benefit}.valueInsuredOne`, '');
                          change(`${benefit}.valueInsuredTwo`, '');
                        }
                      }}
                    />
                  ) : (
                    <Grid container direction="row">
                      <Typography variant="h4" style={{ fontWeight: '600' }}>
                        {myBenefit[index].title}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                {myBenefit[index].id === 'WOP' ||
                  (myBenefit[index].id === 'CRI' && isFormCheckDisabled) ? (
                  <Grid item>
                    <Helper
                      helperText={
                        myBenefit[index].id === 'WOP' ? (
                          <FormattedMessage {...messages.descriptionWOP} />
                        ) : (
                          <FormattedMessage {...messages.descriptionCri} />
                        )
                      }
                    ></Helper>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
            {/* test */}
            <Grid item xs={6} md={3}>
              {myBenefit[index].type === '1' ? (
                <Field
                  // shrink
                  name={`${benefit}.valueInsuredOne`}
                  fullWidth
                  component={FormNumberField}
                  disabled={isFieldsDisabled}
                  type={isShowingField ? 'text' : 'hidden'}
                  color="primary"
                  variant="standard"
                  onChange={(e, newVal) =>
                    change(`${benefit}.valueInsuredTwo`, newVal)
                  }
                  placeholder={
                    ageValidType === 1
                      ? intl.formatMessage(globalMessages.naMessage)
                      : intl.formatMessage(messages.enterAmount)
                  }
                />
              ) : (
                <Field
                  // shrink
                  name={`${benefit}.valueInsuredOne`}
                  component={FormBooleanOption}
                  type="text"
                  margin="normal"
                  disabled={isWopOneDisabled}
                  fullWidth
                />
              )}
            </Grid>
            {lifeAssured.isInsuredTwo ? (
              <Grid item xs={6} md={3} sm={3}>
                {myBenefit[index].type === '1' ? (
                  <Field
                    // shrink
                    name={`${benefit}.valueInsuredTwo`}
                    fullWidth
                    component={FormNumberField}
                    disabled
                    type={isShowingField ? 'text' : 'hidden'}
                    color="primary"
                    variant="standard"
                    placeholder={
                      ageValidType === 1
                        ? intl.formatMessage(globalMessages.naMessage)
                        : intl.formatMessage(messages.enterAmount)
                    }
                  />
                ) : (
                  <Field
                    // shrink
                    name={`${benefit}.valueInsuredTwo`}
                    component={FormBooleanOption}
                    type="text"
                    margin="normal"
                    disabled={isWopTwoDisabled}
                    fullWidth
                  />
                )}
              </Grid>
            ) : null}
          </Grid>
          {index + 1 < fields.length && <Divider />}
        </div>
      );
    })}
  </div>
);

renderBenefit2.propTypes = {
  ...WrappedFieldArrayProps,
  lifeAssured: PropTypes.object,
  options: PropTypes.object,
  change: PropTypes.func,
  startDate: PropTypes.string,
};

function CoverageForm({
  handleSubmit,
  lifeAssured,
  options,
  product,
  gcConfig,
  change,
  // offerCodeData,
  // offerCode,
  term,
  startDate,
  fiaData,
  isAdditionalSinglePremium,
  fiaCharge,
  regularWithdrawal,
  educationFeesWithdrawal,
  isEducationalFeeWithdrawal,
  isRegularWithdrawal,
  isBundlingQuote,
  currency,
  paymentFrequency,
  location,
  productConfig2,
  handleGetProductConfig2,
  coverages2,
  premium,
  growthRate,
  // calculateQuote,
  formValues,
  childDetails,
  changeCoverage,
  isLifeAssuredEqual,
  biTableValues1,
  // gcConfig2,
  agentDetails,
  formErrors,
  submitFailed,
}) {

  const theme = useTheme();
  const intl = useIntl();
  const classes = useStyles();
  const offerList = [];
  const currencyOption = [];
  const paymentFrequencyOption = [];
  const policyTermList = [];
  const startDateList = [];

  // const [coverageValue, setCoverageValue] = useState(0);
  const prevBiTableValues1 = usePrevious(biTableValues1);
  const prevIsBundlingQuote = usePrevious(formValues.isBundlingQuote);
  const prevFormValuesCoverage = usePrevious(formValues.coverages);

  const isInitialMount = useRef(true);
  const coverageValue = formatMonetaryValue(
    get(
      biTableValues1,
      `illustrationTableDict[${numeral(growthRate).value() /
      100}][${term}.0]['Fund Value']`,
      0,
    ),
  );
  if (startDateList.length == 0) {
    startDateList.push({
      title: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .subtract(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate().format('DD/MM/YYYY'),
      value: getStartDate().format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(1, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
    startDateList.push({
      title: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('DD/MM/YYYY'),
      value: getStartDate()
        .add(2, 'months')
        .date(1)
        .format('YYYY-MM-DD'),
    });
  }

  const minStartDate = moment(startDate)
    .date(15)
    .add(5, 'years')
    .toDate();

  const maxStartDate = moment(startDate)
    .add(100, 'years')
    .toDate();
  let datevalue = moment(childDetails?.dateOfBirth, dobFormat)
  let minDate = '';
  let maxDate = '';
  let age = moment().diff(datevalue, 'years', false);
  let dif = 16 - age;
  let minYear = moment().add(dif, 'years').calendar();
  let maxYear = moment().add(dif + 4, 'years').calendar();
  let currentYear = moment().year() + 5
  let minDateYear = moment(minYear).year()
  let maxDateYear = moment(maxYear).year()
  if (minDateYear <= currentYear && maxDateYear > (currentYear)) {
    minDate = 5
    maxDate = maxDateYear - moment().year()
  } else if (minDateYear > currentYear && maxDateYear > currentYear) {
    minDate = minDateYear - moment().year()
    maxDate = maxDateYear - moment().year()
  } else {
    minDate = 5
    maxDate = 5
  }
  let minPremiumPaymentTerm = minDate ? minDate : 5;

  const maxPremiumPaymentTerm = maxDate ? maxDate : 7;
  let minStartYearTerm = new Date().getFullYear() + minPremiumPaymentTerm
  let maxStartYearTerm = new Date().getFullYear() + maxPremiumPaymentTerm
  while (minPremiumPaymentTerm <= maxPremiumPaymentTerm) {
    const myObj = {
      title: `${minPremiumPaymentTerm}`,
      value: minPremiumPaymentTerm,
    };
    policyTermList.push(myObj);
    minPremiumPaymentTerm++;
  }
  // policyTermList.push({ title: 'Single premium', value: 'SinglePremium' });

  offerList.push({ title: 'No Offer', value: '' });
  product?.specialOffers?.map(offer => {
    offerList.push({
      value: offer.specialOfferCode,
      title: offer.specialOfferCode,
    });
  });

  gcConfig.features.benefitCurrencyMap.map(currency => {
    currencyOption.push({ value: currency, title: currency });
  });

  gcConfig.features.premiumPaymentFrequencyType[0].periods.map(frequency => {
    paymentFrequencyOption.push({ value: frequency, title: frequency });
  });
  // productConfig2
  // useEffect(() => {
  //   if (isBundlingQuote && isEmpty(productConfig2)) {
  //     handleGetProductConfig2();
  //   }
  // }, [isBundlingQuote]);

  // CHECK IF THE FIELDS ARE FILLED UP OR UPDATED AFTER FILLED UP
 
  const getbiTableValues1 = () => {

    let errors = Object.keys(formErrors);
    if (
      !isEmpty(startDate) &&
      currency !== 'Select' &&
      term !== 'Select' &&
      paymentFrequency !== 'Select' &&
      premium !== '' &&
      growthRate !== '' &&
      growthRate != '0' &&
      (errors?.length === 0 || (errors?.length <= 2 && (errors[0] === 'hasToCalculate' || errors[1] === 'hasToCalculate' )) || (errors?.length <= 2 && (errors[0] === 'premium' || errors[1] === 'premium')))
    ) {
      change(
        `premium`,
        0,
      );
      changeCoverage(
        {
          ...formValues,
          getVal: false,
          isGetPremium: true,
          hasToCalculate: true,
          premium: 0
        },
        true,
        false,
        isLifeAssuredEqual,
        true
      );
    }
    if (isEmpty(productConfig2)) {
      handleGetProductConfig2();
    }
  };
  const getbiTableValuesCoveragesLife1 = (value, name) => {
    let errors = Object.keys(formErrors);
    if (
      !isEmpty(startDate) &&
      currency !== 'Select' &&
      term !== 'Select' &&
      paymentFrequency !== 'Select' &&
      premium !== '' &&
      growthRate !== '' &&
      growthRate != '0' &&
      (errors?.length === 0 || (errors?.length <= 2 && (errors[0] === 'hasToCalculate' || errors[1] === 'hasToCalculate' )) || (errors?.length <= 2 && (errors[0] === 'premium' || errors[1] === 'premium')))
    ) {
      change(
        `premium`,
        0,
      );
      let coveragesArray = formValues?.coverages?.map(element => {
        let obj = { ...element }
        if (element?.isPrimary && name === 'isTargetFundProtectionLifeOne') {
          obj.LFCPTD = { ...obj.LFCPTD, selected: value }
        } else {
          obj.LFCPTD = { ...obj.LFCPTD, selected: formValues?.isTargetFundProtectionLifeTwo }
        }

        return obj
      })
      changeCoverage(
        {
          ...formValues,
          isTargetFundProtectionLifeOne: value,
          getVal: false,
          isGetPremium: true,
          hasToCalculate: true,
          coverages: coveragesArray,
          premium: 0
        },
        true,
        false,
        isLifeAssuredEqual,
        true
      );
    }
    if (isEmpty(productConfig2)) {
      handleGetProductConfig2();
    }
  };
  const getbiTableValuesCoverages = (value, name) => {
    let errors = Object.keys(formErrors);
    if (
      !isEmpty(startDate) &&
      currency !== 'Select' &&
      term !== 'Select' &&
      paymentFrequency !== 'Select' &&
      premium !== '' &&
      growthRate !== '' &&
      growthRate != '0' &&
      (errors?.length === 0 || (errors?.length <= 2 && (errors[0] === 'hasToCalculate' || errors[1] === 'hasToCalculate' )) || (errors?.length <= 2 && (errors[0] === 'premium' || errors[1] === 'premium')))
    ) {
      change(
        `premium`,
        0,
      );
      let coveragesArray = formValues?.coverages?.map(element => {
        let obj = { ...element }

        if (!element?.isPrimary && name === 'isTargetFundProtectionLifeTwo') {
          obj.LFCPTD = { ...obj.LFCPTD, selected: value }
        } else {
          obj.LFCPTD = { ...obj.LFCPTD, selected: formValues?.isTargetFundProtectionLifeOne }
        }

        return obj
      })
      changeCoverage(
        {
          ...formValues,
          isTargetFundProtectionLifeTwo: value,
          getVal: false,
          isGetPremium: true,
          hasToCalculate: true,
          coverages: coveragesArray,
          premium: 0
        },
        true,
        false,
        isLifeAssuredEqual,
        true
      );
    }
    if (isEmpty(productConfig2)) {
      handleGetProductConfig2();
    }
  };
const updateStartDateEndDate = (term)=>{
  let withdrawendYearDetail =  Number(term) + Number(formValues?.payoutYears)  // Number(formValues?.targetYear) + Number(formValues?.payoutYears)
 let newTargetYear = Number(new Date().getFullYear()) + term
   let details = newTargetYear + Number(formValues?.payoutYears)
  change(
    `targetYear`,
    newTargetYear
  );
  change(
    `startYear`,
    newTargetYear
  );
  change(
    `endYear`,
    details -1
  );
}
  const prePopulateITACoverage = () => {
    forEach(formValues?.coverages2, (benefit, index) => {
      if (benefit.selected) {
        if (benefit.id !== 'WOP' && benefit.id !== 'ESC') {
          // check the maximum and minimum value of the benefit
          const minValue = getMinValueITA(
            benefit?.id,
            gcConfig?.features,
            currency,
          );
          const maxValue = getMaxValueITA(
            benefit?.id,
            gcConfig?.features,
            currency,
          );

          if (
            numeral(coverageValue).value() > maxValue &&
            minValue < numeral(coverageValue).value()
          ) {
            // if exceeded maxValue
            change(
              `coverages2[${index}].valueInsuredOne`,
              Math.floor(maxValue),
            );
          } else if (
            numeral(coverageValue).value() < maxValue &&
            minValue > numeral(coverageValue).value()
          ) {
            // if less than minValue
            change(
              `coverages2[${index}].valueInsuredOne`,
              Math.floor(minValue),
            );
          } else {
            change(
              `coverages2[${index}].valueInsuredOne`,
              Math.floor(numeral(coverageValue).value()),
            );
          }
        }
      }
    });
  };

  useLayoutEffect(() => {
    //only update if biTableValues1 is changed is changed
    if (!isEqual(prevBiTableValues1, biTableValues1)) {
      if (numeral(coverageValue).value() !== 0) {
        // update the coverage values in ITA
        if (formValues?.coverages2?.length > 0) {
          prePopulateITACoverage();
        }
      }
    }
  }, [!isEqual(prevBiTableValues1, biTableValues1)]);

  useLayoutEffect(() => {
    if (typeof formValues.isBundlingQuote !== 'undefined') {
      //this part just to prevent formValues to be read when its undefined and prevent 'isBundlingQuote' being read for the first time
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        //only run when changed not on load
        if (
          !isEqual(prevIsBundlingQuote, formValues.isBundlingQuote) &&
          formValues.isBundlingQuote
        ) {
          prePopulateITACoverage();
        }
      }
    }
  }, [formValues.isBundlingQuote]);

  useEffect(()=>{
    (formValues.targetFunctionality) && change('universityFeeUpdated',(Number(formValues?.targetFund) !== formValues?.calculatedUniversityFee && formValues?.calculatedUniversityFee >0))    
  },[formValues?.targetFund])

  useEffect(()=>{
    if(formValues?.haveAdditionalSinglePremium !== undefined || formValues?.haveAdditionalSinglePremium !== null){
    (formValues.targetFunctionality && ((formValues?.haveAdditionalSinglePremium && formValues?.singlePremium > 0) || (!formValues?.haveAdditionalSinglePremium))) &&
      getbiTableValues1();
    }
  },[formValues?.haveAdditionalSinglePremium])

  return (
    <>
      {location?.state?.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit} id="coveragesForm">
          <Grid container spacing={3} style={{ maxWidth: 660 }}>
            {/* ASSUME START DATE */}
            <Grid item>
              <Field
                // shrink
                name="startDate"
                style={{ width: 260 }}
                component={FormSelect}
                options={startDateList}
                label={<FormattedMessage {...messages.assumedStartDate} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
                onBlur={() => {
                  if (formValues.targetFunctionality) {
                    getbiTableValues1();
                  }
                }}
              />
            </Grid>
            {/* CURRRENCY */}
            < Grid item >
              <Field
                // shrink
                name="currency"
                style={{
                  width: 130,
                  color: changePlaceHolderColor(currency),
                }}
                fullWidth
                component={FormSelect}
                // options={currencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...currencyOption,
                ]}
                label={<FormattedMessage {...messages.currency} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
                onBlur={() => {
                  if (formValues.targetFunctionality) {
                    getbiTableValues1();
                  }
                }}
              />
            </Grid >
            {/* POLICY TERM */}
            < Grid item >
              <Field
                // shrink
                name="term"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(term),
                }}
                component={FormSelect}
                label={<FormattedMessage {...messages.premiumPaymentTerm} />}
                type="text"
                // options={policyTermList}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...policyTermList,
                ]}
                onChange={(e, newVal) => {
                  updateStartDateEndDate(newVal)
                  if (newVal === 'SinglePremium')
                    change('paymentFrequency', '');
                    
                }}
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
                onBlur={() => {
                  if (formValues.targetFunctionality) {
                    getbiTableValues1();
                  }
                }}
              />
            </Grid >
            {/* PAYMENT FREQUENCY */}
            < Grid item >
              <Field
                // shrink
                name="paymentFrequency"
                style={{
                  width: 260,
                  color: changePlaceHolderColor(paymentFrequency),
                }}
                component={FormSelect}
                // options={paymentFrequencyOption}
                options={[
                  { value: 'Select', title: 'Select' },
                  ...paymentFrequencyOption,
                ]}
                label={<FormattedMessage {...messages.paymentFrequency} />}
                type="text"
                disabled={term === 'SinglePremium'}
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.select)}
                onBlur={() => {
                  if (formValues.targetFunctionality) {
                    getbiTableValues1();
                  }
                }}
              />
            </Grid >

            {/* PREMIUM AMOUNT */}
            < Grid item sm={12} >
              <Field
                // shrink
                name="premium"
                style={{ width: 164 }}
                component={FormNumberField}
                disabled={formValues.targetFunctionality}
                label={<FormattedMessage {...messages.premiumAmount} />}
                type="text"
                margin="normal"
                variant="standard"
                placeholder={intl.formatMessage(messages.enterAmount)}

              />
            </Grid >
          </Grid >
          {/* additional single premium */}
          < Divider className={classes.hr} />
          <Grid>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              style={{
                marginBottom: dimension.spacing.xs,
                marginTop: dimension.spacing.xs,
                paddingRight: '10%',
              }}
            >
              <Grid item xs={8} sm={8}>

                <Field
                  // shrink
                  name={`haveAdditionalSinglePremium`}
                  fullWidth
                  component={CustomFormCheck}
                  label={intl.formatMessage(messages.additionalSinglePremium)}
                  validType={false}
                  type="text"
                  variant="standard"
                  onChange={(e, newVal) => {
                    if (!newVal) {
                      change(`haveAdditionalSinglePremium`, false);
                      change(`singlePremium`, 0);
                    }
                  }}
                  
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <Field
                  // shrink
                  name={`singlePremium`}
                  disabled={!formValues?.haveAdditionalSinglePremium}
                  placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                  component={FormNumberField}
                  type="text"
                  margin="normal"

                  fullWidth
                  onBlur={() => {
                    (!formErrors?.singlePremium && formValues.targetFunctionality) &&
                      getbiTableValues1();

                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          {/*end additinal single premium */}
          < Divider
            className={classes.hr}
            style={{ marginBottom: theme.spacing(2) }}
          />
          < Grid container alignItems="center" item spacing={2} >
            <TargetFund
              formValues={formValues}
              maxStartYearTerm={maxStartYearTerm}
              minStartYearTerm={minStartYearTerm}
              formErrors={formErrors}
              submitFailed={submitFailed}
              premiumDisabled={formValues.targetFunctionality}
              change={change}
            />
          </Grid >

          <Divider className={classes.hr} />

          <Grid>
            <Grid container spacing={2} item xs={11} md={12}>
              {/* Assumed growth rate */}
              <Grid item xs={6} md={6}>
                <Field
                  // shrink
                  name="payoutYears"
                  style={{ width: 260 }}
                  component={FormSelect}
                  options={[
                    { value: 'Select', title: 'Select an option' },
                    { value: 1, title: '1' },
                    { value: 2, title: '2' },
                    { value: 3, title: '3' },
                    { value: 4, title: '4' },
                    { value: 5, title: '5' },
                  ]}
                  label={<FormattedMessage {...messages.withdrawalPayOutPreioud} />}
                  type="text"
                  placeholder={intl.formatMessage(globalMessages.select)}
                  margin="normal"
                  variant="standard"
                  onChange={(e, newVal) => {
                      let withdrawendYearDetail = Number(formValues?.targetYear) + Number(newVal) -1
                    change(
                      `startYear`,
                      formValues?.targetYear
                    );
                    change(
                      `endYear`,
                      withdrawendYearDetail
                    );
                    if (formValues.targetFunctionality) {
                      getbiTableValues1();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  // shrink
                  name="withdrawalFrequency"
                  style={{ width: 260 }}
                  disabled
                  component={FormTextField}
                  label={<FormattedMessage {...messages.withdrawalFrequency} />}
                  type="text"
                  margin="normal"
                  variant="standard"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  style={{ width: 260 }}
                  disabled
                  name="startYear"
                  component={FormYearPicker}
                  label={<FormattedMessage {...messages.startYear} />}
                  dateInputFormat="DD/MM/YYYY"
                  placeholder="dd/mm/yyyy"
                  margin="normal"
                  inputProps={{ inputMode: 'numeric' }}
                  InputLabelProps={{
                    style: {
                      fontSize: theme.typography.body2.fontSize,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Field
                  style={{ width: 260 }}
                  disabled
                  name="endYear"
                  component={FormYearPicker}
                  label={<FormattedMessage {...messages.endYear} />}
                  dateInputFormat="DD/MM/YYYY"
                  placeholder="dd/mm/yyyy"
                  openTo="year"
                  margin="normal"
                  inputProps={{ inputMode: 'numeric' }}
                  InputLabelProps={{
                    style: {
                      fontSize: theme.typography.body2.fontSize,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.hr} />

          <Grid>
            <Grid container spacing={3} item xs={10} md={12}>
              {/* Assumed growth rate */}
              <Grid item xs={4} md={4}>
                <Field
                  // shrink
                  name="growthRate"
                  fullWidth
                  component={FormNumberField}
                  label={<FormattedMessage {...messages.assumedGrowthRate} />}
                  type="text"
                  margin="normal"
                  variant="standard"
                  placeholder={intl.formatMessage(messages.enterAmount)}
                  onBlur={() => {
                    if (formValues.targetFunctionality) {
                      getbiTableValues1();
                    }
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Divider className={classes.hr} />
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{
              marginBottom: dimension.spacing.xs,
              marginTop: dimension.spacing.xs,
              paddingRight: '0',
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid container direction="row">
                <Grid item xs={6} sm={6}>
                </Grid>
                {lifeAssured?.insuredOne?.firstName &&
                  <Grid item xs={3} sm={3} >
                    <Typography variant="h4" style={{ fontWeight: '500', color: '#4066B3', fontSize: '18px' }}>
                      {lifeAssured?.insuredOne?.firstName}
                    </Typography>
                  </Grid>
                }
                {lifeAssured?.insuredTwo?.firstName &&
                  <Grid item xs={3} sm={3} >
                    <Typography variant="h4" style={{ fontWeight: '500', color: '#4066B3', fontSize: '18px' }}>
                      {lifeAssured?.insuredTwo?.firstName}
                    </Typography>
                  </Grid>
                }
              </Grid>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography variant="h4" style={{ fontWeight: '600' }}>
                <FormattedMessage {...messages.futurePremiumProtection} /> <Helper
                  helperText={
                    <FormattedMessage {...messages.fppHelperText} />
                  }
                  placement="bottom"
                ></Helper>
              </Typography>
            </Grid>
            {lifeAssured?.insuredOne?.firstName &&
              <Grid item xs={lifeAssured?.insuredTwo?.firstName ? 3 : 6} sm={lifeAssured?.insuredTwo?.firstName ? 3 : 6}>
                <Field
                  // shrink
                  name={`isTargetFundProtectionLifeOne`}
                  component={FormBooleanOption}
                  type="text"
                  margin="normal"
                  fullWidth
                  onChange={(value) => {

                    if (formValues.targetFunctionality) {
                      getbiTableValuesCoveragesLife1(value, 'isTargetFundProtectionLifeOne');
                    }
                    if ((!value) && (lifeAssured?.insuredTwo?.firstName && !formValues.isTargetFundProtectionLifeTwo)) {
                      change(`specialTermsFlag`, false);
                    }
                    else if (!value && (lifeAssured?.insuredTwo?.firstName === '' || lifeAssured?.insuredTwo?.firstName === undefined)) {
                      change(`specialTermsFlag`, false);
                    }
                  }}
                />
              </Grid>
            }
            {lifeAssured?.insuredTwo?.firstName &&
              <Grid item xs={3} sm={3}>
                <Field
                  // shrink
                  name={`isTargetFundProtectionLifeTwo`}
                  component={FormBooleanOption}
                  type="text"
                  margin="normal"
                  fullWidth
                  onChange={(value) => {
                    if (formValues.targetFunctionality) {
                      getbiTableValuesCoverages(value, 'isTargetFundProtectionLifeTwo');
                    }
                    if (!formValues.isTargetFundProtectionLifeOne && !value) {
                      change(`specialTermsFlag`, false);
                    }
                  }}
                />
              </Grid>
            }
            <Grid item xs={12} style={{ padding: '2.5rem 0' }}>
              <Field
                // shrink
                name="specialTermsFlag"
                fullWidth
                disabled={(lifeAssured?.insuredTwo?.firstName ? (!formValues.isTargetFundProtectionLifeOne) && (lifeAssured?.insuredTwo?.firstName && !formValues.isTargetFundProtectionLifeTwo) : (!formValues.isTargetFundProtectionLifeOne))}
                component={CustomFormCheck}
                label={<FormattedMessage {...globalMessages.isBenefitRating} />}
                type="text"
                color="primary"
                margin="normal"
                variant="standard"
              />
            </Grid>
          </Grid>
          <Divider className={classes.hr} />




          {/* ===== Education Fee withdrawal ===== */}


        </form >
      )}
    </>
  );
}

CoverageForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  gcConfig: PropTypes.object,
  regularWithdrawal: PropTypes.object,
  educationFeesWithdrawal: PropTypes.object,
  product: PropTypes.object,
  lifeAssured: PropTypes.object,
  startDate: PropTypes.string,
  fiaCharge: PropTypes.number,
  term: PropTypes.string,
  coverages: PropTypes.array,
  fiaData: PropTypes.object,
  isEducationalFeeWithdrawal: PropTypes.bool,
  isRegularWithdrawal: PropTypes.bool,
  isBenefitITA: PropTypes.bool,
  productConfig2: PropTypes.object,
  handleGetProductConfig2: PropTypes.func,
  isBundlingQuote: PropTypes.bool,
  coverages2: PropTypes.array,
  premium: PropTypes.number,
  growthRate: PropTypes.string,
  calculateQuote: PropTypes.func,
  offerCode: PropTypes.string,
  formValues: PropTypes.object,
  changeCoverage: PropTypes.func,
  isLifeAssuredEqual: PropTypes.bool,
  biTableValues1: PropTypes.object,
  gcConfig2: PropTypes.object,
};

const withForm = reduxForm({
  validate,
  form: 'coverageForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(CoverageForm);

const selector = formValueSelector('coverageForm');

const mapStateToProps = state => {
  const formValues = getFormValues('coverageForm')(state);
  const startDate = selector(state, 'startDate');
  const currency = selector(state, 'currency');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const fiaCharge = selector(state, 'fiaCharge');
  const isAdditionalSinglePremium = selector(state, 'isAdditionalSinglePremium');
  const regularWithdrawal = selector(state, 'regularWithdrawal');
  const term = selector(state, 'term');
  const educationFeesWithdrawal = selector(state, 'educationFeesWithdrawal');
  const premium = selector(state, 'premium');
  const growthRate = selector(state, 'growthRate');
  const offerCode = selector(state, 'offerCode');
  const isEducationalFeeWithdrawal = selector(
    state,
    'isEducationalFeeWithdrawal',
  );
  const isRegularWithdrawal = selector(state, 'isRegularWithdrawal');
  const isBundlingQuote = selector(state, 'isBundlingQuote');
  const formErrors = getFormSyncErrors('coverageForm')(state);
  return {
    startDate,
    fiaCharge,
    isAdditionalSinglePremium,
    term,
    regularWithdrawal: regularWithdrawal?.toJS() || {},
    educationFeesWithdrawal: educationFeesWithdrawal?.toJS() || {},
    isEducationalFeeWithdrawal,
    isRegularWithdrawal,
    currency,
    paymentFrequency,
    isBundlingQuote,
    premium,
    growthRate,
    offerCode,
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    offerCodeData: makeSelectOfferCodeData(),
    formErrors: convertIfImmutable(formErrors),
  };
};

const mapDispatchToProps = dispatch => ({
  calculateQuote: data => dispatch(calculateQuoteAction(data)),
  changeCoverage: (
    value,
    reacalculateQuote = false,
    reverseCalculate = false,
    isLifeAssuredEqual,
    isGetPremium = false,
  ) =>
    dispatch(
      ChangeCoverageAction(
        value,
        reacalculateQuote,
        reverseCalculate,
        isLifeAssuredEqual,
        isGetPremium,
      ),
    ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withConnect;

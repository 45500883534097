import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the oktaAuthCallback state domain
 */

const selectOktaAuthCallbackDomain = state =>
  state.get('oktaAuthCallback', initialState);

/**
 * Other specific selectors
 */

const makeSelectError = () =>
  createSelector(selectOktaAuthCallbackDomain, substate =>
    substate.get('error').toJS(),
  );

/**
 * Default selector used by OktaAuthCallback
 */

const makeSelectOktaAuthCallback = () =>
  createSelector(selectOktaAuthCallbackDomain, substate => substate.toJS());

export default makeSelectOktaAuthCallback;
export { selectOktaAuthCallbackDomain, makeSelectError };

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Field } from 'redux-form/immutable';
import FormTextField from 'components/FormTextField';

const GeneratePinInput = ({ zioPinLength }) => {
  const inputContainerRef = useRef(null);
  const inputFields = new Array(zioPinLength).fill({
    value: '',
    disabled: false,
  });
  const [disabledIndices, setDisabledIndices] = useState(new Set());
  useEffect(() => {
    const numberOfRandom = [1, 2, 2, 3, 3, 4, 4];
    const numDisabled = numberOfRandom[zioPinLength - 4];
    const tempDisabledIndices = new Set();
    while (tempDisabledIndices.size < numDisabled) {
      const randomIndex = Math.floor(Math.random() * zioPinLength);
      tempDisabledIndices.add(randomIndex);
    }
    setDisabledIndices(tempDisabledIndices);
  }, [zioPinLength]);

  const triggerTab = currentInputIndex => {
    let incrementBy = 1;

    const currentInput = inputContainerRef.current.querySelector(
      `input[name='pin_${currentInputIndex}']`,
    );

    if (currentInput.value.length === 0) {
      return false;
    }

    let nextInput = inputContainerRef.current.querySelector(
      `input[name='pin_${currentInputIndex + incrementBy}']`,
    );

    while (incrementBy < inputFields.length && !nextInput) {
      incrementBy += 1;
      nextInput = inputContainerRef.current.querySelector(
        `input[name='pin_${currentInputIndex + incrementBy}']`,
      );
    }

    if (nextInput) {
      nextInput.focus();
    }
  };

  const getFields = () => {
    return inputFields.map((inputField, index) =>
      disabledIndices.has(index) ? (
        <Box
          key={index}
          style={{
            width: '50px',
            height: '43px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '2.5px',
            marginLeft: '2.5px',
          }}
        >
          *
        </Box>
      ) : (
        <Field
          key={index}
          name={`pin_${index}`}
          width="50px"
          component={FormTextField}
          onChange={() => triggerTab(index)}
          type="password"
          color="primary"
          margin="normal"
          style={{
            marginRight: '2.5px',
            marginLeft: '2.5px',
          }}
          inputProps={{
            maxLength: 1,
            style: {
              textAlign: 'center',
              width: '50px',
              paddingRight: 0,
              paddingLeft: 0,
            },
          }}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
        />
      ),
    );
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      ref={inputContainerRef}
    >
      {getFields()}
    </Box>
  );
};

GeneratePinInput.propTypes = {
  zioPinLength: PropTypes.number,
};

export default GeneratePinInput;

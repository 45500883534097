import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  stroke: currentColor;
`;

function IconIllustrations(props) {
  return (
    <SvgIcon viewBox="0 0 23 20" version="1.1" fontSize="inherit" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <GA transform="translate(-11.000000, -284.000000)">
          <g transform="translate(12.000000, 285.500000)">
            <line x1="14" y1="6.5" x2="18" y2="6.5" id="Path" />
            <line x1="14" y1="10.5" x2="18" y2="10.5" id="Path" />
            <path d="M10,13 L4,13 L4,13 C4,11.343 5.15114286,10 6.57142857,10 L7.42857143,10 C8.84885714,10 10,11.343 10,13 L10,13 Z" id="Path" />
            <circle id="Oval" cx="7" cy="7" r="2" />
            <path d="M17.1818182,0 C17.1818182,1.04313889 16.3270227,1.88888889 15.2727273,1.88888889 C14.2184318,1.88888889 13.3636364,1.04313889 13.3636364,0 L7.63636364,0 C7.63636364,1.04313889 6.78156818,1.88888889 5.72727273,1.88888889 C4.67297727,1.88888889 3.81818182,1.04313889 3.81818182,0 L0,0 L0,17 L21,17 L21,0 L17.1818182,0 Z" id="Path" />
          </g>
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconIllustrations;

import React, { useEffect } from 'react';
import { Field, FieldArray } from 'redux-form/immutable';
import { keys, get } from 'lodash/object';
import { forEach } from 'lodash/collection';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import RenderQuestions2 from './RenderQuestions2';

import { Typography, Grid, Divider } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import globalMessages from 'containers/App/messages';
import { find, filter, map } from 'lodash/collection';
// import { isFirstChild } from 'containers/ApplicationFormPage/helpers';

//components
import SingleChoice from './SingleChoice';
import MultipleChoice from './MultipleChoice';
import SearchInput from './SearchInput';
import Unitized from './Unitized';
import BloodPressure from './BloodPressure';
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import Numeric from './Numeric';
import Doctor from './Doctor';
import FormDate from './Date';
import Percent from './Percent';
import CardSectionForm from 'components/CardSectionForm';
import { isEmpty } from 'lodash/lang';
// const useStyle = makeStyles(theme => ({
//   title2: {
//     fontWeight: 800,
//     fontSize: '14px',
//     color: theme.palette.primary.dark,
//   },
//   textarea: {
//     resize: 'vertical',
//   },
// }));

const renderType = (
  questions,
  question,
  answerQuestion,
  section,
  searchAnswer,
  sectionName,
  setSectionAnswer,
  sessionId,
  eTag,
  partyId,
  childQs,
  order,
  index,
) => {
  const theme = useTheme();
  // const classes = useStyle();
  var childrenQuestions = [];
  var parentObject = {};
  //don't render the children of TABLERESP componentType
  if (
    question.parentQuestionId &&
    question.constraints.componentType !== 'TableResp'
  ) {
    //get the parent object
    parentObject = find(questions, o => {
      return o.id === question.parentQuestionId;
    });

    if (parentObject.constraints.componentType === 'TableResp') {
      return '';
    }
  } else if (
    question.constraints.componentType === 'TableResp' &&
    question.type == 'MULTIPLE_CHOICE'
  ) {
    //GET ALL THE CHILDREN OF THE TABLERESP COMPONENTTYPE
    childrenQuestions = filter(questions, currentQuestion => {
      return currentQuestion.parentQuestionId === question.id;
    });
  }

  if (question.type)
    switch (question.type) {
      case 'SINGLE_CHOICE':
        return (
          <Grid item xs={12} key={question.id}>
            <Field
              name={`question-${question.id}`}
              component={SingleChoice}
              question={question}
              questions={questions}
              onChange={(event, newValue, previousValue, name) => {
                const payload = {
                  section,
                  questionId: question.id,
                  value: newValue,
                  questionType: question.type,
                  sessionId,
                  eTag,
                  partyId,
                  order,
                };

                setSectionAnswer({
                  data: { [`question-${question.id}`]: newValue },
                  sectionName: sectionName,
                });
                answerQuestion(payload);
              }}
              childQuestions={
                childQs?.length && (
                  <>
                    <Divider
                      style={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                      }}
                    />
                    <Grid container spacing={2}>
                      {childQs.map(childQId => {
                        const childQuestion = find(
                          questions,
                          q => q.id === childQId,
                        );
                        return renderType(
                          questions,
                          childQuestion,
                          answerQuestion,
                          section,
                          searchAnswer,
                          sectionName,
                          setSectionAnswer,
                          sessionId,
                          eTag,
                          partyId,
                        );
                      })}
                    </Grid>
                  </>
                )
              }
            />
          </Grid>
        );
      case 'MULTIPLE_CHOICE':
        return (
          <Grid item xs={12} key={question.id}>
            <Field
              name={`question-${question.id}`}
              component={MultipleChoice}
              question={question}
              questions={questions}
              onChange={(event, newValue, previousValue, name) => {
                let convertedValue = [];
                //convert it into array type
                if (Object.keys(newValue).length > 0) {
                  const keyArr = keys(newValue);

                  forEach(keyArr, v => {
                    convertedValue = [...convertedValue, get(newValue, v)];
                  });
                }
                const payload = {
                  section,
                  questionId: question.id,
                  answers: convertedValue,
                  questionType: question.type,
                  sessionId,
                  eTag,
                  partyId,
                  order,
                };

                setSectionAnswer({
                  data: { [`question-${question.id}`]: newValue },
                  sectionName: sectionName,
                });
                answerQuestion(payload);
              }}
            >
              {map(childrenQuestions, childQuestion => {
                return RenderQuestions2(
                  childQuestion,
                  answerQuestion,
                  section,
                  searchAnswer,
                  sectionName,
                  setSectionAnswer,
                  sessionId,
                  eTag,
                  partyId,
                  false,
                );
              })}
            </Field>
          </Grid>
        );
      case 'SEARCH':
        return (
          <Grid item xs={12} key={question.id}>
            <Field
              name={`question-${question.id}`}
              component={SearchInput}
              question={question}
              questions={questions}
              section={section}
              sessionId={sessionId}
              searchAnswer={searchAnswer}
              sectionName={sectionName}
              onChange={(event, newValue, previousValue, name) => {
                const payload = {
                  section,
                  questionId: question.id,
                  answers: newValue,
                  questionType: question.type,
                  sessionId,
                  eTag,
                  partyId,
                  order,
                };

                setSectionAnswer({
                  data: { [`question-${question.id}`]: newValue },
                  sectionName: sectionName,
                });

                answerQuestion(payload);
              }}
            />
          </Grid>
        );
      case 'TEXT':
        return (
          <>
            {/* {isFirstChild(questions, question) && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h2" className={classes.title2}>
                    {question.context[0]}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider style={{ margin: '1rem 0' }} />
                </Grid>
              </>
            )} */}
            <Grid item xs={12} key={question.id}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant={'body2'} style={{ fontWeight: 800 }}>
                    {question.text}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Field
                    shrink
                    // style={{ lineHeight: '2rem' }}
                    name={`question-${question.id}`}
                    fullWidth
                    component={FormTextField}
                    type="text"
                    multiline
                    rows={4}
                    margin="normal"
                    variant="standard"
                    onBlur={(event, newValue, previousValue, name) => {
                      const payload = {
                        section,
                        questionId: question.id,
                        value: newValue,
                        questionType: question.type,
                        sessionId,
                        eTag,
                        partyId,
                        order,
                      };

                      setSectionAnswer({
                        data: { [`question-${question.id}`]: newValue },
                        sectionName: sectionName,
                      });

                      answerQuestion(payload);
                    }}
                    // style={{ resize: 'vertical' }}
                    // inputProps={{ className: { resize: 'vertical' } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        );
      case 'STATEMENT':
        return (
          <Grid item xs={12} key={question.id}>
            <Typography
              // variant="h3"
              style={{
                fontWeight: '400px',
                color: '#333',
                fontSize: question.id === 1 ? 18 : 14,
              }}
            >
              {question.text}
            </Typography>
          </Grid>
        );
      case 'NUMERIC':
        return (
          <Grid item xs={12} key={question.id}>
            <Field
              name={`question-${question.id}`}
              fullWidth
              component={Numeric}
              label={question.text}
              type="text"
              margin="normal"
              variant="standard"
              question={question}
              questions={questions}
              onBlur={(event, newValue, previousValue, name) => {
                const payload = {
                  section,
                  questionId: question.id,
                  value: newValue,
                  questionType: question.type,
                  sessionId,
                  eTag,
                  partyId,
                  order,
                };

                setSectionAnswer({
                  data: { [`question-${question.id}`]: newValue },
                  sectionName: sectionName,
                });

                answerQuestion(payload);
              }}
            />
          </Grid>
        );
      case 'UNITIZED':
        return (
          <Grid item xs={10} key={question.id}>
            <Field
              name={`question-${question.id}`}
              component={Unitized}
              label={question.text}
              question={question}
              type="text"
              margin="normal"
              variant="standard"
              onBlur={(event, newValue, previousValue, name) => {
                const payload = {
                  section,
                  questionId: question.id,
                  value: newValue,
                  questionType: question.type,
                  sessionId,
                  eTag,
                  partyId,
                  order,
                };

                setSectionAnswer({
                  data: { [`question-${question.id}`]: newValue },
                  sectionName: sectionName,
                });

                answerQuestion(payload);
              }}
            />
          </Grid>
        );
      case 'DATE':
        return (
          <Grid item xs={12} key={question.id}>
            <Field
              question={question}
              questions={questions}
              name={`question-${question.id}`}
              component={FormDate}
              onChange={(event, newValue, previousValue, name) => {
                const payload = {
                  section,
                  questionId: question.id,
                  value: newValue,
                  questionType: question.type,
                  sessionId,
                  eTag,
                  partyId,
                  order,
                };
                setSectionAnswer({
                  data: { [`question-${question.id}`]: newValue },
                  sectionName: sectionName,
                });

                if (moment(newValue.date).isValid() || !isEmpty(newValue)) {
                  answerQuestion(payload);
                }
              }}
            />
          </Grid>
        );
      case 'BLOOD_PRESSURE':
        return (
          <Grid item xs={12} key={question.id}>
            <FieldArray
              name={`question-${question.id}`}
              question={question}
              questions={questions}
              component={BloodPressure}
              section={section}
              sectionName={sectionName}
              answerQuestion={answerQuestion}
              order={order}
              setSectionAnswer={setSectionAnswer}
              eTag={eTag}
              partyId={partyId}
              sessionId={sessionId}
            />
          </Grid>
        );
      case 'DOCTOR':
        return (
          <Grid item xs={12} key={question.id}>
            <Doctor
              question={question}
              questions={questions}
              setSectionAnswer={setSectionAnswer}
              sectionName={sectionName}
              sessionId={sessionId}
              partyId={partyId}
              section={section}
              eTag={eTag}
              answerQuestion={answerQuestion}
              order={order}
            />
          </Grid>
        );
      case 'PERCENT':
        return (
          <Grid item xs={12} key={question.id}>
            <Field
              name={`question-${question.id}`}
              question={question}
              questions={questions}
              component={Percent}
              onChange={(event, newValue, previousValue, name) => {
                const payload = {
                  section,
                  questionId: question.id,
                  value: newValue,
                  questionType: question.type,
                  sessionId,
                  eTag,
                  partyId,
                  order,
                };

                setSectionAnswer({
                  data: { [`question-${question.id}`]: newValue },
                  sectionName: sectionName,
                });
                answerQuestion(payload);
              }}
            />
          </Grid>
        );
      default:
        return question.type;
    }
};

export default renderType;

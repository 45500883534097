/**
 *
 * Header
 *
 */

import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    height: 6.6rem;
    padding-left: ${props => props.theme.spacing(2.5)}px;
    padding-right: ${props => props.theme.spacing(3.75)}px;
    border-bottom: 1px solid ${props => props.theme.palette.divider};
    margin-bottom: 20px;
  }
`;

function FormHeader({
  title,
  subTitle,
  productName,
}) {
  const theme = useTheme();
  return (
    <Wrapper
      theme={theme}
      container
      alignItems="center"
    >
      <Grid item xs>
        <Typography
          variant="body1"
          style={{
            fontWeight: '700',
          }}
        >
          {title}
          {subTitle && `: ${subTitle}`}
        </Typography>
      </Grid>
      {productName && (
        <Grid item>
          <Typography
            variant="body1"
          >
            {productName}
          </Typography>
        </Grid>
      )}
    </Wrapper>
  );
}

FormHeader.propTypes = {
  title: PropTypes.node,
  subTitle: PropTypes.node,
  productName: PropTypes.node,
};

export default FormHeader;

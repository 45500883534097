import React from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@material-ui/core';

const GA = styled.g`
  fill: currentColor;
`;

function IconNotification(props) {
  return (
    <SvgIcon viewBox="0 0 23 28" version="1.1" {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <GA transform="translate(-278.000000, -204.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g transform="translate(278.700000, 204.044045)">
            <g id="Bell-icon" transform="translate(0.665000, 0.665000)">
              <path d="M10.185484,25.7271172 C11.592354,25.7271172 12.731855,24.5876162 12.731855,23.1807462 L7.63911298,23.1807462 C7.63911298,24.5876162 8.778614,25.7271172 10.185484,25.7271172 Z M17.824597,18.0880042 L17.824597,11.7220767 C17.824597,7.80703132 15.7429387,4.54131052 12.0952622,3.67554438 L12.0952622,2.80977825 C12.0952622,1.75303428 11.2422279,0.9 10.185484,0.9 C9.12874002,0.9 8.27570573,1.75303428 8.27570573,2.80977825 L8.27570573,3.67554438 C4.62802928,4.54131052 2.54637099,7.80703132 2.54637099,11.7220767 L2.54637099,18.0880042 L0,20.6343752 L0,21.9075607 L20.370968,21.9075607 L20.370968,20.6343752 L17.824597,18.0880042 Z M15.278226,19.3611897 L5.09274199,19.3611897 L5.09274199,11.7220767 C5.09274199,8.55821077 7.02161802,5.99274199 10.185484,5.99274199 C13.3493499,5.99274199 15.278226,8.55821077 15.278226,11.7220767 L15.278226,19.3611897 Z" id="Shape" />
            </g>
          </g>
        </GA>
      </g>
    </SvgIcon>
  );
}
export default IconNotification;

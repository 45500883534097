/*
 *
 * DashboardPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  SUB_PAGE_CHANGE_ACTION,
  SUB_PAGE_STEPPER_CHANGE_ACTION,
  SUB_PAGE_LIST_CHANGE_ACTION,
  EXIT_ACTION,
} from './constants';

const defaultState = {
  subPage: 0,
  subPageStepper: 0,
  subPageList: [],
};

export const initialState = fromJS({ ...defaultState });

function dashboardPageReducer(state = initialState, action) {
  switch (action.type) {
    case SUB_PAGE_CHANGE_ACTION:
      return state.set('subPage', action.payload);
    case SUB_PAGE_STEPPER_CHANGE_ACTION:
      return state.set('subPageStepper', action.payload);
    case SUB_PAGE_LIST_CHANGE_ACTION:
      return state.set('subPageList', fromJS(action.payload));

    case EXIT_ACTION:
      return state.set('subPage', 0);
    default:
      return state;
  }
}

export default dashboardPageReducer;

/*
 *
 * IdVerification reducer
 *
 */

import { fromJS } from 'immutable';
import {
  DEFAULT_ACTION,
  SET_SHUFTI_PRO_ACTION,
  RESET_STATE_ACTION,
} from './constants';

const defautState = {
  shuftiPro: {
    verificationUrl: null,
    reference: null,
    error: '',
  },
};

export const initialState = fromJS(defautState);

function idVerificationReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHUFTI_PRO_ACTION:
      return state.set('shuftiPro', fromJS(action.payload));
    case RESET_STATE_ACTION:
      return fromJS(defautState);
    case DEFAULT_ACTION:
      return state;
    default:
      return state;
  }
}

export default idVerificationReducer;

/*
 *
 * DtaIllustrationPage constants
 *
 */
export const CHANGE_LIFE_ASSURED_ACTION =
  'app/StaciIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/StaciIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/StaciIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const SET_CONFIG_ACTION = 'app/StaciIllustrationPage/SET_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION =
  'app/StaciIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/StaciIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/StaciIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/StaciIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/StaciIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/StaciIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/StaciIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/StaciIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/StaciIllustrationPage/SET_APPLICATION_ID_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/StaciIllustrationPage/SET_PTV_VERSION_ACTION';
export const EMAIL_PDF_ACTION = 'app/StaciIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/StaciIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_ERROR_ACTION = 'app/StaciIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/StaciIllustrationPage/RESET_ACTION';
export const CLONE_QUOTE_ACTION =
  'app/StaciIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/StaciIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/StaciIllustrationPage/SET_BI_PDF_ACTION';
export const SET_EMAIL_SENT_ACTION =
  'app/StaciIllustrationPage/SET_EMAIL_SENT_ACTION';
export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/StaciIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';
export const SET_COMPLETED_DIALOG =
  'app/StaciIllustrationPage/SET_COMPLETED_DIALOG';
export const SET_IS_CALCULATE_QUOTE_PAGE_ACTION =
  'app/StaciIllustrationPage/SET_IS_CALCULATE_QUOTE_PAGE_ACTION';
export const SET_PRODUCT_CODE_ACTION =
  'app/StaciIllustrationPage/SET_PRODUCT_CODE_ACTION';

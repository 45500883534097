export const SET_ZIO_PIN_SUCCESS = 'app/MFOauthPage/GET_ZIO_PIN_SUCCESS';
export const SET_ZIO_PIN_FAILURE = 'app/MFOauthPage/GET_ZIO_PIN_FAILURE';
export const CALL_ZIO_PIN_VERIFICATION =
  'app/MFOauthPage/CALL_ZIO_PIN_VERIFICATION';
export const SET_IS_ZIO_PIN_VERIFIED =
  'app/MFOauthPage/SET_IS_ZIO_PIN_VERIFIED';
export const SET_ZIO_PIN_VERIFY_TIMESTAMP =
  'app/MFOauthPage/SET_ZIO_PIN_VERIFY_TIMESTAMP';
export const GET_PERSISTED_ZIO_PIN_DATA =
  'app/MFOauthPage/GET_PERSISTED_ZIO_PIN_DATA';

/*
 * WapIllustrationPage Messages
 *
 * This contains all the text for the WapIllustrationPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.GcIllustrationPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'This is the GcIllustrationPage container!',
  },
  policyOwner: {
    id: `${scope}.policyOwner`,
    defaultMessage: 'Policy Owner',
  },
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email address (optional)',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select',
  },
  selectOption: {
    id: `${scope}.selectOption`,
    defaultMessage: 'Select an option',
  },
  isSecondPolicyOwner: {
    id: `${scope}.isSecondPolicyOwner`,
    defaultMessage: 'Do you want to add a second policy owner?',
  },
  newSinglePremiumAmount: {
    id: `${scope}.newSinglePremiumAmount`,
    defaultMessage: 'New single premium amount',
  },
  whoLifeInsured: {
    id: `${scope}.whoLifeInsured`,
    defaultMessage: 'Who will be the life insured?',
  },
  insuredOne: {
    id: `${scope}.insuredOne`,
    defaultMessage: 'Insured 1',
  },
  insuredTwo: {
    id: `${scope}.insuredTwo`,
    defaultMessage: 'Insured 2',
  },
  isSecondInsured: {
    id: `${scope}.isSecondInsured`,
    defaultMessage: 'Do you want to insure a second life e.g. a spouse?',
  },
  currency: {
    id: `${scope}.currency`,
    defaultMessage: 'Currency',
  },
  coverageAmount: {
    id: `${scope}.coverageAmount`,
    defaultMessage: 'Coverage Amount Required',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Policy term (in years)',
  },
  policyFrequency: {
    id: `${scope}.policyFrequency`,
    defaultMessage: 'Policy Frequency',
  },
  premiumWaiverBenefit: {
    id: `${scope}.premiumWaiverBenefit`,
    defaultMessage: 'Avail of premium waiver benefit?',
  },
  descriptionTwo: {
    id: `${scope}.descriptionTwo`,
    defaultMessage:
      'Should one or more insured persons becomes unable to work, the premium is waived. This is subject to Terms & Conditions.',
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes',
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No',
  },
  enterAmount: {
    id: `${scope}.enterAmount`,
    defaultMessage: 'Enter Amount',
  },
  editPremiumAmount: {
    id: `${scope}.editPremiumAmount`,
    defaultMessage: 'Edit Premium Amount*',
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit',
  },
  update: {
    id: `${scope}.update`,
    defaultMessage: 'Update',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  quoteDescription: {
    id: `${scope}.quoteDescription`,
    defaultMessage:
      '*Editing the illustration directly impacts the sum assured and renders the extra benefits void',
  },
  insuredParty: {
    id: `${scope}.insuredParty`,
    defaultMessage: 'The Insured',
  },
  policySummary: {
    id: `${scope}.policySummary`,
    defaultMessage: 'Policy Summary',
  },
  isPolicyOwnerIndividual: {
    id: `${scope}.isPolicyOwnerIndividual`,
    defaultMessage: 'Will the policy owner(s) be other individuals?',
  },
  relationWithLifeInsured: {
    id: `${scope}.relationWithLifeInsured`,
    defaultMessage: 'Relationship to life insured',
  },
  assumedStartDate: {
    id: `${scope}.assumedStartDate`,
    defaultMessage: 'Assumed start date',
  },
  paymentFrequency: {
    id: `${scope}.paymentFrequency`,
    defaultMessage: 'Payment frequency (if applicable)',
  },
  specialOffer: {
    id: `${scope}.specialOffer`,
    defaultMessage: 'Special offer (optional)',
  },
  addAnotherPolicyOwner: {
    id: `${scope}.addAnotherPolicyOwner`,
    defaultMessage: 'Add another policy owner',
  },
  added: {
    id: `${scope}.added`,
    defaultMessage: 'Added',
  },
  addedInfo: {
    id: `${scope}.addedInfo`,
    defaultMessage: `We have added this illustration to the client's list`,
  },
  exitQuoteNotice: {
    id: `${scope}.exitQuoteNotice`,
    defaultMessage: `You have not completed the quote. Are you sure you want to exit now?`,
  },
  clientList: {
    id: `${scope}.clientList`,
    defaultMessage: 'Client List',
  },
  createAnother: {
    id: `${scope}.createAnother`,
    defaultMessage: 'Create Another',
  },
  continue: {
    id: `${scope}.continue`,
    defaultMessage: 'Continue',
  },
  notePremiumCalculation: {
    id: `${scope}.notePremiumCalculation`,
    defaultMessage:
      'Note: editing the premium directly impacts the level of life cover, and resets the extra benefits.',
  },
  wapIllustration: {
    id: `${scope}.gcIllustration`,
    defaultMessage: ' Wealth Accumulation Plan Illustration',
  },
  viewExportPDF: {
    id: `${scope}.viewExportPDF`,
    defaultMessage: 'View / Export PDF',
  },
  emailPDF: {
    id: `${scope}.emailPDF`,
    defaultMessage: 'Email PDF',
  },
  pleaseEnter: {
    id: `${scope}.pleaseEnter`,
    defaultMessage: 'Please enter',
  },
  reference: {
    id: `${scope}.reference`,
    defaultMessage: 'Reference',
  },
  coverDetails: {
    id: `${scope}.coverDetails`,
    defaultMessage: 'Cover details',
  },
  premiumSummary: {
    id: `${scope}.premiumSummary`,
    defaultMessage: 'Premium summary',
  },
  premiumAmount: {
    id: `${scope}.premiumAmount`,
    defaultMessage: 'Premium amount',
  },
  reviewNotice: {
    id: `${scope}.reviewNotice`,
    defaultMessage: 'Please open and review the illustration to proceed',
  },
  brDebits: {
    id: `${scope}.brDebits`,
    defaultMessage: 'Benefits rating details',
  },
  backToClientList: {
    id: `${scope}.backToClientList`,
    defaultMessage: 'Back to client list',
  },
  specialTerms: {
    id: `${scope}.specialTerms`,
    defaultMessage: 'Special terms',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Premium payment term',
  },
  assumedGrowthRate: {
    id: `${scope}.assumedGrowthRate`,
    defaultMessage: 'Assumed growth rate %',
  },
  startDate: {
    id: `${scope}.startDate`,
    defaultMessage: 'Start date',
  },
  endDate: {
    id: `${scope}.endDate`,
    defaultMessage: 'End date',
  },
  frequency: {
    id: `${scope}.frequency`,
    defaultMessage: 'Frequency',
  },
  regularWithdrawalTitle: {
    id: `${scope}.regularWithdrawalTitle`,
    defaultMessage: 'Would you like to take regular withdrawals?',
  },
  eduWithdrawalTitle: {
    id: `${scope}.eduWithdrawalTitle`,
    defaultMessage: 'Would you like to take withdrawals for education fees?',
  },
  amount: {
    id: `${scope}.amount`,
    defaultMessage: 'Amount',
  },
  percentage: {
    id: `${scope}.percentage`,
    defaultMessage: 'Percentage',
  },
  percentageFundValue: {
    id: `${scope}.percentageFundValue`,
    defaultMessage: 'Percentage of fund value',
  },
  premiumPaid: {
    id: `${scope}.premiumPaid`,
    defaultMessage: 'Premiums paid',
  },
  policyTerm: {
    id: `${scope}.policyTerm`,
    defaultMessage: 'Policy term',
  },
  fundValues: {
    id: `${scope}.fundValues`,
    defaultMessage: 'Fund values',
  },
  projectedFundValues: {
    id: `${scope}.projectedFundValues`,
    defaultMessage: '{value} years projected fund value',
  },
  firstName: {
    id: `${scope}.firstName`,
    defaultMessage: `Child's first name`,
  },
  lastName: {
    id: `${scope}.lastName`,
    defaultMessage: `Child's last name`,
  },
  premiumPaymentTerm: {
    id: `${scope}.premiumPaymentTerm`,
    defaultMessage: 'Premium Payment term',
  },
  withdrawal: {
    id: `${scope}.withdrawal`,
    defaultMessage: 'Withdrawal',
  },
  establishmentCharge: {
    id: `${scope}.establishmentCharge`,
    defaultMessage: 'Establishment charge',
  },
  additionalSinglePremium: {
    id: `${scope}.additionalSinglePremium`,
    defaultMessage: 'Would you like to add an additional single premium (ASP)?',
  },
  ASP: {
    id: `${scope}.ASP`,
    defaultMessage: 'Additional Single Premium',
  },
  growthRate: {
    id: `${scope}.growthRate`,
    defaultMessage: '0.0%',
  },
  offerCodeValid: {
    id: `${scope}.offerCodeValid`,
    defaultMessage: 'This quote benefits from special offer of {value}',
  },
  specialOfferCode: {
    id: `${scope}.specialOfferCode`,
    defaultMessage: 'Special offer',
  },
  errorEmail: {
    id: `${scope}.errorEmail`,
    defaultMessage: '{message}',
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error',
  },
  illustration: {
    id: `${scope}.illustration`,
    defaultMessage: '{name} Illustration',
  },
});

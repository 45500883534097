import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from 'containers/ApplicationFormPage/messages';
import { Field } from 'redux-form/immutable';
import FormTextField from 'components/FormTextField';
import globalMessages from 'containers/App/messages';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import { map } from 'lodash/collection';
import { convertIfImmutable } from 'helpers/lang';
import { mapFinancialInstituteOptions } from 'containers/ApplicationFormPage/helpers';

function BankTransfer(props) {
  const theme = useTheme();
  const intl = useIntl();
  const { financialInstituteOptions } = props;

  const institutionOptions = map(
    convertIfImmutable(financialInstituteOptions),
    institute => {
      return { value: institute.bankCode, title: institute.bankFullName };
    },
  );

  return (
    <>
      <Grid container style={{ marginBottom: theme.spacing(3) }}>
        <Grid item style={{ width: 247 }}>
          <Field
            name="accountHolderName"
            component={FormTextField}
            fullWidth
            label={<FormattedMessage {...messages.accountHoldersName} />}
            placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: theme.spacing(3) }}>
        <Grid item style={{ width: 247 }}>
          <Field
            shrink
            name={`bankName`}
            fullWidth
            component={FormSelect}
            options={[
              { value: 'Select an option', title: 'Select an option' },
              ...mapFinancialInstituteOptions(
                convertIfImmutable(financialInstituteOptions),
              ),
            ]}
            label={<FormattedMessage {...messages.bankName} />}
            placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: theme.spacing(3) }}>
        <Grid item style={{ width: 355 }}>
          <Field
            name="cardNumber"
            component={FormNumberField}
            fullWidth
            label={<FormattedMessage {...messages.accountNumber} />}
            placeholder={intl.formatMessage(globalMessages.pleaseEnter)}
            allowLeadingZero
            type="text"
            thousandSeparator=""
            // inputFormat="############"
            shrink
            margin="normal"
          />
        </Grid>
      </Grid>
    </>
  );
}

BankTransfer.propTypes = {};

export default BankTransfer;

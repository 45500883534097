import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { FiberManualRecord as Dot } from '@material-ui/icons';
import { Grid, Typography, Divider, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DOB_LIST_FORMAT } from 'helpers/constants';
import IconDocumentSrc from 'images/icon_document.svg';
import IconEditSrc from 'images/open-external.svg';
import IconDeleteSrc from 'images/icon-trash.svg';
import { mapStatus } from 'containers/ApplicationPage/helper';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 315,
  },
  headerText: {
    fontSize: '1.4rem',
    color: '#4066B3',
    padding: '20px 30px',
  },
  bodyText: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F2F2F2',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#F9F9F9',
    },
    padding: '20px 30px',
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
  },
  textRef: {
    // paddingRight: 5,
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  text: {
    fontWeight: 'bold',
  },
  btnUpLoad: {
    color: '#4066B3',
    cursor: 'pointer',
  },
  btnDelete: {
    color: '#b6b8b4',
    cursor: 'pointer',
  },
  textTitle: {
    fontSize: 14,
    color: '#4066B3',
    fontWeight: 'bold',
  },
  textResult: {
    fontSize: 14,
    color: '#B6B8B4',
  },
  inProcessText: {
    color: '#00C3B4',
    textTransform: 'capitalize',
  },
  pendingText: {
    color: '#FF8F4A',
    textTransform: 'capitalize',
  },
  actionableText: {
    color: '#E75252',
    textTransform: 'capitalize',
  },
  rejectedText: { 
    color: '#E75252',
    textTransform: 'capitalize',
  },
  iconColumns: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  submittedText: {
    color: '#043594',
    textTransform: 'capitalize',
  },
  textDisabled: {
    color: '#B5B8B4',
  },
  dot: {
    width: '10px',
    marginRight: 5,
  },
}));

export function TableSearch(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const {
    listApplication,
    listIllustration,
    listPolicy,
    textSearch,
    isSearchNewBusiness,
  } = props;

  const handleEditItem = id => {
    history.push(`/applications/${id}`);
  };
  const filterListApp = listApplication.filter(item => {
    if (isSearchNewBusiness) {
      return item.isInforce === null || item.isInforce === false;
    }

    return item.isInforce;
  });

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item md={12} xs={12}>
        <Typography component="div">
          <Typography className={classes.textResult}>
            Showing results 1-
            {filterListApp.length +
              listIllustration.length +
              listPolicy.length}{' '}
            for <i>{textSearch}</i>
          </Typography>
        </Typography>
        <Box mt={1} mb={2}>
          <Divider style={{ width: '100%' }} />
        </Box>
        {isSearchNewBusiness && (
          <>
            <Typography component="div" className={classes.textTitle}>
              <Typography
                variant="h4"
                style={{ fontWeight: 'bold', marginBottom: 5 }}
              >
                <img
                  src={IconDocumentSrc}
                  alt=""
                  style={{ paddingBottom: 3, marginRight: 3 }}
                />
                Illustration portfolio
              </Typography>
            </Typography>
          </>
        )}

        {listIllustration.map((item, index) => {
          return (
            <Grid container key={index} className={classes.bodyText}>
              <Grid
                item
                xs={3}
                className={classes.text}
              >{`${item.prospect.firstName} ${item.prospect.lastName}`}</Grid>
              <Grid item xs={3} md={2}>
                {moment(item.createdAt).format('DD MMM YYYY')}
              </Grid>
              <Grid item xs={3} md={4} className={classes.textRef}>
                {item.productName}
              </Grid>
              <Grid item xs={2}>
                {item.prospect.quotes.length}
              </Grid>
              <Grid item xs={1} className={classes.iconColumns}>
                <img
                  src={IconEditSrc}
                  alt=""
                  className={classes.btnUpLoad}
                  onClick={() => handleEditItem(item.prospect.id)}
                />
                <img src={IconDeleteSrc} alt="" className={classes.btnDelete} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      {!isSearchNewBusiness && (
        <Grid container className={classes.headerText} wrap="nowrap">
          <Grid item xs={3} className={classes.text}>
            Policy Owner
          </Grid>
          <Grid item xs={3} className={classes.text}>
            DOB
          </Grid>
          <Grid item xs={2} className={classes.text}>
            Product
          </Grid>
          <Grid item xs={2} className={classes.text}>
            Ref no
          </Grid>
          <Grid item xs={1} className={classes.text}>
            Status
          </Grid>
        </Grid>
      )}

      {listPolicy.map((item, index) => {
        return (
          <Grid container key={index} className={classes.bodyText}>
            {item.parties.map((element, i) => {
              return (
                <>
                  {element.partyapplicationrelationships
                    .isPrimaryPolicyOwner && (
                    <>
                      <Grid key={i} item xs={3} className={classes.text}>
                        <Typography variant="body2" className={classes.text}>
                          {`${element.firstName} ${element.lastName}`}
                        </Typography>

                        {item.parties.length > 1 && ( // for life 2
                          <Typography
                            variant="body2"
                            className={classes.text}
                            style={{ paddingTop: theme.spacing(1) }}
                          >
                            {`& ${item.parties[1].firstName} ${item.parties[1].lastName}`}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {moment(element.dateOfBirth).format(DOB_LIST_FORMAT)}
                        </Typography>
                        {item.parties.length > 1 && ( // for life 2
                          <Typography
                            variant="body2"
                            style={{ paddingTop: theme.spacing(1) }}
                          >
                            {moment(item.parties[1].dateOfBirth).format(
                              DOB_LIST_FORMAT,
                            )}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  )}
                </>
              );
            })}

            <Grid item xs={2} className={classes.textRef}>
              {item.productName}
            </Grid>
            <Grid item xs={2} className={classes.textRef}>
              {item.referenceNumber}
            </Grid>
            <Grid
              item
              xs={1}
              className={
                item.validForIFQ === 'Y'
                  ? mapStatus(classes, item.mappedStatus)
                  : classes.textDisabled
              }
            >
              <Dot
                className={
                  item.validForIFQ === 'Y'
                    ? clsx(mapStatus(classes, item.mappedStatus), classes.dot)
                    : classes.dot
                }
              />
              {/* RI changes */}
              {(item.status == 'A' || item.status == 'L') ? item.mappedStatus : 'Paid Up'} 
            </Grid>

            <Grid item xs={1} className={classes.iconColumns}>
              {item.validForIFQ === 'Y' && (
                <img
                  src={IconEditSrc}
                  alt=""
                  className={classes.btnUpLoad}
                  onClick={() => {
                    const { policyNumber, productCode, productVersion } = item;
                    history.push({
                      pathname: `ifi`,
                      state: { policyNumber, productCode, productVersion },
                    });
                  }}
                />
              )}
            </Grid>
          </Grid>
        );
      })}

      <Grid item xs={12}>
        {filterListApp.length > 0 && (
          <Typography component="div" className={classes.textTitle}>
            <Typography
              variant="h4"
              style={{ fontWeight: 'bold', marginBottom: 5 }}
            >
              <img
                src={IconDocumentSrc}
                alt=""
                style={{ paddingBottom: 3, marginRight: 3 }}
              />
              Applications
            </Typography>
          </Typography>
        )}

        {filterListApp.map((item, index) => {
          return (
            <Grid container key={index} className={classes.bodyText}>
              {item.parties.map((element, i) => {
                return (
                  <>
                    {element.partyapplicationrelationships
                      .isPrimaryPolicyOwner && (
                      <>
                        <Grid key={i} item xs={3} className={classes.text}>
                          <Typography variant="body2" className={classes.text}>
                            {`${element.firstName} ${element.lastName}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography variant="body2">
                            {moment(item.createdAt).format(DOB_LIST_FORMAT)}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </>
                );
              })}

              <Grid item xs={2} className={classes.textRef}>
                {item.productName}
              </Grid>
              <Grid item xs={2} className={classes.textRef}>
                {item.referenceNumber}
              </Grid>
              <Grid
                item
                xs={1}
                className={mapStatus(classes, item.mappedStatus)}
              >
                {item.mappedStatus}
              </Grid>

              <Grid item xs={1} className={classes.iconColumns}>
                <img
                  src={IconEditSrc}
                  alt=""
                  className={classes.btnUpLoad}
                  onClick={() => handleEditItem(item.id)}
                />
                <img src={IconDeleteSrc} alt="" className={classes.btnDelete} />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
}

TableSearch.propTypes = {
  listApplication: PropTypes.array,
  listIllustration: PropTypes.array,
  textSearch: PropTypes.string,
};

const mapStateToProps = createStructuredSelector({});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(TableSearch);

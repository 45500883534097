/**
 * EspPersonalDetails
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Field} from 'redux-form/immutable';
import { Grid, FormControlLabel, Box, Typography } from '@material-ui/core';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import FormDatePicker from 'components/FormDatePicker';
import { dobFormat } from 'helpers/constants';
import FormRadioGroup from 'components/FormRadioGroup';
import FormCheck from 'components/FormCheck';
import FormSelect from 'components/FormSelect';
import {
    getStartDate,
    calculateAgeNextBirthdate,
} from 'containers/GcIllustrationPage/helper';
import moment from 'moment';
import FormBooleanOption from 'components/FormBooleanOption';
import CustomRadio from 'components/CustomRadioButton';
import Helper from 'components/Helper';
import numeral from 'numeral';
import { changePlaceHolderColor, changeToTitleCase } from 'helpers/lang';
import messages from '../messages';
import FormYearPicker from 'components/FormYearPicker';
import red from '@material-ui/core/colors/red';
import FormNumberField from 'components/FormNumberField';
import { getProductConfig } from 'containers/IllustrationPage/saga';
const lessThan = otherField => (value, previousValue, allValues) => {
    if (!value) return '';
    return value.length <= otherField ? value : previousValue;
};
const useStyles = makeStyles(theme => ({
    error: {
        color: red[800],
        fontWeight: 600,
        fontSize: '14px'
    },
    bold: {
        fontWeight: 800,
    },
}));

function EspPersonalDetails({
    disabled,
    calculateUniversityExpense,
    formValues = {},
    options,
    productConfig,
    disabledPolicyOwner,
    isNationalityShowing,
    minDate,
    maxDate,
    gcConfig,
    currency,
    change
}) {
    const classes = useStyles();
    const currencyOption = [];
    const theme = useTheme();
    const intl = useIntl();
    const [minTargetYear, setMinTargetYear] = useState(new Date().toISOString())
    const [maxTargetYear, setMaxTargetYear] = useState(new Date('01/12/2100'))
    const [gender, setGender] = useState(formValues?.gender ? formValues?.gender : '')
    gcConfig.features.benefitCurrencyMap.map(currency => {
        currencyOption.push({ value: currency, title: currency });
    });
    const eductaionCountryList =  gcConfig?.features?.allowedCountryOfEducation;
    //let countryOfEducationOthersList=[...new Set([...eductaionCountryList,...options?.countryCode])];
    let countryOfEducationOthersList=[];
    let countryOfEducationList = [];
    let countryOfEducationOthersListArray = options?.countryCode?.filter(ele=>{
      let country=  eductaionCountryList?.filter(element=> element?.countryCode === ele?.value)
      country?.length <= 0 ? countryOfEducationOthersList.push(ele) : countryOfEducationList.push(ele)
    })

    const getMinMaxYearDetails = (newVal) => {
        if (newVal) {

            let datevalue = moment(newVal, dobFormat)
            let minDate = '';
            let maxDate = '';
            let age = moment().diff(datevalue, 'years', false);
            let dif = 16 - age;
            let minYear = moment().add(dif, 'years').calendar()
            let maxYear = moment().add(dif + 4, 'years').calendar()
            let currentYear = moment().year() + 5
            let minDateYear = moment(minYear).year()
            let maxDateYear = moment(maxYear).year()
            if (minDateYear <= currentYear && maxDateYear > currentYear) {
                minDate = moment().add(5, 'years').calendar()
                maxDate = maxYear
            } else if (minDateYear > currentYear && maxDateYear > currentYear) {
                minDate = minYear
                maxDate = maxYear
            } else {
                minDate = moment().add(5, 'years').calendar()
                maxDate = moment().add(5, 'Years').calendar()
            }
            setMinTargetYear(minDate)
            setMaxTargetYear(maxDate)

        }
    }
    const calculateExpense = (currency,targetYear=0,countryOfEducation) =>{
        if (currency && currency !== 'Select'&&  currency !== 'Select an option' && targetYear && countryOfEducation !== 'Select an option' && countryOfEducation && countryOfEducation !== 'OT'){
            calculateUniversityExpense({
                'currency':currency ? currency: formValues?.currency,
                'universityStartYear':targetYear?targetYear: formValues?.targetYear,
                'country': countryOfEducation? countryOfEducation: formValues?.countryOfEducation
            })
        }   
    }
    const handlePayoutYearsChange = (countryCode) =>{
        let payoutYearsArray =  eductaionCountryList?.filter(element=> element?.countryCode === countryCode)
        payoutYearsArray?.length>0 ?  change('childDetails.payoutYears',payoutYearsArray[0]['noOfYearsOfStudy']) : change('childDetails.payoutYears','')
       
    }
    useEffect(()=>{
        if(formValues?.countryOfEducation !== 'OT')
        change('childDetails.targetFund',(productConfig?.targetFund && formValues?.countryOfEducation !== 'OT') ? productConfig?.targetFund : '')
    },[productConfig?.targetFund])
    useEffect(()=>{
        if(formValues?.otherUniversityCountryCode){
           let otherCountryArray= options?.countryCode?.filter(element=> element?.value === formValues?.otherUniversityCountryCode)
           otherCountryArray?.length>0 && change('childDetails.otherUniversityCountryName',otherCountryArray[0]['title'])
        }
    },[formValues?.otherUniversityCountryCode])
   const  _checkFundValueModified = (newValue)=>{
        change('childDetails.universityFeeUpdated',(Number(newValue) !== productConfig?.calculatedUniversityFee && productConfig?.calculatedUniversityFee >0))    
   }
    return (
        <Box mb={3} ml={1}>
            <Grid container spacing={3} alignItems="center">
                <Grid item>
                    <Field
                        name="firstName"
                        disabled={disabled}
                        style={{ width: 260 }}
                        component={FormTextField}
                        label={<FormattedMessage {...messages.childFirstName} />}
                        type="text"
                        margin="normal"
                        normalize={lessThan(45)}
                        placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                        format={(value) => changeToTitleCase(value)}
                    />
                </Grid>

                <Grid item>
                    <Field
                        name="lastName"
                        disabled={disabled}
                        style={{ width: 260 }}
                        component={FormTextField}
                        label={<FormattedMessage {...messages.childFamilyName} />}
                        margin="normal"
                        normalize={lessThan(40)}
                        placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                        format={(value) => changeToTitleCase(value)}
                    />
                    <Field
                        name="relationshipToLifeInsured"
                        disabled={disabled}
                        style={{ width: 260, display: 'none' }}
                        type="hidden"
                        component={FormTextField}
                        margin="normal"
                        normalize={lessThan(40)}
                        placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                        format={(value) => changeToTitleCase(value)}
                    />
                </Grid>
                <Grid container />
                <Grid item>
                    <Field
                        style={{ width: 260 }}
                        disabled={disabled}
                        name="dateOfBirth"
                        component={FormDatePicker}
                        label={<FormattedMessage {...globalMessages.dob} />}
                        dateInputFormat="DD/MM/YYYY"
                        placeholder="dd/mm/yyyy"
                        margin="normal"
                        onChange={(e, newVal) => {
                            if (newVal) {
                                getMinMaxYearDetails(newVal)
                            }
                        }
                        }
                        minDate={minDate}
                        maxDate={maxDate}
                        inputProps={{ inputMode: 'numeric' }
                        }
                        InputLabelProps={{
                            style: {
                                fontSize: theme.typography.body2.fontSize,
                            },
                        }}
                    />
                </Grid>

                <Grid item>
                    <Field
                        name="gender"
                        type="radio"
                        component={FormRadioGroup}
                        disabled={disabled}
                        onChange={(e, newVal) => {
                            if (newVal) {
                                setGender(newVal)
                            }
                        }
                        }
                    >
                        <FormControlLabel
                            disabled={disabled}
                            style={{ marginRight: theme.spacing(2) }}
                            control={<CustomRadio checked={formValues?.gender == '1'} />}
                            value={'1'}
                            label={<FormattedMessage {...globalMessages.male} />}
                            labelPlacement="end"

                        />

                        <FormControlLabel
                            disabled={disabled}
                            control={<CustomRadio checked={formValues?.gender == '0'} />}
                            value={'0'}
                            label={<FormattedMessage {...globalMessages.female} />}
                            labelPlacement="end"

                        />
                    </Field>
                </Grid>
                <Grid container />
                <Grid item>
                    <Field
                        // shrink
                        disabled={disabled}
                        name="countryOfResidence"
                        style={{
                            width: 260,
                            color: changePlaceHolderColor(formValues.countryOfResidence),
                        }}
                        component={FormSelect}
                        // options={options.countryCode}
                        options={[
                            { value: 'Select an option', title: 'Select an option' },
                            { value: 'AE', title: 'United Arab Emirates' },
                        ]}
                        label={<FormattedMessage {...messages.countryOfResidence} />}
                        placeholder={intl.formatMessage(globalMessages.select)}
                        type="text"
                        margin="normal"
                        
                    />
                </Grid>
                <Grid item>
                    <Field
                            // shrink
                            disabled={disabled}
                            name="nationality"
                            style={{
                                width: 260,
                                color: changePlaceHolderColor(formValues.nationality),
                            }}
                            component={FormSelect}
                            // options={options.countryCode}
                            options={[
                                { value: 'Select an option', title: 'Select an option' },
                                ...options.countryCode,
                            ]}
                            label={<FormattedMessage {...globalMessages.nationality} />}
                            placeholder={intl.formatMessage(globalMessages.select)}
                            type="text"
                            margin="normal"
                           
                        />
                </Grid>
                <Grid container />
                <Grid item >
                    <Field
                        // shrink
                        disabled={disabled}
                        name="countryOfEducation"
                        style={{
                            width: 260,
                            color: changePlaceHolderColor(formValues.countryOfEducation),
                        }}
                        component={FormSelect}
                        // options={options.countryCode}
                        options={[
                            { value: 'Select an option', title: 'Select an option' },
                            ...countryOfEducationList,
                            { value: 'OT', title: 'Others' },
                        ]}
                        label={<FormattedMessage {...messages.countryOfEducation} />}
                        placeholder={intl.formatMessage(globalMessages.select)}
                        type="text"
                        margin="normal"
                        onChange={(e,newValue)=>{
                            handlePayoutYearsChange(newValue)
                                if(newValue === 'OT'){
                                    change('childDetails.targetFund','')
                                }else{
                                    calculateExpense(formValues?.currency,formValues?.targetYear,newValue)
                                }
                        }
                        }
    
                    />
                </Grid>
                {formValues?.countryOfEducation === 'OT' &&
                <Grid item >
                    <Field
                        // shrink
                        disabled={disabled}
                        name="otherUniversityCountryCode"
                        style={{
                            width: 260,
                            marginTop:'18px',
                            color: changePlaceHolderColor(formValues.countryOfEducationOthers),
                        }}
                        component={FormSelect}
                        // options={options.countryCode}
                        options={[
                            { value: 'Select an option', title: 'Select an option' },
                           ...countryOfEducationOthersList
                        ]}
                        label={<FormattedMessage {...messages.countryOfEducationOthers} />}
                        placeholder={intl.formatMessage(globalMessages.select)}
                        type="text"
                        margin="normal"
                    />
                </Grid>
                }
                <Grid item >
                    <Field
                        style={{ width: 260 }}
                        disabled={disabled}
                        name="targetYear"
                        component={FormYearPicker}
                        label={<FormattedMessage {...messages.universityStartDate} />}
                        placeholder="YYYY"
                        views={['year']}
                        margin="normal"
                        minDate={minTargetYear}
                        maxDate={maxTargetYear}
                        inputProps={{ inputMode: 'numeric' }}
                        InputLabelProps={{
                            style: {
                                fontSize: theme.typography.body2.fontSize,
                            },
                        }}
                        onChange={(e,newvalue)=>{
                            calculateExpense(formValues?.currency,newvalue,formValues?.countryOfEducation)
                        }}
                    />
                </Grid>
                <Grid item >
                    <Field
                        // shrink
                        disabled={disabled}
                        name="currency"
                        style={{
                            width: 200,
                            color: changePlaceHolderColor(currency),
                        }}
                        fullWidth
                        component={FormSelect}
                        // options={currencyOption}
                        options={[
                            { value: 'Select', title: 'Select' },
                            ...currencyOption,
                        ]}
                        label={<FormattedMessage {...messages.currency} />}
                        type="text"
                        margin="normal"
                        variant="standard"
                        placeholder={intl.formatMessage(messages.select)}
                        onChange={(e,newValue)=>{
                            calculateExpense(newValue,formValues.targetYear,formValues?.countryOfEducation)
                          }}
                    />
                </Grid>
                <Grid item>
                    <Field
                        // shrink
                        disabled={disabled}
                        name="targetFund"
                        style={{
                            width: 170,
                            color: changePlaceHolderColor(formValues.targetFund),
                        }}
                        component={FormNumberField}
                        label={<FormattedMessage {...messages.projectedExpenseFee} />}
                        placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                        type="text"
                        margin="normal"
                        onChange={(newValue)=>_checkFundValueModified(newValue)}
                    />
                </Grid>
                <Grid item>
                    <Field
                        // shrink
                        disabled={disabled}
                        name="payoutYears"
                        style={{
                            width: 130,
                            color: changePlaceHolderColor(formValues.payoutYears),
                        }}
                        component={FormTextField}
                        // options={options.countryCode}

                        label={<FormattedMessage {...messages.noOfYearOfStudy} />}
                        placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
                        type="text"
                        margin="normal"
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} style={{ visibility: 'hidden' }}>
                <Field
                    disabled={disabled}
                    name="prospectId"
                    component={FormTextField}
                    type="hidden"
                // margin="normal"
                // variant="standard"
                />
            </Grid>
            {
                disabled &&
                <Grid item xs={12}>
                    <Typography variant="body1" className={classes.error}>
                        <FormattedMessage {...messages.childDetailsWarningMsg1} />
                    </Typography>

                </Grid>
            }
        </Box >

    );
}

EspPersonalDetails.propTypes = {
    options: PropTypes.object,
    formValues: PropTypes.object,
    disabledPolicyOwner: PropTypes.bool,
    isNationalityShowing: PropTypes.bool,
    minDate: PropTypes.number,
    maxDate: PropTypes.number,
    productCode: PropTypes.string,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default EspPersonalDetails;

/**
 * BeneficiaryDetails
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { Field, FormSection } from 'redux-form/immutable';

import { Grid, Typography, FormControlLabel } from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import FormSwitch from 'components/FormSwitch'

export function useISOcountrycode() {
  return sessionStorage.getItem('agentDetails');
}

const useStyle = makeStyles(theme => ({
  container: {
    marginTop: '1rem',
  },
  button: {
    fontWeight: 800,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    // alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  hr: {
    margin: '0 0rem',
    borderTop: '1px solid #DDDFE0',
  },
  bold: {
    fontWeight: 800,
  },
}));

function PayorPolicy(props) {
  const intl = useIntl();
  const classes = useStyle();
  //Armed Service - Changes starts
  const { options,change,employerAddress,natureOfCompanyBusiness, agentDetails} = props;
  const ISOCode = useISOcountrycode()
  //Armed Service - Changes ends

  return (
    <Grid container spacing={3} className={classes.container}>
      <Grid item xs={8}>
        <Field
          name="employerName"
          component={FormTextField}
          label={<FormattedMessage {...messages.employerName} />}
          shrink
          fullWidth
          placeholder={intl.formatMessage(messages.pleaseEnter)}
        />
      </Grid>
      {/* Armed Service - Changes starts */}
      {['AE'].includes(ISOCode) &&

        <Grid item xs={8} container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Typography
            variant="body1"
            style={{
              marginRight: '0px',
              fontSize: '14px',
              fontWeight: 600
            }}>
            <FormattedMessage {...messages.armyservice} />
          </Typography>
          <Field name="armyservice"
            component={FormSwitch}
            onChange={(e, newval) => {
              const data = newval;
              if (data) {
                change('policyPaidBySalary.employerAddress', "N.A");
                change('policyPaidBySalary.natureOfCompanyBusiness', "N.A");
                change('policyPaidBySalary.jobTitle', "NA");
              }
              else {
                change('policyPaidBySalary.employerAddress', "");
                change('policyPaidBySalary.natureOfCompanyBusiness', "");
                change('policyPaidBySalary.jobTitle', "");
              }
            }
            }
          />
        </Grid>
      }
      {/* Armed Service - Changes ends */}
      <Grid item xs={8}>
        <Field
          name="jobTitle"
          component={FormTextField}
          label={<FormattedMessage {...messages.nameOfJobTitle} />}
          shrink
          fullWidth
          placeholder={intl.formatMessage(messages.pleaseEnter)}
          disabled={props.formValues.policyPaidBySalary ? props.formValues.policyPaidBySalary.armyservice : false}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="employerAddress"
          component={FormTextField}
          label={<FormattedMessage {...messages.employerAddress} />}
          multiline
          rows={3}
          shrink
          fullWidth
          placeholder={intl.formatMessage(messages.pleaseProvideDetails)}
          type="text"  
          disabled={props.formValues.policyPaidBySalary? props.formValues.policyPaidBySalary.armyservice : false}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="natureOfCompanyBusiness"
          component={FormTextField}
          label={<FormattedMessage {...messages.natureOfCompanyBusiness} />}
          shrink
          fullWidth
          disabled={props.formValues.policyPaidBySalary? props.formValues.policyPaidBySalary.armyservice : false}
        />
      </Grid>
      <Grid item xs={4}></Grid>
      <Grid item xs={3}>
        <Field
          name="countryDialingCode"
          component={FormSelect}
          label={<FormattedMessage {...messages.countryCode} />}
          options={[
            { value: 'Select', title: 'Select an option' },
            ...options.countryDialingCode,
          ]}
          shrink
          fullWidth
        />
      </Grid>
      <Grid item xs={5}>
        <Field
          name="phoneNumber"
          component={FormNumberField}
          label={<FormattedMessage {...messages.employerPhone} />}
          shrink
          fullWidth
          thousandSeparator=""
          placeholder={intl.formatMessage(messages.pleaseEnter)}
        />
      </Grid>
      <Grid item xs={8}>
        <Field
          name="annualIncome"
          component={FormNumberField}
          label={<FormattedMessage {...messages.bonusAmount} />}
          shrink
          fullWidth
          placeholder={intl.formatMessage(messages.pleaseEnter)}
        />
      </Grid>
      <Grid
        item
        xs={12}
        style={{ padding: '12px 12px 0 12px', marginBottom: '-5px' }}
      >
        <Typography variant="body2" className={classes.bold}>
          <FormattedMessage {...messages.durationOfEmployment} />
        </Typography>
      </Grid>
      <Grid item xs={5}>
        <Field
          name="durationOfEmployment"
          component={FormNumberField}
          shrink
          fullWidth
        />
      </Grid>

      <Grid item xs={8}>
        <Field
          name="durationOfSavings"
          component={FormNumberField}
          label={<FormattedMessage {...messages.durationOfsavingsLabel} />}
          shrink
          fullWidth
          thousandSeparator=""
          placeholder={intl.formatMessage(messages.pleaseEnter)}
        />
      </Grid>
    </Grid>
  );
}

PayorPolicy.propTypes = {
  options: PropTypes.object,
  productCode: PropTypes.string,
  change: PropTypes.func,
};

export default PayorPolicy;

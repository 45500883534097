import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { map } from 'lodash/collection';
import moment from 'moment';
import numeral from 'numeral';

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';
import messages from '../../messages';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.action.hover,
  },
}))(TableRow);

function createData(amount, startDate, endDate, frequency) {
  return { amount, startDate, endDate, frequency };
}

// const rows = [createData('Mr. Frank Smith', '02.05.1986', 'Male', 'Smoker')];

function Withdrawals(props) {
  const theme = useTheme();

  const { productConfigs } = props;

  const currency = productConfigs?.currency;
  const regularWithdrawalAmount = productConfigs?.regularWithdrawal?.amount;
  const regularWithdrawalStartDate =
    productConfigs?.regularWithdrawal?.startDate;
  const regularWithdrawalEndDate = productConfigs?.regularWithdrawal?.endDate;
  const regularWithdrawalFrequency =
    productConfigs?.regularWithdrawal?.frequency;

  const educationFeesWithdrawalAmount =
    productConfigs?.educationFeesWithdrawal?.child1.amount;
  const educationFeesWithdrawalStartDate =
    productConfigs?.educationFeesWithdrawal?.child1.startDate;
  const educationFeesWithdrawalEndDate =
    productConfigs?.educationFeesWithdrawal?.child1.endDate;
  const educationFeesWithdrawalFrequency =
    productConfigs?.educationFeesWithdrawal?.child1.frequency;

  const rows = [
    productConfigs?.regularWithdrawal?.selected
      ? numeral(productConfigs?.regularWithdrawal.percentage).value() !== 0
        ? createData(
            `${productConfigs?.regularWithdrawal.percentage}%`,
            `${moment(regularWithdrawalStartDate).format('DD MMM YYYY')}`,
            `${moment(regularWithdrawalEndDate).format('DD MMM YYYY')}`,
            `${regularWithdrawalFrequency}`,
          )
        : createData(
            `${currency} ${numeral(regularWithdrawalAmount).format('0,0')}`,
            `${moment(regularWithdrawalStartDate).format('DD MMM YYYY')}`,
            `${moment(regularWithdrawalEndDate).format('DD MMM YYYY')}`,
            `${regularWithdrawalFrequency}`,
          )
      : null,

    productConfigs?.educationFeesWithdrawal &&
    productConfigs?.educationFeesWithdrawal.selected
      ? createData(
          `${currency} ${numeral(educationFeesWithdrawalAmount).format('0,0')}`,
          `${moment(educationFeesWithdrawalStartDate).format('DD MMM YYYY')}`,
          `${moment(educationFeesWithdrawalEndDate).format('DD MMM YYYY')}`,
          `${educationFeesWithdrawalFrequency}`,
        )
      : null,

    !isEmpty(productConfigs?.educationFeesWithdrawal?.child2) &&
    productConfigs?.educationFeesWithdrawal.selected
      ? createData(
          `${currency} ${numeral(
            productConfigs?.educationFeesWithdrawal?.child2.amount,
          ).format('0,0')}`,
          `${moment(
            productConfigs?.educationFeesWithdrawal?.child2.startDate,
          ).format('DD MMM YYYY')}`,
          `${moment(
            productConfigs?.educationFeesWithdrawal?.child2.endDate,
          ).format('DD MMM YYYY')}`,
          `${productConfigs?.educationFeesWithdrawal?.child2.frequency}`,
        )
      : null,

    !isEmpty(productConfigs?.educationFeesWithdrawal?.child3) &&
    productConfigs?.educationFeesWithdrawal.selected
      ? createData(
          `${currency} ${numeral(
            productConfigs?.educationFeesWithdrawal?.child3.amount,
          ).format('0,0')}`,
          `${moment(
            productConfigs?.educationFeesWithdrawal?.child3.startDate,
          ).format('DD MMM YYYY')}`,
          `${moment(
            productConfigs?.educationFeesWithdrawal?.child3.endDate,
          ).format('DD MMM YYYY')}`,
          `${productConfigs?.educationFeesWithdrawal?.child3.frequency}`,
        )
      : null,

    !isEmpty(productConfigs?.educationFeesWithdrawal?.child4) &&
    productConfigs?.educationFeesWithdrawal.selected
      ? createData(
          `${currency} ${numeral(
            productConfigs?.educationFeesWithdrawal?.child4.amount,
          ).format('0,0')}`,
          `${moment(
            productConfigs?.educationFeesWithdrawal?.child4.startDate,
          ).format('DD MMM YYYY')}`,
          `${moment(
            productConfigs?.educationFeesWithdrawal?.child4.endDate,
          ).format('DD MMM YYYY')}`,
          `${productConfigs?.educationFeesWithdrawal?.child4.frequency}`,
        )
      : null,
  ];

  const getPaymentFrequency = (frequency,singlePremiumTerm) => {
    switch (frequency) {
      case 'Monthly':
        return 'p/month';

      case 'Quarterly':
        return 'p/quarter';

      case 'Half-Yearly':
        return 'p/half-year';

      case 'Yearly':
        return 'p/year';

      case 'Single':
        if (singlePremiumTerm > 0){
          return 'per year';
        } else {
          return 'Single time';
        }

      default:
        return '';
    }
  };

  if (rows.filter(el => el !== null).length > 0) {
    return (
      <Grid item xs={12} md={10}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ color: theme.palette.primary.subMain }}
            >
              <FormattedMessage {...messages.withdrawals} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TableContainer>
              <Table aria-label="customized table">
                <TableBody>
                  {map(rows, (row, index) => {
                    if (row) {
                      return (
                        <StyledTableRow key={`${row.index}-${index}`}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{ width: '25%' }}
                          >
                            <Typography variant="h4">{row.amount}</Typography>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '25%' }}>
                            <Typography variant="h4">
                              {row.startDate}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '25%' }}>
                            <Typography variant="h4">{row.endDate}</Typography>
                          </StyledTableCell>
                          <StyledTableCell style={{ width: '25%' }}>
                            <Typography variant="h4">
                            {getPaymentFrequency(row.frequency,productConfigs?.singlePremiumTerm)}
                            </Typography>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return '';
}

Withdrawals.propTypes = {
  parties: PropTypes.array,
};

export default Withdrawals;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import { reduxForm } from 'redux-form/immutable';
import messages from '../../messages';
import GeneratePinInput from './GeneratePinInput';
import validate from './validate';

export function ZioPinForm({
  handleSubmit,
  zioPinLength,
  onSubmit,
  zioPinError,
}) {
  const theme = useTheme();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={2}
      >
        {/* <Grid
          item
          style={{
            textAlign: 'center',
            width: '257px',
          }}
        >
          <Typography
            variant="body2"
            style={{
              fontWeight: '700',
              color: theme.palette.info.main,
            }}
          >
            <FormattedMessage {...messages.pinFormate} />
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <GeneratePinInput zioPinLength={zioPinLength} />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" variant="contained">
            <FormattedMessage {...messages.login} />
          </Button>
        </Grid>
        <Grid item>
          {zioPinError?.error && (
            <Typography
              variant="body2"
              style={{
                color: theme.palette.error.main,
              }}
            >
              {' '}
              {zioPinError.message}
            </Typography>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

ZioPinForm.propTypes = {
  handleSubmit: PropTypes.func,
  zioPinLength: PropTypes.number,
  onSubmit: PropTypes.func,
  zioPinError: PropTypes.object,
};

export default reduxForm({
  form: 'zioPinForm',
  validate,
  // enableReinitialize: true,
})(ZioPinForm);

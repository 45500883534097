import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the hubPage state domain
 */

const selectHubPageDomain = state => state.get('hubPage', initialState);

/**
 * Other specific selectors
 */

/**
 * Default selector used by HubPage
 */

const makeSelectHubPage = () =>
  createSelector(selectHubPageDomain, substate => substate.toJS());

const makeSelectListApplicationParty = () =>
  createSelector(selectHubPageDomain, substate =>
    substate.get('listApplication'),
  );

const makeSelectListIllustration = () =>
  createSelector(selectHubPageDomain, substate =>
    substate.get('listIllustration'),
  );

const makeSelectListPolicy = () =>
  createSelector(selectHubPageDomain, substate => substate.get('listPolicy'));

export default makeSelectHubPage;
export {
  selectHubPageDomain,
  makeSelectListApplicationParty,
  makeSelectListIllustration,
  makeSelectListPolicy,
};

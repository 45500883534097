/**
 *
 * QuoteForm
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
  Grid,
  Typography,
  Collapse,
  Button,
  IconButton,
  Card,
  Divider,
  Box,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  getFormValues,
  getFormSyncWarnings,
} from 'redux-form/immutable';
import { InjectedFormProps, change } from 'redux-form';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
// import FormSelect from 'components/FormSelect';
import {
  AddCircle,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@material-ui/icons';
// import FormSlider from 'components/FormSlider';
import { formatMonetaryValue } from 'helpers/math';
// import numeral from 'numeral';
import IconPencilBlue from 'images/pencil-light-blue.svg';
import FormPremium from 'components/FormPremium';
import { Map } from 'immutable';
import { isEmpty } from 'lodash';
import LoadingDialog from 'components/LoadingDialog';
import Benefits from './Benefits';
import messages from '../../messages';
import validate from './validate';

const HeaderContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(3.25)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;
    background-color: ${props => props.theme.palette.primary.dark};
    color: ${props => props.theme.palette.common.white};
  }
`;

const BrContent = styled(({ theme, ...props }) => <div {...props} />)`
  && {
    padding-left: ${props => props.theme.spacing(3.25)}px;
    padding-top: ${props => props.theme.spacing(0.5)}px;
    padding-bottom: ${props => props.theme.spacing(0.5)}px;
    background-color: ${props => props.theme.palette.primary.main};
    color: ${props => props.theme.palette.common.white};
  }
`;
// const BottomRow = styled(({ theme, ...props }) => <Grid {...props} />)`
//   && {
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     min-height: 60px;
//     padding: ${props => props.theme.spacing(1)}px;
//     border-bottom: 1px solid ${props => props.theme.palette.divider};
//   }
// `;

function QuoteForm({
  premium,
  annualizedPremium,
  originalPremium,
  configsDetail,
  currency,
  paymentFrequency,
  specialTermsFlag,
  coverages,
  lifeAssured,
  handleSubmit,
  onSubmit,
  change,
  formValues,
  onUpdateValues,
  // setCustomPremium,
  warningCoverages,
  location,
  // offerCodeData,
  offerCode,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({ editIndex: 0, editInsured: 0 });
  const [isBrShowing, setBrShowing] = useState(false);
  const frequencyLabel = '';

  let isCoveragesError = false;
  if (!isEmpty(warningCoverages)) {
    warningCoverages.coverages.forEach(warningBenefit => {
      if (!isEmpty(warningBenefit)) {
        isCoveragesError = true;
      }
    });
  }

  return (
    <>
      {location.state.type === 1 ? (
        <LoadingDialog isLoading />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card>
            <HeaderContent theme={theme}>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography component="span" variant="h1">
                    {currency}
                  </Typography>
                </Grid>
                <Grid item>
                  <span
                    id="primaryPremiumAmount"
                    style={{
                      color: 'inherit',
                      fontSize: '6.6rem',
                      lineHeight: 1,
                      fontWeight: 700,
                    }}
                  >
                    {formatMonetaryValue(premium, true)}
                  </span>
                  <Typography color="inherit" variant="body1" component="span">
                    {frequencyLabel}
                  </Typography>
                </Grid>
              </Grid>
              {/* ########## START  :: EDIT PREMIUM ########## */}
              <Collapse in={open}>
                <Box pt={1}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item xs>
                      <Field
                        id="premium"
                        name="premium"
                        component={FormPremium}
                        min={originalPremium}
                        max={originalPremium}
                        label=""
                        diff={100}
                        isPrimary
                        type="text"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
              {/* ########## END    :: EDIT PREMIUM ########## */}

              <Divider
                style={{
                  backgroundColor: theme.palette.info.main,
                  marginTop: theme.spacing(1),
                  marginRight: theme.spacing(3),
                  marginBottom: theme.spacing(1),
                }}
              />
              <Collapse in={open}>
                <Typography
                  display="block"
                  variant="caption"
                  component="span"
                  color="inherit"
                >
                  <FormattedMessage {...messages.notePremiumCalculation} />
                </Typography>
              </Collapse>
              <Collapse in={!open}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs>
                    <Typography variant="body2" color="inherit">
                      <span id="secondaryPremiumAmount">
                        {formatMonetaryValue(annualizedPremium, true)}
                      </span>
                      {` ${currency} Annualised premium`}
                    </Typography>
                  </Grid>
                  {/* <Grid item>
                  <IconButton
                    id="edit"
                    onClick={() => setOpen(!open)}
                  >
                    <img src={IconPencilBlue} alt="" />
                  </IconButton>
                </Grid> */}
                  {/* <Grid item xs={3}>
                    <Grid container>
                      <Grid item xs={8} />
                      <Grid item xs={3}>
                        <IconButton
                          id="edit"
                          onClick={() => setOpen(!open)}
                          style={{
                            color: theme.palette.success.main,
                          }}
                        >
                          <img src={IconPencilBlue} alt="" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Collapse>
            </HeaderContent>

            {/* =========== BR CONTENT ============ */}
            {specialTermsFlag ? (
              <BrContent container theme={theme}>
                <Grid container direction="row" alignItems="center">
                  <Grid item xs={1}>
                    <AddCircle style={{ color: theme.palette.common.white }} />
                  </Grid>

                  <Grid item xs={10}>
                    <Typography
                      color="inherit"
                      // variant="h3"
                      component="span"
                      style={{ fontWeight: '900' }}
                    >
                      <FormattedMessage {...messages.brDebits} />
                    </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <IconButton onClick={() => setBrShowing(!isBrShowing)}>
                      {isBrShowing ? (
                        <KeyboardArrowUp
                          style={{ color: theme.palette.primary.light }}
                        />
                      ) : (
                        <KeyboardArrowDown
                          style={{ color: theme.palette.primary.light }}
                        />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>

                {isBrShowing ? (
                  <Divider
                    style={{
                      height: '2px',
                      color: theme.palette.primary.dark,
                    }}
                  />
                ) : null}

                <Collapse in={isBrShowing}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justify="space-between"
                    style={{ padding: theme.spacing(1) }}
                  >
                    <Grid item xs={6} />
                    <Grid item xs={3}>
                      <Typography
                        variant="body1"
                        component="span"
                        style={{ fontWeight: 'bold' }}
                      >
                        {lifeAssured.insuredOne.firstName}
                      </Typography>
                    </Grid>
                    {lifeAssured.isInsuredTwo ? (
                      <Grid item xs={3}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                          component="span"
                        >
                          {lifeAssured.insuredTwo.firstName}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>

                  {coverages.map((benefit, index) => {
                    const isShowingData =
                      benefit.brSelectedInsuredOneValue ||
                      benefit.brSelectedInsuredTwoValue ||
                      benefit.brSelectedPercentValueOne ||
                      benefit.brSelectedPercentValueTwo ||
                      benefit.brSelectedTempValueOne ||
                      benefit.brSelectedTempValueTwo;

                    return isShowingData ? (
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        style={{ padding: theme.spacing(1) }}
                        key={index}
                      >
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            component="span"
                            style={{
                              fontWeight: 'bold',
                              marginTop: theme.spacing(1),
                            }}
                          >
                            {benefit.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} />

                        {(benefit.brSelectedTempValueOne ||
                          benefit.brSelectedTempValueTwo) && (
                          <>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  color: theme.palette.grey[400],
                                  lineHeight: 3,
                                }}
                              >
                                <FormattedMessage {...messages.temporary} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedTempValueOne
                                  ? `+${benefit.perTempMileOne.toFixed(
                                      2,
                                    )} per mille`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedTempValueTwo
                                    ? `+${benefit.perTempMileTwo.toFixed(
                                        2,
                                      )} per mille`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}

                        {(benefit.brSelectedTempValueOne ||
                          benefit.brSelectedTempValueTwo) && (
                          <>
                            <Grid item xs={6}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                <FormattedMessage {...messages.period} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedTempValueOne
                                  ? `+${benefit.perTempMonthOne} months`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedTempValueTwo
                                    ? `+${benefit.perTempMonthTwo} months`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}

                        {(benefit.brSelectedInsuredOneValue ||
                          benefit.brSelectedInsuredTwoValue ||
                          benefit.brSelectedPercentValueOne ||
                          benefit.brSelectedPercentValueTwo) && (
                          <>
                            <Grid
                              item
                              xs={6}
                              style={{ marginTop: theme.spacing(1) }}
                            >
                              <Typography
                                variant="body1"
                                component="span"
                                style={{
                                  color: theme.palette.grey[400],
                                  lineHeight: 3,
                                }}
                              >
                                <FormattedMessage {...messages.permanent} />
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedInsuredOneValue
                                  ? `+${benefit.perMileOne.toFixed(
                                      2,
                                    )} per mille`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedInsuredTwoValue
                                    ? `+${benefit.perMileTwo.toFixed(
                                        2,
                                      )} per mille`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}

                            <Grid item xs={6} />
                            <Grid item xs={3}>
                              <Typography
                                variant="body1"
                                component="span"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {benefit.brSelectedPercentValueOne
                                  ? `+${benefit.perPercentageOne}%`
                                  : '-'}
                              </Typography>
                            </Grid>

                            {lifeAssured.isInsuredTwo ? (
                              <Grid item xs={3}>
                                <Typography
                                  variant="body1"
                                  component="span"
                                  style={{ color: theme.palette.grey[400] }}
                                >
                                  {benefit.brSelectedPercentValueTwo
                                    ? `+${benefit.perPercentageTwo}%`
                                    : '-'}
                                </Typography>
                              </Grid>
                            ) : null}
                          </>
                        )}
                      </Grid>
                    ) : null;
                  })}
                </Collapse>
              </BrContent>
            ) : null}

            <Grid
              container
              direction="row"
              spacing={1}
              justify="space-between"
              alignItems="center"
              style={{
                marginBottom: theme.spacing(1),
                marginTop: theme.spacing(1),
              }}
            >
              <Grid item xs />
              <Grid item xs={3}>
                <Typography
                  color="primary"
                  variant="h3"
                  style={{ marginLeft: theme.spacing(0.5) }}
                >
                  {lifeAssured.insuredOne.firstName}
                </Typography>
              </Grid>
              {lifeAssured.isInsuredTwo ? (
                <Grid item xs={3}>
                  <Typography
                    color="primary"
                    variant="h3"
                    style={{ marginLeft: theme.spacing(0.5) }}
                  >
                    {lifeAssured.insuredTwo.firstName}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>

            {coverages.length > 0 ? (
              <FieldArray
                name="coverages"
                component={Benefits}
                inactive={open}
                coverages={coverages}
                configsDetail={configsDetail}
                currency={currency}
                change={change}
                editData={editData}
                isCoveragesError={isCoveragesError}
                calculateEditData={val => {
                  // const isCheckOfferCode = val.index === 0 && offerCode !== '';
                  setEditData(val);
                  return onUpdateValues(formValues, false, false);
                }}
                changeEditData={val => {
                  setEditData(val);
                }}
                isInsuredTwo={lifeAssured.isInsuredTwo}
              />
            ) : null}

            {/*= ===== Offer code ====== */}
            {/* <BottomRow
              container
              theme={theme}
              style={{ padding: '16px 8px 16px 24px' }}
            >
              <Grid container alignItems="center" spacing={1} wrap="nowrap">
                <Grid item>
                  <Grid container alignItems="center" justify="center">
                    <CheckCircle style={{ color: theme.palette.grey[300] }} />
                  </Grid>
                </Grid>

                <Grid item xs>
                  <Typography
                    variant="h4"
                    style={{
                      fontWeight: '900',
                      color: theme.palette.common.black,
                    }}
                  >
                    <FormattedMessage {...messages.specialOffer} />
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item xs={8}>
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: 'bold',
                          color: theme.palette.common.black,
                        }}
                      >
                        {offerCode || '-'}
                        {offerCodeData.errorMessage && (
                          <>
                            <br />
                            <FormHelperText error>
                              {offerCodeData.errorMessage}
                            </FormHelperText>
                          </>
                        )}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{ marginRight: theme.spacing(1) }}
                    ></Grid>
                  </Grid>
                </Grid>
              </Grid>
            </BottomRow> */}
            {/* ========== END OFFER CODE ============ */}
          </Card>
          {/* {offerCodeData.discountMessage?.english &&
            offerCodeData.errorMessage === '' && (
              <Box my={2}>
                <Typography variant="body1" style={{ fontWeight: 700 }}>
                  <FormattedMessage
                    {...messages.offerCodeValid}
                    values={{
                      value: offerCodeData.discountMessage?.english,
                    }}
                  />
                </Typography>
              </Box>
            )} */}
        </form>
      )}
    </>
  );
}

QuoteForm.propTypes = {
  ...InjectedFormProps,
  lifeAssured: PropTypes.object,
  warningCoverages: PropTypes.object,
  options: PropTypes.object,
  configsDetail: PropTypes.object,
  currency: PropTypes.string,
  interestRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paymentFrequency: PropTypes.string,
  specialTermsFlag: PropTypes.bool,
  premium: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  annualizedPremium: PropTypes.number,
  coverages: PropTypes.any,
  originalPremium: PropTypes.number,
  formValues: PropTypes.object,
  onUpdateValues: PropTypes.func,
  setCustomPremium: PropTypes.func,
  setAnnualizedPremium: PropTypes.func,
};

const withForm = reduxForm({
  form: 'quoteForm',
  warn: validate,
  enableReinitialize: true,
  // keepDirtyOnReinitialize: true,
})(QuoteForm);

const selector = formValueSelector('quoteForm');
const syncWarningsSelector = getFormSyncWarnings('quoteForm');

const mapStateToProps = state => {
  const currency = selector(state, 'currency');
  const interestRate = selector(state, 'interestRate');
  const premium = selector(state, 'premium');
  const paymentFrequency = selector(state, 'paymentFrequency');
  const annualizedPremium = selector(state, 'annualizedPremium');
  const coverages = selector(state, 'coverages');
  const specialTermsFlag = selector(state, 'specialTermsFlag');
  const offerCode = selector(state, 'offerCode');
  const formValues = getFormValues('quoteForm')(state);
  let warningCoverages = syncWarningsSelector(state, 'coverages');
  if (warningCoverages instanceof Map) {
    warningCoverages = warningCoverages.toJS();
  }
  return {
    premium,
    annualizedPremium,
    interestRate,
    currency,
    paymentFrequency,
    specialTermsFlag,
    coverages: coverages?.toJS() || [],
    formValues: formValues?.toJS() || {},
    warningCoverages,
    offerCode,
  };
};

const mapDispatchToProps = () => ({});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withConnect;

/*
 *
 * OpsDashboard constants
 *
 */

export const GET_APPLICATION_DATA_ACTION =
'app/OpsDashboardPage/GET_APPLICATION_DATA_ACTION';
export const SET_APPLICATION_DATA_ACTION =
'app/OpsDashboardPage/SET_APPLICATION_DATA_ACTION';

export const REJECT_APPLICATION_ACTION =
'app/OpsDashboardPage/REJECT_APPLICATION_ACTION';

export const DEFAULT_ACTION = 'app/OpsDashboardPage/DEFAULT_ACTION';

//INITIAL SESSION
export const CALL_INITIAL_SESSION =
  'app/OpsDashboardPage/CALL_INITIAL_SESSION';
export const SET_LIFE_INSURED_PARTIES_ACTION =
  'app/OpsDashboardPage/SET_LIFE_INSURED_PARTIES_ACTION';
export const SET_SESSION_ID = 'app/OpsDashboardPage/SET_SESSION_ID';
export const SET_ETAG = 'app/OpsDashboardPage/SET_ETAG';
export const SET_UNDERWRITING_SECTIONS =
  'app/OpsDashboardPage/SET_UNDERWRITING_SECTIONS';
export const SET_APPLCATION_SUBORDERS =
  'app/OpsDashboardPage/SET_APPLCATION_SUBORDERS';

//APPLICATION DETAILS
export const GET_APPLICATION_DETAILS_ACTION =
  'app/OpsDashboardPage/GET_APPLICATION_DETAILS_ACTION';
export const SET_APPLICATION_DETAILS_ACTION =
  'app/OpsDashboardPage/SET_APPLICATION_DETAILS_ACTION';
export const UPDATE_APPLICATION_STATUS_ACTION =
  'app/OpsDashboardPage/UPDATE_APPLICATION_STATUS_ACTION';

//APPLICATION PARTY
export const UPDATE_APPLICATION_PARTY_ACTION =
  'app/OpsDashboardPage/UPDATE_APPLICATION_PARTY_ACTION';
export const UPDATE_APPLICATION_PARTIES_ACTION =
  'app/OpsDashboardPage/UPDATE_APPLICATION_PARTIES_ACTION';

export const ADD_APPLICATION_PARTY_ACTION =
  'app/OpsDashboardPage/ADD_APPLICATION_PARTY_ACTION';
export const ADD_APPLICATION_PARTIES_ACTION =
  'app/OpsDashboardPage/ADD_APPLICATION_PARTIES_ACTION';

export const DELETE_APPLICATION_PARTY_ACTION =
  'app/OpsDashboardPage/DELETE_APPLICATION_PARTY_ACTION';
export const DELETE_APPLICATION_PARTIES_ACTION =
  'app/OpsDashboardPage/DELETE_APPLICATION_PARTIES_ACTION';

//APPLICATION LIST
export const GET_APPLICATION_LIST_ACTION =
  'app/OpsDashboardPage/GET_APPLICATION_LIST_ACTION';
export const SET_APPLICATION_LIST_ACTION =
  'app/OpsDashboardPage/SET_APPLICATION_LIST_ACTION';

export const UPDATE_APPLICATION_DETAILS_ACTION =
  'app/OpsDashboardPage/UPDATE_APPLICATION_DETAILS_ACTION';

export const CALL_FINANCIAL_INSTITUTE_OPTION =
  'app/OpsDashboardPage/CALL_FINANCIAL_INSTITUTE_OPTION';

export const SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION =
  'app/OpsDashboardPage/SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION';

export const GET_LIST_APPLICATION_PARTY_ACTION =
  'app/OpsDashboardPage/GET_LIST_APPLICATION_PARTY_ACTION';
export const SET_LIST_APPLICATION_PARTY_ACTION =
  'app/OpsDashboardPage/SET_LIST_APPLICATION_PARTY_ACTION';
  
  export const DELETE_APPLICATION_ACTION =
  'app/OpsDashboardPage/DELETE_APPLICATION_ACTION';
  export const SET_DELETE_APPLICATION_ACTION =
  'app/OpsDashboardPage/SET_DELETE_APPLICATION_ACTION';
  export const SET_LOGOUT_WHEN_UPDATE_APP_ACTION =
  'app/OpsDashboardPage/SET_LOGOUT_WHEN_UPDATE_APP_ACTION';
  
  export const GET_SEARCH_ACTION = 'app/OpsDashboardPage/GET_SEARCH_ACTION'; 
  
  export const SET_ILLUSTRATIONS_ACTION =
  'app/OpsDashboardPage/SET_ILLUSTRATIONS_ACTION';
  export const SEND_EMAIL_ACTION = 'app/OpsDashboardPage/SEND_EMAIL_ACTION';
  
  export const GET_ADMIN_ACTION_DETAIL_ACTION =
    'app/OpsDashboardPage/GET_ADMIN_ACTION_DETAIL_ACTION';
  export const SET_ADMIN_ACTION_DETAIL_ACTION =
    'app/OpsDashboardPage/SET_ADMIN_ACTION_DETAIL_ACTION';
  
  export const GET_SUBMIT_ADMIN_ACTION =
    'app/OpsDashboardPage/GET_SUBMIT_ADMIN_ACTION';
  export const SET_SUBMIT_ADMIN_ACTION =
    'app/OpsDashboardPage/SET_SUBMIT_ADMIN_ACTION';
    
    export const SEND_ADMIN_ACTION_EMAIL_ACTION =
    'app/OpsDashboardPage/SEND_ADMIN_ACTION_EMAIL_ACTION';
    
    export const GET_ALL_DOCUMENTS_ACTION =
      'app/OpsDashboardPage/GET_ALL_DOCUMENTS_ACTION';
    export const SET_ALL_DOCUMENTS_ACTION =
      'app/OpsDashboardPage/SET_ALL_DOCUMENTS_ACTION';
  
      export const GET_SINGLE_DOCUMENTS_ACTION =
      'app/OpsDashboardPage/GET_SINGLE_DOCUMENTS_ACTION';
    export const SET_SINGLE_DOCUMENTS_ACTION =
      'app/OpsDashboardPage/SET_SINGLE_DOCUMENTS_ACTION';
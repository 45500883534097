/*
 *
 * QuotePage actions
 *
 */

import {
  SUB_PAGE_CHANGE_ACTION,
  EXIT_ACTION,
  SUB_PAGE_LIST_CHANGE_ACTION,
  SUB_PAGE_STEPPER_CHANGE_ACTION,
  LOGOUT_ACTION,
} from './constants';

export function changeSubPageAction(payload) {
  return {
    type: SUB_PAGE_CHANGE_ACTION,
    payload,
  };
}
export function changeSubStepPageAction(payload) {
  return {
    type: SUB_PAGE_STEPPER_CHANGE_ACTION,
    payload,
  };
}

export function changeSubPageListAction(payload) {
  return {
    type: SUB_PAGE_LIST_CHANGE_ACTION,
    payload,
  };
}

export function exitAction(payload) {
  return {
    type: EXIT_ACTION,
    payload,
  };
}

export function logoutAction(payload) {
  return {
    type: LOGOUT_ACTION,
    payload,
  };
}

/* eslint-disable react/prop-types */
/**
 *
 * Home
 *
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { compose } from 'redux';
import clsx from 'clsx';
import { dimension, typography } from 'styles/constants';
import numeral from 'numeral';
import { find } from 'lodash/collection';
import { isEmpty } from 'lodash';
import {
  Grid,
  Typography,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Radio,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from '@material-ui/core';
import messages from 'containers/IllustrationPage/messages';
import globalMessages from 'containers/App/messages';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import CustomRadio from 'components/CustomRadioButton';
import {
  changeSubPageAction,
  changeSubPageListAction,
} from 'containers/DashboardPage/actions';
import {
  makeSelectAgentDetails,
  makeSelectSelectedAgency,
} from 'containers/App/selectors';
import { makeSelectQuoteDetail } from 'containers/IllustrationPage/selectors';
import {
  resetProductConfigAction,
  getQuotesAction,
} from 'containers/IllustrationPage/actions';
import { useParams, useLocation } from 'react-router-dom';
import { formatMonetaryValue } from 'helpers/math';
import isEmail from 'validator/lib/isEmail';

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: ${props => props.theme.spacing(3)}px;
  }
`;

const useStyles = makeStyles({
  root: {
    width: 300,
    boxShadow: 'none',
  },
  makeAnotherBtn: {
    marginLeft: 25,
    backgroundColor: 'white',
    color: '#4066B3',
    border: '1px solid #A0B3D9',
    padding: '18px 32px',
    '&:hover': {
      color: 'white',
      backgroundColor: '#4066B3',
    },
  },
  makeAnotherBtnDisable: {
    marginLeft: 25,
    backgroundColor: '#ffffff',
    color: '#B6B8B4',
    border: '1px solid #BFBBAA',
    pointerEvents: 'none',
    padding: '18px 34px',
    '&:hover': {
      backgroudColor: '#ffffff',
    },
  },
  processThisOneBtnDisable: {
    backgroundColor: '#BFBBAA',
    padding: '18px 34px',
    color: '#ffffff',
    borderColor: '#BFBBAA',
    pointerEvents: 'none',
    border: '1px solid #A0B3D9',
    '&:hover': {
      backgroundColor: '#BFBBAA',
    },
  },
  processThisOneBtn: {
    padding: '18px 32px',
  },
  header: {
    marginTop: 8,
    fontSize: 28,
    color: '#3765B9',
    paddingBottom: 29,
  },
  buttonTextColor: {
    color: '#0E3494',
  },
  cardTitle: {
    fontSize: 12,
    color: '#9B9B9B',
  },
  clientButton: {
    display: 'flex',
    justifyContent: 'center',
    padding: '17px 10px',
    fontWeight: 'bold',
  },
  cardAvata: {
    marginRight: -30,
    marginLeft: 10,
  },
  backgroundContent: {
    backgroundColor: '#f2f2f2',
  },
  backgroundHeader: {
    backgroundColor: '#f9f9f9',
    padding: '10px 0px',
  },
  backgroundFooter: {
    backgroundColor: '#f9f9f9',
    padding: 0,
  },
  textPrice: {
    fontSize: 30,
    fontWeight: 'bold',
    marginRight: 3,
  },
  textDescription: {
    color: '#9B9B9B',
    fontSize: 12,
    marginBottom: 20,
  },
  borderLef: {
    borderLeft: '1px solid #DDDFE0',
  },
  titleProduct: {
    color: '#4066B3',
    fontWeight: 'bold',
    marginBottom: 28,
  },
  customRadio: {
    color: '#4066B3',
  },
  clientListBody: {
    width: 685,
    '@media (max-width:1024px)': {
      width: 676,
    },
    '@media (max-width:768px)': {
      width: '100%',
    },
  },
});
export function ClientList({
  resetProductConfig,
  quotes,
  getQuotes,
  sendEmail,
  getProductConfig,
  resetProductId,
}) {
  const theme = useTheme();
  const [selected, setSelected] = useState('');
  const [productCode, setProductCode] = useState('');
  const [productVersion, setProductVersion] = useState('');
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();

  useEffect(() => {
    const params = {
      id,
      productCode: location.state && location ? location.state.productCode : '',
      productVersion:
        location.state && location ? location.state.productVersion : '',
    };
    resetProductConfig();
    getQuotes(params);
  }, []);

  // eslint-disable-next-line no-shadow
  const handleChange = (id, productCode, productVersion) => {
    setSelected(id);
    setProductCode(productCode);
    setProductVersion(productVersion);
  };
  const foundQuote = find(quotes.quotes, q => {
    return q?.id === selected;
  });
  return (
    <Wrapper theme={theme}>
      <Typography variant="h1" className={classes.header}>
        {`Client list: ${quotes.firstName} ${quotes.lastName}`}
      </Typography>

      <Typography variant="body1" className={classes.titleProduct}>
        {quotes.quotes && quotes.quotes[0].productName}
      </Typography>

      <div className={classes.clientListBody}>
        <Grid container>
          <Typography
            components="div"
            style={{ width: '100%', marginBottom: 60 }}
          >
            <Button
              className={
                selected === ''
                  ? classes.processThisOneBtnDisable
                  : classes.processThisOneBtn
              }
              variant="contained"
              color="primary"
              onClick={() => {
                resetProductId();
                return getProductConfig({
                  productId: selected,
                  productCode,
                  productVersion,
                  type: 1,
                });
              }}
            >
              <FormattedMessage {...messages.processThisOne} />
            </Button>
            <Button
              variant="contained"
              className={
                selected === ''
                  ? classes.makeAnotherBtnDisable
                  : classes.makeAnotherBtn
              }
              onClick={() => {
                resetProductId();
                return getProductConfig({
                  productId: selected,
                  productCode,
                  productVersion,
                  type: 2,
                });
              }}
              disabled={foundQuote?.isInforce}
            >
              <FormattedMessage {...messages.makeAnother} />
            </Button>
          </Typography>
          {!isEmpty(quotes) &&
            quotes.quotes.map((item, index) => {
              let actualPremium = 0;
              let actualAnnualizedPremium = 0;
              if (item.pricingData) {
                if (item.isBundlingQuote) {
                  actualPremium = item.pricingData[2].premium?.toFixed(0);
                  actualAnnualizedPremium = item.pricingData[2].annualizedPremium?.toFixed(
                    0,
                  );
                } else {
                  actualPremium = numeral(item.productConfigs.premium).value();
                  switch (item.productConfigs.paymentFrequency) {
                    case 'Monthly':
                      actualAnnualizedPremium = actualPremium * 12;
                      break;
                    case 'Quarterly':
                      actualAnnualizedPremium = actualPremium * 4;
                      break;
                    case 'Half-Yearly':
                      actualAnnualizedPremium = actualPremium * 2;
                      break;
                    case 'Yearly':
                      actualAnnualizedPremium = actualPremium;
                      break;

                    default:
                      actualAnnualizedPremium = actualPremium;
                      break;
                  }

                  if (!item.productConfigs.isInforce) {
                    actualPremium = item.pricingData.response.premium?.toFixed(
                      0,
                    );
                    actualAnnualizedPremium = item.pricingData.response.annualizedPremium?.toFixed(
                      0,
                    );
                  }
                }
              }

              if (item.isBundlingQuote) {
                if (
                  item.productConfigs[0].policyCoverTerm === 'CustomPremium'
                ) {
                  actualPremium = numeral(
                    item.productConfigs[0].premium,
                  ).value();

                  switch (item.productConfigs[0].paymentFrequency) {
                    case 'Monthly':
                      actualAnnualizedPremium = actualPremium * 12;
                      break;
                    case 'Quarterly':
                      actualAnnualizedPremium = actualPremium * 4;
                      break;
                    case 'Half-Yearly':
                      actualAnnualizedPremium = actualPremium * 2;
                      break;
                    case 'Yearly':
                      actualAnnualizedPremium = actualPremium;
                      break;

                    default:
                      actualAnnualizedPremium = actualPremium;
                      break;
                  }
                }
              } else if (
                item.productConfigs.policyCoverTerm === 'CustomPremium'
              ) {
                actualPremium = numeral(item.productConfigs.premium).value();

                switch (item.productConfigs.paymentFrequency) {
                  case 'Monthly':
                    actualAnnualizedPremium = actualPremium * 12;
                    break;
                  case 'Quarterly':
                    actualAnnualizedPremium = actualPremium * 4;
                    break;
                  case 'Half-Yearly':
                    actualAnnualizedPremium = actualPremium * 2;
                    break;
                  case 'Yearly':
                    actualAnnualizedPremium = actualPremium;
                    break;

                  default:
                    actualAnnualizedPremium = actualPremium;
                    break;
                }
              }

              return (
                <Grid item xs={4} key={index}>
                  <Box mb={2.5}>
                    <Card className={classes.root}>
                      <CardHeader
                        avatar={
                          <FormControlLabel
                            control={
                              <CustomRadio
                                classes={{ root: classes.customRadio }}
                              />
                            }
                            onChange={() => {
                              resetProductId();
                              return handleChange(
                                item.id,
                                item.productCode,
                                item.productVersion,
                              );
                            }}
                            checked={item.id === selected}
                          />
                        }
                        title={`Ref #${item.referenceNumber}`}
                        onClick={() =>
                          handleChange(
                            item.id,
                            item.productCode,
                            item.productVersion,
                          )
                        }
                        classes={{
                          root: classes.backgroundHeader,
                          title: classes.cardTitle,
                          avatar: classes.cardAvata,
                        }}
                      />
                      <CardContent
                        classes={{ root: classes.backgroundContent }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{ display: 'flex', alignItems: 'baseline' }}
                          >
                            <Typography className={classes.textPrice}>
                              {actualPremium}
                            </Typography>
                            <Typography>
                              {item.isBundlingQuote
                                ? item.productConfigs[0].currency
                                : item.productConfigs.currency}
                            </Typography>
                          </Grid>
                          <Grid item className={classes.textDescription}>
                            <Typography variant="caption">
                              {`${actualAnnualizedPremium}
                               ann`}
                            </Typography>
                          </Grid>
                        </Grid>
                        {item.isBundlingQuote ? (
                          <>
                            <Grid container>
                              {!!item.productConfigs[0].coverages &&
                                item.productConfigs[0].coverages.map(
                                  element => (
                                    <>
                                      {element.isPrimary &&
                                        element.LFC &&
                                        element.LFC.selected && (
                                          <>
                                            <Grid item xs={8} sm={7}>
                                              <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold' }}
                                                gutterBottom
                                              >
                                                <FormattedMessage
                                                  {...messages.lifeCover}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={5}>
                                              <Typography variant="caption">
                                                {formatMonetaryValue(
                                                  element.LFC.value,
                                                  false,
                                                )}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                      {element.isPrimary &&
                                        element.CRI &&
                                        element.CRI.selected && (
                                          <>
                                            <Grid item xs={8} sm={7}>
                                              <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold' }}
                                                gutterBottom
                                              >
                                                <FormattedMessage
                                                  {...messages.ci}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={5}>
                                              <Typography variant="body2">
                                                {formatMonetaryValue(
                                                  element.CRI.value,
                                                  false,
                                                )}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                      {element.isPrimary &&
                                        element.WOP &&
                                        element.WOP.selected && (
                                          <>
                                            <Grid item xs={8} sm={7}>
                                              <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                <FormattedMessage
                                                  {...messages.waiver}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={5}>
                                              <Typography variant="caption">
                                                {element.WOP.value
                                                  ? 'Yes'
                                                  : 'No'}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                    </>
                                  ),
                                )}
                            </Grid>

                            <Grid container>
                              <Grid item>
                                <Typography
                                  variant="caption"
                                  style={{ fontWeight: 'bold' }}
                                  gutterBottom
                                >
                                  <FormattedMessage
                                    {...messages.protectionBenefit}
                                  />
                                </Typography>
                              </Grid>

                              {!!item.productConfigs[1].coverages &&
                                item.productConfigs[1].coverages.map(
                                  element => (
                                    <>
                                      {element.isPrimary &&
                                        element.LFC &&
                                        element.LFC.selected && (
                                          <>
                                            <Grid item xs={8} sm={7}>
                                              <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold' }}
                                                gutterBottom
                                              >
                                                <FormattedMessage
                                                  {...messages.lifeCover}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={5}>
                                              <Typography variant="caption">
                                                {formatMonetaryValue(
                                                  element.LFC.value,
                                                  false,
                                                )}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                      {element.isPrimary &&
                                        element.CRI &&
                                        element.CRI.selected && (
                                          <>
                                            <Grid item xs={8} sm={7}>
                                              <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold' }}
                                                gutterBottom
                                              >
                                                <FormattedMessage
                                                  {...messages.ci}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={5}>
                                              <Typography variant="caption">
                                                {formatMonetaryValue(
                                                  element.CRI.value,
                                                  false,
                                                )}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                      {element.isPrimary &&
                                        element.WOP &&
                                        element.WOP.selected && (
                                          <>
                                            <Grid item xs={8} sm={7}>
                                              <Typography
                                                variant="caption"
                                                style={{ fontWeight: 'bold' }}
                                              >
                                                <FormattedMessage
                                                  {...messages.waiver}
                                                />
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={4} sm={5}>
                                              <Typography variant="caption">
                                                {element.WOP.value
                                                  ? 'Yes'
                                                  : 'No'}
                                              </Typography>
                                            </Grid>
                                          </>
                                        )}
                                    </>
                                  ),
                                )}
                            </Grid>
                          </>
                        ) : (
                          <Grid container>
                            {!!item.productConfigs.coverages &&
                              item.productConfigs.coverages.map(element => (
                                <>
                                  {element.isPrimary &&
                                    element.LFC &&
                                    element.LFC.selected && (
                                      <>
                                        <Grid item xs={8} sm={7}>
                                          <Typography
                                            variant="caption"
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            <FormattedMessage
                                              {...messages.lifeCover}
                                            />
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={5}>
                                          <Typography variant="caption">
                                            {formatMonetaryValue(
                                              element.LFC.value,
                                              false,
                                            )}
                                          </Typography>
                                        </Grid>
                                      </>
                                    )}
                                  {element.isPrimary &&
                                    element.CRI &&
                                    element.CRI.selected && (
                                      <>
                                        <Grid item xs={8} sm={7}>
                                          <Typography
                                            variant="caption"
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            <FormattedMessage
                                              {...messages.ci}
                                            />
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={5}>
                                          <Typography variant="caption">
                                            {formatMonetaryValue(
                                              element.CRI.value,
                                              false,
                                            )}
                                          </Typography>
                                        </Grid>
                                      </>
                                    )}
                                  {element.isPrimary &&
                                    element.WOP &&
                                    element.WOP.selected && (
                                      <>
                                        <Grid item xs={8} sm={7}>
                                          <Typography
                                            variant="caption"
                                            style={{ fontWeight: 'bold' }}
                                          >
                                            <FormattedMessage
                                              {...messages.waiver}
                                            />
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={4} sm={5}>
                                          <Typography variant="caption">
                                            {element.WOP.value ? 'Yes' : 'No'}
                                          </Typography>
                                        </Grid>
                                      </>
                                    )}
                                </>
                              ))}
                          </Grid>
                        )}
                      </CardContent>
                      <CardActions classes={{ root: classes.backgroundFooter }}>
                        <Grid container>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            className={classes.clientButton}
                          >
                            <Button
                              className={classes.buttonTextColor}
                              size="small"
                              onClick={() => {
                                handleChange(
                                  item.id,
                                  item.productCode,
                                  item.productVersion,
                                );
                                return setOpen(true);
                              }}
                            >
                              <FormattedMessage {...messages.email} />
                            </Button>
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            sm={6}
                            className={clsx(
                              classes.clientButton,
                              classes.borderLef,
                            )}
                          >
                            <Button
                              className={classes.buttonTextColor}
                              size="small"
                              onClick={() =>
                                getProductConfig({
                                  productId: item.id,
                                  productCode: item.productCode,
                                  productVersion: item.productVersion,
                                  type: 1,
                                })
                              }
                            >
                              <FormattedMessage {...messages.view} />
                            </Button>
                          </Grid>
                        </Grid>
                      </CardActions>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </div>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage {...messages.emailPDF} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            id="emailAddress"
            label={<FormattedMessage {...globalMessages.email} />}
            type="email"
            error={error}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (email && isEmail(email)) {
                setError(false);
                sendEmail({ id: selected, email });
                return setOpen(false);
              }
              return setError(true);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.send} />
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
}

ClientList.propTypes = {};

const mapStateToProps = createStructuredSelector({
  // auth: makeSelectAuth(),
  selectedAgency: makeSelectSelectedAgency(),
  agentDetails: makeSelectAgentDetails(),
  quotes: makeSelectQuoteDetail(),
});

function mapDispatchToProps(dispatch) {
  return {
    changeSubPage: value => dispatch(changeSubPageAction(value)),
    changeSubPageList: value => dispatch(changeSubPageListAction(value)),
    resetProductConfig: () => dispatch(resetProductConfigAction()),
    getQuotes: value => dispatch(getQuotesAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ClientList);

/**
 * Withdrawal
 */
 import React from 'react';
 import PropTypes from 'prop-types';
 import { FormattedMessage, useIntl } from 'react-intl';
 import { useTheme, makeStyles } from '@material-ui/core/styles';
 import { Field } from 'redux-form/immutable';
 import {
   Grid,
   FormControlLabel,
   Button,
   Divider,
   Box,
 } from '@material-ui/core';
 import globalMessages from 'containers/App/messages';
 import FormTextField from 'components/FormTextField';
 import FormDatePicker from 'components/FormDatePicker';
 import FormSelect from 'components/FormSelect';
 import FormRadioGroup from 'components/FormRadioGroup';
 import FormNumberField from 'components/FormNumberField';
 import CustomRadio from 'components/CustomRadioButton';
 import numeral from 'numeral';
 import { changePlaceHolderColor, convertIfImmutable, changeToTitleCase } from 'helpers/lang';
 import { blue } from '@material-ui/core/colors';
 import messages from 'containers/VistaIllustrationPage/messages';
 
 const useStyles = makeStyles(theme => ({
   hr: {
     borderTop: '1px solid rgba(0,0,0,0.12)',
     marginTop: theme.spacing(4),
     marginBottom: theme.spacing(5),
     width: '100%',
   },
 }));
 
 const newEducationWDField = {
   frequency: 'Select',
   startDate: null,
   endDate: null,
   firstName: '',
   lastName: '',
   amount: null,
 };
 
 function EducationWithdraw({
   frequencyList,
   isBHAndQatar,
   minDate,
   maxDate,
   fields,
 }) {
   const theme = useTheme();
   const intl = useIntl();
   const classes = useStyles();
   const onAddMoreEducationWD = () => {
     if (fields.length < 4) {
       fields.push({ ...newEducationWDField });
     }
   };
   return fields.map((field, index) => {
     return (
       <Box mb={3} ml={1}>
         <Grid container spacing={3} alignItems="center">
           <Grid item>
             <Field
               shrink
               name={`${field}.frequency`}
               component={FormSelect}
               style={{
                 width: 260,
               }}
               // options={frequencyList}
               options={[{ value: 'Select', title: 'Select' }, ...frequencyList]}
               type="text"
               variant="standard"
               label={<FormattedMessage {...messages.frequency} />}
             />
           </Grid>
 
           <Grid item sm={5} xs={12} md={5}></Grid>
 
           <Grid item>
             <Field
               shrink
               style={{
                 width: 260,
               }}
               name={`${field}.startDate`}
               component={FormDatePicker}
               label={<FormattedMessage {...messages.startDate} />}
               dateInputFormat="DD/MM/YYYY"
               placeholder="dd/mm/yyyy"
               margin="normal"
               minDate={minDate}
               maxDate={maxDate}
               InputLabelProps={{
                 style: {
                   fontWeight: 'normal',
                   fontSize: theme.typography.body1.fontSize,
                 },
               }}
               shouldDisableDate={date => {
                 if (date.date() != 15) {
                   return true;
                 }
                 return false;
               }}
             />
           </Grid>
 
           <Grid item>
             <Field
               shrink
               style={{
                 width: 260,
               }}
               name={`${field}.endDate`}
               component={FormDatePicker}
               label={<FormattedMessage {...messages.endDate} />}
               dateInputFormat="DD/MM/YYYY"
               placeholder="dd/mm/yyyy"
               margin="normal"
               minDate={minDate}
               maxDate={maxDate}
               InputLabelProps={{
                 style: {
                   fontWeight: 'normal',
                   fontSize: theme.typography.body1.fontSize,
                 },
               }}
             />
           </Grid>
 
           <Grid item>
             <Field
               shrink
               name={`${field}.firstName`}
               style={{
                 width: 260,
               }}
               component={FormTextField}
               label={<FormattedMessage {...messages.firstName} />}
               placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
               margin="normal"
               format = {(value) => changeToTitleCase(value)} 
             />
           </Grid>
 
           <Grid item>
             <Field
               shrink
               name={`${field}.lastName`}
               style={{
                 width: 260,
               }}
               component={FormTextField}
               label={<FormattedMessage {...messages.lastName} />}
               placeholder={intl.formatMessage(globalMessages.fieldPlaceholder)}
               margin="normal"
               format = {(value) => changeToTitleCase(value)} 
             />
           </Grid>
 
           <Grid item xs={12}>
             <Field
               shrink
               name={`${field}.amount`}
               component={FormNumberField}
               style={{
                 width: 134,
               }}
               type="text"
               variant="standard"
               label="Amount"
             />
 
             {isBHAndQatar && index + 1 === fields.length && fields.length <= 3 && (
               <Button
                 onClick={onAddMoreEducationWD}
                 style={{ color: blue[800], margin: '27px 0 0 5px' }}
               >
                 Add another
               </Button>
             )}
 
             {fields.length > 1 && (
               <Button
                 onClick={() => {
                   fields.remove(index);
                 }}
                 style={{ color: blue[800], margin: '27px 0 0 5px' }}
               >
                 Delete
               </Button>
             )}
           </Grid>
 
           <Divider className={classes.hr} />
         </Grid>
       </Box>
     );
   });
 }
 
 EducationWithdraw.propTypes = {
   formValues: PropTypes.object,
   frequencyList: PropTypes.array,
   minDate: PropTypes.any,
   maxDate: PropTypes.any,
   error: PropTypes.bool,
   isAddMoreEduWD: PropTypes.bool,
   isSecondEduWD: PropTypes.bool,
   isEducationWithdrawal: PropTypes.bool,
   isBHAndQatar: PropTypes.bool,
 };
 
 export default EducationWithdraw;
 
/*
 *
 * SwIllustrationPage reducer
 *
 */

import { fromJS } from 'immutable';
import {
  CHANGE_LIFE_ASSURED_ACTION,
  SET_PRODUCT_CONFIG_ACTION,
  SET_QUOTE_ACTION,
  SET_CALCULATION_ACTION,
  SET_ERROR_ACTION,
  SET_QUOTE_DETAILS_ACTION,
  RESET_ACTION,
  SET_APPLICATION_ID_ACTION,
  SET_SW_CONFIG_ACTION,
  SET_FIA_ACTION,
  SET_BI_TABLE_VALUES_ACTION,
  SET_CLONE_STATUS_ACTION,
  SET_BI_PDF_ACTION,
  SET_EMAIL_SENT_ACTION,
  SET_EMAIL_ERROR_MESSAGE_ACTION,
  SET_PRODUCT_CODE_ACTION,
  SET_PTV_VERSION_ACTION,
  SET_FORM_INPUT,
} from './constants';
import { getStartDate } from './helper';

const defaultState = {
  emailErrorMessage: '',
  emailSent: false,
  quoteId: '',
  applicationId: '',
  referenceNumber: '',
  benefitIllustrationPdf: '',
  input: {},
  status: '',
  errorData: {
    error: false,
    message: [],
  },
  lifeAssured: {
    insuredOne: {
      prospectId: '',
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      isSmoke: null,
      isLifeInsured: true,
      isPrimaryLifeInsured: true,
      isPolicyOwner: true,
      relationshipToLifeInsured: '',
    },
    isInsuredTwo: false,
    insuredTwo: {
      prospectId: '',
      title: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      gender: '',
      isSmoke: null,
      isLifeInsured: true,
      isPolicyOwner: false,
      isPrimaryLifeInsured: false,
      relationshipToLifeInsured: '',
    },
    isPolicyOwnerIndividual: false,
    policyOwnerIndividual: [
      {
        prospectId: '',
        firstName: '',
        lastName: '',
        relationshipToLifeInsured: '',
        isPolicyOwner: true,
        isLifeInsured: false,
        isPrimaryLifeInsured: false,
      },
    ],
  },
  productConfig: {
    name: 'Simple Wealth',
    productCode: 'SIMW2',
    ptvVersion: 'SIMW2_0001',
    fiaCharge: 0,
    establishmentCharge: 4.5,
    startDate: getStartDate().format('YYYY-MM-DD'),
    currency: 'Select',
    paymentFrequency: '',
    offerCode: '',
    premium: null,
    growthRate: '',
    isAdditionalFeatures: false,
    isRegularWithdrawal: false,
    targetFunctionality: false,
    regularWithdrawal: {
      frequency: 'Select',
      startDate: '',
      endDate: '',
      amount: 0,
      percentage: 0,
      isAmountChecked: 1,
    },
  },
  biTableValues: {},
  swConfig: {},
  fiaData: {},
  initialLifeAssured: {},
  successClone: false,
};

export const initialState = fromJS({ ...defaultState });

function swIllustrationPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR_ACTION:
      return state.set('errorData', fromJS(action.payload));
    case RESET_ACTION:
      return fromJS({ ...defaultState });
    case SET_QUOTE_ACTION:
      return state.set('quoteId', action.payload);
    case SET_APPLICATION_ID_ACTION:
      return state.set('applicationId', action.payload);
    case SET_SW_CONFIG_ACTION:
      return state.set('swConfig', fromJS(action.payload));
    case SET_BI_TABLE_VALUES_ACTION:
      return state.set('biTableValues', fromJS(action.payload));
    case CHANGE_LIFE_ASSURED_ACTION:
      return state
        .set('lifeAssured', fromJS(action.payload))
        .set('initialLifeAssured', fromJS(action.payload));
    case SET_PRODUCT_CONFIG_ACTION:
      return state.set('productConfig', fromJS(action.payload.data));
    case SET_PRODUCT_CODE_ACTION:
      return state.setIn(['productConfig', 'productCode'], action.payload);
    case SET_PTV_VERSION_ACTION:
      return state.setIn(['productConfig', 'ptvVersion'], action.payload);
    case SET_QUOTE_DETAILS_ACTION:
      return (
        state
          .set('referenceNumber', action.payload.referenceNumber)
          //.set('benefitIllustrationPdf', action.payload.benefitIllustrationPdf)
          .set('status', action.payload.status)
      );
    case SET_CALCULATION_ACTION:
      return state.setIn(['productConfig', 'premium'], action.payload);
    case SET_FIA_ACTION:
      return state.set('fiaData', fromJS(action.payload));
    case SET_CLONE_STATUS_ACTION:
      return state.set('successClone', action.payload);
    case SET_BI_PDF_ACTION:
      return state.set('benefitIllustrationPdf', action.payload);
    case SET_EMAIL_SENT_ACTION:
      return state.set('emailSent', action.payload);
    case SET_EMAIL_ERROR_MESSAGE_ACTION:
      return state.set('emailErrorMessage', action.payload);
    case SET_FORM_INPUT:
      return state.set('input', fromJS(action.payload));
    default:
      return state;
  }
}

export default swIllustrationPageReducer;

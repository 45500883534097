import React from 'react';
import { convertIfImmutable, partyFilter } from 'helpers/lang';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import { forEach } from 'lodash/collection';
import messages from 'containers/ApplicationFormPage/messages';
import globalMessages from 'containers/App/messages';

const validate = values => {
  const errors = {};
  const parseValues = values.toJS();
  const fundErrors = {};
  const fundPercentageErrors = [];
  let totalPercentage = 0;
  let displayPercent = 0;
  forEach(parseValues.FundList, (fund, index) => {
    if (isEmpty(fund.percent)) {
      fundErrors.percent = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
      fundPercentageErrors[index] = fundErrors;
    }
    if (parseInt(fund.percent) === 0) {
      fundErrors.percent = (
        <FormattedMessage {...globalMessages.minLimit} values={{ value: 1 }} />
      );
      fundPercentageErrors[index] = fundErrors;
    }

    // totalPercentage += parseInt(fund.percent);
    totalPercentage += parseFloat(fund.percent);
  });
  displayPercent = Number.isInteger(totalPercentage) ? parseInt(totalPercentage) : parseFloat(totalPercentage).toFixed(2);
  if (fundPercentageErrors.length > 0) {
    errors.FundList = fundPercentageErrors;
  }
  if (displayPercent > 100) {
    errors.totalPercentage = (
      <FormattedMessage {...messages.maximumPercentage100Allowed} />
    );
    errors._error = 'submission failed';
  }
  if (displayPercent < 100) {
    errors.totalPercentage = (
      <FormattedMessage {...messages.totalPrecentMustBe100} />
    );
    errors._error = 'submission failed';
  }
  // if (!parseValues.percent) {
  //   errors.percent = 'Field is Required'
  // }

  //  for the ASP Fund Value
  const ASPfundPercentageErrors = [];
  let ASPtotalPercentage = 0;
  let ASPdisplayPercent = 0;
  if(parseValues?.isAsp && parseValues?.isInforce){
    forEach(parseValues.ASPFundList, (fund, index) => {

      if (isEmpty(fund.percent)) {
        fundErrors.percent = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
        ASPfundPercentageErrors[index] = fundErrors;
      }
      if (parseInt(fund.percent) === 0) {
        fundErrors.percent = (
          <FormattedMessage {...globalMessages.minLimit} values={{ value: 1 }} />
        );
        ASPfundPercentageErrors[index] = fundErrors;
      }

      ASPtotalPercentage += parseFloat(fund.percent);

    });
    ASPdisplayPercent = Number.isInteger(ASPtotalPercentage) ? parseInt(ASPtotalPercentage) : parseFloat(ASPtotalPercentage).toFixed(2);

    if (ASPfundPercentageErrors.length > 0) {
      errors.ASPFundList = ASPfundPercentageErrors;
    }

    if (ASPdisplayPercent > 100) {
      errors.totalASPPercentage = (
        <FormattedMessage {...messages.maximumPercentage100Allowed} />
      );
      errors._error = 'submission failed';
    }
    if (ASPdisplayPercent < 100) {
      errors.totalASPPercentage = (
        <FormattedMessage {...messages.totalPrecentMustBe100} />
      );
      errors._error = 'submission failed';
    }
  }
  // End -  for the ASP Fund Value

  return errors;
};

export default validate;

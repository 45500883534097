import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash/lang';
import globalMessages from 'containers/App/messages';

import {
  getMaxCurrencyValue,
  getMinCurrencyValue,
} from 'containers/SwIllustrationPage/helper';

const validate = (values, props) => {
  const errors = {};
  const swConfig = props.swConfig;
  const MAX_GROWTH_RATE = swConfig?.features?.growthRate?.maxRate;
  const MIN_GROWTH_RATE = swConfig?.features?.growthRate?.minRate;
  const MAX_TARGET_FUND = 999999999;
  const MAX_TARGE_YEAR = 2100;

  if (!values.isEmpty()) {
    const offerCodeValues = values.get('offerCode');
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const growthRateValues = values.get('growthRate');
    const premiumValues = values.get('premium');
    const targetFunctionalityValues = values.get('targetFunctionality');
    const hasToCalculate = values.get('hasToCalculate');
    const targetYearValues = values.get('targetYear');
    const targetFundValues = values.get('targetFund');

    const isRegularWithdrawalValues = values.get('isRegularWithdrawal');
    const regularWithdrawalValues = values.get('regularWithdrawal');
    // const educationFeeWithdrawalValues = values.get('educationFeesWithdrawal').get('child1');
    // const coveragesValues = values.get('coverages');

    if (!startDateValues || !moment(startDateValues).isValid())
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (targetFunctionalityValues) {
      if (!targetYearValues || targetYearValues === 'Invalid date')
        errors.targetYear = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (
        targetYearValues &&
        targetYearValues < new Date().getFullYear() + 5
      )
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: new Date().getFullYear() + 5 }}
          />
        );
      else if (targetYearValues && targetYearValues > MAX_TARGE_YEAR)
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_TARGE_YEAR }}
          />
        );

      if (!targetFundValues)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (targetFundValues && targetFundValues <= 0)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.greaterThanZero} />
        );
      else if (targetFundValues > MAX_TARGET_FUND) {
        errors.targetFund = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{
              value: MAX_TARGET_FUND.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              ),
            }}
          />
        );
      }
    }

    if (hasToCalculate) {
      errors.hasToCalculate = (
        <FormattedMessage {...globalMessages.hasToCalculate} />
      );
    }

    if (!Number(premiumValues)) {
      if (!targetFunctionalityValues)
        errors.premium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
    } else {
      var minVal = 0;
      var maxVal = 0;
      minVal = getMinCurrencyValue(
        currencyValues,
        swConfig.features,
        'Premium amount',
      );
      maxVal = getMaxCurrencyValue(currencyValues, swConfig.features);

      if (premiumValues < minVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minVal }}
          />
        );

      if (premiumValues > maxVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: maxVal }}
          />
        );
    }

    if (!growthRateValues)
      errors.growthRate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    else {
      if (growthRateValues <= MIN_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage {...globalMessages.minLimitGrowthRate} />
        );
      if (growthRateValues > MAX_GROWTH_RATE)
        errors.growthRate = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_GROWTH_RATE }}
          />
        );
    }

    //Regular withdrawal validation
    if (isRegularWithdrawalValues) {
      var errorRegularWithdrawal = {};
      if (
        !regularWithdrawalValues.get('frequency') ||
        regularWithdrawalValues.get('frequency') === 'Select'
      )
        errorRegularWithdrawal.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );

      if (
        !regularWithdrawalValues.get('startDate') ||
        !moment(regularWithdrawalValues.get('startDate')).isValid()
      )
        errorRegularWithdrawal.startDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var minStartDate = moment(startDateValues)
          .date(15)
          .add(1, 'months')
          .toDate();

        if (moment(regularStartDateValues, 'YYYY-MM-DD').format('DD') != 15)
          errorRegularWithdrawal.startDate = (
            <FormattedMessage {...globalMessages.dateValidation2} />
          );
        else {
          if (regularStartDateValues.isBefore(minStartDate))
            errorRegularWithdrawal.startDate = (
              <FormattedMessage
                {...globalMessages.dateValidation}
                values={{ value: moment(minStartDate).format('DD/MM/YYYY') }}
              />
            );
        }
      }

      if (
        !regularWithdrawalValues.get('endDate') ||
        !moment(regularWithdrawalValues.get('endDate')).isValid()
      )
        errorRegularWithdrawal.endDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularEndDateValue = moment(
          regularWithdrawalValues.get('endDate'),
        );
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var maxEndDate = moment(regularStartDateValues)
          .add(100, 'years')
          .toDate();

        if (regularEndDateValue.isBefore(regularStartDateValues))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.minEndDateValidation} />
          );

        if (regularEndDateValue.isAfter(maxEndDate))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.maxEndDateValidation} />
          );
      }

      if (regularWithdrawalValues.get('isAmountChecked') == 1) {
        if (!regularWithdrawalValues.get('amount'))
          errorRegularWithdrawal.amount = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const withdrawalAmount = regularWithdrawalValues.get('amount');
          var minPremiumAllowed = 0;
          var maxPremiumAllowed = 0;

          swConfig.features.withdrawalSize.map(premiumSize => {
            if (premiumSize.currency === currencyValues) {
              minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
              //maxPremiumAllowed = premiumSize.maxPremiumSingle;
            }
          });

          maxPremiumAllowed = getMaxCurrencyValue(
            currencyValues,
            swConfig.features,
          );

          if (withdrawalAmount < minPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPremiumAllowed }}
              />
            );

          if (withdrawalAmount > maxPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPremiumAllowed }}
              />
            );
        }
      }

      if (regularWithdrawalValues.get('isAmountChecked') == 2) {
        if (!regularWithdrawalValues.get('percentage'))
          errorRegularWithdrawal.percentage = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const minPercentage =
            swConfig.features?.regularWithdrawal?.minPercentage;
          const maxPercentage =
            swConfig.features?.regularWithdrawal?.maxPercentage;

          if (regularWithdrawalValues.get('percentage') <= 0)
            errorRegularWithdrawal.percentage = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPercentage }}
              />
            );

          if (regularWithdrawalValues.get('percentage') > maxPercentage)
            errorRegularWithdrawal.percentage = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPercentage }}
              />
            );
        }
      }
      if (!isEmpty(errorRegularWithdrawal)) {
        errors.regularWithdrawal = errorRegularWithdrawal;
      }
    }

    if (!isEmpty(offerCodeValues)) {
      if (premiumValues === null) {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseFillInThePremium} />
        );
      }
      if (currencyValues === 'Select') {
        errors.offerCode = (
          <FormattedMessage {...globalMessages.pleaseSelectCurrency} />
        );
      }
    }
  }
  return errors;
};

export default validate;

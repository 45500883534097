/**
 *
 * ApplicationPage
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import styled from 'styled-components';
import {
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from '@material-ui/core';
import { filterSearchApplication } from 'helpers/quote';
import { convertIfImmutable } from 'helpers/lang';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import messages from 'containers/ApplicationPage/messages';
import {
  makeSelectQuoteType,
  makeSelectAgentDetails,
} from 'containers/App/selectors';
import TextField from 'components/TextField';
import Search from 'icons/Search';
import {
  getApplicationListAction,
  deleteApplicationAction,
  setApplicationDetailsAction,
} from 'containers/ApplicationPage/actions';
import { TableApplication } from './TableApplication';

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 ${props => props.theme.spacing(2)}px
      ${props => props.theme.spacing(2)}px ${props => props.theme.spacing(2)}px;
  }
`;

const useStyles = makeStyles({
  btnSearch: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleHeader: {
    marginTop: 8,
    fontWeight: 300,
    fontSize: 28,
  },
  icon: {
    color: '#dddfe0',
  },
  applicationHeader: {
    position: 'fixed',
    width: '100%',
    // height: 'fit-content', // Safari bugs
    backgroundColor: '#ffffff',
    paddingBottom: 35,
  },
  widthSearch: {
    padding: 0,
  },
});

export function ApplicationList(props) {
  const theme = useTheme();
  const classes = useStyles();

  const {
    onSearch,
    listApplication,
    quoteType,
    agentDetails,
    setValueSearch,
    deleteApplication,
    setApplicationDetails,
    updateApplicationDetails,
  } = props;

  const searchedApplication = filterSearchApplication(
    convertIfImmutable(listApplication),
    quoteType,
    agentDetails.products,
  );

  const handleSearch = value => {
    setValueSearch(value);
  };

  const onSubmit = e => {
    e.preventDefault();
    onSearch();
  };

  return (
    <form onSubmit={onSubmit}>
      <Wrapper theme={theme}>
        <Grid className={classes.applicationHeader}>
          <Box mt={8} />
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h1" className={classes.titleHeader}>
                <FormattedMessage {...messages.openApplication} />
              </Typography>
            </Grid>
            <Grid item xs={5} md={3} lg={4} className={classes.btnSearch}>
              <TextField
                className={classes.widthSearch}
                // value={valueSearch}
                onChange={event => {
                  handleSearch(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" onClick={onSubmit}>
                      <IconButton>
                        <Search className={classes.icon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <TableApplication
          dataApplication={searchedApplication}
          deleteApplication={deleteApplication}
          setApplicationDetails={setApplicationDetails}
        />
      </Wrapper>
    </form>
  );
}

ApplicationList.propTypes = {
  onSearch: PropTypes.func,
  listApplication: PropTypes.object,
  quoteType: PropTypes.string,
  agentDetails: PropTypes.object,
  setValueSearch: PropTypes.func,
  deleteApplication: PropTypes.func,
  setApplicationDetails: PropTypes.func,
  updateApplicationDetails: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  quoteType: makeSelectQuoteType(),
  agentDetails: makeSelectAgentDetails(),
});

function mapDispatchToProps(dispatch) {
  return {
    getApplicationList: () => dispatch(getApplicationListAction()),
    deleteApplication: id => dispatch(deleteApplicationAction(id)),
    setApplicationDetails: value =>
      dispatch(setApplicationDetailsAction(value)),
    // changeSubStepPage: value => dispatch(changeSubStepPageAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ApplicationList);

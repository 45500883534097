/**
 *
 * AgencySelection
 *
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import reducer from './reducer';
import saga from './saga';
import messages from './messages';
import TextField from 'components/TextField';
import {
  makeSelectAuth,
  makeSelectSelectedAgency,
} from 'containers/App/selectors';
import { changeSelectedAgencyAction } from 'containers/App/actions';
import { MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles(theme => ({
  menuMain: {
    transitionDuration: '0s !important',
    minWidth: 394,
    transition: 'none !important',
    position: 'absolute',
  },
  menuItem: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}`,
      color: 'white',
    },
    fontSize: 14,
  },
}));
export function AgencySelection({
  selectedAgency,
  changeSelectedAgency,
  auth,
  ...props
}) {
  useInjectReducer({ key: 'agencySelection', reducer });
  useInjectSaga({ key: 'agencySelection', saga });
  const classes = useStyle();

  useEffectSkipFirst(() => {
    if (selectedAgency !== '0') {
      changeSelectedAgency(selectedAgency);
    }
  }, [selectedAgency]);

  const options = auth.agent.map((ag, index) => ({
    title: `${ag.agentNumber} - ${ag.agentName}`,
    value: index,
  }));
  return (
    <TextField
      select
      style={{ position: 'relative' }}
      MenuProps={{
        classes: { paper: classes.menuMain },
        variant: 'menu',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      variant="outlined"
      value={selectedAgency}
      placeholder={<FormattedMessage {...messages.selectPlaceholder} />}
      onChange={e => {
        changeSelectedAgency(e.target.value);
      }}
      {...props}
    >
      <MenuItem disabled value="0">
        <FormattedMessage {...messages.selectPlaceholder} />
      </MenuItem>
      {options.map(option => (
        <MenuItem
          className={classes.menuItem}
          key={option.value}
          value={option.value}
        >
          {option.title}
        </MenuItem>
      ))}
    </TextField>
  );
}

AgencySelection.propTypes = {
  auth: PropTypes.object,
  selectedAgency: PropTypes.string,
  changeSelectedAgency: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  selectedAgency: makeSelectSelectedAgency(),
  auth: makeSelectAuth(),
});

function mapDispatchToProps(dispatch) {
  return {
    changeSelectedAgency: value => dispatch(changeSelectedAgencyAction(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AgencySelection);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import { get } from 'lodash/object';

import { isEmpty } from 'lodash/lang';
import { forEach } from 'lodash/collection';
import { isFieldEmpty } from 'utils/form';
import messages from 'containers/ApplicationFormPage/messages';
import { convertIfImmutable } from 'helpers/lang';
import { productCodeCheck } from 'helpers/constants';

const validate = (values, props) => {
  const { ISOCode } = props;
  const errors = {};
  if (
    !values.isEmpty()
    // ISOCode !== 'QA' &&
    // ISOCode !== 'BH' // skipped the validation for B&Q products
  ) {
    const parsedApplicationDetails = convertIfImmutable(
      props?.applicationDetails,
    );
    const { productCode } = parsedApplicationDetails;
    const parsedValues = values.toJS();

    //Fix to stop  proceeding without Currency selection for bahrain and Qatar Region
    //Additional Comments
    if(ISOCode == 'QA' || ISOCode == 'BH'){
      const fundCurrency = get(parsedValues, 'payorFunds.currency');
      if (isEmpty(fundCurrency)) {
        if (isEmpty(errors.payorFunds)) {
          errors.payorFunds = {};
        }
        errors.payorFunds.currency = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      }
    }

    if (productCodeCheck(3, productCode)) {
      if (
        !parsedValues.isPolicyPaidBySalary2 &&
        !parsedValues.isPayorProceeds &&
        !parsedValues.isPayorSource
      ) {
        errors.selectSalary =
          'Select at least one option and fill up the details';

        errors._error = 'submission failed';
      }

      if (parsedValues.isPolicyPaidBySalary2) {
        const policyPaidBySalaryErrors = {};
        const policyPaidBySalary = parsedValues.policyPaidBySalary;
        const requiredFields = [
          'employerName',
          'employerAddress',
          'countryDialingCode',
          'phoneNumber',
          'annualIncome',
          'natureOfCompanyBusiness',
          'durationOfSavings',
          'durationOfEmployment',
          'jobTitle',
        ];
        forEach(requiredFields, field => {
          if (!isEmpty(policyPaidBySalary)) {
            if (
              isFieldEmpty(policyPaidBySalary[field]) ||
              policyPaidBySalary[field] === 'Select'
            ) {
              policyPaidBySalaryErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            } else if (field === 'annualIncome') {
              if (parseInt(policyPaidBySalary[field]) > 999999999999999.99) {
                policyPaidBySalaryErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maxLimit}
                    values={{ value: '999999999999999.99' }}
                  />
                );
              } else if (parseInt(policyPaidBySalary[field]) < 0) {
                policyPaidBySalaryErrors[field] = (
                  <FormattedMessage {...messages.errorAmount} />
                );
              } else if (parseInt(policyPaidBySalary[field]) === 0) {
                policyPaidBySalaryErrors[field] = (
                  <FormattedMessage {...messages.valueCannotBeZero} />
                );
              }
            } else if (field === 'employerName') {
              if (policyPaidBySalary[field]?.length > 78) {
                policyPaidBySalaryErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: '78' }}
                  />
                );
              }
            } else if (field === 'employerAddress') {
              if (policyPaidBySalary[field]?.length > 78) {
                policyPaidBySalaryErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: '78' }}
                  />
                );
              }
            } else if (field === 'jobTitle') {
              var letters = /^[A-Za-z ]*$/;
              if (!letters.test(policyPaidBySalary[field])) {
                policyPaidBySalaryErrors[field] =
                  'Field cannot have numbers & special characters';
              }
            } else if (field === 'phoneNumber') {
              var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
              if (policyPaidBySalary[field]?.length > 25) {
                policyPaidBySalaryErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: '25' }}
                  />
                );
              } else if (format.test(policyPaidBySalary[field])) {
                policyPaidBySalaryErrors[field] =
                  'Field cannot have special characters';
              }
            }
          }
        });
        if (!isEmpty(policyPaidBySalaryErrors)) {
          errors.policyPaidBySalary = policyPaidBySalaryErrors;
        }
      }
    }
    var format = /^[A-Za-z ]*$/;
    const nameOfJobTitle = parsedValues.nameOfJobTitle;
    if (isFieldEmpty(nameOfJobTitle)) {
      errors.nameOfJobTitle = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    } else if (!format.test(nameOfJobTitle)) {
      errors.nameOfJobTitle = 'Field cannot have numbers & special characters';
    }

    if (parsedValues.isPolicyPaidBySalary) {
      const payorDetailsErrors = {};
      const payorDetails = parsedValues.payorDetails;
      const requiredFields = ['paymentCurrency', 'paymentAmount'];

      forEach(requiredFields, field => {
        if (!isEmpty(payorDetails)) {
          if (
            isFieldEmpty(payorDetails[field]) ||
            payorDetails[field] === 'Select'
          ) {
            payorDetailsErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          } else if (field === 'paymentAmount') {
            if (parseInt(payorDetails[field]) > 999999999999) {
              payorDetailsErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maxLimit}
                  values={{ value: '999999999999' }}
                />
              );
            } else if (parseInt(payorDetails[field]) < 0) {
              payorDetailsErrors[field] = (
                <FormattedMessage {...messages.errorAmount} />
              );
            }
          }
        }
      });

      if (!isEmpty(payorDetailsErrors)) {
        errors.payorDetails = payorDetailsErrors;
      }
    } else {
      if (parsedValues.isPayorProceeds) {
        const payorProceedsErrors = [];
        const payorProceeds = parsedValues.payorProceeds;
        forEach(payorProceeds, (proceed, index) => {
          const proceedError = {};
          const requiredTextFields = [
            'totalSaleAmount',
            'addressProperty',
            'sharesProperty',
          ];
          const requiredSelectFields = ['saleCurrency'];
          forEach(requiredTextFields, field => {
            if (isFieldEmpty(proceed[field])) {
              proceedError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );

              payorProceedsErrors[index] = proceedError;
            } else if (field === 'totalSaleAmount') {
              parseInt(proceed[field]);
              if (parseInt(proceed[field]) > 999999999999) {
                proceedError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxLimit}
                    values={{ value: '999999999999' }}
                  />
                );

                payorProceedsErrors[index] = proceedError;
              } else if (parseInt(proceed[field]) < 0) {
                proceedError[field] = (
                  <FormattedMessage {...messages.errorAmount} />
                );

                payorProceedsErrors[index] = proceedError;
              }
            }
          });
          forEach(requiredSelectFields, field => {
            if (isFieldEmpty(proceed[field])) {
              proceedError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
              payorProceedsErrors[index] = proceedError;
            }
            if (proceed[field] === 'Select') {
              proceedError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );

              payorProceedsErrors[index] = proceedError;
            }
          });
        });

        if (payorProceedsErrors.length > 0) {
          errors.payorProceeds = payorProceedsErrors;
        }
      }

      //PAYOR FUNDS
      if (
        productCodeCheck(7, props.formValues.productCode) &&
        ISOCode !== 'QA' &&
        ISOCode !== 'BH'
      ) {
        const payorFundsErrors = [];
        const FundYears = get(parsedValues, 'payorFunds.FundYears');
        const fundCurrency = get(parsedValues, 'payorFunds.currency');

        forEach(FundYears, (FundYear, index) => {
          const fundError = {};
          const requiredTextFields = [
            'earnedSalary',
            'investmentIncome',
            'otherIncome',
          ];

          forEach(requiredTextFields, field => {
            if (isFieldEmpty(FundYear[field])) {
              fundError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );

              payorFundsErrors[index] = fundError;
            } else if (
              field === 'earnedSalary' ||
              field === 'investmentIncome' ||
              field === 'otherIncome'
            ) {
              if (parseInt(FundYear[field]) > 999999999999) {
                fundError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxLimit}
                    values={{ value: '999999999999' }}
                  />
                );

                payorFundsErrors[index] = fundError;
              } else if (parseInt(FundYear[field]) < 0) {
                fundError[field] = (
                  <FormattedMessage {...globalMessages.cannotBeLessThanZero} />
                );

                payorFundsErrors[index] = fundError;
              }
            }
          });
        });
        if (isEmpty(fundCurrency)) {
          if (isEmpty(errors.payorFunds)) {
            errors.payorFunds = {};
          }
          errors.payorFunds.currency = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        }

        if (payorFundsErrors.length > 0) {
          if (isEmpty(errors.payorFunds)) {
            errors.payorFunds = {};
          }
          errors.payorFunds.FundYears = payorFundsErrors;
        }
      }

      if (parsedValues.isPayorSource) {
        const payorSourceErrors = [];
        const { payorSource } = parsedValues;
        // let payorSourceErrors;

        forEach(payorSource, (proceed, index) => {
          const sourceError = {};
          let requiredTextFields;
          switch (proceed.originOfWealth) {
            case 'CompanySale':
              requiredTextFields = [
                'nameOfCompany',
                'natureOfCompany',
                'totalAmount',
                'sharePercentage',
              ];
              break;
            case 'MaturingInvestmentOrPolicyClaim':
              requiredTextFields = [
                'descriptionOfMaturing',
                'maturingProceeds',
              ];
              break;
            case 'Inheritance':
              requiredTextFields = ['detailsOfInheritance', 'totalAmount'];
              break;
            case 'GearedLoan':
              requiredTextFields = [
                'nameOfLoanProvider',
                'natureOfLoanProvider',
                'totalAmount',
              ];
              break;
            case 'Gift':
              requiredTextFields = [
                'reasonForGift',
                'donorsOriginOfWealth',
                'totalAmount',
              ];
              break;
            case 'LotteryBettingCasino':
              requiredTextFields = [
                'detailsOfOrganisation',
                'detailsOfWin',
                'totalAmount',
              ];
              break;
            case 'CompensationPayment':
              requiredTextFields = ['reasonForPayment', 'totalAmount'];
              break;
            case 'CorporateInvestment':
              requiredTextFields = ['natureOfBusiness', 'detailsOfTurnover'];
              break;
            case 'RetirementIncome':
              requiredTextFields = [
                'detailsOfPreviousProfession',
                'nameNAddressOfLastEmployer',
                'approxSalary',
                'pensionIncome',
              ];
              break;
            case 'OtherMonies':
              requiredTextFields = [
                'natureOfSource',
                'totalAmount',
                'recievedFrom',
              ];
              break;
            default:
              break;
          }

          let requiredSelectFields = [];
          switch (proceed.originOfWealth) {
            case 'CompanySale':
              requiredSelectFields = ['currency', 'dateOfSale'];
              break;
            case 'MaturingInvestmentOrPolicyClaim':
              requiredSelectFields = ['currency', 'dateOfMaturity'];
              break;
            case 'Inheritance':
              requiredSelectFields = ['currency', 'dateRecieved'];
              break;
            case 'GearedLoan':
              requiredSelectFields = ['currency', 'dateOfLoan'];
              break;
            case 'Gift':
              requiredSelectFields = [
                'dateRecieved',
                'currency',
                'relationshipToApplicant',
              ];
              break;
            case 'LotteryBettingCasino':
              requiredSelectFields = ['dateOfWin', 'currency'];
              break;
            case 'CompensationPayment':
              requiredSelectFields = ['dateRecieved', 'currency'];
              break;
            case 'CorporateInvestment':
              requiredSelectFields = [];
              break;
            case 'RetirementIncome':
              requiredSelectFields = ['retirementDate', 'currency'];
              break;
            case 'OtherMonies':
              requiredSelectFields = ['dateRecieved', 'currency'];
              break;
            default:
              break;
          }
          requiredSelectFields.push('originOfWealth');
          forEach(requiredTextFields, field => {
            if (isFieldEmpty(proceed[field])) {
              sourceError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );

              payorSourceErrors[index] = sourceError;
            }
            if (
              field === 'totalAmount' ||
              field === 'maturingProceeds' ||
              field === 'approxSalary'
            ) {
              parseInt(proceed[field]);
              if (parseInt(proceed[field]) > 999999999999) {
                sourceError[field] = (
                  <FormattedMessage
                    {...globalMessages.maxLimit}
                    values={{ value: '999999999999' }}
                  />
                );

                payorSourceErrors[index] = sourceError;
              } else if (parseInt(proceed[field]) < 0) {
                sourceError[field] = (
                  <FormattedMessage {...messages.errorAmount} />
                );

                payorSourceErrors[index] = sourceError;
              } else if (parseInt(proceed[field]) === 0) {
                sourceError[field] = (
                  <FormattedMessage {...messages.valueCannotBeZero} />
                );
                payorSourceErrors[index] = sourceError;
              }
            }
            if (field === 'sharePercentage') {
              if (!Number.isInteger(Number(proceed[field]))) {
                sourceError[field] = (
                  <FormattedMessage {...messages.invalidNumber} />
                );

                payorSourceErrors[index] = sourceError;
              } else if (parseInt(proceed[field]) < 0) {
                sourceError[field] = (
                  <FormattedMessage {...messages.errorAmount} />
                );

                payorSourceErrors[index] = sourceError;
              } else if (parseInt(proceed[field]) === 0) {
                sourceError[field] = (
                  <FormattedMessage {...messages.valueCannotBeZero} />
                );
                payorSourceErrors[index] = sourceError;
              } else if (parseFloat(proceed[field]) > 100) {
                sourceError[field] = (
                  <FormattedMessage {...messages.maximumPercentage100Allowed} />
                );
                payorSourceErrors[index] = sourceError;
              }
            }
            if (field === 'natureOfCompany') {
              if (proceed[field]?.length > 30) {
                sourceError[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: '30' }}
                  />
                );
                payorSourceErrors[index] = sourceError;
              }
            }
          });
          forEach(requiredSelectFields, field => {
            if (isFieldEmpty(proceed[field])) {
              sourceError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
              payorSourceErrors[index] = sourceError;
            }
            if (proceed[field] === 'Select') {
              sourceError[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );

              payorSourceErrors[index] = sourceError;
            }
          });
        });

        if (payorSourceErrors.length > 0) {
          errors.payorSource = payorSourceErrors;
        }
      }

      if (productCodeCheck(3, parsedApplicationDetails.productCode)) {
      } else if (
        !parsedValues.isPolicyPaidBySalary &&
        !parsedValues.isPayorProceeds &&
        !parsedValues.isPayorSource
      ) {
        errors.selectSalary =
          'Select at least one option and fill up the details';
        errors._error = 'submission failed';
      } else if (parsedValues.isPayorSource) {
        if (parsedValues.payorSource?.originOfWealth === 'Select') {
          errors.selectOriginOfWealth = 'Select an option';
          errors._error = 'submission failed';
        }
      }
    }
  }
  return errors;
};

export default validate;

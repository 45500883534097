import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  FormSection,
  getFormValues,
  getFormSyncErrors,
} from 'redux-form/immutable';
import { submit } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import messages from '../../../messages';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { convertIfImmutable } from 'helpers/lang';
import { isEmpty, isEqual } from 'lodash/lang';
import { filter, forEach } from 'lodash/collection';
import { map, find } from 'lodash/collection';
import { newCompanyPayor } from 'helpers/placeholder';
import {
  Grid,
  Typography,
  Collapse,
  Box,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import { withStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import EmptyIcon from 'images/checkbox-blue-empty.svg';
import CheckedIcon from 'images/checkbox-blue-tick.svg';
import { productCodeCheck } from 'helpers/constants';

//selector
import { makeSelectOption } from 'containers/App/selectors';

//components
import FormRadioGroup from 'components/FormRadioGroup';
import CardSectionForm from 'components/CardSectionForm';
import FormSwitch from 'components/FormSwitch';
import PayorDetails from './components/PayorDetails';

//validate
import validate from './validate';
import LifeInsuredPayor from './components/lifeInsuredPayor';

const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  containerWrapper: {
    width: '660px',
    marginTop: theme.spacing(4),
  },
  beneficiariesContainer: {
    marginTop: '2rem',
  },
  radioForm: {
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    color: red[800],
    fontWeight: 600,
  },
  wrapperleft: {
    paddingLeft: '2rem',
  },
}));

function Payor(props) {
  const classes = useStyle();
  const theme = useTheme();

  const {
    handleSubmit,
    initialValues,
    formValues,
    change,
    options,
    formErrors,
    applicationDetails,
    canSubmit,
    isInforce,
    disabledFields,
  } = props;
  const parsedInitialValues = convertIfImmutable(initialValues);
  const [anyLifeInsuredSelected, setAnyLifeInsuredSelected] = useState(false);
  const [lifeInsuredIndex, setLifeInsuredIndex] = useState(0);

  useEffect(() => {
    // check if any of the lifeInsuredPayors are selected
    forEach(convertIfImmutable(formValues).lifeInsuredPayor, (party, index) => {
      if (party.lifeInsuredPersonPayor) {
        setAnyLifeInsuredSelected(true);
        setLifeInsuredIndex(index);
        return false;
      }

      setAnyLifeInsuredSelected(false);
    });
  }, [convertIfImmutable(formValues).lifeInsuredPayor]);

  useEffect(() => {
    const Payor = find(parsedInitialValues.lifeInsuredParties, party => {
      return party.partyapplicationrelationships.isPayor;
    });

    if (Payor) {
      if (Payor.partyapplicationrelationships.isPayor) {
        change('selectedLifeInsuredParties', Payor.id);
        change('thirdPersonPayor', false);
        // change('lifeInsuredPersonPayor', false);
        change('isCompanyPayor', false);
      }
    } else if (parsedInitialValues.thirdPayor.id) {
      change('selectedLifeInsuredParties', '');
      change('thirdPersonPayor', true);
      change('lifeInsuredPersonPayor', false);
      change('isCompanyPayor', false);
    } else if (anyLifeInsuredSelected) {
      change('thirdPersonPayor', false);
      change('isCompanyPayor', false);
    } else if (parsedInitialValues.companyPayor.id) {
      change('lifeInsuredPersonPayor', false);
      change('selectedLifeInsuredParties', '');
      change('thirdPersonPayor', false);
      change('isCompanyPayor', true);
    }

    if (isInforce) {
      change('thirdPersonPayor', true);
    }
  }, [applicationDetails]);

  function handleChange(event) {
    if (event.target.value === formValues.selectedLifeInsuredParties) {
      change('selectedLifeInsuredParties', '');
    } else {
      change('selectedLifeInsuredParties', event.target.value);
      change('thirdPersonPayor', false);
      // change('lifeInsuredPersonPayor', false);
      change('isCompanyPayor', false);
    }
  }

  const checkSubmit = () => true;

  useEffect(() => {
    canSubmit(!checkSubmit());
  }, [formValues]);

  return (
    <Grid item xs={12} md={8} lg={6} className={classes.containerWrapper}>
      <Typography
        style={{ marginLeft: '2rem' }}
        variant="body1"
        className={classes.error}
      >
        {formErrors.selectOnePayor}
      </Typography>

      {/* PO paying */}
      {isInforce ? (
        <FormSection
          name="inforcePayor"
          fieldName="inforcePayor"
          component={PayorDetails}
          formValues={formValues}
          options={options}
          lifeInsuredParties={parsedInitialValues.lifeInsuredParties}
          change={change}
          isInforce={isInforce}
          disabledFields={disabledFields}
        />
      ) : (
        <>
          <Typography style={{ marginLeft: '2rem' }} variant="body1">
            <FormattedMessage {...messages.whoIsPaying} />
          </Typography>
          <Box ml={2.5} mt={2} style={{ marginBottom: '3rem' }}>
            <Field
              name="selectedLifeInsuredParties"
              type="radio"
              component={FormRadioGroup}
              onBlur={e => {
                e.preventDefault();
              }}
            >
              <div className={classes.radioForm}>
                {map(parsedInitialValues.lifeInsuredParties, (party, index) => {
                  return (
                    <FormControlLabel
                      key={`${party.id}-${index}`}
                      control={
                        <Checkbox
                          color="primary"
                          checked={
                            party.id === formValues.selectedLifeInsuredParties
                          }
                          disabled={
                            formValues.thirdPersonPayor ||
                            anyLifeInsuredSelected ||
                            // formValues.lifeInsuredPersonPayor ||
                            formValues.isCompanyPayor
                          }
                          onClick={handleChange}
                          icon={<img src={EmptyIcon} alt="people" />}
                          checkedIcon={<img src={CheckedIcon} alt="people" />}
                        />
                      }
                      value={party.id}
                      label={`${party.firstName} ${party.lastName}`}
                      labelPlacement="end"
                    />
                  );
                })}
              </div>
            </Field>
          </Box>
          {/* LI paying */}
          {!(productCodeCheck(8, parsedInitialValues.productCode)) &&
            <FieldArray
              name="lifeInsuredPayor"
              fieldName="lifeInsuredPayor"
              component={LifeInsuredPayor}
              lifeInsuredParties={parsedInitialValues.lifeInsuredParties}
              formValues={formValues}
              options={options}
              change={change}
              applicationDetails={parsedInitialValues}
              lifeInsuredIndex={lifeInsuredIndex}
              isInforce={isInforce}
            />
          }
          {(!productCodeCheck(8, parsedInitialValues.productCode)) &&
            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body1">
                  <FormattedMessage {...messages.thirdPersonPayor} />
                </Typography>

                <Field
                  name="thirdPersonPayor"
                  component={FormSwitch}
                  disabled={
                    !!formValues.selectedLifeInsuredParties ||
                      anyLifeInsuredSelected
                      ? true
                      : false ||
                        // formValues.lifeInsuredPersonPayor ||
                        !!formValues.isCompanyPayor
                        ? true
                        : false
                  }
                />
              </Grid>
              <Collapse
                in={
                  formValues.selectedLifeInsuredParties ||
                    anyLifeInsuredSelected ||
                    // formValues.lifeInsuredPersonPayor ||
                    formValues.isCompanyPayor
                    ? false
                    : formValues.thirdPersonPayor
                }
              >
                <hr className={classes.hr} />

                <FormSection
                  name="thirdPayor"
                  fieldName="thirdPayor"
                  component={PayorDetails}
                  formValues={formValues}
                  options={options}
                  lifeInsuredParties={parsedInitialValues.lifeInsuredParties}
                  change={change}
                  disabledFields={disabledFields}
                />
              </Collapse>
            </CardSectionForm>
          }
          {(!isInforce && !productCodeCheck(8, parsedInitialValues.productCode)) && (
            <CardSectionForm variant="outlined">
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="space-between"
              >
                <Typography variant="body1">
                  <FormattedMessage {...messages.companyPayor} />
                </Typography>

                <Field
                  name="isCompanyPayor"
                  component={FormSwitch}
                  disabled={
                    formValues.selectedLifeInsuredParties ||
                    anyLifeInsuredSelected ||
                    formValues.thirdPersonPayor
                  }
                />
              </Grid>
              <Collapse
                in={
                  formValues.selectedLifeInsuredParties ||
                    anyLifeInsuredSelected ||
                    formValues.thirdPersonPayor
                    ? false
                    : formValues.isCompanyPayor
                }
              >
                <hr className={classes.hr} />

                <FormSection
                  name="companyPayor"
                  fieldName="companyPayor"
                  component={PayorDetails}
                  formValues={formValues}
                  options={options}
                  lifeInsuredParties={parsedInitialValues.lifeInsuredParties}
                  change={change}
                  isCompany
                />
              </Collapse>
            </CardSectionForm>
          )}
        </>
      )}
    </Grid>
  );
}

Payor.propTypes = {
  handleSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  formValues: PropTypes.object,
  change: PropTypes.func,
  options: PropTypes.object,
  formErrors: PropTypes.object,
  applicationDetails: PropTypes.object,
  disabledFields: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  options: makeSelectOption(),
});

function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withForm = reduxForm({
  form: 'payor',
  onSubmit: submit,
  enableReinitialize: true,
  validate,
});

const withFormSelector = connect(state => {
  const formValues = getFormValues('payor')(state);
  const formErrors = getFormSyncErrors('payor')(state);

  return {
    formValues: !isEmpty(formValues) ? formValues.toJS() : {},
    formErrors: convertIfImmutable(formErrors),
  };
});

export default compose(withConnect, withFormSelector, withForm)(Payor);

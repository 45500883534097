import React from 'react';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';

import { isEmpty } from 'lodash/lang';
import isEmail from 'validator/lib/isEmail';
import { forEach, map, includes } from 'lodash/collection';
import { convertIfImmutable, regexNumbers } from 'helpers/lang';
import { isFieldEmpty } from 'utils/form';
import messages from 'containers/App/messages';
import messages2 from 'containers/ApplicationFormPage/messages';
import moment from 'moment';

//regex
import { regexSpecialCharacters, regexLongDashCharacters } from 'helpers/lang';
import { getStartDate } from 'containers/ApplicationFormPage/helpers';
import { isDisabledField } from 'helpers/constants';

const legalAge = getStartDate()
  .toDate()
  .setFullYear(
    getStartDate()
      .toDate()
      .getFullYear() - 18,
  );
const validate = (values, props) => {
  const errors = {};
  if (!values.isEmpty()) {
    const formNameValidate = props.isInforce ? 'inforcePayor' : 'thirdPayor';
    const thirdPayor = values.get(formNameValidate).toJS();
    const lifeInsuredPayor = values.get('lifeInsuredPayor').toJS();
    const companyPayor = values.get('companyPayor').toJS();
    const isCompanyPayor = values.get('isCompanyPayor');
    const selectedLifeInsuredParties = values.get('selectedLifeInsuredParties');
    const thirdPersonPayor = values.get('thirdPersonPayor');
    const lifeInsuredPayorParties = convertIfImmutable(
      values.get('lifeInsuredPayor'),
    );
    const disabledFields = props.disabledFields;

    if (thirdPersonPayor) {
      const thirdPayorErrors = {};
      var additionalTextFields = [];
      var additionalNotRequiredTextFields = [];
      if (
        thirdPayor.selectedAddress &&
        thirdPayor.selectedAddress == 'different'
      ) {
        additionalTextFields = [
          'addressLine1',
          'addressLine2',
          'addressLine3',
          'country',
          'city',
        ];

        additionalNotRequiredTextFields = [
          'postOfficeBoxLine',
          'postOfficeBoxCity',
        ];
      }
      const requiredTextFields = [
        'firstName',
        'lastName',
        'dateOfBirth',
        'gender',
        'relationships',
        'email',
        'primaryPhoneNumber',
        'relationships', 
        ...additionalTextFields,
        ...additionalNotRequiredTextFields,
      ];

      forEach(requiredTextFields, field => {
        if (!isDisabledField(disabledFields, 'payor', field)) {
          if (!additionalNotRequiredTextFields.includes(field)) {
            if (isFieldEmpty(thirdPayor[field])) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
            }
          }

          if (field === 'firstName') {
            if (thirdPayor[field] && thirdPayor[field].length > 45) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 45 }}
                />
              );
            } else if (!regexNumbers.test(thirdPayor[field])) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
              );
            }
          }

          if (field === 'lastName') {
            if (thirdPayor[field] && thirdPayor[field].length > 40) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 40 }}
                />
              );
            } else if (!regexNumbers.test(thirdPayor[field])) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
              );
            }
          }

          // preferredName
          if (!isEmpty(thirdPayor['preferredName'])) {
            if (thirdPayor['preferredName']?.length > 45) {
              thirdPayorErrors['preferredName'] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 45 }}
                />
              );
            } else if (!regexNumbers.test(thirdPayor['preferredName'])) {
              thirdPayorErrors['preferredName'] = (
                <FormattedMessage {...globalMessages.numbersAreNotAllowed} />
              );
            }
          }

          if (!isEmpty(thirdPayor['secondaryPhoneNumber'])) {
            if (thirdPayor['secondaryPhoneNumber'].length > 25) {
              thirdPayorErrors['secondaryPhoneNumber'] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 25 }}
                />
              );
            }
          }

          if (field === 'primaryPhoneNumber') {
            if (thirdPayor[field].length > 25) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 25 }}
                />
              );
            } else if (regexSpecialCharacters.test(thirdPayor[field])) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...messages.specialCharactersNotAllowed} />
              );
            }
          }

          if (field === 'email') {
            if (!isEmpty(thirdPayor[field])) {
              if (thirdPayor[field].length > 70) {
                thirdPayorErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 70 }}
                  />
                );
              } else if (!isEmail(thirdPayor[field])) {
                thirdPayorErrors[field] = (
                  <FormattedMessage {...globalMessages.emailInvalid} />
                );
              }
            }
          }

          if (field === 'postOfficeBoxLine') {
            if (thirdPayor[field] && thirdPayor[field].length > 15) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 15 }}
                />
              );
            }
          }

          if (field === 'postOfficeBoxCity') {
            if (thirdPayor[field] && thirdPayor[field].length > 35) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
            }
          }

          if (field === 'city') {
            if (thirdPayor[field] && thirdPayor[field].length > 35) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
            } else if (regexSpecialCharacters.test(thirdPayor[field])) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...messages.specialCharactersNotAllowed} />
              );
            } else if (!regexNumbers.test(thirdPayor[field])) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...messages.numbersAreNotAllowed} />
              );
            }
          }

          if (additionalTextFields.includes(field)) {
            if (!isEmpty(thirdPayor[field])) {
              if (thirdPayor[field] && thirdPayor[field].length > 35) {
                thirdPayorErrors[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 35 }}
                  />
                );
              }
            }

            if (field === 'country') {
              if (
                thirdPayor[field] === 'Select an option' ||
                thirdPayor[field] === ''
              ) {
                thirdPayorErrors[field] = (
                  <FormattedMessage {...messages.pleaseSelectValue} />
                );
              }
            }
          }
          
          if (field === 'relationships'){
            // && !props.isInforce) {
            //relationship 
            

            thirdPayorErrors[field] = {};

            var requiredRelationshipSelectFields = [
              'relationshipToPolicyOwner', 'reasonToPay'
            ];
            // TEXTFIELD

            const { isPayor, isPolicyOwner } = convertIfImmutable(
              props.initialValues,
            ).inforcePayor?.partyapplicationrelationships;

            //check if both not payor and policy owner
            if (isPayor && isPolicyOwner) {
            } else {
              // SELECT
              forEach(requiredRelationshipSelectFields, relationshipField => {
                if (isFieldEmpty(thirdPayor[field][relationshipField])) {
                  thirdPayorErrors[field][relationshipField] = (
                    <FormattedMessage {...globalMessages.pleaseSelectValue} />
                  );
                } else if (thirdPayor[field][relationshipField] === 'Select an option') {
                  thirdPayorErrors[field][relationshipField] = (
                    <FormattedMessage {...globalMessages.pleaseSelectValue} />
                  );
                } 
                if (thirdPayor[field]['reasonToPay'] === 'OTHERS') {
                  if (isFieldEmpty(thirdPayor[field]['other'])) {
                  thirdPayorErrors[field]['other'] = (
                    <FormattedMessage {...globalMessages.pleaseEnterValue} />);
                  };
                  if (thirdPayor[field]['other']?.length > 100) {
                    thirdPayorErrors[field]['other'] = (
                      <FormattedMessage
                        {...globalMessages.maximumLength}
                        values={{ value1: 100 }}
                      />
                    );
                  }
                }
              });
            }
          }

          if (field === 'dateOfBirth') {
            const today = new Date().setFullYear(new Date().getFullYear());
            const date = Date.parse(thirdPayor[field]);
            const parsedDate = moment(date);
            if (!parsedDate.isValid()) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...messages.invalidDate} />
              );
            } else if (date > today) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...messages.futureDates} />
              );
            } else if (legalAge < date) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...messages.legalAge} />
              );
            }
          }

          if (
            field === 'addressLine1' ||
            field === 'addressLine2' ||
            field === 'addressLine3' ||
            field === 'country' ||
            field === 'city'
          ) {
            if (thirdPayor[field]?.length > 35) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 35 }}
                />
              );
            }

            if (regexLongDashCharacters.test(thirdPayor[field])) {
              thirdPayorErrors[field] = (
                <FormattedMessage {...globalMessages.unsupportedChars} />
              );
            }
          }
        }
      });

      const requiredSelectFields = [
        'relationshipToLifeInsured',
        'primaryNationality',
        'secondaryNationality',
        'countryOfBirth',
        'primaryCountryDialingCode',
      ];

      forEach(requiredSelectFields, field => {
        if (!isDisabledField(disabledFields, 'payor', field)) {
          if (
            isFieldEmpty(thirdPayor[field]) &&
            field !== 'secondaryNationality'
          ) {
            thirdPayorErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          } else if (field === 'secondaryNationality') {
            if (
              thirdPayor[field] === thirdPayor['primaryNationality'] &&
              thirdPayor[field] !== 'Select an option'
            ) {
              thirdPayorErrors[field] = (
                <FormattedMessage
                  {...messages2.sameAsPrimaryNationalityNotAllowed}
                />
              );
            }
          } else if (
            field === 'primaryNationality' &&
            thirdPayor[field] === 'Select an option'
          ) {
            thirdPayorErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          } else if (
            field === 'countryOfBirth' &&
            !isDisabledField(disabledFields, 'payor', 'countryOfBirth') &&
            thirdPayor[field] === 'Select an option'
          ) {
            thirdPayorErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          } else if (
            field === 'primaryCountryDialingCode' &&
            thirdPayor[field] === 'Select'
          ) {
            thirdPayorErrors[field] = (
              <FormattedMessage {...globalMessages.pleaseSelectValue} />
            );
          }
          if (
            !isEmpty(thirdPayor['postOfficeBoxCity']) &&
            isEmpty(thirdPayor['postOfficeBoxLine'])
          ) {
            thirdPayorErrors['postOfficeBoxLine'] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
          if (
            isEmpty(thirdPayor['postOfficeBoxCity']) &&
            !isEmpty(thirdPayor['postOfficeBoxLine'])
          ) {
            thirdPayorErrors['postOfficeBoxCity'] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        }
      });

      if (thirdPayorErrors !== {}) {
        errors[formNameValidate] = thirdPayorErrors;
      }
    }

    //validation for life insured payors
    if (lifeInsuredPayorParties.length > 0) {
      const lifeInsuredPayorPartiesErrors = [];
      forEach(lifeInsuredPayorParties, (party, index) => {
        const partyError = {};
        // if party is selected
        if (party.lifeInsuredPersonPayor) {
          var additionalTextFields = [];
          var additionalNotRequiredTextFields = [];
          if (party.selectedAddress && party.selectedAddress == 'different') {
            additionalTextFields = [
              'addressLine1',
              'addressLine2',
              'addressLine3',
              'country',
              'city',
            ];

            additionalNotRequiredTextFields = [
              'postOfficeBoxLine',
              'postOfficeBoxCity',
              'secondaryPhoneNumber',
              'secondaryCountryDialingCode',
            ];
          }
          const requiredFields = [
            'email',
            'countryOfBirth',
            'primaryPhoneNumber',
            'relationships',
            ...additionalTextFields,
            ...additionalNotRequiredTextFields,
          ];

          const requiredSelectFields = [
            'countryOfBirth',
            'primaryCountryDialingCode',
            'primaryNationality',
            'isPep',
          ];

          const nonRequiredSelectFields = ['secondaryNationality'];
          // TEXTFIELD
          forEach(requiredFields, field => {
            if (!additionalNotRequiredTextFields.includes(field)) {
              if (isFieldEmpty(party[field])) {
                partyError[field] = (
                  <FormattedMessage {...globalMessages.pleaseEnterValue} />
                );
                lifeInsuredPayorPartiesErrors[index] = partyError;
              }

              if (field === 'email') {
                if (!isFieldEmpty(party[field])) {
                  if (!isEmail(party[field])) {
                    partyError[field] = (
                      <FormattedMessage {...globalMessages.emailInvalid} />
                    );
                    lifeInsuredPayorPartiesErrors[index] = partyError;
                  }
                }
              }

              if (
                field === 'addressLine1' ||
                field === 'addressLine2' ||
                field === 'addressLine3' ||
                field === 'country' ||
                field === 'city'
              ) {
                if (party[field]?.length > 35) {
                  partyError[field] = (
                    <FormattedMessage
                      {...globalMessages.maximumLength}
                      values={{ value1: 35 }}
                    />
                  );
                  lifeInsuredPayorPartiesErrors[index] = partyError;
                }

                if (regexLongDashCharacters.test(party[field])) {
                  partyError[field] = (
                    <FormattedMessage {...globalMessages.unsupportedChars} />
                  );
                  lifeInsuredPayorPartiesErrors[index] = partyError;
                }
              }
            }

            if (field === 'postOfficeBoxLine') {
              if (party[field] && party[field].length > 15) {
                partyError[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 15 }}
                  />
                );
                lifeInsuredPayorPartiesErrors[index] = partyError;
              }
            }

            if (field === 'postOfficeBoxCity') {
              if (party[field] && party[field].length > 35) {
                partyError[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 35 }}
                  />
                );
                lifeInsuredPayorPartiesErrors[index] = partyError;
              }
            }

            if (
              field === 'primaryPhoneNumber' ||
              field === 'secondaryPhoneNumber'
            ) {
              if (party[field]?.length > 25) {
                partyError[field] = (
                  <FormattedMessage
                    {...globalMessages.maximumLength}
                    values={{ value1: 25 }}
                  />
                );
                lifeInsuredPayorPartiesErrors[index] = partyError;
              } else if (regexSpecialCharacters.test(party[field])) {
                partyError[field] = (
                  <FormattedMessage {...messages.specialCharactersNotAllowed} />
                );
                lifeInsuredPayorPartiesErrors[index] = partyError;
              }
            }
            if (field === 'relationships') {
              //relationship
              partyError[field] = {};

              var requiredRelationShipTextfields = ['reasonToPay','other'];
              var requiredRelationshipSelectFields = [
                'relationshipToPolicyOwner'
              ];
              forEach(requiredRelationShipTextfields, relationshipField => {
                if (
                  !isDisabledField(disabledFields, 'payor', relationshipField)
                ) {
                  if (isFieldEmpty(party[field][relationshipField])) {
                    partyError[field][relationshipField] = (
                      <FormattedMessage {...globalMessages.pleaseEnterValue} />
                    );

                    lifeInsuredPayorPartiesErrors[index] = partyError;
                  }
                }
              });

              forEach(requiredRelationshipSelectFields, relationshipField => {
                if (
                  !isDisabledField(disabledFields, 'payor', relationshipField)
                ) {
                  if (isFieldEmpty(party[field][relationshipField])) {
                    partyError[field][relationshipField] = (
                      <FormattedMessage {...globalMessages.pleaseSelectValue} />
                    );

                    lifeInsuredPayorPartiesErrors[index] = partyError;
                  }
                }
              });
            }
          });
          // SELECT
          forEach(requiredSelectFields, field => {
            if (isFieldEmpty(party[field])) {
              partyError[field] = (
                <FormattedMessage {...globalMessages.pleaseSelectValue} />
              );
              lifeInsuredPayorPartiesErrors[index] = partyError;
            }
            if (
              !isEmpty(party['postOfficeBoxCity']) &&
              isEmpty(party['postOfficeBoxLine'])
            ) {
              partyError['postOfficeBoxLine'] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
              lifeInsuredPayorPartiesErrors[index] = partyError;
            }
            if (
              isEmpty(party['postOfficeBoxCity']) &&
              !isEmpty(party['postOfficeBoxLine'])
            ) {
              partyError['postOfficeBoxCity'] = (
                <FormattedMessage {...globalMessages.pleaseEnterValue} />
              );
              lifeInsuredPayorPartiesErrors[index] = partyError;
            }
          });

          forEach(nonRequiredSelectFields, field => {
            if (field === 'secondaryNationality') {
              if (party[field] === party['primaryNationality']) {
                partyError[field] = (
                  <FormattedMessage
                    {...messages2.sameAsPrimaryNationalityNotAllowed}
                  />
                );
                lifeInsuredPayorPartiesErrors[index] = partyError;
              }
            }
          });
        }
      });

      if (lifeInsuredPayorPartiesErrors?.length > 0) {
        errors.lifeInsuredPayor = lifeInsuredPayorPartiesErrors;
      }
    }

    if (isCompanyPayor) {
      const companyPayorErrors = {};
      const requiredTextFields = [
        'lastName',
        'addressLine1',
        'addressLine2',
        'addressLine3',
        'country',
        'city',
        'dateOfBirth',
        'relationships',
      ];

      forEach(requiredTextFields, field => {
        if (isFieldEmpty(companyPayor[field])) {
          companyPayorErrors[field] = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        }

        if (field === 'relationships') {
          companyPayorErrors[field] = {};
          if (isFieldEmpty(companyPayor?.relationships?.reasonToPay)||
          companyPayor?.relationships?.reasonToPay ===
            'Select an option'
        ) {
            companyPayorErrors[field]['reasonToPay'] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          } else if (companyPayor?.relationships?.reasonToPay === 'OTHERS'){
            if(isFieldEmpty(companyPayor?.relationships?.other)) {
             companyPayorErrors[field]['other'] = (<FormattedMessage {...globalMessages.pleaseEnterValue} />);
            }
            if (companyPayor?.relationships?.other?.length > 100) {
              companyPayorErrors[field]['other'] = (
                <FormattedMessage
                  {...globalMessages.maximumLength}
                  values={{ value1: 100 }}
                />
              );
            }
          }

          if (
            isFieldEmpty(
              companyPayor?.relationships?.relationshipToPolicyOwner,
            ) ||
            companyPayor?.relationships?.relationshipToPolicyOwner ===
              'Select an option'
          ) {
            companyPayorErrors[field] = {};
            companyPayorErrors[field]['relationshipToPolicyOwner'] = (
              <FormattedMessage {...globalMessages.pleaseEnterValue} />
            );
          }
        }
        if (field === 'country') {
          if (
            companyPayor[field] === 'Select an option' ||
            companyPayor[field] === ''
          ) {
            companyPayorErrors[field] = (
              <FormattedMessage {...messages.pleaseSelectValue} />
            );
          }
        }

        if (
          field === 'lastName' ||
          field === 'addressLine1' ||
          field === 'addressLine2' ||
          field === 'addressLine3' ||
          field === 'city'
        ) {
          if (companyPayor[field]?.length > 35) {
            companyPayorErrors[field] = (
              <FormattedMessage
                {...globalMessages.maximumLength}
                values={{ value1: 35 }}
              />
            );
          }

          if (regexLongDashCharacters.test(companyPayor[field])) {
            companyPayorErrors[field] = (
              <FormattedMessage {...globalMessages.unsupportedChars} />
            );
          }
        }
      });

      if (companyPayorErrors !== {}) {
        errors.companyPayor = companyPayorErrors;
      }
    }

    // check if any of the lifeInsuredPayor is checked

    let anyLifeInsuredSelected = false;

    forEach(convertIfImmutable(lifeInsuredPayor), party => {
      if (party.lifeInsuredPersonPayor) {
        anyLifeInsuredSelected = true;
        return false;
      }
    });

    if (
      !selectedLifeInsuredParties &&
      !thirdPersonPayor &&
      !anyLifeInsuredSelected &&
      !isCompanyPayor
    ) {
      errors.selectOnePayor = (
        <FormattedMessage {...messages2.atleastOnePayor} />
      );

      errors._error = 'submission failed';
    }
  }
  return errors;
};

export default validate;
/**
 *
 * Sidebar
 *
 */

import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Slide,
  useMediaQuery,
  Typography,
  Button,
  Link,
} from '@material-ui/core';
import { HelpOutline as HelpIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ZLogo from 'images/logo-white.png';
import ZLogoOnly from 'images/z-logo-only.png';
import IconLogout from 'images/icon_logout.svg';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import Home from 'icons/Home';
import Illustrations from 'icons/Illustrations';
import Applications from 'icons/Applications';
import CustomDialog from 'components/Dialog';
import { useOktaAuth } from '@okta/okta-react';
import { FormattedMessage } from 'react-intl';
import pdfLink from 'static/pdf/ZurichPro-TermsOfUse-September-2020.pdf';
import globalMessages from 'containers/App/messages';
import MiniDrawer from './MiniDrawer';
import messages from '../messages';

const drawerWidth = 190;

const TriangleShapeView = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid white;
  border-bottom: 10px solid transparent;
`;

const useStyles = makeStyles(theme => ({
  divider: {
    backgroundColor: 'white',
  },
  drawer: {
    width: 40,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  subDrawer: {
    width: 40,
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
    },
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    paddingBottom: 70,
  },
  listItemSelected: {
    backgroundColor: theme.palette.primary.dark,
  },
  listItemHeader: {
    padding: '2rem 0',
    textAlign: 'center',
    display: 'block',
    backgroundColor: theme.palette.primary.dark,
  },
  listItem: {
    height: 90,
    padding: '2rem 0',
    paddingLeft: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.common.white,
    '& .MuiListItemIcon-root': {
      minWidth: 35,
    },
  },
  listItemIcon: {
    color: theme.palette.common.white,
    fontSize: theme.typography.h3.fontSize,
    [theme.breakpoints.up('md')]: {
      fontSize: theme.typography.h1.fontSize,
    },
  },
  listItemIconSmall: {
    color: theme.palette.common.white,
    minWidth: 0,
    fontSize: theme.typography.h3.fontSize,
  },
  underlined: {
    '& .MuiDivider-root': {
      backgroundColor: '#043594!important',
    },
  },
  backgroundSubMenu: {
    height: '100%',
    borderTop: '2px solid #ffffff',
  },
  menuIcon: {
    width: 38,
  },
  termOfUse: {
    backgroundColor: '#043594',
    paddingBottom: theme.spacing(3),
    borderBottom: '1px dotted white',
  },
  backgroundLogout: {
    backgroundColor: theme.palette.primary.dark,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    cursor: 'pointer',
    textAlign: 'center',
  },
}));

function Sidebar({
  subPage,
  subPageList,
  subPageStepper,
  logout,
  clearIllustration,
  isOpsLogin
}) {
  const theme = useTheme();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();
  const [openLogout, setOpenLogout] = useState(false);
  const { oktaAuth } = useOktaAuth();

  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  let menuItems;
  if(isOpsLogin){
    menuItems = [{
        title: 'Dashboard',
        icon: <Home />,
        url: '/dashboard',
      }]
  }else{
     menuItems = [
      {
        title: 'Dashboard',
        icon: <Home />,
        url: '/dashboard',
      },
      {
        title: 'Illustrations',
        icon: <Illustrations />,
        url: '/illustrations',
      },
      {
        title: 'Applications',
        icon: <Applications />,
        url: '/applications',
      },
    ];
  }
  

  const handleOnClickList = (url, index) => {
    clearIllustration();
    if (id !== url) {
      history.push(`${url}`);
    }
  };

  const openSteps = !!subPageList.length;

  const isActiveMenu = baseUrl => {
    const pattern = new RegExp(`(\\${baseUrl})`);
    if (pattern.test(location.pathname)) {
      return true;
    }
    return false;
  };

  const handleOpenLogout = () => {
    setOpenLogout(true);
  };

  const handleLogout = () => {
    logout(oktaAuth);
  };

  return (
    <Drawer
      variant="permanent"
      className={subPageList.length > 0 ? classes.subDrawer : classes.drawer}
      classes={{
        paperAnchorDockedLeft:
          subPageList.length > 0 ? classes.subDrawer : classes.drawer,
      }}
    >
      <List disablePadding>
        <ListItem
          disableGutters
          button
          className={classes.listItemHeader}
          onClick={() => {
            history.push(`/dashboard`);
          }}
        >
          <img src={isMdUp ? ZLogo : ZLogoOnly} alt="zurich-logo" height={24} />
        </ListItem>
        <Divider className={classes.divider} />
      </List>
      <Grid container wrap="nowrap" className={classes.backgroundSubMenu}>
        <Grid
          item
          xs={openSteps && isMdUp ? false : 12}
          className={classes.menuIcon}
        >
          <List disablePadding>
            {menuItems.map((menu, index) => (
              <div key={menu.title} className={classes.underlined}>
                <ListItem
                  button
                  className={clsx(
                    classes.listItem,
                    isActiveMenu(menu.url) && classes.listItemSelected,
                  )}
                  onClick={() => {
                    handleOnClickList(menu.url, index);
                  }}
                >
                  <ListItemIcon
                    className={
                      openSteps
                        ? classes.listItemIconSmall
                        : classes.listItemIcon
                    }
                  >
                    {menu.icon}
                  </ListItemIcon>
                  {!openSteps && (
                    <ListItemText
                      color="inherit"
                      primary={<span>{menu.title}</span>}
                      primaryTypographyProps={{
                        variant: 'body2',
                        style: {
                          fontWeight: 700,
                        },
                      }}
                    />
                  )}
                  <Slide
                    direction="left"
                    in={isActiveMenu(menu.url) && !openSteps}
                    mountOnEnter
                    unmountOnExit
                  >
                    <TriangleShapeView />
                  </Slide>
                </ListItem>
                <Divider />
              </div>
            ))}
          </List>
        </Grid>
        {openSteps && isMdUp && (
          <Grid item xs>
            <MiniDrawer
              subPage={subPage}
              subPageList={subPageList}
              subPageStepper={subPageStepper}
            />
          </Grid>
        )}
      </Grid>
      {openSteps && isMdUp ? (
        <Grid container wrap="nowrap">
          <Grid
            item
            xs={openSteps && isMdUp ? false : 12}
            className={classes.menuIcon}
          ></Grid>
          <Grid item xs className={classes.termOfUse}>
            <Link
              target="_blank"
              href={pdfLink}
              underline="always"
              color="inherit"
              style={{
                marginBottom: theme.spacing(2),
              }}
            >
              <Typography
                variant="caption"
                component="span"
                display="block"
                align="center"
              >
                {isMdUp ? (
                  <FormattedMessage {...messages.termsOfUse} />
                ) : (
                  <HelpIcon color="inherit" />
                )}
              </Typography>
            </Link>
            {isMdUp ? (
              <Typography
                align="center"
                variant="caption"
                component="span"
                display="block"
                style={{ paddingTop: theme.spacing(2) }}
              >
                <FormattedMessage
                  {...globalMessages.releaseVersion}
                  values={{
                    version: process.env.VERSION,
                  }}
                />
              </Typography>
            ) : null}
          </Grid>
        </Grid>
      ) : (
        <>
          <Link
            target="_blank"
            href={pdfLink}
            underline="always"
            color="inherit"
            style={{
              marginBottom: theme.spacing(2),
            }}
          >
            <Typography
              variant="caption"
              component="span"
              display="block"
              align="center"
              color="inherit"
            >
              {isMdUp ? (
                <FormattedMessage {...messages.termsOfUse} />
              ) : (
                <HelpIcon color="inherit" />
              )}
            </Typography>
          </Link>

          {isMdUp ? (
            <Typography
              align="center"
              variant="caption"
              component="span"
              style={{ paddingBottom: theme.spacing(1) }}
            >
              <FormattedMessage
                {...globalMessages.releaseVersion}
                values={{
                  version: process.env.VERSION,
                }}
              />
            </Typography>
          ) : null}
        </>
      )}
      <div
        className={classes.backgroundLogout}
        onClick={handleOpenLogout}
        role="button"
      >
        <Typography
          color="inherit"
          variant="body2"
          component="span"
          display="block"
          align="center"
          style={{
            fontWeight: 'bold',
          }}
        >
          {isMdUp ? (
            <FormattedMessage {...messages.logOut} />
          ) : (
            <img src={IconLogout} alt="" />
          )}
        </Typography>
      </div>
      <CustomDialog
        open={openLogout}
        onClose={() => setOpenLogout(false)}
        title="Logout?"
        actions={
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              margin="normal"
              style={{
                marginRight: 30,
              }}
              onClick={() => setOpenLogout(false)}
            >
              <FormattedMessage {...messages.canCel} />
            </Button>
            <Button
              variant="contained"
              color="primary"
              margin="normal"
              onClick={handleLogout}
            >
              <FormattedMessage {...messages.logOut} />
            </Button>
          </div>
        }
      >
        <Typography variant="body1" style={{ fontWeight: 800 }}>
          <FormattedMessage {...messages.wantToLogOut} />
        </Typography>
      </CustomDialog>
    </Drawer>
  );
}

Sidebar.propTypes = {
  // openSteps: PropTypes.bool,
  subPage: PropTypes.number,
  subPageList: PropTypes.array,
  subPageStepper: PropTypes.number,
  logout: PropTypes.func,
  clearIllustration: PropTypes.func,
};

export default Sidebar;

import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the illustrationPage state domain
 */

const selectIllustrationPageDomain = state =>
  state.get('illustrationPage', initialState);

/**
 * Other specific selectors
 */

const makeSelectProductConfig = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('productConfig').toJS(),
  );

const makeSelectProductConfig2 = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('productConfig2').toJS(),
  );

const makeSelectError = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('error').toJS(),
  );

const makeSelectProductId = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('productId'),
  );

const makeSelectIllustrations = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('illustrations').toJS(),
  );

const makeSelectCustomers = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('customers').toJS(),
  );

const makeSelectQuoteDetail = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('quotes').toJS(),
  );

const makeSelectCustomerDetail = () =>
  createSelector(selectIllustrationPageDomain, substate =>
    substate.get('customerDetail').toJS(),
  );

export {
  selectIllustrationPageDomain,
  makeSelectProductConfig2,
  makeSelectProductConfig,
  makeSelectIllustrations,
  makeSelectQuoteDetail,
  makeSelectProductId,
  makeSelectError,
  makeSelectCustomers,
  makeSelectCustomerDetail,
};

/**
 *
 * FormTextField
 *
 */

import React, { useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import //CheckBoxOutlined as CheckedIcon,
//CheckBoxOutlineBlankOutlined as EmptyIcon,
'@material-ui/icons';
import styled from 'styled-components';
import {
  FormControlLabel as BaseFormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import EmptyIcon from 'images/checkbox-blue-empty.svg';
import CheckedIcon from 'images/checkbox-blue-tick.svg';
import DisabledCheckedIcon from 'images/checkbox-grey-tick.svg';

const FormControlLabel = styled(props => {
  const { fullWidth, ...baseProps } = props;
  return (
    <BaseFormControlLabel
      {...baseProps}
      classes={{
        root: 'formControlLabel-root',
        label: 'formControlLabel-label',
      }}
    />
  );
})`
  &.MuiFormControlLabel-labelPlacementStart {
    margin-left: 0;
    margin-right: 0;
  }

  &.formControlLabel-root {
    ${props => (props.fullWidth ? 'width: 100%;' : null)}
    align-items: flex-start;

    .MuiCheckbox-root {
      ${props => (props.labelPlacement === 'start' ? 'margin-left: 8px' : null)}
    }

    .formControlLabel-label {
      font-size: 1.6rem;
    }
  }
`;

const FormCheck = ({
  label,
  input,
  meta: { error, touched, warning, invalid },
  ...custom
}) => {
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }
  return (
    <FormControl component="fieldset" error={!!warning || (touched && !!error)}>
      <FormControlLabel
        labelPlacement={custom.direction || 'end'}
        control={
          <Checkbox
            checked={input.value !== '' ? input.value : false}
            color="primary"
            icon={<img src={EmptyIcon} alt="people" />}
            checkedIcon={
              <img
                src={custom.disabled ? DisabledCheckedIcon : CheckedIcon}
                alt="people"
              />
            }
          />
        }
        label={label}
        {...input}
        {...custom}
      />
      {helperMsg && <FormHelperText error>{helperMsg}</FormHelperText>}
    </FormControl>
  );
};

FormCheck.propTypes = {
  ...WrappedFieldProps,
};

export default FormCheck;

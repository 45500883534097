/*
 * App Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  SET_APP_LOADING_ACTION,
  SET_AUTH_ACTION,
  RESET_APP_DATA_ACTION,
  LOGOUT_ACTION,
  LOGOUT_SUCCESS_ACTION,
  SET_ERROR_ACTION,
  CLEAR_ERROR_ACTION,
  GET_RESOURCES_ACTION,
  SET_RESOURCES_ACTION,
  CHANGE_SELECTED_AGENCY_ACTION,
  FETCH_AGENT_DETAILS_ACTION,
  SET_AGENT_DETAILS_ACTION,
  GET_PERSISTED_AUTH_ACTION,
  GET_PERSISTED_AUTH_SUCCESS_ACTION,
  SET_SELECTED_AGENCY_ACTION,
  CREATE_FILE,
  SET_FILE,
  SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION,
  SET_CSRF_ACTION,
  GET_CSRF_ACTION,
  VALIDATE_OFFER_CODE_ACTION,
  VALIDATE_OFFER_CODE_SUCCESS_ACTION,
  RESET_VALIDATE_OFFER_CODE,
  SET_OFFER_CODE_ERROR_ACTION,
  SET_OFFER_CODE_VALIDATING_ACTION,
  SET_QUOTE_TYPE,
} from './constants';

export function setAppLoadingAction(loading, message = {}) {
  return {
    type: SET_APP_LOADING_ACTION,
    payload: {
      loading,
      message,
    },
  };
}

export function setAuthAction(data) {
  return {
    type: SET_AUTH_ACTION,
    payload: data,
  };
}

export function resetAppDataAction() {
  return {
    type: RESET_APP_DATA_ACTION,
  };
}

export function setErrorAction({
  message = null,
  values = null,
  isLogout = false,
}) {
  return {
    type: SET_ERROR_ACTION,
    payload: {
      message,
      values,
      isLogout,
    },
  };
}

export function clearErrorAction() {
  return {
    type: CLEAR_ERROR_ACTION,
  };
}

export function logoutAction(
  error = {
    message: null,
    values: null,
  },
) {
  return {
    type: LOGOUT_ACTION,
    payload: {
      error,
    },
  };
}

export function logoutSuccessAction(
  error = {
    message: null,
    values: null,
  },
) {
  return {
    type: LOGOUT_SUCCESS_ACTION,
    payload: {
      error,
    },
  };
}

export function getOptionResourcesAction() {
  return {
    type: GET_RESOURCES_ACTION,
  };
}

export function setResourceOptionsAction(payload) {
  return {
    type: SET_RESOURCES_ACTION,
    payload,
  };
}

export function changeSelectedAgencyAction(value) {
  return {
    type: CHANGE_SELECTED_AGENCY_ACTION,
    payload: value,
  };
}

export function setSelectedAgencyAction(value) {
  return {
    type: SET_SELECTED_AGENCY_ACTION,
    payload: value,
  };
}

export function fetchAgentDetailsAction() {
  return {
    type: FETCH_AGENT_DETAILS_ACTION,
  };
}

export function setAgentDetailsAction(details) {
  return {
    type: SET_AGENT_DETAILS_ACTION,
    payload: details,
  };
}

export function getPersistedAuthAction() {
  return {
    type: GET_PERSISTED_AUTH_ACTION,
  };
}

export function getPersistedAuthSuccessAction() {
  return {
    type: GET_PERSISTED_AUTH_SUCCESS_ACTION,
  };
}

export function createFileAction(payload) {
  return {
    type: CREATE_FILE,
    payload,
  };
}

export function setFileAction(payload) {
  return {
    type: SET_FILE,
    payload,
  };
}

export function setFinancialInstituteOptionAction(payload) {
  return { type: SET_FINANCIAL_INSTITUTE_OPTIONS_ACTION, payload };
}

export function getCsrfAction() {
  return {
    type: GET_CSRF_ACTION,
  };
}

export function setCsrfAction(payload) {
  return {
    type: SET_CSRF_ACTION,
    payload,
  };
}

export function validateOfferCodeAction(payload) {
  return {
    type: VALIDATE_OFFER_CODE_ACTION,
    payload,
  };
}

export function validateOfferCodeSuccessAction(payload) {
  return {
    type: VALIDATE_OFFER_CODE_SUCCESS_ACTION,
    payload,
  };
}

export function setOfferCodeErrorAction(payload) {
  return {
    type: SET_OFFER_CODE_ERROR_ACTION,
    payload,
  };
}

export function resetOfferCodeAction() {
  return {
    type: RESET_VALIDATE_OFFER_CODE,
  };
}

export function setOfferCodeValidatingAction(payload) {
  return {
    type: SET_OFFER_CODE_VALIDATING_ACTION,
    payload,
  };
}

export function setQuoteTypeAction(payload) {
  return {
    type: SET_QUOTE_TYPE,
    payload,
  };
}

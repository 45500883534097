import { take, call, put, select, all, takeLatest } from 'redux-saga/effects';
import { api } from 'environments';
import request from 'utils/request';

import {
  GET_LIST_APPLICATION_PARTY_ACTION,
  GET_LIST_ILLUSTRATION_ACTION,
  GET_LIST_IN_FORCE_POLICY_ACTION,
} from './constants';
import { makeSelectAuth } from 'containers/App/selectors';
import {
  setListApplicationPartyAction,
  setListIllustrationAction,
  setListPolicyAction,
} from './actions';
import { setAppLoadingAction } from 'containers/App/actions';

export function* getApplicationListParty(payload) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/application/get-applications-with-parties?searchText=${payload.payload.valueSearch}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    yield put(setAppLoadingAction(true));
    yield put(setListApplicationPartyAction([]));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setListApplicationPartyAction(response.data));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getIllustrations(payload) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/agency/${payload.payload.agencyNumber}/illustrations?searchText=${payload.payload.valueSearch}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    yield put(setAppLoadingAction(true));
    yield put(setListPolicyAction([]));
    yield put(setListIllustrationAction([]));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setListIllustrationAction(response.data));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

export function* getInForcePolicy(payload) {
  const auth = yield select(makeSelectAuth());
  const endpoint = `${api.host}/api/v${api.version}/b2b/inforce/get-policies?pageSize=1000&pageNumber=1&searchText=${payload.payload.valueSearch}`;
  const requestOpt = {
    method: 'GET',
    headers: {
      'Ocp-Apim-Subscription-Key': api.subKey,
      'Content-Type': 'application/json',
      Authorization: `Bearer ${auth.okta.accessToken}`,
    },
  };

  try {
    yield put(setAppLoadingAction(true));
    yield put(setListPolicyAction([]));
    yield put(setListIllustrationAction([]));
    const response = yield call(request, endpoint, requestOpt);
    yield put(setListPolicyAction(response.data));
    yield put(setAppLoadingAction(false));
  } catch (err) {
    yield put(setAppLoadingAction(false));
  }
}

// Individual exports for testing
export default function* hubPageSaga() {
  // See example in containers/HomePage/saga.js
  yield all([
    takeLatest(GET_LIST_APPLICATION_PARTY_ACTION, getApplicationListParty),
    takeLatest(GET_LIST_ILLUSTRATION_ACTION, getIllustrations),
    takeLatest(GET_LIST_IN_FORCE_POLICY_ACTION, getInForcePolicy),
  ]);
}

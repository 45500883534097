import React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

import globalMessages from 'containers/App/messages';

import { getMinValue, getMaxValue } from 'containers/GcIllustrationPage/helper';
import { isAlphaNumeric } from 'helpers/lang';
import isEmpty from 'lodash/isEmpty';

const validate = (values, props) => {
  const errors = {};
  const gcConfig = props.gcConfig;
  const lifeAssured = props.lifeAssured;
  const MAX_GROWTH_RATE = gcConfig?.agencyNumber === '8910138' && gcConfig?.productCode === "SAVP3" ? 7 : gcConfig?.features?.growthRate?.maxRate;
  const MIN_GROWTH_RATE = gcConfig?.agencyNumber === '8910138' && gcConfig?.productCode === "SAVP3" ? 7 : gcConfig?.features?.growthRate?.minRate;
  const MAX_TARGET_FUND = 999999999;
  const MAX_TARGE_YEAR = 2100;
  if (!values.isEmpty()) {
    const startDateValues = values.get('startDate');
    const currencyValues = values.get('currency');
    const termValues = values.get('term');
    const paymentFrequencyValues = values.get('paymentFrequency');
    const growthRateValues = values.get('growthRate');
    const premiumValues = values.get('premium');
    const targetFunctionalityValues = values.get('targetFunctionality');
    const hasToCalculate = values.get('hasToCalculate');
    const targetYearValues = values.get('targetYear');
    const targetFundValues = values.get('targetFund');
    const isEducationFeeWithdrawalValues = values.get(
      'isEducationalFeeWithdrawal',
    );
    const isRegularWithdrawalValues = values.get('isRegularWithdrawal');
    const regularWithdrawalValues = values.get('regularWithdrawal');
    const educationFeeWithdrawalValues = values
      .get('educationFeesWithdrawal')
      .get('child1');

    if (!startDateValues || !moment(startDateValues).isValid())
      errors.startDate = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!currencyValues || currencyValues === 'Select')
      errors.currency = (
        <FormattedMessage {...globalMessages.pleaseSelectValue} />
      );

    if (!termValues || termValues === 'Select')
      errors.term = <FormattedMessage {...globalMessages.pleaseSelectValue} />;

    if (
      (!paymentFrequencyValues && termValues != 'SinglePremium') ||
      paymentFrequencyValues === 'Select'
    )
      errors.paymentFrequency = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );

    if (targetFunctionalityValues) {
      if (!targetYearValues || targetYearValues === 'Invalid date')
        errors.targetYear = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (
        targetYearValues &&
        targetYearValues < new Date().getFullYear() + 5
      )
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: new Date().getFullYear() + 5 }}
          />
        );
      else if (targetYearValues && targetYearValues > MAX_TARGE_YEAR)
        errors.targetYear = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: MAX_TARGE_YEAR }}
          />
        );

      if (!targetFundValues)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      else if (targetFundValues && targetFundValues <= 0)
        errors.targetFund = (
          <FormattedMessage {...globalMessages.greaterThanZero} />
        );
      else if (targetFundValues > MAX_TARGET_FUND) {
        errors.targetFund = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{
              value: MAX_TARGET_FUND.toString().replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ',',
              ),
            }}
          />
        );
      }
    }

    if (hasToCalculate) {
      errors.hasToCalculate = (
        <FormattedMessage {...globalMessages.hasToCalculate} />
      );
    }
    if (!Number(premiumValues)) {
      if (!targetFunctionalityValues)
        errors.premium = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
    } else {
      var minVal = 0;
      var maxVal = 0;
      if (termValues === 'SinglePremium') {
        minVal = getMinValue('Single', gcConfig.features, currencyValues);
        maxVal = getMaxValue('Single', gcConfig.features, currencyValues);
      } else {
        minVal = getMinValue(
          paymentFrequencyValues,
          gcConfig.features,
          currencyValues,
        );
        maxVal = getMaxValue(
          paymentFrequencyValues,
          gcConfig.features,
          currencyValues,
        );
      }

      if (premiumValues < minVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.minLimit}
            values={{ value: minVal }}
          />
        );

      if (premiumValues > maxVal)
        errors.premium = (
          <FormattedMessage
            {...globalMessages.maxLimit}
            values={{ value: maxVal }}
          />
        );
    }

    if (!growthRateValues)
      errors.growthRate = (
        <FormattedMessage {...globalMessages.pleaseEnterValue} />
      );
    else {
      //For SCB Bahrain case Min and Max growth rate is same.
      if(MIN_GROWTH_RATE == MAX_GROWTH_RATE) {
        if(growthRateValues != MAX_GROWTH_RATE) {
          errors.growthRate = (
            <FormattedMessage {...globalMessages.minMaxSame} 
            values={{ value: MAX_GROWTH_RATE }}/>
          );
        }
      } else {
        if (growthRateValues <= MIN_GROWTH_RATE)
          errors.growthRate = (
            <FormattedMessage {...globalMessages.minLimitGrowthRate} />
          );
        if (growthRateValues > MAX_GROWTH_RATE)
          errors.growthRate = (
            <FormattedMessage
              {...globalMessages.maxLimit}
              values={{ value: MAX_GROWTH_RATE }}
            />
        );
      }
    }

    //Regular withdrawal validation
    if (isRegularWithdrawalValues) {
      var errorRegularWithdrawal = {};
      if (
        !regularWithdrawalValues.get('frequency') ||
        regularWithdrawalValues.get('frequency') === 'Select'
      )
        errorRegularWithdrawal.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );

      if (
        !regularWithdrawalValues.get('startDate') ||
        !moment(regularWithdrawalValues.get('startDate')).isValid()
      )
        errorRegularWithdrawal.startDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var minStartDate = moment(startDateValues)
          .date(15)
          .add(5, 'years')
          .toDate();

        if (moment(regularStartDateValues, 'YYYY-MM-DD').format('DD') != 15)
          errorRegularWithdrawal.startDate = (
            <FormattedMessage {...globalMessages.dateValidation2} />
          );
        else {
          if (regularStartDateValues.isBefore(minStartDate))
            errorRegularWithdrawal.startDate = (
              <FormattedMessage
                {...globalMessages.dateValidation}
                values={{ value: moment(minStartDate).format('DD/MM/YYYY') }}
              />
            );
        }
      }

      if (
        !regularWithdrawalValues.get('endDate') ||
        !moment(regularWithdrawalValues.get('endDate')).isValid()
      )
        errorRegularWithdrawal.endDate = (
          <FormattedMessage {...globalMessages.enterValidValue} />
        );
      else {
        var regularEndDateValue = moment(
          regularWithdrawalValues.get('endDate'),
        );
        var regularStartDateValues = moment(
          regularWithdrawalValues.get('startDate'),
        );
        var maxEndDate = moment(regularStartDateValues)
          .add(100, 'years')
          .toDate();

        if (regularEndDateValue.isBefore(regularStartDateValues))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.minEndDateValidation} />
          );

        if (regularEndDateValue.isAfter(maxEndDate))
          errorRegularWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.maxEndDateValidation} />
          );
      }

      if (regularWithdrawalValues.get('isAmountChecked') == 1) {
        if (!regularWithdrawalValues.get('amount'))
          errorRegularWithdrawal.amount = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const withdrawalAmount = regularWithdrawalValues.get('amount');
          var minPremiumAllowed = 0;
          var maxPremiumAllowed = 0;

          gcConfig.features.withdrawalSize.map(premiumSize => {
            if (premiumSize.currency === currencyValues) {
              minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
            }
          });

          if (termValues === 'SinglePremium')
            maxPremiumAllowed = getMaxValue(
              'Single',
              gcConfig.features,
              currencyValues,
            );
          else
            maxPremiumAllowed = getMaxValue(
              paymentFrequencyValues,
              gcConfig.features,
              currencyValues,
            );

          if (withdrawalAmount < minPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPremiumAllowed }}
              />
            );

          if (withdrawalAmount > maxPremiumAllowed)
            errorRegularWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPremiumAllowed }}
              />
            );
        }
      }
      if (!isEmpty(errorRegularWithdrawal)) {
        errors.regularWithdrawal = errorRegularWithdrawal;
      }
    }

    //Education Fee withdrawal validation
    if (isEducationFeeWithdrawalValues) {
      var errorEducationFeesWithdrawal = {};
      if (
        !educationFeeWithdrawalValues.get('frequency') ||
        educationFeeWithdrawalValues.get('frequency') === 'Select'
      )
        errorEducationFeesWithdrawal.frequency = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );

      if (!educationFeeWithdrawalValues.get('startDate'))
        errorEducationFeesWithdrawal.startDate = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      else {
        var educationFeeStartDateValues = moment(
          educationFeeWithdrawalValues.get('startDate'),
        );
        var minStartDate = moment(startDateValues)
          .date(15)
          .add(5, 'years')
          .toDate();

        if (
          moment(educationFeeStartDateValues, 'YYYY-MM-DD').format('DD') != 15
        )
          errorEducationFeesWithdrawal.startDate = (
            <FormattedMessage {...globalMessages.dateValidation2} />
          );
        else {
          if (educationFeeStartDateValues.isBefore(minStartDate))
            errorEducationFeesWithdrawal.startDate = (
              <FormattedMessage
                {...globalMessages.dateValidation}
                values={{ value: moment(minStartDate).format('DD/MM/YYYY') }}
              />
            );
        }
      }

      if (!educationFeeWithdrawalValues.get('endDate'))
        errorEducationFeesWithdrawal.endDate = (
          <FormattedMessage {...globalMessages.pleaseSelectValue} />
        );
      else {
        var educationFeeEndDateValue = moment(
          educationFeeWithdrawalValues.get('endDate'),
        );
        var educationFeeStartDateValues = moment(
          educationFeeWithdrawalValues.get('startDate'),
        );
        var maxEndDate = moment(regularStartDateValues)
          .add(100, 'years')
          .toDate();

        if (educationFeeEndDateValue.isBefore(educationFeeStartDateValues))
          errorEducationFeesWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.minEndDateValidation} />
          );

        if (educationFeeEndDateValue.isAfter(maxEndDate))
          errorEducationFeesWithdrawal.endDate = (
            <FormattedMessage {...globalMessages.maxEndDateValidation} />
          );
      }

      if (!educationFeeWithdrawalValues.get('firstName')) {
        errorEducationFeesWithdrawal.firstName = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      } else {
        if (!isAlphaNumeric(educationFeeWithdrawalValues.get('firstName'))) {
          errorEducationFeesWithdrawal.firstName = (
            <FormattedMessage {...globalMessages.onlyAlphanumericCharacters} />
          );
        }
      }

      if (!educationFeeWithdrawalValues.get('lastName')) {
        errorEducationFeesWithdrawal.lastName = (
          <FormattedMessage {...globalMessages.pleaseEnterValue} />
        );
      } else {
        if (!isAlphaNumeric(educationFeeWithdrawalValues.get('lastName'))) {
          errorEducationFeesWithdrawal.lastName = (
            <FormattedMessage {...globalMessages.onlyAlphanumericCharacters} />
          );
        }
      }

      if (educationFeeWithdrawalValues.get('isAmountChecked') == 1) {
        if (!educationFeeWithdrawalValues.get('amount'))
          errorEducationFeesWithdrawal.amount = (
            <FormattedMessage {...globalMessages.pleaseEnterValue} />
          );
        else {
          const withdrawalAmount = educationFeeWithdrawalValues.get('amount');
          var minPremiumAllowed = 0;
          var maxPremiumAllowed = 0;
          let withdrawalFrequencyValues = educationFeeWithdrawalValues.get(
            'frequency',
          );

          gcConfig.features.withdrawalSize.map(premiumSize => {
            if (premiumSize.currency === currencyValues) {
              minPremiumAllowed = premiumSize.minimumWithdrawalAmount;
            }
          });

          if (termValues === 'SinglePremium')
            maxPremiumAllowed = getMaxValue(
              'Single',
              gcConfig.features,
              currencyValues,
            );
          else
            maxPremiumAllowed = getMaxValue(
              withdrawalFrequencyValues,
              gcConfig.features,
              currencyValues,
            );

          if (withdrawalAmount < minPremiumAllowed)
            errorEducationFeesWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.minWithdrawalValidation}
                values={{ value: minPremiumAllowed }}
              />
            );

          if (withdrawalAmount > maxPremiumAllowed)
            errorEducationFeesWithdrawal.amount = (
              <FormattedMessage
                {...globalMessages.maxWithdrawalValidation}
                values={{ value: maxPremiumAllowed }}
              />
            );
        }
      }

      if (!isEmpty(errorEducationFeesWithdrawal)) {
        errors.educationFeesWithdrawal = {
          child1: errorEducationFeesWithdrawal,
        };
      }
    }
  }
  return errors;
};

export default validate;

/**
 *
 * Review
 *
 */

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Grid,
  Typography,
  Button,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Box,
} from '@material-ui/core';
import CustomDialog from 'components/Dialog';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import globalMessages from 'containers/App/messages';
import {
  Cancel,
  CheckCircle,
  DescriptionOutlined,
  Info,
} from '@material-ui/icons';
import { formatMonetaryValue } from 'helpers/math';
import InfoRow from 'components/InfoRow';
import { isEmpty } from 'lodash';
import ReviewUserData from 'components/ReviewUserData';
import { makeSelectOfferCodeData } from 'containers/App/selectors';
import { validateOfferCodeAction } from 'containers/App/actions';
import {
  getQuoteAction,
  updateStatusAction,
  emailPdfAction,
  setEmailSentAction,
} from '../actions';
import {
  makeSelectLifeAssured,
  makeSelectProductConfig,
  makeSelectBiPdf,
  makeSelectReferenceNumber,
  makeSelectFiaCharge,
  makeSelectemailSentStatus,
  makeSelectEmailErrorMessage,
  makeSelectConfig,
} from '../selectors';
import { isValidEmail } from '../helper';
import messages from '../messages';

function Review({
  lifeAssured,
  productConfig,
  getQuote,
  benefitIllustrationPdf,
  updateStatus,
  emailPdf,
  referenceNumber,
  offerCodeData,
  fiaData,
  validateOfferCode,
  emailSent,
  setEmailSent,
  emailErrorMessage,
  itaConfig,
  isValidQuoteByDate,
}) {
  const theme = useTheme();
  useEffect(() => {
    getQuote(0);
  }, []);

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [errorMail, setErrorMail] = useState(false);
  const [specialTermsData, setSpecialTermsData] = useState([]);
  const [standerdData, setStanderdData] = useState([]);

  let isBenefitRating = false;

  useEffect(() => {
    if (emailSent) {
      setErrorMail(true);
    }
  }, [emailSent]);

  useEffect(() => {
    if (offerCodeData.code) {
      validateOfferCode({
        offerCode: offerCodeData.code,
        productCode: itaConfig.productCode,
        productVersion: itaConfig.productVersion,
        premiumType: productConfig.term,
        currency: productConfig.currency,
        premium: productConfig.coverages?.[0]?.valueInsuredOne,
      });
    }
    return () => {
      setEmailSent(false);
    };
  }, []);

  useEffect(() => {
    const specialTermsDataArray = [];
    const standerdDataArray = [];
    productConfig.coverages.map(cvg => {
      isBenefitRating =
        !cvg.brSelectedInsuredOneValue &&
        !cvg.brSelectedInsuredTwoValue &&
        !cvg.brSelectedTempValueOne &&
        !cvg.brSelectedTempValueTwo &&
        !cvg.brSelectedPercentValueOne &&
        !cvg.brSelectedPercentValueTwo;

      if (isBenefitRating) {
        standerdDataArray.push(cvg);
        setStanderdData(standerdDataArray);
      } else {
        specialTermsDataArray.push(cvg);
        setSpecialTermsData(specialTermsDataArray);
      }
    });
  }, [productConfig]);

  const getBrTitle = brValue => {
    switch (brValue) {
      case '0':
        return '';

      case '1':
        return 'Per Mille';

      case '2':
        return 'Percentage';

      case '3':
        return 'Period';

      default:
        return '';
    }
  };
  let frequencyLabel = 'p/month';
  switch (productConfig.paymentFrequency) {
    case 'Monthly':
      frequencyLabel = 'p/month';
      break;

    case 'Quarterly':
      frequencyLabel = 'p/quarter';
      break;

    case 'Half-Yearly':
      frequencyLabel = 'p/half-year';
      break;

    case 'Yearly':
      frequencyLabel = 'p/year';
      break;

    default:
      frequencyLabel = 'p/month';
      break;
  }
  return (
    <div style={{ marginTop: theme.spacing(5), maxWidth: 650 }}>
      {/* ########## START  :: REFERENCE NUMBER ########## */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: theme.spacing(2) }}
      >
        <Grid item>
          {/* <Button
            display="block"
            variant="text"
            color="primary"
            onClick={() => onClickBackToClientList()}
          >
            <FormattedMessage {...messages.backToClientList} />
          </Button> */}
        </Grid>

        <Grid item>
          <Typography
            display="block"
            align="right"
            variant="body2"
            style={{ color: '#666666' }}
          >
            <FormattedMessage {...messages.reference} />:{` ${referenceNumber}`}
          </Typography>
        </Grid>
        {offerCodeData.errorMessage && (
          <Grid item xs={12}>
            <Typography
              display="block"
              align="right"
              variant="body2"
              style={{ color: 'red' }}
            >
              <FormattedMessage {...globalMessages.specialOfferExpired} />
            </Typography>
          </Grid>
        )}
        {/* {!isValidQuoteByDate && (
          <Grid item xs={12}>
            <Typography
              display="block"
              align="right"
              variant="body2"
              style={{ color: 'red' }}
            >
              <FormattedMessage {...globalMessages.quoteInvalid} />
            </Typography>
          </Grid>
        )} */}
      </Grid>

      {/* ########## END    :: REFERENCE NUMBER ########## */}
      {/* ########## START  :: POLICY OWNER ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.policyOwner} />
        </Typography>
        {/* LI 1 as PO */}
        {lifeAssured.insuredOne.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredOne} />
        )}
        {/* LI 2 as PO */}
        {lifeAssured.isInsuredTwo && lifeAssured.insuredTwo.isPolicyOwner && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
        {/* other POs */}
        {lifeAssured.isPolicyOwnerIndividual &&
          lifeAssured.policyOwnerIndividual.map((po, index) => {
            const key = `${po.firstName}-${index}`;
            return <ReviewUserData key={key} party={po} />;
          })}
      </Box>
      {/* ########## END    :: POLICY OWNER ########## */}
      {/* ########## START  :: LIFE INSURED ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.insuredParty} />
        </Typography>

        <ReviewUserData party={lifeAssured.insuredOne} />
        {lifeAssured.isInsuredTwo && (
          <ReviewUserData party={lifeAssured.insuredTwo} />
        )}
      </Box>
      {/* ########## END    :: LIFE INSURED ########## */}

      {/* ########## START  :: PREMIUM SUMMARY ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.premiumSummary} />
        </Typography>

        {!isEmpty(productConfig.slicedPremium)
          ? Object.keys(productConfig.slicedPremium).map((key, index) => {
              const cellKey = `$premiumKey${index}`;
              const item = productConfig.slicedPremium[key];
              return (
                item.Time && (
                  <InfoRow key={cellKey}>
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item xs>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          >
                            <Info color="inherit" />
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body2">
                              <FormattedMessage
                                {...messages.premiumTemporaryAmount}
                              />
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography variant="body2" align="left">
                          {`${item.Time * 12} months`}
                        </Typography>
                      </Grid>

                      <Grid item xs={3}>
                        <Typography variant="body2" align="left">
                          {productConfig.currency}{' '}
                          {formatMonetaryValue(item.Premium, true)}
                          {` ${frequencyLabel}`}
                          {/* {` p/${
                            productConfig.paymentFrequency === 'Monthly'
                              ? 'month'
                              : 'year'
                          }`} */}
                        </Typography>
                      </Grid>
                    </Grid>
                  </InfoRow>
                )
              );
            })
          : null}
        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.premiumAmount} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" align="left">
                {productConfig.currency}{' '}
                {formatMonetaryValue(productConfig.premium, true)}
                {` p/${
                  productConfig.paymentFrequency === 'Monthly'
                    ? 'month'
                    : 'year'
                }`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        <InfoRow>
          <Grid container alignItems="center" wrap="nowrap">
            <Grid item xs>
              <Grid container spacing={2} alignItems="center">
                <Grid
                  item
                  style={{
                    color: theme.palette.grey[400],
                  }}
                >
                  <Info color="inherit" />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2">
                    <FormattedMessage {...messages.policyTermTitle} />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body2" align="left">
                {`${productConfig.term} Years`}
              </Typography>
            </Grid>
          </Grid>
        </InfoRow>

        {!isEmpty(fiaData) && itaConfig.features.haveFiaCharge ? (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...globalMessages.advisorFeeLabel} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" align="left">
                  {`${productConfig.fiaCharge} %`}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        ) : null}

        {offerCodeData.code ? (
          <InfoRow>
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item xs>
                <Grid container spacing={2} alignItems="center">
                  <Grid
                    item
                    style={{
                      color: theme.palette.grey[400],
                    }}
                  >
                    <Info color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <Typography variant="body2">
                      <FormattedMessage {...messages.specialOffer} />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" align="left">
                  {offerCodeData.discountMessage?.english}
                </Typography>
              </Grid>
            </Grid>
          </InfoRow>
        ) : null}
      </Box>
      {/* ########## END    :: PREMIUM SUMMARY ########## */}

      {/* ########## START  :: SPECIAL TERMS DETAILS ########## */}
      {productConfig.specialTermsFlag && specialTermsData.length > 0 ? (
        <Box mb={5}>
          <Typography
            variant="h3"
            style={{
              marginBottom: theme.spacing(2),
              color: theme.palette.primary.subMain,
            }}
          >
            <FormattedMessage {...messages.specialTerms} />
          </Typography>
          {specialTermsData.map((cvg, index) => {
            const key = `$specTerms${index}`;
            return (
              <Grid container direction="column" key={key}>
                <InfoRow style={{ maxHeight: 64 }}>
                  <Grid
                    container
                    alignItems="center"
                    wrap="nowrap"
                    justify="space-between"
                  >
                    <Grid item xs={6}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          style={{
                            color: theme.palette.grey[400],
                          }}
                        >
                          <Info color="primary" />
                        </Grid>
                        <Grid item xs>
                          <Typography variant="body2">{cvg.title}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {cvg.id === 'WOP'
                          ? cvg.valueInsuredOne
                            ? 'Yes'
                            : 'No'
                          : cvg.selected
                          ? `${productConfig.currency} ${formatMonetaryValue(
                              cvg.valueInsuredOne,
                              false,
                            )}`
                          : `--`}
                      </Typography>
                    </Grid>

                    {lifeAssured.isInsuredTwo ? (
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {cvg.id === 'WOP'
                            ? cvg.valueInsuredTwo
                              ? 'Yes'
                              : 'No'
                            : cvg.selected
                            ? `${productConfig.currency} ${formatMonetaryValue(
                                cvg.valueInsuredTwo,
                                false,
                              )}`
                            : `--`}
                        </Typography>
                      </Grid>
                    ) : null}
                  </Grid>
                </InfoRow>

                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue ||
                  cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                  <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.dark,
                        paddingBottom: theme.spacing(1),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...messages.permanent} />
                    </Typography>
                  </InfoRow>
                )}
                {(cvg.brSelectedInsuredOneValue ||
                  cvg.brSelectedInsuredTwoValue) && (
                  <InfoRow variant="br">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          ></Grid>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {getBrTitle('1')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        {!cvg.brSelectedInsuredOneValue ? (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            --
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedInsuredOneValue
                              ? `+${cvg.perMileOne.toFixed(2)}`
                              : `--`}
                          </Typography>
                        )}
                      </Grid>

                      {lifeAssured.isInsuredTwo ? (
                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedInsuredTwoValue
                              ? `+${cvg.perMileTwo.toFixed(2)}`
                              : `--`}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </InfoRow>
                )}

                {(cvg.brSelectedPercentValueOne ||
                  cvg.brSelectedPercentValueTwo) && (
                  <InfoRow variant="br">
                    <Grid
                      container
                      alignItems="center"
                      wrap="nowrap"
                      justify="space-between"
                    >
                      <Grid item xs={6}>
                        <Grid container spacing={2} alignItems="center">
                          <Grid
                            item
                            style={{
                              color: theme.palette.grey[400],
                            }}
                          ></Grid>
                          <Grid item xs>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {getBrTitle('2')}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        {!cvg.brSelectedPercentValueOne ? (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            --
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedPercentValueOne
                              ? `+${cvg.perPercentageOne}`
                              : `--`}
                          </Typography>
                        )}
                      </Grid>

                      {lifeAssured.isInsuredTwo ? (
                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.brSelectedPercentValueTwo
                              ? `+${cvg.perPercentageTwo}`
                              : `--`}
                          </Typography>
                        </Grid>
                      ) : null}
                    </Grid>
                  </InfoRow>
                )}

                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <InfoRow variant="br" style={{ backgroundColor: '#f5f5f5' }}>
                    <Typography
                      variant="h4"
                      style={{
                        color: theme.palette.primary.dark,
                        paddingBottom: theme.spacing(1),
                        fontWeight: 'bold',
                      }}
                    >
                      <FormattedMessage {...messages.temporary} />
                    </Typography>
                  </InfoRow>
                )}
                {(cvg.brSelectedTempValueOne || cvg.brSelectedTempValueTwo) && (
                  <>
                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('1')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMileOne !== 0
                              ? `+${cvg.perTempMileOne.toFixed(2)}`
                              : `--`}
                          </Typography>
                        </Grid>

                        {lifeAssured.isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMileTwo !== 0
                                ? `+${cvg.perTempMileTwo.toFixed(2)}`
                                : `--`}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>

                    <InfoRow variant="br">
                      <Grid
                        container
                        alignItems="center"
                        wrap="nowrap"
                        justify="space-between"
                      >
                        <Grid item xs={6}>
                          <Grid container spacing={2} alignItems="center">
                            <Grid
                              item
                              style={{
                                color: theme.palette.grey[400],
                              }}
                            ></Grid>
                            <Grid item xs>
                              <Typography
                                variant="body2"
                                style={{ color: theme.palette.grey[400] }}
                              >
                                {getBrTitle('3')}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item xs={3}>
                          <Typography
                            variant="body2"
                            style={{ color: theme.palette.grey[400] }}
                          >
                            {cvg.perTempMonthOne !== 0
                              ? `+${cvg.perTempMonthOne} months`
                              : `--`}
                          </Typography>
                        </Grid>

                        {lifeAssured.isInsuredTwo ? (
                          <Grid item xs={3}>
                            <Typography
                              variant="body2"
                              style={{ color: theme.palette.grey[400] }}
                            >
                              {cvg.perTempMonthTwo !== 0
                                ? `+${cvg.perTempMonthTwo} month`
                                : `--`}
                            </Typography>
                          </Grid>
                        ) : null}
                      </Grid>
                    </InfoRow>
                  </>
                )}
              </Grid>
            );
          })}
        </Box>
      ) : null}
      {/* ########## END    :: SPECIAL TERMS DETAILS ########## */}

      {/* ########## START  :: COVER DETAILS ########## */}
      <Box mb={5}>
        <Typography
          variant="h3"
          style={{
            marginBottom: theme.spacing(2),
            color: theme.palette.primary.subMain,
          }}
        >
          <FormattedMessage {...messages.coverDetails} />
        </Typography>

        {standerdData.map((cvg, index) => {
          if (!cvg.selected) {
            return null;
          }
          return (
            <Grid container direction="column" key={cvg.id}>
              <InfoRow style={{ maxHeight: 64 }}>
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  justify="space-between"
                >
                  <Grid item xs={6}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        style={{
                          color: theme.palette.grey[400],
                        }}
                      >
                        {cvg.selected ? (
                          <CheckCircle color="inherit" />
                        ) : (
                          <Cancel color="inherit" />
                        )}
                      </Grid>
                      <Grid item xs>
                        <Typography variant="body2">{cvg.title}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="body2">
                      {cvg.id === 'WOP'
                        ? cvg.valueInsuredOne
                          ? 'Yes'
                          : 'No'
                        : cvg.selected
                        ? `${productConfig.currency} ${formatMonetaryValue(
                            cvg.valueInsuredOne,
                            false,
                          )}`
                        : '-'}
                    </Typography>
                  </Grid>

                  {lifeAssured.isInsuredTwo ? (
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {cvg.id === 'WOP'
                          ? cvg.valueInsuredTwo
                            ? 'Yes'
                            : 'No'
                          : cvg.selected
                          ? `${productConfig.currency} ${formatMonetaryValue(
                              cvg.valueInsuredTwo,
                              false,
                            )}`
                          : '-'}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              </InfoRow>
            </Grid>
          );
        })}
      </Box>
      {/* ########## END    :: COVER DETAILS ########## */}

      <Typography
        variant="body1"
        style={{
          marginBottom: theme.spacing(2),
        }}
      >
        <FormattedMessage {...messages.reviewNotice} />
      </Typography>
      <InfoRow variant="document">
        <Grid container alignItems="center" wrap="nowrap" spacing={2}>
          <Grid item>
            <DescriptionOutlined color="inherit" />
          </Grid>
          <Grid item xs>
            <Typography variant="body1" color="inherit">
              <FormattedMessage
                {...messages.illustration}
                values={{
                  name: itaConfig.shortName,
                }}
              />
            </Typography>
          </Grid>

          <Grid item>
            <Link
              href={benefitIllustrationPdf}
              target="_blank"
              align="center"
              variant="body2"
              disabled={!benefitIllustrationPdf}
              onClick={() => updateStatus(1)}
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.viewExportPDF} />
            </Link>
          </Grid>
          <Grid item>
            <Link
              target="_blank"
              align="center"
              variant="body2"
              onClick={() => {
                setOpen(true);
              }}
              style={{
                color: theme.palette.success.main,
                fontWeight: 700,
              }}
            >
              <FormattedMessage {...messages.emailPDF} />
            </Link>
          </Grid>
        </Grid>
      </InfoRow>

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <FormattedMessage {...messages.emailPDF} />
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="normal"
            id="emailAddress"
            label={<FormattedMessage {...globalMessages.email} />}
            type="email"
            error={error}
            onChange={e => setEmail(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (isValidEmail(email)) {
                setError(false);
                emailPdf(email);
                return setOpen(false);
              }
              return setError(true);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.send} />
          </Button>
        </DialogActions>
      </Dialog>

      {/* EMAIL ERROR  */}
      <CustomDialog
        open={errorMail}
        onClose={() => {
          setErrorMail(false);
          setEmailSent(false);
        }}
        title={<FormattedMessage {...messages.error} />}
        variant="error"
        actions={
          <Button
            onClick={() => {
              setErrorMail(false);
              return setEmailSent(false);
            }}
            color="primary"
          >
            <FormattedMessage {...globalMessages.close} />
          </Button>
        }
      >
        <Typography variant="h2">
          <FormattedMessage
            {...messages.errorEmail}
            values={{ message: emailErrorMessage }}
          />
        </Typography>
      </CustomDialog>
    </div>
  );
}

Review.propTypes = {
  fiaData: PropTypes.object,
  lifeAssured: PropTypes.object,
  productConfig: PropTypes.object,
  benefitIllustrationPdf: PropTypes.string,
  getQuote: PropTypes.func,
  updateStatus: PropTypes.func,
  emailPdf: PropTypes.func,
  referenceNumber: PropTypes.string,
  offerCodeData: PropTypes.object,
  onClickBackToClientList: PropTypes.func,
  emailSent: PropTypes.bool,
  setEmailSent: PropTypes.func,
  emailErrorMessage: PropTypes.string,
  itaConfig: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  lifeAssured: makeSelectLifeAssured(),
  productConfig: makeSelectProductConfig(),
  itaConfig: makeSelectConfig(),
  benefitIllustrationPdf: makeSelectBiPdf(),
  fiaData: makeSelectFiaCharge(),
  referenceNumber: makeSelectReferenceNumber(),
  offerCodeData: makeSelectOfferCodeData(),
  emailSent: makeSelectemailSentStatus(),
  emailErrorMessage: makeSelectEmailErrorMessage(),
});

function mapDispatchToProps(dispatch) {
  return {
    updateStatus: val => dispatch(updateStatusAction(val)),
    getQuote: val => dispatch(getQuoteAction(val)),
    emailPdf: value => dispatch(emailPdfAction(value)),
    setEmailSent: value => dispatch(setEmailSentAction(value)),
    validateOfferCode: payload => dispatch(validateOfferCodeAction(payload)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Review);

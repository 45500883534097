/*
 *
 * SwIllustrationPage constants
 *
 */

export const CHANGE_LIFE_ASSURED_ACTION =
  'app/SwIllustrationPage/CHANGE_LIFE_ASSURED_ACTION';
export const SET_PRODUCT_CONFIG_ACTION =
  'app/SwIllustrationPage/SET_PRODUCT_CONFIG_ACTION';
export const SET_COVERAGES_LIST_ACTION =
  'app/SwIllustrationPage/SET_COVERAGES_LIST_ACTION';
export const SET_SW_CONFIG_ACTION =
  'app/SwIllustrationPage/SET_SW_CONFIG_ACTION';
export const GET_SW_CONFIG_SUCCESS_ACTION =
  'app/SwIllustrationPage/GET_SW_CONFIG_ACTION';
export const CREATE_QUOTE_ACTION = 'app/SwIllustrationPage/CREATE_QUOTE_ACTION';
export const CALCULATE_QUOTE_ACTION =
  'app/SwIllustrationPage/CALCULATE_QUOTE_ACTION';
export const SET_QUOTE_ACTION = 'app/SwIllustrationPage/SET_QUOTE_ACTION';
export const SET_CALCULATION_ACTION =
  'app/SwIllustrationPage/SET_CALCULATION_ACTION';
export const ADD_POLICY_OWNER_ACTION =
  'app/SwIllustrationPage/ADD_POLICY_OWNER_ACTION';
export const GET_QUOTE_ACTION = 'app/SwIllustrationPage/GET_QUOTE_ACTION';
export const UPDATE_STATUS_ACTION =
  'app/SwIllustrationPage/UPDATE_STATUS_ACTION';
export const SET_QUOTE_DETAILS_ACTION =
  'app/SwIllustrationPage/SET_QUOTE_DETAILS_ACTION';
export const SET_PTV_VERSION_ACTION =
  'app/SwIllustrationPage/SET_PTV_VERSION_ACTION';
export const SET_APPLICATION_ID_ACTION =
  'app/SwIllustrationPage/SET_APPLICATION_ID_ACTION';
export const EMAIL_PDF_ACTION = 'app/SwIllustrationPage/EMAIL_PDF_ACTION';
export const CREATE_APPLICATION_ACTION =
  'app/SwIllustrationPage/CREATE_APPLICATION_ACTION';
export const SET_ERROR_ACTION = 'app/SwIllustrationPage/SET_ERROR_ACTION';
export const RESET_ACTION = 'app/SwIllustrationPage/RESET_ACTION';
export const GET_FIA_ACTION = 'app/SwIllustrationPage/GET_FIA_ACTION';
export const SET_FIA_ACTION = 'app/SwIllustrationPage/SET_FIA_ACTION';
export const SET_BI_TABLE_VALUES_ACTION =
  'app/SwIllustrationPage/SET_BI_TABLE_VALUES_ACTION';
export const CLONE_QUOTE_ACTION = 'app/SwIllustrationPage/CLONE_QUOTE_ACTION';
export const SET_CLONE_STATUS_ACTION =
  'app/SwIllustrationPage/SET_CLONE_STATUS_ACTION';
export const SET_BI_PDF_ACTION = 'app/SwIllustrationPage/SET_BI_PDF_ACTION';

export const SET_EMAIL_SENT_ACTION =
  'app/SwIllustrationPage/SET_EMAIL_SENT_ACTION';

export const SET_EMAIL_ERROR_MESSAGE_ACTION =
  'app/SwIllustrationPage/SET_EMAIL_ERROR_MESSAGE_ACTION';
export const SET_PRODUCT_CODE_ACTION =
  'app/SwIllustrationPage/SET_PRODUCT_CODE_ACTION';

export const SET_FORM_INPUT = 'app/SwIllustrationPage/SET_FORM_INPUT';

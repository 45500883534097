/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  const contentType = response.headers.get('content-type');
  if (contentType && contentType.indexOf('application/octet-stream') !== -1) {
    return response.blob();
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  if (response.status >= 500 && response.status < 600) {
    throw error;
  }
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  // return fetch(url, {
  //   ...options,
  //   headers: {
  //     ...options.headers,
  //     'X-CSRF-Token': sessionStorage.getItem('csrf'),
  //   },
  //   credentials: 'include',
  // })
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .catch(err => {
      if (err.response.status === 401 || err.response.status === 403) {
        sessionStorage.clear();
        window.location.href = '/login';
      }

      throw err;
    });
}

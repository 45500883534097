/**
 * BeneficiaryDetails
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'redux-form/immutable';

import { newTaxPurposes } from 'helpers/placeholder';
import { Box, Grid, Link } from '@material-ui/core';
import messages from 'containers/ApplicationFormPage/messages';
import FormTextField from 'components/FormTextField';
import FormNumberField from 'components/FormNumberField';
import FormSelect from 'components/FormSelect';
import Helper from 'components/Helper';
import { convertIfImmutable, changePlaceHolderColor } from 'helpers/lang';
import { isDisabledField } from 'helpers/constants';

const useStyle = makeStyles(() => ({
  container: {
    marginTop: '1rem',
  },
  button: {
    fontWeight: 800,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100%',
  },
  hr: {
    margin: '0 0rem',
    borderTop: '1px solid #DDDFE0',
  },
}));

function TaxPuposes(props) {
  const intl = useIntl();
  const classes = useStyle();

  const { fields, formValues, options, disabled, disabledFields } = props;

  const addTaxPurposes = () => {
    if (fields.length < 3) {
      fields.push({ ...newTaxPurposes });
    }
  };

  return fields.map((field, index) => {
    return (
      <Grid container spacing={3} className={classes.container}>
        <Grid item>
          <Field
            name={`${field}.countryOfTax`}
            // fullWidth
            disabled={disabled}
            component={FormSelect}
            style={{
              color: changePlaceHolderColor(
                formValues.taxPurposes[0].countryOfTax,
              ),
              width: 210,
            }}
            options={[
              { title: 'Select an option', value: 'Select an option' },
              ...options.countryCode,
            ]}
            label={<FormattedMessage {...messages.countryOfTax} />}
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            margin="normal"
            variant="standard"
            disabled={isDisabledField(disabledFields, 'po', 'countryOfTax')}
          />
        </Grid>
        <Grid item>
          <Field
            name={`${field}.taxReferenceNumber`}
            fullWidth
            disabled={disabled}
            component={FormTextField}
            thousandSeparator=""
            style={{ width: 210 }}
            label={
              <Box pb={0}>
                <FormattedMessage {...messages.taxReferenceNumber} />
                <Helper
                  helperText={messages.showtaxReferenceNumber.defaultMessage}
                />
              </Box>
            }
            placeholder={intl.formatMessage(messages.pleaseEnter)}
            type="text"
            margin="normal"
            variant="standard"
            disabled={isDisabledField(
              disabledFields,
              'po',
              'taxReferenceNumber',
            )}
          />
        </Grid>
        <Grid item>
          {!disabled && (
            <div className={classes.buttonContainer}>
              <Grid container spacing={0}>
                {fields.length === index + 1 && fields.length < 3 && (
                  <Grid item style={{ marginRight: 20 }}>
                    <Link className={classes.button} onClick={addTaxPurposes}>
                      Add
                    </Link>
                  </Grid>
                )}
                {fields.length === 1 ? (
                  ''
                ) : (
                  <Grid item>
                    <Link
                      className={classes.button}
                      onClick={() => {
                        fields.remove(index);
                      }}
                    >
                      Delete
                    </Link>
                  </Grid>
                )}
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    );
  });
}

TaxPuposes.propTypes = {
  fields: PropTypes.object,
  formValues: PropTypes.object,
  options: PropTypes.object,
};

export default TaxPuposes;

/**
 *
 * LifeInsuredForm
 *
 */

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  Grid,
  Typography,
  Collapse,
  Card,
  FormControlLabel,
  Divider,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Field,
  reduxForm,
  FormSection,
  formValueSelector,
} from 'redux-form/immutable';
import { InjectedFormProps, WrappedFieldArrayProps } from 'redux-form';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import validate from './validate';
import { fromJS } from 'immutable';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import globalMessages from 'containers/App/messages';
import FormTextField from 'components/FormTextField';
import OptSwitch from 'components/OptSwitch';
import FormSelect from 'components/FormSelect';
import FormRadioGroup from 'components/FormRadioGroup';
import CustomRadio from 'components/CustomRadioButton';
import Helper from 'components/Helper';
import { getStartDate } from '../../helper';
import { isEmpty } from 'lodash';
import {
  setCoveragesListAction,
  setInitProductConfigsCoveragesAction,
  resetProductConfigsAction,
  setSustainabilityYearsAction,
} from '../../actions';
import FuturaPersonalDetails from '../../FuturaComponents/PersonalDetails';
import { OLDER_FUTURA } from '../../constants';

const useStyle = makeStyles(theme => ({
  hr: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginLeft: '-2rem',
    marginRight: '-2rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  radioForm: {
    display: 'flex',
    alignItems: 'center',
    direction: 'column',
  },
}));

const Wrapper = styled(({ theme, ...props }) => <Grid {...props} />)`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const CardWrapper = styled(({ theme, ...props }) => <Card {...props} />)`
  && {
    padding: ${props => props.theme.spacing(2)}px;
    margin-bottom: 10px;
    max-width: 640px;
  }
`;

const renderSwitch = ({ input: { onChange, value }, disabled }) => {
  return (
    <OptSwitch
      checked={value}
      value={value}
      color="default"
      onChange={onChange}
      labelNegative={<FormattedMessage {...messages.no} />}
      disabled={disabled}
    />
  );
};

renderSwitch.propTypes = {
  theme: PropTypes.object,
  disabled: PropTypes.bool,
};

const getCoverageValueByLifeBasis = (coverage, lifeBasis, isOlderFutura) => {
  const lifeBasisWithOlderFutura = isOlderFutura ? 'JLBD' : lifeBasis;
  const chosenValue = {
    JLBD: {
      number: coverage.id === 'LFC' ? Number(coverage.valueInsuredOne) : 0,
      boolean: false,
    },
    JLFD: {
      number: Number(coverage.valueInsuredOne),
      boolean: coverage.valueInsuredOne,
    },
  };

  if (typeof coverage.valueInsuredOne === 'boolean') {
    return coverage.selected
      ? chosenValue[lifeBasisWithOlderFutura].boolean
      : false;
  } else {
    return coverage.selected ? chosenValue[lifeBasisWithOlderFutura].number : 0;
  }
};

const getBenefitSlicesByLifeBasis = (slices, lifeBasis) => {
  if (lifeBasis === 'SL') {
    return null;
  }

  return slices.map(item => ({ ...item, benefitAmount: 0, benefitTerm: 0 }));
};

const getTermValueByLifeBasis = (termOne, lifeBasis, isOlderFutura) => {
  if (['JLBD', 'SL'].includes(lifeBasis) && !isOlderFutura) {
    return 0;
  }

  return termOne;
};

function LifeInsuredForm({
  options,
  insuredOne,
  insuredTwo,
  isInsuredTwo,
  lifeBasis,
  lifeBasisHarvest,
  initProductConfig,
  policyConfig,
  handleSubmit,
  onSubmit,
  setSustainabilityYears,
  setInitCoverages,
  resetProductConfigs,
}) {
  const theme = useTheme();
  const classes = useStyle();
  // const [isAdditionalFeature, setAdditionalFeature] = useState(false);
  // const isShowFiaCharge =
  //   !isEmpty(fiaData) &&
  //   policyConfig?.features?.haveFiaCharge &&
  //   !['FUTU4', 'SCFUT'].includes(policyConfig?.productCode);
  const isPremiumHoliday = initProductConfig ? initProductConfig.isPremiumHoliday : false;
  const minDob = getStartDate()
    .toDate()
    .setFullYear(
      getStartDate()
        .toDate()
        .getFullYear() - 75,
    );
  const maxDob = getStartDate()
    .toDate()
    .setFullYear(
      getStartDate()
        .toDate()
        .getFullYear() - 18,
    );
  const isOlderFutura = OLDER_FUTURA.includes(policyConfig.productCode);

  return (
    <>
      <form onSubmit={() => handleSubmit(onSubmit)}>
        <Wrapper theme={theme}>
          <FormSection name="insuredOne">
            {lifeBasisHarvest !== 'SL' && (
              <Typography
                variant="h3"
                style={{
                  marginBottom: theme.spacing(2),
                  color: theme.palette.primary.subMain,
                }}
              >
                <FormattedMessage {...messages.lifeInsuredOne} />{' '}
                {insuredOne.firstName} {insuredOne.lastName}
              </Typography>
            )}
            <FuturaPersonalDetails
              options={options}
              formValues={insuredOne}
              minDate={minDob}
              maxDate={maxDob}
              isDisableForm={true}
              isPremiumHoliday = {isPremiumHoliday}
            />
          </FormSection>

          {lifeBasisHarvest !== 'SL' ? (
            <>
              <Divider style={{ margin: '40px 0' }} />
              <FormSection name="insuredTwo">
                <Typography
                  variant="h3"
                  style={{
                    marginBottom: theme.spacing(2),
                    color: theme.palette.primary.subMain,
                  }}
                >
                  <FormattedMessage {...messages.lifeInsuredTwo} />{' '}
                  {insuredTwo.firstName} {insuredTwo.lastName}
                </Typography>
                <FuturaPersonalDetails
                  options={options}
                  formValues={insuredTwo}
                  minDate={minDob}
                  maxDate={maxDob}
                  isDisableForm={true}
                  isPremiumHoliday = {isPremiumHoliday}
                />
              </FormSection>
            </>
          ) : (
            ['FUTU4', 'FUTU5'].includes(policyConfig?.productCode) && (
              <CardWrapper variant="outlined" theme={theme}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                >
                  <Typography variant="body1">
                    {<FormattedMessage {...messages.isSecondInsured} />}
                  </Typography>

                  <Field
                    name="isInsuredTwo"
                    component={renderSwitch}
                    theme={theme}
                    margin="normal"
                    nameType="isInsuredTwo"
                  />
                </Grid>
                <Collapse in={isInsuredTwo}>
                  <hr className={classes.hr} />
                  <FormSection name="insuredTwo">
                    <FuturaPersonalDetails
                      options={options}
                      formValues={insuredTwo}
                      minDate={minDob}
                      maxDate={maxDob}
                      isDisableForm={false}
                      isPremiumHoliday = {isPremiumHoliday}
                    />
                  </FormSection>

                  <Divider />

                  <Field
                    name="lifeBasis"
                    type="radio"
                    style={{ marginTop: theme.spacing(2) }}
                    component={FormRadioGroup}
                    onChange={(e, value) => {
                      const myNewCoverages = initProductConfig.coverages.map(
                        item => ({
                          ...item,
                          benefitSlicesTwo: getBenefitSlicesByLifeBasis(
                            item.benefitSlicesOne,
                            value,
                          ),
                          valueInsuredTwo: getCoverageValueByLifeBasis(
                            item,
                            value,
                            isOlderFutura,
                          ),
                          termTwo: getTermValueByLifeBasis(
                            item.termOne,
                            value,
                            isOlderFutura,
                          ),
                          initValueInsuredTwo: getCoverageValueByLifeBasis(
                            item,
                            value,
                            isOlderFutura,
                          ),
                          initTermTwo: getTermValueByLifeBasis(
                            item.termOne,
                            value,
                            isOlderFutura,
                          ),
                        }),
                      );
                      resetProductConfigs({
                        ...initProductConfig,
                        coverages: myNewCoverages,
                      });
                      setInitCoverages(myNewCoverages);
                      setSustainabilityYears(null);
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ margin: theme.spacing(1) }}
                    >
                      {
                        <FormattedMessage
                          {...messages.whatPolicyBasisRequired}
                        />
                      }
                    </Typography>

                    <Grid container direction="column">
                      <FormControlLabel
                        control={<CustomRadio checked={lifeBasis === 'JLFD'} />}
                        value={'JLFD'}
                        style={{ margin: theme.spacing(1) }}
                        label={
                          <>
                            <FormattedMessage {...messages.jlfd} />{' '}
                            <Helper
                              helperText={messages.tooltipJLFD.defaultMessage}
                            />
                          </>
                        }
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        control={<CustomRadio checked={lifeBasis === 'JLBD'} />}
                        value={'JLBD'}
                        style={{ margin: theme.spacing(1) }}
                        label={
                          <>
                            <FormattedMessage {...messages.jlbd} />{' '}
                            <Helper
                              helperText={messages.tooltipJLBD.defaultMessage}
                            />
                          </>
                        }
                        labelPlacement="end"
                      />
                    </Grid>
                  </Field>
                </Collapse>
              </CardWrapper>
            )
          )}
        </Wrapper>
        <Grid item xs={12} style={{ visibility: 'hidden' }}>
          <Field
            name="lifeBasisHarvest"
            component={FormTextField}
            type="hidden"
          />
        </Grid>
      </form>
    </>
  );
}

LifeInsuredForm.propTypes = {
  ...InjectedFormProps,
  options: PropTypes.object,
  policyConfig: PropTypes.object,
  initProductConfig: PropTypes.object,
  fiaData: PropTypes.object,
  insuredOne: PropTypes.object,
  insuredTwo: PropTypes.object,
  isInsuredTwo: PropTypes.bool,
  lifeBasis: PropTypes.string,
  fiaCharge: PropTypes.string,
  isInsuredOnePolicyOwner: PropTypes.bool,
  isInsuredTwoPolicyOwner: PropTypes.bool,
  isPolicyOwnerIndividual: PropTypes.bool,
  resetInsuredPolicyOwner: PropTypes.func,
  resetCoverages: PropTypes.func,
  policyOwnerIndividual: PropTypes.array,
  resetPolicyOwnerIndividual: PropTypes.func,
};

const withForm = reduxForm({
  validate,
  form: 'lifeAssuredForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(LifeInsuredForm);

const selector = formValueSelector('lifeAssuredForm');
const mapStateToProps = state => {
  let isInsuredTwo = selector(state, 'isInsuredTwo');
  const fiaCharge = selector(state, 'fiaCharge');
  let lifeBasis = selector(state, 'lifeBasis');
  let insuredOne = selector(state, 'insuredOne');
  let insuredTwo = selector(state, 'insuredTwo');
  let isInsuredOnePolicyOwner = false;
  let isInsuredTwoPolicyOwner = false;
  if (insuredOne) {
    var insuredData = insuredOne.toJS();
    isInsuredOnePolicyOwner = insuredData.isPolicyOwner;
  }
  if (insuredTwo) {
    var insuredData = insuredTwo.toJS();
    isInsuredTwoPolicyOwner = insuredData.isPolicyOwner;
  }
  if (!isInsuredTwo) isInsuredTwoPolicyOwner = false;
  let policyOwnerIndividual = selector(state, 'policyOwnerIndividual');
  let isPolicyOwnerIndividual = selector(state, 'isPolicyOwnerIndividual');
  let isBoldInsuredPolicyOwner =
    isInsuredOnePolicyOwner && isInsuredTwoPolicyOwner;
  return {
    isInsuredTwo,
    lifeBasis,
    fiaCharge: fiaCharge || '1',
    insuredTwo: insuredTwo?.toJS() || {},
    insuredOne: insuredOne?.toJS() || {},
    isPolicyOwnerIndividual,
    isInsuredOnePolicyOwner,
    isInsuredTwoPolicyOwner,
    policyOwnerIndividual: policyOwnerIndividual?.toJS() || [],
    isBoldInsuredPolicyOwner,
  };
};

const mapDispatchToProps = dispatch => ({
  resetInsuredPolicyOwner: () =>
    dispatch(change('lifeAssuredForm', 'insuredTwo.isPolicyOwner', false)),
  resetCoverages: val => dispatch(setCoveragesListAction(val)),
  setInitCoverages: data =>
    dispatch(setInitProductConfigsCoveragesAction(data)),
  resetPolicyOwnerIndividual: () =>
    dispatch(
      change(
        'lifeAssuredForm',
        'policyOwnerIndividual',
        fromJS([
          {
            prospectId: '',
            firstName: '',
            lastName: '',
            relationshipToLifeInsured: 'Select an option',
            isPolicyOwner: true,
            isLifeInsured: false,
            isPrimaryLifeInsured: false,
          },
        ]),
      ),
    ),
  resetProductConfigs: data => dispatch(resetProductConfigsAction(data)),
  setSustainabilityYears: value =>
    dispatch(setSustainabilityYearsAction(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps)(withForm);

export default withConnect;

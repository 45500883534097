import React from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { WrappedFieldArrayProps } from 'redux-form';
import { formatMonetaryValue } from 'helpers/math';

const Benefits = ({
  formValues = {},
  years,
  wapConfig,
  editData,
  change,
}) => {
  const theme = useTheme();

  return (
    <Box bgcolor={theme.palette.primary.dark} >

      <Grid container
        justify='space-between'
        alignItems='center'
        wrap='nowrap'>

        <Grid
          item
          xs={4}
          style={{
            padding: `${theme.spacing(2)}px`,
            borderRight: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant="body2"
            style={{ color: theme.palette.common.white, fontSize: '1.6rem' }}
          >
            {formatMonetaryValue(formValues['total premiums paid to date'], false)}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            padding: `${theme.spacing(2)}px`,
            borderRight: `1px solid ${theme.palette.primary.main}`,
            fontSize: '1.6rem',
          }}
        >
          <Typography
            // variant="body2"
            style={{ color: theme.palette.common.white }}
          >
            {years}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          style={{
            padding: `${theme.spacing(2)}px`,
            borderRight: `1px solid ${theme.palette.primary.main}`,
          }}
        >
          <Typography
            variant='body2'
            align='right'
            style={{
              color: theme.palette.common.white,
              fontWeight: 'bold',
              fontSize: '1.6rem',
            }}
          >
            {formatMonetaryValue(formValues['Fund Value'], false)}
          </Typography>
        </Grid>

      </Grid>
    </Box>
  );
}

Benefits.propTypes = {
  ...WrappedFieldArrayProps,
  formValues: PropTypes.object,
  theme: PropTypes.object,
  wapConfig: PropTypes.object,
  editData: PropTypes.object,
  years: PropTypes.number,
  change: PropTypes.func,
};

export default Benefits;

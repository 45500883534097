/* eslint-disable react/no-children-prop */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import GuardedRoute from 'components/Route';
import { FormattedMessage } from 'react-intl';
import { Grid, Snackbar } from '@material-ui/core';
import injectSaga from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import OktaAuthCallback from 'containers/OktaAuthCallback';
import LoginPage from 'containers/LoginPage';
import RegisterPage from 'containers/RegisterPage';
import DashboardPage from 'containers/DashboardPage';
import IdVerification from 'containers/IdVerification';
import LoadingDialog from 'components/LoadingDialog';
import PopupLoading from 'components/PopupLoading';
import ScrollToTop from 'components/ScrollToTop';
import illustrationPageReducer from 'containers/IllustrationPage/reducer';
import saga from './saga';
import {
  makeSelectLoading,
  makeSelectLoadingMessage,
  makeSelectError,
  makeSelectOption,
  makeSelectAuth,
  makeSelectAgentDetails,
  makeSelectPersistance,
  makeSelectFinancialInstituteOptions,
} from './selectors';
import GlobalStyle from '../../global-styles';
import '../../global-styles.css';
import AppWrapper from './AppWrapper';
import {
  getOptionResourcesAction,
  getPersistedAuthAction,
  setErrorAction,
  clearErrorAction,
} from './actions';
import Notification from 'components/Notification';
import { isEmpty } from 'lodash';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { makeSelectProductConfig } from 'containers/IllustrationPage/selectors';
import { getLocation } from 'connected-react-router';

const getLocationStateByProductConfig = (productConfig, params) => {
  const { productVersion } = productConfig;
  const quoteId = params[0];
  switch (productVersion) {
    case 'ITAS6_0001':
    case 'ITAS6_0002':
      return {
        state: { itaConfig: productConfig, quoteId },
        invalidRedirect: '/illustrations/ita/review',
      };
    case 'DTME2_0001':
    case 'DTME2_0002':
      return {
        state: { dtaConfig: productConfig, quoteId },
        invalidRedirect: '/illustrations/dta/review',
      };
    case 'FUTU5_0001':
      return {
        state: { futuraConfig: productConfig, quoteId },
        invalidRedirect: '/illustrations/futura/review',
      };
    case 'WAPL2_0001':
      return {
        state: { wapConfig: productConfig, quoteId },
        invalidRedirect: '/illustrations/wap/review',
      };
    case 'MFS01_0001':
      return {
        state: { gcConfig: productConfig, quoteId },
        invalidRedirect: '/illustrations/gc/review',
      };
    case 'SIMW2_0001':
      return {
        state: { swConfig: productConfig, quoteId },
        invalidRedirect: '/illustrations/sw/review',
      };
    case 'RSP01_0001':
    case 'RSP01_0002':
    case 'RSP01_0003':
    case 'RSP01_0004':
      return {
        state: { gcConfig: productConfig, quoteId },
        invalidRedirect: '/illustrations/rps/review',
      };
    default:
      break;
  }
};

function App({
  loading,
  loadingMessage,
  error,
  getOptionResources,
  auth,
  productConfig,
  agentDetails,
  financialInstituteOptions,
  persistance,
  getPersistedAuth,
  clearError,
}) {
  useInjectReducer({
    key: 'illustrationPage',
    reducer: illustrationPageReducer,
  });
  const isAuthPage = useRouteMatch('/login');
  const isRegisterPage = useRouteMatch('/register');
  const location = useLocation();
  const [isOpenAppError, setOpenAppError] = useState(false);
  let invalidRedirect = '/dashboard';
  let stateParams;
  if (location.pathname === '/oAuth/callback') {
    const oktaHash = queryString.parse(location.hash);
    const params = oktaHash?.state.split(',') || [];
    // Create location state for integrate from DA
    if (!isEmpty(productConfig) && auth?.okta?.accessToken) {
      const locationState = getLocationStateByProductConfig(
        productConfig,
        params,
      );
      invalidRedirect = locationState.invalidRedirect;
      stateParams = locationState.state;
    }
  }

  useEffect(() => {
    if (isAuthPage === null) {
      getOptionResources();
      getPersistedAuth();
    }
  }, []);

  useEffect(() => {
    setOpenAppError(!!error.message);
  }, [error.message]);

  const handleErrorSnackbarClose = () => {
    setOpenAppError(false);
    clearError();
  };

  // const handleErrorSnackbarClose = () => clearError();
  if (!persistance.loaded && !isAuthPage && !isRegisterPage) {
    return <LoadingDialog isLoading />;
  }
  return (
    <AppWrapper>
      <ScrollToTop />
      <Helmet titleTemplate="%s - B2C" defaultTitle="Quote & Apply from Zurich">
        <meta name="description" content="360F's B2C" />
      </Helmet>
      <Grid item xs>
        <Switch>
          <GuardedRoute
            path="/oAuth/callback"
            valid={
              !auth?.okta?.accessToken ||
              isEmpty(agentDetails) ||
              financialInstituteOptions.toJS().length === 0
            }
            invalidRedirectTo={invalidRedirect}
            state={{ ...stateParams, isNavigateFromDAQuote: true, type: 1 }}
            component={OktaAuthCallback}
          />
          <GuardedRoute
            path="/login"
            exact
            valid={!auth?.okta?.accessToken}
            invalidRedirectTo="/dashboard"
            component={LoginPage}
          />
          <GuardedRoute
            path="/register"
            component={RegisterPage}
            valid={!auth?.okta?.accessToken}
            invalidRedirectTo="/dashboard"
          />
          <GuardedRoute
            valid={!!auth?.okta?.accessToken}
            invalidRedirectTo="/login"
            path={['/dashboard', '/illustrations', '/applications', '/ifi','/adminapplications']}
            component={DashboardPage}
          />
            
          <GuardedRoute path="/download" exact valid component={PopupLoading} />
          <Route path="/id-verification" component={IdVerification} />
          <Route path="/" render={() => <Redirect to="/login" />} />
        </Switch>
      </Grid>
      <Notification
        open={isOpenAppError}
        onClose={handleErrorSnackbarClose}
        autoHideDuration={5000}
        message={
          error.message ? (
            <FormattedMessage {...error.message} values={{ ...error.values }} />
          ) : null
        }
        severity="error"
      />
      <LoadingDialog
        isLoading={loading}
        message={
          loadingMessage.id ? <FormattedMessage {...loadingMessage} /> : null
        }
      />
      <GlobalStyle />
    </AppWrapper>
  );
}
App.propTypes = {
  loading: PropTypes.bool,
  loadingMessage: PropTypes.object,
  options: PropTypes.object,
  getOptionResources: PropTypes.func,
  error: PropTypes.object,
  auth: PropTypes.object,
  financialInstituteOptions: PropTypes.object,
  persistance: PropTypes.object,
  getPersistedAuth: PropTypes.func,
};

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  loadingMessage: makeSelectLoadingMessage(),
  error: makeSelectError(),
  options: makeSelectOption(),
  auth: makeSelectAuth(),
  agentDetails: makeSelectAgentDetails(),
  persistance: makeSelectPersistance(),
  financialInstituteOptions: makeSelectFinancialInstituteOptions(),
  productConfig: makeSelectProductConfig(),
});

function mapDispatchToProps(dispatch) {
  return {
    getOptionResources: () => dispatch(getOptionResourcesAction()),
    getPersistedAuth: () => dispatch(getPersistedAuthAction()),
    setError: payload => dispatch(setErrorAction(payload)),
    clearError: () => dispatch(clearErrorAction()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withSaga = injectSaga({ key: 'app', saga });

export default compose(withSaga, withConnect)(App);

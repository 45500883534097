import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 24,
    height: 24,
    border: '1px solid #4066B3',
    backgroundColor: '#ffffff',
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    border: '1px solid #4066B3',
    backgroundColor: '#ffffff',
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor: '#4066B3',
      position: 'absolute',
      top: '5px',
      left: '5px',
      content: '""',
    },
  },
});

// Inspired by blueprintjs
export default function CustomRadio(props) {
  const classes = useStyles();
  return (
    <Radio
      className={classes.root}
      disableRipple
      color="primary"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

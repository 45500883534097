/*
 * HubPage Messages
 *
 * This contains all the text for the HubPage container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.HubPage';

export default defineMessages({
  hubPage: {
    id: `${scope}.hubPage`,
    defaultMessage: 'Dashboard Page',
  },
  newIllustratoin: {
    id: `${scope}.newIllustratoin`,
    defaultMessage: 'New Illustration',
  },
  somethingSpecific: {
    id: `${scope}.somethingSpecific`,
    defaultMessage: 'Looking for something specific?',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
  newBusiness: {
    id: `${scope}.newBusiness`,
    defaultMessage: 'New business',
  },
  existingBusiness: {
    id: `${scope}.existingBusiness`,
    defaultMessage: 'Existing Business',
  },
  searchPolicy: {
    id: `${scope}.searchPolicy`,
    defaultMessage: 'Search for a policy',
  },
  searchPolicyPlaceHolder: {
    id: `${scope}.searchPolicyPlaceHolder`,
    defaultMessage: 'Enter policy number or policy owner name',
  },
  searchDatabase: {
    id: `${scope}.searchDatabase`,
    defaultMessage: 'Search database',
  },
  enterPolicyNumber: {
    id: `${scope}.enterPolicyNumber`,
    defaultMessage: 'Enter policy number or policy owner name',
  },
});

/**
 *
 * FormNumberField
 *
 */

import React from 'react';
import { WrappedFieldProps } from 'redux-form';
import NumberField from 'components/NumberField';
import { fixLeadingZero } from 'helpers/math';

const FormNumberField = ({
  label,
  input: { value, onChange, onBlur, ...otherInputProps },
  meta: { invalid, error, touched, warning },
  inputFormat,
  allowLeadingZero,
  ...custom
}) => {
  let helperMsg = null;
  if (warning) {
    helperMsg = warning;
  }
  if (touched && error) {
    helperMsg = error;
  }

  return (
    <NumberField
      variant="outlined"
      label={label}
      value={value}
      // onChange={newVal => {
      //   if (isEmpty(newVal) && !isNumber(newVal)) {
      //     return onChange(null);
      //   }
      //   return onChange(numeral(newVal).value());
      // }}
      onChange={newVal => onChange(newVal)}
      // onBlur={() => {
      //   const fixedLeadingZero = fixLeadingZero(value);
      //   if (isEmpty(value) && !isNumber(value)) {
      //     return onBlur(null);
      //   }
      //   return onBlur(numeral(fixedLeadingZero).value());
      // }}
      onBlur={() => {
        const fixedLeadingZero = fixLeadingZero(value);
        if (allowLeadingZero) {
          return onBlur(value);
        }
        return onBlur(fixedLeadingZero);
      }}
      error={!!warning || (touched && invalid)}
      helperText={helperMsg}
      format={inputFormat}
      {...custom}
      {...otherInputProps}
    />
  );
};

FormNumberField.propTypes = {
  ...WrappedFieldProps,
};

export default FormNumberField;
